import React, { Component } from "react";
import { Row, FormGroup, FormControl, FormLabel, Button, Card} from 'react-bootstrap';
import './login.sass';
import { withRouter } from 'react-router-dom';

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false // Indicates in progress state of login form
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let { formData } = this.state;
        formData[name] = value;

        this.setState({
            formData: formData
        });
    }

    validateLoginForm = (e) => {

        let errors = {};
        const { formData } = this.state;

        if (formData.email==="admin@superzop.com" && formData.password==="secret" ) {
           
            localStorage.setItem("userid","1");
            return true;
        }
            
             else if(formData.email==="zadmin@superzop.com" && formData.password==="secret1")
           { 
            localStorage.setItem("userid","2");
            return true;
        
        }
            else{
            return false;}
        }
    

    login = (e) => {

        e.preventDefault();

        let errors = this.validateLoginForm();
        
        if(errors === true){
            window.location.href="./update";
            sessionStorage.setItem("token","superzop")
        } else {
            this.setState({
                errors: errors,
                formSubmitted: true
            })
        }
    }

    render() {

        const { errors, formSubmitted } = this.state;

        return (
    <div>
        <Card bg="light" style={{height:"80vh"}}>
            <Card.Header className="text-center" >Login<Button style={{float:"right"}} onClick={()=>{this.props.history.push('/')}} >Go to Dashboard</Button></Card.Header>
            <Card.Body>
            <div className="Login">
                <Row>
                    <form onSubmit={this.login}>
                        <FormGroup controlId="email" validationState={ formSubmitted ? (errors.email ? 'error' : 'success') : null }>
                            <FormLabel>Email</FormLabel>
                            <FormControl type="text" name="email" placeholder="Enter your email" onChange={this.handleInputChange} />
                        { errors.email                        }
                        </FormGroup>
                        <FormGroup controlId="password" validationState={ formSubmitted ? (errors.password ? 'error' : 'success') : null }>
                            <FormLabel>Password</FormLabel>
                            <FormControl type="password" name="password" placeholder="Enter your password" onChange={this.handleInputChange} />
                        { errors.password                        }
                        </FormGroup>
                        <div className="Button">
                        <Button type="submit" bsStyle="primary">Sign-In</Button>
                        </div>
                    </form>
                </Row>
            </div>
            </Card.Body>
        </Card>
    </div>
        )
    }
}

export default Login;
