import React from 'react'

const  UpdatedTime = () => {

    var today = new Date();
    var h =today.getHours()
    var m = today.getMinutes();
    var s = today.getSeconds();
    if (m < 10){ 
    m='0'+m;}
    if (s< 10){ 
    s ='0'+s;}
    var todaysTime =`${h}:${m}:${s}`;

    return todaysTime
}

export default UpdatedTime
