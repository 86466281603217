import React, { useCallback } from 'react'
import {useState,useEffect,useContext,createContext} from 'react'
import {Table,Button,Modal,Form,Alert,Dropdown,DropdownButton} from 'react-bootstrap'
import { FaTrashAlt } from 'react-icons/fa';
import database from '../../../../service/firebaseConfig';
import ApartmentEditModal from './ModifyApartments'
import Delapt from './DeleteApartment'

// export const UserValue=createContext()
// import ApartmentBlock from './apartmentBlocks';



const ApartmentCreationModal=(props)=> {

  const [validated, setValidated] = useState(false);
  const [getBlockNos,setBlockNos]=useState(0);
  const [newgetHooks,newsetHooks]=useState([]);
  const [getblockDetails,setblockDetails]=useState([])
  const [getAptInput,setAptInput] = useState('')
  const [getblocksinFo,setblocksinFo]=useState("")
  const [getAptAddress,setAptAddress]=useState("")
  const [getContactPerson,setContactPerson]=useState("")
  const [getContactPhoneNo,setContactPhoneNo]=useState("")
  const [getASMName,setASMName]=useState("")
  const [getASMId,setASMId]=useState("")
  const [getLatitude,setLatitude]=useState("")
  const [getLongitude,setLongitude]=useState("")
  const [getPin,setPin]=useState("")
  const [getArea,setArea]=useState("")

  // const flatData=useContext(UserValue)
  var arrElem=[];
  let blocksinFo=""

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else{

        event.preventDefault()
        props.addNewBlock?
      console.log("new blocks created"):
      props.apartmentName(getAptInput,getAptAddress,getContactPerson,getContactPhoneNo,getASMName,getASMId,getLatitude,getLongitude,
      getPin,getArea)
      alert("Data saved..")
      props.onHide()
      window.location.reload()
        }


    setValidated(true);
  };

  const tableCreation=()=>{

    setBlockNos(getBlockNos+1)
    console.log(getblockDetails)
    setblockDetails([...getblockDetails,getblocksinFo])
    for(var i=0;i<=getBlockNos;i++ ){

      arrElem.push(i)
    }

    newsetHooks(arrElem)

}

  return (
      <Modal
      id="modale"
      keyboard="false"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
          {props.addNewBlock?"Add Blocks to apartment":"Add apartments"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
          <thead></thead>
          <tbody>
          {props.addNewBlock &&
          <tr>
            <td>Apartment Id</td>
            <td><Form.Control type="text" value={props.editAptId} readOnly /></td>
          </tr>}
          <tr>
            <td>Apartment Name</td>
            <td><Form.Control type="text"  value={props.addNewBlock?props.editAptName:getAptInput}
                onChange={(e)=>{setAptInput( e.target.value)}} required />
                <Form.Control.Feedback type="invalid">
                Apartment name cant be blank
                </Form.Control.Feedback>
            </td>
          </tr>
          {!props.addNewBlock&& <tr>
          <td>Add address</td>
          <td><Form.Control required type="text" onChange={(e)=>setAptAddress(e.target.value)}></Form.Control>
              <Form.Control.Feedback type="invalid">
              *required
              </Form.Control.Feedback>
          </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Contact person</td>
            <td><Form.Control type="text" onChange={(e)=>setContactPerson(e.target.value)}></Form.Control>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Contact Phone Number</td>
            <td><Form.Control type="text" onChange={(e)=>setContactPhoneNo(e.target.value)}></Form.Control>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Latitude</td>
            <td><Form.Control type="text" onChange={(e)=>setLatitude(e.target.value)}></Form.Control>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Longitude</td>
            <td><Form.Control type="text" onChange={(e)=>setLongitude(e.target.value)}></Form.Control>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Select ASM</td>
            <td><DropdownButton id="dropdown-basic-button" title={getASMName?getASMName:"--Select--"}>

              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90078")}} name="Santosh Singh">Santosh Singh</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90147")}} name="Channakrishna Reddy">Channakrishna Reddy</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90125")}} name="Rayomant Kadam">Rayomant Kadam</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90010")}} name="JP">JP</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90075")}} name="Sagar">Sagar</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90015")}} name="Deepak">Deepak</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90071")}} name="Vinod Vijayan">Vinod Vijayan</Dropdown.Item>
              <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90000")}} name="SuperZop">SuperZop</Dropdown.Item>

              </DropdownButton>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>ASM Id</td>
            <td><Form.Control type="text" value={getASMId && getASMId} readOnly></Form.Control>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Pincode</td>
            <td><Form.Control type="text" onChange={(e)=>setPin(e.target.value)}></Form.Control>
            </td>
          </tr>}
          {!props.addNewBlock&& <tr>
            <td>Area</td>
            <td><Form.Control required type="text" onChange={(e)=>setArea(e.target.value)}></Form.Control>
              <Form.Control.Feedback type="invalid">
                *required
              </Form.Control.Feedback>
            </td>
          </tr>}
          <tr>
            <td colSpan="2"> </td></tr></tbody></Table>
          {props.addNewBlock ?
            <>
            <Button onClick={()=>{tableCreation()}}>Add block</Button>
            <Table id="blocks">
            {getBlockNos>0?newgetHooks.map(items=>(
            <tr><td>Block {items+1}</td>
              <td><Form.Control type="text" id="formDisabled" onChange={(e)=>{setblocksinFo(e.target.value)}}
                    placeholder="Enter new apartment block name" required /></td>
            </tr>

    )):''} </Table>
    </>:""}
        </Modal.Body>
        <Modal.Footer>
      {props.addNewBlock?<Button type="submit" variant="success" onClick={()=>{console.log(getblocksinFo);

        setblockDetails([...getblockDetails,getblocksinFo])
          getblockDetails.push(getblocksinFo)
          console.log(getblockDetails)
        props.BlockData(getblockDetails);
        setBlockNos(0);props.onHide()}} >Update apartment</Button>:
        <Button type="submit" variant="success" onClick={()=>{setBlockNos(0)}} >Add apartment</Button>}
          <Button onClick={()=>{setBlockNos(0);setValidated(false);props.onHide()}} >Close</Button>
        </Modal.Footer></Form>
      </Modal>
    );
  }
const Apartments=({children})=> {

    const [modalShow, setModalShow] = useState(false)
    const [getStatus, setStatus] = useState(false)
    const [getApartments,setApartments]=useState([])
    const [getApartMentId, setApartMentId] = useState('')
    const [getapartmentKey,setapartmentKey]=useState('')
    const [getEditBlock,setEditBlock] = useState(false)
    const [getselectaptId,setselectaptId]= useState('')
    const [getselectaptName,setselectaptName]= useState('')
    const [getdelAptId,setdelAptId]=useState('')
    const [getBlockDetails,setBlockDetails]=useState([])
    const [EditmodalShow,setEditModalShow]=useState(false)

    const sendFlatBlockdetails=[]

    let delAptName=''

    const apartmentsFetch=()=>{

        const apartMents=[]
        database.database().ref('Apartments/Mumbai').once("value",snapShot=>{

                const apartmentdetails=snapShot.val();
                console.log(apartmentdetails)
                Object.keys(apartmentdetails).forEach(items=>{
                    apartMents.push({"apartment_id":apartmentdetails[items].apartment_id,
                    "apartment_name":apartmentdetails[items].apartment_name,
                    "address":apartmentdetails[items].address})

                })
                console.log(apartMents)
                setApartments(apartMents)
        })
      }

      const setUniqueId =()=>{
            let NewId=''
            getApartments.forEach(items=>{
            console.log(items.apartment_id);
            NewId=Math.max(items.apartment_id);
            })
        const assignNewApartmentId=NewId+1
        setApartMentId(assignNewApartmentId)

    }

        const pushNewapartment=(apartment_Name,aptAddress,contactPerson,contactPhone,ASMName,ASMId,latitude,longitude,pin,area)=>{

        if(getEditBlock===false){
        if(getApartMentId!==undefined){

        let AptDetails={}
        var initializeLastId=Number(getApartMentId+"000");
        var today = new Date();
        var month =''+(today.getMonth()+1)
        var date=''+today.getDate()
        if (month.length < 2){
        month='0'+month;
        console.log(month)  }
        if (date.length < 2){
        date ='0'+date;
        console.log(date)}
        var TodaysDate = today.getFullYear()+'-'+month+'-'+date;

        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = TodaysDate+' '+time;
        console.log(apartment_Name)
        console.log(sendFlatBlockdetails)
          AptDetails["apartment_id"]=getApartMentId.toString()
          AptDetails["apartment_name"]=apartment_Name
          AptDetails["last_id"]=initializeLastId
          AptDetails["address"]=aptAddress
          AptDetails["contact_person"]=contactPerson
          AptDetails["created_date"]=dateTime
          AptDetails["contact_phone_number"]=contactPhone
          AptDetails["asm"]=ASMName
          AptDetails["asm_id"]=parseInt(ASMId)
          AptDetails["latitude"]=latitude
          AptDetails["longitude"]=longitude
          AptDetails["pincode"]=pin
          AptDetails["area"]=area
          AptDetails["status"]="Y"

        console.log(AptDetails)

        database.database().ref('Apartments/Mumbai/'+getApartMentId+'/').set(AptDetails).then(()=>{

          alert(`Apartment details created successfully with ID ${getApartMentId}`)
          setStatus(!getStatus)
        })


        }
      }
      }




    useEffect(() => {

        apartmentsFetch()
    }, [])

    useEffect(() => {
        if(getApartments.length>0)
        setUniqueId()
    }, [getApartments])

    useEffect(() => {
      if(getBlockDetails.length>0)
      {
        getBlockDetails.splice(0,1)
      getBlockDetails.map((item,index)=>{

        database.database().ref(`Blocks/Mumbai/${getselectaptId}/${item}`).update({0:"no flats entered"}).then(()=>
        alert ("Updated Block details"))
            console.log(item)

      })

      console.log(getBlockDetails)
    }
    }, [getBlockDetails])


    return (

        <div style={{textAlign:"center"}} >
        {getApartments.length!==0?(<>
          <Button style={{display:"inline-block"}} variant="primary" onClick={() => {setModalShow(true);setEditBlock(false)}}>
        Add apartments
      </Button><br/><br/>
        <Table  striped bordered hover>
        <thead>
          <tr>

            <th>Apartment Id</th>
            <th>Apartment Name</th>
            <th>Address</th>
            <th>Add blocks and flats</th>
            <th>Edit Apartments</th>
            <th>Delete apartments</th>
          </tr>
        </thead>
        <tbody>
        {getApartments.map((items)=>(
          <tr>
            <td>{items.apartment_id}</td>
            <td>{items.apartment_name}</td>
            <td>{items.address}</td>
            <td><Button variant="success" onClick={() => {setModalShow(true);console.log(items.apartment_name);setEditBlock(true);
            setselectaptId(items.apartment_id);setselectaptName(items.apartment_name)}}>Add blocks</Button> </td>
            <td><Button variant="info" onClick={()=>{setEditModalShow(true);setselectaptId(items.apartment_id);setselectaptName(items.apartment_name)}}>Edit apartment details</Button></td>
            <td><Button variant="outline-danger" onClick={()=>{setdelAptId(items.apartment_id)}}> <FaTrashAlt/> </Button></td></tr>))}</tbody></Table>
            <Delapt apartId={getdelAptId} />
              <ApartmentCreationModal
              apartmentName={pushNewapartment}
              newapartmentId={getApartMentId}
            //  recieveData={blocksAndFlatsInfo}
              addNewBlock={getEditBlock}
              show={modalShow}
              BlockData={setBlockDetails}
              onHide={() => setModalShow(false)}
              editAptId={getselectaptId}
              editAptName={getselectaptName}
              />
      {EditmodalShow &&
      <ApartmentEditModal
        show={EditmodalShow}
        onHide={() => setEditModalShow(false)}
        apartmentId={getselectaptId}
        apartmentName={getselectaptName}
      />}
      </>
          ):( <Alert variant="primary">Loading..</Alert>)}
      </div>
    )
}

export default Apartments
