import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  Stack,
  Select,
  TextInput,
  Divider,
  Text,
  Table,
  Box,
  ScrollArea,
  Flex,
  NumberInput,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { AddIngredientModal } from "./AddIngredientModal";
import { BOMFormData } from "./types";
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";
import moment from "moment";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IconSquareRoundedX } from "@tabler/icons-react";
import {ProductIngredientsById} from "./ProductsIngredientsById"

const InlineTextStyles = styled.div`
  text-align: center;
  .mantine-Text-root {
    display: inline;
  }
`;

export function CreateBomDrawer({
  materialsAndCombinations,
  opened,
  setOpened,
  productMaster,
  fetchBoMListingData,
  setfFinalCostInCalculationModal
}) {

  const [autoFormData, setAutoFormData] = useState({
    selectedItem:null,
    userInput:null,
    tableData:null,
    totalCost:null,
    packagingCost:null,
    costPerQty:null,  
    });
  

  const [categorySelections, setCategorySelection] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [itemsListOptions, setSelectedItemsListOptions] = useState([])
  const [getSelectedItem, setItemSelections] = useState('')
  const [apiDatas, setApiDatas] = useState('')
  
 
  
  useEffect(()=>{

    const getDropdownOptions_URL = `${DELIVERY_BASE_URL}/api/superzop/billmanagement/getBillMaterialsCategoryDetails`   

    const requestOptions =  {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                          }
                        }
    
  
    fetch(getDropdownOptions_URL,requestOptions).then((response)=>{
      const results = response.json()
      return results
    }
    ).then((data)=>{

      const datas = data.data

      // Making category format for rendering from the dictionary/hashMap key values obtained from Bill_Materials table through the raw API datas
      // this is all the type of category
      const category = Object.keys(datas).map(key => ({
        value: key,
        label: key 
      }));

      // storing all the raw received hashSet values from the API 
      setApiDatas(datas)
      // setting up category to render at UI in the category section of the UI
      // it is list of objects
      setCategorySelection(category)
      // setting default category selection
      // considering the first value inside of the list of objects as default
      setSelectedCategory(category[0].value)


      // setting the defalut options from the selected 'default category'
      // datas is the complete hasSet and category[0].value is the selected key eg. Batter
     
      const selectedKeyType = datas[(category[0].value)]
      
      // making a simple list of Items' objects of the selected 'default category'
      const dropDownMenu = selectedKeyType.map(item => ({
        value:{"item_id":item.item_id,"weight_unit":item.weight_unit,"packaging_cost":item.packaging_cost, "weight_val": parseFloat(item.weight_val)},
        label: item.item_description // this key is a must in mantine 'label' for it understand label bu default
      }));

      setSelectedItemsListOptions(dropDownMenu) // set the default dropdown menu
      
    })

  },[])



  const parentMaterials = materialsAndCombinations[0] || [];
  const childMaterialsObj = materialsAndCombinations[1] || {};
  const [parentItemSelectDropdownValues, setParentItemSelectDropdownValues] =
    useState([]);
  const [formData, setFormData] = useState(
    new BOMFormData(new Date(), "batter")
  );
  const [isAddIngredientModalOpened, setIsAddIngredientModalOpened] =
    useState(false);

  function validateFormFields() {
    // const { type, item_id, qty, packaging_cost, selectedIngredients } =
    //   formData;
    // console.log(
    //   "checking ",
    //   type,
    //   item_id,
    //   packaging_cost,
    //   qty,
    //   selectedIngredients.length === 0
    // );
    // if (
    //   !type ||
    //   !item_id ||
    //   !packaging_cost||
    //   !qty ||
    //   selectedIngredients.length === 0
    // )
    //   return false;
    return true;
  }

  function onFormSubmit() {

    if (!autoFormData) {
      return toast("Please click on Show Ingredients and Pricing button First", { type: toast.TYPE.WARNING });
  } else {
      for (const key in autoFormData) {
          if (autoFormData[key] === null) {
              return toast("Please click on Show Ingredients and Pricing button First", { type: toast.TYPE.WARNING });
          }
      }
  }

    const cost_per_kg = autoFormData.costPerQty;
    let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`);

    const data = {
      type: formData.type,
      bom_date: moment(formData.creation_date).format("YYYY-MM-DD"),
      bom_item_id: formData.item_id,
      bom_item_name: formData.name,
      bom_item_weight: formData.weight,
      total_weight: autoFormData.userInputTotalQuantityConversionInKg,
      total_cost: autoFormData.totalCost-autoFormData.packagingCost,
      cost_per_kg,
      bom_quantity: autoFormData.userInput,
      packing_cost: autoFormData.packagingCost,
      major_category: formData.major_category,
      final_price: autoFormData.costPerQty+autoFormData.packagingCost,
      outer_size: formData.outer_size,
      item: Object.entries(autoFormData.tableData).map(([key, value]) => {

          let outer_size

          if(productMaster[value.IngredientId]){
           outer_size = productMaster[value.IngredientId].outer_size;
          }
          else{
            outer_size=formData.outer_size
          }
          
          return {
            item_id: value.IngredientId,
            item_name: key,
            weight_kg: parseFloat(value.adjustedQuantityInkg),
            outer_size: outer_size,
            cost: value.price
          };
        }),
      created_by: loggedEmailId,
      warehouse:sessionStorage.getItem("Logged User City")
    };

    console.log("check submitted data--->", data);

    const url = `${DELIVERY_BASE_URL}/api/superzop/billmanagement/createBillMaterials`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status != 200) {
          return toast("Error occured while creating BoM", {
            type: toast.TYPE.ERROR,
          });
        }
        console.log("submitted successfully", res.status);
        fetchBoMListingData();
        setOpened(false);
        return toast("Successfully created BoM", { type: toast.TYPE.SUCCESS });
      })
      .catch((err) => {
        console.log("Error: ", err);
        return toast("Error occured while creating BoM", {
          type: toast.TYPE.ERROR,
        });
      });
  }

  const onFormModified = useCallback(
    (key, value) => {
      if (key === "parentItem") {
        if (!productMaster[value]) {
          return toast(
            `Error : Data not found in prod master for item: ${value}`,
            { type: toast.TYPE.ERROR }
          );
        }
        const {
          category,
          outer_size,
          item_description1,
          brand,
          weight,
          base_price,
        } = productMaster[value];
        const childIngredients = childMaterialsObj[value];
        const newFormObj = formData.clone();
        newFormObj.availableIngredientsForSelection = childIngredients;
        newFormObj.major_category = category;
        newFormObj.outer_size = outer_size;
        newFormObj.item_id = value;
        newFormObj.name = item_description1;
        newFormObj.brand = brand;
        newFormObj.weight = weight;
        setFormData(newFormObj);
      } else if (key === "packaging_cost") {
        const newFormObj = formData.clone();
        newFormObj.modifyPackagingCost(value);
        setFormData(newFormObj);
      } else if (key === "quantity") {
        const newFormObj = formData.clone();
        newFormObj.modifyQuantity(value);
        setFormData(newFormObj);
      } else if (key === "type") {
        const newFormObj = formData.clone();
        newFormObj.type = value;
        setFormData(newFormObj);
      }
    },
    [formData]
  );
  

  const onModifyIngredient = useCallback(
    (type, data) => {
      const { item_id, weight = "" } = data;
      let newFormObj = formData.clone();
      switch (type) {
        case "add":
          const { item_description1, outer_size, base_price } =
            productMaster[item_id];
            console.log("Here The Outer Size value is comming",outer_size)
          newFormObj.addIngredientToSelection(
            item_id,
            weight,
            item_description1,
            outer_size,
            base_price
          );
          break;
        case "delete":
          newFormObj.removeIngredientFromSelection(item_id);
          toast(`Deleted ingredient ${item_id}`, { type: toast.TYPE.SUCCESS });
          break;
      }
      setFormData(newFormObj);
    },
    [formData]
  );

  useEffect(() => {
    if (!materialsAndCombinations || !materialsAndCombinations[0]) return;
    const dropdownValues = [];
    const parentItems = parentMaterials;
    parentItems.forEach((item) => {
      const {
        brand = "",
        item_description1 = "",
        weight = "",
      } = productMaster[item] || {};
      dropdownValues.push({
        value: item,
        label: `${item} | ${item_description1} | ${brand} | ${weight}`,
      });
    });
    setParentItemSelectDropdownValues(dropdownValues);
  }, []);

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Create Bill of Material"
        padding="xl"
        size="50vw"
      >
        <ScrollArea style={{ height: "90vh" }} offsetScrollbars>
          <form action="">

            <Stack
              spacing={"1.5rem"}
              align="stretch"
              justify="center"
              pb={"3rem"}
            >

              <DatePicker
                placeholder="Date"
                label="Creation date"
                value={new Date()}
                disabled
              />

              <Select
                required
                label="Category"
                // value={formData.type}
                value={selectedCategory}
                placeholder="Select category"
                onChange={(val) => {

                  onFormModified("type", val);
                
                  // if the category is same as before then do nothing
                  if(selectedCategory!=val)
                  {
                    // set the changed/new category value 
                    setSelectedCategory(val)
                    // make the Item selection row dropdown empty on change/(new value) of category row change
                    setItemSelections('')


                  // On change of category re-making the dropdown menu selection of the Items
                  // apiDatas is the complete hasSet and 'val' is the selected key eg. Batter
                  const selectedKeyType = apiDatas[val]
                  // making a simple list of Items' objects of the selected 'new category'
                  const dropDownMenu = selectedKeyType.map(item => ({
                    value: {"item_id":item.item_id,"weight_unit":item.weight_unit,"packaging_cost":item.packaging_cost, "weight_val": parseFloat(item.weight_val)},
                    label: item.item_description // this 'label' key is a must in mantine for it to understand label rendering by default
                  }));

                  setSelectedItemsListOptions(dropDownMenu)
                  }
                }}

                data={categorySelections}
              />

              <Select
                required
                label="Select Item"
                // value={formData.item_id}
                value = {getSelectedItem} // reads and renders the values from the 'label' key of the object
                placeholder="Pick one"
                data={itemsListOptions}
                onChange={(value) => {
                  onFormModified("parentItem", value.item_id); // takes the 'value' key from the object to identify the itemId
                  setItemSelections(value)
                }}
              />

          {(formData.item_id && getSelectedItem )&& <ProductIngredientsById 
          productMaster={productMaster}
          itemId={formData.item_id}
          autoFormData={autoFormData}
          setAutoFormData={setAutoFormData}
          getSelectedItemValue={getSelectedItem}
          setfFinalCostInCalculationModal={setfFinalCostInCalculationModal} />}

              <Divider color="black" my="sm" variant="dashed" />
              <Flex justify={"center"}>
                <Button
                  type="submit"
                  style={{ width: "50%" }}
                  color="teal"
                  onClick={(event) => {
                    event.preventDefault();
                    onFormSubmit();
                  }}
                >
                  Create
                </Button>
              </Flex>
            </Stack>
          </form>
        </ScrollArea>
      </Drawer>
      {isAddIngredientModalOpened && (
        <AddIngredientModal
          opened={isAddIngredientModalOpened}
          setOpened={setIsAddIngredientModalOpened}
          onModifyIngredient={onModifyIngredient}
          availableItemIds={formData.availableIngredientsForSelection}
          productMaster={productMaster}
        />
      )}
    </>
  );
}
