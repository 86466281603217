import React,{useState,useEffect} from 'react'
import { Row,Form ,InputGroup,Card,Alert, Button, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import database from '../../../service/firebaseConfig';
import AadharFrontPage from './AadharFrontUpload';
import AadharBackPage from './AadharBackUpload'
import GstDocument from './GSTupload'
import LicenseDocument from './LicenseUpload'
import DatePicker from "react-datepicker";
// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import NavBar from '../../../Dashboard/Navbar/Dashboard_Navabar';
import FooterComponent from '../../../components/Footer/index';




const CreateNewRetailer=()=> {

    const [getMobNo,setMobNo]=useState("")
    const [getShopName,setShopName]=useState("")
    const [getShopType,setShopType]=useState("")
    const [getAddress1,setAddress1]=useState("")
    const [getAddress2,setAddress2]=useState("")
    const [getArea,setArea]=useState("")
    const [getPinCode,setPinCode]=useState("")
    const [getEmail,setEmail]=useState("")
    const [getCity,setCity]=useState("")
    const [getOwnerName,setOwnerName]=useState("")
    const [getPanNumber,setPanNumber]=useState("")
    const [getAadharNumber,setAadharNumber]=useState("")
    const [getAgentId,setAgentId]=useState("")
    const [getGST,setGST]=useState("")
    const [validated, setValidated] = useState(false);
    const [getAadharFront,setAadharFront]=useState("")
    const [getAadharBack,setAadharBack]=useState("")
    const [getNewGstDocument,setNewGstDocument]=useState("")
    const [getNewLicense,setNewLicense]=useState("")
    const [getDate,setDate]=useState(new Date())
    const [getAadharFrontButtonStatus,setAadharFrontButtonStatus]=useState(false)
    const [getAadharBackButtonStatus,setAadharBackButtonStatus]=useState(false)
    const [getGstButtonStatus,setGstButtonStatus]=useState(false)
    const [getLicenseButtonStatus,setLicenseButtonStatus]=useState(false)
    const [dataSubmission,setdataSubmission]=useState(true)
    
    const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    else{
       setdataSubmission(false)
        let last_id
        database.database().ref(`App_Version`).once("value",snapshot=>{
            const all_data=snapshot.val()
            last_id=(Object.values(all_data)[0]["last_retailer_id"])
        }).then(()=>{
            database.database().ref(`Retailer_Master_New/${getMobNo}`).set({
            retailer_id:last_id+1,
            aadhar_number:getAadharNumber,
            address1:getAddress1,
            address2:getAddress2,
            area:getArea,
            agent_id:getAgentId,
            city:getCity,
            dob:getDate.getDate()+"/"+(getDate.getMonth()+1)+"/"+getDate.getFullYear(),
            email:getEmail,
            pan_number:getPanNumber,
            phone:getMobNo,
            pincode:getPinCode,
            shop_name:getShopName,
            store_owner:getOwnerName,
            store_type:getShopType,
            gst_number:getGST,
            documents:{
                aadhar_front_page:getAadharFront,
                aadhar_back_page:getAadharBack,
                gst_document:getNewGstDocument,
                license_document:getNewLicense

            }

        }).then(()=> alert(`data inserted successfully with retailer id ${last_id+1}`))
        }).then(()=>{
            database.database().ref(`App_Version/-Ld8_P2P4ue6FYUiatdt`).update({last_retailer_id:last_id+1})  
            setAadharBack("")
            setAadharFront("")
            setNewGstDocument("")
            setNewLicense("")
        }).then(()=>{
            setdataSubmission(true)
            event.preventDefault()
            
        })
    }
    setValidated(true);
    };



    return (
      <>
      <div>
      <NavBar/>
      </div><br/><br/><br/><br/>
        <div style={{textAlign:"center"}}>

            <Alert variant="primary" style={{height:"75px"}}>Create New Retailer</Alert>
            {!dataSubmission?
                      "submitting data":
            <Card  style={{display:"inline-block", backgroundColor:"#FFF0F5" ,width:"1400px"}} >
            <Card.Body>
            <Card.Text>
        <Form noValidate validated={validated} onSubmit={handleSubmit} style={{padding:"30px"}}>
            <Form.Row>
            <Form.Group as={Col} md="4" controlId="validationFormik01">
                <Form.Label >Mobile Number</Form.Label>
                    <Form.Control
                    required
                    onChange={(e)=>setMobNo( e.target.value)}
                    type="number"
                    name="firstName"
                />

            <Form.Control.Feedback type="invalid">Mobile number required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormik02">
            <Form.Label>Shop Name</Form.Label>
            <Form.Control
                onChange={(e)=>setShopName( e.target.value)}
                required
                type="text"
                name="lastName"
            />

            <Form.Control.Feedback type="invalid">Shop name required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
            <Form.Label>Shop Type</Form.Label>
            
                <Form.Control
                onChange={(e)=>setShopType(e.target.value)}
                required
                type="text"
                aria-describedby="inputGroupPrepend"
                name="username"
            />
                <Form.Control.Feedback type="invalid">
                   Shop type required
                </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Label>Address1</Form.Label>
              <Form.Control
              onChange={(e)=>setAddress1(e.target.value)}
              required
                type="text"
                name="address1"
              
              />
                <Form.Control.Feedback type="invalid">
                Address required
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik04">
              <Form.Label>Address2</Form.Label>
              <Form.Control
              onChange={(e)=>setAddress2(e.target.value)}
              required
                type="text"
                
                name="address2"
              
              />
              <Form.Control.Feedback type="invalid">
                Address 2 required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik05">
              <Form.Label>Area</Form.Label>
              <Form.Control
              onChange={(e)=>setArea(e.target.value)}
              required
                type="text"
                
                name="zip"
              
              />
              <Form.Control.Feedback type="invalid">
              Area name required
             </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} md="3" controlId="validationFormik06">
              <Form.Label>City</Form.Label>
              <Form.Control
              onChange={(e)=>setCity(e.target.value)}
              required
                type="text"
                
                name="zip"
              
              />
              <Form.Control.Feedback type="invalid">
              City name required
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik07">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
              onChange={(e)=>setPinCode(e.target.value)}
              required
                type="number"
                
                name="zip"
              
              />
              <Form.Control.Feedback type="invalid">
              Pincode required
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik08">
              <Form.Label>Email-id</Form.Label>
              <Form.Control
              onChange={(e)=>setEmail(e.target.value)}
              required
                type="email"
                
                name="email"
              
              />
              <Form.Control.Feedback type="invalid">
              Email-id format invalid
             </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Card style={{backgroundColor:"#800080",color:"white"}}><Card.Body>Owner Details</Card.Body></Card><br/>
          <Form.Row>
          <Form.Group as={Col} md="3" controlId="validationFormik09">
              <Form.Label>Owner name</Form.Label>
              <Form.Control
              onChange={(e)=>setOwnerName(e.target.value)}
              required
                type="text"
                 name="store_owner"
              
              />
              <Form.Control.Feedback type="invalid">
             Owner name Required
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik10">
              <Form.Label>Date of Birth</Form.Label><br/>
             
              <DatePicker
                    className="form-control"
                    selected={getDate}
                    onChange={(date)=>{setDate(date)}}
                    minDate={new Date("1900/01/01")}
                    maxDate={new Date()}
                />
            
             
              <Form.Control.Feedback type="invalid">
              DOB Cant be empty
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik11">
              <Form.Label>Pan</Form.Label>
              <Form.Control
               onChange={(e)=>setPanNumber(e.target.value)}
              required
                type="text"
                name="pan_number"
              
              />
              <Form.Control.Feedback type="invalid">
              PAN Cant be empty
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik12">
              <Form.Label>Aadhar Number</Form.Label>
              <Form.Control
               onChange={(e)=>setAadharNumber(e.target.value)}
              required
                type="text"
                name="aadhar_number"
              
              />
              <Form.Control.Feedback type="invalid">
             Aadhar number Cant be empty
             </Form.Control.Feedback>
            </Form.Group>
            </Form.Row>
            <Form.Row>
            
            <Form.Group as={Col} md="3" controlId="validationFormik13">
            
            <Form.Label>Upload Aadhar Front</Form.Label><br/>
           
            
            <Button onClick={()=>{setAadharFrontButtonStatus(true);setAadharBackButtonStatus(false);setGstButtonStatus(false);setLicenseButtonStatus(false)}}>
                 Upload aadhar front</Button><br/>
                    {getAadharFrontButtonStatus &&
                (<AadharFrontPage buttonStatus={setAadharFrontButtonStatus} aadharFrontUrl={setAadharFront}/>)}
            <div><a href={getAadharFront} target="_blank">{getAadharFront}</a></div>
            
              <Form.Control.Feedback type="invalid">
            cant be empty
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik14"> 

                <Form.Label>Upload Aadhar Back</Form.Label><br/>
                <Button onClick={()=>{setAadharBackButtonStatus(true);setAadharFrontButtonStatus(false);setGstButtonStatus(false);setLicenseButtonStatus(false)}}>
                     Upload aadhar back</Button><br/>
                    {getAadharBackButtonStatus &&
                (<AadharBackPage buttonStatus={setAadharBackButtonStatus} aadharBackUrl={setAadharBack}/>)}
            <div><a href={getAadharBack} target="_blank">{getAadharBack}</a></div>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
                </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik15">
              <Form.Label>Agent ID</Form.Label>
              <Form.Control
               onChange={(e)=>setAgentId(e.target.value)}
              required
                type="text"
                name="agent_id"
              
              />
              <Form.Control.Feedback type="invalid">
              Agent Id Cant be empty
             </Form.Control.Feedback>
            </Form.Group>   
            </Form.Row>
            <Card style={{backgroundColor:"#800080",color:"white"}}><Card.Body>Document Upload</Card.Body></Card><br/>
            <Form.Row>
            <Form.Group as={Col} md="3" controlId="validationFormik16">
            <Form.Label>GST Number</Form.Label>
              <Form.Control
              onChange={(e)=>setGST(e.target.value)}
              required
                type="text"
                name="gst_number"
              
              />
             <Form.Control.Feedback type="invalid">
             GST Number Cant be empty
             </Form.Control.Feedback>
            </Form.Group> 
            <Form.Group as={Col} md="3" controlId="validationFormik17"> 
             
            <Form.Label>GST Document Upload</Form.Label>
            <Button onClick={()=>{setGstButtonStatus(true);setAadharFrontButtonStatus(false);setAadharBackButtonStatus(false);setLicenseButtonStatus(false)}}>Upload GST document</Button> 
            {getGstButtonStatus && <GstDocument buttonStatus={setGstButtonStatus} gstDocument={setNewGstDocument}/>}
            <div><a href={getNewGstDocument}  target="_blank">{getNewGstDocument}</a></div>
              <Form.Control.Feedback type="invalid">
             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationFormik18"> 
                
              <Form.Label>Shop License Upload</Form.Label>
              <Button onClick={()=>{setLicenseButtonStatus(true);setAadharFrontButtonStatus(false);setGstButtonStatus(false);setAadharBackButtonStatus(false)}}>
                   Upload License document</Button> 
            {getLicenseButtonStatus && <LicenseDocument  buttonStatus={setLicenseButtonStatus} licenseDocument={setNewLicense}/>}
            <div><a href={getNewLicense} target="_blank" >{getNewLicense}</a></div>
              <Form.Control.Feedback type="invalid">
             </Form.Control.Feedback>
            </Form.Group>
            </Form.Row><br/><br/>
            
          <Button variant="primary" size="lg" style={{width:"200px"}} type="submit" >Submit form</Button>
        </Form>
        </Card.Text>
    </Card.Body>
  </Card>}
  </div><br/>
    <div style={{position:"fixed",bottom:"0",width:"100%"}}>
        <FooterComponent/>
    </div>
    </>
    )
}

export default CreateNewRetailer
