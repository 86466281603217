import React, { useEffect, useState,useRef } from 'react';
import {
    Button,
    TextInput,
    ScrollArea
} from "@mantine/core";

import {Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material'

import { DELIVERY_BASE_URL } from "../../service/servicesConfig";
import { Prices, PriceMapping, hardCodedItemsItemId } from './types';



export function ProductIngredientsById({ itemId, productMaster,setAutoFormData,autoFormData,setfFinalCostInCalculationModal,getSelectedItemValue }) {



const [ingredientsData,setingredientsdata] = useState({}); 
const [requiredQuantity, setRequiredQuantity] = useState(null);
const [allRequiredIngredientsData,setAllRequiredIngredientsdata] = useState({}); 
const [itemsBasePrice, setItemsBasePrice] = useState(null);
const prevItemIdRef = useRef(null); 

const dBObjectsBasePriceMappingKey = PriceMapping.dBObjectsBasePriceMappingKey
const itemsIdForHardCodedValue = hardCodedItemsItemId.dBObjectsItemIdMappingKey

useEffect(() => {

    // Only fetch data if itemId has changed
    if (prevItemIdRef.current !== itemId) {
    setingredientsdata(null)
    setAllRequiredIngredientsdata({})
    setRequiredQuantity(null)

    fetch(`${DELIVERY_BASE_URL}/api/superzop/billmanagement/getBillMaterialsReferenceDetails?item_id=${itemId}`)
    .then(response => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json(); 
    })
    .then(data => {
        //console.log("URL",`https://dev-services2.superzop.com/api/superzop/billmanagement/getBillMaterialsReferenceDetails?item_id=${itemId}`)
        setingredientsdata(data);
        return data;
    }).then((data) => {
        const apiData = data.data
        let allRequiredIngredients = {}
        for (const key in apiData)
        {
            let UIvalue=apiData[key].UI_value
            if(key==="RO Water")
            {
                UIvalue="RO Water"
                apiData[key].UI_value="RO Water"
            }

            let ingredientPercentage=parseFloat(apiData[key].percentage)
           
            allRequiredIngredients[UIvalue]=ingredientPercentage
        }
        fetchedDataFormat(allRequiredIngredients,apiData)}).
    then(() => {
        setAutoFormData((prevData) => ({
            ...prevData,
            selectedItem:itemId,
            packagingCost: parseFloat(getSelectedItemValue.packaging_cost) //parseFloat(dBObjectsBasePriceMappingKey["PackagingPrice"])
        }));
    }).
    catch(error => {
        console.error('Error fetching data:', error);
    });
    }   
}, [itemId]);



const fetchedDataFormat=(allRequiredIngredients,apiData)=>{
    
        setAllRequiredIngredientsdata(allRequiredIngredients)
       
        const AllItemsIdAndIngredientsId=filteredAllItemsPriceFromFirebase()

        const ingredientBasePrices = mapIngredientsWithTheirBasePrice(AllItemsIdAndIngredientsId, itemId, apiData);
        
        setItemsBasePrice(ingredientBasePrices)


}


const filteredAllItemsPriceFromFirebase =()=>{
    // Initialized data structures
    const allItemsList = new Set();
    const AllItemsIdAndIngredientsId = {};

    // Process product data
    Object.keys(productMaster).forEach((key) => {
    const item = productMaster[key];

    // Check for missing "bill_material" property
    if (!item.hasOwnProperty("bill_material")) {
        //console.warn(`Skipping item ${key}: missing "bill_material" property`);
        return;
    }

    // Handle "bill_material" as an array
    let billMaterial = item.bill_material.split(",");

    // Extract and process parent information
    billMaterial.forEach((singleItemId) => {
        allItemsList.add(singleItemId.trim()); // Remove potential whitespace

        if (!AllItemsIdAndIngredientsId[singleItemId]) {
        AllItemsIdAndIngredientsId[singleItemId] = [];
        }
        AllItemsIdAndIngredientsId[singleItemId].push(item.item_id);
    });
    });

    return AllItemsIdAndIngredientsId

    }


//  console.log("Total Number of Product Id is :",Object.keys(productMaster[itemId]).length)
// console.log(JSON.stringify(productMaster[id], null, 2))

const mapIngredientsWithTheirBasePrice=(AllItemsIdAndIngredientsId,itemId,apiData)=>
{

    const itemsIngredientBasePrice = {};
    let currentItemsIngredientsIdList = AllItemsIdAndIngredientsId[itemId];

    for (let firebaseIngredientName in apiData) {
       
            const generatedRequiredKey = firebaseIngredientName
        
            let i = 0;
            while (i < currentItemsIngredientsIdList.length) {
                let ingredientId = currentItemsIngredientsIdList[i];
                if (ingredientId in productMaster) {
                    let ingredientObject = productMaster[ingredientId];
                 
                   // console.log(generatedRequiredKey.trim().toLowerCase(),"ind",ingredient)
                   // console.log(ingredientObject["item_description1"].trim().toLowerCase(),"Id",ingredientId)
                   if (generatedRequiredKey.trim().toLowerCase() === ingredientObject["item_description1"].trim().toLowerCase()) {
                      
                        // const base_price= parseFloat(ingredientObject["base_price"]);
                        const base_price= parseFloat(ingredientObject["landing_cost"]) ? parseFloat(ingredientObject["landing_cost"]) : parseFloat(ingredientObject["base_price"]);
                        //console.log("deleted-->",currentItemsIngredientsIdList[i])
                        const ID=currentItemsIngredientsIdList.splice(i, 1); // Delete the item at index i
                        const Id=ID[0]
                        itemsIngredientBasePrice[apiData[firebaseIngredientName].UI_value] ={

                            base_price,
                            Id
                        }

                        i--; // Adjust the index after deletion
                        break;
                    }
                }
                i++;
            }
        
    }
   
    itemsIngredientBasePrice["Packaging"]=parseFloat(getSelectedItemValue.packaging_cost)

    return itemsIngredientBasePrice;
    
}



return (
    <div>
    {/* Rendering QuantityInput component */}
    
    <QuantityInput onQuantityChange={setRequiredQuantity} setAutoFormData={setAutoFormData}  getSelectedItemValue={getSelectedItemValue}  />
    

    {/* Rendering the Final Component when the requiredQuantity & itemsBasePrice is available */}
    {requiredQuantity && itemsBasePrice && (
    <div>
    <h5 style={{textAlign:"center",marginTop:"5px",marginBottom:"3px"}}><b>Showing For </b> <b>{requiredQuantity} Pieces of ({getSelectedItemValue.weight_val} {getSelectedItemValue.weight_unit}) <h5>(Total is {requiredQuantity*getSelectedItemValue.weight_val} {getSelectedItemValue.weight_unit})</h5></b> </h5>
    <QuantityAndPropotionTable allRequiredIngredientsData={allRequiredIngredientsData} requiredQuantity={requiredQuantity} itemsBasePrice={itemsBasePrice} setAutoFormData={setAutoFormData} setfFinalCostInCalculationModal={setfFinalCostInCalculationModal} getSelectedItemValue={getSelectedItemValue}/>
    </div>
    )}
</div>
);
};


// Component For Taking the Qunatity input
const QuantityInput = ({ onQuantityChange,setAutoFormData,getSelectedItemValue }) => {
    const [quantity, setQuantity] = useState('');
    

    const handleQuantityChange = (e) => {
        const inputQuantity = e.target.value.trim(); // Remove whitespaces
        setQuantity(inputQuantity);
    };

    const setQuantityChange=(e)=>{
        const parsedQuantity = parseFloat(quantity); // Parsing input to float
        if (isNaN(parsedQuantity)) {
        alert('Please enter a valid quantity in Numbers.');
        return;
        }
        onQuantityChange(parsedQuantity); 

        const weightUnit = getSelectedItemValue.weight_unit
        const weightValue = getSelectedItemValue.weight_val

        setAutoFormData((prevData) => ({
            ...prevData,
            userInput: parsedQuantity,  // Update the userInput field in form data
            userInputTotalQuantityConversionInKg : weightUnit==="gm" ? (weightValue * parsedQuantity) / 1000 : (weightValue * parsedQuantity)
        }));

        //setQuantity('');  // uncomment this to erase the quantity field after entering the values
    }

    return (
    <div>
        <TextInput
        required
        value={quantity}
        label="Enter Required No. Of Pieces:"
        onChange={handleQuantityChange}
            />
        <br></br>
        <Button
    style={{
        borderRadius: 35,
        backgroundColor: "#21b6ae",
        fontSize: "18px"
        }}
        variant="contained"
        onClick={setQuantityChange}
        >

        <Typography style={{ color: "white",marginTop:"3px",marginBottom:"3px" }}>Show Ingredients and Pricing</Typography>

        </Button>
    </div>
    );
};


// Component For propotion Calculations 
const QuantityAndPropotionTable = ({ allRequiredIngredientsData, requiredQuantity, itemsBasePrice, setAutoFormData, setfFinalCostInCalculationModal, getSelectedItemValue }) => {

    const calculateQuantityPropotion = (allRequiredIngredientsData, requiredQuantity, itemsBasePrice) => {
        const calculatedIngredients = {};

        const weightUnit = getSelectedItemValue.weight_unit
        const packetSize = parseFloat(getSelectedItemValue.weight_val)
        const newRequiredQuantity = requiredQuantity * packetSize

        let total_cost=0
        let totalRounded_pricePerQuantity_cost=0
        
        for (let ingredient in allRequiredIngredientsData) {
        
            const ingredientWeightPercentage = allRequiredIngredientsData[ingredient];
            const ingredientBasePrice = itemsBasePrice[ingredient]["base_price"];
            const ingredientId = itemsBasePrice[ingredient]["Id"]
            

            // Calculate the quantity and price for the ingredient
            let calculatedQuantity = (newRequiredQuantity / 100) * ingredientWeightPercentage;

            let calculatedPrice
            let adjustedQuantityInKg
    
            if(weightUnit==="gm"){
                adjustedQuantityInKg = calculatedQuantity/1000
                // converting the calculatedQuantity in Kg because ingredientBasePrice is given as per kg
                calculatedPrice = (adjustedQuantityInKg) * ingredientBasePrice
            }
            else
            {   
                adjustedQuantityInKg = calculatedQuantity
                // here the calculatedQunatity is already in Kg
                calculatedPrice = adjustedQuantityInKg * ingredientBasePrice;
            }
           
            const roundedQuantity = parseFloat(calculatedQuantity).toFixed(2)===parseFloat(0).toFixed(2) ?  parseFloat(calculatedQuantity).toFixed(3) : parseFloat(calculatedQuantity).toFixed(2)
            // Rounded price for calculation of perIngredient cost from the total of perIngredient after % from API /100 * requiredQuantity
            const roundedPrice = parseFloat(calculatedPrice).toFixed(2)===parseFloat(0).toFixed(2) ?  parseFloat(calculatedPrice).toFixed(3) : parseFloat(calculatedPrice).toFixed(2)

            // If required Calculating only for the exact values after 2 place decimal places
            // const pricePerQuantity = parseInt((roundedPrice / requiredQuantity )*100)/100

            // Calculating only for the Rounding off values after 2 place decimal places
            const pricePerQuantity = parseFloat(roundedPrice / requiredQuantity).toFixed(2)
            const roundedPricePerQuantity = parseFloat(pricePerQuantity)
            
         
            if (Number(roundedPrice)){
                total_cost += parseFloat(roundedPrice)
                }

            if (Number(roundedPricePerQuantity)){
                totalRounded_pricePerQuantity_cost += parseFloat(roundedPricePerQuantity)
                }
           
            // Add the calculated values to the result object
            calculatedIngredients[ingredient] = {
                quantity: roundedQuantity,
                adjustedQuantityInkg : weightUnit==="gm" ? parseFloat(adjustedQuantityInKg).toFixed(2) : roundedQuantity,
                price: roundedPrice,
                perQuantityPrice : roundedPricePerQuantity,
                IngredientId:ingredientId
            };
        }
       
        itemsBasePrice["Total Cost Per Qty"]=parseFloat(totalRounded_pricePerQuantity_cost).toFixed(2)
        // total_cost+=itemsBasePrice["Packaging"]
        itemsBasePrice["Total Cost"]=parseFloat(total_cost).toFixed(2);

        setfFinalCostInCalculationModal(parseFloat(parseFloat(itemsBasePrice["Total Cost Per Qty"]) + parseFloat(itemsBasePrice["Packaging"])).toFixed(2))
        return calculatedIngredients;
    };

    const calculatedIngredients = calculateQuantityPropotion(allRequiredIngredientsData, requiredQuantity, itemsBasePrice);

    useEffect(() => {
        setAutoFormData((prevData) => ({
            ...prevData, 
            tableData: calculatedIngredients,
            totalCost:parseFloat(itemsBasePrice["Total Cost"]),
            costPerQty:parseFloat(itemsBasePrice["Total Cost Per Qty"])
        }));
    }, [allRequiredIngredientsData, requiredQuantity, itemsBasePrice, setAutoFormData]);

    return (
        <div>
             {/* <ScrollArea style={{ height: "100%", maxHeight: "calc(100vh - 72px)" }} offsetScrollbars> */}
        <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Ingredient</b></TableCell>
                        <TableCell><b>Quantity</b></TableCell>
                        <TableCell><b>Price</b></TableCell>
                        <TableCell><b>Price Per Quantity</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(calculatedIngredients).map(([ingredient, { quantity, price, perQuantityPrice, IngredientId  }]) => (
                        <TableRow key={ingredient}>
                            <TableCell>
                                <Typography>{ingredient} - ({<b>{IngredientId}</b>})</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{quantity}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{price}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{parseFloat(perQuantityPrice).toFixed(2)}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell align="center"><b>Total Cost</b></TableCell>
                        <TableCell align="center" colSpan={2}><b>{parseFloat(parseFloat(itemsBasePrice["Total Cost"])).toFixed(2)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"><b>Price Per Total Quantity</b></TableCell>
                        <TableCell align="center" colSpan={2}><b>{parseFloat(itemsBasePrice["Total Cost Per Qty"]).toFixed(2)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"><b>Packaging Cost</b></TableCell>
                        <TableCell align="center" colSpan={2}><b>{itemsBasePrice["Packaging"]}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center"><b>Final Cost (Per Piece) </b></TableCell>
                        <TableCell align="center" colSpan={2}><b>{parseFloat(parseFloat(itemsBasePrice["Total Cost Per Qty"]) + parseFloat(itemsBasePrice["Packaging"])).toFixed(2)}</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        {/* </ScrollArea> */}
    </div>
    );
};



