import XLSX from 'xlsx'

const ExportFinancialBill = ({listedFinanceDetails,multipleExport,exportDate,...props}) => {

    const exportFinancialBill = [["SI No.","RGRN Number","Invoice Number","Supplier Name","Information Correct","Reasons","Cheque Date","Cheque Number","Cheque Amount","Invoice Amount","Remarks","Approved At","Approved By"]]
    let index = 0;
    Object.keys(listedFinanceDetails).forEach(item=>{
        index +=1
        exportFinancialBill.push([index,listedFinanceDetails[item].grn_no,listedFinanceDetails[item].invoice_number,listedFinanceDetails[item].supplier_name,listedFinanceDetails[item].info_correct,listedFinanceDetails[item].reasons,
        listedFinanceDetails[item].cheque_date, listedFinanceDetails[item].cheque_number, listedFinanceDetails[item].cheque_amt, listedFinanceDetails[item].invoice_amt,listedFinanceDetails[item].remarks,listedFinanceDetails[item].approved_at,listedFinanceDetails[item].approved_by])
    })

    const wb = XLSX.utils.book_new()

    const financeDetailsList = XLSX.utils.aoa_to_sheet(exportFinancialBill)
    XLSX.utils.book_append_sheet(wb, financeDetailsList, `Finance Details`)
    XLSX.writeFile(wb, `Finance_Details_${exportDate}.xlsx`);
    multipleExport(false)

    return null
}

export default ExportFinancialBill
