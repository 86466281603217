import { Button, Select, Text, Title } from "@mantine/core";
import React from "react";
import styled from "styled-components";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { storeSeries as storeSeriesList } from "./utils/types";

const HeaderStyles = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: end;
  height: 7rem;
  width: 100%;
  margin-top: 4rem;
  flex-direction: row;
  padding: 1rem;
  gap: 2rem;
`;

const Header = ({
  handleOnAddWidgetModalShow,
  isProductMasterDataLoaded,
  storeSeries,
  setStoreSeries,
}) => {
  return (
    <>
      <NavBar />

      <HeaderStyles>
        <Title
          fz="14"
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 700,
          })}
        >
          App home screen config
        </Title>

        <Select
          label="Store Series"
          clearable
          name="store_series"
          placeholder="Select a Series"
          onChange={setStoreSeries}
          value={storeSeries}
          data={storeSeriesList}
        />
        <Button
          gradient={{ from: "teal", to: "lime", deg: 105 }}
          loading={!isProductMasterDataLoaded}
          onClick={() => handleOnAddWidgetModalShow(true)}
        >
          Create widget
        </Button>
      </HeaderStyles>
    </>
  );
};

export default Header;
