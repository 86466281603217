import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Flex, Grid, Image, Space, Text } from "@mantine/core";
import { COMMOM_BACKGROUND_STYLES } from "../../styles/styles";
import {
  IconChevronRight,
  IconListCheck,
  IconShoppingBag,
} from "@tabler/icons-react";

function CardUIForType({ type, data }) {
  if (!type) return <></>;
  const widgetData = data.data || [];
  switch (type) {
    case "BANNER":
      return (
        <Flex
          direction="row"
          justify="space-between"
          style={{ ...COMMOM_BACKGROUND_STYLES(data) }}
        >
          {widgetData.map((item, index) => {
            return (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Image
                  radius={"md"}
                  width="100%"
                  height="200px"
                  fit="stretch"
                  src={item.image_url}
                />
              </div>
            );
          })}
        </Flex>
      );

    case "SPLIT_BANNER":
      return (
        <Flex
          direction="row"
          justify="space-between"
          style={{ ...COMMOM_BACKGROUND_STYLES(data) }}
        >
          {widgetData.map((item, index) => {
            return (
              <div
                style={{
                  aspectRatio: "1 / 1",
                  width: "47%",
                  overflow: "hidden",
                }}
              >
                <Image
                  radius={"md"}
                  width="100%"
                  height="100%"
                  fit="contain"
                  src={item.image_url}
                />
              </div>
            );
          })}
        </Flex>
      );

    case "HORIZONTAL_SHORTCUT":
      return (
        <Flex
          direction="row"
          gap="1rem"
          justify="space-between"
          style={{
            marginBottom: "1.5rem",
            ...COMMOM_BACKGROUND_STYLES(data),
          }}
        >
          {widgetData.map((item, index) => {
            return (
              <Card style={{ width: "50%", height: "4rem" }} variant="outlined">
                <CardContent>
                  <Flex direction="row" gap="sm" align="center">
                    {item.text === "Past Orders" ? (
                      <IconShoppingBag
                        stroke={1.5}
                        size="1.75rem"
                        color="black"
                      />
                    ) : item.text === "Previously Bought Items" ? (
                      <IconListCheck
                        stroke={1.5}
                        size="1.75rem"
                        color="black"
                      />
                    ) : (
                      ""
                    )}
                    <Text fz="xs" fw="bold">
                      {item.text}
                    </Text>
                  </Flex>
                </CardContent>
              </Card>
            );
          })}
        </Flex>
      );

    case "SHORTCUTS":
      return (
        <>
          {widgetData.map((item, index) => {
            return (
              <>
                <Card style={{ height: "4rem" }} raised={true}>
                  <CardContent>
                    <Flex direction="row" gap={"md"} align={"center"}>
                      <div style={{ width: "2rem", height: "2rem" }}>
                        <Image
                          radius={"md"}
                          width="100%"
                          height="100%"
                          fit="contain"
                          src={item.icon_url}
                        />
                      </div>

                      <div style={{ marginTop: "-.3rem", marginRight: "auto" }}>
                        <Text fz="sm" fw="600">
                          {item.text}
                        </Text>
                        <Text fz="xs" color="dimmed">
                          {item.description}
                        </Text>
                      </div>

                      <div style={{ justifySelf: "end" }}>
                        <IconChevronRight
                          size="1.75rem"
                          stroke={2}
                          color="grey"
                        />
                      </div>
                    </Flex>
                  </CardContent>
                </Card>
                <Space h="md" />
              </>
            );
          })}
        </>
      );

    case "PROMOTION_VIDEO":
      let video_url = widgetData[0].video_url;
      console.log("video url", video_url);
      video_url = video_url.replace("youtu.be", "youtube.com/embed");

      return (
        <div>
          <iframe
            style={{ width: "100%", overflow: "hidden", borderRadius: "7px" }}
            width={"100%"}
            height={"217"}
            src={`${video_url}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
            allowfullscreen
          ></iframe>
        </div>
      );

    default:
      return null;
  }
}

export function CardPreviewWidget({ data }) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <CardUIForType type={data.type} data={data} />
    </Box>
  );
}
