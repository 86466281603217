import styled from 'styled-components';

export const InputTagContainer = styled.div`
    margin: 0rem 0.5rem;

    input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        outline: none;
        padding: 0.5rem;
        cursor: ${props => (props.status === "true") ? "not-allowed" : "default"};
    }
`;