import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { Alert, Form, Navbar, InputGroup,Badge,Button,Table } from 'react-bootstrap';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import XLSX from 'xlsx';
import moment from 'moment';
import baseUrl from '../../service/servicesConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function StockReport(props) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(yesterday);
    const [reportData, setReportData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [totalPcsPacked, setTotalPcsPacked] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });


    useEffect(() => {
        handleSubmit();
    }, []); 
    
    // initial data on page load

    const handleSubmit = () => {
      

        let start_date = moment(startDate).format("YYYY-MM-DD");
        let end_date = moment(endDate).format("YYYY-MM-DD");

         baseUrl.get(`/api/superzop/commercial/stockconversion/stockconversionReport?startDate=${start_date}&endDate=${end_date}`)
            .then((response) => {

                if (response.status === 200) {
                    return response.data;
                }    
            }).then(data =>{
                setReportData(data);
                calculateTotals(data);
            })
            .catch((error) => {
                return toast(`Api request for report failed ${error}`, { type: toast.TYPE.ERROR })
                
            });
               
          
        };
                
                
    const calculateTotals = (data) => {
        let totalPcs = 0;
        let totalWt = 0;

        data.forEach(item => {
            totalPcs += item.Pieces;
            totalWt += item.total_weight_packed;
        });

        setTotalPcsPacked(totalPcs);
        setTotalWeight(totalWt);
    };

    const exportDetails =  (data) => {
        const wb = XLSX.utils.book_new();
        const itemsLabelList =[];
            data.forEach((item, index) => {
                if (index === 0) {
                    const keys = Object.keys(item);
                    itemsLabelList.push(keys);
                }
                const values = Object.values(item);
                itemsLabelList.push(values);
            });
       
        const itemsExported = XLSX.utils.aoa_to_sheet(itemsLabelList);
        XLSX.utils.book_append_sheet(wb, itemsExported, `Stock Report`);
        XLSX.writeFile(wb, `Stock_Report.xlsx`);
    }
    const handleExport = () => {
        let start_date = moment(startDate).format("YYYY-MM-DD");
        let end_date = moment(endDate).format("YYYY-MM-DD");

        baseUrl.get(`/api/superzop/commercial/stockconversion/stockconversionExport?startDate=${start_date}&endDate=${end_date}`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
            }).then(data => {
                exportDetails(data);
            })
            .catch((error) => {
               
                return toast(`Api request export failed :${error}`, { type: toast.TYPE.ERROR })
            });
    };
    return (
        <>
        <NavBar />
        <div style={{ paddingTop: 100 }}>
            
            <Alert style={{ display: "flex", textAlign: "justify", alignItems: "center", justifyContent: "center" }} variant={"success"}>
                Stock Conversion Report
            </Alert>
            <Navbar className="bg-light mr-auto justify-content-between" >
                <Form onSubmit={handleSubmit} inline>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <Badge variant="dark"> Select Start Date  </Badge>
                        <DatePicker
                            selected={startDate}
                            onChange={(val) => { setStartDate(val);  }}
                            minDate={new Date("2017/01/01")}
                            // maxDate={new Date()}
                            maxDate={endDate}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"
                        />
                    </InputGroup>
    
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <Badge variant="dark"> Select End Date  </Badge>
                        <DatePicker
                            selected={endDate}
                            onChange={(val) => { setEndDate(val);  }}
                            // minDate={new Date("2017/01/01")}
                            minDate={startDate}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"
                        />
                    </InputGroup>
    
                    <InputGroup size="sm" style={{ margin: 10 }}>
                        <Button type="submit" size="sm" variant="success">Submit</Button>
                    </InputGroup>
                    <InputGroup size="sm" style={{ margin: 10 }}>
                        <Button size="sm" variant="success" onClick={handleExport}>Export</Button>
                    </InputGroup>
                </Form>
            </Navbar>
    
            {reportData.length > 0 ? (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Pcs Packed</th>
                            <th>Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.category}</td>
                                <td>{item.Pieces}</td>
                                <td>{item.total_weight_packed}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{totalPcsPacked}</td>
                            <td>{totalWeight}</td>
                        </tr>
                    </tfoot>
                </Table>
            ) : (
                <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}>No data available for selected dates</div>
            )}
        </div>
    </>
    
    );
}


export default StockReport;
