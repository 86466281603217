import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import database from "../../service/firebaseConfig";
import Backdrop from "../../CommonRequirements/backdrop";

function UpdateImgFile({ downloadedUrl, children, ...props }) {
  const [backDrop, setBackDrop] = useState(false);

  const upLoadFile = (files) => {
    setBackDrop(true);
    const storag = database.storage();
    const storeData = storag.ref();
    const randomFile = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);

    // e.target.files contains File object references

    let uploadedDt = `${new Date().getDate()}-${
      new Date().getMonth() + 1
    }-${new Date().getFullYear()}`;
    let folderName = "superzop_ordering_product_images";
    let imgName = `${randomFile}_goni_img_${uploadedDt}`;

    console.log(files);
    var uploadTask = storeData.child(`${folderName}/${imgName}`).put(files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            console.log("Upload error");
        }
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
          downloadedUrl(downloadURL);
          setBackDrop(false);
        });
      }
    );
  };
  return (
    <div>
      <ImageUploader
        withIcon={false}
        withLabel={false}
        singleImage
        buttonText={children}
        buttonStyles={{ backgroundColor: "white" }}
        onChange={(val) => {
          upLoadFile(val);
          console.log(val);
        }}
        imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
        maxFileSize={5242880}
      />
      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </div>
  );
}

export default UpdateImgFile;
