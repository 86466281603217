import { FORM_DATA_FORMAT } from "./types";

export function getInitialFormData(widget, type) {
  if (widget === "CARD") {
    if (type === "BANNER") return [FORM_DATA_FORMAT.CARD.BANNER];
    if (type === "SPLIT_BANNER")
      return [
        { ...FORM_DATA_FORMAT.CARD.SPLIT_BANNER },
        { ...FORM_DATA_FORMAT.CARD.SPLIT_BANNER },
      ];
    if (type === "SHORTCUTS") return [{ ...FORM_DATA_FORMAT.CARD.SHORTCUTS }];
    if (type === "PROMOTION_VIDEO")
      return [{ ...FORM_DATA_FORMAT.CARD.PROMOTION_VIDEO }];
  }
  return [{ ...FORM_DATA_FORMAT[widget][type] }];
}

export function formatYoutubeURLForPreview(url) {
  if (!url) return;

  if (!url.includes("embed")) {
    const url_split = url.split("v=");
    const videoCode = url_split[1];
    return `https://www.youtube.com/embed/${videoCode}`;
  }

  return url;
}
