import React from 'react'
import {useState,useEffect} from 'react'
import {Table,Alert,Accordion,Card, Button,Modal,Form} from 'react-bootstrap'
import database from '../../../../service/firebaseConfig';


const GetflatModal=(props)=>{

    const [validated, setValidated] = useState(false);
    const [getFlatNos,setFlatNos]=useState(0);
    const [newGetFlat,newSetFlat]=useState([])
    const [getflatNames,setflatNames]=useState('')
    const [getFlatArrays,setFlatArrays]=useState([])
    const [getMoreData,setMoreData]=useState(false);


    var arrofFlat=[];

    const handleSubmit = (event) => {

    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    else{
        event.preventDefault()
        console.log(getFlatArrays)
        props.getFlatdetails(getFlatArrays)
        alert("flat details submitted")
        props.onHide()
        window.location.reload()
    }
    setValidated(true);
    };
    const flatCreation=()=>{
    setFlatNos(getFlatNos+1)
    if(getflatNames!==''){
        setMoreData(true)
    setFlatArrays([...getFlatArrays,getflatNames])}
    for(var i=0;i<=getFlatNos;i++ ){

        arrofFlat.push(i)
    }
    console.log(getFlatArrays)
    newSetFlat(arrofFlat)
}

    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard="false"
    >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
            Add Flats for block {props.Blockname}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Button onClick={flatCreation}>Add flats</Button>
        <Table id="blocks">
        {getFlatNos>0?newGetFlat.map(items=>(
            <tr>
            <td><Form.Control type="text" placeholder="Enter flat name" onChange={(e)=>{setflatNames(e.target.value); 
            console.log(getflatNames)}} /></td>
            </tr>
        )):''}
        </Table>
        </Modal.Body>
        <Modal.Footer>
        <Button  variant="success" type="submit" onClick={()=>{console.log(getFlatArrays); setFlatArrays([...getFlatArrays,getflatNames])}}>Add flats</Button>
        <Button onClick={props.onHide} >Close</Button>
        </Modal.Footer></Form>
    </Modal>
    );
}

function ViewFlatsBlocks() {

    const[getApartmentNames,setApartmentNames]=useState([])
    const[getblockandFlat,setblockandFlat]=useState([])
    const[getFlatDetails,setFlatDetails]=useState([])
    const[modalShow, setModalShow] = useState(false)
    const[getcurrentBlock,setcurrentBlock]=useState("")
    const[getCorrespondingFlats,setCorrespondingFlats]=useState("")
    const[getcurrentApt,setcurrentApt]=useState("")
    const[getFlatsLists,setFlatsLists]=useState([])
    const[getBlockApartmentId,setBlockApartmentId]=useState("")

    const blocksandFlatsDetails=[]
    let FlatDetails=[]
    let blockdetails={}
    let count=0

    const ApartmentDetails=()=>{
        const apartMents=[]
        database.database().ref('Apartments/Mumbai').once("value",snapShot=>{

            
            const apartmentdetails=snapShot.val();
            console.log(apartmentdetails)
            Object.keys(apartmentdetails).forEach(items=>{
                apartMents.push({"apartment_id":apartmentdetails[items].apartment_id,
                "apartment_name":apartmentdetails[items].apartment_name})

            })
            console.log(apartMents)
            
    }).then(()=>{
        setApartmentNames(apartMents)
        console.log(getApartmentNames)
    })}

    const fetchBlocksAndFlats=()=>{

        
        database.database().ref('Blocks/Mumbai').once("value",snapShot=>{

            blockdetails= snapShot.val();
            console.log(blockdetails)
            console.log(getBlockApartmentId)
            Object.keys(blockdetails).forEach(items=>{
                console.log(items)
            blocksandFlatsDetails.push([items,Object.keys(blockdetails[items])])
            FlatDetails.push(Object.values(blockdetails[items]))
            
            })
            
            console.log(blocksandFlatsDetails)
    
    })
    .then(()=>{

    console.log(blocksandFlatsDetails)
        setblockandFlat(blocksandFlatsDetails)
        setFlatDetails(FlatDetails)
    })
    
}

    const pushFlatDetails=()=>{

        let count=0;
        const newFlats={}
        console.log(getcurrentApt)
        console.log(getcurrentBlock)
        if(getFlatsLists[0]!=="no flats entered"){
        getFlatsLists.forEach(items=>{

            count++
        })
    }
    else{
        count=0
    }
        getCorrespondingFlats.map((items,index)=>{
                
            newFlats[count]=items
            count++
        })
        console.log(newFlats)
        database.database().ref(`Blocks/Mumbai/${getcurrentApt}/${getcurrentBlock}`).update(newFlats).then(()=>{
                alert("Data updated")
        })
        
    }

    const ViewBlocksFlats=({Index,Items})=>{
        
        console.log(getblockandFlat[Index])
        console.log(Items.apartment_id)

        return(
            <>
            
            <div> 
                <Accordion defaultActiveKey="0" >
                    {getblockandFlat[Index]!==undefined  ? getblockandFlat[Index][1].map((Blockitems,blockIndex)=>(<>
                        
                <Card>
                <Accordion.Toggle as={Card.Header} eventKey={blockIndex} style={{backgroundColor:"#FA8072",color:"white"}}>
                    {Blockitems}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={blockIndex} >
                    <Card.Body>
                        
                        <Table striped bordered hover style={{backgroundColor:"#FFFAF0"}}>
                                    <thead>
                                        <tr>
                                            <th colSpan='2'>Flats of {Blockitems}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getFlatDetails[Index][blockIndex]!==undefined?getFlatDetails[Index][blockIndex].map((flatItems,flatIndex)=>(
                                        <tr>
                                            <td>{flatIndex}</td>
                                            <td>{flatItems}</td>
                                        </tr> )):"Flats details not available"}
                                        <tr ><td colSpan="2">
                                        <Button variant="info" onClick={()=>{setModalShow(true);
                                            setcurrentApt(Items.apartment_id);setcurrentBlock(Blockitems);
                                            setFlatsLists(getFlatDetails[Items][blockIndex])}}>
                                            Add flats</Button>   
                                        </td></tr>
                                    </tbody>
                            </Table>
                    </Card.Body>
                </Accordion.Collapse>
                </Card>
                </>)):
                (<><Card>
                    <Card.Body>
                    Blocks and flats data yet not available...
                    </Card.Body>
                    </Card></>)}
                </Accordion>
                
            </div></>
        )
    }
    
    useEffect(() => {
        ApartmentDetails()
    }, [])

    useEffect(() => {
        fetchBlocksAndFlats()
    }, [])

    useEffect(() => {
        if(getCorrespondingFlats!=="")
        pushFlatDetails()
    }, [getCorrespondingFlats])
    
    
    return (
    <>
    {getApartmentNames.length>0 && getblockandFlat.length>0&&getFlatDetails.length>0?

        <div style={{textAlign:"center"}}>
            
            <Alert variant="primary">Apartments Blocks and Flats list</Alert>
            <div style={{width:"1500px",display:"inline-block"}}>
            {getApartmentNames.map((items,index)=>(
            <Accordion defaultActiveKey="0" >
            <Card>
            <Accordion.Toggle as={Card.Header} eventKey={index} style={{backgroundColor:"DarkCyan",color:"white"}}>
                {items.apartment_name}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
            <Card.Body>
                {getblockandFlat[index]!==undefined && getblockandFlat.forEach(ValidBlocks=>(
                ValidBlocks[0] === items.apartment_id && console.log(items.apartment_id)
                ))}
                <ViewBlocksFlats Index={index} Items={items}/>
            </Card.Body>
            </Accordion.Collapse>
            </Card>
            </Accordion>
            ))}
            </div>
        </div>:'Data Loading...'}
                    
    <GetflatModal
    show={modalShow}
    onHide={() => setModalShow(false)}
    Blockname={getcurrentBlock}
    getFlatdetails={setCorrespondingFlats}
    />
    </>
        
    )
}

export default ViewFlatsBlocks
