import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import {
  NumberInput,
  Radio,
  Select as MantineSelect,
  TextInput,
  Checkbox,
} from "@mantine/core";
import "../../OfferMaster.scss";

export const RenderFieldsBasedOnSelectedAction = React.memo(
  (props) => {
    let discountType = props.discountType;
    let isMultiple = props.isMultiple;
    let setIsMultiple = props.setIsMultiple;
    let productData = props.productData;
    let discountPercentage = props.discountPercentage;
    let setDiscountPercentage = props.setDiscountPercentage;
    let discountValue = props.discountValue;
    let setDiscountValue = props.setDiscountValue;
    let freeProductQty = props.freeProductQty;
    let setFreeProductQty = props.setFreeProductQty;
    let freeProductMinQty = props.freeProductMinQty;
    let setFreeProductMinQty = props.setFreeProductMinQty;
    let freeProductMaxQty = props.freeProductMaxQty;
    let setFreeProductMaxQty = props.setFreeProductMaxQty;
    let supercoinsAmt = props.supercoinsAmt;
    let setSupercoinsAmt = props.setSupercoinsAmt;
    let userSelectedFreeProduct = props.userSelectedFreeProduct;

    const handleOnFreeProductSelect = props.handleOnFreeProductSelect;
    const isFreeProductFieldsDisabled =
      Object.keys(props.userSelectedFreeProduct).length === 0;

    if (props.actionTypeSelection === "instant") {
      return (
        <>
          <Row
            style={{
              margin: "auto",
              paddingBottom: "40px",
            }}
          >
            <Col>
              <Radio.Group
                value={discountType}
                styles={{ radioWrapper: { alignSelf: "center" } }}
                onChange={props.setDiscountType}
                label="Select discount % or value"
                description=""
                required
              >
                <Radio value="percentDiscount" label="Percentage Off" />
                <Radio value="valueDiscount" label="Rupee Off" />
              </Radio.Group>
            </Col>
          </Row>

          {discountType === "valueDiscount" && (
            <Row style={{ marginBottom: "20px" }}>
              <Col>
                <Checkbox
                  label="Is multiple?"
                  size="sm"
                  checked={isMultiple}
                  color="orange"
                  onChange={(event) => {
                    setIsMultiple(event.currentTarget.checked);
                  }}
                />
              </Col>
            </Row>
          )}

          <Row style={{ margin: "auto", paddingBottom: "40px" }}>
            <Col>
              {discountType === "percentDiscount" ? (
                <Form.Group
                  controlId="instantDiscountPercent"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <NumberInput
                    name="instantDiscountPercent"
                    value={discountPercentage}
                    onChange={(val) => {
                      setDiscountPercentage(val);
                    }}
                    required
                    precision={2}
                    label="Discount Percentage"
                    description="Discount to apply in terms of percentage"
                    hideControls
                  />
                </Form.Group>
              ) : (
                <Form.Group
                  controlId="instantDiscountValue"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <NumberInput
                    value={discountValue}
                    onChange={(val) => {
                      setDiscountValue(val);
                    }}
                    name="instantDiscountValue"
                    label="Discount Value"
                    description="Discount to apply in terms of rupees"
                    hideControls
                    required
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
        </>
      );
    } else if (props.actionTypeSelection === "cashback") {
      return (
        <>
          <Row
            style={{
              margin: "auto",
              paddingBottom: "40px",
            }}
          >
            <Col>
              <Radio.Group
                value={discountType}
                onChange={props.setDiscountType}
                label="Select discount % or value"
                description=""
                required
              >
                <Radio value="percentDiscount" label="Percentage Off" />
                <Radio value="valueDiscount" label="Rupee Off" />
              </Radio.Group>
            </Col>
          </Row>

          {discountType === "valueDiscount" && (
            <Row style={{ marginBottom: "20px" }}>
              <Col>
                <Checkbox
                  label="Is multiple?"
                  size="sm"
                  checked={isMultiple}
                  color="orange"
                  onChange={(event) => {
                    setIsMultiple(event.currentTarget.checked);
                  }}
                />
              </Col>
            </Row>
          )}

          <Row style={{ margin: "auto", paddingBottom: "40px" }}>
            <Col>
              {discountType === "percentDiscount" ? (
                <Form.Group
                  controlId="cashbackDiscountPercent"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <NumberInput
                    value={discountPercentage}
                    onChange={(val) => {
                      setDiscountPercentage(val);
                    }}
                    name="cashbackDiscountPercent"
                    required
                    label="Discount Percentage"
                    description="Discount to apply in terms of percentage"
                    hideControls
                  />
                </Form.Group>
              ) : (
                <Form.Group
                  controlId="cashbackDiscountValue"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <NumberInput
                    value={discountValue}
                    onChange={(val) => {
                      setDiscountValue(val);
                    }}
                    name="cashbackDiscountValue"
                    required
                    label="Discount Value"
                    description="Discount to apply in terms of rupees"
                    hideControls
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
        </>
      );
    } else if (props.actionTypeSelection === "supercoins") {
      return (
        <>
          <Row style={{ margin: "auto", paddingBottom: "40px" }}>
            <Col>
              <Form.Group
                controlId="supercoinsAmt"
                style={{
                  display: "block",
                  width: "500px",
                  textAlign: "center",
                  margin: "auto",
                  paddingBotttom: "40px",
                }}
              >
                <NumberInput
                  value={supercoinsAmt}
                  onChange={(val) => {
                    setSupercoinsAmt(val);
                  }}
                  name="supercoinsAmt"
                  required
                  hideControls
                  label="No of supercoins"
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      );
    } else if (props.actionTypeSelection === "freeProduct") {
      return (
        <>
          <Row>
            <Col>
              <Form.Group
                controlId="freeProductSelect"
                style={{
                  display: "block",
                  width: "500px",
                  textAlign: "center",
                  margin: "auto",
                  paddingBottom: "40px",
                  fontSize: "15px",
                }}
              >
                <Form.Label>Select Product</Form.Label>
                <MantineSelect
                  searchable
                  clearable
                  value={userSelectedFreeProduct}
                  onChange={handleOnFreeProductSelect}
                  name="freeProductSelect"
                  data={productData}
                />
              </Form.Group>
            </Col>
          </Row>

          <fieldset disabled={isFreeProductFieldsDisabled}>
            <Row>
              <Col>
                <Form.Group
                  controlId="freeProductQuantity"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                  }}
                >
                  <NumberInput
                    name="freeProductQuantity"
                    required
                    value={freeProductQty}
                    onChange={(val) => {
                      setFreeProductQty(val);
                    }}
                    label="Free product quantity"
                    hideControls
                    min={1}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  controlId="freeProductMinQuantity"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                  }}
                >
                  <NumberInput
                    name="freeProductMinQuantity"
                    required
                    value={freeProductMinQty}
                    onChange={(val) => {
                      setFreeProductMinQty(val);
                    }}
                    label="Free product min quantity"
                    hideControls
                    min={1}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  controlId="freeProductMaxQuantity"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                  }}
                >
                  <NumberInput
                    name="freeProductMaxQuantity"
                    required
                    value={freeProductMaxQty}
                    onChange={(val) => {
                      setFreeProductMaxQty(val);
                    }}
                    label="Free product max quantity"
                    hideControls
                    min={1}
                  />
                </Form.Group>
              </Col>
            </Row>
          </fieldset>
        </>
      );
    }
    return null;
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);
