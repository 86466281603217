import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Table,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import database from "../../service/firebaseConfig";
import { PROD_MASTER_TABLE } from "../../constants/allConstants";
import { validateIsAdminUser } from "../../service/credentials";
import {
  REGION_ALL,
  REGION_BOM_ALL,
  REGION_BOM_A,
  REGION_BOM_B,
  REGION_BOM_H,
  REGION_BOM_N,
  REGION_PUNE_P,
  LOGGED_USER_EMAIL,
  ALL_PRODUCT_EDIT_LIST,
} from "../../constants/allConstants";
import { toast } from "react-toastify";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import CurrentLoginUser from "../../CommonRequirements/FetchUserEmailId";

function EditPriceItems(props) {
  let statusIndCounter = 0;
  let showPricingElements = false;
  // let conversion_per = props.cost_of_conversion_per;
  // let wastage_per = props.cost_of_wastage_per;
  // let pm_cost = props.pm_cost;
  // let base_price = props.base_price;
  console.log(props.editableData);
  const {
    dealer_price,
    apmc_charge,
    gst_charge,
    transport_charge,
    market_price,
    moq,
    weight,
    case_size,
    brand,
    brand_hindi,
    brand_marathi,
    brand_gujarati,
    hindi_description,
    marathi_description,
    gujarati_description,
    long_description,
    long_description_hindi,
    long_description_marathi,
    long_description_gujarati,
    item_description1,
    category,
    major_category,
    sub_category,
    variety,
    active_status,
    buying_flag,
    uom,
    outer_size,
    image,
    image_url,
    parent_id,
    parent_id1,
    hsn_code,
    top_selling,
    super_coins,
    max_order_quantity,
    ean,
    pcs_per_bag,
    product_type,
    packing_material_size
  } = props.editableData;

  const [productEdit, setProductEdit] = useState({});
  const [selectedVariety, setSelectedVariety] = useState(variety);
  const [selectedProductType, setSelectedProductType] = useState(product_type);
  const [selectedPackingMaterialSize, setSelectedPackingMaterialSize] = useState(packing_material_size);
  const [activeStatus, setActiveStatus] = useState(active_status);
  const [priceDetails, setPriceDetails] = useState({})
  console.log(UpdatedDate());
  console.log(CurrentLoginUser());
  const calculatePriceDetails = async (itemId) => {
      const priceDet = await database
        .database()
        .ref(`Price_Details/mumbai/items/${itemId}`)
        .once("value");
      let calVal = priceDet.val();  
      setPriceDetails(calVal);
  }
  useEffect(()=>{
    calculatePriceDetails(props.keyNumber);
  }, [])
  if (
    props.currentRegion != REGION_ALL &&
    props.currentRegion != REGION_BOM_ALL
  ) {
    showPricingElements = true;
  }
  // const pushUpdatedProducts = () => {
  //   console.log(
  //     "CurrentRegion::: " +
  //       props.currentRegion +
  //       " Item:::: " +
  //       props.keyNumber +
  //       "Product values:::: " +
  //       productEdit
  //   );
  //   const regionA = "A";
  //   const regionB = "B";
  //   const regionN = "N";
  //   const regionPG = "PG";
  //   const regionNK = "NK";
  //   const regionST = "ST";
  //   const regionH = "H";
  //   const regionP = "P";
  //   const regionK = "K";

  //   statusIndCounter = 0;
  //   if (props.currentRegion === REGION_ALL) {
  //     ALL_PRODUCT_EDIT_LIST.forEach((item, index) => {
  //       pushForDiffRegions(item, 9);
  //     });
  //   } else if (props.currentRegion === REGION_BOM_ALL) {
  //     pushForDiffRegions(regionA, 8);
  //     pushForDiffRegions(regionB, 8);
  //     pushForDiffRegions(regionN, 8);
  //     pushForDiffRegions(regionPG, 9);
  //     pushForDiffRegions(regionNK, 9);
  //     pushForDiffRegions(regionST, 9);
  //     pushForDiffRegions(regionH, 8);
  //     pushForDiffRegions(regionK, 8);
  //   } else {
  //     database
  //       .database()
  //       .ref(`${PROD_MASTER_TABLE}/${props.currentRegion}/${props.keyNumber}`)
  //       .update({
  //         ...productEdit,
  //         variety: selectedVariety,
  //         active_status: activeStatus,
  //         updated_by: CurrentLoginUser(),
  //         last_updated: UpdatedDate(),
  //       })
  //       .then(() => {
  //         notifyUser(true);
  //         props.onHide();
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1000);
  //       });
  //   }
  // };

  // const pushUpdatedProducts = async () => {
  //   console.log(
  //     "CurrentRegion::: " +
  //       props.currentRegion +
  //       " Item:::: " +
  //       props.keyNumber +
  //       "Product values:::: " +
  //       productEdit
  //   );
  //   const regionA = "A";
  //   const regionB = "B";
  //   const regionN = "N";
  //   const regionPG = "PG";
  //   const regionNK = "NK";
  //   const regionST = "ST";
  //   const regionH = "H";
  //   const regionP = "P";
  //   const regionK = "K";
  
  //   statusIndCounter = 0;
  //   let updatePendingPriceCalled = false;
  
  //   const calculateGrossCost = async (parentId, childData) => {
  //     const priceDetails = await database
  //       .database()
  //       .ref(`Price_Details/mumbai/items/${parentId}`)
  //       .once("value");
  //     let calVal = priceDetails.val();
  //     let gross_cost = calVal?.gross_cost ? calVal?.gross_cost : 0;
  
  //     const weight = childData.weight || props.editableData.weight;
  
  //     if (weight.includes("gm")) {
  //       let weightVal = weight.split(" ")[0];
  //       gross_cost = gross_cost * (Number(weightVal) / 1000);
  //     }
  //     if (weight.includes("kg")) {
  //       let weightVal = weight.split(" ")[0];
  //       gross_cost = gross_cost * weightVal;
  //     }
  
  //     return parseFloat(gross_cost).toFixed(2);
  //   };
  
  //   const updatePendingPriceDetails = async (productEdit) => {
  //     // let prodpendingpriceele = { ...productEdit };
  //     let prodpendingpriceele = {};
  //     let prodele = {};

  //     prodele["item_id"] = props.keyNumber;
  //     prodele["parent_id"] = productEdit.parent_id;

  //     let randmtwodigitnum = Math.floor(
  //       Math.random() * (999 - 100 + 1) + 100
  //     );
  //     let childprodkey = productEdit.parent_id + "-" + randmtwodigitnum;

  //     let d = new Date();
  //     let currdformat =[d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear(),].join("-") + 
  //       " " + [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":");
      
  //     // console.log(childprodkey);
  //     // childprodele["rowselectkey"] = childprodkey;
  
  //     if (productEdit.parent_id) {
  //       const childGrossCost = await calculateGrossCost(
  //         productEdit.parent_id,
  //         productEdit
  //       );
  //       prodele["gross_cost"] = childGrossCost;
  //     }

  //     prodele["updated_from"] = "Edit Product Details";
  //     prodele["approved"] = "N";
  //     prodele["updated_by"] = sessionStorage.getItem("Logged User Email Id");
  //     prodele["updated_at"] = currdformat;
  
  //     const currentYear = new Date().getFullYear();
  //     const currentMonth = new Date().getMonth() + 1;
  //     const currdate = new Date().getDate();
      
  //     prodpendingpriceele[childprodkey] = prodele;

  //     database
  //       .database()
  //       .ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}`)
  //       .update(prodpendingpriceele)
  //       .then(() => {
  //         toast("Successfully added product price for approval.", {
  //           type: toast.TYPE.SUCCESS,
  //         });
  //         props.onHide();
  //         // if (Object.keys(original_prod_ele).length > 0) {
  //         //   props.callback(original_prod_ele);
  //         // }
  //       })
  //       .catch((error) => {
  //         toast("Error in saving price details : " + error, {
  //           type: toast.TYPE.ERROR,
  //         });
  //         props.onHide();
  //       });
  //   };
  
  //   const pushForDiffRegions = async (region, counter) => {
  //     const item = props.keyNumber;
  //     const casesize = (region == 'S' || region == 'D') ? '1' : productEdit.case_size ? productEdit.case_size : case_size;
  //     const productSnapshot = await database
  //       .database()
  //       .ref(`${PROD_MASTER_TABLE}`)
  //       .child(region)
  //       .child(item)
  //       .once("value");
  
  //     const product = productSnapshot.val();
  //     if (product) {
  //       await database
  //         .database()
  //         .ref(`${PROD_MASTER_TABLE}/${region}/${props.keyNumber}`)
  //         .update({
  //           ...productEdit,
  //           case_size: casesize,
  //           variety: selectedVariety,
  //           active_status: activeStatus,
  //           updated_at: UpdatedDate(),
  //           updated_by: CurrentLoginUser(),
  //         })
  //         .then(async () => {
  //           statusIndCounter++;
  //           console.log("Updated for the region:: " + region);
  //           notifyUserWithMessage(
  //             true,
  //             "Updated for the region:: " + region
  //           );
  //           if (counter === statusIndCounter) {
  //             if (!updatePendingPriceCalled) {
  //               await updatePendingPriceDetails(productEdit);
  //               updatePendingPriceCalled = true;
  //             }
  //             notifyUser(true);
  //             props.onHide();
  //             setTimeout(() => {
  //               window.location.reload();
  //             }, 2000);
  //           }
  //         })
  //         .catch((error) => {
  //           statusIndCounter++;
  //           console.log("Error for the region:: " + region);
  //           notifyUser(false);
  //           if (counter === statusIndCounter) {
  //             // if (!updatePendingPriceCalled) {
  //             //   await updatePendingPriceDetails(productEdit);
  //             //   updatePendingPriceCalled = true;
  //             // }
  //             window.location.reload();
  //           }
  //         });
  //     } else {
  //       statusIndCounter++;
  //       console.log("Product not available:: " + region);
  //       notifyUserWithMessage(false, "Product not available:: " + region);
  //       if (counter === statusIndCounter) {
  //         if (!updatePendingPriceCalled) {
  //           await updatePendingPriceDetails(productEdit);
  //           updatePendingPriceCalled = true;
  //         }
  //         window.location.reload();
  //       }
  //     }
  //   };
  
  //   if (props.currentRegion === REGION_ALL) {
  //     ALL_PRODUCT_EDIT_LIST.forEach((item, index) => {
  //       pushForDiffRegions(item, 9);
  //     });
  //   } else if (props.currentRegion === REGION_BOM_ALL) {
  //     pushForDiffRegions(regionA, 8);
  //     pushForDiffRegions(regionB, 8);
  //     pushForDiffRegions(regionN, 8);
  //     pushForDiffRegions(regionPG, 9);
  //     pushForDiffRegions(regionNK, 9);
  //     pushForDiffRegions(regionST, 9);
  //     pushForDiffRegions(regionH, 8);
  //     pushForDiffRegions(regionK, 8);
  //   } else {
  //     await database
  //       .database()
  //       .ref(`${PROD_MASTER_TABLE}/${props.currentRegion}/${props.keyNumber}`)
  //       .update({
  //         ...productEdit,
  //         variety: selectedVariety,
  //         active_status: activeStatus,
  //         updated_by: CurrentLoginUser(),
  //         last_updated: UpdatedDate(),
  //       })
  //       .then(async () => {
  //         await updatePendingPriceDetails(productEdit);
  //         notifyUser(true);
  //         props.onHide();
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1000);
  //       });
  //   }
  // };  

  const productTypes = [
    "Khetika Consumer Pack",
    "Other Consumer Pack",
    "Khetika Small Pack",
    "Branded Goni",
    "Unbranded Goni"
  ];

  const packingMaterialSizes = [
    "100 gm",
    "200 gm",
    "250 gm",
    "500 gm",
    "1 kg",
    "5 kg"
  ];

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [day, month, year].join("-");
  }

  const isValid = (data) => {
    if (
      data === undefined ||
      data === null ||
      data === "" ||
      data.length === 0
    ) {
      return 0;
    }
    return data;
  };


  const parseToNo = (string) => {
    return parseFloat(isValid(string));
  };

  const getAbsFromPer = (data) => {
    return parseFloat(parseToNo(data) / 100);
  };

  const getRoundNo = (data) => {
    return parseToNo(data).toFixed(2);
  };

  const pushUpdatedProducts = async () => {
    console.log(
      "CurrentRegion::: " +
        props.currentRegion +
        " Item:::: " +
        props.keyNumber +
        "Product values:::: " +
        productEdit
    );
  
    const regionA = "A";
    const regionB = "B";
    const regionN = "N";
    const regionPG = "PG";
    const regionNK = "NK";
    const regionST = "ST";
    const regionH = "H";
    const regionP = "P";
    const regionK = "K";
  
    statusIndCounter = 0;
    let updatePendingPriceCalled = false;
  
    const calculateGrossCost = async (parentId, childData) => {
      const priceDetails = await database
        .database()
        .ref(`Price_Details/mumbai/items/${parentId}`)
        .once("value");
      let calVal = priceDetails.val();
      let gross_cost = calVal?.gross_cost ? calVal?.gross_cost : 0;
  
      const weight = childData.weight || props.editableData.weight;
      const mrp = childData.market_price || props.editableData.market_price;
  
      if (weight.includes("gm")) {
        let weightVal = weight.split(" ")[0];
        gross_cost = gross_cost * (Number(weightVal) / 1000);
      }
      if (mrp && mrp != 0 && weight.includes("kg")) {
        let weightVal = weight.split(" ")[0];
        gross_cost = gross_cost * weightVal;
      }
  
      return parseFloat(gross_cost).toFixed(2);
    };
  
    const updatePendingPriceDetails = async (productEdit) => {
      let prodpendingpriceele = {};
      let prodele = {};
      prodele = { ...priceDetails };

      prodele["item_id"] = props.keyNumber;
      prodele["parent_id"] = productEdit.parent_id || props.editableData.parent_id;
      prodele["parent_item_name"] = ''
  
      let randmtwodigitnum = Math.floor(
        Math.random() * (999 - 100 + 1) + 100
      );
      let childprodkey = props.keyNumber + "-" + randmtwodigitnum;

      let d = new Date();
      let currdformat = [
          d.getDate().padLeft(),
          (d.getMonth() + 1).padLeft(),
          d.getFullYear(),
      ].join("-") + 
        " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");
      
      if (productEdit.parent_id) {

        const priceDetails = await database
                        .database()
                        .ref(`Price_Details/mumbai/items/${productEdit.parent_id}`)
                        .once("value");
        let calVal = priceDetails.val();

        const childGrossCost = await calculateGrossCost(
          productEdit.parent_id,
          productEdit
        );
        prodele["gross_cost"] = childGrossCost;
        // const wastage = getAbsFromPer(priceDetails.wastage_per) * parseToNo(childGrossCost);
        const wastage = getAbsFromPer(priceDetails.wastage_per) * parseToNo(childGrossCost);
        // let conversion_charges = getAbsFromPer(priceDetails.conversion_per) * parseToNo(priceDetails.base_price);
        let conversion_charges = getAbsFromPer(priceDetails.conversion_per) * parseToNo(calVal.base_price);
        conversion_charges = getRoundNo(conversion_charges);
        let secondaryPMCost = priceDetails?.package_material_cost ? priceDetails?.package_material_cost : priceDetails?.material_cost_factor ? parseToNo(priceDetails.material_cost_factor) / parseToNo(priceDetails.lot_size) : 0 ;
        let landingCost = (Number(childGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + secondaryPMCost; //(Number(calVal.package_material_cost ?? 0));
        landingCost = parseFloat(landingCost.toFixed(2));
        prodele["landing_cost"] = landingCost;
        prodele["base_price"] = calVal.base_price;
        prodele['source_type'] = calVal.source_type;

        if(calVal.source_type.toLowerCase() == 'mill'){
          prodele['mill_transport_value'] = calVal.mill_transport_value;
          prodele['cd_per'] = calVal.cd_per;
        }
      }
  
      prodele["updated_from"] = "Edit Product Details";
      prodele["approved"] = "N";
      prodele["updated_by"] = sessionStorage.getItem("Logged User Email Id");
      prodele["updated_at"] = currdformat;
  
      let currdate = formatDate(new Date());
      let currentYear = currdate.split("-")[2];
      let currentMonth = currdate.split("-")[1];
  
      prodpendingpriceele[childprodkey] = prodele;
      
      if (productEdit.parent_id) {
        await database
          .database()
          .ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}`)
          .update(prodpendingpriceele)
          .then(() => {
            toast("Successfully added product price for approval.", {
              type: toast.TYPE.SUCCESS,
            });
          })
          .catch((error) => {
            toast("Error in saving price details : " + error, {
              type: toast.TYPE.ERROR,
            });
          });

      }
    };
  
    const pushForDiffRegions = async (region, counter) => {
      const item = props.keyNumber;
      const casesize = (region == 'S' || region == 'D') ? '1' : productEdit.case_size ? productEdit.case_size : case_size;
      const productSnapshot = await database
        .database()
        .ref(`${PROD_MASTER_TABLE}`)
        .child(region)
        .child(item)
        .once("value");
  
      const product = productSnapshot.val();
      if (product) {
        const updateData = {
          ...productEdit,
          case_size: casesize,
          variety: selectedVariety,
          active_status: activeStatus,
          updated_at: UpdatedDate(),
          updated_by: CurrentLoginUser(),
        };
        
        if (selectedProductType) {
          updateData.product_type = selectedProductType;
        }
        
        if (selectedPackingMaterialSize) {
          updateData.packing_material_size = selectedPackingMaterialSize;
        }

        await database
          .database()
          .ref(`${PROD_MASTER_TABLE}/${region}/${props.keyNumber}`)
          .update(updateData)
          .then(async () => {
            statusIndCounter++;
            console.log("Updated for the region:: " + region);
            notifyUserWithMessage(
              true,
              "Updated for the region:: " + region
            );
            if (counter === statusIndCounter && !updatePendingPriceCalled) {
              await updatePendingPriceDetails(productEdit);
              updatePendingPriceCalled = true;
              notifyUser(true);
              props.onHide();
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          })
          .catch(async(error) => {
            statusIndCounter++;
            console.log("Error for the region:: " + region);
            notifyUser(false);
            if (counter === statusIndCounter && !updatePendingPriceCalled) {
              await updatePendingPriceDetails(productEdit);
              updatePendingPriceCalled = true;
              window.location.reload();
            }
          });
      } else {
        statusIndCounter++;
        console.log("Product not available:: " + region);
        notifyUserWithMessage(false, "Product not available:: " + region);
        if (counter === statusIndCounter && !updatePendingPriceCalled) {
          await updatePendingPriceDetails(productEdit);
          updatePendingPriceCalled = true;
          window.location.reload();
        }
      }
    };
  
    if (props.currentRegion === REGION_ALL) {
      const promises = ALL_PRODUCT_EDIT_LIST.map((item, index) => pushForDiffRegions(item, 9));
      await Promise.all(promises);
    } else if (props.currentRegion === REGION_BOM_ALL) {
      const promises = [
        pushForDiffRegions(regionA, 8),
        pushForDiffRegions(regionB, 8),
        pushForDiffRegions(regionN, 8),
        pushForDiffRegions(regionPG, 9),
        pushForDiffRegions(regionNK, 9),
        pushForDiffRegions(regionST, 9),
        pushForDiffRegions(regionH, 8),
        pushForDiffRegions(regionK, 8),
      ];
      await Promise.all(promises);
    } else {
      await database
        .database()
        .ref(`${PROD_MASTER_TABLE}/${props.currentRegion}/${props.keyNumber}`)
        .update({
          ...productEdit,
          variety: selectedVariety,
          active_status: activeStatus,
          updated_by: CurrentLoginUser(),
          last_updated: UpdatedDate(),
        })
        .then(async () => {
          await updatePendingPriceDetails(productEdit);
          notifyUser(true);
          props.onHide();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };


  const notifyUser = (success) => {
    if (success) {
      return toast("Changed the status", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't change the status", { type: toast.TYPE.ERROR });
  };

  const notifyUserWithMessage = (success, message) => {
    if (!message) {
      return console.log("please pass message arguement");
    }
    if (success) {
      return toast(message, {
        type: toast.TYPE.SUCCESS,
        style: { width: "100px", color: "red" },
      });
    }
    return toast(message, { type: toast.TYPE.ERROR });
  };

  // const pushForDiffRegions = (region, counter) => {
  //   const item = props.keyNumber;
  //   const casesize = (region == 'S' || region == 'D') ? '1':productEdit.case_size?productEdit.case_size:case_size;
  //   database
  //     .database()
  //     .ref(`${PROD_MASTER_TABLE}`)
  //     .child(region)
  //     .child(item)
  //     .once("value", (results) => {
  //       const product = results.val();
  //       if (product) {
  //         database
  //           .database()
  //           .ref(`${PROD_MASTER_TABLE}/${region}/${props.keyNumber}`)
  //           .update({
  //             ...productEdit,
  //             case_size:casesize,
  //             variety: selectedVariety,
  //             active_status: activeStatus,
  //             updated_at: UpdatedDate(),
  //             updated_by: CurrentLoginUser(),
  //           })
  //           .then(
  //             () => {
  //               statusIndCounter++;
  //               console.log("Updated for the region:: " + region);
  //               notifyUserWithMessage(
  //                 true,
  //                 "Updated for the region:: " + region
  //               );
  //               // console.log("Status counter:: " + statusIndCounter);
  //               if (counter == statusIndCounter) {
  //                 notifyUser(true);
  //                 props.onHide();
  //                 setTimeout(() => {
  //                   window.location.reload();
  //                 }, 2000);
  //               }
  //             },
  //             function (error) {
  //               statusIndCounter++;
  //               if (error) {
  //                 console.log("Error for the region:: " + region);
  //                 notifyUser(false);
  //               }
  //               // else if (counter == statusIndCounter) {
  //               //   console.log("Updated for the region:: " + region);
  //               //   notifyUser(true);
  //               // }
  //               else {
  //                 console.log("Updated for the region:: " + region);
  //                 notifyUserWithMessage(
  //                   true,
  //                   "Updated for the region:: " + region
  //                 );
  //               }
  //               if (counter == statusIndCounter) {
  //                 // notifyUser(true);
  //                 window.location.reload();
  //               }
  //             }
  //           );
  //       } else {
  //         statusIndCounter++;
  //         console.log("Product not available:: " + region);
  //         notifyUserWithMessage(false, "Product not available:: " + region);
  //         if (counter == statusIndCounter) {
  //           // notifyUser(true);
  //           window.location.reload();
  //         }
  //       }
  //       // props.onHide();
  //     });
  // };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Product Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && (
              <>
                {market_price ? (
                  <tr>
                    <td>Market Price</td>
                    <td>
                      {" "}
                      <Form.Control
                        type="text"
                        defaultValue={market_price}
                        onChange={(e) => {
                          setProductEdit({
                            ...productEdit,
                            market_price: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                ) : null}
                {top_selling ? (
                  <tr>
                    <td>Top Selling</td>
                    <td>
                      <Form.Control
                        type="text"
                        defaultValue={top_selling}
                        onChange={(e) => {
                          setProductEdit({
                            ...productEdit,
                            top_selling: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>MoQ</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={moq}
                      onChange={(e) => {
                        setProductEdit({ ...productEdit, moq: e.target.value });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td> EAN Number</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={ean}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          ean: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Weight</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={weight}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          weight: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Case Size</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={case_size}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          case_size: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Hsn Code</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={hsn_code}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          hsn_code: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Brand</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={brand}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          brand: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Brand in Hindi</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={brand_hindi}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          brand_hindi: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Brand in Marathi</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={brand_marathi}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          brand_marathi: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Brand in Gujarati</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={brand_gujarati}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          brand_gujarati: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Item Description</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={item_description1}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          item_description1: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Description in Hindi</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={hindi_description}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          hindi_description: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Description in Marathi</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={marathi_description}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          marathi_description: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Description in Gujarati</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={gujarati_description}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          gujarati_description: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Long Description</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={long_description}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          long_description: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Long Description in Hindi</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={long_description_hindi}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          long_description_hindi: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Long Description in Marathi</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={long_description_marathi}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          long_description_marathi: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Long Description in Gujarati</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={long_description_gujarati}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          long_description_gujarati: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={category}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          category: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Major Category</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={major_category}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          major_category: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sub category</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={sub_category}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          sub_category: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Image Name</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={image}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          image: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Image URL</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={image_url}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          image_url: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>UoM</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={uom}
                      onChange={(e) => {
                        setProductEdit({ ...productEdit, uom: e.target.value });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Outer Size</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={outer_size}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          outer_size: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Parent ID</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={parent_id}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          parent_id: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Parent ID 1</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={parent_id1}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          parent_id1: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pcs Per Bag</td>
                  <td>
                    <Form.Control
                      type="Number"
                      defaultValue={pcs_per_bag}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          pcs_per_bag: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>

                <tr>
                <td>Product Type</td>
                <td>
                  <DropdownButton
                    id="dropdown-product-type"
                    title={selectedProductType ? selectedProductType : "Select Product Type"}
                  >
                    {productTypes.map((type) => (
                      <Dropdown.Item
                        key={type}
                        onClick={() => setSelectedProductType(type)}
                      >
                        {type}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </td>
              </tr>
              <tr>
                <td>Packing Material Size</td>
                <td>
                  <DropdownButton
                    id="dropdown-packing-material-size"
                    title={selectedPackingMaterialSize ? selectedPackingMaterialSize : "Select Packing Material Size"}
                  >
                    {packingMaterialSizes.map((size) => (
                      <Dropdown.Item
                        key={size}
                        onClick={() => setSelectedPackingMaterialSize(size)}
                      >
                        {size}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </td>
              </tr>
                <tr>
                  <td>Buying Flag</td>
                  <td>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        productEdit.buying_flag
                          ? productEdit.buying_flag
                          : buying_flag
                      }
                    >
                      <Dropdown.Item
                        onClick={(e) => {
                          setProductEdit({
                            ...productEdit,
                            buying_flag: e.target.name,
                          });
                        }}
                        name="Y"
                      >
                        Y
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setProductEdit({
                            ...productEdit,
                            buying_flag: e.target.name,
                          });
                        }}
                        name="N"
                      >
                        N
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
                <tr>
                  <td>Active Status</td>
                  <td>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={activeStatus ? activeStatus : active_status}
                    >
                      <Dropdown.Item
                        onClick={(e) => {
                          setActiveStatus(e.target.name);
                        }}
                        name="Y"
                      >
                        Y
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setActiveStatus(e.target.name);
                        }}
                        name="N"
                      >
                        N
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>Variety</td>
              <td>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={selectedVariety ? selectedVariety : variety}
                >
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="GONI"
                  >
                    GONI
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="SMALL PACK"
                  >
                    SMALL PACK
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="BOX"
                  >
                    BOX
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="BAG OF PACKS"
                  >
                    BAG OF PACKS
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="HIGH VALUE LOOSE"
                  >
                    HIGH VALUE LOOSE
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="FMCG"
                  >
                    FMCG
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="CRATE LINES"
                  >
                    CRATE LINES
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      setSelectedVariety(e.target.name);
                    }}
                    name="GONI SUGAR"
                  >
                    GONI SUGAR
                  </Dropdown.Item>
                </DropdownButton>
              </td>
            </tr>
            {category == 'SUPER COINS' ? (
              <tr>
                <td>Super Coins</td>
                <td>
                  <Form.Control
                    type="number"
                    defaultValue={super_coins}
                    onChange={(e) => {
                      setProductEdit({
                        ...productEdit,
                        super_coins: Number(e.target.value),
                      });
                    }}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td>Max Order Qty</td>
              <td>
                <Form.Control
                  type="number"
                  defaultValue={max_order_quantity}
                  onChange={(e) => {
                    setProductEdit({
                      ...productEdit,
                      max_order_quantity: ""+Number(e.target.value),
                    });
                  }}
                />
              </td>
            </tr>
             {!validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && (
                <>
                  <tr>
                  <td>Parent ID</td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={parent_id}
                      onChange={(e) => {
                        setProductEdit({
                          ...productEdit,
                          parent_id: e.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
              </>
             )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={pushUpdatedProducts}>
            Update
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditPriceItems;
