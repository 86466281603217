import React, { useEffect, useState, useRef } from 'react'
import { Breadcrumb, Dropdown, DropdownButton, Alert, Tab, Row, Col, Nav, Table, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import database from '../../service/firebaseConfig';
import { FaWindows } from 'react-icons/fa';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import Creatable from 'react-select/creatable'

import UserEmail from '../../CommonRequirements/FetchUserEmailId'
import UpdatedDate from '../../CommonRequirements/UpdatedDate'
import UpdatedTime from '../../CommonRequirements/UpdatedTime'
import request from 'request';
import baseUrl from '../../service/servicesConfig';

const PurchaseDetailsData = database.database().ref(`Purchase_Details`)
const Product_Base = database.database().ref(`Product_Base_Data`)
const SuppliersDB = database.database().ref(`Suppliers`)
const priceDetailsDB = database.database().ref(`Price_Details/mumbai/items`);
const productsMasterDB = database.database().ref(`Product_Master_New/A`);
function formatPurchaseDate(date) {
    return `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-${((date.getMonth()) + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getFullYear()}`
}


const EditPurchaseModal = ({ data, itemCode, selectedDate, ...props }) => {
    console.log('EditPurchaseModal');
    // console.log(selectedDate);
    // console.log(itemCode);
    console.log(data);
    const [suppliersList, setSuppliersList] = useState()
    const [category, setCategory] = useState(data.category)
    const [articleCode, setArticleCode] = useState(data.article_code)
    const [itemName, setItemName] = useState(data.item_name)
    const [outerType, setOuterType] = useState(data.outer_type)
    const [outerSize, setOuterSize] = useState(data.outer_size)
    const [bagPurchased, setBagPurchased] = useState(data.bag_purchased)
    const [totalWeight, setTotalWeight] = useState(data.total_weight)
    const [articleInfo, setArticleInfo] = useState("")
    const [articleOptions, setArticleOptions] = useState("")
    const [validated, setValidated] = useState(false)
    const [supplier, setSupplier] = useState(data.supplier_name)
     const [SupplierID, setSupplierID] = useState(data.supplier_id);
  const [SupplierEmail, setSupplierEmail] = useState(data.supplier_email);
    const [editPurchaseDate, setEditPurchaseDate] = useState(data.purchased_date ? new Date(data.purchased_date.split("-")[0], data.purchased_date.split("-")[1] - 1, data.purchased_date.split("-")[2]) : new Date());
    const [invoiceNumber, setInvoiceNumber] = useState(data.invoice_number)
    const categoryField = useRef();
    const itemNameField = useRef();
    const itemNoField = useRef();
    const totalWtPurchasedRef = useRef();
    const supplierRef = useRef();
    const outersizeRef = useRef();
    const [disableList, setDisableList] = useState(false)
    const [uomValue, setUomValue] = useState("")
    const [purchasePrice, setPurchasePrice] = useState(data.purchase_price)
    const [replacementBrand, setReplacementBrand] = useState("")
const [Payment_Terms, setPayment_Terms] = useState(data.payment_terms)
const [Delivery, setDelivery] = useState(data.delivery_in_days)
    const unique_key = itemCode;
const [GstCharges, setGstCharges] = useState(data.gst_charges_per);
const [Fssai, setFssai] = useState(data.bill_fssai);
const [Gstin, setGstin] = useState(data.bill_gstin);
const [phone, setPhone] = useState(data.bill_phone);
const [Address, setAddress] = useState(data.bill_address);
const [Street, setStreet] = useState(data.bill_street);
const [City, setCity] = useState(data.bill_city);
const [Pin, setPin] = useState(data.bill_pin);
const [State, setState] = useState(data.bill_state);
const [Apmc, setApmc] = useState(data.apmc_charges_per);
    const [InvoiceDate,setInvoiceDate] = useState(data.invoice_date)
    const [DateChange,setDateChange] = useState(new Date(`${data.invoice_date.split("-")[2]}`,`${data.invoice_date.split("-")[1]-1}`,`${data.invoice_date.split("-")[0]}`))
const [Mrp,setMrp] = useState("")
const [hsn,setHSN] = useState(data.hsn_code);
const [Source, setSource] = useState(data?.source_type ? data.source_type : "");
const [Transport, setTransport] = useState(data?.transport ? data.transport : "");
const [CashDisc, setCashDisc] = useState(data.cash_discount);
const [TransCharges, setTransCharges] = useState(data.transport_charges);
const[basePrice,setBasePrice] = useState(data.base_price)

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            return toast("Purchase Details updated", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't Update the Purchase Details", { type: toast.TYPE.ERROR });
    };

  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({name : suppliersList[supplier].supplier_name,email :suppliersList[supplier].EmailID,key : suppliersList[supplier].supplier_id,fssai : suppliersList[supplier].fssai,gstin:suppliersList[supplier]['GST_Identification_Number_(GSTIN)'],phone: suppliersList[supplier].MobilePhone,address:suppliersList[supplier].Billing_Address,street:suppliersList[supplier].Billing_Street2,city:suppliersList[supplier].Billing_City,pin:suppliersList[supplier].Billing_Code,state:suppliersList[supplier].Billing_State})
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        suppliers.push({ value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state});
      });
    }).then(() => {
      setSuppliersList(suppliers)
    });
  };


  const fetchProductItem = () => {
    const productBaseInfo = [];
    productsMasterDB
      .once("value", (snapShot) => {
        const productItems = snapShot.val();

        Object.keys(productItems).forEach((productId) => {
          if (productItems[productId]["buying_flag"] == "Y") {
            productBaseInfo.push({
              articleId: productId,
              category: productItems[productId].category,
              lot_size: productItems[productId].lot_size,
              brand: productItems[productId].brand,
              variety: productItems[productId].variety,
              itemName: productItems[productId].long_description,
              base_price:
                productItems[productId].base_price &&
                productItems[productId].base_price,
              hsn_code : productItems[productId].hsn_code,
})

          }
        });
      })
      .then(() => {
        setArticleInfo(productBaseInfo);
      });
  };

    useEffect(() => {
        fetchProductItem()
        fetchSuppliers()
    }, [])

    useEffect(() => {

        itemCode = itemCode.substring(11);
        itemCode = itemCode.includes("-") ? itemCode.split("-")[0] : itemCode;
        Product_Base.child(`${itemCode}`).once("value", snapShot => {

            const itemsDetails = snapShot.val();
            setUomValue(itemsDetails?.uom ? itemsDetails.uom : "")

        })
    }, [])

    const populateArticle = () => {

        const allOptionsArticle = []
        articleInfo.forEach(item => {
            let itemName = item.itemName
            if (item.brand)
                itemName = item.brand + " - " + item.itemName;

            allOptionsArticle.push({ "value": itemName, "label": itemName })
        })
        setArticleOptions(allOptionsArticle)
    }
    useEffect(() => {
        if (articleInfo)
            populateArticle()
    }, [articleInfo])

     const insertDomData = (itemValue, articleCode) => {
    if (articleCode) {
      setDisableList(false);

      articleInfo.forEach((item) => {
        if (articleCode.includes(item.articleId)) {
          setDisableList(true);
          console.log(item);
          categoryField.current.value = item.category;
          let itemValue = item.itemName;
          if (item.brand) itemValue = item.brand + " - " + item.itemName;
          console.log(
            "Updating based on ArticleCode:: " +
              articleCode +
              " Article Name :: " +
              itemValue +
              " Category:: " +
              item.category
          )
          setHSN(item.hsn_code);
          setCategory(item.category);
          setItemName(itemValue);
          setOuterType(item.variety);
          setSource(item.so)
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          console.log("Outer Size::: " + item.lot_size + item.outer_size);
          Product_Base.child(`${articleCode}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
            setUomValue(itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC");
            setOuterSize(
              Number(itemsDetails.outer_size)
                ? Number(itemsDetails.outer_size)
                : 1
            );
          });
          priceDetailsDB.child(`${articleCode}`).once('value',(snapShot) => {
            const prices = snapShot.val();
            console.log(prices);
            // setSource(prices.source_type)
          })
        }
      });
    } else if (itemValue) {
      articleInfo.forEach((item) => {
        let itemName = item.itemName;
        if (item.brand) itemName = item.brand + " - " + item.itemName;

        if (itemValue == itemName) {
          console.log(
            "Updating based on ArticleName:: " +
              itemValue +
              " Article ID :: " +
              item.articleId +
              " Category:: " +
              item.category
          );
          setCategory(item.category);
          setHSN(item.hsn_code);
          categoryField.current.value = item.category;
          setArticleCode(item.articleId);
          itemNoField.current.value = item.articleId;
          setOuterType(item.variety);
          setUomValue(item.uom);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${item.articleId}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
console.log(itemsDetails);
            if (itemsDetails) {
              setUomValue(itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC");
              setOuterSize(
                Number(itemsDetails.outer_size)
                  ? Number(itemsDetails.outer_size)
                  : 1
              );
            }
          });
          priceDetailsDB.child(`${item.articleId}`).once('value').then((snapShot) => {
              console.log(snapShot.val());
              const prices = snapShot.val();
              setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
              setApmc(prices?.apmc_per ? prices.apmc_per : "0")
              if(prices?.source_type === 'local_pick')
              {
                setSource("LOCAL PICK")
              }
              else if(prices?.source_type === 'dc_delivered')
              {
                setSource("DC DELIVERED")
              }
              else
              {
               setSource("MILL")
               setTransport(prices?.mill_transport ? prices.mill_transport : "");
               setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
               setCashDisc(prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
              }
            })
        }
      });
    }
  };
    useEffect(() => {
        if (articleCode)
            insertDomData()
    }, [articleCode])

    const PushPurchaseDetails = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            console.log("SupplierName:: " + supplier);
            console.log("Selected date:: " + selectedDate);
            console.log("editPurchaseDate:" + editPurchaseDate);

            let newPurchaseDate = formatPurchaseDate(editPurchaseDate);

            const totalWightVal = totalWeight ? totalWeight : outerSize && bagPurchased ? (outerSize) * (bagPurchased) :
                bagPurchased ? (data.outer_size) * (bagPurchased) :
                    outerSize ? (outerSize) * (data.bag_purchased) : data.total_weight;

            let outerSizeValue = outerSize ? outerSize : data.outer_size;
            let bagPurchasedValue = bagPurchased ? bagPurchased : data.bag_purchased;
            let totalWeightCalc = totalWeight ? totalWeight : totalWightVal;

            console.log('totalWeightCalc: ', totalWeightCalc, ' totalWeight: ', totalWeight, ' data.total_weight: ', data.total_weight);
            if (supplier) {
                console.log("SupplierName:: " + supplier);
                var newSupplier = true;
                suppliersList.forEach(item => {
                    if (supplier == item.value) {
                        newSupplier = false;
                    }
                });
                if (newSupplier) {
                    SuppliersDB.push({ supplier_name: supplier });
                }
            }

            const dataToUpdate = {

                "category": category ? category : data.category, 
                "article_code": articleCode ? articleCode : data.article_code,
                "item_name": itemName ? itemName : data.item_name, 
                "outer_type": outerType ? outerType : data.outer_type, 
                "outer_size": outerSizeValue, 
                "replacement_brand": replacementBrand ? replacementBrand : data.replacement_brand,
                 "bag_purchased": bagPurchasedValue,
                "total_weight": totalWeightCalc,
                "invoice_number":invoiceNumber ? invoiceNumber : data.invoice_number, 
                "uom": uomValue, 
                "purchase_price": purchasePrice ? purchasePrice : data.purchase_price,
                "supplier_name": supplier ? supplier : data.supplier_name, 
                "bill_address":Address,
                "bill_city":City,
                "bill_street":Street,
                "bill_state":State,
                "bill_pin":Pin,
                "bill_gstin":Gstin,
                "bill_fssai":Fssai,
                "bill_phone":phone,
                "gst_charges_per" : GstCharges,
                "apmc_charges_per":Apmc,
                "hsn_code":hsn,
                "base_price": basePrice,
                "invoice_date":InvoiceDate,
                "source_type" : Source,
                "transport" : Transport,
                "transport_charges" : TransCharges,
                "cash_discount" : CashDisc,
                "transport ": Transport,
                "supplier_email":SupplierEmail,
                "supplier_id":SupplierID,
                "delivery_in_days":Delivery,
                "payment_terms" : Payment_Terms,
                "mrp": Mrp,
                "po_amount" : purchasePrice*(totalWeight ? totalWeight : outerSize && bagPurchased ? (outerSize) * (bagPurchased) :
                                        bagPurchased ? (data.outer_size) * (bagPurchased) :
                                            outerSize ? (outerSize) * (data.bag_purchased) : data.total_weight),
                "updated_by": UserEmail(), 
                "updated_at": `${UpdatedDate()} ${UpdatedTime()}`

            }

            itemCode = unique_key.substring(11);

            //update in firebase
            if (newPurchaseDate != selectedDate) {
                dataToUpdate.purchased_date = newPurchaseDate;
                dataToUpdate.created_at = data.created_at.slice(0, data.created_at.indexOf('T'));
            } else {

                dataToUpdate.purchased_date = selectedDate;
            }

            console.log('newPurchaseDate: ',newPurchaseDate,' selectedDate: ',selectedDate);

            const firebaseProm = new Promise((resolve, reject) => {

                PurchaseDetailsData.child(`${newPurchaseDate.split("-")[2]}/${newPurchaseDate.split("-")[1]}/${newPurchaseDate}`).child(itemCode).update(dataToUpdate).then(() => {
                    console.log(newPurchaseDate);

                    if (newPurchaseDate != selectedDate) {
                        PurchaseDetailsData.child(`${selectedDate.split("-")[2]}/${selectedDate.split("-")[1]}/${selectedDate}`).child(itemCode).remove().then(() => {

                            resolve("Success");
                        });
                    } else {

                        resolve("Success");
                    }

                }).catch(error => {

                    reject(error);
                })
            });

            const mysqlProm = new Promise((resolve, reject) => {

                const jsonObj = dataToUpdate;
                jsonObj.key = itemCode;
                console.log(jsonObj);

                const purchase_date_new = `${jsonObj.purchased_date.split("-")[2]}-${jsonObj.purchased_date.split("-")[1]}-${jsonObj.purchased_date.split("-")[0]}`;
                jsonObj.purchased_date = purchase_date_new;
                //if (newPurchaseDate == selectedDate) {
                const url = `/api/superzop/commercial/purchasedetails/updatepurchasedetailsrecord?key=${unique_key}`
                baseUrl.put(url, jsonObj).then(res => {
                    console.log('PushPurchaseDetails ', res);
                    resolve("Success");
                }).catch((error) => {
                    reject(error);
                });
                /*} else {
                    const url = '/api/superzop/commercial/purchasedetails/createpurchasedetailsrecord';
                    baseUrl.post(url, jsonObj).then(res => {
                        console.log('updatePurchaseDetails ', res);
                        resolve("Success");
                        const deletUrl = `/api/superzop/commercial/purchasedetails/deletepurchasedetails?key=${unique_key}`;
                        baseUrl.delete(deletUrl).then(res => {
                            resolve("Success");
                        }).catch((error) => {
                            reject("Failed");
                        });
                    }).catch((error) => {
                        console.error("An Error has occurred ", error);
                        reject(error);
                    });
                }*/
            });

            Promise.all([firebaseProm, mysqlProm]).then(() => {
                props.onHide();
                notifyUser(true);
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }).catch(error => {
                console.error(error);
                props.onHide();
                notifyUser(false);
            })

            /*PurchaseDetailsData.child(`${newPurchaseDate.split("-")[2]}/${newPurchaseDate.split("-")[1]}/${newPurchaseDate}`).child(itemCode).update(dataToUpdate).then(() => {
                console.log(newPurchaseDate);
                console.log(selectedDate);
                if (newPurchaseDate != selectedDate) {
                    PurchaseDetailsData.child(`${selectedDate.split("-")[2]}/${selectedDate.split("-")[1]}/${selectedDate}`).child(itemCode).remove().then(() => {
                        props.onHide();
                        notifyUser(true);
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000);
                    });
                } else {
                    props.onHide();
                    notifyUser(true);
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                }

            });

            const jsonObj = dataToUpdate
            jsonObj.key = itemCode;
            jsonObj.created_at = data.created_at ? data.created_at : `${UpdatedDate()} ${UpdatedTime()}`;

            const url = '/api/superzop/commercial/purchasedetails/createpurchasedetailsrecord';

            jsonPlaceholder.post(url, jsonObj).then(res => {

                console.log('PushPurchaseDetails ', res);

            }).catch((error) => {


            });*/

        }
        setValidated(true)
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );
    const formatDate = (val) => {
    let date = new Date(val)
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
}

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={PushPurchaseDetails}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Purchase Details
                </Modal.Title>
                </Modal.Header>
                <Modal.Body  style={{
      maxHeight: 'calc(100vh - 210px)',
      overflowY: 'auto'
     }}>
                    <div>
                        <Table>
                            <tr>
                                <td>Purchase Date</td>
                                <td><DatePicker
                                    selected={editPurchaseDate}
                                    onChange={(val) => { setEditPurchaseDate(val); }}
                                    minDate={new Date("2017/01/01")}
                                    maxDate={new Date()}
                                    aria-describedby="basic-addon1"
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomDateInput />}

                                /></td>
                            </tr>
                            <tr>
                                <td>Item Name</td>
                                <td>
                                    {(articleCode == "" || disableList == false) ? <Select required options={articleOptions} ref={itemNameField} placeholder={data.item_name} onChange={(val) => { setItemName(val.value); let itemName = val.value; let itemCode = null; insertDomData(itemName, itemCode) }} style={{ width: "150px" }} /> :
                                        <Form.Control ref={itemNameField} value={itemName} readOnly />}
                                </td>
                            </tr>
                            <tr>
                                <td>Article Code</td>
                                <td >
                                    <Form.Control ref={itemNoField} placeholder={data.article_code} value={articleCode} readOnly />
                                </td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>
                                    <Form.Control value={category} ref={categoryField} readOnly />
                                </td></tr>
                            <tr>
                                <td>Outer Type</td>
                                <td><DropdownButton id="dropdown-basic-button" title={outerType ? outerType : data.outer_type}>
                                    <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"SMALL PACK"} >SMALL PACK</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"GONI"} >GONI</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"BAG OF PACKS"} >BAG OF PACKS</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"HIGH VALUE OF LOOSE"} >HIGH VALUE OF LOOSE</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"FMCG"} >FMCG</Dropdown.Item>
                                </DropdownButton>
                                </td>
                            </tr>
                            <tr>
                                <td>Supplier Name</td>
                                <td>
                                    {/* <Creatable required options={suppliersList} ref={supplierRef} placeholder={data.supplier_name} onChange={(val) => { setSupplier(val.value) }} style={{ width: "100px" }} /> */}
                                    <Select required options={suppliersList} ref={supplierRef} placeholder={data.supplier_name} onChange={(val) =>{
                      setSupplier(val.value);
                      setSupplierID(val.key);
                      setSupplierEmail(val.email);
                      setAddress(val.address);
                      setStreet(val.street)
                      setCity(val.city)
                      setPin(val.pin)
                      setState(val.state)
                      setPhone(val.phone);
                      setGstin(val.gstin);
                      }} style={{ width: "100px" }} />
                                </td>
                            </tr>
                            <tr>
                <td>Supplier ID </td>
                <td>
                  <Form.Control value = {SupplierID} readOnly />
                </td>
              </tr>
              {/* Supplier Email from Supplier DB */}
              <tr>
                <td>Supplier Email</td>
                <td>
                  <Form.Control value = {SupplierEmail}   onChange = {(e) => setSupplierEmail(e.target.value) }/>
                </td>
              </tr>
                            <tr>
                                <td>Invoice Number</td>
                                <td>
                                    <Form.Control onChange={(e) => setInvoiceNumber(e.target.value)} 
                                    defaultValue={invoiceNumber ? invoiceNumber : data.invoice_number} />
                                </td>
                            </tr>
                            <tr>
                <td>Invoice Date</td>
                <td>
                  <DatePicker selected={DateChange} dateFormat = {'dd-MM-yyyy'}  onChange ={(val) => {setInvoiceDate(formatDate(val));
                  setDateChange(val);}}
                  minDate={editPurchaseDate}
                  customInput={<CustomDateInput />} />
                </td>
              </tr>
                            <tr>
                                <td>Base Price</td>
                                <td>
                                    <Form.Control value={basePrice} readOnly />
                                </td>
                            </tr>
                            <tr>
                                <td>Purchase Price</td>
                                <td>
                                    <Form.Control value =  {purchasePrice} onChange={(e) => { setPurchasePrice(e.target.value) 
                                    }} />
                                </td>
                            </tr>
                            <tr>
                                <td>UOM</td>
                                <td>
                                    <Form.Control readOnly value={uomValue ? uomValue : data.uom === null ? "" : data.uom} />
                                </td>
                            </tr>
                            <tr>
                                <td>Outer Size(Kg/Pc)</td>
                                <td>
                                    <Form.Control type="number" readOnly ref={outersizeRef} value={outerSize ? outerSize : data.outer_size} />
                                </td></tr>
                                                 <tr>
                <td>HSN Code</td>
                <td>
                  <Form.Control  value = {hsn}  readOnly/>
                </td>
              </tr>
                            <tr>
               
              
                                <td>Replacement Brand</td>
                                <td>
                                    <Form.Control defaultValue={replacementBrand ? replacementBrand : data.replacement_brand ? data.replacement_brand : ""} onChange={(e) => { setReplacementBrand(e.target.value) }} />
                                    {/* <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback> */}
                                </td>
                            </tr>
                            <tr>
                                <td>No Of Bags Purchased</td>
                                <td>
                                    <Form.Control  value={bagPurchased} onChange={(e) => { setBagPurchased(e.target.value); setTotalWeight("") 
                                 }} />
                                </td>
                            </tr>
                            <tr>
                                <td>Total Weight Purchased</td>
                                <td>
                                    <Form.Control ref={totalWtPurchasedRef} value={totalWeight ? totalWeight : outerSize && bagPurchased ? (outerSize) * (bagPurchased) :
                                        bagPurchased ? (data.outer_size) * (bagPurchased) :
                                            outerSize ? (outerSize) * (data.bag_purchased) : data.total_weight} onChange={(e) => { console.log('e.target.value: ', e.target.value); setTotalWeight(e.target.value); }} />
                                </td>
                            </tr>
                            <tr>
                <td>Source</td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    required
                    title={Source} >
                    <Dropdown.Item

                      onClick={(e) => setSource(e.target.name)}
                      name={"MILL"}
                    >
                      MILL
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setSource(e.target.name)}

                      name={"LOCAL PICK"}
                    >
                      LOCAL PICK
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setSource(e.target.name)}

                      name={"DC DELIVERED"}
                    >
                      DC DELIVERED
                    </Dropdown.Item>
                  </DropdownButton>
                  </td>
              </tr>
              {Source === 'MILL' ? (
                <>
              <tr>

                <td>
                  Transport
                </td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={Transport ? Transport.toUpperCase() : "--Select--"} 
                     >
                    <Dropdown.Item

                      onClick={(e) => setTransport(e.target.name)}
                      name={"included"}
                    >
                      INCLUDED
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setTransport(e.target.name)}

                      name={"excluded"}
                    >
                      EXCLUDED
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr> 
               </>) : null}
               {Transport === 'excluded' && Source === 'MILL' ? (<>
               <tr><td>Transport Charges</td>
              <td><Form.Control defaultValue={TransCharges} onChange={(e) => setTransCharges(e.target.value)}/></td></tr></>) : null}
              {Source === 'MILL' ? (<>
              <tr><td>Cash Discount</td>
               <td><Form.Control defaultValue={CashDisc} onChange={(e) => setCashDisc(e.target.value)}/></td></tr></>) : null}


              <tr>
                <td>Payment Terms (in Days)</td>
                <td>
                  <Form.Control  defaultValue={data.payment_terms} onChange = {(e) => setPayment_Terms(e.target.value)} />
                 
                </td>
              </tr>
              <tr>
                <td>Delivery Period (in Days)</td>
                <td>
                  <Form.Control  defaultValue={data.delivery_in_days} onChange = {(e) => setDelivery(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td>PO Amount </td>
                <td>
                  <Form.Control value ={Number(purchasePrice)*Number(totalWeight ? totalWeight : outerSize && bagPurchased ? (outerSize) * (bagPurchased) :
                                        bagPurchased ? (data.outer_size) * (bagPurchased) :
                                            outerSize ? (outerSize) * (data.bag_purchased) : data.total_weight)} readOnly/>
                </td>
              </tr>
              <tr>
                <td>MRP </td>
                <td>
                  <Form.Control defaultValue = {data.mrp !== null ? data.mrp : ""} onChange={(e) => setMrp(e.target.value)}/>
                </td>
              </tr>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit">Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}

export default EditPurchaseModal
