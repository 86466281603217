import React from "react";
import "react-datetime/css/react-datetime.css";
import {
  Form,
  Button,
  Col,
  Container as ReactBtsContainer,
  InputGroup,
} from "react-bootstrap";
import {
  Select as MantineSelect,
  SimpleGrid,
  MultiSelect,
  TextInput,
  NumberInput,
  Grid,
  Text,
} from "@mantine/core";
import Datetime from "react-datetime";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

import RetailerSeriesDrawer from "../RetailerSeriesDrawer";
import { OfferRange } from "./OfferRange.component";

export const SlabLevelPricing = (props) => {
  const {
    offerTypeSelection,
    userSelectedProductId,
    setUserSelectedProductId,
    productData,
    userSelectedProduct,
    userSelectedStoreSeries,
    setUserSelectedStoreSeries,
    userSelectedRetailerSeries,
    setUserSelectedRetailerSeries,
    retailerSeriesData,
    allStoreSeries,
    offerPercentage,
    productFinalPriceRef,
    isNotificationFieldsShown,
    dealerPrice,
    isSubmitBtnDisabled,
    isRetailerSeriesDrawerOpen,
    setIsRetailerSeriesDrawerOpen,
    handleNewRetailerSeriesCreated,
    onSubmit,
    history,
    handleOfferRangeStackValueModified,
    handleAddNewOfferRange,
    handleRemoveOfferRange,
    handleCreateRetailerGrpClick,
    offerRangeStack,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
  } = props;

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <form onSubmit={onSubmit}>
          <div style={{ textAlign: "center" }}>
            <SimpleGrid cols={2}>
              <div style={{ paddingTop: "7px" }}>
                <br />
                <Text className="required" size="sm">
                  Offer Start
                </Text>
                <InputGroup
                  style={{
                    padding: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Datetime
                    timeFormat={"HH:mm"}
                    dateFormat={"DD-MM-YYYY"}
                    value={currentStartDate}
                    onChange={(date) => {
                      setCurrentStartDate(date._d);
                    }}
                  />
                </InputGroup>
              </div>
              <div style={{ paddingTop: "7px" }}>
                <br />

                <Text className="required" size="sm">
                  Offer End
                </Text>
                <InputGroup
                  style={{
                    padding: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Datetime
                    timeFormat={"HH:mm"}
                    dateFormat={"DD-MM-YYYY"}
                    value={currentEndDate}
                    onChange={(date) => {
                      setCurrentEndDate(date._d);
                    }}
                  />
                </InputGroup>
              </div>
            </SimpleGrid>
            <SimpleGrid cols={3}>
              <div>
                <Form.Group
                  controlId="offerId"
                  style={{
                    display: "inline-block",
                    width: "400px",
                    textAlign: "center",
                    marginTop: "0px",
                    padding: 20,
                  }}
                ></Form.Group>
              </div>
            </SimpleGrid>
            <br />

            <Grid
              justify="space-between"
              style={{
                marginLeft: "40px",
                marginTop: "10px",
                marginRight: "40px",
              }}
            >
              <Grid.Col span={6}>
                <div>
                  <Form.Group
                    controlId="ItemId"
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    <MantineSelect
                      value={userSelectedProductId}
                      label="Item Id"
                      placeholder="Select"
                      description="Selected Item"
                      searchable
                      clearable
                      name="ItemId"
                      onChange={setUserSelectedProductId}
                      nothingFound="No options"
                      required
                      data={productData}
                    />
                  </Form.Group>
                </div>
              </Grid.Col>

              <Grid.Col span={4}>
                <Form.Group
                  controlId="dealerPrice"
                  style={{
                    display: "inline-block",
                    width: "400px",
                    textAlign: "center",
                  }}
                >
                  <NumberInput
                    name="dealerPrice"
                    label="Dealer Price"
                    description="Selected product dealer price"
                    value={dealerPrice}
                    disabled
                  />
                </Form.Group>
              </Grid.Col>
            </Grid>

            {offerRangeStack.map((item, index) => {
              const {
                offerDiscountType,
                minPurQty,
                maxPurQty,
                offerPrice,
                offerPercentage,
                productFinalPrice,
                isMinPurQtyDisabled,
                isMaxPurQtyDisabled,
              } = item;

              return (
                <OfferRange
                  key={index}
                  index={index}
                  length={offerRangeStack.length}
                  offerDiscountType={offerDiscountType}
                  productFinalPriceRef={productFinalPriceRef}
                  productFinalPrice={productFinalPrice}
                  userSelectedProduct={userSelectedProduct}
                  offerPercentage={offerPercentage}
                  offerPrice={offerPrice}
                  maxPurQty={maxPurQty}
                  minPurQty={minPurQty}
                  handleOfferRangeStackValueModified={
                    handleOfferRangeStackValueModified
                  }
                  isMinPurQtyDisabled={isMinPurQtyDisabled}
                  isMaxPurQtyDisabled={isMaxPurQtyDisabled}
                  handleAddNewOfferRange={handleAddNewOfferRange}
                  handleRemoveOfferRange={handleRemoveOfferRange}
                  offerTypeSelection={offerTypeSelection}
                />
              );
            })}

            <SimpleGrid cols={2}>
              <Form.Group
                as={Col}
                controlId="storeSeries"
                style={{
                  display: "inline-block",
                  marginTop: offerTypeSelection === "others" ? "-50px" : "",
                }}
              >
                <MultiSelect
                  searchable
                  label="Select store series"
                  description="select the store series to apply this offer to"
                  className="basic-multi-select"
                  onChange={setUserSelectedStoreSeries}
                  value={userSelectedStoreSeries}
                  name="storeSeries"
                  clearable
                  data={allStoreSeries}
                  required={
                    offerTypeSelection === "MDD" || offerTypeSelection === "BPP"
                  }
                />
              </Form.Group>
              <div>
                <Form.Group as={Col} controlId="retailerSeries">
                  <MultiSelect
                    data={retailerSeriesData}
                    description="Select the retailer series to apply this offer to"
                    label="Select Retailer Series"
                    placeholder="Pick retailer series"
                    onChange={setUserSelectedRetailerSeries}
                    value={userSelectedRetailerSeries}
                    searchable
                    nothingFound="nothing found"
                    clearButtonLabel="Clear selection"
                    clearable
                  />
                </Form.Group>

                <Button
                  size="sm"
                  type="button"
                  className="offerMasterFormRetailerSeriesBtn"
                  onClick={handleCreateRetailerGrpClick}
                >
                  + Create new retailer group
                </Button>
                <Button
                  style={{ marginLeft: ".8rem" }}
                  size="sm"
                  type="button"
                  className="offerMasterFormRetailerSeriesBtn"
                  onClick={() => {
                    props.history.push("/retailerGroups");
                  }}
                >
                  View all retailer groups
                </Button>
              </div>
            </SimpleGrid>
            <SimpleGrid cols={isNotificationFieldsShown ? 2 : 1}>
              <div>
                <br />
                <br />

                <Button
                  disabled={isSubmitBtnDisabled}
                  className="offerMasterFormSubmitBtn"
                  type="submit"
                >
                  Create offer
                </Button>
              </div>
            </SimpleGrid>
          </div>
        </form>
      </div>
      <RetailerSeriesDrawer
        isRetailerSeriesDrawerOpen={isRetailerSeriesDrawerOpen}
        setIsRetailerSeriesDrawerOpen={setIsRetailerSeriesDrawerOpen}
        handleNewRetailerSeriesCreated={handleNewRetailerSeriesCreated}
      />
    </>
  );
};
