import React, { useState, useEffect } from 'react'
import { Badge, Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, Alert, ButtonGroup, Table } from 'react-bootstrap'
import { toast } from 'react-toastify';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import 'react-toastify/dist/ReactToastify.css';
import EditStockCoversion from './StockConversionModal';
import baseUrl from '../../service/servicesConfig';
import { validateIsAdminUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import database from '../../service/firebaseConfig';


function StockConversionListing(props) {

    const [getBackDrop, setBackDrop] = useState(false)
    const [stockConversionList, setStockConversionList] = useState("")
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState("")
    const [editStockConversion, setEditStockConversion] = useState(false)
    const [parentCategory, setParentCategory] = useState("")
    const [parentCode, setParentCode] = useState("")
    const [nonConvertedStock, setNonConvertedStock] = useState("")
    const [editingItemDetails, setEditingItemDetails] = useState("");
    const [productsData, setProductsData] = useState({});
    const Product_Base = database.database().ref(`Product_Base_Data`);

    let filterResult = ""

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify = (unavailability) => {

        if (unavailability)
            return toast("No Purchase details available for selected date range ", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        /*fetch(`https://services2.superzop.com/api/superzop/commercial/stockconversion/stockconversionlist`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setStockConversionList(data); setBackDrop(false); if (!data.orders.length > 0) { notify(true) } }).catch(err => console.log(err))*/



        baseUrl.get(`/api/superzop/commercial/stockconversion/stockconversionlistnew`).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            //console.log(data);

            if (isEmpty(productsData)) {
                Product_Base.once("value", snapShot => {
                    console.log("Set Product Base data")
                    console.log(snapShot.val());
                    setProductsData(snapShot.val());
                }).then(() => {
                    setBackDrop(false);
                    setStockConversionList(data);
                    if (!data.length > 0) {
                        notify(true)
                    }
                    console.log("stockConversionList: ", data);
                })
            }
        }).catch(err => console.log(err))

    }, []);

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const deleteStockConversionNew = (item) => {

        console.log('deleteStockConversion:', item);

        const answer = window.confirm("Are you sure you want to delete?");

        if (answer) {

            let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
            let date = new Date();
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            let outer_size = productsData[item.parent_code]["outer_size"];
            if (!outer_size) {
                outer_size = 1;
            }

            let non_converted_stock = item.non_converted_stock;
            if (!non_converted_stock) {
                non_converted_stock = 0;
            }

            const stock_transfer = Number(non_converted_stock) / Number(outer_size);
            //console.log('parent_code: ', item.parent_code, ' outer_size: ', outer_size);

            const data = {
                stock_transfer_date: date,
                created_by: loggedEmailId,
                major_category: item.major_category,
                //non_converted_stock: item.non_converted_stock,
                non_converted_stock: non_converted_stock,
                parent_code: item.parent_code,
                parent_name: item.parent_name,
                //stock_transfer_total_weight: `${item.stock_transfer_total_weight}`,
                stock_transfer_total_weight: non_converted_stock,
                bag_weight_loss: item.total_bag_weight_loss,
                //stock_transfer: item.stock_transfer,
                stock_transfer: stock_transfer,
                //total_weight_converted: item.total_weight_converted
            }

            const url = `/api/superzop/commercial/stockconversion/editstockconversionandsi?type=delete`;

            baseUrl.post(url, data).then(res => {

                console.log('Push Converted Stocks ', res);

                //resolve("Success");

                return toast('Deleted and Synced System Inventory', { type: toast.TYPE.SUCCESS });

            }).catch((error) => {

                console.error(error);
                //reject(error);
                return toast('Failed to Delete', { type: toast.TYPE.SUCCESS });
            });
        }
    }


    const deleteStockConversion = (item) => {

        console.log('deleteStockConversion:', item);
        const answer = window.confirm("Are you sure you want to delete?");

        if (answer) {

            let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
            let date = new Date();
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

            const data = {
                stock_transfer_date: date,
                created_by: loggedEmailId,
                major_category: item.major_category,
                non_converted_stock: item.non_converted_stock,
                parent_code: item.parent_code,
                parent_name: item.parent_name,
                stock_transfer_total_weight: `${item.stock_transfer_total_weight}`,
                bag_weight_loss: item.total_bag_weight_loss,
                //total_weight_converted: item.total_weight_converted
            }

            const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${date}&item_id=${item.parent_code}`;

            let stock_tansfer_to_delete = item.non_converted_stock;
            if (stock_tansfer_to_delete) {
                let obj;
                if (Number(stock_tansfer_to_delete) >= 0) {
                    obj = {
                        stock_transfer: `-${stock_tansfer_to_delete}`
                    }
                } else {
                    stock_tansfer_to_delete = Number(stock_tansfer_to_delete) * -1;
                    obj = {
                        stock_transfer: stock_tansfer_to_delete
                    }
                }

                console.log('url: ', url, ' obj: ', obj);

                baseUrl.put(url, obj).then(res => {

                    console.log('Synced System Inventory ', res);
                    const save_stockconversion_url = `/api/superzop/commercial/stockconversion/savestockconversionintotable`

                    baseUrl.post(save_stockconversion_url, data).then(res => {

                        console.log(data)
                        toast("Deleted Successfully ", { type: toast.TYPE.SUCCESS });
                        // setTimeout(() => {
                        //     window.location.reload()
                        // }, 2000);

                        //const sync_sys_inv_url = `/api/superzop/systeminventory/savesysteminventoryintotable?start_date=${date}&end_date=${date}&item_id=${item.parent_code}`
                        /*const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${date}&end_date=${date}&item_id=${item.parent_code}`;
        
                        baseUrl.post(sync_sys_inv_url).then(res => {
        
                            console.log('isAdmin updatePurchaseDetails ', res);
                            return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                        }).catch((error) => {
                            console.log(error);
                        });*/


                    }).catch((error) => {
                        console.log(error)
                        return toast("Couldn't create the stock conversion record in MySQL", { type: toast.TYPE.ERROR });
                    });
                    toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                }).catch(error => {
                    console.log(error);
                })
            } else {

                const save_stockconversion_url = `/api/superzop/commercial/stockconversion/savestockconversionintotable`

                baseUrl.post(save_stockconversion_url, data).then(res => {

                    console.log(data)
                    toast("Deleted Successfully ", { type: toast.TYPE.SUCCESS });
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 2000);

                    //const sync_sys_inv_url = `/api/superzop/systeminventory/savesysteminventoryintotable?start_date=${date}&end_date=${date}&item_id=${item.parent_code}`
                    /*const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${date}&end_date=${date}&item_id=${item.parent_code}`;
    
                    baseUrl.post(sync_sys_inv_url).then(res => {
    
                        console.log('isAdmin updatePurchaseDetails ', res);
                        return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                    }).catch((error) => {
                        console.log(error);
                    });*/


                }).catch((error) => {
                    console.log(error)
                    return toast("Couldn't create the stock conversion record in MySQL", { type: toast.TYPE.ERROR });
                });
            }
        }

    }

    const searchConversionList = () => {

        const filterData = []
        filterResult = stockConversionList.filter(obj => {

            if (obj.parent_code == null || obj.parent_name == null || obj.major_category == null)
                return

            const itemId = obj.parent_code && obj.parent_code.trim();
            const itemDesc = obj.parent_name && obj.parent_name.trim().toLowerCase();
            const categoryVal = obj.major_category && obj.major_category.trim().toLowerCase()

            setSearchText(searchText.trim().toLowerCase());
            return itemDesc.match(searchText) || categoryVal.match(searchText) || itemId.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(items)
        })
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchConversionList()
    }, [searchText])
    return (
        <div>
            <NavBar />
            <Alert style={{ paddingTop: 100, display: "flex", justifyContent: "center" }} variant={"danger"}>Stock Conversion Listing</Alert>

            <div>
                <Navbar className="bg-light mr-auto justify-content-between"   >
                    <Form inline >
                        {stockConversionList.length > 0 && <InputGroup style={{ marginLeft: "100px" }}>
                            <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Item Name / Category" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                        </InputGroup>}
                        <Button style={{ display: "flex", justifyContent: "center", marginLeft: 20 }} size="sm" onClick={() => { props.history.push('/stockConversion') }}>Stock Transfer</Button>
                        <Button style={{ display: "flex", justifyContent: "center", marginLeft: 20 }} size="sm" onClick={() => { props.history.push('/stockConversionReport') }}>Stock Conversion Report</Button>
                        
                    </Form></Navbar>
                {stockConversionList.length > 0 &&
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <td>Major Category</td>
                                <td>Parent Name</td>
                                <td>Parent Code</td>
                                <td>Stock transfer Total Weight</td>
                                {/* <td>Total Bag Weight Loss</td> */}
                                <td>Converted Stock in Kg</td>
                                <td>Wastage in Kg</td>
                                <td>Bag Weight Loss in Kg</td>
                                {/* <td>Non-Converted Stock</td> */}
                                {/* <td>Convert Stock</td>
                                {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                    <center> X </center>
                                </td>} */}
                            </tr>
                        </thead>
                        <tbody>
                            {stockConversionList.length > 0 && filterList.length == 0 ? stockConversionList.map(items => (
                                <tr>
                                    <td>{items.major_category ? items.major_category : ""}</td>
                                    <td>{items.parent_name}</td>
                                    <td>{items.parent_code}</td>
                                    <td>{items.stock_transfer_total_weight}</td>
                                    {/* <td>{items.total_bag_weight_loss}</td> */}
                                    <td>{items.total_weight_converted}</td>
                                    <td>{items?.total_wastage}</td>
                                    <td>{items.total_bag_weight_loss}</td>
                                    {/* <td>{items.non_converted_stock}</td> */}
                                    {/* <td><Button onClick={() => { setEditStockConversion(true); setParentCategory(items.major_category); setParentCode(items.parent_code); setNonConvertedStock(items.non_converted_stock); setEditingItemDetails(items) }}>Convert</Button></td>
                                    {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) &&
                                        <td><Button onClick={() => deleteStockConversionNew(items)}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>} */}
                                </tr>
                            )) :
                                filterList.map(items => (
                                    <tr>
                                        <td>{items.major_category ? items.major_category : ""}</td>
                                        <td>{items.parent_name}</td>
                                        <td>{items.parent_code}</td>
                                        <td>{items.stock_transfer_total_weight}</td>
                                        {/* <td>{items.total_bag_weight_loss}</td> */}
                                        <td>{items.total_weight_converted}</td>
                                        <td>{items?.total_wastage}</td>
                                        <td>{items.total_bag_weight_loss}</td>
                                        {/* <td>{items.non_converted_stock}</td> */}
                                        {/* {items.non_converted_stock <= 0 ? <td></td> : <td><Button onClick={() => { setEditStockConversion(true); setParentCategory(items.major_category); setParentCode(items.parent_code); setNonConvertedStock(items.non_converted_stock); }}>Convert</Button></td>} */}
                                    </tr>
                                ))

                            }

                        </tbody>
                    </Table>
                }
            </div>
            {editStockConversion && <EditStockCoversion
                show={editStockConversion}
                onHide={() => setEditStockConversion(false)}
                majorCat={parentCategory}
                parentItemCode={parentCode}
                itemData={editingItemDetails}
                nonConvertedStock={nonConvertedStock}
            />}
        </div>
    )
}

export default StockConversionListing
