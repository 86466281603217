import styled from 'styled-components';

export const ProductDataCardContainer = styled.div`
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    margin: 1rem 2rem 3rem 2rem;
    padding: 2rem;
    
    .topDetails {
        ol {
            li {
                min-width: 70%;

                ::before {
                    content: '';
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                }

                &:nth-child(2) { 
                    min-width: 15%;
                    display: inherit;
                    text-align: right;
                }
            }

            .editSign {
                min-width: 10%;
                text-align: right;
            }
        }
    }

    &:hover {
        box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.3);
    }
`;

export const TabFirstContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;

    .leftMain {
        min-width: 80%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        padding: 0rem 1rem;

        h6 {
            width: 100%;
            line-height: 1.4;
            span {
                width: 35%;
                display: inline-block;
                font-weight: bold;
            }
        }
    }

    .rightMain {
        max-width: 20%;
        padding: 0rem 1rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        a {
            figure {
                img {
                    padding: 1rem;
                    border-radius: 100%; 
                    
                    &:hover {
                        box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
`;

export const TabSecondContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    .leftMain {
        min-width: 80%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        padding: 0rem 1rem;
    h6 {
        width: 100%;
        line-height: 1.4;
        span {
            width: 35%;
            display: inline-block;
            font-weight: bold;
        }
    }
}
.rightMain {
    max-width: 20%;
    padding: 0rem 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    Button{
        
    }
}
`;

export const CheckBoxContainer = styled.div`
    margin: 0rem 1rem; 

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;
    }

    label:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #0079bf;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 15px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
    }

    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 14px;
        width: 6px;
        height: 22px;
        border: solid #0079bf;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;