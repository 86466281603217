import React, { useState, useEffect } from 'react'
import { Row, Form, InputGroup, Card, Alert, Button, Col, Dropdown } from 'react-bootstrap';
import database from '../../../service/firebaseConfig';
import {ORDERING_BASE_URL} from '../../../service/servicesConfig';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom'
import { useForm,Controller } from "react-hook-form"
// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Select from 'react-select';
import NewimageUpload from './ImageUpload'
import NavBar from '../../../Dashboard/Navbar/Dashboard_Navabar'
import FooterComponent from '../../../components/Footer/index'
import { toast } from 'react-toastify';
import request from 'request';
import { BASE_IMAGE_URL,IMAGE_TOKEN,ALL_REGIONS_DD } from '../../../constants/allConstants';

function CreateNotifications() {

    const todaysDate = () => {

        const d = new Date();

        const year = d.getFullYear();
        let month = d.getMonth()+1;
        let day = d.getDate();

        month = month.toString().length===1 ? "0"+month : month;
        day = day.toString().length===1 ? "0"+day : day;

        return `${year}-${month}-${day}`;
        //return "2021-04-19";
    }

    const [validated, setValidated] = useState(false);
    const [getMessage, setMessage] = useState("")
    const [getTitle, setTitle] = useState("")
    const [getImgUrl, setImgUrl] = useState("")
    const [getImgFolder, setImgFolder] = useState("superzop_ordering_offers_images")
    const [getImgName, setImgName] = useState("")
    const [buttonStatus, setButtonStatus] = useState(false)
    const [getDropDownItem, setDropDownItem] = useState(ALL_REGIONS_DD)
    const [newImgName, setNewImgName] = useState("")
    const [notificationType, setNotificationType] = useState("");
    const [scheduleNotification, setScheduleNotification] = useState("no");
    const [appsNotification, setAppsNotification] = useState("com.retranzinfolabs.superzopordering");
    const [selectDate, setSelectDate] = useState(todaysDate);
    const [timings, setTimings] = useState({
        _9_am: false,
        _9_30_am: false,
        _10_am: false,
        _10_30_am: false,
        _11_am: false,
        _11_30_am: false,
        _12_pm: false,
        _12_30_pm: false,
        _1_pm: false,
        _1_30_pm: false,
        _2_pm: false,
        _2_30_pm: false,
        _3_pm: false,
        _3_30_pm: false,
        _4_pm: false,
        _4_30_pm: false,
        _5_pm: false,
        _5_30_pm: false,
        _6_pm: false,
        _6_30_pm: false,
        _7_pm: false,
        _7_30_pm: false,
        _8_pm: false,
        _8_30_pm: false,
        _9_pm: false,
    });
    const userModifying = sessionStorage.getItem(`Logged User Email Id`)

    const [selectedOption, setSelectedOption] = useState(''); 
    const [phoneNumber, setPhoneNumber] = useState(''); 
    const [immediateChecked, setImmediateChecked] = useState(false);
   
    // console.log('selectDate',selectDate);

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });


    


    const handleSubmit = (event) => {
        
        let selectedSeries = [];
        event.preventDefault();
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (getDropDownItem.length === 0) {
                getDropDownItem = ALL_REGIONS_DD
            }
            const randomFile = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)

            let uploadedDt = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`
            let imgName = `${randomFile}_${uploadedDt}`
            const randomText = (Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 19));
            let addKey = database.database().ref(`Notifications/A`).push().getKey();
            let dtModified = new Date()
            dtModified = `${dtModified.getDate()}/${dtModified.getMonth() + 1}/${dtModified.getFullYear()} ${dtModified.getHours() < 10 ? "0" + dtModified.getHours() : dtModified.getHours()}:${dtModified.getMinutes() < 10 ? "0" + dtModified.getMinutes() : dtModified.getMinutes()}`

            if (!notificationType || notificationType == "Bell") {
                Object.values(getDropDownItem).forEach(item => {
                    database.database().ref(`Notifications/${item.value}`).child(addKey).set({
                        title: getTitle,
                        calendar_date: new Date().getTime(),
                        message: getMessage,
                        image_url: getImgUrl ? getImgUrl : BASE_IMAGE_URL+getImgName+IMAGE_TOKEN,
                        image_folder: getImgFolder ? getImgFolder : "superzop_ordering_offers_images",
                        image: getImgName,
                        read_status: "N",
                        added_by: userModifying,
                        added_at: dtModified,
                        retailer_id: 90001,
                        series: item
                    }).then(() => {
                        // setTimeout(() => {
                        //     window.location.reload()
                        // }, 2000);
                        setButtonStatus(true);
                        return toast(`Data inserted succesfully for the Series - ${item.value}`, { type: toast.TYPE.SUCCESS })
                    })
                })
                // }
            } else {

                const notifTimings = [];

                if (timings._9_am) {

                    notifTimings.push("9 AM");
                }

                if (timings._9_30_am) {

                    notifTimings.push("9:30 AM");
                }

                if (timings._10_am) {

                    notifTimings.push("10 AM");
                }

                if (timings._10_30_am) {

                    notifTimings.push("10:30 AM");
                }

                if (timings._11_am) {

                    notifTimings.push("11 AM");
                }

                if (timings._11_30_am) {

                    notifTimings.push("11:30 AM");
                }

                if (timings._12_pm) {

                    notifTimings.push("12 PM");
                }

                if (timings._12_30_pm) {

                    notifTimings.push("12:30 PM");
                }

                if (timings._1_pm) {

                    notifTimings.push("1 PM");
                }

                if (timings._1_30_pm) {

                    notifTimings.push("1:30 PM");
                }

                if (timings._2_pm) {

                    notifTimings.push("2 PM");
                }

                if (timings._2_30_pm) {

                    notifTimings.push("2;30 PM");
                }

                if (timings._3_pm) {

                    notifTimings.push("3 PM");
                }

                if (timings._3_30_pm) {

                    notifTimings.push("3:30 PM");
                }

                if (timings._4_pm) {

                    notifTimings.push("4 PM");
                }

                if (timings._4_30_pm) {

                    notifTimings.push("4:30 PM");
                }

                if (timings._5_pm) {

                    notifTimings.push("5 PM");
                }

                if (timings._5_30_pm) {

                    notifTimings.push("5:30 PM");
                }

                if (timings._6_pm) {

                    notifTimings.push("6 PM");
                }

                if (timings._6_30_pm) {

                    notifTimings.push("6:30 PM");
                }

                if (timings._7_pm) {

                    notifTimings.push("7 PM");
                }

                if (timings._7_30_pm) {

                    notifTimings.push("7:30 PM");
                }

                if (timings._8_pm) {

                    notifTimings.push("8 PM");
                }

                if (timings._8_30_pm) {

                    notifTimings.push("8:30 PM");
                }

                if (timings._9_pm) {

                    notifTimings.push("9 PM");
                }
              


// new code
const timeStr = new Date().toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric", // Use "numeric" instead of "2-digit" to disable leading zeroes, in single hr, eg, 01, 09
    minute: "2-digit"
});

let timeStrSplit = timeStr.split(" ");
let [hour,mins] = timeStrSplit[0].split(":");
let amPm = timeStrSplit[1].toLowerCase();
let new_time_str = "";
if(mins < 25 ){
new_time_str = `_${hour}_30_${amPm}`
}else{
hour = Number(hour) + 1;
new_time_str = `_${hour}_${amPm}`
}

// console.log(new_time_str)
  
  
if (timings.hasOwnProperty(new_time_str)) {
    // console.log(`The key ${new_time_str} exists in the timings object.`);
  
    let converted_time = convertToTimeString(new_time_str);
    notifTimings.push(converted_time);
    // console.log(notifTimings)
  } else {
    // console.log(`The key ${new_time_str} does not exist in the timings object.`);
  }
  
  function convertToTimeString(input) {
    const timeParts = input.split('_');
    const hour = parseInt(timeParts[1]);
  
    let formattedHour;
    if (hour === 0) {
      formattedHour = 12;
    } else if (hour <= 12) {
      formattedHour = hour;
    } else {
      formattedHour = hour - 12;
    }
  
    let period = '';
    if (!isNaN(parseInt(timeParts[2])) && (timeParts[3] === 'am' || timeParts[3] === 'pm')) {
      period = timeParts[3].toUpperCase();
    } else if (timeParts[2] && (timeParts[2].toLowerCase() === 'am' || timeParts[2].toLowerCase() === 'pm')) {
      period = timeParts[2].toUpperCase();
    }
  
    const hasMinutes = timeParts[2] && !isNaN(parseInt(timeParts[2]));
  
    const timeString = period ? `${formattedHour}${hasMinutes ? ':' + timeParts[2] : ''} ${period}` : `${formattedHour}${period ? ' ' + period : ''}`;
    return timeString;
  }
  

if (notificationType === 'Ring' && notifTimings.length === 0 && !immediateChecked) {
    alert("Please select timings");
    return;
  }
  
  // new code ends
// debugger;
                const obj = {
                    "notification_title": getTitle,
                    "notification_description": getMessage,
                    "notification_image": getImgUrl ? getImgUrl : BASE_IMAGE_URL+getImgName+IMAGE_TOKEN,
                    "app_package": appsNotification ? appsNotification : "com.retranzinfolabs.superzopordering",
                    "loop": 1,
                    "scheNotTimObj": {
                        "notificationTimings": notifTimings
                        },
                      
                    }

                    Object.values(getDropDownItem).forEach(item => {
                        obj[(item.label).toLowerCase().split(" ")[1]+"_"+(item.label).toLowerCase().split(" ")[0]] = item.value;
                    });
               

                var reqJSON = {

                    url: ORDERING_BASE_URL+'/api/superzop/admin/schedulenotificationfortimings',
                    body: obj,
                    json: true
                }

              

                request.post(reqJSON, function (error, response, body) {
                    if (error) throw error;

                    var info = JSON.stringify(body, null, "");

                   
                  
                    if (!error && response.statusCode === 200) {
                        console.log(" statusCode body info " + info);
                        // setTimeout(() => {
                        //     window.location.reload()
                        // }, 3000);
                        setButtonStatus(true);
                        return toast(`Data inserted succesfully - ${obj.notification_title}`, { type: toast.TYPE.SUCCESS })
                    }
                    return null;
                });
            }
        }

          // code for immediate notifications
    const sendTextNotification = true; 
    const sendWhatsappNotification = true; 

   
    if (sendTextNotification) {
       
        const recipientPhoneNumber = 'RECIPIENT_PHONE_NUMBER';
        const textMessage = `Notification: ${getTitle}\nMessage: ${getMessage}`;

      
        // sendTextMessage(recipientPhoneNumber, textMessage);
    }

   
    if (sendWhatsappNotification) {
       
        const recipientWhatsAppNumber = 'RECIPIENT_WHATSAPP_NUMBER';
        const whatsappMessage = `Notification: ${getTitle}\nMessage: ${getMessage}`;


        // sendWhatsAppMessage(recipientWhatsAppNumber, whatsappMessage);
    }
        setValidated(true);
    }

    useEffect(() => {
        if (getImgUrl !== '') {
            const fetchReqData = getImgUrl.split("/")[7]
            const fetchImgFolder = fetchReqData.split("%")[0]
            const fetchImgName = fetchReqData.split("%2F")[1].split("?")[0]
            setImgFolder(fetchImgFolder)
            setImgName(fetchImgName)

        }
    }, [getImgUrl])

    const handleImmediateOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setPhoneNumber("")
      };
    
      // Add a function to handle phone number input change
      const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
      };

    const changeNotificationType = (event) => {

        setNotificationType(event.target.value);
        setButtonStatus(false);
        console.log("changeNotificationType", notificationType);
    }

    const changeScheduleNotification = (event) => {

        setScheduleNotification(event.target.value);
        console.log("changeScheduleNotification", scheduleNotification);
    }

    const changeAppsNotification = (event) => {

        setAppsNotification(event.target.value);
    }

    const handleSelectDateChange = (event) => {

        console.log("handleSelectDateChange", event.target.value);

        setSelectDate(event.target.value);
    }

    const handleScheduleTimings = (event) => {

        console.log("handleScheduleTimings", event.target.value);
        const timingsVal = event.target.value;
        switch (timingsVal) {
            case '9 AM':
                setTimings({ ...timings, _9_am: timings._9_am ? false : true });
                break;

            case '9:30 AM':
                setTimings({ ...timings, _9_30_am: timings._9_30_am ? false : true });
                break;
            case '10 AM':
                setTimings({ ...timings, _10_am: timings._10_am ? false : true });
                break;
            case '10:30 AM':
                setTimings({ ...timings, _10_30_am: timings._10_30_am ? false : true });
                break;
            case '11 AM':
                setTimings({ ...timings, _11_am: timings._11_am ? false : true });
                break;
            case '11:30 AM':
                setTimings({ ...timings, _11_30_am: timings._11_30_am ? false : true });
                break;
            case '12 PM':
                setTimings({ ...timings, _12_pm: timings._12_pm ? false : true });
                break;
            case '12:30 PM':
                setTimings({ ...timings, _12_30_pm: timings._12_30_pm ? false : true });
                break;
            case '1 PM':
                setTimings({ ...timings, _1_pm: timings._1_pm ? false : true });
                break;
            case '1:30 PM':
                setTimings({ ...timings, _1_30_pm: timings._1_30_pm ? false : true });
                break;
            case '2 PM':
                setTimings({ ...timings, _2_pm: timings._2_pm ? false : true });
                break;
            case '2:30 PM':
                setTimings({ ...timings, _2_30_pm: timings._2_30_pm ? false : true });
                break;
            case '3 PM':
                setTimings({ ...timings, _3_pm: timings._3_pm ? false : true });
                break;
            case '3:30 PM':
                setTimings({ ...timings, _3_30_pm: timings._3_30_pm ? false : true });
                break;
            case '4 PM':
                setTimings({ ...timings, _4_pm: timings._4_pm ? false : true });
                break;
            case '4:30 PM':
                setTimings({ ...timings, _4_30_pm: timings._4_30_pm ? false : true });
                break;
            case '5 PM':
                setTimings({ ...timings, _5_pm: timings._5_pm ? false : true });
                break;
            case '5:30 PM':
                setTimings({ ...timings, _5_30_pm: timings._5_30_pm ? false : true });
                break;
            case '6 PM':
                setTimings({ ...timings, _6_pm: timings._6_pm ? false : true });
                break;
            case '6:30 PM':
                setTimings({ ...timings, _6_30_pm: timings._6_30_pm ? false : true });
                break;
            case '7 PM':
                setTimings({ ...timings, _7_pm: timings._7_pm ? false : true });
                break;
            case '7:30 PM':
                setTimings({ ...timings, _7_30_pm: timings._7_30_pm ? false : true });
                break;
            case '8 PM':
                setTimings({ ...timings, _8_pm: timings._8_pm ? false : true });
                break;
            case '8:30 PM':
                setTimings({ ...timings, _8_30_pm: timings._8_30_pm ? false : true });
                break;
            case '9 PM':
                setTimings({ ...timings, _9_pm: timings._9_pm ? false : true });
                break;

        }
        // if (timingsVal == '9 AM') {
        //     setTimings({ ...timings, _9_am: timings._9_am ? false : true });
        // }

        console.log("handleScheduleTimings", timings);
    }

    const handleImmediateNotification = (event) => {
       
        const isImmediateChecked = event.target.checked;
        setImmediateChecked(isImmediateChecked);
   
    };
    


    return (
        <>
            <div>
                <NavBar />
            </div><br /><br /><br /><br />
            <div style={{ textAlign: "center" }}>
                <Alert variant="primary" style={{ height: "80px" }}>Create New Notifications</Alert>
                <Card style={{ display: "inline-block", width: "1000px" }} > <Button variant="info" style={{ float: "right" }} >
                    <Link style={{ color: "white" }} to="/series_listing">List Series</Link></Button>
                    <Card.Body >
                        <Card.Text style={{ textAlign: "center" }}>
                            <Form  noValidate validated={validated} onSubmit={handleSubmit} style={{ padding: "30px", textAlign: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Title</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={(e) => setTitle(e.target.value)}
                                                type="text"
                                                name="Title"
                                            />
                                            <Form.Control.Feedback type="invalid">Title is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Message</Form.Label>
                                            <Form.Control
                                                required
                                                onChange={(e) => setMessage(e.target.value)}
                                                type="text"
                                                name="Message"
                                            />
                                            <Form.Control.Feedback type="invalid">Message is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Card ><Card.Body style={{ backgroundColor: "#87CEEB" }}>Image Upload</Card.Body></Card><br />
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Enter Image name</Form.Label>
                                            <Form.Control
                                                onChange={(e) => setImgName(e.target.value)}
                                                value={getImgName}
                                                type="text"
                                                name="img_uploaded"
                                                disabled={getImgUrl?true:false}
                                            />
                                            <Form.Control.Feedback type="invalid">Image Name is required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Upload Image</Form.Label>
                                            <NewimageUpload imgFolderName={setImgFolder} imageFileName={setImgName} newImage={setImgUrl} />
                                            <a href={getImgUrl} target="_blank">{getImgUrl}</a>

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            {/* <Form.Label >Image URL</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={getImgUrl?getImgUrl:getImgName&&BASE_IMAGE_URL+getImgName+IMAGE_TOKEN}
                                                name="Image URL"
                                            />
                                            <Form.Control.Feedback type="invalid">image required</Form.Control.Feedback> */}
                                            <img style={{width:"300px"}} src={getImgUrl?getImgUrl:BASE_IMAGE_URL+getImgName+IMAGE_TOKEN} />
                                        </Form.Group>
                                    </Form.Row>
                                    {/* <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Image Folder</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={getImgFolder}
                                                name="Image URL"
                                            />
                                            <Form.Control.Feedback type="invalid">*required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row> */}
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Select Series</Form.Label>
                                            <Select
                                                isMulti
                                                defaultValue={ALL_REGIONS_DD}
                                                options={
                                                    ALL_REGIONS_DD
                                                }
                                                className="basic-multi-select"
                                                onChange={(value) => { setDropDownItem(value) }}
                                                classNamePrefix="select"
                                            />
                                            <Form.Control.Feedback type="invalid">*required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>

                                            <Form.Label >Calender Date</Form.Label>
                                            <Col sm={10}>

                                                <input type="date" value={selectDate} name="select_date" onChange={handleSelectDateChange} />
                                            </Col>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Row} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Notification Type</Form.Label>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="Bell"
                                                    value="Bell"
                                                    onChange={changeNotificationType}
                                                    name="notification_type"
                                                    id="notification_type_bell"
                                                    defaultChecked
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Ring"
                                                    value="Ring"
                                                    onChange={changeNotificationType}
                                                    name="notification_type"
                                                    id="notification_type_ring"
                                                />
                                                 
                                            </Col>
                                        </Form.Group>
                                    </Form.Row>
                                    {/* {notificationType == 'Ring' ? <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Row} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Schedule Notification</Form.Label>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    value="yes"
                                                    onChange={changeScheduleNotification}
                                                    name="schedule_notification"
                                                    id="schedule_notification_yes"
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    value="no"
                                                    onChange={changeScheduleNotification}
                                                    name="schedule_notification"
                                                    id="schedule_notification_no"
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Row> : <></>} */}
                                    {notificationType == 'Ring' ? <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }} >
                                        <Form.Group as={Col} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <div>
                                            <input type="checkbox"  id="immediate_notif" name="timings" onChange={handleImmediateNotification}/>
                                        <label style={{ marginLeft: "5px" }} htmlFor="immediate_notif">Immediate</label>
                                            </div>
                                            <Form.Label >Select Timings</Form.Label>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="checkbox" value="9 AM" id="a_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="a_series">9 AM</label>
                                                        </td>

                                                        <td>
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="9:30 AM" id="b_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="b_series">9:30 AM</label>
                                                        </td>

                                                        <td>
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="10 AM" id="c_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="c_series">10 AM</label>
                                                        </td>

                                                        <td>
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="10:30 AM" id="h_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="h_series">10:30 AM</label>
                                                        </td>

                                                        <td>
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="11 AM" id="n_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="n_series">11 AM</label>
                                                        </td>

                                                        <td>
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="11:30 AM" id="p_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="p_series">11:30 AM</label>
                                                        </td>
                                                        <td>
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="12 PM" id="s_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="s_series">12 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="12:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">12:30 PM</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="1 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">1 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="1:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">1:30 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="2 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">2 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="2:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">2:30 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="3 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">3 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="3:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">3:30 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="4 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">4 PM</label>
                                                        </td>

                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="4:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">4:30 PM</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="5 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">5 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="5:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">5:30 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="6 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">6 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="6:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">6:30 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="7 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">7 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="7:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">7:30 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="8 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">8 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="8:30 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">8:30 PM</label>
                                                        </td>
                                                        <td >
                                                            <input style={{ marginLeft: "5px" }} type="checkbox" value="9 PM" id="t_series" name="timings" onChange={handleScheduleTimings} />
                                                            <label style={{ marginLeft: "5px" }} htmlFor="t_series">9 PM</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Form.Group>
                                    </Form.Row> : <></>}
                                    {/* {notificationType == 'Immediate' ? <>
                                    <div>
      <h3>Immediate Notification Options:</h3>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value="Text"
          checked={selectedOption === 'Text'}
          onChange={handleImmediateOptionChange}
        />
        <label className="form-check-label">
          Text
          {selectedOption === 'Text' && (
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="form-control"
              placeholder="Enter phone number for Text message"
            />
          )}
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value="WhatsApp"
          checked={selectedOption === 'WhatsApp'}
          onChange={handleImmediateOptionChange}
        />
        <label className="form-check-label">
          WhatsApp
          {selectedOption === 'WhatsApp' && (
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="form-control"
              placeholder="Enter phone number for WhatsApp"
            />
          )}
        </label>
      </div>
    </div>

              
                                    </>:<></>} */}
                                    {/* <Form.Row style={{ display: "inline-block", width: "700px", textAlign: "center" }}>
                                        <Form.Group as={Row} controlId="validationFormik01" style={{ display: "inline-block" }}>
                                            <Form.Label >Apps</Form.Label>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="Superzop"
                                                    value="com.retranzinfolabs.superzopordering"
                                                    onChange={changeAppsNotification}
                                                    checked={appsNotification === 'com.retranzinfolabs.superzopordering' ? true : false}
                                                    name="apps_notification"
                                                    id="apps_notification_superzop"
                                                />
                                                 <Form.Check
                                                    type="radio"
                                                    label="YoGrocery"
                                                    value="com.retranzinfolabs.superstore"
                                                    checked={appsNotification === 'com.retranzinfolabs.superstore' ? true : false}
                                                    onChange={changeAppsNotification}
                                                    name="apps_notification"
                                                    id="apps_notification_yogrocery"
                                                /> 
                                            </Col>
                                        </Form.Group>
                                    </Form.Row> */}
                                </div>
                                <Button type="submit" variant="danger" disabled= {buttonStatus} style={{ borderRadius: "20px", width: "300px"}}>Submit</Button>
                            </Form></Card.Text></Card.Body>
                </Card>
            </div><br />
            <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
                <FooterComponent />
            </div>
        </>
    )
}

export default CreateNotifications
