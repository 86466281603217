import React,{useEffect,useState} from 'react'
import { Form,Modal,Button,Table } from "react-bootstrap"
import database from '../../service/firebaseConfig';
import { toast } from 'react-toastify';
import { ALL_REGIONS_LIST,RETAILER_REGIONS_DD, TRANSPORT_FACTOR, REGION_ALL, PROD_MASTER_TABLE,LOGGED_USER_EMAIL } from '../../constants/allConstants';
import UpdatedDate from '../../CommonRequirements/UpdatedDate';
import CurrentLoginUser from '../../CommonRequirements/FetchUserEmailId';
import { validateIsAdminUser,validateProductOnlyUser } from '../../service/credentials'
import Select from 'react-select';
import { OFFER_BASE_URL, ORDERING_BASE_URL} from "../../service/servicesConfig";
import axios from 'axios';
import moment from 'moment';

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
}

const  EditSuperchoiceDealerPrice=({itemId,cityName,transportCharges,regionName,DealerPrice,BasePrice,marketPrice,totalpriceValue,actualApmcCharges,offerInd,longDescription,lotSize,retailerMarginPerc,selling_price,selling_price_ex_gst,gross_cost,cost_of_conversion_per,cost_of_wastage_per, pm_cost, secondary_pm_cost, landing_cost, ...props})=> {
    const productDetails = database.database().ref(PROD_MASTER_TABLE)

    const [basePrice, setBasePrice] = useState(BasePrice)
    const [dealerPrice, setDealerPrice] = useState()
    const [priceDetails,setPriceDetails] = useState()
    const [mrp, setMrp] = useState(marketPrice)
    const [gstPercent, setGstPercent] = useState("")
    const [apmcPercent, setApmcPercent] = useState("")
    const [gstCharges, setGstCharges] = useState("")
    const [apmcCharges, setApmcCharges] = useState(actualApmcCharges)
    const [offerIndicator, setOfferIndicator] = useState(offerInd)
    const [dataPush, setDataToDb] = useState(false)
    const [getRetailerMarginPerc, setRetailerMarginPerc] = useState(retailerMarginPerc)
    const [getRetailerMarginPercForOther,setRetailerMarginPercForOther] = useState(retailerMarginPerc);
    const [sellingPrice, setSellingPrice] = useState(0)
    const [sellingPriceWoRounding, setSellingPriceWoRounding] = useState(selling_price?selling_price:0)
    const [sellingPriceExGst, setSellingPriceExGst] = useState(selling_price_ex_gst?selling_price_ex_gst:0)
    const [grossCost, setGrossCost] = useState(gross_cost ? gross_cost :0);
    const [costOfConversionPer, setCostOfConversionPer] = useState(cost_of_conversion_per ? cost_of_conversion_per :0);
    const [costOfWastagePer, setCostOfWastagePer] = useState(cost_of_wastage_per ? cost_of_wastage_per :0);
    const [conversionCost, setConversionCost] =useState(grossCost !== 0 && costOfConversionPer !=0 ? (grossCost * costOfConversionPer) / 100 : 0);
    const [wastageCost, setWastageCost] = useState(grossCost !== 0 && costOfWastagePer !=0 ? (grossCost * costOfWastagePer) / 100 : 0);
    const [pmCost, setPmCost] = useState(pm_cost ? pm_cost :0);
    const [secondaryPmCost, setSecondaryPmCost] = useState(secondary_pm_cost ? pm_cost :0);
    const [landingCost, setLandingCost] = useState(landing_cost ? landing_cost :0); //cost_of_wastage_per, pm_cost
    const regionsValue = ALL_REGIONS_LIST;
    // retailer margins state
    const [getRetailerSeriesMargins, setRetailerSeriesMargins] = useState([]);
    const [getSeriesFromDD,setSeriesForDD] = useState({value:"ALL",label:"ALL"});
    const [dataPushToPriceDetails,setDataPushToPriceDetails] = useState(false)
    const [dataPushToPendingPriceDetails ,setDataPushToPendingPriceDetails] = useState(false);
    
    
    const calculateConversionCost = (grossCost,conversionPer) =>{
        let calVal = (grossCost * conversionPer)/100;
        return parseFloat(calVal.toFixed(2));        ;
    }

    const calculateWastageCost = (grossCost, wastagePer) => {
        let calVal = (grossCost * wastagePer) /100;
        return parseFloat(calVal.toFixed(2));        ;
    }

    const calculateLandingCost = () =>{
        let landing_cost = Number(grossCost) + Number(wastageCost) + Number(conversionCost) + Number(secondaryPmCost);
        landing_cost = parseFloat(landing_cost.toFixed(2));
        setLandingCost(landing_cost);
    }

    const notifyUser = (success) => {
        if (success) {
            return toast("Updated the changes", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't update the changes", { type: toast.TYPE.ERROR });
    };


    const getPriceDetails = () =>{
            database.database().ref(`Price_Details/mumbai/items/${itemId}`).once("value",snapShot=>{
            if(snapShot.hasChildren()){
            console.log(`item id is ${itemId}`)
            const price_details = snapShot.val();
            setPriceDetails(price_details)
            setRetailerMarginPerc(price_details.retailer_margin_per)
            setGstPercent(price_details.gst_per)
            setApmcPercent(price_details.apmc_per)
            // get retailer margins for series
            let itemAllProps = Object.entries(price_details);
            let retailerMargins = itemAllProps.filter(item => item[0].substring(0,20) == "retailer_margin_per_");
            setRetailerSeriesMargins(retailerMargins)
            }
        })
    }

    const dealerPriceValidation = (item_id,dealer_price,callFrom) =>{
        
        const url = `${OFFER_BASE_URL}/product-offer?item_id=${item_id}&dealer_price=${dealer_price}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
        axios.get(url)
       .then(function (response) {
         let isValid =  response.data.data;
         if(isValid) {
            if(callFrom == 'admin'){
                setDataToDb(true);
                setDataPushToPriceDetails(true)
            }else if(callFrom == 'commercial') {
                setDataPushToPendingPriceDetails(true)
            }
         }else {
             return toast("Dealer Price should be greater than offer price.", { type: toast.TYPE.ERROR });
         }
        }).catch(function (error) {
         return false;
       });
    }

    const PushDataToProductsDetails = () =>{
        if(dataPush){
        if(regionName===REGION_ALL && getSeriesFromDD.value =="ALL"){
            let update_count = regionsValue.length;
            regionsValue.forEach(regionsValue=>{
            let dealer_price = dealerPrice;
            let gst_charge = gstCharges;
            let selling_price = sellingPrice;
            
            let prodele =[];
                getRetailerSeriesMargins.map((item) => {
                    let series              = item[0].split("_")[3];
                    let seriesMarginValue   = item[1];
                    prodele[series] = seriesMarginValue;
                    if (series == regionsValue) {
                        selling_price = calSellingPriceForSeries(seriesMarginValue);
                        dealer_price = calDealerPriceForSeries(selling_price);
                        gst_charge = calGstForSeries(dealer_price);
                    }
                });
                let prodARef = productDetails.child(regionsValue).child(itemId);
                prodARef.once('value').then(dataSnapshot => {
                    update_count--;
                    if(dataSnapshot.val()){
                        prodARef.update({
                            dealer_price:dealer_price || "",
                            gst_charge:gst_charge || "",
                            market_price:mrp || "",
                            selling_price:selling_price,
                            selling_price_ex_gst:sellingPriceExGst || "",
                            retailer_margin_per:prodele[regionsValue] !=undefined ? prodele[regionsValue] :getRetailerMarginPerc,
                            gross_cost:grossCost,
                            // primary_pm_cost : pmCost,
                            package_material_cost : secondaryPmCost,
                            conversion_per: costOfConversionPer,
                            wastage_per:costOfWastagePer,
                            wastage:wastageCost,
                            landing_cost:landingCost,
                            conversion:conversionCost,
                            last_updated:UpdatedDate(),
                            updated_by:CurrentLoginUser()
                        })
                    }
                }).then(()=>{
                    // update_count--;
                    if(update_count==0) {
                        notifyUser(true);
                        props.onHide();
                        setTimeout(function(){
                          window.location.reload();
                        }, 2000);
                    };
                })
            })
        }
        else if (getSeriesFromDD.value !="ALL")
        {
            let prodARef = productDetails.child(getSeriesFromDD.value).child(itemId);
            prodARef.once('value').then(dataSnapshot => { 
                if(dataSnapshot.val()){ 
                    prodARef.update({
                        base_price:basePrice || "",
                        dealer_price:dealerPrice || "",
                        gst_charge:gstCharges || "",
                        market_price:mrp || "",
                        selling_price:sellingPrice?("" +sellingPrice):"",
                        selling_price_ex_gst:sellingPriceExGst || "",
                        retailer_margin_per : getRetailerMarginPercForOther,
                        gross_cost:grossCost,
                        // primary_pm_cost : pmCost,
                        package_material_cost: secondaryPmCost,
                        conversion_per: costOfConversionPer,
                        wastage_per:costOfWastagePer,
                        landing_cost:landingCost,
                        conversion:conversionCost,
                        wastage:wastageCost,
                        last_updated:UpdatedDate(),
                        updated_by:CurrentLoginUser()
    
                    }).then(()=>{
                        notifyUser(true);
                        props.onHide();
                        setTimeout(function(){
                            window.location.reload();
                        }, 2000);
                    })
                }
            })
            
        }}
    }

    const writeDataToPendingPriceUpdateDB = () => {
      if(dataPush){
        let currdate=formatDate(new Date());
        let currentYear=currdate.split("-")[2];
        let currentMonth=currdate.split("-")[1];

        let d = new Date(),
    //   currdformat = [d.getDate().padLeft(),
    //             (d.getMonth()+1).padLeft(),
    //              d.getFullYear()].join('-') +' ' +
    //             [d.getHours().padLeft(),
    //              d.getMinutes().padLeft()].join(':');

        currdformat = [
            d.getDate().padLeft(),
            (d.getMonth() + 1).padLeft(),
            d.getFullYear(),
        ].join("-") + 
            " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");

        let prodele={};
        prodele['dealer_price']=dealerPrice || "";
        prodele['base_price']=basePrice || "";
        prodele['apmc_charge']=apmcCharges || "";
        prodele['gst_charge']=gstCharges || "";
        let region_name=regionName!="ALL"?regionName:'A';
        prodele['transport_charge']=""+TRANSPORT_FACTOR[region_name];
        prodele['item_id']=itemId;
        prodele['approved']="Y";
        prodele['gross_cost'] = grossCost;
        // prodele['primary_pm_cost'] = pmCost;
        prodele['package_material_cost'] = secondaryPmCost;
        prodele['conversion_per'] =costOfConversionPer;
        prodele['wastage_per'] = costOfWastagePer;
        prodele['landing_cost'] = landingCost;
        prodele['conversion'] = conversionCost;
        prodele['wastage'] = wastageCost;
        prodele['old_base_price']=""+BasePrice;
        prodele['old_dealer_price']=""+DealerPrice;
        prodele['updated_by']=sessionStorage.getItem("Logged User Email Id");
        prodele['updated_at']=currdformat;
        prodele['approved_by']=sessionStorage.getItem("Logged User Email Id");
        prodele['approved_at']=currdformat;
        prodele['updated_from']="Edit Price";
        // prodele['landing_cost'] = 80;
        // prodele['buying_price_ex_gst'] = 80;
        getRetailerSeriesMargins.map((item) => {
            let series              = item[0];
            let seriesMarginValue   = item[1];
            prodele[series] = seriesMarginValue;
        });
        //if(getSeriesFromDD.value == "ALL"){ 
        prodele["retailer_margin_per"] = getRetailerMarginPerc;
        //} 
        if(getSeriesFromDD.value != "ALL") {
            prodele["retailer_margin_per_"+getSeriesFromDD.value] = getRetailerMarginPercForOther; //getRetailerMarginPerc;
        }

        let productid=itemId;
        let randomtwodigit=Math.floor(Math.random() * 90) + 10;
        let productkey=productid+"-"+randomtwodigit;
        console.log(randomtwodigit);
        console.log(prodele);
        console.log(productid);
        console.log(productkey);

        //save in Pending_Price_Update table
        database.database().ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/${productkey}`).update(prodele)
        .then(() => {
          console.log("Successfully added product price to PendingPriceUpdate.");
        }).catch((error) => {
          console.log("Error in saving price details to PendingPriceUpdate : "+error);
        });
      }

      if(dataPushToPendingPriceDetails) {
        let currdate=formatDate(new Date());
        let currentYear=currdate.split("-")[2];
        let currentMonth=currdate.split("-")[1];

        let d = new Date(),
    //   currdformat = [d.getDate().padLeft(),
    //             (d.getMonth()+1).padLeft(),
    //              d.getFullYear()].join('-') +' ' +
    //             [d.getHours().padLeft(),
    //              d.getMinutes().padLeft()].join(':');

        currdformat = [
            d.getDate().padLeft(),
            (d.getMonth() + 1).padLeft(),
            d.getFullYear(),
        ].join("-") + 
            " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");

        let prodele={};
        prodele['dealer_price']=dealerPrice || "";
        prodele['base_price']=basePrice || "";
        prodele['apmc_charge']=apmcCharges || "";
        prodele['gst_charge']=gstCharges || "";
        let region_name=regionName!="ALL"?regionName:'A';
        prodele['transport_charge']=""+TRANSPORT_FACTOR[region_name];
        prodele['item_id']=itemId;
        prodele['approved']="N";
        prodele['old_base_price']=""+BasePrice;
        prodele['old_dealer_price']=""+DealerPrice;
        prodele['updated_by']=sessionStorage.getItem("Logged User Email Id");
        prodele['updated_at']=currdformat;
        let dealer_price = dealerPrice;
        prodele['gst_charge'] = gstCharges || "";
        prodele['market_price']= mrp || "";
        prodele['selling_price']= sellingPriceWoRounding.toFixed(2) || ""; //sellingPrice?("" +sellingPriceWoRounding):"";
        prodele['selling_price_ex_gst']= sellingPriceExGst || "";
        prodele['dealer_price']= dealer_price || "";
        prodele['gross_cost'] = grossCost;
        // prodele['primary_pm_cost'] = pmCost;
        prodele['package_material_cost'] = secondaryPmCost;
        prodele['conversion_per'] =costOfConversionPer;
        prodele['wastage_per'] = costOfWastagePer;
        prodele['landing_cost'] = landingCost;
        prodele['conversion'] = conversionCost;
        prodele['wastage'] = wastageCost;
        prodele['updated_from']="Edit Dealer Price";
        if(getSeriesFromDD.value == "ALL"){  
            prodele["retailer_margin_per"] = getRetailerMarginPerc;
            getRetailerSeriesMargins.map((item) => {
                let series              = item[0];
                let seriesMarginValue   = item[1];
                prodele[series] = seriesMarginValue;
            });
            
        }else {
            if(getSeriesFromDD.value != "ALL") {
                // prodele["retailer_margin_per"] = getRetailerMarginPerc;
                prodele["retailer_margin_per_"+getSeriesFromDD.value] = getRetailerMarginPercForOther; //getRetailerMarginPerc;
            }
        }
        
        let productid=itemId;
        let randomtwodigit=Math.floor(Math.random() * 90) + 10;
        let productkey=productid+"-"+randomtwodigit;
        console.log(randomtwodigit);
        console.log(prodele);
        console.log(productid);
        console.log(productkey);

        //save in Pending_Price_Update table
        database.database().ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/${productkey}`).update(prodele)
        .then(() => {
          console.log("Successfully added product price to PendingPriceUpdate.");
          notifyUser(true);
          setTimeout(() => {
            props.onHide();
            window.location.reload();
          }, 2000)
        }).catch((error) => {
          console.log("Error in saving price details to PendingPriceUpdate : "+error);
          notifyUser(false);
        });
      }
    }

    const PushDataToPriceDetails = () =>{
        if(dataPushToPriceDetails){
            let price_details_obj = {};
            if(getSeriesFromDD.value == "ALL"){ 
                price_details_obj["retailer_margin_per"] = getRetailerMarginPerc;
                getRetailerSeriesMargins.map((item) => {
                    let series              = item[0];
                    let seriesMarginValue   = item[1];
                    price_details_obj[series] = seriesMarginValue;
                });
            } else {
                console.log("retailer_margin_per_"+getSeriesFromDD.value)
                price_details_obj["retailer_margin_per_"+getSeriesFromDD.value] = getRetailerMarginPercForOther;
            }

            price_details_obj['gross_cost'] = grossCost;
            // price_details_obj['primary_pm_cost'] = pmCost;
            price_details_obj['package_material_cost'] = secondaryPmCost;
            price_details_obj['conversion_per'] =costOfConversionPer;
            price_details_obj['wastage_per'] = costOfWastagePer;
            price_details_obj['wastage'] = wastageCost;
            price_details_obj['landing_cost'] = landingCost;
            price_details_obj['conversion'] = conversionCost;
            price_details_obj['selling_price'] = sellingPriceWoRounding.toFixed(2);
            price_details_obj['selling_price_ex_gst'] = sellingPriceExGst;
            price_details_obj["last_updated"] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //UpdatedDate();
            price_details_obj["updated_by"] = CurrentLoginUser();
            
            //save in Price_Update table
            database.database().ref(`Price_Details/mumbai/items/${itemId}`).update(price_details_obj)
            .then(async () => {
                let url = `${ORDERING_BASE_URL}/api/superzop/admin/price_details/insertpricedetails`;
                price_details_obj['item_id'] = itemId;
                let dataToSubmit = price_details_obj;
                let respData = await axios.post(url, dataToSubmit);
                let resCode = respData.data?.success || 0;   
                if(resCode == 1)      {
                  console.log('Successfully updated price details');
                }else {
                  console.log('Error while updating price details in MySQL');
                }
                console.log("Successfully updated price to Price Details.");
            }).catch((error) => {
            console.log("Error in saving price details to Price Details : "+error);
            notifyUser(false);
            });
        }
    }



    const isValid = (data) => {
        if (data === undefined || data === null || data === "" || data.length === 0) {
            return 0;
        }
        return data;
    };

    const parseToNo = (string) => {
        return parseFloat(isValid(string));
    };

    const getAbsFromPer = (data) => {
        return parseFloat(parseToNo(data) / 100);
    };

    const getRoundNo = (data) => {
        return parseToNo(data).toFixed(2);
    };

    const getRoundNoThree = (data) => {
        return parseToNo(data).toFixed(3);
    };

    const getRoundNoFour = (data) => {
        return parseToNo(data).toFixed(4);
    };

    useEffect(() =>{
        calculateLandingCost();
    },[secondaryPmCost, costOfConversionPer, costOfWastagePer])

    useEffect(() => {
        if(!priceDetails)
            getPriceDetails()
    }, [])

    useEffect(() => {
        if(gstPercent)
            calGstCharges()
    }, [gstPercent])

    useEffect(() => {
        if(dealerPrice)
            calGstCharges()
    }, [dealerPrice])

    useEffect(() => {
    if(dataPush)
        PushDataToProductsDetails()
    }, [dataPush])

    useEffect(() => {
    if(dataPush)
        PushDataToPriceDetails()
        writeDataToPendingPriceUpdateDB()
    }, [dataPush])

    useEffect(()=>{
        if(dataPushToPriceDetails) {
            PushDataToPriceDetails();
        }  
      },[dataPushToPriceDetails]);
      
    useEffect(()=>{
      if(dataPushToPendingPriceDetails) {
        writeDataToPendingPriceUpdateDB();
      }  
    },[dataPushToPendingPriceDetails]);

    useEffect(() => {
      if(getRetailerMarginPerc && mrp) {
        calSellingPrice()
      }
    },[mrp, getRetailerMarginPerc])

    useEffect(() => {
      if(gstPercent && sellingPriceWoRounding) {
        calSellingPriceExGst()
      }
    },[gstPercent, sellingPriceWoRounding])

    const calGstCharges = () => {
        let totalPrice=parseToNo(dealerPrice)
        if((!mrp || parseToNo(mrp) == 0) && !longDescription.replace(/[^A-Z0-9]/ig, "").includes("Atta5kg")) {
            totalPrice = parseToNo(dealerPrice)/parseToNo(lotSize);
        }
        let gst_charge = getAbsFromPer(gstPercent) * totalPrice;
        gst_charge = getRoundNoFour(gst_charge);
        setGstCharges(gst_charge)
    };

    const calGstForSeries = (dealer_price) => {
        let totalPrice=parseToNo(dealer_price)
        if((!mrp || parseToNo(mrp) == 0) && !longDescription.replace(/[^A-Z0-9]/ig, "").includes("Atta5kg")) {
            totalPrice = totalPrice/parseToNo(lotSize);
        }
        let gst_charge = getAbsFromPer(gstPercent) * (totalPrice + parseToNo(transportCharges));
        gst_charge = getRoundNoFour(gst_charge);
        return gst_charge;
    };

    const calSellingPriceForSeries = (retailer_margin) => {
        let sellPriceVal=parseToNo(mrp)/(1+getAbsFromPer(retailer_margin));
        return sellPriceVal;
      }
  
      const calDealerPriceForSeries = (sellPrice) => {
        let sellPriceExGst = parseToNo(sellPrice) / (1 + getAbsFromPer(gstPercent))
        return getRoundNo(sellPriceExGst);
      }


    const calSellingPrice = (val) => {
      let sellPriceVal=parseToNo(mrp)/(1+getAbsFromPer(getRetailerMarginPerc));
      if(val){
        sellPriceVal = parseToNo(mrp)/(1+getAbsFromPer(val))
      }
      setSellingPriceWoRounding(sellPriceVal)
      setSellingPrice(Math.round(sellPriceVal))
    }

    const calSellingPriceExGst = () => {
      let sellPriceExGst = parseToNo(sellingPriceWoRounding) / (1 + getAbsFromPer(gstPercent))
      setSellingPriceExGst(sellPriceExGst.toFixed(2));
      setDealerPrice(sellPriceExGst.toFixed(2))
    }

    const handleSeriesChange = (e) => {
        console.log(e)
        setSeriesForDD(e)
        calSellingPrice(retailerMarginPerc);
        setRetailerMarginPercForOther(retailerMarginPerc);
        getRetailerSeriesMargins.map((val)=>{
            if(val[0].split('_')[3] == e.value){
                setRetailerMarginPercForOther(val[1]);     
                calSellingPrice(val[1]);
            }
        })
        
    }

    const retailerMarginsList = getRetailerSeriesMargins.map((item) => {
        let commonLabel         = 'Retailer Margin %';
        let series              = item[0].split("_")[3];
        let seriesMarginLabel   = commonLabel +" "+ series ;
        let seriesMarginValue   = item[1];
        if(getSeriesFromDD.value =="ALL")
        {
            return (
                <tr>
                    <td>{seriesMarginLabel}</td>
                    <td><Form.Control type="text" defaultValue={seriesMarginValue} onChange={(e)=>{ setDbvals(item[0],e.target.value);}} readonly/></td>
                </tr>
             ) 
        }
        else{
            if(getSeriesFromDD.value == series )
            {

                return (
                    <tr>
                        <td>{commonLabel}</td>
                        <td><Form.Control type="text" defaultValue={seriesMarginValue?seriesMarginValue:getRetailerMarginPercForOther} onChange={(e)=>{ setDbvals(item[0],e.target.value);}}  /></td>
                    </tr>
                 ) 

            }
            
        }
         
    })

    const generateRetKeys = () => {
        if(getSeriesFromDD.value !== "ALL"){
            let retSeriesKey = ""

             retSeriesKey = `retailer_margin_per_`+getSeriesFromDD.value
             return retSeriesKey
        }
        
    }

    let availableRetMargins = getRetailerSeriesMargins.filter((item) =>{ 
        return item[0] == generateRetKeys()
    })
    

const setDbvals = (key,val) => {

    console.log(key)
    console.log(val)
    if((getSeriesFromDD.value == "ALL" && key == undefined)){
        setRetailerMarginPerc(val)
        // setRetailerMarginPercForOther(val)
        calSellingPrice(val)
    } else if(getSeriesFromDD.value != "ALL") {
        setRetailerMarginPercForOther(val)
        calSellingPrice(val)
    }else if (key != undefined) {
        let newRetailerMargin = [...getRetailerSeriesMargins];
        getRetailerSeriesMargins.map((item,index) => {
            let series              = item[0];
            if (series == key) {
                newRetailerMargin[index][1] = val;
                console.log(newRetailerMargin)
            }
            setRetailerSeriesMargins(newRetailerMargin)
        });
    }

}


    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Edit Dealer Price
        </Modal.Title>
        </Modal.Header>
        <Modal.Body >
                <Table>
                    <tr>
                        <td>Select Series</td>
                        <Select
                        options = {RETAILER_REGIONS_DD}
                        defaultValue ={RETAILER_REGIONS_DD[0]}
                        onChange = {handleSeriesChange}
                        
                        >

                        </Select>
                    </tr>
                     {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (<tr> 
                        <td>MRP</td>
                        <td><Form.Control type="text" defaultValue={mrp}  onChange={(e)=>setMrp(e.target.value)} disabled={true} /></td>
                    </tr>) :  (<tr> 
                        <td>MRP</td>
                        <td><Form.Control type="text" defaultValue={mrp}  onChange={(e)=>setMrp(e.target.value)} disabled={true}/></td>
                    </tr>))}
                    {(availableRetMargins.length == 0) && (
                    <tr>
                    <td>Retailer Margin %</td>
                    <td><Form.Control type="text" value={getSeriesFromDD.value == 'ALL' ? getRetailerMarginPerc : getRetailerMarginPercForOther}  onChange={(e)=>{ setDbvals(generateRetKeys(),e.target.value);  }} readonly/></td>
                    </tr>)}
                    { retailerMarginsList}
                    <tr>
                        <td>GST%</td>
                        <td><Form.Control type="text" defaultValue={gstPercent} readOnly onChange={(e)=>setGstPercent(e.target.value)}/></td>
                    </tr>
                    <tr>
                        <td>GST charges</td>
                        <td><Form.Control type="text" value={gstPercent && gstCharges} readOnly/></td>
                    </tr>
                    <tr>
                        <td>Selling Price</td>
                        <td><Form.Control type="text" value={sellingPrice && sellingPriceWoRounding.toFixed(2)} readOnly /></td>
                    </tr>

                    <tr>
                        <td>Selling Price Ex Gst (Dealer Price)</td>
                        <td><Form.Control type="text" value={sellingPriceExGst && sellingPriceExGst} readOnly /></td>
                    </tr>

                    <tr>
                        <td>Gross Cost</td>
                        <td><Form.Control type="text" value={gross_cost && gross_cost} readOnly /></td>
                    </tr>    

                    <tr>
                        <td>Labour Cost of Conversion %</td>
                        <td><Form.Control type="text" value={costOfConversionPer && costOfConversionPer}  onChange={
                            (e) => {
                                setCostOfConversionPer(e.target.value);
                                setConversionCost(calculateConversionCost(grossCost, e.target.value));
                            }
                            } /></td>
                    </tr>

                    <tr>
                        <td>Conversion Cost</td>
                        <td><Form.Control type="text" value={conversionCost !=0 ? parseFloat(conversionCost).toFixed(2):0} readOnly /></td>
                    </tr>

                    <tr>
                        <td>Secondary PM Cost</td>
                        <td><Form.Control type="text" value={secondaryPmCost !=0 ? secondaryPmCost : 0} onChange={(e) => setSecondaryPmCost(e.target.value)} /></td>
                    </tr>

                    <tr>
                        <td>Wastage %</td>
                        <td><Form.Control type="text" value={costOfWastagePer && costOfWastagePer} onChange={
                            (e) => {
                                setCostOfWastagePer(e.target.value)
                                setWastageCost(calculateWastageCost(grossCost, e.target.value));
                            }
                            } /></td>
                    </tr>

                     <tr>
                        <td>Wastage Cost</td>
                        <td><Form.Control type="text" value={wastageCost!=0 ? (wastageCost).toFixed(2) : 0} readOnly /></td>
                    </tr>

                    <tr>
                        <td>Landing Cost</td>
                        <td><Form.Control type="text" value={landingCost !=0 ? landingCost : 0} readOnly /></td>
                    </tr>
                </Table>
        </Modal.Body>
        <Modal.Footer>
        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? <Button variant="success" onClick={()=>{dealerPriceValidation(itemId,sellingPriceExGst,'admin')}} >Submit form</Button>
        :<Button variant="success" onClick={()=>{dealerPriceValidation(itemId,sellingPriceExGst,'commercial')}} >Submit form</Button>
        )}
        <Button onClick={()=>{props.onHide()}}>Close</Button>
        </Modal.Footer>
    </Modal>
    );
}
export default EditSuperchoiceDealerPrice