import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between; 
`;

export const BulkUploadContainer = styled.div`
    margin: 8rem;
    height: 100vh;
    padding: 2rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.2), 0px -1px 1px 1px rgba(0, 0, 0, 0.1);

    .req_symb {
        color: #FF0000;
    }

    div {
        text-align: center;
        padding: 1rem 1rem;
    }

    h6 {
        display: inline-block;
        text-decoration: underline;
        min-width: 94%;
        max-width: 94%;
    }

        Button {
        margin-top: 10px;
        font-size: 1.0rem;
        display: inline-block;
        padding: 0.4rem 0.8rem;
        border-radius: 0.4rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
        
        &:hover {
            cursor: pointer;
            font-weight: bold;
            box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
        }
    }

    header {
        text-align: center;
        h3 {
            display: inline-block;
            min-width: 75%;
            max-width: 75%;
        }

        button {
            font-size: 1.5rem;
            margin-right: 30px;
            display: inline-block;
            padding: 0.4rem 0.8rem;
            border-radius: 0.4rem;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:hover {
                cursor: pointer;
                font-weight: bold;
                box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }

        span {
            font-size: 1.5rem;
            display: inline-block;
            padding: 0.4rem 0.8rem;
            border-radius: 0.4rem;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:hover {
                cursor: pointer;
                font-weight: bold;
                box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }      



    }
`;

export const TabContainer = styled.div`
    // background: #cecece;
    margin: 2rem auto;
    padding: 1rem;

    .leftPart_items {
        margin: 0.5rem 0rem;
    }
    
    .rightPart_items {
        background: whitesmoke;
        
        h6 {
            font-size: 1.1rem;
            word-spacing: 0.15rem;
            letter-spacing: 0.05rem;         
        }

        input {
            padding: 0.5rem;
            margin: 0.5rem;
            outline: none;
            border-radius: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        button {
            margin: 0.5rem auto;
        }
    }
`;