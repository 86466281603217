import React ,{useState,useEffect}from 'react'
import { Form,Modal,Button, Table } from 'react-bootstrap'
import axios from 'axios'
import { OFFER_BASE_URL} from "../../service/servicesConfig";

function DeleteItem({...props}){
    console.log(props)
 const [deletedItem, setDeletedItem] = useState("")
 console.log(deletedItem)

 const updateDeletedItem = () => {
    // debugger;
    const url = `${OFFER_BASE_URL}/stock-management?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
    axios.delete(url, {
        data:{

            item_id: props.itemIdToEdit
        }
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
}


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                   Are you sure want to Delete this item {props.itemIdToEdit}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() =>{updateDeletedItem(); props.deleteItem(true);props.onHide()}} > Delete Item</Button>
                <Button onClick={props.onHide} >Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default DeleteItem;