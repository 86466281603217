import { Button, Center, Modal, Text } from "@mantine/core";
import React from "react";

function DeleteWidgetConfirmation(props) {
  const { modalOpened, setModalOpened, handleOnDeleteConfirmed } = props;
  return (
    <Modal opened={modalOpened} onClose={() => setModalOpened(false)}>
      <Center>
        <Text ta="center" style={{ color: "red", fontSize: "1rem" }} fw={800}>
          Are you sure you want to delete this widget?
        </Text>
      </Center>
      <br />
      <Center>
        <Button
          color="red"
          onClick={() => {
            setModalOpened(false);
            handleOnDeleteConfirmed();
          }}
          style={{ margin: ".7rem" }}
        >
          {" "}
          Confirm
        </Button>
        <Button
          color="teal"
          onClick={() => {
            setModalOpened(false);
          }}
          style={{ margin: ".7rem" }}
        >
          {" "}
          Cancel
        </Button>
      </Center>
    </Modal>
  );
}

export default DeleteWidgetConfirmation;
