import React, { useContext } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import styled from "styled-components";
import { Center, Flex, Grid, Image, SimpleGrid, Text } from "@mantine/core";
import { IconChevronsRight, IconPlus } from "@tabler/icons-react";
import { ProductMasterContext } from "../../../../context/AppHomeScreen/ProductMasterContext";
import { COMMOM_BACKGROUND_STYLES } from "../../styles/styles";

const CardStyles = styled(Card)`
  position: relative;
  overflow: visible !important;
  background-color: transparent;
  border-radius: 0;
  width: 10rem !important;
`;

export function CardListUIForType({
  type,
  data,
  productsListByCategory,
  productsListByBrand,
}) {
  const category = data.text ? data.text.toUpperCase() : "";
  const prodsByCategory = productsListByCategory[category] || [];
  const widgetData = data.data;

  if (!type) return <></>;

  switch (type) {
    case "BRAND":
      return (
        <React.Fragment>
          <Flex
            direction="row"
            justify="space-between"
            align="flex-end"
            style={{
              width: "100%",
              backgroundColor: "white",
              marginBottom: "4px",
            }}
          >
            <Flex direction="row" align="center" gap=".5rem">
              <Text style={{ textAlign: "left" }} fw="600" fz="xs">
                {data.text}
              </Text>
            </Flex>
          </Flex>
          <Flex
            gap="xl"
            direction="row"
            align={"center"}
            style={{
              overflow: "scroll",
              ...COMMOM_BACKGROUND_STYLES(data),
            }}
          >
            {widgetData.map((item, index) => {
              return (
                <div
                  style={{
                    height: "10rem",
                    overflow: "visible",
                  }}
                >
                  <CardStyles raised={true}>
                    <Center>
                      <CardMedia
                        sx={{
                          height: 150,
                          width: "100%",
                          padding: 0,
                        }}
                        image={item.brand_image}
                        style={{ backgroundSize: "cover" }}
                      />
                    </Center>
                  </CardStyles>
                </div>
              );
            })}
          </Flex>
        </React.Fragment>
      );

    default:
      return null;
  }
}

function CardListPreviewWidget({ data }) {
  const { state } = useContext(ProductMasterContext);

  return (
    <>
      <CardListUIForType
        data={data}
        type={data.type}
        productsListByCategory={state.productsListByCategory}
        productsListByBrand={state.productsListByBrand}
      />
    </>
  );
}

export default CardListPreviewWidget;
