import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Table } from "react-bootstrap";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import {
  CITY_MUMBAI,
  CITY_PUNE,
  CITY_ALL,
  TRANSPORT_FACTOR,
  REGION_ALL,
  PROD_MASTER_TABLE,
  ALL_REGIONS_LIST,
} from "../../constants/allConstants";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import CurrentLoginUser from "../../CommonRequirements/FetchUserEmailId";
import { ORDERING_BASE_URL} from "../../service/servicesConfig";
import axios from 'axios';
import moment from 'moment';
import {
  validateIsAdminUser,
  validateProductOnlyUser,
} from "../../service/credentials";

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

const QuickEdit = ({
  itemId,
  cityName,
  transportCharges,
  regionName,
  DealerPrice,
  BasePrice,
  marketPrice,
  totalpriceValue,
  actualApmcCharges,
  offerInd,
  longDescription,
  lotSize,
  retailerMarginPerc,
  ...props
}) => {
  const productDetails = database.database().ref(PROD_MASTER_TABLE);

  const [basePrice, setBasePrice] = useState(BasePrice);
  const [dealerPrice, setDealerPrice] = useState(DealerPrice);
  const [mrp, setMrp] = useState(marketPrice);
  const [gstPercent, setGstPercent] = useState("");
  const [apmcPercent, setApmcPercent] = useState("");
  const [gstCharges, setGstCharges] = useState("");
  const [apmcCharges, setApmcCharges] = useState(actualApmcCharges);
  const [offerIndicator, setOfferIndicator] = useState(offerInd);
  const [dataPush, setDataToDb] = useState(false);

  const notifyUser = (success) => {
    if (success) {
      return toast("Updated the changes", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't update the changes", { type: toast.TYPE.ERROR });
  };

  const notifyUserWithMessage = (success, message) => {
    if (!message) {
      return console.log("please pass message arguement");
    }
    if (success) {
      return toast(message, {
        type: toast.TYPE.SUCCESS,
        style: { width: "100px", color: "red" },
      });
    }
    return toast(message, { type: toast.TYPE.ERROR });
  };

  const getPriceDetails = () => {
    database
      .database()
      .ref(`Price_Details/mumbai/items/${itemId}`)
      .once("value", (snapShot) => {
        if (snapShot.hasChildren()) {
          console.log(`item id is ${itemId}`);
          const price_details = snapShot.val();
          console.log(price_details);
          setGstPercent(price_details.gst_per);
          setApmcPercent(price_details.apmc_per);
        }
      });
  };
  const regionsValue = ["A", "B", "K", "PG", "NK", "ST", "N", "H", "P", "T"];

  const PushDataToProductsDetails = () => {
    if (dataPush) {
      if (regionName === REGION_ALL) {
        ALL_REGIONS_LIST.forEach((regionsValue, index) => {
          productDetails
            .child(regionsValue)
            .child(itemId)
            .once("value", (snapshot) => {
              if (snapshot.hasChildren()) {
                console.log(`data updated in ${regionsValue}`);
                productDetails
                  .child(regionsValue)
                  .child(itemId)
                  .update({
                    base_price: basePrice || "",
                    dealer_price: dealerPrice || "",
                    gst_charge: gstCharges || "",
                    market_price: mrp || "",
                    offer_indicator: offerIndicator || "",
                    last_updated: UpdatedDate(),
                    updated_by: CurrentLoginUser(),
                  })
                  .then(() => {
                    console.log("Updated for the region:: " + regionsValue);
                    notifyUserWithMessage(
                      true,
                      "Updated for the region:: " + regionsValue
                    );
                  });
              } else {
                console.log("Product not available:: " + regionsValue);
                notifyUserWithMessage(
                  false,
                  "Product not available:: " + regionsValue
                );
              }
            });
        });
      } else {
        productDetails
          .child(regionName)
          .child(itemId)
          .update({
            base_price: basePrice || "",
            dealer_price: dealerPrice || "",
            gst_charge: gstCharges || "",
            market_price: mrp || "",
            offer_indicator: offerIndicator || "",
            last_updated: UpdatedDate(),
            updated_by: CurrentLoginUser(),
          })
          .then(() => {
            notifyUser(true);
            window.location.reload();
          });
      }
    }
  };

  const writeDataToPendingPriceUpdateDB = () => {
    if (dataPush) {
      let currdate = formatDate(new Date());
      let currentYear = currdate.split("-")[2];
      let currentMonth = currdate.split("-")[1];

      let d = new Date(),
        // currdformat =
        //   [
        //     d.getDate().padLeft(),
        //     (d.getMonth() + 1).padLeft(),
        //     d.getFullYear(),
        //   ].join("-") +
        //   " " +
        //   [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":");
        currdformat = [
          d.getDate().padLeft(),
          (d.getMonth() + 1).padLeft(),
          d.getFullYear(),
          ].join("-") + 
              " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");

      let prodele = {};
      prodele["dealer_price"] = dealerPrice || "";
      prodele["base_price"] = basePrice || "";
      prodele["apmc_charge"] = apmcCharges || "";
      prodele["gst_charge"] = gstCharges || "";
      let region_name = regionName != "ALL" ? regionName : "A";
      prodele["transport_charge"] = "" + TransportFactor[region_name];
      prodele["item_id"] = itemId;
      prodele["approved"] = "Y";
      prodele["old_base_price"] = "" + BasePrice;
      prodele["old_dealer_price"] = "" + DealerPrice;
      prodele["updated_by"] = sessionStorage.getItem("Logged User Email Id");
      prodele["updated_at"] = currdformat;
      prodele["approved_by"] = sessionStorage.getItem("Logged User Email Id");
      prodele["approved_at"] = currdformat;
      prodele["updated_from"] = "Edit Price";

      let productid = itemId;
      let randomtwodigit = Math.floor(Math.random() * 90) + 10;
      let productkey = productid + "-" + randomtwodigit;
      console.log(randomtwodigit);
      console.log(prodele);
      console.log(productid);
      console.log(productkey);

      //save in Pending_Price_Update table
      database
        .database()
        .ref(
          `Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/${productkey}`
        )
        .update(prodele)
        .then(() => {
          console.log(
            "Successfully added product price to PendingPriceUpdate."
          );
        })
        .catch((error) => {
          console.log(
            "Error in saving price details to PendingPriceUpdate : " + error
          );
        });
    }
  };

  const PushDataToPriceDetails = () => {
    if (dataPush) {
      if (regionName !== "P") {
        database
          .database()
          .ref(`Price_Details/mumbai/items/${itemId}`)
          .update({
            base_price: basePrice || "",
            apmc_per: apmcPercent || "",
            gst_per: gstPercent || "",
            last_updated: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), //UpdatedDate(),
            updated_by: CurrentLoginUser(),
          })
          .then(async () => {
            let url = `${ORDERING_BASE_URL}/api/superzop/admin/price_details/insertpricedetails`;;
            let dataToSubmit = {
              base_price: basePrice || "",
              apmc_per: apmcPercent || "",
              gst_per: gstPercent || "",
              item_id:itemId,
              last_updated: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), //UpdatedDate(),
              updated_by: CurrentLoginUser(),
            };
            let respData = await axios.post(url, dataToSubmit);
            let resCode = respData.data?.success || 0;
            if(resCode == 1){
              notifyUser(true);
            }else {
              notifyUser(false);
            }
            
            props.onHide();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      }
    }
  };

  const TransportFactor = {
    A: "1.00",
    K: "1.00",
    B: "1.50",
    N: "1.00",
    PG: "1.00",
    NK: "2.00",
    ST: "1.00",
    P: "1.00",
    H: "1.00",
    T: "1.00",
  };

  const isValid = (data) => {
    if (
      data === undefined ||
      data === null ||
      data === "" ||
      data.length === 0
    ) {
      return 0;
    }
    return data;
  };

  const parseToNo = (string) => {
    return parseFloat(isValid(string));
  };

  const getAbsFromPer = (data) => {
    return parseFloat(parseToNo(data) / 100);
  };

  const getRoundNo = (data) => {
    return parseToNo(data).toFixed(2);
  };

  const getRoundNoThree = (data) => {
    return parseToNo(data).toFixed(3);
  };

  const getRoundNoFour = (data) => {
    return parseToNo(data).toFixed(4);
  };

  

  const calApmcCharges = () => {
    if (basePrice !== "") {
      let apmc_charge = getAbsFromPer(apmcPercent) * parseToNo(basePrice);
      apmc_charge = getRoundNoThree(apmc_charge);
      setApmcCharges(apmc_charge);
    } else {
      setApmcCharges(apmcCharges);
    }
  };

  const calGstCharges = () => {
    let totalPrice = parseToNo(dealerPrice);
    if (
      (!mrp || parseToNo(mrp) == 0) &&
      !longDescription.replace(/[^A-Z0-9]/gi, "").includes("Atta5kg")
    ) {
      totalPrice = parseToNo(dealerPrice) / parseToNo(lotSize);
    }
    let gst_charge =
      getAbsFromPer(gstPercent) *
      (totalPrice + parseToNo(apmcCharges) + parseToNo(transportCharges));
    gst_charge = getRoundNoFour(gst_charge);
    setGstCharges(gst_charge);
  };

  useEffect(() => {
    getPriceDetails();
  }, []);

  useEffect(() => {
    if (apmcPercent !== "") {
      calApmcCharges();
    }
  }, [apmcPercent]);

  useEffect(() => {
    if (gstPercent) calGstCharges();
  }, [gstPercent]);

  useEffect(() => {
    if (dealerPrice) calGstCharges();
  }, [dealerPrice]);

  useEffect(() => {
    if (dataPush) PushDataToProductsDetails();
  }, [dataPush]);

  useEffect(() => {
    if (dataPush) PushDataToPriceDetails();
    writeDataToPendingPriceUpdateDB();
  }, [dataPush]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Dealer Price
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <tr>
            <td>Base Price</td>
            <td>
              {" "}
              <Form.Control
                type="text"
                defaultValue={basePrice}
                readOnly
                onChange={(e) => setBasePrice(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Dealer Price</td>
            <td>
              {" "}
              <Form.Control
                type="text"
                defaultValue={dealerPrice}
                onChange={(e) => {
                  setDealerPrice(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>MRP</td>
            <td>
              <Form.Control
                type="text"
                defaultValue={mrp}
                onChange={(e) => setMrp(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>APMC%</td>
            <td>
              <Form.Control
                type="text"
                defaultValue={apmcPercent}
                readOnly
                onChange={(e) => setApmcPercent(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>GST%</td>
            <td>
              <Form.Control
                type="text"
                defaultValue={gstPercent}
                readOnly
                onChange={(e) => setGstPercent(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>APMC charges</td>
            <td>
              <Form.Control
                type="text"
                value={apmcPercent && apmcCharges}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>GST charges</td>
            <td>
              <Form.Control
                type="text"
                value={gstPercent && gstCharges}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>Offer Indicator</td>
            <td>
              <Form.Control
                type="text"
                defaultValue={offerIndicator}
                onChange={(e) => setOfferIndicator(e.target.value)}
              />
            </td>
          </tr>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => {
            setDataToDb(true);
          }}
        >
          Submit form
        </Button>
        <Button
          onClick={() => {
            props.onHide();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default QuickEdit;