import React from 'react';


const TestSampleData = ({productkey,testSampleDetails}) => {
    console.log(testSampleDetails);
    console.log(productkey);

    const TestSampleTableHeader = () => {
        let testheader={
          "grain_length1":"< 2mm",
          "grain_length2":"2 - 4mm",
          "grain_length3":"4 - 8mm",
          "grain_length4":"> 8mm"
        };

        return (
            <thead className="thead-light">
                <tr>
                  <th>Length</th>
                  <th>{testheader.grain_length1}</th>
                  <th>{testheader.grain_length2}m</th>
                  <th>{testheader.grain_length3}</th>
                  <th>{testheader.grain_length4}</th>
                  <th>Broken %</th>
                  <th>Length</th>
                  <th>Width</th>
                  <th>LW Ratio</th>
                  <th>TestDate</th>
                  <th>TestType</th>
                </tr>
            </thead>
        );
    }

    const TestSampleTableBody = () => {
      let rows=[];
      if (testSampleDetails) {
          rows = Object.keys(testSampleDetails).map((key, index) => {
            return (
                <tr key={index}>
                    <td>Percentage</td>
                    <td>{testSampleDetails[index].grain_length1_percentage}</td>
                    <td>{testSampleDetails[index].grain_length2_percentage}</td>
                    <td>{testSampleDetails[index].grain_length3_percentage}</td>
                    <td>{testSampleDetails[index].grain_length4_percentage}</td>
                    <td>{testSampleDetails[index].broken_grain_percentage}</td>
                    <td>{testSampleDetails[index].length}</td>
                    <td>{testSampleDetails[index].width}</td>
                    <td>{testSampleDetails[index].lwratio}</td>
                    <td>{testSampleDetails[index].date}</td>
                    <td>{testSampleDetails[index].test_type}</td>
                </tr>
            );
          });
      } else {
          rows = [];
      }
      return (<><tbody>{rows}</tbody></>);
    };

    return (<>
      <center><h5>Test Samples</h5></center>
      {(testSampleDetails.length>0) ? (<table className="table table-striped">
          <TestSampleTableHeader testSampleDetails={testSampleDetails} />
          <TestSampleTableBody testSampleDetails={testSampleDetails} />
      </table>) : (<div>No test smaples available</div>)}
      </>
    );
};

export default TestSampleData;
