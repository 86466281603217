import React, { createContext, useContext, useReducer, useState } from "react";
import firebase from "../../service/firebaseConfig";

export const ProductMasterContext = createContext();

export const ProductMasterProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const contextReducer = (state, action) => {
    switch (action.type) {
      case "populateProdMaster": {
        return {
          ...state,
          productsListByCategory: action.payload.productsListByCategory,
          productsListBySubCategory: action.payload.productsListBySubCategory,
          allItemsById: action.payload.allItemsById,
        };
      }

      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const initialState = {
    productsListByCategory: {},
    productsListBySubCategory: {},
    productsListByBrand: {},
    allItemsById: {},
  };

  const [state, dispatch] = useReducer(contextReducer, initialState);

  const populateProductMasterData = (series = "A") => {
    firebase
      .database()
      .ref(`Product_Master_New/${series}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.hasChildren()) {
          let results = snapshot.val();
          const allItemsById = {};

          Object.keys(results).forEach((itemKey) => {
            allItemsById[itemKey] = results[itemKey];
          });

          // console.log("check received data", allItemsById);

          const { prodsByCategory, prodsBySubCategory, prodsByBrand } =
            getProductsByCategories(results);
          dispatch({
            type: "populateProdMaster",
            payload: {
              productsListByCategory: prodsByCategory,
              productsListBySubCategory: prodsBySubCategory,
              productsListByBrand: prodsByBrand,
              allItemsById,
            },
          });
          setError(null);
        } else {
          dispatch({
            type: "populateProdMaster",
            payload: {
              productsListByCategory: {},
              productsListBySubCategory: {},
              productsListByBrand: {},
              allItemsById: {},
            },
          });
          setError("Unable to retrive prod master data.");
        }
      })
      .catch((err) => {
        setError(`Unable to retrieve prod master data.`);
        console.log(err);
      });
  };

  return (
    <ProductMasterContext.Provider
      value={{
        state,
        populateProdMasterData: populateProductMasterData,
      }}
    >
      {children}
    </ProductMasterContext.Provider>
  );
};

function getProductsByCategories(prod_master_data) {
  const prodsByCategory = {};
  const prodsBySubCategory = {};
  const prodsByBrand = {};

  Object.keys(prod_master_data).forEach((key, index) => {
    const item = prod_master_data[key];
    const category = item.category;
    const sub_category = item.sub_category;
    const brand = item.brand;

    if (!category) return;
    if (category in prodsByCategory) {
      if (prodsByCategory[category].length < 11)
        prodsByCategory[category].push(item);
    } else prodsByCategory[category] = [item];

    if (!sub_category) return;
    if (sub_category in prodsBySubCategory) {
      if (prodsBySubCategory[sub_category].length < 11)
        prodsBySubCategory[sub_category].push(item);
    } else prodsBySubCategory[sub_category] = [item];

    if (!brand) return;
    if (brand in prodsByBrand) {
      if (prodsByBrand[brand].length < 11) prodsByBrand[brand].push(item);
    } else prodsByBrand[brand] = [item];
  });

  console.log("check prod By brand", prodsByBrand);
  return {
    prodsByCategory,
    prodsBySubCategory,
    prodsByBrand,
  };
}
