import {SESSION_AUTHENTICATED_EMAIL} from '../constants/allConstants'

const  FetchUserEmailId=()=> {

    const GetCurrentUser = sessionStorage.getItem(SESSION_AUTHENTICATED_EMAIL);
    
    return GetCurrentUser
}

export default FetchUserEmailId
