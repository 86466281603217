import React, { Component,useState,useRef,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import UpdateGrtDetailsModal from './UpdateGrtDetails'
import './modal.css';

const GrtListTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr >
                <th>Sl No.</th>
                <th>Sample ID</th>
                <th>Testing Date</th>
                <th>Test Type</th>
                <th>Category</th>
                <th>Item Code</th>
                <th>Item Name</th>
                <th>Sub Category</th>
                <th>Base Price</th>
                <th>Supplier</th>
                <th>Index Score</th>
                <th>Result</th>
            </tr>
        </thead>
    );

}

const GrtListTableBody = props => {
    const [updateGrtDetails, setUpdateGrtDetails] = useState(false)
    const [grtDetails, setGrtDetails] = useState("")
    let rows=[];

    if(props.productDetailsListData) {
        rows=props.productDetailsListData.map((row, index) => {
            return (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td><Button onClick={()=>{setUpdateGrtDetails(true);setGrtDetails(row)}}>{row['Sample ID']}</Button></td>
                    <td>{row['Testing Date']}</td>
                    <td>{row['Test Type']}</td>
                    <td>{row['Category'].toUpperCase()}</td>
                    <td>{row['Product Code']}</td>
                    <td>{row['Product Name']}</td>
                    <td>{row['sub_category']}</td>
                    <td>{row['base_price']}</td>
                    <td>{row['supplier_name']}</td>
                    <td>{row['Index Score']}</td>
                    <td>{row['Result']}</td>
                </tr>
            );
        });
      }else{
        rows=[];
      }

    return (<><tbody>{rows}</tbody>
      {updateGrtDetails &&<UpdateGrtDetailsModal
                show={updateGrtDetails}
                onHide={() => setUpdateGrtDetails(false)}
                updateDetails = {grtDetails}
            />}
      </>);
}

const GrtListTable = (props) => {
    const { productDetailsListData } = props;
    return (
        <Table striped bordered style={{margin:30, width:'90%', marginLeft: "auto",marginRight: "auto"}}>
            <GrtListTableHeader />
            <GrtListTableBody productDetailsListData={productDetailsListData} />
        </Table>
    );
}

export default GrtListTable
