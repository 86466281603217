import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  Stack,
  Select,
  TextInput,
  FileInput,
  Divider,
  Text,
  Table,
  Box,
  ScrollArea,
  Flex,
  NumberInput,
  ActionIcon,
  Tooltip,
  MultiSelect,
  
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { toast } from "react-toastify";
import axios from "axios";
import UploadImage from "./ImageUpload";
import { ImageOverlay } from "./ItemsView";
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";

import moment from "moment";
import database from '../../service/firebaseConfig';
import styled from "styled-components";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { main } from "@popperjs/core";

const InlineTextStyles = styled.div`
  text-align: center;
  .mantine-Text-root {
    display: inline;
  }
`;

export function CreateWorkOrderDrawer({

  opened,
  setOpened,
  refreshListData,
  userName
}) {
  
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [workOrderData, setWorkOrderData] = useState({
    description: '',
    type: '',
    sub_type: '',
    approved_by: '', 
    created_by: userName,
    status: 'open',
    due_date:'',
    total_amt: 0,
    item: [
      {
        id: 1,
        item_description: '',
        image_url: '',
        total_cost: 0,
        rate_per_hour: 0,
        hours_worked: 0
      },
      
    ],
    supplier_id: '',
  supplier_name: '', 

  });

  const typeData = {
    'Machine Servicing': ['BOPT', 'Sealing', 'Stitching', 'Batter', 'RPC'],
    'Stationery': ['Paper', 'Other Stationery'],
    'Packing Material': ['Brown Bags', '10 Kg Bags', 'Others', 'Labels'],
    'Rent': ['Warehouse/Depot', 'HO', 'Blr Office'],
    'Water': ['Drinking', 'Tanker'],
    'Internet': ['Warehouse/Depot', 'HO'],
    'Accommodation': ['Hotel Booking', 'Food Expenses'],
    'Pest Control': ['Fumigation', 'Others'],
    'Printers': ['Rent', 'Servicing', 'Cartridge/Ink'],
    'Maintenance': ['Electricity', 'Plumbing', 'Furniture', 'Fixtures', 'Cleaning'],
    'Marketing Material': ['Banners', 'Pamphlets'],
    'Refreshments': ['Tea/Coffee', 'Snacks'],
    'Free Products': ['Jute Bag', 'Others'],
    'Transportation': ['Last Mile Transport', 'Depot Transfers'],
    'Labour': ['Warehouse Labour', 'Depot Labour', 'Mathadi'],
    'Security': ['Security Salary'],
  };

  const approverData = [
    { value: 'amresh@superzop.com', label: 'Amresh' },
    { value: 'darshan@superzop.com', label: 'Darshan' },
    { value: 'prithwi@superzop.com', label: 'Prithwi' },
    { value: 'raghu@superzop.com', label: 'Raghu' }
  ];

  const [suppliersListData,setSuppliersListData] = useState([]);
  const [selectedSupplier,setSelectedSupplier] = useState("");
  const [supplierID,setSupplierID] = useState("");
 

   
   const handleTypeChange = (value) => {
    setWorkOrderData({ ...workOrderData, type: value, });
  };

 
  const handleSubtypeChange = (value) => {
    setWorkOrderData({ ...workOrderData, sub_type: value });
  };

  
  const handleDueDate = (value) => {
    let dateVal = moment(value).format('YYYY-MM-DD');
    setWorkOrderData({ ...workOrderData, due_date:value});
  };

  
  const handleApproverChange = (value) => {
    setWorkOrderData({ ...workOrderData, approved_by: value });
  };

 
  const calculateTotalAmt = (items) => {
  
   return items.reduce((total, item) => total + item.total_cost, 0);
  };

 // Event handler to update formData when any field changes
 const handleFieldChange = (fieldName, value, index = null) => {
  if (index !== null) {
    // Handle changes for fields within the 'items' array
    const updatedItems = [...workOrderData.item];
    updatedItems[index] = {
      ...updatedItems[index],
      [fieldName]: value,
    };

    if (workOrderData.type === 'Labour' && (fieldName === 'rate_per_hour' || fieldName === 'hours_worked')) {
      updatedItems[index] = {
        ...updatedItems[index],
        total_cost: updatedItems[index].rate_per_hour * updatedItems[index].hours_worked,
      };
    }
    // for total amt dynamic calc
    
    const newTotalAmt = calculateTotalAmt(updatedItems);

    setWorkOrderData({
      ...workOrderData,
      item: updatedItems,
      total_amt: newTotalAmt
    });
  } else {
    // Handle changes for top-level fields
    // resetting fields as its retaining previous values

    if (fieldName === 'type') {
      // Reset 'rate_per_hour' and 'hours_worked' or 'total_cost' based on the new type
      if (value === 'Labour') {
        // If the new type is 'Labour,' reset 'total_cost' to zero
        setWorkOrderData({
          ...workOrderData,
          type: value,
          item: workOrderData.item.map((item) => ({
            ...item,
            total_cost: 0,
          })),
        });
      } else {
        // If the new type is not 'Labour,' reset 'rate_per_hour' and 'hours_worked' to zero
        setWorkOrderData({
          ...workOrderData,
          type: value,
          item: workOrderData.item.map((item) => ({
            ...item,
            rate_per_hour: 0,
            hours_worked: 0,
          })),
        });
      }
    } else{
      setWorkOrderData({
        ...workOrderData,
        [fieldName]: value,
      });
    }
   
  }
};
 


const handleImageUploadForItem = (id, imageURL) => {
  const updatedItems = workOrderData.item.map((item) =>
    item.id === id ? { ...item, image_url: imageURL } : item
  );
 
  
  const updatedWorkOrderData = {
    ...workOrderData,
    item: updatedItems,
  };

  setWorkOrderData(updatedWorkOrderData);
};

const onUpload = (id, downloadURL) => {
 
  const itemIndex = workOrderData.item.findIndex((item) => item.id === id);

  if (itemIndex !== -1) {
   
    const updatedWorkOrderData = { ...workOrderData };
    // Update the download URL for the specific item
    updatedWorkOrderData.item[itemIndex].image_url = downloadURL;
    setWorkOrderData(updatedWorkOrderData);
  }
};
  


  function onFormSubmit() {
    

    if (hasSubmitted) {
      return;
    }


  let isDataValid = validateWorkOrderData();

    const currentDate = moment().format('YYYY-MM-DD');
    const updatedItems = workOrderData.item.map((item) => {
      const { id, ...itemWithoutId } = item;
      return itemWithoutId;
    });
    // without id items
    const updatedWorkOrderData = {
      ...workOrderData,
      item: updatedItems,
    };
    if(isDataValid){
      setHasSubmitted(true);
      saveWorkOrder(updatedWorkOrderData)
    }
    
  }

  const validateWorkOrderData = () => {
    if (!workOrderData.description || !workOrderData.type || !workOrderData.total_amt || !workOrderData.due_date || !workOrderData.sub_type || !workOrderData.approved_by) {
      toast("Please fill in all the required fields.", { type: toast.TYPE.ERROR });
      return false;
    }
  
    if (workOrderData.type === 'Labour') {
      if (!workOrderData.item.every(item => item.rate_per_hour && item.hours_worked && item.image_url && item.total_cost && item.item_description)) {
        toast("Please fill in all the required fields for Labour items.", { type: toast.TYPE.ERROR });
        return false;
      }
    } else {
      // Check for missing values in the items array when the type is not 'Labour'
      if (!workOrderData.item.every(item => item.item_description && item.image_url && item.total_cost)) {
        toast("Please fill in all the required fields for items.", { type: toast.TYPE.ERROR });
        return false;
      }
    }
    if(selectedSupplier == ""){
      toast("Please select a supplier for work order",{ type: toast.TYPE.ERROR });
      return false;
    }
  
    return true;
  };
  
  

  const addItem = () => {
    const { item, type } = workOrderData;
  
    if (item.length >= 5) {
      toast("You can only add up to 5 items.", { type: toast.TYPE.ERROR });
      return;
    }
  
    let newItem = {
      id: item.length + 1,
      image_url: "",
      item_description: "",
      total_cost: 0,
      rate_per_hour:0,
      hours_worked:0
    };
  
    // If the selected type is 'Labour', add 'rate_per_hour' and 'hours_worked' fields to the new item
    if (type === 'Labour') {
      newItem = {
        ...newItem,
        rate_per_hour: 0,
        hours_worked: 0,
      };
    }
  
    const updatedWorkOrderData = {
      ...workOrderData,
      item: [...item, newItem],
    };
  
    setWorkOrderData(updatedWorkOrderData);
  };
  

  


  function saveWorkOrder(apiData) {
   
    let url =  `${DELIVERY_BASE_URL}/api/superzop/workorder/createWorkOrders`
    axios
      .post(url, apiData)
      .then(function (response) {
        
        setOpened(false);
        toast("Successfully saved work order", { type: toast.TYPE.SUCCESS })
        refreshListData();
       
      })
      .catch(function (error) {
       toast("Please fill required values, saving work order details failed", { type: toast.TYPE.ERROR });
       
      }).finally(() => {
        setHasSubmitted(false); 
      });
  }



  useEffect(() =>{
    fetchSupplierList();
  },[]);

  
  const fetchSupplierList = async () => {
    try{

      const Suppliers_DB = database.database().ref(`Suppliers`);

        const snapshot = await Suppliers_DB.once('value');
        const suppliersData = snapshot.val();
        
        let sortSupplierList = [];
        let suppliers = []
        Object.keys(suppliersData).forEach((supplier) => {
          if (suppliersData[supplier].supplierType == "Expenses"){
            sortSupplierList.push({name : suppliersData[supplier].supplier_name,key : suppliersData[supplier].supplier_id})
           
          }
        });
      
      
        sortSupplierList.sort();
        sortSupplierList.forEach((items) => {
          if (items.name && items.key) {
            suppliers.push({
              value: `${items.key}_${items.name}`,
              label: items.name,
            });
          }
          
        });
        setSuppliersListData(suppliers)
    } catch (error){
        console.error('Error fetching supplier details:', error);
    }
};

useEffect(() =>{
if(selectedSupplier){
  const [selectedKey, selectedName] = selectedSupplier.split('_');
  setSupplierID(selectedKey)
}
},[selectedSupplier]);

useEffect(() =>{
if(supplierID){
  const [selectedKey, selectedName] = selectedSupplier.split('_');
  setWorkOrderData((prevWorkOrderData) => ({
    ...prevWorkOrderData,
    supplier_id: selectedKey,
    supplier_name: selectedName,
  }));
}
},[supplierID])



  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Create Work Order"
        padding="xl"
        size="50vw"
      >
        <ScrollArea style={{ height: "90vh" }} offsetScrollbars>
          <form action="">
            <Stack
              spacing={"1.5rem"}
              align="stretch"
              justify="center"
              pb={"3rem"}
            >
              <DatePicker
                placeholder="Date"
                label="Creation date"
                value={new Date()}
                disabled
              />

              <DatePicker
                required
                placeholder="Date"
                label="Due date"
                value={workOrderData.due_date}
                minDate={new Date()}
                onChange={(value) => handleDueDate(value)}
              />
              {/* <Select
                required
                label="Type"
                placeholder="Select"
                data={typeData}
             value={workOrderData.type}
             onChange={(value) => handleFieldChange('type', value)}
            searchable
            maxDropdownHeight={400}
            clearable
              /> */}


<Select
        required
        label="Type"
        placeholder="Select"
        data={Object.keys(typeData).map((type) => ({ value: type, label: type }))}
        value={workOrderData.type}
        onChange={(value) => handleTypeChange(value)}
        searchable
        maxDropdownHeight={400}
        clearable
      />

      {workOrderData.type && (
        <Select
          required
          label="Subtype"
          placeholder="Select"
          data={typeData[workOrderData.type].map((subtype) => ({ value: subtype, label: subtype }))}
          value={workOrderData.subtype}
          onChange={(value) => handleSubtypeChange(value)}
          searchable
          maxDropdownHeight={400}
          clearable
        />
      )}

        <TextInput
        required
        label="Description"
        placeholder="expense descritption"
        value={workOrderData.description}
        onChange={(event) => handleFieldChange('description', event.target.value)}

            />

              <Select
                required
                label="Supliers List"
                placeholder="Select"
                data={suppliersListData}
             value={selectedSupplier}
             onChange={setSelectedSupplier}
            searchable
            maxDropdownHeight={400}
            clearable
              />

      <TextInput
        required
        label="Supplier ID"
        value={supplierID}
       disabled

            />
           
{workOrderData.item.map((item, index) => (
            <div key={item.id}>
              
              <TextInput
              required
                label={`Item ${index + 1} - Description`}
                placeholder="Description"
                value={item.description}
                onChange={(event) => handleFieldChange('item_description', event.target.value, index)}
              />
            {workOrderData.type === "Labour" ? (
    <>
    <NumberInput
      required
      label={`Item ${index + 1} - Hours Worked`}
      placeholder="Enter hours (1-24)"
      value={item.hours_worked}
      min={1}
      max={24}
      allowDecimal={false}
      allowNegative={false}
      onChange={(hoursVal) => handleFieldChange('hours_worked', hoursVal, index)}
    />
    <NumberInput
      required
      label={`Item ${index + 1} - Rate (per hour)`}
      placeholder="Enter rate"
      value={item.rate_per_hour}
      min={1}
      allowNegative={false}
      allowDecimal={false}
      onChange={(rateVal) => handleFieldChange('rate_per_hour', rateVal, index)}
    />
  </>
) : (
  <NumberInput
    required
    label={`Item ${index + 1} - Amount`}
    placeholder="Total Cost"
    value={item.total_cost}
    min={1}
    allowNegative={false}
    onChange={(totalCostValue) => handleFieldChange('total_cost', totalCostValue,index)}
  />
)}

<UploadImage id={item.id} onImageUpload={handleImageUploadForItem} onUpload={onUpload} />


            </div>
          ))}

<Button
          type="button"
          style={{ width: "50%" }}
          color="orangered"
          onClick={addItem}
        >
          Add Item
        </Button>

     
        <Divider color="black" my="sm" variant="dashed" />
             
          <NumberInput
            required
            label="Total Amount"
            value = {workOrderData.total_amt}
            disabled
            />

            <Select 
              required
              label="Approver"
              placeholder="Select"
              data={approverData}
              value={workOrderData.approved_by}
              onChange={(value) => handleApproverChange(value)}
              searchable
              maxDropdownHeight={400}
              clearable
           />

          {/* <TextInput
            required
            label="Remarks"
            placeholder="Enter remarks"
            value={workOrderData.remarks}
            onChange={(event) => handleRemarksChange(event)}
          /> */}
       <Divider color="black" my="sm" variant="dashed" />

     
              <Flex justify={"center"}>
                <Button
                  type="submit"
                  style={{ width: "50%" }}
                  color="teal"
                  onClick={(event) => {
                    event.preventDefault();
                    onFormSubmit();
                  }}
                >
                  Create
                </Button>
              </Flex>
            </Stack>
          </form>
        </ScrollArea>
      </Drawer>
      

    </>
  );
}
