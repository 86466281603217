import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Drawer,
  ScrollArea,
  SimpleGrid,
  Textarea,
  TextInput,
} from "@mantine/core";

import { RETAILERS_GROUP_POST_URL } from "../OfferMasterURLs";

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

const RetailerSeriesDrawer = (props) => {
  const [retailGrpIdsListError, setRetailGrpIdsListError] = useState("");
  const [retailGrpIdError, setRetailGrpIdError] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);

  const submitDataToAPI = (retailerIdListArr, retailerGrpId) => {
    const dataToSubmit = {
      retailersList: retailerIdListArr,
      group_id: retailerGrpId,
    };
    axios
      .post(RETAILERS_GROUP_POST_URL, dataToSubmit)
      .then((response) => {
        let resCode = response.data?.success || 0;

        if (resCode === 1) {
          props.setIsRetailerSeriesDrawerOpen(false);
          setIsSubmitBtnDisabled(false);
          toast("Successfully created retailer group", {
            type: toast.TYPE.SUCCESS,
          });
          if (props.handleNewRetailerSeriesCreated)
            props.handleNewRetailerSeriesCreated(retailerGrpId);
        } else {
          setIsSubmitBtnDisabled(false);
          return toast(
            JSON.stringify(
              response.data?.message ||
                "An Error occured while attempting to save retailer group"
            ),
            {
              type: toast.TYPE.ERROR,
            }
          );
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message ||
          "An Error occured while attempting to save retailer group";
        setIsSubmitBtnDisabled(false);
        return toast(JSON.stringify(errorMessage), {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const handleRetailerSeriesFormSubmit = (event) => {
    event.preventDefault();
    const retailerGrpIdsList =
      event.target?.elements.retailerGroupIdsList?.value;
    const retailerGrpId = event.target.elements.retailerGroupID?.value;

    if (!retailerGrpIdsList || retailerGrpIdsList === "") {
      return setRetailGrpIdsListError("This field cannot be left empty");
    }
    if (!retailerGrpId || retailerGrpId === "") {
      return setRetailGrpIdError("This field is required");
    }

    let formattedRetailerGrpIds = retailerGrpIdsList.split("\n");
    setIsSubmitBtnDisabled(true);
    submitDataToAPI(formattedRetailerGrpIds, retailerGrpId);
  };

  return (
    <Drawer
      position="right"
      opened={props.isRetailerSeriesDrawerOpen}
      onClose={() => props.setIsRetailerSeriesDrawerOpen(false)}
      title="Create Custom Retailer Series"
      padding="xl"
      size="xl"
    >
      <form onSubmit={handleRetailerSeriesFormSubmit}>
        {/* <ScrollArea style={{ height: "100%" }}> */}
        <SimpleGrid cols={1}>
          {/* <div>Text Area from retailer Group creation</div> */}
          <Textarea
            placeholder={`10001${"\n"}10002${"\n"}etc...`}
            label="Enter Retailer ID's"
            name="retailerGroupIdsList"
            error={retailGrpIdsListError}
            required
            minRows={10}
          />
          <TextInput
            placeholder="active_retailers_group"
            label="Enter retailer group ID"
            required
            name="retailerGroupID"
            error={retailGrpIdError}
          />
          <Button disabled={isSubmitBtnDisabled} type="submit">
            Create Group
          </Button>
        </SimpleGrid>
        {/* </ScrollArea> */}
      </form>
    </Drawer>
  );
};

export default RetailerSeriesDrawer;
