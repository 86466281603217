import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { CardPreviewWidget } from "./previewComponents/card";
import { GridPreviewWidget } from "./previewComponents/grid";
import HorizontalScrollPreviewWidget from "./previewComponents/horizontal_scroll";
import { Space, Text } from "@mantine/core";
import { ProductMasterContext } from "../../../context/AppHomeScreen/ProductMasterContext";
import { WidgetsListContext } from "../../../context/AppHomeScreen/WidgetsListContext";
import CardListPreviewWidget from "./previewComponents/card_list";
import { VerticalScrollPreviewWidget } from "./previewComponents/vertical_scroll";

const MobilePreviewContainer = styled.div`
  overflow: scroll;
  border: 5px solid;
  height: 40rem;
  background-color: white;
  box-shadow: 3px 3px 10px black;
  border-radius: 5px;
  padding: 1rem;
`;

function MobilePreview(props) {
  const { populateProdMasterData } = useContext(ProductMasterContext);
  const { state } = useContext(WidgetsListContext);

  useEffect(() => {
    populateProdMasterData();
  }, []);

  return (
    <>
      <Text
        style={{ textAlign: "center", marginBottom: "1rem" }}
        fw="600"
        fz="md"
      >
        App Preview
      </Text>

      <MobilePreviewContainer>
        {state.widgetsList.map((item) => {
          switch (item.widget) {
            case "CARD":
              return (
                <>
                  <CardPreviewWidget data={item} />
                  <Space h="md" />
                </>
              );

            case "GRID":
              return (
                <>
                  <GridPreviewWidget data={item} />
                  <Space h="md" />
                </>
              );

            case "HORIZONTAL_SCROLL":
              return (
                <>
                  <HorizontalScrollPreviewWidget data={item} />
                  <Space h="md" />
                </>
              );

            case "VERTICAL_SCROLL":
              return (
                <>
                  <VerticalScrollPreviewWidget data={item} />
                  <Space h="md" />
                </>
              );

            case "CARD_LIST":
              return (
                <>
                  <CardListPreviewWidget data={item} />
                  <Space h="md" />
                </>
              );
          }
        })}
      </MobilePreviewContainer>
    </>
  );
}

export default MobilePreview;
