import React,{useState} from 'react'
import {Container,Row,Col,Button} from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import AdminPortal from './admin_portal'
import SuperkreditPortal from './superkredit_portal'
import DeliveryPortal from './delivery_portal.js'
import SupportPortal from './support_portal'
import RoutingPortal from './routing_portal'
import FooterComp from '../Footer';
import DashboardNavBar from '../../Dashboard/Navbar/Dashboard_Navabar'

function ManagePortal() {
    const [adminportalselected, setadminportalselected] = useState(true);
    const [superkreditportalselected, setsuperkreditportalselected] = useState(false);
    const [value, setvalue] = useState("admin-portal");

    const [selectedPortal,setSelectedPortal] = useState("Admin Portal");


    return (
        <>
        <DashboardNavBar />

        <Container style={{paddingTop:'100px'}}>
            <Row>
                <Col xs={2}>
                  <DropdownButton
                  alignRight
                  title={selectedPortal}
                  id="dropdown-menu-select-portal"
                  >
                  <Dropdown.Item href="#" name="Admin Portal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Admin Portal</Dropdown.Item>
                  <Dropdown.Item href="#" name="Superkredit Portal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Superkredit Portal</Dropdown.Item>
                  <Dropdown.Item href="#" name="Delivery Portal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Delivery Portal</Dropdown.Item>
                  <Dropdown.Item href="#" name="Support Portal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Support Portal</Dropdown.Item>
                  <Dropdown.Item href="#" name="Routing Portal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Routing Portal</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col xs={4}>
                  <Button variant="success" style={{float:'right'}} onClick={()=>{window.location.href="/#/settings"}}>Manage User Roles</Button>
                </Col>
            </Row>

            {selectedPortal=="Admin Portal"?<AdminPortal />:selectedPortal=="Superkredit Portal"?<SuperkreditPortal />:selectedPortal=="Delivery Portal"?
            <DeliveryPortal />:selectedPortal=="Support Portal"?<SupportPortal />:selectedPortal=="Routing Portal"?<RoutingPortal />:null}
        </Container>
        <FooterComp />
        </>
    )
}

export default ManagePortal
