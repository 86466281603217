import React, { useState, useEffect, useRef } from 'react';
import database from '../../service/firebaseConfig';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Card, Table, Button, Form, Modal, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FetchUserEmailId from '../../CommonRequirements/FetchUserEmailId'
import UpdatedDate from '../../CommonRequirements/UpdatedDate'
import UpdatedTime from '../../CommonRequirements/UpdatedTime'
import Switch from "react-switch";
import baseUrl from '../../service/servicesConfig';




const InfoConfirmPopup = ({ dataObjects, selectedDate, uniqKeyToModify, informationApproval, reason, grnNoToModify, billReceivedData, ...props }) => {

    const PurchaseDetailsData = database.database().ref(`Purchase_Details`)


    const [infoCorrect, setInfoCorrect] = useState("")
    const [reasonItemWise, setReasonItemWise] = useState("")
    const [billReceivedConfirm, setBillReceivedConfirm] = useState("")



    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyDetails = (success) => {
        if (success) {
            return toast(`Information Details updated for ${selectedDate.getDate()}-${(selectedDate.getMonth() + 1)}-${selectedDate.getFullYear()} ,${uniqKeyToModify}`, { type: toast.TYPE.SUCCESS });
        }
    };

    const handleMainSubmit = (e) => {

        e.preventDefault()
        Object.keys(dataObjects).forEach(objectKey => {
            //console.log(dataObjects[objectKey].grn_no)
            //console.log(grnNoToModify)
            //console.log('billReceivedConfirm ', billReceivedConfirm, " billReceivedData ", billReceivedData);
            /*if (dataObjects[objectKey].hasOwnProperty('grn_no') && dataObjects[objectKey].grn_no === grnNoToModify) {

                PurchaseDetailsData.child(`${selectedDate.getFullYear()}/${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}/${selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate()}-${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}-${selectedDate.getFullYear()}`).child(objectKey).update({
                    "info_correct": infoCorrect !== "" ? infoCorrect : informationApproval, "reasons": reasonItemWise ? reasonItemWise : "", "bill_received": billReceivedConfirm !== "" ? "" + billReceivedConfirm : "", "approved_by": FetchUserEmailId(), "approved_at": `${UpdatedDate()} ${UpdatedTime()}`
                }
                ).then(() => {
                    notifyDetails(true); props.onHide(); setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                })
            }*/

            if (dataObjects[objectKey].grn_no && dataObjects[objectKey].grn_no === grnNoToModify) {

                const unique_key = dataObjects[objectKey].uniqueId;
                const firebaseUniqKey = unique_key.substring(11);
                let bill_received = billReceivedConfirm !== "" ? billReceivedConfirm : (billReceivedData && billReceivedData === "true") ? "true" : "false";
                // if (bill_received == 1)
                //     bill_received = true;
                // else
                //     bill_received = false;

                //console.log('bill_received ', bill_received);

                if (!infoCorrect && bill_received) {

                    return toast("Please select information correct", { type: toast.TYPE.ERROR });
                } else {

                    const jsonObj = {
                        "info_correct": infoCorrect !== "" ? infoCorrect : informationApproval,
                        "reasons": reasonItemWise ? reasonItemWise : "",
                        //"bill_received": billReceivedConfirm !== "" ? "" + billReceivedConfirm : "",
                        "bill_received": bill_received.toString(),
                        "approved_by": FetchUserEmailId(),
                        "approved_at": `${UpdatedDate()} ${UpdatedTime()}`
                    }

                    console.log('jsonObj ', jsonObj);

                    console.log(`uniqueId: ${unique_key} firebaseUniqKey ${firebaseUniqKey} approved_by ${FetchUserEmailId()} approved_at ${UpdatedDate()} ${UpdatedTime()} bill_received ${jsonObj.bill_received}`);

                    const url = `/api/superzop/commercial/purchasedetails/updatepurchasedetailsrecord?key=${unique_key}`;

                    const firebaseProm = new Promise((resolve, reject) => {

                        PurchaseDetailsData.child(`${selectedDate.getFullYear()}/${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}/${selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate()}-${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}-${selectedDate.getFullYear()}`).child(firebaseUniqKey).update(jsonObj).then(() => {
                            resolve("Success");
                        }).catch(error => {
                            reject(error);
                        })
                    });

                    const mysqlProm = new Promise((resolve, reject) => {

                        baseUrl.put(url, jsonObj).then(res => {

                            resolve("Success");
                        }).catch((error) => {

                            reject(error);
                        });
                    });

                    Promise.all([firebaseProm, mysqlProm]).then(() => {

                        notifyDetails(true);
                        props.onHide();
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000);
                    }).catch(error => {
                        console.error('error: ', error);
                    })
                }
            }
        })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleMainSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Information Confirm
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", padding: "10px", marginBottom: "20px" }}>
                        <InputGroup size="sm">
                            <InputGroup.Prepend style={{ marginRight: "10px" }}>
                                <InputGroup.Text>Bill Received</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Switch onChange={(val) => { setBillReceivedConfirm(val) }} checked={billReceivedConfirm !== "" ? (billReceivedConfirm) : (billReceivedData && billReceivedData === "true") ? true : false} />
                        </InputGroup>
                    </div>
                    <div>
                        <Form.Group>
                            <InputGroup style={{ padding: "10px" }}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Information Correct</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DropdownButton variant="outline-primary" title={infoCorrect ? infoCorrect : (informationApproval ? informationApproval : "Select Approval")} id="input-group-dropdown-2">
                                    <Dropdown.Item name="Yes" onClick={(e) => { setInfoCorrect(e.target.name) }}>Yes</Dropdown.Item>
                                    <Dropdown.Item name="No" onClick={(e) => { setInfoCorrect(e.target.name) }}>No</Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                            {infoCorrect === "Yes" ? "" : ((infoCorrect === "No" || reason) ? <InputGroup style={{ padding: "10px" }} >
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Reason</InputGroup.Text>
                                </InputGroup.Prepend>
                                <InputGroup.Append>
                                    <DropdownButton variant="outline-primary" title={reasonItemWise ? reasonItemWise : (reason ? reason : "Select Reason")}>
                                        <Dropdown.Item name="Item Missing" onClick={(e) => { setReasonItemWise(e.target.name) }}>Item Missing</Dropdown.Item>
                                        <Dropdown.Item name="Qty Incorrect" onClick={(e) => { setReasonItemWise(e.target.name) }}>Qty Incorrect</Dropdown.Item>
                                        <Dropdown.Item name="Supplier Name Incorrect" onClick={(e) => { setReasonItemWise(e.target.name) }}>Supplier Name Incorrect</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup.Append>
                            </InputGroup> : "")}
                        </Form.Group>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button type={"submit"}>Update Info</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );

}

export default InfoConfirmPopup
