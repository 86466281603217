import styled from 'styled-components';

export const ProductContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: 2rem auto;
    padding: 2.5rem 6rem;
`;

export const NoProdContainer = styled.div`
    width:100%;
    height: 75vh;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;
    
    span {
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;
        padding: 0.5rem;
    }
`;