import styled from 'styled-components';

export const ToggleWrapper = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;  

    .toggleStock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .tgl {
            display: none;

            &,
            &:after,
            &:before,
            & *,
            & *:after,
            & *:before,
            & + .tgl-btn {
                box-sizing: border-box;

                &::selection {
                    background: none;
                }
            }
        
            + .tgl-btn {
                outline: 0;
                display: block;
                width: 6rem;
                height: 2rem;
                margin: 0rem 1rem;
                position: relative;
                cursor: pointer;
                user-select: none;

                &:after,
                &:before {
                    content: "";
                    position: relative;
                    display: block;
                    width: 50%;
                    height: 100%;
                }
            
                &:after {
                    left: 0;
                }
            
                &:before {
                    display: none;
                }
            }
            
            &:checked + .tgl-btn:after {
                left: 50%;
            }
        }

        .tgl-flat {
            + .tgl-btn {
                padding: 4px;
                background: #fff;
                text-align: center;
                border: 2px solid #ffa07a;
                border-radius: 1rem;
                transition: all 0.4s ease-in-out;

                &:after {
                    content: "OUT";
                    background: #ffa07a;
                    color: #000;
                    border-radius: 1rem;
                    transition: all .4s ease-in-out;
                }
            }

            &:checked + .tgl-btn {
                border: 2px solid #7FC6A6;

                &:after {
                    content: "IN";
                    left: 50%;
                    color: #000;
                    background: #7FC6A6;
                }
            }                       
        }
    }
`;