import React, { useEffect, useState, useRef } from 'react'
import { Breadcrumb, Dropdown, DropdownButton, Alert, Tab, Row, Col, Nav, Table, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import baseUrl from '../../service/servicesConfig';

const SyncPurchaseDetailsModal = ({ ...props }) => {
    let currdate = new Date();
    const [purchaseYear, setPurchaseYear] = useState(currdate.getFullYear());
    const [purchaseMonth, setPurchaseMonth] = useState(currdate.getMonth() + 1);

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const syncPurchaseDetails = () => {
        if (purchaseMonth == "") {
            return toast("Invalid month", { type: toast.TYPE.ERROR });
        } else if (purchaseYear == "") {
            return toast("Invalid year", { type: toast.TYPE.ERROR });
        } else {

            const save_purchasedetails_url = `/api/superzop/commercial/purchasedetails/savepurchasedetailstable?month=${purchaseMonth}&year=${purchaseYear}`;

            baseUrl.post(save_purchasedetails_url).then(data => {

                console.log(data);
                props.onHide();
                return toast("Synced Purchase Details", { type: toast.TYPE.SUCCESS });
            }).catch((error) => {

                console.log(error);
                props.onHide();
                return toast("Couldn't sync Purchase Details record", { type: toast.TYPE.ERROR });
            });
            /*fetch(`https://services2.superzop.com/api/superzop/commercial/purchasedetails/savepurchasedetailstable?month=${purchaseMonth}&year=${purchaseYear}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => Promise.resolve(response))
                .then(data => {
                    console.log(data);
                    props.onHide();
                    return toast("Synced Purchase Details", { type: toast.TYPE.SUCCESS });
                })
                .catch((error) => {
                    console.log(error);
                    props.onHide();
                    return toast("Couldn't sync Purchase Details record", { type: toast.TYPE.ERROR });

                });*/
        }
    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sync Purchase Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Purchase Year</td>
                                <td ><Form.Control value={purchaseYear} onChange={(e) => { setPurchaseYear(e.target.value); }} /></td>
                            </tr>
                            <tr>
                                <td>Purchase Month</td>
                                <td ><Form.Control value={purchaseMonth} onChange={(e) => { setPurchaseMonth(e.target.value); }} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => { syncPurchaseDetails(); }}>Sync</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )

}

export default SyncPurchaseDetailsModal
