import React, { useState, useEffect } from 'react'
import { Table, Button, Form, Card, Dropdown, DropdownButton, Alert ,InputGroup,Spinner,} from 'react-bootstrap';
import database from '../../service/firebaseConfig';
import {LOGGED_USER_EMAIL} from '../../constants/allConstants'
import { toast } from 'react-toastify';
import ProductStockModal from './ProductStockModal';
import AddItem from './addItemModal'
import DeleteItem from './deleteItemModal'
import EditItem from './editItemModal'
import CaseSizeModal from './CaseSizeModal'
import LogoComp from '../LogoComp/index'
import FooterComponent from '../Footer/index';
import LoadingOverlay from 'react-loading-overlay';
import { UserStatusContainer } from '../LogoComp/wrapper';
import XLSX from 'xlsx'
import { FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { useWindowScroll } from '@mantine/hooks';
import { OFFER_BASE_URL } from '../../service/servicesConfig';
// import StockCheckBoxComp from '../CustomCheckBox';
import { CheckBoxContainer } from './wrapper';


const EditStock = (props) => {

    const fetchProductStockDB = database.database().ref(`Product_Base_Data`);
    const fetchProductItemsDb = database.database().ref(`Product_Master_New/A`)

    const [productsData, setProductsData] = useState("")
    const stockStatusItems = []
    const [updatedStockStatus, setUpdatedStockStatus] = useState("")
    const [searchText, setSearchText] = useState("")
    const [stockInd, setStockInd] = useState("")
    const [stockData, setStockData] = useState("")
    const [itemIdToChange, setItemIdToChange] = useState("")
    const [transactData, setTransactData] = useState(0)
    const [addOperation, setAddOperation] = useState(false)
    const [subOperation, setSubOperation] = useState(false)
    const [productStockModal, setProductStockModal] = useState(false)
    const [stockUpdatedvalue, setStockUpdatedValue] = useState("")
    const [currentStockVal, setCurrentStockVal] = useState("")
    const [updatedDomValue, setUpdatedDomValue] = useState("")
    const [confirmDomUpdate, setConfirmDomUpdate] = useState(false)
    let [countStock, setCountStock] = useState(1)
    let [filterCount, setFilterCount] = useState(0)
    const [caseSizeModal, setcaseSizeModal] = useState(false)
    const [caseSizeDom, setCaseSizeDom] = useState("")
    const [caseUpdatedValue, setCaseUpdatedValue] = useState("")
    const [currentCaseValue, setCurrentCaseValue] = useState("")
    const [actualCaseSize, setActualCaseSize] = useState("")
    const [noResultFound, setNoResultFound] = useState(false)
    const [categoryVarieties, setCategoryVarieties] = useState("")
    const [categoryInfo, setCategoryInfo] = useState("")
    const [exportData, setExportData] = useState(false)
    const [initCheckbox,setInitCheckbox] = useState(true)
   

    const [addItemModal,setAddItemModal]  = useState(false);
    const [deleteItemModal,setDeleteItemModal]  = useState(false);
    const [editItemModal,setEditItemModal]  = useState(false);
    const [stockDataApi,setStockDataApi] = useState("");

    const [stockDataAPI, setStockDataAPI] = useState([]);
    const [updateItem,setUpdateItem] = useState(false);
    const [deleteItem,setDeleteItem] = useState(false);
    const [editItem,setEditItem] = useState(false);
    const [stockApiItems,setStockAPIiTEMS] = useState("");
    const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6Ik"

    let dtModified = new Date()
    dtModified = `${dtModified.getDate()}/${dtModified.getMonth()+1}/${dtModified.getFullYear()} ${dtModified.getHours()<10 ? "0"+dtModified.getHours():dtModified.getHours()}:${dtModified.getMinutes()<10 ? "0"+dtModified.getMinutes():dtModified.getMinutes()}`

    let filterResult = [];
    // const {stockData} = props.location || false
    
    if(stockUpdatedvalue){
        console.log(stockUpdatedvalue)
    }

    const fetchStockData = () => {
        
        console.log("inside earlier")
        const allStockData = []
        const collectItemId = []
        let categoryList = []
        const stock_url = `${OFFER_BASE_URL}/stock-management?token=${TOKEN}`;
        Promise.all([
            axios.get(stock_url)
            ,fetchProductStockDB.once("value")]).then(([val1,val2]) => {
            
            console.log("fetch all data finish");
            let stockObj = val2.val() || {};
            let stockDataFromAPIRes = (val1.data.data);
            console.log("api response");
            console.log(stockDataFromAPIRes);
            console.log("firebase response");
            console.log(stockObj);
            setStockAPIiTEMS(stockDataFromAPIRes)
            
            
            stockDataFromAPIRes.forEach((stockOb)=>{
            Object.entries(stockObj).forEach(([key,value])=>{
                    
                    if(stockOb['item_id'] == key && value['active_status'] == "Y")
                    // if(stockOb['item_id'] == key )
                   
                    {
                        
                            console.log(`key ${key}: value `,value)
    
                            const stockDetails = {};
                            collectItemId.push(key)
                            stockDetails["item_id"] = key;
                            stockDetails["item_description1"] = value.long_description;
                            stockDetails["brand"] = value.brand;
                            stockDetails["stock_ind"] = value.stock_ind;
                            stockDetails["product_stock"] = stockOb['qty'];
                            stockDetails["category"] = value.category;
                            stockDetails["sub_category"] = value.sub_category;
                            stockDetails["case_size"] = stockOb['case_size']?stockOb['case_size']:"";
                            allStockData.push(stockDetails)
                        //}
                    }
                })
            })    
            console.log(`stockDataAPI`,stockDataAPI)
            allStockData.forEach(categories=>{
                categoryList.push(categories.category)
            })
            categoryList = [...new Set(categoryList)]
            console.log(categoryList)
            setCategoryVarieties(categoryList)
            setStockData(allStockData)
            setCountStock(allStockData.length)
            console.log(categoryList)
            console.log(allStockData)
          });
    
    }
    
    useEffect(() => {
            // fetchStockData()
    }, [])


    const fetchProductDataFromDB = () => {
        console.log("fetching product base data");
    
        return new Promise((resolve, reject) => {
          
    
            fetchProductStockDB.once(
            "value",
            (snapshot) => {
              let data = snapshot.val();
              console.log("product data", data);
              resolve(data);
            },
            (err) => {
              reject("error occured while fetching data from firebase");
            }
          );
        });
      };
      const fetchProductDataFromPdmast_New = () => {
        console.log("fetching product master data");
    
        return new Promise((resolve, reject) => {
          
    
            fetchProductItemsDb.once(
            "value",
            (snapshot) => {
              let data = snapshot.val();
              console.log("product data", data);
              resolve(data);
            },
            (err) => {
              reject("error occured while fetching data from firebase");
            }
          );
        });
      };
    const fetchStockOutData = () => {
        const allStockData = []
        const collectItemId = []
        let categoryList = []
       
    
        Promise.all([
            fetchProductDataFromPdmast_New()  
            ,fetchProductDataFromDB()]).then(([value1,value2]) => {
            
            console.log("fetch all data finish");
            console.log("Products data")
            let productsData = Object.values(value1)
            console.log((productsData))
            console.log("Products stock data")
            let productStockData = (value2)
            console.log((productStockData))
           
            
            
            productsData.forEach((prodObj)=>{
            Object.entries(productStockData).forEach(([key,value])=>{
                    
                    if(prodObj['item_id'] == key && value['active_status'] == "Y" && prodObj['dealer_price'] !== 0 && prodObj['stock_ind'] !=="Y" )
                   
                   
                    {
                        
                            // console.log(`key ${key}: value `,value)
    
                            const stockDetails = {};
                            collectItemId.push(key)
                            stockDetails["item_id"] = key;
                            stockDetails["item_description1"] = prodObj.long_description;
                            stockDetails["brand"] = prodObj.brand;
                            stockDetails["stock_ind"] = prodObj.stock_ind;
                            stockDetails["product_stock"] = value.product_stock;
                            stockDetails["category"] = prodObj.category;
                            stockDetails["sub_category"] = prodObj.sub_category;
                            stockDetails["case_size"] = value.case_size?value.case_size:"";
                            allStockData.push(stockDetails)
                        }
                    })
                })
               
           
            console.log("all stock data")
            console.log(allStockData)
            allStockData.forEach(categories=>{
                categoryList.push(categories.category)
            })
            categoryList = [...new Set(categoryList)]
            console.log(categoryList)
            setCategoryVarieties(categoryList)
            setStockData(allStockData)
            setCountStock(allStockData.length)
            console.log(categoryList)
            console.log(allStockData)
        }) 
    
    }

    useEffect(()=>{
        if(initCheckbox){
            console.log("display stock ind N")
            fetchStockOutData()
        }else if(!initCheckbox){
            fetchStockData()
        }
        
    },[initCheckbox])
 
    
    

    const initialStockIndStatus = () => {

        stockData.map(items => {
            // if (items.stock_ind === "Y")
             {
                stockStatusItems.push(items)
                // setCountStock(countStock++);
            }
        })
        setUpdatedStockStatus(stockStatusItems)
    }

    useEffect(() => {
        if (stockData )
            initialStockIndStatus()
    }, [stockData])

    const changeStockIndData = () => {

        setFilterCount(filterCount=0)
        document.getElementById("searchProduct").value = ""
        setProductsData([])
        if (stockInd === "Y") {
            // setCountStock(countStock=1)
            stockData.map(items => {
                if (items.stock_ind === "Y") {
                    if(categoryInfo && categoryInfo!=="All" && categoryInfo===items.category){

                        stockStatusItems.push(items)
                        // setCountStock(countStock++);
                        return
                    }
                    stockStatusItems.push(items)
                    // setCountStock(countStock++);
                }
            })
            console.log(stockStatusItems)
            setUpdatedStockStatus(stockStatusItems)
        }
        if (stockInd === "N") {
            // setCountStock(countStock=1)
            stockData.map(items => {
                if (items.stock_ind === "N") {
                    if(categoryInfo && categoryInfo!=="All" && categoryInfo===items.category){
                        stockStatusItems.push(items)
                        // setCountStock(countStock++);
                        return
                    }
                    stockStatusItems.push(items)
                    // setCountStock(countStock++);
                }
            })
            console.log(stockStatusItems)
            setUpdatedStockStatus(stockStatusItems)
        }
        if (stockInd === "All") {
            // setCountStock(countStock=1)
            stockData.map(items => {
                if(categoryInfo && categoryInfo!=="All" && categoryInfo===items.category){
                    stockStatusItems.push(items)
                    // setCountStock(countStock++);
                    return   
                }
                stockStatusItems.push(items)
                // setCountStock(countStock++);
            })
            console.log(stockStatusItems)
            setUpdatedStockStatus(stockStatusItems)
        }

    }

    useEffect(() => {
        if (stockInd) {

            // changeStockIndData()
        }
    }, [stockInd])


    const filterCategoryList = () => {

        const filterCategoryListArray = []
        let filterCountCategory = 0
        stockData.forEach(items=>{
            if(items.category === categoryInfo){
            filterCountCategory++
            filterCategoryListArray.push(items)}
            else if(categoryInfo === "All"){
            filterCategoryListArray.push(items)
            }
        })
        setFilterCount(filterCountCategory)
        setUpdatedStockStatus(filterCategoryListArray)

    }

    useEffect(() => {
        if(categoryInfo)
        filterCategoryList()
    }, [categoryInfo])

    const exportStockDetails = () => {

        const wb = XLSX.utils.book_new()
        const itemsLabelList = [["ITEM ID","ITEM BRAND","ITEM DESCRIPTION","STOCK[PIECES]","STOCK IND","CATEGORY"]]
        productsData.length!==0?productsData.forEach(item=>{
            itemsLabelList.push([item.item_id,item.brand,item.item_description1,item.product_stock,item.case_size,item.category])
        }):
        updatedStockStatus.forEach(item=>{
            itemsLabelList.push([item.item_id,item.brand,item.item_description1,item.product_stock,item.stock_ind,item.category])
        })
        const itemsExported = XLSX.utils.aoa_to_sheet(itemsLabelList)
        XLSX.utils.book_append_sheet(wb, itemsExported, `Product Stock`)
        XLSX.writeFile(wb, `Product_Stock.xlsx`)
        setExportData(false)
    }

    useEffect(() => {
        if(exportData)
        exportStockDetails()
    }, [exportData])



    const renderList = () => {


        filterResult = updatedStockStatus.filter(obj => {

            const productName = obj.item_description1.trim().toLowerCase();
            const brandName = obj.brand.trim().toLowerCase();
            const itemIdSearch = obj.item_id.trim().toLowerCase();
            setSearchText(searchText.trim().toLowerCase());
            
            return productName.match(searchText) || brandName.match(searchText) || itemIdSearch.match(searchText)
        })
        if(filterResult.length===0){
            setNoResultFound(true)
        }
        else {
            setNoResultFound(false)
        }
        console.log(filterResult)
        setFilterCount(filterResult.length)
        setProductsData(filterResult)

    }
    useEffect(() => {
        if (searchText)
            renderList()
        else if(searchText===""){
            setFilterCount(filterCount=0)
        }
    }, [searchText])

    const StockTransactions = () =>{
        var transactableData = database.database().ref(`Product_Base_Data/${itemIdToChange}`).child(`product_stock`)
        transactableData.transaction((product_stock)=>{

            if(product_stock!==null){

                if(addOperation){
                product_stock = parseInt(product_stock) + parseInt(stockUpdatedvalue);
                const result = parseInt(product_stock) + parseInt(stockUpdatedvalue)
                console.log(result)
                setTransactData(result);
                console.log(transactData)

            }if(subOperation){

                product_stock  = product_stock - stockUpdatedvalue;
                const result = parseInt(product_stock) - parseInt(stockUpdatedvalue)
                console.log(result)
                setTransactData(result)
            }}
            notifyUser(true)
            return product_stock ;
        })
        setAddOperation(false)
        setSubOperation(false)
        setStockUpdatedValue("");
        setConfirmDomUpdate(true)
    }

    const domUpdationWithData = () =>{
        fetchProductStockDB.child(`${itemIdToChange}`).once("value",newValue=>{
            const details = newValue.val();
            console.log(details)
            setUpdatedDomValue(details.product_stock)
            setConfirmDomUpdate(false)
        })
    }

    const calculateCase = () =>{

            var newProductStock = caseUpdatedValue * actualCaseSize;
            console.log("Updated Product Stock"+newProductStock);
            setCaseSizeDom(newProductStock/actualCaseSize)
            fetchProductStockDB.child(`${itemIdToChange}`).update({
                product_stock:parseInt(newProductStock)
            }).then(()=>{notifyCaseSize(true);setSubOperation(false);setCaseUpdatedValue(false); setAddOperation(false)})
    }

    useEffect(() => {
        if(caseUpdatedValue)
        calculateCase()
    }, [caseUpdatedValue])

    useEffect(() => {
    if(stockUpdatedvalue)
        StockTransactions()
    }, [stockUpdatedvalue])

    useEffect(() => {
        if(confirmDomUpdate)
        domUpdationWithData()
    }, [confirmDomUpdate])

    
    useEffect(() =>{
            if(updateItem){
                notifyUser(true)
            
            } else if(editItem) {
                notifyUser(true)
                
            } else if(deleteItem){
                notifyUser(true)
                
            }
    },[updateItem,editItem,deleteItem])

    

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            toast(`Updated the product stock`, { type: toast.TYPE.SUCCESS });
            fetchStockData();
            setTimeout(() => {
                    window.location.reload();
                   }, 4200);
            return;
        } else {
            toast("Couldn't update the product stock", { type: toast.TYPE.ERROR });
           }
        };
    const notifyCaseSize = (success) => {
        if (success) {
            
            toast(`Updated the case size`, { type: toast.TYPE.SUCCESS });
            fetchStockData();
            return;
        } else {
         toast("Couldn't update the case size", { type: toast.TYPE.ERROR });
        }
    };


    const fetchStockDataFromAPI = () => {
        console.log("fetching stock data from API");

        return new Promise((resolve, reject) => {
            const stock_url = `${OFFER_BASE_URL}/stock-management?token=${TOKEN}`
          axios
            .get(stock_url)
            .then((res) => {
              let data = res.data.data;
              console.log("finished fetching stock data from API");
              resolve(data);
            })
            .catch((err) => {
              reject(
                "error occured while atempting to fetch stock data from API"
              );
            });
        });
      };


      const generateStockData =  () => {
        
        console.log("generate stock data start");
         return new Promise((resolve, reject) => {
          fetchStockDataFromAPI().then((data) => {
            let stock_data = [];
            data.forEach((item, index) => {
              stock_data.push(item);
            });
    
            setStockDataAPI(stock_data);
            console.log("generate stock data finish");
            resolve();
            return stock_data;
          });
        });
      };
    
      const handleCheckboxChange = (event) => {
       
        setInitCheckbox(event.target.checked);
        setCategoryInfo("All")
        console.log(event.target.checked);
      };
    
   
    return (
        <div style={{ textAlign: "center",marginLeft:"50px",marginRight:"50px" }}>
            <LogoComp/>
            <Alert variant="primary" style={{padding:"15px"}}>Edit Product Stock</Alert>
            <div style={{ display: "inline-block" }}>{stockData ?
                <>
                    <div style={{padding:"10px",width:"1000px",display:"inline-block"}}>
                    <InputGroup>
                    <Form.Control id="searchProduct" style={{width:"300px",display:"inline-block"}} className="searchBox" placeholder="Item ID / Description / Brand" onChange={(e) => setSearchText(e.target.value)} />
                    <InputGroup.Append><Button onClick={()=>window.location.reload()} style={{float:"right"}}>REFRESH</Button></InputGroup.Append>
                    </InputGroup>
                    </div>
                    <div style={{marginBottom:"50px",marginLeft:"50px"}}>
                    <Form inline>
                        {/*  
                    <span >
                    <InputGroup >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">Select Stock Ind</InputGroup.Text>
                        </InputGroup.Prepend>
                        
                        <DropdownButton id="dropdown-basic-button" title={stockInd ? stockInd : "Y"}>

                            <Dropdown.Item onClick={(e) => { setStockInd(e.target.name) }} name="All">All</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setStockInd(e.target.name) }} name="Y">Y</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setStockInd(e.target.name) }} name="N">N</Dropdown.Item>
                        </DropdownButton>
                        </InputGroup>
                        </span>*/}
                        <span style={{display:"inline-block",margin:"0px",marginLeft:"30px"}}>
                        <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">Category</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownButton id="dropdown-basic-button"  title={categoryInfo ? categoryInfo : "Select"}>
                        {categoryVarieties?categoryVarieties.map(categoryItem=>(
                        <Dropdown.Item onClick={(e) => { setCategoryInfo(e.target.name) }} name={categoryItem}>{categoryItem}</Dropdown.Item>
                        )):""}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={(e) => { setCategoryInfo(e.target.name) }} name={"All"}>{"All"}</Dropdown.Item>
                        </DropdownButton>
                        {!initCheckbox &&(<>
                        <Button style={{marginLeft:"10px", marginRight:"10px"}} onClick = {() => {setAddItemModal(true);}} >Add items</Button>
                        
                        </>)}
                        
                        

                        {/* <Button style={{marginLeft:"auto", marginRight:"10px"}} onClick = {() => {setDeleteItemModal(true);}}>Delete items</Button> */}
                        
                        </InputGroup></span>
                        
                        <span style={{marginLeft:"auto", marginRight:"10px"}}><InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text >Stock Out List</InputGroup.Text>
                            
                            </InputGroup.Prepend>
                            <CheckBoxContainer>

                            <input type="checkbox" id={`outOfStock`} checked={initCheckbox} onChange={handleCheckboxChange} />
            <label htmlFor={`outOfStock`}></label>
                            </CheckBoxContainer>
                        {/* <StockCheckBoxComp isChecked={true} /> */}
                            
                        </InputGroup></span>
                        <span style={{marginLeft:"auto", marginRight:"10px"}}><InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text id="total_items">Total Items</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control readOnly value={`${filterCount}/${countStock}`}/>
                            <Button style={{marginLeft:"10px"}}onClick={()=>setExportData(true)}>Export</Button>
                        </InputGroup></span>
                        </Form>
                        </div>
                        <div>
                            <Table bordered="true" style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                        <td>ITEM ID</td>
                                        <td>ITEM BRAND</td>
                                        <td>ITEM DESCRIPTION</td>
                                        {!initCheckbox && 
                                        ( 
                                        <>
                                        <td>STOCK[PIECES]</td>
                                        <td>STOCK[CASE]</td>
                                        </>
                                        )
                                        }
                                       
                                        <td>STOCK STATUS</td>
                                        <td>CATEGORY</td>
                                        {!initCheckbox && (
                                            <>
                                            <td>EDIT</td>
                                            <td>DELETE</td>
                                            </>
                                        )}
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchText === "" ?
                                        (updatedStockStatus) &&
                                        updatedStockStatus.map(item => (
                                            <tr>
                                                <td>
                                                    {item.item_id}
                                                </td>
                                                <td>
                                                    {item.brand}
                                                </td>
                                                <td>
                                                    {item.item_description1}
                                                </td>
                                                {!initCheckbox &&(
                                                    <>
                                                   
                                                <td>
                                                    {/* <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);console.log(item.item_id);if(item.item_id!==itemIdToChange){ setUpdatedDomValue("")};setSubOperation(true);setProductStockModal(true);setCurrentStockVal(item.product_stock)}}>-</Button></span> */}
                                                    <span 
                                                    // style={item.product_stock>0?item.product_stock<5?{backgroundColor:"red",color:"white",width:"35px",height:"30px",display:"inline-block"}:{backgroundColor:"white",width:"35px",height:"30px",display:"inline-block"}:
                                                        // {backgroundColor:"grey",color:"white",width:"35px",height:"30px",display:"inline-block"}}
                                                        
                                                        >
                                                        {item.item_id===itemIdToChange? updatedDomValue?updatedDomValue: item.product_stock:item.product_stock}</span>
                                                    {/* <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setAddOperation(true);setProductStockModal(true);setCurrentStockVal(item.product_stock)}}>+</Button></span> */}
                                                </td>
                                                <td>{item.case_size?<>
                                                    <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setSubOperation(true);setcaseSizeModal(true);setCurrentCaseValue(item.product_stock/item.case_size);setActualCaseSize(item.case_size)}}>-</Button></span>
                                                    <span style={{width:"35px",display:"inline-block"}}>
                                                    {item.item_id===itemIdToChange?caseSizeDom?caseSizeDom:item.product_stock/item.case_size:item.product_stock/item.case_size}</span>
                                                    <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setAddOperation(true);setcaseSizeModal(true);setCurrentCaseValue(item.product_stock/item.case_size);setActualCaseSize(item.case_size)}}>+</Button></span></>:""}
                                                </td>
                                                </>
                                                )}
                                                <td>
                                                <span style={item.stock_ind =="Y"?{ backgroundColor:"grey",color:"white",width:"35px",height:"30px",display:"inline-block"}:{backgroundColor:"red",color:"white",width:"35px",height:"30px",display:"inline-block"}}>
                                                    {item.stock_ind}
                                                    </span>
                                                    </td>
                                                    <td>
                                                    {item.category} - {item.sub_category}
                                                </td>
                                                {!initCheckbox && (
                                                    <>
                                                   
                                                <td>
                                                 <Button style={{marginLeft:"auto", marginRight:"10px"}} onClick = {() => {setItemIdToChange(item.item_id);setEditItemModal(true);}}><FaPencilAlt/></Button>
                                                </td>
                                                <td>
                                                 <Button style={{marginLeft:"auto", marginRight:"10px"}} onClick = {() => {setItemIdToChange(item.item_id);setDeleteItemModal(true);}}><FaRegTrashAlt/></Button>
                                                </td>
                                                </>
                                                )}
                                            </tr>
                                        )) :
                                        productsData.length!==0?
                                        productsData.map(item => (
                                            <tr>
                                                <td>
                                                    {item.item_id}
                                                </td>
                                                <td>
                                                    {item.brand}
                                                </td>
                                                <td>
                                                    {item.item_description1}
                                                </td>
                                                {!initCheckbox && (
                                                    <>
                                                   
                                                <td>
                                                    {/* <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setSubOperation(true);setProductStockModal(true);setCurrentStockVal(item.product_stock)}}>-</Button></span>{''} */}
                                                    <span 
                                                    // style={item.product_stock>0?item.product_stock<5?
                                                    // {backgroundColor:"red",color:"white",width:"35px",height:"30px",display:"inline-block"}:{backgroundColor:"white",width:"35px",height:"30px",display:"inline-block"}:
                                                        // {backgroundColor:"grey",color:"white",width:"35px",height:"30px",display:"inline-block"}}
                                                        >
                                                        {item.item_id===itemIdToChange? updatedDomValue?updatedDomValue: item.product_stock:item.product_stock}</span>
                                                    {/* <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setAddOperation(true);setProductStockModal(true);setCurrentStockVal(item.product_stock)}}>+</Button></span> */}
                                                </td>
                                                <td>{item.case_size?<>
                                                    <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setSubOperation(true);setcaseSizeModal(true);setCurrentCaseValue(item.product_stock/item.case_size);setActualCaseSize(item.case_size)}}>-</Button></span>
                                                    <span style={{width:"38px",display:"inline-block"}}>
                                                    {item.item_id===itemIdToChange?caseSizeDom?caseSizeDom:item.product_stock/item.case_size:item.product_stock/item.case_size}</span>
                                                    <span><Button variant="primary" onClick={()=>{setItemIdToChange(item.item_id);setAddOperation(true);setcaseSizeModal(true);setCurrentCaseValue(item.product_stock/item.case_size);setActualCaseSize(item.case_size)}}>+</Button></span></>:""}
                                                </td>
                                                </>
                                                )}
                                                <td>
                                                    <span style={item.stock_ind =="Y"?{ backgroundColor:"grey",color:"white",width:"35px",height:"30px",display:"inline-block"}:{backgroundColor:"red",color:"white",width:"35px",height:"30px",display:"inline-block"}}>
                                                    
                                                    {item.stock_ind}
                                                    </span>
                                                    
                                                    </td>
                                                    <td>
                                                    {item.category} - {item.sub_category}
                                                </td>
                                            </tr>
                                        )):<tr><td colSpan="7" rowSpan="10"><div style={{display:"inline-block"}}>No results found...</div></td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                            </>
                    :"Loading..."}
        </div>
        {productStockModal && <ProductStockModal
        show = {productStockModal}
        onHide = {()=>setProductStockModal(false)}
        itemIdToEdit = {itemIdToChange}
        initialDomValue = {updatedDomValue?updatedDomValue:""}
        updatedStockValue = {setStockUpdatedValue}
        currentOperation = {addOperation?"Add":"Subtract"}
        currentStockValue = {currentStockVal}
        />}
        {caseSizeModal && <CaseSizeModal
        show = {caseSizeModal}
        onHide = {()=>setcaseSizeModal(false)}
        itemIdToEdit = {itemIdToChange}
        initialCaseDomValue = {caseSizeDom?caseSizeDom:""}
        updatedcaseSize = {setCaseUpdatedValue}
        currentOperation = {addOperation?"Add":"Subtract"}
        currentcaseSize = {currentCaseValue}
        />}
        {addItemModal && <AddItem
            show = {addItemModal}
            onHide = {()=>setAddItemModal(false)}
            itemIdToEdit = {itemIdToChange}
            updateItem = {setUpdateItem}
            stockApiItems = {stockApiItems}
            />
            

        }
        { deleteItemModal && <DeleteItem
            show = {deleteItemModal}
            onHide = {()=>setDeleteItemModal(false)}
            itemIdToEdit = {itemIdToChange}
            deleteItem = {setDeleteItem}
            />
            
        }
        { editItemModal && <EditItem
            show = {editItemModal}
            onHide = {() => setEditItemModal(false)}
            itemIdToEdit = {itemIdToChange}
            editItem = {setEditItem}
            />
            
        }
        <br/><br/><br/><br/><br/>
        <FooterComponent/>
        </div>
            )
        }
        
        export default EditStock
