import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";
import { Badge, Form, Modal, Navbar, Jumbotron, InputGroup, Button, Alert, ButtonGroup, Table } from 'react-bootstrap'
import Select from 'react-select';
import database from '../../service/firebaseConfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import Backdrop from '../../CommonRequirements/backdrop'
import { toast } from 'react-toastify';
import ConvertedStock from './ConvertedStock';
import baseUrl from '../../service/servicesConfig';
import { validateIsAdminUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';

const Product_Base = database.database().ref(`Product_Base_Data`)

const StockConversionModal = ({ itemData, majorCat, parentItemCode, nonConvertedStock, ...props }) => {

    const [currentDate, setCurrentDate] = useState(new Date())
    const [articleCodesList, setArticleCodesList] = useState("")
    const [childItemCode, setChildItemCode] = useState("")
    const [childWeight, setChildWeight] = useState("")
    const [actualChildWeight, setActualChildWeight] = useState("")
    const [childUoM, setChildUoM] = useState("")
    const [wastage, setWastage] = useState(0)
    const [childStock, setChildStock] = useState("")
    const [onceDisplay, setOnceDisplay] = useState(true)
    const [backDrop, setBackDrop] = useState(false)
    const [previouslySelected, setPreviouslySelected] = useState("")
    const { control, errors, handleSubmit, watch } = useForm();
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

    let articleCodeSelected = watch("child_item_name")

    console.log('data: ', itemData);

    if (articleCodeSelected && articleCodeSelected != previouslySelected) {
        setPreviouslySelected(articleCodeSelected);
        setBackDrop(true)
        Product_Base.once("value", snapShot => {

            const productDetails = snapShot.val()
            Object.keys(productDetails).forEach(itemCode => {
                let item_description = productDetails[itemCode].brand ? productDetails[itemCode].brand + " | " + productDetails[itemCode].long_description : productDetails[itemCode].long_description;
                if (articleCodeSelected.value.includes(item_description)) {
                    setChildItemCode(itemCode)
                    setChildWeight(productDetails[itemCode].outer_size && productDetails[itemCode].outer_size)
                    setActualChildWeight(getActualChildWeight(productDetails[itemCode].outer_size, productDetails[itemCode].weight, productDetails[itemCode].uom));
                    setChildUoM(productDetails[itemCode].uom)
                }
            })
        }).then(() => setBackDrop(false))
    }

    const getActualChildWeight = (outer_size, weight, uom) => {
        if (uom.toLowerCase() == 'pc' && weight.includes("gm")) {
            outer_size = weight.replace(/[^0-9]/g, '') / 1000;
        } else if (uom.toLowerCase() == 'pc' && weight.toLowerCase().includes("kg")) {
            outer_size = weight.replace(/[^0-9]/g, '');
        }
        return outer_size;
    }


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
        setBackDrop(true)
        Product_Base.once("value", snapShot => {

            let articleCodesArray = []
            const articleCodeList = snapShot.val()
            Object.keys(articleCodeList).forEach(articleCode => {

                if (articleCodeList[articleCode].parent_id && articleCodeList[articleCode].parent_id.includes(parentItemCode))
                    articleCodesArray.push({ value: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description, label: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description })
                if (articleCodeList[articleCode].parent_id1 && articleCodeList[articleCode].parent_id1.includes(parentItemCode))
                    articleCodesArray.push({ value: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description, label: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description })


            })
            setArticleCodesList(articleCodesArray)
            setBackDrop(false)
        })
    }, [])

    const onSubmitNew = data => {

        setSubmitButtonDisable(true);

        let newDate = currentDate ? currentDate : new Date()
        newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
        let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
        data["date_of_packing"] = newDate
        data["created_by"] = loggedEmailId
        data["child_item_name"] = data["child_item_name"].value
        data["parent_item_code"] = parentItemCode && parentItemCode
        data["major_category"] = majorCat && majorCat
        data["uom"] = childUoM && childUoM
        data["child_item_code"] = childItemCode ? childItemCode : ""
        data["child_weight"] = childWeight ? childWeight : ""
        data["child_item_code"] = childItemCode ? childItemCode : ""
        data["child_stock_production"] = childStock ? childStock : ""
        data["total_weight_packed"] = childStock ? childWeight * childStock : ""
        data["wastage"] = wastage ? wastage : ""
        data["converted_stock"] = actualChildWeight ? wastage ? (actualChildWeight * childStock) + Number(wastage) : (actualChildWeight * childStock) : ""

        // push to API 
        if (data["converted_stock"] > nonConvertedStock) {
            setSubmitButtonDisable(false);
            return toast("Packaged stock can't be more than available limit : " + nonConvertedStock, { type: toast.TYPE.ERROR });
        } else if (data["converted_stock"] == 0) {
            setSubmitButtonDisable(false);
            return toast("Packaged stock can't zero", { type: toast.TYPE.ERROR });
        } else {

            const url = `/api/superzop/commercial/stockconversion/convertedstocks/editconvertedstockandsi?type=add`;

            baseUrl.post(url, data).then(res => {

                console.log('Push Converted Stocks ', res);

                //resolve("Success");

                setTimeout(() => {
                    window.location.reload()
                }, 2000);

                //setSubmitButtonDisable(false);
                return toast('Edited and Synced System Inventory', { type: toast.TYPE.SUCCESS });

            }).catch((error) => {

                console.error(error);
                //reject(error);
                setSubmitButtonDisable(false);
                return toast('Failed to Edit', { type: toast.TYPE.SUCCESS });
            });
        }
    }

    const onSubmit = data => {

        let newDate = currentDate ? currentDate : new Date()
        newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
        let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
        data["date_of_packing"] = newDate
        data["created_by"] = loggedEmailId
        data["child_item_name"] = data["child_item_name"].value
        data["parent_item_code"] = parentItemCode && parentItemCode
        data["major_category"] = majorCat && majorCat
        data["uom"] = childUoM && childUoM
        data["child_item_code"] = childItemCode ? childItemCode : ""
        data["child_weight"] = childWeight ? childWeight : ""
        data["child_item_code"] = childItemCode ? childItemCode : ""
        data["child_stock_production"] = childStock ? childStock : ""
        data["total_weight_packed"] = childStock ? childWeight * childStock : ""
        data["wastage"] = wastage ? wastage : ""
        data["converted_stock"] = actualChildWeight ? wastage ? (actualChildWeight * childStock) + Number(wastage) : (actualChildWeight * childStock) : ""

        // push to API 
        if (data["converted_stock"] > nonConvertedStock) {
            return toast("Packaged stock can't be more than available limit : " + nonConvertedStock, { type: toast.TYPE.ERROR });
        } else if (data["converted_stock"] == 0) {
            return toast("Packaged stock can't zero", { type: toast.TYPE.ERROR });
        } else {

            const save_converted_stock_url = `/api/superzop/commercial/stockconversion/convertedstocks/saveconvertedstocksintotable?key=${data["parent_item_code"]}`;
            baseUrl.post(save_converted_stock_url, data).then(response => {
                if (response.status === 200) {
                    return response.data;
                }
            }).then(res => {
                console.log(res);
                props.onHide()
                setTimeout(() => {
                    window.location.reload()
                }, 3000);

                /*let date = new Date();
                date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

                //const sync_sys_inv_url = `/api/superzop/systeminventory/savesysteminventoryintotable?start_date=${newDate}&end_date=${date}&item_id=${parentItemCode}`
                const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${newDate}&end_date=${date}&item_id=${parentItemCode}`;

                baseUrl.post(sync_sys_inv_url).then(res => {

                    console.log('isAdmin updatePurchaseDetails ', res);
                    return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                }).catch((error) => {
                    console.log(error);
                });*/

                const item_id = data.child_item_code ? data.child_item_code : parentItemCode;
                //const old_converted_stock = itemData.converted_stock;
                //const old_dump_date = data.dump_date;

                const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${newDate}&item_id=${item_id}`;

                const obj = {
                    converted_stock: data.converted_stock
                }

                console.log('url: ', url, ' obj: ', obj)

                baseUrl.put(url, obj).then(res => {

                    console.log('Synced System Inventory ', res);
                    return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                }).catch(error => {
                    console.log(error);
                })

                return toast("Converted Stock conversion record", { type: toast.TYPE.SUCCESS });
            }).catch(error => {
                console.log(error)
                props.onHide()
                return toast("Couldn't convert the stock conversion record", { type: toast.TYPE.ERROR });

            });

            /*fetch(`https://services2.superzop.com/api/superzop/commercial/stockconversion/convertedstocks/saveconvertedstocksintotable?key=${data["parent_item_code"]}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => Promise.resolve(response))
                .then(data => {

                    console.log(data)
                    props.onHide()
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                    return toast("Converted Stock conversion record", { type: toast.TYPE.SUCCESS });

                })
                .catch((error) => {

                    console.log(error)
                    props.onHide()
                    return toast("Couldn't convert the stock conversion record", { type: toast.TYPE.ERROR });
                });*/
        }
    }



    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    )
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Convert Stock
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={/*handleSubmit(onSubmit)*/handleSubmit(onSubmitNew)}>
                    <Table>
                        <tr>
                            <td>Date of packaging</td>
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                <td>
                                    <DatePicker name={"dateField"} selected={currentDate}
                                        onChange={(val) => { setCurrentDate(val) }}
                                        minDate={new Date("2017/01/01")}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<CustomDateInput />} />
                                    {errors.dateField && "date is required"}
                                </td> :
                                <td>
                                    <DatePicker name={"dateField"} selected={currentDate}
                                        onChange={(val) => { setCurrentDate(val) }}
                                        minDate={new Date(new Date(new Date().setDate(new Date().getDate() - 4)))}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<CustomDateInput />} />
                                    {errors.dateField && "date is required"}
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>Major Category</td>
                            <td><Form.Control name={"major_category"} readOnly value={majorCat && majorCat} /></td>
                        </tr>
                        <tr>
                            <td>Parent Item Code</td>
                            <td><Form.Control name={"parent_item_code"} readOnly value={parentItemCode && parentItemCode} /></td>
                        </tr>
                        <tr>
                            <td>Child Item Name</td>
                            <td>
                                <Controller
                                    as={Select}
                                    name="child_item_name"
                                    options={articleCodesList}
                                    control={control}
                                    rules={{ required: true }}

                                />
                                {errors.child_item_name && <span style={{ color: "red", float: "left" }}>*select child item name</span>}
                            </td>
                        </tr>
                        <tr>
                            <td>Child Item Code</td>
                            <td><Form.Control readOnly name={"child_item_code"} value={childItemCode && childItemCode} /></td>
                        </tr>
                        <tr>
                            <td>Child Weight</td>
                            <td><Form.Control readOnly name={"child_weight"} value={childWeight && childWeight} /></td>
                        </tr>
                        <tr>
                            <td>Child UoM</td>
                            <td><Form.Control readonly name={"child_uom"} value={childUoM && childUoM} /></td>
                        </tr>
                        <tr>
                            <td>Child Stock Production</td>
                            <td><Form.Control name={"child_stock_production"} value={childStock} required onChange={(e) => { setChildStock(e.target.value); }} />
                                {errors.child_stock_production && <span style={{ color: "red", float: "left" }}>*Enter Stock Transfer value</span>}
                            </td>
                        </tr>
                        <tr>
                            <td>Total Weight Packed</td>
                            <td><Form.Control readOnly name={"total_weight_packed"} value={childWeight && childStock && childWeight * childStock} /></td>
                        </tr>
                        <tr>
                            <td>Wastage in KG </td>
                            <td><Form.Control number name={"wastage"} value={wastage} required onChange={(e) => { setWastage(e.target.value); }} />
                                {errors.wastage && <span style={{ color: "red", float: "left" }}>*Enter wastage value</span>}
                            </td>
                        </tr>
                        <tr>
                            <td>Converted Stock in KG</td>
                            <td><Form.Control name={"converted_stock"} value={actualChildWeight ? wastage ? (actualChildWeight * childStock) + Number(wastage) : (actualChildWeight * childStock) : ""} /></td>
                        </tr>
                    </Table>
                    <Button onClick={props.onHide} style={{ float: "right" }}>Close</Button>
                    <Button disabled={submitButtonDisable} type={"submit"} style={{ float: "right", marginRight: 20 }} variant={"success"}>Submit</Button>
                </form>
                {backDrop ? (<Backdrop parentLoadStatus={backDrop} />) : (null)}
            </Modal.Body>
        </Modal>

    )
}

export default StockConversionModal
