import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { forwardRef } from 'react';
import { Table } from 'react-bootstrap';
import database from '../../service/firebaseConfig';
import logo from './sz_logo.png'
const Template = forwardRef((props,ref) => {
    
  return (
    <>
    <div ref = {ref}>
    <Table>
        <tr>
                        <td><img src={logo}  alt={"superzopLogo"} style={{  width: "220px", height: "70px" }} /></td>
                          <td style={{ width: "500px", textAlign: "left" }}><b>Retranz Infolabs Private Limited</b><br />
                          Warehouse No 7, Santosh Warehousing Complex,<br />Village Dhansar, Navi Mumbai, Maharashtra – 410210 <br />
                  Phone : 9901044177<br />
                  Email : support@superzop.com<br />
                  GSTIN : 27AAHCR2573F1ZA <br />
                  FSSAI : 11522998000504</td>
                    </tr>
    </Table>
    <div>
        <div style={{ borderTop: "2px solid black", borderBottom: "2px solid black", textAlign: "center" }}>
                        <b style={{ fontSize: "20px" }}>Suppliers Invoice</b>
                    </div>
    </div>
    <Table>
                            <tr>
                                <td style={{ textAlign: "left" }}><div>
                                    <b>{props.data.supplier_name}</b><br/>
                                    {props.data.bill_address}{" "}{props.data.bill_street}<br />
                                    {props.data.bill_city}{" "}{props.data.bill_pin}<br />
                                    {props.data.bill_state}<br />
                                    {/* {convertCity(orderdetails.city || "")}-{orderdetails.pincode}<br /> */}
                                    ✆{props.data.bill_phone}<br />
                                    GSTIN : {props.data.bill_gstin}<br />
                                   FSSAI : {props.data.bill_fssai}<br />

                                </div></td>
                                <td style={{ width: "400px", textAlign: "left" }}><div><Table borderless style={{ cellSpacing: "0px" }}>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Supplier ID :</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{props.data.supplier_id}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>PO Number :</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{props.data.po_number}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Number :</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{props.data.invoice_number}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{props.data.invoice_date}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>PO Date :</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{props.data.purchased_date}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Items</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>1</td>
                                    </tr>
                                </Table>
                                </div>
                                </td>
                            </tr>
                        </Table>
                        <Table>
                            <thead style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                            <tr>
                                <td style={{paddingRight:"10px"}}><b>Sl No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Item description</b></td>
                                <td style={{paddingRight:"10px"}}><b>HSN Code</b></td>
                                <td style={{paddingRight:"10px"}}><b>Qty/No</b></td>
                                <td style={{paddingRight:"10px"}}><b>Total Weight</b></td>
                                <td style={{paddingRight:"10px"}}><b>Buying Price (per pack)</b></td>
                                <td style={{paddingRight:"10px"}}><b>Amount (Rs.)</b></td>
                                <td style={{paddingRight:"10px"}}><b>CGST</b></td>
                                <td style={{paddingRight:"10px"}}><b>SGST</b></td>
                                <td style={{paddingRight:"10px"}}><b>Total Amount</b></td>
                            </tr>
                        </thead>
                        <tbody style={{padding:"0"}}>
                                        <td style={{paddingRight:"10px"}}>1</td>
                                        <td style={{paddingRight:"10px"}}>{props.data.item_name}</td>
                                        <td style={{paddingRight:"10px"}}>{props.data.hsn_code}</td>
                                        <td style={{paddingRight:"10px"}}>{props.data.bags_received?props.data.bags_received:props.data.bag_purchased}</td>
                                        <td style={{paddingRight:"10px"}}>{props.data.total_weight_received?props.data.total_weight_received:props.data.total_weight}{" "}{props.data.uom}</td>
                                        <td style={{paddingRight:"10px"}}>{props.data.invoice_amt?(props.data.invoice_amt/props.data.total_weight_received?props.data.total_weight_received:props.data.total_weight):props.data.purchase_price}</td>
                                         <td style={{paddingRight:"10px"}}>{props.data.invoice_amt?props.data.invoice_amt:(Number(props.data.purchase_price)*Number(props.data.total_weight))}</td>
                                        <td style={{paddingRight:"10px"}}>{((Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))))/2}</td>
                                        <td style={{paddingRight:"10px"}}>{((Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))))/2}</td>
                                        <td style={{paddingRight:"10px"}}>{(((Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight)))) + (props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))))}</td>


                        </tbody>
                        </Table>
                        <div style={{ borderTop: "1px solid black" }}>
                        <Table ><tr>
                            <td style={{ textAlign: "left" }}>
                                <br /><br /><br />
                                <div style={{textAlign:"center"}}><i>I/We hereby certify that the goods mentioned in the invoice are warranted
                                to be of the same nature<br /><br />
                                Certified that the particulars given above are true and correct</i><br /><br />
                            </div></td>
                            <td style={{ width: "400px", textAlign: "left", cellSpacing: "0px" }}><div>
                                <Table bordered style={{ border: "2px solid black" }}>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Amount (Exl GST)</td>
                                        <td style={{ border: "2px solid black" }}>{props.data.invoice_amt?props.data.invoice_amt:(Number(props.data.purchase_price)*Number(props.data.total_weight))}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>CGST Amount</td>
                                        <td style={{ border: "2px solid black" }}>{((Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))))/2}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>SGST Amount</td>
                                        <td style={{ border: "2px solid black" }}>{((Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))))/2}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}><b>Total Amount</b></td>
                                        <td style={{ border: "2px solid black" }}>{((Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight)))) + (props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight)))}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Cash Discount</td>
                                        <td style={{ border: "2px solid black" }}>{((Number(props.data.cash_discount)/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))))}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Transport Charges</td>
                                        <td style={{ border: "2px solid black" }}>{((Number(props.data.transport_charges))*Number(props.data.total_weight_received?props.data.total_weight_received:props.data.total_weight))}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>APMC Charges</td>
                                        <td style={{ border: "2px solid black" }}>{props.data.apmc_charges_per?((Number(props.data.apmc_charges_per.split("%")[0])/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight)))):0}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}>Scheme Amount</td>
                                        <td style={{ border: "2px solid black" }}>{props.data.scheme_amount}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black" }}><b>Net Amount Payable</b></td>
                                        <td style={{ border: "2px solid black" }}><b>{(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))) - Number(props.data.scheme_amount) +  (Number(props.data?.gst_charges_per ? props.data.gst_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight))) + (Number(props.data?.apmc_charges_per ? props.data.apmc_charges_per.split("%")[0] : "0")/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight)))  + ((Number(props.data.transport_charges))*Number(props.data.total_weight_received?props.data.total_weight_received:props.data.total_weight)) - ((Number(props.data.cash_discount)/100)*(props.data.invoice_amt?Number(props.data.invoice_amt):(Number(props.data.purchase_price)*Number(props.data.total_weight)))) }</b></td>
                                    </tr>

                                </Table><br /><br /><br />
                                For Retranz Infolabs Private Limited<br /><br /><br />
                                <i>(Authorized Signatory)</i>
                            </div></td>
                        </tr></Table>
                    </div>
                        </div>
                        
    </>
  )
})

export default Template;