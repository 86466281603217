import XLSX from 'xlsx'

function ExportStockTake({listedStockTake,multipleExport,exportDate,...props}) {

    const exportStockTake = [["Item Id","Item Name","Category","Sub Category","UOM","Outer Size(Kg/Pc)","System Inventory(Kg/Pc)","Actual Stock in Bag /Box","Actual Loose Stock (Kg/Pc)",
    "Actual Stock (Kg/Pc)","Stock Loss/Gain (Kg/Pc)"]]

    Object.keys(listedStockTake).forEach(item=>{

        exportStockTake.push([listedStockTake[item].article_code,listedStockTake[item].item_name,listedStockTake[item].category,listedStockTake[item].sub_category,listedStockTake[item].UOM,listedStockTake[item].outer_size,
            listedStockTake[item].system_inventory,listedStockTake[item].actual_stock_bag_box,listedStockTake[item].loose_stock,
            listedStockTake[item].actual_stock,listedStockTake[item].approve_stock_loss_gain?listedStockTake[item].approve_stock_loss_gain:listedStockTake[item].stock_loss_gain])
    })

    const wb = XLSX.utils.book_new()

    const stockTakeList = XLSX.utils.aoa_to_sheet(exportStockTake)
    XLSX.utils.book_append_sheet(wb, stockTakeList, `Stock Take Details`)
    XLSX.writeFile(wb, `Stock_Take_Details_${exportDate}.xlsx`);
    multipleExport(false)

    return null
}

export default ExportStockTake
