import React, { useEffect, useRef, useState } from "react";
import { Modal, Table, Form, Button } from "react-bootstrap";
import { deliveryCommercialServer } from '../../service/servicesConfig';
import { toast } from 'react-toastify';
import baseUrl from '../../service/servicesConfig';

const ListGRNOpenModal = (props) => {

    const [grnOpenList, setGrnOpenList] = useState([]);
    const [modalTitle, setModalTitle] = useState("Open GRN Dates: ");
    const [cbGRNCloseList, setCbGRNCloseList] = useState({});
    const [formDatesList, setFormDatesList] = useState({}); // list of dates object mapped to date in yyyy-mm-dd format

    let btnRef = useRef();

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {

        const today = new Date();
        const today_date = today.getDate();
        const today_month = today.getMonth() + 1;
        const today_year = today.getFullYear();
        const today_comp_date = `${today_year}-${today_month}-${today_date}`;

        const last30days = new Date(today.setDate(today.getDate() - 30));
        const last30days_date = last30days.getDate();
        const last30days_month = last30days.getMonth() + 1;
        const last30days_year = last30days.getFullYear();
        const last30days_comp_date = `${last30days_year}-${last30days_month}-${last30days_date}`;

        const url = `/api/superzop/commercial/purchasedetails/getgrncloserecordbasedongrndate?grn_start_date=${last30days_comp_date}&grn_end_date=${today_comp_date}`;

        deliveryCommercialServer.get(url).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {
            //console.log('data: ', data);

            const grnCloseDates = data.filter((item) => {

                return item.grn_entry_close === 1;
            }).map((item) => {
                return item.grn_date.substring(0, 10);
            });

            const grnOpenDates = [];
            const fromDates = {};
            for (let i = 0; i <= 30; i++) {
                let date = new Date();
                date.setDate(date.getDate() - i);
                const dateString = date.toISOString().split('T')[0];
                const dateSplit = date.toDateString().substring(4, 10).split(" ");
                const dateFormated = `${dateSplit[1]}-${dateSplit[0]}`;
                const findGrnClosed = grnCloseDates.find((item) => {
                    return item === dateString;
                });

                if (!findGrnClosed) {

                    //console.log(date.toDateString().substring(4,10));
                    grnOpenDates.push(dateFormated);
                }
                //console.log('findGrnClosed: ',findGrnClosed);
                fromDates[dateFormated] = dateString;
            }
            setFormDatesList(fromDates);

            console.log('grnOpenDates: ', grnOpenDates);
            setGrnOpenList(grnOpenDates);
            setModalTitle(`Open GRN Dates: ${grnOpenDates.length}`);
        }).catch((error) => {
            console.log(error);
        });

    }, []);

    const closeGRNSubmit = () => {

        const today = new Date();
        const date = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
        const time = `${today.getHours() < 10 ? "0" + today.getHours() : today.getHours()}:${today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()}:${today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds()}`;

        const dateTime = date + ' ' + time;

        console.log(" closeGRNSubmit ", cbGRNCloseList)

        if (Object.keys(cbGRNCloseList).length === 0) {

            return toast("Please Select Dates to Close GRN", { type: toast.TYPE.ERROR });
        }

        for (let key in cbGRNCloseList) {
            if (cbGRNCloseList.hasOwnProperty(key)) {
                console.log(key + " -> " + cbGRNCloseList[key] + formDatesList[key]);

                const jsonObj = {
                    "grn_date": formDatesList[key],
                    "grn_entry_close": cbGRNCloseList[key],
                    "updated_by": "Admin",
                    "updated_at": dateTime
                }

                const url = "/api/superzop/commercial/purchasedetails/creategrnentrycloserecord";

                //baseUrl.post(url, jsonObj).then(res => {
                baseUrl.post(url, jsonObj).then(res => {

                    //console.log('closeGRNEntry ', res);
                    if (res.status === 200) {

                        toast(`Closed GRN for ${formDatesList[key]} date successfully`, { type: toast.TYPE.SUCCESS });
                        //return null;
                    }
                }).catch((error) => {

                    console.log(error);
                });
            }
        }

        setTimeout(() => {
            window.location.reload()
        }, 3000);
    }

    const handleChange = (event) => {

        console.log(" handleChange ", event.target.checked);
        console.log(" handleChange name: ", event.target.name);
        const obj = {
            ...cbGRNCloseList,
        }

        if (event.target.checked)
            obj[event.target.name] = event.target.checked;
        else {

            delete obj[event.target.name];
        }

        setCbGRNCloseList(obj);
    }

    return (
        <Form >
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                </tr>
                            </thead>
                            <tbody>
                                {grnOpenList ?
                                    Object.keys(grnOpenList).map((items, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        name={grnOpenList[items]}
                                                        label={grnOpenList[items]}
                                                        id={`inline-${index}`} />
                                                </td>
                                            </tr>
                                        </>
                                    )) : null}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" onClick={() => { closeGRNSubmit() }} ref={btnRef}>Close GRN</Button>
                </Modal.Footer>

            </Modal>
        </Form>
    )
}

export default ListGRNOpenModal;