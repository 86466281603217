import { useListState } from "@mantine/hooks";
import React, { createContext, useReducer, useState } from "react";

export const WidgetsListContext = createContext();

export const WidgetsListProvider = ({ children }) => {
  const contextReducer = (state, action) => {
    switch (action.type) {
      case "populateWidgets": {
        return { ...state, widgetsList: action.payload.widgetsList };
      }

      // case addNewWidget: {
      //   return {
      //     ...state,
      //     widgetsList: [...state.widgetsList, action.payload.widget],
      //   };
      // }
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const initialState = {
    widgetsList: [],
  };

  const [state, dispatch] = useReducer(contextReducer, initialState);

  return (
    <WidgetsListContext.Provider value={{ state, dispatch }}>
      {children}
    </WidgetsListContext.Provider>
  );
};
