import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal,Badge } from 'react-bootstrap'
import qualitydb from '../../service/qualityfirebaseconfig';
import { toast } from 'react-toastify';
import UpdateFullGrtDetailsModal from './UpdateFullGrtDetails'
import './modal.css';



function UpdateGrtDetailsModal({updateDetails,...props}) {
    const [itemDetailsObject, setItemDetailsObject] = useState({})
    const [retailerData, setRetailerData] = useState("")
    const [grtProdData,setGrtProdData] = useState([])
    const [modalHeaderContent,setModalHeaderContent] = useState({});
    const [updateFullGrtDetails, setUpdateFullGrtDetails] = useState(false)
    const [grtFullDetails, setGrtFullDetails] = useState("")


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const getResult = (criteria,thresh1,thresh2, sample) => {
      let result;
      if(criteria == 'Higher') {
        if(sample>thresh1){
          result="Accept";
        }else{
          if(sample>thresh2){
            result="Warn";
          }else{
            result="Reject";
          }
        }
      } else {
        if(sample<thresh1){
          result="Accept";
        }else{
          if(sample<thresh2){
            result="Warn";
          }else{
            result="Reject";
          }
        }
      }
      return result;
    }

    const setElementData = (obj,parameter_data) => {
      if(parameter_data) {
        obj['Sample Value'] = parameter_data.sample_value || 'N/A';
        obj['Standard Value'] = parameter_data.standard_value || 'N/A';
        obj['Criteria'] = parameter_data.criteria || 'N/A';
        obj['Threshold Range 1'] = parameter_data.threshold_range_1 || 'N/A';
        obj['Threshold Range 2'] = parameter_data.threshold_range_2 || 'N/A';
        obj['Score'] = parameter_data.score || 'N/A';
        obj['Weightage'] = parameter_data.weightage || 'N/A';
        obj['Result'] = parameter_data.result || 'N/A'; 
      } else {
        obj['Sample Value'] = 'N/A';
        obj['Standard Value'] = 'N/A';
        obj['Criteria'] = 'N/A';
        obj['Threshold Range 1'] = 'N/A';
        obj['Threshold Range 2'] = 'N/A';
        obj['Score'] = 'N/A';
        obj['Weightage'] = 'N/A';
        obj['Result'] = 'N/A';
      }
      return obj;
    }

    useEffect(() => {
        console.log(updateDetails);
        setGrtFullDetails(updateDetails);
        let product_id=updateDetails['Product Code'];
        console.log(product_id);
        let finalProdList=[];
        let parameterList=['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %','moisture','grain_count','dd_count','weeviled_grain_per','sava'];
        if(product_id){
          qualitydb.database().ref(`Product_Master_Grading/`).once('value', function(snapshot) {
            let all_product_data=snapshot.val();
            // console.log(all_product_data);
            let prod_data;
            let prodfalg=false;
            Object.keys(all_product_data).map((pkey,indexx) => {
              if(all_product_data[pkey]['itemnid']==product_id && prodfalg==false){
                prod_data=all_product_data[pkey];
                prodfalg=true;
              }
            });
            console.log('prod_data');
            console.log(prod_data);
            let propertyNames = Object.keys(prod_data);
            let propertyValues = Object.values(prod_data);
            let entries = Object.entries(prod_data);
            console.log('entries');
            console.log(entries);
            if(prod_data!=undefined){
              let index_score_sum=0;
              for(let i=0;i<9;i++){
                let ele={};
                ele['Parameter']=parameterList[i];
                const scores = updateDetails['scores'];
                const latest_score_ts = Object.keys(scores).sort().reverse()[0]
                const latest_score = scores[latest_score_ts]                
                if(parameterList[i]=='Avg Length (mm)'){
                  const parameter_data = latest_score.length
                  setElementData(ele,parameter_data)
                
                      // // let length_threshold_per=(prod_data['length_threshold_per']!=undefined?prod_data['length_threshold_per']:"5%");
                      // // length_threshold_per = parseInt(length_threshold_per.substring(0, length_threshold_per.length - 1));
                      // // let standard_length=prod_data['length'];
                      // // standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                      // // let thresh1=prod_data['length_threshold_abs']?parseFloat(prod_data['length_threshold_abs']):standard_length*(1+(length_threshold_per/100));
                      // // thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                      // // let sample_length=updateDetails['Length'];
                      // // sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));
                      // // let criteria="Higher";
                      // // let score=sample_length/standard_length;
                      // // score=Math.round((score + Number.EPSILON) * 100);

                      // ele['Sample Value']=updateDetails['Length'];
                      // ele['Standard Value']=prod_data['length'];
                      // ele['Criteria']=criteria;


                      // ele['Threshold Range 1']=thresh1;

                      // // let length_threshold_per2=(prod_data['length_threshold_per2']!=undefined?prod_data['length_threshold_per2']:"10%");
                      // // length_threshold_per2 = parseInt(length_threshold_per2.substring(0, length_threshold_per2.length - 1));
                      // // let thresh2=prod_data['length_threshold_abs2']?parseFloat(prod_data['length_threshold_abs2']):standard_length*(1+(length_threshold_per2/100));
                      // // thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                      // // let result = getResult(criteria,thresh1,thresh2,sample_length)

                      // // let weightage=(prod_data['length_weightage_per']!=undefined?prod_data['length_weightage_per']:"10%");
                      // // weightage = parseInt(weightage.substring(0, weightage.length - 1));
                      // // index_score_sum=index_score_sum+(score*weightage);

                      // ele['Threshold Range 2']=thresh2;
                      // ele['Score']=score+'%';
                      // ele['Weightage']=weightage;
                      // ele['Result']=result;
                }else if(parameterList[i]=='Avg Width (mm)'){
                  const parameter_data = latest_score.width
                  setElementData(ele,parameter_data)

                      // // let width_threshold_per=(prod_data['width_threshold_per']!=undefined?prod_data['width_threshold_per']:"3%");
                      // // width_threshold_per = parseInt(width_threshold_per.substring(0, width_threshold_per.length - 1));
                      // // let standard_width=prod_data['width'];
                      // // standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));
                      // // let thresh1=prod_data['width_threshold_abs']?parseFloat(prod_data['width_threshold_abs']):standard_width*(1+(width_threshold_per/100));
                      // // thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                      // // let sample_width=updateDetails['Width'];
                      // // sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));
                      // // let criteria;
                      // // let score;

                      // // if(prod_data['sub_category'].toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati") || updateDetails.sub_category.toLowerCase().includes("wheat")){
                      // //   criteria="Higher";
                      // //   score=sample_width/standard_width;
                      // //   score=Math.round((score + Number.EPSILON) * 100);
                      // // }else{
                      // //   criteria="Lower";
                      // //   score=standard_width/sample_width;
                      // //   score=Math.round((score + Number.EPSILON) * 100);
                      // // }

                      // ele['Sample Value']=updateDetails['Width'];
                      // ele['Standard Value']=prod_data['width'];
                      // ele['Criteria']=criteria;


                      // ele['Threshold Range 1']=thresh1;

                      // // let width_threshold_per2=(prod_data['width_threshold_per2']!=undefined?prod_data['width_threshold_per2']:"5%");
                      // // width_threshold_per2 = parseInt(width_threshold_per2.substring(0, width_threshold_per2.length - 1));
                      // // let thresh2=prod_data['width_threshold_abs2']?parseFloat(prod_data['width_threshold_abs2']):standard_width*(1+(width_threshold_per2/100));
                      // // thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                      // // let result = getResult(criteria,thresh1,thresh2,sample_width)


                      // // let weightage=(prod_data['width_weightage_per']!=undefined?prod_data['width_weightage_per']:"35%");
                      // // weightage = parseInt(weightage.substring(0, weightage.length - 1));
                      // // index_score_sum=index_score_sum+(score*weightage);

                      // ele['Threshold Range 2']=thresh2;
                      // ele['Score']=score+'%';
                      // ele['Weightage']=weightage;
                      // ele['Result']=result;
                }else if(parameterList[i]=='LW Ratio'){
                  const parameter_data = latest_score.lw_ratio
                  setElementData(ele,parameter_data)

                      // // let length_width_threshold_per=(prod_data['length_width_threshold_per']!=undefined?prod_data['length_width_threshold_per']:"3%");
                      // // length_width_threshold_per = parseInt(length_width_threshold_per.substring(0, length_width_threshold_per.length - 1));
                      // // let standard_length=prod_data['length'];
                      // // standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                      // // let standard_width=prod_data['width'];
                      // // standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));

                      // // let standard_length_width=standard_length/standard_width;  // TO BE DONE ---------------------------------------------------------------------------
                      // // standard_length_width = Math.round((standard_length_width + Number.EPSILON) * 100) / 100;
                      // // let thresh1=prod_data['length_width_threshold_abs']?parseFloat(prod_data['length_width_threshold_abs']):standard_length_width*(1+(length_width_threshold_per/100));
                      // // thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                      // // let sample_length=updateDetails['Length'];
                      // // sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));
    
                      // // let sample_width=updateDetails['Width'];
                      // // sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));
    
                      // // let sample_length_width=sample_length>sample_width?parseFloat(sample_length/sample_width):parseFloat(sample_width/sample_length); 
                      // // let criteria;
                      // // let score;
                      // // if(updateDetails.sub_category.toLowerCase().includes("wheat")){
                      // //   criteria="Lower";
                      // //   score=standard_length_width/sample_length_width;
                      // //   score=Math.round((score + Number.EPSILON) * 100);
                      // // }else{
                      // //   criteria="Higher";
                      // //   score=sample_length_width/standard_length_width;
                      // //   score=Math.round((score + Number.EPSILON) * 100);
                      // // }

                      // ele['Sample Value']=updateDetails['LW Ratio'];
                      // ele['Standard Value']=standard_length_width;
                      // ele['Criteria']=criteria;
                      // ele['Threshold Range 1']=thresh1;

                      // // let length_width_threshold_per2=(prod_data['length_width_threshold_per2']!=undefined?prod_data['length_width_threshold_per2']:"5%");
                      // // length_width_threshold_per2 = parseInt(length_width_threshold_per2.substring(0, length_width_threshold_per2.length - 1));
                      // // let thresh2=prod_data['length_width_threshold_abs2']?parseFloat(prod_data['length_width_threshold_abs2']):standard_length_width*(1+(length_width_threshold_per2/100));
                      // // thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                      // // let result = getResult(criteria,thresh1,thresh2,sample_length_width)

                      // // let weightage=(prod_data['length_width_weightage_per']!=undefined?prod_data['length_width_weightage_per']:"40%");
                      // // weightage = parseInt(weightage.substring(0, weightage.length - 1));
                      // // index_score_sum=index_score_sum+(score*weightage);

                      // ele['Threshold Range 2']=thresh2;
                      // ele['Score']=score+'%';
                      // ele['Weightage']=weightage;
                      // ele['Result']=result;
                }else if(parameterList[i]=='Broken %'){
                  const parameter_data = latest_score.broken_percentage
                  setElementData(ele,parameter_data)

                      // // let broken_threshold_per=(prod_data['broken_threshold_per']!=undefined?prod_data['broken_threshold_per']:"5%");
                      // // broken_threshold_per = parseFloat(broken_threshold_per.substring(0, broken_threshold_per.length - 1));
                      // // let standard_broken_perc=prod_data['broken_grain_percentage'];
                      // // standard_broken_perc = parseFloat(standard_broken_perc.substring(0, standard_broken_perc.length - 1));
                      // // let thresh1=broken_threshold_per;

                      // // let sample_broken_perc=updateDetails['BrokenGrainPerc'];
                      // // sample_broken_perc = parseFloat(sample_broken_perc.substring(0, sample_broken_perc.length - 1));
                      // // let criteria="Lower";;
                      // // let score=sample_broken_perc >0?standard_broken_perc/sample_broken_perc:1;
                      // // score=Math.round((score + Number.EPSILON) * 100);

                      // // let broken_threshold_per2=(prod_data['broken_threshold_per2']!=undefined?prod_data['broken_threshold_per2']:"20%");
                      // // broken_threshold_per2 = parseFloat(broken_threshold_per2.substring(0, broken_threshold_per2.length - 1));
                      // // let thresh2=broken_threshold_per2;

                      // // let result = getResult(criteria,thresh1,thresh2,sample_broken_perc)

                      // // let weightage=(prod_data['broken_weightage_per']!=undefined?prod_data['broken_weightage_per']:"15%");
                      // // weightage = parseInt(weightage.substring(0, weightage.length - 1));

                      // if(updateDetails.sub_category.toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati"))
                      //   {
                      //   ele['Sample Value']=updateDetails['BrokenGrainPerc'];
                      //   ele['Standard Value']='N/A';
                      //   ele['Criteria']=criteria;
                      //   ele['Threshold Range 1']='N/A';
                      //   ele['Threshold Range 2']='N/A';
                      //   ele['Score']='N/A';
                      //   ele['Weightage']='N/A';
                      //   ele['Result']='N/A';
                      // }else{
                      //   ele['Sample Value']=updateDetails['BrokenGrainPerc'];
                      //   ele['Standard Value']=prod_data['broken_grain_percentage'];
                      //   ele['Criteria']=criteria;
                      //   ele['Threshold Range 1']=thresh1+'%';
                      //   index_score_sum=index_score_sum+(score*weightage);
                      //   ele['Threshold Range 2']=thresh2+'%';
                      //   ele['Score']=score+'%';
                      //   ele['Weightage']=weightage;
                      //   ele['Result']=result;
                      // }
                } else {
                  const parameter_data = latest_score[parameterList[i]]
                  setElementData(ele,parameter_data)                  
                }
                finalProdList.push(ele);
              }
              // console.log(finalProdList);
              let overallResult;
              // let accept_counter=0;
              // let warn_counter=0;
              // let reject_counter=0;
              // for(let x=0;x<finalProdList.length;x++){
              //   if(!(finalProdList[x]['Parameter']=="Broken %" && (updateDetails.sub_category.toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati")))){
              //     let orig_result=finalProdList[x]['Result'];
              //     if(orig_result=="Accept"){
              //       accept_counter=accept_counter+1;
              //     }else if(orig_result=="Warn"){
              //       warn_counter=warn_counter+1;
              //     }else if(orig_result=="Reject"){
              //       reject_counter=reject_counter+1;
              //     }
              //   }
              // }

              // if(updateDetails.sub_category.toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati")){
              //   if(reject_counter>0){
              //     overallResult="Reject";
              //   }else if(accept_counter>1){
              //     overallResult="Accept";
              //   }else{
              //     overallResult="Warn";
              //   }
              // }else{
              //   if(reject_counter>0){
              //     overallResult="Reject";
              //   }else if(accept_counter>2){
              //     overallResult="Accept";
              //   }else{
              //     overallResult="Warn";
              //   }
              // }


              // index_score_sum=index_score_sum/100;
              // index_score_sum=Math.round((index_score_sum + Number.EPSILON) * 100) / 100;
              // console.log('index_score_sum'+index_score_sum);

              setModalHeaderContent({'overall_index_score':updateDetails['Index Score'],'overall_result':updateDetails['Result'],'sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name'], 'brand':updateDetails['brand']});
              setGrtProdData(finalProdList);
              // let updateOrigGrtSampleRowValues=updateDetails;
              // updateOrigGrtSampleRowValues['Index Score']=updateDetails['Index Score'];
              // updateOrigGrtSampleRowValues['Result']=updateDetails['Result'];
            }else{
              setModalHeaderContent({'overall_index_score':'','overall_result':'','sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name'], 'brand':updateDetails['brand']});
              setGrtProdData([]);
            }

          });
        }else{
          //product_id is not available
          setModalHeaderContent({'overall_index_score':'N/A','overall_result':'N/A','sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name'], 'brand':updateDetails['brand']});
          let defaultProdList=[];
          for(let i=0;i<4;i++){
            let ele={};
            ele['Parameter']=parameterList[i];
            //['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %']
            if(parameterList[i]=='Avg Length (mm)'){
              ele['Sample Value']=updateDetails['Length'];
            }else if(parameterList[i]=='Avg Width (mm)'){
              ele['Sample Value']=updateDetails['Width'];
            }else if(parameterList[i]=='LW Ratio'){
              ele['Sample Value']=updateDetails['LW Ratio'];
            }else if(parameterList[i]=='Broken %'){
              ele['Sample Value']=updateDetails['BrokenGrainPerc'];
            }

            ele['Standard Value']='N/A';
            ele['Threshold Range 1']='N/A';
            ele['Threshold Range 2']='N/A';
            ele['Weightage']='N/A';
            ele['Score']='N/A';
            ele['Result']='N/A';
            defaultProdList.push(ele);
            setGrtProdData(defaultProdList);
          }
        }

    }, [])


    return (<>
            <Modal
                {...props}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    <center>Overall Result</center>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{backgroundColor: "yellow", opacity: 0.7,padding:10,marginBelow:10}}>
                  <div className="row">
                    <div className="col">
                      <Badge color="primary">Sample ID : {modalHeaderContent['sample_id']}</Badge>
                    </div>
                    <div className="col">
                    <Badge color="light">Product Name : {modalHeaderContent['product_name']}</Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                    <Badge color="primary">Category : {modalHeaderContent['category']}</Badge>
                    </div>
                    <div className="col">
                    <Badge color="primary">Product ID : {modalHeaderContent['product_id']}</Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                    <Badge color="primary">Brand : {modalHeaderContent['brand']}</Badge>
                    </div>
                    <div className="col">
                    <Badge color="primary">Supplier Name : {modalHeaderContent['supplier_name']}</Badge>
                    </div>
                  </div>
                </div>
                {(grtProdData.length>0)?(
                <Table>
                  <thead>
                    <tr>
                      <th>Parameter</th>
                      <th>Sample Value</th>
                      <th>Standard Value</th>
                      <th>Threshold Range 1</th>
                      <th>Threshold Range 2</th>
                      <th>Weightage</th>
                      <th>Score</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(grtProdData.length>0) ? grtProdData.map(prod => (
                        <tr>
                            <td><b>{prod['Parameter']}</b></td>
                            <td>{prod['Sample Value']}</td>
                            <td>{prod['Standard Value']}</td>
                            <td>{prod['Threshold Range 1']}</td>
                            <td>{prod['Threshold Range 2']}</td>
                            <td>{prod['Weightage']}</td>
                            <td>{prod['Score']}</td>
                            <td>{prod['Result']}</td>
                        </tr>
                      )) :null}
                      </tbody>
                    </Table>):(<div></div>)}
                    <div style={{backgroundColor: "lightgrey", opacity: 0.7,padding:10}} >
                      <br/>
                      <div className="row" style={{marginLeft:30}} >
                          <p>Overall Index Score: <b>{modalHeaderContent['overall_index_score']}</b></p>
                      </div>
                      <div className="row" style={{marginLeft:30}}>
                          <p>Overall Result: <b>{modalHeaderContent['overall_result']}</b></p>
                      </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{setUpdateFullGrtDetails(true);}}>Detailed Report</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>

            {updateFullGrtDetails &&<UpdateFullGrtDetailsModal
                      show={updateFullGrtDetails}
                      onHide={() => setUpdateFullGrtDetails(false)}
                      updateFullDetails = {grtFullDetails}
                  />}
        </>
    )
}

export default UpdateGrtDetailsModal
