import React, { useState, useEffect,useRef, useContext, Fragment } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Breadcrumb, Tab,Form, Row, Col, Nav, Button,InputGroup,Badge,Dropdown, DropdownButton } from 'react-bootstrap';
import Select from 'react-select';
import qualitydb from '../../service/qualityfirebaseconfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import XLSX from 'xlsx'
import database from '../../service/firebaseConfig';
import LoaderComp from '../../components/Loader';
import { validateGRNOnlyUser, validateIsAdminUser, validatePurchaseUser, validatePurchaseExportUser, validatePurchaseExportOnlyUser , validatePurchaseOnlyUser,validatePurchaseReportUser} from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import {Link} from 'react-router-dom';
import PriceUpdatesHistoryTable from './priceupdateshistorytable';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
}

const PriceUpdatesHistory = () => {
  let today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [loading,setLoading] = useState(true);
  const [priceAnalysisList,setPriceAnalysisList]=useState([]);
  const [searchPriceAnalysisList,setSearchPriceAnalysisList]=useState([]);
  const [searchText,setSearchText]=useState("");
  const [priceChangeText,setPriceChangeText]=useState("All");
  const [updateFromFilter,setUpdateFromFilter]=useState("All");

  useEffect(()=>{
    fetchProducts(startDate);
  },[]);

  useEffect(() => {
      if (searchText){
        filterProductPriceDetails();
      }else{
        setPriceAnalysisList(searchPriceAnalysisList);
      }
  }, [searchText])

  const filterProductPriceDetails = () => {
    console.log('filterProductPriceDetails');
    console.log(searchText);

    let checksearchtext=searchText.trim().toLowerCase();
    let filteredData=[];
    for(let i=0;i<searchPriceAnalysisList.length;i++){
      let pushflag=false;
      let itemname=searchPriceAnalysisList[i]['item_name'];
      let itemid=searchPriceAnalysisList[i]['item_id'];
      let category=searchPriceAnalysisList[i]['category'];

      if(itemname){
        itemname=itemname.trim().toLowerCase();
        if(itemname.includes(checksearchtext)){
          pushflag=true;
          filteredData.push(searchPriceAnalysisList[i]);
        }
      }

      if(itemid && pushflag==false){
        itemid=itemid.trim().toLowerCase();
        if(itemid.includes(checksearchtext)){
          filteredData.push(searchPriceAnalysisList[i]);
          pushflag=true;
        }
      }

      if(category && pushflag==false){
        category=category.trim().toLowerCase();
        if(category.includes(checksearchtext)){
          filteredData.push(searchPriceAnalysisList[i]);
          pushflag=true;
        }
      }
    }
    setPriceAnalysisList(filteredData);
  }

  useEffect(()=>{
    filterProductsByUpdatedFrom();
  },[updateFromFilter]);

  const filterProductsByUpdatedFrom = () => {
    console.log(updateFromFilter);
    let filteredData=[];
    if(updateFromFilter=="All"){
      setPriceAnalysisList(searchPriceAnalysisList);
    }else{
      for(let i=0;i<searchPriceAnalysisList.length;i++){
        let updated_from=searchPriceAnalysisList[i]['updated_from'];

        if(updateFromFilter=="Price Update"){
          if(updated_from=="Price Update"){
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }else if(updateFromFilter=="Price Edit"){
          if(updated_from=="Edit Price"){
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }else if(updateFromFilter=="DealerPrice Edit"){
          if(updated_from=="DealerPrice Edit"){
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }
      }
      setPriceAnalysisList(filteredData);
    }
  }

  useEffect(()=>{
    filterProductsByPriceChange();
  },[priceChangeText])

  const filterProductsByPriceChange = () => {
    console.log(priceChangeText);
    let filteredData=[];
    if(priceChangeText=="All"){
      setPriceAnalysisList(searchPriceAnalysisList);
    }else{
      for(let i=0;i<searchPriceAnalysisList.length;i++){
        let oldBasePrice=Number(searchPriceAnalysisList[i]['old_base_price']);
        let basePrice=Number(searchPriceAnalysisList[i]['base_price']);

        if(priceChangeText=="Increased"){
          if(basePrice>oldBasePrice){
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }else if(priceChangeText=="Decreased"){
          if(basePrice<oldBasePrice){
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }else if(priceChangeText=="No Change"){
          if(basePrice==oldBasePrice){
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }
      }
      setPriceAnalysisList(filteredData);
    }
  }


  const fetchProducts = (startdt) => {
    setLoading(true);
    let finalDate=formatDate(new Date(startdt));
    let finalYear=finalDate.split('-')[2];
    let finalMonth=finalDate.split('-')[1];

    Promise.all([
      database.database().ref(`Pending_Price_Update/${finalYear}/${finalMonth}/${finalDate}`).once('value'),
      database.database().ref(`Product_Master_New/A/`).once('value')
    ]).then(([snapshot, productsnap]) => {
        let productdataobj=productsnap.val();

        if(snapshot.hasChildren()){
          let pendingproductobj=snapshot.val();
          let finalProductData=[];
          Object.keys(pendingproductobj).map((key,value)=>{
            let ele=pendingproductobj[key];
            let itemid=pendingproductobj[key]['item_id'];
            if(productdataobj[itemid]){
              ele['item_name']=`${productdataobj[itemid]['brand']} - ${productdataobj[itemid]['long_description']}`;
              ele['category']=productdataobj[itemid]['category'];
            }
            finalProductData.push(ele);
          });
          console.log(finalProductData);
          setPriceAnalysisList(finalProductData);
          setSearchPriceAnalysisList(finalProductData);
          setLoading(false);
        }else{
          setPriceAnalysisList([]);
          setSearchPriceAnalysisList([]);
          setLoading(false);
        }
    });
  }

  const handleDateChange = (selecteddate) => {
    setPriceAnalysisList([]);
    setSearchPriceAnalysisList([]);
    setPriceChangeText("All");
    setLoading(true);
    setStartDate(new Date(selecteddate));
    fetchProducts(selecteddate);
  }

  const exportPriceDetails = () => {
    console.log('exportPriceDetails');
    const wb = XLSX.utils.book_new()

    let priceDetailsSummary = [["ItemId","Item Description","Category","Old BasePrice", "Source Type" , "Mill Transport", "CD%" ,"BasePrice","Old DealerPrice","DealerPrice", "Total Price","GST Charge","Approved","ApprovedBy","ApprovedAt","UpdatedBy","UpdatedAt","UpdatedFrom", "Extra Margin Per", "Retailer Margin Per", "Retailer Margin Per_S"]]

    priceAnalysisList.map(item => {
      let priceDetailsItem = [];
      let currdate = new Date();
      let extra_margin_per='';
      if(item.approved == 'Y' && item.approved_at) {
        const dateString = item.approved_at;
        const [day, month, year] = dateString.split(/[\s:-]/);
        let approvedDate = new Date(year, month - 1, day);
        if(formatDate(currdate) == formatDate(approvedDate)) {
          extra_margin_per = item['extra_margin_per'];
        }
      }
      
      priceDetailsItem.push(Number(item['item_id']))
      priceDetailsItem.push(item['item_name'])
      priceDetailsItem.push(item['category'])
      priceDetailsItem.push(item['old_base_price']?Number(item['old_base_price']):0)
      priceDetailsItem.push(item['source_type'])
      priceDetailsItem.push((item['mill_transport']))
      priceDetailsItem.push((item['cd_per']))
      priceDetailsItem.push(Number(item['base_price']))
      priceDetailsItem.push(Number(item['old_dealer_price']))
      priceDetailsItem.push(Number(item['dealer_price']))
      priceDetailsItem.push(item['total_price'] ? Number(item['total_price']):"")
      priceDetailsItem.push(Number(item['gst_charge']))
      priceDetailsItem.push(item['approved'])
      priceDetailsItem.push(item['approved_by'])
      priceDetailsItem.push(item['approved_at'])
      priceDetailsItem.push(item['updated_by'])
      priceDetailsItem.push(item['updated_at'])
      priceDetailsItem.push(item['updated_from'])
      priceDetailsItem.push(extra_margin_per);
      priceDetailsItem.push(item['retailer_margin_per'])
      priceDetailsItem.push(item['retailer_margin_per_S'])
      priceDetailsSummary.push(priceDetailsItem)
    })
    let priceExportDetails = XLSX.utils.aoa_to_sheet(priceDetailsSummary)
    let wscols = [
        {wch:15},
        {wch:25},
        {wch:15},
        {wch:15},
        {wch:15},
        {wch:15},
        {wch:15},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:20},
        {wch:25}
    ];

    priceExportDetails['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, priceExportDetails, `Price Details`)
    let finalDate=formatDate(new Date(startDate));
    XLSX.writeFile(wb, `PriceUpdates_${finalDate}.xlsx`)
  }

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
        value={value}
        onClick={onClick}
        style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  return (
    <>
    <NavBar />
    <Form inline style={{margin:30, width:'90%', marginLeft: "auto",marginRight: "auto", paddingTop:100}}>
        <InputGroup size="sm" style = {{ margin : 5}}>
          <InputGroupAddon addonType="prepend">Start Date</InputGroupAddon>
                <DatePicker
                    selected={startDate}
                    onChange={(handleDateChange)}
                    minDate={new Date("2017/01/01")}
                    maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                />
        </InputGroup>
    </Form>
    <div className="row">
      <div className="col-md-3" style={{paddingLeft:30}}>
        <Form.Control type="search" style={{marginLeft:10}} placeholder="ItemID / ItemName / Category" value={searchText} onChange={(e)=>{ handleKeyPress(e); }}/>
      </div>
      <div className="col-md-3">
        <InputGroup style={{paddingLeft:30}}>
              <InputGroup.Prepend>
                  <InputGroup.Text id="regionid">Price Change</InputGroup.Text>
              </InputGroup.Prepend>
              <DropdownButton id="dropdown-basic-button" title={priceChangeText?priceChangeText:"All"}>
                <Dropdown.Item value={"All"} onClick={(e)=>{e.preventDefault();setPriceChangeText("All")}}>All</Dropdown.Item>
                <Dropdown.Item value={"Increased"} onClick={(e)=>{e.preventDefault();setPriceChangeText("Increased")}}>Increased</Dropdown.Item>
                <Dropdown.Item value={"Decreased"} onClick={(e)=>{e.preventDefault();setPriceChangeText("Decreased")}}>Decreased</Dropdown.Item>
              </DropdownButton>
        </InputGroup>
      </div>
      <div className="col-md-3">
        <InputGroup style={{paddingLeft:30}}>
              <InputGroup.Prepend>
                  <InputGroup.Text id="regionid">UpdatedFrom</InputGroup.Text>
              </InputGroup.Prepend>
              <DropdownButton id="dropdown-basic-button" title={updateFromFilter?updateFromFilter:"All"}>
                <Dropdown.Item value={"All"} onClick={(e)=>{e.preventDefault();setUpdateFromFilter("All")}}>All</Dropdown.Item>
                <Dropdown.Item value={"Price Update"} onClick={(e)=>{e.preventDefault();setUpdateFromFilter("Price Update")}}>Price Update</Dropdown.Item>
                <Dropdown.Item value={"Price Edit"} onClick={(e)=>{e.preventDefault();setUpdateFromFilter("Price Edit")}}>Price Edit</Dropdown.Item>
                <Dropdown.Item value={"DealerPrice Edit"} onClick={(e)=>{e.preventDefault();setUpdateFromFilter("DealerPrice Edit")}}>DealerPrice Edit</Dropdown.Item>
              </DropdownButton>
        </InputGroup>
      </div>
      <div className="col-md-3">
        <Button variant="warning" onClick={() => { exportPriceDetails(); }}>Export</Button>
      </div>
    </div>

    {(priceAnalysisList.length>0) ? <PriceUpdatesHistoryTable priceAnalysisListData={priceAnalysisList} /> : ((loading)? (<LoaderComp />):(<div style={{margin:40}}>No product data present</div>)) }
    </>
  );
};

export default PriceUpdatesHistory;
