import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert,Dropdown,DropdownButton, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {Switch} from '@material-ui/core'
import firebase from '../../service/firebaseConfig';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    //dd/mm/yyyy hh:mm
    return [day, month, year].join('/')+" "+[hour,minute].join(':');
}

function EditUserRoleAccessModalPopup({selectedPortal,selectedUser,usersList,pageHeadingMap,subPageToMainPageMap,deliveryPageHeadingMap,deliverySubPageToMainPageMap,supportPageHeadingMap,supportSubPageToMainPageMap,...props}) {
  console.log("EditUserRoleAccessModalPopup");
  console.log(selectedUser);

  let userAllowedRoutes=new Set(selectedUser.allowed_routes);
  console.log("userAllowedRoutes");
  console.log(userAllowedRoutes);
  let routeObj={};
  if(selectedPortal=="DeliveryPortal"){
    Object.keys(deliverySubPageToMainPageMap).map((route,rindex)=>{
      routeObj[`${route}`]=userAllowedRoutes.has(route);
    });
  }else if(selectedPortal=="AdminPortal"){
    Object.keys(subPageToMainPageMap).map((route,rindex)=>{
      routeObj[`${route}`]=userAllowedRoutes.has(route);
    });
  }else if(selectedPortal=="SupportPortal"){
    Object.keys(supportSubPageToMainPageMap).map((route,rindex)=>{
      routeObj[`${route}`]=userAllowedRoutes.has(route);
    });
  }

  console.log(routeObj);

  let selectedUserRole=selectedUser.usertype?selectedUser.usertype:"";
  const [userType,setUserType] = useState(selectedUserRole);
  const [routeChecked,setRouteChecked] = useState(routeObj);


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const editUser = () => {
      console.log("editUser");
      console.log(userType);
      console.log(routeChecked);

      let selectedRouteList=[];
      Object.keys(routeChecked).map((route,index)=>{
        if(routeChecked[route]){
          selectedRouteList.push(route);
        }
      });

      let userDetailsObject = {};
      userDetailsObject['allowed_routes']=selectedRouteList;
      userDetailsObject['last_modified_at']=formatDate(new Date());
      userDetailsObject['modified_by']=sessionStorage.getItem("Logged User Email Id");
      console.log(selectedRouteList);
      console.log(selectedPortal);

      let isDuplicateUserRole=false;
      for(let i=0;i<usersList.length;i++){
        if(usersList[i].usertype==userType && userType!=selectedUserRole){
          isDuplicateUserRole=true;
        }
      }

      if(selectedRouteList.length==0 && selectedPortal !=='SuperKreditPortal'){
        toast("No allowed routes.", { type: toast.TYPE.ERROR });
      }else if(userType==""){
        toast("Please enter user role.", { type: toast.TYPE.ERROR });
      }else if(isDuplicateUserRole==true){
        toast("User role already exists.", { type: toast.TYPE.ERROR });
      }else{
        if(userType!=selectedUserRole){
          let updatePathObj={};
          updatePathObj[`/${selectedUserRole}`]=null;
          updatePathObj[`/${userType}`]=userDetailsObject;
          console.log(updatePathObj);

          firebase.database().ref(`User_Role_Access/${selectedPortal}`).update(updatePathObj).then((snap) => {
            userDetailsObject['usertype']=userType;
            // userDetailsObject['keychange']="Y";
            toast("Updated user role", { type: toast.TYPE.SUCCESS });
            props.onHide();
            props.callback(userDetailsObject);
          }).catch((error) => {
            toast("Error in updating user role: "+error, { type: toast.TYPE.ERROR });
          });
        }else{
          firebase.database().ref(`User_Role_Access/${selectedPortal}/${userType}`).update(userDetailsObject).then((snap) => {
            userDetailsObject['usertype']=userType;
            // userDetailsObject['keychange']="N";
            toast("Updated user role", { type: toast.TYPE.SUCCESS });
            props.onHide();
            props.callback(userDetailsObject);
          }).catch((error) => {
            toast("Error in updating user role: "+error, { type: toast.TYPE.ERROR });
          });
        }
      }
    }

    const updateCheckedRoutes = (isRouteChecked) => {
      console.log('updateCheckedVehicles');
      console.log(isRouteChecked);

      if(selectedPortal=="DeliveryPortal"){
        //deliveryPageHeadingMap,deliverySubPageToMainPageMap
        let routeObj={};
        Object.keys(deliverySubPageToMainPageMap).map((route,rindex)=>{
          routeObj[`${route}`]=isRouteChecked;
        });
        setRouteChecked(routeObj);

      }else if(selectedPortal=="AdminPortal"){

        let routeObj={};
        Object.keys(subPageToMainPageMap).map((route,rindex)=>{
          routeObj[`${route}`]=isRouteChecked;
        });
        setRouteChecked(routeObj);

      }else if(selectedPortal=="SupportPortal"){
        //supportPageHeadingMap,supportSubPageToMainPageMap
        let routeObj={};
        Object.keys(supportSubPageToMainPageMap).map((route,rindex)=>{
          routeObj[`${route}`]=isRouteChecked;
        });
        setRouteChecked(routeObj);

      }
    }

    const updateRouteSelection = (routeVal,isRouteChecked) => {

      if(selectedPortal=="AdminPortal"){
        let routeObj={};
        Object.keys(subPageToMainPageMap).map((route,index)=>{

          // 1) page checked - check both parent and subpage
          if(isRouteChecked==true){
            if(routeVal==route){
              routeObj[`${routeVal}`]=isRouteChecked;
              if(subPageToMainPageMap[routeVal]){
                routeObj[`${subPageToMainPageMap[routeVal].parent}`]=isRouteChecked;
              }
            }else{
              routeObj[`${route}`]=routeChecked[route];
            }
          }else{
            // 2) parent unchecked
            if(subPageToMainPageMap[routeVal].parent==routeVal && subPageToMainPageMap[routeVal].parent==subPageToMainPageMap[route].parent){
              routeObj[`${route}`]=isRouteChecked;
            }else if(routeVal==route){
              // 3) subpage unchecked
              routeObj[`${routeVal}`]=isRouteChecked;
              if(subPageToMainPageMap[routeVal]){
                let isChildChecked=false;
                Object.keys(routeChecked).map((rval,rindex)=>{
                  if(subPageToMainPageMap[rval]){
                    if(subPageToMainPageMap[rval].parent==subPageToMainPageMap[routeVal].parent && subPageToMainPageMap[routeVal].parent!=routeVal && rval!=routeVal){
                      if(routeChecked[rval]==true){
                        console.log("rval"+rval);
                        isChildChecked=true;
                      }
                    }
                  }
                });

                routeObj[`${subPageToMainPageMap[routeVal].parent}`]=isChildChecked;
              }
            }else{
              routeObj[`${route}`]=routeChecked[route];
            }
          }


        });
        console.log("routeObj");
        console.log(routeObj);

        setRouteChecked(routeObj);
      }else if(selectedPortal=="DeliveryPortal"){
        ////supportPageHeadingMap,supportSubPageToMainPageMap
        //deliveryPageHeadingMap,deliverySubPageToMainPageMap
        let routeObj={};
        Object.keys(deliverySubPageToMainPageMap).map((route,index)=>{

          // 1) page checked - check both parent and subpage
          if(isRouteChecked==true){
            if(routeVal==route){
              routeObj[`${routeVal}`]=isRouteChecked;
              if(deliverySubPageToMainPageMap[routeVal]){
                routeObj[`${deliverySubPageToMainPageMap[routeVal].parent}`]=isRouteChecked;
              }
            }else{
              routeObj[`${route}`]=routeChecked[route];
            }
          }else{
            // 2) parent unchecked
            if(deliverySubPageToMainPageMap[routeVal].parent==routeVal && deliverySubPageToMainPageMap[routeVal].parent==deliverySubPageToMainPageMap[route].parent){
              routeObj[`${route}`]=isRouteChecked;
            }else if(routeVal==route){
              // 3) subpage unchecked
              routeObj[`${routeVal}`]=isRouteChecked;
              if(deliverySubPageToMainPageMap[routeVal]){
                let isChildChecked=false;
                Object.keys(routeChecked).map((rval,rindex)=>{
                  if(deliverySubPageToMainPageMap[rval]){
                    if(deliverySubPageToMainPageMap[rval].parent==deliverySubPageToMainPageMap[routeVal].parent && deliverySubPageToMainPageMap[routeVal].parent!=routeVal && rval!=routeVal){
                      if(routeChecked[rval]==true){
                        console.log("rval"+rval);
                        isChildChecked=true;
                      }
                    }
                  }
                });

                routeObj[`${deliverySubPageToMainPageMap[routeVal].parent}`]=isChildChecked;
              }
            }else{
              routeObj[`${route}`]=routeChecked[route];
            }
          }


        });
        console.log("routeObj");
        console.log(routeObj);

        setRouteChecked(routeObj);
      }else if(selectedPortal=="SupportPortal"){
        let routeObj={};
        Object.keys(supportSubPageToMainPageMap).map((route,index)=>{

          // 1) page checked - check both parent and subpage
          if(isRouteChecked==true){
            if(routeVal==route){
              routeObj[`${routeVal}`]=isRouteChecked;
              if(supportSubPageToMainPageMap[routeVal]){
                routeObj[`${supportSubPageToMainPageMap[routeVal].parent}`]=isRouteChecked;
              }
            }else{
              routeObj[`${route}`]=routeChecked[route];
            }
          }else{
            // 2) parent unchecked
            if(supportSubPageToMainPageMap[routeVal].parent==routeVal && supportSubPageToMainPageMap[routeVal].parent==supportSubPageToMainPageMap[route].parent){
              routeObj[`${route}`]=isRouteChecked;
            }else if(routeVal==route){
              // 3) subpage unchecked
              routeObj[`${routeVal}`]=isRouteChecked;
              if(supportSubPageToMainPageMap[routeVal]){
                let isChildChecked=false;
                Object.keys(routeChecked).map((rval,rindex)=>{
                  if(supportSubPageToMainPageMap[rval]){
                    if(supportSubPageToMainPageMap[rval].parent==supportSubPageToMainPageMap[routeVal].parent && supportSubPageToMainPageMap[routeVal].parent!=routeVal && rval!=routeVal){
                      if(routeChecked[rval]==true){
                        console.log("rval"+rval);
                        isChildChecked=true;
                      }
                    }
                  }
                });

                routeObj[`${supportSubPageToMainPageMap[routeVal].parent}`]=isChildChecked;
              }
            }else{
              routeObj[`${route}`]=routeChecked[route];
            }
          }


        });
        console.log("routeObj");
        console.log(routeObj);

        setRouteChecked(routeObj);
      }
    }

    const renderRoutes = () => {

      if(selectedPortal=="AdminPortal"){
        //pageHeadingMap,subPageToMainPageMap
        let rows = Object.keys(pageHeadingMap).map((mainPage,index)=>{
          return (
            <>
            <h5 style={{position: "relative",left: "-50px"}}>{pageHeadingMap[mainPage]}</h5>
            <ul>
              {Object.keys(subPageToMainPageMap).map((route,rindex)=>{
                if(subPageToMainPageMap[route]){
                  if(subPageToMainPageMap[route].parent==mainPage){
                    return (
                      <tr key={route}>
                          <td>{subPageToMainPageMap[route].name}</td>

                          <td>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <Input addon type="checkbox" className="bulkRouteSelection" checked={routeChecked[route]} onChange={(e)=>{
                                    updateRouteSelection(route,e.target.checked);
                                  }} />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </td>
                      </tr>
                    )
                  }
                }


              })}
            </ul>
            </>
          )
        });

        return (<><div>{rows}</div></>)

      }else if(selectedPortal=="DeliveryPortal"){
        //deliveryPageHeadingMap,deliverySubPageToMainPageMap
        let rows = Object.keys(deliveryPageHeadingMap).map((mainPage,index)=>{
          return (
            <>
            <h5 style={{position: "relative",left: "-50px"}}>{deliveryPageHeadingMap[mainPage]}</h5>
            <ul>
              {Object.keys(deliverySubPageToMainPageMap).map((route,rindex)=>{
                if(deliverySubPageToMainPageMap[route]){
                  if(deliverySubPageToMainPageMap[route].parent==mainPage){
                    return (
                      <tr key={route}>
                          <td>{deliverySubPageToMainPageMap[route].name}</td>
                          <td>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <Input addon type="checkbox" className="bulkRouteSelection" checked={routeChecked[route]} onChange={(e)=>{
                                    updateRouteSelection(route,e.target.checked);
                                  }} />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </td>
                      </tr>
                    )
                  }
                }


              })}
            </ul>
            </>
          )
        });

        return (<><div>{rows}</div></>)
      }else if(selectedPortal=="SupportPortal"){
        let rows = Object.keys(supportPageHeadingMap).map((mainPage,index)=>{
          return (
            <>
            <h5 style={{position: "relative",left: "-50px"}}>{supportPageHeadingMap[mainPage]}</h5>
            <ul>
              {Object.keys(supportSubPageToMainPageMap).map((route,rindex)=>{
                if(supportSubPageToMainPageMap[route]){
                  if(supportSubPageToMainPageMap[route].parent==mainPage){
                    return (
                      <tr key={route}>
                          <td>{supportSubPageToMainPageMap[route].name}</td>
                          <td>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <Input addon type="checkbox" className="bulkRouteSelection" checked={routeChecked[route]} onChange={(e)=>{
                                    updateRouteSelection(route,e.target.checked);
                                  }} />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </td>
                      </tr>
                    )
                  }
                }


              })}
            </ul>
            </>
          )
        });

        return (<><div>{rows}</div></>)
      }

    }


    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Update User Role
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                  <tbody>
                      <tr>
                          <td>UserRoleName</td>
                          <td><Form.Control type="text" defaultValue={userType} onChange={(e) => { setUserType(e.target.value) }} /></td>
                      </tr>
                      {selectedPortal !== 'SuperKreditPortal' ? (  <tr>
                        <td>Allowed Routes</td>
                        <td>
                          <div>
                              <tr>
                                  <td>Route</td>
                                  <th><input type="checkbox" id="selectAllCheckbox" onChange={(e) => {
                                      let bulkRouteChecks = document.getElementsByClassName('bulkRouteSelection');
                                      for (let i = 0; i < bulkRouteChecks.length; i++) {
                                          if (e.target.checked) {
                                              bulkRouteChecks[i].checked = true;
                                          } else {
                                              bulkRouteChecks[i].checked = false;
                                          }
                                      }
                                      updateCheckedRoutes(e.target.checked);
                                  }} /></th>
                              </tr>

                              {(selectedPortal=="AdminPortal" || selectedPortal=="DeliveryPortal" || selectedPortal=="SupportPortal")?renderRoutes():null}
                            </div>
                          </td>
                        </tr>) : null}
                    

                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={editUser}>Save</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditUserRoleAccessModalPopup
