export function validateCouponCode(coupon_code) {
  const objToReturn = {
    isValid: false,
    message: " ",
  };
  if (coupon_code === "") {
    objToReturn.isValid = true;
    return objToReturn;
  }

  const alphaNumericRegex = /^[a-z0-9]+$/i;
  if (coupon_code.length > 15) {
    objToReturn.message = "Cannot exceed 15 characters";
    return objToReturn;
  } else if (!alphaNumericRegex.test(coupon_code)) {
    objToReturn.message = "Special characters are not allowed";
    return objToReturn;
  }
  objToReturn.isValid = true;
  objToReturn.message = "";
  return objToReturn;
}
