import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { forwardRef } from "react";
import { Table } from "react-bootstrap";
import database from "../../service/firebaseConfig";
import logo from "./sz_logo.png";

const Template = forwardRef((props, ref) => {

  console.log("+++++++++++++++++++++++");
  console.log(props.data);
  console.log("+++++++++++++++++++++++");
 
  if (props.data === undefined){
    return (<>Loading...</>);
  }

  const [TotalVal, setTotalVal] = useState({
    Amount_Exl_GST: "",
    CGST_Amount: "",
    SGST_Amount: "",
    Total_Amount: "",
    Cash_Discount: "",
    Transport_Charges: "",
    APMC_Charges: "",
    Scheme_Amount: "",
    Net_Amount_Payable: "",
  });

  useEffect(() => {
    let data = props.data;

    let Amount_Exl_GST = 0;
    let CGST_Amount = 0;
    let SGST_Amount = 0;
    let Total_Amount = 0;
    let Cash_Discount = 0;
    let Transport_Charges = 0;
    let APMC_Charges = 0;
    let Scheme_Amount = 0;
    let Net_Amount_Payable = 0;

    props.data.map((data) => {
      Amount_Exl_GST += Number(
        data.invoice_amt
          ? data.invoice_amt
          : Number(data.purchase_price) * Number(data.total_weight)
      );

      CGST_Amount +=
        Number(
          (Number(
            data?.gst_charges_per ? data.gst_charges_per.split("%")[0] : "0"
          ) /
            100) *
            (data.invoice_amt
              ? Number(data.invoice_amt)
              : Number(data.purchase_price) * Number(data.total_weight))
        ) / 2;

      SGST_Amount +=
        Number(
          (Number(
            data?.gst_charges_per ? data.gst_charges_per.split("%")[0] : "0"
          ) /
            100) *
            (data.invoice_amt
              ? Number(data.invoice_amt)
              : Number(data.purchase_price) * Number(data.total_weight))
        ) / 2;

      Total_Amount +=
        Number(
          Number(
            data?.gst_charges_per ? data.gst_charges_per.split("%")[0] : "0"
          ) / 100
        ) *
          (data.invoice_amt
            ? Number(data.invoice_amt)
            : Number(data.purchase_price) * Number(data.total_weight)) +
        (data.invoice_amt
          ? Number(data.invoice_amt)
          : Number(data.purchase_price) * Number(data.total_weight));

      Cash_Discount +=
        Number(Number(data.cash_discount) / 100) *
        (data.invoice_amt
          ? Number(data.invoice_amt)
          : Number(data.purchase_price) * Number(data.total_weight));

      Transport_Charges += Number(
        Number(data.transport_charges) *
          Number(
            data.total_weight_received
              ? data.total_weight_received
              : data.total_weight
          )
      );

      APMC_Charges += Number(
        data.apmc_charges_per
          ? (Number(data.apmc_charges_per.split("%")[0]) / 100) *
              (data.invoice_amt
                ? Number(data.invoice_amt)
                : Number(data.purchase_price) * Number(data.total_weight))
          : 0
      );

      Scheme_Amount += Number(data.scheme_amount);

      Net_Amount_Payable += Number(
        (data.invoice_amt
          ? Number(data.invoice_amt)
          : Number(data.purchase_price) * Number(data.total_weight)) -
          Number(data.scheme_amount) +
          (Number(
            data?.gst_charges_per ? data.gst_charges_per.split("%")[0] : "0"
          ) /
            100) *
            (data.invoice_amt
              ? Number(data.invoice_amt)
              : Number(data.purchase_price) * Number(data.total_weight)) +
          (Number(
            data?.apmc_charges_per ? data.apmc_charges_per.split("%")[0] : "0"
          ) /
            100) *
            (props.data.invoice_amt
              ? Number(data.invoice_amt)
              : Number(data.purchase_price) * Number(data.total_weight)) +
          Number(data.transport_charges) *
            Number(
              data.total_weight_received
                ? data.total_weight_received
                : data.total_weight
            ) -
          (Number(data.cash_discount) / 100) *
            (data.invoice_amt
              ? Number(data.invoice_amt)
              : Number(data.purchase_price) * Number(data.total_weight))
      );

      return "";
    });

    setTotalVal({
      Amount_Exl_GST: Amount_Exl_GST,
      CGST_Amount: CGST_Amount,
      SGST_Amount: SGST_Amount,
      Total_Amount: Total_Amount,
      Cash_Discount: Cash_Discount,
      Transport_Charges: Transport_Charges,
      APMC_Charges: APMC_Charges,
      Scheme_Amount: Scheme_Amount,
      Net_Amount_Payable: Net_Amount_Payable,
    });

    return () => {};
  }, []);

  return (
    <>
      <div ref={ref}>
        <Table>
          <tr>
            <td>
              <img
                src={logo}
                alt={"superzopLogo"}
                style={{ width: "220px", height: "70px" }}
              />
            </td>
            <td style={{ width: "500px", textAlign: "left" }}>
              <b>Retranz Infolabs Private Limited</b>
              <br />
              Warehouse No 7, Santosh Warehousing Complex,
              <br />
              Village Dhansar, Navi Mumbai, Maharashtra – 410210
              <br />
              Phone : 9901044177
              <br />
              Email : support@superzop.com
              <br />
              GSTIN : 27AAHCR2573F1ZA <br />
              FSSAI : 11522998000504
            </td>
          </tr>
        </Table>

        <div>
          <div
            style={{
              borderTop: "2px solid black",
              borderBottom: "2px solid black",
              textAlign: "center",
            }}
          >
            <b style={{ fontSize: "20px" }}>Suppliers Invoice</b>
          </div>
        </div>

        <Table>
          <tr>
            <td style={{ textAlign: "left" }}>
              <div>
                <b>{props.data[0]?.supplier_name} </b>
                <br />
                {props.data[0]?.bill_address} {props.data[0]?.bill_street}
                <br />
                {props.data[0]?.bill_city} {props.data[0]?.bill_pin}
                <br />
                {props.data[0]?.bill_state}
                <br />
                {/* {convertCity(orderdetails.city || "")}-{orderdetails.pincode}<br /> */}
                ✆{props.data[0]?.bill_phone}
                <br />
                GSTIN : {props.data[0]?.bill_gstin}
                <br />
                FSSAI : {props.data[0]?.bill_fssai}
                <br />
              </div>
            </td>
            <td style={{ width: "400px", textAlign: "left" }}>
              <div>
                <Table borderless style={{ cellSpacing: "0px" }}>
                  <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                    <td style={{ padding: "0px" }}>Supplier ID :</td>
                    <td style={{ textAlign: "right", padding: "0px" }}>
                      {props.data[0]?.supplier_id}
                    </td>
                  </tr>
                  <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                    <td style={{ padding: "0px" }}>PO Number :</td>
                    <td style={{ textAlign: "right", padding: "0px" }}>
                      {props.data[0]?.po_number}
                    </td>
                  </tr>
                  <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                    <td style={{ padding: "0px" }}>Invoice Number :</td>
                    <td style={{ textAlign: "right", padding: "0px" }}>
                      {props.data[0]?.invoice_number}
                    </td>
                  </tr>
                  <tr style={{ cellSpacing: "0px" }}>
                    <td style={{ padding: "0px" }}>Invoice Date</td>
                    <td style={{ textAlign: "right", padding: "0px" }}>
                      {props.data[0]?.invoice_date}
                    </td>
                  </tr>
                  <tr style={{ cellSpacing: "0px" }}>
                    <td style={{ padding: "0px" }}>PO Date :</td>
                    <td style={{ textAlign: "right", padding: "0px" }}>
                      {props.data[0]?.purchased_date}
                    </td>
                  </tr>
                  <tr style={{ cellSpacing: "0px" }}>
                    <td style={{ padding: "0px" }}>No of Items</td>
                    <td style={{ textAlign: "right", padding: "0px" }}>
                      {props.data.length}
                    </td>
                  </tr>
                </Table>
              </div>
            </td>
          </tr>
        </Table>

        <Table responsive>
          <thead
            style={{
              borderTop: "2px solid black",
              borderBottom: "2px solid black",
            }}
          >
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <b>Sl No</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>Item description</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>HSN Code</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>Qty/No</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>Total Weight</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>Buying Price (per pack)</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>Amount (Rs.)</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>CGST</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>SGST</b>
              </td>
              <td style={{ paddingRight: "10px" }}>
                <b>Total Amount</b>
              </td>
            </tr>
          </thead>
          <tbody style={{ padding: "0" }}>
            {props &&
              props.data.map((item, indx) => {
                return (
                  <tr>
                    <td style={{ paddingRight: "10px" }}>{indx + 1}</td>
                    <td style={{ paddingRight: "10px" }}>{item.item_name}</td>
                    <td style={{ paddingRight: "10px" }}>{item.hsn_code}</td>
                    <td style={{ paddingRight: "10px" }}>
                      {item.bags_received
                        ? item.bags_received
                        : item.bag_purchased}
                    </td>
                    <td style={{ paddingRight: "10px" }}>
                      {item.total_weight_received
                        ? item.total_weight_received
                        : item.total_weight}{" "}
                      {item.uom}
                    </td>
                    <td style={{ paddingRight: "10px" }}>
                      {item.invoice_amt
                        ? item.invoice_amt / item.total_weight_received
                          ? item.total_weight_received
                          : item.total_weight
                        : item.purchase_price}
                    </td>
                    <td style={{ paddingRight: "10px" }}>
                      {Number(item.invoice_amt
                        ? item.invoice_amt
                        : Number(item.purchase_price) *
                          Number(item.total_weight)
                      )?.toFixed(2)}
                    </td>
                    <td style={{ paddingRight: "10px" }}>
                      {Number(
                        ((Number(
                          item?.gst_charges_per
                            ? item.gst_charges_per.split("%")[0]
                            : "0"
                        ) /
                          100) *
                          (item.invoice_amt
                            ? Number(item.invoice_amt)
                            : Number(item.purchase_price) *
                              Number(item.total_weight))) /
                        2
                      )?.toFixed(2)}
                    </td>
                    <td style={{ paddingRight: "10px" }}>
                      {Number(
                        ((Number(
                          item?.gst_charges_per
                            ? item.gst_charges_per.split("%")[0]
                            : "0"
                        ) /
                          100) *
                          (item.invoice_amt
                            ? Number(item.invoice_amt)
                            : Number(item.purchase_price) *
                              Number(item.total_weight))) /
                        2
                      )?.toFixed(2)}
                    </td>
                    <td style={{ paddingRight: "10px" }}>
                      {Number(
                        (Number(
                          item?.gst_charges_per
                            ? item.gst_charges_per.split("%")[0]
                            : "0"
                        ) /
                          100) *
                          (item.invoice_amt
                            ? Number(item.invoice_amt)
                            : Number(item.purchase_price) *
                              Number(item.total_weight)) +
                        (item.invoice_amt
                          ? Number(item.invoice_amt)
                          : Number(item.purchase_price) *
                            Number(item.total_weight))
                      )?.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {/* {JSON.stringify(TotalVal)} */}

        <div style={{ borderTop: "1px solid black" }}>
          <Table>
            <tr>
              <td style={{ textAlign: "left" }}>
                <br />
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <i>
                    I/We hereby certify that the goods mentioned in the invoice
                    are warranted to be of the same nature
                    <br />
                    <br />
                    Certified that the particulars given above are true and
                    correct
                  </i>
                  <br />
                  <br />
                </div>
              </td>
              <td
                style={{
                  width: "400px",
                  textAlign: "left",
                  cellSpacing: "0px",
                }}
              >
                <div>
                  <Table bordered style={{ border: "2px solid black" }}>
                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        Amount (Exl GST)
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.Amount_Exl_GST}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>CGST Amount</td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.CGST_Amount}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>SGST Amount</td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.SGST_Amount}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        <b>Total Amount</b>
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.Total_Amount}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        Cash Discount
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.Cash_Discount}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        Transport Charges
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.Transport_Charges}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        APMC Charges
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.APMC_Charges}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        Scheme Amount
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        {TotalVal?.Scheme_Amount}
                      </td>
                    </tr>

                    <tr style={{ border: "2px solid black" }}>
                      <td style={{ border: "2px solid black" }}>
                        <b>Net Amount Payable</b>
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        <b>{TotalVal?.Net_Amount_Payable}</b>
                      </td>
                    </tr>
                  </Table>
                  <br />
                  <br />
                  <br />
                  For Retranz Infolabs Private Limited
                  <br />
                  <br />
                  <br />
                  <i>(Authorized Signatory)</i>
                </div>
              </td>
            </tr>
          </Table>
        </div>
      </div>
    </>
  );
});

export default Template;
