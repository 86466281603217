import React, { useState,useEffect } from 'react'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import { FaPencilAlt } from 'react-icons/fa';

const PriceAnalysisTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
                <td>Purchase Date</td>
                <td>ItemId</td>
                <td>Item Name</td>
                <td>Category</td>
                <td>UOM</td>
                <td>Base Price</td>
                <td>Purchase Price</td>
            </tr>
        </thead>
    );
}

const PriceAnalysisTableBody = props => {
  let rows;
    if(props.priceAnalysisListData){
      rows=props.priceAnalysisListData.map((item, index) => {
          let priceText="";
          let purchasePrice=Number(item.purchase_price);
          let basePrice=Number(item.base_price);
          if(purchasePrice>basePrice){
            priceText="Increased";
          }else if(purchasePrice<basePrice){
            priceText="Decreased";
          }else{
            priceText="No Change";
          }

          return (
              <tr key={index} style={{backgroundColor:priceText && (priceText==="Increased"?"#FFCCCC":(priceText==="Decreased"?"#CCFFCC":"#FFFFFF"))}}>
                  <td>{item.purchased_date}</td>
                  <td>{item.article_code}</td>
                  <td>{item.item_name}</td>
                  <td>{item.category}</td>
                  <td>{item.uom}</td>
                  <td>{item.base_price}</td>
                  <td>{item.purchase_price}</td>
              </tr>
          );
      });
    }else{
      rows = [];
    }

    return (<><tbody>{rows}</tbody></>);
}

const PriceAnalysisTable = (props) => {
    const { priceAnalysisListData } = props;
    return (
        <table className="table" style={{marginTop:10}}>
            <PriceAnalysisTableHeader />
            <PriceAnalysisTableBody priceAnalysisListData={priceAnalysisListData} />
        </table>
    );
}

export default PriceAnalysisTable
