import React, { useState, useEffect, useRef } from 'react'
import database from '../../../service/firebaseConfig'
import { Row, FormGroup, FormControl, Accordion, Button, Form, Table, Col, Nav, Card, Jumbotron } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form"
import { toast } from 'react-toastify';

const retailerDetailsDb = database.database().ref('Retailer_Master_New');
const walletDetailsDb = database.database().ref('Wallet_Master_New');



function WalletDetails() {

    const [viewDetails, setViewDetails] = useState(false)
    const [submitDetails, setSubmitDetails] = useState(false)
    const [walletAmt, setWalletAmt] = useState("")
    const [totalAmt, setTotalAmt] = useState("")
    const [retailerId, setRetailerId] = useState("")
    const [walletTransactions, setWalletTransactions] = useState("")

    const { register, errors, handleSubmit, watch } = useForm({defaultValues:{
        order_id:"0"
    }});

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {

        if (viewDetails && watch("retailer_phone") !== "") {
            retailerDetailsDb.child(`${watch("retailer_phone")}`).once("value", snapShot => {
                const retailerInfo = snapShot.val()
                if(retailerInfo) {
                    retailerInfo.wallet? setWalletAmt(retailerInfo.wallet):setWalletAmt("0")
                    setRetailerId(retailerInfo.retailer_id && retailerInfo.retailer_id)
                } else {
                    alert('Retailer is invalid::: ' + watch("retailer_phone"));
                }
            })
            setViewDetails(false)
        }

    }, [viewDetails])

    useEffect(() => {
        if (retailerId) {
            walletDetailsDb.child(`${retailerId}`).once("value", snapShot => {

                const walletInfo = snapShot.val()
                setWalletTransactions(walletInfo)

            })
        }
    }, [retailerId])

    useEffect(() => {
        if (watch(`wallet_amt_added`)) {
            setTotalAmt(Number(walletAmt) + Number(watch(`wallet_amt_added`)))
        }
    }, [watch(`wallet_amt_added`)])

    const onSubmit = data => {
        if(submitDetails) {
            return;
        }
        setSubmitDetails(true);

        //add wallet amount to retailers DB
        retailerDetailsDb.child(`${watch("retailer_phone")}`).update({

            "wallet":data.total_amt

        }).then(()=> toast(`Updated wallet details for ${watch("retailer_phone")} `, { type: toast.TYPE.SUCCESS }))

        //add wallet transactions to wallet master DB

        const randomText = (Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 19))
        let newDateFormat = new Date().toString().split("(")[0]
        newDateFormat = newDateFormat.replace("GMT+0530","GMT+05:30")
        let modifiedYr = newDateFormat.substring(11,16)
        newDateFormat = newDateFormat.replace(modifiedYr,"")
        newDateFormat = `${newDateFormat}${modifiedYr}` 


        walletDetailsDb.child(`${retailerId}`).push({

            "details": data.wallet_details,
            "order_id":data.order_id,
            "retailer_id":retailerId+"",
            "transaction_date": newDateFormat,
            "wallet_amt":`+${data.wallet_amt_added}`


        }).then(()=> toast(`Updated wallet transactions successfully for retailer id ${retailerId}`, { type: toast.TYPE.SUCCESS }))

        setTimeout(() => {
            window.location.reload();
        }, 3000);

    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table>
                    <tr>
                        <td>Enter Retailer Phone</td>
                        <td><Form.Control name={"retailer_phone"} ref={register()} /></td>
                    </tr>
                    <tr>
                        <td colSpan="2"><Button onClick={() => setViewDetails(true)}>Search</Button></td>
                    </tr>
                    {walletAmt && <tr>
                        <td>Enter Wallet Details</td>
                        <td><Form.Control name={"wallet_details"} ref={register({required:true})} /></td>
                    </tr>}
                    {errors.wallet_details && <span style={{color:"red",float:"left"}}>*enter wallet details</span>}
                    {walletAmt && <tr>
                        <td>Enter order Id</td>
                        <td><Form.Control name={"order_id"}  ref={register({required:true})} /></td>
                    </tr>
                    }
                    {errors.order_id && <span style={{color:"red",float:"left"}}>*enter order ID</span>}
                    {walletAmt && 
                    <tr>
                        <td>Wallet Amount</td>
                        <td><Form.Control name={"wallet_amt"} readOnly value={walletAmt} ref={register()} /></td>
                    </tr>}
                    {walletAmt && <tr>
                        <td>Enter Amount to be added</td>
                        <td><Form.Control name={"wallet_amt_added"} ref={register({required:true})} /></td>
                    </tr>}
                    {errors.wallet_amt_added && <span style={{color:"red",float:"left"}}>*enter wallet amount to add</span>}
                    {watch(`wallet_amt_added`) !== ""&& walletAmt && <tr>
                        <td>Total Amount</td>
                        <td><Form.Control name={"total_amt"} readOnly value={totalAmt && totalAmt} ref={register()} /></td>
                    </tr>}
                </Table>
                {walletAmt && <center><Button type={"submit"}>Submit</Button></center>}
                {walletTransactions && <Jumbotron>
                <Accordion >
                    {walletTransactions && Object.keys(walletTransactions).map((transactId, index) => (
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={index}>
                                {transactId}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                    <Table>
                                        <tr>
                                            <td>Wallet Details</td>
                                            <td><Form.Control  readOnly value={walletTransactions[transactId].details && walletTransactions[transactId].details}  /></td>
                                        </tr>
                                        <tr>
                                            <td>Order ID</td>
                                            <td><Form.Control readOnly value={walletTransactions[transactId].order_id && walletTransactions[transactId].order_id}  /></td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Date</td>
                                            <td><Form.Control readOnly value={walletTransactions[transactId].transaction_date && walletTransactions[transactId].transaction_date}  /></td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Amount</td>
                                            <td><Form.Control readOnly value={walletTransactions[transactId].wallet_amt && walletTransactions[transactId].wallet_amt}  /></td>
                                        </tr>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
                </Jumbotron>}
            </form>
        </div>
    )
}

export default WalletDetails
