import { ALL_REGIONS_LIST } from "../../../constants/allConstants";

export const actionsList = [
  { label: "category list", value: "category_list" },
  { label: "add to cart", value: "add_to_cart" },
  { label: "wish list", value: "wish_list" },
  { label: "popular", value: "popular" },
  { label: "offers", value: "offers" },
  { label: "rate us", value: "rate_us" },
  { label: "spin wheel", value: "spin_wheel" },
  { label: "whats new", value: "whats_new" },
  { label: "previously bought", value: "previously_bought" },
  { label: "past orders", value: "past_orders" },
  { label: "super kredit", value: "super_kredit" },
];

export const widgetsList = [
  {
    label: "Grid",
    value: "GRID",
  },
  {
    label: "Auto Slider",
    value: "AUTO_SLIDER",
  },
  {
    label: "Card",
    value: "CARD",
  },
  {
    label: "Horizontal Scroll",
    value: "HORIZONTAL_SCROLL",
  },
  {
    label: "Vertical Scroll",
    value: "VERTICAL_SCROLL",
  },
];

export const typesList = {
  CARD: [
    { label: "banner", value: "BANNER" },
    { label: "split banner", value: "SPLIT_BANNER" },
    { label: "shortcuts", value: "SHORTCUTS" },
    { label: "spin wheel", value: "SPIN_WHEEL" },
    { label: "promotion video", value: "PROMOTION_VIDEO" },
    { label: "brand_list", value: "BRAND_LIST" },
    { label: "brand_grid", value: "BRAND_GRID" },
  ],
  AUTO_SLIDER: [{ label: "products", value: "PRODUCTS" }],
  HORIZONTAL_SCROLL: [
    { label: "products", value: "PRODUCTS" },
    { label: "sub_category ", value: "SUB_CATEGORY" },
    { label: "combine_prod", value: "COMBINE_PROD" },
  ],
  VERTICAL_SCROLL: [
    { label: "products list", value: "PRODUCTS_LIST" },
    { label: "products grid", value: "PRODUCTS_GRID" },
  ],
  GRID: [],
};

export const storeSeries = ALL_REGIONS_LIST.map((item) => ({
  label: `Series ${item}`,
  value: item,
}));

export const FORM_DATA_FORMAT = {
  CARD: {
    BANNER: {
      image_url: "",
      action: "",
      item_ids: [],
      text: "",
      view_type: "",
      header_image: "",
      header_title: "",
      header_description: "",
      header_background_color: "",
    },

    SPLIT_BANNER: {
      image_url: "",
      action: "",
      item_ids: [],
      text: "",
      view_type: "",
      header_image: "",
      header_title: "",
      header_description: "",
      header_background_color: "",
    },

    SHORTCUTS: {
      icon_url: "",
      text: "",
      text_hi: "",
      text_mr: "",
      text_gj: "",
      action: "",
      hindi_description: "",
      gujarati_description: "",
      marathi_description: "",
      description: "",
    },

    PROMOTION_VIDEO: {
      video_url: "",
      auto_play: false,
      loop: false,
      mute: false,
    },
  },
  AUTO_SLIDER: {
    PRODUCTS: {
      item_ids: [],
      text: "",
    },
  },
  HORIZONTAL_SCROLL: {
    PRODUCTS: {
      item_ids: [],
      text: "",
    },
    SUB_CATEGORY: {
      image_url: "",
      text: "",
      text_hi: "",
      text_mr: "",
      text_gj: "",
    },
    COMBINE_PROD: {
      item_ids: [],
    },
  },
  VERTICAL_SCROLL: {
    text: "",
    text_hi: "",
    text_mr: "",
    text_gj: "",
    item_ids: [],
  },
};
