import React from "react";
import {
  Modal,
  Alert,
  Dropdown,
  DropdownButton,
  Card,
  Table,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Accordion,
} from "react-bootstrap";

function ViewPurchaseModal({ data, ...props }) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          View Purchase
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
      maxHeight: 'calc(100vh - 210px)',
      overflowY: 'auto'
     }}>
        <Table striped bordered>
          <tr>
            <td>Purchased Date</td>
            <td>
              <Form.Control readOnly defaultValue={data.purchased_date} />
            </td>
          </tr>
          <tr>
            <td>PO number</td>
            <td>
              <Form.Control readOnly defaultValue={data.po_number} />
            </td>
          </tr>
          <tr>
            <td>Article code</td>
            <td>
              <Form.Control readOnly defaultValue={data.article_code} />
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <Form.Control readOnly defaultValue={data.category} />
            </td>
          </tr>
          <tr>
            <td>Item Name</td>
            <td>
              <Form.Control readOnly defaultValue={data.item_name} />
            </td>
          </tr>
          <tr>
            <td>Outer Type</td>
            <td>
              <Form.Control readOnly defaultValue={data.outer_type} />
            </td>
          </tr>
          <tr>
            <td>Outer Size (Kg/Pc)</td>
            <td>
              <Form.Control readOnly defaultValue={data.outer_size} />
            </td>
          </tr>
          <tr>
            <td>No Of Bags Purchased</td>
            <td>
              <Form.Control readOnly defaultValue={data.bag_purchased} />
            </td>
          </tr>
          <tr>
            <td>Total Weight Purchased</td>
            <td>
              <Form.Control readOnly defaultValue={data.total_weight} />
            </td>
          </tr>
          <tr>
            <td>Supplier</td>
            <td>
              <Form.Control readOnly defaultValue={data.supplier_name} />
            </td>
          </tr>
           <tr>
                <td>Source</td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    required
                    title={data.source_type}  disabled>
                    <Dropdown.Item

                      // onClick={(e) => setSource(e.target.name)}
                      name={"MILL"}
                    >
                      MILL
                    </Dropdown.Item>
                    <Dropdown.Item
                      // onClick={(e) => setSource(e.target.name)}

                      name={"LOCAL PICK"}
                    >
                      LOCAL PICK
                    </Dropdown.Item>
                    <Dropdown.Item
                      // onClick={(e) => setSource(e.target.name)}

                      name={"DC DELIVERED"}
                    >
                      DC DELIVERED
                    </Dropdown.Item>
                  </DropdownButton>
                  </td>
              </tr>
              {data.source_type === 'MILL' ? (
                <>
              <tr>

                <td>
                  Transport
                </td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={data.transport.toUpperCase()} 
                    disabled >
                    <Dropdown.Item

                      // onClick={(e) => setTransport(e.target.name)}
                      name={"included"}
                    >
                      INCLUDED
                    </Dropdown.Item>
                    <Dropdown.Item
                      // onClick={(e) => setTransport(e.target.name)}

                      name={"excluded"}
                    >
                      EXCLUDED
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr> 
               </>) : null}
               {data.transport === 'excluded' ? (<>
               <tr><td>Transport Charges</td>
              <td><Form.Control value={data.transport_charges} readOnly/></td></tr>
              <tr><td>Cash Discount</td>
               <td><Form.Control value={data.cash_discount} readOnly/></td></tr>
</>) : null}
<tr>
                <td>HSN Code</td>
                <td>
                  <Form.Control  value = {data.hsn_code} readOnly />
                </td>
              </tr>
              <tr>
                <td>Base Price</td>
                <td>
                  <Form.Control
                    type="number"
                    readOnly
                    value={data?.base_price ? data.base_price : ""}
                  />
                </td>
              </tr>
              <tr>
                <td>Purchase Price</td>
                <td>
                  <Form.Control
                    type="number"
                    value={data.purchase_price}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>Invoice Date</td>
                <td>
                  <Form.Control
                    
                  
                    value={data.invoice_date}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>Invoice Number</td>
                <td>
                  <Form.Control
                    
                  
                    value={data.invoice_number}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>Payment Terms (in Days)</td>
                <td>
                  <Form.Control   value={data.payment_terms} readOnly/>
                 
                </td>
              </tr>
              <tr>
                <td>Delivery Period (in Days)</td>
                <td>
                  <Form.Control  required value={data.delivery_in_days}  readOnly/>
                </td>
              </tr>
              <tr>
                <td>PO Amount </td>
                <td>
                  <Form.Control value ={data.po_amount} readOnly />
                </td>
              </tr>
              <tr>
                <td>MRP </td>
                <td>
                  <Form.Control value = {data.mrp !== null ? data.mrp : ""}  readOnly/>
                </td>
              </tr>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewPurchaseModal;
