import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Navbar,
  Dropdown,
  DropdownButton,
  Card,
  Table,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Accordion,
  Toast,
  Badge,
  Alert,
  Modal,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { InputGroupAddon, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
// import AddNewPurchase from './Add_Purchase';
import AddGRNModal from "./AddGRNModal";
import EditGRNModal from "./EditGRNModal";
import EditPurchaseModal from "./EditPurchaseModal";
import ViewPurchaseModal from "./ViewPurchaseModal";
import SyncPurchaseDetailsModal from "./SyncPurchaseDetailsModal";
import ViewGRNModal from "./ViewGrnModal";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import {
  validateDCManagerUser,
  validateGRNOnlyUser,
  validateStockOnlyUser,
  validateIsAdminUser,
  validatePurchaseUser,
  validatePurchaseExportUser,
  validatePurchaseExportOnlyUser,
  validatePurchaseOnlyUser,
  validatePurchaseReportUser,
  validateGRNUser,
  validateGRNOrFinanceUser,
} from "../../service/credentials";
import ExportDetails from "./ExportPurchaseDetails";
import Backdrop from "../../CommonRequirements/backdrop";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { Link } from "react-router-dom";
import request from "request";
//import baseUrl from '../../service/servicesConfig';
import {
  DELIVERY_BASE_URL,
  deliveryCommercialServer,
} from "../../service/servicesConfig";
import { zohoSyncServer } from "../../service/servicesConfig";
import ListGRNOpenModal from "./ListGRNOpenModal";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import firebase from "../../service/firebaseConfig";
import axios from "axios";
import "./ListPurchase.css";

const PurchaseDetailsData = database.database().ref(`Purchase_Details`);

function ListPurchase() {
  const [getDate, setDate] = useState(new Date());
  const [dataRender, setDataRender] = useState(false);
  const [productEditTable, setProductEditTable] = useState([]);
  const [modifiedDate, setModifiedDate] = useState("");
  const [modifiedMonth, setModifiedMonth] = useState("");
  const [renderAllDetails, setRenderAllDetails] = useState(false);
  const [editPurchaseModal, setEditPurchaseModal] = useState(false);
  const [viewPurchaseModal, setViewPurchaseModal] = useState(false);
  const [viewGRNModal, setViewGRNModal] = useState(false);
  const [addGRNModal, setAddGRNModal] = useState(false);
  const [editGRNModal, setEditGRNModal] = useState(false);
  const [editingItemDetails, setEditingItemDetails] = useState("");
  const [itemData, setItemData] = useState("");
  const [monthData, setMonthData] = useState("");
  const [gRNAvailable, setGRNAvailable] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState("");
  const [exportDetails, setExportDetails] = useState(false);
  const [getBackDrop, setBackDrop] = useState(false);
  const [itemCodeList, setItemCodeList] = useState("");
  const [syncModal, setSyncModal] = useState(false);

  const [BarCode, setBarCode] = useState();
  const [BarCodeSplit, setBarCodeSplit] = useState();

  const [BarCodeList, setBarCodeList] = useState([]);
  const [BarCodeSplitList, setBarCodeSplitList] = useState([]);

  const [ProductItem, setProductItem] = useState({});

  const [dateRangeType, setDateRangeType] = useState(
    sessionStorage.getItem("dateRangeType")
      ? sessionStorage.getItem("dateRangeType")
      : validateGRNOrFinanceUser(sessionStorage.getItem(LOGGED_USER_EMAIL))
      ? "GRN"
      : "Purchase"
  );
  const [grnEntryClose, setGrnEntryClose] = useState(false);
  const [listGRNOpen, setListGRNOpen] = useState(false);
  const [zohoSyncButtonStatus, setZohoSyncButtonStatus] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = async (item) => {
    if (!item.grn_no)
      return toast("Error : Unable to fetch qr codes, GRN No not found.", {
        type: toast.TYPE.ERROR,
      });
    const url = `${DELIVERY_BASE_URL}/api/bag-qr?grn_number=${item.grn_no}`;

    let toast_id = toast("Fetching data, Please wait", {
      type: toast.TYPE.SUCCESS,
      autoClose: false,
    });

    let res = await axios
      .get(url)
      .catch((err) => {
        console.log("Error occured while fetching qr ids", err.message);
        return toast(`Error occured while fetching qr ids, ${err.message}`, {
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        toast.dismiss(toast_id);
      });

    const { data } = res.data;
    let qr_list = [];
    let qr_list_split = [];

    data.forEach((item, index) => {
      const qrcode = item.qr_id;
      qr_list_split.push(qrcode);
      qr_list.push(qrcode);
    });

    // formatting item_name
    // console.log("check item", item);
    let sp = item?.item_name.split("-");
    let new_item_name = sp[0];
    let obj = item;
    obj["new_item_name"] = new_item_name;
    setProductItem(obj);
    setBarCodeList(qr_list);
    setBarCodeSplitList(qr_list_split);
    setShow(true);
  };

  let componentRef = useRef();

  let filterResult = "";

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  function printString(columnNumber) {
    let columnName = [];

    while (columnNumber > 0) {
      let rem = columnNumber % 26;

      if (rem == 0) {
        columnName.push("Z");
        columnNumber = Math.floor(columnNumber / 26) - 1;
      } else {
        columnName.push(String.fromCharCode(rem - 1 + "A".charCodeAt(0)));
        columnNumber = Math.floor(columnNumber / 26);
      }
    }
    return columnName.reverse().join("");
  }

  const notifyDetails = (success) => {
    if (success) {
      let newDtSelected = new Date(sessionStorage.getItem(`datekey`));
      return toast(
        `No details available for ${newDtSelected.getDate()}-${
          newDtSelected.getMonth() + 1
        }-${newDtSelected.getFullYear()}`,
        { type: toast.TYPE.ERROR }
      );
    }
  };
  const handleChange = (date) => {
    setBackDrop(true);
    if (!date) {
      setDate("");
      return;
    }
    setDate(date);
    setDataRender(true);
    let dateSelected = date.getDate();
    let monthSelected = date.getMonth() + 1;

    if (dateSelected < 10) dateSelected = "0" + dateSelected;
    if (monthSelected < 10) monthSelected = "0" + monthSelected;
    setModifiedDate(dateSelected);
    setModifiedMonth(monthSelected);
    setMonthData(monthSelected);
    sessionStorage.setItem("datekey", date);
    window.location.reload();
  };

  // console.log(
  //   "sessionStorage.getItem(`dateRangeType`) ",
  //   sessionStorage.getItem(`dateRangeType`)
  // );

  /*useEffect(() => {

        setBackDrop(true)
        const pushTableDetails = []
        let updateItemsKeys = []
        let newItemKeysArray = []
        let grnDetailsCheck = []
        let sessionDate
        let filterDate
        let sessionYr
        let sessionMn
        let sessionDt
        if (sessionStorage.getItem(`datekey`)) {

            sessionDate = sessionStorage.getItem(`datekey`)
            filterDate = new Date(sessionDate)
            setDate(filterDate)
            sessionYr = filterDate.getFullYear();
            sessionMn = filterDate.getMonth() + 1;
            if (sessionMn < 10)
                sessionMn = "0" + sessionMn
            sessionDt = filterDate.getDate();
            if (sessionDt < 10)
                sessionDt = "0" + sessionDt;
        }

        PurchaseDetailsData.child(`${sessionDate ? sessionYr : getDate.getFullYear()}/${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}/${sessionDate ? sessionDt : getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}-${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}-${sessionDate ? sessionYr : getDate.getFullYear()}`).once("value", snapShot => {
            if (snapShot.val()) {

                const purchasedDetails = snapShot.val()
                setItemCodeList(purchasedDetails)
                setSelectedDate(snapShot.key);
                console.log(purchasedDetails)

                Object.keys(purchasedDetails).forEach(itemKey => {
                    purchasedDetails[itemKey].uniqueKey = itemKey;
                    pushTableDetails.push(
                        purchasedDetails[itemKey]
                    )

                })
                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                pushTableDetails.forEach((items, index) => {
                    if (items.hasOwnProperty('grn_date'))
                        grnDetailsCheck.push(true)
                    else
                        grnDetailsCheck.push(false)
                })
            }
            else { return toast(`No details available for ${filterDate ? filterDate.getDate() : getDate.getDate()}-${filterDate ? filterDate.getMonth() + 1 : getDate.getMonth() + 1}-${filterDate ? filterDate.getFullYear() : getDate.getFullYear()}`, { type: toast.TYPE.ERROR }) }
        }).then(() => {
            setProductEditTable(pushTableDetails);
            setFilterList(pushTableDetails)
            setRenderAllDetails(true)
            setGRNAvailable(grnDetailsCheck)
            setBackDrop(false)
        })
    }, [])*/

  useEffect(() => {
    // window.onbeforeunload = function () {
    //     console.log('window.onbeforeunload');
    //     return true;
    // };

    return () => {
      //console.log('return () window.onbeforeunload');
      sessionStorage.setItem("dateRangeType", "");
      //window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    //setBackDrop(true);
    setGrnEntryClose(false);
    let pushTableDetails = [];
    let updateItemsKeys = [];
    let newItemKeysArray = [];
    let grnDetailsCheck = [];
    let sessionDate;
    let filterDate;
    let sessionYr;
    let sessionMn;
    let sessionDt;
    if (sessionStorage.getItem(`datekey`)) {
      sessionDate = sessionStorage.getItem(`datekey`);
      filterDate = new Date(sessionDate);
      setDate(filterDate);
      sessionYr = filterDate.getFullYear();
      sessionMn = filterDate.getMonth() + 1;
      if (sessionMn < 10) sessionMn = "0" + sessionMn;
      sessionDt = filterDate.getDate();
      if (sessionDt < 10) sessionDt = "0" + sessionDt;
    } else {
      handleChange(new Date());
    }

    const complete_date = `${sessionYr}-${sessionMn}-${sessionDt}`;
    const complete_date_2 = `${sessionDt}-${sessionMn}-${sessionYr}`;

    console.log(
      `${complete_date} ${complete_date_2} ${sessionStorage.getItem("datekey")}`
    );
    console.log("DEBUG date", complete_date);

    let getUrl;
    if (dateRangeType === "Purchase") {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    } else if (dateRangeType === "GRN") {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?grn_start_date=${complete_date}&grn_end_date=${complete_date}`;
    } else {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    }

    console.log("getUrl: ", getUrl);

    //baseUrl.get(getUrl).then(response => {
    deliveryCommercialServer
      .get(getUrl)
      .then((response) => {
        //console.log('response: ',response);
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
      .then((data) => {
        //console.log("fetch: ", data);

        setItemCodeList(data);
        setSelectedDate(complete_date_2);
        //console.log(data)
        //data.forEach((item) => {

        //})
        pushTableDetails.push(...data);

        pushTableDetails = pushTableDetails.map((item) => {
          if (item.unique_key) item.uniqueKey = item.unique_key;
          else item.uniqueKey = "";
          return item;
        });

        pushTableDetails.sort((a, b) => {
          if (a.created_at < b.created_at) {
            return -1;
          } else if (a.created_at > b.created_at) {
            return 1;
          } else {
            return 0;
          }
        });

        pushTableDetails.forEach((items, index) => {
          if (items.grn_date) grnDetailsCheck.push(true);
          else grnDetailsCheck.push(false);
        });
      })
      .then(() => {
        setProductEditTable(pushTableDetails);
        setFilterList(pushTableDetails);
        setRenderAllDetails(true);
        setGRNAvailable(grnDetailsCheck);
        setBackDrop(false);

        if (pushTableDetails.length == 0) {
          //setBackDrop(false);
          throw "No Data";
        }
      })
      .catch((error) => {
        console.log(error);

        if (error === "No Data") {
          return toast(
            `No details available for ${
              filterDate ? filterDate.getDate() : getDate.getDate()
            }-${
              filterDate ? filterDate.getMonth() + 1 : getDate.getMonth() + 1
            }-${filterDate ? filterDate.getFullYear() : getDate.getFullYear()}`,
            { type: toast.TYPE.ERROR }
          );
        }
      });

    //if (dateRangeType === 'GRN') {

    const grn_entry_close_url = `/api/superzop/commercial/purchasedetails/getgrncloserecordbasedongrndate?grn_date=${complete_date}`;

    //baseUrl.get(grn_entry_close_url).then(response => {
    deliveryCommercialServer
      .get(grn_entry_close_url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
      .then((data) => {
        //console.log(data);

        if (data.length > 0) {
          const grn_entry_close = data[0].grn_entry_close;

          if (grn_entry_close == 1) {
            setGrnEntryClose(true);
          } else setGrnEntryClose(false);
        } else {
          setGrnEntryClose(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // return toast("Couldn't update Purchase Details record in MySQL", { type: toast.TYPE.ERROR });
      });
    //}
  }, [dateRangeType]);

  const searchPurchaseList = () => {
    const filterData = [];
    filterResult = Object.keys(productEditTable).filter((obj) => {
      const itemId = productEditTable[obj].article_code.trim().toLowerCase();
      const itemDesc =
        (productEditTable[obj].item_name &&
          productEditTable[obj].item_name.trim().toLowerCase()) ||
        "";
      const invoiceNo =
        (productEditTable[obj].invoice_number &&
          productEditTable[obj].invoice_number.trim().toLowerCase()) ||
        "";
      const grnNo =
        (productEditTable[obj].grn_no &&
          productEditTable[obj].grn_no.trim().toLowerCase()) ||
        "";
      const supplierName =
        (productEditTable[obj].supplier_name &&
          productEditTable[obj].supplier_name.trim().toLowerCase()) ||
        "";

      setSearchText(searchText.trim().toLowerCase());
      return (
        itemId.match(searchText) ||
        itemDesc.match(searchText) ||
        invoiceNo.match(searchText) ||
        grnNo.match(searchText) ||
        supplierName.match(searchText)
      );
    });
    filterResult.forEach((items) => {
      filterData.push(productEditTable[items]);
    });
    setFilterList(filterData);
  };
  useEffect(() => {
    if (searchText) searchPurchaseList();
  }, [searchText]);

  const listPurchaseDetails = () => {
    PurchaseDetailsData.child(
      `${getDate.getFullYear()}/${modifiedMonth}/${modifiedDate}-${modifiedMonth}-${getDate.getFullYear()}`
    )
      .once("value", (snapShot) => {
        if (snapShot.val()) {
          setSelectedDate(snapShot.key());
          const purchasedDetails = snapShot.val();
          setItemCodeList(purchasedDetails);
          const pushTableDetails = [];
          let updateItemsKeys = [];
          let grnDetailsCheck = [];
          let newItemKeysArray = [];

          Object.keys(purchasedDetails).forEach((items) => {
            updateItemsKeys.push({
              uniqueKey: items,
              articleCode: purchasedDetails[items].article_code,
            });

            //console.log(items);
            pushTableDetails.push(purchasedDetails[items]);
          });
          pushTableDetails.sort((a, b) => {
            if (a.created_at < b.created_at) {
              return -1;
            } else if (a.created_at > b.created_at) {
              return 1;
            } else {
              return 0;
            }
          });
          pushTableDetails.forEach((items, index) => {
            updateItemsKeys.forEach((uniqKey, uniqIndex) => {
              if (uniqKey.articleCode === items.article_code) {
                newItemKeysArray.push(uniqKey.uniqueKey);
                updateItemsKeys.splice(uniqIndex, 1);
              }
            });
            if (items.hasOwnProperty("grn_date")) grnDetailsCheck.push(true);
            else grnDetailsCheck.push(false);
          });
          setProductEditTable(pushTableDetails);
          setFilterList(pushTableDetails);
          setRenderAllDetails(true);
          setGRNAvailable(grnDetailsCheck);
        } else {
          setRenderAllDetails(false);
          notifyDetails(true);
        }
      })
      .then(() => {
        setDataRender(false);
        setBackDrop(false);
      });
  };

  useEffect(() => {
    if (dataRender) listPurchaseDetails();
  }, [dataRender]);

  const GetDateInMMM = (date) => {
    if (date) {
      var arr = date.split("-");
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var formatddate = arr[2] + "-" + months[Number(arr[1]) - 1];
      return formatddate;
    } else {
      return "";
    }
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const openRTVPage = () => {
    console.log("openRTVPage");
    window.open("#/rtvdetails");
  };

  const openAddPurchase = () => {
    window.open("#/addPurchaseDetails");
  };

  const openSuppliersPage = () => {
    window.open("#/addsupplier");
  };

  const closeGRNEntry = () => {
    const grn_date = `${getDate.getFullYear()}-${
      getDate.getMonth() + 1 < 10
        ? "0" + (getDate.getMonth() + 1)
        : getDate.getMonth() + 1
    }-${getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}`;
    const answer = window.confirm(
      `Are you sure you want to close GRN for ${grn_date} date?`
    );

    //console.log("answer: ", answer);

    const today = new Date();
    const date = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
    const time = `${
      today.getHours() < 10 ? "0" + today.getHours() : today.getHours()
    }:${
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()
    }:${
      today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds()
    }`;

    const dateTime = date + " " + time;
    //console.log(dateTime);

    const jsonObj = {
      grn_date: grn_date,
      grn_entry_close: true,
      updated_by: "Admin",
      updated_at: dateTime,
    };

    if (answer) {
      const url =
        "/api/superzop/commercial/purchasedetails/creategrnentrycloserecord";

      //baseUrl.post(url, jsonObj).then(res => {
      deliveryCommercialServer
        .post(url, jsonObj)
        .then((res) => {
          //console.log('closeGRNEntry ', res);
          if (res.status === 200) {
            toast(`Closed GRN for ${grn_date} date successfully`, {
              type: toast.TYPE.SUCCESS,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const showListGRNOpen = () => {
    console.log("showListGRNOpen");
  };

  const syncPOsToZoho = () => {
    console.log("syncPOsToZoho");
    const grn_date = `${getDate.getFullYear()}-${
      getDate.getMonth() + 1 < 10
        ? "0" + (getDate.getMonth() + 1)
        : getDate.getMonth() + 1
    }-${getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}`;
    setZohoSyncButtonStatus(true);
    const getUrl = `/create/purchase-order?organization_id=60014544472&grn_date=${grn_date}`;
    zohoSyncServer
      .get(getUrl)
      .then((response) => {
        //console.log('response: ',response);
        console.log(response);
        if (response.status === 200) {
          toast(`POs synched to Zoho`, {
            type: toast.TYPE.SUCCESS,
          });
        } else {
          toast(`Issue in synching all POs`, {
            type: toast.TYPE.WARNING,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`POs sync error: ${error}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const deletePurchaseItem = (item) => {
    const itemToDelete = item.unique_key;
    //console.log(`${getDate.getFullYear()}/${(getDate.getMonth() + 1) < 10 ? "0" + (getDate.getMonth() + 1) : getDate.getMonth() + 1}/${getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}-${(getDate.getMonth() + 1) < 10 ? "0" + (getDate.getMonth() + 1) : getDate.getMonth() + 1}-${getDate.getFullYear()}/${itemToDelete}`)
    console.log(item);
    const answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // Object.keys(itemCodeList).forEach(itemList=>{
      //     if(itemList.includes(itemToDelete)){
      //PurchaseDetailsData.child(`${getDate.getFullYear()}/${(getDate.getMonth() + 1) < 10 ? "0" + (getDate.getMonth() + 1) : getDate.getMonth() + 1}/${getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}-${(getDate.getMonth() + 1) < 10 ? "0" + (getDate.getMonth() + 1) : getDate.getMonth() + 1}-${getDate.getFullYear()}/${itemToDelete}`).remove().then(() => { toast(`Deleted ${itemToDelete} successfully`, { type: toast.TYPE.SUCCESS }); /*window.location.reload();*/ })
      // }})

      //const unique_key = `${getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}-${(getDate.getMonth() + 1) < 10 ? "0" + (getDate.getMonth() + 1) : getDate.getMonth() + 1}-${getDate.getFullYear()}-${itemToDelete}`;

      const unique_key = itemToDelete;
      const firebaseKey = unique_key.substring(11);

      //const url = `http://services2.superzop.com:3000/api/superzop/commercial/purchasedetails/deletepurchasedetails?key=${unique_key}`;

      const total_weight_received = item.total_weight_received;

      if (total_weight_received) {
        const item_id = item.article_code;
        const grn_date = item.grn_date;

        const update_si_url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${grn_date}&item_id=${item_id}`;

        const obj = {
          grn_total_weight: `-${total_weight_received}`,
        };

        deliveryCommercialServer
          .put(update_si_url, obj)
          .then((response) => {
            const url = `/api/superzop/commercial/purchasedetails/deletepurchasedetails?key=${unique_key}`;
            console.log("unique_key: ", unique_key, " url: ", url);

            /*const reqJSON = {

                        url: url
                    }

                    request.delete(reqJSON, function (error, response, body) {
                        if (error) throw error;

                        const info = JSON.stringify(body, null, "");

                        console.log(" body info " + info);
                        console.log(" statusCode " + response.statusCode);
                        if (!error && response.statusCode === 200) {

                            console.log(" statusCode body info " + info);
                            //console.log(info.forks_count + " Forks");
                        }
                        return null;
                    });*/

            const sqlProm = new Promise((resolve, reject) => {
              //baseUrl.delete(url).then(res => {
              deliveryCommercialServer
                .delete(url)
                .then((res) => {
                  console.log("deletePurchaseItem ", res);
                  resolve("Success");
                })
                .catch((error) => {
                  console.error(error);
                  reject(error);
                });
            });

            const firebaseProm = new Promise((resolve, reject) => {
              PurchaseDetailsData.child(
                `${getDate.getFullYear()}/${
                  getDate.getMonth() + 1 < 10
                    ? "0" + (getDate.getMonth() + 1)
                    : getDate.getMonth() + 1
                }/${
                  getDate.getDate() < 10
                    ? "0" + getDate.getDate()
                    : getDate.getDate()
                }-${
                  getDate.getMonth() + 1 < 10
                    ? "0" + (getDate.getMonth() + 1)
                    : getDate.getMonth() + 1
                }-${getDate.getFullYear()}/${firebaseKey}`
              )
                .remove()
                .then(() => {
                  resolve("Success");
                })
                .catch((error) => {
                  reject(error);
                });
            });

            Promise.all([sqlProm, firebaseProm])
              .then(() => {
                window.location.reload();

                toast(`Deleted ${itemToDelete} successfully`, {
                  type: toast.TYPE.SUCCESS,
                });
              })
              .catch((error) => {
                toast(`Failed to deleted item`, { type: toast.TYPE.ERROR });
              });

            toast("Synced System Inventory", { type: toast.TYPE.SUCCESS });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const url = `/api/superzop/commercial/purchasedetails/deletepurchasedetails?key=${unique_key}`;
        console.log("unique_key: ", unique_key, " url: ", url);

        /*const reqJSON = {

                    url: url
                }

                request.delete(reqJSON, function (error, response, body) {
                    if (error) throw error;

                    const info = JSON.stringify(body, null, "");

                    console.log(" body info " + info);
                    console.log(" statusCode " + response.statusCode);
                    if (!error && response.statusCode === 200) {

                        console.log(" statusCode body info " + info);
                        //console.log(info.forks_count + " Forks");
                    }
                    return null;
                });*/

        const sqlProm = new Promise((resolve, reject) => {
          //baseUrl.delete(url).then(res => {
          deliveryCommercialServer
            .delete(url)
            .then((res) => {
              console.log("deletePurchaseItem ", res);
              resolve("Success");
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        });

        const firebaseProm = new Promise((resolve, reject) => {
          PurchaseDetailsData.child(
            `${getDate.getFullYear()}/${
              getDate.getMonth() + 1 < 10
                ? "0" + (getDate.getMonth() + 1)
                : getDate.getMonth() + 1
            }/${
              getDate.getDate() < 10
                ? "0" + getDate.getDate()
                : getDate.getDate()
            }-${
              getDate.getMonth() + 1 < 10
                ? "0" + (getDate.getMonth() + 1)
                : getDate.getMonth() + 1
            }-${getDate.getFullYear()}/${firebaseKey}`
          )
            .remove()
            .then(() => {
              resolve("Success");
            })
            .catch((error) => {
              reject(error);
            });
        });

        Promise.all([sqlProm, firebaseProm])
          .then(() => {
            window.location.reload();

            toast(`Deleted ${itemToDelete} successfully`, {
              type: toast.TYPE.SUCCESS,
            });
          })
          .catch((error) => {
            toast(`Failed to deleted item`, { type: toast.TYPE.ERROR });
          });
      }
    }
  };

  console.log("grnEntryClose ", grnEntryClose, " getDate: ", getDate);

  const addGRNButton = (index, item) => {
    //console.log('item: ', item);

    let button = null;
    const grnAvailableTernary = !gRNAvailable[index] ? (
      <td>
        <center>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              setAddGRNModal(true);
              setEditingItemDetails(item);
              setItemData(item.uniqueKey);
            }}
          >
            Add
          </Button>
        </center>
      </td>
    ) : (
      <td>
        <center>
          <Button
            size="sm"
            onClick={() => {
              setEditGRNModal(true);
              setEditingItemDetails(item);
              setItemData(item.uniqueKey);
            }}
          >
            Edit
          </Button>
        </center>
      </td>
    );
    const infoCorrectTernary =
      item.hasOwnProperty("info_correct") && item.info_correct === "Yes" ? (
        <td>
          <center>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                setViewGRNModal(true);
                setEditingItemDetails(item);
              }}
            >
              View
            </Button>
          </center>
        </td>
      ) : (
        grnAvailableTernary
      );

    const grnEntryCloseTernary = grnEntryClose ? (
      <td>
        <center>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setViewGRNModal(true);
              setEditingItemDetails(item);
            }}
          >
            View
          </Button>
        </center>
      </td>
    ) : (
      infoCorrectTernary
    );

    const grnEntryCloseVar = !item.grn_entry_close ? (
      item.grn_date ? (
        <td>
          <center>
            <Button
              size="sm"
              disabled={true}
              onClick={() => {
                setEditGRNModal(true);
                setEditingItemDetails(item);
                setItemData(item.uniqueKey);
              }}
            >
              Edit
            </Button>
          </center>
        </td>
      ) : (
        <td>
          <center>
            <Button
              size="sm"
              variant="success"
              onClick={() => {
                setAddGRNModal(true);
                setEditingItemDetails(item);
                setItemData(item.uniqueKey);
              }}
            >
              Add
            </Button>
          </center>
        </td>
      )
    ) : (
      <td>
        <center>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setViewGRNModal(true);
              setEditingItemDetails(item);
            }}
          >
            View
          </Button>
        </center>
      </td>
    );

    const adminVar = item.grn_date ? (
      <td>
        <center>
          <Button
            size="sm"
            onClick={() => {
              setEditGRNModal(true);
              setEditingItemDetails(item);
              setItemData(item.uniqueKey);
            }}
          >
            Edit
          </Button>
        </center>
      </td>
    ) : (
      <td>
        <center>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              setAddGRNModal(true);
              setEditingItemDetails(item);
              setItemData(item.uniqueKey);
            }}
          >
            Add
          </Button>
        </center>
      </td>
    );

    button =
      !validatePurchaseExportOnlyUser(
        sessionStorage.getItem(LOGGED_USER_EMAIL)
      ) &&
      !validateStockOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) &&
      !validatePurchaseOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))
        ? grnEntryCloseVar
        : null;

    const idAdmin = validateIsAdminUser(
      sessionStorage.getItem(LOGGED_USER_EMAIL)
    );

    if (idAdmin) {
      return adminVar;
    } else {
      return button;
    }
  };

  return (
    <>
      <NavBar />
      <Navbar style={{ paddingTop: 100 }}>
        <Form inline>
          {/* <Badge variant="dark">Select Purchase Date </Badge> */}
          <InputGroup
            size="sm"
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            <DropdownButton
              size="sm"
              id="dropdown-basic-button"
              title={
                dateRangeType === "Purchase" ? "Purchase Date" : "GRN Date"
              }
            >
              <Dropdown.Item
                value="Purchase"
                onClick={(e) => {
                  e.preventDefault();
                  setDateRangeType("Purchase");
                  sessionStorage.setItem("dateRangeType", "Purchase");
                }}
              >
                Purchase Date
              </Dropdown.Item>
              <Dropdown.Item
                value="GRN"
                onClick={(e) => {
                  e.preventDefault();
                  setDateRangeType("GRN");
                  sessionStorage.setItem("dateRangeType", "GRN");
                }}
              >
                GRN Date
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup>
          <DatePicker
            selected={getDate}
            onChange={handleChange}
            minDate={new Date("2017/01/01")}
            maxDate={new Date()}
            aria-describedby="basic-addon1"
            dateFormat="dd/MM/yyyy"
            className="red-border"
            customInput={<CustomDateInput />}
          />
          {productEditTable.length > 0 && (
            <InputGroup style={{ marginLeft: "5px" }}>
              <Form.Control
                id="searchProduct"
                size="sm"
                style={{ width: "250px" }}
                className="searchBox"
                placeholder="Item Id / Item Name / Invoice No / GRN / Supplier"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value == "") setFilterList("");
                }}
              />
            </InputGroup>
          )}
        </Form>
        {validatePurchaseUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
          <Button
            size="sm"
            style={{ margin: 10 }}
            variant="success"
            onClick={() => {
              openAddPurchase();
            }}
          >
            + Purchase
          </Button>
        ) : null}
        {validatePurchaseUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
          <Button
            size="sm"
            style={{ margin: 10 }}
            variant="success"
            onClick={() => {
              openRTVPage();
            }}
          >
            RTV
          </Button>
        ) : null}
        {validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
          <Button
            size="sm"
            style={{ margin: 10 }}
            variant="success"
            onClick={() => {
              openSuppliersPage();
            }}
          >
            Suppliers
          </Button>
        ) : null}
        {dateRangeType === "GRN" ? (
          !grnEntryClose ? (
            validateGRNUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
              <Button
                size="sm"
                variant="success"
                style={{ margin: 10 }}
                onClick={() => {
                  closeGRNEntry();
                }}
              >
                Open GRN
              </Button>
            ) : (
              <Button
                size="sm"
                variant="success"
                disabled={true}
                style={{ margin: 10 }}
                onClick={() => {
                  closeGRNEntry();
                }}
              >
                Open GRN
              </Button>
            )
          ) : (
            <div>
              <Button
                size="sm"
                variant="danger"
                disabled={true}
                style={{ margin: 10 }}
              >
                GRN Closed
              </Button>
              <Button
                size="sm"
                variant="danger"
                disabled={zohoSyncButtonStatus}
                style={{ margin: 10 }}
                onClick={() => {
                  syncPOsToZoho();
                }}
              >
                Zoho PO Sync
              </Button>
            </div>
          )
        ) : null}
        <Link to={"/purchaseReport"}>
          <Button style={{ margin: 10 }} size="sm" variant="warning">
            Reports
          </Button>
        </Link>
        {validatePurchaseReportUser(
          sessionStorage.getItem(LOGGED_USER_EMAIL)
        ) && (
          <Link style={{ margin: 10 }} to={"/priceupdates"}>
            <Button size="sm" variant="warning">
              Price Updates
            </Button>
          </Link>
        )}
        {/* {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && (
          <Button
            style={{ margin: 10 }}
            size="sm"
            variant="dark"
            onClick={() => {
              setSyncModal(true);
            }}
          >
            Sync
          </Button>
        )} */}
        {grnEntryClose &&
        validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) &&
        productEditTable.length > 0 ? (
          <Button
            size="sm"
            variant={"warning"}
            onClick={() => {
              setExportDetails(true);
            }}
          >
            Export | {`${getDate.getDate()}-${getDate.getMonth() + 1}`}{" "}
          </Button>
        ) : validatePurchaseExportUser(
            sessionStorage.getItem(LOGGED_USER_EMAIL)
          ) ? (
          <Button
            size="sm"
            variant={"warning"}
            onClick={() => {
              setExportDetails(true);
            }}
          >
            Export | {`${getDate.getDate()}-${getDate.getMonth() + 1}`}{" "}
          </Button>
        ) : null}

        {productEditTable.length > 0 &&
        validatePurchaseExportUser(
          sessionStorage.getItem(LOGGED_USER_EMAIL)
        ) ? (
          <Button
            size="sm"
            variant={"warning"}
            style={{ margin: 10 }}
            onClick={() => {
              setListGRNOpen(true);
            }}
          >
            List GRN Open
          </Button>
        ) : null}
        {validatePurchaseUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
          <Link to={"/invoices"}>
            <Button size="sm" variant={"warning"} style={{ margin: 10 }}>
              Invoices
            </Button>
          </Link>
        ) : null}
      </Navbar>
      <br />
      <br />
      {renderAllDetails ? (
        <Table striped bordered>
          <thead>
            <tr>
              <td>
                <center>PO Number</center>
              </td>
              <td>
                <center>Item Id</center>
              </td>
              <td>
                <center>Item Name</center>
              </td>
              <td>
                <center>Category</center>
              </td>
              <td>
                <center>Purchase Price</center>
              </td>
              <td>
                <center>Bags Purch.</center>
              </td>
              <td>
                <center>Total Weight (Kg/Pc)</center>
              </td>
              <td>
                <center>Supplier</center>
              </td>
              <td>
                <center>Bags Recvd.</center>
              </td>
              <td>
                <center>Weight Recvd.(Kg/Pc)</center>
              </td>
              <td>
                <center>Invoice No.</center>
              </td>
              <td>
                <center>GRN No.</center>
              </td>
              <td>
                <center>
                  {dateRangeType === "Purchase"
                    ? "Material Receiving Date"
                    : "Purchase Date"}
                </center>
              </td>
              {!validatePurchaseExportOnlyUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) &&
              !validateStockOnlyUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) ? (
                <td>
                  <center> Purchase </center>
                </td>
              ) : null}
              {!validatePurchaseExportOnlyUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) &&
              !validatePurchaseOnlyUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) &&
              !validateStockOnlyUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) ? (
                <td>
                  <center> GRN </center>
                </td>
              ) : null}
              {validateIsAdminUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) && (
                <td>
                  <center> X </center>
                </td>
              )}
              <td>
                <center> Print QR </center>
              </td>
            </tr>
          </thead>
          <tbody>
            {renderAllDetails
              ? filterList
                ? Object.keys(filterList).map((items, index) => (
                    <>
                      {" "}
                      {((filterList[items].supplier_id &&
                        filterList[items].supplier_id.includes("VA") &&
                        validateDCManagerUser(
                          sessionStorage.getItem(LOGGED_USER_EMAIL)
                        )) ||
                        !(
                          filterList[items].supplier_id &&
                          filterList[items].supplier_id.includes("VA")
                        )) && (
                        <tr
                          style={{
                            backgroundColor:
                              filterList[items].hasOwnProperty(
                                "info_correct"
                              ) && filterList[items].info_correct === "No"
                                ? "#FFCCCC"
                                : "",
                          }}
                        >
                          <td>
                            <center>
                              {filterList[items].po_number || ""}{" "}
                            </center>
                          </td>
                          <td>
                            <center>{filterList[items].article_code} </center>
                          </td>
                          <td>
                            <center>{filterList[items].item_name} </center>
                          </td>
                          <td>
                            <center>{filterList[items].category} </center>
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                filterList[items].purchase_price ==
                                filterList[items].base_price
                                  ? ""
                                  : filterList[items].purchase_price >
                                    filterList[items].base_price
                                  ? "#CCFFCC"
                                  : "#FFCCCC",
                            }}
                          >
                            <center>{filterList[items].purchase_price} </center>
                          </td>
                          <td>
                            <center>{filterList[items].bag_purchased} </center>
                          </td>
                          <td>
                            <center>
                              {filterList[items].total_weight}{" "}
                              {filterList[items].uom}{" "}
                            </center>
                          </td>
                          <td>
                            <center>{filterList[items].supplier_name} </center>
                          </td>
                          <td>
                            <center>{filterList[items].bags_received} </center>
                          </td>
                          <td>
                            <center>
                              {filterList[items].total_weight_received}{" "}
                            </center>
                          </td>
                          <td>
                            <center>{filterList[items].invoice_number} </center>
                          </td>
                          <td>
                            <center>{filterList[items].grn_no} </center>
                          </td>
                          <td>
                            <center>
                              {GetDateInMMM(filterList[items].grn_date)}{" "}
                            </center>
                          </td>
                          {!validateStockOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) &&
                          !validatePurchaseExportOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) ? (
                            !gRNAvailable[index] ? (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setEditPurchaseModal(true);
                                      setEditingItemDetails(filterList[items]);
                                      setItemData(filterList[items].uniqueKey);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </center>
                              </td>
                            ) : (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => {
                                      setViewPurchaseModal(true);
                                      setEditingItemDetails(filterList[items]);
                                    }}
                                  >
                                    View
                                  </Button>
                                </center>
                              </td>
                            )
                          ) : null}
                          {/* {(!validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && !validatePurchaseOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? (((filterList[items].hasOwnProperty("info_correct") && (filterList[items].info_correct === "Yes")) ? <td><center><Button size="sm" variant="secondary" onClick={() => { setViewGRNModal(true); setEditingItemDetails(filterList[items]) }}>View</Button></center></td> : (!gRNAvailable[index] ? <td><center><Button size="sm" variant="success" onClick={() => { setAddGRNModal(true); setEditingItemDetails(filterList[items]); setItemData(filterList[items].uniqueKey) }}>Add</Button></center></td> :
                                            <td><center><Button size="sm" onClick={() => { setEditGRNModal(true); setEditingItemDetails(filterList[items]); setItemData(filterList[index].uniqueKey) }}>Edit</Button></center></td>))) : null} */}
                          {validateGRNUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? addGRNButton(index, filterList[items]) : <td></td>}
                          {validateIsAdminUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) && (
                            // <td><Button onClick={() => deletePurchaseItem(filterList[items].unique_key)}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>
                            <td>
                              <Button
                                onClick={() =>
                                  deletePurchaseItem(filterList[items])
                                }
                              >
                                <i
                                  style={{ color: "white" }}
                                  class="fas fa-trash-alt fa-1x"
                                ></i>
                              </Button>
                            </td>
                          )}
                          <td>
                            {filterList[items].grn_no && (
                              <Button
                                onClick={() => handleShow(filterList[items])}
                              >
                                <i
                                  style={{ color: "white" }}
                                  class="fa fa-print"
                                ></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                : Object.keys(productEditTable).map((items, index) => (
                    <>
                      {((filterList[items].supplier_id &&
                        filterList[items].supplier_id.includes("VA") &&
                        validateIsAdminUser(
                          sessionStorage.getItem(LOGGED_USER_EMAIL)
                        )) ||
                        !(
                          filterList[items].supplier_id &&
                          filterList[items].supplier_id.includes("VA")
                        )) && (
                        <tr
                          style={{
                            backgroundColor:
                              productEditTable[items].hasOwnProperty(
                                "info_correct"
                              ) &&
                              (productEditTable[items].info_correct === "No"
                                ? "#FFCCCC"
                                : ""),
                          }}
                        >
                          <td>
                            <center>
                              {productEditTable[items].po_number}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].article_code}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].item_name}{" "}
                            </center>
                          </td>
                          <td>
                            <center>{productEditTable[items].category} </center>
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                productEditTable[items].purchase_price ==
                                productEditTable[items].base_price
                                  ? ""
                                  : productEditTable[items].purchase_price >
                                    productEditTable[items].base_price
                                  ? "#CCFFCC"
                                  : "#FFCCCC",
                            }}
                          >
                            <center>
                              {productEditTable[items].purchase_price}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].bag_purchased}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].total_weight}{" "}
                              {productEditTable[items].uom}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].supplier_name}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].bags_received}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].total_weight_received}{" "}
                            </center>
                          </td>
                          <td>
                            <center>
                              {productEditTable[items].invoice_number}{" "}
                            </center>
                          </td>
                          <td>
                            <center>{productEditTable[items].grn_no} </center>
                          </td>
                          <td>
                            <center>
                              {GetDateInMMM(productEditTable[items].grn_date)}{" "}
                            </center>
                          </td>
                          {!validateStockOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) &&
                          !validatePurchaseExportOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) ? (
                            !gRNAvailable[index] ? (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setEditPurchaseModal(true);
                                      setEditingItemDetails(
                                        productEditTable[items]
                                      );
                                      setItemData(
                                        productEditTable[items].uniqueKey
                                      );
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </center>
                              </td>
                            ) : (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => {
                                      setViewPurchaseModal(true);
                                      setEditingItemDetails(
                                        productEditTable[items]
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                </center>
                              </td>
                            )
                          ) : null}
                          {!validateStockOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) &&
                          !validatePurchaseExportOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) &&
                          !validatePurchaseOnlyUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) ? (
                            productEditTable[items].hasOwnProperty(
                              "info_correct"
                            ) &&
                            productEditTable[items].info_correct === "Yes" ? (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => {
                                      setViewGRNModal(true);
                                      setEditingItemDetails(
                                        productEditTable[items]
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                </center>
                              </td>
                            ) : !gRNAvailable[index] ? (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => {
                                      setAddGRNModal(true);
                                      setEditingItemDetails(
                                        productEditTable[items]
                                      );
                                      setItemData(
                                        productEditTable[items].uniqueKey
                                      );
                                    }}
                                  >
                                    Add
                                  </Button>
                                </center>
                              </td>
                            ) : (
                              <td>
                                <center>
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setEditGRNModal(true);
                                      setEditingItemDetails(
                                        productEditTable[items]
                                      );
                                      setItemData(
                                        productEditTable[index].uniqueKey
                                      );
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </center>
                              </td>
                            )
                          ) : null}
                          {validateIsAdminUser(
                            sessionStorage.getItem(LOGGED_USER_EMAIL)
                          ) && (
                            // <td><Button onClick={() => deletePurchaseItem(productEditTable[items].uniqueKey)}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>
                            <td>
                              <Button
                                onClick={() =>
                                  deletePurchaseItem(productEditTable[items])
                                }
                              >
                                <i
                                  style={{ color: "white" }}
                                  class="fas fa-trash-alt fa-1x"
                                ></i>
                              </Button>
                            </td>
                          )}
                        </tr>
                      )}
                    </>
                  ))
              : ""}
          </tbody>
        </Table>
      ) : (
        ""
      )}

      {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}

      {exportDetails && (
        <ExportDetails
          size="sm"
          listedPurchaseDetails={productEditTable}
          multipleExport={setExportDetails}
          exportDate={getDate}
        />
      )}

      {editPurchaseModal && (
        <EditPurchaseModal
          show={editPurchaseModal}
          onHide={() => setEditPurchaseModal(false)}
          data={editingItemDetails}
          itemCode={itemData}
          selectedDate={selectedDate}
          selectedMonth={monthData}
          selectedYear={getDate.getFullYear()}
        />
      )}
      {
        <ViewPurchaseModal
          show={viewPurchaseModal}
          onHide={() => setViewPurchaseModal(false)}
          data={editingItemDetails}
        />
      }
      {viewGRNModal && (
        <ViewGRNModal
          show={viewGRNModal}
          onHide={() => setViewGRNModal(false)}
          data={editingItemDetails}
        />
      )}
      {editGRNModal && (
        <EditGRNModal
          show={editGRNModal}
          onHide={() => setEditGRNModal(false)}
          data={editingItemDetails}
          itemCode={itemData}
          selectedDate={selectedDate}
          selectedMonth={monthData}
          selectedYear={getDate.getFullYear()}
        />
      )}
      {addGRNModal && (
        <AddGRNModal
          show={addGRNModal}
          onHide={() => setAddGRNModal(false)}
          data={editingItemDetails}
          itemCode={itemData}
          selectedDate={selectedDate}
          selectedMonth={monthData}
          selectedYear={getDate.getFullYear()}
        />
      )}
      {syncModal && (
        <SyncPurchaseDetailsModal
          show={syncModal}
          onHide={() => setSyncModal(false)}
        />
      )}
      {listGRNOpen && (
        <ListGRNOpenModal
          show={listGRNOpen}
          onHide={() => setListGRNOpen(false)}
        />
      )}

      <Modal show={show} dialogClassName="custom-modal" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Print Qr Code </Modal.Title>
          <style type="text/css" media="print">
            {
              "\
              @page { size: 50mm 25mm, orientation:portrait  }\
            "
            }
          </style>
          <ReactToPrint
            trigger={() => <Button> Print </Button>}
            content={() => componentRef}
          />
        </Modal.Header>

        <Modal.Body ref={(el) => (componentRef = el)}>
          <Container>
            {BarCodeList &&
              BarCodeList.map((item, i) => {
                return (
                  <div
                    style={{
                      pageBreakAfter: "always",
                      marginTop: "150px",marginLeft: "5px"
                    }}
                  >
                    <Row className="">
                      <Col>
                        <QRCode
                          title="stockConversion"
                          value={item}
                          size={500}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "auto",
                          }}
                          viewBox={`0 0 200 200`}
                        />
                      </Col>
                      <Col>
                        <h2 style={{ "font-size": "110px" }}>
                          <b>
                            <u> {ProductItem?.new_item_name?.toUpperCase()} </u>
                          </b>
                        </h2>

                        {/* <p> Bar code : {item} </p> */}
                        {/* <p>BATCH NO : </p> */}

                        {/* <b>NET QUANTITY: </b> {ProductItem?.total_weight} {' '}  {ProductItem?.uom} <br/> */}
                        {/* <b>MRP:</b>  {Product?.market_price} {' '}  <br/> */}
                        {/* <b>DATE OF PACKAGING:</b>  {ProductItem?.date_of_packing} {' '} <br/> */}
                        {/* <b>BEST BEFORE :</b>  {'90 Days'} {' '} <br/>
                            <b>POCESSED & PACKAGED BY : </b>  Retranz Infolabs Private Limited. D1, Indian corporation compound Diva Anjur Village, bhiwandi Thane - 421302 (Maharastia) */}
                      </Col>
                    </Row>

                    <Row>
                      <h3 style={{ "font-size": "110px" }}>
                        {BarCodeSplitList[i]}{" "}
                      </h3>
                    </Row>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <style type="text/css" media="print">
            {
              "\
              @page { size: 50mm 25mm  }\
            "
            }
          </style>
          <ReactToPrint
            trigger={() => <Button> Print </Button>}
            content={() => componentRef}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ListPurchase;
