import database from '../../../service/firebaseConfig';
import { toast } from 'react-toastify';


const DeleteNotifications = (callback_confirm,keys,Series) => {

            
    const seriesList = ['A','B','AA','BB','NN','K','N','P','NK','ST','PG']

    const answer=window.confirm("Are you sure you want to delete?")

    if(answer){
    
    if(Series == "" || Series == "all" || Series == "All"){

    seriesList.forEach(items=>{
        
        console.log("Deleted item ::",items)
        database.database().ref(`Notifications/${items}/${keys}`).remove().then(()=>{

            setTimeout(() => {
                window.location.reload()
            }, 3000);
            return toast(`Succesfully deleted the record from series ${items}`,{ type: toast.TYPE.SUCCESS }) 
        })
    })

    }
    else{
        database.database().ref(`Notifications/${Series}/${keys}`).remove().then(()=>{
            callback_confirm(`data deleted of ${keys} in series ${Series}`)
        })

    }
}
}

export default DeleteNotifications
