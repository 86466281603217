import React from 'react';
import ReactDOM from 'react-dom';
import RootRoute from './routes';
import './index.css';
import { StateProvider } from './store';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';

const RootComponent = (
  <StateProvider>
    <RootRoute />
  </StateProvider>
);

ReactDOM.render(RootComponent, document.getElementById('root'));
