import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function PageNotFound({ ...props}) {
    const redirectToHomePage = () => {
      window.location.href="/#/Dashboard";
    }

    return (
      <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateX(-50%) translateY(-50%)"}}>
        <center><h1>404</h1></center>
        <center><h2>OOPS! PAGE NOT FOUND</h2></center>
        <p>
          We can't find the page you are looking for. You can either return to the previous page, or visit our home page.
        </p>
        <center><Button variant="primary" onClick={redirectToHomePage}>
            Back to home page
        </Button></center>
      </div>
    )
}

export default PageNotFound
