import styled from 'styled-components';

export const ChangeRegionContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;    

    select {
        background: #fff;
        outline: none
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin: 0rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    label {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.2);
        
        span {
            font-size: 0.9rem;
        }
    }
`;