import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  Stack,
  Select,
  TextInput,
  Divider,
  Text,
  Table,
  Box,
  NumberInput,
  Modal,
  Flex,
  Space,
} from "@mantine/core";
import { toast } from "react-toastify";

export function AddIngredientModal({
  availableItemIds,
  opened,
  setOpened,
  onModifyIngredient,
  productMaster,
}) {
  const [itemSelectDropdownValues, setItemSelectDropdownValues] = useState([]);
  const [itemId, setItemId] = useState("");
  const [weight, setWeight] = useState(null);
  // const isItemSelectDisabled = useRef(item_id ?? null);

  function validateFormFields() {
    if (!itemId || !weight) return false;
    return true;
  }

  const onFormSubmit = useCallback(() => {
    if (!validateFormFields())
      return toast("Please fill all fields", { type: toast.TYPE.WARNING });

    onModifyIngredient("add", { item_id: itemId, weight: weight });
    setOpened(false);
  }, [itemId, weight]);

  useEffect(() => {
    setItemSelectDropdownValues(
      availableItemIds.map((item_id) => {
        const {
          brand = "",
          item_description1 = "",
          weight = "",
        } = productMaster[item_id] || {};
        return {
          value: item_id,
          label: `${item_id} | ${item_description1} | ${brand} | ${weight}`,
        };
      })
    );
  }, []);

  return (
    <Modal
      size={"lg"}
      opened={opened}
      onClose={() => setOpened(false)}
      title="Add Ingredient"
    >
      <Stack spacing={"1.5rem"} align="stretch" justify="center">
        <Select
          required
          // disabled={isItemSelectDisabled.current}
          value={itemId}
          onChange={setItemId}
          label="Select Item"
          placeholder="Pick one"
          data={itemSelectDropdownValues}
        />
        {/* <TextInput label="Weight" disabled /> */}
        <NumberInput
          label="Weight kg(s)"
          min={0.01}
          precision={2}
          required
          onChange={(val) => {
            setWeight(val);
          }}
        />

        <Space v={"sm"} />
        <Group grow style={{ width: "100%" }}>
          <Button
            color="teal"
            onClick={() => {
              onFormSubmit();
            }}
          >
            Add
          </Button>
          <Button color="red" onClick={() => setOpened(false)}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
