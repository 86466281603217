import React, { createContext, useReducer } from 'react';
import { SEARCH_PRODUCTS, REGION_CHANGE_BOM, REGION_CHANGE_PUNE,REGION_CHANGE_S, REGION_CHANGE_ALL, CITY_ALL, EDIT_PRODUCTS, CLOSE_SCREEN, UPDATE_DATA_IN_DB, ADD_PRODUCTS, BULK_UPLOADS, CITY_CHANGE, CITY_MUMBAI, CITY_PUNE, SERIES_S } from '../constants/allConstants';

console.log("store executing")
const initialState = [];
const rootStore = createContext(initialState);
const { Provider } = rootStore;

const StateProvider = ({ children }) => {

    const validInpCheck = (data) => {

        return data !== undefined ? (data !== null ? (data.length > 0 ? true : false) : false) : false;
    };
    const [state, dispatch] = useReducer((state, action) => {
        console.log(action)
        switch (action.type) {
            case SEARCH_PRODUCTS:
                const { searchText } = action;
                if (searchText === null || searchText === "") {   // if 1st load: show all data & for after clearing search text load init data
                    const temp = { ...state };
                    const resArr = {};
                    const isCity = temp.hasOwnProperty('curr_city') ? temp.curr_city : false;
                    const isRegion_BOM = temp.hasOwnProperty('bom_region') ? temp.bom_region : false;
                    const isRegion_PUNE = temp.hasOwnProperty('pune_region') ? temp.pune_region : false;
                    const isRegion_ALL = temp.hasOwnProperty('all_region') ? temp.all_region : false;
                    if (isCity !== false) { resArr['curr_city'] = isCity; }
                    if (isRegion_BOM !== false) { resArr['bom_region'] = isRegion_BOM; }
                    if (isRegion_PUNE !== false) { resArr['pune_region'] = isRegion_PUNE; }
                    if (isRegion_ALL !== false) { resArr['all_region'] = isRegion_ALL; }
                    console.log("Search Products"+isRegion_BOM+isRegion_PUNE+isRegion_ALL);
                    return resArr;
                }
                let productId, productName, productBrand, filteredResult = [], filterText;
                // const isNoRegex = /\d/;
                const isNoRegex = /^\d+$/;
                filterText = validInpCheck(searchText) ? searchText.trim() : "";
                if (isNoRegex.test(filterText)) {   // filter if product id
                    filteredResult = state.allProducts.filter(obj => {
                        productId = obj.keyNo;
                        return productId.match(filterText);
                    });
                } else if (!isNoRegex.test(filterText)) {   // filter based on product name
                    filteredResult = state.allProducts.filter(obj => {
                        productName = validInpCheck(obj.item_description1) ? obj.item_description1.trim().toLowerCase() : "";
                        productBrand = validInpCheck(obj.brand) ? obj.brand.trim().toLowerCase() : "";
                        filterText = filterText.toLowerCase();
                        return productName.match(filterText) || productBrand.match(filterText);
                    });
                }
                filteredResult = (filteredResult.length === 0) ? [{ id: 0, productNotFound: true }] : filteredResult;
                return { ...state, filteredRes: filteredResult };

            case REGION_CHANGE_BOM: return { bom_region: action.data, curr_city: CITY_MUMBAI };

            case REGION_CHANGE_PUNE: return { pune_region: action.data, curr_city: CITY_PUNE };

            case REGION_CHANGE_ALL: return { all_region: action.data, curr_city: CITY_ALL };

            case EDIT_PRODUCTS: 
                const temp = { ...state };
                const resArr = {};
                const isCity = temp.hasOwnProperty('curr_city') ? temp.curr_city : false;
                const isRegion_BOM = temp.hasOwnProperty('bom_region') ? temp.bom_region : false;
                const isRegion_PUNE = temp.hasOwnProperty('pune_region') ? temp.pune_region : false;
                const isRegion_ALL = temp.hasOwnProperty('all_region') ? temp.all_region : false;
                if (isCity !== false) { resArr['curr_city'] = isCity; }
                if (isRegion_BOM !== false) { resArr['bom_region'] = isRegion_BOM; }
                if (isRegion_PUNE !== false) { resArr['pune_region'] = isRegion_PUNE; }
                if (isRegion_ALL !== false) { resArr['all_region'] = isRegion_ALL; }
                return { editData: action.data, regionDetails:resArr, addProduct: true, bulkUploads: true, hideRegionOption: true };

            case CLOSE_SCREEN: return { screenClosed: true };

            case UPDATE_DATA_IN_DB: return {};

            case ADD_PRODUCTS: return { addProduct: true, bulkUploads: false, hideRegionOption: true };

            case BULK_UPLOADS: return { bulkUploads: true, addProduct: false, hideRegionOption: true };

            case CITY_CHANGE: return { curr_city: action.data };

            case "stockModalShow": return {showStockModal: true}

            default: return {};
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}> {children} </Provider>
};

export { rootStore, StateProvider };