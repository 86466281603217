import React, { useState, useEffect } from "react";
import {
  Container,
  Toast,
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import firebase from "../../service/firebaseConfig";
import { useForm } from "react-hook-form";
import { Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import { support_portal_url } from "../../service/servicesConfig";
import AddUserModalPopup from "./SuperKreditPortalUsers/adduser";
import EditUserModalPopup from "./SuperKreditPortalUsers/edituser";
import DeleteUserModalPopup from "./SuperKreditPortalUsers/deleteuser";

const SuperkreditPortal = () => {
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [rowIndex, setRowIndex] = useState();
  const [deletedUser, setDeletedUser] = useState({});
  const [addUserModalShow, setAddUserModalShow] = useState(false);
  const [editUserModalShow, setEditUserModalShow] = useState(false);
  const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    let apiurlstr = `${support_portal_url}/api/superkreditportalusers?token=${sessionStorage.getItem(
      "apiToken"
    )}`;
    fetch(apiurlstr)
      .then((response) => {
        return response["status"] == 200 ? response.json() : [];
      })
      .then((result) => {
        if (result["success"] == 1) {
          let userdata = result["data"];
          let userTableData = [];
          for (let i = 0; i < userdata.length; i++) {
            let ele = userdata[i];
            ele.otp_bypass = ele.otp_bypass ? Number(ele.otp_bypass) : null;
            if (ele.status === "Active") {
              userTableData.push(ele);
            }
          }
          setUsersList(userTableData);
        } else {
          setUsersList([]);
          let errMessage = result["message"] ? result["message"] : "";
          alert(errMessage);
          if (result["status"] == 401 || result["status"] == 403) {
            sessionStorage.clear();
            window.location.href = "/";
          }
        }
      })
      .catch((error) => {
        setUsersList([]);
      });
  }, []);

  const UsersTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <td>Status</td>
          <td>Mobile</td>
          <td>OTP Bypass</td>
          <td>Email</td>
          <td>UserType</td>
          <td>Last Login</td>
          <td>Edit</td>
          <td>Delete</td>
        </tr>
      </thead>
    );
  };

  const UsersTableBody = () => {
    let rows = usersList.map((user, index) => {
      return (
        <tr key={index}>
          <td>{user.status === "Active" ? " ✅ " : " ❌ "}</td>
          <td>{user.mobile}</td>
          <td>{user.otp_bypass ? "Y" : user.mobile ? "N" : ""}</td>
          <td>{user.email}</td>
          <td>{user.user_type}</td>
          <td>{user.last_login}</td>
          <td>
            <Button
              variant="primary"
              onClick={() => {
                setEditUserModalShow(true);
                setSelectedUser(user);
                setRowIndex(index);
              }}
            >
              Edit
            </Button>
          </td>
          <td>
            <Button
              variant="danger"
              onClick={() => {
                setDeleteUserModalShow(true);
                setDeletedUser(user);
              }}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const UsersTable = () => {
    return (
      <table className="table" style={{ marginTop: 10 }}>
        <UsersTableHeader />
        <UsersTableBody />
      </table>
    );
  };

  const addUser = (newUserData) => {
    console.log(newUserData);
    if (newUserData) {
      let newUserList = [];
      if (newUserData.status === "Active") {
        newUserList.push(newUserData);
      }

      setUsersList(usersList.concat(newUserList));
    }
  };

  const editUser = (updatedData) => {
    console.log("editUser");
    console.log(updatedData);
    if (updatedData["status"] == "N") {
      setUsersList(usersList.filter((item) => item.id !== updatedData.id));
    } else {
      let updatedUsersList = [...usersList];
      updatedUsersList[rowIndex]["status"] = updatedData["status"];
      updatedUsersList[rowIndex]["email"] = updatedData["email"];
      updatedUsersList[rowIndex]["password"] = updatedData["password"];
      updatedUsersList[rowIndex]["user_type"] = updatedData["user_type"];
      updatedUsersList[rowIndex]["mobile"] = updatedData["mobile"];
      updatedUsersList[rowIndex]["otp_bypass"] = updatedData["otp_bypass"];

      setUsersList(updatedUsersList);
    }
  };

  const deleteUser = (user) => {
    setUsersList(usersList.filter((item) => item.id !== user.id));
  };

  return (
    <>
      <center>
        <h5 style={{ paddingTop: 30 }}>Manage Admin Users</h5>
      </center>
      <Button
        variant="success"
        style={{ float: "right" }}
        onClick={() => {
          setAddUserModalShow(true);
        }}
      >
        Add User
      </Button>

      {usersList.length > 0 ? (
        <UsersTable />
      ) : (
        <div style={{ margin: 40 }}>No users present</div>
      )}

      {addUserModalShow && (
        <AddUserModalPopup
          show={addUserModalShow}
          onHide={() => setAddUserModalShow(false)}
          callback={addUser}
        />
      )}

      {editUserModalShow && (
        <EditUserModalPopup
          show={editUserModalShow}
          onHide={() => setEditUserModalShow(false)}
          selectedUser={selectedUser}
          callback={editUser}
        />
      )}

      {deleteUserModalShow && (
        <DeleteUserModalPopup
          show={deleteUserModalShow}
          onHide={() => setDeleteUserModalShow(false)}
          deletedUser={deletedUser}
          callback={deleteUser}
        />
      )}
    </>
  );
};

// const SuperkreditPortal = () => {
//   const [users, setUsers] = useState([]);
//   const [usersarray, setusersarray] = useState([]);
//   const [userid, setuserid] = useState("");
//   const [showtoast, setshowtoast] = useState(false);
//   const [toastvalue, settoastvalue] = useState("");
//   const toggleshowtoast = () => setshowtoast(!showtoast);
//   const [loadingstate, setloadingstate] = useState(false);
//   const toggleloadingstate = () => setloadingstate(!loadingstate);
//   const [delid, setdelid] = useState("");
//   const db = firebase.database();
//   const dbref = db.ref(`Users/SuperKredit`);
//   const [show, setshow] = useState(false);
//   const [addbutton, setaddbutton] = useState(false);
//   const [editbutton, seteditbutton] = useState(false);
//   const handleClose = () => setshow(false);
//   const handleShow = () => setshow(true);
//   const [showdel, setshowdel] = useState(false);
//   const handleDelClose = () => setshowdel(false);
//   const handleDelShow = () => setshowdel(true);
//   const { register, handleSubmit } = useForm();
//   const [edituser, setedituser] = useState({});
//   const [deluser, setdeluser] = useState({});
//   const [switchstate, setswitchstate] = useState(true);

//   const onSubmit = (data) => {
//     if (addbutton) {
//       const finalData = {
//         email: data.email,
//         password: data.password,
//         user_type: data.user_type,
//         status: data.status ? "Active" : "Not Active",
//       };
//       console.log(finalData);

//       if (data.user_type == "SELECT") {
//         settoastvalue("Please select user type");
//         toggleshowtoast();
//         return;
//       }

//       var newPostRef = dbref.push();
//       newPostRef
//         .set(JSON.parse(JSON.stringify(finalData)))
//         .then(() => {
//           console.log("Updated to users admin");
//           settoastvalue("User Added Successfully");
//           handleClose();
//           toggleloadingstate();
//           toggleshowtoast();
//         })
//         .catch((err) => console.log(err));
//       setaddbutton(false);
//     } else if (editbutton) {
//       console.log(userid);
//       console.log(edituser);
//       const finalData2 = {
//         email: data.email,
//         password: data.password,
//         user_type: data.user_type,
//         status: data.status ? "Active" : "Not Active",
//       };
//       console.log(finalData2);
//       dbref
//         .child(userid)
//         .update(JSON.parse(JSON.stringify(finalData2)))
//         .then(() => {
//           settoastvalue("Edited successfully");
//           seteditbutton(false);
//           toggleloadingstate();
//           toggleshowtoast();
//         })
//         .catch((err) => alert(err));
//     }
//   };

//   const editButtonHandler = (user, editid) => {
//     setedituser(user);
//     setuserid(editid);
//     console.log(user);
//   };

//   const deleteButtonHandler = () => {
//     let tempdeldata = {
//       email: deluser.email,
//       password: deluser.password,
//       user_type: deluser.user_type,
//       status: "N",
//     };
//     console.log(tempdeldata);
//     dbref
//       .child(delid)
//       .update(JSON.parse(JSON.stringify(tempdeldata)))
//       .then(() => {
//         settoastvalue("User Deleted Successfully");
//         toggleloadingstate();
//         handleDelClose();
//         toggleshowtoast();
//         console.log("deleted");
//       })
//       .catch((err) => alert(err));
//   };

//   const manageDelButtonHandler = (user, id) => {
//     setdelid(id);
//     setdeluser(user);
//     handleDelShow();
//   };

//   useEffect(() => {
//     dbref.once("value").then((snapshot) => {
//       const details = snapshot.val();
//       setUsers(
//         Object.values(details).filter((user) => user.status === "Active")
//       );
//       setusersarray(details ? Object.keys(details) : []);
//     });
//   }, [loadingstate]);

//   return (
//     <Container>
//       <div
//         aria-live="polite"
//         aria-atomic="true"
//         style={{
//           position: "relative",
//           minHeight: "100px",
//         }}
//       >
//         <Toast
//           show={showtoast}
//           onClose={toggleshowtoast}
//           style={{
//             position: "absolute",
//             top: 0,
//             right: 0,
//             backgroundColor: "#0069D9",
//             color: "white",
//             padding: "5px",
//           }}
//           autohide={true}
//           delay={5000}
//         >
//           <Toast.Header>
//             <strong className="mr-auto">Alert</strong>
//           </Toast.Header>
//           <Toast.Body>{toastvalue}</Toast.Body>
//         </Toast>
//       </div>
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add & Edit Users</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSubmit(onSubmit)}>
//             <Form.Group controlId="email">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 name="email"
//                 placeholder="Enter Email"
//                 ref={register}
//                 defaultValue={edituser.email && edituser.email}
//                 required
//               />
//             </Form.Group>
//             <Form.Group controlId="password">
//               <Form.Label>Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 name="password"
//                 placeholder="Password"
//                 ref={register}
//                 defaultValue={edituser.password && edituser.password}
//                 required
//               />
//             </Form.Group>
//             <Form.Group controlId="user_type">
//               <Form.Label>Select User Type</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="user_type"
//                 ref={register}
//                 defaultValue={edituser.user_type && edituser.user_type}
//                 required
//               >
//                 {edituser.user_type ? null : (
//                   <option key={"SELECT"}>SELECT</option>
//                 )}
//                 <option>admin</option>
//                 <option>finance_internal</option>
//                 <option>finance_external</option>
//                 <option>finance</option>
//                 <option>financeHead</option>
//               </Form.Control>
//             </Form.Group>
//             <Container style={{ margin: "5px 10px", padding: "10px" }}>
//               <Row>
//                 <Col>Status Active Yes/No</Col>
//                 <Col>
//                   <Switch
//                     name="status"
//                     color="primary"
//                     inputRef={register}
//                     checked={switchstate}
//                     onChange={(e) => setswitchstate(e.target.checked)}
//                     ref={register}
//                   />
//                 </Col>
//               </Row>
//             </Container>
//             <Button type="submit" variant="primary">
//               Submit
//             </Button>
//           </Form>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <Modal show={showdel} onHide={handleDelClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Alert</Modal.Title>
//         </Modal.Header>

//         <Modal.Body>Do You really want to delete the item?</Modal.Body>
//         <Modal.Footer>
//           <Button variant="danger" onClick={handleDelClose}>
//             No
//           </Button>
//           <Button variant="success" onClick={() => deleteButtonHandler()}>
//             Yes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <Container>
//         <h5>Manage SuperKredit Users</h5>
//         <Button
//           variant="success"
//           style={{ float: "right" }}
//           onClick={() => {
//             handleShow();
//             setaddbutton(true);
//             setedituser({});
//           }}
//         >
//           Add User
//         </Button>
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Status</th>
//               <th>Email</th>
//               <th>Password</th>
//               <th>User Type</th>
//               <th>Edit</th>
//               <th>Delete</th>
//             </tr>
//           </thead>
//           <tbody>
//             {users &&
//               Object.values(users).map((user, index) => {
//                 let id = usersarray[index];
//                 return (
//                   <tr key={index}>
//                     <td>{user.status === "Active" ? " ✅ " : " ❌ "}</td>
//                     <td>{user.email && user.email}</td>
//                     <td>{user.password && user.password}</td>
//                     <td>{user.user_type && user.user_type}</td>
//                     <td>
//                       <Button
//                         variant="primary"
//                         onClick={() => {
//                           seteditbutton(true);
//                           editButtonHandler(user, id);
//                           handleShow();
//                         }}
//                       >
//                         Edit
//                       </Button>
//                     </td>
//                     <td>
//                       <Button
//                         variant="danger"
//                         onClick={() => {
//                           manageDelButtonHandler(user, id);
//                         }}
//                       >
//                         Delete
//                       </Button>
//                     </td>
//                   </tr>
//                 );
//               })}
//           </tbody>
//         </Table>
//       </Container>
//     </Container>
//   );
// };

export default SuperkreditPortal;
