import React from "react";
const ImageComponent = (props) => {
  const { imgDetails, imageUrl, style, alt } = props;

  return (
    <div>
      {props.newImg === "" ? (
        <figure style={style || {}}>
          <img
            src={imageUrl}
            alt={alt}
            id={imgDetails.id}
            className={imgDetails.class}
            height={imgDetails.height}
            width={imgDetails.width}
            title={imgDetails.titleText}
          />
        </figure>
      ) : (
        <figure style={style || {}}>
          <img
            src={props.newImg}
            alt={alt}
            className={imgDetails.class}
            height={imgDetails.height}
            width={imgDetails.width}
            title="new image"
          />
        </figure>
      )}
    </div>
  );
};

export default ImageComponent;
