import React, { useState, useEffect } from 'react'
import { Breadcrumb, Navbar, Dropdown, Tab, Tabs, Alert, Table, Button, Form, Card, InputGroup, Toast, Badge, Jumbotron } from 'react-bootstrap';
import { InputGroupAddon } from 'reactstrap';
import DatePicker from "react-datepicker";
import Backdrop from '../../CommonRequirements/backdrop'
import { toast } from 'react-toastify';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import database from '../../service/firebaseConfig';
import { Chart } from "react-google-charts";
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core';
import FetchProductTestData from './FetchProductTestData';
import { validateIsAdminUser, validateStockUser } from '../../service/credentials';
import { removeDuplicates } from '../../service/commonChecks';
import XLSX from 'xlsx'
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import baseUrl from '../../service/servicesConfig';


const fetchProductStockDB = database.database().ref(`Product_Base_Data`);

function InventoryItems({ location }) {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [getBackDrop, setBackDrop] = useState(false)
    const [itemData, setItemData] = useState("")
    const [itemDesc, setItemDesc] = useState("")
    const [itemDetails, setItemDetails] = useState("")
    const [showData, setShowData] = useState(false)
    const [refreshList, setRefreshList] = useState(false)
    const [handleDateRange, setHandleDateRange] = useState(false)
    const [chartDetailsInfo, setChartDetailsInfo] = useState("")
    const [showGraph, setShowGraph] = useState(true)
    const [ordersDetails, setOrdersDetails] = useState("")
    const [chartOrderInfo, setChartOrderInfo] = useState("")
    const [outerSize, setOuterSize] = useState("")
    const [productTestData, setProductTestData] = useState("")
    const [itemAnalysedData, setItemAnalysedData] = useState("")
    const [productDumpDetails, setProductDumpDetails] = useState("")
    const [purchaseDetails, setPurchaseDetails] = useState("")
    const [convertedStockDetails, setConvertedStockDetails] = useState("")
    const [stockTransferDetails, setStockTransferDetails] = useState("")
    const [stockTake, setStockTake] = useState("")
    const [purchaseChartInfo, setPurchaseChartInfo] = useState("")
    const [exportData, setExportData] = useState(false)
    const [inventoryDataPoints, setInventoryDataPoints] = useState({});
    const [stockTakeChart, setStockTakeChart] = useState("")
    const [dumpChartInfo, setDumpChartInfo] = useState("")
    const [chartFinalData, setChartFinalData] = useState("")
    const [tableBodyItem, setTableBodyItem] = useState([]);
    const [nonConvertedData, setNonConvertedData] = useState(0);


    const productTestSessionData = JSON.parse(sessionStorage.getItem("productTestData"))
    const todayDate = new Date().toISOString().slice(0, 10);

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });


    useEffect(() => {
        console.log(location)
        if (location.state !== undefined) {
            sessionStorage.setItem("itemDetail", JSON.stringify(location.state.itemDetail))
            setItemData(location.state.itemDetail)
            return
        }
        else {
            setItemData(JSON.parse(sessionStorage.getItem("itemDetail")))
        }


    }, [])

    useEffect(() => {
        if (itemData) {
            fetchProductStockDB.child(itemData.item_id).once("value", snapshot => {
                const fetchProducts = snapshot.val();
                setOuterSize(fetchProducts.outer_size && fetchProducts.outer_size)
                setItemDesc(fetchProducts.brand ? fetchProducts.brand + " - " + fetchProducts.long_description : fetchProducts.long_description);
            })
        }
    }, [itemData])

    const structureChartData = () => {
        let chartDetails = [["Inventory Date", "System Inventory", "Stock Take Data", "GRN Quantity", "Delivered Quantity", "Quantity Dumped"]]
        itemDetails.forEach((items, index) => {
            chartDetails.push([items.inventory_date, Number(items.system_inventory), Number(items.stock_data), Number(items.grn_total_weight), Number(items.item_delivered_qty), Number(items.qty_dumped)])
        })
        setChartDetailsInfo(chartDetails)
    }

    useEffect(() => {
        if (itemDetails)
            structureChartData()
    }, [itemDetails])

    const structureOrdersChartData = () => {

        if (ordersDetails.length > 0) {

            let chartDetails = [["Delivery Date", "Item Delivered Quantity"]]
            ordersDetails.forEach((items, index) => {
                console.log(items)
                chartDetails.push([items.delivery_date, (items.item_delivered_qty) * Number(outerSize)])
            })
            setChartOrderInfo(chartDetails)
        }
    }

    useEffect(() => {
        if (ordersDetails && outerSize)
            structureOrdersChartData()
    }, [ordersDetails, outerSize])

    const structurePurchaseChart = () => {

        if (purchaseDetails.length > 0) {

            let chartDetails = [["GRN Date", "Total Weight Received"]]
            purchaseDetails.forEach((items, index) => {
                console.log(items)
                chartDetails.push([items.grn_date, Number(items.total_weight_received)])
            })
            setPurchaseChartInfo(chartDetails)
        }
    }

    useEffect(() => {
        if (purchaseDetails)
            structurePurchaseChart()
    }, [purchaseDetails])

    const structureDumpChart = () => {

        if (productDumpDetails.length > 0) {

            let chartDetails = [["Delivery Date", "Item Delivered Quantity"]]
            productDumpDetails.forEach((items, index) => {
                console.log(items)
                chartDetails.push([items.delivery_date, (items.item_delivered_qty) * Number(outerSize)])
            })
            setDumpChartInfo(chartDetails)
        }
    }

    useEffect(() => {
        if (productDumpDetails)
            structureDumpChart()
    }, [productDumpDetails])

    const structureStockTake = () => {

        if (stockTake.length > 0) {

            let chartDetails = [["Date", "Stock Take Data (KG/PC)"]]
            stockTake.forEach((items, index) => {
                console.log(items)
                chartDetails.push([items.stock_date, Number(items.actual_stock)])
            })
            setStockTakeChart(chartDetails)
        }
    }

    useEffect(() => {
        if (stockTake)
            structureStockTake()
    }, [stockTake])

    //fetch system inventory data

    useEffect(() => {
        if (itemData) {
            startDate.setDate(startDate.getDate() - 1)
            const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
            const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

            const inv_datapoints_url = `/api/superzop/systeminventory/inventorydatapoints?start_date=${startDateConversion}&end_date=${endDateConversion}&item_id=${itemData.item_id}`

            baseUrl.get(inv_datapoints_url).then(response => {
                if (response.status === 200) {
                    return response.data;
                }
            }).then(data => {
                console.log("Inventory Data points");
                console.log(data);
                setInventoryDataPoints(data);
                data.Product_Dump ? setProductDumpDetails(data.Product_Dump) : setProductDumpDetails();
                data.Purchase_Details ? setPurchaseDetails(data.Purchase_Details) : setPurchaseDetails();
                data.Product_Stock_Take ? setStockTake(data.Product_Stock_Take) : setStockTake();
                data.Driver_Orders_History ? setOrdersDetails(data.Driver_Orders_History) : setOrdersDetails();
                data.Stock_Conversion ? setStockTransferDetails(data.Stock_Conversion) : setStockTransferDetails();
                data.Converted_Stocks ? setConvertedStockDetails(data.Converted_Stocks) : setConvertedStockDetails();
                console.log(data)
            });
            const getsysteminventory_url = `/api/superzop/systeminventory/getsysteminventory?start_date=${startDateConversion}&end_date=${endDateConversion}&item_id=${itemData.item_id}`;

            baseUrl.get(getsysteminventory_url).then(response => {
                if (response.status === 200) {
                    return response.data;
                }
            }).then(data => { setItemDetails(data) })

            // const non_converted_stock_url = `/api/superzop/commercial/stockconversion/stockconversionlistnew`;

            // baseUrl.get(non_converted_stock_url).then(response => {
            //     if (response.status === 200) {
            //         return response.data;
            //     }
            // }).then(data => {
            //     data.forEach((item) => {
            //         console.log(item);
            //         if(item.parent_code == itemData.item_id && item.non_converted_stock > 0) {
            //             setNonConvertedData(item.non_converted_stock); 
            //         }           
            //     })
                
                
            // })

        }
    }, [itemData])

    useEffect(() => {
        if (productTestData) {
            let itemMultipleTestData = []
            console.log("productTestData::", productTestData, itemData.item_id)
            productTestData.forEach(item => {
                if (item["Product Code"] === itemData.item_id) {
                    itemMultipleTestData.push(item)
                    console.log(item)
                }
            })
            setItemAnalysedData(itemMultipleTestData)
        }
    }, [productTestData])


    const handleDateRangeChange = () => {

        if (startDate > endDate) {

            setBackDrop(false)
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        //get product data
        FetchProductTestData(startDate, endDate, setProductTestData)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        const getsysteminventory_url = `/api/superzop/systeminventory/getsysteminventory?start_date=${startDateConversion}&end_date=${endDateConversion}&item_id=${itemData.item_id}`;

        baseUrl.get(getsysteminventory_url).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            setItemDetails(data);
            setShowData(false);
            setShowGraph(true);
        })

        const inv_datapoints_url = `/api/superzop/systeminventory/inventorydatapoints?start_date=${startDateConversion}&end_date=${endDateConversion}&item_id=${itemData.item_id}`

        baseUrl.get(inv_datapoints_url).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            console.log("Inventory Data points");
            console.log(data);
            setInventoryDataPoints(data);
            data.Product_Dump ? setProductDumpDetails(data.Product_Dump) : setProductDumpDetails();
            data.Purchase_Details ? setPurchaseDetails(data.Purchase_Details) : setPurchaseDetails();
            data.Product_Stock_Take ? setStockTake(data.Product_Stock_Take) : setStockTake();
            data.Driver_Orders_History ? setOrdersDetails(data.Driver_Orders_History) : setOrdersDetails();
            data.Stock_Conversion ? setStockTransferDetails(data.Stock_Conversion) : setStockTransferDetails();
            data.Converted_Stocks ? setConvertedStockDetails(data.Converted_Stocks) : setConvertedStockDetails();
            console.log(data)
        });

        // const non_converted_stock_url = `/api/superzop/commercial/stockconversion/stockconversionlistnew`;

        // baseUrl.get(non_converted_stock_url).then(response => {
        //     if (response.status === 200) {
        //         return response.data;
        //     }
        // }).then(data => {
        //     data.forEach((item) => {
        //         console.log(item);
        //         if(item.parent_code == itemData.item_id && item.non_converted_stock > 0) {
        //             setNonConvertedData(item.non_converted_stock); 
        //         }           
        //     })
            
            
        // })

    }

    useEffect(() => {
        if (showData) {
            handleDateRangeChange()
        }
    }, [showData])

    const refreshListItems = () => {

        if (startDate > endDate) {

            setBackDrop(false)
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }

        if (!handleDateRange)
            startDate.setDate(startDate.getDate() - 1)

        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        console.log(startDateConversion, endDateConversion, itemData.item_id);

        const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${startDateConversion}&end_date=${endDateConversion}&item_id=${itemData.item_id}`;

        baseUrl.post(sync_sys_inv_url).then(res => {

            if (res.status == 200) {
                console.log(res.status)
                notifyDetails(true)
            }
        }).catch((error) => {
            console.log(error);
        });
        setRefreshList(false)
    }

    const notifyDetails = (success) => {
        if (success)
            toast(`Data refreshed successfully`, { type: toast.TYPE.SUCCESS })
    }

    useEffect(() => {
        if (refreshList)
            refreshListItems()
    }, [refreshList])



    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    const exportInventoryDetails = () => {


        const wb = XLSX.utils.book_new()

        setBackDrop(true)
        let inventorySummary = [["Inventory Date", "System Inventory (Mid Night)", "System Inventory (After Shipped)","Dumped Quantity",  "GRN Quantity", "Stock Transfer", "Packaged Quantity", "Returned Quantity", "Shipped Quantity", "Other Order Quantiy", "Customer Returns","Stock Take Quantity"]]
        tableBodyItem.map(item => {


            let inventoryDetailsPerItem = [];

            inventoryDetailsPerItem.push(item.date)
            inventoryDetailsPerItem.push(todayDate == item.date ?  Number(item.system_inventory).toFixed(2)+ " (Current)" : (Number(item.system_inventory)).toFixed(2))
            inventoryDetailsPerItem.push(todayDate == item.date ? (Number(item.system_inventory)  - item.total_weight_received  + item.qty_dumped - item.converted_stock + item.stock_transfer).toFixed(2) : (Number(item.system_inventory) - item.total_weight_received + item.qty_dumped - item.converted_stock + item.stock_transfer - item.item_modified_qty + item.item_delivered_qty).toFixed(2))
            inventoryDetailsPerItem.push(item.qty_dumped)
            inventoryDetailsPerItem.push(item.total_weight_received)
            inventoryDetailsPerItem.push(item.stock_transfer)
            inventoryDetailsPerItem.push(item.converted_stock)
            inventoryDetailsPerItem.push(todayDate == item.date ? 0 : (item.item_modified_qty - item.item_delivered_qty).toFixed(1))
            inventoryDetailsPerItem.push(item.item_modified_qty && (item.item_modified_qty).toFixed(1))
            inventoryDetailsPerItem.push(item.item_qty && (item.item_qty).toFixed(1))
            inventoryDetailsPerItem.push(item.item_returned_qty && (item.item_returned_qty).toFixed(1))
            inventoryDetailsPerItem.push(item.actual_stock)
        
            inventorySummary.push(inventoryDetailsPerItem)

        })
        const inventoryExportDetails = XLSX.utils.aoa_to_sheet(inventorySummary)
        XLSX.utils.book_append_sheet(wb, inventoryExportDetails, `System Inventory List`)
        XLSX.writeFile(wb, `Inventory_Item_List.xlsx`)
        setExportData(false)
        setBackDrop(false)
    }
    useEffect(() => {
        if (exportData)
            exportInventoryDetails()
    }, [exportData])


    const tableDetails = () => {

        return (
            <Table bordered striped variant="primary">
                <thead>
                    <tr>
                        <td>Inventory Date</td>
                        <td>Inventory (After Shipped)</td>
                        <td>Inventory (Mid Night)</td>
                        <td>Dumped Qty</td>
                        <td>GRN Qty</td>
                        <td>Stock Transfer Qty</td>
                        <td>Packaged Qty</td>
                        <td>Returned Qty</td>
                        <td>Shipped Qty</td>
                        <td>Other Order Qty</td>
                        <td>Customer Returns</td>
                        <td>Stock Take</td>
                    </tr>
                </thead>
                <tbody>
                    {tableBodyItem && tableBodyItem.length > 0 && tableBodyItem.map(item => (
                        <tr>
                            <td>{item.date}</td>
                            <td>{todayDate == item.date ? (Number(item.system_inventory) - item.total_weight_received + item.qty_dumped - item.converted_stock + item.stock_transfer).toFixed(2) : (Number(item.system_inventory) - item.total_weight_received + item.qty_dumped - item.converted_stock + item.stock_transfer - item.item_modified_qty + item.item_delivered_qty).toFixed(2)}</td>
                            <td>{todayDate == item.date ?  Number(item.system_inventory).toFixed(2)+ " (Current)" : (Number(item.system_inventory)).toFixed(2)}</td>
                            <td>{item.qty_dumped}</td>
                            <td>{item.total_weight_received}</td>
                            <td>{item.stock_transfer}</td>
                            <td>{item.converted_stock}</td>
                            <td>{todayDate == item.date ? 0 : (item.item_modified_qty - item.item_delivered_qty).toFixed(1)}</td>
                            <td>{item.item_modified_qty && (item.item_modified_qty).toFixed(1)}</td>
                            <td>{item.item_qty && (item.item_qty).toFixed(1)}</td>
                            <td>{item.item_returned_qty && (item.item_returned_qty).toFixed(1)}</td>
                            <td>{item.actual_stock}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>)
    }
    useEffect(() => {

        if (itemDetails.length > 0) {
            let chartDataInfo = [];
            let items = []
            chartDataInfo.push(["Date", "Delivered Qty", "Shipped Qty", "Quantity Dumped", "GRN Qty", "Stock Take Qty"])
            itemDetails.forEach(inventoryitem => {
                let inv_date = inventoryitem.inventory_date;
                let item = {}
                item.date = inv_date
                item.system_inventory = inventoryitem.system_inventory;
                item.stock_date = inventoryitem.stock_date;
                item.item_delivered_qty = 0
                item.qty_dumped = 0
                item.actual_stock = 0
                item.total_weight_received = 0
                item.stock_transfer = 0
                item.converted_stock = 0
                console.log("stockTransfer")
                console.log(stockTransferDetails)
                stockTake && stockTake.forEach(stockItem => {
                    if (inv_date.includes(stockItem.stock_date)) {
                        item.actual_stock = stockItem.actual_stock
                    }
                })
                purchaseDetails && purchaseDetails.forEach(purchaseItem => {
                    if (inv_date.includes(purchaseItem.grn_date)) {
                        item.total_weight_received = purchaseItem.total_weight_received
                    }
                })
                ordersDetails && ordersDetails.forEach(orderItem => {
                    if (inv_date.includes(orderItem.delivery_date)) {
                        item.item_delivered_qty = orderItem.item_delivered_qty * inventoryitem.outer_size
                        item.item_modified_qty = orderItem.item_modified_qty * inventoryitem.outer_size
                        item.item_returned_qty = orderItem.item_returned_qty * inventoryitem.outer_size
                    }
                })
                productDumpDetails && productDumpDetails.forEach(dumpItem => {
                    if (inv_date.includes(dumpItem.dump_date)) {
                        item.qty_dumped = dumpItem.qty_dumped
                    }
                })
                inventoryDataPoints.Stock_Conversion && inventoryDataPoints.Stock_Conversion.forEach(stockTransferItem => {
                    if (inv_date.includes(stockTransferItem.stock_transfer_date)) {
                        item.stock_transfer = stockTransferItem.stock_transfer
                    }
                })
                inventoryDataPoints.Converted_Stocks && inventoryDataPoints.Converted_Stocks.forEach(convertedStockItem => {
                    if (inv_date.includes(convertedStockItem.date_of_packing)) {
                        item.converted_stock = convertedStockItem.converted_stock
                    }
                })
                inventoryDataPoints.Other_Orders && inventoryDataPoints.Other_Orders.forEach(otherOrderItem => {
                    if (inv_date.includes(otherOrderItem.other_order_date)) {
                        item.item_qty = otherOrderItem.item_qty * inventoryitem.outer_size
                    }
                })
                console.log(item)
                if (!item.item_delivered_qty) {
                    item.item_delivered_qty = 0;
                }
                if (!item.item_modified_qty) {
                    item.item_modified_qty = 0;
                }

                items.push(item)
                chartDataInfo.push([inv_date, item.item_delivered_qty, item.item_modified_qty, item.item_qty, item.qty_dumped, item.total_weight_received, item.actual_stock])
            })
            console.log("items")
            console.log(items)
            setChartFinalData(chartDataInfo)
            setTableBodyItem(items)
        }
    }, [ordersDetails, itemDetails])

    return (
        <>
            <NavBar />
            <div style={{ paddingTop: 100, marginRight: 20, marginLeft: 20 }}>
                <div style={{ textAlign: "center" }}>
                    <Alert variant="info">System Inventory Listing</Alert>
                    <Navbar>
                        <Form inline>
                            <Badge variant="dark" style={{ marginLeft: 10 }}>Select Start Date </Badge>
                            <DatePicker
                                selected={startDate}
                                onChange={(val) => { setStartDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"
                                customInput={<CustomDateInput />}

                            />
                            <Badge variant="dark" style={{ marginLeft: 10 }}>Select End Date </Badge>
                            <DatePicker

                                selected={endDate}
                                onChange={(val) => { setEndDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"
                                customInput={<CustomDateInput />}
                            />
                            <Button size="sm" style={{ margin: 10 }} onClick={() => { setShowData(true); setHandleDateRange(true) }}>Show</Button>
                            <Button size="sm" style={{ margin: 20 }} variant={"info"} onClick={() => setRefreshList(true)}>Sync Data</Button>
                            {(validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <Button size="sm" style={{ marginLeft: 100 }} variant="warning" onClick={() => setExportData(true)}>Export</Button> : ""}

                        </Form>
                    </Navbar>
                    {itemDetails &&
                        <Card className="text-center" >
                            <Card.Header>
                                {itemDetails[0] && `${itemDetails[0].item_id} | ${itemDesc} | ${itemDetails[0].category} - ${itemDetails[0].sub_category} | ${itemDetails[0].outer_size} ${itemDetails[0].UOM}`}
                            </Card.Header>
                            Stock Date: {itemDetails[0].stock_date}
                            {nonConvertedData > 0 && ` | Non Converted Stock: ${nonConvertedData}`}
                        </Card>}
                    {/* <Table bordered striped variant="primary">
                        <thead>
                            <tr >
                                <td>Inventory Date</td>
                                <td>System Inventory (KG/PC)</td>
                                <td>Stock Take (KG/PC)</td>
                                <td>Quantity Dumped (KG/PC)</td>
                                <td>Delivered Qty (KG/PC)</td>
                                <td>GRN Qty (KG/PC)</td>
                            </tr>
                        </thead>
                        {itemDetails && itemDetails.map(item => (
                            <tbody>
                                <tr>
                                    <td>{item.inventory_date}</td>
                                    <td>{item.system_inventory}</td>
                                    <td>{item.stock_data}</td>
                                    <td>{item.qty_dumped}</td>
                                    <td>{item.item_delivered_qty}</td>
                                    <td>{item.grn_total_weight}</td>
                                </tr>
                            </tbody>
                        ))
                        }
                    </Table> */}
                </div>
                {itemDetails.length > 0 && <>
                    <Card className="text-center" >
                        <Card.Header>
                            Item Entry Details
                        </Card.Header>
                    </Card>
                    <div>
                        {tableDetails()}
                    </div>
                    {/* <div>
                    {showGraph && <Chart
                        width={1500}
                        height={1000}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={chartDetailsInfo && chartDetailsInfo}
                        options={{
                            title: 'System inventory Reports',
                            chartArea: { width: '50%' },
                            hAxis: {
                                title: 'Units',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'Inventory Date',
                            },
                            legend: 'none'
                        }}
                    />}
                </div> */}

                    {/* <div>
            {chartFinalData && <Chart
                width={1500}
                height={1000}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={chartFinalData && chartFinalData}
                options={{
                    title: 'Reports',
                    chartArea: { width: '50%' },
                    hAxis: {
                        title: 'Units',
                        minValue: 0,
                    },
                    vAxis: {
                        title: 'Date',
                    },
                }}
            />}
        </div> */}
                </>
                }
                {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
            </div>
        </>
    )
}

export default InventoryItems
