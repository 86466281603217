import React from "react";
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Table,
  Grid,
} from "@mantine/core";
import { Row, Col } from "react-bootstrap";

import deleteLogo from "../../../../assests/images/delete.png";
import editLogo from "../../../../assests/images/edit.png";

const OfferListingCardContentScreen = (props) => {
  const offerCategory = props.offerCategory;
  const offerDetails = { ...props.offerDetails };
  const offerType = offerDetails.offer_type;
  const handleUploadImageClick = props.handleUploadImageClick;
  const handleDeleteIconClick = props.handleDeleteIconClick;

  const ProductOffersCardContent = () => {
    const offerId = offerDetails.offer_id || "";
    const offerType = offerDetails.offer_type;
    const startDate = offerDetails.start_date;
    const endDate = offerDetails.end_date;
    const itemId = offerDetails.item_id || offerDetails.item_ids || "";
    const itemDescription = offerDetails.offer_description || "";
    const retailerGrp = offerDetails.group_id || " ";
    const storeSeries = offerDetails.store_series || "";
    const imgUrl = offerDetails.offer_banner_url || "";
    const offerCode = offerDetails.offer_code || "";
    const notification_text = offerDetails.notification_text || "";
    const notification_timings = offerDetails.notification_timings || [];
    const minWeight = offerDetails.minimum_order_weight || "";

    return (
      <>
        <Row className="OfferListingCard__Header">
          <Col
            style={{ backgroundColor: "cyan" }}
            className="OfferListingCard__Header__Col"
          >
            <div style={{ fontSize: "17px", fontWeight: "bold" }}>
              Product Offer
            </div>
            <div
              className="OfferListingCard__Header__Col__EditIcon"
              onClick={(event) => {
                const urlParamsContent = getProductOfferURL(offerDetails);
                console.log("check url", urlParamsContent);
                props.history.push(`/offersMaster${urlParamsContent}`);
              }}
            >
              <Image radius="md" src={editLogo} alt="Edit offer icon" />
            </div>
            <div
              className="OfferListingCard__Header__Col__DeleteIcon"
              onClick={handleDeleteIconClick}
            >
              <Image radius="md" src={deleteLogo} alt="Delete offer icon" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Grid
              grow
              justify="center"
              gutter="xl"
              style={{ textAlign: "center", margin: "0px" }}
            >
              <Grid.Col span={4}>
                <Table>
                  <tbody>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer Id
                      </td>
                      <td>{offerId}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer Code
                      </td>
                      <td>{offerCode}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer start date
                      </td>
                      <td>{startDate}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer end date
                      </td>
                      <td>{endDate}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Item Id(s)
                      </td>
                      <td style={{ wordWrap: "anywhere" }}>{itemId}</td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
              <Grid.Col span={4}>
                <Table>
                  <tbody>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer description
                      </td>
                      <td>{itemDescription}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Retailer group
                      </td>
                      <td>{retailerGrp}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Store series
                      </td>
                      <td>{storeSeries}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer Type
                      </td>
                      <td>{offerType}</td>
                    </tr>
                    {offerType === "BulkOffer" && (
                      <tr>
                        <td className="OfferListingCard__Main_SubHeading">
                          Min Weight (Kgs)
                        </td>
                        <td>{minWeight}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Grid.Col>
              <Grid.Col span={2}>
                <div style={{ width: 240, margin: "auto" }}>
                  <Image
                    radius="md"
                    width={240}
                    height={150}
                    src={imgUrl || ""}
                    alt="Offer Banner Image"
                  />
                </div>
                <div>
                  <Button
                    variant="filled"
                    color="dark"
                    style={{ marginTop: "10px" }}
                    onClick={handleUploadImageClick}
                  >
                    {" "}
                    Update Offer Image
                  </Button>
                </div>
              </Grid.Col>
              {/* <Grid.Col span={4} style={{ backgroundColor: "red" }}>
          3
        </Grid.Col> */}
            </Grid>
          </Col>
        </Row>

        {notification_text ? (
          <Row
            style={{
              borderTop: "3px",
              borderStyle: "dashed",
              borderColor: "black",
            }}
          >
            <Col>
              <div
                className="OfferListingCard__Main_SubHeading"
                style={{ textAlign: "center", textDecoration: "underline" }}
              >
                Notification details
              </div>
              <Grid
                grow
                justify="center"
                gutter="xl"
                style={{ textAlign: "center", margin: "0px" }}
              >
                <Grid.Col span={2}>Text : {notification_text}</Grid.Col>
                <Grid.Col span={2}>
                  Timings : {notification_timings.join(", ")}
                </Grid.Col>
              </Grid>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </>
    );
  };

  const OfferRangeTableFields = (offerDetails) => {
    let arrToReturn = [];
    const min_pur_qty = offerDetails[`minimum_purchase_qty`];
    const max_pur_qty = offerDetails[`maximum_purchase_qty`];
    const offer_price = offerDetails[`offer_price`];

    arrToReturn.push(
      <>
        {min_pur_qty !== "" && min_pur_qty !== null && (
          <tr>
            {min_pur_qty !== "" && min_pur_qty !== null && (
              <td> {min_pur_qty}</td>
            )}
            {max_pur_qty !== "" && max_pur_qty !== null && (
              <td>{max_pur_qty}</td>
            )}
            {offer_price !== "" && offer_price !== null && (
              <td>{offer_price}</td>
            )}
          </tr>
        )}
      </>
    );

    for (let i = 2; i < 5; i++) {
      const min_pur_qty = offerDetails[`minimum_purchase_qty${i}`];
      const max_pur_qty = offerDetails[`maximum_purchase_qty${i}`];
      const offer_price = offerDetails[`offer_price${i}`];

      arrToReturn.push(
        <>
          {min_pur_qty !== "" && min_pur_qty !== null && (
            <tr>
              {min_pur_qty !== "" && min_pur_qty !== null && (
                <td>{min_pur_qty}</td>
              )}
              {max_pur_qty !== "" && max_pur_qty !== null && (
                <td>{max_pur_qty}</td>
              )}
              {offer_price !== "" && offer_price !== null && (
                <td>{offer_price}</td>
              )}
            </tr>
          )}
        </>
      );
    }

    return arrToReturn;
  };

  const SLPproductOffersCardContent = () => {
    const offerId = offerDetails.offer_id || "";
    const offerType = offerDetails.offer_type;

    const itemId = offerDetails.item_id || "";
    const retailerGrp = offerDetails.group_id || " ";
    const storeSeries = offerDetails.store_series || "";

    const imgUrl = offerDetails.offer_banner_url || "";
    const notification_text = offerDetails.notification_text || "";
    const notification_timings = offerDetails.notification_timings || "";

    const offerStartDate = offerDetails.start_date || "";
    const offerEndDate = offerDetails.end_date || "";

    return (
      <>
        <Row className="OfferListingCard__Header">
          <Col
            style={{ backgroundColor: "cyan" }}
            className="OfferListingCard__Header__Col"
          >
            <div style={{ fontSize: "17px", fontWeight: "bold" }}>
              Product Offer
            </div>
            <div
              className="OfferListingCard__Header__Col__EditIcon"
              onClick={(event) => {
                const urlParamsContent = getProductOfferURL(offerDetails);
                // console.log("check url", urlParamsContent);
                props.history.push(`/offersMaster${urlParamsContent}`);
              }}
            >
              <Image radius="md" src={editLogo} alt="Edit offer icon" />
            </div>
            <div
              className="OfferListingCard__Header__Col__DeleteIcon"
              onClick={handleDeleteIconClick}
            >
              <Image radius="md" src={deleteLogo} alt="Delete offer icon" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Grid
              grow
              justify="center"
              gutter="xl"
              style={{ textAlign: "center", margin: "0px" }}
            >
              <Grid.Col span={8}>
                <Table>
                  <tbody>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer Id
                      </td>
                      <td>{offerId}</td>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer Type
                      </td>
                      <td>{offerType}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Item Id
                      </td>
                      <td>{itemId}</td>
                      <td className="OfferListingCard__Main_SubHeading">
                        Retailer group
                      </td>
                      <td>{retailerGrp}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer start date
                      </td>
                      <td>{offerStartDate}</td>
                      <td className="OfferListingCard__Main_SubHeading">
                        Store series
                      </td>
                      <td>{storeSeries}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer end date
                      </td>
                      <td>{offerEndDate}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table striped withBorder withColumnBorders>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Min pur qty</th>
                      <th style={{ textAlign: "center" }}>Max pur qty</th>
                      <th style={{ textAlign: "center" }}>Offer price</th>
                    </tr>
                  </thead>

                  <tbody>{OfferRangeTableFields(offerDetails)}</tbody>
                </Table>
              </Grid.Col>

              <Grid.Col span={2}>
                <div style={{ width: 240, margin: "auto" }}>
                  <Image
                    radius="md"
                    width={240}
                    height={150}
                    src={imgUrl || ""}
                    alt="Offer Banner Image"
                  />
                </div>
                <div>
                  <Button
                    variant="filled"
                    color="dark"
                    style={{ marginTop: "10px" }}
                    onClick={handleUploadImageClick}
                  >
                    {" "}
                    Update Offer Image
                  </Button>
                </div>
              </Grid.Col>
            </Grid>
          </Col>
        </Row>

        {notification_text ? (
          <Row
            style={{
              borderTop: "3px",
              borderStyle: "dashed",
              borderColor: "black",
            }}
          >
            <Col>
              <div
                className="OfferListingCard__Main_SubHeading"
                style={{ textAlign: "center", textDecoration: "underline" }}
              >
                Notification details
              </div>
              <Grid
                grow
                justify="center"
                gutter="xl"
                style={{ textAlign: "center", margin: "0px" }}
              >
                <Grid.Col span={2}>Text : {notification_text}</Grid.Col>
                <Grid.Col span={2}>
                  Timings : {notification_timings.join(", ")}
                </Grid.Col>
              </Grid>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </>
    );
  };

  const CustomerOffersCardContent = () => {
    let {
      id,
      coupon_code,
      offer_description,
      valid_from,
      valid_until,
      minimum_order_value,
      offer_availing_limit,
      maximum_discount_amount,
      customer_group,
      store_series,
    } = offerDetails;

    return (
      <>
        <Row className="OfferListingCard__Header">
          <Col
            style={{ backgroundColor: "cyan" }}
            className="OfferListingCard__Header__Col"
          >
            <div style={{ fontSize: "17px", fontWeight: "bold" }}>
              {offerCategory === "customerOffer"
                ? `Customer Offer`
                : `Custom Offer`}
            </div>

            <div
              className="OfferListingCard__Header__Col__EditIcon"
              onClick={(event) => {
                const urlParamsContent = getCustomerOfferURL(offerDetails);
                // console.log("check url", urlParamsContent);
                props.history.push(`/offersMaster${urlParamsContent}`);
              }}
            >
              <Image radius="md" src={editLogo} alt="Edit offer icon" />
            </div>

            <div
              className="OfferListingCard__Header__Col__DeleteIcon"
              onClick={handleDeleteIconClick}
            >
              <Image radius="md" src={deleteLogo} alt="Delete offer icon" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Grid
              grow
              justify="center"
              gutter="xl"
              style={{ textAlign: "center", margin: "0px" }}
            >
              <Grid.Col span={4}>
                <Table>
                  <tbody>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer Id
                      </td>
                      <td>{id}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Coupon code
                      </td>
                      <td>{coupon_code}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer start date
                      </td>
                      <td>{valid_from}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer end date
                      </td>
                      <td>{valid_until}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Retailer group
                      </td>
                      <td>{customer_group}</td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
              <Grid.Col span={4}>
                <Table>
                  <tbody>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer description
                      </td>
                      <td>{offer_description}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Min order value
                      </td>
                      <td>{minimum_order_value}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Max discount
                      </td>
                      <td>{maximum_discount_amount}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Offer availing limit
                      </td>
                      <td>{offer_availing_limit}</td>
                    </tr>
                    <tr>
                      <td className="OfferListingCard__Main_SubHeading">
                        Store series
                      </td>
                      <td>{store_series}</td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
            </Grid>
          </Col>
        </Row>
      </>
    );
  };

  const getProductOfferURL = ({
    offer_type,
    item_id,
    item_ids,
    start_date,
    end_date,
    offer_price,
    offer_id,
    offer_perc,
    minimum_purchase_qty,
    maximum_purchase_qty,
    category,
    offer_description,
    store_series,
    group_id,
    offer_banner_url,
    popup_banner_url,
    homescreen_banner_url,
    offer_code,
    notification_text,
    notification_timings,
    only_for_new_customers,
    minimum_purchase_qty2,
    minimum_purchase_qty3,
    minimum_purchase_qty4,
    minimum_purchase_qty5,
    maximum_purchase_qty2,
    maximum_purchase_qty3,
    maximum_purchase_qty4,
    maximum_purchase_qty5,
    offer_price2,
    offer_price3,
    offer_price4,
    offer_price5,
    offer_perc2,
    offer_perc3,
    offer_perc4,
    offer_perc5,
    bulkOffersProductsMap,
    minimum_order_weight,
  }) => {
    group_id = group_id ?? "";
    offer_banner_url = encodeURIComponent(offer_banner_url);
    popup_banner_url = encodeURIComponent(popup_banner_url);
    homescreen_banner_url = encodeURIComponent(homescreen_banner_url);
    notification_timings =
      notification_timings && notification_timings.length > 0
        ? notification_timings.join(",")
        : "";
    const bulkOfferProducts = JSON.stringify(bulkOffersProductsMap);
    const bulkOfferMinWeight = minimum_order_weight;
    const url = `?offer_type=${offer_type}&offer_desc=${offer_description}&start=${start_date}&end=${end_date}&offer_code=${offer_code}&retail_series=${group_id}&store_series=${store_series}&item_id=${item_id}&item_ids=${item_ids}&min_pur_qty=${minimum_purchase_qty}&min_pur_qty2=${minimum_purchase_qty2}&min_pur_qty3=${minimum_purchase_qty3}&min_pur_qty4=${minimum_purchase_qty4}&min_pur_qty5=${minimum_purchase_qty5}&max_pur_qty=${maximum_purchase_qty}&max_pur_qty2=${maximum_purchase_qty2}&max_pur_qty3=${maximum_purchase_qty3}&max_pur_qty4=${maximum_purchase_qty4}&max_pur_qty5=${maximum_purchase_qty5}&offer_price=${offer_price}&offer_price2=${offer_price2}&offer_price3=${offer_price3}&offer_price4=${offer_price4}&offer_price5=${offer_price5}&category=${category}&offer_id=${offer_id}&offer_per=${offer_perc}&offer_per2=${offer_perc2}&offer_per3=${offer_perc3}&offer_per4=${offer_perc4}&offer_per5=${offer_perc5}&image_url=${offer_banner_url}&popup_url=${popup_banner_url}&homescreen_url=${homescreen_banner_url}&notify_text=${notification_text}&notify_timings=${notification_timings}&only_new_cust=${only_for_new_customers}&bulkOfferProducts=${bulkOfferProducts}&bulkOfferMinWeight=${bulkOfferMinWeight}`;
    return url;
  };

  const getCustomerOfferURL = ({
    id,
    //Condition Fields
    minimum_order_value,
    maximum_order_value,
    category,
    sub_category,
    minimum_category_unit,
    minimum_category_weight,
    minimum_category_value,
    minimum_buy_product,
    minimum_buy_product_unit,
    minimum_buy_product_weight,
    minimum_buy_product_value,
    //////////

    //Action Fields
    discount_percent,
    discount_value,
    multiple,
    offer_product,
    offer_product_unit,
    minimum_product_unit,
    maximum_product_unit,
    supercoins,
    //////////

    coupon_code,
    mode,
    offer_availing_limit,
    excluded_products,
    excluded_coupon_codes,
    offer_description,
    valid_from,
    valid_until,
    customer_group,
    store_series,
    applicable_to_all,
    only_for_new_customers,
    excluded_from_listing,
  }) => {
    if (offerCategory === "customerOffer")
      excluded_products = Object.keys(excluded_products);

    const url = `?mode=${mode}&offer_desc=${offer_description}&start=${valid_from}&end=${valid_until}&limit=${offer_availing_limit}&coupon_code=${coupon_code}&excluded_prods=${excluded_products}&excluded_coupon_codes=${excluded_coupon_codes}&retail_series=${customer_group}&store_series=${store_series}&disc_percent=${discount_percent}&disc_value=${discount_value}&is_multiple=${multiple}&free_prod=${offer_product}&free_prod_qty=${offer_product_unit}&free_prod_min=${minimum_product_unit}&free_prod_max=${maximum_product_unit}&supercoin_amt=${supercoins}&min_order_val=${minimum_order_value}&max_order_val=${maximum_order_value}&min_cat=${category}&min_sub_cat=${sub_category}&min_cat_value=${minimum_category_value}&min_cat_units=${minimum_category_unit}&min_cat_weight=${minimum_category_weight}&min_prod=${minimum_buy_product}&min_prod_value=${minimum_buy_product_value}&min_prod_units=${minimum_buy_product_unit}&min_prod_weight=${minimum_buy_product_weight}&applicable_to_all=${applicable_to_all}&only_new_cust=${only_for_new_customers}&excluded_from_listing=${excluded_from_listing}`;
    return url;
  };

  return offerCategory === "productOffer" && offerType === "SLP" ? (
    <SLPproductOffersCardContent />
  ) : offerCategory === "productOffer" ? (
    <ProductOffersCardContent />
  ) : offerCategory === "customerOffer" || offerCategory === "customOffer" ? (
    <CustomerOffersCardContent />
  ) : (
    ""
  );
};

export default OfferListingCardContentScreen;
