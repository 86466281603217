import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  Divider,
  Table,
  ScrollArea,
  Flex,
  Modal,
  Image,
  Text,
} from "@mantine/core";
import styled from "styled-components";

const InlineTextStyles = styled.div`
  text-align: center;
  .mantine-Text-root {
    display: inline;
  }
`;

export function ViewCalculationsModal({
  opened,
  setOpened,
  data: {
    batter_name,
    batter_id,
    quantity,
    total_cost,
    cost_per_kg,
    final_price,
    packing_cost,
    total_weight,
    items,
    image_url,
  },
  finalCostInCalculationModal}) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Calculations"
        padding="xl"
        size={"lg"}
      >
        <ScrollArea style={{ height: "100%" }} offsetScrollbars>
          <Stack
            spacing={"1.5rem"}
            align="stretch"
            justify="center"
            pb={"1rem"}
          >
            <Divider my="sm" variant="dashed" />

            <Flex direction={"row"} gap={"40px"} justify={"center"}>
              {" "}
              <Image
                width={100}
                height={80}
                radius="md"
                src={image_url}
                alt="Product image"
              />
              <Flex direction={"column"} align={"center"} justify={"center"}>
                <Text>{batter_name}</Text>
                <Text size={"sm"} color="grey">
                  {batter_id}
                </Text>
              </Flex>
            </Flex>

            <Table striped verticalSpacing="xs" fontSize="xs">
              <thead>
                {/* <tr>
                  <th style={{ textAlign: "center" }}> {batter_name} </th>
                  <th colSpan={3} style={{ textAlign: "center" }}>
                    {batter_id}
                  </th>
                </tr> */}
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td colSpan={1}>
                    {" "}
                    <b>Standard Batch</b>
                  </td>
                  <td></td>
                  <td>
                    {" "}
                    <b>{quantity} pcs </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Ingredient Name</b>
                  </td>
                  <td>
                    <b> Weight </b>
                  </td>

                  <td>
                    <b> % </b>
                  </td>

                  <td>
                    <b>Cost </b>
                  </td>
                </tr>

                {items.map(({ item_name, weight_kg, cost, outer_size }) => {

                //  let modifiedWeight = parseFloat(weight_kg * outer_size).toFixed(2);
                //  if (modifiedWeight % 1 === 0) {
                //   modifiedWeight = parseFloat(weight_kg * outer_size).toFixed(3);
                //  }

                  return (
                    <tr>
                      <td>{item_name}</td>
                      <td>{weight_kg}</td>
                      <td>
                        {Number((weight_kg / total_weight) * 100).toFixed(2)}
                      </td>
                      <td>{cost}</td>
                    </tr>
                  );
                })}

                <tr>
                  <td>
                    <b>Total weight </b>
                  </td>
                  <td>
                    <b> {total_weight} </b>
                  </td>
                  <td>
                    {" "}
                    <b>100.00 </b>
                  </td>
                  <td>
                    <b>{total_cost} </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Total cost/ Pcs</b>
                  </td>
                  <td></td>
                  <td></td>

                  <td>
                    <b>{cost_per_kg} </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Packaging material </b>
                  </td>
                  <td></td>
                  <td></td>

                  <td>
                    {" "}
                    <b> {packing_cost} </b>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <b>Final price </b>
                  </td>
                  <td></td>
                  <td></td>

                  <td>
                    {" "}
                    <b>{final_price} </b>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Divider color="black" my="sm" variant="dashed" />
            <Flex justify={"center"}>
              <Button
                style={{ width: "50%" }}
                color="red"
                onClick={(event) => {
                  setOpened(false);
                }}
              >
                Close
              </Button>
            </Flex>
          </Stack>
        </ScrollArea>
      </Modal>
    </>
  );
}
//test