import React, { useState } from "react";
import database from "../../service/firebaseConfig";
import { Image, Notification, Progress, Text } from "@mantine/core";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

const NewImageUpload = ({ ...props }) => {
  const [uploadPercentage, setUploadPercentage] = useState();
  const imageUploadURL = props.imageUploadURL;
  // const [imageUploadURL, setImageUploadURL] = useState("");
  const [uploadedImageName, setUploadedImageName] = useState("");
  const storag = database.storage();
  const storeData = storag.ref();
  const uploadToastId = React.useRef(null);

  const upLoadFile = (e) => {
    props.setPreventFormSubmit(true);
    const explicitImgName = props.imgName?.trim().replace(" ", "_") || "";
    const uploadedImageFileName = e.target.files[0].name.replace(/\s/g, "");
    let imageNameForStorage;

    uploadToastId.current = toast("Uploading Image, please wait... ", {
      type: toast.TYPE.INFO,
      autoClose: false,
      hideProgressBar: true,
      closeButton: false,
    });
    const uplImgNameArr = uploadedImageFileName.split(".");
    const imgExtension = uplImgNameArr[uplImgNameArr.length - 1];

    if (explicitImgName) {
      let explImgNameArr = explicitImgName.split(".");
      let imgName = explImgNameArr[0];
      console.log("got image name", explicitImgName);
      imageNameForStorage = `${imgName}${Math.floor(
        Math.random() * 1000
      )}.${imgExtension}`;
    } else {
      console.log("did not receive any image name");
      let imgName = uplImgNameArr[0];
      let imgExtension = uplImgNameArr[uplImgNameArr.length - 1];
      imageNameForStorage = `${imgName}${Math.floor(
        Math.random() * 1000
      )}.${imgExtension}`;
    }
    // e.target.files contains File object references
    let folderName = "superzop_ordering_offers_images";
    var uploadTask = storeData
      .child(`${folderName}/${imageNameForStorage}`)
      .put(e.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");

        if (progress == 0 || progress >= 100) {
          setUploadPercentage("");
        } else {
          setUploadPercentage(Math.ceil(progress));
        }
        console.log(snapshot.state);
        switch (snapshot.state) {
          case "paused": // or 'paused'
            console.log("Upload is paused");
            break;
          case "running": // or 'running'
            console.log("Upload is running");
            break;
          default:
            console.log("Upload error");
        }
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          toast.dismiss(uploadToastId.current);

          toast("Image uploaded successfully", {
            type: toast.TYPE.SUCCESS,
          });

          console.log("File available at", downloadURL);
          props.setPreventFormSubmit(false);
          // setImageUploadURL(downloadURL);
          setUploadedImageName(imageNameForStorage);
          props.setUploadedImageName(imageNameForStorage);
          props.setImageUploadURL(downloadURL);
        });
      }
    );
  };
  return (
    <>
      <div>
        {imageUploadURL ? (
          <div className="imagePreviewContainer">
            <div
              className="imagePreviewContainer"
              style={{ width: 80, marginLeft: "auto", marginRight: "auto" }}
            >
              <Image
                className="imagePreviewBox"
                fit="cover"
                width={80}
                height={80}
                src={imageUploadURL || ""}
              />
              <div
                onClick={(e) => {
                  setUploadedImageName("");
                  props.setImageUploadURL("");
                  props.handleOnResetImageClick(e);
                }}
                className="removeImageButton"
              >
                ❌
              </div>
            </div>

            <br />
            <Text
              weight={500}
              style={{ marginBottom: "10px", fontSize: ".7rem" }}
            >
              {uploadedImageName}
            </Text>
          </div>
        ) : null}

        {imageUploadURL ? null : (
          <Button
            variant="primary"
            onClick={() => {
              if (props.uniqueKey) {
                document.getElementById(props.uniqueKey).click();
              } else {
                document.getElementById("fileUp").click();
              }
            }}
          >
            {uploadPercentage
              ? ` Uploading : ${uploadPercentage} %`
              : "Browse..."}
          </Button>
        )}

        <input
          type="file"
          id={props.uniqueKey || "fileUp"}
          style={{ display: "none" }}
          onChange={upLoadFile}
          multiple="image/*"
        />
        <br />
        <br />
      </div>
    </>
  );
};

export default React.memo(NewImageUpload);
