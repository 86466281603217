import React, { Component,useState,useRef,useEffect,useMemo } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { FaPencilAlt,FaSort}  from 'react-icons/fa';
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import { validateIsAdminUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import UpdatePurchaseOrderModal from './UpdatePurchaseOrder'
import './arrows.css'
function objectsAreSame(x, y) {
   var objectsAreSame = true;
   for(var propertyName in x) {
      if(x[propertyName] !== y[propertyName]) {
         objectsAreSame = false;
         break;
      }
   }
   return objectsAreSame;
}

// demarcate the figures in indian system

function formatIndianNumber(number) {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'decimal',
    useGrouping: true
  });

  return formatter.format(Math.floor(number));
}

function formattedNumber(number){

  let without_decimal = number.toString().replace(/(\.\d*?[1-9])0+$/, "$1");
  return without_decimal;
} 


  const PurchaseOrdrListTableHeader = (props) => {
    
    const { sortColumn, sortOrder, setSortColumn, setSortOrder } = props;
  
    const handleSort = (column) => {
      if (sortColumn === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    };
    
    const renderSortArrow = (column) => {
      const isSortColumn = sortColumn === column;
      const defaultSortImage = <FaSort />;
      const sortImage = isSortColumn ? null : defaultSortImage;
    
      return (
        <div className="sort-arrow-container">
          {sortImage}
          <span className={`sort-arrow ${isSortColumn && sortOrder === "asc" ? "active up" : ""}`}></span>
          <span className={`sort-arrow ${isSortColumn && sortOrder === "desc" ? "active down" : ""}`}></span>
        </div>
      );
    };
    
    
  
    const boldStyle = {
      fontWeight: "bold",
    };
    return (
      <thead className="thead-light">
        <tr>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>Category</th>
          <th>UOM</th>
          <th>Outer Size (Kg/Pc)</th>
          <th>Landing Cost(&#8377;)</th>
          <th>
          {renderSortArrow("inventory_after_shipped")}<button onClick={() => handleSort("inventory_after_shipped")} style={boldStyle}>
              Inventory (Kg/Pc)  
            </button>
          </th>
          <th> {renderSortArrow("inventoryValue")}
            <button onClick={() => handleSort("inventoryValue")} style={boldStyle}>
              Inventory Value(&#8377;)  

            </button>
          </th>
          <th> {renderSortArrow("avgDailySales")}
            <button onClick={() => handleSort("avgDailySales")} style={boldStyle}>
              Avg Daily Sale Rate (Kg/Pc) 
            </button>
          </th>
          <th> {renderSortArrow("effectiveInventoryOuters")}
            <button onClick={() => handleSort("effectiveInventoryOuters")} style={boldStyle}>
              Inventory in Outers (Bag/Pc)  
            </button>
          </th>
          <th> {renderSortArrow("effectiveInventory")}
            <button onClick={() => handleSort("effectiveInventory")} style={boldStyle}>
              Inventory (Days) 
            </button>
          </th>
          <th>Reorder Qty (In Outers)</th>
          <th>Status</th>
          {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
            <th>Edit</th>
          ) : null}
        </tr>
      </thead>
    );
  };
  

const PurchaseOrdrListTableBody = props => {
  
  const {setInventoryAfterShippedSum, setEffectiveInventorySum,setHoldInventorySum,sortColumn,sortOrder}= props;
  
  
    const [rows, setRows] = useState([]);
    const [updatePurchaseOrder, setUpdatePurchaseOrder] = useState(false)
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState("")
    const [rowIndex, setRowIndex] = useState()
    const [purchaseListOrderDetails, setPurchaseListOrderDetails] = useState(props.purchaseOrderListData);
    
    if(!objectsAreSame(props.purchaseOrderListData,purchaseListOrderDetails)){
      setPurchaseListOrderDetails(props.purchaseOrderListData);
    }


    const updatePurchaseList = (updatedData) => {
      
      let updatedPurchaseOrderList = [...purchaseListOrderDetails];
      updatedPurchaseOrderList[rowIndex]['reorder_point'] = updatedData['reorder_point'];
      updatedPurchaseOrderList[rowIndex]['stock_target'] = updatedData['stock_target'];
      updatedPurchaseOrderList[rowIndex]['max_inventory'] = updatedData['max_inventory'];
     
      setPurchaseListOrderDetails(updatedPurchaseOrderList)
    };

    // Default sort column and order
const defaultSortColumn = "category";
const defaultSortOrder = "asc";
 
    
    const   sortData = (data) => {
      const compare = (a, b) => {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      };
    
      return data.sort((a, b) => {
        let columnA, columnB;
    
        if (sortColumn === "inventory_after_shipped") {
          columnA = parseInt(a.inventory)
          columnB = parseInt(b.inventory)
        } else if (sortColumn === "inventoryValue") {
          columnA = parseInt(a.inventoryValue)
          columnB = parseInt(b.inventoryValue)
        } else if (sortColumn === "avgDailySales") {
          columnA = parseInt(a.avgDailySales)
          columnB = parseInt(b.avgDailySales)
        } else if (sortColumn === "effectiveInventoryOuters") {
          columnA = parseInt(a.inventoryOuters)
          columnB = parseInt(b.inventoryOuters)
        } else if (sortColumn === "effectiveInventory") {
          columnA = parseInt(a.inventoryDays)
          columnB = parseInt(b.inventoryDays)
        } else {
          // Default sort column
          console.log(a.itemCode)
          console.log(a.category)
          columnA = a.category?a.category.toLowerCase():"";
          columnB = b.category?b.category.toLowerCase():"";
        }
    
        if (sortOrder === "asc") {
          return compare(columnA, columnB);
        } else {
          return compare(columnB, columnA);
        }
      });
    };
    

    useEffect(() => {


      if(purchaseListOrderDetails) {
        
        let inventoryAfterShippedTotal = 0;
    let effectiveInventoryTotal = 0; 
    let dailySalesTotal = 0;
    let inventoryValueTotal = 0;
    let updatedRows = [];
    let holdInventoryTotal = 0;
       
        

        
         updatedRows = purchaseListOrderDetails.map((row,index) =>{
          

          let effectiveInventory;

          if (row.inventory_after_shipped !== undefined && row.order_qty_avg !== undefined) {
          
            if (row.order_qty_avg === null || row.order_qty_avg === 0) {
              effectiveInventory = 0;
            } else {
              effectiveInventory = row.inventory_after_shipped / row.order_qty_avg;
              effectiveInventory = Math.round((effectiveInventory + Number.EPSILON) * 100) / 100;
            }
          } else {
            effectiveInventory = 0;
          }
          
          
          
          
          // INVERTORY VALUE, DAYS TOTAL , kpi

          let inventory_after_shipped = ((row.inventory_after_shipped)*(row.outer_size)).toFixed(2);
          
          let inventoryValueCol = (inventory_after_shipped * (row.base_price)).toFixed(2);
      
          inventoryValueTotal += parseFloat(inventoryValueCol);

          inventoryAfterShippedTotal += parseFloat(inventory_after_shipped);
          
          effectiveInventoryTotal += parseFloat(effectiveInventory);

          holdInventoryTotal += parseFloat(row.hold_inventory_qty) || 0;


          // Inventory In OUTERS

          let effectiveInventoryOuters;

          if(!row.outer_size){
            row.outer_size = 1
          }


          if(row.inventory_after_shipped!=undefined && row.outer_size!=undefined){
            
            effectiveInventoryOuters = inventory_after_shipped / row.outer_size;

            effectiveInventoryOuters = Math.round((effectiveInventoryOuters + Number.EPSILON) * 100) / 100 ;

          }

          // Status, Reorder Quantity, Daily sales average

          let status;
          let reorder_qty;
          let avgDailySales;
          
          if(row.order_qty_avg!=undefined && row.outer_size!=undefined){

            avgDailySales = row.order_qty_avg * row.outer_size;

            avgDailySales = Math.round((avgDailySales + Number.EPSILON) * 100) / 100;

          //   daily sales total calc for kpi

            dailySalesTotal += avgDailySales;
          }

          if(row.stock_target!=undefined){
            if(effectiveInventory<row.stock_target){
              status="Buy";
              
              reorder_qty=((row.stock_target * avgDailySales)- inventory_after_shipped)/row.outer_size;
            }
          }else{
            if(effectiveInventory<4){
              status="Buy";
             
              reorder_qty=((4 * avgDailySales)- inventory_after_shipped)/row.outer_size;
            }
          }
          if(row.max_inventory!=undefined){
            if(effectiveInventory>row.max_inventory){
              status="Excess";
            }
          }else{
            if(row.Category=="MASALAS/SPICES" || row.Category=="DRY FRUITS"){
              if(effectiveInventory>15){
                status="Excess";
              }
            }else{
              if(effectiveInventory>10){
                status="Excess";
              }
            }
          }
          
          return{
              itemCode : row.item_id,
              itemName : row.brand?row.brand +' | '+row.item_disc : row.item_disc,
              category: row.sub_category?row.category +' - '+row.sub_category : row.category,
              uom : row.UOM,
              outerSize : row.outer_size,
              basePrice : row.base_price,
              inventory : inventory_after_shipped,
              inventoryValue : inventoryValueCol?inventoryValueCol:"",
              avgDailySales : avgDailySales?avgDailySales:0,
              inventoryOuters: effectiveInventoryOuters?effectiveInventoryOuters:"",
              inventoryDays: effectiveInventory?effectiveInventory:0,
              holdInventory: row.hold_inventory_qty?row.hold_inventory_qty:"",
              reOrderQty : reorder_qty?reorder_qty.toFixed(1):"",
              status: status?status:"OnPar",
             
          }

      })
        
        const sortedData = sortData(updatedRows);
     
        setRows(sortedData.map((row, index) => {
          return (
            <tr key={index} style={{backgroundColor: row.status && (row.status === "Excess" ? "#FFCCCC" : "#CCFFCC")}}>
              <td>{row.itemCode}</td>
              <td>{row.itemName}</td>
              <td>{row.category}</td>
              <td>{row.uom}</td>
              <td>{row.outerSize}</td>
              <td>{row.basePrice}</td>
              <td>{Math.ceil(row.inventory)}</td>
              <td>{formatIndianNumber(row.inventoryValue)}</td>
              <td>{row.avgDailySales != 0? row.avgDailySales:""}</td>
              <td>{row.inventoryOuters}</td>
              <td>{row.inventoryDays != 0? row.inventoryDays:""}</td>
              <td>{row.reOrderQty}</td>
              <td>{row.status}</td>
              {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
                <td>
                  <Button onClick={() => {
                    setUpdatePurchaseOrder(true);
                    setPurchaseOrderDetails(row);
                    setRowIndex(index);
                  }}>
                    <FaPencilAlt/>
                  </Button>
                </td>
              ) : null}
            </tr>
          );
        }));

        let inventoryDays;

        if (dailySalesTotal !== 0) {
          inventoryDays = (inventoryAfterShippedTotal / dailySalesTotal).toFixed(2);
        } else {
          inventoryDays = 0;
        }
        
        inventoryValueTotal = formatIndianNumber(inventoryValueTotal)
        setInventoryAfterShippedSum(inventoryValueTotal);
    setEffectiveInventorySum(inventoryDays);
    holdInventoryTotal = formatIndianNumber(holdInventoryTotal)
    setHoldInventorySum(holdInventoryTotal);
        
      }
     
    }, [purchaseListOrderDetails,setInventoryAfterShippedSum, setEffectiveInventorySum,setHoldInventorySum,sortColumn,sortOrder])

    return (<>
    
    <tbody>{rows}</tbody>
        <UpdatePurchaseOrderModal
                show={updatePurchaseOrder}
                onHide={() => setUpdatePurchaseOrder(false)}
                callback = {updatePurchaseList}
                updateDetails = {purchaseOrderDetails}
            />
      </>);


}

const InventoryKPIs = (props) => {
  const { inventoryValue, inventoryDays, holdInventory } = props;

  return (
    <div className="row">
      <div className="col-md-3">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inventory-value">Inventory Value</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            type="text"
            className="form-control"
            placeholder="Enter value"
            value={inventoryValue}
            readOnly
            style={{ color: '#006666', fontWeight: "700", fontFamily: 'Arial, sans-serif' }}
          />
        </InputGroup>
      </div>
      <div className="col-md-3">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inventory-days">Inventory Days</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            type="text"
            className="form-control"
            placeholder="Enter value"
            value={inventoryDays}
            readOnly
            style={{ color: '#006666', fontWeight: "700", fontFamily: 'Arial, sans-serif' }}
          />
        </InputGroup>
      </div>
      <div className="col-md-3">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="hold-inventory">Hold Inventory</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            type="text"
            className="form-control"
            placeholder="Enter value"
            value={holdInventory}
            readOnly
            style={{ color: '#006666', fontWeight: "700", fontFamily: 'Arial, sans-serif' }}
          />
        </InputGroup>
      </div>
    </div>
  );
};



const PurchaseOrderListTable = (props) => {
    const { purchaseOrderListData } = props;

    const [inventoryAfterShippedSum, setInventoryAfterShippedSum] = useState(0);
    const [effectiveInventorySum, setEffectiveInventorySum] = useState(0);
    const [holdInventorySum, setHoldInventorySum] = useState(0);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");


  
    return (
      <>
      <hr></hr>
      <InventoryKPIs inventoryValue={inventoryAfterShippedSum}
      inventoryDays={effectiveInventorySum} holdInventory={holdInventorySum}/>
        <table className="table table-striped" style={{ marginTop: 10 }}>
        <PurchaseOrdrListTableHeader
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          
        />
        <PurchaseOrdrListTableBody
          
          purchaseOrderListData={purchaseOrderListData}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          setInventoryAfterShippedSum={setInventoryAfterShippedSum}
          setEffectiveInventorySum={setEffectiveInventorySum}
          setHoldInventorySum = {setHoldInventorySum}
        />
      </table>
          </>
    );
}

export default PurchaseOrderListTable
