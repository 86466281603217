import React, { useState, useEffect } from 'react'
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Table, Button, Form, Card, InputGroup, Toast, Badge, Jumbotron } from 'react-bootstrap';
import { InputGroupAddon } from 'reactstrap';
import { Chart } from "react-google-charts";
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'


function InventoryAnalysis({location}) {

    const [itemData, setItemData] = useState("")
    const [chartFilterData, setChartFilterData] = useState("")
    const [maxObservation, setMaxObservation] = useState("")
    const [obsvIndexHeader, setObsvIndexHeader] = useState("")
    const [finaldata, setFinalData] = useState("")



    useEffect(() => {
        console.log(location)
        if (location.state!==undefined) {
            sessionStorage.setItem("itemToAnalyse",JSON.stringify(location.state.itemToAnalyse))
            setItemData(location.state.itemToAnalyse)
            return
        }
        else{
            setItemData(JSON.parse(sessionStorage.getItem("itemToAnalyse")))
            console.log(JSON.parse(sessionStorage.getItem("itemToAnalyse")))
        }
        

    }, [])

    const addChartdata = () =>{

        let chartFinalInfo = []
        let chartHeaderDetails = []
        chartHeaderDetails.push("X")
        obsvIndexHeader.forEach(headers=>{
            chartHeaderDetails.push(headers)
        })
        chartFinalInfo.push(chartHeaderDetails)
        console.log(chartFilterData)
        chartFilterData.forEach((item,index)=>{
            let chartFilterInfo = []
            chartFilterInfo.push(index)
            console.log(Object.values(item)[0])
            Object.values(item)[0].forEach(itemObserved=>{
                console.log(itemObserved)
                chartFilterInfo.push(itemObserved?Number(itemObserved.slice(0, -1)):0)
            })
            let chartFilterLength = chartFilterInfo.length
            if(chartFilterLength<=(maxObservation+1))
            {
                for(var i= 0;i<(maxObservation+1)-chartFilterLength;i++){
                    console.log(chartFilterInfo)
                    chartFilterInfo.push(0)
                }
            }
            chartFinalInfo.push(chartFilterInfo)
        })
        console.log(chartFinalInfo)
        setFinalData(chartFinalInfo)
        // let chartFinalInfo = [["X", "OI1", "OI2"],[0, 101.6, 105],[1, 95.4,0]]
    
    }

    useEffect(() => {
        if(chartFilterData && obsvIndexHeader)
        addChartdata()
    }, [chartFilterData,obsvIndexHeader])

    const structureChartData = () => {

        let c = 1
        let max = -864849
        let supplierUnique
        let observedIndexList = []
        let indexListData = []
        supplierUnique = itemData[0]["supplier_name"]
        console.log(itemData)
        itemData.forEach((items, index) => {
            if(items["supplier_name"].indexOf(supplierUnique)!==-1){
                observedIndexList.push(items["Index Score"])
                c += 1
            }
            else{
                if(max<c)
                max=c
                c=1
                indexListData.push({[supplierUnique]:observedIndexList})
                observedIndexList = []
                supplierUnique = items["supplier_name"]
                observedIndexList.push(items["Index Score"])
            }
        })
        setMaxObservation(max)
        setChartFilterData(indexListData)
        indexListData.forEach(items=>{console.log(Object.keys(items)[0])})

        let observedVal = []
        console.log(max)
        for(let i = 0;i<Number(max);i++){
        observedVal.push(`OI${i+1}`)
        }
        console.log(observedVal)
        console.log(chartFilterData)
        setObsvIndexHeader(observedVal)
    }

    useEffect(() => {
        if (itemData.length>0)
            structureChartData()
    }, [itemData])


    return (
        <>
        <NavBar />
        <div style={{ paddingTop: 100, marginRight: 20, marginLeft: 20,textAlign:"center" }}>
            <Alert variant="primary">Inventory Analysis</Alert>
            <Table bordered striped variant="primary">
                        <thead>
                            <tr >
                                <td>Supplier Name</td>
                                <td>SKU Name</td>
                                {obsvIndexHeader && obsvIndexHeader.map(itemHeader=>(
                                <td>{itemHeader}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {chartFilterData.length>0 && chartFilterData.map((item,index) => (
                            <tr>
                            <td>{Object.keys(item)[0]===itemData[index]["supplier_name"]?itemData[index]["supplier_name"]:""}</td>
                            <td>{Object.keys(item)[0]===itemData[index]["supplier_name"]?itemData[index]["Product Name"]:""}</td>
                            {item[Object.keys(item)[0]].map(observedVal=>(
                                <td>{observedVal?observedVal:""}</td>
                            ))}
                            </tr>
                        
                        ))
                        }
                    </tbody>
                    </Table>
                    <div>
                        {finaldata && <Chart
                            width={1000}
                            height={500}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={finaldata && finaldata}
                            options={{
                                title: 'Inventory Analysis Reports',
                                chartArea: { width: '50%' },
                                hAxis: {
                                    title: 'Observed Index',
                                    minValue: 0,
                                },
                                vAxis: {
                                    title: 'Percentage',
                                },
                                legend: 'none'
                            }}
                        />}
                    </div>
        </div>
        </>
    )
}

export default InventoryAnalysis
