import { CITY_MUMBAI, CITY_PUNE,CITY_ALL ,REGION_BOM_ALL,REGION_ALL,REGION_YO_GROCERY, REGION_BOM_DF} from '../constants/allConstants';

export const getCurrCity = (state) => {
    return (state.hasOwnProperty('curr_city') ? state.curr_city : CITY_ALL);
};

export const getCurrRegion = (state) => {
    
    const currCity = getCurrCity(state);
    // console.log("Region "+state.all_region+state.bom_region+state.pune_region);
    if (currCity === CITY_ALL) {
        if (state.hasOwnProperty('all_region')) {
            return state.all_region;
        }
        return REGION_ALL;
    } else if (currCity === CITY_MUMBAI) {
        if (state.hasOwnProperty('bom_region')) {
            return state.bom_region;
        }
        return REGION_BOM_ALL;
    } else if (currCity === CITY_PUNE) {
        return "P";
    } else if (currCity === REGION_YO_GROCERY) {
        return "S";
    } else if(currCity === REGION_BOM_DF){
        return "DF";
    }
};