import React,{useState} from 'react'
import database from '../../../service/firebaseConfig';
import { Button } from 'react-bootstrap';



const AadharFrontPageUpload=(props)=> {

   
  const [getSuccessUpload,setSuccessUpload]=useState("")
    const storag= database.storage();
    const storeData=storag.ref()

    
    const upLoadFile=(e)=>{

       setSuccessUpload("Uploading...")
      console.log("AadharFrontPageUpload")
      console.log(storag)
      console.log(storeData)
       // e.target.files contains File object references
      
    
        console.log(e.target.files[0]);
        var uploadTask = storeData.child(`superzop_ordering_product_images/aadhar_front.jpg`).put(e.target.files[0]);
        uploadTask.on('state_changed', ((snapshot)=>{
      
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          console.log(snapshot.state)
          switch (snapshot.state) {
           
            case "paused": // or 'paused'
              console.log('Upload is paused');
              break;
            case "running": // or 'running'
              console.log('Upload is running');
              break;
              default:
                console.log("Upload error")
          }
        }), function(error) {
          // Handle unsuccessful uploads
        }, function() {
         
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log('File available at', downloadURL);
            
            
                    props.aadharFrontUrl(downloadURL)
                    setSuccessUpload("Uploaded successfully")
                    setTimeout(() => {
                        setSuccessUpload("")
                        props.buttonStatus(false)
                    }, 1500);
                    
        
          });
        });
      
  
    }
   return(
    <>
   <div>
   <Button variant="info" onClick={()=>{document.getElementById("files").click()}}>{getSuccessUpload!==""?getSuccessUpload:"Browse..."}</Button>
    <input type="file" id="files" style={{display:"none"}} onChange={upLoadFile} multiple="image/*" /><br/><br/>

    </div></>
  
   )
}

export default React.memo(AadharFrontPageUpload)
