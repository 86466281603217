import React ,{useState,useEffect}from 'react'
import { Form,Modal,Button, Table } from 'react-bootstrap'
import axios from 'axios';
import { toast } from 'react-toastify';
import { OFFER_BASE_URL} from "../../service/servicesConfig";


function AddItem({...props}){
    console.log(props)
const [addedItem,setAddedItem] = useState("");
const [quantity,setQuantity] = useState("");
const [addItem,setAddItem] = useState(false);
console.log(addedItem)
console.log(quantity)
const allItems = props.stockApiItems.map(item => item.item_id);
console.log(allItems)

const checkItem = (val) => {
    console.log(val)
    console.log(!allItems.includes(val))

        setAddedItem(val)
    
    // if(allItems.map(item => item))
}

// console.log(`checkItem`,checkItem())

const updateAddedItem = () => {
    if(addedItem ==""){
        alert("Cannot enter a blank Item Id, Enter a valid Item Id")
    }
    if(addedItem !==""){
        const url = `${OFFER_BASE_URL}/stock-management?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
    // debugger;
    axios.post(url, {
        item_id: addedItem,
        qty: quantity
      })
      .then(function (response) {
        console.log(response);
        props.updateItem(true);
        // notifyUser(true)
      })
      .catch(function (error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.data.message.substring(14,34));
            alert("please enter unique Item Id :"+error.response.data.message.substring(14,34))
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      });
     
    } 
    /*else{
        alert("Please don't add a blank Item Id or a duplicate item")
    }*/
    
}

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add item
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tr>
                        <td>Enter the item Id</td>
                        <td><Form.Control  onChange={(e)=> checkItem(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td>Enter quantity</td>
                        <td><Form.Control onChange={(e)=>setQuantity(e.target.value)} /></td>
                    </tr>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() =>{updateAddedItem();props.onHide()}} >Add Item</Button>
                <Button onClick={props.onHide} >Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default AddItem;