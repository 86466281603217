import React, { useState, useEffect,useRef, useContext, Fragment } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Breadcrumb, Tab,Form, Row, Col, Nav, Button,InputGroup,Badge } from 'react-bootstrap';
import Select from 'react-select';
import qualitydb from '../../service/qualityfirebaseconfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import GrtListTable from './grtlisttable'
import XLSX from 'xlsx'
import database from '../../service/firebaseConfig';
import LoaderComp from '../../components/Loader';
import { validateGRNOnlyUser, validateIsAdminUser, validatePurchaseUser, validatePurchaseExportUser, validatePurchaseExportOnlyUser , validatePurchaseOnlyUser,validatePurchaseReportUser} from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import {Link} from 'react-router-dom'

function formatDatetime(dt){
  if(dt){
    let dtlist=dt.split('-');
    let date=dtlist[0];
    let month=dtlist[1];
    if(date.length<2){
      date='0'+date;
    }
    if(month.length<2){
      month='0'+month;
    }
    return date+'-'+month+'-'+dtlist[2];
  }else{
    return dt;
  }
}

const GrtList = () => {
  const [testDate, setTestDate] = useState(new Date());
  const [testEndDate,setTestEndDate] = useState(new Date());
  const [loading,setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const [searchProductDetails, setSearchProductDetails] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [exportData, setExportData] = useState(false);
  const [itemAnalysedData, setItemAnalysedData] = useState("")


  useEffect(() => {
    let currentUrl=window.location.href;
    if(currentUrl.includes('itemid')){
      let itemid=currentUrl.substring(currentUrl.lastIndexOf("=") + 1, currentUrl.length);
      fetchProductsByItemID(itemid);
    }else{
      let curdt=new Date();
      fetchProducts(curdt,curdt);
    }
  }, [])

  useEffect(() => {
      if (exportData)
          exportGrtDetails()
  }, [exportData])

  useEffect(() => {
      if (searchText){
        filterGrtDetails();
      }else{
        setProductDetails(productDetails);
      }
  }, [searchText])

  const filterGrtDetails = () => {
    console.log('filterGrtDetails');
    console.log(searchText);

    let checksearchtext=searchText.trim().toLowerCase();
    let filteredData=[];
    for(let i=0;i<searchProductDetails.length;i++){
      let pushflag=false;
      console.log(searchProductDetails[i]);
      let sampleid=searchProductDetails[i]['Sample ID'];
      let itemid=searchProductDetails[i]['Product Code'];
      let description=searchProductDetails[i]['Product Name'];

      if(sampleid){
        sampleid=sampleid.trim().toLowerCase();
        if(sampleid.includes(checksearchtext)){
          pushflag=true;
          filteredData.push(searchProductDetails[i]);
        }
      }

      if(itemid && pushflag==false){
        itemid=itemid.trim().toLowerCase();
        if(itemid.includes(checksearchtext)){
          filteredData.push(searchProductDetails[i]);
          pushflag=true;
        }
      }

      if(description && pushflag==false){
        description=description.trim().toLowerCase();
        if(description.includes(checksearchtext)){
          filteredData.push(searchProductDetails[i]);
          pushflag=true;
        }
      }
    }
    setProductDetails(filteredData);
  }

  const fetchProductsByItemID = (itemid) => {
    if(itemid){
      let curdate = new Date(),
          month = '' + (curdate.getMonth() + 1),
          day = '' + curdate.getDate(),
          year = curdate.getFullYear();

      let finalDate=day+'-'+month+'-'+year;
      qualitydb.database().ref(`Product_Grading_Result/${finalDate}`).once('value', function(snapshot) {
        qualitydb.database().ref(`Testing_Product_Details/${year}/${month}/${finalDate}`).once('value', function(snap) {
          // TestType -> Product_Grading_Result -> Compare
          // TestType -> Testing_Product_Details -> Analyse
          let finalProductList=[];
          let prod_grading=snapshot.val();
          let test_prod=snap.val()

          if(prod_grading){
            Object.keys(prod_grading).map((prodkey,index) => {
              let prod_grading_phone=prod_grading[prodkey];
              Object.keys(prod_grading_phone).map((pkey,ind) => {
                let testproddid=prod_grading_phone[pkey]['product_id'];
                if(testproddid==itemid && prod_grading_phone[pkey]['sample_id']){
                  let ele={};
                  ele['Testing Date']=finalDate;
                  ele['Sample ID']=prod_grading_phone[pkey]['sample_id'];
                  ele['Test Type']='Compare';
                  ele['Category']=prod_grading_phone[pkey]['category'];
                  ele['Product Code']=prod_grading_phone[pkey]['product_id'];
                  ele['Product Name']=prod_grading_phone[pkey]['description'];
                  ele['Index Score']='';
                  ele['Result']='';
                  ele['Width']=prod_grading_phone[pkey]['average_length'];
                  ele['Length']=prod_grading_phone[pkey]['average_width'];
                  let grainlength=prod_grading_phone[pkey]['average_width'];
                  let grainwidth=prod_grading_phone[pkey]['average_length'];
                  grainlength = parseFloat(grainlength.substring(0, grainlength.length - 2));
                  grainwidth = parseFloat(grainwidth.substring(0, grainwidth.length - 2));
                  let length_width_ratio=grainlength/grainwidth;
                  length_width_ratio=Math.round((length_width_ratio + Number.EPSILON) * 100) / 100;
                  ele['LW Ratio']=(prod_grading_phone[pkey]['average_grain_length_width_ratio']?prod_grading_phone[pkey]['average_grain_length_width_ratio']:length_width_ratio);
                  ele['BrokenGrainPerc']=prod_grading_phone[pkey]['broken_grain_percentage'];
                  ele['supplier_name']=prod_grading_phone[pkey]['supplier_name'];
                  console.log(ele)
                  finalProductList.push(ele);
                }
              });
            });
          }

          if(test_prod){
            Object.keys(test_prod).map((productkey,indeex) => {
              let testproducttid=test_prod[productkey]['product_id'];
              if(testproducttid==itemid && test_prod[productkey]['sample_id']){
                let el={};
                el['Testing Date']=finalDate;
                el['Sample ID']=test_prod[productkey]['sample_id'];
                el['Test Type']='Analyze';
                el['Category']=test_prod[productkey]['category'];
                el['Product Code']=test_prod[productkey]['product_id'];
                el['Product Name']='';
                el['Index Score']='';
                el['Result']='';
                el['Width']=test_prod[productkey]['average_height'];
                el['Length']=test_prod[productkey]['average_width'];
                el['LW Ratio']=test_prod[productkey]['average_grain_length_width_ratio'];
                el['BrokenGrainPerc']=test_prod[productkey]['broken_rice_percentage'];
                el['supplier_name']=test_prod[productkey]['supplier_name'];
                finalProductList.push(el);
              }
            });
          }
          console.log("ProductList");
          console.log(finalProductList);
          if(finalProductList.length==0){
            setLoading(false);
          }else{
            setLoading(true);
          }
          setSearchProductDetails(finalProductList);
          setItemAnalysedData(finalProductList)
          setProductDetails(finalProductList);
        });
      });
    }else{
      setLoading(false);
      setSearchProductDetails([]);
      setProductDetails([]);
    }
  }

  const fetchProducts = (testingdate,testingenddate) => {
    console.log(testingdate)
    console.log(testingenddate)
    let d = new Date(testingdate),
        month = ("0" + (d.getMonth() + 1)).slice(-2),
        day = ("0" + d.getDate()).slice(-2),
        year = d.getFullYear();

    let finalDate=day+'-'+month+'-'+year;
    console.log("Final Date::"+finalDate);
    let finalDateYMD=year+'-'+month+'-'+day;
    console.log(finalDateYMD);

    let ed = new Date(testingenddate),
        emonth = ("0" + (ed.getMonth() + 1)).slice(-2),
        eday = ("0" + ed.getDate()).slice(-2),
        eyear = ed.getFullYear();

    let finalEndDate=eday+'-'+emonth+'-'+eyear;
    console.log("Final End Date::"+finalEndDate);
    let finalEndDateYMD=eyear+'-'+emonth+'-'+eday;
    console.log(finalEndDateYMD);

    let sampleIdSet=new Set();
    Promise.all([
      qualitydb.database().ref(`Product_Grading_Result/`).once('value'),
      qualitydb.database().ref(`Testing_Product_Details/`).once('value'),
      qualitydb.database().ref(`Product_Master_Grading/`).once('value'),
      database.database().ref(`Product_Base_Data/`).once('value')
    ]).then(([snapshot, snap, prodsnapshot,prodbasesnapshot]) => {

      //Product_Base_Data
      // TestType -> Product_Grading_Result -> Compare
      // TestType -> Testing_Product_Details -> Analyse
      let finalProductList=[];
      let prodtest_grading=snapshot.val();
      let test_prod_dets=snap.val();
      let productmaster=prodsnapshot.val();
      let prodbase=prodbasesnapshot.val();
      // console.log(prodbase);


      if(prodtest_grading){
        Object.keys(prodtest_grading).map((sdkey,sdindex) => {
          let sdkeylist=sdkey.split('-');
          let prod_grading=prodtest_grading[sdkey];
          let sdkeyinymdformat= sdkeylist[2]+'-'+("0" + sdkeylist[1]).slice(-2)+'-'+("0" + sdkeylist[0]).slice(-2);
          if(sdkeyinymdformat>=finalDateYMD && sdkeyinymdformat<=finalEndDateYMD){
            if(prod_grading){
              Object.keys(prod_grading).map((prodkey,index) => {
                let prod_grading_phone=prod_grading[prodkey];
                Object.keys(prod_grading_phone).map((pkey,ind) => {
                  if(prod_grading_phone[pkey]['sample_id'] && !sampleIdSet.has(prod_grading_phone[pkey]['sample_id'])){
                    if(prod_grading_phone[pkey]['product_id']){
                      Object.keys(productmaster).map((prmkey,prmindex) => {
                        if(productmaster[prmkey]['itemnid']==prod_grading_phone[pkey]['product_id'] && !sampleIdSet.has(prod_grading_phone[pkey]['sample_id'])){
                          Object.keys(prodbase).map((prbasekey,prbaseindex) =>{
                            if(prbasekey==prod_grading_phone[pkey]['product_id']){
                              let ele={};
                              let testing_date=prod_grading_phone[pkey]['time']?sdkey + ' '+prod_grading_phone[pkey]['time']:sdkey;
                              ele['Testing Date']=formatDatetime(testing_date);
                              ele['Sample ID']=prod_grading_phone[pkey]['sample_id'];
                              ele['Test Type']='Compare';
                              ele['Category']=prod_grading_phone[pkey]['category'];
                              ele['Product Code']=prod_grading_phone[pkey]['product_id'];
                              ele['Product Name']=prod_grading_phone[pkey]['description']+' - '+prodbase[prbasekey]['brand'];
                              ele['Width']=prod_grading_phone[pkey]['average_length'];
                              ele['Length']=prod_grading_phone[pkey]['average_width'];
                              let grainlength=prod_grading_phone[pkey]['average_width'];
                              let grainwidth=prod_grading_phone[pkey]['average_length'];
                              grainlength = parseFloat(grainlength.substring(0, grainlength.length - 2));
                              grainwidth = parseFloat(grainwidth.substring(0, grainwidth.length - 2));
                              let length_width_ratio=grainlength/grainwidth;
                              length_width_ratio=Math.round((length_width_ratio + Number.EPSILON) * 100) / 100;
                              ele['LW Ratio']=(prod_grading_phone[pkey]['average_grain_length_width_ratio']?prod_grading_phone[pkey]['average_grain_length_width_ratio']:length_width_ratio);
                              ele['BrokenGrainPerc']=prod_grading_phone[pkey]['broken_grain_percentage'];
                              ele['moisture']=prod_grading_phone[pkey]['moisture'];
                              ele['grain_count']=prod_grading_phone[pkey]['grain_count'];
                              ele['dd_count']=prod_grading_phone[pkey]['dd_count'];
                              ele['weeviled_grain_per']=prod_grading_phone[pkey]['weeviled_grain_per'];
                              ele['sava']=prod_grading_phone[pkey]['sava'];                              
                              ele['supplier_name']=prod_grading_phone[pkey]['supplier_name'];
                              ele['brand']=prodbase[prbasekey]['brand'];
                              ele['sub_category']=prodbase[prbasekey]['sub_category'];
                              ele['uom']=prodbase[prbasekey]['uom'];
                              ele['base_price']=prod_grading_phone[pkey]['base_price']?prod_grading_phone[pkey]['base_price']:"";
                              let scores = prod_grading_phone[pkey]['scores'];
                              ele['scores'] = scores;
                              let latest_score_ts = Object.keys(scores).sort().reverse()[0]
                              if (latest_score_ts && scores[latest_score_ts].overall) {
                                ele['Index Score'] = scores[latest_score_ts].overall.score;
                                ele['Result'] = scores[latest_score_ts].overall.result;
                              } else {
                                ele['Index Score'] = 'N/A';
                                ele['Result'] = 'N/A';
                              }                              
                              // let reslist=getResultIndexScoreForTestSample(ele,productmaster[prmkey]);
                              // if(reslist){
                              //   ele['Index Score']=reslist[0];
                              //   ele['Result']=reslist[1];
                              // }else{
                              //   ele['Index Score']='N/A';
                              //   ele['Result']='N/A';
                              // }
                              sampleIdSet.add(prod_grading_phone[pkey]['sample_id']);
                              finalProductList.push(ele);
                            }
                          });
                        }
                      });
                    }else{
                      let ele={};
                      let testing_date = prod_grading_phone[pkey]['time']?finalDate + ' '+prod_grading_phone[pkey]['time']:finalDate;
                      ele['Testing Date']=formatDatetime(testing_date);
                      ele['Sample ID']=prod_grading_phone[pkey]['sample_id'];
                      ele['Test Type']='Compare';
                      ele['Category']=prod_grading_phone[pkey]['category'];
                      ele['Product Code']=prod_grading_phone[pkey]['product_id'];
                      ele['Product Name']=prod_grading_phone[pkey]['description'];
                      ele['Width']=prod_grading_phone[pkey]['average_length'];
                      ele['Length']=prod_grading_phone[pkey]['average_width'];
                      let grainlength=prod_grading_phone[pkey]['average_width'];
                      let grainwidth=prod_grading_phone[pkey]['average_length'];
                      grainlength = parseFloat(grainlength.substring(0, grainlength.length - 2));
                      grainwidth = parseFloat(grainwidth.substring(0, grainwidth.length - 2));
                      let length_width_ratio=grainlength/grainwidth;
                      length_width_ratio=Math.round((length_width_ratio + Number.EPSILON) * 100) / 100;
                      ele['LW Ratio']=(prod_grading_phone[pkey]['average_grain_length_width_ratio']?prod_grading_phone[pkey]['average_grain_length_width_ratio']:length_width_ratio);
                      ele['BrokenGrainPerc']=prod_grading_phone[pkey]['broken_grain_percentage'];
                      ele['moisture']=prod_grading_phone[pkey]['moisture'];
                      ele['grain_count']=prod_grading_phone[pkey]['grain_count'];
                      ele['dd_count']=prod_grading_phone[pkey]['dd_count'];
                      ele['weeviled_grain_per']=prod_grading_phone[pkey]['weeviled_grain_per'];
                      ele['sava']=prod_grading_phone[pkey]['sava'];
                      ele['supplier_name']=prod_grading_phone[pkey]['supplier_name'];
                      ele['Index Score']='N/A';
                      ele['Result']='N/A';
                      ele['brand']='';
                      ele['sub_category']='';
                      ele['uom']='';
                      ele['base_price']='';
                      sampleIdSet.add(prod_grading_phone[pkey]['sample_id']);
                      finalProductList.push(ele);
                    }
                  }

                });
              });
            }
          }
        });
      }


      // console.log(test_prod_dets);
      let test_prod_dummy=[];
      Object.keys(test_prod_dets).map((sdtkey,sdtindex) => {
        let test_prod_year_dets=test_prod_dets[sdtkey];
        Object.keys(test_prod_year_dets).map((ysdtkey,ysdtindex) => {
          let test_prod_year_date_dets=test_prod_year_dets[ysdtkey];
          Object.keys(test_prod_year_date_dets).map((dysdtkey,dysdtindex) => {
            let ele={};
            let datekkkeyarr=dysdtkey.split('-');
            let datekeeey=datekkkeyarr[2]+'-'+datekkkeyarr[1]+'-'+datekkkeyarr[0];
            ele[datekeeey]=test_prod_year_date_dets[dysdtkey];
            test_prod_dummy.push(ele);
          });
        });
      });

      // console.log('test_prod');
      // console.log(test_prod_dummy);

        if(test_prod_dummy){
          Object.keys(test_prod_dummy).map((productttkey,ttindeex) => {
            let test_prod_date=test_prod_dummy[productttkey];
            Object.keys(test_prod_date).map((produuctkey,indneex) => {
              let test_prod=test_prod_date[produuctkey];
              if(produuctkey>=finalDateYMD && produuctkey<=finalEndDateYMD){
                Object.keys(test_prod).map((productkey,indeex) => {
                  if(test_prod[productkey]['sample_id'] && !sampleIdSet.has(test_prod[productkey]['sample_id'])){
                    if(test_prod[productkey]['product_id']){
                      Object.keys(productmaster).map((prmkey,prmindex) => {
                        if(productmaster[prmkey]['itemnid']==test_prod[productkey]['product_id'] && !sampleIdSet.has(test_prod[productkey]['sample_id'])){
                          Object.keys(prodbase).map((prbasekey,prbaseindex) =>{
                            if(prbasekey==test_prod[productkey]['product_id']){
                              let el={};
                              let prodkeylist=produuctkey.split('-');
                              let produuctkeyindmyformat= prodkeylist[2]+'-'+prodkeylist[1]+'-'+prodkeylist[0];
                              let testing_date=test_prod[productkey]['time']?produuctkeyindmyformat + ' '+test_prod[productkey]['time']:produuctkeyindmyformat;
                              el['Testing Date']=formatDatetime(testing_date);
                              el['Sample ID']=test_prod[productkey]['sample_id'];
                              el['Test Type']='Analyze';
                              el['Category']=test_prod[productkey]['category'];
                              el['Product Code']=test_prod[productkey]['product_id'];
                              el['Product Name']=prodbase[prbasekey]['brand'];
                              el['Width']=test_prod[productkey]['average_height'];
                              el['Length']=test_prod[productkey]['average_width'];
                              el['LW Ratio']=test_prod[productkey]['average_grain_length_width_ratio'];
                              el['BrokenGrainPerc']=test_prod[productkey]['broken_rice_percentage'];
                              el['supplier_name']=test_prod[productkey]['supplier_name'];
                              el['brand']=prodbase[prbasekey]['brand'];
                              el['sub_category']=prodbase[prbasekey]['sub_category'];
                              el['uom']=prodbase[prbasekey]['uom'];
                              el['base_price']=test_prod[productkey]['base_price'];
                              let reslist=getResultIndexScoreForTestSample(el,productmaster[prmkey]);
                              if(reslist){
                                el['Index Score']=reslist[0];
                                el['Result']=reslist[1];
                              }else{
                                el['Index Score']='N/A';
                                el['Result']='N/A';
                              }
                              sampleIdSet.add(test_prod[productkey]['sample_id']);
                              finalProductList.push(el);
                            }
                          });
                        }
                      });
                    }else{
                      let el={};
                      let prodkeylist=produuctkey.split('-');
                      let produuctkeyindmyformat= prodkeylist[2]+'-'+prodkeylist[1]+'-'+prodkeylist[0];
                      let testing_date=test_prod[productkey]['time']?produuctkeyindmyformat + ' '+test_prod[productkey]['time']:produuctkeyindmyformat;
                      el['Testing Date']=formatDatetime(testing_date);
                      el['Sample ID']=test_prod[productkey]['sample_id'];
                      el['Test Type']='Analyze';
                      el['Category']=test_prod[productkey]['category'];
                      el['Product Code']=test_prod[productkey]['product_id'];
                      el['Product Name']='';
                      el['Width']=test_prod[productkey]['average_height'];
                      el['Length']=test_prod[productkey]['average_width'];
                      el['LW Ratio']=test_prod[productkey]['average_grain_length_width_ratio'];
                      el['BrokenGrainPerc']=test_prod[productkey]['broken_rice_percentage'];
                      el['supplier_name']=test_prod[productkey]['supplier_name'];
                      el['brand']='';
                      el['sub_category']='';
                      el['uom']='';
                      el['Index Score']='N/A';
                      el['Result']='N/A';
                      sampleIdSet.add(test_prod[productkey]['sample_id']);
                      finalProductList.push(el);
                    }
                  }
                });
              }
            });
          });
        }
        // console.log("ProductList");
        // console.log(finalProductList);
        if(finalProductList.length==0){
          setLoading(false);
        }else{
          setLoading(true);
        }
        setSearchProductDetails(finalProductList);
        setProductDetails(finalProductList);
        setItemAnalysedData(finalProductList)
    }).catch((err) => {
      setLoading(false);
      setSearchProductDetails([]);
      setProductDetails([]);
    });
  }

  const getResult = (criteria,thresh1,thresh2, sample) => {
    let result;
    if(criteria == 'Higher') {
      if(sample>thresh1){
        result="Accept";
      }else{
        if(sample>thresh2){
          result="Warn";
        }else{
          result="Reject";
        }
      }
    } else {
      if(sample<thresh1){
        result="Accept";
      }else{
        if(sample<thresh2){
          result="Warn";
        }else{
          result="Reject";
        }
      }
    }
    return result;
  }

  const getResultIndexScoreForTestSample = (updateDetails,prod_data) => {
    // console.log('getResultIndexScoreForTestSample');
    // console.log(updateDetails);
    // console.log(prod_data);
    let product_id=updateDetails['Product Code'];
    // console.log(product_id);
    let finalProdList=[];
    let parameterList=['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %'];
    if(product_id){
        if(prod_data!=undefined){
          let index_score_sum=0;
          for(let i=0;i<4;i++){
            let ele={};
            ele['Parameter']=parameterList[i];
            if(parameterList[i]=='Avg Length (mm)'){
                  let length_threshold_per=(prod_data['length_threshold_per']!=undefined?prod_data['length_threshold_per']:"5%");
                  length_threshold_per = parseInt(length_threshold_per.substring(0, length_threshold_per.length - 1));
                  let standard_length=prod_data['length'];
                  standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                  let thresh1=prod_data['length_threshold_abs']?parseFloat(prod_data['length_threshold_abs']):standard_length*(1+(length_threshold_per/100));
                  thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                  let sample_length=updateDetails['Length'];
                  sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));
                  let criteria="Higher";
                  let score=sample_length/standard_length;
                  score=Math.round((score + Number.EPSILON) * 100);

                  ele['Sample Value']=updateDetails['Length'];
                  ele['Standard Value']=prod_data['length'];
                  ele['Criteria']=criteria;


                  ele['Threshold Range 1']=thresh1;

                  let length_threshold_per2=(prod_data['length_threshold_per2']!=undefined?prod_data['length_threshold_per2']:"10%");
                  length_threshold_per2 = parseInt(length_threshold_per2.substring(0, length_threshold_per2.length - 1));
                  let thresh2=prod_data['length_threshold_abs2']?parseFloat(prod_data['length_threshold_abs2']):standard_length*(1+(length_threshold_per2/100));
                  thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                  let result = getResult(criteria,thresh1,thresh2,sample_length)
                  let weightage=(prod_data['length_weightage_per']!=undefined?prod_data['length_weightage_per']:"10%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));
                  index_score_sum=index_score_sum+(score*weightage);

                  ele['Threshold Range 2']=thresh2;
                  ele['Score']=score+'%';
                  ele['Weightage']=weightage;
                  ele['Result']=result;
                  console.log(product_id+" ::Length:: "+result);
            }else if(parameterList[i]=='Avg Width (mm)'){
                  let width_threshold_per=(prod_data['width_threshold_per']!=undefined?prod_data['width_threshold_per']:"3%");
                  width_threshold_per = parseInt(width_threshold_per.substring(0, width_threshold_per.length - 1));
                  let standard_width=prod_data['width'];
                  standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));
                  let thresh1=prod_data['width_threshold_abs']?parseFloat(prod_data['width_threshold_abs']):standard_width*(1+(width_threshold_per/100));
                  thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                  let sample_width=updateDetails['Width'];
                  sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));
                  let criteria;
                  let score;
                  if(prod_data['sub_category'].toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati") ||  updateDetails.sub_category.toLowerCase().includes("wheat")){
                    criteria="Higher";
                    score=sample_width/standard_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }else{
                    criteria="Lower";
                    score=standard_width/sample_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }

                  ele['Sample Value']=updateDetails['Width'];
                  ele['Standard Value']=prod_data['width'];
                  ele['Criteria']=criteria;


                  ele['Threshold Range 1']=thresh1;

                  let width_threshold_per2=(prod_data['width_threshold_per2']!=undefined?prod_data['width_threshold_per2']:"5%");
                  width_threshold_per2 = parseInt(width_threshold_per2.substring(0, width_threshold_per2.length - 1));
                  let thresh2=prod_data['width_threshold_abs2']?parseFloat(prod_data['width_threshold_abs2']):standard_width*(1+(width_threshold_per2/100));
                  thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                  let result = getResult(criteria,thresh1,thresh2,sample_width)


                  let weightage=(prod_data['width_weightage_per']!=undefined?prod_data['width_weightage_per']:"35%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));
                  index_score_sum=index_score_sum+(score*weightage);

                  ele['Threshold Range 2']=thresh2;
                  ele['Score']=score+'%';
                  ele['Weightage']=weightage;
                  ele['Result']=result;
                  console.log(product_id+" ::Width:: "+result);
            }else if(parameterList[i]=='LW Ratio'){
                  let length_width_threshold_per=(prod_data['length_width_threshold_per']!=undefined?prod_data['length_width_threshold_per']:"3%");
                  length_width_threshold_per = parseInt(length_width_threshold_per.substring(0, length_width_threshold_per.length - 1));
                  let standard_length=prod_data['length'];
                  standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                  let standard_width=prod_data['width'];
                  standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));

                  let standard_length_width=standard_length/standard_width;  // TO BE DONE ---------------------------------------------------------------------------
                  standard_length_width = Math.round((standard_length_width + Number.EPSILON) * 100) / 100;
                  let thresh1=prod_data['length_width_threshold_abs']?parseFloat(prod_data['length_width_threshold_abs']):standard_length_width*(1+(length_width_threshold_per/100));
                  thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                  let sample_length=updateDetails['Length'];
                  sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));

                  let sample_width=updateDetails['Width'];
                  sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));

                  let sample_length_width=sample_length>sample_width?parseFloat(sample_length/sample_width):parseFloat(sample_width/sample_length);                  
                  let criteria;
                  let score;
                  if(updateDetails.sub_category.toLowerCase().includes("wheat")){
                    criteria="Lower";
                    score=standard_length_width/sample_length_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }else{
                    criteria="Higher";
                    score=sample_length_width/standard_length_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }

                  ele['Sample Value']=updateDetails['LW Ratio'];
                  ele['Standard Value']=standard_length_width;
                  ele['Criteria']=criteria;
                  ele['Threshold Range 1']=thresh1;

                  let length_width_threshold_per2=(prod_data['length_width_threshold_per2']!=undefined?prod_data['length_width_threshold_per2']:"5%");
                  length_width_threshold_per2 = parseInt(length_width_threshold_per2.substring(0, length_width_threshold_per2.length - 1));
                  let thresh2=prod_data['length_width_threshold_abs2']?parseFloat(prod_data['length_width_threshold_abs2']):standard_length_width*(1+(length_width_threshold_per2/100));
                  thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                  let result = getResult(criteria,thresh1,thresh2,sample_length_width)

                  let weightage=(prod_data['length_width_weightage_per']!=undefined?prod_data['length_width_weightage_per']:"40%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));
                  index_score_sum=index_score_sum+(score*weightage);

                  ele['Threshold Range 2']=thresh2;
                  ele['Score']=score+'%';
                  ele['Weightage']=weightage;
                  ele['Result']=result;
                  console.log(product_id+" ::LW Ratio:: "+result);
            }else if(parameterList[i]=='Broken %'){
                  let broken_threshold_per=(prod_data['broken_threshold_per']!=undefined?prod_data['broken_threshold_per']:"5%");
                  broken_threshold_per = parseFloat(broken_threshold_per.substring(0, broken_threshold_per.length - 1));
                  let standard_broken_perc=prod_data['broken_grain_percentage'];
                  standard_broken_perc = parseFloat(standard_broken_perc.substring(0, standard_broken_perc.length - 1));
                  let thresh1=broken_threshold_per;

                  let sample_broken_perc=updateDetails['BrokenGrainPerc'];
                  sample_broken_perc = parseFloat(sample_broken_perc.substring(0, sample_broken_perc.length - 1));
                  let criteria="Lower";;
                  let score=sample_broken_perc >0?standard_broken_perc/sample_broken_perc:1;
                  score=Math.round((score + Number.EPSILON) * 100);

                  ele['Sample Value']=updateDetails['BrokenGrainPerc'];
                  ele['Standard Value']=prod_data['broken_grain_percentage'];
                  ele['Criteria']=criteria;
                  ele['Threshold Range 1']=thresh1;

                  let broken_threshold_per2=(prod_data['broken_threshold_per2']!=undefined?prod_data['broken_threshold_per2']:"20%");
                  broken_threshold_per2 = parseFloat(broken_threshold_per2.substring(0, broken_threshold_per2.length - 1));
                  let thresh2=broken_threshold_per2;
                  let result = getResult(criteria,thresh1,thresh2,sample_broken_perc)

                  let weightage=(prod_data['broken_weightage_per']!=undefined?prod_data['broken_weightage_per']:"15%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));

                  if(updateDetails.sub_category.toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati")){
                    ele['Criteria']=criteria;
                    ele['Score']='N/A';
                    ele['Weightage']='N/A';
                    ele['Result']='N/A';
                  }else{
                    ele['Criteria']=criteria;
                    index_score_sum=index_score_sum+(score*weightage);
                    ele['Score']=score+'%';
                    ele['Weightage']=weightage;
                    ele['Result']=result;
                  }
                  console.log(product_id+" ::Broken %:: "+result);
            }
            finalProdList.push(ele);
          }
          // console.log(finalProdList);
          let overallResult;
          let accept_counter=0;
          let warn_counter=0;
          let reject_counter=0;
          for(let x=0;x<finalProdList.length;x++){
            if(!(finalProdList[x]['Parameter']=="Broken %" && (updateDetails.sub_category.toLowerCase().includes("basmati") || updateDetails['Product Name'].toLowerCase().includes("basmati")))){
              let orig_result=finalProdList[x]['Result'];
              if(orig_result=="Accept"){
                accept_counter=accept_counter+1;
              }else if(orig_result=="Warn"){
                warn_counter=warn_counter+1;
              }else if(orig_result=="Reject"){
                reject_counter=reject_counter+1;
              }
            }
          }

          if(updateDetails.sub_category.toLowerCase().includes("basmati")  || updateDetails['Product Name'].toLowerCase().includes("basmati")){
            if(reject_counter>0){
              overallResult="Reject";
            }else if(accept_counter>1){
              overallResult="Accept";
            }else{
              overallResult="Warn";
            }
          }else{
            if(reject_counter>0){
              overallResult="Reject";
            }else if(accept_counter>2){
              overallResult="Accept";
            }else{
              overallResult="Warn";
            }
          }


          index_score_sum=index_score_sum/100;
          index_score_sum=Math.round((index_score_sum + Number.EPSILON) * 100) / 100;
          // console.log('index_score_sum'+index_score_sum);

          // setModalHeaderContent({'overall_index_score':index_score_sum+'%','overall_result':overallResult,'sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name']});
          // setGrtProdData(finalProdList);
          let updateOrigGrtSampleRowValues=updateDetails;
          updateOrigGrtSampleRowValues['Index Score']=index_score_sum+'%';
          updateOrigGrtSampleRowValues['Result']=overallResult;
          return [index_score_sum+'%',overallResult];
          // props.callback(updateOrigGrtSampleRowValues);
        }else{
          console.log('invalid product id');
          return ['N/A','N/A'];
          // setModalHeaderContent({'overall_index_score':'','overall_result':'','sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name']});
          // setGrtProdData([]);
        }
    }else{
      //product_id is not available
      // setModalHeaderContent({'overall_index_score':'N/A','overall_result':'N/A','sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name']});
      let defaultProdList=[];
      for(let i=0;i<4;i++){
        let ele={};
        ele['Parameter']=parameterList[i];
        //['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %']
        if(parameterList[i]=='Avg Length (mm)'){
          ele['Sample Value']=updateDetails['Length'];
        }else if(parameterList[i]=='Avg Width (mm)'){
          ele['Sample Value']=updateDetails['Width'];
        }else if(parameterList[i]=='LW Ratio'){
          ele['Sample Value']=updateDetails['LW Ratio'];
        }else if(parameterList[i]=='Broken %'){
          ele['Sample Value']=updateDetails['BrokenGrainPerc'];
        }

        ele['Standard Value']='N/A';
        ele['Threshold Range 1']='N/A';
        ele['Threshold Range 2']='N/A';
        ele['Score']='N/A';
        ele['Result']='N/A';
        defaultProdList.push(ele);
        return ['N/A','N/A'];
        // setGrtProdData(defaultProdList);
      }
    }
  }

  const handleChange = (selecteddate) => {
    setProductDetails([]);
    setSearchProductDetails([]);
    setLoading(true);
    setTestDate(new Date(selecteddate));
    fetchProducts(selecteddate,testEndDate);
  }

  const handleEndDateChange = (selecteddate) => {
    setProductDetails([]);
    setSearchProductDetails([]);
    setLoading(true);
    setTestEndDate(new Date(selecteddate));
    fetchProducts(testDate,selecteddate);
  }

  const CustomDateInput = ({ value, onClick }) => (
    <input
        value={value}
        onClick={onClick}
        style={{ border: "solid 1px black", height: "30px" }}
    />
);

  const openProductList = () => {
    window.open("#/grtproducts");
  };

  const exportGrtDetails = () => {
      const wb = XLSX.utils.book_new()

      let grtSampleSummary = [["Testing Date", "Sample ID", "Test Type", "Category", "Product Code","Product Name", "Index Score", "Result"]]

      productDetails.map(item => {
        let grtSampleDetailsPerItem = [];
        grtSampleDetailsPerItem.push(item['Testing Date'])
        grtSampleDetailsPerItem.push(item['Sample ID'])
        grtSampleDetailsPerItem.push(item['Test Type'])
        grtSampleDetailsPerItem.push(item['Category'])
        grtSampleDetailsPerItem.push(item['Product Code'])
        grtSampleDetailsPerItem.push(item['Product Name'])
        grtSampleDetailsPerItem.push(item['Index Score'])
        grtSampleDetailsPerItem.push(item['Result'])
        grtSampleSummary.push(grtSampleDetailsPerItem)
      })
      let grtSampleExportDetails = XLSX.utils.aoa_to_sheet(grtSampleSummary)
      let wscols = [
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:35},
          {wch:15},
          {wch:15}
      ];

      grtSampleExportDetails['!cols'] = wscols;
      XLSX.utils.book_append_sheet(wb, grtSampleExportDetails, `Grt Test Sample List`)
      XLSX.writeFile(wb, `Grt_Test_Sample_List.xlsx`)
      setExportData(false)
  }

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
    <NavBar />
    <Form inline style={{margin:30, width:'90%', marginLeft: "auto",marginRight: "auto", paddingTop:100}}>
        <InputGroup size="sm" style = {{ margin : 5}}>
          <InputGroupAddon addonType="prepend">Start Date</InputGroupAddon>
                <DatePicker
                    selected={testDate}
                    onChange={(handleChange)}
                    minDate={new Date("2017/01/01")}
                    maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                />
        </InputGroup>
        <InputGroup size="sm" style = {{ margin : 5}}>
          <InputGroupAddon addonType="prepend">End Date</InputGroupAddon>
                <DatePicker
                    selected={testEndDate}
                    onChange={(handleEndDateChange)}
                    minDate={new Date("2017/01/01")}
                    maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                />
        </InputGroup>
        <div style={{ marginLeft: "auto"}}>
          <Button style = {{ margin:10}} variant="success" onClick={() => { openProductList() }}>Products List</Button>
          <Link variant="success" style={{ color: "#fff", backgroundColor: "#28a745", padding: "0.55rem 0.75rem", borderRadius:"0.25rem" }} to={{ pathname: '/grtProductsAnalysis', state: { itemGRTAnalyse: itemAnalysedData && itemAnalysedData } }} >GRT Insights</Link>
          <Button style={{ margin:10}} variant="warning" onClick={() => setExportData(true)}>Export</Button>
        </div>
    </Form>
        <Form.Control type="search" style={{margin:10, width:'50%', marginLeft: "auto",marginRight: "auto"}} placeholder="SampleID / Product Code / Product Name" value={searchText} onChange={(e)=>{ handleKeyPress(e); }}/>
    {(Object.keys(productDetails).length>0) ? <GrtListTable productDetailsListData={productDetails} /> : ((loading)? ((searchText)?(<div style={{margin:40}}>No test data match the search</div>):<LoaderComp />):(<div style={{margin:40}}>No test data present</div>)) }
    </>
  );
};

export default GrtList;
