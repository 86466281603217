import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Card, Table, Button, Form, ButtonGroup, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import database from '../../service/firebaseConfig';
import { toast } from 'react-toastify';
import EditStockTake from './EditStockTake';
import UserEmail from '../../CommonRequirements/FetchUserEmailId'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { validateIsAdminUser, validatePurchaseUser, validateStockUser, validateDCManagerUser, validatePurchaseExportOnlyUser } from '../../service/credentials';
import Backdrop from '../../CommonRequirements/backdrop'
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import ExportStockTake from './ExportStockTake'
import { Link } from 'react-router-dom'
import XLSX from 'xlsx'
import baseUrl from '../../service/servicesConfig';


const StockTakeDatabase = database.database().ref(`Product_Stock_Take`)

function ListStockTake() {

    const [getDate, setDate] = useState(new Date())
    const [dataRender, setDataRender] = useState(false)
    const [productEditTable, setProductEditTable] = useState([])
    const [modifiedDate, setModifiedDate] = useState("")
    const [modifiedMonth, setModifiedMonth] = useState("")
    const [renderAllDetails, setRenderAllDetails] = useState(false)
    const [editStockTakeConfirm, setEditStockTakeConfirm] = useState(false)
    const [viewStockTake, setViewStockTake] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState("")
    const [exportDetails, setExportDetails] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [stockTakeDetails, setStockTakeDetails] = useState("")
    const [itemCodeList, setItemCodeList] = useState("")
    const [stockTakeFileData, setStockTakeFileData] = useState([]);


    const currentDate = sessionStorage.getItem("datekey") ? new Date(sessionStorage.getItem("datekey")) : new Date()
    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    let filterResult = ""

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyDetails = (success) => {
        if (success) {
            console.log(getDate)
            return toast(`No details available for ${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`, { type: toast.TYPE.ERROR });
        }
    };
    const handleChange = (date) => {

        setBackDrop(true)
        if (!date) {
            setDate("")
            return;
        }
        setDate(date)
        console.log(date)
        setDataRender(true)
        let dateSelected = date.getDate();
        let monthSelected = date.getMonth() + 1;

        if (dateSelected < 10)
            dateSelected = '0' + dateSelected;
        if (monthSelected < 10)
            monthSelected = '0' + monthSelected;
        setModifiedDate(dateSelected);
        setModifiedMonth(monthSelected);
        sessionStorage.setItem("datekey", date)
        window.location.reload()
    }
    useEffect(() => {

        setBackDrop(true)
        let pushTableDetails = []
        let updateItemsKeys = []
        let newItemKeysArray = []
        let grnDetailsCheck = []
        let sessionDate
        let filterDate
        let sessionYr
        let sessionMn
        let sessionDt
        if (sessionStorage.getItem(`datekey`)) {

            sessionDate = sessionStorage.getItem(`datekey`)
            filterDate = new Date(sessionDate)
            setDate(filterDate)
            sessionYr = filterDate.getFullYear();
            sessionMn = filterDate.getMonth() + 1;
            if (sessionMn < 10)
                sessionMn = "0" + sessionMn
            sessionDt = filterDate.getDate();
            if (sessionDt < 10)
                sessionDt = "0" + sessionDt;
        } else {
            handleChange(new Date());
        }

        const complete_date = `${sessionYr}-${sessionMn}-${sessionDt}`;
        const complete_date_2 = `${sessionDt}-${sessionMn}-${sessionYr}`;

        console.log(`${complete_date} ${complete_date_2} ${sessionStorage.getItem('datekey')}`);

        const getUrl = `/api/superzop/commercial/productstocktake/getproductstocktakebasesonstockdate?start_date=${complete_date}&end_date=${complete_date}`;

        baseUrl.get(getUrl).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {

            if (data.length > 0) {
                console.log('response data: ', data);
                setItemCodeList(data);

                pushTableDetails.push(...data);

                pushTableDetails = pushTableDetails.map((item) => {

                    if (item.unique_key)
                        item.uniqueKey = item.unique_key;
                    else
                        item.uniqueKey = "";
                    return item;
                });

                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

            } else {

                return toast(`No details available for ${complete_date_2}`, { type: toast.TYPE.ERROR })
            }
        }).then(() => {
            setProductEditTable(pushTableDetails);
            setFilterList(pushTableDetails)
            console.log(pushTableDetails)
            setRenderAllDetails(true)
            setBackDrop(false)
        });

        /*StockTakeDatabase.child(`${sessionDate?sessionYr:getDate.getFullYear()}/${sessionDate?sessionMn:((getDate.getMonth())+1)<10?"0"+(getDate.getMonth()+1):(getDate.getMonth()+1)}/${sessionDate?sessionDt:getDate.getDate()<10?"0"+getDate.getDate():getDate.getDate()}-${sessionDate?sessionMn:((getDate.getMonth())+1)<10?"0"+(getDate.getMonth()+1):(getDate.getMonth()+1)}-${sessionDate?sessionYr:getDate.getFullYear()}`).once("value", snapShot => {
            if(snapShot.val()){

                const stockTakeData = snapShot.val()
                setItemCodeList(snapShot.val())
                
                Object.keys(stockTakeData).forEach(itemKey => {
                    stockTakeData[itemKey].uniqueKey = itemKey;
                    pushTableDetails.push(
                                {"uniqKey":itemKey,...stockTakeData[itemKey]}
                            )

                    })
                    pushTableDetails.sort((a,b)=>{
                        if (a.created_at < b.created_at) {
                            return -1;
                        } else if (a.created_at> b.created_at) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
            }
            else{return toast(`No details available for ${currentDate.getDate()}-${currentDate.getMonth()+1}-${currentDate.getFullYear()}`, { type: toast.TYPE.ERROR })}
        }).then(()=>{setProductEditTable(pushTableDetails); 
            setFilterList(pushTableDetails)
            console.log(pushTableDetails)
            setRenderAllDetails(true)
            setBackDrop(false)})*/
    }, [])

    const searchPurchaseList = () => {
        const filterData = []
        console.log(productEditTable)
        filterResult = Object.keys(productEditTable).filter(obj => {

            const itemId = productEditTable[obj].article_code.trim().toLowerCase();
            const itemDesc = productEditTable[obj].item_name && productEditTable[obj].item_name.trim().toLowerCase() || "";

            setSearchText(searchText.trim().toLowerCase());

            return itemId.match(searchText) || itemDesc.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(productEditTable[items])
        })
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchPurchaseList()
    }, [searchText])

    const listPurchaseDetails = () => {

        StockTakeDatabase.child(`${getDate.getFullYear()}/${modifiedMonth}/${modifiedDate}-${modifiedMonth}-${getDate.getFullYear()}`).once("value", snapShot => {
            if (snapShot.val()) {

                const stockTakeDetails = snapShot.val()
                setItemCodeList(snapShot.val())
                const pushTableDetails = []
                let updateItemsKeys = []
                let newItemKeysArray = []

                Object.keys(stockTakeDetails).forEach(items => {

                    updateItemsKeys.push({ "uniqueKey": items, "articleCode": stockTakeDetails[items].article_code })

                    console.log(items)
                    pushTableDetails.push(
                        stockTakeDetails[items]
                    )
                })
                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                pushTableDetails.forEach((items, index) => {
                    updateItemsKeys.forEach((uniqKey, uniqIndex) => {

                        if (uniqKey.articleCode === items.article_code) {
                            newItemKeysArray.push(uniqKey.uniqueKey)
                            updateItemsKeys.splice(uniqIndex, 1)
                        }
                    })
                })
                setProductEditTable(pushTableDetails)
                setFilterList(pushTableDetails)
                setRenderAllDetails(true)
            }
            else {
                setRenderAllDetails(false)
                notifyDetails(true)
            }
        }).then(() => { setDataRender(false); setBackDrop(false) })
    }

    // useEffect(() => {
    //     if (dataRender)
    //         listPurchaseDetails()
    // }, [dataRender])



    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );


    const openAddStockTake = () => {
        window.open("#/addStockTake");
    };

    const deleteStockTakeItem = (itemToDelete, article_code, actual_stock) => {

        console.log('itemToDelete: ', itemToDelete, ' article_code: ', article_code, ' actual_stock: ', actual_stock);

        const answer = window.confirm("Are you sure you want to delete?")
        if (answer) {

            const url = '/api/superzop/commercial/productstocktake/addeditstocktakeandsi?type=delete';

            let stock_date = itemToDelete.substring(0, 10);

            const stock_date_date = stock_date.split("-")[0];
            const stock_date_month = stock_date.split("-")[1];
            const stock_date_year = stock_date.split("-")[2];

            stock_date = `${stock_date_year}-${stock_date_month}-${stock_date_date}`;

            //console.log('stock_date: ',stock_date);

            const obj = {
                "itemToDelete": itemToDelete,
                "stock_date": stock_date,
                "article_code": article_code,
                "actual_stock": actual_stock
            }

            baseUrl.post(url, obj).then(res => {

                //console.log('deleteStockTakeItem ', res);
                toast(`Deleted item successfully`, { type: toast.TYPE.SUCCESS });
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }).catch(error => {

                console.error(error);
            });


            /*const sqlProm = new Promise((resolve, reject) => {

                const url = `/api/superzop/commercial/productdump/deleteproductstocktake?key=${itemToDelete}`;
                baseUrl.delete(url).then(res => {

                    console.log('deleteStockTakeItem ', res);
                    //toast(`Deleted item successfully`, { type: toast.TYPE.SUCCESS });
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 2000);
                    resolve("Success");
                }).catch(error => {

                    console.error(error);
                    reject(error);
                });
            });

            const firebaseProm = new Promise((resolve, reject) => {

                const stockDateDel = itemToDelete.substring(0, 10);
                const itemIdDel = itemToDelete.substring(11);

                StockTakeDatabase.child(`${stockDateDel.split("-")[2]}/${stockDateDel.split("-")[1]}/${stockDateDel}/${itemIdDel}`).remove()
                    .then(() => resolve("Success"))
                    .catch(error => {

                        console.error(error);
                        reject(error);
                    });

                //console.log('stockDateDel: ',stockDateDel,' itemIdDel: ',itemIdDel);
            });

            Promise.all([firebaseProm, sqlProm]).then(() => {

                toast(`Deleted item successfully`, { type: toast.TYPE.SUCCESS });
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }).catch((error) => {
            });*/

        }

    }

    const syncSystemInventory = () => {
        //console.log(filterList);

        const stkTakItemLen = filterList.length;
        if (stkTakItemLen != 0) {
            const req_arr = []

            const currentDateObj = new Date();

            const currYear = currentDateObj.getFullYear();
            const currMonth = currentDateObj.getMonth().toString().length === 1 ? `0${currentDateObj.getMonth() + 1}` : currentDateObj.getMonth() + 1;
            const currDate = currentDateObj.getDate().toString().length === 1 ? `0${currentDateObj.getDate()}` : currentDateObj.getDate();

            const todays_date = `${currYear}-${currMonth}-${currDate}`;
            const item_ids = [];
            let stock_date;
            filterList.forEach(item => {

                const item_id = item.article_code;
                stock_date = item.stock_date.substring(0, 10);

                //console.log("item_id: ",item_id," stock_date: ",stock_date);

                item_ids.push(item_id);

                /*const promise = new Promise((resolve, reject) => {

                    const url = `/api/superzop/systeminventory/savesysteminventoryintotable?start_date=${stock_date}&end_date=${todays_date}&item_id=${item_id}`;

                    baseUrl.post(url).then(() => {
                        resolve("Success");
                    }).catch(err => {
                        reject(err);
                    })
                })
                req_arr.push(promise);*/
            });

            const url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${stock_date}&end_date=${todays_date}`;

            const body = {
                "item_ids": item_ids
            }
            /*Promise.all(req_arr).then(() => {

                toast(`Synced System Inventory Successfully`, { type: toast.TYPE.SUCCESS });
            }).catch((error) => {
                toast(`Failed to Sync`, { type: toast.TYPE.ERROR });
            });*/

            baseUrl.post(url, body).then((res) => {

                console.log("res: ", res);
                if (res.status == 200)
                    toast(`Synced System Inventory Successfully`, { type: toast.TYPE.SUCCESS });
                else
                    toast(`Failed to Sync`, { type: toast.TYPE.ERROR });
            }).catch(err => {
                toast(`Failed to Sync`, { type: toast.TYPE.ERROR });
            })
        } else {
            toast(`No Items to Sync`, { type: toast.TYPE.ERROR });
        }
    }

    const handleStockTakeFile = (e) => {

        const stock_take_file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            //console.log('uploadBulkStocKTake', ws);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            //console.log("Data>>>" + data);
            setStockTakeFileData(data);
        };

        if (stock_take_file)
            reader.readAsBinaryString(stock_take_file);

    }

    const uploadBulkStocKTake = () => {

        console.log('uploadBulkStocKTake', stockTakeFileData);

        let stockTakeFileDataArr = stockTakeFileData;

        const stockTakeFileLen = stockTakeFileDataArr.length;

        if (stockTakeFileLen != 0) {

            const headingsArr = stockTakeFileDataArr[0];
            console.log('headingsArr', headingsArr);
            const validate = validateStockFileDataHeadings(headingsArr);

            if (validate) {

                stockTakeFileDataArr.splice(0, 1);


                const createStkTakProm = []
                stockTakeFileDataArr.forEach(stockTakeItem => {

                    console.log('stockTakeItem', stockTakeItem);

                    const unique_key = `${stockTakeItem[12]}-${stockTakeItem[4]}`;

                    const obj = {
                        "stock_date": stockTakeItem[12],
                        "category": stockTakeItem[5],
                        "article_code": stockTakeItem[4],
                        "sub_category": stockTakeItem[14],
                        "UOM": stockTakeItem[0],
                        "item_name": stockTakeItem[9],
                        "outer_size": stockTakeItem[11],
                        "system_inventory": stockTakeItem[15],
                        "inventory_outers": stockTakeItem[8],
                        "actual_stock_bag_box": stockTakeItem[2],
                        "loose_stock": stockTakeItem[10],
                        "actual_stock": stockTakeItem[1],
                        "stock_loss_gain": stockTakeItem[13],
                        "created_by": stockTakeItem[7],
                        "unique_key": unique_key
                    }

                    const promObj = new Promise((resolve, reject) => {

                        const postUrl = `/api/superzop/commercial/productstocktake/createproductstocktakerecord`;

                        baseUrl.post(postUrl, obj).then(() => {
                            resolve("Success");
                        }).catch(error => {
                            reject(error);
                        });
                    });

                    createStkTakProm.push(promObj);
                });

                Promise.all(createStkTakProm).then(() => {

                    toast("Uploaded Successfully", { type: toast.TYPE.SUCCESS });
                }).catch(error => {
                    toast("Failed to upload", { type: toast.TYPE.ERROR });
                })

            } else {

                toast("Please upload with a valid Stock Take Template", { type: toast.TYPE.ERROR });
            }

            //console.log('validate: ', validate);
        }
    }

    const validateStockFileDataHeadings = (headingsArr) => {

        const haystack = ["UOM", "actual_stock", "actual_stock_bag_box", "approve_stock_loss_gain", "article_code", "category", "created_at", "created_by", "inventory_outers", "item_name", "loose_stock", "outer_size", "stock_date", "stock_loss_gain", "sub_category", "system_inventory"];
        // for (let i = 0; i < headingsArr.length; i++) {
        //     if ($.inArray(needles[i], haystack) == -1) return false;
        // }
        // return true;

        const res = headingsArr.every(val => {
            return haystack.includes(val);
        });

        return res;
    }

    return (
        <>
            <NavBar />
            <Navbar className="bg-light mr-auto justify-content-between" style={{ paddingTop: 100 }}>
                <Form inline>
                    <Badge variant="dark">Select Stock Take Date </Badge>
                    <DatePicker
                        selected={getDate}
                        onChange={(handleChange)}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        className="red-border"
                        customInput={<CustomDateInput />}
                    />
                    {productEditTable.length > 0 && <InputGroup style={{ marginLeft: "20px" }}>
                        <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Item Name " value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                    </InputGroup>}
                </Form>
                {(validatePurchaseUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <Button size="sm" variant="success" onClick={() => { openAddStockTake() }}>Add Stock Take</Button> : null}
                {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <Link to={"/stockTakeReport"}><Button size={"sm"} variant={"warning"} style={{ marginRight: "10px", marginLeft: "10px" }}>Report</Button></Link> : null}
                {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <Button size={"sm"} variant={"warning"} onClick={() => { setExportDetails(true) }} style={{ marginRight: "10px", marginLeft: "10px" }}>Export | {`${getDate.getDate()}-${getDate.getMonth() + 1}-${getDate.getFullYear()}`}</Button> : null}
                {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? <Button size={"sm"} variant={"warning"} onClick={() => syncSystemInventory()}>Sync Inventory | {`${getDate.getDate()}-${getDate.getMonth() + 1}-${getDate.getFullYear()}`}</Button> : null}
                {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? <Link to={"/uploadstocktake"}><Button size={"sm"} variant={"warning"} >Bulk Upload</Button></Link> : null}

            </Navbar>
            {/* <Navbar className="bg-light mr-auto justify-content-between" >
                <Form inline>
                    <Form.Group controlId="formFileLg" className="mb-3" style={{ width: '20em' }}>
                        <Form.Label style={{ fontWeight: "bold" }}>Bulk Product Stock Take Upload</Form.Label>
                        <Form.Control type="file" size="lg" onChange={handleStockTakeFile} />
                    </Form.Group>
                    <Button size="sm" variant="dark" onClick={() => { uploadBulkStocKTake() }}>Upload</Button>
                </Form>
            </Navbar> */}
            <br /><br />
            {
                renderAllDetails ?
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <td>
                                    <center>Stock Date</center>
                                </td>
                                <td>
                                    <center>Item ID</center>
                                </td>
                                <td>
                                    <center>Item Name</center>
                                </td>
                                <td>
                                    <center>Category</center>
                                </td>
                                <td>
                                    <center>Sub Category</center>
                                </td>
                                <td>
                                    <center>Outer Size (Kg/Pc)</center>
                                </td>
                                <td>
                                    <center>UOM</center>
                                </td>
                                <td>
                                    <center>System Inventory (Kg/Pc)</center>
                                </td>
                                <td>
                                    <center>Actual Stock in Bag / Box</center>
                                </td>
                                <td>
                                    <center>Loose Stock (Kg/Pc)</center>
                                </td>
                                <td>
                                    <center>Actual Stock (Kg/Pc)</center>
                                </td>
                                <td>
                                    <center>Stock Loss / Gain (Kg/Pc) </center>
                                </td>
                                {/* {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                    <center>Approved Stock Loss / Gain (Kg/Pc) </center>
                                </td>} */}
                                <td>
                                    <center> X </center>
                                </td>
                                {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                    <center> X </center>
                                </td>}
                            </tr>
                        </thead>
                        <tbody>
                            {renderAllDetails ? filterList ?
                                Object.keys(filterList).map((items, index) => (
                                    <>
                                        <tr>
                                            <td><center>{filterList[items].stock_date && `${filterList[items].stock_date.split("-")[2].split("T")[0]}-${monthList[filterList[items].stock_date.split("-")[1] - 1]}-${filterList[items].stock_date.split("-")[0]}`} </center></td>
                                            <td><center>{filterList[items].article_code} </center></td>
                                            <td><center>{filterList[items].item_name} </center></td>
                                            <td><center>{filterList[items].category} </center></td>
                                            <td><center>{filterList[items].sub_category} </center></td>
                                            <td><center>{filterList[items].outer_size} </center></td>
                                            <td><center>{filterList[items].UOM} </center></td>
                                            <td><center>{Number(filterList[items].system_inventory).toFixed(2)} </center></td>
                                            {/* <td><center>{filterList[items].outer_size ? (Number(filterList[items].system_inventory) / Number(filterList[items].outer_size)).toFixed(2) : 0} </center></td> */}
                                            <td><center>{filterList[items].actual_stock_bag_box} </center></td>
                                            <td><center>{filterList[items].loose_stock} </center></td>
                                            <td><center>{filterList[items].actual_stock} </center></td>
                                            <td><center>{Number(filterList[items].approve_stock_loss_gain != null ? filterList[items].approve_stock_loss_gain : filterList[items].stock_loss_gain).toFixed(2)} </center></td>
                                            {/* {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) &&
                                                <td><center> {filterList[items].approve_stock_loss_gain != null && Number(filterList[items].approve_stock_loss_gain).toFixed(2)}  </center></td>} */}
                                            {(validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                                (filterList[items].authorized_by) ?
                                                    <td><center><Button variant='success' onClick={() => { setEditStockTakeConfirm(true); setStockTakeDetails(filterList[items]) }}>Edit</Button></center>
                                                    </td> : <td><center><Button variant='secondary' onClick={() => { setEditStockTakeConfirm(true); setStockTakeDetails(filterList[items]) }}>Edit</Button></center>
                                                    </td> :
                                                (filterList[items].authorized_by) ? <td><center><Button variant='success' onClick={() => { setViewStockTake(true) }}>X</Button></center>
                                                </td> : <td><center><Button variant='secondary' onClick={() => { setEditStockTakeConfirm(true); setStockTakeDetails(filterList[items]) }}>Edit</Button></center></td>}
                                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) &&
                                                <td><Button onClick={() => deleteStockTakeItem(filterList[items].uniqueKey, filterList[items].article_code, filterList[items].actual_stock)}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>}
                                        </tr>
                                    </>))
                                :
                                Object.keys(productEditTable).map((items, index) => (
                                    <>
                                        <tr>
                                            <td><center>{productEditTable[items].article_code} </center></td>
                                            <td><center>{productEditTable[items].item_name} </center></td>
                                            <td><center>{productEditTable[items].category} </center></td>
                                            <td><center>{productEditTable[items].sub_category} </center></td>
                                            <td><center>{productEditTable[items].outer_size} </center></td>
                                            <td><center>{productEditTable[items].UOM} </center></td>
                                            <td><center>{Number(productEditTable[items].system_inventory).toFixed(2)} </center></td>
                                            {/* <td><center>{Number(productEditTable[items].inventory_outers).toFixed(2)} </center></td> */}
                                            <td><center>{productEditTable[items].actual_stock_bag_box} </center></td>
                                            <td><center>{productEditTable[items].loose_stock} </center></td>
                                            <td><center>{productEditTable[items].actual_stock} </center></td>
                                            <td><center>{Number(productEditTable[items].approve_stock_loss_gain != null ? productEditTable[items].approve_stock_loss_gain : productEditTable[items].stock_loss_gain).toFixed(2)} </center></td>
                                            {(validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                                (productEditTable[items].authorized_by) ?
                                                    <td><center><Button variant='success' onClick={() => { setEditStockTakeConfirm(true); setStockTakeDetails(productEditTable[items]) }}>Edit</Button></center>
                                                    </td> : <td><center><Button variant='secondary' onClick={() => { setEditStockTakeConfirm(true); setStockTakeDetails(productEditTable[items]) }}>Edit</Button></center>
                                                    </td> : (filterList[items].authorized_by) ? <td><center><Button variant='success' onClick={() => { setViewStockTake(true) }}>X</Button></center>
                                                    </td> : <td><center><Button variant='secondary' onClick={() => { setEditStockTakeConfirm(true); setStockTakeDetails(productEditTable[items]) }}>Edit</Button></center></td>}
                                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) &&
                                                <td><Button onClick={() => deleteStockTakeItem(productEditTable[items].uniqueKey, filterList[items].article_code, filterList[items].actual_stock)}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>}
                                        </tr>
                                    </>)) : ""}
                        </tbody>
                    </Table> : ""
            }

            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}


            {
                editStockTakeConfirm && <EditStockTake
                    show={editStockTakeConfirm}
                    onHide={() => setEditStockTakeConfirm(false)}
                    data={stockTakeDetails}
                    dateToEdit={sessionStorage.getItem("datekey") ? sessionStorage.getItem("datekey") : new Date()}
                />
            }
            {/* {viewStockTake && <ViewStockTakeModal
                show = {viewStockTake}
                onHide = {()=> setViewStockTake(false)}
            />} */}
            {
                exportDetails && <ExportStockTake size="sm"
                    listedStockTake={productEditTable}
                    multipleExport={setExportDetails}
                    exportDate={getDate}
                />
            }
        </>
    )
}

export default ListStockTake
