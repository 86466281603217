import React ,{useState,useEffect}from 'react'
import { Form,Modal,Button, Table } from 'react-bootstrap'
import axios from 'axios';
import { OFFER_BASE_URL } from '../../service/servicesConfig';

function EditItem({...props}){
    console.log(props)
const [editedItem,setEditedItem] = useState("");
const [quantity,setQuantity] = useState("");

console.log(editedItem)
console.log(quantity)

const updateEditedItem = () => {
    const url = `${OFFER_BASE_URL}/stock-management?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
    axios.put(url, {
        item_id: props.itemIdToEdit,
        qty: quantity
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
}

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit item {props.itemIdToEdit}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    {/* <tr> */}
                        {/* <td>Enter the item Id</td> */}
                        {/* <td><Form.Control   /></td> */}
                    {/* </tr> */}
                    <tr>
                        <td>Enter quantity</td>
                        <td><Form.Control onChange={(e)=>setQuantity(e.target.value)} /></td>
                    </tr>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() =>{updateEditedItem(); props.editItem(true); props.onHide()}} >Update Item</Button>
                <Button onClick={props.onHide} >Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default EditItem;