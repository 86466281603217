import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  Badge,
  Form,
  Dropdown,
  Navbar,
  Jumbotron,
  InputGroup,
  Button,
  DropdownButton,
  ButtonGroup,
  Table,
} from "react-bootstrap";
import { InputGroupAddon } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import ExportReport from "./ExportPurchaseDetails";
import Backdrop from "../../CommonRequirements/backdrop";
import {
  validateIsAdminUser,
  validatePurchaseUser,
  validatePurchaseExportUser,
  validatePurchaseExportOnlyUser,
  validatePurchaseOnlyUser,
  validatePurchaseReportUser,
} from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import baseUrl from "../../service/servicesConfig";

function PurchaseReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [purchaseDetails, setPurchaseDetails] = useState("");
  const [exportData, setExportData] = useState(false);
  const [getBackDrop, setBackDrop] = useState(false);
  const [purchaseSearch, setPurchaseSearch] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState("");

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_CENTER,
  });

  const notify = (unavailability) => {
    if (unavailability)
      return toast("No Purchase details available for selected date range ", {
        type: toast.TYPE.ERROR,
      });
  };

  const monthsList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let filterResult = "";

  useEffect(() => {
    setBackDrop(true);
    startDate.setDate(startDate.getDate() - 1);
    const startDateConversion = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()
    }`;
    const endDateConversion = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1 < 10
        ? "0" + (endDate.getMonth() + 1)
        : endDate.getMonth() + 1
    }-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`;
    // fetch(`https://services2.superzop.com/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${startDateConversion}&purchase_end_date=${endDateConversion}`).then(res => {
    //     return res.json()
    // }).then(data => { console.log(data); setPurchaseDetails(data); setBackDrop(false); if (!data.orders.length > 0) { notify(true) } }).catch(err => console.log(err))

    const getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${startDateConversion}&purchase_end_date=${endDateConversion}`;

    baseUrl
      .get(getUrl)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          return response.data;
        }
      })
      .then((data) => {
        console.log(data);
        setPurchaseDetails(data);
        setBackDrop(false);
        if (!data.length > 0) {
          notify(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDateRangeChange = () => {
    if (startDate > endDate) {
      setBackDrop(false);
      setPurchaseDetails("");
      return toast("Please enter a valid date range", {
        type: toast.TYPE.ERROR,
      });
    }
    let conversionStartDtType = `purchase_start_date`;
    let conversionEndDtType = `purchase_end_date`;
    if (dateRangeType && dateRangeType.includes(`GRN`)) {
      conversionStartDtType = `grn_start_date`;
      conversionEndDtType = `grn_end_date`;
    }
    setPurchaseDetails("");
    const startDateConversion = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()
    }`;
    const endDateConversion = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1 < 10
        ? "0" + (endDate.getMonth() + 1)
        : endDate.getMonth() + 1
    }-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`;

    const getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${endDateConversion}`;

    baseUrl
      .get(getUrl)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          return response.data;
        }
      })
      .then((data) => {
        console.log(data);
        setPurchaseDetails(data);
        setBackDrop(false);
        if (!data.length > 0) {
          notify(true);
        }
      })
      .catch((err) => console.log(err));

    /*fetch(`https://services2.superzop.com/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setPurchaseDetails(data); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))*/
  };

  const searchStockTakeReport = () => {
    const filterData = [];

    filterResult = purchaseDetails.filter((obj) => {
      const itemId = obj.article_code.trim().toLowerCase();
      const itemDesc =
        (obj.item_name && obj.item_name.trim().toLowerCase()) || "";
      const categorySearch =
        (obj.category && obj.category.trim().toLowerCase()) || "";

      setSearchText(searchText.trim().toLowerCase());

      return (
        itemId.match(searchText) ||
        itemDesc.match(searchText) ||
        categorySearch.match(searchText)
      );
    });
    filterResult.forEach((items) => {
      filterData.push(items);
    });

    setFilterList(filterData);
  };
  useEffect(() => {
    if (searchText) searchStockTakeReport();
  }, [searchText]);

  useEffect(() => {
    if (purchaseSearch) handleDateRangeChange();
  }, [purchaseSearch]);

  useEffect(() => {
    if (dateRangeType) {
      handleDateRangeChange();
    }
  }, [dateRangeType]);

  return (
    <>
      <NavBar />
      <div style={{ width: "2400px", paddingTop: 100 }}>
        <Navbar className="bg-light mr-auto justify-content-between">
          <Form inline>
            <Button size="sm" onClick={() => window.history.back()}>
              <i class="fas fa-arrow-circle-left" size="3x"></i>
            </Button>
            <InputGroup
              size="sm"
              style={{ marginLeft: "30px", marginRight: "30px" }}
            >
              <DropdownButton
                size="sm"
                id="dropdown-basic-button"
                title={dateRangeType ? dateRangeType : "Search Purchase"}
              >
                <Dropdown.Item
                  value={"Purchase"}
                  onClick={(e) => {
                    e.preventDefault();
                    setBackDrop(true);
                    setDateRangeType("Purchase");
                  }}
                >
                  Search Purchase
                </Dropdown.Item>
                <Dropdown.Item
                  value={"GRN"}
                  onClick={(e) => {
                    e.preventDefault();
                    setBackDrop(true);
                    setDateRangeType("GRN");
                  }}
                >
                  Search GRN
                </Dropdown.Item>
              </DropdownButton>
            </InputGroup>
            <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
              <Badge variant="dark"> Start Date </Badge>
              <DatePicker
                selected={startDate}
                onChange={(val) => {
                  setStartDate(val);
                }}
                minDate={new Date("2017/01/01")}
                maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>
            <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
              <Badge variant="dark">End Date </Badge>
              <DatePicker
                selected={endDate}
                onChange={(val) => {
                  setEndDate(val);
                }}
                minDate={new Date("2017/01/01")}
                maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>
            <Button
              size="sm"
              onClick={() => {
                setPurchaseSearch(true);
                setBackDrop(true);
              }}
            >
              Search
            </Button>
            {purchaseDetails.length > 0 && (
              <InputGroup style={{ marginLeft: "20px" }}>
                <Form.Control
                  id="searchProduct"
                  size="sm"
                  style={{ width: "500px" }}
                  className="searchBox"
                  placeholder="Item Id / Item Name / Category"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value == "") setFilterList("");
                  }}
                />
              </InputGroup>
            )}
            <InputGroup size="sm" style={{ marginLeft: 400, marginRight: 50 }}>
              {validatePurchaseExportUser(
                sessionStorage.getItem(LOGGED_USER_EMAIL)
              ) ? (
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => setExportData(true)}
                >
                  Export
                </Button>
              ) : (
                ""
              )}
            </InputGroup>
          </Form>
        </Navbar>
        {purchaseDetails.length > 0 && (
          <Jumbotron>
            <Table striped bordered>
              <thead>
                <tr>
                  <td>Purchased date</td>
                  <td>PO number</td>
                  <td>Article Code</td>
                  <td>Item Name</td>
                  <td>Category</td>
                  <td>Outer Size</td>
                  <td>Outer Type</td>
                  <td>Base Price</td>
                  <td>Purchase Price</td>
                  <td>Replacement Brand</td>
                  <td>Location</td>
                  <td>Bags Purchased</td>
                  <td>Bags Received</td>
                  <td>Total Weight(Kg/Pc)</td>
                  <td>Total Weight Received(Kg/Pc)</td>
                  <td>UoM</td>
                  <td>Difference Receiving</td>
                  <td>Material Receiving Date</td>
                  <td>GRN number</td>
                  <td>Invoice Number</td>
                  <td>Supplier Name</td>
                  <td>Created At</td>
                  <td>Created By</td>
                  <td>Updated At</td>
                  <td>Updated By</td>
                </tr>
              </thead>
              <tbody>
                {purchaseDetails.length > 0 && filterList.length == 0
                  ? purchaseDetails.map((items) => (
                      <tr>
                        <td>{items.purchased_date}</td>
                        <td>{items.po_number}</td>
                        <td>{items.article_code}</td>
                        <td>{items.item_name}</td>
                        <td>{items.category}</td>
                        <td>{items.outer_size}</td>
                        <td>{items.outer_type}</td>
                        <td>{items.base_price}</td>
                        <td>{items.purchase_price}</td>
                        <td>{items.replacement_brand}</td>
                        <td>{items.location}</td>
                        <td>{items.bag_purchased}</td>
                        <td>{items.bags_received}</td>
                        <td>{items.total_weight}</td>
                        <td>{items.total_weight_received}</td>
                        <td>{items.uom}</td>
                        <td>{items.diff_receiving}</td>
                        <td>{items.grn_date}</td>
                        <td>{items.grn_no}</td>
                        <td>{items.invoice_number}</td>
                        <td>{items.supplier_name}</td>
                        <td>
                          {items["created_at"] &&
                            `${
                              items["created_at"].split(" ")[0].split("-")[2]
                            }-${
                              monthsList[
                                items["created_at"]
                                  .split(" ")[0]
                                  .split("-")[1]
                                  .substr(-1) - 1
                              ]
                            } ${
                              items["created_at"].split(" ")[1].split(".")[0]
                            }`}
                        </td>
                        <td>
                          {items.created_by && items.created_by.split(".")[0]}
                        </td>
                        <td>
                          {items["updated_at"] &&
                            `${
                              items["updated_at"].split(" ")[0].split("-")[2]
                            }-${
                              monthsList[
                                items["updated_at"]
                                  .split(" ")[0]
                                  .split("-")[1]
                                  .substr(-1) - 1
                              ]
                            } ${
                              items["updated_at"].split(" ")[1].split(".")[0]
                            }`}
                        </td>
                        <td>
                          {items.updated_by && items.updated_by.split(".")[0]}
                        </td>
                      </tr>
                    ))
                  : filterList.map((items) => (
                      <tr>
                        <td>{items.purchased_date}</td>
                        <td>{items.po_number}</td>
                        <td>{items.article_code}</td>
                        <td>{items.item_name}</td>
                        <td>{items.category}</td>
                        <td>{items.outer_size}</td>
                        <td>{items.outer_type}</td>
                        <td>{items.base_price}</td>
                        <td>{items.purchase_price}</td>
                        <td>{items.replacement_brand}</td>
                        <td>{items.location}</td>
                        <td>{items.bag_purchased}</td>
                        <td>{items.bags_received}</td>
                        <td>{items.total_weight}</td>
                        <td>{items.total_weight_received}</td>
                        <td>{items.uom}</td>
                        <td>{items.diff_receiving}</td>
                        <td>{items.grn_date}</td>
                        <td>{items.grn_no}</td>
                        <td>{items.invoice_number}</td>
                        <td>{items.supplier_name}</td>
                        <td>
                          {items["created_at"] &&
                            `${
                              items["created_at"].split(" ")[0].split("-")[2]
                            }-${
                              monthsList[
                                items["created_at"]
                                  .split(" ")[0]
                                  .split("-")[1]
                                  .substr(-1) - 1
                              ]
                            } ${
                              items["created_at"].split(" ")[1].split(".")[0]
                            }`}
                        </td>
                        <td>
                          {items.created_by && items.created_by.split(".")[0]}
                        </td>
                        <td>
                          {items["updated_at"] &&
                            `${
                              items["updated_at"].split(" ")[0].split("-")[2]
                            }-${
                              monthsList[
                                items["updated_at"]
                                  .split(" ")[0]
                                  .split("-")[1]
                                  .substr(-1) - 1
                              ]
                            } ${
                              items["updated_at"].split(" ")[1].split(".")[0]
                            }`}
                        </td>
                        <td>
                          {items.updated_by && items.updated_by.split(".")[0]}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </Jumbotron>
        )}
        {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}
      </div>
      {exportData && (
        <ExportReport
          listedPurchaseDetails={purchaseDetails}
          multipleExport={setExportData}
          exportDate={`${new Date().getDate()}-${
            new Date().getMonth() + 1
          }-${new Date().getFullYear()}`}
        />
      )}
    </>
  );
}

export default PurchaseReport;
