import React, { useContext, useState, useEffect, Fragment } from 'react';
import { SearchContainer, DisplayItemsContainer } from './wrapper';
import { rootStore } from '../../store';
import { SEARCH_PRODUCTS } from '../../constants/allConstants';
import ProductContext from '../../context/productList';
import { getCurrCity, getCurrRegion } from '../../service/getCityRegion';

const SearchComponent = () => {

    const checkAvail = (property) => {
        return (globalState.hasOwnProperty(property) === true || globalState.hasOwnProperty(property));
    };

    const checkIsSearchTotalProd = () => {
        const obj = globalState;
        const len = obj.hasOwnProperty('filteredRes') ? obj.filteredRes.length : null;
        if ((len > 0) && (obj.filteredRes[0].id !== 0 && obj.filteredRes[0].productNotFound !== true)) {
            return len;
        }
        return null;
    };

    const productData = useContext(ProductContext);
    console.log(productData)
    const global_state = useContext(rootStore);
    const globalState = global_state.state;
    globalState.allProducts = productData;
    const isUserEditingProd = globalState.hasOwnProperty('editData') ? true : false;
    const showSearch = (checkAvail('editData') || checkAvail('addProduct') || checkAvail('bulkUploads')) ? false : true;
    const { dispatch } = global_state;
    const [currCity, setCurrCity] = useState(getCurrCity(globalState));
    const [currRegion, setCurrRegion] = useState(getCurrRegion(globalState));
    const [userInput, setUserInput] = useState(null);
    const totalProd = checkIsSearchTotalProd();
    const clearSearchParamsOnEditClose = globalState.hasOwnProperty('screenClosed') ? true : false;
    let previousUserInput = "";

    const searchOnChange = () => {
        if(!isUserEditingProd && previousUserInput!= userInput) {
            dispatch({ type: SEARCH_PRODUCTS, searchText: userInput });
        }
    };

    const onSearchChange = (event) => {
        event.preventDefault();
        const inpVal = event.target.value.trim();
        setUserInput(inpVal);
    };

    const checkIsRegionChanged = () => {
        if ((getCurrCity(globalState) !== currCity) || (getCurrRegion(globalState) !== currRegion)) {
            setUserInput(null);
            setCurrCity(getCurrCity(globalState));
            setCurrRegion(getCurrRegion(globalState));
        }
    };

    useEffect(() => {
        if (!globalState.hasOwnProperty('filteredRes')) {
            checkIsRegionChanged();
        }
    }, [globalState]);

    useEffect(() => {
        searchOnChange();
    }, [userInput]);

    const ShowComp = () => {
        if (clearSearchParamsOnEditClose) {
            setUserInput(null);
        }

        return (
            <Fragment>
                <input
                    autoFocus
                    type="text"
                    name="search-box"
                    defaultValue={userInput}
                    placeholder="Item ID / Name / Brand"
                    onChange={onSearchChange}
                />
                {totalProd === null ? null : <DisplayItemsContainer>Matched Items : {totalProd} </DisplayItemsContainer>}
            </Fragment>
        );
    };

    return (
        <SearchContainer>
            {showSearch ? <ShowComp /> : null}
        </SearchContainer>
    );
};

export default SearchComponent;
