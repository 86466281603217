import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Alert,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Button,
  Form,
  Modal,
  Badge,
  Navbar,
} from "react-bootstrap";
import { toast } from "react-toastify";
import database from "../../service/firebaseConfig";
import { FaWindows, FaLessThanEqual } from "react-icons/fa";
import Select from "react-select";
import Creatable from "react-select/creatable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import Switch from "react-switch";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import {
  validateIsAdminUser,
  validateDumpApprovalUser,
} from "../../service/credentials";
import baseUrl from "../../service/servicesConfig";

const ProductDumpDetails = database.database().ref(`Product_Dump`);
const Product_Base = database.database().ref(`Product_Base_Data`);
const SuppliersDB = database.database().ref(`Suppliers`);

function EditProductDump({ data, dataId, ...props }) {
  const [dumpDate, setProductDumpDate] = useState("");
  const [category, setCategory] = useState("");
  const [articleCode, setArticleCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [outerType, setOuterType] = useState("");
  const [qtyDumped, setQtyDumped] = useState("");
  const [articleInfo, setArticleInfo] = useState("");
  const [articleOptions, setArticleOptions] = useState("");
  const [disableList, setDisableList] = useState(false);
  const [dateToModify, setDateToModify] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [dumpReason, setDumpReason] = useState("");
  const [approvalStatus, setApprovalStatus] = useState(
    data.approve_status == "Approved" ? true : false
  );
  const [salvageRate, setSalvageRate] = useState("");
  const [salvageAmt, setSalvageAmt] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [normalRate, setNormalRate] = useState("");
  const [QrIdOpt, setQrIdOpt] = useState([]);
  const [OldQId, setOldQId] = useState([])
  // const [qrdValue, setQrdValue] = useState(null);


  const PurchaseData = [];
  const DateofPurchase = [];
  let purchaseItems = {};
  const categoryField = useRef();
  const itemNoField = useRef();
  const itemNameField = useRef();
  const totalWtPurchasedRef = useRef();
  const datepickerRef = useRef();
  const quantityDumpedRef = useRef();
  let btnRef = useRef();
  const salvageRateRef = useRef();

  console.log("data: ", data);

  const ListOfReasons = [
    { value: "Damaged/Spoilt Product", label: "Damaged/Spoilt Product" },
    { value: "Expired Product", label: "Expired Product" },
    { value: "Wet Product", label: "Wet Product" },
    { value: "Infestation", label: "Infestation" },
    { value: "Bad Quality", label: "Bad Quality" },
  ];

  const getSelectedDate = sessionStorage.getItem("datekey")
    ? new Date(sessionStorage.getItem("datekey"))
    : "";
  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast("Product Dump Details updated", {
        type: toast.TYPE.SUCCESS,
      });
    }
    return toast("Couldn't update the Dump Details", {
      type: toast.TYPE.ERROR,
    });
  };

  const fetchProductItem = () => {
    const url = `/api/superzop/pricelist/price-details`;
    Promise.all([Product_Base.once("value"), baseUrl.get(url)]).then(
      ([snapShot, priceSnap]) => {
        const productItems = snapShot.val();
        const priceItems = priceSnap.data.data || {};

        let priceMap = {};
        Object.keys(priceItems).forEach((productId) => {
          priceMap[productId] =
            Number(priceItems[productId].final_buying_price) || 0;
        });

        const productBaseInfo = [];
        Object.keys(productItems).forEach((productId) => {
          if (productItems[productId]["buying_flag"] == "Y") {
            productBaseInfo.push({
              articleId: productId,
              category: productItems[productId].category,
              uom: productItems[productId].uom,
              lot_size: productItems[productId].lot_size
                ? productItems[productId].lot_size
                : 0,
              brand: productItems[productId].brand,
              variety: productItems[productId].variety,
              itemName: productItems[productId].long_description,
              normal_rate: Number(priceMap[productId]) || 0,
            });
          }
        });

        setArticleInfo(productBaseInfo);
      }
    );
  };

  useEffect(() => {
    fetchProductItem();
    console.log(datepickerRef.current);
    console.log(data);
  }, []);

  useEffect(() => {
    fetchProductItem();
    console.log(datepickerRef.current);
  }, []);

  const populateArticle = () => {
    const allOptionsArticle = [];
    articleInfo.forEach((item) => {
      let itemName = item.itemName;
      if (item.brand) itemName = item.brand + " - " + item.itemName;

      allOptionsArticle.push({ value: itemName, label: itemName });
    });
    setArticleOptions(allOptionsArticle);
  };

  useEffect(() => {
    if (articleInfo) populateArticle();
  }, [articleInfo]);


useEffect(() => {
    console.log('-----------------------------');
    console.log(data.article_code);
    console.log('-----------------------------');


    setOldQId(data.qr_id)
    let article_Id = data.article_code
    const url = `/api/superzop/get-qr-id?item_id=${article_Id}`;

    baseUrl
      .get(url)
      .then((res) => {
          console.log(res.data.data.qr_id);
        if(res.data.data.qr_id.length > 0){
          let opt = res.data.data.qr_id.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          setQrIdOpt(opt);
          toast("Qr ids added ", { type: toast.TYPE.SUCCESS });
        }else{
          toast(`Not found any Qr id for this item id ${article_Id}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Not found any Qr id for this item id ${article_Id}`, { type: toast.TYPE.ERROR });
      });

      


  }, []);

  const insertDomData = (itemValue, articleCode) => {
    let article_Id = null;

    if (articleCode) {
      setDisableList(false);

      articleInfo.forEach((item) => {
        if (articleCode.includes(item.articleId)) {
          setDisableList(true);
          categoryField.current.value = item.category;
          let itemValue = item.itemName;
          if (item.brand) itemValue = item.brand + " - " + item.itemName;
          console.log(
            "Updating based on ArticleCode:: " +
              articleCode +
              " Article Name :: " +
              itemValue +
              " Category:: " +
              item.category
          );
          article_Id = articleCode;
          setCategory(item.category);
          setItemName(itemValue);
          setOuterType(item.variety);
          setUomValue(item.uom);
          quantityDumpedRef.current.value = item.lot_size;
          setQtyDumped(Number(item.lot_size));
          setNormalRate(Number(item.normal_rate));
          console.log("LotSize" + item.lot_size);
        }
      });
    } else if (itemValue) {
      articleInfo.forEach((item) => {
        let itemName = item.itemName;
        if (item.brand) itemName = item.brand + " - " + item.itemName;

        if (itemValue == itemName) {
          console.log(
            "Updating based on ArticleName:: " +
              itemValue +
              " Article ID :: " +
              item.articleId +
              " Category:: " +
              item.category
          );
          article_Id = item.articleId;
          setCategory(item.category);
          categoryField.current.value = item.category;
          quantityDumpedRef.current.value = item.lot_size;
          setQtyDumped(Number(item.lot_size));
          setNormalRate(Number(item.normal_rate));
          setUomValue(item.uom);
          setArticleCode(item.articleId);
          itemNoField.current.value = item.articleId;
          setOuterType(item.variety);
        }
      });
    }

    const url = `/api/superzop/get-qr-id?item_id=${article_Id}`;

    baseUrl
      .get(url)
      .then((res) => {
          console.log(res.data.data.qr_id);
        if(res.data.data.qr_id.length > 0){
          let opt = res.data.data.qr_id.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          setQrIdOpt(opt);
          toast("Qr ids added ", { type: toast.TYPE.SUCCESS });
        }else{
          toast(` Not found any Qr id for this item  id ${article_Id}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Not found any Qr id for this item id ${article_Id}`, { type: toast.TYPE.ERROR });
      });
  };

  const PushProductDumpNew = (event) => {
    let todaysDate = "";
    let currentMn = "";
    let currentDt = "";
    const form = event.currentTarget;
    const approve_status_data = data.approve_status;
    const approve_status_modal = approvalStatus ? "Approved" : "Unapproved";
    event.preventDefault();
    if (form.checkValidity() === false) {
      //event.preventDefault();
      event.stopPropagation();
    } else {
      console.log(
        "approve_status_data: ",
        approve_status_data,
        " approve_status_modal: ",
        approve_status_modal
      );
      if (
        approve_status_data === "Approved" &&
        approve_status_modal === "Unapproved"
      ) {
        return toast("Can not edit an Approved item to Unapproved", {
          type: toast.TYPE.ERROR,
        });
      }

      let productDumpDate = data.dump_date;
      btnRef.current.setAttribute("disabled", "disabled");

      const productDumpDt = new Date(
        productDumpDate.split("-")[0],
        productDumpDate.split("-")[1],
        productDumpDate.split("-")[2]
      );
      const year = productDumpDt.getFullYear();
      const month =
        productDumpDt.getMonth().toString().length === 1
          ? `0${productDumpDt.getMonth()}`
          : productDumpDt.getMonth();
      const date =
        productDumpDt.getDate().toString().length === 1
          ? `0${productDumpDt.getDate()}`
          : productDumpDt.getDate();

      const product_dump_date_format = `${year}-${month}-${date}`;

      const currentDateObj = new Date();

      const currYear = currentDateObj.getFullYear();
      const currMonth =
        currentDateObj.getMonth().toString().length === 1
          ? `0${currentDateObj.getMonth() + 1}`
          : currentDateObj.getMonth() + 1;
      const currDate =
        currentDateObj.getDate().toString().length === 1
          ? `0${currentDateObj.getDate()}`
          : currentDateObj.getDate();

      let fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
      fourDaysOlderDate = fourDaysOlderDate.setDate(
        fourDaysOlderDate.getDate() - 4
      );

      console.log(
        "productDumpDate: ",
        productDumpDate,
        " product_dump_date_format: ",
        product_dump_date_format,
        " fourDaysOlderDate: ",
        fourDaysOlderDate
      );

      const updatedData = {
        dump_date: productDumpDate,
        category: category ? category : data.category,
        article_code: articleCode ? articleCode : data.article_code,
        item_name: itemName ? itemName : data.item_name,
        uom: uomValue ? uomValue : data.uom,
        qty_dumped: qtyDumped ? qtyDumped : data.qty_dumped,
        reason: dumpReason ? dumpReason : data.reason,
        normal_rate: normalRate || data.normal_rate,
        salvage_rate: salvageRate || data.salvage_rate,
        salvage_amt: salvageAmt || data.salvage_amt,
        approve_status: approve_status_modal,
        authorized_by: UserEmail(),
        old_qr_id : OldQId,
        // qr_id : qrdValue
      };

      /*const productDumpDateSplit = productDumpDate.split("-");
            const key = `${productDumpDateSplit[2]}-${productDumpDateSplit[1]}-${productDumpDateSplit[0]}-${articleCode ? articleCode : data.article_code}`;*/
      const unique_key = data.unique_key;
      const firebaseKey = unique_key.substring(11);
      console.log(updatedData, firebaseKey, unique_key, productDumpDate);

      const old_dump_date = data.dump_date;
      const old_qty_dumped = data.qty_dumped;
      const item_id = updatedData.article_code;

      updatedData.old_dump_date = old_dump_date;
      updatedData.old_qty_dumped = old_qty_dumped;
      updatedData.product_dump_key = unique_key;

      const url = `/api/superzop/commercial/productdump/editdumpandsi?type=edit`;

      baseUrl
        .post(url, updatedData)
        .then((res) => {
          console.log("PushProductDumpNew ", res);

          //resolve("Success");

          setTimeout(() => {
            window.location.reload();
          }, 2000);

          return toast("Edited and Synced System Inventory", {
            type: toast.TYPE.SUCCESS,
          });
        })
        .catch((error) => {
          console.error(error);
          //reject(error);
          return toast("Failed to Edit", { type: toast.TYPE.SUCCESS });
        });
    }
  };

  const PushProductDump = (event) => {
    let todaysDate = "";
    let currentMn = "";
    let currentDt = "";
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      //event.preventDefault();
      event.stopPropagation();
    } else {
      let productDumpDate = data.dump_date;
      btnRef.current.setAttribute("disabled", "disabled");

      const productDumpDt = new Date(
        productDumpDate.split("-")[0],
        productDumpDate.split("-")[1],
        productDumpDate.split("-")[2]
      );
      const year = productDumpDt.getFullYear();
      const month =
        productDumpDt.getMonth().toString().length === 1
          ? `0${productDumpDt.getMonth()}`
          : productDumpDt.getMonth();
      const date =
        productDumpDt.getDate().toString().length === 1
          ? `0${productDumpDt.getDate()}`
          : productDumpDt.getDate();

      const product_dump_date_format = `${year}-${month}-${date}`;

      const currentDateObj = new Date();

      const currYear = currentDateObj.getFullYear();
      const currMonth =
        currentDateObj.getMonth().toString().length === 1
          ? `0${currentDateObj.getMonth() + 1}`
          : currentDateObj.getMonth() + 1;
      const currDate =
        currentDateObj.getDate().toString().length === 1
          ? `0${currentDateObj.getDate()}`
          : currentDateObj.getDate();

      let fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
      fourDaysOlderDate = fourDaysOlderDate.setDate(
        fourDaysOlderDate.getDate() - 4
      );

      console.log(
        "productDumpDate: ",
        productDumpDate,
        " product_dump_date_format: ",
        product_dump_date_format,
        " fourDaysOlderDate: ",
        fourDaysOlderDate
      );

      const updatedData = {
        dump_date: productDumpDate,
        category: category ? category : data.category,
        article_code: articleCode ? articleCode : data.article_code,
        item_name: itemName ? itemName : data.item_name,
        uom: uomValue ? uomValue : data.uom,
        qty_dumped: qtyDumped ? qtyDumped : data.qty_dumped,
        reason: dumpReason ? dumpReason : data.reason,
        salvage_rate: salvageRate || data.salvage_rate,
        salvage_amt: salvageAmt || data.salvage_amt,
        approve_status: approvalStatus ? "Approved" : "Unapproved",
        authorized_by: UserEmail(),
      };

      /*const productDumpDateSplit = productDumpDate.split("-");
            const key = `${productDumpDateSplit[2]}-${productDumpDateSplit[1]}-${productDumpDateSplit[0]}-${articleCode ? articleCode : data.article_code}`;*/
      const unique_key = data.unique_key;
      const firebaseKey = unique_key.substring(11);
      console.log(updatedData, firebaseKey, unique_key, productDumpDate);

      const old_dump_date = data.dump_date;
      const old_qty_dumped = data.qty_dumped;
      const item_id = updatedData.article_code;

      if (product_dump_date_format !== old_dump_date) {
        const old_dump_url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${old_dump_date}&item_id=${item_id}`;

        const old_dump_obj = {
          qty_dumped: `-${old_qty_dumped}`,
        };

        baseUrl
          .put(old_dump_url, old_dump_obj)
          .then((response) => {
            const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${product_dump_date_format}&item_id=${item_id}`;

            const obj = {
              qty_dumped: updatedData.qty_dumped,
            };

            console.log("url: ", url, " obj: ", obj);

            baseUrl
              .put(url, obj)
              .then((res) => {
                console.log("isAdmin updatePurchaseDetails ", res);

                //update in MySql
                const mysqlProm = new Promise((resolve, reject) => {
                  const url = `/api/superzop/commercial/productdump/updateproductdumprecord?key=${unique_key}`;

                  baseUrl
                    .post(url, updatedData)
                    .then((res) => {
                      console.log("PushPurchaseDetails ", res);

                      resolve("Success");
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });

                //update in firebase
                const firebaseProm = new Promise((resolve, reject) => {
                  const year = productDumpDate.split("-")[0];
                  const month = productDumpDate.split("-")[1];
                  const date = productDumpDate.split("-")[2];

                  const complete_date = `${date}-${month}-${year}`;

                  ProductDumpDetails.child(`${year}/${month}/${complete_date}`)
                    .child(firebaseKey)
                    .update(updatedData)
                    .then(() => {
                      resolve("Success");
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });

                Promise.all([mysqlProm, firebaseProm])
                  .then(() => {
                    console.log("Successfully updated");

                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                    notifyUser(true);

                    //if (new Date(product_dump_date_format) < new Date(fourDaysOlderDate)) {

                    /*//const item_id = data.article_code;
                            const todayDate = `${currYear}-${currMonth}-${currDate}`;

                            //const sync_sys_inv_url = `/api/superzop/systeminventory/savesysteminventoryintotable?start_date=${product_dump_date_format}&end_date=${todayDate}&item_id=${articleCode}`
                            const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${product_dump_date_format}&end_date=${todayDate}&item_id=${articleCode}`;

                            baseUrl.post(sync_sys_inv_url).then(res => {

                                console.log('isAdmin updatePurchaseDetails ', res);
                                return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                            }).catch((error) => {
                                console.log(error);
                            });*/
                    //}
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                toast("Synced System Inventory", { type: toast.TYPE.SUCCESS });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${product_dump_date_format}&item_id=${item_id}`;

        const obj = {
          qty_dumped: updatedData.qty_dumped,
        };

        console.log("url: ", url, " obj: ", obj);

        baseUrl
          .put(url, obj)
          .then((res) => {
            console.log("isAdmin updatePurchaseDetails ", res);

            //update in MySql
            const mysqlProm = new Promise((resolve, reject) => {
              const url = `/api/superzop/commercial/productdump/updateproductdumprecord?key=${unique_key}`;

              baseUrl
                .post(url, updatedData)
                .then((res) => {
                  console.log("PushPurchaseDetails ", res);

                  resolve("Success");
                })
                .catch((error) => {
                  reject(error);
                });
            });

            //update in firebase
            const firebaseProm = new Promise((resolve, reject) => {
              const year = productDumpDate.split("-")[0];
              const month = productDumpDate.split("-")[1];
              const date = productDumpDate.split("-")[2];

              const complete_date = `${date}-${month}-${year}`;

              ProductDumpDetails.child(`${year}/${month}/${complete_date}`)
                .child(firebaseKey)
                .update(updatedData)
                .then(() => {
                  resolve("Success");
                })
                .catch((error) => {
                  reject(error);
                });
            });

            Promise.all([mysqlProm, firebaseProm])
              .then(() => {
                console.log("Successfully updated");

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
                notifyUser(true);
              })
              .catch((error) => {
                console.error(error);
              });
            toast("Synced System Inventory", { type: toast.TYPE.SUCCESS });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    setValidated(true);
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const dumpList = () => {
    window.open("#/productDumpList");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={/*PushProductDump*/ PushProductDumpNew}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Product Dump
          </Modal.Title>
        </Modal.Header>

        <Modal.Body {...props}>
          {articleInfo == "" ? (
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={50}
              width={50}
              style={{ display: "flex", justifyContent: "center" }}
              timeout={10000} //20 secs
            />
          ) : (
            <div>
              <Table size="sm">
                <tr>
                  <td>Dump Date </td>
                  <td>
                    <Form.Control readOnly placeholder={data.dump_date} />
                  </td>
                </tr>
                <tr>
                  <td>Item Name</td>
                  <td>
                    {articleCode == "" || disableList == false ? (
                      <Select
                        required
                        options={articleOptions}
                        ref={itemNameField}
                        onChange={(val) => {
                          setItemName(val.value);
                          let itemName = val.value;
                          let itemCode = null;
                          insertDomData(itemName, itemCode);
                        }}
                        style={{ width: "150px" }}
                        placeholder={data.item_name}
                      />
                    ) : (
                      <Form.Control
                        ref={itemNameField}
                        value={itemName}
                        readOnly
                        placeholder={data.item_name}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Article Code</td>
                  <td>
                    {itemName == "" || disableList ? (
                      <Form.Control
                        ref={itemNoField}
                        onChange={(e) => {
                          setArticleCode(e.target.value);
                          let itemCode = e.target.value;
                          let itemName = null;
                          insertDomData(itemName, itemCode);
                        }}
                        placeholder={data.article_code}
                      />
                    ) : (
                      <Form.Control
                        ref={itemNoField}
                        onChange={(e) => {
                          setArticleCode(e.target.value);
                          let itemCode = e.target.value;
                          let itemName = null;
                          insertDomData(itemName, itemCode);
                        }}
                        readOnly
                        placeholder={data.article_code}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>
                    <Form.Control
                      ref={categoryField}
                      readOnly
                      placeholder={data.category}
                    />
                  </td>
                </tr>
                <tr>
                  <td>UOM</td>
                  <td>
                    <Form.Control
                      value={uomValue ? uomValue : data.uom}
                      readOnly
                      onChange={(e) => {
                        setUomValue(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>Bag QR ID</td>
                  <td>
                    <Select
                      isMulti
                      onChange={(selectedOptions) => { setQrdValue(selectedOptions.map(option => option.value)) }}
                      options={QrIdOpt}
                    ></Select>
                  </td>
                </tr> */}
                <tr>
                  <td>Qty Dumped(Kg/Pc)</td>
                  <td>
                    <Form.Control
                      ref={quantityDumpedRef}
                      onChange={(e) => {
                        setQtyDumped(e.target.value);
                        setSalvageAmt(
                          salvageRateRef.current.value
                            ? (normalRate -
                                Number(salvageRateRef.current.value)) *
                                Number(e.target.value)
                            : data.salvage_rate
                            ? Number(data.salvage_rate) * Number(e.target.value)
                            : ""
                        );
                      }}
                      placeholder={data.qty_dumped}
                    />
                    <Form.Control.Feedback type="invalid">
                      *Required
                    </Form.Control.Feedback>
                  </td>
                </tr>
                <tr>
                  <td>Salvage Rate</td>
                  <td>
                    <Form.Control
                      ref={salvageRateRef}
                      onChange={(e) => {
                        setSalvageRate(e.target.value);
                        setSalvageAmt(
                          quantityDumpedRef.current.value
                            ? Number(quantityDumpedRef.current.value) *
                                (normalRate - Number(e.target.value))
                            : data.qty_dumped
                            ? Number(data.qty_dumped) *
                              (normalRate - Number(e.target.value))
                            : ""
                        );
                      }}
                      placeholder={data.salvage_rate}
                    />
                    <Form.Control.Feedback type="invalid">
                      *Required
                    </Form.Control.Feedback>
                  </td>
                </tr>
                <tr>
                  <td>Salvage Amount</td>
                  <td>
                    <Form.Control
                      readOnly
                      onChange={(e) => {
                        setSalvageAmt(e.target.value);
                      }}
                      value={salvageAmt && salvageAmt}
                      placeholder={data.salvage_amt}
                    />
                    <Form.Control.Feedback type="invalid">
                      *Required
                    </Form.Control.Feedback>
                  </td>
                </tr>
                <tr>
                  <td>Reasons</td>
                  <td>
                    <Creatable
                      options={ListOfReasons}
                      onChange={(val) => {
                        setDumpReason(val.value);
                      }}
                      placeholder={data.reason}
                    />
                  </td>
                </tr>
                {validateDumpApprovalUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) && (
                  <tr>
                    <td>Approve</td>
                    <td>
                      <Switch
                        disabled={
                          data.approve_status == "Approved" ? true : false
                        }
                        onChange={(val) => {
                          setApprovalStatus(val);
                        }}
                        checked={approvalStatus}
                      />
                    </td>
                  </tr>
                )}
              </Table>
              <br />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" type="submit" ref={btnRef}>
            Update Dump Details{" "}
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditProductDump;
