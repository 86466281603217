import React, { useState, useEffect } from 'react'
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Row, Table, Button, Form, Card, InputGroup, Toast, Badge, Jumbotron } from 'react-bootstrap';
import { InputGroupAddon } from 'reactstrap';
import { Chart } from "react-google-charts";
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { toast } from 'react-toastify';
import Backdrop from '../../CommonRequirements/backdrop'
import DatePicker from "react-datepicker";
import ReactSelect from 'react-select';
import Select from 'react-select';
import GrtAnalysisTable from './grtanalysistable'
import database from '../../service/firebaseConfig';
import qualitydb from '../../service/qualityfirebaseconfig';


const GrtAnalysisList = () => {
  const [grtAnalysisData,setGrtAnalysisData] = useState([]);
  const [searchProductData,setSearchProductData] = useState([]);
  let selecteddate=new Date();
  selecteddate.setDate(selecteddate.getDate() - 10);
  const [startDate, setStartDate] = useState(selecteddate);
  const [endDate, setEndDate] = useState(new Date());
  const [maxObservation, setMaxObservation] = useState("")
  const [obsvIndexHeader, setObsvIndexHeader] = useState("");
  const [searchObsvIndexHeader, setSearchObsvIndexHeader] = useState("");
  const [productTestData, setProductTestData] = useState([]);
  const [getBackDrop, setBackDrop] = useState(true);
  const [showData, setShowData] = useState(false);
  const [productNameFilter, setProductNameFilter] = useState([]);
  const [categoryList,setCategoryList] = useState([]);
  const [subCategoryList,setSubCategoryList] = useState([]);
  const [productSkuList,setProductSkuList] = useState([]);
  const [supplierList,setSupplierList] = useState([]);
  const [chartData,setChartData] = useState([]);
  const [searchChartData,setSearchChartData] = useState([]);
  const [chartFilterData, setChartFilterData] = useState("")

  const [categoryText,setCategoryText] = useState("");
  const [subCategoryText,setSubCategoryText] = useState("");
  const [productSkuText,setProductSkuText] = useState("");
  const [supplierText,setSupplierText] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchProducts(startDate, endDate);
  }, [])

  useEffect(() => {
      if (startDate && endDate) {
          handleDateRangeChange()
      }
  }, [startDate,endDate])

  useEffect(() => {
      if(searchText){
        filterGrtAnalyseProductDetails();
      }else{
        console.log('hi');
        setProductNameFilter(searchProductData);
        setChartFilterData(searchChartData);
        setObsvIndexHeader(searchObsvIndexHeader);
      }
  }, [searchText])

  useEffect(() => {
      if(categoryText){
        filterProductByCategory();
      }else{
        setProductNameFilter(searchProductData);
        setChartFilterData(searchChartData);
        setObsvIndexHeader(searchObsvIndexHeader);
      }
  }, [categoryText])

  useEffect(() => {
      if(subCategoryText){
        filterProductBySubCategory();
      }else{
        setProductNameFilter(searchProductData);
        setChartFilterData(searchChartData);
        setObsvIndexHeader(searchObsvIndexHeader);
      }
  }, [subCategoryText])
  
  useEffect(() => {
      if(productSkuText){
        filterProductBySku()
        console.log(productSkuText)
      }else{
        setProductNameFilter(searchProductData);
        setChartFilterData(searchChartData);
        setObsvIndexHeader(searchObsvIndexHeader);
      }
  }, [productSkuText])

  useEffect(() => {
      if(supplierText){
        filterProductBySupplier();
        console.log(supplierText)

      }else{
        setProductNameFilter(searchProductData);
        setChartFilterData(searchChartData);
        setObsvIndexHeader(searchObsvIndexHeader);
      }
  }, [supplierText])

  const handleDateRangeChange = () => {
      if (startDate > endDate) {
        setBackDrop(false)
        return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })
      }else{
        //fetch product data
        setBackDrop(true);
        fetchProducts(startDate, endDate);
      }
  }

  const filterProductByCategory = () => {
    let checksearchtext=categoryText.trim().toLowerCase();
    let filteredData=[];
    let filteredChartData=[];
    let max=-1;

    Object.keys(searchProductData).map((prodkey,index) => {
      let category=searchProductData[prodkey][2];
      let itemid=searchProductData[prodkey][0];
      let observedindex=searchProductData[prodkey][6];
      let observedindexLength=observedindex.length;

      if(category){
        category=category.trim().toLowerCase();
        if(category.includes(checksearchtext)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);
          if(observedindexLength>max){
            max=observedindexLength;
          }
        }
      }
    });

    let observedVal = [];
    for (let i = 0; i < max; i++) {
        observedVal.push(`OI${i + 1}`)
    }

    setObsvIndexHeader(observedVal);

    // console.log(filteredData);
    setProductNameFilter(filteredData);
    setChartFilterData(filteredChartData);
  }

  const filterProductBySupplier = () => {
    // let checksearchtext=supplierText.trim().toLowerCase();
    let filteredData=[];
    let filteredChartData=[];
    let max=-1;

    Object.keys(searchProductData).map((prodkey,index) => {
      let supplier=searchProductData[prodkey][5];
      let itemid=searchProductData[prodkey][0];
      let observedindex=searchProductData[prodkey][6];
      let observedindexLength=observedindex.length;
      supplier=supplier.trim().toLowerCase();

      if(supplier && supplierText.length > 0){
        if(supplierText.find(o => o.value.trim().toLowerCase() === supplier)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);
          if(observedindexLength>max){
            max=observedindexLength;
          }
        }
      }
    });

    let observedVal = [];
    for (let i = 0; i < max; i++) {
        observedVal.push(`OI${i + 1}`)
    }

    setObsvIndexHeader(observedVal);

    // console.log(filteredData);
    setProductNameFilter(filteredData);
    setChartFilterData(filteredChartData);
  }

  const filterProductBySubCategory = () => {
    let checksearchtext=subCategoryText.trim().toLowerCase();
    let filteredData=[];
    let filteredChartData=[];
    let max=-1;

    Object.keys(searchProductData).map((prodkey,index) => {
      let subcategory=searchProductData[prodkey][3];
      let itemid=searchProductData[prodkey][0];
      let observedindex=searchProductData[prodkey][6];
      let observedindexLength=observedindex.length;

      if(subcategory){
        subcategory=subcategory.trim().toLowerCase();
        if(subcategory.includes(checksearchtext)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);

          if(observedindexLength>max){
            max=observedindexLength;
          }
        }
      }
    });

    let observedVal = [];
    for (let i = 0; i < max; i++) {
        observedVal.push(`OI${i + 1}`)
    }

    setObsvIndexHeader(observedVal);

    // console.log(filteredData);
    setProductNameFilter(filteredData);
    setChartFilterData(filteredChartData);
  }


  const filterProductBySku = () => {
    // let checksearchtext=productSkuText;
    let filteredData=[];
    let filteredChartData=[];
    let max=-1;

    Object.keys(searchProductData).map((prodkey,index) => {
      let subcategory=searchProductData[prodkey][3];
      let itemid=searchProductData[prodkey][0];
      let observedindex=searchProductData[prodkey][6];
      let observedindexLength=observedindex.length;

      if(itemid){        
        if(productSkuText.find(o => o.value === itemid)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);

          if(observedindexLength>max){
            max=observedindexLength;
          }
        }
      }
    });

    let observedVal = [];
    for (let i = 0; i < max; i++) {
        observedVal.push(`OI${i + 1}`)
    }

    setObsvIndexHeader(observedVal);

    // console.log(filteredData);
    setProductNameFilter(filteredData);
    setChartFilterData(filteredChartData);
  }

  const filterGrtAnalyseProductDetails = () => {
    let checksearchtext=searchText.trim().toLowerCase();
    let filteredData=[];
    let filteredChartData=[];
    let chartOI=[];
    let max=-1;

    Object.keys(searchProductData).map((prodkey,index) => {
      let pushflag=false;

      let category=searchProductData[prodkey][2];
      let subcategory=searchProductData[prodkey][3];
      let brand=searchProductData[prodkey][4];
      let supplier=searchProductData[prodkey][5];

      let itemid=searchProductData[prodkey][0];
      let observedindex=searchProductData[prodkey][6];
      let observedindexLength=observedindex.length;

      if(brand){
        brand=brand.trim().toLowerCase();
        if(brand.includes(checksearchtext)){
          pushflag=true;
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);
        }
      }

      if(subcategory && pushflag==false){
        subcategory=subcategory.trim().toLowerCase();
        if(subcategory.includes(checksearchtext)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);
          pushflag=true;
        }
      }

      if(supplier && pushflag==false){
        supplier=supplier.trim().toLowerCase();
        if(supplier.includes(checksearchtext)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);
          pushflag=true;
        }
      }

      if(category && pushflag==false){
        category=category.trim().toLowerCase();
        if(category.includes(checksearchtext)){
          filteredData.push(searchProductData[prodkey]);
          let itemele={};
          itemele[itemid]=observedindex;
          filteredChartData.push(itemele);
          pushflag=true;
        }
      }

      if(pushflag==true){
        if(observedindexLength>max){
          max=observedindexLength;
        }
      }
    });

    let observedVal = [];
    // console.log(max)
    for (let i = 0; i < max; i++) {
        observedVal.push(`OI${i + 1}`)
    }

    setProductNameFilter(filteredData);
    setChartFilterData(filteredChartData);
    setObsvIndexHeader(observedVal);
  }

  useEffect(() => {
      if (grtAnalysisData.length>0){
        structureChartData();
        filterProductCategory();
      }
  }, [grtAnalysisData])

  const filterProductCategory = () =>{
    console.log('filterProductCategory');
    let dropdownCategoryList=[];
    let dropdownSubCategoryList=[];
    let dropdownProductSkuList=[];
    let dropdownSupplierList=[];
    let categorySet=new Set();
    let subCategorySet=new Set();
    let productSkuSet=new Set();
    let supplierSet=new Set();

    Object.keys(productNameFilter).map((prodkey,index) => {
      console.log('prodkey')
      console.log(prodkey)
      let category=productNameFilter[prodkey][2];
      let subcategory=productNameFilter[prodkey][3];
      let productSku=productNameFilter[prodkey][0];
      let productSkuLabel=productNameFilter[prodkey][0] + " - " + productNameFilter[prodkey][1] + " - " + productNameFilter[prodkey][4];
      let supplier=productNameFilter[prodkey][5];

      if(!categorySet.has(category) && category!==undefined){
        categorySet.add(category);
        dropdownCategoryList.push({"value":category,"label":category});
      }

      if(!subCategorySet.has(subcategory) && subcategory!==undefined){
        subCategorySet.add(subcategory);
        dropdownSubCategoryList.push({"value":subcategory,"label":subcategory});
      }
      
      if(!productSkuSet.has(productSku) && productSku!==undefined){
        productSkuSet.add(productSku);
        dropdownProductSkuList.push({"value":productSku,"label":productSkuLabel});
      }

      if(!supplierSet.has(supplier) && supplier!==undefined){
        supplierSet.add(supplier);
        dropdownSupplierList.push({"value":supplier,"label":supplier});
      }
    });

    console.log(dropdownCategoryList);
    console.log(dropdownSubCategoryList);
    setCategoryList(dropdownCategoryList);
    setSubCategoryList(dropdownSubCategoryList);
    setProductSkuList(dropdownProductSkuList);
    setSupplierList(dropdownSupplierList);
  }


  const structureChartData = () => {

      let c = 1
      let max = -864849

      let indexListData = []
      let productUniqueData = []

        grtAnalysisData.forEach(items => {
            if (items.hasOwnProperty("Product Code") && items["Product Code"] !== undefined)
                productUniqueData.push(items["Product Code"])
        })
        let uniqueArrayCodes = [...new Set(productUniqueData)]
        uniqueArrayCodes.forEach(ItemCode => {
            let observedIndexList = []
            grtAnalysisData.forEach((items, index) => {
                if (items.hasOwnProperty("Product Code") && items["Product Code"] !== undefined) {
                    if (items["Product Code"] === (ItemCode)) {
                        observedIndexList.push(items["Index Score"])
                        c += 1
                    }

                }
            })
            if (max < c)
                max = c
            c = 1
            // console.log({ [ItemCode]: observedIndexList })
            indexListData.push({ [ItemCode]: observedIndexList })
        })

        setMaxObservation(max);

        let objFiltered = []
        grtAnalysisData.forEach((prodItem, index) => {
            if (prodItem["Product Code"] !== undefined)
                objFiltered.push(prodItem)
        })

        objFiltered = objFiltered.filter((v, i, a) => a.findIndex(t => (t["Product Code"] === v["Product Code"])) === i)
        // console.log(objFiltered)
        let itemDetailsFiltered = []

        objFiltered.forEach((items, index) => {

            indexListData.forEach(ItemCode => {
                if (items["Product Code"] === Object.keys(ItemCode)[0]) {
                    itemDetailsFiltered.push([items["Product Code"], items["Product Name"], items["Category"], items["sub_category"], items["brand"], items["supplier_name"], Object.values(ItemCode)[0]])
                }
            })
        })

        console.log(itemDetailsFiltered);

        let observedVal = []
        // console.log(max)
        for (let i = 0; i < Number(max) - 1; i++) {
            observedVal.push(`OI${i + 1}`)
        }

        console.log('chartttttttttttttttt dataffffff');
        console.log(indexListData);

        setProductNameFilter(itemDetailsFiltered);
        setSearchProductData(itemDetailsFiltered);
        setObsvIndexHeader(observedVal);
        setSearchObsvIndexHeader(observedVal);
        setChartFilterData(indexListData);
        setSearchChartData(indexListData);
  }


    const addChartdata = () => {
      console.log('addchartdata');
      console.log(chartFilterData);

      let chartFinalInfo = [];
      let chartHeaderDetails = [];
      let max=-1;
      chartHeaderDetails.push("X");

      for(let i=0;i<chartFilterData.length;i++){
        let itemChartData=chartFilterData[i];
        let itemid=Object.keys(itemChartData)[0];
        let observedindexes=Object.values(itemChartData)[0];
        chartHeaderDetails.push(itemid);

        let obsindexlength=observedindexes.length;
        if(obsindexlength>max){
          max=obsindexlength;
        }
      }

      chartFinalInfo.push(chartHeaderDetails);

      for(let j=0;j<max;j++){
        let itemData=[];
        itemData.push(j);

        chartFilterData.forEach(itemArrange => {
          itemData.push(Object.values(itemArrange)[0][j] !== undefined ? Number(Object.values(itemArrange)[0][j].split("%")[0]) : null)
        });

        chartFinalInfo.push(itemData);
      }

      console.log(chartFinalInfo);

      setBackDrop(false)
      setChartData(chartFinalInfo);

        // let chartFinalInfo = [["X", "OI1", "OI2"],[0, 101.6, 105],[1, 95.4,0]]

    }

    useEffect(() => {
        if (chartFilterData && obsvIndexHeader)
            addChartdata()
    }, [chartFilterData, obsvIndexHeader])


  const getResult = (criteria, thresh1, thresh2, sample) => {
      let result;
      if (criteria == 'Higher') {
          if (sample > thresh1) {
              result = "Accept";
          } else {
              if (sample > thresh2) {
                  result = "Warn";
              } else {
                  result = "Reject";
              }
          }
      } else {
          if (sample < thresh1) {
              result = "Accept";
          } else {
              if (sample < thresh2) {
                  result = "Warn";
              } else {
                  result = "Reject";
              }
          }
      }
      return result;
  }

  const getResultIndexScoreForTestSample = (updateDetails, prod_data) => {
      // console.log('getResultIndexScoreForTestSample');
      // console.log(updateDetails);
      // console.log(prod_data);
      let product_id = updateDetails['Product Code'];
      // console.log(product_id);
      let finalProdList = [];
      let parameterList = ['Avg Length (mm)', 'Avg Width (mm)', 'LW Ratio', 'Broken %'];
      if (product_id) {
          if (prod_data != undefined) {
              let index_score_sum = 0;
              for (let i = 0; i < 4; i++) {
                  let ele = {};
                  ele['Parameter'] = parameterList[i];
                  if (parameterList[i] == 'Avg Length (mm)') {
                      let length_threshold_per = (prod_data['length_threshold_per'] != undefined ? prod_data['length_threshold_per'] : "5%");
                      length_threshold_per = parseInt(length_threshold_per.substring(0, length_threshold_per.length - 1));
                      let standard_length = prod_data['length'];
                      standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                      let thresh1 = prod_data['length_threshold_abs'] ? parseFloat(prod_data['length_threshold_abs']) : standard_length * (1 + (length_threshold_per / 100));
                      thresh1 = Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                      let sample_length = updateDetails['Length'];
                      sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));
                      let criteria = "Higher";
                      let score = sample_length / standard_length;
                      score = Math.round((score + Number.EPSILON) * 100);

                      ele['Sample Value'] = updateDetails['Length'];
                      ele['Standard Value'] = prod_data['length'];
                      ele['Criteria'] = criteria;


                      ele['Threshold Range 1'] = thresh1;

                      let length_threshold_per2 = (prod_data['length_threshold_per2'] != undefined ? prod_data['length_threshold_per2'] : "10%");
                      length_threshold_per2 = parseInt(length_threshold_per2.substring(0, length_threshold_per2.length - 1));
                      let thresh2 = prod_data['length_threshold_abs2'] ? parseFloat(prod_data['length_threshold_abs2']) : standard_length * (1 + (length_threshold_per2 / 100));
                      thresh2 = Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                      let result = getResult(criteria, thresh1, thresh2, sample_length)
                      let weightage = (prod_data['length_weightage_per'] != undefined ? prod_data['length_weightage_per'] : "10%");
                      weightage = parseInt(weightage.substring(0, weightage.length - 1));
                      index_score_sum = index_score_sum + (score * weightage);

                      ele['Threshold Range 2'] = thresh2;
                      ele['Score'] = score + '%';
                      ele['Weightage'] = weightage;
                      ele['Result'] = result;
                  } else if (parameterList[i] == 'Avg Width (mm)') {
                      let width_threshold_per = (prod_data['width_threshold_per'] != undefined ? prod_data['width_threshold_per'] : "3%");
                      width_threshold_per = parseInt(width_threshold_per.substring(0, width_threshold_per.length - 1));
                      let standard_width = prod_data['width'];
                      standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));
                      let thresh1 = prod_data['width_threshold_abs'] ? parseFloat(prod_data['width_threshold_abs']) : standard_width * (1 + (width_threshold_per / 100));
                      thresh1 = Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                      let sample_width = updateDetails['Width'];
                      sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));
                      let criteria;
                      let score;
                      if (prod_data['sub_category'] == "Basmati Rice") {
                          criteria = "Higher";
                          score = sample_width / standard_width;
                          score = Math.round((score + Number.EPSILON) * 100);
                      } else {
                          criteria = "Lower";
                          score = standard_width / sample_width;
                          score = Math.round((score + Number.EPSILON) * 100);
                      }

                      ele['Sample Value'] = updateDetails['Width'];
                      ele['Standard Value'] = prod_data['width'];
                      ele['Criteria'] = criteria;


                      ele['Threshold Range 1'] = thresh1;

                      let width_threshold_per2 = (prod_data['width_threshold_per2'] != undefined ? prod_data['width_threshold_per2'] : "5%");
                      width_threshold_per2 = parseInt(width_threshold_per2.substring(0, width_threshold_per2.length - 1));
                      let thresh2 = prod_data['width_threshold_abs2'] ? parseFloat(prod_data['width_threshold_abs2']) : standard_width * (1 + (width_threshold_per2 / 100));
                      thresh2 = Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                      let result = getResult(criteria, thresh1, thresh2, sample_width)


                      let weightage = (prod_data['width_weightage_per'] != undefined ? prod_data['width_weightage_per'] : "35%");
                      weightage = parseInt(weightage.substring(0, weightage.length - 1));
                      index_score_sum = index_score_sum + (score * weightage);

                      ele['Threshold Range 2'] = thresh2;
                      ele['Score'] = score + '%';
                      ele['Weightage'] = weightage;
                      ele['Result'] = result;
                  } else if (parameterList[i] == 'LW Ratio') {
                      let length_width_threshold_per = (prod_data['length_width_threshold_per'] != undefined ? prod_data['length_width_threshold_per'] : "3%");
                      length_width_threshold_per = parseInt(length_width_threshold_per.substring(0, length_width_threshold_per.length - 1));
                      let standard_length = prod_data['length'];
                      standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                      let standard_width = prod_data['width'];
                      standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));

                      let standard_length_width = standard_length / standard_width;  // TO BE DONE ---------------------------------------------------------------------------
                      standard_length_width = Math.round((standard_length_width + Number.EPSILON) * 100) / 100;
                      let thresh1 = prod_data['length_width_threshold_abs'] ? parseFloat(prod_data['length_width_threshold_abs']) : standard_length_width * (1 + (length_width_threshold_per / 100));
                      thresh1 = Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                      let sample_length_width = parseFloat(updateDetails['LW Ratio']);
                      let criteria = "Higher";
                      let score = sample_length_width / standard_length_width;
                      score = Math.round((score + Number.EPSILON) * 100);

                      ele['Sample Value'] = updateDetails['LW Ratio'];
                      ele['Standard Value'] = standard_length_width;
                      ele['Criteria'] = criteria;
                      ele['Threshold Range 1'] = thresh1;

                      let length_width_threshold_per2 = (prod_data['length_width_threshold_per2'] != undefined ? prod_data['length_width_threshold_per2'] : "5%");
                      length_width_threshold_per2 = parseInt(length_width_threshold_per2.substring(0, length_width_threshold_per2.length - 1));
                      let thresh2 = prod_data['length_width_threshold_abs2'] ? parseFloat(prod_data['length_width_threshold_abs2']) : standard_length_width * (1 + (length_width_threshold_per2 / 100));
                      thresh2 = Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                      let result = getResult(criteria, thresh1, thresh2, sample_length_width)

                      let weightage = (prod_data['length_width_weightage_per'] != undefined ? prod_data['length_width_weightage_per'] : "40%");
                      weightage = parseInt(weightage.substring(0, weightage.length - 1));
                      index_score_sum = index_score_sum + (score * weightage);

                      ele['Threshold Range 2'] = thresh2;
                      ele['Score'] = score + '%';
                      ele['Weightage'] = weightage;
                      ele['Result'] = result;
                  } else if (parameterList[i] == 'Broken %') {
                      let broken_threshold_per = (prod_data['broken_threshold_per'] != undefined ? prod_data['broken_threshold_per'] : "5%");
                      broken_threshold_per = parseFloat(broken_threshold_per.substring(0, broken_threshold_per.length - 1));
                      let standard_broken_perc = prod_data['broken_grain_percentage'];
                      standard_broken_perc = parseFloat(standard_broken_perc.substring(0, standard_broken_perc.length - 1));
                      let thresh1 = broken_threshold_per;

                      let sample_broken_perc = updateDetails['BrokenGrainPerc'];
                      sample_broken_perc = parseFloat(sample_broken_perc.substring(0, sample_broken_perc.length - 1));
                      let criteria = "Lower";;
                      let score = sample_broken_perc > 0 ? standard_broken_perc / sample_broken_perc : sample_broken_perc;
                      score = Math.round((score + Number.EPSILON) * 100);

                      ele['Sample Value'] = updateDetails['BrokenGrainPerc'];
                      ele['Standard Value'] = prod_data['broken_grain_percentage'];
                      ele['Criteria'] = criteria;
                      ele['Threshold Range 1'] = thresh1;

                      let broken_threshold_per2 = (prod_data['broken_threshold_per2'] != undefined ? prod_data['broken_threshold_per2'] : "20%");
                      broken_threshold_per2 = parseFloat(broken_threshold_per2.substring(0, broken_threshold_per2.length - 1));
                      let thresh2 = broken_threshold_per2;
                      let result = getResult(criteria, thresh1, thresh2, sample_broken_perc)

                      let weightage = (prod_data['broken_weightage_per'] != undefined ? prod_data['broken_weightage_per'] : "15%");
                      weightage = parseInt(weightage.substring(0, weightage.length - 1));

                      if (updateDetails.sub_category == 'Basmati Rice') {
                          ele['Threshold Range 2'] = thresh2;
                          ele['Score'] = 'N/A';
                          ele['Weightage'] = 'N/A';
                          ele['Result'] = 'N/A';
                      } else {
                          index_score_sum = index_score_sum + (score * weightage);
                          ele['Threshold Range 2'] = thresh2;
                          ele['Score'] = score + '%';
                          ele['Weightage'] = weightage;
                          ele['Result'] = result;
                      }
                  }
                  finalProdList.push(ele);
              }
              // console.log(finalProdList);
              let overallResult;
              let accept_counter = 0;
              let warn_counter = 0;
              let reject_counter = 0;
              for (let x = 0; x < finalProdList.length; x++) {
                  if (!(finalProdList[x]['Parameter'] == "Broken %" && updateDetails.sub_category == 'Basmati Rice')) {
                      let orig_result = finalProdList[x]['Result'];
                      if (orig_result == "Accept") {
                          accept_counter = accept_counter + 1;
                      } else if (orig_result == "Warn") {
                          warn_counter = warn_counter + 1;
                      } else if (orig_result == "Reject") {
                          reject_counter = reject_counter + 1;
                      }
                  }
              }

              if (updateDetails.sub_category == 'Basmati Rice') {
                  if (reject_counter > 0) {
                      overallResult = "Reject";
                  } else if (accept_counter > 1) {
                      overallResult = "Accept";
                  } else {
                      overallResult = "Warn";
                  }
              } else {
                  if (reject_counter > 0) {
                      overallResult = "Reject";
                  } else if (accept_counter > 2) {
                      overallResult = "Accept";
                  } else {
                      overallResult = "Warn";
                  }
              }


              index_score_sum = index_score_sum / 100;
              index_score_sum = Math.round((index_score_sum + Number.EPSILON) * 100) / 100;
              // console.log('index_score_sum'+index_score_sum);

              // setModalHeaderContent({'overall_index_score':index_score_sum+'%','overall_result':overallResult,'sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name']});
              // setGrtProdData(finalProdList);
              let updateOrigGrtSampleRowValues = updateDetails;
              updateOrigGrtSampleRowValues['Index Score'] = index_score_sum + '%';
              updateOrigGrtSampleRowValues['Result'] = overallResult;
              return [index_score_sum + '%', overallResult];
              // props.callback(updateOrigGrtSampleRowValues);
          } else {
              console.log('invalid product id');
              return ['N/A', 'N/A'];
              // setModalHeaderContent({'overall_index_score':'','overall_result':'','sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name']});
              // setGrtProdData([]);
          }
      } else {
          //product_id is not available
          // setModalHeaderContent({'overall_index_score':'N/A','overall_result':'N/A','sample_id':updateDetails['Sample ID'],'product_name':updateDetails['Product Name'],'category':updateDetails['Category'],'product_id':updateDetails['Product Code'],'supplier_name':updateDetails['supplier_name']});
          let defaultProdList = [];
          for (let i = 0; i < 4; i++) {
              let ele = {};
              ele['Parameter'] = parameterList[i];
              //['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %']
              if (parameterList[i] == 'Avg Length (mm)') {
                  ele['Sample Value'] = updateDetails['Length'];
              } else if (parameterList[i] == 'Avg Width (mm)') {
                  ele['Sample Value'] = updateDetails['Width'];
              } else if (parameterList[i] == 'LW Ratio') {
                  ele['Sample Value'] = updateDetails['LW Ratio'];
              } else if (parameterList[i] == 'Broken %') {
                  ele['Sample Value'] = updateDetails['BrokenGrainPerc'];
              }

              ele['Standard Value'] = 'N/A';
              ele['Threshold Range 1'] = 'N/A';
              ele['Threshold Range 2'] = 'N/A';
              ele['Score'] = 'N/A';
              ele['Result'] = 'N/A';
              defaultProdList.push(ele);
              return ['N/A', 'N/A'];
              // setGrtProdData(defaultProdList);
          }
      }
  }

  //setProductTestData
  const fetchProducts = (startDate,endDate) => {
      console.log(startDate,endDate)
      let d = new Date(startDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      let finalDate = day + '-' + month + '-' + year;
      // console.log(finalDate);
      let finalDateYMD = year + '-' + month + '-' + day;
      console.log(finalDateYMD);

      let ed = new Date(endDate),
          emonth = '' + (ed.getMonth() + 1),
          eday = '' + ed.getDate(),
          eyear = ed.getFullYear();

      let finalEndDate = eday + '-' + emonth + '-' + eyear;
      // console.log(finalEndDate);
      let finalEndDateYMD = eyear + '-' + emonth + '-' + eday;
      console.log(finalEndDateYMD);

      let sampleIdSet = new Set();
      let finalProductList = [];
      Promise.all([
          qualitydb.database().ref(`Product_Grading_Result/`).once('value'),
          qualitydb.database().ref(`Testing_Product_Details/`).once('value'),
          qualitydb.database().ref(`Product_Master_Grading/`).once('value'),
          database.database().ref(`Product_Base_Data/`).once('value')
      ]).then(([snapshot, snap, prodsnapshot, prodbasesnapshot]) => {

          //Product_Base_Data
          // TestType -> Product_Grading_Result -> Compare
          // TestType -> Testing_Product_Details -> Analyse

          let prodtest_grading = snapshot.val();
          let test_prod_dets = snap.val();
          let productmaster = prodsnapshot.val();
          let prodbase = prodbasesnapshot.val();
          // console.log(prodbase);


          if (prodtest_grading) {
              Object.keys(prodtest_grading).map((sdkey, sdindex) => {
                  let sdkeylist = sdkey.split('-');
                  let prod_grading = prodtest_grading[sdkey];
                  let sdkeyinymdformat = sdkeylist[2] + '-' + sdkeylist[1] + '-' + sdkeylist[0];
                  if (sdkeyinymdformat >= finalDateYMD && sdkeyinymdformat <= finalEndDateYMD) {
                      if (prod_grading) {
                          Object.keys(prod_grading).map((prodkey, index) => {
                              let prod_grading_phone = prod_grading[prodkey];
                              Object.keys(prod_grading_phone).map((pkey, ind) => {
                                  if (prod_grading_phone[pkey]['sample_id'] && !sampleIdSet.has(prod_grading_phone[pkey]['sample_id'])) {
                                      if (prod_grading_phone[pkey]['product_id']) {
                                          Object.keys(productmaster).map((prmkey, prmindex) => {
                                              if (productmaster[prmkey]['itemnid'] == prod_grading_phone[pkey]['product_id'] && !sampleIdSet.has(prod_grading_phone[pkey]['sample_id'])) {
                                                  Object.keys(prodbase).map((prbasekey, prbaseindex) => {
                                                      if (prbasekey == prod_grading_phone[pkey]['product_id']) {
                                                          let ele = {};
                                                          ele['Testing Date'] = sdkey + ' ' + prod_grading_phone[pkey]['time'];
                                                          ele['Sample ID'] = prod_grading_phone[pkey]['sample_id'];
                                                          ele['Test Type'] = 'Compare';
                                                          ele['Category'] = prod_grading_phone[pkey]['category'];
                                                          ele['Product Code'] = prod_grading_phone[pkey]['product_id'];
                                                          ele['Product Name'] = prod_grading_phone[pkey]['description'];
                                                          ele['Width'] = prod_grading_phone[pkey]['average_length'];
                                                          ele['Length'] = prod_grading_phone[pkey]['average_width'];
                                                          let grainlength = prod_grading_phone[pkey]['average_width'];
                                                          let grainwidth = prod_grading_phone[pkey]['average_length'];
                                                          grainlength = parseFloat(grainlength.substring(0, grainlength.length - 2));
                                                          grainwidth = parseFloat(grainwidth.substring(0, grainwidth.length - 2));
                                                          let length_width_ratio = grainlength / grainwidth;
                                                          length_width_ratio = Math.round((length_width_ratio + Number.EPSILON) * 100) / 100;
                                                          ele['LW Ratio'] = (prod_grading_phone[pkey]['average_grain_length_width_ratio'] ? prod_grading_phone[pkey]['average_grain_length_width_ratio'] : length_width_ratio);
                                                          ele['BrokenGrainPerc'] = prod_grading_phone[pkey]['broken_grain_percentage'];
                                                          ele['supplier_name'] = prod_grading_phone[pkey]['supplier_name'];
                                                          ele['brand'] = prodbase[prbasekey]['brand'];
                                                          ele['sub_category'] = prodbase[prbasekey]['sub_category'];
                                                          ele['uom'] = prodbase[prbasekey]['uom'];
                                                          let scores = prod_grading_phone[pkey]['scores'];
                                                          ele['scores'] = scores;
                                                          let latest_score_ts = Object.keys(scores).sort().reverse()[0]
                                                          if (latest_score_ts && scores[latest_score_ts].overall) {
                                                            ele['Index Score'] = scores[latest_score_ts].overall.score;
                                                            ele['Result'] = scores[latest_score_ts].overall.result;
                                                          } else {
                                                            ele['Index Score'] = 'N/A';
                                                            ele['Result'] = 'N/A';
                                                          }
                                                        //   let reslist = getResultIndexScoreForTestSample(ele, productmaster[prmkey]);
                                                        //   if (reslist) {
                                                        //       ele['Index Score'] = reslist[0];
                                                        //       ele['Result'] = reslist[1];
                                                        //   } else {
                                                        //       ele['Index Score'] = 'N/A';
                                                        //       ele['Result'] = 'N/A';
                                                        //   }
                                                          sampleIdSet.add(prod_grading_phone[pkey]['sample_id']);
                                                          finalProductList.push(ele);
                                                      }
                                                  });
                                              }
                                          });
                                      } else {
                                          let ele = {};
                                          ele['Testing Date'] = finalDate + ' ' + prod_grading_phone[pkey]['time'];
                                          ele['Sample ID'] = prod_grading_phone[pkey]['sample_id'];
                                          ele['Test Type'] = 'Compare';
                                          ele['Category'] = prod_grading_phone[pkey]['category'];
                                          ele['Product Code'] = prod_grading_phone[pkey]['product_id'];
                                          ele['Product Name'] = prod_grading_phone[pkey]['description'];
                                          ele['Width'] = prod_grading_phone[pkey]['average_length'];
                                          ele['Length'] = prod_grading_phone[pkey]['average_width'];
                                          let grainlength = prod_grading_phone[pkey]['average_width'];
                                          let grainwidth = prod_grading_phone[pkey]['average_length'];
                                          grainlength = parseFloat(grainlength.substring(0, grainlength.length - 2));
                                          grainwidth = parseFloat(grainwidth.substring(0, grainwidth.length - 2));
                                          let length_width_ratio = grainlength / grainwidth;
                                          length_width_ratio = Math.round((length_width_ratio + Number.EPSILON) * 100) / 100;
                                          ele['LW Ratio'] = (prod_grading_phone[pkey]['average_grain_length_width_ratio'] ? prod_grading_phone[pkey]['average_grain_length_width_ratio'] : length_width_ratio);
                                          ele['BrokenGrainPerc'] = prod_grading_phone[pkey]['broken_grain_percentage'];
                                          ele['supplier_name'] = prod_grading_phone[pkey]['supplier_name'];
                                          ele['Index Score'] = 'N/A';
                                          ele['Result'] = 'N/A';
                                          ele['brand'] = '';
                                          ele['sub_category'] = '';
                                          ele['uom'] = '';
                                          sampleIdSet.add(prod_grading_phone[pkey]['sample_id']);
                                          finalProductList.push(ele);
                                      }
                                  }

                              });
                          });
                      }
                  }
              });
          }


          // console.log(test_prod_dets);
          let test_prod_dummy = [];
          Object.keys(test_prod_dets).map((sdtkey, sdtindex) => {
              let test_prod_year_dets = test_prod_dets[sdtkey];
              Object.keys(test_prod_year_dets).map((ysdtkey, ysdtindex) => {
                  let test_prod_year_date_dets = test_prod_year_dets[ysdtkey];
                  Object.keys(test_prod_year_date_dets).map((dysdtkey, dysdtindex) => {
                      let ele = {};
                      let datekkkeyarr = dysdtkey.split('-');
                      let datekeeey = datekkkeyarr[2] + '-' + datekkkeyarr[1] + '-' + datekkkeyarr[0];
                      ele[datekeeey] = test_prod_year_date_dets[dysdtkey];
                      test_prod_dummy.push(ele);
                  });
              });
          });

          // console.log('test_prod');
          // console.log(test_prod_dummy);

          if (test_prod_dummy) {
              Object.keys(test_prod_dummy).map((productttkey, ttindeex) => {
                  let test_prod_date = test_prod_dummy[productttkey];
                  Object.keys(test_prod_date).map((produuctkey, indneex) => {
                      let test_prod = test_prod_date[produuctkey];
                      if (produuctkey >= finalDateYMD && produuctkey <= finalEndDateYMD) {
                          Object.keys(test_prod).map((productkey, indeex) => {
                              if (test_prod[productkey]['sample_id'] && !sampleIdSet.has(test_prod[productkey]['sample_id'])) {
                                  if (test_prod[productkey]['product_id']) {
                                      Object.keys(productmaster).map((prmkey, prmindex) => {
                                          if (productmaster[prmkey]['itemnid'] == test_prod[productkey]['product_id'] && !sampleIdSet.has(test_prod[productkey]['sample_id'])) {
                                              Object.keys(prodbase).map((prbasekey, prbaseindex) => {
                                                  if (prbasekey == test_prod[productkey]['product_id']) {
                                                      let el = {};
                                                      el['Testing Date'] = produuctkey + ' ' + test_prod[productkey]['time'];
                                                      el['Sample ID'] = test_prod[productkey]['sample_id'];
                                                      el['Test Type'] = 'Analyze';
                                                      el['Category'] = test_prod[productkey]['category'];
                                                      el['Product Code'] = test_prod[productkey]['product_id'];
                                                      el['Product Name'] = '';
                                                      el['Width'] = test_prod[productkey]['average_height'];
                                                      el['Length'] = test_prod[productkey]['average_width'];
                                                      el['LW Ratio'] = test_prod[productkey]['average_grain_length_width_ratio'];
                                                      el['BrokenGrainPerc'] = test_prod[productkey]['broken_rice_percentage'];
                                                      el['supplier_name'] = test_prod[productkey]['supplier_name'];
                                                      el['brand'] = prodbase[prbasekey]['brand'];
                                                      el['sub_category'] = prodbase[prbasekey]['sub_category'];
                                                      el['uom'] = prodbase[prbasekey]['uom'];
                                                      let reslist = getResultIndexScoreForTestSample(el, productmaster[prmkey]);
                                                      if (reslist) {
                                                          el['Index Score'] = reslist[0];
                                                          el['Result'] = reslist[1];
                                                      } else {
                                                          el['Index Score'] = 'N/A';
                                                          el['Result'] = 'N/A';
                                                      }
                                                      sampleIdSet.add(test_prod[productkey]['sample_id']);
                                                      finalProductList.push(el);
                                                  }
                                              });
                                          }
                                      });
                                  } else {
                                      let el = {};
                                      el['Testing Date'] = produuctkey + ' ' + test_prod[productkey]['time'];
                                      el['Sample ID'] = test_prod[productkey]['sample_id'];
                                      el['Test Type'] = 'Analyze';
                                      el['Category'] = test_prod[productkey]['category'];
                                      el['Product Code'] = test_prod[productkey]['product_id'];
                                      el['Product Name'] = '';
                                      el['Width'] = test_prod[productkey]['average_height'];
                                      el['Length'] = test_prod[productkey]['average_width'];
                                      el['LW Ratio'] = test_prod[productkey]['average_grain_length_width_ratio'];
                                      el['BrokenGrainPerc'] = test_prod[productkey]['broken_rice_percentage'];
                                      el['supplier_name'] = test_prod[productkey]['supplier_name'];
                                      el['brand'] = '';
                                      el['sub_category'] = '';
                                      el['uom'] = '';
                                      el['Index Score'] = 'N/A';
                                      el['Result'] = 'N/A';
                                      sampleIdSet.add(test_prod[productkey]['sample_id']);
                                      finalProductList.push(el);
                                  }
                              }
                          });
                      }
                  });
              });
          }
          console.log("ProductList");
          console.log(finalProductList);
          setGrtAnalysisData(finalProductList);
          setBackDrop(false);

      }).catch((err) => {
          console.log(err);
      });

  }


  const CustomDateInput = ({ value, onClick }) => (
      <input
          value={value}
          onClick={onClick}
          style={{ border: "solid 1px black" }}
      />
  );

  const handleKeyPress = (e) => {
    setCategoryText(null);
    setSupplierText(null);
    setSubCategoryText(null);
    setProductSkuText(null);
    setSearchText(e.target.value);
  };



    return (
      <>
      <NavBar />
      <div style={{ paddingTop: 100, marginRight: 20, marginLeft: 20, textAlign: "center" }}>
          <h2>
          <Badge variant="secondary" style={{ color: "#dee2e6", backgroundColor: "#1a20a3" }}>GRT Insights</Badge>
          </h2>
          <Navbar>
          <Form inline style={{ margin: "30px auto 0", maxWidth: "700px" }}>
            <Badge variant="light" style={{ margin: 20 }}>Start Date </Badge>
            <DatePicker
                selected={startDate}
                onChange={(val) => { setStartDate(val) }}
                minDate={new Date("2017/01/01")}
                maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
            />
            <Badge variant="light" style={{ margin: 20 }}>End Date </Badge>
            <DatePicker
                selected={endDate}
                onChange={(val) => { setEndDate(val) }}
                minDate={new Date("2017/01/01")}
                maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
            />
            <Row>
            <InputGroup style={{ margin: "20px",margin: "auto", textAlign: "center" }}>
                <Form.Control id="searchProduct" style={{ width: "600px", textAlign: "center"  }} className="searchBox" placeholder="Search: Category / Sub-Category / Brand / Supplier" value={searchText} onChange={(e)=>{ handleKeyPress(e); }} />
            </InputGroup>
                <div size="sm" style={{ margin: "20px auto 0px", width: "100%", maxWidth: "600px" }} >
                    <ReactSelect size="sm" placeholder={"Product - All"} isClearable={"true"} isMulti onChange={(val) => { setProductSkuText(val); }} options={productSkuList} />
                </div>            
                <div size="sm" style={{ margin: "20px auto 0px", width: "100%", maxWidth: "200px"}}>
                    <ReactSelect size="sm" placeholder={"Category - All"} isClearable={"true"} onChange={(val) => { setCategoryText(val?val.value:""); }} options={categoryList} />
                </div>
                <div size="sm" style={{ margin: "20px auto 0px", width: "100%", maxWidth: "200px" }}>
                    <ReactSelect size="sm" placeholder={"SubCategory - All"} isClearable={"true"} onChange={(val) => { setSubCategoryText(val?val.value:""); }} options={subCategoryList} />
                </div>
                <div size="sm" style={{ margin: "20px auto 0px", width: "100%", maxWidth: "600px" }}>
                    <ReactSelect size="sm" placeholder={"Supplier - All"} isClearable={"true"} isMulti onChange={(val) => { setSupplierText(val) }} options={supplierList} />
                </div>
            </Row>
            </Form>
          </Navbar>
          <div>
              {chartData && <Chart
                  width={1000}
                  height={500}
                  chartType="LineChart"
                  style={{ margin: "auto"}}
                  loader={<div>Loading Chart</div>}
                  data={chartData && chartData}
                  options={{
                      title: 'GRT Reports Insights',
                      chartArea: { width: '70%' },
                      hAxis: {
                          title: 'Test Results (No.)',
                          minValue: 0,
                      },
                      vAxis: {
                          title: 'Overall Index values (%)',
                      },
                      legend: 'none'
                  }}
              />}
          </div>

          {(productNameFilter.length>0)?(<GrtAnalysisTable grtProdData={productNameFilter} obsvIndexHeader={obsvIndexHeader} />):((getBackDrop)?(""):(<div>No products found</div>))}

      </div>
      {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />):null}

      </>
    )
}

export default GrtAnalysisList
