import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import { Badge, Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, Modal, ButtonGroup, Table } from 'react-bootstrap'
import Select from 'react-select';
import database from '../../service/firebaseConfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { toast } from 'react-toastify';
import Backdrop from '../../CommonRequirements/backdrop'
import baseUrl from '../../service/servicesConfig';
import { validateIsAdminUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';

const Product_Base = database.database().ref(`Product_Base_Data`)


function ConversionEntriesModal({ dataItems, childDataItems, ...props }) {

    const [currentDate, setCurrentDate] = useState(new Date(dataItems.stock_transfer_date))
    const [parentNameList, setParentNameList] = useState("")
    const [itemCode, setItemCode] = useState("")
    const [childItemName, setChildItemName] = useState(childDataItems.child_item_name)
    const [childItemCode, setChildItemCode] = useState(childDataItems.child_item_code)
    const [childWeight, setChildWeight] = useState(childDataItems.child_weight)
    const [actualChildWeight, setActualChildWeight] = useState("")
    const [childUoM, setChildUoM] = useState(childDataItems.uom)
    const [outerSize, setOuterSize] = useState(dataItems.outer_size)
    const [uomVal, setUomVal] = useState("")
    const [stockTransfer, setStockTransfer] = useState("")
    const [category, setCategory] = useState("")
    const [bagWeightLoss, setBagWeightLoss] = useState(dataItems.bag_weight_loss)
    const [getBackDrop, setBackDrop] = useState(false)
    const [stockTransferTotalWt, setStockTransferTotalWt] = useState("")
    const [itemName, setItemName] = useState("")
    const [childStock, setChildStock] = useState(childDataItems.child_stock_production)
    const [wastage, setWastage] = useState(childDataItems.wastage)
    const [articleCodesList, setArticleCodesList] = useState([])
    const [previouslySelected, setPreviouslySelected] = useState("")
    const [productBaseData, setProductBaseData] = useState({})
    const [childOuterSize, setChildOuterSize] = useState(childDataItems.child_outer_size)
    const [systemInventory, setSystemInventory] = useState(0)
    const [stkConvEntryClose, setStkConvEntryClose] = useState(false);

    const { control, register, errors, getValues, handleSubmit, watch } = useForm();

    let articleCodeSelected = watch("child_item_name")

    console.log('data: ', dataItems);
    console.log('childDataItems: ', childDataItems);

    const getActualChildWeight = (outer_size, weight, uom) => {
        if (uom.toLowerCase() == 'pc' && weight.toString().includes("gm")) {
            outer_size = weight.replace(/[^0-9]/g, '') / 1000;
        } else if (uom.toLowerCase() == 'pc' && weight.toString().toLowerCase().includes("kg")) {
            outer_size = weight.replace(/[^0-9]/g, '');
        }
        return outer_size;
    }

    useEffect(() => {

        setActualChildWeight(getActualChildWeight(childOuterSize, childWeight, childUoM));
    }, [])

    useEffect(() => {
        const parentItemCode = dataItems.parent_code;
        setBackDrop(true);
        const prdBaseData = {};
        Product_Base.once("value", snapShot => {

            let articleCodesArray = []
            const articleCodeList = snapShot.val()
            Object.keys(articleCodeList).forEach(articleCode => {
                prdBaseData[articleCode] = { ...articleCodeList[articleCode] }
                if (articleCodeList[articleCode].parent_id && articleCodeList[articleCode].parent_id.includes(parentItemCode))
                    articleCodesArray.push({ value: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description, label: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description })
                if (articleCodeList[articleCode].parent_id1 && articleCodeList[articleCode].parent_id1.includes(parentItemCode))
                    articleCodesArray.push({ value: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description, label: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description })


            })
            setProductBaseData(prdBaseData);
            setArticleCodesList(articleCodesArray)
            setBackDrop(false)
        })
    }, [])

    useEffect(() => {
        if (watch("parent_name")) {
            let parentItemSelected = watch("parent_name")
            Product_Base.once("value", snapShot => {

                const productDetails = snapShot.val()
                Object.keys(productDetails).forEach(itemCode => {
                    let item_description = productDetails[itemCode].brand ? productDetails[itemCode].brand + " | " + productDetails[itemCode].long_description : productDetails[itemCode].long_description;
                    if (parentItemSelected.value.includes(item_description)) {
                        setCategory(productDetails[itemCode].category)
                        setItemCode(itemCode)
                        setOuterSize(productDetails[itemCode].outer_size)
                        setUomVal(productDetails[itemCode].uom)
                    }
                })
            })
        }
    }, [watch("parent_name")])

    /*useEffect(() => {
        if (stockTransfer) {
            setStockTransferTotalWt(outerSize && outerSize * stockTransfer)
            setBagWeightLoss("0")
        }
    }, [stockTransfer])*/

    /*useEffect(() => {
        let stockTransferdata = stockTransfer
        if (!stockTransfer) {
            stockTransferdata = dataItems.stock_transfer;
        }
        if (stockTransferTotalWt) {
            setBagWeightLoss(outerSize && (outerSize * stockTransferdata - stockTransferTotalWt))
        }

    }, [stockTransferTotalWt])*/


    useEffect(() => {
        setBackDrop(true)
        Product_Base.once("value", snapShot => {

            let articleCodesArray = []
            const articleCodeList = snapShot.val()
            Object.keys(articleCodeList).forEach(articleCode => {
                if (articleCodeList[articleCode]["buying_flag"] == 'Y')
                    articleCodesArray.push({ articleCode: articleCode, value: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description, label: articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description })
            })
            setParentNameList(articleCodesArray)
            setBackDrop(false)
        })
    }, [])

    useEffect(() => {
        console.log("useEffect itemCode: ", itemCode);

        const si_item_id = dataItems.parent_code;
        if (si_item_id) {
            setBackDrop(true)
            let calcDate = currentDate ? currentDate : new Date()
            calcDate = `${calcDate.getFullYear()}-${calcDate.getMonth() + 1}-${calcDate.getDate()}`

            baseUrl.get(`/api/superzop/systeminventory/getsysteminventory?date=${calcDate}&item_id=${si_item_id}`).then(response => {
                if (response.status === 200) {
                    return response.data;
                }
            }).then(data => {
                setBackDrop(false);
                console.log(data);
                setSystemInventory(data[0].system_inventory);
            }).catch(err => {
                setBackDrop(false);
                console.log(err);
                setSystemInventory(0);
            })
        }

        stockConversionEntryDateChange();

    }, [currentDate])

    const insertDomValue = (value) => {

        console.log(value)

        Product_Base.once("value", snapShot => {

            let itemDetailsArray = []
            const articleCodeList = snapShot.val()
            Object.keys(articleCodeList).forEach(articleCode => {

                if (articleCode.includes(value)) {

                    setCategory(articleCodeList[articleCode].category)
                    setItemName(articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description)
                    setOuterSize(articleCodeList[articleCode].outer_size)
                    setUomVal(articleCodeList[articleCode].uom)

                }

            })
            setBackDrop(false)
        })
    }

    const onSubmitStockConversion = data => {

        if (stkConvEntryClose && !validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) {

            return toast("Edit is closed for this date", { type: toast.TYPE.ERROR });
        } else if (bagWeightLoss < 0) {
            return toast("Stock Transfer Total Weight can't be more then Stock Transfer", { type: toast.TYPE.ERROR });
        } else {
            let newDate = currentDate ? currentDate : new Date()

            const currYear = newDate.getFullYear();
            const currMonth = newDate.getMonth().toString().length === 1 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
            const currDate = newDate.getDate().toString().length === 1 ? `0${newDate.getDate()}` : newDate.getDate();

            newDate = `${currYear}-${currMonth}-${currDate}`;
            let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)

            data["stock_transfer_date"] = newDate
            data["updated_by"] = loggedEmailId
            data["parent_name"] = data["parent_name"] ? data["parent_name"].value : data["parent_name_included"]
            data["parent_code"] = itemCode ? itemCode : dataItems.parent_code
            data["major_category"] = category ? category : dataItems.major_category
            data["stock_transfer"] = stockTransfer ? stockTransfer : dataItems.stock_transfer
            data["stock_transfer_total_weight"] = stockTransferTotalWt ? stockTransferTotalWt : dataItems.stock_transfer_total_weight
            data["bag_weight_loss"] = bagWeightLoss
            data["outer_size"] = outerSize ? outerSize : dataItems.outer_size
            data["uom"] = uomVal ? uomVal : dataItems.uom
            if (data["parent_name_included"])
                delete data["parent_name_included"]

            //console.log(JSON.stringify(data))
            //console.log(dataItems.id)

            const item_id = itemCode ? itemCode : dataItems.parent_code;

            const old_stock_transfer_date = dataItems.stock_transfer_date;
            //const old_stock_transfer = dataItems.stock_transfer;
            const old_stock_transfer = dataItems.stock_transfer_total_weight;

            console.log('old_stock_transfer_date: ', old_stock_transfer_date, ' newDate: ', newDate);

            data["id"] = dataItems.id;
            data["old_stock_transfer_date"] = old_stock_transfer_date;
            data["old_stock_transfer"] = old_stock_transfer;

            console.log('onSubmitStockConversion: ', data);
            if (old_stock_transfer) {

                const url = `/api/superzop/commercial/stockconversion/editstockconversionandsi?type=edit`;

                baseUrl.post(url, data).then(res => {

                    console.log('PushPurchaseDetails ', res);

                    //resolve("Success");

                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 2000);

                    onSubmitConvertedStock(data);

                    toast('Edited Stock Conversion and Synced System Inventory', { type: toast.TYPE.SUCCESS });

                }).catch((error) => {

                    console.error(error);
                    //reject(error);
                    return toast('Failed to Edit', { type: toast.TYPE.SUCCESS });
                });
            } else if (childDataItems) {
                onSubmitConvertedStock(data);
            }
        }
    }

    const onSubmitConvertedStock = data => {

        let newDate = currentDate ? currentDate : new Date()
        newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
        let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
        data["date_of_packing"] = newDate
        data["updated_by"] = loggedEmailId
        data["parent_item_code"] = itemCode ? itemCode : childDataItems.parent_item_code
        //data["major_category"] = majorCat ? majorCat : childDataItems.major_category
        data["child_item_name"] = childItemName ? childItemName : childDataItems.child_item_name
        data["child_item_code"] = childItemCode ? childItemCode : childDataItems.child_item_code
        data["child_weight"] = childWeight ? childWeight : childDataItems.child_weight
        data["child_item_code"] = childItemCode ? childItemCode : childDataItems.child_item_code
        data["child_stock_production"] = childStock ? childStock : childDataItems.child_stock_production
        data["total_weight_packed"] = childWeight && childStock ? childWeight * childStock : childDataItems.total_weight_packed
        data["wastage"] = wastage ? wastage : childDataItems.wastage
        data["converted_stock"] = actualChildWeight ? wastage ? (actualChildWeight * childStock) + Number(wastage) : (actualChildWeight * childStock) : childDataItems.converted_stock
        data["id"] = childDataItems.id
        data["old_date_of_packing"] = childDataItems.date_of_packing
        data["old_total_weight_packed"] = childDataItems.total_weight_packed
        console.log(data);
        console.log("Updation ID:::" + dataItems.id)
        // push to API 

        console.log('onSubmitConvertedStock: ', data);

        const item_id = childItemCode ? childItemCode : dataItems.child_item_code;

        const url = `/api/superzop/commercial/stockconversion/convertedstocks/editconvertedstockandsi?type=edit`;

        baseUrl.post(url, data).then(res => {

            console.log('Push Converted Stocks ', res);

            //resolve("Success");

            setTimeout(() => {
                window.location.reload()
            }, 2000);

            return toast('Edited Converted Stock and Synced System Inventory', { type: toast.TYPE.SUCCESS });

        }).catch((error) => {

            console.error(error);
            //reject(error);
            return toast('Failed to Edit', { type: toast.TYPE.SUCCESS });
        });
    }

    const onSubmit = data => {
        if (bagWeightLoss < 0) {
            return toast("Stock Transfer Total Weight can't be more then Stock Transfer", { type: toast.TYPE.ERROR });
        } else {
            let newDate = currentDate ? currentDate : new Date()

            const currYear = newDate.getFullYear();
            const currMonth = newDate.getMonth().toString().length === 1 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
            const currDate = newDate.getDate().toString().length === 1 ? `0${newDate.getDate()}` : newDate.getDate();

            newDate = `${currYear}-${currMonth}-${currDate}`;
            let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)

            data["stock_transfer_date"] = newDate
            data["updated_by"] = loggedEmailId
            data["parent_name"] = data["parent_name"] ? data["parent_name"].value : data["parent_name_included"]
            data["parent_code"] = itemCode ? itemCode : dataItems.parent_code
            data["major_category"] = category ? category : dataItems.major_category
            data["stock_transfer"] = stockTransfer ? stockTransfer : dataItems.stock_transfer
            data["stock_transfer_total_weight"] = stockTransferTotalWt ? stockTransferTotalWt : dataItems.stock_transfer_total_weight
            data["bag_weight_loss"] = bagWeightLoss
            data["outer_size"] = outerSize ? outerSize : dataItems.outer_size
            data["uom"] = uomVal ? uomVal : dataItems.uom
            if (data["parent_name_included"])
                delete data["parent_name_included"]

            console.log(JSON.stringify(data))
            console.log(dataItems.id)

            const item_id = itemCode ? itemCode : dataItems.parent_code;

            const old_stock_transfer_date = dataItems.stock_transfer_date;
            //const old_stock_transfer = dataItems.stock_transfer;
            const old_stock_transfer = dataItems.stock_transfer_total_weight;

            console.log('old_stock_transfer_date: ', old_stock_transfer_date, ' newDate: ', newDate);

            if (old_stock_transfer) {

                if (newDate !== old_stock_transfer_date) {

                    const old_stock_transfer_url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${old_stock_transfer_date}&item_id=${item_id}`;

                    let old_stock_transfer_obj
                    if (old_stock_transfer) {

                        // checking old_stock_transfer value is whether +ve or -ve.

                        if (Number(old_stock_transfer) >= 0) {
                            // send old_stock_transfer as negative value if it is found positive
                            old_stock_transfer_obj = {
                                stock_transfer: `-${old_stock_transfer}`
                            }
                        } else {

                            // convert old_stock_transfer to positive value
                            old_stock_transfer = Number(old_stock_transfer) * -1;

                            // send old_stock_transfer as postive value if it is found positive
                            old_stock_transfer_obj = {
                                stock_transfer: old_stock_transfer
                            }
                        }

                    } else {
                        old_stock_transfer_obj = {
                            stock_transfer: "0"
                        }
                    }

                    baseUrl.put(old_stock_transfer_url, old_stock_transfer_obj).then(response => {

                        const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${newDate}&item_id=${item_id}`;

                        const obj = {
                            //stock_transfer: data.stock_transfer
                            stock_transfer: data.stock_transfer_total_weight
                        }

                        console.log('url: ', url, ' obj: ', obj)

                        baseUrl.put(url, obj).then(res => {

                            console.log('Synced System Inventory ', res);

                            const update_stock_conversion_url = `api/superzop/commercial/stockconversion/updatestockconversionintotable?key=${dataItems.id}`;
                            baseUrl.post(update_stock_conversion_url, data).then(response => {
                                if (response.status === 200) {
                                    return response.data;
                                }
                            }).then(resData => {
                                console.log(resData)
                                props.onHide()
                                // setTimeout(() => {
                                //     window.location.reload()
                                // }, 3000);

                                return toast("Updated Stock conversion record in MySQL", { type: toast.TYPE.SUCCESS });

                            }).catch((error) => {
                                console.log(error)
                                props.onHide()
                                return toast("Couldn't update stock conversion record in MySQL", { type: toast.TYPE.ERROR });
                            });
                            return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                        }).catch(error => {
                            console.log(error);
                        })
                    }).catch(error => {
                        console.log(error);
                    })

                } else {

                    const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${newDate}&item_id=${item_id}`;

                    const obj = {
                        //stock_transfer: data.stock_transfer
                        stock_transfer: data.stock_transfer_total_weight
                    }

                    console.log('url: ', url, ' obj: ', obj)

                    baseUrl.put(url, obj).then(res => {

                        console.log('Synced System Inventory ', res);

                        const update_stock_conversion_url = `api/superzop/commercial/stockconversion/updatestockconversionintotable?key=${dataItems.id}`;
                        baseUrl.post(update_stock_conversion_url, data).then(response => {
                            if (response.status === 200) {
                                return response.data;
                            }
                        }).then(resData => {
                            console.log(resData)
                            props.onHide()
                            // setTimeout(() => {
                            //     window.location.reload()
                            // }, 3000);

                            return toast("Updated Stock conversion record in MySQL", { type: toast.TYPE.SUCCESS });

                        }).catch((error) => {
                            console.log(error)
                            props.onHide()
                            return toast("Couldn't update stock conversion record in MySQL", { type: toast.TYPE.ERROR });
                        });
                        toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }



            /*fetch(`https://services2.superzop.com/api/superzop/commercial/stockconversion/updatestockconversionintotable?key=${dataItems.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(response => Promise.resolve(response))
                .then(data => {
                    console.log(data)
                    props.onHide()
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                    return toast("Updated Stock conversion record in MySQL", { type: toast.TYPE.SUCCESS });

                })
                .catch((error) => {
                    console.log(error)
                    props.onHide()
                    return toast("Couldn't update stock conversion record in MySQL", { type: toast.TYPE.ERROR });
                });*/
        }
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    )

    const stockConversionEntryDateChange = () => {

        let calcDate = currentDate ? currentDate : new Date()

        const complete_date = `${calcDate.getFullYear()}-${calcDate.getMonth() + 1 < 10 ? "0" + (calcDate.getMonth() + 1) : calcDate.getMonth() + 1}-${calcDate.getDate() < 0 ? "0" + calcDate.getDate() : calcDate.getDate()}`

        const stk_conv_entry_close_url = `/api/superzop/commercial/stockconversion/getstockconversioncloserecordbasedonstockconversiondate?stock_conversion_date=${complete_date}`

        baseUrl.get(stk_conv_entry_close_url).then(response => {

            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {

            //console.log(data);

            if (data.length > 0) {

                const stock_conversion_entry_close = data[0].stock_conversion_entry_close;

                if (stock_conversion_entry_close == 1) {

                    setStkConvEntryClose(true);
                } else
                    setStkConvEntryClose(false);
            } else {
                setStkConvEntryClose(false);
            }
        }).catch((error) => {
            console.log(error)
            // return toast("Couldn't update Purchase Details record in MySQL", { type: toast.TYPE.ERROR });

        });
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Conversion
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <form onSubmit={/*handleSubmit(onSubmit); handleSubmit(onSubmitNew)*/ handleSubmit(onSubmitStockConversion) /*handleSubmit(onSubmitConvertedStock)*/}>
                        <Table>
                            <tr>
                                <td>Stock Transfer Date</td>
                                {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                    <td>
                                        <DatePicker name={"dateField"} selected={currentDate}
                                            onChange={(val) => { setCurrentDate(val) }}
                                            minDate={new Date("2017/01/01")}
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<CustomDateInput />} />
                                    </td> :
                                    <td><Form.Control readOnly value={currentDate} /></td>}
                            </tr>
                            <tr>
                                <td>Parent Name</td>
                                {!itemName && <td>
                                    <Controller
                                        as={Select}
                                        name="parent_name"
                                        value={itemName ? itemName : dataItems.parent_name}
                                        options={parentNameList}
                                        control={control}
                                        readOnly
                                        placeholder={dataItems.parent_name}

                                    />
                                </td>}
                                {itemName && <td><Form.Control readOnly name={"parent_name_included"} readOnly value={itemName} /></td>}
                            </tr>
                            <tr>
                                <td>Parent Code</td>
                                <td><Form.Control name={"parent_code"} readOnly onChange={(e) => insertDomValue(e.target.value)} defaultValue={itemCode ? itemCode : dataItems.parent_code} /></td>
                            </tr>
                            <tr>
                                <td>Major Category</td>
                                <td><Form.Control name={"major_category"} readonly value={category ? category : dataItems.major_category} /></td>
                            </tr>
                            <tr>
                                <td>Outer Size</td>
                                <td><Form.Control name={"outer_size"} readonly value={outerSize ? outerSize : dataItems.outer_size} /></td>
                            </tr>
                            <tr>
                                <td>UoM</td>
                                <td><Form.Control name={"uom"} readonly value={uomVal ? uomVal : dataItems.uom} /></td>
                            </tr>
                            <tr>
                                <td>System Inventory</td>
                                <td><Form.Control readOnly value={systemInventory && systemInventory} />
                                </td>
                            </tr>
                            <tr>
                                <td>Child Item Name</td>
                                <td>
                                    <Form.Control name={"uom"} readonly value={childItemName ? childItemName : childDataItems.child_item_name} />
                                </td>
                            </tr>
                            <tr>
                                <td>Child Item Code</td>
                                <td><Form.Control readOnly name={"child_item_code"} value={childItemCode ? childItemCode : childDataItems.child_item_code} /></td>
                            </tr>
                            <tr>
                                <td>Child Weight</td>
                                <td><Form.Control readOnly name={"child_weight"} value={childWeight ? childWeight : childDataItems.child_weight} /></td>
                            </tr>
                            <tr>
                                <td>Child UoM</td>
                                <td><Form.Control readonly name={"child_uom"} value={childUoM ? childUoM : childDataItems.uom} /></td>
                            </tr>
                            <tr>
                                <td>Child Stock Production</td>
                                <td><Form.Control name={"child_stock_production"} value={childStock} required onChange={(e) => {
                                    setChildStock(e.target.value);
                                    const stockTransferTotalWtCal = (e.target.value * actualChildWeight) + Number(bagWeightLoss ? bagWeightLoss : 0) + Number(wastage ? wastage : 0);
                                    setStockTransferTotalWt(stockTransferTotalWtCal);
                                    let stockTransferCal = stockTransferTotalWtCal / outerSize;
                                    setStockTransfer(stockTransferCal);
                                    setBagWeightLoss(bagWeightLoss ? bagWeightLoss : 0);
                                }} />
                                    {errors.child_stock_production && <span style={{ color: "red", float: "left" }}>*Enter Stock Transfer value</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>Total Weight Packed</td>
                                <td><Form.Control readOnly name={"total_weight_packed"} value={childWeight && childStock && childWeight * childStock} /></td>
                            </tr>
                            <tr>
                                <td>Wastage in KG </td>
                                <td><Form.Control number name={"wastage"} value={wastage} required onChange={(e) => {
                                    setWastage(e.target.value);
                                    const stockTransferTotalWtCal = (childStock * actualChildWeight) + Number(bagWeightLoss ? bagWeightLoss : 0) + Number(e.target.value ? e.target.value : 0);
                                    setStockTransferTotalWt(stockTransferTotalWtCal);
                                    let stockTransferCal = stockTransferTotalWtCal / outerSize;
                                    setStockTransfer(stockTransferCal);
                                }} />
                                    {errors.wastage && <span style={{ color: "red", float: "left" }}>*Enter wastage value</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>Converted Stock in KG</td>
                                <td><Form.Control name={"converted_stock"} value={actualChildWeight ? wastage ? (actualChildWeight * childStock) + Number(wastage) : (actualChildWeight * childStock) : ""} /></td>
                            </tr>
                            <tr>
                                <td>Bag Weight Loss</td>
                                {/* <td><Form.Control readOnly name={"bag_weight_loss"} value={bagWeightLoss} /></td> */}
                                <td><Form.Control name={"bag_weight_loss"} type="number" value={bagWeightLoss} onChange={(e) => {
                                    setBagWeightLoss(e.target.value);
                                    const stockTransferTotalWtCal = (childStock * actualChildWeight) + Number(e.target.value ? e.target.value : 0) + Number(wastage ? wastage : 0);
                                    //console.log("childStock: ", childStock, " actualChildWeight: ", actualChildWeight, " stockTransferTotalWtCal: ", stockTransferTotalWtCal);
                                    setStockTransferTotalWt(stockTransferTotalWtCal);
                                    let stockTransferCal = stockTransferTotalWtCal / outerSize;
                                    setStockTransfer(stockTransferCal);
                                }} /></td>
                            </tr>
                            <tr>
                                <td>Stock Transfer</td>
                                <td><Form.Control name={"stock_transfer"} value={stockTransfer ? stockTransfer : dataItems.stock_transfer} readOnly onChange={(e) => { setStockTransfer(e.target.value); }} />
                                </td>
                            </tr>
                            <tr>
                                <td>Stock Transfer Total Weight</td>
                                <td><Form.Control name={"stock_transfer_total_weight"} type="number" readOnly value={stockTransferTotalWt ? stockTransferTotalWt : dataItems.stock_transfer_total_weight} onChange={(e) => { let val = e.target.value; val = val.replace(/([^0-9.]+)/, ""); e.target.value = val; setStockTransferTotalWt(val); }} /></td>
                            </tr>
                            <tr>
                                <td colSpan="2"><Button type="submit">Update</Button></td>
                            </tr>
                        </Table>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConversionEntriesModal
