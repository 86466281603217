import React,{useEffect,useState} from 'react';
import { Form,Modal,Button,Table } from "react-bootstrap";
import database from '../../service/firebaseConfig';
import { toast } from "react-toastify";

const DB_REF_RetProds = database.database().ref(`Retailer_Products`);

const ModalEditRow = ({updateItem,...props}) => {
    let rowData = props.rowData;
    const [editedRetailerMargin, setEditedRetailerMargin] = useState(rowData.retailer_margin);
  

    const [retailerId,setRetailerId] = useState(props.retailer);

    const [existingProducts,setExistingProducts] = useState(props.existingProducts)
   

    let item_id = rowData.item_id;
    

    const [SellingPrice, setSellingPrice] = useState(0);
    
    const [SellingPriceExGst, setSellingPriceExGst] = useState(0);
    
    const [GstCharges, setGstCharges] = useState(0);
    const [sellingPriceWoRounding, setSellingPriceWoRounding] = useState(0);
    

    const handleRetailerMarginChange = (event) => {
        let margin = event.target.value;
       
        if (isNaN(margin) || margin < 0) {
            setEditedRetailerMargin(0);
        } else {
            setEditedRetailerMargin(margin);
        }
    };

    const isValid = (data) => {
        if (
          data === undefined ||
          data === null ||
          data === "" ||
          data.length === 0
        ) {
          return 0;
        }
        return data;
      };

      const parseToNo = (string) => {
        return parseFloat(isValid(string));
      };

      const getAbsFromPer = (data) => {
        return parseFloat(parseToNo(data) / 100);
      };

      const getRoundNoFour = (data) => {
        return parseToNo(data).toFixed(4);
    };
           
    const calculateSellingPrice = (marketPrice, retailerMargin) => {
        
       let sp =  parseToNo(marketPrice)/(1+getAbsFromPer(retailerMargin))
       return sp;
    };
    
    
    const calculateSellingPriceExGst = (sellingPrice, gstPercentage) => {
        let spExGst = parseToNo(sellingPrice) / (1 + getAbsFromPer(gstPercentage))
        return spExGst.toFixed(2);
    };
    
    const calculateGstCharges = (dealerPrice, gstPercentage) => {
        let gst_charge = getAbsFromPer(gstPercentage) * dealerPrice;
        gst_charge = getRoundNoFour(gst_charge);
        return gst_charge;
    };
    
    
    useEffect(() => {
      
        const sellingPrice = calculateSellingPrice(rowData.market_price, editedRetailerMargin);
        const sellingPriceWithoutGst = calculateSellingPriceExGst(sellingPrice,rowData.gst_per)
        const gstCharges = calculateGstCharges(sellingPriceWithoutGst, rowData.gst_per);

        setSellingPrice(Math.round(sellingPrice));
        setGstCharges(gstCharges);
        setSellingPriceExGst(sellingPriceWithoutGst);
    }, [editedRetailerMargin]);


    const handleUpdateClick = () => {
        

        // updateItem(retailerId, item_id, editedRetailerMargin,SellingPriceExGst);
      
        database.database().ref(`Retailer_Products/${retailerId}/${item_id}`).update({
            retailer_margin: editedRetailerMargin,
            dealer_price: SellingPriceExGst,
            gst_charge : GstCharges

          })
          .then(() => {
            // console.log('Retailer margin updated successfully');
            updateItem();
            props.onHide(); 
            return toast("Successfully updated margin in server", { type: toast.TYPE.SUCCESS });
          })
          .catch((error) => {
            console.error('Error updating retailer margin:', error);
             props.onHide();
            return toast("Couldn't update retailer margin ", { type: toast.TYPE.ERROR });
          });
      };
    
        
        

    return(
        <>
    <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Edit Retailer margin for {rowData.item_id}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body >
                <Table>
                    <tr>
                        <td>Item Id</td>
                        <td><Form.Control type="text" value={rowData.item_id} readOnly /></td>
                    </tr>
                    <tr>
                        <td>MRP</td>
                        <td><Form.Control type="text" value={rowData.market_price} readOnly /></td>
                    </tr>
                   
                    <tr>
                    <td>Retailer Margin %</td>
                    <td><Form.Control type="text" value={editedRetailerMargin === undefined ? 0 : editedRetailerMargin} onChange={handleRetailerMarginChange} /></td>
                    </tr>
                    
                    <tr>
                        <td>GST%</td>
                        <td><Form.Control type="text" value={rowData.gst_per} readOnly /></td>
                    </tr>
                    <tr>
                        <td>GST charges</td>
                        <td><Form.Control type="text" value={GstCharges} readOnly/></td>
                    </tr>
                    <tr>
                        <td>Selling Price</td>
                        <td><Form.Control type="text" value={SellingPrice} readOnly /></td>
                    </tr>

                    <tr>
                        <td>Selling Price Ex Gst (Dealer Price)</td>
                        <td><Form.Control type="text" value={SellingPriceExGst} readOnly /></td>
                    </tr>
                </Table>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={handleUpdateClick} >Update</Button>
        <Button onClick={()=>{props.onHide()}}>Close</Button>
        </Modal.Footer>
    </Modal>
    
        </>
    )
}

export default ModalEditRow;