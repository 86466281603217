import React, {useState,useEffect} from 'react'
import {Table,Form,Button,Modal,DropdownButton,Dropdown} from 'react-bootstrap'
import database from '../../../../service/firebaseConfig';


const ModifyApartments=(props)=> {

   const [getAptName,setAptName]=useState("")
   const [getAptAddress,setAptAddress]=useState("")
   const [getContactPerson,setContactPerson]=useState("")
   const [getContactPhoneNo,setContactPhoneNo]=useState("")
   const [getASMName,setASMName]=useState("")
   const [getASMId,setASMId]=useState("")
   const [getLatitude,setLatitude]=useState("")
   const [getLongitude,setLongitude]=useState("")
   const [getPin,setPin]=useState("")
   const [getArea,setArea]=useState("")
   const [getData,setData]=useState(false)

   const getDetails=()=>{

      setAptName(props.apartmentName)
      database.database().ref(`Apartments/Mumbai/${props.apartmentId}`).once("value",results=>{

         const fetchApartmentDetails=results.val();
         setAptAddress(fetchApartmentDetails.address)
         setContactPerson(fetchApartmentDetails.contact_person)
         setContactPhoneNo(fetchApartmentDetails.contact_phone_number)
         setASMName(fetchApartmentDetails.asm)
         setASMId(fetchApartmentDetails.asm_id)
         setLatitude(fetchApartmentDetails.latitude)
         setLongitude(fetchApartmentDetails.longitude)
         setPin(fetchApartmentDetails.pincode)
         setArea(fetchApartmentDetails.area)
         console.log(fetchApartmentDetails)
      })
   }

   const pushEditApartmentDetails=()=>{

      var today = new Date();
      var month =''+(today.getMonth()+1)
      var date=''+today.getDate()
      if (month.length < 2){ 
      month='0'+month;
      console.log(month)  }
      if (date.length < 2){ 
      date ='0'+date;
      console.log(date)}
      var TodaysDate = today.getFullYear()+'-'+month+'-'+date;

      database.database().ref(`Apartments/Mumbai/${props.apartmentId}`).update({
         
         apartment_name:getAptName,
         address:getAptAddress,
         contact_person:getContactPerson,
         contact_phone_number:getContactPhoneNo,
         updated_date:TodaysDate,
         asm:getASMName,
         asm_id:parseInt(getASMId),
         latitude:getLatitude,
         longitude:getLongitude,
         pincode:getPin,
         area:getArea,

      }).then(()=>{alert(`Apartment details updated successfully`);props.onHide();window.location.reload()})
   }

   useEffect(() => {
      getDetails()
   }, [props])

   useEffect(() => {
      if(getData)
      pushEditApartmentDetails()
   }, [getData])

return (
   
<div>

<Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
      <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
         Edit Apartment Details
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
         <Table striped bordered hover>
            <tr>
               <td>Apartment Id</td>
               <td><Form.Control type="text" value={props.apartmentId} readOnly /></td>
            </tr>
            <tr>
               <td>Apartment Name</td>
               <td><Form.Control type="text"  defaultValue={getAptName}
                     onChange={(e)=>{setAptName( e.target.value)}} required />
               </td>
            </tr> 
            <tr>
               <td>Address</td>
               <td><Form.Control required type="text" defaultValue={getAptAddress} onChange={(e)=>setAptAddress(e.target.value)}></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Contact person</td>
               <td><Form.Control type="text" defaultValue={getContactPerson} onChange={(e)=>setContactPerson(e.target.value)}></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Contact Phone Number</td>
               <td><Form.Control type="text" defaultValue={getContactPhoneNo} onChange={(e)=>setContactPhoneNo(e.target.value)}></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Latitude</td>
               <td><Form.Control type="text" defaultValue={getLatitude} onChange={(e)=>setLatitude(e.target.value)}></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Longitude</td>
               <td><Form.Control type="text" defaultValue={getLongitude} onChange={(e)=>setLongitude(e.target.value)}></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Select ASM</td>
               <td><DropdownButton id="dropdown-basic-button" title={getASMName?getASMName:"--Select--"}>

                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90078")}} name="Santosh Singh">Santosh Singh</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90147")}} name="Channakrishna Reddy">Channakrishna Reddy</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90125")}} name="Rayomant Kadam">Rayomant Kadam</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90010")}} name="JP">JP</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90075")}} name="Sagar">Sagar</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90015")}} name="Deepak">Deepak</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90071")}} name="Vinod Vijayan">Vinod Vijayan</Dropdown.Item>
                     <Dropdown.Item onClick={(e)=>{setASMName(e.target.name);setASMId("90000")}} name="SuperZop">SuperZop</Dropdown.Item>

                  </DropdownButton>
               </td>
            </tr>
            <tr>
               <td>ASM Id</td>
               <td><Form.Control type="text" value={getASMId && getASMId} readOnly></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Pincode</td>
               <td><Form.Control type="text" defaultValue={getPin} onChange={(e)=>setPin(e.target.value)}></Form.Control>
               </td>
            </tr>
            <tr>
               <td>Area</td>
               <td><Form.Control type="text" defaultValue={getArea} onChange={(e)=>setArea(e.target.value)}></Form.Control>
               </td>
            </tr>
         </Table>
      </Modal.Body>
      <Modal.Footer>
         <Button onClick={()=>{setData(true);pushEditApartmentDetails()}} variant="danger">Update Apartments</Button>
         <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
</Modal>
            
</div>
      )
}

export default React.memo(ModifyApartments)
