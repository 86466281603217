import React from "react";
import styled from "styled-components";
import { WidgetsListItem } from "./WidgetListItem";

const WidgetsContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: scroll;
  height: 100%;
  padding: 1rem;
`;

const WidgetsContainer = ({
  handleOnEditWidgetModalShow,
  widgetsList,
  deleteWidgetItem,
}) => {
  return (
    <WidgetsContainerStyles>
      {widgetsList.map((element, index) => {
        return (
          <WidgetsListItem
            key={index}
            index={index}
            handleOnEditWidgetModalShow={handleOnEditWidgetModalShow}
            item={element}
            deleteWidgetItem={deleteWidgetItem}
          />
        );
      })}
    </WidgetsContainerStyles>
  );
};

export default WidgetsContainer;
