import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import baseUrl from '../../service/servicesConfig';
import { toast } from 'react-toastify';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Table, Button, Form, Jumbotron, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';

const StockConversionDetails = (props) => {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [showData, setShowData] = useState(false)
    const [handleDateRange, setHandleDateRange] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [stockConvAndConvStkList, setStockConvAndConvStkList] = useState("");
    const [convertedStockList, setConvertedStockList] = useState("");
    const [stockConversionList, setStockConversionList] = useState("");

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    console.log('props: ', props)

    console.log('window: ', window);

    const parent_code = props.match.params.parent_code;

    console.log('parent_code: ', parent_code);

    // if (!parent_code) {
    //     window.alert('hello parent window');
    // }

    useEffect(() => {
        if (showData) {
            handleDateRangeChange()
        }
    }, [showData])

    useEffect(() => {

        setBackDrop(true)
        //startDate.setDate(startDate.getDate() - 1)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        baseUrl.get(`/api/superzop/commercial/stockconversion/getstockconversiondata?start_date=${startDateConversion}&end_date=${endDateConversion}&parent_code=${parent_code}`).then(response => {
            //baseUrl.get(`/api/superzop/commercial/stockconversion/getstockconversiondata?start_date=${startDateConversion}&end_date=${startDateConversion}`).then(response => {

            if (response.status === 200) {
                return response.data;
            }
            //return res.json()
        }).then(data => {
            baseUrl.get(`/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(response => {
                //baseUrl.get(`/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${startDateConversion}`).then(response => {

                if (response.status === 200) {
                    return response.data;
                }
                //return res.json()
            }).then(convStkData => {
                setConvertedStockList(convStkData)
                setStockConversionList(data);
                setBackDrop(false);
                if (!data.length > 0) {
                    notify(true)
                }
                const parentChildList = data.map((item) => {

                    const childCodeList = convStkData.filter((childItem) => {

                        const child_mapping_id = childItem.mapping_id;

                        const parent_mapping_id = item.mapping_id;

                        if (parent_mapping_id)
                            return child_mapping_id === parent_mapping_id;
                    })

                    if (childCodeList.length > 0) {
                        const obj = { ...childCodeList[0], ...item }
                        obj.child_id = childCodeList[0].id;
                        obj.child_uom = childCodeList[0].uom;
                        return obj;
                    } else
                        return item;
                });

                setStockConvAndConvStkList(parentChildList);

                console.log('stockConvAndConvStkList: ', stockConvAndConvStkList);
                //console.log('stockConversionList: ', stockConversionList);
            }).catch(err => console.log(err))
            //console.log(data);

        }).catch(err => console.log(err))

    }, [])

    const handleDateRangeChange = () => {

        // if (startDate > endDate) {

        //     setBackDrop(false)
        //     setStockConversionList("")
        //     return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        // }
        let conversionStartDtType = `start_date`
        let conversionEndDtType = `end_date`

        setStockConversionList("")
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        baseUrl.get(`/api/superzop/commercial/stockconversion/getstockconversiondata?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${endDateConversion}&parent_code=${parent_code}`).then(response => {
            //baseUrl.get(`/api/superzop/commercial/stockconversion/getstockconversiondata?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${startDateConversion}`).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            baseUrl.get(`/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(response => {
                //baseUrl.get(`/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${startDateConversion}`).then(response => {

                if (response.status === 200) {
                    return response.data;
                }
                //return res.json()
            }).then(convStkData => {
                setConvertedStockList(convStkData)
                setStockConversionList(data);
                setBackDrop(false);
                if (!data.length > 0) {
                    notify(true)
                }
                const parentChildList = data.map((item) => {

                    const childCodeList = convStkData.filter((childItem) => {

                        const mapping_id_item = childItem.mapping_id;

                        return mapping_id_item === item.mapping_id;
                    })

                    if (childCodeList.length > 0) {
                        const obj = { ...childCodeList[0], ...item }
                        obj.child_id = childCodeList[0].id;
                        obj.child_uom = childCodeList[0].uom;
                        return obj;
                    } else
                        return item;
                });

                setStockConvAndConvStkList(parentChildList);

                //console.log('stockConvAndConvStkList: ', stockConvAndConvStkList);

                //console.log('stockConversionList: ', stockConversionList);
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))
    }

    const notify = (unavailability) => {

        if (unavailability)
            return toast("No Stock conversions available for selected date ", { type: toast.TYPE.ERROR })
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    const tableDetails = () => {

        return (
            <Table bordered striped >
                <thead>
                    <tr>
                        <td>Stock Transfer Date</td>
                        <td>Major Category</td>
                        <td>Stock Transfer (Bags)</td>
                        <td>Stock Transfer Total Weight (Kgs)</td>
                        <td>Bag Weight Loss</td>
                        <td>Child Code</td>
                        <td>Child Name</td>
                        <td>Child Outer Size</td>
                        <td>Converted Stock in Kg</td>
                        <td>Wastage in Kg</td>
                        <td>Created By</td>
                        <td>Updated By</td>

                    </tr>
                </thead>
                <tbody>
                    {stockConvAndConvStkList.length > 0 && stockConvAndConvStkList.map(items => (
                        <tr>
                            <td>{items.stock_transfer_date}</td>
                            <td>{items.outer_size} {items.uom}</td>
                            <td>{items.stock_transfer}</td>
                            <td>{items.stock_transfer_total_weight}</td>
                            <td>{items.bag_weight_loss}</td>
                            <td>{items.child_item_code}</td>
                            <td>{items.child_item_name}</td>
                            <td>{items.child_outer_size} {items.child_uom}</td>
                            <td>{items.converted_stock}</td>
                            <td>{items.wastage}</td>
                            <td>{items.created_by && items.created_by.split("@")[0]}</td>
                            <td>{items.updated_by && items.updated_by.split("@")[0]}</td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>
        );
    }

    return (
        <div className={"main-content-class"}>
            <NavBar />
            <div style={{ paddingTop: 100, marginRight: 20, marginLeft: 20 }}>
                <div style={{ textAlign: "center" }}>
                    <Alert variant="info">Stock Conversion Details</Alert>
                    <Navbar>
                        <Form className={"navigational-data"} inline>
                            <Badge variant="dark" style={{ marginLeft: 10 }}>Select Start Date </Badge>
                            <DatePicker
                                selected={startDate}
                                onChange={(val) => { setStartDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"
                                customInput={<CustomDateInput />}

                            />
                            <Badge variant="dark" style={{ marginLeft: 10 }}>Select End Date </Badge>
                            <DatePicker

                                selected={endDate}
                                onChange={(val) => { setEndDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"
                                customInput={<CustomDateInput />}
                            />
                            <Button size="sm" style={{ margin: 10 }} onClick={() => { setShowData(true); setHandleDateRange(true) }}>Show</Button>
                        </Form>
                    </Navbar>
                    {stockConversionList.length > 0 && <div>
                        {tableDetails()}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default StockConversionDetails;