import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
`;

export const AddProductContainer = styled.div`
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.2), 0px -1px 1px 1px rgba(0, 0, 0, 0.1);

    .req_symb {
        color: #FF0000;
    }

    header {
        text-align: center;
        
        h3 {
            display: inline-block;
            min-width: 75%;
            max-width: 75%;
        }

        button {
            font-size: 1.5rem;
            margin-right: 30px;
            display: inline-block;
            padding: 0.4rem 0.8rem;
            border-radius: 0.4rem;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:hover {
                cursor: pointer;
                font-weight: bold;
                box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }

        span {
            font-size: 1.5rem;
            display: inline-block;
            padding: 0.4rem 0.8rem;
            border-radius: 0.4rem;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:hover {
                cursor: pointer;
                font-weight: bold;
                box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }
`;

export const TopContainer = styled.section`
    padding: 0.5rem 1rem;
    display: flex;
    margin: 0.25rem auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h6 {
        display: flex;
        width: 24%;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        margin: 1rem 0.5rem;
        font-size: 1.1rem;
        padding: 0.8rem;
        border-radius: 0.5rem;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
    
        span {
            width: 40%;
            font-weight: bold;  
            margin-right: 1rem;
        }
        
        input {
            width: 60%;
            outline: none;
            padding: 0.5rem 1rem;
            margin: 0rem 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        &:hover {
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
        }
    }
`;

export const MidSplit = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const LeftContainer = styled.div`
    width: 35%;
    padding: 1rem;
    display: flex;
    margin-right: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    h6 {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        margin: 1rem 0.5rem;
        font-size: 1.1rem;
        padding: 1rem;
        border-radius: 0.5rem;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
    
        span {
            width: 100%;
            font-weight: bold;  
            margin-right: 1rem;
        }
        
        input {
            outline: none;
            padding: 0.5rem 1rem;
            margin: 0rem 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        &:hover {
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
        }
    }    
`;

export const MidContainer = styled.div`
    width: 35%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);

    h6 {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        margin: 1rem 0.5rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 1.1rem;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
    
        span {
            width: 100%;
            font-weight: bold;  
            margin-right: 1rem;
        }

        input {
            outline: none;
            padding: 0.5rem 1rem;
            margin: 0rem 1rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        &:hover {
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
        }
    }   
`;

export const RightContainer = styled.div`
    width: 27%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .topPart {

        h6 {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            margin: 1rem 0.5rem;
            font-size: 1.1rem;
            padding: 1rem 1.5rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.1);

            input {
                outline: none;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                border: 1px solid rgba(0, 0, 0, 0.2);
            }

            label {
                margin: 0rem 0.5rem;
            }

            .classForRegion {
                width: 60%;
            }

            &:hover {
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .calcPart {
        margin-top: 2rem;

        .calc_btn {
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            font-size: 1.3rem;
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.2);
            
            &:hover {
                background: #f1f1f1;
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .midPart {
        margin: 2rem 0rem;

        h6 {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0.5rem;
            font-size: 1.1rem;
            padding: 1rem 1.5rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            
            span {
                width: 100%;
                display: inline-block;
                font-weight: bold;  
                margin-right: 1rem;
            }
    
            .data_text {
                width: 20%;
                font-weight: normal;
                margin-left: 1rem;
            }
    
            &:hover {
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            }
        } 
    }

    .botPart {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .add {
            padding: 0.5rem 1rem;
            margin-right: 1rem;
            border-radius: 0.5rem;
            font-size: 1.3rem;
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.2);
            
            &:hover {
                color: #90ee90;
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            }
        }

        .cancel {
            padding: 0.5rem 1rem;
            margin-left: 1rem;
            border-radius: 0.5rem;
            font-size: 1.3rem;
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.2);
            
            &:hover {
                color: #ff0000;
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            }
        }
    }
`;

