import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Table, Button, Form, Jumbotron, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input } from 'reactstrap';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './InventoryListing.css'
import database from '../../service/firebaseConfig';
import Backdrop from '../../CommonRequirements/backdrop'
import { toast } from 'react-toastify';
import ReactSelect from 'react-select'
import XLSX from 'xlsx'
import { validateIsAdminUser, validateStockUser,validateDCManagerUser } from '../../service/credentials';
import { Link } from 'react-router-dom'
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import { IconButton } from '@material-ui/core';
import baseUrl from '../../service/servicesConfig';



const InventoryListing = () => {

    const [startDate, setStartDate] = useState(new Date())
    const [inventoryDetails, setInventoryDetails] = useState("")
    const [exportData, setExportData] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState([])
    const [itemInventoryDetails, setItemInventoryDetails] = useState("")
    const [handleDateChange, setHandleDateChange] = useState(true)
    const [filterCategory, setFilterCategory] = useState("")
    const [filterSubCategory, setFilterSubCategory] = useState("")
    const [categorySelected, setCategorySelected] = useState("")
    const [subCategorySelected, setSubCategorySelected] = useState("")
    const [activeFlag, setActiveFlag] = useState("Y")
    const [categoryRefresh, setCategoryRefresh] = useState(false)
    const [subCategoryRefresh, setSubCategoryRefresh] = useState(false)
    const [dataAdd, setDataAdd] = useState(false)
    const [actualInventoryData, setActualInventoryData] = useState("")
    const [inventoryDataPoints, setInventoryDataPoints] = useState({})
    const [nonConvertedData,setNonConvertedData] = useState([])
    const categoryRef = useRef()
    const categoryFilterRef = useRef()
    let filterResult = ""
    const todayDate = new Date().toISOString().slice(0, 10);

    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify = (unavailability) => {

        if (unavailability) {
            setBackDrop(false);
            return toast("No Inventory Details Available for selected date range ", { type: toast.TYPE.ERROR })
        }

    }


    const formattedDate = (date) => { 
        return date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
    }


    //get active flag

    useEffect(() => {

        if (inventoryDetails.length > 0 && inventoryDataPoints) {

            database.database().ref(`Product_Base_Data`).once("value", snapShot => {
                const productBaseDetails = snapShot.val();
                inventoryDetails.forEach(item => {
                    if (productBaseDetails[item.item_id]) {
                        item["active_status"] = productBaseDetails[item.item_id].active_status
                        item["category"] = productBaseDetails[item.item_id].category
                        item["sub_category"] = productBaseDetails[item.item_id].sub_category
                        item["outer_size"] = productBaseDetails[item.item_id].outer_size
                        item["brand"] = productBaseDetails[item.item_id].brand
                        item["item_disc"] = productBaseDetails[item.item_id].long_description
                    }
                    item["qty_dumped"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].qty_dumped?inventoryDataPoints[item.item_id].qty_dumped:0;
                    item["actual_stock"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].actual_stock?inventoryDataPoints[item.item_id].actual_stock:0;
                    item["total_weight_received"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].total_weight_received?inventoryDataPoints[item.item_id].total_weight_received:0;
                    item["item_delivered_qty"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].item_delivered_qty?inventoryDataPoints[item.item_id].item_delivered_qty * Number(item["outer_size"]):0;
                    item["item_modified_qty"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].item_modified_qty?inventoryDataPoints[item.item_id].item_modified_qty * Number(item["outer_size"]):0;
                    item["stock_transfer"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].stock_transfer?inventoryDataPoints[item.item_id].stock_transfer:0;
                    item["converted_stock"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].converted_stock?inventoryDataPoints[item.item_id].converted_stock:0;
                    item["item_qty"] = inventoryDataPoints[item.item_id] && inventoryDataPoints[item.item_id].item_qty?inventoryDataPoints[item.item_id].item_qty:0;
                    
                })
                console.log("Setting data from Product Base Data");
                console.log(inventoryDetails);
                setBackDrop(false)
                setDataAdd(true)
                settingData();
            })
        }

    }, [inventoryDetails,nonConvertedData,inventoryDataPoints])

    const handleDateRangeChange = () => {
        setBackDrop(true);
        setHandleDateChange(false);
        setInventoryDetails({});
        setItemInventoryDetails({});
        setInventoryDataPoints({});
        setFilterList("");
        setCategorySelected("");
        setSubCategorySelected("");
        setSearchText("");
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        console.log(startDateConversion)

        const getsysteminventory_url = `/api/superzop/systeminventory/getsysteminventory?date=${startDateConversion}`;

        baseUrl.get(getsysteminventory_url).then(response => {

            console.log(response);
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            console.log(data);
            setInventoryDetails(data);
            setActualInventoryData(data)
            if (!data.length > 0) {
                notify(true)
            };
            // setBackDrop(false);
        }).catch(err => console.log(err));

        // const non_converted_stock_url = `/api/superzop/commercial/stockconversion/stockconversionlistnew`;

        // baseUrl.get(non_converted_stock_url).then(response => {
        //     if (response.status === 200) {
        //         return response.data;
        //     }
        // }).then(data => {
        //     setNonConvertedData(data); 
        // })

        const inventory_data_points_url = `/api/superzop/systeminventory/inventorydatapoints?start_date=${startDateConversion}&end_date=${startDateConversion}`;

        baseUrl.get(inventory_data_points_url).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            let inventoryDataPointsList = {};
            if(data.Product_Dump) {
                data.Product_Dump.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["qty_dumped"] = obj.qty_dumped;
                });
            }
            if(data.Product_Stock_Take) {
                data.Product_Stock_Take.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["actual_stock"] = obj.actual_stock;
                });
            }
            if(data.Purchase_Details) {
                data.Purchase_Details.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["total_weight_received"] = obj.total_weight_received;
                });
            }
            if(data.Driver_Orders_History) {
                data.Driver_Orders_History.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["item_delivered_qty"] = obj.item_delivered_qty;
                    inventoryDataPointsList[obj.article_code]["item_modified_qty"] = obj.item_modified_qty;
                });
            }
            if(data.Stock_Conversion) {
                data.Stock_Conversion.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["stock_transfer"] = obj.stock_transfer;
                });
            }
            if(data.Converted_Stocks) {
                data.Converted_Stocks.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["converted_stock"] = obj.converted_stock;
                });
            }
            if(data.Other_Orders) {
                data.Other_Orders.forEach(function(obj) {
                    if(!inventoryDataPointsList[obj.article_code]) {
                        inventoryDataPointsList[obj.article_code] = {};
                    }
                    inventoryDataPointsList[obj.article_code]["item_qty"] = obj.item_qty;
                });
            }
            console.log(inventoryDataPointsList);
            setInventoryDataPoints(inventoryDataPointsList);
        })
    }

    useEffect(() => {
        if (startDate && handleDateChange)
            handleDateRangeChange()
    }, [startDate, handleDateChange])

    const searchInventory = () => {

        const filterData = []
        filterResult = itemInventoryDetails.filter(obj => {
            if (!(obj.hasOwnProperty("item_disc") && obj.hasOwnProperty("brand")))
                return
            if ((obj.item_disc == null || obj.brand == null))
                return
            const itemId = obj.item_id;
            const itemName = obj.item_disc.trim().toLowerCase();
            const itemBrand = obj.brand.trim().toLowerCase();


            setSearchText(searchText.trim().toLowerCase());
            return itemId.match(searchText) || itemName.match(searchText) || itemBrand.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(items)
        })
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchInventory()
    }, [searchText])

    const includeItemDetails = () => {
        if(itemInventoryDetails && itemInventoryDetails.length>0) {
            let filterFlagOptions = []

            itemInventoryDetails.forEach(itemLists => {
                if (itemLists.category === "" || itemLists.category === null || itemLists.category === "undefined")
                    return
                filterFlagOptions.push({ "value": itemLists.category, "label": itemLists.category })
            })
            setFilterCategory(Array.from(new Set(filterFlagOptions.map(JSON.stringify))).map(JSON.parse))
        }
    }
    useEffect(() => {
        if (itemInventoryDetails.length > 0 || categorySelected !== "")
            includeItemDetails()
    }, [itemInventoryDetails, categorySelected])


    useEffect(() => {
        if (itemInventoryDetails.length > 0)
            setBackDrop(false)
    }, [itemInventoryDetails])


    const getSystemInventory = (item, outer_size) => {
        // console.log(item.item_id + " - SystemInventory - " + item.system_inventory + " outer size :: " + outer_size);
        let system_inventory = Number(item.system_inventory);
        if(item.non_converted && todayDate == item.inventory_date) {
            system_inventory += item.non_converted;
        }
        return system_inventory.toFixed(1);
    }
    const getSystemInventoryAfterShipping = (item, outer_size) => {
        // console.log(item.item_id + " - SystemInventory - " + item.system_inventory + " other details :: "+" "+item.total_weight_received  +" "+ item.qty_dumped +" "+ item.converted_stock + " "+item.stock_transfer);
        let system_inventory = Number(item.system_inventory);
        if(item.non_converted && todayDate == item.inventory_date) {
            system_inventory += item.non_converted;
        }
        system_inventory = todayDate == item.inventory_date ? (Number(item.system_inventory)  - item.total_weight_received  + item.qty_dumped - item.converted_stock + item.stock_transfer) : (Number(item.system_inventory) - item.total_weight_received + item.qty_dumped - item.converted_stock + item.stock_transfer - item.item_modified_qty + item.item_delivered_qty)
        return system_inventory.toFixed(1);
    }
    

    const exportInventoryDetails = () => {

        const wb = XLSX.utils.book_new()
        let wbName = "SI";
        setBackDrop(true)
        let inventorySummary = [];
        inventorySummary = todayDate==inventoryDetails[0].inventory_date?
        [["Major Category", "Sub Category", "Item Code", "Item Name", "Outer Size (Kg/Pc)", "UoM", "System Inventory (After Shipped)", "System Inventory (Current + Non Converted)", "Avg Sale Rate (Kg/Pc)", "Inventory In Days", "Inventory In Outers (Bags/Pc)", "Replacement Brand", "Location"]] :
        [["Major Category", "Sub Category", "Item Code", "Item Name", "Outer Size (Kg/Pc)", "UoM", "System Inventory (After Shipped)", "System Inventory (Mid Night)", "Avg Sale Rate (Kg/Pc)", "Inventory In Days", "Inventory In Outers (Bags/Pc)", "Replacement Brand", "Location"]]
        if(itemInventoryDetails.length > 0 && searchText === "") {
            itemInventoryDetails.map(item => {
                let inventoryDetailsPerItem = [];
                let inventory = getSystemInventory(item, item.outer_size);
                let inventoryAfterShipping = getSystemInventoryAfterShipping(item, item.outer_size);
                let avg_sale_rate = (Number(item.order_qty_avg) * item.outer_size).toFixed(1);
                inventoryDetailsPerItem.push(item.category)
                inventoryDetailsPerItem.push(item.sub_category)
                inventoryDetailsPerItem.push(item.item_id)
                inventoryDetailsPerItem.push(item.brand ? (item.brand + " - " + item.item_disc) : item.item_disc)
                inventoryDetailsPerItem.push(item.outer_size)
                inventoryDetailsPerItem.push(item.UOM)
                inventoryDetailsPerItem.push(inventoryAfterShipping)
                inventoryDetailsPerItem.push(inventory)
                inventoryDetailsPerItem.push((inventory / item.outer_size).toFixed(1))
                inventoryDetailsPerItem.push(avg_sale_rate)
                inventoryDetailsPerItem.push(avg_sale_rate > 0 ? (inventory / avg_sale_rate).toFixed(1) : 0)
                inventoryDetailsPerItem.push(item.replacement_brand)
                inventoryDetailsPerItem.push(item.location)
                inventorySummary.push(inventoryDetailsPerItem)

            })
        } else if (filterList.length > 0) {
            filterList.map(item => {
                let inventoryDetailsPerItem = [];
                let inventory = getSystemInventory(item, item.outer_size);
                let inventoryAfterShipping = getSystemInventoryAfterShipping(item, item.outer_size);
                let avg_sale_rate = (Number(item.order_qty_avg) * item.outer_size).toFixed(1);
                inventoryDetailsPerItem.push(item.category)
                inventoryDetailsPerItem.push(item.sub_category)
                inventoryDetailsPerItem.push(item.item_id)
                inventoryDetailsPerItem.push(item.brand ? (item.brand + " - " + item.item_disc) : item.item_disc)
                inventoryDetailsPerItem.push(item.outer_size)
                inventoryDetailsPerItem.push(item.UOM)
                inventoryDetailsPerItem.push(inventoryAfterShipping)
                inventoryDetailsPerItem.push(inventory)
                inventoryDetailsPerItem.push((inventory / item.outer_size).toFixed(1))
                inventoryDetailsPerItem.push(avg_sale_rate)
                inventoryDetailsPerItem.push(avg_sale_rate > 0 ? (inventory / avg_sale_rate).toFixed(1) : 0)
                inventoryDetailsPerItem.push(item.replacement_brand)
                inventoryDetailsPerItem.push(item.location)
                inventorySummary.push(inventoryDetailsPerItem)

            })
        }

        if(subCategorySelected) {
            wbName = wbName+"_"+subCategorySelected.replace(/[^0-9a-z]/gi, '');
        } else if(categorySelected) {
            wbName = wbName+"_"+categorySelected.replace(/[^0-9a-z]/gi, '');
        }
        wbName = wbName+"_"+formattedDate(startDate)
        const inventoryExportDetails = XLSX.utils.aoa_to_sheet(inventorySummary)
        XLSX.utils.book_append_sheet(wb, inventoryExportDetails, wbName)
        XLSX.writeFile(wb, wbName+".xlsx")
        setExportData(false)
        setBackDrop(false)
    }

    const settingData = () => {
        console.log("Setting data:: " + inventoryDetails.length)
        if (inventoryDetails && inventoryDetails.length > 0 && (categorySelected || categoryRefresh || dataAdd)) {
            const newCategoryFilterDetails = []
            inventoryDetails.forEach(items => {
                if (subCategorySelected || categorySelected) {
                    if (items.category === categorySelected) {
                        if (subCategorySelected) {
                            if (items.sub_category === subCategorySelected) {
                                if (activeFlag === "" || activeFlag === "All") {
                                    newCategoryFilterDetails.push(items)
                                } else if (activeFlag == 'Y' && activeFlag == items.active_status) {
                                    newCategoryFilterDetails.push(items)
                                } else if (activeFlag == 'N' && (items.active_status == "" || items.active_status == "N")) {
                                    newCategoryFilterDetails.push(items)
                                }
                            }
                        } else {
                            if (activeFlag === "" || activeFlag === "All") {
                                newCategoryFilterDetails.push(items)
                            } else if (activeFlag == 'Y' && activeFlag == items.active_status) {
                                newCategoryFilterDetails.push(items)
                            } else if (activeFlag == 'N' && (items.active_status == "" || items.active_status == "N")) {
                                newCategoryFilterDetails.push(items)
                            }
                        }
                    }
                } else {
                    if (activeFlag === "" || activeFlag === "All") {
                        newCategoryFilterDetails.push(items)
                    } else if (activeFlag == 'Y' && activeFlag == items.active_status) {
                        newCategoryFilterDetails.push(items)
                    } else if (activeFlag == 'N' && (items.active_status == "" || items.active_status == "N")) {
                        newCategoryFilterDetails.push(items)
                    }
                }
            })
            setBackDrop(true)
            newCategoryFilterDetails.sort((a, b) => parseFloat(b.system_inventory) - parseFloat(a.system_inventory));
            setItemInventoryDetails(newCategoryFilterDetails)
            setTimeout(() => {
                setBackDrop(false)
            }, 2000);
        }
    }



    useEffect(() => {
        settingData();
    }, [categorySelected, subCategorySelected, categoryRefresh, activeFlag, dataAdd])

    useEffect(() => {
        if (itemInventoryDetails && categorySelected) {
            let filterSubCategoryOptions = []
            setSubCategorySelected("")
            actualInventoryData.forEach(itemLists => {
                if (categorySelected === itemLists.category)
                    filterSubCategoryOptions.push({ "value": itemLists.sub_category, "label": itemLists.sub_category })
            })
            setFilterSubCategory(Array.from(new Set(filterSubCategoryOptions.map(JSON.stringify))).map(JSON.parse))
        }
    }, [categorySelected])

    const goToAnalysisPage = (item) => {
        sessionStorage.setItem("itemDetail", JSON.stringify(item));
        window.open("#/inventoryListItems");
    }




    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    useEffect(() => {
        if (exportData)
            exportInventoryDetails()
    }, [exportData])

    let outer_size = 1;
    let avg_sale_rate = 0;
    let inventory = 0;
    return (
        <div className={"main-content-class"}>
            <NavBar />
            <div style={{ paddingTop: 100, textAlign: "center" }}>
                <Alert variant={"info"}>SYSTEM INVENTORY</Alert><br />
                <Navbar className="bg-light mr-auto justify-content-between" className={"navigation-bar"}>
                    <Form className={"navigational-data"} inline>
                        <Badge variant="dark">Select Inventory Date </Badge>
                        <DatePicker
                            selected={startDate}
                            onChange={(val) => { setStartDate(val); setHandleDateChange(true) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"
                            customInput={<CustomDateInput />}

                        />

                        {inventoryDetails.length > 0 && <><InputGroup style={{ marginLeft: "20px" }}>
                            <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Brand / Description" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                        </InputGroup>
                            <InputGroup size="sm" style={{ marginLeft: "auto", marginRight: 10 }}>
                                {(validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <Button size="sm" variant="warning" onClick={() => setExportData(true)}>Export</Button> : ""}
                            </InputGroup></>}
                    </Form>
                </Navbar>
                <Form inline style={{ display: "flex", marginBelow: "10px", marginLeft: "15px" }}>
                    <InputGroup size="sm">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="active-flag">Active Lines</InputGroup.Text>
                        </InputGroup.Prepend>
                        {/* {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && */}
                        <DropdownButton size="sm" id="dropdown-basic-button" title={activeFlag ? activeFlag : "Select active lines"}>
                            <Dropdown.Item value={"Y"} onClick={(e) => { e.preventDefault(); setActiveFlag("Y");  }}>Y</Dropdown.Item>
                            <Dropdown.Item value={"N"} onClick={(e) => { e.preventDefault(); setActiveFlag("N"); }}>N</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item value={"All"} onClick={(e) => { e.preventDefault(); setActiveFlag("All"); }}>All</Dropdown.Item>

                        </DropdownButton> 
                    </InputGroup>
                    <div size="sm" style={{ marginLeft: "10px", width: "100%", maxWidth: "500px", padding: "5px" }}>
                        <ReactSelect size="sm" ref={categoryFilterRef} placeholder={"Select a Category"} isClearable={"true"} onChange={(val) => { if (val !== null) { setCategorySelected(val.value); setSubCategorySelected(""); } else { setCategoryRefresh(true); setCategorySelected(""); } }} options={filterCategory} /></div>
                    {<div size="sm" style={{ marginLeft: "10px", width: "100%", maxWidth: "500px", padding: "5px" }}>
                        {(categorySelected) ? <ReactSelect size="sm" style={{ marginLeft: "10px", width: "500px" }} placeholder={"Select a SubCategory"} isClearable={"true"} onChange={(val) => { if (val !== null) setSubCategorySelected(val.value); else { setSubCategorySelected(""); setCategoryRefresh(true) } }} options={filterSubCategory} /> : ""}</div>}
                    <InputGroup size="sm" style={{ marginLeft: "20px" }}>
                    </InputGroup>
                </Form>

            </div>
            {itemInventoryDetails.length > 0 &&
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <td><center>Cat</center></td>
                                <td><center>Sub Cat</center></td>
                                <td><center>Item Code</center></td>
                                <td><center>Item Description</center></td>
                                <td><center>Outer Size (Kg/Pc)</center></td>
                                <td><center>UoM</center></td>
                                <td><center>System Inventory (After Shipped)</center></td>
                                {todayDate == itemInventoryDetails[0].inventory_date? <td><center>System Inventory (Current + Non Converted)</center></td> : <td><center>System Inventory (Mid Night)</center></td>}
                                <td><center>Avg Sale Rate (Kg/Pc)</center></td>
                                <td><center>Inventory In Days</center></td>
                                <td><center>Inventory In Outers (Bags/Pc)</center></td>
                                <td><center>Rep. Brand</center></td>
                                <td><center>Location</center></td>
                                <td><center>X</center></td>
                                {/* <td><center>System Inventory For App StockOut</center></td> */}


                            </tr>
                        </thead>
                        <tbody>
                            {(itemInventoryDetails.length > 0 && searchText === "") ? itemInventoryDetails.map(items => (
                                <tr>
                                    <td><center>{items.category ? items.category.replace('/', '/ ') : ""}</center></td>
                                    <td><center>{items.sub_category}</center></td>
                                    <td><center>{items.item_id}</center></td>
                                    <td><center>{items.brand ? (items.brand + " - " + items.item_disc) : items.item_disc}</center></td>
                                    <td><center>{items.outer_size}</center></td>
                                    <td><center>{items.UOM}</center></td>
                                    <td><center>{getSystemInventoryAfterShipping(items, items.outer_size)}</center></td>
                                    <td><center>{inventory = getSystemInventory(items, items.outer_size)}</center></td>
                                    <td><center>{avg_sale_rate = (Number(items.order_qty_avg) * items.outer_size).toFixed(1)}</center></td>
                                    <td><center>{avg_sale_rate > 0 ? (inventory / avg_sale_rate).toFixed(1) : 0}</center></td>
                                    <td><center>{(inventory / items.outer_size).toFixed(1)}</center></td>
                                    <td><center>{items.replacement_brand}</center></td>
                                    <td><center>{items.location}</center></td>
                                    <td><center><IconButton onClick={() => { goToAnalysisPage(items) }}><i class="fas fa-chart-bar" size="7x"></i></IconButton></center></td>
                                </tr>
                            )) :

                                filterList.length > 0 && filterList.map(items => (
                                    <tr>
                                        <td><center>{items.category ? items.category.replace('/', '/ ') : ""}</center></td>
                                        <td><center>{items.sub_category}</center></td>
                                        <td><center>{items.item_id}</center></td>
                                        <td><center>{items.brand ? (items.brand + " - " + items.item_disc) : items.item_disc}</center></td>
                                        <td><center>{items.outer_size}</center></td>
                                        <td><center>{items.UOM}</center></td>
                                        <td><center>{getSystemInventoryAfterShipping(items, items.outer_size)}</center></td>
                                        <td><center>{inventory = getSystemInventory(items, items.outer_size)}</center></td>
                                        <td><center>{avg_sale_rate = (Number(items.order_qty_avg) * items.outer_size).toFixed(1)}</center></td>
                                        <td><center>{avg_sale_rate > 0 ? (inventory / avg_sale_rate).toFixed(1) : 0}</center></td>
                                        <td><center>{(inventory / items.outer_size).toFixed(1)}</center></td>
                                        <td><center>{items.replacement_brand}</center></td>
                                        <td><center>{items.location}</center></td>
                                        <td><center><IconButton onClick={() => { goToAnalysisPage(items) }}><i class="fas fa-chart-bar" size="7x"></i></IconButton></center></td>
                                        {/* <td><center>{Math.trunc(inventory / outer_size)}</center></td> */}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
            }

            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}

        </div>
    )
}

export default InventoryListing
