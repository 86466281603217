import React, { useEffect, useState, useRef } from "react";
import {
  Navbar,
  Badge,
  Form,
  InputGroup,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import { validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import AddEditSupplierModal from "./AddEditSupplierModal";
import XLSX from "xlsx";

const SuppliersDB = database.database().ref(`Suppliers`);

const AddSupplier = (props) => {
  const [suppliersList, setSuppliersList] = useState();
  const [showSuppliersList, setShowSuppliersList] = useState();
  const [supplierName, setSupplierName] = useState();
  const [searchSupplierText, setSearchSupplierText] = useState("");
  const [addeditsuppliermodal, setAddEditSupplierModal] = useState(false);
  const [supplierModalType, setSupplierModalType] = useState("Add");
  const [supplierEditData, setSupplierEditData] = useState();

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    //const suppliers = []
    const sortSupplierList = [];

    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        // console.log(supplier);
        const supplier_name = suppliersList[supplier]?.supplier_name
          ? suppliersList[supplier].supplier_name
          : suppliersList[supplier].Company_Name;
        const supplier_id = suppliersList[supplier]?.supplier_id
          ? suppliersList[supplier].supplier_id
          : suppliersList[supplier].CF_Supplier_ID;
        const first_name = suppliersList[supplier]?.first_name
          ? suppliersList[supplier].first_name
          : suppliersList[supplier].First_Name;
        const last_name = suppliersList[supplier]?.last_name
          ? suppliersList[supplier].last_name
          : suppliersList[supplier].Last_Name;
        const phone_number = suppliersList[supplier]?.phone_number
          ? suppliersList[supplier].phone_number
          : suppliersList[supplier].MobilePhone;
        const email = suppliersList[supplier]?.email
          ? suppliersList[supplier].email
          : suppliersList[supplier].EmailID;
        const remarks = suppliersList[supplier]?.remarks
          ? suppliersList[supplier].remarks
          : suppliersList[supplier].Notes;
        const supplierType = suppliersList[supplier]?.supplierType
          ? suppliersList[supplier].supplierType
          : suppliersList[supplier].CF_Supplier_Type;
        const supplierGroup = suppliersList[supplier]?.supplierGroup
          ? suppliersList[supplier].supplierGroup
          : suppliersList[supplier].CF_Supplier_Group;
        const supplierBankName = suppliersList[supplier]?.supplierBankName
          ? suppliersList[supplier].supplierBankName
          : suppliersList[supplier].CF_Supplier_Bank_Name;
        const supplierAcNumber = suppliersList[supplier]?.supplierAcNumber
          ? suppliersList[supplier].supplierAcNumber
          : suppliersList[supplier].CF_Supplier_Account_Number;
        const supplierBankIfsc = suppliersList[supplier]?.supplierBankIfsc
          ? suppliersList[supplier].supplierBankIfsc
          : suppliersList[supplier].CF_Supplier_Bank_IFSC;
        const supplierBankBranch = suppliersList[supplier].supplierBankBranch
          ? suppliersList[supplier].supplierBankBranch
          : suppliersList[supplier].CF_Supplier_Bank_Branch;
        const PaymentTermsLabel = suppliersList[supplier]?.PaymentTermsLabel
          ? suppliersList[supplier].PaymentTermsLabel
          : suppliersList[supplier].Payment_Terms_Label;
        const BranchID = suppliersList[supplier]?.BranchID
          ? suppliersList[supplier].BranchID
          : suppliersList[supplier].Branch_ID;
        const BranchName = suppliersList[supplier]?.BranchName
          ? suppliersList[supplier].BranchName
          : suppliersList[supplier].Branch_Name;
        const sourceOfSupply = suppliersList[supplier]?.sourceOfSupply
          ? suppliersList[supplier].sourceOfSupply
          : suppliersList[supplier].Source_of_Supply;
        const GstTreatment = suppliersList[supplier]?.GstTreatment
          ? suppliersList[supplier].GstTreatment
          : suppliersList[supplier].GST_Treatment;
        const Gstin = suppliersList[supplier]?.Gstin
          ? suppliersList[supplier].Gstin
          : suppliersList[supplier]["GST_Identification_Number_(GSTIN)"];
        const Billing_Address = suppliersList[supplier].Billing_Address;
        const Shipping_Address = suppliersList[supplier].Shipping_Address;
        const Billing_City = suppliersList[supplier].Billing_City;
        const Shipping_City = suppliersList[supplier].Shipping_City;
        const Billing_Street = suppliersList[supplier].Billing_Street2;
        const Shipping_Street = suppliersList[supplier].Shipping_Street2;
        const Billing_State = suppliersList[supplier].Billing_State;
        const Shipping_State = suppliersList[supplier].Shipping_State;
        const Billing_Code = suppliersList[supplier]?.Billing_Code ? suppliersList[supplier].Billing_Code : "" ;
        const Shipping_Code = suppliersList[supplier]?.Shipping_Code ? suppliersList[supplier].Shipping_Code : "";
        const ImgUrl = suppliersList[supplier].ImgUrl;
        const PAN = suppliersList[supplier]?.PAN ? suppliersList[supplier].PAN : "" ;
        const TAN = suppliersList[supplier]?.TAN ? suppliersList[supplier].TAN : "";
        const FSSAI = suppliersList[supplier].FSSAI;
        const TrAbv50 = suppliersList[supplier]?.TrAbv50
          ? suppliersList[supplier].TrAbv50
          : "No"  ;
        const billadd = suppliersList[supplier]?.billadd ? suppliersList[supplier].billadd : "Not Same";
        sortSupplierList.push({
          supplier_name: supplier_name,
          label: supplier_name,
          key: supplier,
          supplier_id: supplier_id ? supplier_id : "",
          first_name: first_name,
          last_name: last_name,
          phone_number: phone_number,
          email: email,
          remarks: remarks,
          supplierType: supplierType,
          supplierGroup: supplierGroup,
          supplierAcNumber: supplierAcNumber,
          supplierBankName: supplierBankName,
          supplierBankIfsc: supplierBankIfsc,
          supplierBankBranch: supplierBankBranch,
          PaymentTermsLabel: PaymentTermsLabel,
          BranchID: BranchID,
          BranchName: BranchName,
          sourceOfSupply: sourceOfSupply,
          GstTreatment: GstTreatment,
          Gstin: Gstin,
          Billing_Address: Billing_Address,
          Shipping_Address: Shipping_Address,
          Billing_City : Billing_City,
          Shipping_City : Shipping_City,
          Billing_Street : Billing_Street,
          Shipping_Street : Shipping_Street,
          Billing_Code:Billing_Code,
          Shipping_Code:Shipping_Code,
          Billing_State:Billing_State,
          Shipping_State : Shipping_State,

          PAN: PAN,
          TAN: TAN,
          FSSAI: FSSAI,
          ImgUrl: ImgUrl,
          TrAbv50: TrAbv50,
          billadd: billadd,
        });

        /*sortSupplierList[supplier] = {
                    "supplier_name": supplier_name,
                    "label": supplier_name,
                    "key": supplier,
                    "supplier_id": supplier_id ? supplier_id : "",
                    "first_name": first_name,
                    "last_name": last_name,
                    "phone_number": phone_number,
                    "email": email,
                    "remarks": remarks
                }*/
      });

      // sort by supplier_name
      sortSupplierList.sort(function (a, b) {
        //var nameA = a.supplier_name.toUpperCase(); // ignore upper and lowercase
        let nameA = a.supplier_id;
        //var nameB = b.supplier_id.toUpperCase(); // ignore upper and lowercase
        let nameB = b.supplier_id;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      //sortSupplierList.sort();
      // sortSupplierList.forEach(items => {
      //     suppliers.push({ "value": items, "label": items })
      // })
      // console.log('suppliers: ', sortSupplierList);
    }).then(() => {
      setSuppliersList(sortSupplierList);
      setShowSuppliersList(sortSupplierList);
    });
  }, []);

  useEffect(() => {
    if (searchSupplierText) searchSupplierList();
    else setShowSuppliersList(suppliersList);
  }, [searchSupplierText]);

  useEffect(() => {
    setShowSuppliersList(showSuppliersList);
  }, [showSuppliersList]);

  const searchSupplierList = () => {
    const filterData = [];
    const filterResult = suppliersList.filter((obj) => {
      //const supplierName = suppliersList[obj].supplier_name && suppliersList[obj].supplier_name.trim().toLowerCase() || "";
      const supplierName =
        (obj.supplier_name && obj.supplier_name.trim().toLowerCase()) || "";
      const supplierid = obj?.supplier_id
        ? obj.supplier_id.toString().trim().toLowerCase()
        : " ";
      const phoneNumber =
        (obj.phone_number && obj.phone_number.trim().toLowerCase()) || "";
      const poc = (obj.first_name && obj.first_name.trim().toLowerCase()) || "";
      //setSearchSupplierText(searchSupplierText.trim().toLowerCase());
      if (supplierName.match(searchSupplierText.trim().toLowerCase()) != null)
        return supplierName.match(searchSupplierText.trim().toLowerCase());
      if (supplierid.match(searchSupplierText.trim().toLowerCase()) != null)
        return supplierid.match(searchSupplierText.trim().toLowerCase());
      if (phoneNumber.match(searchSupplierText.trim().toLowerCase()) != null)
        return phoneNumber.match(searchSupplierText.trim().toLowerCase());
      if (poc.match(searchSupplierText.trim().toLowerCase()) != null)
        return poc.match(searchSupplierText.trim().toLowerCase());
    });
    setShowSuppliersList(filterResult);
  };

  const deleteSupplierItem = (item) => {
    const deleteKey = item.key;
    const supplier_name = item.supplier_name;

    console.log(deleteKey);

    //console.log('showSuppliersList item: ', showSuppliersList);

    const answer = window.confirm(
      `Are you sure you want to delete '${supplier_name}' supplier?`
    );

    if (answer) {
      SuppliersDB.child(deleteKey)
        .remove()
        .then(() => {
          /*setTimeout(() => {
                        window.location.reload()
                    }, 2000);*/

          for (let [i, supplier] of showSuppliersList.entries()) {
            if (supplier.key == deleteKey) {
              const latSuppList = [...showSuppliersList];
              latSuppList.splice(i, 1);
              //console.log('latSuppList: ', latSuppList);
              setShowSuppliersList(latSuppList);
            }
          }

          for (let [i, supplier] of suppliersList.entries()) {
            if (supplier.key == deleteKey) {
              const latSuppList = [...suppliersList];
              latSuppList.splice(i, 1);
              //console.log('latSuppList: ', latSuppList);
              setSuppliersList(latSuppList);
            }
          }

          return toast(`Deleted ${supplier_name} successfully`, {
            type: toast.TYPE.SUCCESS,
          });
        })
        .catch((error) => {
          toast(`Failed to deleted item`, { type: toast.TYPE.ERROR });
        });
    }
  };
  const onEditHandler = (item) => {
    //console.log("onEditHandler: ",item)
    const itemKey = item.key;

    for (let [i, supplier] of showSuppliersList.entries()) {
      if (supplier.key == itemKey) {
        const latSuppList = [...showSuppliersList];
        latSuppList[i] = item;
        //console.log('latSuppList: ', latSuppList);
        setShowSuppliersList(latSuppList);
      }
    }

    for (let [i, supplier] of suppliersList.entries()) {
      if (supplier.key == itemKey) {
        const latSuppList = [...suppliersList];
        latSuppList[i] = item;
        //latSuppList.splice(i, 1);
        //console.log('latSuppList: ', latSuppList);
        setSuppliersList(latSuppList);
      }
    }
  };

  const handleModalCloseShow = (val) => {
    setAddEditSupplierModal(val);
  };
  //const handleModalShow = () => setAddEditSupplierModal(true);

  const exportSupplierDetails = () => {
    const exportSupplierDetails = [
      [
        "Supplier ID",
        "Supplier Name",
        "Point Of Contact",
        "Phone Number",
        "Email",
        "Remarks",
        "Supplier Type",
        "Supplier Group",
        "Supplier Ac Number",
        "Supplier Bank Name",
        "Supplier Bank Ifsc",
        "Supplier Bank Branch",
        "Payment Terms Label",
        "Branch ID",
        "Branch Name",
        "Source Of Supply",
        "GST Treatment",
        "GSTIN",
        "PAN",
        "TAN",
        "FSSAI",
        "Billing Address",
        "Shipping Address",
        "Transaction Above 50,00,000",
        "Cheque Image URL",
      ],
    ];

    Object.keys(suppliersList).forEach((supplier) => {
      exportSupplierDetails.push([
        suppliersList[supplier].supplier_id,
        suppliersList[supplier].supplier_name,
        `${
          suppliersList[supplier].first_name
            ? suppliersList[supplier].first_name
            : ""
        }   ${
          suppliersList[supplier].last_name
            ? suppliersList[supplier].last_name
            : ""
        }`,
        suppliersList[supplier].phone_number,
        suppliersList[supplier].email,
        suppliersList[supplier].remarks,
        suppliersList[supplier].supplierType,
        suppliersList[supplier].supplierGroup,
        suppliersList[supplier].supplierBankName,
        suppliersList[supplier].supplierAcNumber,
        suppliersList[supplier].supplierBankIfsc,
        suppliersList[supplier].supplierBankBranch,
        suppliersList[supplier].PaymentTermsLabel,
        suppliersList[supplier].BranchID,
        suppliersList[supplier].BranchName,
        suppliersList[supplier].sourceOfSupply,
        suppliersList[supplier].GstTreatment,
        suppliersList[supplier].Gstin,
        suppliersList[supplier].PAN,
        suppliersList[supplier].TAN,
        suppliersList[supplier].FSSAI,
        `${
          suppliersList[supplier].Billing_Address
            ? suppliersList[supplier].Billing_Address
            : ""
        } ${
          suppliersList[supplier].Billing_Street
            ? suppliersList[supplier].Billing_Street
            : " "
        } ${
          suppliersList[supplier].Billing_City
            ? suppliersList[supplier].Billing_City
            : " "
        } ${
          suppliersList[supplier].Billing_State
            ? suppliersList[supplier].Billing_State
            : ""
        }  ${
          suppliersList[supplier].Billing_Code
            ? suppliersList[supplier].Billing_Code
            : " "
        }`,
        `${
          suppliersList[supplier].Shipping_Address
            ? suppliersList[supplier].Shipping_Address
            : " "
        } ${
          suppliersList[supplier].Shipping_Street
            ? suppliersList[supplier].Shipping_Street
            : " "
        } ${
          suppliersList[supplier].Shipping_City
            ? suppliersList[supplier].Shipping_City
            : " "
        } ${
          suppliersList[supplier].Shipping_State
            ? suppliersList[supplier].Shipping_State
            : ""
        }  ${
          suppliersList[supplier].Shipping_Code
            ? suppliersList[supplier].Shipping_Code
            : ""
        }`,
        suppliersList[supplier].TrAbv50,
        suppliersList[supplier].ImgUrl,
      ]);
    });

    const wb = XLSX.utils.book_new();
    const suppliersDetailList = XLSX.utils.aoa_to_sheet(exportSupplierDetails);
    suppliersDetailList["!cols"] = fitToColumn(exportSupplierDetails);
    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 1))
        ),
      }));
    }
    XLSX.utils.book_append_sheet(wb, suppliersDetailList, `Supplier Details`);
    XLSX.writeFile(wb, `Suppliers_Details_${Date.now()}.xlsx`);
  };

  return (
    <>
      <NavBar />
      <Navbar
        className="bg-light mr-auto justify-content-between"
        style={{ paddingTop: 100 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Badge variant="dark">Add Supplier</Badge>
        </div>
        <InputGroup style={{ marginLeft: "20px" }}>
          <Form.Control
            id="searchSupplier"
            size="sm"
            style={{ width: "300px" }}
            className="searchBox"
            placeholder="Search Supplier"
            value={searchSupplierText}
            onChange={(e) => {
              setSearchSupplierText(e.target.value);
            }}
          />
        </InputGroup>
        <Button
          size="sm"
          variant="success"
          style={{ marginLeft: "20px" }}
          onClick={() => {
            setAddEditSupplierModal(true);
            setSupplierModalType("Add");
          }}
        >
          Add Supplier
        </Button>
        <Button
          size="sm"
          variant="warning"
          style={{ marginLeft: "20px" }}
          onClick={exportSupplierDetails}
        >
          Export
        </Button>
        {/* <Form>
          <Form.File onChange={handleChange} />
          <Button
            size="sm"
            variant="primary"
            style={{ marginLeft: "20px" }}
            onClick={handleSubmit}
          >
            Import
          </Button>
        </Form> */}
      </Navbar>
      <Table striped bordered>
        <thead>
          <tr>
            <td>
              <center>
                <b>Supplier Id</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Name</b>
              </center>
            </td>
            <td>
              <center>
                <b>Point Of Contact</b>
              </center>
            </td>
            <td>
              <center>
                <b>Phone Number</b>
              </center>
            </td>
            <td>
              <center>
                <b>Email</b>
              </center>
            </td>
            <td>
              <center>
                <b>Remarks</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Type</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Group</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Account Number</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Bank Name</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Bank IFSC</b>
              </center>
            </td>
            <td>
              <center>
                <b>Supplier Bank Branch</b>
              </center>
            </td>
            <td>
              <center>
                <b>Branch ID</b>
              </center>
            </td>
            <td>
              <center>
                <b>Branch Name</b>
              </center>
            </td>
            <td>
              <center>
                <b>Payment Term Labels</b>
              </center>
            </td>
            <td>
              <center>
                <b>Source Of Supply</b>
              </center>
            </td>
            <td>
              <center>
                <b>GST Treatment</b>
              </center>
            </td>
            <td>
              <center>
                <b>GSTIN</b>
              </center>
            </td>
            <td>
              <center>
                <b>PAN</b>
              </center>
            </td>
            <td>
              <center>
                <b>TAN</b>
              </center>
            </td>
            <td>
              <center>
                <b>FSSAI</b>
              </center>
            </td>
            <td>
              <center>
                <b>Billing Address</b>
              </center>
            </td>
            <td>
              <center>
                <b>Shipping Address</b>
              </center>
            </td>
            <td>
              <center>
                <b>Cancelled Cheque</b>
              </center>
            </td>
            <td>
              <center>
                <b>Edit</b>
              </center>
            </td>
            {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && (
              <td>
                <center>
                  <b>X</b>
                </center>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {showSuppliersList
            ? Object.keys(showSuppliersList).map((items, index) => (
                <>
                  <tr>
                    <td>
                      <center>{showSuppliersList[items].supplier_id} </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].supplier_name} </center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].first_name}{" "}
                        {showSuppliersList[items].last_name}
                      </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].phone_number} </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].email} </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].remarks} </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].supplierType}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].supplierGroup}</center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].supplierAcNumber}
                      </center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].supplierBankName}
                      </center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].supplierBankIfsc}
                      </center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].supplierBankBranch}
                      </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].BranchID}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].BranchName}</center>
                    </td>

                    <td>
                      <center>
                        {showSuppliersList[items].PaymentTermsLabel}
                      </center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].sourceOfSupply}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].GstTreatment}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].Gstin}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].PAN}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].TAN}</center>
                    </td>
                    <td>
                      <center>{showSuppliersList[items].FSSAI}</center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].Billing_Address}{" "}
                        {showSuppliersList[items].Billing_Street}{" "}
                        {showSuppliersList[items].Billing_City}{" "}
                        {showSuppliersList[items].Billing_Code}{" "}
                        {showSuppliersList[items].Billing_State}
                      </center>
                    </td>
                    <td>
                      <center>
                        {showSuppliersList[items].Shipping_Address}{" "}
                        {showSuppliersList[items].Shipping_Street}{" "}
                        {showSuppliersList[items].Shipping_City}{" "}
                        {showSuppliersList[items].Shipping_Code}{" "}
                        {showSuppliersList[items].Shipping_State}
                      </center>
                    </td>
                    <td>
                      <center>
                        {suppliersList[items]?.ImgUrl ? (
                          <a
                            href={showSuppliersList[items].ImgUrl}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={showSuppliersList[items].ImgUrl}
                              width="300px"
                              height="150px"
                            />{" "}
                          </a>
                        ) : (
                          <p>Image Not Uploaded</p>
                        )}
                      </center>
                    </td>
                    <td>
                      <center>
                        <Button
                          size="sm"
                          onClick={() => {
                            setSupplierModalType("Edit");
                            setSupplierEditData(showSuppliersList[items]);
                            setAddEditSupplierModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </center>
                    </td>
                    {validateIsAdminUser(
                      sessionStorage.getItem(LOGGED_USER_EMAIL)
                    ) && (
                      <td>
                        <Button
                          onClick={() =>
                            deleteSupplierItem(showSuppliersList[items])
                          }
                        >
                          <i
                            style={{ color: "white" }}
                            className="fas fa-trash-alt fa-1x"
                          ></i>
                        </Button>
                      </td>
                    )}
                  </tr>
                </>
              ))
            : null}
        </tbody>
      </Table>

      {addeditsuppliermodal && (
        <AddEditSupplierModal
          show={addeditsuppliermodal}
          showCloseModal={handleModalCloseShow}
          onHide={() => setAddEditSupplierModal(false)}
          data={suppliersList}
          onEdit={onEditHandler}
          supplierModalType={supplierModalType}
          supplierEditData={supplierEditData}
        />
      )}
    </>
  );
};

export default AddSupplier;
