import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert,Dropdown,DropdownButton, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {Switch} from '@material-ui/core'
import firebase from '../../../service/firebaseConfig';
import {support_portal_url} from '../../../service/servicesConfig';

function AddUserModalPopup({...props}) {
  console.log("AddUserModalPopup");

  const [userDetailsObject,setUserDetailsObject] = useState({});
  const [userType,setUserType] = useState("");
  const [userActive,setUserActive] = useState(true);
  const [userOtpBypass,setUserOtpBypass] = useState(true);
  const [usertypeList,setUsertypeList] = useState([]);


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useState(() => {
      firebase.database().ref(`User_Role_Access/AdminPortal`).once('value').then((snapshot) => {
        if(snapshot.hasChildren()){
          const userroledata = snapshot.val();
          let newUserTypes=Object.keys(userroledata);
          let adminUser=["admin"];
          setUsertypeList(adminUser.concat(newUserTypes));
        }else{
          setUsertypeList([]);
        }
      });
    },[])

    const addUser = () => {
      console.log("addUser");
      console.log(userDetailsObject);
      console.log(userType);
      console.log(userActive);
      let mobileNum=userDetailsObject.mobile?userDetailsObject.mobile:"";
      if(Object.keys(userDetailsObject).length==0){
        toast("Please enter email and password.", { type: toast.TYPE.ERROR });
      }else if(mobileNum==""){
        toast("Please enter mobile.", { type: toast.TYPE.ERROR });
      }else if(mobileNum.length!=10){
        toast("Please enter valid mobile number.", { type: toast.TYPE.ERROR });
      }else if(!userDetailsObject.email){
        toast("Please enter email.", { type: toast.TYPE.ERROR });
      }else if(!userDetailsObject.password){
        toast("Please enter password.", { type: toast.TYPE.ERROR });
      }else if(userType==""){
        toast("Please select usertype.", { type: toast.TYPE.ERROR });
      }else{
        let userObj={};
        userObj['mobile']=userDetailsObject.mobile;
        userObj['email']=userDetailsObject.email;
        userObj['password']=userDetailsObject.password;
        userObj['status']=userActive==true?"Active":"N";
        userObj['user_type']=userType;
        userObj['otp_bypass']=userOtpBypass;

        fetch(`${support_portal_url}/api/adminportaluser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': sessionStorage.getItem("apiToken")
            },
            body: JSON.stringify(userObj)
        }).then(res => {
          return res.json();
        }).then(data => {
          console.log(data);
          if(data["success"]==1){
            userObj['id']=data.userid;
            toast("Added user ", { type: toast.TYPE.SUCCESS });
            props.onHide();
            props.callback(userObj);
          }else{
            let errMessage=data['message']?data['message']:"";
            toast("Error in adding user : "+errMessage, { type: toast.TYPE.ERROR });
            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }
          }
        }).catch(err => {
          toast("Error in adding user : "+err, { type: toast.TYPE.ERROR });
        })
      }
    }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Add User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                  <tbody>
                      <tr>
                          <td>Mobile</td>
                          <td><Form.Control type="tel" pattern="[1-9]{1}[0-9]{9}" required onChange={(e) => {setUserDetailsObject({ ...userDetailsObject, "mobile": (e.target.value) })}} /></td>
                      </tr>
                      <tr>
                          <td>Email</td>
                          <td><Form.Control type="text" onChange={(e) => {setUserDetailsObject({ ...userDetailsObject, "email": (e.target.value) })}} /></td>
                      </tr>
                      <tr>
                          <td>Password</td>
                          <td><Form.Control type="password" onChange={(e) => setUserDetailsObject({ ...userDetailsObject, "password": (e.target.value) })} /></td>
                      </tr>
                      <tr>
                          <td>Usertype</td>
                          <td>
                              <InputGroup>
                                <DropdownButton as={InputGroup.Append} variant="outline-primary" title={userType?userType:"SELECT"}>
                                  {(usertypeList.length > 0)? usertypeList.map((usertype,index) => (
                                    <Dropdown.Item href="#" key={index} name={usertype} onClick={(e)=>{e.preventDefault();setUserType(e.target.name)}}>{usertype}</Dropdown.Item>
                                  )):null }
                                </DropdownButton>
                              </InputGroup>
                          </td>
                      </tr>
                      <tr>
                          <td>Status</td>
                          <td><Switch name="status" color="primary" checked={userActive} onChange={(e)=>{setUserActive(e.target.checked)}} /></td>
                      </tr>
                      <tr>
                          <td>OTP Bypass</td>
                          <td><Switch name="otp_bypass" color="primary" checked={userOtpBypass} onChange={(e)=>{setUserOtpBypass(e.target.checked)}} /></td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={addUser}>Add User</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddUserModalPopup