import React, { useState, useContext, useEffect } from "react";
import { Breadcrumb, Tab, Row, Col, Nav, Button, Form } from "react-bootstrap";
import { rootStore } from "../../store";
import { useHistory } from "react-router-dom";
import ChangeRegionComp from "../ChangeRegion";
import SearchComp from "../Search";
import StockEdit from "../ProductDataCard/EditStock";
import ProductContext from "../../context/productList";
import { validateIsAdminUser, getUserType } from "../../service/credentials";
import {
  ADD_PRODUCTS,
  BULK_UPLOADS,
  INIT_PAGE_TITLE,
  SESSION_TEXT,
  LOGGED_USER_EMAIL,
  ALL_REGIONS_LIST,
  PROD_MASTER_TABLE
} from "../../constants/allConstants";
import {
  NavBarContainer,
  LeftContainer,
  SZLogoContainer,
  
  MidContainer,
  RightContainer,
  BulkUploadContainer,
  AddProductContainer,
  UserStatusContainer,
  InventoryContainer,
  StockContainer,
  ProdsContainer
} from "./wrapper";

import database from "../../service/firebaseConfig";
import Modal from 'react-bootstrap/Modal';
import { TransferList } from "@mantine/core";
import { CompassCalibrationOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";

const NavBar = (props) => {
  
  let history = useHistory();
  const StockArray = [];
  const isUserSignedIn = true;
  const userStatusText = isUserSignedIn ? "Sign Out" : "Sign In";
  const globalState = useContext(rootStore);
  const productData = useContext(ProductContext);
  console.log(productData);

  const { dispatch } = globalState;
  const hideRegion =
    globalState.state.hasOwnProperty("hideRegionOption") === true
      ? true
      : false;
  const showAddProd =
    globalState.state.hasOwnProperty("addProduct") === true ? false : true;
  const isEditProd =
    globalState.state.hasOwnProperty("editData") === true ? true : false;
  const showBulkUpload =
    globalState.state.hasOwnProperty("bulkUploads") === true ? false : true;
    toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
      if (success) {
        return toast("Changed the status", { type: toast.TYPE.SUCCESS });
      }
      return toast("Couldn't change the status", { type: toast.TYPE.ERROR });
    };

    const notifyUserWithMessage = (success, message) => {
      if (!message) {
        return console.log("please pass message argument");
      }
      if (success) {
        return toast(message, {
          type: toast.TYPE.SUCCESS,
          style: { width: "100px", color: "red" },
        });
      }
      return toast(message, { type: toast.TYPE.ERROR });
    };
    

  const removeSession = () => {
    sessionStorage.clear();
    history.push("/login");
  };

  const addNewProduct = () => {
    document.title = `${INIT_PAGE_TITLE} | Add Product`;
    dispatch({ type: ADD_PRODUCTS });
    history.push("/addProd");
  };

  const goToInventory = () => {
    history.push("/listpurchase");
  };

  const addProdAtBulk = () => {
    document.title = `${INIT_PAGE_TITLE} | Bulk Upload`;
    dispatch({ type: BULK_UPLOADS });
    history.push("/bulk");
  };

  const onLogoClick = () => {
    switch (getUserType(sessionStorage.getItem(LOGGED_USER_EMAIL))) {
      case "admin":
        history.push("/Dashboard");
        break;
      case "product":
        history.push("/home");
        break;
      case "purchase":
        history.push("/addPurchaseDetails");
        break;
      case "grn":
        history.push("/listpurchase");
        break;
    }
  };



  return (
    <>
      <NavBarContainer>
        <Form>
          <Form.Row>
            <LeftContainer>
              <SZLogoContainer
                onClick={onLogoClick}
                key="0"
                title="Reload Home Page"
              />
              {!hideRegion ? <ChangeRegionComp /> : null}
            </LeftContainer>
            <RightContainer>
              <UserStatusContainer>
                <button onClick={removeSession}>{userStatusText}</button>
              </UserStatusContainer>
            </RightContainer>
          </Form.Row>
          <Form.Row>
            <LeftContainer>
              {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) &&
                (showAddProd ? (
                  <AddProductContainer onClick={addNewProduct}>
                    {" "}
                    Add New{" "}
                  </AddProductContainer>
                ) : null)}
            </LeftContainer>

            <MidContainer>
              <SearchComp />
            </MidContainer>

            <RightContainer>
              {!isEditProd && (
                <StockContainer>
                  {" "}
                  <Button
                    size="sm"
                    variant="success"
                    style={{ margin: "3px"}}
                    onClick={() => {
                      history.push({ pathname: "/stockEdit" });
                    }}
                  >
                    Stock
                  </Button>

                </StockContainer>
              )}
            </RightContainer>
            {/* <RightContainer>
              <StockContainer>
              <Button
                    size="sm"
                    variant="success"
                    style={{ margin: "3px"}}
                    onClick={() => {
                      history.push({ pathname: "/modernTrade" });
                    }}
                  >
                    Mt price
                  </Button>
              </StockContainer>
            </RightContainer> */}
          </Form.Row>
        </Form>
      </NavBarContainer>
    </>
  );
};

export default NavBar;
