import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  Stack,
  Select,
  TextInput,
  Divider,
  Text,
  Table,
  Box,
  NumberInput,
  Modal,
  Flex,
  Space,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { toast } from "react-toastify";
import { set } from "date-fns";
import moment from "moment";
import axios from "axios";
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";



export function PaymentModal({id, opened, setOpened, refreshListData,user,usertype}) {
const [paymentDetails,setPaymentDetails] = useState("");
const [paidAmount,setPaidAmount] = useState();
const currentDate = moment().format('YYYY-MM-DD');
const [paymentDate,setPaymentDate] = useState("");
const [paymentMode,setPaymentMode] = useState("");

function updatePaymentDetails () {
  if (
    !paymentDetails ||
    paidAmount === undefined ||
    paidAmount <= 0 ||
    !paymentDate ||
    !paymentMode
  ) {
    toast("Please fill in all required payment details.", {
      type: toast.TYPE.ERROR,
    });
    return;
  }
    let updateObj = { "work_order_id":id,
    "payment_done":1,
    "paid_amt":paidAmount,
    "payment_details":paymentDetails,
    "status":"Processed",
    "approved_at":currentDate,
    "approved_by":user,
    "payment_mode":paymentMode,
    "payment_date":moment(paymentDate).format('YYYY-MM-DD')

  }
   
    const url = `${DELIVERY_BASE_URL}/api/superzop/workorder/updateWorkOrders`
    axios.put(url, updateObj)
      .then(function (response) {
        refreshListData();
        toast("Changed Status,and saved payment details.", { type: toast.TYPE.SUCCESS });
        setOpened(false);
      })
      .catch(function (error) {
        console.log(error);
        toast("Couldn't change the status, and payment details not saved ", { type: toast.TYPE.ERROR });
      });
     
}


    return (
      <Modal opened={opened} onClose={() => setOpened(false)} title="Change status">
        <Stack spacing={"1.5rem"} align="stretch" justify="center">
          
            {`Enter Payment Details for this work ID - ${id}?`}
            
          <Space v={"sm"} />
          <Group grow style={{ width: "100%" }}>
          <TextInput
            required
            label="Payment Details"
            placeholder="remarks.."
            value={paymentDetails}
            onChange={(event) => setPaymentDetails(event.target.value)}
            />
            <NumberInput
                required
                label={`Amount Paid`}
                placeholder="e.g. 2000"
                value={paidAmount}
                min={1}
                allowDecimal={false}
                allowNegative={false}
                onChange={setPaidAmount}
            />

        
           
          </Group>
          <Group grow style={{ width: "100%" }}> 
          {user === 'finance@superzop.com' || user === 'financehead@superzop.com' || usertype == "admin" ? (
         
         <>
          <DatePicker
          required
          label="Payment Date"
          placeholder="Select payment date"
          value={paymentDate}
          onChange={setPaymentDate}
          />

           <TextInput
           required
           label="Payment Mode"
           placeholder="payment type"
           value={paymentMode}
           onChange={(event) => setPaymentMode(event.target.value)}
           />

         </>
         ) : null}
         </Group>
          <Space v={"sm"} />
          <Button color="teal" onClick={() => {updatePaymentDetails(); }}>Confirm</Button>
            <Button color="red" onClick={() => setOpened(false)}>Cancel</Button>
        </Stack>
      </Modal>
    );
  }
  