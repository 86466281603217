import React , {useState,useEffect} from 'react'
import {Modal, Button,Table, Form } from 'react-bootstrap';
import DeleteCombo from './DeleteCombo'
import database from '../../service/firebaseConfig';
import { PROD_MASTER_TABLE,REGION_S } from '../../constants/allConstants';

const Combo=({allComboItems,itemIdDetails,...props})=> {

    const [getNewCombo,setNewCombo]= useState(false)
    const [getDeletedCombos,setDeletedCombos] = useState([])
    const [getLastComboItem,setLastComboItem]=useState("")
    const [getLatestCombo,setLatestCombo]=useState(false)
    const [lastCombo,setlastCombo]=useState(false)
    const [getIncomingData,setIncomingData]=useState(false)
    const [newCombo,setnewCombo]=useState(0)
    const [getComboArray,setComboArray]=useState([])
    const [getDataOfCombos,setDataOfCombos]=useState("")
    const [getComboList,setComboList]=useState([])
    const [validated, setValidated] = useState(false);

    const deletedComboItems=[]
    let counter =0
    const arrofFlat=[]

    const checkCombos=()=>{

        
        let count=1
        Object.keys(allComboItems).map(items=>{
            
            setLastComboItem(items.substr(-1))
            if(items===("combo"+count)){
                console.log("saved items"+items)
            }
            else{
                console.log(`Deleted item combo${count}`)
                deletedComboItems.push(`combo${count}`)
                if(items===("combo"+(++count)))
                console.log(`saved items combo${count}`)
                else{
                    console.log(`Deleted item combo${count}`)
                    deletedComboItems.push(`combo${count}`)
                    if(items===("combo"+(++count)))
                    console.log(`saved items combo${count}`)
                    else{
                        console.log(`Deleted item combo${count}`)
                        deletedComboItems.push(`combo${count}`)
                        if(items===("combo"+(++count)))
                        console.log(`saved items combo${count}`)
                        else{
                            console.log(`Deleted item combo${count}`)
                            deletedComboItems.push(`combo${count}`)
                            if(items===("combo"+(++count)))
                            console.log(`saved items combo${count}`)
                            else{
                                console.log(`Deleted item combo${count}`)
                                deletedComboItems.push(`combo${count}`)
                                if(items===("combo"+(++count)))
                                console.log(`saved items combo${count}`)
                                else{
                                    console.log(`Deleted item combo${count}`)
                                    deletedComboItems.push(`combo${count}`)
                                    if(items===("combo"+(++count)))
                                    console.log(`saved items combo${count}`)
                                    else{
                                        console.log(`Deleted item combo${count}`)
                                        deletedComboItems.push(`combo${count}`)
                                        if(items===("combo"+(++count)))
                                        console.log(`saved items combo${count}`)
                                        else{
                                            console.log(`Deleted item combo${count}`)
                                            deletedComboItems.push(`combo${count}`)
                                            if(items===("combo"+(++count)))
                                            console.log(`saved items combo${count}`)
                                            else{
                                                console.log(`Deleted item combo${count}`)
                                                deletedComboItems.push(`combo${count}`)
                                                if(items===("combo"+(++count)))
                                                console.log(`saved items combo${count}`)
                                                else{
                                                    console.log(`Deleted item combo${count}`)
                                                    deletedComboItems.push(`combo${count}`)
                                                    if(items===("combo"+(++count)))
                                                    console.log(`saved items combo${count}`)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            count++
        })
        console.log(deletedComboItems)
        setDeletedCombos(deletedComboItems)
    }
   
    const addNewCombos=()=>{

        setIncomingData(true)
        
        if(getDeletedCombos.length>0){

            
            setComboArray([...getComboArray,getDeletedCombos[0]])
            setComboList([...getComboList,getDataOfCombos])
            getDeletedCombos.shift()
            console.log(getDeletedCombos)
            console.log(getComboArray)
            setLatestCombo(true)
            
        }
        else if(getDeletedCombos.length==0){
            console.log("entering")
            
            if(getLatestCombo){    
            if(newCombo<10){
                console.log("entering1")
                if(getLastComboItem!==""){
                    console.log(getLastComboItem)
                    const lastItem=parseInt(getLastComboItem)+1;
                    setnewCombo(lastItem)
                    console.log(lastItem)
                    setComboArray([...getComboArray,(`combo${lastItem}`)])
                    setComboList([...getComboList,getDataOfCombos])
                    setLastComboItem("")
                }
                else{setnewCombo(newCombo+1);console.log(newCombo);
                    setComboArray([...getComboArray,(`combo${newCombo+1}`)]);
                    setComboList([...getComboList,getDataOfCombos])}
                
            }
            else{
                setlastCombo(true)
                
            }
        }
        }
    }



        const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            }
            else{
                event.preventDefault();
                const finalComboList={}
                getComboList.shift()
                console.log(getComboList)
                console.log(getComboArray)
                for(var i=0;i<getComboArray.length;i++){
                    
                    finalComboList[getComboArray[i]]=getComboList[i]
                }
                console.log(finalComboList)
                database.database().ref(`${PROD_MASTER_TABLE}/${REGION_S}/${itemIdDetails}`).update(finalComboList).
                then(()=>{alert("data updated succesfully");props.onHide();window.location.reload()})
            }
        
            setValidated(true);
        }
    

    useEffect(() => {
        if(getNewCombo)
        checkCombos()
    }, [getNewCombo])

    useEffect(() => {
        if(getDeletedCombos.length>0)
        addNewCombos()
        
    }, [getDeletedCombos])

    

    return (
        <div>
    <Modal
        {...props}
        size="lg"
        aria-labelledby="combo_items"
        centered
    >
         <Form noValidate validated={validated} onSubmit={handleSubmit}>
    <Modal.Header closeButton>
        <Modal.Title id="combo_items">
        Add or Modify Combo
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    
        <Table>
        {Object.keys(allComboItems).map(items=>(
            <tr>
                <td>{items}</td>
                <td>{allComboItems[items]}</td>
                <td><Button variant="link" onClick={()=>{DeleteCombo(itemIdDetails,items);alert("Are u sure u want to delete")}}>
                    <i class="fas fa-trash-alt"></i></Button>
                </td>
            </tr>
        ))}
        </Table>
        {!lastCombo?<Button variant="info" onClick={()=>{setNewCombo(true);addNewCombos()}}>Add Combo</Button>:""}
        {
                getIncomingData?
                getDeletedCombos.length>0?getComboArray.map((items,index)=>(
                <Table>
                    <tr>
                        <td>{items}</td>
                        <td><Form.Control required onChange={(e)=>{
                            setDataOfCombos(e.target.value)
                        }}></Form.Control></td>
                        <Form.Control.Feedback type="invalid">Enter data</Form.Control.Feedback>
                    </tr>
                    

                </Table>))
            :getComboArray.map((items,index)=>(<Table>
            <tr>
                <td>{items}</td>
                <td><Form.Control required onChange={(e)=>{
                            setDataOfCombos(e.target.value)
                        }}></Form.Control></td>
            <Form.Control.Feedback type="invalid">Enter data</Form.Control.Feedback>
            </tr>
        </Table>))
        :""}
       
    </Modal.Body>
    <Modal.Footer>
        <Button type="submit" variant="danger" onClick={()=>{setComboList([...getComboList,getDataOfCombos])}}>Submit</Button>
        <Button onClick={()=>{props.onHide();window.location.reload()}}>Close</Button>
    </Modal.Footer>
    </Form>
    </Modal>
    
        </div>
    )
}

export default React.memo(Combo)
