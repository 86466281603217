import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import database from "../../service/firebaseConfig";
import { TRANSPORT_FACTOR, ALL_REGIONS_LIST, LOGGED_USER_EMAIL } from "../../constants/allConstants";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import PriceCalculations from "../../service/priceCalculations";
import {  validateIsAdminUser } from "../../service/credentials";
import { OFFER_BASE_URL, ORDERING_BASE_URL} from "../../service/servicesConfig";
import axios from 'axios';
import moment from 'moment';

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

function formatDate(date) {
  var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
}

function BulkUpdateProductDealerPriceModal({tempProductDetails,bulkProductUpdateModalOpen,region,productDataObj,priceDataObj,...props}) {
  console.log("BulkUpdateProductDealerPriceModal");
  console.log(bulkProductUpdateModalOpen);
  console.log(tempProductDetails);
  tempProductDetails.sort((a, b) => {
    if (a.type === 'parent' && b.type !== 'parent') {
      return -1;
    } else if (a.type !== 'parent' && b.type === 'parent') {
      return 1;
    } else {
      return 0;
    }
  });
  let origProductDetails = JSON.parse(JSON.stringify(tempProductDetails));
  const [productDetails, setProductDetails] = useState(origProductDetails);
  const [firebaseTotalUpdateCounter, setFirebaseTotalUpdateCounter] = useState(0);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [productChecked, setProductChecked] = useState({});
  const [mrp, setMrp] = useState('100');
  const [getRetailerMarginPerc, setRetailerMarginPerc] = useState();
  const [getSeriesFromDD,setSeriesForDD] = useState({value:"ALL",label:"ALL"});
  const [getRetailerSeriesMargins, setRetailerSeriesMargins] = useState([]);
  const [sellingPriceWoRounding, setSellingPriceWoRounding] = useState("10"?"10":0)
  const [sellingPriceExGst, setSellingPriceExGst] = useState("10"?"10":0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [dealerPrice, setDealerPrice] = useState();
  const [gstPercent, setGstPercent] = useState("");

  const checkRef = useRef();
  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    if(gstPercent && sellingPriceWoRounding) {
      calSellingPriceExGst();
    }
  },[gstPercent, sellingPriceWoRounding])

  useEffect(() => {
      if(updateCounter==firebaseTotalUpdateCounter && firebaseTotalUpdateCounter>0){
      toast(`Successfully updated price.`, { type: toast.TYPE.SUCCESS });
      props.onHide();
        setTimeout(function(){
        window.location.reload();
      }, 3000);
    }
    },[updateCounter])

  const updateCheckedProducts = (isProductChecked, updatedFrom) => {
      let prodObj={};
      productDetails.map((prod, index) => {
        if(prod.updated_from == updatedFrom) {
          let productkey=prod['rowselectkey'];
          prodObj[`${productkey}`]=isProductChecked;
        }
      });
    setProductChecked(prodObj);
    }

  //table logic
  const ProductTableHeader = () => {

    // let isProductChecked=initialRender==true?true:checkRef.current.checked;
    let rows=[];
    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
          return val.updated_from == 'Price Update'
      });
      productDetails.map((prod,index) =>{
        if(prod.updated_from == 'Price Update') { 
          let productkey=prod['rowselectkey'];
          let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
          rows = <tr>
           <th>
            <input
              type="checkbox"
              id="selectAllCheckbox"
              defaultChecked={isProductChecked}
              onChange={(event) => {
                console.log(event);
                setInitialRender(false);
                let bulkOrdersChecks = document.getElementsByClassName("bulkProductsCheck");
                for (let i = 0; i < bulkOrdersChecks.length; i++) {
                  // if (event.currentTarget.checked) {
                    bulkOrdersChecks[i].checked = event.currentTarget.checked;
                  // } else {
                  //   bulkOrdersChecks[i].checked = false;
                  // }
                }
                updateCheckedProducts(event.currentTarget.checked,'Price Update');
              }} />
                </th>
                  <th>ItemID</th>
                  <th>Item Description</th>
                  <th>Old Base Price</th>
                  <th>Base Price</th>
                  <th>GST %</th>
                  <th>Source Type</th>
                  <th>Transport</th>
                  <th>Mill Transport</th>
                  <th>Cash Discount</th>
                  <th>Dealer Price</th>
                  <th>Total Price</th>
                  <th>Gross Cost</th>
                  <th>Landing Cost</th>
                  <th>Delete</th>
        </tr>
        }
      })
      
        //}
      //})
    }else {
      rows = [];
    }
    return (
      <thead className="thead-light">
        {rows}
      </thead>
      );
  }
  const ProductTableHeader2 = () =>{
    let rows=[];

    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
        return val.updated_from == 'Edit Dealer Price'
      })
      let retailerMarginPerSeriesListArray = [];
      productDetails.map((prod) =>{
        {ALL_REGIONS_LIST.map((region) =>{
          if(prod[`retailer_margin_per_${region}`]) {
            retailerMarginPerSeriesListArray.push(region);
          }
        })}
      })
      let retailerMargin = 0;
      productDetails.map((val) =>{
        if(val['retailer_margin_per']) {
          retailerMargin =1;
        }
      })
      //retailerMarginPerSeriesList = new Set(retailerMarginPerSeriesList);
      const uniqueSet = new Set();

    // Create an array to store the unique values in order
      const retailerMarginPerSeriesList = [];

      for (const item of retailerMarginPerSeriesListArray) {
        // Check if the Set doesn't already have the item
        if (!uniqueSet.has(item)) {
          uniqueSet.add(item); // Add the item to the Set
          retailerMarginPerSeriesList.push(item); // Add the item to the result array
        }
      }
      productDetails.map((prod,index) =>{
        if(prod.updated_from == 'Edit Dealer Price') {
        let productkey=prod['rowselectkey'];
        let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
        rows = <tr>
        <th>
        <input
          type="checkbox"
          id="selectAllCheckbox"
          defaultChecked={isProductChecked}
          // checked= {prod['rowselectkey']}
          onChange={(event) => {
            console.log(event);
            setInitialRender(false);
            let bulkOrdersChecks = document.getElementsByClassName("bulkProductsCheck2");
            for (let i = 0; i < bulkOrdersChecks.length; i++) {
              // if (event.currentTarget.checked) {
              //   bulkOrdersChecks[i].checked = true;
                bulkOrdersChecks[i].checked = event.currentTarget.checked;
              // } else {
              //   bulkOrdersChecks[i].checked = false;
              // }
            }
            updateCheckedProducts(event.currentTarget.checked, 'Edit Dealer Price');
          }} />
            </th>
              <th>ItemID</th>
              <th>Item Description</th>
              <th>Base Price</th>
              <th>GST %</th>
              {retailerMargin >0 && <th>Retailer Margin %</th>}
              
              {retailerMarginPerSeriesList.map((region) => {
                return (<th>{ `Retailer Margin Per_${region}` }</th>)
              })}
              <th>Dealer Price</th>
              {/* <th>Total Price</th> */}
              <th>Delete</th>
          </tr>
        }
        
      })
      
    } else {
      rows = [];
    }

  return (
    <thead className="thead-light">
      {rows}
    </thead>
    );
  }
  const ProductTableHeader1 = () => {

    // let isProductChecked=initialRender==true?true:checkRef.current.checked;
    let rows=[];

    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
        return val.updated_from == 'Edit Price'
      })
      productDetails.map((prod,index) =>{
        if(prod.updated_from == 'Edit Price') {
        let productkey=prod['rowselectkey'];
        let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
        rows = <tr>
        <th>
        <input
          type="checkbox"
          id="selectAllCheckbox"
          defaultChecked={isProductChecked}
          // checked= {prod['rowselectkey']}
          onChange={(event) => {
            console.log(event);
            setInitialRender(false);
            let bulkOrdersChecks = document.getElementsByClassName("bulkProductsCheck1");
            for (let i = 0; i < bulkOrdersChecks.length; i++) {
              // if (event.currentTarget.checked) {
              //   bulkOrdersChecks[i].checked = true;
                bulkOrdersChecks[i].checked = event.currentTarget.checked;
              // } else {
              //   bulkOrdersChecks[i].checked = false;
              // }
            }
            updateCheckedProducts(event.currentTarget.checked,'Edit Price');
          }} />
            </th>
              <th>ItemID</th>
              <th>Item Description</th>
              {/* <th>Old Base Price</th> */}
              <th>Base Price</th>
              <th>GST %</th>
              <th>Source Type</th>
              <th>Transport</th>
              <th>Mill Transport</th>
              <th>Cash Discount</th>
              <th>Extra Margin Charges</th>
              {/* {ALL_REGIONS_LIST.forEach((region) =>{
                if(prod[`extra_margin_per_${region}`]) {
                  return (<th>{ `Extra Margin Charges_${region}` }</th>)
                 
                }
              })} */}
              <th>Dealer Price</th>
              <th>Total Price</th>
              <th>Delete</th>
          </tr>
        }
        
      })
      
    } else {
      rows = [];
    }

  return (
    <thead className="thead-light">
      {rows}
    </thead>
    );
  }

  const ProductTableHeader3 = () => {
    let rows=[];
    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
          return val.updated_from == 'Edit Product Details'
      });
      productDetails.map((prod,index) =>{
        if(prod.updated_from == 'Edit Product Details') { 
          let productkey=prod['rowselectkey'];
          let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
          rows = <tr>
           <th>
            <input
              type="checkbox"
              id="selectAllCheckbox"
              defaultChecked={isProductChecked}
              onChange={(event) => {
                console.log(event);
                setInitialRender(false);
                let bulkOrdersChecks = document.getElementsByClassName("bulkProductsCheck3");
                for (let i = 0; i < bulkOrdersChecks.length; i++) {
                  // if (event.currentTarget.checked) {
                    bulkOrdersChecks[i].checked = event.currentTarget.checked;
                  // } else {
                  //   bulkOrdersChecks[i].checked = false;
                  // }
                }
                updateCheckedProducts(event.currentTarget.checked,'Edit Product Details');
              }} />
                </th>
                  <th>ItemID</th>
                  <th>Item Description</th>
                  <th>Parent ItemID</th>
                  <th>Parent Item Description</th>
                   {/* <th>Old Base Price</th>
                  <th>Base Price</th>
                  <th>Margin %</th>
                  <th>GST %</th>
                  <th>Source Type</th>
                  <th>Transport</th>
                  <th>Mill Transport</th>
                  <th>Cash Discount</th>
                  <th>Dealer Price</th>
                  <th>Total Price</th>  */}
                  <th>Gross Cost</th>
                  <th>Landing Cost</th>
                  <th>Delete</th>
        </tr>
        }
      })
      
        //}
      //})
    }else {
      rows = [];
    }
    return (
      <thead className="thead-light">
        {rows}
      </thead>
      );
  }
  const calSellingPriceForSeries = (retailer_margin, mrp) => {
    let sellPriceVal=parseToNo(mrp)/(1+getAbsFromPer(retailer_margin));
    return sellPriceVal;
  }

  const calDealerPriceForSeries = (sellPrice,gstPercent) => {
    let sellPriceExGst = parseToNo(sellPrice) / (1 + getAbsFromPer(gstPercent))
    return getRoundNo(sellPriceExGst);
  }

  const calSellingPrice= (val,mrp)=>{
    let sellPriceVal=parseToNo(mrp)/(1+getAbsFromPer(getRetailerMarginPerc));
      if(val){
        sellPriceVal = parseToNo(mrp)/(1+getAbsFromPer(val))
      }
      //setSellingPriceWoRounding(sellPriceVal)
      //setSellingPrice(Math.round(sellPriceVal))
      return sellPriceVal;
  }

  const calSellingPriceExGst = (sellingPriceWoRounding,gstPercent) => {
    let sellPriceExGst = parseToNo(sellingPriceWoRounding) / (1 + getAbsFromPer(gstPercent))
    //setSellingPriceExGst(sellPriceExGst.toFixed(2));
    //setDealerPrice(sellPriceExGst.toFixed(2))
    return sellPriceExGst.toFixed(2);
  }

  const handleChange = (rowNum, itemType, name, value) => {
    let itemId = productDetails[rowNum]["article_code"];

    if (itemType == "parent") {
      let updatedProductDataList = [];
      let storedGrossCost;
      for (let i = 0; i < productDetails.length; i++) {
        let childId = productDetails[i]["article_code"];
        let innerItemType = productDetails[i].type;
        let parent_id = productDetails[i]["parent_id"] ? productDetails[i]["parent_id"]: "";
        if (itemId == parent_id) {
          let show_mat = priceDataObj[childId].material_cost > 0 ? true: false;
          let show_was = priceDataObj[childId].wastage > 0 ? true : false;
          let updatedProdPriceObj = dealerPriceCalculations(
            priceDataObj[childId],
            productDataObj[childId],
            show_mat,
            show_was,
            productDetails[i]['purchase_price'],name,value
          );

          let ele = productDetails[i];
          ele[name] = value ; 
          if (name == "newCD" || name  == 'newExtraMargin') {
            ele["newDealerPrice"] = updatedProdPriceObj['dealer_price'];
            ele["newTotalPrice"] = updatedProdPriceObj['total_price'];
          }
          else if(name == 'newSource' || name =='newtransportcharge' || name == 'newTransport')
          {
            ele[name] = value;
            ele["newDealerPrice"] = updatedProdPriceObj['dealer_price'];
            ele["newTotalPrice"] = updatedProdPriceObj['total_price'];
          } 
          // else if(name == "retailer_margin_per") {
          //   ele[name] = value;
          //   ele["retailer_margin_per"] = updatedProdPriceObj['retailer_margin_per'];
          // }
          else {
            if (ele['newTransport']?.toUpperCase() == "EXCLUDED") {
              ele["newDealerPrice"] = updatedProdPriceObj['dealer_price'];
              ele["newTotalPrice"] = updatedProdPriceObj['total_price'];
            }
          }

          if (!storedGrossCost) {
            storedGrossCost = updatedProdPriceObj['gross_cost'];
          }
          ele['gross_cost'] = storedGrossCost;
          ele['landing_cost'] = updatedProdPriceObj['landing_cost'];

          if(innerItemType == 'child') {
            let innerStoredGrossCost = storedGrossCost;
            if((productDataObj[childId].weight).includes('gm')) {
              let weightVal = productDataObj[childId].weight.split(' ')[0];
              innerStoredGrossCost = innerStoredGrossCost * (Number(weightVal) /1000 );
            }
            if(priceDataObj[childId].mrp && priceDataObj[childId].mrp != 0 && (productDataObj[childId].weight).includes('kg')) {
              let weightVal = productDataObj[childId].weight.split(' ')[0];
              innerStoredGrossCost = innerStoredGrossCost * weightVal
            }
            const wastage = getAbsFromPer(priceDataObj[childId].wastage_per) * parseToNo(innerStoredGrossCost);
            let conversion_charges = getAbsFromPer(priceDataObj[childId].conversion_per) * parseToNo(priceDataObj[childId].base_price);
            conversion_charges = getRoundNo(conversion_charges);
            let secondaryPMCost = priceDataObj[childId]?.package_material_cost ? priceDataObj[childId]?.package_material_cost : priceDataObj[childId]?.material_cost_factor ? parseToNo(priceDataObj[childId].material_cost_factor) / parseToNo(priceDataObj[childId].lot_size) :0 ;
            let landingCost = (Number(innerStoredGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + secondaryPMCost;//(Number(priceDataObj[childId]?.package_material_cost ?? 0));
            landingCost = parseFloat(landingCost.toFixed(2));
            ele['gross_cost'] = innerStoredGrossCost;
            ele["landing_cost"] = landingCost;
          }
          updatedProductDataList.push(ele);
        }
        else {
          updatedProductDataList.push(productDetails[i]);
        }
      }
      setProductDetails(updatedProductDataList);
      console.log(name);
      if(name != 'newSource' || name != 'newtransportcharge')
      {
    // setTimeout(() => {
    //   let bulkitemqty = document.getElementsByName(name);
    //   bulkitemqty[rowNum].focus();
    // }, 0);
      }
  
    } 
  };

  const updateProductBasedOnRetailerMargin = (rowNum,itemType,seriesName,updatedRetailerMargin) => { 
    let itemId=productDetails[rowNum]['article_code'];

    if(itemType=="parent"){
      let updatedProductDataList=[];
      for(let i=0;i<productDetails.length;i++){
        let childId=productDetails[i]['article_code'];
        let parent_id=productDetails[i]['parent_id']?productDetails[i]['parent_id']:"";
        if(itemId==parent_id){
          //let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[childId],productDataObj[childId],false,false,updatedBasePrice);
          let mrp = productDetails[i].mrp? productDetails[i].mrp:'';
          let gstPercent = productDetails[i]['productdata']['gst_per'] ? productDetails[i]['productdata']['gst_per'] :0;
          let calculatedSellingPrice = calSellingPrice(updatedRetailerMargin,mrp);
          let calculatedSellingPriceExGst = calSellingPriceExGst(calculatedSellingPrice,gstPercent)
          //console.log(updatedProdPriceObj);

        //update product state
        let ele = productDetails[i];
        ele["newDealerPrice"] = calculatedSellingPriceExGst;
        ele["selling_price"] = calculatedSellingPrice.toFixed(2);
        if(ele["retailer_margin_per"] != '' && (ele["retailer_margin_per"] != undefined)) {
          ele["retailer_margin_per"] = updatedRetailerMargin;  
        }else {
          ele[seriesName] = updatedRetailerMargin;
        }
        
        updatedProductDataList.push(ele);
      } else {
        updatedProductDataList.push(productDetails[i]);
      }
    }
    setProductDetails(updatedProductDataList);
    // setTimeout(() => {
    //     let bulkitemqty=document.getElementsByClassName("itemQty");
    //   bulkitemqty[rowNum].focus();
    //   }, 0)
    }else if(itemType=="child" && productDetails[rowNum].category != 'GIFT'){

    //   let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[itemId],productDataObj[itemId],false,false,updatedRetailerMargin);
    // console.log(updatedProdPriceObj);
    let updatedProductDataList = [...productDetails];
    let mrp = updatedProductDataList[rowNum].mrp? updatedProductDataList[rowNum].mrp:'';
    let gstPercent = updatedProductDataList[rowNum]['productdata']['gst_per'] ? updatedProductDataList[rowNum]['productdata']['gst_per'] :0;
    let calculatedSellingPrice = calSellingPrice(updatedRetailerMargin,mrp);
    let calculatedSellingPriceExGst = calSellingPriceExGst(calculatedSellingPrice,gstPercent)
    updatedProductDataList[rowNum]['newDealerPrice'] = calculatedSellingPriceExGst;
    updatedProductDataList[rowNum]["selling_price"] = calculatedSellingPrice.toFixed(2);
    updatedProductDataList[rowNum]["retailer_margin_per"] = updatedRetailerMargin;
      
    setProductDetails(updatedProductDataList);
    // setTimeout(() => {
    //     let bulkitemqty=document.getElementsByClassName("itemQty");
    //   bulkitemqty[rowNum].focus();
    // }, 0);
  }
    
  }
    const updateProductBasedOnBasePrice = (rowNum,itemType,updatedBasePrice) => {
    console.log(updatedBasePrice);
      let itemId=productDetails[rowNum]['article_code'];
    console.log(itemId);

      if(itemType=="parent"){
        let updatedProductDataList=[];
        let storedGrossCost;
        for(let i=0;i<productDetails.length;i++){
          let innerItemType = productDetails[i].type;
          let childId=productDetails[i]['article_code'];
          let parent_id=productDetails[i]['parent_id']?productDetails[i]['parent_id']:"";
          if(itemId==parent_id){
            let show_mat = priceDataObj[childId].material_cost > 0 ? true: false;
            let show_was = priceDataObj[childId].wastage > 0 ? true : false;
            let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[childId],productDataObj[childId],show_mat,show_was,updatedBasePrice);
            console.log(updatedProdPriceObj);

          //update product state
          let ele = productDetails[i];
          ele["purchase_price"] = updatedBasePrice;
          if(innerItemType == 'parent' || (innerItemType == 'child' && productDetails[i]['productdata']['market_price'] == '')) {
            ele["newDealerPrice"] = updatedProdPriceObj["dealer_price"];            
            ele["newTotalPrice"] = updatedProdPriceObj["total_price"];
          }
          ele["newgstcharge"] = updatedProdPriceObj["gst_charge"];
          ele["newapmccharge"] = updatedProdPriceObj["apmc_charge"];
          if (!storedGrossCost) {
            storedGrossCost = updatedProdPriceObj['gross_cost'];
          }
          ele['gross_cost'] = storedGrossCost;
          ele['landing_cost'] = updatedProdPriceObj['landing_cost'];

          if(innerItemType == 'child') {
            let innerStoredGrossCost = storedGrossCost;
            if((productDataObj[childId].weight).includes('gm')) {
              let weightVal = productDataObj[childId].weight.split(' ')[0];
              innerStoredGrossCost = innerStoredGrossCost * (Number(weightVal) /1000 );
            }
            if(priceDataObj[childId].mrp && priceDataObj[childId].mrp != 0 && (productDataObj[childId].weight).includes('kg')) {
              let weightVal = productDataObj[childId].weight.split(' ')[0];
              innerStoredGrossCost = innerStoredGrossCost * weightVal
            }
            const wastage = getAbsFromPer(priceDataObj[childId].wastage_per) * parseToNo(innerStoredGrossCost);
            let conversion_charges = getAbsFromPer(priceDataObj[childId].conversion_per) * parseToNo(priceDataObj[childId].base_price);
            conversion_charges = getRoundNo(conversion_charges);
            let secondaryPMCost = priceDataObj[childId]?.package_material_cost ? priceDataObj[childId]?.package_material_cost : priceDataObj[childId]?.material_cost_factor ? parseToNo(priceDataObj[childId].material_cost_factor) / parseToNo(priceDataObj[childId].lot_size) :0 ;
            let landingCost = (Number(innerStoredGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + secondaryPMCost;//(Number(priceDataObj[childId]?.package_material_cost ?? 0));
            landingCost = parseFloat(landingCost.toFixed(2));
            ele['gross_cost'] = innerStoredGrossCost;
            ele["landing_cost"] = landingCost;
          }

          updatedProductDataList.push(ele);
        } else {
          updatedProductDataList.push(productDetails[i]);
        }
      }
      setProductDetails(updatedProductDataList);
      // setTimeout(() => {
      //     let bulkitemqty=document.getElementsByClassName("itemQty1");
      //   bulkitemqty[rowNum].focus();
      //   }, 0)
      }else if(itemType=="child" && productDetails[rowNum].category != 'GIFT'){
        let show_mat = priceDataObj[itemId].material_cost > 0 ? true: false;
        let show_was = priceDataObj[itemId].wastage > 0 ? true : false;
        let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[itemId],productDataObj[itemId],show_mat,show_was,updatedBasePrice);
      console.log(updatedProdPriceObj);

      //update product state
      let updatedProductDataList = [...productDetails];
        updatedProductDataList[rowNum]['purchase_price'] = updatedBasePrice;
        if(productDataObj[itemId].market_price == '') {
          updatedProductDataList[rowNum]['newDealerPrice'] = updatedProdPriceObj['dealer_price'];
        }        
        updatedProductDataList[rowNum]['newgstcharge'] = updatedProdPriceObj['gst_charge'];
        updatedProductDataList[rowNum]['newapmccharge'] = updatedProdPriceObj['apmc_charge'];
      setProductDetails(updatedProductDataList);
      // setTimeout(() => {
      //     let bulkitemqty=document.getElementsByClassName("itemQty1");
      //   bulkitemqty[rowNum].focus();
      // }, 0);
    }
  };

  const ProductTableBody = () => {
  let rows=[];
    
  if(productDetails.length>0){
    let updatedProductDetails = productDetails.filter((val)=>{
        return val.updated_from == 'Price Update'
    });
    rows = productDetails.map((prod, index) => {
        if(prod.updated_from == 'Price Update') {
        let productkey=prod['rowselectkey'];
        let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
      return (
        <tr
          key={index}
          style={{
            backgroundColor: prod.type == "child" ? "#808080" : "#FFCCCC",
          }}
        >
          <td>
            <input
              type="checkbox"
              className="bulkProductsCheck"
              defaultChecked={isProductChecked}
              onChange={(e) => {
                console.log(e.target.checked);
                setInitialRender(false);
                setProductChecked({
                  ...productChecked,
                  [prod.rowselectkey]: e.target.checked,
                });
              }}
            />
          </td>
          <td>{prod["article_code"]}</td>
          <td>{prod["item_name"]}</td>
          <td>{prod.type == 'parent' && prod["base_price"] ? prod["base_price"] : prod['old_base_price'] ? prod['old_base_price'] :''}</td>
          <td>
            <Form.Control
              className="itemQty1"
              name={prod.rowselectkey}
              type="number"
              style={{ width: "80%", margin: "auto", textAlign: "center" }}
              value={prod.purchase_price}
              onChange={(e) => {
                updateProductBasedOnBasePrice(
                  index,
                  prod["type"],
                  e.target.value
                );
              }}
            />
          </td>
          {prod.type == 'parent' ? <td>{prod['productdata'] && prod['productdata']['gst_per'] ? prod['productdata']['gst_per'] : ''}</td>
                    : <td>{prod['gst_per'] ? prod['gst_per'] :''}</td>}
          {prod.type == 'parent' ? (<td>
            <DropdownButton
              id="dropdown-basic-button"
              title={prod["newSource"] ? prod["newSource"] : ''}
              name="newSource"
              required
            >
              <Dropdown.Item
                // onClick={(e) => setSource(e.target.name)}
                onClick={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    "newSource",
                    e.target.name
                  );
                }}
                name={"MILL"}
              >
                MILL
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={(e) => setSource(e.target.name)}
                onClick={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    "newSource",
                    e.target.name
                  );
                }}
                name={"LOCAL PICK"}
              >
                LOCAL PICK
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={(e) => setSource(e.target.name)}
                onClick={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    "newSource",
                    e.target.name
                  );
                }}
                name={"DC DELIVERED"}
              >
                DC DELIVERED
              </Dropdown.Item>
            </DropdownButton>
            </td>):(<td><DropdownButton
              id="dropdown-basic-button"
              title={prod["newSource"] ? prod["newSource"] : ''}
              name="newSource"
              required
            >
              <Dropdown.Item
                // onClick={(e) => setSource(e.target.name)}
                
                name={"MILL"}
              >
                MILL
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={(e) => setSource(e.target.name)}
                
                name={"LOCAL PICK"}
              >
                LOCAL PICK
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={(e) => setSource(e.target.name)}
                
                name={"DC DELIVERED"}
              >
                DC DELIVERED
              </Dropdown.Item>
            </DropdownButton></td> )}
            {prod.type == 'parent' ? (<td>
            <DropdownButton
              id="dropdown-basic-button"
              name="newTransport"
              title={
                prod?.newTransport ? prod["newTransport"].toUpperCase() : ""
              }
              disabled={prod["newSource"] != "MILL"}
            >
              <Dropdown.Item
                // onClick={(e) => setTransport(e.target.name)}
                onClick={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    "newTransport",
                    e.target.name
                  );
                }}
                name="INCLUDED"
              >
                INCLUDED
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={(e) => setTransport(e.target.name)}
                onClick={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    "newTransport",
                    e.target.name
                  );
                }}
                name="EXCLUDED"
              >
                EXCLUDED
              </Dropdown.Item>
            </DropdownButton>
          </td>):<td>
          <DropdownButton
              id="dropdown-basic-button"
              name="newTransport"
              title={
                prod?.newTransport ? prod["newTransport"].toUpperCase() : ""
              }
              disabled={prod["newSource"] != "MILL"}              
            >
              <Dropdown.Item
                // onClick={(e) => setTransport(e.target.name)}
                
                name="INCLUDED"
              >
                INCLUDED
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={(e) => setTransport(e.target.name)}
                
                name="EXCLUDED"
              >
                EXCLUDED
              </Dropdown.Item>
            </DropdownButton></td>}
          {prod.type == 'parent' ? (<td>
            <Form.Control
              defaultValue={prod["newtransportcharge"]}
              name="newtransportcharge"
              type="number"
              onChange={(e) => {
                handleChange(
                  index,
                  prod["type"],
                  e.target.name,
                  e.target.value
                );
              }}
              disabled={
                prod?.newTransport
                  ? prod["newSource"].toUpperCase() != "MILL"  || (prod["newSource"].toUpperCase() == "MILL" && prod["newTransport"].toUpperCase() != "EXCLUDED")
                  : true
              }
              
              style={{ width: "65%", margin: "auto", textAlign: "center" }}
              onWheel={(event) => {
                event.preventDefault();
              }}
            />
          </td>):<td>
          <Form.Control
              defaultValue={prod["newtransportcharge"]}
              name="newtransportcharge"
              type="number"

              disabled={
                prod?.newTransport
                  ? prod["newSource"].toUpperCase() != "MILL"  || (prod["newSource"].toUpperCase() == "MILL" && prod["newTransport"].toUpperCase() != "EXCLUDED")
                  : true
              }
              
              style={{ width: "65%", margin: "auto", textAlign: "center" }}
              onWheel={(event) => {
                event.preventDefault();
              }}
            /></td>}
          {prod.type == 'parent' ? (<td>
            <Form.Control
              defaultValue={prod["newCD"]}
              name="newCD"
              type="number"
              style={{ width: "65%", margin: "auto", textAlign: "center" }}
              disabled={prod["newSource"] != "MILL"}
              onWheel={(event) => {
                event.preventDefault();
              }}
              onChange={(e) => {
                handleChange(
                  index,
                  prod["type"],
                  e.target.name,
                  e.target.value
                );
              }}
            />
          </td>):<td><Form.Control
              defaultValue={prod["newCD"]}
              name="newCD"
              type="number"
              style={{ width: "65%", margin: "auto", textAlign: "center" }}
              disabled={prod["newSource"] != "MILL"}              
              onWheel={(event) => {
                event.preventDefault();
              }}
            /></td>}
          {/* {prod.type == 'parent' ? (<td>
            <Form.Control
              defaultValue={prod["newExtraMargin"] ? prod["newExtraMargin"].split('%')[0]: ''}
              type="number"
              name="newExtraMargin"
              disabled= {true}
              style={{ width: "65%", margin: "auto", textAlign: "center" }}
              onWheel={(event) => {
                event.preventDefault();
              }}
              // onChange={(e) => {
              //   handleChange(
              //     index,
              //     prod["type"],
              //     e.target.name,
              //     e.target.value
              //   );
              // }}
            />
          </td>):<td></td>} */}
          {/* {prod.type == 'parent' ? <td>{prod["newDealerPrice"]}</td> :<td></td>} */}
          {<td>{prod["newDealerPrice"]}</td>}
          {/* {prod.type == 'parent' ? <td>{prod["newTotalPrice"]}</td> :<td></td>} */}
          {<td>{prod["newTotalPrice"]}</td>}
          {<td>{prod["gross_cost"]}</td>}
          {<td>{prod["landing_cost"]}</td>}
          {prod.type == 'parent' ? <td>
            {prod.type == "parent" ? (
              <Button
                onClick={() => {
                  deleteProductFromPendingApprovalList(prod);
                }}
              >
                Delete
              </Button>
            ) : null}
          </td>:<td></td>}
        </tr>
      );
     }
        
    });
  } else {
    rows = [];
  }

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
  };

  const ProductTableBody2 = () =>{
    let rows=[];
    
    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
        return val.updated_from == 'Edit Dealer Price'
      });

    let retailerMargin = 0;
    productDetails.map((val) =>{
      if(val['retailer_margin_per']) {
        retailerMargin =1;
      }
    })
    let retailerMarginPerSeriesListArray = [];
    productDetails.map((prod) =>{
      {ALL_REGIONS_LIST.map((region) =>{
        if(prod[`retailer_margin_per_${region}`]) {
          retailerMarginPerSeriesListArray.push(region);
        }
      })}
    })
    const uniqueSet = new Set();

    // Create an array to store the unique values in order
    const retailerMarginPerSeriesList = [];

    for (const item of retailerMarginPerSeriesListArray) {
      // Check if the Set doesn't already have the item
      if (!uniqueSet.has(item)) {
        uniqueSet.add(item); // Add the item to the Set
        retailerMarginPerSeriesList.push(item); // Add the item to the result array
      }
    }

    rows = productDetails.map((prod, index) => {
        if(prod.updated_from == 'Edit Dealer Price') {
          let productkey=prod['rowselectkey'];
          let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
          return (
            <tr
              key={index}
              style={{
                backgroundColor: prod.type == "child" ? "#808080" : "#FFCCCC",
              }}
            >
              <td>
                <input
                  type="checkbox"
                  className="bulkProductsCheck2"
                  defaultChecked={isProductChecked}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setInitialRender(false);
                    setProductChecked({
                      ...productChecked,
                      [prod.rowselectkey]: e.target.checked,
                    });
                  }}
                />
              </td>
              <td>{prod["article_code"]}</td>
              <td>{prod["item_name"]}</td>
              <td>
                <Form.Control
                  className="itemQty"
                  name={prod.rowselectkey}
                  type="number"
                  style={{ width: "80%", margin: "auto", textAlign: "center" }}
                  value={Number(prod.purchase_price).toFixed(2)}
                  disabled= {true}
                />
              </td>
              {prod.type == 'parent' ? <td>{prod['productdata']['gst_per'] ? prod['productdata']['gst_per'] : ''}</td>: <td></td>}
              {/* {prod.type == 'parent' ? <td>{prod["newDealerPrice"]}</td> :<td></td>} */}
              {retailerMargin >0 && prod['retailer_margin_per'] ? <td>
                <Form.Control
                  className="itemQty"
                  name="retailer_margin_per"
                  type="number"
                  style={{ width: "80%", margin: "auto", textAlign: "center" }}
                  value={prod[`retailer_margin_per`]}
                  onChange={(e)=>{ updateProductBasedOnRetailerMargin(
                    index,
                    prod["type"],
                    e.target.name,
                    e.target.value
                    ) 
                  }}
                />
              </td>: retailerMargin >0 && <td><Form.Control
                  className="itemQty"
                  name="retailer_margin_per"
                  type="number"
                  style={{ width: "80%", margin: "auto", textAlign: "center" }}
                  value={prod[`retailer_margin_per`]}
                  disabled= {true}
                />
              </td>}

              {retailerMarginPerSeriesList.map((region) => {
                if(prod[`retailer_margin_per_${region}`] && (prod['retailer_margin_per'] != undefined)) {
                  return (<td>
                    <Form.Control
                      className="itemQty"
                      name={`retailer_margin_per_${region}`}
                      type="number"
                      style={{ width: "80%", margin: "auto", textAlign: "center" }}
                      value={prod[`retailer_margin_per_${region}`]}
                      onChange={(e) => {
                          handleChange(
                            index,
                            prod["type"],
                            e.target.name,
                            e.target.value
                          );
                        }}
                   />
                </td>)
                }else if(prod[`retailer_margin_per_${region}`] && (prod['retailer_margin_per'] == undefined)) {
                  return (<td>
                    <Form.Control
                      className="itemQty"
                      name={`retailer_margin_per_${region}`}
                      type="number"
                      style={{ width: "80%", margin: "auto", textAlign: "center" }}
                      value={prod[`retailer_margin_per_${region}`]}
                      onChange={(e)=>{ updateProductBasedOnRetailerMargin(
                        index,
                        prod["type"],
                        e.target.name,
                        e.target.value
                        ) 
                      }}
                   />
                </td>)
                }else {
                  return (<td>
                    <Form.Control
                      className="itemQty"
                      name={`retailer_margin_per_${region}`}
                      type="number"
                      style={{ width: "80%", margin: "auto", textAlign: "center" }}
                      value= ""
                      disabled= {true}
                   />
                </td>)
                }
              })}
              {<td>{prod.newDealerPrice} </td>}
              {/* {prod.type == 'parent' ? <td>{prod["newTotalPrice"]}</td> :<td></td>} */}
              {prod.type == 'parent' ? <td>
                {prod.type == "parent" ? (
                  <Button
                    onClick={() => {
                      deleteProductFromPendingApprovalList(prod);
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </td>:<td></td>}
            </tr>
          );
        }
        
    });
  } else {
    rows = [];
  }

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
  }

  const ProductTableBody1 = () => {
    let rows=[];
    
    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
        return val.updated_from == 'Edit Price'
      });
    rows = productDetails.map((prod, index) => {
        if(prod.updated_from == 'Edit Price') {
          let productkey=prod['rowselectkey'];
          let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
          return (
            <tr
              key={index}
              style={{
                backgroundColor: prod.type == "child" ? "#808080" : "#FFCCCC",
              }}
            >
              <td>
                <input
                  type="checkbox"
                  className="bulkProductsCheck1"
                  defaultChecked={isProductChecked}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setInitialRender(false);
                    setProductChecked({
                      ...productChecked,
                      [prod.rowselectkey]: e.target.checked,
                    });
                  }}
                />
              </td>
              <td>{prod["article_code"]}</td>
              <td>{prod["item_name"]}</td>
              <td>
                <Form.Control
                  className="itemQty"
                  name={prod.rowselectkey}
                  type="number"
                  style={{ width: "80%", margin: "auto", textAlign: "center" }}
                  value={prod.purchase_price}
                  disabled= {true}
                  onChange={(e) => {
                    updateProductBasedOnBasePrice(
                      index,
                      prod["type"],
                      e.target.value
                    );
                  }}
                />
              </td>
              {prod.type == 'parent' ? <td>{prod['productdata']['gst_per'] ? prod['productdata']['gst_per'] : ''}</td>: <td></td>}
              {prod.type == 'parent' ? (<td>
                <DropdownButton
                  disabled= {true}
                  id="dropdown-basic-button"
                  title={prod["newSource"] ? prod["newSource"].toUpperCase() : ''}
                  name="newSource"
                  required
                >
                  <Dropdown.Item
                    // onClick={(e) => setSource(e.target.name)}
                    onClick={(e) => {
                      handleChange(
                        index,
                        prod["type"],
                        "newSource",
                        e.target.name
                      );
                    }}
                    name={"MILL"}
                  >
                    MILL
                  </Dropdown.Item>
                  <Dropdown.Item
                    // onClick={(e) => setSource(e.target.name)}
                    onClick={(e) => {
                      handleChange(
                        index,
                        prod["type"],
                        "newSource",
                        e.target.name
                      );
                    }}
                    name={"LOCAL PICK"}
                  >
                    LOCAL PICK
                  </Dropdown.Item>
                  <Dropdown.Item
                    // onClick={(e) => setSource(e.target.name)}
                    onClick={(e) => {
                      handleChange(
                        index,
                        prod["type"],
                        "newSource",
                        e.target.name
                      );
                    }}
                    name={"DC DELIVERED"}
                  >
                    DC DELIVERED
                  </Dropdown.Item>
                </DropdownButton>
                </td>):(<td></td> )}
                {prod.type == 'parent' ? (<td>
                <DropdownButton
                  disabled= {true}
                  id="dropdown-basic-button"
                  name="newTransport"
                  title={
                    prod?.newTransport ? prod["newTransport"].toUpperCase() : ""
                  }
                  // disabled={ prod?.newSource && prod["newSource"].toUpperCase() != "MILL"}
                >
                  <Dropdown.Item
                    // onClick={(e) => setTransport(e.target.name)}
                    onClick={(e) => {
                      handleChange(
                        index,
                        prod["type"],
                        "newTransport",
                        e.target.name
                      );
                    }}
                    name="INCLUDED"
                  >
                    INCLUDED
                  </Dropdown.Item>
                  <Dropdown.Item
                    // onClick={(e) => setTransport(e.target.name)}
                    onClick={(e) => {
                      handleChange(
                        index,
                        prod["type"],
                        "newTransport",
                        e.target.name
                      );
                    }}
                    name="EXCLUDED"
                  >
                    EXCLUDED
                  </Dropdown.Item>
                </DropdownButton>
              </td>):<td></td>}
              {prod.type == 'parent' ? (<td>
                <Form.Control
                  disabled= {true}
                  defaultValue={prod["newtransportcharge"]}
                  name="newtransportcharge"
                  type="number"
                  onChange={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      e.target.name,
                      e.target.value
                    );
                  }}
                  // disabled={
                  //   prod?.newTransport
                  //     ? prod["newSource"].toUpperCase() != "MILL"  || (prod["newSource"].toUpperCase() == "MILL" && prod["newTransport"].toUpperCase() != "EXCLUDED")
                  //     : true
                  // }
                  
                  style={{ width: "65%", margin: "auto", textAlign: "center" }}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                />
              </td>):<td></td>}
              {prod.type == 'parent' ? (<td>
                <Form.Control
                  disabled= {true}
                  defaultValue={prod["newCD"]}
                  name="newCD"
                  type="number"
                  style={{ width: "65%", margin: "auto", textAlign: "center" }}
                  // disabled={ prod?.newSource && prod["newSource"].toUpperCase() != "MILL"}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onChange={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      e.target.name,
                      e.target.value
                    );
                  }}
                />
              </td>):<td></td>}
              {prod.type == 'parent' ? (<td>
                <span>A</span>
                <Form.Control
                  defaultValue={prod["newExtraMargin"] ? prod["newExtraMargin"].split('%')[0]: ''}
                  type="number"
                  name="newExtraMargin"
                  style={{ width: "65%", margin: "auto", textAlign: "center" }}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onChange={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      e.target.name,
                      e.target.value
                    );
                  }}
                />
                
                {ALL_REGIONS_LIST.map((region) =>{
                if(prod[`extra_margin_per_${region}`]) {
                  //return //(<th>{ `Extra Margin Charges_${region}` }</th>)
                  return (
                      <span>
                      <label>{region}</label>
                     <Form.Control
                      defaultValue={prod[`extra_margin_per_${region}`] ? prod[`extra_margin_per_${region}`]: ''}
                      type="number"
                      name= {`extra_margin_per_${region}`}
                      style={{ width: "65%", margin: "auto", textAlign: "center" }}
                      onWheel={(event) => {
                        event.preventDefault();
                      }}
                      onChange={(e) => {
                        handleChange(
                          index,
                          prod["type"],
                          e.target.name,
                          e.target.value
                        );
                      }}
                     />
                     </span>
                    )
                 
                }
               })
               }
              </td>):<td></td>}
              


              {/* {prod.type == 'parent' ? <td>{prod["newDealerPrice"]}</td> :<td></td>} */}
              {<td>{prod["newDealerPrice"]}</td>}
              {prod.type == 'parent' ? <td>{prod["newTotalPrice"]}</td> :<td></td>}
              {prod.type == 'parent' ? <td>
                {prod.type == "parent" ? (
                  <Button
                    onClick={() => {
                      deleteProductFromPendingApprovalList(prod);
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </td>:<td></td>}
            </tr>
          );
        }
        
    });
  } else {
    rows = [];
  }

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
  };

  const ProductTableBody3 = () => {
    let rows=[];
      
    if(productDetails.length>0){
      let updatedProductDetails = productDetails.filter((val)=>{
          return val.updated_from == 'Edit Product Details'
      });
      rows = productDetails.map((prod, index) => {
          if(prod.updated_from == 'Edit Product Details') {
          let productkey=prod['rowselectkey'];
          let isProductChecked=initialRender==true?true:(productChecked[productkey]!==undefined?productChecked[productkey]:true);
        return (
          <tr
            key={index}
            style={{
              backgroundColor: prod.type == "child" ? "#808080" : "#FFCCCC",
            }}
          >
            <td>
              <input
                type="checkbox"
                className="bulkProductsCheck3"
                defaultChecked={isProductChecked}
                onChange={(e) => {
                  console.log(e.target.checked);
                  setInitialRender(false);
                  setProductChecked({
                    ...productChecked,
                    [prod.rowselectkey]: e.target.checked,
                  });
                }}
              />
            </td>
            <td>{prod["article_code"]}</td>
            <td>{prod["item_name"]}</td>
            <td>{prod["parent_id"]}</td>
            <td>{productDataObj[prod["parent_id"]].brand}-{productDataObj[prod["parent_id"]].long_description}</td>
            {/* <td>{prod["parent_item_name"]}</td> */}
            {/* <td>{prod.type == 'parent' && prod["base_price"] ? prod["base_price"] : prod['old_base_price'] ? prod['old_base_price'] :''}</td>
            {prod.type == 'parent' ? <td>
              <Form.Control
                className="itemQty1"
                name={prod.rowselectkey}
                type="number"
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
                value={prod.purchase_price}
                onChange={(e) => {
                  updateProductBasedOnBasePrice(
                    index,
                    prod["type"],
                    e.target.value
                  );
                }}
              />
            </td> : <td>
              <Form.Control
                className="itemQty1"
                name={prod.rowselectkey}
                type="number"
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
                value={prod.purchase_price}
                disabled = {true}
              />
            </td>}
            
            {prod.type == 'parent' ? 
            <td>
            <Form.Control
                defaultValue={prod["newExtraMargin"] ? prod["newExtraMargin"].split('%')[0]: ''}
                type="number"
                name="newExtraMargin"
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onChange={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    e.target.name,
                    e.target.value
                  );
                }}
              /> </td> :  <td>
                <Form.Control
                defaultValue={prod["newExtraMargin"] ? prod["newExtraMargin"].split('%')[0]: ''}
                type="number"
                name="newExtraMargin"
                disabled = {true}
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                onWheel={(event) => {
                  event.preventDefault();
                }}
                />
              </td>
              }
            {prod.type == 'parent' ? <td>{prod['productdata'] && prod['productdata']['gst_per'] ? prod['productdata']['gst_per'] : ''}</td>
                      : <td>{prod['gst_per'] ? prod['gst_per'] :''}</td>}
            {prod.type == 'parent' ? (<td>
              <DropdownButton
                id="dropdown-basic-button"
                title={prod["newSource"] ? prod["newSource"] : ''}
                name="newSource"
                required
              >
                <Dropdown.Item
                  // onClick={(e) => setSource(e.target.name)}
                  onClick={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      "newSource",
                      e.target.name
                    );
                  }}
                  name={"MILL"}
                >
                  MILL
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={(e) => setSource(e.target.name)}
                  onClick={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      "newSource",
                      e.target.name
                    );
                  }}
                  name={"LOCAL PICK"}
                >
                  LOCAL PICK
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={(e) => setSource(e.target.name)}
                  onClick={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      "newSource",
                      e.target.name
                    );
                  }}
                  name={"DC DELIVERED"}
                >
                  DC DELIVERED
                </Dropdown.Item>
              </DropdownButton>
              </td>):(<td><DropdownButton
                id="dropdown-basic-button"
                title={prod["newSource"] ? prod["newSource"] : ''}
                name="newSource"
                disabled={true}
                required
              >
                <Dropdown.Item
                  // onClick={(e) => setSource(e.target.name)}
                  
                  name={"MILL"}
                >
                  MILL
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={(e) => setSource(e.target.name)}
                  
                  name={"LOCAL PICK"}
                >
                  LOCAL PICK
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={(e) => setSource(e.target.name)}
                  
                  name={"DC DELIVERED"}
                >
                  DC DELIVERED
                </Dropdown.Item>
              </DropdownButton></td> )}
              {prod.type == 'parent' ? (<td>
              <DropdownButton
                id="dropdown-basic-button"
                name="newTransport"
                title={
                  prod?.newTransport ? prod["newTransport"].toUpperCase() : ""
                }
                disabled={prod["newSource"] != "MILL"}
              >
                <Dropdown.Item
                  // onClick={(e) => setTransport(e.target.name)}
                  onClick={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      "newTransport",
                      e.target.name
                    );
                  }}
                  name="INCLUDED"
                >
                  INCLUDED
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={(e) => setTransport(e.target.name)}
                  onClick={(e) => {
                    handleChange(
                      index,
                      prod["type"],
                      "newTransport",
                      e.target.name
                    );
                  }}
                  name="EXCLUDED"
                >
                  EXCLUDED
                </Dropdown.Item>
              </DropdownButton>
            </td>):<td>
            <DropdownButton
                id="dropdown-basic-button"
                name="newTransport"
                title={
                  prod?.newTransport ? prod["newTransport"].toUpperCase() : ""
                }
                // disabled={prod["newSource"] != "MILL"}
                disabled={true}
              >
                <Dropdown.Item
                  // onClick={(e) => setTransport(e.target.name)}
                  
                  name="INCLUDED"
                >
                  INCLUDED
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={(e) => setTransport(e.target.name)}
                  
                  name="EXCLUDED"
                >
                  EXCLUDED
                </Dropdown.Item>
              </DropdownButton></td>}
            {prod.type == 'parent' ? (<td>
              <Form.Control
                defaultValue={prod["newtransportcharge"]}
                name="newtransportcharge"
                type="number"
                onChange={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    e.target.name,
                    e.target.value
                  );
                }}
                disabled={
                  prod?.newTransport
                    ? prod["newSource"].toUpperCase() != "MILL"  || (prod["newSource"].toUpperCase() == "MILL" && prod["newTransport"].toUpperCase() != "EXCLUDED")
                    : true
                }
                
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                onWheel={(event) => {
                  event.preventDefault();
                }}
              />
            </td>):<td>
            <Form.Control
                defaultValue={prod["newtransportcharge"]}
                name="newtransportcharge"
                type="number"
  
                // disabled={
                //   prod?.newTransport
                //     ? prod["newSource"].toUpperCase() != "MILL"  || (prod["newSource"].toUpperCase() == "MILL" && prod["newTransport"].toUpperCase() != "EXCLUDED")
                //     : true
                // }
                disabled={true}
                
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                onWheel={(event) => {
                  event.preventDefault();
                }}
              /></td>}
            {prod.type == 'parent' ? (<td>
              <Form.Control
                defaultValue={prod["newCD"]}
                name="newCD"
                type="number"
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                disabled={prod["newSource"] != "MILL"}
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onChange={(e) => {
                  handleChange(
                    index,
                    prod["type"],
                    e.target.name,
                    e.target.value
                  );
                }}
              />
            </td>):<td><Form.Control
                defaultValue={prod["newCD"]}
                name="newCD"
                type="number"
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                // disabled={prod["newSource"] != "MILL"}
                disabled={true}
                onWheel={(event) => {
                  event.preventDefault();
                }}
              /></td>} */}
            {/* {prod.type == 'parent' ? (<td>
              <Form.Control
                defaultValue={prod["newExtraMargin"] ? prod["newExtraMargin"].split('%')[0]: ''}
                type="number"
                name="newExtraMargin"
                disabled= {true}
                style={{ width: "65%", margin: "auto", textAlign: "center" }}
                onWheel={(event) => {
                  event.preventDefault();
                }}
                // onChange={(e) => {
                //   handleChange(
                //     index,
                //     prod["type"],
                //     e.target.name,
                //     e.target.value
                //   );
                // }}
              />
            </td>):<td></td>} */}
            {/* {prod.type == 'parent' ? <td>{prod["newDealerPrice"]}</td> :<td></td>} */}
            {/* {<td>{prod["newDealerPrice"]}</td>} */}
            {/* {prod.type == 'parent' ? <td>{prod["newTotalPrice"]}</td> :<td></td>} */}
            {/* {<td>{prod["newTotalPrice"]}</td>} */}
            {<td>{prod["gross_cost"]}</td>}
            {<td>{prod["landing_cost"]}</td>}
            {prod.type == 'parent' ? <td>
              {prod.type == "parent" ? (
                <Button
                  onClick={() => {
                    deleteProductFromPendingApprovalList(prod);
                  }}
                >
                  Delete
                </Button>
              ) : null}
            </td>:<td></td>}
          </tr>
        );
       }
          
      });
    } else {
      rows = [];
    }
  
    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const deleteProductFromPendingApprovalList = (prod) => {
    let itemId = prod.rowselectkey;
    let parentID = prod.parent_id;
    let tableUpdateDate = prod.table_update_date;

    let parentChildDeletionObj = {};
    for (let i = 0; i < productDetails.length; i++) {
      if (productDetails[i].parent_id == parentID) {
        let table_update_date = productDetails[i].table_update_date;
        let tableYear = table_update_date.split("-")[2];
        let tableMonth = table_update_date.split("-")[1];
        let prodID = productDetails[i].rowselectkey;
        parentChildDeletionObj[
          `/${tableYear}/${tableMonth}/${table_update_date}/${prodID}`
        ] = null;
      }
    }

    database
      .database()
      .ref(`Pending_Price_Update`)
      .update(parentChildDeletionObj)
      .then(() => {
        console.log("success");
        setProductDetails(
          productDetails.filter((item) => item.parent_id !== prod.parent_id)
        );
        toast("Successfully deleted product price.", {
          type: toast.TYPE.SUCCESS,
        });
        props.onHide();
        props.callback(itemId, tableUpdateDate);
      })
      .catch((error) => {
        console.log(error);
        toast("Error in deleting product price details : " + error, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const dealerPriceValidation = async(item_id,dealer_price,prod) =>{
    if(prod.updated_from == 'Edit Product Details'){
      return true;
    } else {
      const url = `${OFFER_BASE_URL}/product-offer?item_id=${item_id}&dealer_price=${dealer_price}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
      const resp = await axios.get(url);
      const data = await resp.data;
      let isValid = data.data;
      return isValid
    }
  //   axios.get(url)
  //  .then(function (response) {
  //    let isValid =  response.data.data;
  //    return isValid;
  //   //  if(isValid) {
  //   //         setDataToDb(true);
  //   //         setDataPushToPriceDetails(true)
  //   //  }else {
  //   //      return toast("Dealer Price should be greater than offer price.", { type: toast.TYPE.ERROR });
  //   //  }
  //   }).catch(function (error) {
  //    return false;
  //  });
}

  const bulkUpdateProductDealerPrice = async(updatedFr,index) => {
    let d = new Date(),
    // currdformat = [d.getDate().padLeft(),
    //           (d.getMonth()+1).padLeft(),
    //            d.getFullYear()].join('-') +' ' +
    //           [d.getHours().padLeft(),
    //            d.getMinutes().padLeft()].join(':');

    currdformat = [
      d.getDate().padLeft(),
      (d.getMonth() + 1).padLeft(),
      d.getFullYear(),
      ].join("-") + 
          " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");


      let productmasterele={};
      let pricedetailsele={};
      // let updatePriceDetails = {};
      let updatePriceDetailsArray = [];
      let approvedproductsele={};
      let p1 = [];
      let bulkProductsCheck;
      if(updatedFr == 'Edit Dealer Price') {
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck2");  
      }else if(updatedFr == 'Edit Price'){
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck1");
      } else if(updatedFr == 'Price Update'){
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck");
      } else if(updatedFr == 'Edit Product Details') {
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck3");
      }
      // let bulkProductsCheck1 = document.getElementsByClassName("bulkProductsCheck1");
      // bulkProductsCheck = {...bulkProductsCheck,...bulkProductsCheck1};
      let errorCount = 0;
      let itemIdHavingError = [];
      let storedGrossCost;
      for(let z=0;z<bulkProductsCheck.length;z++){
        if(bulkProductsCheck[z].checked){
          let productDetailsNew = productDetails.filter((val)=>{
              if(val.updated_from == updatedFr){
                return val;
              }
          })
          let prod=productDetailsNew[z];
          //&& (val.type != 'child' && val['productdata']['category'] != 'GIFT')
          if(prod['productdata']['category'] != 'GIFT') {
            p1.push(Promise.all([
              dealerPriceValidation(prod.article_code, prod.newDealerPrice,prod)
            ]).then(async ([isValid])=>{
              if(isValid) {
                let updatePriceDetails = {};
                console.log(prod.article_code);
                console.log(prod.type);
                let productid=prod.article_code;
                let show_mat = priceDataObj[productid].material_cost > 0 ? true: false;
                let show_was = priceDataObj[productid].wastage > 0 ? true : false;
                let updatedProdPriceObj = dealerPriceCalculations(
                  priceDataObj[productid],
                  productDataObj[productid],
                  show_mat,
                  show_was,
                  );
                let calcObj= updatedProdPriceObj;//prod['calcObj'];
                let productdata=prod['productdata'];
                let productkey=prod.rowselectkey;
                let itemName = prod.item_name;
                let itemDealerPrice = prod.dealer_price;
                let table_update_date=prod.table_update_date;
                let tableYear=table_update_date.split("-")[2];
                let tableMonth=table_update_date.split("-")[1];
                productmasterele[`/${productid}/base_price`]=""+prod.purchase_price;
                productmasterele[`/${productid}/updated_by`]=sessionStorage.getItem("Logged User Email Id");
                productmasterele[`/${productid}/updated_at`]=currdformat;
                if(itemDealerPrice > 0 && productDataObj[productid].market_price == ''){
                  productmasterele[`/${productid}/dealer_price`]=""+prod.newDealerPrice;
                }
                if ((prod.type != 'child') ||  (productDataObj[prod.parent_id] && !storedGrossCost) ) {
                  storedGrossCost = calcObj['gross_cost'];
                }
                if(prod.type == 'parent' && itemDealerPrice >0) {
                  productmasterele[`/${productid}/gst_charge`]=calculateGST(calcObj.gst_abs_per,prod.newDealerPrice,productdata)
                  if(updatedFr == 'Price Update') {
                    productmasterele[`/${productid}/cd_per`] = "" + prod.newCD && prod.newCD !=undefined  ? prod.newCD :'';
                    productmasterele[`/${productid}/extra_margin_per`] = "" + prod.newExtraMargin;
                    productmasterele[`/${productid}/mill_transport`] = "" + prod.newTransport && prod.newTransport != undefined ? prod.newTransport :"";
                    productmasterele[`/${productid}/source_type`] = "" + prod.newSource && prod.newSource !=undefined ? prod.newSource :"" ;
                    productmasterele[`/${productid}/mill_transport_value`] = "" + prod.newtransportcharge && prod.newtransportcharge !=undefined ? prod.newtransportcharge:"";
                  }else if(updatedFr == 'Edit Price') {
                    productmasterele[`/${productid}/extra_margin_per`] = "" + prod.newExtraMargin;
                  }else if(updatedFr == 'Edit Dealer Price') {
                    productmasterele[`/${productid}/selling_price`] = "" + prod.selling_price;
                    productmasterele[`/${productid}/selling_price_ex_gst`] = "" + prod.selling_price_ex_gst;
                    productmasterele[`/${productid}/gross_cost`] = prod.gross_cost;
                    productmasterele[`/${productid}/primary_pm_cost`] = prod.primary_pm_cost ? prod.primary_pm_cost : priceDataObj[productid]['primary_pm_cost'] ? priceDataObj[productid]['primary_pm_cost'] :'' ;
                    productmasterele[`/${productid}/conversion_per`] = prod.conversion_per;
                    productmasterele[`/${productid}/wastage_per`] = prod.wastage_per;
                    productmasterele[`/${productid}/conversion`] = prod.conversion;
                    productmasterele[`/${productid}/wastage`] = prod.wastage;
                    productmasterele[`/${productid}/landing_cost`] = prod.landing_cost;
                    if(prod.retailer_margin_per!=undefined) {
                     productmasterele[`/${productid}/retailer_margin_per`] = "" + prod.retailer_margin_per;
                   } 
                  }
                }
      
                let transportDet = '';
                if(prod.newTransport == 'INCLUDED' || prod.newTransport == 'Included' || prod.newTransport == 'included'){
                  transportDet = 'included';
                }else if(prod.newTransport == 'EXCLUDED' || prod.newTransport == 'Excluded' || prod.newTransport == 'excluded'){
                  transportDet = 'excluded';
                }
                
                let sourceType = '';
                if(prod.newSource == 'MILL' || prod.newSource == 'Mill' || prod.newSource == 'mill'){
                  sourceType = 'mill';
                }else if (prod.newSource == 'DC Delivered' || prod.newSource == 'DC DELIVERED' || prod.newSource == 'dc_delivered'){
                  sourceType = 'dc_delivered';
                }else if (prod.newSource == 'LOCAL PICK' || prod.newSource == 'Local Pick' || prod.newSource == 'local_pick'){
                  sourceType = 'local_pick';
                }
                updatePriceDetails['item_id'] = productid;
                pricedetailsele[`/${productid}/base_price`]=""+prod.purchase_price;
                updatePriceDetails['base_price']  = ""+prod.purchase_price;
                pricedetailsele[`/${productid}/selling_price`]=""+prod.selling_price;
                updatePriceDetails['selling_price']  = ""+prod.selling_price;
                pricedetailsele[`/${productid}/selling_price_ex_gst`]=""+prod.selling_price_ex_gst;
                updatePriceDetails['selling_price_ex_gst']  = ""+prod.selling_price_ex_gst;
                pricedetailsele[`/${productid}/updated_by`]=sessionStorage.getItem("Logged User Email Id");
                updatePriceDetails['updated_by']  = sessionStorage.getItem("Logged User Email Id");;
                pricedetailsele[`/${productid}/updated_at`]=currdformat;
                updatePriceDetails['updated_at']  = currdformat;;
                if(itemDealerPrice >0) {
                // pricedetailsele[`/${productid}/dealer_price`] = prod.newDealerPrice;
                //   {ALL_REGIONS_LIST.forEach((region) =>{
                //     if(region == 'A') {
                //       pricedetailsele[`/${productid}/dealer_price_${region}`] = prod.newDealerPrice; //calcObj[`dealer_price_${region}`];
                //     }else if(calcObj[`dealer_price_${region}`]) {
                //       pricedetailsele[`/${productid}/dealer_price_${region}`] = calcObj[`dealer_price_${region}`];
                //     }
                //   })}
                }
                // if(prod.type == 'parent') {
                  if((updatedFr == 'Price Update' && prod.type != 'child' && itemDealerPrice >0) || (updatedFr == 'Price Update' && prod.type == 'child') || updatedFr == 'Edit Product Details' ) { 
                    pricedetailsele[`/${productid}/cd_per`] = "" + prod.newCD && prod.newCD != undefined  ? prod.newCD :'';
                    updatePriceDetails['cd_per']  = "" + prod.newCD && prod.newCD != undefined  ? prod.newCD :'';
                    if(updatedFr == 'Price Update') {
                      pricedetailsele[`/${productid}/extra_margin_per`] = "" + prod.newExtraMargin;
                      updatePriceDetails['extra_margin_per']  = "" + prod.newExtraMargin;
                      {ALL_REGIONS_LIST.forEach((region) =>{
                        if(prod[`extra_margin_per_${region}`]) {
                          pricedetailsele[`/${productid}/extra_margin_per_${region}`] = prod[`extra_margin_per_${region}`];
                          updatePriceDetails[`extra_margin_per_${region}`]  = prod[`extra_margin_per_${region}`];
                        }
                      })}
                      pricedetailsele[`/${productid}/total_price`] = prod.newTotalPrice && prod.newTotalPrice != undefined ?prod.newTotalPrice :"";
                      updatePriceDetails['total_price']  = prod.newTotalPrice && prod.newTotalPrice != undefined ?prod.newTotalPrice :"";
                    }
                    pricedetailsele[`/${productid}/mill_transport`] = "" + transportDet;
                    updatePriceDetails['mill_transport']  = "" + transportDet;
                    pricedetailsele[`/${productid}/source_type`] = "" + sourceType;
                    updatePriceDetails['source_type']  = "" + sourceType;
                    pricedetailsele[`/${productid}/mill_transport_value`] = "" + prod.newtransportcharge && prod.newtransportcharge != undefined ? prod.newtransportcharge :"";
                    updatePriceDetails['mill_transport_value']  = "" + prod.newtransportcharge && prod.newtransportcharge != undefined ? prod.newtransportcharge :"";
                    if(productDataObj[productid].market_price == '' ) {
                      pricedetailsele[`/${productid}/total_price`] = prod.newTotalPrice && prod.newTotalPrice != undefined ?prod.newTotalPrice :"";
                      updatePriceDetails['total_price']  = prod.newTotalPrice && prod.newTotalPrice != undefined ?prod.newTotalPrice :"";
                      pricedetailsele[`/${productid}/dealer_price`] = prod.newDealerPrice && prod.newDealerPrice != undefined ? prod.newDealerPrice : "";
                      updatePriceDetails['dealer_price'] = prod.newDealerPrice && prod.newDealerPrice !=undefined ? prod.newDealerPrice : "";
                    }
                    
                    pricedetailsele[`/${productid}/buying_price_ex_gst`] = calcObj.buying_price_ex_gst ? calcObj.buying_price_ex_gst : prod['calcObj'].buying_price_ex_gst;
                    updatePriceDetails['buying_price_ex_gst']  = calcObj.buying_price_ex_gst ? calcObj.buying_price_ex_gst : prod['calcObj'].buying_price_ex_gst;
                    pricedetailsele[`/${productid}/landing_cost`] = calcObj.landing_cost ? calcObj.landing_cost : prod['calcObj'].landing_cost;
                    updatePriceDetails['landing_cost']  = calcObj.landing_cost ? calcObj.landing_cost : prod['calcObj'].landing_cost;
                    pricedetailsele[`/${productid}/gross_cost`] = storedGrossCost; //calcObj.gross_cost ? calcObj.gross_cost : prod['calcObj'].gross_cost;
                    updatePriceDetails['gross_cost']  = storedGrossCost; //calcObj.gross_cost ? calcObj.gross_cost : prod['calcObj'].gross_cost;

                    if (prod.type == 'child' && productDataObj[prod.parent_id]) {
                      let innerStoredGrossCost = storedGrossCost;
                      if ((productDataObj[productid].weight).includes('gm')) {
                        let weightVal = productDataObj[productid].weight.split(' ')[0];
                        innerStoredGrossCost = innerStoredGrossCost * (Number(weightVal) / 1000);
                      }
                      // if ((productDataObj[productid].weight).includes('kg')) {
                      //   let weightVal = productDataObj[productid].weight.split(' ')[0];
                      //   innerStoredGrossCost = innerStoredGrossCost * weightVal;
                      // }
                      const wastage = getAbsFromPer(priceDataObj[productid].wastage_per) * parseToNo(innerStoredGrossCost);
                      let conversion_charges = getAbsFromPer(priceDataObj[productid].conversion_per) * parseToNo(priceDataObj[productid].base_price);
                      conversion_charges = getRoundNo(conversion_charges);
                      let secondaryPMCost = priceDataObj[productid]?.package_material_cost ? priceDataObj[productid].package_material_cost : priceDataObj[productid]?.material_cost_factor ? parseToNo(priceDataObj[productid].material_cost_factor) / parseToNo(priceDataObj[productid].lot_size) :0 ;
                      let landingCost = (Number(innerStoredGrossCost ?? 0)) + (Number(wastage ?? 0)) + (Number(conversion_charges ?? 0)) + secondaryPMCost;//(Number(priceDataObj[productid]?.package_material_cost ?? 0));
                      landingCost = parseFloat(landingCost.toFixed(2));
                      pricedetailsele[`/${productid}/gross_cost`] = innerStoredGrossCost;
                      updatePriceDetails['gross_cost'] = innerStoredGrossCost;
                      pricedetailsele[`/${productid}/landing_cost`] = landingCost;
                      updatePriceDetails['landing_cost'] = landingCost;
                    }

                    if(updatedFr == 'Edit Product Details') {
                      const priceDetails = await database
                        .database()
                        .ref(`Price_Details/mumbai/items/${productDataObj[productid].parent_id}`)
                        .once("value");
                      let calVal = priceDetails.val();
                      const childGrossCost = await calculateGrossCost(productDataObj[productid].parent_id, productDataObj[productid]);
                      // prodele["gross_cost"] = childGrossCost;
                      // const wastage = getAbsFromPer(priceDataObj[productid].wastage_per) * parseToNo(childGrossCost);
                      const wastage = getAbsFromPer(priceDataObj[productid].wastage_per) * parseToNo(childGrossCost);
                      // let conversion_charges = getAbsFromPer(priceDataObj[productid].conversion_per) * parseToNo(priceDataObj[productid].base_price);
                      let conversion_charges = getAbsFromPer(priceDataObj[productid].conversion_per) * parseToNo(calVal.base_price);
                      conversion_charges = getRoundNo(conversion_charges);
                      // let landingCost = (Number(childGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + (Number(priceDataObj[productid]?.package_material_cost ?? 0));
                      let secondaryPMCost = priceDataObj[productid]?.package_material_cost ? priceDataObj[productid].package_material_cost : priceDataObj[productid]?.material_cost_factor ? parseToNo(priceDataObj[productid].material_cost_factor) / parseToNo(priceDataObj[productid].lot_size) :0 ;
                      let landingCost = (Number(childGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + secondaryPMCost;//(Number(calVal?.package_material_cost ?? 0));
                      landingCost = parseFloat(landingCost.toFixed(2));
                      pricedetailsele[`/${productid}/gross_cost`] = childGrossCost;
                      updatePriceDetails['gross_cost'] = childGrossCost;
                      pricedetailsele[`/${productid}/landing_cost`] = landingCost;
                      updatePriceDetails['landing_cost'] = landingCost;

                      pricedetailsele[`/${productid}/base_price`] = calVal.base_price;
                      updatePriceDetails['base_price'] = calVal.base_price;

                      pricedetailsele[`/${productid}/source_type`] = calVal.source_type;
                      updatePriceDetails['source_type'] = calVal.source_type;

                      if(calVal.source_type.toLowerCase() == 'mill'){
                        pricedetailsele[`/${productid}/mill_transport_value`] = calVal.mill_transport_value;
                        updatePriceDetails['mill_transport_value'] = calVal.mill_transport_value;
                        pricedetailsele[`/${productid}/mill_transport`] = calVal.mill_transport;
                        updatePriceDetails['mill_transport'] = calVal.mill_transport;
                        pricedetailsele[`/${productid}/cd_per`] = calVal.cd_per;
                        updatePriceDetails['cd_per'] = calVal.cd_per;
                      }
                        productmasterele[`/${productid}/base_price`] = calVal.base_price;
                        productmasterele[`/${productid}/selling_price`] = "" + prod.selling_price;
                        productmasterele[`/${productid}/selling_price_ex_gst`] = "" + prod.selling_price_ex_gst;
                        productmasterele[`/${productid}/primary_pm_cost`] = prod.primary_pm_cost ? prod.primary_pm_cost : priceDataObj[productid]['primary_pm_cost'] ? priceDataObj[productid]['primary_pm_cost'] :'' ;
                        productmasterele[`/${productid}/conversion_per`] = prod.conversion_per;
                        productmasterele[`/${productid}/wastage_per`] = prod.wastage_per;
                        productmasterele[`/${productid}/conversion`] = conversion_charges;
                        productmasterele[`/${productid}/wastage`] = wastage;
                        productmasterele[`/${productid}/gross_cost`]=childGrossCost;
                        productmasterele[`/${productid}/landing_cost`]=landingCost;
                    }

                  }else if(updatedFr == 'Edit Price') {
                    pricedetailsele[`/${productid}/extra_margin_per`] = "" + prod.newExtraMargin;
                    updatePriceDetails['extra_margin_per'] = "" + prod.newExtraMargin;
                    {ALL_REGIONS_LIST.forEach((region) =>{
                      if(prod[`extra_margin_per_${region}`]) {
                        pricedetailsele[`/${productid}/extra_margin_per_${region}`] = prod[`extra_margin_per_${region}`];
                        updatePriceDetails[`extra_margin_per_${region}`] = prod[`extra_margin_per_${region}`];
                      }
                    })}
                    pricedetailsele[`/${productid}/total_price`] = prod.newTotalPrice && prod.newTotalPrice != undefined ?prod.newTotalPrice :"";
                    updatePriceDetails[`total_price`] = prod.newTotalPrice && prod.newTotalPrice != undefined ?prod.newTotalPrice :"";
                  }else if(updatedFr == 'Edit Dealer Price' && itemDealerPrice >0) {
                    pricedetailsele[`/${productid}/gross_cost`] = prod.gross_cost;
                    pricedetailsele[`/${productid}/primary_pm_cost`] = prod.primary_pm_cost ? prod.primary_pm_cost : priceDataObj[productid]['primary_pm_cost'] ? priceDataObj[productid]['primary_pm_cost'] :'' ;
                    pricedetailsele[`/${productid}/conversion_per`] = prod.conversion_per;
                    pricedetailsele[`/${productid}/conversion`] = prod.conversion;
                    pricedetailsele[`/${productid}/wastage_per`] = prod.wastage_per;
                    pricedetailsele[`/${productid}/wastage`] = prod.wastage;
                    pricedetailsele[`/${productid}/landing_cost`] = prod.landing_cost;

                    updatePriceDetails['gross_cost'] = prod.gross_cost;
                    updatePriceDetails['primary_pm_cost'] = prod.primary_pm_cost ? prod.primary_pm_cost : priceDataObj[productid]['primary_pm_cost'] ? priceDataObj[productid]['primary_pm_cost'] :'' ;
                    updatePriceDetails['conversion_per'] = prod.conversion_per;
                    updatePriceDetails['conversion'] = prod.conversion;
                    updatePriceDetails['wastage_per'] = prod.wastage_per;
                    updatePriceDetails['wastage'] = prod.wastage;
                    updatePriceDetails['landing_cost'] = prod.landing_cost;
                    
                    if(prod.retailer_margin_per !=undefined)
                      pricedetailsele[`/${productid}/retailer_margin_per`] = "" + prod.retailer_margin_per;
                      updatePriceDetails[`retailer_margin_per`] = "" + prod.retailer_margin_per;
                    {ALL_REGIONS_LIST.forEach((region) =>{
                      if(prod[`retailer_margin_per_${region}`]) {
                        pricedetailsele[`/${productid}/retailer_margin_per_${region}`] = prod[`retailer_margin_per_${region}`];
                        updatePriceDetails[`retailer_margin_per_${region}`] = prod[`retailer_margin_per_${region}`];
                      }
                    })}
                  }
                  
                  pricedetailsele[`/${productid}/last_updated`] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //new Date();
                  updatePriceDetails[`last_updated`] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                //}
      
                approvedproductsele[`/${tableYear}/${tableMonth}/${table_update_date}/${productkey}/approved`]="Y";
                approvedproductsele[`/${tableYear}/${tableMonth}/${table_update_date}/${productkey}/approved_by`]=sessionStorage.getItem("Logged User Email Id");
                approvedproductsele[`/${tableYear}/${tableMonth}/${table_update_date}/${productkey}/approved_at`]=currdformat;
                if(updatedFr !== 'Edit Dealer Price' && itemDealerPrice >0 && productDataObj[productid].market_price == '' ){
                  approvedproductsele[`/${tableYear}/${tableMonth}/${table_update_date}/${productkey}/total_price`]=prod.newTotalPrice && prod.newTotalPrice !=undefined ? prod.newTotalPrice :"";
                }else if(updatedFr == 'Edit Dealer Price' && itemDealerPrice >0) {
                  if(prod.retailer_margin_per !=undefined) {
                   approvedproductsele[`/${tableYear}/${tableMonth}/${table_update_date}/${productkey}/retailer_margin_per`]=prod.retailer_margin_per;
                  }
                }
                updatePriceDetailsArray.push(updatePriceDetails);
              }else {
                //toast("Dealer Price should be greater than offer price.", { type: toast.TYPE.ERROR });
                errorCount += 1;
                itemIdHavingError.push(prod.article_code);
                // setTimeout(() => {
                //   props.onHide();
                // }, 3000);
              }
              
            }))
          }
          
        }
      }

      let val = await Promise.all(p1);
      console.log(productmasterele);
      if(errorCount >0) {
        itemIdHavingError = itemIdHavingError.join(',')
        toast(`Dealer Price should be greater than offer price for Item Id ${itemIdHavingError}`, { type: toast.TYPE.ERROR });
      }else if (Object.keys(productmasterele).length > 0) {
        try {
          // Update PriceDetails
          let priceDetsRef = database.database().ref(`Price_Details/mumbai/items`);
          await priceDetsRef.update(pricedetailsele);
          let updateCount = 0;
          const updatePromises = updatePriceDetailsArray.map(async (val) => {
            let url = `${ORDERING_BASE_URL}/api/superzop/admin/price_details/insertpricedetails`;
            let dataToSubmit = val;
            let respData = await axios.post(url, dataToSubmit);
            let resCode = respData.data?.success || 0;
            if (resCode == 1) {
              updateCount += 1;
            }
          });
    
          await Promise.all(updatePromises);
    
          if (updateCount == updatePriceDetailsArray.length) {
            setUpdateCounter(updateCounter => updateCounter + 1);
          } else {
            toast("Error in updating price details in mysql: ", { type: toast.TYPE.ERROR });
          }
        } catch (pricedetserror) {
          toast("Error in updating price details: " + pricedetserror, { type: toast.TYPE.ERROR });
        }
    
        // Approve pending products
        let todayDate = formatDate(new Date());
        let todayYear = todayDate.split('-')[2];
        let todayMonth = todayDate.split('-')[1];
        let approvedProdRef = database.database().ref(`Pending_Price_Update`);
        try {
          await approvedProdRef.update(approvedproductsele);
          setUpdateCounter(updateCounter => updateCounter + 1);
        } catch (approveproderror) {
          toast("Error in updating approved in Pending Price table: " + approveproderror, { type: toast.TYPE.ERROR });
        }
    
        // Update ProductMaster
        const regions = {
          "A": 3,
          "B": 3,
          "N": 3,
          "H": 3,
          "P": 3,
          "All": 7
        };
        
        if (regions[region]) {
          setFirebaseTotalUpdateCounter(regions[region]);
    
          if (region === "All") {
            ALL_REGIONS_LIST.forEach(region => {
              updateProdMasterTableDB(region, currdformat, updatedFr);
            });
          } else {
            let prodRef = database.database().ref(`Product_Master_New/${region}`);
            try {
              await prodRef.update(productmasterele);
              setUpdateCounter(updateCounter => updateCounter + 1);
            } catch (prodmastererror) {
              toast(`Error in updating product master region ${region}: ${prodmastererror}`, { type: toast.TYPE.ERROR });
            }
          }
        }
      }else{
      toast("Please select products for approval.", { type: toast.TYPE.ERROR });
    }
    }


    const calculateGrossCost = async (parentId, childData) => {
      const priceDetails = await database
        .database()
        .ref(`Price_Details/mumbai/items/${parentId}`)
        .once("value");
      let calVal = priceDetails.val();
      let gross_cost = calVal?.gross_cost ? calVal?.gross_cost : 0;
  
      const weight = childData.weight || props.editableData.weight;
  
      if (weight.includes("gm")) {
        let weightVal = weight.split(" ")[0];
        gross_cost = gross_cost * (Number(weightVal) / 1000);
      }
      // if (weight.includes("kg")) {
      //   let weightVal = weight.split(" ")[0];
      //   gross_cost = gross_cost * weightVal;
      // }
  
      return parseFloat(gross_cost).toFixed(2);
    };

    const updateProdMasterTableDB = async (regionName,currdformat,updatedFr) => {
      let productmasterele={};
      let bulkProductsCheck;
      if(updatedFr == 'Edit Dealer Price') {
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck2");  
      }else if(updatedFr == 'Edit Price'){
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck1");
      } else if(updatedFr == 'Price Update'){
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck");
      } else if(updatedFr == 'Edit Product Details') {
        bulkProductsCheck=document.getElementsByClassName("bulkProductsCheck3");
      }
      // let productid;
      for(let z=0;z<bulkProductsCheck.length;z++){
        if(bulkProductsCheck[z].checked){
          const updObj = {};

          let productDetailsNew = productDetails.filter((val)=>{
            if(val.updated_from == updatedFr){
              return val;
            }
          })
          let prod=productDetailsNew[z];
          try {
            if(prod['productdata']['category'] != 'GIFT') { 
            
              let productid=prod.article_code;
              let productkey=prod.rowselectkey;
              let itemDealerPrice = prod.dealer_price;
              let show_mat = priceDataObj[productid].material_cost > 0 ? true: false;
              let show_was = priceDataObj[productid].wastage > 0 ? true : false;
              let updatedProdPriceObj = dealerPriceCalculations(
                priceDataObj[productid],
                productDataObj[productid],
                show_mat,
                show_was,
                );
              // let prod=productDetails[z];
              
              let calcObj= updatedProdPriceObj; //prod['calcObj'];
              let productdata=prod['productdata'];
              // if(prod.type=="child"){
              //   productdata= prod['productdata'];  //productDataObj[productid];
              // } else {
                let dealer_price = '';
                let total_price;
                let total_bag_price; 
                let gst_charge = '';
                if(itemDealerPrice >0){
                  total_price = calcObj['total_price_wo_rounding_'+regionName]?calcObj['total_price_wo_rounding_'+regionName]:calcObj['total_price'];
                  total_bag_price = calcObj['total_bag_price_'+regionName]?calcObj['total_bag_price_'+regionName]:calcObj['total_bag_price'];
                }
                if(updatedFr !== 'Edit Dealer Price' && itemDealerPrice >0) {
                  dealer_price = calcObj['dealer_price_'+regionName]?calcObj['dealer_price_'+regionName]:calcObj['dealer_price'];
                  let extra_margin = prod['extra_margin_per_'+regionName]?prod['extra_margin_per_'+regionName]:prod['newExtraMargin'];
                  updObj['extra_margin_per']  = ""+extra_margin;
                  updObj['total_price'] = getRoundNo(total_price);
                  updObj['total_bag_price'] = ""+total_bag_price ? total_bag_price :0;
                  //updObj['gst_charge'] = calculateGST(calcObj.gst_abs_per,dealer_price,productdata);
                  gst_charge = calculateGST(calcObj.gst_abs_per,dealer_price,productdata);
                }else if(updatedFr == 'Edit Dealer Price' && itemDealerPrice >0){
                  let seriesMarginValue = prod[`retailer_margin_per_${regionName}`]? prod[`retailer_margin_per_${regionName}`]:prod[`retailer_margin_per`];
                  let mrp = prod.mrp? prod.mrp:'';
                  let gstPercentSeries = productdata['gst_per']?productdata['gst_per']:0;
                  let selling_price = calSellingPriceForSeries(seriesMarginValue,mrp);
                  let selling_price_ex_gst = calSellingPriceExGst(selling_price,gstPercentSeries)
                  
                  let new_dealer_price = calDealerPriceForSeries(selling_price,gstPercentSeries);
                  gst_charge = calculateGST(calcObj.gst_abs_per,new_dealer_price,productdata);
                  dealer_price = new_dealer_price; 
                  // updObj['selling_price'] = "" + selling_price;
                  // updObj['selling_price_ex_gst'] = "" + selling_price_ex_gst;
                  if(prod[`retailer_margin_per_${regionName}`] !=undefined || prod[`retailer_margin_per`] != undefined) {
                    updObj['retailer_margin_per'] = "" + seriesMarginValue; //"" + prod.retailer_margin_per;
                    updObj['selling_price'] = "" + selling_price.toFixed(2);
                    updObj['selling_price_ex_gst'] = "" + selling_price_ex_gst;
                  }

                  updObj[`gross_cost`] = prod.gross_cost;
                  updObj[`primary_pm_cost`] = prod.primary_pm_cost ? prod.primary_pm_cost : priceDataObj[productid]['primary_pm_cost'] ? priceDataObj[productid]['primary_pm_cost'] :'' ;
                  updObj[`conversion_per`] = prod.conversion_per;
                  updObj[`wastage_per`] = prod.wastage_per;
                  updObj[`conversion`] = prod.conversion;
                  updObj[`wastage`] = prod.wastage;
                  updObj[`landing_cost`] = prod.landing_cost;
                  // updObj['gst_charge']= up_gst_charge;
                }
                if(itemDealerPrice >0 && (updatedFr != 'Edit Dealer Price' || (updatedFr == 'Edit Dealer Price' && prod[`retailer_margin_per_${regionName}`] !=undefined || (prod[`retailer_margin_per`] != undefined)))) {
                  updObj['dealer_price'] = ""+ dealer_price;       
                  updObj['gst_charge']= gst_charge;     
                }
                
                if(updatedFr == 'Edit Product Details') {
                  const priceDetails = await database
                        .database()
                        .ref(`Price_Details/mumbai/items/${productDataObj[productid].parent_id}`)
                        .once("value");
                  let calVal = priceDetails.val();
                  const childGrossCost = await calculateGrossCost(productDataObj[productid].parent_id, productDataObj[productid]);
                  // prodele["gross_cost"] = childGrossCost;
                  // const wastage = getAbsFromPer(priceDataObj[productid].wastage_per) * parseToNo(childGrossCost);
                  // let conversion_charges = getAbsFromPer(priceDataObj[productid].conversion_per) * parseToNo(priceDataObj[productid].base_price);
                  const wastage = getAbsFromPer(priceDataObj[productid].wastage_per) * parseToNo(childGrossCost);
                  let conversion_charges = getAbsFromPer(priceDataObj[productid].conversion_per) * parseToNo(calVal.base_price);
                  conversion_charges = getRoundNo(conversion_charges);
                  let secondaryPMCost = priceDataObj[productid]?.package_material_cost ? priceDataObj[productid].package_material_cost : priceDataObj[productid]?.material_cost_factor ? parseToNo(priceDataObj[productid].material_cost_factor) / parseToNo(priceDataObj[productid].lot_size) :0 ;
                  let landingCost = (Number(childGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + secondaryPMCost; // (Number(calVal?.package_material_cost ?? 0));
                  landingCost = parseFloat(landingCost.toFixed(2));
                  
                  productmasterele[`/${productid}/base_price`] = calVal.base_price;
                  productmasterele[`/${productid}/selling_price`] = "" + prod.selling_price;
                  productmasterele[`/${productid}/selling_price_ex_gst`] = "" + prod.selling_price_ex_gst;
                  productmasterele[`/${productid}/primary_pm_cost`] = prod.primary_pm_cost ? prod.primary_pm_cost : priceDataObj[productid]['primary_pm_cost'] ? priceDataObj[productid]['primary_pm_cost'] :'' ;
                  productmasterele[`/${productid}/conversion_per`] = priceDataObj[productid].conversion_per;
                  productmasterele[`/${productid}/wastage_per`] = priceDataObj[productid].wastage_per;
                  productmasterele[`/${productid}/conversion`] = conversion_charges;
                  productmasterele[`/${productid}/wastage`] = wastage;
                  productmasterele[`/${productid}/gross_cost`]=childGrossCost;
                  productmasterele[`/${productid}/landing_cost`]=landingCost;
                }

                updObj['base_price']  = ""+prod.purchase_price;
                updObj['updated_by']  = sessionStorage.getItem("Logged User Email Id");
                updObj['updated_at']  = currdformat;
              productmasterele[productid] = updObj;
            }
          } catch (error) {
            toast("Error while updating data in product master new data : " + error, {
              type: toast.TYPE.ERROR,
            })
          }
          
          
        }
      }

      // if(Object.keys(productmasterele).length>0){
      //   Object.entries(productmasterele).forEach((key,value)=>{
      //     let prodARef=database.database().ref(`Product_Master_New/${regionName}/${key[0]}`);
      //     //let table_details = database.database().ref(`${PROD_MASTER_TABLE}/${region_name}/${prodData.item_id}`);
      //     prodARef.once('value').then(dataSnapshot => { 
      //       if(dataSnapshot.val()){
      //         prodARef.update(key[1], function (error) { 
      //           if (error) {
      //             // setDBStatus(dbStatus[`region_${region_name.toLowerCase()}`] = false);
      //             // errorMessage = "Couldn't able to update the region " + region_name;
      //             console.log("Could not able to update for the region::: " + regionName);
      //             setUpdateCounter(updateCounter => updateCounter + 1);
      //           } else {
      //               console.log("Updated successfully for the region::: " + regionName);
      //               setUpdateCounter(updateCounter => updateCounter + 1);
      //               // setDBStatus(dbStatus[`region_${region_name.toLowerCase()}`] = true);
      //           }
      //         })
      //           // setUpdateCounter(updateCounter => updateCounter + 1);
      //         // }).catch((prodmastererror) => {
      //         //   //toast(`Error in updating product master region ${regionName} : ${prodmastererror}`, { type: toast.TYPE.ERROR });
      //         //   console.log(`Error in updating product master region ${regionName} : ${prodmastererror}`, { type: toast.TYPE.ERROR });
      //         // });
      //       }          
      //     })
      //   })        
      // }

      if (Object.keys(productmasterele).length > 0) {
        const updatePromises = Object.entries(productmasterele).map(async ([productid, updateData]) => {
          let prodARef = database.database().ref(`Product_Master_New/${regionName}/${productid}`);
          // return prodARef.once('value')
          //   .then(dataSnapshot => {
          //     if (dataSnapshot.val()) {
          //       return prodARef.update(updateData).then(() => {
          //         console.log("Updated successfully for the region: " + regionName);
          //         setUpdateCounter(updateCounter => updateCounter + 1);
          //       }).catch((error) => {
          //         console.log("Could not update for the region: " + regionName, error);
          //         setUpdateCounter(updateCounter => updateCounter + 1);
          //       });
          //     }
          //   });

          try {
            let dataSnapshot = await prodARef.once('value');
            if (dataSnapshot.val()) {
              await prodARef.update(updateData);
              console.log("Updated successfully for the region: " + regionName);
              setUpdateCounter(updateCounter => updateCounter + 1);
            } else {
              setUpdateCounter(updateCounter => updateCounter + 1);
            }
          } catch (error) {
            console.log("Could not update for the region: " + regionName, error);
            setUpdateCounter(updateCounter => updateCounter + 1);
          }
        });
      

        await Promise.all(updatePromises).then(() => {
          console.log("All updates completed.");
        }).catch((error) => {
          console.log("Error during update process: ", error);
        });
      }
    }

  const calculateGST = (gst_abs_per,dealer_price,productdata)  => {
    let gstCharges =  gst_abs_per * (parseFloat(dealer_price));
    if(parseFloat(productdata.lot_size) > 1 && (!productdata.market_price || parseFloat(isValid(productdata.market_price)) < 1)) {
        gstCharges = gst_abs_per * (parseFloat(dealer_price)) / parseFloat(productdata.lot_size);
    }
    return gstCharges.toFixed(4);
  };


    const dealerPriceCalculations = (priceFromDB, prodData, showMaterial, showWastage,updatedBasePrice, name, value) => {

    let newProdDetails = productDetails.filter((val) => val.article_code == prodData['item_id']);
    if(newProdDetails[0].newSource == 'MILL' || newProdDetails[0].newSource == 'mill' || newProdDetails[0].newSource == 'Mill'){
      priceFromDB['source_type'] = 'mill';
    }else if(newProdDetails[0].newSource == 'DC Delivered' || newProdDetails[0].newSource == 'dc_delivered' || newProdDetails[0].newSource == 'DC DELIVERED'){
      priceFromDB['source_type'] = 'dc_delivered';
    } else if(newProdDetails[0].newSource == 'Local Pick' || newProdDetails[0].newSource == 'local_pick' || newProdDetails[0].newSource == 'LOCAL PICK'){
      priceFromDB['source_type'] = 'local_pick';
    }

    if(newProdDetails[0].newTransport =='INCLUDED'){
       priceFromDB['mill_transport'] = 'included';
    } else if(newProdDetails[0].newTransport =='EXCLUDED'){
      priceFromDB['mill_transport'] = 'excluded';
   }

    if(name == 'newSource' && value == 'MILL') {
      priceFromDB['source_type'] = 'mill';
    }
    if(name == 'newSource' && (value == 'DC Delivered' || value == 'DC DELIVERED')) {
      priceFromDB['source_type'] = 'dc_delivered';
    }
    if(name == 'newSource' && (value == 'Local Pick'|| value == 'LOCAL PICK')) {
      priceFromDB['source_type'] = 'local_pick';
    }

    if(name == 'newTransport' && value == 'EXCLUDED') {
      priceFromDB['mill_transport'] = 'excluded';
    } else if(name == 'newTransport' && value == 'INCLUDED') {
      priceFromDB['mill_transport'] = 'included';
    }

    if(updatedBasePrice) {
      priceFromDB['base_price'] = updatedBasePrice;
      prodData['base_price'] = updatedBasePrice;
    }
    if(name == 'newExtraMargin') {
      priceFromDB['extra_margin_per'] = `${value}%`;
    }
    
    if((newProdDetails[0].newCD)){
      priceFromDB['cd_per'] = newProdDetails[0].newCD;
    }

    if((newProdDetails[0].newtransportcharge)){
      priceFromDB['mill_transport_value'] = newProdDetails[0].newtransportcharge;
    }

    if(name == 'newCD'){
      priceFromDB['cd_per'] = value;
    }

    if(name =='newtransportcharge') {
      priceFromDB['mill_transport_value'] = value;
    }
    if(name =='retailer_margin_per') {
      priceFromDB['retailer_margin_per'] = value;
    }
    let resObj = PriceCalculations(priceFromDB, prodData, showMaterial, showWastage);
    return resObj;
  };

  const isValid = (data) => {
        if (data === undefined || data === null || data === "" || data.length === 0) {
      return 0;
    }
    return data;
  };

  const parseToNo = (string) => {
    return parseFloat(isValid(string));
  };

  const getAbsFromPer = (data) => {
    return parseFloat(parseToNo(data) / 100);
  };

  const getRoundNo = (data) => {
    return parseToNo(data).toFixed(2);
  };

  const getRoundNoThree = (data) => {
    return parseToNo(data).toFixed(3);
  };

    return (<>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Pending Approval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
                  <div>
                      {productDetails.filter((val)=>{
                          return val.updated_from == 'Edit Price'
                        }).length > 0 && <h4>Margin Update</h4>}
                      <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                      
                        <ProductTableHeader1 />
                        <ProductTableBody1 />
                      </table>
                      {productDetails.filter((val)=>{
                          return val.updated_from == 'Edit Price'
                        }).length > 0 && validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button variant={"success"} onClick={(e)=>{bulkUpdateProductDealerPrice('Edit Price')}}>Approve</Button> }
                  </div>
                  <div>
                      {productDetails.filter((val)=>{
                            return val.updated_from == 'Price Update'
                        }).length >0 &&  <h4>Base Price Update</h4>}
                      <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                      
                        <ProductTableHeader />
                        <ProductTableBody />
                      </table>
                      {productDetails.filter((val)=>{
                            return val.updated_from == 'Price Update'
                        }).length >0 &&  validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button variant={"success"} onClick={(e)=>{bulkUpdateProductDealerPrice('Price Update')}}>Approve</Button> }
                  </div>

                  <div>
                      {productDetails.filter((val)=>{
                            return val.updated_from == 'Edit Dealer Price'
                        }).length >0 &&  <h4>Retailer Price Update</h4>}
                      <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                      
                        <ProductTableHeader2 />
                        <ProductTableBody2 />
                      </table>
                      {productDetails.filter((val)=>{
                            return val.updated_from == 'Edit Dealer Price'
                        }).length >0 &&  validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button variant={"success"} onClick={(e,index)=>{bulkUpdateProductDealerPrice('Edit Dealer Price',index)}}>Approve</Button> }
                  </div>

                  <div>
                      {productDetails.filter((val)=>{
                            return val.updated_from == 'Edit Product Details'
                        }).length >0 &&  <h4>Parent Id Update</h4>}
                      <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                      
                        <ProductTableHeader3 />
                        <ProductTableBody3 />
                      </table>
                      {productDetails.filter((val)=>{
                            return val.updated_from == 'Edit Product Details'
                        }).length >0 &&  validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button variant={"success"} onClick={(e)=>{bulkUpdateProductDealerPrice('Edit Product Details')}}>Approve</Button> }
                  </div>
                    
                </div>
        </Modal.Body>
        <Modal.Footer>
        {/* { validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button variant={"success"} onClick={bulkUpdateProductDealerPrice}>Approve</Button> } */}
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
    )
}

export default BulkUpdateProductDealerPriceModal
