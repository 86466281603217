import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Form,
  Alert,
  Button,
  Modal,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  DropdownMenu,
} from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import database from "../../service/firebaseConfig";
import { TRANSPORT_FACTOR, REGION_BOM_A } from "../../constants/allConstants";
import "./style.css";
import PriceCalculations from "../../service/priceCalculations";
import { OFFER_BASE_URL} from "../../service/servicesConfig";
import axios from 'axios';

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

function UpdateProductNewDealerPriceModal({
  updateDetails,
  updateProduct,
  newBasePrice,
  region,
  productDataObj,
  priceDataObj,
  ...props
}) {
  console.log("UpdateProductNewDealerPriceModal");
  // console.log(newBasePrice);
  const [basePrice, setBasePrice] = useState(newBasePrice);
  const [parentChildProductsList, setParentChildProductsList] = useState([]);
  const [source, setSource] = useState();
  const [transport, setTransport] = useState();
  const [dealerPrice, setDealerPrice] = useState();

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setBasePrice(newBasePrice);
    if (updateProduct) {
      let origProdDetails = JSON.parse(JSON.stringify(updateDetails));
      let actualParentItem = origProdDetails.article_code;
      let parentGrossCost = origProdDetails.gross_cost;
      setDealerPrice(origProdDetails.newDealerPrice);

      let finalProductsList = [];
      origProdDetails.type = "parent";
      origProdDetails.parent_id = actualParentItem;
      origProdDetails["newSource"] = origProdDetails.source_type;
      origProdDetails["newTransport"] = origProdDetails.transport;
      origProdDetails["newtransportcharge"] = origProdDetails.transport_charges;
      origProdDetails["newCD"] = origProdDetails.cash_discount;
      origProdDetails["newExtraMargin"] = origProdDetails.extra_margin_per;

      setSource(origProdDetails.source_type);
      setTransport(origProdDetails.transport);

      priceDataObj[origProdDetails.article_code]['cd_per'] = origProdDetails && origProdDetails.cash_discount ?origProdDetails.cash_discount:priceDataObj[origProdDetails.article_code]['cd_per'];
      priceDataObj[origProdDetails.article_code]['mill_transport_value'] = origProdDetails && origProdDetails.transport_charges ?origProdDetails.transport_charges:priceDataObj[origProdDetails.article_code]['mill_transport_value'];
      priceDataObj[origProdDetails.article_code]['source_type'] = origProdDetails && origProdDetails.source_type ?origProdDetails.source_type:priceDataObj[origProdDetails.article_code]['source_type'];
      priceDataObj[origProdDetails.article_code]['mill_transport'] = origProdDetails && origProdDetails.transport ?origProdDetails.transport:priceDataObj[origProdDetails.article_code]['transport'];
      priceDataObj[origProdDetails.article_code]["apmc_loading"] = origProdDetails && origProdDetails.apmc_loading ? origProdDetails.apmc_loading :0; 
      let show_mat = priceDataObj[origProdDetails.article_code].material_cost > 0 ? true: false;
      let show_was = priceDataObj[origProdDetails.article_code].wastage > 0 ? true : false;
      let updatedProdPriceObj = dealerPriceCalculations(
        priceDataObj[origProdDetails.article_code],
        productDataObj[origProdDetails.article_code],
        show_mat,
        show_was,
        origProdDetails.purchase_price
      );
      origProdDetails["newTotalPrice"] = updatedProdPriceObj.total_price;      ;
      // setSource(origProdDetails.source_type);
      // setTransport(origProdDetails.transport);
      console.log(origProdDetails);
      finalProductsList.push(origProdDetails);

      Object.keys(productDataObj).map(async (key, value) => {
        let parent_id = productDataObj[key]["parent_id"] ? productDataObj[key]["parent_id"]: "";
        let parent_id1 = productDataObj[key]["parent_id1"] ? productDataObj[key]["parent_id1"]: "";
        let category = productDataObj[key].category;
        let sub_category = productDataObj[key].sub_category;
        let brand = productDataObj[key].brand;
        let itemName = productDataObj[key].long_description;
        let market_price = productDataObj[key].market_price ? productDataObj[key].market_price :'';

        let childId = key;
        if (
          (parent_id == actualParentItem || parent_id1 == actualParentItem) 
          //&& !(brand.includes("Khetika Naturalé") || brand.includes("Khetika Naturale") ||brand.includes("SuperChoice") 
          // ||brand.includes("Khetika Choice") ||brand.includes("KhetikaChoice")) && market_price == '' && category != 'GIFT'
          ) {
          priceDataObj[childId] = priceDataObj[childId] || {};
          // priceDataObj[childId]['cd_per'] = finalProductsList[0] && finalProductsList[0].newCD ? finalProductsList[0].newCD: priceDataObj && priceDataObj[childId] && priceDataObj[childId]['cd_per'] ? priceDataObj[childId]['cd_per'] :"";
          priceDataObj[childId]['cd_per'] =  finalProductsList[0]?.newCD ?? priceDataObj[childId]?.['cd_per'] ?? "";
          priceDataObj[childId]['mill_transport_value'] = finalProductsList[0] && finalProductsList[0].newtransportcharge ?finalProductsList[0].newtransportcharge:priceDataObj[childId]['mill_transport_value'];
          priceDataObj[childId]['source_type'] = finalProductsList[0] && finalProductsList[0].newSource ?finalProductsList[0].newSource:priceDataObj[childId]['source_type'];
          // priceDataObj[childId]['extra_margin_per'] = finalProductsList[0] && finalProductsList[0].newExtraMargin ?finalProductsList[0].newExtraMargin:priceDataObj[childId]['extra_margin_per'];
          priceDataObj[childId]['transport'] = finalProductsList[0] && finalProductsList[0].newTransport ?finalProductsList[0].newTransport:priceDataObj[childId]['transport'];
          priceDataObj[childId]["apmc_loading"] = finalProductsList[0] && finalProductsList[0].apmc_loading ? finalProductsList[0].apmc_loading :0; 
          let show_mat = priceDataObj[childId].material_cost > 0 ? true: false;
          let show_was = priceDataObj[childId].wastage > 0 ? true : false;

          let updatedProdPriceObj = dealerPriceCalculations(
            priceDataObj[childId],
            productDataObj[childId],
            show_mat,
            show_was,
            newBasePrice
          );
          console.log(priceDataObj[childId], productDataObj[childId]);
          let childprodele = {};
          if(productDataObj[childId].market_price == '') { 
            // childprodele["dealer_price"] = updatedProdPriceObj["dealer_price"];
            // setDealerPrice(updatedProdPriceObj["dealer_price"]);
            childprodele["newDealerPrice"] = updatedProdPriceObj["dealer_price"];
            setDealerPrice(updatedProdPriceObj["dealer_price"]);
            childprodele["newTotalPrice"] = updatedProdPriceObj["total_price"];
          }
          
          childprodele["base_price"] = newBasePrice;
          childprodele["apmc_charge"] = "" + updatedProdPriceObj["apmc_charge"];
          childprodele["gst_charge"] = getRoundNoThree(
            updatedProdPriceObj["gst_charge"]
          );
          childprodele["transport_charge"] = parseToNo(
            productDataObj[childId].transport_charge
          );
          childprodele["article_code"] = childId;
          childprodele["old_base_price"] = "" + updateDetails.base_price;
          childprodele["old_dealer_price"] = productDataObj[childId].dealer_price;
          childprodele["type"] = "child";
          childprodele["parent_id"] = actualParentItem;

          childprodele["purchase_price"] = newBasePrice;
          
          childprodele["newgstcharge"] = getRoundNoThree(
            updatedProdPriceObj["gst_charge"]
          );
          childprodele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
          childprodele["newtransportcharge"] = priceDataObj[childId].mill_transport_value;
          childprodele["newSource"] = priceDataObj[childId].source_type;
          childprodele["newTransport"] = priceDataObj[childId].transport;
          childprodele["newCD"] = priceDataObj[childId].cd_per;
          childprodele["newExtraMargin"] = priceDataObj[childId].extra_margin_per;
          childprodele["extra_margin_per"] = priceDataObj[childId].extra_margin_per;
          childprodele["gst_per"] = "" +  priceDataObj[childId].gst_per ? priceDataObj[childId].gst_per : '0.00'; //updateDetails.gst_per;
          childprodele["item_name"] = "" + itemName;
          childprodele["apmc_loading"] = priceDataObj[childId].apmc_loading;

          let grossCost = parentGrossCost;

          if((productDataObj[childId].weight).includes('gm')) {
            let weightVal = productDataObj[childId].weight.split(' ')[0];
            grossCost = grossCost * (Number(weightVal) /1000 );
          }
          if(priceDataObj[childId].mrp && priceDataObj[childId].mrp!=0 && (productDataObj[childId].weight).includes('kg')) {
            let weightVal = productDataObj[childId].weight.split(' ')[0];
            grossCost = grossCost * weightVal
          }
          childprodele["gross_cost"] = grossCost; //updatedProdPriceObj.gross_cost;
          const wastage = getAbsFromPer(priceDataObj[childId].wastage_per) * parseToNo(grossCost);
          let conversion_charges = getAbsFromPer(priceDataObj[childId].conversion_per) * parseToNo(priceDataObj[childId].base_price);
          conversion_charges = getRoundNo(conversion_charges);
          // let landingCost = Number(grossCost) + Number(wastage) + Number(conversion_charges) + Number(updatedProdPriceObj.package_material_cost);
          let secondaryPMCost = priceDataObj[childId]?.package_material_cost ? priceDataObj[childId].package_material_cost : priceDataObj[childId]?.material_cost_factor ? parseToNo(priceDataObj[childId].material_cost_factor) / parseToNo(priceDataObj[childId].lot_size) :0 ;
          let landingCost = (Number(grossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + secondaryPMCost; //(Number(priceDataObj[childId]?.package_material_cost ?? 0));
          landingCost = parseFloat(landingCost.toFixed(2));

          childprodele["landing_cost"] = landingCost; //updatedProdPriceObj.landing_cost;

          let randmtwodigitnum = Math.floor(
            Math.random() * (999 - 100 + 1) + 100
          );
          let childprodkey = childId + "-" + randmtwodigitnum;
          console.log(childprodkey);
          childprodele["rowselectkey"] = childprodkey;

          finalProductsList.push(childprodele);
        }
      });

      const uniqueData = {};

        // Iterate through the data array
      for (const item of finalProductsList) {
        const { article_code, pending_price_updated_at } = item;

        if (article_code in uniqueData) {
          if ((pending_price_updated_at >= uniqueData[article_code].pending_price_updated_at)) {
            uniqueData[article_code] = item;
          }
        } else {
          uniqueData[article_code] = item;
        }
      }
      const latestDateRecords = Object.values(uniqueData);

      console.log(latestDateRecords);
      setParentChildProductsList(latestDateRecords);
    }
  }, [updateProduct]);

  const calculateGrossCost = async (parent_id, weight) => {
    const priceDetails = await database.database().ref(`Price_Details/mumbai/items/${parent_id}`).once("value");
    let calVal = priceDetails.val();
    let gross_cost = calVal?.gross_cost ? calVal?.gross_cost : 0;
    if((weight).includes('gm')) {
      let weightVal = weight.split(' ')[0];
      gross_cost = gross_cost * (Number(weightVal) /1000 );
    }
    if((weight).includes('kg')) {
      let weightVal = weight.split(' ')[0];
      gross_cost = gross_cost * weightVal
    }

    gross_cost = parseFloat(gross_cost).toFixed(2);
    //setGrossCost(parseFloat(gross_cost).toFixed(2));
    return gross_cost;
  }

  const ProductTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <th>ItemID</th>
          <th>Item Description</th>
          <th>Old Base Price</th>
          <th>Base Price</th>
          <th>Dealer Price</th>
          <th>Total Price</th>
          <th>GST %</th>
          <th>Source Type</th>
          {source == "MILL" && <th>Transport</th>}
          {source == "MILL" && transport?.toUpperCase() == "EXCLUDED" && (
            <th>Mill Transport</th>
          )}

          { source == "MILL" && <th>Cash Discount(%)</th> }
          <th>Extra Margin(%)</th>
          <th>Gross Cost</th>
          <th>Landing Cost</th>
        </tr>
      </thead>
    );
  };
  const handleChange = (rowNum, itemType, name, value) => {
    console.log(value);
    if (itemType == "parent") {
      let updatedProductDataList = [];
      for (let i = 0; i < parentChildProductsList.length; i++) {
        let itemId = parentChildProductsList[i]["article_code"];
        let show_mat = priceDataObj[itemId].material_cost > 0 ? true: false;
        let show_was = priceDataObj[itemId].wastage > 0 ? true : false;
        let updatedProdPriceObj = dealerPriceCalculations(
          priceDataObj[itemId],
          productDataObj[itemId],
          show_mat,
          show_was,
          parentChildProductsList[i]["purchase_price"],
          name,
          value
        );
        console.log(updatedProdPriceObj);
        //update product state
        let ele = parentChildProductsList[i];
        ele[name] = value;
        if (name != "newtransportcharge" && (ele.type == 'parent' || (ele.type == 'child' && name != 'newExtraMargin'))) {
          // cd-per,extra-margin-per impact
          ele["newDealerPrice"] = updatedProdPriceObj['dealer_price']; //getRoundNo(parseToNo(updatedProdPriceObj['total_price']) + parseToNo(getAbsFromPer(ele[name]))*parseToNo(ele['purchase_price']))*parseToNo(ele["lot_size"]);
          setDealerPrice(updatedProdPriceObj['dealer_price']);
          ele["newTotalPrice"] = updatedProdPriceObj['total_price'];
        } else {
          if (transport?.toUpperCase() == "EXCLUDED" && (ele.type == 'parent' || (ele.type == 'child' && name != 'newExtraMargin'))) {
            // transport impact
            ele["newDealerPrice"] = updatedProdPriceObj['dealer_price']; //getRoundNo(parseToNo(updatedProdPriceObj['dealer_price']) + parseToNo(ele['newtransportcharge']));
            setDealerPrice(updatedProdPriceObj['dealer_price']);
            ele["newTotalPrice"] = updatedProdPriceObj['total_price'];
          }
        }
          if(ele.type == 'child' && name == 'newExtraMargin') {
            ele['newExtraMargin'] = ele['extra_margin_per'];
          }
          updatedProductDataList.push(ele);
      }
      setParentChildProductsList(updatedProductDataList);
      // setTimeout(() => {
      //   let bulkitemqty = document.getElementsByName(name);
      //   bulkitemqty[rowNum].focus();
      // }, 0);
    } else if (itemType == "child") {
      let itemId = parentChildProductsList[rowNum]["article_code"];
      //update product state
      let updatedProductDataList = [...parentChildProductsList];
      // updatedProductDataList[rowNum][name] = value;
      setParentChildProductsList(updatedProductDataList);
      // setTimeout(() => {
      //   let bulkitemqty = document.getElementsByName(name);
      //   bulkitemqty[rowNum].focus();
      // }, 0);
    }
  };
  const updateProductBasedOnBasePrice = (
    rowNum,
    itemType,
    updatedBasePrice
  ) => {
    console.log(updatedBasePrice);
    console.log(itemType);
    if (itemType == "parent") {
      let updatedProductDataList = [];
      let storedGrossCost;
      for (let i = 0; i < parentChildProductsList.length; i++) {
        let innerItemType = parentChildProductsList[i].type;
        let itemDealerPrice = parentChildProductsList[i].dealer_price;
        let productItemName = (parentChildProductsList[i].item_name).toLowerCase().includes('free');
        let itemId = parentChildProductsList[i]["article_code"];
        if(priceDataObj[itemId]?.source_type?.toLowerCase() == "mill") {
          priceDataObj[itemId].cd_per = parentChildProductsList[i].newCD;
          priceDataObj[itemId].mill_transport_value = parentChildProductsList[i].newtransportcharge;
        }        
        let show_mat = priceDataObj[itemId].material_cost > 0 ? true: false;
        let show_was = priceDataObj[itemId].wastage > 0 ? true : false;
        let updatedProdPriceObj;
        if(itemDealerPrice > 0 && !productItemName) {
          updatedProdPriceObj = dealerPriceCalculations(
            priceDataObj[itemId],
            productDataObj[itemId],
            show_mat,
            show_was,
            updatedBasePrice
          );
        }
        console.log(updatedProdPriceObj);

        //update product state
        let ele = parentChildProductsList[i];
        ele["purchase_price"] = updatedBasePrice;
        if(itemDealerPrice > 0 && !productItemName) { 
          ele["newDealerPrice"] = updatedProdPriceObj["dealer_price"];
          setDealerPrice(updatedProdPriceObj['dealer_price']);
          ele["newgstcharge"] = updatedProdPriceObj["gst_charge"];
          ele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
          ele['newTotalPrice'] = updatedProdPriceObj["total_price"];
          if (!storedGrossCost) {
            storedGrossCost = updatedProdPriceObj['gross_cost'];
          } 
          // else {
          //   ele['gross_cost'] = storedGrossCost;
          // }
          ele['gross_cost'] = storedGrossCost;
          ele['landing_cost'] = updatedProdPriceObj['landing_cost'];
        }

        if(innerItemType == 'child') {
          let innerStoredGrossCost = storedGrossCost;
          if((productDataObj[itemId].weight).includes('gm')) {
            let weightVal = productDataObj[itemId].weight.split(' ')[0];
            innerStoredGrossCost = innerStoredGrossCost * (Number(weightVal) /1000 );
          }
          // if((productDataObj[itemId].weight).includes('kg')) {
          //   let weightVal = productDataObj[itemId].weight.split(' ')[0];
          //   innerStoredGrossCost = innerStoredGrossCost * weightVal
          // }
          const wastage = getAbsFromPer(priceDataObj[itemId].wastage_per) * parseToNo(innerStoredGrossCost);
          let conversion_charges = getAbsFromPer(priceDataObj[itemId].conversion_per) * parseToNo(priceDataObj[itemId].base_price);
          conversion_charges = getRoundNo(conversion_charges);
          let landingCost = (Number(innerStoredGrossCost ?? 0)) + (Number(wastage ?? 0)) +  (Number(conversion_charges ?? 0)) + (Number(priceDataObj[itemId]?.package_material_cost ?? priceDataObj[itemId]?.material_cost_factor ?? 0)); //(Number(priceDataObj[itemId]?.package_material_cost ?? 0));
          landingCost = parseFloat(landingCost.toFixed(2));
          ele['gross_cost'] = innerStoredGrossCost;
          ele["landing_cost"] = landingCost;
        }
        updatedProductDataList.push(ele);
      }
      setParentChildProductsList(updatedProductDataList);
      setTimeout(() => {
        let bulkitemqty = document.getElementsByClassName("itembaseprice");
        bulkitemqty[rowNum].focus();
      }, 0);
    } else if (itemType == "child") {      
      let updatedProductDataList = [...parentChildProductsList];
      setParentChildProductsList(updatedProductDataList);
      setTimeout(() => {
        let bulkitemqty = document.getElementsByClassName("itembaseprice");
        bulkitemqty[rowNum].focus();
      }, 0);
    }
  };

  const ProductTableBody = () => {
    let rows = [];
    console.log("ProductTableBody");
    console.log(parentChildProductsList);
    if (parentChildProductsList.length > 0) {
      rows = parentChildProductsList.map((prod, index) => {
          return (
            <tr
              key={index}
              style={{
                backgroundColor: prod.type == "child" ? "#808080" : "#FFCCCC",
              }}
            >
              <td>{prod["article_code"]}</td>
              <td style={{ whiteSpace: "normal", margin: "auto", textAlign: "center" }}>{prod["item_name"]}</td>
              {prod.type === "parent" && (<td>{prod["base_price"]}</td>) ? (<td>{prod["base_price"]}</td>) : (<td>{prod["old_base_price"]}</td>)}
              <td>
                <Form.Control
                  className="itembaseprice"
                  type="number"
                  style={{ width: "85%", margin: "auto", textAlign: "center" }}
                  value={prod.purchase_price}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onChange={(e) => {
                    updateProductBasedOnBasePrice(
                      index,
                      prod["type"],
                      e.target.value
                    );
                  }}
                />
              </td>
              {/* {prod.type == 'parent' && <td>{prod["newDealerPrice"]}</td> ? <td>{prod["newDealerPrice"]}</td> : <td></td>} */}
              {<td>{prod["newDealerPrice"]}</td>}
              {<td>{prod['newTotalPrice']}</td>}
              {<td>{prod["gst_per"]}</td>}
              {prod.type == 'parent' ? (<td>
                <DropdownButton id="dropdown-basic-button" title={source}>
                  <Dropdown.Item
                    onClick={(e) => {setSource(e.target.name);
                      handleChange(
                        index,
                        prod["type"],
                        e.target.name,
                        e.target.value
                      );                  
                    }}
                    name="MILL"
                  >
                    MILL
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {setSource(e.target.name);
                      handleChange(
                        index,
                        prod["type"],
                        e.target.name,
                        e.target.value
                      );
                    }}
                    name="LOCAL PICK"
                  >
                    LOCAL PICK
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {setSource(e.target.name);
                      handleChange(
                        index,
                        prod["type"],
                        e.target.name,
                        e.target.value
                      );                                      
                    }}
                    name="DC DELIVERED"
                  >
                    DC DELIVERED
                  </Dropdown.Item>
                </DropdownButton>
              </td>):(<td>
                <DropdownButton id="dropdown-basic-button" title={source} disabled = {true}>
                  <Dropdown.Item name="MILL"> MILL</Dropdown.Item>
                  <Dropdown.Item name="LOCAL PICK"> LOCAL PICK</Dropdown.Item>
                  <Dropdown.Item name="DC DELIVERED">DC DELIVERED </Dropdown.Item>
                </DropdownButton></td> )}
                {prod.type != 'parent' ? source === "MILL" && (<td><DropdownButton
                    id="dropdown-basic-button"
                    disabled = {true}
                    title={transport?.toUpperCase() ? transport.toUpperCase() : ""}
                  >
                    <Dropdown.Item name="INCLUDED"> INCLUDED </Dropdown.Item>
                    <Dropdown.Item name="EXCLUDED"> EXCLUDED </Dropdown.Item>
                  </DropdownButton></td>) : source === "MILL" &&
                (<td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={transport?.toUpperCase() ? transport.toUpperCase() : ""}
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        setTransport(e.target.name)
                        handleChange(
                          index,
                          prod["type"],
                          "newTransport",
                          e.target.name
                        );
                      }}
                      name="INCLUDED"
                    >
                      INCLUDED
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setTransport(e.target.name);
                        handleChange(
                          index,
                          prod["type"],
                          "newTransport",
                          e.target.name
                        );
                      }}
                      name="EXCLUDED"
                    >
                      EXCLUDED
                    </Dropdown.Item>
                  </DropdownButton>
                </td>)}
              {prod.type != 'parent' ? source == "MILL" && transport?.toUpperCase() == "EXCLUDED" && (<td><Form.Control
                    defaultValue={prod["newtransportcharge"]}
                    name="newtransportcharge"
                    type="number"
                    disabled = {true}
                    style={{ width: "70%", margin: "auto", textAlign: "center" }}
                    onWheel={(event) => {
                      event.preventDefault();
                    }}
                  /></td>) : source == "MILL" && transport?.toUpperCase() == "EXCLUDED" && (
                <td>
                  <Form.Control
                    defaultValue={prod["newtransportcharge"]}
                    name="newtransportcharge"
                    type="number"
                    style={{ width: "70%", margin: "auto", textAlign: "center" }}
                    onChange={(e) => {
                      handleChange(
                        index,
                        prod["type"],
                        e.target.name,
                        e.target.value
                      );
                    }}
                    onWheel={(event) => {
                      event.preventDefault();
                    }}
                  />
                </td>
              )}
              {prod.type != 'parent' ? source == "MILL" && <td><Form.Control
                  defaultValue={prod["newCD"]}
                  name="newCD"
                  type="number"
                  disabled = {true}
                  style={{ width: "70%", margin: "auto", textAlign: "center" }}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                /></td> : source == "MILL" && (
              <td>
                <Form.Control
                  defaultValue={prod["newCD"]}
                  name="newCD"
                  type="number"
                  style={{ width: "70%", margin: "auto", textAlign: "center" }}
                  onChange={(e) =>
                    handleChange(
                      index,
                      prod["type"],
                      e.target.name,
                      e.target.value
                    )
                  }
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                />
              </td>
              )}

              {prod.type == 'parent' ? (<td>
                <Form.Control
                  defaultValue={prod["newExtraMargin"]?.split("%")[0]}
                  type="number"
                  name="newExtraMargin"
                  // disabled = {true}
                  onChange={(e) =>
                    handleChange(
                      index,
                      prod["type"],
                      e.target.name,
                      e.target.value
                    )
                  }
                  style={{ width: "50%", margin: "auto", textAlign: "center" }}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                />
              </td>):(<td><Form.Control
                  defaultValue={prod["newExtraMargin"]?.split("%")[0]}
                  type="number"
                  name="extra_margin_new"
                  disabled = {true}
                  style={{ width: "50%", margin: "auto", textAlign: "center" }}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                /></td>)}
              <td>
                <Form.Control
                  defaultValue={prod["gross_cost"]}
                  name="grossCost"
                  type="number"
                  disabled= {true}
                  style={{ width: "100%", margin: "auto", textAlign: "center" }}
                />
              </td>

              <td>
                <Form.Control
                  defaultValue={prod["landing_cost"]}
                  name="landingCost"
                  type="number"
                  disabled= {true}
                  style={{ width: "100%", margin: "auto", textAlign: "center" }}
                />
              </td>
            </tr>
          );
      });
    } else {
      rows = [];
    }

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const dealerPriceValidation = async (item_id,dealer_price) =>{
     
    const url = `${OFFER_BASE_URL}/product-offer?item_id=${item_id}&dealer_price=${dealer_price}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
    const resp = await axios.get(url);
    const isValid = resp.data.data;
    if(isValid) {
      updateProductDealerPrice();
    }else {
      return toast("Dealer Price should be greater than offer price.", { type: toast.TYPE.ERROR });
    }
    
  }
  
  const updateProductDealerPrice = () => {
    console.log("updateProductPrice");

    let currdate = formatDate(new Date());
    let currentYear = currdate.split("-")[2];
    let currentMonth = currdate.split("-")[1];

    let d = new Date(),
      // currdformat =
      //   [
      //     d.getDate().padLeft(),
      //     (d.getMonth() + 1).padLeft(),
      //     d.getFullYear(),
      //   ].join("-") +
      //   " " +
      //   [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":");

      currdformat = [
        d.getDate().padLeft(),
        (d.getMonth() + 1).padLeft(),
        d.getFullYear(),
        ].join("-") + 
            " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");

    let prodpendingpriceele = {};
    let original_prod_ele = {};

    for (let i = 0; i < parentChildProductsList.length; i++) {
      let prodele = {};
      if (parentChildProductsList[i]["type"] == "parent") { 
        prodele["dealer_price"] = parentChildProductsList[i]["newDealerPrice"];
        prodele["base_price"] = parentChildProductsList[i]["purchase_price"];
        prodele["apmc_charge"] = "" + parentChildProductsList[i]["newapmccharge"];
        prodele["gst_charge"] = parentChildProductsList[i]["newgstcharge"];
        prodele["source_type"] = source;
        prodele["transport"] = transport;
        prodele["extra_margin_per"] = parentChildProductsList[i]["newExtraMargin"];
        prodele["cd_per"] = parentChildProductsList[i]["newCD"] ? parentChildProductsList[i]["newCD"] :'';
        prodele["mill_transport_value"] = "" + parentChildProductsList[i]["newtransportcharge"];
        prodele["parent_id"] = parentChildProductsList[i]["parent_id"];
        let itemID = parentChildProductsList[i].article_code;
        prodele["item_id"] = itemID;
        prodele["approved"] = "N";
        prodele["old_base_price"] = "" + updateDetails.base_price;
        prodele["old_dealer_price"] = productDataObj[itemID].dealer_price;
        prodele["updated_by"] = sessionStorage.getItem("Logged User Email Id");
        prodele["updated_at"] = currdformat;
        prodele["total_price"] = parentChildProductsList[i]['newTotalPrice'];
        prodele["updated_from"] = "Price Update";
        prodele['landing_cost'] = updateDetails['calcObj'].landing_cost;
        prodele['gross_cost'] = updateDetails['calcObj'].gross_cost;
        prodele['buying_price_ex_gst'] = updateDetails['calcObj'].buying_price_ex_gst;        ;
      } else if(parentChildProductsList[i]["type"] == "child" && !parentChildProductsList[i]["item_name"].toLowerCase().includes('free')) {
        prodele["base_price"] = parentChildProductsList[i]["purchase_price"];
        prodele["parent_id"] = parentChildProductsList[i]["parent_id"];
        let itemID = parentChildProductsList[i].article_code;
        prodele["item_id"] = itemID;
        prodele["approved"] = "N";
        if(productDataObj[itemID].market_price == '') {
          prodele["old_dealer_price"] = productDataObj[itemID].dealer_price;
          prodele["dealer_price"] = parentChildProductsList[i]["newDealerPrice"];
          prodele['buying_price_ex_gst'] = updateDetails['calcObj'].buying_price_ex_gst;
        }
        prodele["updated_by"] = sessionStorage.getItem("Logged User Email Id");
        prodele["updated_at"] = currdformat;
        prodele["updated_from"] = "Price Update";
        prodele['landing_cost'] = parentChildProductsList[i].landing_cost; //updateDetails['calcObj'].landing_cost;
        prodele['gross_cost'] = parentChildProductsList[i].gross_cost; //updateDetails['calcObj'].gross_cost;
        prodele["base_price"] = parentChildProductsList[i]["purchase_price"];
        prodele["apmc_charge"] = "" + parentChildProductsList[i]["newapmccharge"];
        prodele["gst_charge"] = parentChildProductsList[i]["newgstcharge"];
        prodele["source_type"] = source;
        prodele["transport"] = transport;
        prodele["extra_margin_per"] = parentChildProductsList[i]["newExtraMargin"];
        prodele["cd_per"] = parentChildProductsList[i]["newCD"] ? parentChildProductsList[i]["newCD"] :'';
        prodele["mill_transport_value"] = "" + parentChildProductsList[i]["newtransportcharge"];
        prodele["old_base_price"] = "" + updateDetails.base_price;
        prodele["apmc_loading"] = parentChildProductsList[i]['apmc_loading'];
      }else if(parentChildProductsList[i]["type"] == "child" && parentChildProductsList[i]["item_name"].toLowerCase().includes('free')) {
        prodele["base_price"] = parentChildProductsList[i]["purchase_price"];
        prodele["parent_id"] = parentChildProductsList[i]["parent_id"];
        prodele["updated_from"] = "Price Update";
        prodele["old_base_price"] = "" + updateDetails.base_price;
        prodele["approved"] = "N";
        prodele["updated_by"] = sessionStorage.getItem("Logged User Email Id");
        prodele["updated_at"] = currdformat;
        let itemID = parentChildProductsList[i].article_code;
        prodele["item_id"] = itemID;
      }
      

      if (parentChildProductsList[i]["type"] == "parent") {
        original_prod_ele = prodele;
      }

      let productkey = parentChildProductsList[i]["rowselectkey"];
      prodpendingpriceele[productkey] = prodele;
    }

    console.log(prodpendingpriceele);
    console.log(original_prod_ele);

    //API to be used
    //save in Pending_Price_Update table
    database
      .database()
      .ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}`)
      .update(prodpendingpriceele)
      .then(() => {
        toast("Successfully added product price for approval.", {
          type: toast.TYPE.SUCCESS,
        });
        props.onHide();
        if (Object.keys(original_prod_ele).length > 0) {
          props.callback(original_prod_ele);
        }
      })
      .catch((error) => {
        toast("Error in saving price details : " + error, {
          type: toast.TYPE.ERROR,
        });
        props.onHide();
      });
  };

  const dealerPriceCalculations = (
    priceFromDB,
    prodData,
    showMaterial,
    showWastage,
    updatedBasePrice,
    name,
    value
  ) => {

    if(source == 'MILL') {
      priceFromDB['source_type'] = 'mill';
    }else if(source == 'DC Delivered' || source == 'DC DELIVERED'){
      priceFromDB['source_type'] = 'dc_delivered';
    }else if(source == 'LOCAL PICK' || source == 'Local Pick' || priceFromDB['source_type'] == 'LOCAL PICK'){
      priceFromDB['source_type'] = 'local_pick';
    }

    if(name == 'MILL') {
      priceFromDB['source_type'] = 'mill';
    }
    if(name == 'DC Delivered' || name == 'DC DELIVERED') {
      priceFromDB['source_type'] = 'dc_delivered';
    }
    if(name == 'LOCAL PICK' || name == 'Local Pick') {
      priceFromDB['source_type'] = 'local_pick';
    }

    if(transport == 'INCLUDED'){
      priceFromDB['mill_transport'] = 'included';
    } else if(transport == 'EXCLUDED') {
      priceFromDB['mill_transport'] = 'excluded';
    }

    if(name == 'newTransport' && value == 'EXCLUDED') {
      priceFromDB['mill_transport'] = 'excluded';
    } else if(name == 'newTransport' && value == 'INCLUDED') {
      priceFromDB['mill_transport'] = 'included';
    }
  
    if(updatedBasePrice) {
      priceFromDB['base_price'] = updatedBasePrice;
      prodData['base_price'] = updatedBasePrice;
    }
    if(name == 'newExtraMargin') {
      priceFromDB['extra_margin_per'] = `${value}%`;
    }
    
    if(name == 'newCD'){
      priceFromDB['cd_per'] = value;
    }

    if(name =='newtransportcharge') {
      priceFromDB['mill_transport_value'] = value;
    }
    
    let resObj = PriceCalculations(priceFromDB, prodData, showMaterial, showWastage);
    return resObj;
    // const resObj = {};
    // resObj.material_cost = priceFromDB.material_cost;

    // if (!showMaterial) {
    //   priceFromDB.material_cost_factor = 0;
    // }
    // if (showMaterial) {
    //   priceFromDB.material_cost_factor = 5;
    // }
    // if (!showWastage) {
    //   priceFromDB.wastage_per = 0;
    // }
    // if (showWastage) {
    //   priceFromDB.wastage_per = 2;
    // }

    // //material cost
    // resObj.material_cost =
    //   parseToNo(priceFromDB.material_cost_factor) /
    //   parseToNo(priceFromDB.lot_size);
    // resObj.material_cost = getRoundNo(resObj.material_cost);

    // //wastage cost
    // resObj.wastage =
    //   getAbsFromPer(priceFromDB.wastage_per) * parseToNo(updatedBasePrice);
    // resObj.wastage = getRoundNo(resObj.wastage);

    // //szmargin charges
    // let sz_margin_per = 1;
    // resObj.sz_margin_charges =
    //   getAbsFromPer(sz_margin_per) * parseToNo(updatedBasePrice);
    // resObj.sz_margin_charges = getRoundNo(resObj.sz_margin_charges);

    // //conversion charges
    // resObj.conversion_charges =
    //   getAbsFromPer(priceFromDB.conversion_per) * parseToNo(updatedBasePrice);
    // resObj.conversion_charges = getRoundNo(resObj.conversion_charges);

    // //extra margin charges
    // resObj.extra_margin_charges =
    //   getAbsFromPer(priceFromDB.extra_margin_per) * parseToNo(updatedBasePrice);
    // resObj.extra_margin_charges = getRoundNo(resObj.extra_margin_charges);

    // //total price
    // resObj.total_price_wo_rounding =
    //   parseToNo(updatedBasePrice) +
    //   parseToNo(priceFromDB.apmc_loading) +
    //   parseToNo(resObj.wastage) +
    //   parseToNo(
    //     getAbsFromPer(priceFromDB.conversion_per) * parseToNo(updatedBasePrice)
    //   ) +
    //   parseToNo(resObj.material_cost) +
    //   parseToNo(
    //     getAbsFromPer(priceFromDB.extra_margin_per) *
    //       parseToNo(updatedBasePrice)
    //   ) +
    //   parseToNo(resObj.sz_margin_charges);
    // resObj.total_price = getRoundNo(resObj.total_price_wo_rounding);

    // //total bag price
    // resObj.total_bag_price =
    //   parseToNo(resObj.total_price_wo_rounding) *
    //   parseToNo(priceFromDB.lot_size);
    // resObj.total_bag_price = getRoundNo(resObj.total_bag_price);

    // //apmc charges
    // resObj.apmc_charge =
    //   getAbsFromPer(priceFromDB.apmc_per) * parseToNo(updatedBasePrice);
    // resObj.apmc_charge = getRoundNoThree(resObj.apmc_charge);

    // //gst charges
    // let region_name = region != "All" ? region : "A";
    // resObj.gst_charge =
    //   getAbsFromPer(priceFromDB.gst_per) *
    //   (parseToNo(resObj.total_price_wo_rounding) +
    //     parseToNo(resObj.apmc_charge) +
    //     (parseToNo(priceFromDB.transport_charge) / TRANSPORT_FACTOR["A"]) *
    //       TRANSPORT_FACTOR[region_name]);
    // resObj.gst_charge = getRoundNo(resObj.gst_charge);
    // resObj.gst_abs_per = getAbsFromPer(priceFromDB.gst_per);

    // //final price
    // resObj.final_price =
    //   parseToNo(resObj.total_price_wo_rounding) +
    //   parseToNo(priceFromDB.transport_charge) +
    //   parseToNo(resObj.apmc_charge) +
    //   parseToNo(resObj.gst_charge);
    // resObj.final_price = getRoundNo(resObj.final_price);

    // //final bag price
    // resObj.final_bag_price =
    //   parseToNo(resObj.final_price) * parseToNo(priceFromDB.lot_size);
    // resObj.final_bag_price = getRoundNo(resObj.final_bag_price);

    // if (
    //   parseToNo(prodData.market_price) > 0 ||
    //   prodData.long_description.replace(/[^A-Z0-9]/gi, "").includes("Atta5kg")
    // ) {
    //   resObj["dealer_price"] = parseToNo(resObj.total_price) + "";
    //   resObj["dealer_price_pune"] = parseToNo(resObj.total_price_pune) + "";
    // } else if (parseToNo(prodData.market_price) === 0) {
    //   resObj["dealer_price"] = parseToNo(resObj.total_bag_price) + "";
    //   resObj["dealer_price_pune"] = parseToNo(resObj.total_bag_price_pune) + "";
    // }

    // return resObj;
  };

  const isValid = (data) => {
    if (
      data === undefined ||
      data === null ||
      data === "" ||
      data.length === 0
    ) {
      return 0;
    }
    return data;
  };

  const parseToNo = (string) => {
    return parseFloat(isValid(string));
  };

  const getAbsFromPer = (data) => {
    return parseFloat(parseToNo(data) / 100);
  };

  const getRoundNo = (data) => {
    return parseToNo(data).toFixed(2);
  };

  const getRoundNoThree = (data) => {
    return parseToNo(data).toFixed(3);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="dealerpriceupdatetable"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Price Update Approval of {updateDetails.article_code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="table-wrapper"
            style={{ marginTop: 15, paddingBottom: 40 }}
          >
            <div className="table-responsive" style={{ overflowX: 'auto' }}>
            
            <table
              className="table table-striped"
              style={{ textAlign: "center" }}
            >
              <ProductTableHeader />
              <ProductTableBody />
            </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"success"} onClick={(e)=>dealerPriceValidation(updateDetails.article_code,dealerPrice )}>
            Apply for Approval
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateProductNewDealerPriceModal;
