import React from 'react'

const UpdatedDate = () => {

    var today = new Date();
    var month =''+(today.getMonth()+1)
    var date=''+today.getDate()
    if (month.length < 2){ 
    month='0'+month;}
    if (date.length < 2){ 
    date ='0'+date;}
    var TodaysDate = today.getFullYear()+'-'+month+'-'+date;

    return TodaysDate
}

export default UpdatedDate
