import React, { useEffect, useState, useRef } from 'react'
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import database from '../../service/firebaseConfig';
import Select from 'react-select'
import DatePicker from "react-datepicker";

import UserEmail from '../../CommonRequirements/FetchUserEmailId'
import Loader from 'react-loader-spinner';
import { validateIsAdminUser,validateDCManagerUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import baseUrl from '../../service/servicesConfig';

const StockTakeDb = database.database().ref(`Product_Stock_Take`)
const Product_Base = database.database().ref(`Product_Base_Data`)

function EditStockTake({ data, dataId, dateToEdit, ...props }) {


    const [stockDate, setStockDate] = useState("")
    const [category, setCategory] = useState("")
    const [articleCode, setArticleCode] = useState("")
    const [itemName, setItemName] = useState("")
    const [articleInfo, setArticleInfo] = useState("")
    const [articleOptions, setArticleOptions] = useState("")
    const [disableList, setDisableList] = useState(false)
    const [dateToModify, setDateToModify] = useState(new Date(dateToEdit))
    const [validated, setValidated] = useState(false)
    const [outerSize, setOuterSize] = useState(data.inventory_outers)
    const [lotSize, setLotSize] = useState("")
    const [systemInventory, setSystemInventory] = useState("")
    const [inventoryOuters, setInventoryOuters] = useState("")
    const [bagOrBoxStock, setBagOrBoxStock] = useState(data.actual_stock_bag_box)
    const [looseStock, setLooseStock] = useState(data.loose_stock)
    const [actualStock, setActualStock] = useState("")
    const [stockLossGain, setStockLossGain] = useState("")
    const [approveLossGain, setApproveLossGain] = useState("")
    const [stockDetails, setStockDetails] = useState("")
    const [subCategory, setSubCategory] = useState("")
    const [uomValue, setUomValue] = useState("")

    const categoryField = useRef();
    const itemNoField = useRef();
    const itemNameField = useRef();
    const datepickerRef = useRef();
    const outersizeRef = useRef();
    const systemInventoryRef = useRef()
    const outerInventoryRef = useRef()
    const subCatgeoryRef = useRef()
    let btnRef = useRef();


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            return toast("Stock Take Details updated", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't update the Stock Take", { type: toast.TYPE.ERROR });
    };


    const fetchProductItem = () => {
        const productBaseInfo = []
        Product_Base.once("value", snapShot => {
            const productItems = snapShot.val();
            Object.keys(productItems).forEach(productId => {
                if (productItems[productId]["buying_flag"] == 'Y') {
                    productBaseInfo.push({
                        "articleId": productId, "category": productItems[productId].category, "lot_size": productItems[productId].lot_size, "brand": productItems[productId].brand, "variety": productItems[productId].variety, "itemName": productItems[productId].long_description,
                        "product_stock": productItems[productId].product_stock, "case_size": productItems[productId].case_size
                    })
                }
            })
        }).then(() => { setArticleInfo(productBaseInfo) })
    }
    useEffect(() => {
        fetchProductItem()
        console.log(datepickerRef.current)
        console.log(data)
    }, [])

    useEffect(() => {
        fetchProductItem()
        console.log(datepickerRef.current)
    }, [])

    const populateArticle = () => {

        const allOptionsArticle = []
        articleInfo.forEach(item => {
            let itemName = item.itemName
            if (item.brand)
                itemName = item.brand + " - " + item.itemName;

            allOptionsArticle.push({ "value": itemName, "label": itemName })
        })
        setArticleOptions(allOptionsArticle)
    }
    useEffect(() => {
        if (articleInfo)
            populateArticle()
    }, [articleInfo])

    const insertDomData = (itemValue, articleCode) => {
        let currentMn = ""
        let currentDt = ""
        let stockTakeDate = `${stockDate.split('-')[2]}-${stockDate.split('-')[1]}-${stockDate.split('-')[0]}`;
        if (articleCode) {
            setDisableList(false);

            articleInfo.forEach(item => {
                if (articleCode.includes(item.articleId)) {

                    if (stockDate == "") {
                        if ((dateToModify.getDate()) < 10) {
                            currentDt = "0" + (dateToModify.getDate())
                        }
                        else { currentDt = dateToModify.getDate() }
                        if ((dateToModify.getMonth() + 1) < 10)
                            currentMn = "0" + (dateToModify.getMonth() + 1);
                        else { currentMn = dateToModify.getMonth() + 1 }
                        stockTakeDate = `${dateToModify.getFullYear()}-${currentMn}-${currentDt}`
                    }
                    setDisableList(true);
                    categoryField.current.value = item.category
                    let itemValue = item.itemName
                    if (item.brand)
                        itemValue = item.brand + " - " + item.itemName;
                    console.log("Updating based on ArticleCode:: " + articleCode + " Article Name :: " + itemValue + " Category:: " + item.category);
                    StockTakeDb.child(`${stockTakeDate.split("-")[2]}/${stockTakeDate.split("-")[1]}/${stockTakeDate}/${item.articleId}`).once("value", snapShot => {
                        if (snapShot.hasChildren()) {
                            console.log(snapShot.val())
                            const stockTableData = snapShot.val()
                            setActualStock(Number(stockTableData.actual_stock))
                            setLooseStock(Number(stockTableData.loose_stock))
                            setBagOrBoxStock(Number(stockTableData.actual_stock_bag_box))
                            setStockLossGain(Number(stockTableData.stock_loss_gain))
                        }
                    }).then((val) => { console.log(val) })
                    fetch(`https://services2.superzop.com/api/superzop/systeminventory/getsysteminventory?date=${stockTakeDate}&item_id=${item.articleId}`).then(res => {
                        return res.json()
                    }).then(data => {
                        console.log(data[0]); setStockDetails(data[0]);
                        setCategory(item.category != undefined ? item.category : data[0].category)
                        setItemName(itemValue ? itemValue : data[0].long_description)
                        setUomValue(item.uom)
                        setLotSize(item.outer_size ? item.outer_size : data[0].outer_size)
                        setSystemInventory(data[0].system_inventory)
                        outersizeRef.current.value = item.outer_size ? item.outer_size : data[0].outer_size
                        systemInventoryRef.current.value = item.system_inventory ? item.system_inventory : data[0].system_inventory
                        outerInventoryRef.current.value = Number(data[0].system_inventory / outersizeRef.current.value)

                    }).catch(err => console.log(err))

                }
            })
        } else if (itemValue) {
            articleInfo.forEach(item => {
                let itemName = item.itemName
                if (item.brand)
                    itemName = item.brand + " - " + item.itemName;

                if (itemValue == itemName) {

                    console.log("Updating based on ArticleName:: " + itemValue + " Article ID :: " + item.articleId + " Category:: " + item.category);
                    setArticleCode(item.articleId)
                    itemNoField.current.value = item.articleId

                    if (stockDate == "") {
                        if ((dateToModify.getDate()) < 10) {
                            currentDt = "0" + (dateToModify.getDate())
                        }
                        else { currentDt = dateToModify.getDate() }
                        if ((dateToModify.getMonth() + 1) < 10)
                            currentMn = "0" + (dateToModify.getMonth() + 1);
                        else { currentMn = dateToModify.getMonth() + 1 }
                        stockTakeDate = `${dateToModify.getFullYear()}-${currentMn}-${currentDt}`
                    }

                    fetch(`https://services2.superzop.com/api/superzop/systeminventory/getsysteminventory?date=${stockTakeDate}&item_id=${item.articleId}`).then(res => {
                        return res.json()
                    }).then(data => {
                        console.log(data[0]); setStockDetails(data[0]);

                        setCategory(item.category != undefined ? item.category : data[0].category)
                        setSubCategory(item.sub_category != undefined ? item.sub_category : data[0].sub_category)
                        setUomValue(item.uom)
                        setLotSize(item.outer_size ? item.outer_size : data[0].outer_size)
                        setSystemInventory(data[0].system_inventory)
                        outersizeRef.current.value = item.outer_size ? item.outer_size : data[0].outer_size
                        systemInventoryRef.current.value = item.system_inventory ? item.system_inventory : data[0].system_inventory
                        outerInventoryRef.current.value = Number(data[0].system_inventory / outersizeRef.current.value)

                    }).catch(err => console.log(err))

                    StockTakeDb.child(`${stockTakeDate.split("-")[2]}/${stockTakeDate.split("-")[1]}/${stockTakeDate}/${item.articleId}`).once("value", snapShot => {
                        if (snapShot.hasChildren()) {
                            console.log(snapShot.val())
                            const stockTableData = snapShot.val()
                            setActualStock(Number(stockTableData.actual_stock))
                            setLooseStock(Number(stockTableData.loose_stock))
                            setBagOrBoxStock(Number(stockTableData.actual_stock_bag_box))
                            setStockLossGain(Number(stockTableData.stock_loss_gain))
                        }
                    })


                }
            })
        }
    }


    const UpdateStockTake = (event) => {


        let todaysDate = ""
        let currentMn = ""
        let currentDt = ""
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
        }
        else {

            btnRef.current.setAttribute("disabled", "disabled");

            console.log('approveLossGain: ', approveLossGain, approveLossGain ? approveLossGain : data.approve_stock_loss_gain);

            const isAdmin = validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL));

            const stockTakeDate = data.stock_date.substring(0, 10);

            const stockTakeDt = new Date(stockTakeDate.split("-")[0], stockTakeDate.split("-")[1], stockTakeDate.split("-")[2]);
            const year = stockTakeDt.getFullYear();
            const month = stockTakeDt.getMonth().toString().length === 1 ? `0${stockTakeDt.getMonth()}` : stockTakeDt.getMonth();
            const date = stockTakeDt.getDate().toString().length === 1 ? `0${stockTakeDt.getDate()}` : stockTakeDt.getDate();

            const stock_take_date_format = `${year}-${month}-${date}`;

            const currentDateObj = new Date();

            const currYear = currentDateObj.getFullYear();
            const currMonth = currentDateObj.getMonth().toString().length === 1 ? `0${currentDateObj.getMonth() + 1}` : currentDateObj.getMonth() + 1;
            const currDate = currentDateObj.getDate().toString().length === 1 ? `0${currentDateObj.getDate()}` : currentDateObj.getDate();

            let fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
            fourDaysOlderDate = fourDaysOlderDate.setDate(fourDaysOlderDate.getDate() - 4);

            console.log('stockTakeDate: ', stockTakeDate, ' stock_take_date_format: ', stock_take_date_format, ' fourDaysOlderDate: ', fourDaysOlderDate, ' isAdmin: ', isAdmin);

            if (!isAdmin) {
                if (new Date(stock_take_date_format) < new Date(fourDaysOlderDate)) {

                    console.log("if (new Date(stock_take_date_format) > new Date(fourDaysGreaterDate)) {");

                    return toast('Stock Take date can not be older than 4 days from current date', { type: toast.TYPE.ERROR });
                }
            }

            let dataToUpdate = {
                "stock_date": data.stock_date,
                "category": category ? category : data.category,
                "article_code": articleCode ? articleCode : data.article_code,
                "item_name": itemName ? itemName : data.item_name,
                "outer_size": outerSize ? outerSize : data.outer_size,
                "system_inventory": systemInventory ? systemInventory : data.system_inventory,
                "inventory_outers": inventoryOuters ? inventoryOuters : data.inventory_outers,
                "actual_stock_bag_box": bagOrBoxStock ? bagOrBoxStock : data.actual_stock_bag_box,
                "loose_stock": looseStock ? looseStock : data.loose_stock,
                "actual_stock": actualStock ? actualStock : data.actual_stock,
                "stock_loss_gain": stockLossGain ? stockLossGain : data.stock_loss_gain
            }
            if (validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) {
                //dataToUpdate["approve_stock_loss_gain"] = stockLossGain ? stockLossGain : data.approve_stock_loss_gain ? data.approve_stock_loss_gain : data.stock_loss_gain;
                dataToUpdate["authorized_by"] = UserEmail()
                dataToUpdate["approve_stock_loss_gain"] = approveLossGain ? approveLossGain : data.approve_stock_loss_gain ? data.approve_stock_loss_gain : data.stock_loss_gain;
            }

            const unique_key = data.uniqueKey;
            const firebaseKey = unique_key.substring(11);
            const stock_date = data.stock_date.substring(0, 10);

            const stock_date_year = stock_date.split("-")[0];
            const stock_date_month = stock_date.split("-")[1];
            const stock_date_date = stock_date.split("-")[2];

            dataToUpdate.unique_key = unique_key;
            dataToUpdate.firebaseKey = firebaseKey;

            const url = '/api/superzop/commercial/productstocktake/addeditstocktakeandsi?type=edit';

            baseUrl.post(url, dataToUpdate).then(res => {

                console.log('UpdateStockTake ', res);

                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                notifyUser(true);
            }).catch((error) => {

                console.error("An Error has occurred ", error);
            });

            /*const sqlProm = new Promise((resolve, reject) => {

                const putUrl = `/api/superzop/commercial/productstocktake/updateproductstocktakerecord?key=${unique_key}`;
                dataToUpdate.stock_date = stock_date;

                baseUrl.put(putUrl, dataToUpdate).then(res => {

                    console.log('updatePurchaseDetails ', res);

                    resolve("Success");
                }).catch((error) => {

                    console.error("An Error has occurred ", error);
                    reject(error);
                });

            });

            const firebaseProm = new Promise((resolve, reject) => {

                const stock_date_firebase = `${stock_date_date}-${stock_date_month}-${stock_date_year}`;

                dataToUpdate.stock_date = stock_date_firebase;

                StockTakeDb.child(`${stock_date_year}/${stock_date_month}/${stock_date_firebase}`).child(firebaseKey).update(dataToUpdate).then(() => {
                    resolve("Success");
                }).catch((error) => {
                    reject(error);
                });
            });

            Promise.all([firebaseProm, sqlProm]).then(() => {

                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                notifyUser(true);

                //console.log('articleCode: ',articleCode);

                if (new Date(stock_take_date_format) < new Date(fourDaysOlderDate)) {

                    //const item_id = data.article_code;
                    const todayDate = `${currYear}-${currMonth}-${currDate}`;

                    //const sync_sys_inv_url = `/api/superzop/systeminventory/savesysteminventoryintotable?start_date=${stock_take_date_format}&end_date=${todayDate}&item_id=${articleCode}`
                    const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${stock_take_date_format}&end_date=${todayDate}&item_id=${dataToUpdate.article_code}`;

                    baseUrl.post(sync_sys_inv_url).then(res => {

                        console.log('isAdmin updatePurchaseDetails ', res);
                        return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }).catch((error) => {
                notifyUser(false);
            });*/
        }

        setValidated(true)

    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={UpdateStockTake}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Stock Take
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body {...props}>
                    {(articleInfo == "") ?
                        <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            style={{ display: 'flex', justifyContent: 'center' }}
                            timeout={10000} //20 secs
                        /> :
                        <div>
                            <Table size="sm" >
                                <tr>
                                    <td>Stock Take Date</td>
                                    <td>
                                        <DatePicker
                                            readOnly
                                            ref={datepickerRef}
                                            selected={dateToModify}
                                            onChange={(val) => { setDateToModify(val); setStockDate((`${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}-${((val.getMonth()) + 1) < 10 ? "0" + (val.getMonth() + 1) : (val.getMonth() + 1)}-${val.getFullYear()}`)); console.log(val) }}
                                            minDate={new Date("2017/01/01")}
                                            maxDate={new Date()}
                                            aria-describedby="basic-addon1"
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<CustomDateInput />}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Item Name</td>
                                    <td>
                                        <Form.Control ref={itemNameField} value={data.item_name} readOnly />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Article Code</td>
                                    <td >
                                        <Form.Control ref={itemNoField} value={data.article_code} readOnly placeholder={data.article_code} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>
                                        <Form.Control ref={categoryField} readOnly placeholder={data.category} />
                                    </td></tr>

                                <tr>
                                    <td>Sub Category</td>
                                    <td>
                                        <Form.Control ref={subCatgeoryRef} readOnly placeholder={data.sub_category} />
                                    </td></tr>
                                <tr>
                                    <td>UOM</td>
                                    <td>
                                        <Form.Control value={uomValue} placeholder={data.UOM} readOnly />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Outer Size(Kg/Pc)</td>
                                    <td>
                                        <Form.Control readOnly placeholder={data.outer_size} />
                                    </td></tr>
                                <tr>
                                    <td>System Inventory(Kg/Pc)</td>
                                    <td>
                                        <Form.Control type="number" readOnly ref={systemInventoryRef} value={systemInventory ? systemInventory : Number(data.system_inventory).toFixed(2)} placeholder={data.system_inventory} />
                                    </td></tr>
                                <tr>
                                    <td>System Inventory In Outers</td>
                                    <td>
                                        <Form.Control readOnly placeholder={(Number(data.system_inventory) / Number(data.outer_size)).toFixed(2)} />
                                    </td></tr>
                                <tr>
                                    <td>Actual Stock in Bag /Box</td>
                                    <td>
                                        <Form.Control type="number" value={bagOrBoxStock} onChange={(e) => { setBagOrBoxStock(e.target.value); setActualStock((e.target.value !== "") ? Number((e.target.value) * (lotSize ? lotSize : data.outer_size)) + Number(looseStock ? looseStock : data.loose_stock) : ""); setStockLossGain(systemInventoryRef.current.value ? Number((e.target.value) * (lotSize ? lotSize : data.outer_size)) + Number(looseStock ? looseStock : data.loose_stock) - Number(systemInventoryRef.current.value) : "") }} placeholder={data.actual_stock_bag_box} />
                                    </td></tr>
                                <tr>
                                    <td>Actual Loose Stock Kg/Pc</td>
                                    <td>
                                        <Form.Control type="number" defaultValue={looseStock} onChange={(e) => { setLooseStock(e.target.value); setActualStock((e.target.value !== "") ? Number(bagOrBoxStock * (lotSize ? lotSize : data.outer_size)) + Number(e.target.value) : ""); setStockLossGain(bagOrBoxStock && (e.target.value !== "") && systemInventoryRef.current.value ? Number(bagOrBoxStock * (lotSize ? lotSize : data.outer_size)) + Number(e.target.value) - Number(systemInventoryRef.current.value) : "") }} placeholder={data.loose_stock} />
                                    </td></tr>
                                <tr>
                                    <td>Actual Stock (Kg/Pc)</td>
                                    <td>
                                        <Form.Control readOnly value={actualStock ? actualStock : ""} placeholder={data.actual_stock} />
                                    </td></tr>
                                <tr>
                                    <td>Entered Stock Loss/Gain (Kg/Pc)	</td>
                                    <td>
                                        {validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Form.Control readonly placeholder={Number(data.stock_loss_gain).toFixed(2)} />}
                                        {!validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Form.Control onChange={(e) => { setStockLossGain(e.target.value) }} value={stockLossGain ? stockLossGain : data.stock_loss_gain} placeholder={data.stock_loss_gain} />}
                                        {/* <Form.Control readonly placeholder={Number(data.stock_loss_gain).toFixed(2)} /> */}
                                    </td></tr>
                                {validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <tr>
                                    <td>Approved Stock Loss/Gain (Kg/Pc)</td>
                                    <td>
                                        <Form.Control type="number" onChange={(e) => { /*setStockLossGain(e.target.value)*/ setApproveLossGain(e.target.value) }} defaultValue={approveLossGain ? approveLossGain : data.approve_stock_loss_gain ? data.approve_stock_loss_gain : data.stock_loss_gain} placeholder={data.approve_stock_loss_gain} />
                                    </td></tr>}
                            </Table>
                            <br />
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" ref={btnRef}>Update Stock Take Details  </Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}

export default EditStockTake
