import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import { Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, DropdownButton, ButtonGroup, Table, Alert } from 'react-bootstrap'
import { InputGroupAddon } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx'
import Backdrop from '../../CommonRequirements/backdrop'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import baseUrl from '../../service/servicesConfig';


function StockTakeReport() {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [stockTakeDetails, setStockTakeDetails] = useState("")
    const [exportData, setExportData] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [stockList, setStockList] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState("")


    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let filterResult = ""

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify = (unavailability) => {

        if (unavailability)
            return toast("No Stock Take Details Available for selected date range ", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        startDate.setDate(startDate.getDate() - 1)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        const getUrl = `/api/superzop/commercial/productstocktake/getproductstocktakebasesonstockdate?start_date=${startDateConversion}&end_date=${endDateConversion}`;
        /*fetch(`https://services2.superzop.com/api/superzop/commercial/productstocktake/getproductstocktakebasesonstockdate?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setStockTakeDetails(data); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))*/

        baseUrl.get(getUrl).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {
            console.log(data);
            const mofiyData = calculateInventoryOuters(data);
            setStockTakeDetails(mofiyData);
            setBackDrop(false);
            if (!data.length > 0) { notify(true) }
        }).catch(err => console.log(err))
    }, [])

    const calculateInventoryOuters = (data) => {
        const modfiyData = data.map(item => {

            const inventory_in_outers = item.outer_size ? (Number(item.system_inventory) / Number(item.outer_size)).toFixed(2) : 0;
            item.inventory_outers = inventory_in_outers;
            return item;
        })

        return modfiyData;
    }



    const handleDateRangeChange = () => {

        if (startDate > endDate) {

            setBackDrop(false)
            setStockTakeDetails("")
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        setStockTakeDetails("")
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        /*fetch(`https://services2.superzop.com/api/superzop/commercial/productstocktake/getproductstocktakebasesonstockdate?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setStockTakeDetails(data); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))*/

        const getUrl = `/api/superzop/commercial/productstocktake/getproductstocktakebasesonstockdate?start_date=${startDateConversion}&end_date=${endDateConversion}`;
        baseUrl.get(getUrl).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {
            const mofiyData = calculateInventoryOuters(data);
            console.log(mofiyData);
            setStockTakeDetails(mofiyData);
            setBackDrop(false);
            if (!data.length > 0) { notify(true) }
        }).catch(err => console.log(err))
    }



    useEffect(() => {
        if (stockList)
            handleDateRangeChange()
    }, [stockList])

    const searchStockTakeReport = () => {
        const filterData = []
        console.log(stockTakeDetails)
        filterResult = stockTakeDetails.filter(obj => {

            const itemId = obj.article_code.trim().toLowerCase();
            const itemDesc = obj.item_name && obj.item_name.trim().toLowerCase() || "";
            const categorySearch = obj.category && obj.category.trim().toLowerCase() || "";

            setSearchText(searchText.trim().toLowerCase());

            return itemId.match(searchText) || itemDesc.match(searchText) || categorySearch.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(items)
        })
        console.log(filterData)
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchStockTakeReport()
    }, [searchText])


    const exportstockTakeDetails = () => {

        const wb = XLSX.utils.book_new()

        setBackDrop(true)
        //let stockTakeDetailsSummary = [["Actual Stock", "Actual Stock Bag/Box", "Approve Stock Loss/Gain", "Article Code", "Category", "Created At", "Created By", "Invetory Outers", "Item Name", "Loose Stock", "Outer Size", "Stock Date", "Stock Loss/Gain", "System Inventory"]]
        let stockTakeDetailsSummary = [["Stock Date", "Item ID", "Item Name", "Category", "Sub Category", "UoM", "Outer Size", "System Inventory", "Invetory Outers", "Actual Stock", "Actual Stock Bag/Box", "Stock Loss/Gain", "Approve Stock Loss/Gain", "Created At", "Created By", "Loose Stock"]]
        stockTakeDetails.map(item => {

            let stockTakeDetailsPerItem = [];

            console.log(item);

            Object.keys(item).forEach(itemLabels => {

                let itemDetail = item[itemLabels]
                //console.log(itemLabels + " " + itemDetail);
                if ((itemLabels.includes("created_at") || itemLabels.includes("stock_date")))
                    itemDetail = `${item[itemLabels].split("T")[0].split("-")[2]}-${monthList[item[itemLabels].split("T")[0].split("-")[1].slice(1) - 1]}-${item[itemLabels].split("T")[0].split("-")[0].slice(2)}`

                if (itemLabels.includes("stock_date")) {

                    stockTakeDetailsPerItem[0] = itemDetail;
                } else if (!itemLabels.includes("unique_key")) {

                    stockTakeDetailsPerItem.push(itemDetail)
                }


            })
            stockTakeDetailsSummary.push(stockTakeDetailsPerItem)

        })
        const StockTakeDetails = XLSX.utils.aoa_to_sheet(stockTakeDetailsSummary)
        XLSX.utils.book_append_sheet(wb, StockTakeDetails, `Stock Take List`)
        XLSX.writeFile(wb, `Stock_Take_List.xlsx`)
        setExportData(false)
        setBackDrop(false)


    }

    useEffect(() => {
        if (exportData)
            exportstockTakeDetails()
    }, [exportData])





    return (
        <>
            <NavBar />
            <div style={{ paddingTop: 100 }}>
                <Alert style={{ textAlign: "center" }} variant={"info"}>STOCK TAKE REPORT</Alert>
                <Navbar className="bg-light mr-auto justify-content-between" >
                    <Form inline>
                        <Button onClick={() => window.history.back()}><i class="fas fa-arrow-circle-left" size="3x"></i></Button>
                        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                            <InputGroupAddon addonType="prepend">Select Start Date</InputGroupAddon>
                            <DatePicker
                                selected={startDate}
                                onChange={(val) => { setStartDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"

                            />
                        </InputGroup>
                        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                            <InputGroupAddon addonType="prepend">Select End Date</InputGroupAddon>
                            <DatePicker
                                selected={endDate}
                                onChange={(val) => { setEndDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"

                            />
                        </InputGroup>
                        <Button size="sm" onClick={() => { setStockList(true); setBackDrop(true) }}>Show</Button>
                        {stockTakeDetails.length > 0 && <InputGroup style={{ marginLeft: "20px" }}>
                            <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Item Name / Category" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                        </InputGroup>}
                        <InputGroup size="sm" style={{ marginLeft: 30, marginRight: 50 }}>
                            <Button size="sm" variant="warning" onClick={() => setExportData(true)}>Export</Button>
                        </InputGroup>
                    </Form>
                </Navbar>
                {stockTakeDetails.length > 0 &&
                    <Jumbotron>
                        <Table>
                            <thead>
                                <tr>

                                    <td><center>Stock Date</center></td>
                                    <td><cente>Item ID</cente></td>
                                    <td><center>Item Name</center></td>
                                    <td><center>Category</center></td>
                                    <td><center>Outer Size</center></td>
                                    <td><center>UoM</center></td>
                                    <td><center>System Inventory</center></td>
                                    <td><center>Inventory Outers</center></td>
                                    <td><center>Actual Stock Bag/Box</center></td>
                                    <td><center>Loose Stock</center></td>
                                    <td><center>Actual Stock</center></td>
                                    <td><center>Stock Loss/Gain</center></td>
                                    <td><center>Approve Stock Loss/Gain</center></td>
                                    <td><center>Created At </center></td>
                                    <td><center>Created By</center></td>
                                </tr>
                            </thead>
                            <tbody>
                                {stockTakeDetails.length > 0 && filterList.length == 0 ? stockTakeDetails.map(items => (
                                    <tr>
                                        <td><center>{items.stock_date && `${items.stock_date.split("T")[0].split("-")[2]}-${monthList[items.stock_date.split("T")[0].split("-")[1] - 1]}-${items.stock_date.split("T")[0].split("-")[0].slice(2)}`}</center></td>
                                        <td><center>{items.article_code}</center></td>
                                        <td><center>{items.item_name}</center></td>
                                        <td><center>{items.category}</center></td>
                                        <td><center>{items.outer_size}</center></td>
                                        <td><center>{items.UOM}</center></td>
                                        <td><center>{items.system_inventory && Number(items.system_inventory).toFixed(2)}</center></td>
                                        <td><center>{items.inventory_outers}</center></td>
                                        <td><center>{items.actual_stock_bag_box}</center></td>
                                        <td><center>{items.loose_stock}</center></td>
                                        <td><center>{items.actual_stock}</center></td>
                                        <td><center>{items.stock_loss_gain && Number(items.stock_loss_gain).toFixed(2)}</center></td>
                                        <td><center>{items.approve_stock_loss_gain}</center></td>
                                        <td><center>{items.created_at && `${items.created_at.split("T")[0].split("-")[2]}-${monthList[items.created_at.split("T")[0].split("-")[1] - 1]}-${items.created_at.split("T")[0].split("-")[0].slice(2)}`}</center></td>
                                        <td><center>{items.created_by && items.created_by.split("@")[0]}</center></td>
                                    </tr>
                                )) :
                                    filterList.map(items => (
                                        <tr>
                                            <td><center>{items.stock_date && `${items.stock_date.split("T")[0].split("-")[2]}-${monthList[items.stock_date.split("T")[0].split("-")[1] - 1]}-${items.stock_date.split("T")[0].split("-")[0].slice(2)}`}</center></td>
                                            <td><center>{items.article_code}</center></td>
                                            <td><center>{items.item_name}</center></td>
                                            <td><center>{items.category}</center></td>
                                            <td><center>{items.outer_size}</center></td>
                                            <td><center>{items.UOM}</center></td>
                                            <td><center>{items.system_inventory && Number(items.system_inventory).toFixed(2)}</center></td>
                                            <td><center>{items.inventory_outers}</center></td>
                                            <td><center>{items.actual_stock_bag_box}</center></td>
                                            <td><center>{items.loose_stock}</center></td>
                                            <td><center>{items.actual_stock}</center></td>
                                            <td><center>{items.stock_loss_gain && Number(items.stock_loss_gain).toFixed(2)}</center></td>
                                            <td><center>{items.approve_stock_loss_gain}</center></td>
                                            <td><center>{items.created_at && `${items.created_at.split("T")[0].split("-")[2]}-${monthList[items.created_at.split("T")[0].split("-")[1] - 1]}-${items.created_at.split("T")[0].split("-")[0].slice(2)}`}</center></td>
                                            <td><center>{items.created_by && items.created_by.split("@")[0]}</center></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Jumbotron>
                }
                {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
            </div>
        </>
    )
}

export default StockTakeReport
