import React, { useContext, useEffect, useState } from "react";
import { createStyles, keyframes, Text, Tooltip } from "@mantine/core";
import CreateNewWidgetModal from "../../components/AppHomeScreenConfig/Modals/CreateWidget/CreateWidgetModal";
import Header from "../../components/AppHomeScreenConfig/Header";
import WidgetsContainer from "../../components/AppHomeScreenConfig/WidgetsUI/WidgetsContainer";
import styled from "styled-components";
import EditWidgetModal from "../../components/AppHomeScreenConfig/Modals/EditWidgetModal";
import axios from "axios";
import {
  WidgetsListContext,
  WidgetsListProvider,
} from "../../context/AppHomeScreen/WidgetsListContext";
import { OFFER_BASE_URL } from "../../service/servicesConfig";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  IconGripVertical,
  IconSettingsFilled,
  IconTrashFilled,
} from "@tabler/icons-react";
import MobilePreview from "../../components/AppHomeScreenConfig/MobilePreview/mobilePreview";
import { ProductMasterProvider } from "../../context/AppHomeScreen/ProductMasterContext";
import { IconMenuOrder } from "@tabler/icons-react";
import database from "../../service/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
`;

export const dragBlink = keyframes({
  "0%": { backgroundColor: "red" },
  "50%": { backgroundColor: "blue" },
  "100%": { backgroundColor: "red" },
});

const ButtonStyles = styled.div`
  transition: transform 0.1s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `.3rem solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : "#000"
    }`,
    padding: `1rem .5rem`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
    backgroundColor: "#FFBB00",
    marginBottom: theme.spacing.lg,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
    animation: `${dragBlink} 1s ease-in-out infinite`,
  },

  symbol: {
    fontSize: "1rem",
    fontWeight: 700,
    width: "1rem",
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : "black",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },
}));

Array.prototype.reorder = function (startIndex, endIndex) {
  const arr = this;

  if (startIndex === endIndex || startIndex < 0 || endIndex > arr.length) {
    return arr;
  }

  const startValue = arr[startIndex];
  let curr = arr[endIndex];
  if (startIndex < endIndex) {
    for (let i = endIndex; i > startIndex; i--) {
      let temp = arr[i - 1];
      arr[i - 1] = curr;
      curr = temp;
    }

    arr[endIndex] = startValue;
  } else if (endIndex < startIndex) {
    for (let i = endIndex; i < startIndex; i++) {
      let temp = arr[i + 1];
      arr[i + 1] = curr;
      curr = temp;
    }

    arr[endIndex] = startValue;
  }

  return arr;
};

const AppHomeScreenConfig = (props) => {
  const [CreateNewWidgetModalOpened, setCreateNewWidgetModalOpened] =
    useState(false);
  const [editWidgetModalOpened, setEditWidgetModalOpened] = useState(false);
  const [editTargetIndex, setEditTargetIndex] = useState(null);
  const { state, dispatch } = useContext(WidgetsListContext);
  const { classes, cx } = useStyles();
  const [productMasterRaw, setProductMasterRaw] = useState({});
  const [availableItemsForSelection, setAvailableItemsForSelection] = useState(
    []
  );
  const [categoriesUI, setCategoriesUI] = useState([]);
  const [catWithSubCat, setCatWithSubCat] = useState({});
  const [storeSeries, setStoreSeries] = useState("A");
  // const [widgetsListLocal, setWidgetsListLocal] = useListState(
  //   state.widgetsList
  // );

  useEffect(() => {
    toast.dismiss();
    fetchProductDataFromDB();
    toast("Fetching data, please wait...", {
      autoClose: "false",
      type: toast.TYPE.WARNING,
      position: "top-right",
      hideProgressBar: true,
    });
  }, []);

  useEffect(() => {
    fetchHomeScreenConfiguration(storeSeries);
  }, [storeSeries]);

  useEffect(() => {
    if (Object.keys(productMasterRaw) < 1) return;

    const formatted_items = [];
    Object.keys(productMasterRaw).forEach((item_id) => {
      const { item_description1, brand } = productMasterRaw[item_id];
      formatted_items.push({
        value: item_id,
        label: `${item_id}-${brand}-${item_description1}`,
      });
    });
    // console.log("formatted items", JSON.stringify(formatted_items));
    setAvailableItemsForSelection(formatted_items);
  }, [productMasterRaw]);

  // useEffect(() => {
  //   setWidgetsListLocal.setState(state.widgetsList);
  // }, [state.widgetsList]);

  function getCategoryAndSubCatFromProducts(product_master) {
    let catWithSubCatDict = {};
    let categories = [];

    Object.keys(product_master).forEach((item_key) => {
      const item = product_master[item_key];

      if (
        item.category &&
        item.category !== "" &&
        !(item.category in catWithSubCatDict)
      ) {
        categories.push({ value: item.category, label: item.category });
        catWithSubCatDict[item.category] = [item.sub_category];
      } else if (
        item.category &&
        item.category !== "" &&
        item.category in catWithSubCatDict
      ) {
        let arr = catWithSubCatDict[item.category];
        if (
          item.sub_category &&
          item.sub_category !== "" &&
          !arr.includes(item.sub_category)
        ) {
          arr.push(item.sub_category);
        }
        catWithSubCatDict[item.category] = [...arr];
      }
    });

    return { categories, catWithSubCatDict };
  }

  const fetchProductDataFromDB = () => {
    return new Promise((resolve, reject) => {
      if (Object.keys(productMasterRaw).length > 0) resolve();

      let product_master_new_ref = database
        .database()
        .ref(`Product_Master_New/A`);
      product_master_new_ref.once(
        "value",
        (snapshot) => {
          let data = snapshot.val();
          const { categories, catWithSubCatDict } =
            getCategoryAndSubCatFromProducts(data);

          setProductMasterRaw(data);
          setCategoriesUI(categories);
          setCatWithSubCat(catWithSubCatDict);
          toast.dismiss();
          resolve();
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  function fetchHomeScreenConfiguration(store_series = "A") {
    const homeScreenGetConfigURL = `${OFFER_BASE_URL}/home-screen-layout?store_series=${store_series}&retailer_id=10010&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`;

    axios
      .get(homeScreenGetConfigURL)
      .then((res) => {
        const widgetsData = res.data.data;
        dispatch({
          type: "populateWidgets",
          payload: { widgetsList: widgetsData },
        });
      })
      .catch((err) => {
        console.log(
          "error occured while atempting to fetch retailer data from API",
          err
        );
      });
  }

  function refetchData() {
    fetchHomeScreenConfiguration(storeSeries);
  }

  function prepareDataObjFromAPIResponse(payload_ref_obj) {
    let { widget, type, data } = payload_ref_obj;

    if (!data) {
      payload_ref_obj.data = [{}];
      data = payload_ref_obj.data[0];
    } else {
      data = data[0];
    }

    if (widget === "AUTO_SLIDER") {
      if (type === "PRODUCTS") {
        data.text = payload_ref_obj.text;
        data.item_id = payload_ref_obj.item_id;
        delete payload_ref_obj.text;
        delete payload_ref_obj.item_id;
      }
    } else if (widget === "HORIZONTAL_SCROLL") {
      if (type === "PRODUCTS") {
        data.text = payload_ref_obj.text;
        data.item_id = payload_ref_obj.item_id;
        delete payload_ref_obj.text;
        delete payload_ref_obj.item_id;
      }
      if (type === "SUB_CATEGORY") {
        data.parent_image_url = payload_ref_obj.image_url;
        delete payload_ref_obj.image_url;
        data.text = payload_ref_obj.text;
        delete payload_ref_obj.text;
        data.text_hi = payload_ref_obj.text_hi;
        delete payload_ref_obj.text_hi;
        data.text_mr = payload_ref_obj.text_mr;
        delete payload_ref_obj.text_mr;
        data.text_gj = payload_ref_obj.text_gj;
        delete payload_ref_obj.text_gj;
      }
      if (type === "COMBINE_PROD") {
        data.item_id = payload_ref_obj.item_id;
        delete payload_ref_obj.item_id;
      }
    } else if (widget === "VERTICAL_SCROLL") {
      if (type === "PRODUCTS_LIST" || type === "PRODUCTS_GRID") {
        data.text = payload_ref_obj.text;
        delete payload_ref_obj.text;
        data.text_hi = payload_ref_obj.text_hi;
        delete payload_ref_obj.text_hi;
        data.text_mr = payload_ref_obj.text_mr;
        delete payload_ref_obj.text_mr;
        data.text_gj = payload_ref_obj.text_gj;
        delete payload_ref_obj.text_gj;
        data.item_id = payload_ref_obj.item_id;
        delete payload_ref_obj.item_id;
      }
    }

    console.log("confirm payload", payload_ref_obj);
  }

  function formatDataBeforeAPICall(payload_ref_obj) {
    let { widget, type, data } = payload_ref_obj;

    data = data[0];

    if (widget === "AUTO_SLIDER") {
      if (type === "PRODUCTS") {
        payload_ref_obj.text = data.text;
        payload_ref_obj.item_id = data.item_id.join(",");
      }
      delete payload_ref_obj.data;
    } else if (widget === "HORIZONTAL_SCROLL") {
      if (type === "PRODUCTS") {
        payload_ref_obj.text = data.text;
        payload_ref_obj.item_id = data.item_id.join(",");
      }
      if (type === "SUB_CATEGORY") {
        const { image_url, sub_category } = data;

        payload_ref_obj.image_url = data.parent_image_url;
        payload_ref_obj.text = data.text;
        payload_ref_obj.text_hi = data.text_hi;
        payload_ref_obj.text_mr = data.text_mr;
        payload_ref_obj.text_gj = data.text_gj;

        payload_ref_obj.data = [];
        payload_ref_obj.data[0] = { image_url, sub_category };
        return;
      }
      if (type === "COMBINE_PROD") {
        payload_ref_obj.item_id = data.item_id.join(",");
      }
      delete payload_ref_obj.data;
    } else if (widget === "VERTICAL_SCROLL") {
      if (type === "PRODUCTS_LIST" || type === "PRODUCTS_GRID") {
        payload_ref_obj.text = data.text;
        payload_ref_obj.text_hi = data.text_hi;
        payload_ref_obj.text_mr = data.text_mr;
        payload_ref_obj.text_gj = data.text_gj;
        payload_ref_obj.item_id = data.item_id.join(",");
      }
      delete payload_ref_obj.data;
    }
  }

  function deleteWidget(widget_data, index) {
    if (!widget_data || (index !== 0 && !index)) return;

    const url = `${OFFER_BASE_URL}/home-screen-widget?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`;

    const data = {
      id: widget_data.id,
      is_active: widget_data.is_active === "Y" ? "N" : "Y",
    };

    console.log("data to delete", data);
    axios
      .delete(url, { data })
      .then((res) => {
        toast("Widget deleted successfully", { type: toast.TYPE.SUCCESS });

        let widgetsList = [...state.widgetsList];
        widgetsList.splice(index, 1);
        dispatch({
          type: "populateWidgets",
          payload: { widgetsList },
        });
      })
      .catch((err) => {
        console.log(
          "error occured while atempting to fetch retailer data from API",
          err
        );
        toast("Failed to delete widget", { type: toast.TYPE.ERROR });
      });
  }

  function handleOnAddWidgetModalShow(_isModalShown = false) {
    setCreateNewWidgetModalOpened(_isModalShown);
  }

  function handleOnEditWidgetModalShow(
    _isModalShown = false,
    _editTargetIndex
  ) {
    if (!_editTargetIndex && _editTargetIndex !== 0) {
      return;
    }
    setEditWidgetModalOpened(_isModalShown);
    console.log("check edit targetindex", _editTargetIndex);
    setEditTargetIndex(_editTargetIndex);
  }

  // function deleteWidgetItem(index) {
  //   const widgetsListCopy = JSON.parse(JSON.stringify(widgetsListLocal));
  //   delete widgetsListCopy[index];
  //   console.log("check widgets list", widgetsListCopy);

  //   setWidgetsListLocal(widgetsListCopy);
  // }

  // function insertNewWidget(widget) {
  //   const _widgetsListCopy = [...widgetsListLocal];
  //   _widgetsListCopy.push(widget);
  //   setWidgetsListLocal(_widgetsListCopy);
  // }

  return (
    <PageContainer>
      <Header
        handleOnAddWidgetModalShow={handleOnAddWidgetModalShow}
        isProductMasterDataLoaded={Object.keys(productMasterRaw).length > 0}
        storeSeries={storeSeries}
        setStoreSeries={setStoreSeries}
      />
      <MainContainer>
        <div style={{ width: "65vw" }}>
          <Text
            style={{ textAlign: "center", marginBottom: "1rem" }}
            fw="600"
            fz="md"
          >
            Widgets
          </Text>
          <DragDropContext
            onDragEnd={({ destination, source }) => {
              // console.log("check reorder", source.index, destination.index);
              dispatch({
                type: "populateWidgets",
                payload: {
                  widgetsList: state.widgetsList.reorder(
                    source.index,
                    destination.index
                  ),
                },
              });

              // setWidgetsListLocal.reorder({
              //   from: source.index,
              //   to: destination?.index || 0,
              // });
            }}
          >
            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {state.widgetsList.map((item, index) => (
                    <Draggable
                      key={item.id + ""}
                      index={index}
                      draggableId={item.id + ""}
                      style={{ width: "100%" }}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={cx(classes.item, {
                            [classes.itemDragging]: snapshot.isDragging,
                          })}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            {...provided.dragHandleProps}
                            className={classes.dragHandle}
                          >
                            {/* <Tooltip withArrow label="Hold to drag"> */}
                            {/* <IconMenuOrder
                              stroke={1.5}
                              size="1.5rem"
                              color="black"
                            /> */}
                            {/* </Tooltip> */}
                          </div>

                          <div
                            style={{
                              flexDirection: "row",
                              width: "100%",
                              gap: "1rem",
                              display: "flex",
                            }}
                          >
                            <Text> Priority : {item.priority}</Text>
                            <Text> Widget : {item.widget}</Text>
                            <Text> Type : {item.type} </Text>
                            {item.action && <Text>ACTION: {item.action}</Text>}
                            <ButtonStyles
                              style={{
                                marginLeft: "auto",
                                justifySelf: "end",
                                marginRight: "1rem",
                                display:
                                  Object.keys(productMasterRaw).length === 0
                                    ? "none"
                                    : "inline-block",
                              }}
                              onClick={() => {
                                setEditTargetIndex(index);
                                setEditWidgetModalOpened(true);
                              }}
                            >
                              <IconSettingsFilled
                                stroke={1.5}
                                size="1.5rem"
                                color="black"
                              />
                            </ButtonStyles>
                            <ButtonStyles
                              style={{
                                justifySelf: "end",
                                marginRight: "1rem",
                                display:
                                  Object.keys(productMasterRaw).length === 0
                                    ? "none"
                                    : "inline-block",
                              }}
                              onClick={() => {
                                deleteWidget(item, index);
                              }}
                            >
                              <IconTrashFilled
                                stroke={1.5}
                                size="1.5rem"
                                color="red"
                              />
                            </ButtonStyles>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        {/* <WidgetsContainer
          handleOnEditWidgetModalShow={handleOnEditWidgetModalShow}
          widgetsList={state.widgetsList}
          deleteWidgetItem={deleteWidgetItem}
        /> */}

        <div style={{ width: "32vw" }}>
          <MobilePreview />
        </div>
      </MainContainer>

      {CreateNewWidgetModalOpened && (
        <CreateNewWidgetModal
          modalOpened={CreateNewWidgetModalOpened}
          setModalOpened={setCreateNewWidgetModalOpened}
          availableItemsForSelection={availableItemsForSelection}
          selectedStoreSeries={storeSeries}
          refetchData={refetchData}
          categoriesUI={categoriesUI}
          catWithSubCat={catWithSubCat}
          formatDataBeforeAPICall={formatDataBeforeAPICall}
        />
      )}

      {editWidgetModalOpened && (
        <EditWidgetModal
          modalOpened={editWidgetModalOpened}
          setModalOpened={setEditWidgetModalOpened}
          editTargetIndex={editTargetIndex}
          availableItemsForSelection={availableItemsForSelection}
          widgetData={state.widgetsList[editTargetIndex]}
          selectedStoreSeries={storeSeries}
          categoriesUI={categoriesUI}
          catWithSubCat={catWithSubCat}
          refetchData={refetchData}
          formatDataBeforeAPICall={formatDataBeforeAPICall}
          prepareDataObjFromAPIResponse={prepareDataObjFromAPIResponse}
        />
      )}
    </PageContainer>
  );
};

const AppHomeScreenPage = () => {
  const AppHomeScreenPageStyles = styled.div`
    padding: 0.5rem;
    position: relative;
  `;

  return (
    <ProductMasterProvider>
      <WidgetsListProvider>
        <AppHomeScreenPageStyles>
          <AppHomeScreenConfig style={{ padding: "1rem" }} />
        </AppHomeScreenPageStyles>
      </WidgetsListProvider>
    </ProductMasterProvider>
  );
};

export default AppHomeScreenPage;
