import XLSX from "xlsx";
import database from "../../service/firebaseConfig";

function ExportPurchaseDetails({
  listedPurchaseDetails,
  multipleExport,
  exportDate,
  ...props
}) 
{
function updateSupplierIDs(name) {

const dbref = database.database().ref("Suppliers");
return new Promise((resolve,reject) => {

  dbref
    .orderByChild("supplier_name")
    .equalTo(`${name}`)
    .once("value")
    .then((val) => {
      resolve(Object.keys(val.val())[0]);
    });
});
}
function PushIds() {
const arr = [];
Object.keys(listedPurchaseDetails).forEach((item) => {
 arr.push(updateSupplierIDs(listedPurchaseDetails[item].supplier_name));
});
  return  Promise.all(arr);
}
function exportxls(arr) {
  
  const exportPurchaseDetails = [
    [
      "article_code",
      "grn_date",
      "grn_no",
      "Invoice Number",
      "Item Name",
      "Purchase Price",
      "Total Weight",
      "Supplier Name",
      "Vendor Code",
      "Status",
      "Broker",
    ],
  ]
  Object.keys(listedPurchaseDetails).map((item)=> {
    listedPurchaseDetails[item]["supplier_id"] = arr[item];
  })
  console.log(listedPurchaseDetails);
  Object.keys(listedPurchaseDetails).forEach((item) => {
    exportPurchaseDetails.push([
      listedPurchaseDetails[item].article_code,
      listedPurchaseDetails[item].grn_date,
      listedPurchaseDetails[item].grn_no,
      listedPurchaseDetails[item].invoice_number,
      listedPurchaseDetails[item].item_name,
      listedPurchaseDetails[item].purchase_price,
      listedPurchaseDetails[item].total_weight,
      listedPurchaseDetails[item].supplier_name,
      listedPurchaseDetails[item].supplier_id,
      "Issued",
      "Direct",
    ]);
  });
  const wb = XLSX.utils.book_new();
  const purchaseDetailsList = XLSX.utils.aoa_to_sheet(exportPurchaseDetails);
  purchaseDetailsList["!cols"] = fitToColumn(exportPurchaseDetails);
  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
      wch: Math.max(
        ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 1))
      ),
    }));
  }
  XLSX.utils.book_append_sheet(wb, purchaseDetailsList, `Purchase Details`);
  XLSX.writeFile(wb, `Purchase_Details_${exportDate}.xlsx`);
  multipleExport(false);
}

PushIds().then((arr) => {
exportxls(arr);
}).catch((err) => {
console.log(err);
})

return null;
}

export default ExportPurchaseDetails;
