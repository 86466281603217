import React from 'react';
import { LoaderWrapper } from './wrapper';

const LoaderComp = () => {
    return (
        <LoaderWrapper>
            <header>
                Loading the data from server ...
            </header>
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </LoaderWrapper>
    );
};

export default LoaderComp;
