export const imageDetails = {
  altText: "product-img",
  height: 120,
  width: 120,
  titleText: "click to open in new tab",
};

export const videoDetails = {
  altText: "product-video",
  height: 120,
  width: 120,
  titleText: "click to open in new tab",
};
