export const isValid = (data) => {
    if (data === undefined || data === null || data === "" || data.length === 0) {
        return 0;
    }
    return data;
};

export const parseToNumber = (string) => {
    return parseFloat(isValid(string));
};

export const getRoundNumber = (data) => {
    return parseToNumber(data).toFixed(2);
};