import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Alert,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Button,
  Form,
  Modal,
  Badge,
  Navbar,
} from "react-bootstrap";
import { validateDCManagerUser, validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { toast } from "react-toastify";
import database from "../../service/firebaseConfig";
import { FaWindows, FaLessThanEqual } from "react-icons/fa";
import Select from "react-select";
import Creatable from "react-select/creatable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import baseURL from "../../service/servicesConfig";
import { deliveryCommercialServer } from "../../service/servicesConfig";
import AddSupplier from "./AddSupplier";

const PurchaseDetailsData = database.database().ref(`Purchase_Details`);
const Product_Base = database.database().ref(`Product_Base_Data`);
const SuppliersDB = database.database().ref(`Suppliers`);
const productsMasterDB = database.database().ref(`Product_Master_New/A`);
const priceDetailsDB = database.database().ref(`Price_Details/mumbai/items`);
function RtvList(props) {
  const [suppliersList, setSuppliersList] = useState();
  const [addNewRecord, setAddNewRecord] = useState(false);
  const [submitDetails, setSubmitDetails] = useState(false);
  const [purchasedDate, setPurchasedDate] = useState("");
  const [category, setCategory] = useState("");
  const [articleCode, setArticleCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemSelection, setItemSelection] = useState("");
  const [outerType, setOuterType] = useState("");
  const [outerSize, setOuterSize] = useState("");
  const [isInvoiceFieldDisabled, setIsInvoiceFieldDisabled] = useState(false);
  const [bagPurchased, setBagPurchased] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [eachPurchaseData, setEachPurchaseData] = useState("");
  const [productBaseData, setProductBaseData] = useState({});
  const [articleInfo, setArticleInfo] = useState("");
  const [articleOptions, setArticleOptions] = useState("");
  const [hsn,setHSN] = useState("");
  const [Source, setSource] = useState("");
  const [isSelectFilled, setIsSelectFilled] = useState(false);
  const [isValidationDisabled, setIsValidationDisabled] = useState(false);
 
  const [dateChange,setdateChange] = useState(new Date());
   const [invoiceDate,setinvoiceDate] = useState(`${
                          dateChange.getDate() < 10
                            ? "0" + dateChange.getDate()
                            : dateChange.getDate()
                        }-${
                          dateChange.getMonth() + 1 < 10
                            ? "0" + (dateChange.getMonth() + 1)
                            : dateChange.getMonth() + 1
                        }-${dateChange.getFullYear()}`);
  const [disableList, setDisableList] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [supplierSelection, setSupplierSelection] = useState("");
  const [isSupplierSelectionDisabled, setIsSupplierSelectionDisabled] =
    useState(false);
  const [correspondingItems, setCorrespondingItems] = useState("");
  const [dateToModify, setDateToModify] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [basePrice, setBasePrice] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [SupplierID, setSupplierID] = useState("");
  const [SupplierEmail, setSupplierEmail] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [replacementBrand, setReplacementBrand] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [showExistingPoChecked, setShowExistingPoChecked] = useState(true);
  const [existingPoNumbersToDisp, setExistingPoNumbersToDisp] = useState([]);
  const [articleCodeWithBags, setArticleCodeWithBags] = useState([]);
  const [existingPoSelection, setExistingPoSelection] = useState({});
  const [Payment_Terms, setPayment_Terms] = useState("")
  const [Delivery, setDelivery] = useState("")
  const [exisitingPoNumbersAndSuppliers, setExistingPoNumbersAndSuppliers] =
    useState({});
      const [PoAmt, setPoAmt] = useState(Number(purchasePrice)* Number(totalWeight));
const [GstCharges, setGstCharges] = useState("");
const [Fssai, setFssai] = useState("");
const [Gstin, setGstin] = useState("");
const [phone, setPhone] = useState("");
const [Address, setAddress] = useState("");
const [Street, setStreet] = useState("");
const [City, setCity] = useState("");
const [Pin, setPin] = useState("");
const [State, setState] = useState("");
const [Apmc, setApmc] = useState("");

const [Mrp,setMrp] = useState("")




  const PurchaseData = [];
  const DateofPurchase = [];
  let purchaseItems = {};
  const categoryField = useRef();
  const itemNoField = useRef();
  const itemNameField = useRef();
  const totalWtPurchasedRef = useRef();
  const supplierRef = useRef();
  const datepickerRef = useRef();
  let btnRef = useRef();
const [Transport, setTransport] = useState("");
const [CashDisc, setCashDisc] = useState("");
const [TransCharges, setTransCharges] = useState("");
  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast("Purchase Details added", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't add the Purchase Details", {
      type: toast.TYPE.ERROR,
    });
  };

  const fetchProductItem = () => {
    const productBaseInfo = [];
    productsMasterDB
      .once("value", (snapShot) => {
        const productItems = snapShot.val();

        Object.keys(productItems).forEach((productId) => {
          if (productItems[productId]["buying_flag"] == "Y") {
            productBaseInfo.push({
              articleId: productId,
              category: productItems[productId].category,
              lot_size: productItems[productId].lot_size,
              brand: productItems[productId].brand,
              variety: productItems[productId].variety,
              itemName: productItems[productId].long_description,
              base_price:
                productItems[productId].base_price &&
                productItems[productId].base_price,
              hsn_code : productItems[productId].hsn_code,
})

          }
        });
      })
      .then(() => {
        setArticleInfo(productBaseInfo);
      });
  };

  const fetchPoList = (selectedDate) => {
    console.log("selectedDate", moment(selectedDate).format("YYYY-MM-DD"));
    if (!selectedDate) {
      return console.log("ERROR date arg not received");
    }

    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

    const getUrl = `/api/superzop/commercial/purchasedetails/getpolist?create_date=${formattedDate}`;
    console.log("fetch po list URL ", getUrl);
    deliveryCommercialServer
      .get(getUrl)
      .then((response) => {
        if (response.status === 200) {
          const po_list = [];
          const po_list_with_suppliers = {};
          const article_code_with_bags = {};
          const item_ids = [];
          const bags = [];
          const data = response.data;

          data.forEach((item, index) => {
            po_list.push({ value: item.po_number, label: item.po_number });
            po_list_with_suppliers[item.po_number] = {
              supplier_name: item.supplier_name,
              invoice_number: item.invoice_number || "",
            };
            const pairs = item.article_code_with_bags ? item.article_code_with_bags.split(',') : [];
            pairs.forEach(pair => {
              const [item_id, bag] = pair.split(':');
              // article_code_with_bags[item.po_number] ={
              //   item_id : item_id,
              //   bag_purchased: bag,
              // }
              if (article_code_with_bags.hasOwnProperty(item.po_number)) {
                article_code_with_bags[item.po_number].push({ item_id, bag });
              } else {
                  article_code_with_bags[item.po_number] = [{ item_id, bag }];
              }
              // item_ids.push(item_id);
              // bags.push(bag);
            });
          
            
          });

          console.log("set this po list", data);
          setArticleCodeWithBags(article_code_with_bags);
          setExistingPoNumbersToDisp(po_list);
          setExistingPoNumbersAndSuppliers(po_list_with_suppliers);
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.log("error occured while fetching po list");
        console.log(err);
        setExistingPoNumbersToDisp([]);
      });
  };

  useEffect(() => {
    fetchProductItem();
    fetchPoList(dateToModify);
    console.log(datepickerRef.current);
  }, []);

  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({name : suppliersList[supplier].supplier_name,email :suppliersList[supplier].EmailID,key : suppliersList[supplier].supplier_id,fssai : suppliersList[supplier].fssai,gstin:suppliersList[supplier]['GST_Identification_Number_(GSTIN)'],phone: suppliersList[supplier].MobilePhone,address:suppliersList[supplier].Billing_Address,street:suppliersList[supplier].Billing_Street2,city:suppliersList[supplier].Billing_City,pin:suppliersList[supplier].Billing_Code,state:suppliersList[supplier].Billing_State, supplier_id:suppliersList[supplier].supplier_id})
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        if(!(items.supplier_id && items.supplier_id.includes('VA'))) {
          suppliers.push({ value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state});
        } else if(items.supplier_id && items.supplier_id.includes('VA') && (validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)))) {
          suppliers.push({ value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state});
        }
      });
    }).then(() => {
      setSuppliersList(suppliers)
    });
  };

  useEffect(() => {
    fetchProductItem();
    fetchSuppliers();
    console.log(datepickerRef.current);
  }, []);

  const populateArticle = () => {
    const allOptionsArticle = [];
    articleInfo.forEach((item) => {
      let itemName = item.itemName;
      if (item.brand) itemName = item.brand + " - " + item.itemName;

      allOptionsArticle.push({ value: itemName, label: itemName });
    });
    setArticleOptions(allOptionsArticle);
  };

  useEffect(() => {
    if (articleInfo) populateArticle();
  }, [articleInfo]);

  const insertDomData = (itemValue, articleCode) => {
    if (articleCode) {
      setDisableList(false);

      articleInfo.forEach((item) => {
        if (articleCode.includes(item.articleId)) {
          setDisableList(true);
          console.log(item);
          categoryField.current.value = item.category;
          let itemValue = item.itemName;
          if (item.brand) itemValue = item.brand + " - " + item.itemName;
          console.log(
            "Updating based on ArticleCode:: " +
              articleCode +
              " Article Name :: " +
              itemValue +
              " Category:: " +
              item.category
          )
          item.hsn_code ? setHSN(item.hsn_code) : setHSN("");
          setCategory(item.category);
          setItemName(itemValue);
          setOuterType(item.variety);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${articleCode}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
            setUomValue(itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC");
            setOuterSize(
              Number(itemsDetails.outer_size)
                ? Number(itemsDetails.outer_size)
                : 1
            );
          });
          priceDetailsDB.child(`${articleCode}`).once('value').then((snapShot) => {
            console.log(snapShot.val());
            const prices = snapShot.val();
            setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
            setApmc(prices?.apmc_per ? prices.apmc_per : "0")
            if(prices?.source_type === 'local_pick')
            {
              setSource("LOCAL PICK")
            }
            else if(prices?.source_type === 'dc_delivered')
            {
              setSource("DC DELIVERED")
            }
            else
            {
             setSource("MILL")
             setTransport(prices?.mill_transport ? prices.mill_transport : "");
             setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
             setCashDisc(prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
            }
          })

        }
      });
    } else if (itemValue) {
      articleInfo.forEach((item) => {
        let itemName = item.itemName;
        if (item.brand) itemName = item.brand + " - " + item.itemName;

        if (itemValue == itemName) {
          console.log(
            "Updating based on ArticleName:: " +
              itemValue +
              " Article ID :: " +
              item.articleId +
              " Category:: " +
              item.category
          );
          setCategory(item.category);
          item.hsn_code ? setHSN(item.hsn_code) : setHSN("");
          categoryField.current.value = item.category;
          setArticleCode(item.articleId);
          itemNoField.current.value = item.articleId;
          setOuterType(item.variety);
          setUomValue(item.uom);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${item.articleId}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
console.log(itemsDetails);
            if (itemsDetails) {
              setUomValue(itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC");
              setOuterSize(
                Number(itemsDetails.outer_size)
                  ? Number(itemsDetails.outer_size)
                  : 1
              );
            }
          });
          priceDetailsDB.child(`${item.articleId}`).once('value').then((snapShot) => {
              console.log(snapShot.val());
              const prices = snapShot.val();
              setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
              setApmc(prices?.apmc_per ? prices.apmc_per : "0")
              if(prices?.source_type === 'local_pick')
              {
                setSource("LOCAL PICK")
              }
              else if(prices?.source_type === 'dc_delivered')
              {
                setSource("DC DELIVERED")
              }
              else
              {
               setSource("MILL")
               setTransport(prices?.mill_transport ? prices.mill_transport : "");
               setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
               setCashDisc(prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
              }
            })
        }
      });
    }
  };

  const resetValues = () => {
    setArticleCode("");
    setCategory("");
    setItemName("");
    setInvoiceNumber("");
    setExistingPoSelection({});
    setShowExistingPoChecked(true);
    setItemSelection("");
    setSupplierSelection(null);
    setIsSupplierSelectionDisabled(false);
    setSupplier("");
    setOuterType("");
    setCategory("");
    setOuterSize("");
    setInvoiceNumber("");
    setUomValue("");
    setBasePrice("");
    setPurchasePrice("");
    setTransport("")
    setCashDisc("")
    setReplacementBrand("");
    setBagPurchased("");
    setButtonStatus(false);
    setTotalWeight("");
    setHSN("");
    setPayment_Terms("");
    setDelivery("");
    setSupplierID("");
    setSupplierEmail("");
    setMrp("");
  };

  const generateNewPoNumber = (selectedDate) => {
    let yearFromDate = selectedDate.split("-")[2];
    let newYearStr = yearFromDate.substr(yearFromDate.length - 2);
    let randomFiveDigitNum = new Date().getTime() % 100000;
    let po_number = `${newYearStr}${
      selectedDate.split("-")[1]
    }${selectedDate.split("-")[0]}${randomFiveDigitNum}`;
    return po_number;
  };

  const PushPurchaseDetails = (event) => {
    let currentMn = "";
    let currentDt = "";
    const form = event.currentTarget;
    console.log("check existingPoSel", existingPoSelection);
    if (showExistingPoChecked && Object.keys(existingPoSelection).length < 2) {
      event.preventDefault();
      return toast("Please select an purchase order number", {
        type: toast.TYPE.ERROR,
      });
    }
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setIsValidationDisabled(false);
      let purchaseDateValue = purchasedDate;
      setButtonStatus(true);
      console.log("source"+Source)
      if (
        articleCode !== "" ||
        itemName !== "" ||
        (supplier != "" && uomValue && outerSize && outerType && Source !="undefined")
      ) {
        if (outerType !== "") {
          const randomKey =
            articleCode +
            "-" +
            Math.floor(Math.random() * (999 - 100 + 1) + 100); //itemKey

          if (purchasedDate == "") {
            if (dateToModify.getDate() < 10) {
              currentDt = "0" + dateToModify.getDate();
            } else {
              currentDt = dateToModify.getDate();
            }
            if (dateToModify.getMonth() + 1 < 10)
              currentMn = "0" + (dateToModify.getMonth() + 1);
            else {
              currentMn = dateToModify.getMonth() + 1;
            }
            purchaseDateValue = `${currentDt}-${currentMn}-${dateToModify.getFullYear()}`;
          }
          var newSupplier = true;
          suppliersList.forEach((item) => {
            if (supplier == item.value) {
              newSupplier = false;
            }
          });
          if (newSupplier) {
            SuppliersDB.push({ supplier_name: supplier });
          }
          let po_number = "";
          if (showExistingPoChecked) {
            po_number = existingPoSelection.value;
          } else {
            po_number = generateNewPoNumber(purchaseDateValue);
          }
          console.log("PurchaseDate" + purchaseDateValue);
          console.log("saved supplier name", supplier);
          const jsonObj = {
            purchased_date: purchaseDateValue,
            bill_address:Address?Address:"",
            bill_city:City?City:"",
            bill_street:Street?Street:"",
            bill_state:State?State:"",
            bill_pin:Pin?Pin:"",
            bill_gstin:Gstin?Gstin:"",
            bill_fssai:Fssai?Fssai:"",
            bill_phone:phone?phone:"",
            gst_charges_per : GstCharges?GstCharges:"",
            apmc_charges_per:Apmc?Apmc:"",
            category: category?category:"",
            hsn_code:hsn?hsn:"",
            article_code: articleCode,
            base_price: basePrice,
            invoice_number: invoiceNumber,
            purchase_price: purchasePrice ? purchasePrice : basePrice,
            uom: uomValue ? uomValue :"",
            item_name: itemName,
            invoice_number:invoiceNumber,
            invoice_date:invoiceDate,
            po_number: po_number,
            outer_type: outerType,
            outer_size: outerSize,
            replacement_brand: replacementBrand ? replacementBrand : "",
            bag_purchased: bagPurchased,
            total_weight: totalWeight ? totalWeight : outerSize * bagPurchased,
            source_type : Source,
            transport_charges: TransCharges,
            cash_discount : CashDisc,
            transport : Transport,
            supplier_name: supplier,
            supplier_email:SupplierEmail?SupplierEmail:"",
            supplier_id:SupplierID,
            delivery_in_days:Delivery?Delivery:"",
            payment_terms : Payment_Terms?Payment_Terms:"",
            po_amount : PoAmt ? PoAmt : purchasePrice * (totalWeight ? totalWeight : outerSize * bagPurchased),
            mrp: Mrp,
            created_by: UserEmail(),
            created_at: `${UpdatedDate()} ${UpdatedTime()}`,
          };

          console.log("data to save", jsonObj);

            PurchaseDetailsData.child(
              `${purchaseDateValue.split("-")[2]}/${
                purchaseDateValue.split("-")[1]
              }/${purchaseDateValue}`
            )
              .child(randomKey)
              .update(jsonObj);

          jsonObj.key = randomKey;
          jsonObj.updated_at = `${UpdatedDate()} ${UpdatedTime()}`;

          const url =
            "/api/superzop/commercial/purchasedetails/createpurchasedetailsrecord";

          baseURL
            .post(url, jsonObj)
            .then((res) => {
              console.log("updatePurchaseDetails ", res);
              notifyUser(true);
              setButtonStatus(false);
              resetValues();
              setValidated(false);
            })
            .catch((error) => {
              console.error("An Error has occurred ", error);
              notifyUser(false);
              setButtonStatus(false);
            });

        } else {
          return toast("Select Outer Type", { type: toast.TYPE.ERROR });
        }
      } else {
        setButtonStatus(false);
        return toast("Article Code / Outer Size / UOM / Supplier can't be empty", {
          type: toast.TYPE.ERROR,
        });
      }
    }

    setValidated(true);
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const openListPurchase = () => {
    window.open("#/listpurchase");
  };

  return (
    <>
      <NavBar />
      <Form noValidate validated={validated} onSubmit={PushPurchaseDetails}>
        <Navbar
          className="bg-light mr-auto justify-content-between"
          style={{ paddingTop: 100 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Badge variant="dark">Update Purchase Order</Badge>
          </div>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              openListPurchase();
            }}
          >
            List Purchase Details
          </Button>
        </Navbar>
        {articleInfo == "" ? (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ display: "flex", justifyContent: "center" }}
            timeout={10000} //20 secs
          />
        ) : (
          <div>
            <Table size="sm" responsive>
            <tbody>
              <tr>
                <td>Purchase Date</td>
                <td>
                  <DatePicker
                    ref={datepickerRef}
                    required
                    selected={dateToModify}
                    onChange={(val) => {
                      setDateToModify(val);
                      setExistingPoSelection({});
                      setIsSelectFilled(false);
                      resetValues();
                      setIsValidationDisabled(true);
                      setPurchasedDate(
                        `${
                          val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );
                      // console.log("DEBUG check this", val);
                      fetchPoList(val);
                    }}
                    minDate={new Date("2017/01/01")}
                    maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {/* <input
                    type="checkbox"
                    id="existing_po_checkbox"
                    name="existing_po"
                    checked={showExistingPoChecked}
                    onChange={(event) => {
                      setShowExistingPoChecked(event.target.checked);
                      setExistingPoSelection(null);
                      setIsSupplierSelectionDisabled(false);
                      setSupplierSelection(null);
                      setSupplier("");
                    }}
                  /> */}
                  <label
                    style={{ marginLeft: "10px" }}
                  >
                    {" "}
                    Select an Existing PO Number
                  </label>
                  {existingPoNumbersToDisp.length > 0 ? (
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={existingPoNumbersToDisp.length === 0}
                      isClearable={true}
                      isSearchable={true}
                      name="existing_po_select"
                      options={existingPoNumbersToDisp}
                      value={existingPoSelection}
                      isRequired={true}
                      onChange={(val) => {
                        if (val) {
                          resetValues();
                          const filteredOptionsArticle = [];
                          // articleInfo.forEach((item) => {
                          //   let itemName = item.itemName;
                          //   if (item.brand) itemName = item.brand + " - " + item.itemName;

                          //   filteredOptionsArticle.push({ value: itemName, label: itemName });
                          // });
                          // setArticleOptions(filteredOptionsArticle);

                          const filteredItemsFromBags = articleCodeWithBags[val.value] || [];
                          const itemIds = filteredItemsFromBags.map(item => item.item_id);
                          const filteredArticleInfo = articleInfo.filter(item => itemIds.includes(item.articleId));
                          filteredArticleInfo.forEach((item) => {
                              let itemName = item.itemName;
                              if (item.brand) itemName = item.brand + " - " + item.itemName;
  
                              filteredOptionsArticle.push({ value: itemName, label: itemName });
                            });

                          setArticleOptions(filteredOptionsArticle);
                          setExistingPoSelection(val);
                          setIsSelectFilled(true);
                          if (
                            exisitingPoNumbersAndSuppliers[val.value]
                              .supplier_name &&
                            exisitingPoNumbersAndSuppliers[val.value]
                              .supplier_name !== ""
                          ) {
                            const supplier_name =
                              exisitingPoNumbersAndSuppliers[val.value]
                                .supplier_name;
                            setSupplierSelection({
                              value: supplier_name,
                              label: supplier_name,
                            });
                            setSupplier(supplier_name);
                            setIsSupplierSelectionDisabled(true);

                            if (
                              exisitingPoNumbersAndSuppliers[val.value]
                                .invoice_number
                            ) {
                              setInvoiceNumber(
                                exisitingPoNumbersAndSuppliers[val.value]
                                  .invoice_number
                              );
                              setIsInvoiceFieldDisabled(true);
                            } else {
                              setInvoiceNumber("");
                              setIsInvoiceFieldDisabled(false);
                            }
                          } else {
                            setSupplierSelection(null);
                            setSupplier("");
                            setInvoiceNumber("");
                            setIsSupplierSelectionDisabled(false);
                            setIsInvoiceFieldDisabled(false);
                          }
                        } else {
                          setSupplierSelection(null);
                          setSupplier("");
                          setInvoiceNumber("");
                          setExistingPoSelection({});
                          setIsSelectFilled(false);
                          setIsSupplierSelectionDisabled(false);
                          setIsInvoiceFieldDisabled(false);
                        }
                      }}
                    />
                  ) : (
                    <p style={{ color: 'red' }}>No PO available for the selected date.</p>
                  )}
                </td>
              </tr>
              {/* {!isSelectFilled && <p style={{ color: 'red' }}>This field is required.</p>} */}
              <tr>
                <td>Item Name *</td>
                <td>
                  {articleCode == "" || disableList == false ? (
                    <Select
                      required
                      options={articleOptions}
                      value={itemSelection}
                      onChange={(val) => {
                        setBagPurchased("");
                        setItemSelection(val);
                        setItemName(val.value);
                        let itemName = val.value;
                        let itemCode = null;
                        insertDomData(itemName, itemCode);
                      }}
                      style={{ width: "150px" }}
                    />
                  ) : (
                    <Form.Control value={itemName} readOnly />
                  )}
                </td>
              </tr>
              {/* Source Mill Local Pick  DC Delivered */}
              <tr>
                <td>Source *</td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    required
                    title={Source?.toUpperCase() ? Source.toUpperCase() : "--Select--"} >
                    <Dropdown.Item

                      onClick={(e) => setSource(e.target.name)}
                      name={"MILL"}
                    >
                      MILL
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setSource(e.target.name)}

                      name={"LOCAL PICK"}
                    >
                      LOCAL PICK
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setSource(e.target.name)}

                      name={"DC DELIVERED"}
                    >
                      DC DELIVERED
                    </Dropdown.Item>
                  </DropdownButton>
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              {Source === 'MILL' ? (
                <>
              <tr>

                <td>
                  Transport *
                </td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={Transport?.toUpperCase()  ? Transport.toUpperCase() : "--Select--"} >
                    <Dropdown.Item

                      onClick={(e) => setTransport(e.target.name)}
                      name={"included"}
                    >
                      INCLUDED
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setTransport(e.target.name)}

                      name={"excluded"}
                    >
                      EXCLUDED
                    </Dropdown.Item>
                  </DropdownButton>
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr> 
               </>) : null}
               {Source === 'MILL' && Transport === 'excluded' ? (
               <tr><td>Transport Charges</td>
              <td><Form.Control value={TransCharges} onChange={(e) => setTransCharges(e.target.value)}/></td></tr>) : null}
              {Source === 'MILL' ? (
              <tr><td>Cash Discount</td>
               <td><Form.Control value={CashDisc} onChange={(e) => setCashDisc(e.target.value)}/></td></tr>):null}
              <tr>
                <td>Article Code</td>
                <td>
                  {itemName == "" || disableList ? (
                    <Form.Control
                      ref={itemNoField}
                      value={articleCode}
                      onChange={(e) => {
                        setArticleCode(e.target.value);
                        let itemCode = e.target.value;
                        let itemName = null;
                        insertDomData(itemName, itemCode);
                      }}
                    />
                  ) : (
                    <Form.Control
                      ref={itemNoField}
                      value={articleCode}
                      onChange={(e) => {
                        setArticleCode(e.target.value);
                        let itemCode = e.target.value;
                        let itemName = null;
                        insertDomData(itemName, itemCode);
                      }}
                      readOnly
                    />
                  )}
                </td>
              </tr>
              {/* HSN Code readonly from master DB */}
              <tr>
                <td>HSN Code</td>
                <td>
                  <Form.Control  value = {hsn} readOnly />
                </td>
              </tr>
              <tr>
                <td>Category</td>
                <td>
                  <Form.Control value={category} ref={categoryField} readOnly />
                </td>
              </tr>

              <tr>
                <td>Outer Type</td>
                <td>
                    <Form.Control
                    type="text"
                    readOnly
                    value={outerType ? outerType : ""}
                    />
                  {/* <DropdownButton
                    disabled={true}
                    id="dropdown-basic-button"
                    title={outerType ? outerType : "--Select--"}
                  >
                    <Dropdown.Item
                      onClick={(e) => setOuterType(e.target.name)}
                      name={"SMALL PACK"}
                    >
                      SMALL PACK
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setOuterType(e.target.name)}
                      name={"GONI"}
                    >
                      GONI
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setOuterType(e.target.name)}
                      name={"BAG OF PACKS"}
                    >
                      BAG OF PACKS
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setOuterType(e.target.name)}
                      name={"HIGH VALUE OF LOOSE"}
                    >
                      HIGH VALUE OF LOOSE
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setOuterType(e.target.name)}
                      name={"FMCG"}
                    >
                      FMCG
                    </Dropdown.Item>
                  </DropdownButton> */}
                </td>
              </tr>
              <tr>
                <td>Outer Size(Kg/Pc)</td>
                <td>
                  <Form.Control
                    readOnly
                    value={outerSize ? outerSize + " " + uomValue : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Base Price</td>
                <td>
                  <Form.Control
                    type="number"
                    readOnly
                    value={basePrice ? basePrice : ""}
                  />
                </td>
              </tr>
              <tr>
                <td>Purchase Price</td>
                <td>
                  <Form.Control
                    type="number"
                    required={!isValidationDisabled}
                    step={"any"}
                    value={purchasePrice}
                    onChange={(e) => {setPurchasePrice(e.target.value)
                    setPoAmt("")}}
                    
                  /> {
                    !isValidationDisabled && (<Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>)
                  }
                </td>
              </tr>
              <tr>
                <td>Replacement Brand</td>
                <td>
                  <Form.Control
                    value={replacementBrand ? replacementBrand : ""}
                    onChange={(e) => {
                      setReplacementBrand(e.target.value);
                    }}
                  />
                </td>
              </tr>
              {/* For MILL Type Only  Transport (Excluded/Included) Mill Transport Cash Discount from Price master DB */}

              {/* Payment Terms(in Days) Delivery Period(in Days) MRP  */}
              {/* Invoice Date */}
              <tr>
                <td>Invoice Date</td>
                <td>
                  <DatePicker
                    required
                    selected={dateChange}
                    minDate={dateToModify}
                    // selected should be greater than or equal to po date
                    onChange={(val) => {
                      setdateChange(val);
                      setinvoiceDate(
                        `${
                          val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );                    }}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </td>
              </tr>
              <tr>
                <td>Invoice Number</td>
                <td>
                  <Form.Control
                    step={"any"}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    value={invoiceNumber}
                  />
                </td>
              </tr>
              <tr>
                <td>Payment Terms (in Days)</td>
                <td>
                  <Form.Control required={!isValidationDisabled} type="number"  value={Payment_Terms} onChange={(e) => {
                    setPayment_Terms(e.target.value)
                  }}/>
                  {
                    !isValidationDisabled && (<Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>)
                  }
                </td>
              </tr>
              <tr>
                <td>Delivery Period (in Days)</td>
                <td>
                  <Form.Control type="number" required={!isValidationDisabled} value={Delivery} onChange = {(e) =>{setDelivery(e.target.value)} } />
                  {
                    !isValidationDisabled && (<Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>)
                  }
                </td>
              </tr>
              <tr>
                <td>Supplier Name </td>
                <td>
                  {/* <Creatable required options={suppliersList} ref={supplierRef} value={supplierSelection} onChange={(val) => { setSupplierSelection(val); setSupplier(val.value) }} style={{ width: "100px" }} /> */}
                  <Select
                    required
                    options={suppliersList}
                    ref={supplierRef}
                    value={supplierSelection}
                    onChange={(val) => {
                      setSupplierSelection(val);
                      setSupplier(val.value);
                      setSupplierID(val.key);
                      setSupplierEmail(val.email);
                      setAddress(val.address);
                      setStreet(val.street)
                      setCity(val.city)
                      setPin(val.pin)
                      setState(val.state)
                      setPhone(val.phone);
                      setGstin(val.gstin);
                      
                      console.log(val);
                    }}
                    style={{ width: "100px" }}
                    isDisabled={isSupplierSelectionDisabled}
                  />
                </td>
                <td>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                      window.open("#/addsupplier");
                    }}
                  >
                    Add Supplier
                  </Button>
                </td>
              </tr>
              {/* Supplier ID from Supplier DB */}
              <tr>
                <td>Supplier ID </td>
                <td>
                  <Form.Control value = {SupplierID} readOnly />
                </td>
              </tr>
              {/* Supplier Email from Supplier DB */}
              <tr>
                <td>Supplier Email</td>
                <td>
                  <Form.Control value = {SupplierEmail}   onChange = {(e) => setSupplierEmail(e.target.value) }/>
                </td>
              </tr>
              <tr>
                <td>No Of Bags Purchased *</td>
                <td>
                  <Form.Control
                    type="number"
                    value={bagPurchased}
                    required={!isValidationDisabled}
                    onChange={(e) => {
                      let filteredItemsFromBags = articleCodeWithBags[existingPoSelection.value] 
                      const foundItem = filteredItemsFromBags.find(item => item.item_id === articleCode)?.bag
                      let finalBag = foundItem ? foundItem : null;
                      // const filteredArticleInfo = articleInfo.filter(item => itemIds.includes(item.articleId));
                      if (e.target.value > -1 || e.target.value < -finalBag) {
                        e.preventDefault();
                        setBagPurchased("");
                        setTotalWeight("");
                        return toast("Please enter a value between -1 and " + (-finalBag) + " for bag purchased to return", {
                            type: toast.TYPE.ERROR,
                        });
                      }
                      setBagPurchased(e.target.value);
                      setTotalWeight("");

                    }}
                  />
                  {
                    !isValidationDisabled && (<Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>)
                  }
                </td>
              </tr>
              <tr>
                <td>Total Weight Purchased *</td>
                <td>
                  <Form.Control
                    ref={totalWtPurchasedRef}
                    value={
                      totalWeight
                        ? totalWeight
                        : bagPurchased && outerSize
                        ? outerSize * bagPurchased
                        : ""
                    }
                    onChange={(e) => {
                      setTotalWeight(e.target.value);
                      setPoAmt(Number(e.target.value) *  Number(purchasePrice))
                    }}
                  />
                </td>
              </tr>
              {/* PO Amount from Price DB */}
              <tr>
                <td>PO Amount </td>
                <td>
                  <Form.Control value ={PoAmt ? PoAmt : purchasePrice * (totalWeight ? totalWeight : outerSize * bagPurchased)} readOnly />
                </td>
              </tr>
              <tr>
                <td>MRP </td>
                <td>
                  <Form.Control value = {Mrp} onChange={(e) => setMrp(e.target.value)} />
                </td>
              </tr>
              </tbody>
            </Table>
            <center>
              <Button variant="success" type="submit" disabled={existingPoNumbersToDisp.length == 0 || buttonStatus}>
              Update Purchase Order
              </Button>
            </center>
            <br />
          </div>
        )}
      </Form>
    </>
  );
}

export default RtvList;