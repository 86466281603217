import firebase from 'firebase';

const qualityFirebaseConfig = {
    apiKey: "AIzaSyAKzpErcarZ9vfoV0i_WIvH1w69DcLzz74",
    authDomain: "superzop-quality.firebaseapp.com",
    databaseURL: "https://superzop-quality.firebaseio.com/",
    projectId: "superzop-quality",
    storageBucket: "superzop-quality.appspot.com"
};


const qualitydb = firebase.initializeApp(qualityFirebaseConfig, 'qualitydb');

export default qualitydb;
