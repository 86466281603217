import React, { useState, useContext, useEffect } from "react";
import { Breadcrumb, Tab, Row, Col, Nav, Button, Form } from "react-bootstrap";
import { rootStore } from "../../store";
import { useHistory } from "react-router-dom";
import ChangeRegionComp from "../ChangeRegion";
import ProductContext from "../../context/productList";
import {
  ALL_REGIONS_LIST,
  PROD_MASTER_TABLE
} from "../../constants/allConstants";
import {
  NavBarContainer,
  LeftContainer,
  SZLogoContainer,
  
  MidContainer,
  RightContainer,
  UserStatusContainer,
  StockContainer,
} from "./wrapper";

import database from "../../service/firebaseConfig";
import Modal from 'react-bootstrap/Modal';
import { TransferList } from "@mantine/core";
import { toast } from "react-toastify";

const Popular = () => {
  
  let history = useHistory();
  const isUserSignedIn = true;
  const userStatusText = isUserSignedIn ? "Sign Out" : "Sign In";
  let [popularProductsList,setPopularProductsList] = useState([[],[]]);
  let [newProdsList,setNewProdsList] = useState([[],[]]);
  let [topProdsList,setTopProdsList] = useState([[],[]]);

  let [productData,setProductData] = useState([]);
  let [popularProducts,setPopularProducts] = useState([]);

  let popular_products_ref = database.database().ref("Popular_Products");
  let products_ref = database.database().ref("Product_Master_New/A");

  let allPopular = [];
  let allProducts = [];

    // popular products modal changes

  const [showPopProdsModal,setShowPopProdsModal] = useState(false);
  const handleClose = () => setShowPopProdsModal(false);
  const handleOpen = () => {
    setShowPopProdsModal(true);
    const popDesc = productData.filter((product) =>{
      return ((popularProducts.map(item =>item).includes(product.item_id)))
    })
    popDesc.map((popProd) => {
    allPopular.push({label:`${popProd.item_id}--${popProd.item_description1}--${popProd.brand}`,value:`${popProd.item_id}`})
   })
   
   let filterProducts = productData.filter((product) =>{
    return !(allPopular.map(item => item.value)).includes(product.item_id)
  });
  const finalAllProducts = filterProducts.map((product) =>`${product.item_id}--${product.item_description1}--${product.brand}`);
  finalAllProducts.forEach((product) => { 
    allProducts.push( { label:product, value:product.split("--")[0] } )
  })    
    setPopularProductsList([allProducts,allPopular]);
  };

  const handleUpdate = () => {  
    let setPopularObj = Object.assign({}, ...(popularProductsList[1].map(item => ({ [item.value]: {item_id:item.value }}) )));
        popular_products_ref.set(setPopularObj);
        getData();
      setShowPopProdsModal(false)
    }
//  -----popular products modal state changes ends ----------------

  useState(() =>{
    console.log("Getting Data"); 
    getData();
  },[])

      // fn to get popular products
    function getData () {
      popular_products_ref.once("value").then((snapshot) =>
     {
      console.log("Popular Products Data")
       if(snapshot.hasChildren()){
        const popularData = snapshot.val();
        let popularKeys = Object.keys(popularData)
        setPopularProducts(popularKeys)
       }
       else{
        setPopularProducts([])
       }
      });
      products_ref.once("value").then((snapshot) =>
        {
          console.log("Products Data");
          if(snapshot.hasChildren() ){
            const tempArr = [];
            snapshot.forEach((child) => {
              const item = child.val();
              if (
                item['active_status'] == 'Y' ||
                item['stock_ind'] == 'Y') {
                tempArr.push(item);
              }
            });
            setProductData(tempArr)
        }
        else{
          setProductData([])
        }
      });
    }

    const notifyUser = (success) => {
      if (success) {
        return toast("Changed the status", { type: toast.TYPE.SUCCESS });
      }
      return toast("Couldn't change the status", { type: toast.TYPE.ERROR });
    };

    const notifyUserWithMessage = (success, message) => {
      if (!message) {
        return console.log("please pass message argument");
      }
      if (success) {
        return toast(message, {
          type: toast.TYPE.SUCCESS,
          style: { width: "100px", color: "red" },
        });
      }
      return toast(message, { type: toast.TYPE.ERROR });
    };

   
    let allProds = [];
    const productsMasterDB = database.database();
    
    
    //  ----------new products modal states ------

    const[showNewProdsModal,setShowNewProdsModal] = useState(false);
    const handleNewProdsClose =() => setShowNewProdsModal(false);
    const handleNewProdsOpen = () => {
      setShowNewProdsModal(true);
        let newProducts =  productData.filter(item=>item.new_product_ind=="Y")
        .map((item) => {return ({label:`${item.item_id}--${item.item_description1}--${item.brand}`,value:item.item_id,new_product_ind:item.new_product_ind})}) 
      let filterProductsFromNewProds = productData.filter((product) => {
        return !(newProducts.map(item =>item.value)).includes(product.item_id)
      })
      console.log(filterProductsFromNewProds);
      const finalAllProds = filterProductsFromNewProds.map((product) =>`${product.item_id}--${product.item_description1}--${product.brand}`);

      finalAllProds.forEach((product) => { 
        allProds.push( { label:product, value:product.split("--")[0] })
      })
      setNewProdsList([allProds,newProducts]);
    }
    const handleNewProdsUpdate = () => {   
           saveNewProds();
          setTimeout(function() {window.location.reload()}, 4000);
      setShowNewProdsModal(false)
    }
    // ------- new products  modal state ends-----
    //  new products end

    // top sellimg products
    const [showTopSellingModal,setShowTopSellingModal] = useState(false);
    const handleTopSellingModalClose = () => setShowTopSellingModal(false);
    let allItemsList = [];
    const handleTopSellingModalOpen = () => {
      // debugger;
      setShowTopSellingModal(true);
      let topSellingProducts = productData.filter((item) => item.top_selling === "Top Selling");

      let topSellingList = topSellingProducts.map((item) => ({
          label: `${item.item_id}--${item.item_description1}--${item.brand}`,
          value: item.item_id,
          top_selling: item.top_selling,
          }));

      let otherProducts = productData.filter((product) =>{
        return !(topSellingList.map(item =>item.value)).includes(product.item_id)
      })
      

      const finalAllProds = otherProducts.map((product) =>`${product.item_id}--${product.item_description1}--${product.brand}`);
      
      finalAllProds.forEach((product) => { 
        allItemsList.push( { label:product, value:product.split("--")[0] })
      })
      setTopProdsList([allItemsList,topSellingList]);

    }

    const handleTopProductsUpdate = () => {   
      saveTopSelling();
     setTimeout(function() {window.location.reload()}, 4000);
 setShowTopSellingModal(false)
}
    
    const saveNewProducts = (series,item_id,new_ind) => {
      
      productsMasterDB.ref(`${PROD_MASTER_TABLE}`)
        .child(series)
        .child(item_id)
        .once("value", (results) => {
          const product = results.val();
          console.log(product)
          if (product) {
            
            productsMasterDB.ref(`${PROD_MASTER_TABLE}`)
              .child(series)
              .child(item_id)
              .update(
                {
                  new_product_ind: new_ind,
                  
                },
                function (error) {
                 
                  if (error) {
                    console.log("Error for the region:: " + series);
                    notifyUser(false);
                  }
                  else {
                    console.log("Updated for the region:: " + series);
                    notifyUserWithMessage(
                      true,
                      "Updated for the region:: " + series
                    );
                  }
                }
              );
          } else {
            
           
            console.log("Product not available:: " + series);
            notifyUserWithMessage(false, "Product not available:: " + series);
          }
        });
    };


   
  

    function saveNewProds(){
      
      let addedItems = newProdsList[1].filter(item => item.new_product_ind=="N"||item.new_product_ind==""||item.new_product_ind==null).map(addedItem =>addedItem.value)
      console.log(addedItems)
 
      let removedItems = newProdsList[0].filter(item=> item.new_product_ind=="Y").map(removedItem=>removedItem.value)
      console.log(removedItems)
  
      
      if(addedItems){
        addedItems.forEach(item =>{

          ALL_REGIONS_LIST.forEach(series =>{
            saveNewProducts(series,item,'Y')
          })

        })
      }
    if(removedItems){

      removedItems.forEach(item =>{

        ALL_REGIONS_LIST.forEach(series =>{
          saveNewProducts(series,item,'N')

        })

      })

    }
    
    }
    

    function saveTopSelling(){
      // debugger;
      let addedItems = topProdsList[1].filter(item => item.top_selling !== "Top Selling" || !item.top_selling).map(addedItem => addedItem.value);
      console.log(addedItems);
      
 
       let removedItems = topProdsList[0].filter(item=> item.top_selling=="Top Selling").map(removedItem=>removedItem.value);
        console.log(removedItems)
  
      let a = ['A']
      if(addedItems){
        addedItems.forEach(item =>{

          ALL_REGIONS_LIST.forEach(series =>{
            saveTopSellingInDb(series,item,'Top Selling')
          })

        })
      }
    if(removedItems){

      removedItems.forEach(item =>{

        ALL_REGIONS_LIST.forEach(series =>{
          saveTopSellingInDb(series,item,'')

        })

      })

    }
    
    }

    const saveTopSellingInDb = (series, item_id, top_ind) => {
      // debugger;
      const productRef = productsMasterDB.ref(`${PROD_MASTER_TABLE}/${series}/${item_id}`);
    
      productRef.once("value", (snapshot) => {
        const product = snapshot.val();
        console.log(product);
        if (product) {
    
          productRef.update({
            top_selling:top_ind
          }, (error) => {
            if (error) {
              console.log("Error for the region:: " + series);
              notifyUser(false);
            } else {
              console.log("Updated for the region:: " + series);
              notifyUserWithMessage(true, "Updated for the region:: " + series);
            }
          });
        } else {
          console.log("Product not available:: " + series);
          // notifyUserWithMessage(false, "Product not available:: " + series);
        }
      });
    };
    
    

  const removeSession = () => {
    sessionStorage.clear();
    history.push("/login");
  };

  const onLogoClick = () => {
    history.push("/Dashboard");
  };



  return (
    <>
      <NavBarContainer>
        <Form>
          <Form.Row>
            <LeftContainer>
              <SZLogoContainer
                onClick={onLogoClick}
                key="0"
                title="Reload Home Page"
              />
            </LeftContainer>
            <RightContainer>
              <UserStatusContainer>
                <button onClick={removeSession}>{userStatusText}</button>
              </UserStatusContainer>
            </RightContainer>
          </Form.Row>
          <Form.Row>  
            <RightContainer>

            </RightContainer>
            <RightContainer>
            </RightContainer>
          </Form.Row>
        </Form>
      </NavBarContainer>
      {(
                <StockContainer>
                  {" "}
                  <Button
                    size="sm"
                    style={{ margin: "3px"}}
                    variant="success"
                    onClick ={handleOpen}
                  >
                    Super Basket
                  </Button>
                  {/* pop prods modal */}
                   <Modal show = {showPopProdsModal} onHide = {handleClose} size="lg">
                     <Modal.Header closeButton>
                      <Modal.Title>Super Basket list</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                      <TransferList
                      value ={popularProductsList}
                      onChange ={setPopularProductsList}
                      searchPlaceholder="Search"
                      nothingFound="Nothing here"
                      titles={["All Products","Popular Products"]}
                      breakpoint="sm"
                      /> 
                     </Modal.Body>
                     <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant ="primary" onClick ={handleUpdate}>
                        Update
                      </Button>
                     </Modal.Footer>
                   </Modal>

                   {/* new prods*/} 
                   <Button
                    size="sm"
                    variant="success"
                    style={{ margin: "3px"}}
                    onClick ={handleNewProdsOpen}
                  >
                    New Products
                  </Button>

                   <Modal show = {showNewProdsModal} onHide = {handleNewProdsClose} size="lg">
                     <Modal.Header closeButton>
                      <Modal.Title>New Products List</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                      <TransferList
                      value ={newProdsList}
                      onChange ={setNewProdsList}
                      searchPlaceholder="Search"
                      nothingFound="Nothing here"
                      titles={["All Products","New Products"]}
                      breakpoint="sm"
                      /> 
                     </Modal.Body>
                     <Modal.Footer>
                      <Button variant="secondary" onClick={handleNewProdsClose}>
                        Close
                      </Button>
                      <Button variant ="primary" onClick ={handleNewProdsUpdate}>
                        Update
                      </Button>
                     </Modal.Footer>
                   </Modal>

                     {/* new prods*/} 
                   <Button
                    size="sm"
                    variant="success"
                    style={{ margin: "3px"}}
                    onClick ={handleTopSellingModalOpen}
                  >
                    ToP Selling Products
                  </Button>

                <Modal show = {showTopSellingModal} onHide = {handleTopSellingModalClose} size="lg">
                     <Modal.Header closeButton>
                      <Modal.Title>Top Products List</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                      <TransferList
                      value ={topProdsList}
                      onChange ={setTopProdsList}
                      searchPlaceholder="Search"
                      nothingFound="Nothing here"
                      titles={["All Products","Top Products"]}
                      breakpoint="sm"
                      /> 
                     </Modal.Body>
                     <Modal.Footer>
                      <Button variant="secondary" onClick={handleTopSellingModalClose}>
                        Close
                      </Button>
                      <Button variant ="primary" onClick ={handleTopProductsUpdate}>
                        Update
                      </Button>
                     </Modal.Footer>
                   </Modal>

                </StockContainer>
              )}
    </>
  );
};

export default Popular;
