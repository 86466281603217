import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import { RETAILER_GROUP_URL } from "./OfferMasterURLs";
import styled from "styled-components";

import { useTable, useGlobalFilter, useFilters } from "react-table";
import RetailerSeriesEditDrawer from "./retailerSeriesEditDrawer";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .summary_header {
    font-size: 1.5rem;
  }
  .loading_text {
    font-size: 1.2rem;
  }
  .export_btn {
    margin-left: 20px;
  }
  .search_container {
    border: 0.1rem solid black;
    padding: 0.3rem;
    margin-bottom: 20px;
    border-radius: 0.3rem;
  }
  .search_bar {
    width: 100%;
    text-decoration: none;
    padding-left: 0.5rem;
  }
  .search_bar:active,
  .search_bar:link,
  .search_bar:focus {
    text-decoration: none;
    outline: none;
  }
`;

const RetailerGroups = (props) => {
  const [retailerGroups, setRetailerGroups] = useState([]);

  const fetchRetailerSeriesDataFromAPI = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(RETAILER_GROUP_URL)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(
            "error occured while atempting to fetch retailer data from API"
          );
        });
    });
  };

  const generateTableData = () => {
    Promise.all([fetchRetailerSeriesDataFromAPI()]).then((values) => {
      generateRetailerGroups(values[0]);
    });
  };

  useEffect(() => {
    generateTableData();
  }, []);

  const generateRetailerGroups = (retailer_groups_obj) => {
    const retailer_groups_new = [];
    if (!retailer_groups_obj) {
      return console.log("Error Missing arguements");
    }

    Object.keys(retailer_groups_obj).forEach((key, index) => {
      retailer_groups_new.push({
        group_name: key,
        group_retailers: retailer_groups_obj[key].join(","),
      });
    });

    setRetailerGroups(retailer_groups_new);
  };

  return (
    <>
      <div>
        <h3
          style={{
            textAlign: "center",
            display: "inline-block",
            marginRight: "2rem",
            marginLeft: "1vw",
            fontWeight: "bold",
          }}
        >
          Retailer groups
        </h3>
        <Button
          size="sm"
          type="button"
          onClick={() => {
            props.history.push("/offersMaster");
          }}
        >
          🠔 Back to offer creation
        </Button>
      </div>

      <Styles>
        <MainTable refreshData={generateTableData} data={retailerGroups} />
      </Styles>
    </>
  );
};

const MainTable = (props) => {
  const [isRetailerSeriesDrawerOpen, setIsRetailerSeriesDrawerOpen] =
    useState(false);
  const [currentSeriesGroupName, setCurrentSeriesGroupName] = useState("");
  const [currentSeriesGroupRetailers, setCurrentSeriesGroupRetailers] =
    useState("");
  const data = props.data;
  const refreshData = props.refreshData;

  //!Due to an issue with the react-table library, Select filter does not work without this dummmy function.
  //!Also this is being passed to useTable
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "GROUP NAME",
        accessor: "group_name",
      },
      {
        Header: "RETAILERS",
        accessor: "group_retailers",
      },
    ],
    []
  );

  const GlobalFilter = useCallback(({ filter, setFilter }) => {
    console.log("running global filter", filter);
    return (
      <div className="search_container">
        <input
          className="search_bar"
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Search..."
        />{" "}
      </div>
    );
  }, []);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetFilters: true,
      autoResetHiddenColumns: true,
      autoResetPage: true,
    },
    useFilters,
    useGlobalFilter
  );

  const { globalFilter } = state;

  const handleOnEditRetailerSeriesClick = (group_name, group_retailers) => {
    setCurrentSeriesGroupName(group_name);
    setCurrentSeriesGroupRetailers(group_retailers);
    setIsRetailerSeriesDrawerOpen(true);
  };

  // Render the UI for your table
  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      {/* //Apply the table props */}
      <table style={{ fontSize: ".85rem" }} {...getTableProps()}>
        <thead>
          {/* // Loop over the header rows */}
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* // Loop over the headers in each row */}
              {headerGroup.headers.map((column) => (
                // Apply the header cell props
                <th {...column.getHeaderProps()}>
                  {/* // Render the header */}
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th>Add more retailers to group</th>
            </tr>
          ))}
        </thead>
        {/*  Apply the table body props  */}
        <tbody {...getTableBodyProps()}>
          {/* // Loop over the table rows */}
          {rows.map((row, i) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {/* // Loop over the rows cells */}
                {row.cells.map((cell) => {
                  // Apply the cell props
                  if (cell.column.id === "group_name") {
                    return (
                      <td
                        style={{ fontWeight: "bold", fontSize: ".9rem" }}
                        {...cell.getCellProps()}
                      >
                        {/* // Render the cell contents */}
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>
                      {/* // Render the cell contents */}
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                <td>
                  <Button
                    size="xs"
                    color="teal"
                    onClick={() => {
                      // console.log("debug", row);
                      handleOnEditRetailerSeriesClick(
                        row.original.group_name,
                        row.original.group_retailers
                      );
                    }}
                  >
                    Edit
                  </Button>
                  {/* <button
                    type="button"
                    onClick={() => {
                      // console.log("debug", row);
                      handleOnEditRetailerSeriesClick(
                        row.original.group_name,
                        row.original.group_retailers
                      );
                    }}
                  >
                    Click{" "}
                  </button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <RetailerSeriesEditDrawer
        isRetailerSeriesDrawerOpen={isRetailerSeriesDrawerOpen}
        currentSeriesGroupName={currentSeriesGroupName}
        currentSeriesGroupRetailers={currentSeriesGroupRetailers}
        setIsRetailerSeriesDrawerOpen={setIsRetailerSeriesDrawerOpen}
        refreshData={refreshData}
      />
    </>
  );
};

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default RetailerGroups;
