// "react-bootstrap": "^1.0.0-beta.16",

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import {
  TransferList,
  Text,
  Checkbox,
  SegmentedControl,
  SimpleGrid,
  MultiSelect,
  TextInput,
  NumberInput,
} from "@mantine/core";

import { Form, Button, Col, InputGroup, Row, Toast } from "react-bootstrap";
import database from "../../../service/firebaseConfig";

import { toast } from "react-toastify";
import { ALL_OFFERS_REGION_LIST } from "../../../constants/allConstants";
import { useDateStyles } from "../utils";

import moment from "moment";
import { RenderFieldsBasedOnSelectedAction } from "./renderOptionalFields/renderFieldsAction";
import { RenderFieldsBasedOnSelectedConditions } from "./renderOptionalFields/renderFieldsConditions";
import RetailerSeriesDrawer from "./RetailerSeriesDrawer";
import Backdrop from "../../../CommonRequirements/backdrop";
import {
  ACTIVE_OFFERS_URL,
  COUPON_CODES_GET_URL,
  RETAILERS_GROUP_URL,
} from "../OfferMasterURLs";
import { validateCouponCode } from "../utils/utils";

const CustomOffer = (props) => {
  const userType = sessionStorage.getItem("Logged User Type");
  const setOfferCategoryChangeDisabled = props.SetOfferCategoryChangeDisabled;
  let dateToday = new Date();

  const [currentStartDate, setCurrentStartDate] = useState(
    new Date(
      dateToday.getFullYear(),
      dateToday.getMonth(),
      dateToday.getDate(),
      0,
      0
    )
  );

  const [currentEndDate, setCurrentEndDate] = useState(
    new Date(
      dateToday.getFullYear(),
      dateToday.getMonth(),
      dateToday.getDate(),
      23,
      59
    )
  );

  const [couponCode, setCouponCode] = useState("");
  const [offerAvailingLimit, setOfferAvailingLimit] = useState("");
  const [greaterThanAmtCondition, setGreaterThanAmtCondition] = useState(false);
  const [minOrderValue, setMinOrderValue] = useState("");
  const [maxOrderValue, setMaxOrderValue] = useState("");
  const [minAmtInCategoryCondition, setMinAmtInCategoryCondition] =
    useState(false);
  const [minCategoryUnits, setMinCategoryUnits] = useState();
  const [minCategoryValue, setMinCategoryValue] = useState();
  const [minCategoryWeight, setMinCategoryWeight] = useState();
  const [minAmtInProductCondition, setMinAmtInProductCondition] =
    useState(false);
  const [minProductUnits, setMinProductUnits] = useState(0);
  const [minProductWeight, setMinProductWeight] = useState(0);
  const [minProductValue, setMinProductValue] = useState(0);
  const [discountType, setDiscountType] = useState("percentDiscount");
  const [productType, setProductType] = useState("productUnits");
  const [categoryQuantityType, setCategoryQuantityType] =
    useState("categoryUnits");
  const [categoryType, setCategoryType] = useState("brandSelection");
  const [excludedProductsData, setExcludedProductsData] = useState([[], []]);
  const [allCouponCodes, setAllCouponCodes] = useState([]);
  const [excludedCouponCodes, setExcludedCouponCodes] = useState([]);

  const [actionTypeSelection, setActionTypeSelection] = useState("instant");

  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [freeProductQty, setFreeProductQty] = useState(1);
  const [freeProductMinQty, setFreeProductMinQty] = useState(1);
  const [freeProductMaxQty, setFreeProductMaxQty] = useState(1);
  const [supercoinsAmt, setSupercoinsAmt] = useState("");

  const [productData, setProductData] = useState([]);
  const [productBrands, setProductBrands] = useState([]);

  const [productSubCategories, setProductSubCategories] = useState([]);
  const [productCatWithSubCat, setProductCatWithSubCat] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [allStoreSeries, setAllStoreSeries] = useState([]);

  const [userSelectedMinProduct, setUserSelectedMinProduct] = useState({});
  const [userSelectedFreeProduct, setUserSelectedFreeProduct] = useState({});
  const [userSelectedMinCategory, setUserSelectedMinCategory] = useState({});
  const [userSelectedMinSubCategory, setUserSelectedMinSubCategory] =
    useState("");
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const [userSelectedMinBrand, setUserSelectedMinBrand] = useState({});
  const [userSelectedRetailerSeries, setUserSelectedRetailerSeries] = useState(
    []
  );
  const [userSelectedStoreSeries, setUserSelectedStoreSeries] = useState(
    ALL_OFFERS_REGION_LIST
  );
  const [retailerSeriesData, setRetailerSeriesData] = useState([]);
  const [isRetailerSeriesDrawerOpen, setIsRetailerSeriesDrawerOpen] =
    useState(false);
  const [isOnlyForNewUsers, setIsOnlyForNewUsers] = useState(false);
  const [isExcludedFromListing, setIsExcludedFromListing] = useState(false);

  const [offerDescription, setOfferDescription] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [isApplicableToAll, setIsApplicableToAll] = useState(false);
  const [isOfferEditMode, setIsOfferEditMode] = useState(false);
  const preExcludedProducts = ["1300110012", "1300120012", "1300120042"];

  const isDateSelectionDisabled = isOfferEditMode && userType !== "admin";

  const defaultStartMinDate = useRef(new Date());

  const { classes, cx } = useDateStyles();

  // const preExcludedProducts = ["1300110012", "1300120012", "1300120042"];

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const fetchProductDataFromDB = () => {
    console.log("fetching product base data");

    return new Promise((resolve, reject) => {
      let product_base_ref = database.database().ref("Product_Base_Data");

      product_base_ref.once(
        "value",
        (snapshot) => {
          let data = snapshot.val();
          console.log("product data", data);
          resolve(data);
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  const fetchAllCouponCodes = () => {
    console.log("fetching coupon codes");

    return new Promise((resolve, reject) => {
      axios
        .get(ACTIVE_OFFERS_URL)
        .then((res) => {
          let data = res.data.data;
          console.log("finished fetching coupon code data from API");
          resolve(data);
        })
        .catch((err) => {
          reject(
            "error occured while atempting to fetch retailer data from API"
          );
        });
    });
  };

  const fetchRetailerSeriesDataFromAPI = () => {
    console.log("fetching retailer series data from API");
    return new Promise((resolve, reject) => {
      axios
        .get(RETAILERS_GROUP_URL)
        .then((res) => {
          let data = res.data.data;
          console.log("finished fetching retailer series data from API");
          resolve(data);
        })
        .catch((err) => {
          reject(
            "error occured while atempting to fetch retailer data from API"
          );
        });
    });
  };

  const fetchAllData = () => {
    console.log("fetch all data start");
    setShowLoadingOverlay(true);
    Promise.all([fetchProductDataFromDB(), fetchAllCouponCodes()]).then(
      (values) => {
        generateFormData(values[0]);
        setShowLoadingOverlay(false);
        console.log("check coupon codes", values[1]);

        const active_coupon_codes = values[1].filter((item) => {
          item.label = item.coupon_code;
          item.value = item.coupon_code;
          return item.is_active === "Y";
        });

        setAllCouponCodes(active_coupon_codes);
        console.log("fetch all data finish");
      }
    );
  };

  const generateFormData = (data) => {
    console.log("starting generateFormData");
    if (!data) {
      return console.log("No data received in generateFormData");
    }
    let formattedData = [];
    let brandsArr = [];
    let brands = [];
    let catWithSubCatDict = {};
    let categories = [];
    let allProducts = [];
    let excludedProducts = [];
    const allExcludedProdsList = [];

    const keyArr = Object.keys(data);
    for (let index = 0; index < keyArr.length; index++) {
      const key = Object.keys(data)[index];
      ////////////////////// ITEM OPTIONS //////////////////
      formattedData.push({
        value: key,
        item_id: key,
        label: `${key} /${data[key].long_description} / ${data[key].brand}`,
      });

      ///////////////////////////////////////////////////////

      //////////////////// PROD CATEGORIES //////////////////
      //Generating Brands, categories and sub-categories

      let item = data[key];
      //Generate Brands
      if (item.brand && item.brand !== "" && !brandsArr.includes(item.brand)) {
        brandsArr.push(item.brand);
        brands.push({ value: item.brand, label: item.brand });
      }

      //Generate Categories along with Sub category list
      if (
        item.category &&
        item.category !== "" &&
        !(item.category in catWithSubCatDict)
      ) {
        categories.push({ value: item.category, label: item.category });
        catWithSubCatDict[item.category] = [item.sub_category];
      } else if (
        item.category &&
        item.category !== "" &&
        item.category in catWithSubCatDict
      ) {
        let arr = catWithSubCatDict[item.category];
        if (
          item.sub_category &&
          item.sub_category !== "" &&
          !arr.includes(item.sub_category)
        ) {
          arr.push(item.sub_category);
        }
        catWithSubCatDict[item.category] = [...arr];
      }

      /////////////////////////////////////////////////////////

      //////////////////// EXCLUDED PRODS /////////////////////
      allExcludedProdsList.push(key);
      if (!preExcludedProducts.includes(key)) {
        allProducts.push({ value: key, label: key });
      } else {
        excludedProducts.push({ value: key, label: key });
      }
      /////////////////////////////////////////////////////////
    }
    setProductData(formattedData);
    setProductCategories(categories);
    setProductBrands(brands);
    setProductCatWithSubCat(catWithSubCatDict);

    setExcludedProductsData([allProducts, excludedProducts]);
    checkForURLSearchParams(
      categories,
      catWithSubCatDict,
      brands,
      allExcludedProdsList
    );
  };

  const generateRetailerSeries = () => {
    console.log("generate retailer series start");
    return new Promise((resolve, reject) => {
      fetchRetailerSeriesDataFromAPI().then((data) => {
        let retailerSeries = [];
        data.forEach((item, index) => {
          retailerSeries.push(item);
        });

        setRetailerSeriesData(retailerSeries);
        console.log("generate retailer series finish");
        resolve();
      });
    });
  };

  const checkForURLSearchParams = (
    prodCategories,
    prodSubCategories,
    prodBrands,
    allExcludedProdsList
  ) => {
    const queryString = props.location.search;
    if (!queryString || queryString === "") {
      setIsOfferEditMode(false);
      return;
    }
    const urlParams = new URLSearchParams(queryString);
    for (var key of urlParams.keys()) {
      console.log("check urlParam ", key, urlParams.get(key));
    }
    const action_param = urlParams.get("mode");
    const offer_desc_param = urlParams.get("offer_desc");
    const offer_start_raw_param = urlParams.get("start");
    const offer_end_raw_param = urlParams.get("end");
    let offer_date_st = offer_start_raw_param.split(" ");
    const offer_start_time_raw = offer_date_st[1];
    const offer_start_hours = offer_start_time_raw.split(":")[0];
    const offer_start_minutes = offer_start_time_raw.split(":")[1];

    offer_date_st = offer_date_st[0].split("-");
    let offer_date_en = offer_end_raw_param.split(" ");

    const offer_end_time_raw = offer_date_en[1];

    const offer_end_hours = offer_end_time_raw.split(":")[0];
    const offer_end_minutes = offer_end_time_raw.split(":")[1];

    offer_date_en = offer_date_en[0].split("-");

    const offer_start = new Date(
      offer_date_st[0],
      Number(offer_date_st[1]) - 1,
      offer_date_st[2],
      offer_start_hours,
      offer_start_minutes
    );
    const offer_end = new Date(
      offer_date_en[0],
      Number(offer_date_en[1]) - 1,
      offer_date_en[2],
      offer_end_hours,
      offer_end_minutes
    );
    const avail_limit_param = urlParams.get("limit");
    const coupon_code_param = urlParams.get("coupon_code");
    const excluded_products_params = urlParams.get("excluded_prods")
      ? urlParams.get("excluded_prods").split(",")
      : [];
    const excluded_coupon_codes_params = urlParams.get("excluded_coupon_codes")
      ? urlParams.get("excluded_coupon_codes").split(",")
      : [];

    const retailer_series_params = urlParams.get("retail_series").split(",");
    const store_series_params = urlParams.get("store_series").split(",");
    const applicable_to_all_params = urlParams.get("applicable_to_all");
    const only_new_cust = urlParams.get("only_new_cust");
    const excluded_from_listing_params = urlParams.get("excluded_from_listing");

    let actionTypeSelectionSt = "";
    let discountTypeSt = "";
    let discountPercentageSt = "";
    let discountValueSt = "";
    let userSelectedFreeProductSt = "";
    let freeProductQtySt = "";
    let freeProductMaxQtySt = "";
    let freeProductMinQtySt = "";
    let supercoinsAmtSt = "";
    let greaterThanAmtConditionSt = "";
    let minOrderValueSt = "";
    let maxOrderValueSt = "";
    let minAmtInCategoryConditionSt = "";
    let categoryTypeSt = "";
    let userSelectedMinBrandSt = "";
    let categoryQuantityTypeSt = "";
    let minCategoryValueSt = "";
    let minCategoryWeightSt = "";
    let minCategoryUnitsSt = "";
    let userSelectedMinCategorySt = "";
    let productSubCategoriesSt = [];
    let userSelectedMinSubCategorySt = "";
    let minAmtInProductConditionSt = "";
    let userSelectedMinProductSt = "";
    let productTypeSt = "";
    let minProductValueSt = "";
    let minProductUnitsSt = "";
    let minProductWeightSt = "";
    let isApplicableToAllSt = false;
    let isOnlyForNewUsersSt = false;
    let isExcludedFromListingSt = false

    ///-----------------------Actions
    if (action_param === "instant" || action_param === "cashback") {
      let discount_percent_param = urlParams.get("disc_percent");
      let discount_value_param = urlParams.get("disc_value");

      actionTypeSelectionSt = action_param;

      if (
        discount_percent_param &&
        discount_percent_param !== "" &&
        discount_percent_param !== "null" &&
        discount_percent_param !== "0"
      ) {
        discountTypeSt = "percentDiscount";
        discountPercentageSt = Number(discount_percent_param);
      } else if (
        discount_value_param &&
        discount_value_param !== "" &&
        discount_value_param !== "null" &&
        discount_value_param !== "0"
      ) {
        discountTypeSt = "valueDiscount";
        discountValueSt = Number(discount_value_param);
      }
    }
    if (action_param === "freeproduct") {
      actionTypeSelectionSt = "freeProduct";
      let free_product_param = urlParams.get("free_prod");
      let free_product_quantity_param = urlParams.get("free_prod_qty");
      let free_product_max_param = urlParams.get("free_prod_max");
      let free_product_min_param = urlParams.get("free_prod_min");
      userSelectedFreeProductSt = free_product_param;
      freeProductQtySt =
        free_product_quantity_param === "null"
          ? 0
          : Number(free_product_quantity_param);
      freeProductMaxQtySt =
        free_product_max_param === "null" ? 0 : Number(free_product_max_param);
      freeProductMinQtySt =
        free_product_min_param === "null" ? 0 : Number(free_product_min_param);
    }
    if (action_param === "supercoins") {
      actionTypeSelectionSt = action_param;
      let supercoin_amt_param = urlParams.get("supercoin_amt");
      supercoinsAmtSt = Number(supercoin_amt_param);
    }
    ///---------------------

    ///-------------------- Conditions
    const min_order_value_param = urlParams.get("min_order_val");
    const max_order_value_param = urlParams.get("max_order_val");
    const min_category_param = urlParams.get("min_cat");
    const min_prod_param = urlParams.get("min_prod");

    const min_subcategory_param = urlParams.get("min_sub_cat");
    const min_category_value_param = urlParams.get("min_cat_value");
    const min_category_weight_param = urlParams.get("min_cat_weight");
    const min_category_units_param = urlParams.get("min_cat_units");
    const min_prod_value_param = urlParams.get("min_prod_value");
    const min_prod_units_param = urlParams.get("min_prod_units");
    const min_prod_weight_param = urlParams.get("min_prod_weight");

    if (
      min_order_value_param &&
      min_order_value_param !== "" &&
      min_order_value_param !== "null" &&
      min_order_value_param !== "0"
    ) {
      greaterThanAmtConditionSt = true;
      minOrderValueSt = Number(min_order_value_param);
      maxOrderValueSt =
        max_order_value_param === "null" || !max_order_value_param
          ? maxOrderValueSt
          : max_order_value_param;

      // maxOrderValueSt =
      //   max_order_value_param !== "null"
      //     ? Number(max_order_value_param)
      //     : minOrderValueSt;
    }
    if (
      min_category_param &&
      min_category_param !== "" &&
      min_category_param !== "null"
    ) {
      minAmtInCategoryConditionSt = true;
      if (
        prodBrands.some((element) => {
          return element.value === min_category_param;
        })
      ) {
        categoryTypeSt = "brandSelection";
        userSelectedMinBrandSt = min_category_param;
        if (
          min_category_value_param &&
          min_category_value_param !== "" &&
          min_category_value_param !== "null" &&
          min_category_value_param !== "0"
        ) {
          categoryQuantityTypeSt = "categoryValue";
          minCategoryValueSt = Number(min_category_value_param);
        } else if (
          min_category_weight_param &&
          min_category_weight_param !== "" &&
          min_category_weight_param !== "null" &&
          min_category_weight_param !== "0"
        ) {
          categoryQuantityTypeSt = "categoryWeight";
          minCategoryWeightSt = Number(min_category_weight_param);
        } else if (
          min_category_units_param &&
          min_category_units_param !== "" &&
          min_category_units_param !== "null" &&
          min_category_units_param !== "0"
        ) {
          categoryQuantityTypeSt = "categoryUnits";
          minCategoryUnitsSt = Number(min_category_units_param);
        }
      } else if (
        prodCategories.some((element) => {
          return element.value === min_category_param;
        })
      ) {
        categoryTypeSt = "categorySelection";
        userSelectedMinCategorySt = min_category_param;

        let formattedSubCategories = getUISubCatArr(
          prodSubCategories,
          min_category_param
        );

        productSubCategoriesSt = formattedSubCategories;
        if (
          min_subcategory_param &&
          min_subcategory_param !== "" &&
          min_subcategory_param !== "null"
        ) {
          userSelectedMinSubCategorySt = min_subcategory_param;
        }
        if (
          min_category_value_param &&
          min_category_value_param !== "" &&
          min_category_value_param !== "null" &&
          min_category_value_param !== "0"
        ) {
          categoryQuantityTypeSt = "categoryValue";
          minCategoryValueSt = Number(min_category_value_param);
        } else if (
          min_category_weight_param &&
          min_category_weight_param !== "" &&
          min_category_weight_param !== "null" &&
          min_category_weight_param !== "0"
        ) {
          categoryQuantityTypeSt = "categoryWeight";
          minCategoryWeightSt = Number(min_category_weight_param);
        } else if (
          min_category_units_param &&
          min_category_units_param !== "" &&
          min_category_units_param !== "null" &&
          min_category_units_param !== "0"
        ) {
          categoryQuantityTypeSt = "categoryUnits";
          minCategoryUnitsSt = Number(min_category_units_param);
        }
      }
    }
    if (min_prod_param && min_prod_param !== "" && min_prod_param !== "null") {
      minAmtInProductConditionSt = true;
      userSelectedMinProductSt = min_prod_param.split(",");

      if (
        min_prod_value_param &&
        min_prod_value_param !== "" &&
        min_prod_value_param !== "null" &&
        min_prod_value_param !== "0"
      ) {
        productTypeSt = "productValue";
        minProductValueSt = Number(min_prod_value_param);
      } else if (
        min_prod_weight_param &&
        min_prod_weight_param !== "" &&
        min_prod_weight_param !== "null" &&
        min_prod_weight_param !== "0"
      ) {
        productTypeSt = "productWeight";
        minProductWeightSt = Number(min_prod_weight_param);
      } else if (
        min_prod_units_param &&
        min_prod_units_param !== "" &&
        min_prod_units_param !== "null" &&
        min_prod_units_param !== "0"
      ) {
        productTypeSt = "productUnits";
        minProductUnitsSt = Number(min_prod_units_param);
      }
    }

    /////////////////////

    if (applicable_to_all_params === "Y") {
      isApplicableToAllSt = true;
    }
    if (only_new_cust === "Y") {
      isOnlyForNewUsersSt = true;
    }
    if (excluded_from_listing_params === "Y") {
      isExcludedFromListingSt = true;
    }

    setActionTypeSelection(actionTypeSelectionSt);
    setDiscountType(discountTypeSt);
    setDiscountPercentage(discountPercentageSt);
    setDiscountValue(discountValueSt);
    setUserSelectedFreeProduct(userSelectedFreeProductSt);
    setFreeProductQty(freeProductQtySt);
    setFreeProductMaxQty(freeProductMaxQtySt);
    setFreeProductMinQty(freeProductMinQtySt);
    setSupercoinsAmt(supercoinsAmtSt);
    setGreaterThanAmtCondition(greaterThanAmtConditionSt);
    setMinOrderValue(minOrderValueSt);
    setMaxOrderValue(maxOrderValueSt);
    setMinAmtInCategoryCondition(minAmtInCategoryConditionSt);
    setCategoryType(categoryTypeSt);
    setUserSelectedMinBrand(userSelectedMinBrandSt);
    setCategoryQuantityType(categoryQuantityTypeSt);
    setMinCategoryValue(minCategoryValueSt);
    setMinCategoryWeight(minCategoryWeightSt);
    setMinCategoryUnits(minCategoryUnitsSt);
    setUserSelectedMinCategory(userSelectedMinCategorySt);
    setProductSubCategories(productSubCategoriesSt);
    setUserSelectedMinSubCategory(userSelectedMinSubCategorySt);
    setMinAmtInProductCondition(minAmtInProductConditionSt);
    setUserSelectedMinProduct(userSelectedMinProductSt);
    setProductType(productTypeSt);
    setMinProductValue(minProductValueSt);
    setMinProductWeight(minProductWeightSt);
    setMinProductUnits(minProductUnitsSt);
    setIsApplicableToAll(isApplicableToAllSt);
    setIsOnlyForNewUsers(isOnlyForNewUsersSt);
    setIsExcludedFromListing(isExcludedFromListingSt)

    setOfferDescription(offer_desc_param);
    setCouponCode(coupon_code_param);
    setOfferAvailingLimit(Number(avail_limit_param));
    setExcludedProductsData([
      allExcludedProdsList
        .filter((item) => !excluded_products_params.includes(item))
        .map((item) => ({ value: item, label: item })),
      excluded_products_params.map((item) => ({ value: item, label: item })),
    ]);
    console.log("setting excluded coupon codes", excluded_coupon_codes_params);
    setExcludedCouponCodes(excluded_coupon_codes_params);

    setUserSelectedRetailerSeries(retailer_series_params);
    setUserSelectedStoreSeries(store_series_params);
    setCurrentStartDate(offer_start);
    setCurrentEndDate(offer_end);
    defaultStartMinDate.current = offer_start;
    setOfferCategoryChangeDisabled(true);
    setIsOfferEditMode(true);
  };

  const getUISubCatArr = (subCatDict, selectedCat) => {
    let availableSubCategories = subCatDict[selectedCat] || [];
    // console.log(
    //   "setting availableSubCategories to ",
    //   subCatDict,
    //   min_subcategory_param
    // );
    let formattedSubCategories = [];

    availableSubCategories.forEach((item, index) => {
      if (item) {
        formattedSubCategories.push({ value: item, label: item });
      }
    });

    return formattedSubCategories;
  };

  useEffect(() => {
    fetchAllData();
    generateRetailerSeries();
    generateUIStoreSeries(ALL_OFFERS_REGION_LIST);
  }, []);

  // useEffect(() => {
  //   setUserSelectedMinProduct({});
  // }, [minAmtInProductCondition]);

  useEffect(() => {
    setUserSelectedRetailerSeries([]);
    setUserSelectedStoreSeries(ALL_OFFERS_REGION_LIST);
  }, [isApplicableToAll]);

  // useEffect(() => {
  //   setUserSelectedMinBrand({});
  //   setUserSelectedMinCategory({});
  //   setUserSelectedMinSubCategory({});
  // }, [minAmtInCategoryCondition]);

  // useEffect(() => {
  //   if (actionTypeSelection === "freeProduct") {
  //     setUserSelectedFreeProduct({});
  //   }
  // }, [actionTypeSelection]);

  const handleOnCategoryTypeChange = (selectedItem) => {
    setCategoryType(selectedItem);
    // setUserSelectedMinBrand({});
    // setUserSelectedMinCategory({});
    // setUserSelectedMinSubCategory({});
  };

  const handleOnMinProductSelect = (selectedItem) => {
    if (!selectedItem) {
      setUserSelectedMinProduct({});
      return;
    }
    console.log("selected product", selectedItem);
    setUserSelectedMinProduct(selectedItem);
  };

  const handleOnFreeProductSelect = (selectedItem) => {
    if (!selectedItem) {
      setUserSelectedFreeProduct({});
      console.log(selectedItem);
      return;
    }
    setUserSelectedFreeProduct(selectedItem);
  };

  const handleOnCategorySelect = (selectedItem, categoryType) => {
    console.log("running handleOnCategorySelect", selectedItem);
    if (!selectedItem) {
      if (categoryType === "brand") {
        return setUserSelectedMinBrand({});
      } else if (categoryType === "category") {
        setUserSelectedMinSubCategory("");
        setProductSubCategories([]);
        setUserSelectedMinCategory({});
        return;
      } else if (categoryType === "subCategory") {
        return setUserSelectedMinSubCategory("");
      }
    }

    if (categoryType === "brand") {
      setUserSelectedMinBrand(selectedItem);
    } else if (categoryType === "category") {
      console.log("check productCatWithSubCat", productCatWithSubCat);
      let availableSubCategories = productCatWithSubCat[selectedItem] || [];
      let formattedSubCategories = [];

      availableSubCategories.forEach((item, index) => {
        if (item) {
          formattedSubCategories.push({ value: item, label: item });
        }
      });
      setUserSelectedMinCategory(selectedItem);
      setUserSelectedMinSubCategory("");
      setProductSubCategories(formattedSubCategories);
    } else if (categoryType === "subCategory") {
      setUserSelectedMinSubCategory(selectedItem);
    }
  };

  const handleCreateRetailerGrpClick = (event) => {
    setIsRetailerSeriesDrawerOpen(true);
  };

  const handleNewRetailerSeriesCreated = (newRetailerSeriesId) => {
    generateRetailerSeries().then(() => {
      if (!newRetailerSeriesId) {
        return console.log("Did not receive new retailer name ");
      }

      let userRetailSeries = [...userSelectedRetailerSeries];
      if (!userRetailSeries.includes(newRetailerSeriesId)) {
        userRetailSeries.push(newRetailerSeriesId);
        setUserSelectedRetailerSeries(userRetailSeries);
      }
    });
  };

  const getActionFields = (actionType, formData) => {
    let dataToReturn = {};
    if (actionType === "instant") {
      if (discountType === "percentDiscount") {
        dataToReturn["discount_percent"] = formData.instantDiscountPercent;
      } else if (discountType === "valueDiscount") {
        dataToReturn["discount_value"] = formData.instantDiscountValue;
      }
    } else if (actionType === "cashback") {
      if (discountType === "percentDiscount") {
        dataToReturn["discount_percent"] = formData.cashbackDiscountPercent;
      } else if (discountType === "valueDiscount") {
        dataToReturn["discount_value"] = formData.cashbackDiscountValue;
      }
    } else if (actionType === "freeProduct") {
      dataToReturn = {
        offer_product: userSelectedFreeProduct || "",
        offer_product_unit: formData.freeProductQuantity,
        minimum_product_unit: formData.freeProductMinQuantity,
        maximum_product_unit: formData.freeProductMaxQuantity,
      };
    } else if (actionType === "supercoins") {
      dataToReturn = {
        supercoins: formData.supercoinsAmt,
      };
    }
    return dataToReturn;
  };

  const getConditionFields = (formData) => {
    const dataToReturn = {};

    if (greaterThanAmtCondition) {
      if (formData.orderValueConditionInput) {
        dataToReturn["minimum_order_value"] = formData.orderValueConditionInput;
        dataToReturn["maximum_order_value"] =
          formData.orderValueMaxConditionInput
            ? Number(formData.orderValueMaxConditionInput)
            : "";
      }
    }

    if (minAmtInCategoryCondition) {
      if (categoryType === "brandSelection") {
        dataToReturn["category"] = userSelectedMinBrand;
      } else if (categoryType === "categorySelection") {
        dataToReturn["category"] = userSelectedMinCategory || "";
        dataToReturn["sub_category"] = userSelectedMinSubCategory || "";
      }

      if (categoryQuantityType === "categoryUnits") {
        dataToReturn["minimum_category_unit"] = formData.categoryUnitsInput;
      } else if (categoryQuantityType === "categoryWeight") {
        dataToReturn["minimum_category_weight"] = formData.categoryWeightInput;
      } else if (categoryQuantityType === "categoryValue") {
        dataToReturn["minimum_category_value"] = formData.categoryValueInput;
      }
    }

    if (minAmtInProductCondition) {
      if (userSelectedMinProduct) {
        dataToReturn["minimum_buy_product"] = userSelectedMinProduct.join(",");
      }
      if (productType === "productUnits") {
        dataToReturn["minimum_buy_product_unit"] = formData.productUnitsInput;
      } else if (productType == "productWeight") {
        dataToReturn["minimum_buy_product_weight"] =
          formData.productWeightInput;
      } else if (productType == "productValue") {
        dataToReturn["minimum_buy_product_value"] = formData.productValueInput;
      }
    }

    return dataToReturn;
  };
  const generateUIStoreSeries = (store_series_arr) => {
    // console.log("received arguments", store_series_arr);
    const UI_store_series = [];
    store_series_arr.forEach((item) => {
      UI_store_series.push({ value: item, label: `Series ${item}` });
    });

    setAllStoreSeries(UI_store_series);
  };

  const renderFormSubmitErrorToast = (msg = "") => {
    setIsSubmitBtnDisabled(false);
    toast(msg, {
      type: toast.TYPE.WARNING,
    });
  };

  const formatData = (data) => {
    let dataToSubmit = {};
    if (!data) {
      return console.log("No data received for formatData");
    }

    let store_series = [];
    let unformattedStoreSeries = userSelectedStoreSeries;
    if (unformattedStoreSeries) {
      unformattedStoreSeries.forEach((item, index) => {
        store_series.push(item);
      });
    }

    let retailer_series = [];
    let unformattedRetailerSeries = userSelectedRetailerSeries;
    if (unformattedRetailerSeries) {
      unformattedRetailerSeries.forEach((item, index) => {
        retailer_series.push(item);
      });
    }

    let excludedProducts = [];
    let unformattedExcludedProducts = excludedProductsData[1];

    if (unformattedExcludedProducts) {
      unformattedExcludedProducts.forEach((item, index) => {
        excludedProducts.push(Number(item.value));
      });
    }

    const mysql_offer_start_date =
      moment(currentStartDate).format("YYYY-MM-DD HH:mm");

    const mysql_offer_end_date =
      moment(currentEndDate).format("YYYY-MM-DD HH:mm");

    dataToSubmit = {
      offer_description: data.offerDescription,
      coupon_code: data.couponCode,
      excluded_products: excludedProducts,
      excluded_coupon_codes: excludedCouponCodes.join(","),
      mode: actionTypeSelection,
      action: getActionFields(actionTypeSelection, data),
      condition: getConditionFields(data),
      offer_availing_limit: data.offerAvailingLimit,
      store_series: store_series.join(),
      retailer_series: retailer_series.join(),
      offer_start_date: moment(currentStartDate).utc().unix(),
      offer_end_date: moment(currentEndDate).utc().unix(),
      mysql_offer_start_date,
      mysql_offer_end_date,
      created_by: sessionStorage.getItem("Logged User Email Id"),
      created_at: Date.now(),
      applicable_to_all: isApplicableToAll ? "Y" : "N",
      only_for_new_customers: isOnlyForNewUsers,
      excluded_from_listing : isExcludedFromListing ? "Y" : "N",

    };

    console.log("submitted data", dataToSubmit);
    props
      .submitDataToApi(dataToSubmit, "custom", isOfferEditMode)
      .then(() => {
        setTimeout(() => {
          props.history.push("/offersMaster");
          window.location.reload();
        }, 4000);
        setIsSubmitBtnDisabled(false);
      })
      .catch((err) => {
        setIsSubmitBtnDisabled(false);
      });
  };

  const handleSubmit = (event) => {
    setIsSubmitBtnDisabled(true);
    event.preventDefault();

    if (!currentEndDate || !currentStartDate) {
      return renderFormSubmitErrorToast("Start date and End date is required");
    }

    if (moment(currentEndDate).isBefore(currentStartDate)) {
      return renderFormSubmitErrorToast(
        "Offer end date cannot be sooner than the Offer Start date"
      );
    }
    if (minAmtInProductCondition) {
      if (Object.keys(userSelectedMinProduct).length === 0) {
        return renderFormSubmitErrorToast(
          "Please select a product for min product condition"
        );
      }

      if (productType === "productUnits" && minProductUnits === 0) {
        return renderFormSubmitErrorToast("Product units cannot be 0");
      } else if (productType === "productValue" && minProductValue === 0) {
        return renderFormSubmitErrorToast("Product value cannot be 0");
      }
    }

    if (
      actionTypeSelection === "freeProduct" &&
      Object.keys(userSelectedFreeProduct).length === 0
    ) {
      return renderFormSubmitErrorToast("Please select a free product");
    }

    let storeSeriesRaw = userSelectedStoreSeries;
    let retailerSeriesRaw = userSelectedRetailerSeries;

    if (
      storeSeriesRaw.value === "" &&
      !storeSeriesRaw.length &&
      retailerSeriesRaw.length <= 0
    ) {
      return renderFormSubmitErrorToast(
        "Both store series selection and retailer series selection cannot be empty"
      );
    }

    if (minAmtInCategoryCondition) {
      if (
        categoryType === "brandSelection" &&
        Object.keys(userSelectedMinBrand).length === 0
      ) {
        return renderFormSubmitErrorToast("Please select a brand");
      }
    }

    if (
      !minAmtInProductCondition &&
      !minAmtInCategoryCondition &&
      !greaterThanAmtCondition
    ) {
      return renderFormSubmitErrorToast(
        "Offer cannot be applied without any conditions"
      );
    }
    if (minAmtInCategoryCondition) {
      if (
        categoryType === "categorySelection" &&
        Object.keys(userSelectedMinCategory).length === 0 &&
        Object.keys(userSelectedMinSubCategory).length === 0
      ) {
        return renderFormSubmitErrorToast(
          "Please select a category or subcategory"
        );
      }
    }

    const elements = event.target.elements;

    if (Number(freeProductMinQty) > Number(freeProductMaxQty)) {
      return renderFormSubmitErrorToast(
        "Free product min quantity cannot be greater than the max quantity"
      );
    }

    const data = {};
    data["couponCode"] = couponCode || null;
    data["offerAvailingLimit"] = offerAvailingLimit || null;
    data["offerDescription"] = offerDescription || null;

    //Action Fields

    if (actionTypeSelection === "instant") {
      data["instantDiscountPercent"] = discountPercentage || null;

      data["instantDiscountValue"] = discountValue || null;
    } else if (actionTypeSelection === "cashback") {
      data["cashbackDiscountPercent"] = discountPercentage || null;
      data["cashbackDiscountValue"] = discountValue || null;
    }

    data["supercoinsAmt"] = supercoinsAmt || null;
    data["freeProductQuantity"] = freeProductQty || null;
    data["freeProductMinQuantity"] = freeProductMinQty || 0;
    data["freeProductMaxQuantity"] = freeProductMaxQty || null;
    //-------------------------------------------

    //Condition Fields

    data["orderValueConditionInput"] = minOrderValue || null;
    data["orderValueMaxConditionInput"] = maxOrderValue || null;
    data["categoryUnitsInput"] = minCategoryUnits || null;
    data["categoryValueInput"] = minCategoryValue || null;
    data["categoryWeightInput"] = minCategoryWeight || null;
    data["productUnitsInput"] = minProductUnits || null;
    data["productValueInput"] = minProductValue || null;
    data["productWeightInput"] = minProductWeight || null;

    //-------------------------------------------
    // setFormData(data);
    formatData(data);
  };

  // const CustomDateInput = ({ value, onClick }) => (
  //   <input
  //     value={value}
  //     onClick={onClick}
  //     style={{ border: "solid 1px black", padding: 5 }}
  //   />
  // );

  return (
    <>
      {showLoadingOverlay ? (
        <Backdrop parentLoadStatus={showLoadingOverlay} />
      ) : null}
      {/* {!productData ? (
        <Loader color="orange" size="lg" />
      ) : !productCategories ? (
        <Loader color="orange" size="lg" />
      ) : (
        <> */}

      {/* <Grid justify="center" style={{ textAlign: "center" }}>
        <Grid.Col span={3}>1</Grid.Col>
        <Grid.Col span={3}>2</Grid.Col>
      </Grid> */}
      {/* <div className="offerMasterFormTitle">Create new customer Offer</div> */}
      <RetailerSeriesDrawer
        isRetailerSeriesDrawerOpen={isRetailerSeriesDrawerOpen}
        setIsRetailerSeriesDrawerOpen={setIsRetailerSeriesDrawerOpen}
        handleNewRetailerSeriesCreated={handleNewRetailerSeriesCreated}
      />
      <form
        style={{ textAlign: "center", width: "100vw", marginTop: "10px" }}
        onSubmit={handleSubmit}
      >
        {isOfferEditMode ? (
          <div>
            {" "}
            <div
              className="OfferEditMode__ReturnText"
              onClick={(event) => {
                props.history.push("/offersMaster");
                window.location.reload();
              }}
            >
              <b>Offer Edit mode</b> <br />
              Click to return to create mode
            </div>
          </div>
        ) : (
          ""
        )}

        <Row>
          <Col>
            <br />
            <Text className="required" size="sm">
              Offer Start
            </Text>
            <InputGroup
              style={{
                padding: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <InputGroup.Prepend>
                <InputGroup.Text id="active-flag">Offer Start</InputGroup.Text>
              </InputGroup.Prepend> */}
              <Datetime
                timeFormat={"HH:mm"}
                dateFormat={"DD-MM-YYYY"}
                value={currentStartDate}
                onChange={(date) => {
                  setCurrentStartDate(date._d);
                }}
              />
            </InputGroup>
          </Col>
          <Col>
            <br />
            <Text className="required" size="sm">
              Offer End
            </Text>
            <InputGroup
              style={{
                padding: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Datetime
                timeFormat={"HH:mm"}
                value={currentEndDate}
                dateFormat={"DD-MM-YYYY"}
                onChange={(date) => {
                  setCurrentEndDate(date._d);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <fieldset disabled={isOfferEditMode}>
              <Form.Group
                controlId="couponCode"
                style={{
                  display: "block",
                  width: "500px",
                  margin: "auto",
                  paddingBottom: "40px",
                }}
              >
                {/* <Form.Label>Coupon Code</Form.Label> */}
                <TextInput
                  name="couponCode"
                  label="Coupon Code"
                  description="Coupon code for this offer"
                  value={couponCode}
                  required
                  onChange={(event) => {
                    const input = event.currentTarget.value;

                    const codeValidation = validateCouponCode(input);

                    if (codeValidation.isValid) {
                      setCouponCode(input);
                    } else {
                      return toast(`Coupon code : ${codeValidation.message}`, {
                        type: toast.TYPE.WARNING,
                      });
                    }
                  }}
                />
              </Form.Group>
            </fieldset>
          </Col>
          <Col>
            <Form.Group
              controlId="offerAvailingLimit"
              style={{
                display: "block",
                margin: "auto",
                width: "500px",
                textAlign: "center",
                paddingBottom: "40px",
              }}
            >
              {/* <Form.Label>Offer Availing Limit</Form.Label> */}
              <NumberInput
                name="offerAvailingLimit"
                value={offerAvailingLimit}
                onChange={(val) => {
                  setOfferAvailingLimit(val);
                }}
                required
                label="Offer Availing Limit"
                description="How many times this offer can be availed"
                hideControls
              />
              {/* <Form.Control type="number" name="offerAvailingLimit" required /> */}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="offerMasterFormFieldsHeadingMain">
              Select Offer Action (Mode)
            </div>
            <SegmentedControl
              size="lg"
              value={actionTypeSelection}
              style={{ width: "800px" }}
              data={[
                { label: "Instant", value: "instant" },
                { label: "Cashback", value: "cashback" },
                { label: "Free Product", value: "freeProduct" },
                { label: "Supercoins", value: "supercoins" },
              ]}
              onChange={(value) => {
                setActionTypeSelection(value);
              }}
              required
            />
            <br />
            <br />
          </Col>
        </Row>

        <RenderFieldsBasedOnSelectedAction
          actionTypeSelection={actionTypeSelection}
          discountType={discountType}
          setDiscountType={setDiscountType}
          productData={productData}
          handleOnFreeProductSelect={handleOnFreeProductSelect}
          userSelectedFreeProduct={userSelectedFreeProduct}
          discountPercentage={discountPercentage}
          setDiscountPercentage={setDiscountPercentage}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          freeProductQty={freeProductQty}
          setFreeProductQty={setFreeProductQty}
          freeProductMinQty={freeProductMinQty}
          setFreeProductMinQty={setFreeProductMinQty}
          freeProductMaxQty={freeProductMaxQty}
          setFreeProductMaxQty={setFreeProductMaxQty}
          supercoinsAmt={supercoinsAmt}
          setSupercoinsAmt={setSupercoinsAmt}
        />

        <Row
          style={{
            margin: "auto",
            paddingBottom: "40px",
          }}
        >
          <Col>
            <div className="offerMasterFormFieldsHeadingMain">
              Apply Conditions on the selected offer action (minimum one)
            </div>
            <SimpleGrid cols={3}>
              <div>
                {" "}
                <Checkbox
                  label="If order total is greater than {amount}."
                  size="md"
                  checked={greaterThanAmtCondition}
                  color="orange"
                  style={{ justifyContent: "center" }}
                  onChange={(event) =>
                    setGreaterThanAmtCondition(event.currentTarget.checked)
                  }
                />
              </div>
              <div>
                {" "}
                <Checkbox
                  label="Buy at least minimum products in a category/ sub-category."
                  size="md"
                  checked={minAmtInCategoryCondition}
                  color="teal"
                  style={{ justifyContent: "center" }}
                  onChange={(event) =>
                    setMinAmtInCategoryCondition(event.currentTarget.checked)
                  }
                />
              </div>
              <div>
                {" "}
                <Checkbox
                  label="Buy at least minimum specific product {amount}."
                  size="md"
                  checked={minAmtInProductCondition}
                  color="grape"
                  style={{ justifyContent: "center" }}
                  onChange={(event) =>
                    setMinAmtInProductCondition(event.currentTarget.checked)
                  }
                />
              </div>
            </SimpleGrid>
          </Col>
        </Row>

        <RenderFieldsBasedOnSelectedConditions
          greaterThanAmtCondition={greaterThanAmtCondition}
          minOrderValue={minOrderValue}
          setMinOrderValue={setMinOrderValue}
          maxOrderValue={maxOrderValue}
          setMaxOrderValue={setMaxOrderValue}
          minAmtInCategoryCondition={minAmtInCategoryCondition}
          minCategoryUnits={minCategoryUnits}
          setMinCategoryUnits={setMinCategoryUnits}
          minCategoryValue={minCategoryValue}
          minCategoryWeight={minCategoryWeight}
          setMinCategoryValue={setMinCategoryValue}
          setMinCategoryWeight={setMinCategoryWeight}
          minProductUnits={minProductUnits}
          setMinProductUnits={setMinProductUnits}
          minProductValue={minProductValue}
          setMinProductValue={setMinProductValue}
          minProductWeight={minProductWeight}
          setMinProductWeight={setMinProductWeight}
          minAmtInProductCondition={minAmtInProductCondition}
          productBrands={productBrands}
          productSubCategories={productSubCategories}
          productCategories={productCategories}
          categoryType={categoryType}
          categoryQuantityType={categoryQuantityType}
          setCategoryQuantityType={setCategoryQuantityType}
          productData={productData}
          userSelectedMinBrand={userSelectedMinBrand}
          userSelectedMinCategory={userSelectedMinCategory}
          userSelectedMinSubCategory={userSelectedMinSubCategory}
          userSelectedMinProduct={userSelectedMinProduct}
          handleOnCategorySelect={handleOnCategorySelect}
          handleOnMinProductSelect={handleOnMinProductSelect}
          handleOnCategoryTypeChange={handleOnCategoryTypeChange}
          productType={productType}
          setProductType={setProductType}
        />

        <ExcludedProductsSelect
          excludedProductsData={excludedProductsData}
          setExcludedProductsData={setExcludedProductsData}
        />
        <Row>
          <Col>
            <Form.Group
              controlId="offerDescription"
              style={{
                display: "inline-block",
                width: "650px",
                textAlign: "center",
                paddingBottom: "20px",
              }}
            >
              {/* <Form.Label>Offer Description</Form.Label> */}
              <TextInput
                value={offerDescription}
                onChange={(event) => {
                  setOfferDescription(event.currentTarget.value);
                }}
                name="offerDescription"
                label="Offer Description"
                required
              />
              {/* <Form.Control type="text" name="offerDescription" required /> */}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              controlId="excluedCouponCodes"
              style={{ fontSize: "15px", marginLeft: "10px" }}
            >
              <MultiSelect
                onChange={setExcludedCouponCodes}
                value={excludedCouponCodes}
                label="Excluded Coupon Codes"
                searchable
                clearable
                name="excluedCouponCodes"
                data={allCouponCodes}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* <input
          type="checkbox"
          style={{ height: "15px", width: "15px" }}
          id="applicable_all_checkbox"
          name="applicable_all_checkbox"
          checked={isApplicableToAll}
          onChange={(event) => {
            setIsApplicableToAll(event.target.checked);
          }}
        /> */}
        {/* <label
          style={{ marginLeft: "7px", fontSize: "17px" }}
          htmlFor="applicable_all_checkbox"
        >
          {" "}
          Applicable to all
        </label> */}

        {isApplicableToAll ? (
          <>
            <br />
            <br />
            <br />
          </>
        ) : (
          <Row>
            <Col>
              <Form.Group
                controlId="storeSeries"
                style={{ fontSize: "15px", marginLeft: "10px" }}
              >
                <MultiSelect
                  onChange={setUserSelectedStoreSeries}
                  value={userSelectedStoreSeries}
                  label="Select Series/ Stores"
                  searchable
                  clearable
                  name="storeSeries"
                  data={allStoreSeries}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                as={Col}
                controlId="retailerSeries"
                style={{ fontSize: "15px" }}
              >
                <MultiSelect
                  label="Select Retailer Series"
                  data={retailerSeriesData}
                  placeholder="Pick retailer series"
                  onChange={setUserSelectedRetailerSeries}
                  value={userSelectedRetailerSeries}
                  searchable
                  nothingFound="nothing found"
                  clearButtonLabel="Clear selection"
                  clearable
                />

                <Button
                  size="sm"
                  type="button"
                  className="offerMasterFormRetailerSeriesBtn"
                  onClick={handleCreateRetailerGrpClick}
                >
                  + Create new retailer group
                </Button>
                <Button
                  style={{ marginLeft: ".8rem" }}
                  size="sm"
                  type="button"
                  className="offerMasterFormRetailerSeriesBtn"
                  onClick={() => {
                    props.history.push("/retailerGroups");
                  }}
                >
                  View all retailer groups
                </Button>
              </Form.Group>
            </Col>
          </Row>
        )}

        <div>
          <input
            style={{ height: "15px", width: "15px" }}
            type="checkbox"
            id="only_for_new_users_checkbox"
            checked={isOnlyForNewUsers}
            onChange={(event) => {
              setIsOnlyForNewUsers(event.target.checked);
            }}
          />
          <label
            style={{ marginLeft: "10px" }}
            htmlFor="only_for_new_users_checkbox"
          >
            {" "}
            Offer only for new customers
          </label>
        </div>

        <div>
          <input
            style={{ height: "15px", width: "15px" }}
            type="checkbox"
            id="excluded_from_listing_checkbox"
            checked={isExcludedFromListing}
            onChange={(event) => {
              setIsExcludedFromListing(event.target.checked);
            }}
          />
          <label
            style={{ marginLeft: "10px" }}
            htmlFor="excluded_from_listing_checkbox"
          >
            {" "}
            Excluded from listing
          </label>
        </div>

        <Button
          type="submit"
          disabled={isSubmitBtnDisabled}
          className="offerMasterFormSubmitBtn"
        >
          {isOfferEditMode ? "Update offer" : "Create offer"}
        </Button>
      </form>
      {/* </>
      )} */}
    </>
  );
};

const ExcludedProductsSelect = React.memo(
  (props) => {
    const excludedProductsData = props.excludedProductsData;
    const setExcludedProductsData = props.setExcludedProductsData;
    return (
      <Form.Group
        controlId="excludedProducts"
        style={{
          display: "block",
          paddingBottom: "40px",
          marginLeft: "100px",
          marginRight: "100px",
          fontSize: "15px",
        }}
      >
        <Form.Label>Excluded Products</Form.Label>
        <TransferList
          value={excludedProductsData}
          onChange={setExcludedProductsData}
          searchPlaceholder="Search..."
          nothingFound="Nothing here"
          titles={["All Products", "Excluded Products"]}
          breakpoint="sm"
        />
      </Form.Group>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export default CustomOffer;
