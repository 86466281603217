import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Center, Flex, Grid, Image, SimpleGrid, Text } from "@mantine/core";
import { Stack } from "@mantine/core";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ProductMasterContext } from "../../../../context/AppHomeScreen/ProductMasterContext";
import Button from "@mui/material/Button";
import { COMMOM_BACKGROUND_STYLES } from "../../styles/styles";

export function VerticalScrollUIForType({ type, data }) {
  const {
    state: { allItemsById },
  } = useContext(ProductMasterContext);
  if (!type) return <></>;
  const widgetData = data.item_id;

  switch (type) {
    case "PRODUCTS_LIST":
      return (
        <div
          style={{ ...COMMOM_BACKGROUND_STYLES(data), paddingBottom: "2rem" }}
        >
          <Text style={{ marginBottom: "1rem" }} fw="600" fz="md">
            New Arrivals
          </Text>
          <Stack gap={"1rem"}>
            {widgetData.map((item, index) => {
              return (
                <div
                  style={{
                    width: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <Card style={{ height: "5rem" }} raised={true}>
                    <CardContent>
                      <Flex direction="row" gap={"1.5rem"} align={"center"}>
                        <div style={{ width: "3rem", height: "3rem" }}>
                          <Image
                            radius={"md"}
                            width="100%"
                            height="100%"
                            fit="contain"
                            src={allItemsById[item]?.image_url || ""}
                          />
                        </div>

                        <div
                          style={{ marginTop: "-.3rem", marginRight: "auto" }}
                        >
                          <Text fz="xs" fw="600">
                            {allItemsById[item]?.brand}
                          </Text>
                          <Text fz="xs" color="dimmed">
                            {allItemsById[item]?.long_description}
                          </Text>
                          <Text fz="xs" color="red">
                            {`₹ ${allItemsById[item]?.dealer_price}`}
                          </Text>
                        </div>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            style={{ backgroundColor: "red" }}
                          >
                            Add
                          </Button>
                        </div>
                      </Flex>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </Stack>
        </div>
      );
    default:
      return null;
  }
}

export function VerticalScrollPreviewWidget({ data, allItemsById }) {
  return (
    <>
      <VerticalScrollUIForType
        type={data.type}
        data={data}
        allItemsById={allItemsById}
      />
    </>
  );
}

export default Grid;
