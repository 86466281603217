import React from 'react';
import { ToggleWrapper } from './wrapper';

const ToggleComp = (props) => {

    const handleInputToggle = () => { };

    return (
        <ToggleWrapper>
            <div className="toggleStock" onClick={props.handleStatus} >
                {props.text.header} <input type="checkbox" checked={props.isInStock} onChange={handleInputToggle} className="tgl tgl-flat" id="tog_stock" />
                <label htmlFor="tog_stock" className="tgl-btn"></label>
            </div>
        </ToggleWrapper>
    );
};

export default ToggleComp;
