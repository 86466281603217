import {
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  Modal,
  Navbar,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import database from "../../service/firebaseConfig";
import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Alert, Badge } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import Template from "./invoice-template";
import Template2 from "./invoice-template2";
import ReactToPrint from "react-to-print";
import { deliveryCommercialServer } from "../../service/servicesConfig";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import {
  validatePurchaseUser,
  validateGRNOrFinanceUser,
} from "../../service/credentials";
import { toast } from "react-toastify";
// import { html2pdf } from 'js-html2pdf';

function ItemsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Items Purchased
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered>
          <thead>
            <tr>
              <td>
                <center>Item Name</center>
              </td>
              <td>
                <center>No. of Bags</center>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <center>{props.data.name}</center>
              </td>
              <td>
                <center>{props.data.qty}</center>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Invoices = () => {
  const [invcesLst, setinvcesLst] = useState([]);
  const [InvoiceObj, setInvoiceObj] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [Supplier, setSupplier] = useState();
  const [itemsDetails, setItemDetails] = useState({});
  const [TemplateDetails, setTemplateDetails] = useState({});
  const [getDate, setDate] = useState(new Date());
  const [dataRender, setDataRender] = useState(false);
  const [modifiedDate, setModifiedDate] = useState("");
  const [modifiedMonth, setModifiedMonth] = useState("");
  const [monthData, setMonthData] = useState("");
  const [getBackDrop, setBackDrop] = useState(false);
  const [dateRangeType, setDateRangeType] = useState(
    sessionStorage.getItem("dateRangeType")
      ? sessionStorage.getItem("dateRangeType")
      : validateGRNOrFinanceUser(sessionStorage.getItem(LOGGED_USER_EMAIL))
      ? "GRN"
      : "Purchase"
  );
  const [suppliersEmail, setSupplierMail] = useState("");
  const [productEditTable, setProductEditTable] = useState([]);
  const [renderAllDetails, setRenderAllDetails] = useState(false);
  const [editPurchaseModal, setEditPurchaseModal] = useState(false);
  const [viewPurchaseModal, setViewPurchaseModal] = useState(false);
  const [viewGRNModal, setViewGRNModal] = useState(false);
  const [addGRNModal, setAddGRNModal] = useState(false);
  const [editGRNModal, setEditGRNModal] = useState(false);
  const [editingItemDetails, setEditingItemDetails] = useState("");
  const [itemData, setItemData] = useState("");
  const [gRNAvailable, setGRNAvailable] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState("");
  const [exportDetails, setExportDetails] = useState(false);
  const [itemCodeList, setItemCodeList] = useState("");
  const [syncModal, setSyncModal] = useState(false);
  const [grnEntryClose, setGrnEntryClose] = useState(false);
  const [listGRNOpen, setListGRNOpen] = useState(false);
  const [show, setShow] = useState(false);
  const invoiceRef = useRef();
  const invoiceRef2 = useRef();
  const invoiceRef3 = useRef();

  useEffect(() => {
    let pushTableDetails = [];
    let newDate = formatDate(getDate);
    let year = newDate.split("-")[2];
    let month = newDate.split("-")[1];
    const dbref = database
      .database()
      .ref(`Purchase_Details/${year}/${month}/${newDate}`);

    const complete_date = `${year}-${month}-${getDate.getDate()}`;

    let getUrl;
    if (dateRangeType === "Purchase") {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    } else if (dateRangeType === "GRN") {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?grn_start_date=${complete_date}&grn_end_date=${complete_date}`;
    } else if(dateRangeType === 'Payment') {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?payment_start_date=${complete_date}&payment_end_date=${complete_date}`;
    } else {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    }

    deliveryCommercialServer
      .get(getUrl)
      .then((response) => {
        //console.log('response: ',response);
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
      .then((data) => {
        let invoiceObj = {};
        pushTableDetails.push(...data);
        let tableDetailsArray = [];

        let is_grn_no_present = true;

        pushTableDetails.map((item) => {
          is_grn_no_present = item?.grn_no ? true : false;
        });

        if (is_grn_no_present === true && 0) {
          // TODO : need more cleaver way to sort it out
          pushTableDetails = pushTableDetails.map((item) => {
            if (invoiceObj[item?.grn_no] === undefined) {
              invoiceObj[item?.grn_no] = [item];

              if (item.unique_key) item.uniqueKey = item.unique_key;
              else item.uniqueKey = "";
              tableDetailsArray.push(item);
              return item;
            } else {
              let arr = invoiceObj[item?.grn_no];
              arr.push(item);
              invoiceObj[item?.grn_no] = arr;
            }
            return;
          });
        } else {
          pushTableDetails = pushTableDetails.map((item) => {
            if (invoiceObj[item?.po_number] === undefined) {
              invoiceObj[item?.po_number] = [item];

              if (item.unique_key) item.uniqueKey = item.unique_key;
              else item.uniqueKey = "";
              tableDetailsArray.push(item);
              return item;
            } else {
              let arr = invoiceObj[item?.po_number];
              arr.push(item);
              invoiceObj[item?.po_number] = arr;
            }
            return;
          });
        }

        tableDetailsArray.sort((a, b) => {
          if (a.created_at < b.created_at) {
            return -1;
          } else if (a.created_at > b.created_at) {
            return 1;
          } else {
            return 0;
          }
        });

        console.log(tableDetailsArray);
        console.log(invoiceObj);

        setinvcesLst(tableDetailsArray);
        setInvoiceObj(invoiceObj);
      });
  }, [getDate]);

  useEffect(() => {
    //setBackDrop(true);
    setGrnEntryClose(false);
    let pushTableDetails = [];
    let updateItemsKeys = [];
    let newItemKeysArray = [];
    let grnDetailsCheck = [];
    let sessionDate;
    let filterDate;
    let sessionYr;
    let sessionMn;
    let sessionDt;
    if (sessionStorage.getItem(`datekey`)) {
      sessionDate = sessionStorage.getItem(`datekey`);
      filterDate = new Date(sessionDate);
      setDate(filterDate);
      sessionYr = filterDate.getFullYear();
      sessionMn = filterDate.getMonth() + 1;
      if (sessionMn < 10) sessionMn = "0" + sessionMn;
      sessionDt = filterDate.getDate();
      if (sessionDt < 10) sessionDt = "0" + sessionDt;
    } else {
      handleChange(new Date());
    }

    const complete_date = `${sessionYr}-${sessionMn}-${sessionDt}`;
    const complete_date_2 = `${sessionDt}-${sessionMn}-${sessionYr}`;

    console.log(
      `${complete_date} ${complete_date_2} ${sessionStorage.getItem("datekey")}`
    );

    let getUrl;
    if (dateRangeType === "Purchase") {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    } else if (dateRangeType === "GRN") {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?grn_start_date=${complete_date}&grn_end_date=${complete_date}`;
    } else if(dateRangeType === 'Payment') {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?payment_start_date=${complete_date}&payment_end_date=${complete_date}`;
    } else {
      getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    }

    console.log("getUrl: ", getUrl);

    //baseUrl.get(getUrl).then(response => {
    deliveryCommercialServer
      .get(getUrl)
      .then((response) => {
        //console.log('response: ',response);
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
      .then((data) => {
        //console.log("fetch: ", data);

        setItemCodeList(data);
        setSelectedDate(complete_date_2);
        //console.log(data)
        //data.forEach((item) => {

        //})
        pushTableDetails.push(...data);

        pushTableDetails = pushTableDetails.map((item) => {
          if (item.unique_key) item.uniqueKey = item.unique_key;
          else item.uniqueKey = "";
          return item;
        });

        pushTableDetails.sort((a, b) => {
          if (a.created_at < b.created_at) {
            return -1;
          } else if (a.created_at > b.created_at) {
            return 1;
          } else {
            return 0;
          }
        });

        pushTableDetails.forEach((items, index) => {
          if (items.grn_date) grnDetailsCheck.push(true);
          else grnDetailsCheck.push(false);
        });
      })
      .then(() => {
        setProductEditTable(pushTableDetails);
        setFilterList(pushTableDetails);
        setRenderAllDetails(true);
        setGRNAvailable(grnDetailsCheck);
        setBackDrop(false);

        if (pushTableDetails.length == 0) {
          //setBackDrop(false);
          throw "No Data";
        }
      })
      .catch((error) => {
        console.log(error);

        if (error === "No Data") {
          return toast(
            `No details available for ${
              filterDate ? filterDate.getDate() : getDate.getDate()
            }-${
              filterDate ? filterDate.getMonth() + 1 : getDate.getMonth() + 1
            }-${filterDate ? filterDate.getFullYear() : getDate.getFullYear()}`,
            { type: toast.TYPE.ERROR }
          );
        }
      });

    //if (dateRangeType === 'GRN') {

    const grn_entry_close_url = `/api/superzop/commercial/purchasedetails/getgrncloserecordbasedongrndate?grn_date=${complete_date}`;

    //baseUrl.get(grn_entry_close_url).then(response => {
    deliveryCommercialServer
      .get(grn_entry_close_url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
      .then((data) => {
        //console.log(data);

        if (data.length > 0) {
          const grn_entry_close = data[0].grn_entry_close;

          if (grn_entry_close == 1) {
            setGrnEntryClose(true);
          } else setGrnEntryClose(false);
        } else {
          setGrnEntryClose(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // return toast("Couldn't update Purchase Details record in MySQL", { type: toast.TYPE.ERROR });
      });
    //}
  }, [dateRangeType]);

  const ComponentToMailWrapper = ({ item }) => {
    const invoiceRef = useRef();
    const [show, setShow] = useState(false);
    const [suppliersEmail, setSupplierMail] = useState("");

    const handleClose = () => {
      setShow(false);
    };

    const handleDownload = useReactToPrint({
      onPrintError: (error) => console.log(error),
      content: () => invoiceRef.current,
      removeAfterPrint: true,
      print: async (printIframe) => {
        if (suppliersEmail === "") {
          return toast(`Please Provide Supplier's Email`, {
            type: toast.TYPE.ERROR,
          });
        }
        const document = printIframe.contentDocument;
        if (document) {
          const html = document.getElementsByTagName("html")[0];

          // const exporter = new html2pdf(html);
          // await exporter.getPdf(true);
          const obj = {
            name: "Mahesh",
            email: suppliersEmail,
            subject: "testing",
            messages: `${html.outerHTML}`,
          };
          deliveryCommercialServer
            .post("/api/email", obj)
            .then(() => {
              return toast(`Success`, { type: toast.TYPE.SUCCESS });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    });

    // const handleEmail  = (email) => {

    // const obj = { email : `${email}` ,subject : "Invoice",messages : `${invoiceRef.current.outerHTML}` }
    // deliveryCommercialServer.post('http://localhost:5000/api/email',obj).then ((res) => {
    //   return toast(

    //             `Success`,
    //             { type: toast.TYPE.SUCCESS }
    //           )
    // }).catch((err) => {
    //   console.log(err);
    // })
    // }

    return (
      <>
        <Button
          variant="success"
          onClick={() => {
            setShow(true);
            setSupplierMail(item.supplier_email);
          }}
        >
          Email
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Suppliers Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              defaultValue={suppliersEmail}
              onChange={(e) => {
                setSupplierMail(e.target.value);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleDownload();
                handleClose();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ display: "none" }}>
          {/* <Template2 ref={invoiceRef3} data={InvoiceObj[item?.po_number]} /> */}
          <Template2 ref={invoiceRef} data={InvoiceObj[item?.po_number]} />
        </div>
      </>
    );
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  function formatDate(selecteddate) {
    let dt = new Date(selecteddate);

    let day = dt.getDate();

    let monthIndex = dt.getMonth();
    monthIndex += 1;
    if (monthIndex < 10) {
      monthIndex = "0" + monthIndex;
    }
    let year = dt.getFullYear();
    //temp solution to pre date invoice dates
    let formattedDate = `${day}-${monthIndex}-${year}`;
    return formattedDate;
  }

  const List = invcesLst;
  const openAddPurchase = () => {
    window.open("#/addPurchaseDetails");
  };

  const handleChange = (date) => {
    setBackDrop(true);
    if (!date) {
      setDate("");
      return;
    }
    setDate(date);
    setDataRender(true);
    let dateSelected = date.getDate();
    let monthSelected = date.getMonth() + 1;

    if (dateSelected < 10) dateSelected = "0" + dateSelected;
    if (monthSelected < 10) monthSelected = "0" + monthSelected;
    setModifiedDate(dateSelected);
    setModifiedMonth(monthSelected);
    setMonthData(monthSelected);
    sessionStorage.setItem("datekey", date);
    window.location.reload();
  };

  return (
    <>
      <NavBar />

      <Navbar
        className="bg-light mr-auto justify-content-between"
        style={{ paddingTop: 100 }}
      >
        <Form inline>
          <InputGroup
            size="sm"
            style={{ marginLeft: "30px", marginRight: "30px" }}
          >
            <DropdownButton
              size="sm"
              id="dropdown-basic-button"
              title={
                dateRangeType === "Purchase"
                  ? "Select Purchase Date"
                  : dateRangeType === "GRN" ? "Select GRN Date" : "Select Payment Date"
              }
            >
              <Dropdown.Item
                value="Purchase"
                onClick={(e) => {
                  e.preventDefault();
                  setDateRangeType("Purchase");
                  sessionStorage.setItem("dateRangeType", "Purchase");
                }}
              >
                Select Purchase Date
              </Dropdown.Item>
              <Dropdown.Item
                value="GRN"
                onClick={(e) => {
                  e.preventDefault();
                  setDateRangeType("GRN");
                  sessionStorage.setItem("dateRangeType", "GRN");
                }}
              >
                Select GRN Date
              </Dropdown.Item>
              <Dropdown.Item
                value="Payment"
                onClick={(e) => {
                  e.preventDefault();
                  setDateRangeType("Payment");
                  sessionStorage.setItem("dateRangeType", "Payment");
                }}
              >
                Select Payment Date
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup>
          <DatePicker
            selected={getDate}
            onChange={handleChange}
            minDate={new Date("2017/01/01")}
            maxDate={new Date()}
            aria-describedby="basic-addon1"
            dateFormat="dd/MM/yyyy"
            className="red-border"
            customInput={<CustomDateInput />}
          />
          {validatePurchaseUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
            <Button
              size="sm"
              style={{ margin: 10 }}
              variant="success"
              onClick={() => {
                openAddPurchase();
              }}
            >
              Add Purchase
            </Button>
          ) : null}
        </Form>
      </Navbar>

      <Table striped bordered>
        <thead>
          <tr>
            <td>
              <center>Purchase No.</center>
            </td>
            <td>
              <center>Purchase Date</center>
            </td>
            <td>
              <center>GRN No.</center>
            </td>
            <td>
              <center>GRN Date</center>
            </td>
            <td>
              <center>Payment Date</center>
            </td>
            <td>
              <center>Supplier</center>
            </td>
            <td>
              <center>Invoice No.</center>
            </td>
            <td>
              <center>Items</center>
            </td>
            {/* <td>
              <center>Print</center>
            </td> */}
            <td>
              <center>E-Mail</center>
            </td>
          </tr>
        </thead>
        <tbody>
          {List.map((item) => {
            return (
              <>
                {" "}
                <tr>
                  <td>
                    <center>{item.po_number}</center>
                  </td>
                  <td>
                    <center>{item.purchased_date}</center>
                  </td>
                  <td>
                    <center>{item?.grn_no ? item.grn_no : ""}</center>
                  </td>
                  <td>
                    <center>{item?.grn_date ? item.grn_date : ""}</center>
                  </td>
                  <td>
                    <center>{item?.payment_date ? item.payment_date : ""}</center>
                  </td>
                  <td>
                    <center>{item.supplier_name}</center>
                  </td>
                  <td>
                    <center>{item.invoice_number}</center>
                  </td>
                  <td>
                    <center>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setModalShow(true);
                          setItemDetails(item);
                        }}
                      >
                        View
                      </Button>
                    </center>
                  </td>
                  {/* <td>
                    <center>
                    <div>
                      <ReactToPrint
                        trigger={() => <Button variant="success">Print</Button>}
                        content={() => invoiceRef2.current}
                        pageStyle={{ margin: "50px", border: "2px" }}
                      />
                      <div style={{ display: "none" }}>
                        <Template ref={invoiceRef2} data={item} />
                      </div>
                    </div>
                      <ComponentToPrintWrapper item={item} />
                    </center>
                  </td> */}
                  <td>
                    <center>
                      {/* <Button variant = 'success' onClick={() => {setShow(true);setSupplierMail(item.supplier_email);}} >Email</Button> */}
                      <ComponentToMailWrapper item={item} />
                    </center>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
        <ItemsModal
          show={""}
          onHide={() => setModalShow(false)}
          data={{
            po_number: itemsDetails?.po_number,
            name: itemsDetails?.item_name,
            qty: itemsDetails.bag_purchased,
          }}
        />
      </Table>

      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* { JSON.stringify(InvoiceObj[itemsDetails?.po_number]?.[0]) } */}

          <div>
            <Template2
              ref={invoiceRef}
              data={InvoiceObj[itemsDetails?.po_number]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={""}>
            Close
          </Button>

          <div>
            <ReactToPrint
              trigger={() => <Button variant="success">Print</Button>}
              content={() => invoiceRef2.current}
              pageStyle={{ margin: "50px", border: "2px" }}
            />
            <div style={{ display: "none" }}>
              <Template2
                ref={invoiceRef2}
                data={InvoiceObj[itemsDetails?.po_number]}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* {
           InvoiceObj &&  InvoiceObj[itemsDetails?.po_number]?.map((item)=>{
              return (
                <>
                  <p>{item?.po_number}</p>
                  <p>{item?.purchased_date}</p>
                  <p>{item?.item_name}</p>
                  <p>{item?.bag_purchased}</p>
                </>
              )
            })
          } */}
    </>
  );
};

const ComponentToPrintWrapper = ({ item }) => {
  const invoiceRef = useRef();
  return (
    <>
      <ReactToPrint
        trigger={() => <Button variant="success">Print</Button>}
        content={() => invoiceRef.current}
        pageStyle={{ margin: "50px", border: "2px" }}
      />
      <div style={{ display: "none" }}>
        <Template ref={invoiceRef} data={item} />
      </div>{" "}
    </>
  );
};

const ComponentToMailWrapper = ({ item }) => {
  const invoiceRef = useRef();
  const [show, setShow] = useState(false);
  const [suppliersEmail, setSupplierMail] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => invoiceRef.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      if (suppliersEmail === "") {
        return toast(`Please Provide Supplier's Email`, {
          type: toast.TYPE.ERROR,
        });
      }
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        console.log(html);
        // const exporter = new html2pdf(html);
        // await exporter.getPdf(true);
        const obj = {
          name: "Mahesh",
          email: suppliersEmail,
          subject: "testing",
          messages: `${html.outerHTML}`,
        };
        deliveryCommercialServer
          .post("/api/email", obj)
          .then(() => {
            return toast(`Success`, { type: toast.TYPE.SUCCESS });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  });

  // const handleEmail  = (email) => {

  // const obj = { email : `${email}` ,subject : "Invoice",messages : `${invoiceRef.current.outerHTML}` }
  // deliveryCommercialServer.post('http://localhost:5000/api/email',obj).then ((res) => {
  //   return toast(

  //             `Success`,
  //             { type: toast.TYPE.SUCCESS }
  //           )
  // }).catch((err) => {
  //   console.log(err);
  // })
  // }

  return (
    <>
      <Button
        variant="success"
        onClick={() => {
          setShow(true);
          setSupplierMail(item.supplier_email);
        }}
      >
        Email
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Suppliers Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            defaultValue={suppliersEmail}
            onChange={(e) => {
              setSupplierMail(e.target.value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDownload();
              handleClose();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ display: "none" }}>
        {/* <Template2 ref={invoiceRef2} data={InvoiceObj[itemsDetails?.po_number]} /> */}
        <Template ref={invoiceRef} data={item} />
      </div>
    </>
  );
};

export default Invoices;
