import styled from 'styled-components';

export const EditProductsContainerAdmin = styled.div`
    height: 100%;
    margin: 6rem;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);

    header {
        width: 100%;
        margin: 0.5rem auto;

        h3 {
            min-width: 95%;
            max-width: 95%;
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
            display: inline-block;
            letter-spacing: 0.15rem;
        }

        .close_icon {
            display: inline-block;
            padding: 0.4rem 0.8rem;
            font-size: 1.5rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0.3rem;

            &:hover {
                cursor: pointer;
                font-weight: bold;
                box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .edit_item_name {
        h4 {
            padding: 0.5rem;
            font-size: 1.2rem;
            margin: 0.5rem auto;
            background: #f1f1f1;
            border-radius: 0.5rem;
        }
    }

    .calContainer {
        width: 100%;
        margin: 0.5rem auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        .leftPanel {
            min-width: 35%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            padding: 1rem;
            margin-right: 1rem;
            border-radius: 0.5rem;
            box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);

            h6 {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin: 0.5rem auto;
                padding: 0.5rem;
                border-radius: 0.5rem;

                span {
                    display: inline-block;
                    width: 40%;
                    font-weight: bold;
                }

                &:hover {
                    box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                }
            }
            h6-empty {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin: 0.5rem auto;
                padding: 0.5rem;
                border-radius: 0.5rem;

                span {
                    display: inline-block;
                    width: 40%;
                    font-weight: bold;
                }
                &:hover {
                    box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                }
            }

            .hz_line {
                border-top: 1px solid rgba(0, 0, 0, 0.3);
                margin: 1rem 4rem;
            }
        }

        .rightPanel {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem;
            margin-left: 1rem;
            border-radius: 0.5rem;
            box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);

            .topPart {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;

                h6 {
                    width: 45%;
                    display: flex;
                    flex-wrap: wrap:
                    flex-direction: row;
                    border-radius: 0.5rem;
                    align-items: center;
                    padding: 1rem;
                    margin: 0.5rem;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    span {
                        font-weight: bold;
                        width: 70%;
                    }

                    &:hover {
                        font-weight: bold;
                        box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                    }
                }
            }

            .midPart {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;

                h6 {
                    width: 30%;
                    display: flex;
                    flex-wrap: wrap:
                    flex-direction: row;
                    border-radius: 0.5rem;
                    align-items: center;
                    padding: 1rem;
                    margin: 0.5rem;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    span {
                        font-weight: bold;
                        width: 60%;
                    }

                    &:hover {
                        font-weight: bold;
                        box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                    }
                }
            }

            .midBelowPart {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;

                h6 {
                    width: 60%;
                    display: flex;
                    flex-wrap: wrap:
                    flex-direction: row;
                    border-radius: 0.5rem;
                    align-items: center;
                    padding: 1rem;
                    margin: 0.5rem;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    span {
                        font-weight: bold;
                        width: 60%;
                    }

                    &:hover {
                        font-weight: bold;
                        box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                    }
                }
            }

            .botPart {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                .update {
                    padding: 0.5rem 1rem;
                    margin-right: 1rem;
                    border-radius: 0.5rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                    border: 1px solid rgba(0, 0, 0, 0.2);

                    &:hover {
                        color: #90ee90;
                        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
                    }
                }

                .cancel {
                    padding: 0.5rem 1rem;
                    margin-left: 1rem;
                    border-radius: 0.5rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                    border: 1px solid rgba(0, 0, 0, 0.2);

                    &:hover {
                        color: #ff0000;
                        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
`;

export const EditProductsContainerFinance = styled.div`
    height: 100%;
    margin: 6rem 15rem;
    margin-bottom: 1rem;
    padding: 1rem 4rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);

    header {
        width: 100%;
        margin: 0.5rem auto;

        h3 {
            min-width: 95%;
            max-width: 95%;
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
            display: inline-block;
            letter-spacing: 0.15rem;
        }

        .close_icon {
            display: inline-block;
            padding: 0.4rem 0.8rem;
            font-size: 1.5rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0.3rem;

            &:hover {
                cursor: pointer;
                font-weight: bold;
                box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .edit_item_name {
        h4 {
            padding: 0.5rem;
            font-size: 1.2rem;
            margin: 0.5rem auto;
            background: #f1f1f1;
            border-radius: 0.5rem;
        }
    }

    .calContainer {
        width: 100%;
        margin: 0.5rem auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        .leftPanel {
            min-width: 35%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            padding: 1rem;
            margin-right: 1rem;
            border-radius: 0.5rem;
            box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);

            h6 {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin: 0.5rem auto;
                padding: 0.5rem;
                border-radius: 0.5rem;

                span {
                    display: inline-block;
                    width: 40%;
                    font-weight: bold;
                }

                &:hover {
                    box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                }
            }
        }

        .rightPanel {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1rem;
            border-radius: 0.5rem;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);

            .topPart {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 1rem auto;

                h6 {
                    width: 50%;
                    display: flex;
                    flex-wrap: wrap:
                    flex-direction: row;
                    border-radius: 0.5rem;
                    align-items: center;
                    padding: 1rem;
                    margin: 0.5rem;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    span {
                        font-weight: bold;
                        width: 70%;
                    }

                    &:hover {
                        font-weight: bold;
                        box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.15);
                    }
                }
            }

            .botPart {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin: 1rem auto;

                .update {
                    padding: 0.5rem 1rem;
                    margin-right: 1rem;
                    border-radius: 0.5rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                    border: 1px solid rgba(0, 0, 0, 0.2);

                    &:hover {
                        color: #90ee90;
                        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
                    }
                }

                .cancel {
                    padding: 0.5rem 1rem;
                    margin-left: 1rem;
                    border-radius: 0.5rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                    border: 1px solid rgba(0, 0, 0, 0.2);

                    &:hover {
                        color: #ff0000;
                        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
`;
