import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { LOGGED_USER_EMAIL, LOGGED_USER_TYPE } from "./constants/allConstants";
import IdleTimeOutModal from "./IdleModal";
import PageNotFound from "./PageNotFound";
import IdleTimer from "react-idle-timer";
import firebase from "./service/firebaseConfig";
import {
  validateIsAdminUser,
  validatePurchaseUser,
  validatePurchaseExportUser,
  validatePackageOnlyUser,
  validatePurchaseExportOnlyUser,
  validateBuyingHead,
  validateProductUser,
  validateProductOnlyUser,
  validateGRNUser,
  validateProductHeadOnlyUser,
  validatePurchaseReportUser,
  validateOPSUser,
  validateOPSOnlyUser,
  validateDispatchOnlyUser,
  validateStockUser,
} from "./service/credentials";

let allowedRoutesSet = new Set();

class ProtectedRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 60 * 60, // 60 minutes
      showModal: false,
      userLoggedIn: false,
    };
    sessionStorage.setItem("isTimedOut", "N");

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);

    let userType = sessionStorage.getItem("Logged User Type");

    if (userType && this.state.userLoggedIn == false) {
      // alert("fb fetch")
      firebase
        .database()
        .ref(`User_Role_Access/AdminPortal/${userType}/allowed_routes`)
        .once("value")
        .then((snapshot) => {
          let userRoleDetails = snapshot.val();
          console.log(userRoleDetails);

          if (userRoleDetails) {
            allowedRoutesSet = new Set();
            let userRoutes = Object.values(userRoleDetails);
            for (let i = 0; i < userRoutes.length; i++) {
              let route = `/${userRoutes[i]}`;
              if (!allowedRoutesSet.has(route)) {
                allowedRoutesSet.add(route);
              }
            }
            console.log(allowedRoutesSet);
          }
          this.setState({ userLoggedIn: true });
        });
    }
  }

  _onAction(e) {
    sessionStorage.setItem("isTimedOut", "N");
  }

  _onActive(e) {
    sessionStorage.setItem("isTimedOut", "N");
  }

  _onIdle(e) {
    const isTimedOut = sessionStorage.getItem("isTimedOut");
    if (isTimedOut == "Y") {
      sessionStorage.clear();
      window.location.href = "/";
    } else {
      this.setState({ showModal: true, timeout: 1000 * 60 * 60 });
      this.idleTimer.start();
      sessionStorage.setItem("isTimedOut", "Y");
    }
  }

  handleClose() {
    sessionStorage.setItem("isTimedOut", "N");
    this.setState({ showModal: false, timeout: 1000 * 60 * 60 });
  }

  render() {
    const { component: Component, ...props } = this.props;

    var sessionStartTimestamp = Number(
      sessionStorage.getItem("session-start-date")
    );
    let currentTime = Date.now();
    let ttl = (currentTime - sessionStartTimestamp) / 1000;
    //86400 -1 day
    if (ttl > 86400 && sessionStartTimestamp > 0) {
      sessionStorage.clear();
      window.location.href = "/";
    }

    var validEmail = sessionStorage.getItem(LOGGED_USER_EMAIL);
    var validUserType = sessionStorage.getItem(LOGGED_USER_TYPE);

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        {this.state.showModal && (
          <IdleTimeOutModal
            showModal={this.state.showModal}
            callback={this.handleClose}
          />
        )}

        {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
          <Route
            {...props}
            render={(props) =>
              validEmail && validUserType ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        ) : (
          this.state.userLoggedIn == true && (
            <Route
              {...props}
              render={(props) =>
                validEmail && validUserType ? (
                  allowedRoutesSet.has(props.match.url) ? (
                    <Component {...props} />
                  ) : (
                    <PageNotFound />
                  )
                ) : (
                  <Redirect to="/" />
                )
              }
            />
          )
        )}
      </>
    );
  }
}

export default ProtectedRoute;
