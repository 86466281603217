import React from 'react'
import {Tabs,Tab} from 'react-bootstrap'
import Apartment from '../Apartments_Blocks_Flats/Apartments_details/Apartments'
import ViewFlatsBlocks from '../Apartments_Blocks_Flats/Apartments_details/ViewFlatsBlocks'
import NavBar from '../../../Dashboard/Navbar/Dashboard_Navabar';
import FooterComponent from '../../../components/Footer/index';
// import ModifyApartments from './Apartments_details/ModifyApartments'

function ApartmentDetails() {

    return (
    <>
    <NavBar/><br/><br/><br/><br/>
    <div>
        <Tabs variant="pills" defaultActiveKey="apartment" id="uncontrolled-tab-example">
        <Tab eventKey="apartment" title="Home page">
        <Apartment/>
        </Tab>
        <Tab eventKey="viewApartment" title="View Flats and blocks and Add new flats">
        <ViewFlatsBlocks/>
        </Tab>
        {/* <Tab eventKey="modify_apartments" title="Modify apartments">
        <ModifyApartments/>
        </Tab> */}
        </Tabs>
    </div><br/><br/>
    <div style={{position:"fixed",bottom:"0",width:"100%"}}>
    <FooterComponent/>
    </div>
    </>
    )
}

export default ApartmentDetails
