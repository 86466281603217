import React, { useState,useEffect } from 'react'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import { FaPencilAlt } from 'react-icons/fa';

const PriceUpdatesHistoryTableHeader = () => {
    return (
        <thead className="thead-light">
            <tr>
                <td>ItemId</td>
                <td>Item Description</td>
                <td>Source Type</td>
                <td>Mill Transport</td>
                <td>CD%</td>
                <td>Category</td>
                <td>Old BasePrice</td>
                <td>BasePrice</td>
                <td>Old DealerPrice</td>
                <td>DealerPrice</td>
                <td>Total Price</td>
                <td>GST Charge</td>
                <td>Approved</td>
                <td>ApprovedBy</td>
                <td>ApprovedAt</td>
                <td>UpdatedBy</td>
                <td>UpdatedAt</td>
                <td>UpdatedFrom</td>
            </tr>
        </thead>
    );
}

const PriceUpdatesHistoryTableBody = props => {
  let rows;
    if(props.priceAnalysisListData){
      rows=props.priceAnalysisListData.map((item, index) => {
          let priceText="";
          let basePrice=Number(item.base_price);
          let oldBasePrice=Number(item.old_base_price);
          if(basePrice>oldBasePrice){
            priceText="Increased";
          }else if(basePrice<oldBasePrice){
            priceText="Decreased";
          }else{
            priceText="No Change";
          }

          return (
              <tr key={index} style={{backgroundColor:priceText && (priceText==="Increased"?"#FFCCCC":(priceText==="Decreased"?"#CCFFCC":"#FFFFFF"))}}>
                  <td>{item.item_id}</td>
                  <td>{item.item_name}</td>
                  <td>{item.source_type}</td>
                  <td>{item.mill_transport}</td>
                  <td>{item.cd_per}</td>
                  <td>{item.category}</td>
                  <td>{item.old_base_price}</td>
                  <td>{item.base_price}</td>
                  <td>{item.old_dealer_price}</td>
                  <td>{item.dealer_price}</td>
                  <td>{item.total_price}</td>
                  <td>{item.gst_charge}</td>
                  <td>{item.approved}</td>
                  <td>{item.approved_by}</td>
                  <td>{item.approved_at}</td>
                  <td>{item.updated_by}</td>
                  <td>{item.updated_at}</td>
                  <td>{item.updated_from}</td>
              </tr>
          );
      });
    }else{
      rows = [];
    }

    return (<><tbody>{rows}</tbody></>);
}

const PriceUpdatesHistoryTable = (props) => {
    const { priceAnalysisListData } = props;
    return (
        <table className="table" style={{marginTop:10}}>
            <PriceUpdatesHistoryTableHeader />
            <PriceUpdatesHistoryTableBody priceAnalysisListData={priceAnalysisListData} />
        </table>
    );
}

export default PriceUpdatesHistoryTable
