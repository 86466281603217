import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import { Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, DropdownButton, ButtonGroup, Table, Alert } from 'react-bootstrap'
import { InputGroupAddon } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx'
import Backdrop from '../../CommonRequirements/backdrop'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import baseUrl from '../../service/servicesConfig';

function ProductDumpReport() {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [dumpDetails, setDumpDetails] = useState("")
    const [exportData, setExportData] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [dumpList, setDumpList] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState("")


    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let filterResult = ""

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify = (unavailability) => {

        if (unavailability)
            return toast("No Dump Details Available for selected date range ", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        startDate.setDate(startDate.getDate() - 1)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        const pdt_dump_url = `/api/superzop/commercial/productdump/getproductdumpbasesondumpdate?start_date=${startDateConversion}&end_date=${endDateConversion}`;
        baseUrl.get(pdt_dump_url).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            console.log(data);
            setDumpDetails(data);
            setBackDrop(false);
            if (!data.length > 0) {
                notify(true)
            }
        }).catch(err => {
            setBackDrop(false);
            console.log(err);
        })

        /*fetch(`https://services2.superzop.com/api/superzop/commercial/productdump/getproductdumpbasesondumpdate?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setDumpDetails(data); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))*/

    }, [])



    const handleDateRangeChange = () => {

        if (startDate > endDate) {

            setBackDrop(false)
            setDumpDetails("")
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        setDumpDetails("")
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        const pdt_dump_url = `/api/superzop/commercial/productdump/getproductdumpbasesondumpdate?start_date=${startDateConversion}&end_date=${endDateConversion}`;
        baseUrl.get(pdt_dump_url).then(response => {
            if (response.status === 200) {
                return response.data;
            }
        }).then(data => {
            console.log(data);
            setDumpDetails(data);
            setBackDrop(false);
            if (!data.length > 0) {
                notify(true)
            }
        }).catch(err => {
            setBackDrop(false);
            console.log(err);
        })
        /*fetch(`https://services2.superzop.com/api/superzop/commercial/productdump/getproductdumpbasesondumpdate?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setDumpDetails(data); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))*/
    }



    useEffect(() => {
        if (dumpList)
            handleDateRangeChange()
    }, [dumpList])

    const searchProductDump = () => {
        const filterData = []
        console.log(dumpDetails)
        filterResult = dumpDetails.filter(obj => {

            const itemId = obj.article_code.trim().toLowerCase();
            const itemDesc = obj.item_name && obj.item_name.trim().toLowerCase() || "";
            const categorySearch = obj.category && obj.category.trim().toLowerCase() || "";

            setSearchText(searchText.trim().toLowerCase());

            return itemId.match(searchText) || itemDesc.match(searchText) || categorySearch.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(items)
        })
        console.log(filterData)
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchProductDump()
    }, [searchText])


    const exportDumpDetails = () => {

        const wb = XLSX.utils.book_new()

        setBackDrop(true)
        let dumpDetailsSummary = [["Dump Date", "Article Code", "Item Name", "Category", "Quantity Dumped", "UOM", "Salvage Rate", "Salvage Amount", "Reason", "Approve Status", "Authorized By", "Created At", "Created By"]]
        dumpDetails.map(item => {


            item["dump_date"] = `${item["dump_date"].split("T")[0].split("-")[2]}-${monthList[item["dump_date"].split("T")[0].split("-")[1].slice(1) - 1]}-${item["dump_date"].split("T")[0].split("-")[0].slice(2)}`
            item["created_at"] = `${item["created_at"].split("T")[0].split("-")[2]}-${monthList[item["created_at"].split("T")[0].split("-")[1].slice(1) - 1]}-${item["created_at"].split("T")[0].split("-")[0].slice(2)}`
            item["authorized_by"] = item["authorized_by"] && item["authorized_by"].split("@")[0]
            item["created_by"] = item["created_by"] && item["created_by"].split("@")[0]
            dumpDetailsSummary.push([item["dump_date"], item["article_code"], item["item_name"], item["category"], item["qty_dumped"], item["uom"], item["salvage_rate"], item["salvage_amt"], item["reason"], item["approve_status"], item["authorized_by"], item["created_at"], item["created_by"]])

        })
        const DumpDetails = XLSX.utils.aoa_to_sheet(dumpDetailsSummary)
        XLSX.utils.book_append_sheet(wb, DumpDetails, `Product Dump List`)
        XLSX.writeFile(wb, `Product_Dump_List.xlsx`)
        setExportData(false)
        setBackDrop(false)


    }

    useEffect(() => {
        if (exportData)
            exportDumpDetails()
    }, [exportData])





    return (
        <div>
            <NavBar />
            <Alert style={{ textAlign: "center", paddingTop: 100 }} variant={"info"}>PRODUCT DUMP REPORT</Alert>
            <Navbar className="bg-light mr-auto justify-content-between" >
                <Form inline>
                    <Button size="sm" onClick={() => window.history.back()}><i class="fas fa-arrow-circle-left" size="3x"></i></Button>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <InputGroupAddon addonType="prepend">Select Start Date</InputGroupAddon>
                        <DatePicker
                            selected={startDate}
                            onChange={(val) => { setStartDate(val) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"

                        />
                    </InputGroup>
                    <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                        <InputGroupAddon addonType="prepend">Select End Date</InputGroupAddon>
                        <DatePicker
                            selected={endDate}
                            onChange={(val) => { setEndDate(val) }}
                            minDate={new Date("2017/01/01")}
                            maxDate={new Date()}
                            aria-describedby="basic-addon1"
                            dateFormat="dd/MM/yyyy"

                        />
                    </InputGroup>
                    <Button size="sm" onClick={() => { setDumpList(true); setBackDrop(true) }}>Show </Button>
                    {dumpDetails.length > 0 && <InputGroup style={{ marginLeft: "20px" }}>
                        <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Item Name / Category" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                    </InputGroup>}
                    <InputGroup size="sm" style={{ marginLeft: 30, marginRight: 50 }}>
                        <Button size="sm" variant="warning" onClick={() => setExportData(true)}>Export</Button>
                    </InputGroup>
                </Form>
            </Navbar>
            {dumpDetails.length > 0 &&
                <Jumbotron>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <td><center>Dump Date</center></td>
                                <td><center>Article Code</center></td>
                                <td><center>Item Name</center></td>
                                <td><center>Category</center></td>
                                <td><center>Quantity Dumped</center></td>
                                <td><center>UOM</center></td>
                                <td><center>Salvage Rate</center></td>
                                <td><center>Salvage Amount</center></td>
                                <td><center>Reason</center></td>
                                <td><center>Approve Status</center></td>
                                <td><center>Authorized By</center></td>
                                <td><center>Created At </center></td>
                                <td><center>Created By</center></td>

                            </tr>
                        </thead>
                        <tbody>
                            {dumpDetails.length > 0 && filterList.length == 0 ? dumpDetails.map(items => (
                                <tr>
                                    <td><center>{items.dump_date ? `${items.dump_date.split("T")[0].split("-")[2]}-${monthList[items.dump_date.split("T")[0].split("-")[1] - 1]}-${items.dump_date.split("T")[0].split("-")[0].slice(2)}` : ""}</center></td>
                                    <td><center>{items.article_code}</center></td>
                                    <td><center>{items.item_name}</center></td>
                                    <td><center>{items.category}</center></td>
                                    <td><center>{items.qty_dumped}</center></td>
                                    <td><center>{items.uom}</center></td>
                                    <td><center>{items.salvage_rate}</center></td>
                                    <td><center>{items.salvage_amt}</center></td>
                                    <td><center>{items.reason}</center></td>
                                    <td><center>{items.approve_status}</center></td>
                                    <td><center>{items.authorized_by && items.authorized_by.split("@")[0]}</center></td>
                                    <td><center>{items.created_at ? `${items.created_at.split("T")[0].split("-")[2]}-${monthList[items.created_at.split("T")[0].split("-")[1] - 1]}-${items.created_at.split("T")[0].split("-")[0].slice(2)}` : ""}</center></td>
                                    <td><center>{items.created_by && items.created_by.split("@")[0]}</center></td>
                                </tr>
                            )) :
                                filterList.map(items => (
                                    <tr>
                                        <td><center>{items.dump_date ? `${items.dump_date.split("T")[0].split("-")[2]}-${monthList[items.dump_date.split("T")[0].split("-")[1] - 1]}-${items.dump_date.split("T")[0].split("-")[0].slice(2)}` : ""}</center></td>
                                        <td><center>{items.article_code}</center></td>
                                        <td><center>{items.item_name}</center></td>
                                        <td><center>{items.category}</center></td>
                                        <td><center>{items.qty_dumped}</center></td>
                                        <td><center>{items.uom}</center></td>
                                        <td><center>{items.salvage_rate}</center></td>
                                        <td><center>{items.salvage_amt}</center></td>
                                        <td><center>{items.reason}</center></td>
                                        <td><center>{items.approve_status}</center></td>
                                        <td><center>{items.authorized_by && items.authorized_by.split("@")[0]}</center></td>
                                        <td><center>{items.created_at ? `${items.created_at.split("T")[0].split("-")[2]}-${monthList[items.created_at.split("T")[0].split("-")[1] - 1]}-${items.created_at.split("T")[0].split("-")[0].slice(2)}` : ""}</center></td>
                                        <td><center>{items.created_by && items.created_by.split("@")[0]}</center></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Jumbotron>
            }
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </div>
    )
}

export default ProductDumpReport
