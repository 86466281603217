import React, { useState, useEffect, useRef } from "react";
import { TextInput, SimpleGrid, Switch } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { Dropdown } from "react-bootstrap";
import NavBar from "../../../Dashboard/Navbar/Dashboard_Navabar";
import FooterComponent from "../../Footer/index";
import { toast } from "react-toastify";
import Backdrop from "../../../CommonRequirements/backdrop";
import OfferListingCard from "./OfferListingCard/OfferListingCard";
import InfiniteScroll from "react-infinite-scroll-component";

import axios from "axios";
import {
  PRODUCT_OFFER_URL,
  OFFER_URL,
  ACTIVE_OFFERS_URL,
  EXPIRED_OFFERS_URL,
  OFFER_BANNER_URL,
  EXPIRED_CUSTOM_OFFERS_URL,
  ACTIVE_CUSTOM_OFFERS_URL,
  CUSTOM_OFFER_URL,
  CUSTOM_OFFERS_URL,
} from "../OfferMasterURLs";

// const offersListingDB = database.database().ref(`Offer_Master`);

const OffersListingNew = (props) => {
  const [productOffers, setProductOffers] = useState([]);
  const [activeProductOffers, setActiveProductOffers] = useState([]);
  const [customerOffers, setCustomerOffers] = useState([]);
  const [activeCustomerOffers, setActiveCustomerOffers] = useState([]);
  const [activeCustomOffers, setActiveCustomOffers] = useState([]);
  const [expiredCustomOffers, setExpiredCustomOffers] = useState([]);
  const [activeOffersWithNotifications, setActiveOffersWithNotifications] =
    useState({});
  const [expiredOffersWithNotifications, setExpiredOffersWithNotifications] =
    useState({});
  const [paginateItems, setPaginateItems] = useState([]);
  const [paginateHasMore, setPaginateHasMore] = useState(true);
  const [offerCategoryData, setOfferCategoryData] = useState([]);
  const [offerCategoryDataToDisp, setOfferCategoryDataToDisp] = useState([]);
  const [offerCategory, setOfferCategory] = useState(
    sessionStorage.getItem("offerCategory") || "productOffer"
  );
  const offerCategoryDropdownText = `${offerCategory}`;

  const [offerTypeSelection, setOfferTypeSelection] = useState(
    sessionStorage.getItem("offerListingType") || "all"
  );

  const [offerActionSelection, setOfferActionSelection] = useState("all");
  const [offerSearchTerm, setOfferSearchTerm] = useState("");

  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [showActiveOffersChecked, setShowActiveOffersChecked] = useState(true);
  const [
    showOffersWithNotificationsChecked,
    setShowOffersWithNotificationsChecked,
  ] = useState(false);
  const [isNotificationToggleDisabled, setIsNotificationToggleDisabled] =
    useState(false);
  const uploadingImgToast = useRef();
  const deletingOfferToast = useRef();

  const formatOfferTypeNames = (offerName) => {
    if (offerName === "MDD") {
      return "Mid Day Deal / Best Price";
    } else if (offerName === "special") {
      return "Special";
    } else if (offerName === "BPP") {
      return "Best Price";
    } else if (offerName === "all") {
      return "All";
    } else if (offerName === "SLP") {
      return "SLP";
    } else if (offerName === "others") {
      return "Others";
    } else if (offerName === "BulkOffer") {
      return "Bulk Offer";
    }

    return offerName;
  };
  const formatActionTypeNames = (offerName) => {
    if (offerName === "instant") {
      return "Instant";
    } else if (offerName === "cashback") {
      return "Cashback";
    } else if (offerName === "freeproduct") {
      return "Free product";
    } else if (offerName === "supercoins") {
      return "Supercoins";
    } else if (offerName === "all") {
      return "All";
    }

    return offerName;
  };

  let offerTypeDropDownText = formatOfferTypeNames(offerTypeSelection);
  let offerActionDropDownText = formatActionTypeNames(offerActionSelection);

  const fetchCustomOffersFromApi = () => {
    axios
      .get(EXPIRED_CUSTOM_OFFERS_URL)
      .then((response) => {
        let custom_offers = response.data.data || [];
        console.log("fetch custom offers", custom_offers);
        setExpiredCustomOffers(custom_offers);
        //Deactivate loader here
        setShowLoadingOverlay(false);
      })
      .catch((error) => {
        //Deactivate loader here
        setShowLoadingOverlay(false);
        console.log("An error occured while attempting to fetch data");
      });

    axios
      .get(ACTIVE_CUSTOM_OFFERS_URL)
      .then((response) => {
        let custom_offers = response.data.data || [];
        console.log("fetch custom offers", custom_offers);
        setActiveCustomOffers(custom_offers);
        //Deactivate loader here
        setShowLoadingOverlay(false);
      })
      .catch((error) => {
        //Deactivate loader here
        setShowLoadingOverlay(false);
        console.log("An error occured while attempting to fetch data");
      });
  };

  const fetchAllExpiredOffersFromApi = () => {
    axios
      .get(EXPIRED_OFFERS_URL)
      .then((response) => {
        let product_offers = response.data.product_offers || [];
        let customer_offers = response.data.data || [];
        let notification_offers = response.data.notifications || {};
        //Deactivate loader here
        setShowLoadingOverlay(false);
        setProductOffers(product_offers);
        setCustomerOffers(customer_offers);
        setExpiredOffersWithNotifications(notification_offers);
      })
      .catch((error) => {
        //Deactivate loader here
        setShowLoadingOverlay(false);
        console.log("An error occured while attempting to fetch data");
      });
  };

  const fetchAllActiveOffersFromApi = () => {
    axios
      .get(ACTIVE_OFFERS_URL)
      .then((response) => {
        let notification_offers = response.data.notifications || {};
        let customer_offers = response.data.data || [];
        let product_offers = response.data.product_offers || [];

        //Deactivate loader here
        setShowLoadingOverlay(false);
        setActiveProductOffers(product_offers);
        setActiveCustomerOffers(customer_offers);
        setActiveOffersWithNotifications(notification_offers);
      })
      .catch((error) => {
        //Deactivate loader here
        setShowLoadingOverlay(false);
        console.log("An error occured while attempting to fetch data");
      });
  };

  const refetchAllOffersData = () => {
    setShowLoadingOverlay(true);
    setProductOffers([]);
    setCustomerOffers([]);
    fetchAllExpiredOffersFromApi();
    fetchAllActiveOffersFromApi();
    fetchCustomOffersFromApi();
  };

  const handleDeleteOffer = (offer_code, offer_category, offer_type) => {
    setShowLoadingOverlay(true);
    deletingOfferToast.current = toast("Deleting offer...", {
      type: toast.TYPE.INFO,
      autoClose: false,
      hideProgressBar: true,
      closeButton: false,
    });

    let data = {};
    let url = PRODUCT_OFFER_URL;
    if (offer_category === "productOffer") {
      if (offer_type === "SLP") {
        data = {
          offer_id: offer_code,
        };
      } else {
        data = {
          offer_code,
        };
      }
      url = PRODUCT_OFFER_URL;
    } else if (offer_category === "customerOffer") {
      data = {
        coupon_code: offer_code,
      };
      url = OFFER_URL;
    } else if (offer_category === "customOffer") {
      data = {
        id: offer_code,
      };
      url = CUSTOM_OFFERS_URL;
    }

    axios
      .delete(url, {
        data,
      })
      .then((response) => {
        toast.dismiss(deletingOfferToast.current);
        toast("Successfully deleted offer", { type: toast.TYPE.SUCCESS });
        refetchAllOffersData();
        setShowLoadingOverlay(false);
      })
      .catch((err) => {
        toast.dismiss(deletingOfferToast.current);
        console.log("Error occured while attemting to delete offer");
        console.log(err);
        setShowLoadingOverlay(false);
        toast("Could not delete offer", { type: toast.TYPE.ERROR });
      });
  };

  const handleUploadNewImage = (uploadedImageURL, offerCode) => {
    return new Promise((resolve, reject) => {
      setShowLoadingOverlay(true);
      uploadingImgToast.current = toast("Updating offer banner...", {
        type: toast.TYPE.INFO,
        autoClose: false,
        hideProgressBar: true,
        closeButton: false,
      });

      axios
        .put(OFFER_BANNER_URL, {
          offer_code: offerCode,
          offer_banner_url: uploadedImageURL,
        })
        .then((response) => {
          toast.dismiss(uploadingImgToast.current);
          toast("Successfully updated offer banner image", {
            type: toast.TYPE.SUCCESS,
          });

          setShowLoadingOverlay(false);
          resolve();
          refetchAllOffersData();
          // console.log(response.body);
        })
        .catch((err) => {
          toast.dismiss(uploadingImgToast.current);
          console.log(
            "An error occured while attempting to update offer image"
          );
          toast("An error occured while attempting to update offer image", {
            type: toast.TYPE.ERROR,
          });
          setShowLoadingOverlay(false);
          reject();
        });
    });
  };

  const handleOnOfferCategorySelection = (eventKey, obj) => {
    sessionStorage.setItem("offerCategory", eventKey);
    disableNotificationsToggle(eventKey === "customerOffer");
    setOfferCategory(eventKey);
    setOfferCategoryData([]);
    setOfferCategoryDataToDisp([]);
    setOfferListingPaginationData([]);
  };

  const handleOnOfferTypeSelection = (eventKey, obj) => {
    sessionStorage.setItem("offerListingType", eventKey);
    setOfferTypeSelection(eventKey);
  };

  const handleOnActionTypeSelection = (eventKey, obj) => {
    setOfferActionSelection(eventKey);
  };

  const disableNotificationsToggle = (disabled) => {
    if (disabled) {
      setShowOffersWithNotificationsChecked(false);
      setIsNotificationToggleDisabled(true);
    } else {
      setIsNotificationToggleDisabled(false);
    }
  };

  const getOfferListingData = (
    offers,
    offer_category,
    offer_type,
    offer_action,
    searchTerm = ""
  ) => {
    const dataToReturn = [];
    if (offer_category === "productOffer") {
      if (!offer_type) {
        return console.log("did not receive any offer type");
      }

      if (offer_type === "all" && !searchTerm) {
        return offers;
      } else if (offer_type === "all" && searchTerm) {
        offers.forEach((item, index) => {
          const item_id = item.item_id || item.item_ids || "";
          if (
            item.offer_type === "SLP" &&
            item.offerId &&
            item.offer_id
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            dataToReturn.push(item);
          } else if (
            (item.offer_type !== "SLP" &&
              item.offer_code &&
              item.offer_code
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            item.offer_id
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.offer_description &&
              item.offer_description
                .toLowerCase(0)
                .includes(searchTerm.toLowerCase()))
          ) {
            dataToReturn.push(item);
          }
        });
        return dataToReturn;
      }

      if (offer_type === "SLP" && searchTerm) {
        offers.forEach((item, index) => {
          const item_id = item.item_id || item.item_ids || "";
          if (
            (item.offer_type === offer_type &&
              item.offer_id &&
              item.offer_id
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            item_id.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            dataToReturn.push(item);
          }
        });
        return dataToReturn;
      }

      if (searchTerm) {
        offers.forEach((item, index) => {
          const item_id = item.item_id || item.item_ids || "";
          if (
            item.offer_type === offer_type &&
            (item.offer_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
              ((item.offer_id || item.offer_id === 0) &&
                item.offer_id
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())) ||
              (item.offer_description &&
                item.offer_description
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())))
          ) {
            dataToReturn.push(item);
          }
        });
      } else {
        offers.forEach((item, index) => {
          if (offer_type !== "MDD" && item.offer_type === offer_type) {
            dataToReturn.push(item);
          } else if (
            offer_type === "MDD" &&
            (item.offer_type === "MDD" || item.offer_type === "BPP")
          ) {
            dataToReturn.push(item);
          }
        });
      }
    } else if (
      offer_category === "customerOffer" ||
      offer_category === "customOffer"
    ) {
      if (!offer_action) {
        return console.log("did not receive any offer action");
      }

      if (offer_action === "all" && !searchTerm) {
        return offers;
      } else if (offer_action === "all" && searchTerm) {
        offers.forEach((item, index) => {
          if (
            item.coupon_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.offer_description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            dataToReturn.push(item);
          }
        });
        return dataToReturn;
      }

      if (searchTerm) {
        offers.forEach((item, index) => {
          if (
            (item.mode === offer_action &&
              item.coupon_code
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            item.offer_description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            dataToReturn.push(item);
          }
        });
      } else {
        offers.forEach((item, index) => {
          if (item.mode === offer_action) {
            dataToReturn.push(item);
          }
        });
      }
    }

    return dataToReturn;
  };

  const getOffersBasedOnNotification = (
    offers_arr,
    is_filter_with_notifications
  ) => {
    const dataToReturn = [];
    if (!offers_arr) {
      return console.log("Error, No arguements received");
    }

    if (!is_filter_with_notifications) {
      return offers_arr;
    }

    offers_arr.forEach((item, index) => {
      if (is_filter_with_notifications) {
        if (item.notification_text && item.notification_text !== "") {
          dataToReturn.push(item);
        }
      }
    });

    return dataToReturn;
  };

  const setOfferListingPaginationData = (dataToDisp) => {
    setPaginateItems(dataToDisp.slice(0, 10));
    if (dataToDisp.length > 10) {
      setPaginateHasMore(true);
    } else {
      setPaginateHasMore(false);
    }
  };

  useEffect(() => {
    //Activate loader here
    setShowLoadingOverlay(true);
    // fetchSeriesAFromDb();
    fetchAllExpiredOffersFromApi();
    fetchAllActiveOffersFromApi();
    fetchCustomOffersFromApi();
    disableNotificationsToggle(offerCategory === "customerOffer");
  }, []);

  useEffect(() => {
    let dataToDisp = [];
    let prod_offers = [];
    let cust_offers = [];
    let custom_offers = [];
    if (showActiveOffersChecked) {
      if (showOffersWithNotificationsChecked) {
        prod_offers = activeOffersWithNotifications.product || [];
        cust_offers = activeOffersWithNotifications.customer || [];
      } else {
        prod_offers = activeProductOffers;
        cust_offers = activeCustomerOffers;
      }

      custom_offers = activeCustomOffers;
    } else {
      if (showOffersWithNotificationsChecked) {
        prod_offers = expiredOffersWithNotifications.product || [];
        cust_offers = expiredOffersWithNotifications.customer || [];
      } else {
        prod_offers = productOffers;
        cust_offers = customerOffers;
      }
      custom_offers = expiredCustomOffers;
    }
    if (offerCategory === "productOffer") {
      dataToDisp = getOfferListingData(
        prod_offers,
        "productOffer",
        offerTypeSelection,
        undefined,
        offerSearchTerm
      );
    } else if (offerCategory === "customerOffer") {
      dataToDisp = getOfferListingData(
        cust_offers,
        "customerOffer",
        undefined,
        offerActionSelection,
        offerSearchTerm
      );
    } else if (offerCategory === "customOffer") {
      dataToDisp = getOfferListingData(
        custom_offers,
        "customOffer",
        undefined,
        offerActionSelection,
        offerSearchTerm
      );
    }

    setOfferCategoryData(dataToDisp);
    setOfferCategoryDataToDisp(dataToDisp);
    setOfferListingPaginationData(dataToDisp);
  }, [
    productOffers,
    customerOffers,
    activeCustomOffers,
    expiredCustomOffers,
    activeProductOffers,
    activeCustomerOffers,
    activeOffersWithNotifications,
    expiredOffersWithNotifications,
    offerCategory,
    offerTypeSelection,
    offerActionSelection,
    offerSearchTerm,
    showActiveOffersChecked,
    showOffersWithNotificationsChecked,
  ]);

  return (
    <>
      {showLoadingOverlay ? (
        <Backdrop parentLoadStatus={showLoadingOverlay} />
      ) : null}
      <div className="offerListingMainContainer">
        <NavBar />
        <br />
        <br />
        <br />
        <br />

        <SimpleGrid cols={3}>
          <div>
            <Dropdown
              style={{ textAlign: "center", marginBottom: "20px" }}
              onSelect={handleOnOfferCategorySelection}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {offerCategoryDropdownText}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="productOffer"
                  active={offerCategory === "productOffer"}
                >
                  Product Offer
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="customerOffer"
                  active={offerCategory === "customerOffer"}
                >
                  Customer Offer
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="customOffer"
                  active={offerCategory === "customOffer"}
                >
                  Custom Offer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {offerCategory === "productOffer" ? (
            <div>
              <Dropdown onSelect={handleOnOfferTypeSelection}>
                <Dropdown.Toggle variant="danger" id="dropdown-basic">
                  {offerTypeDropDownText}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="all"
                    active={offerTypeSelection === "all"}
                  >
                    All{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="MDD"
                    active={offerTypeSelection === "MDD"}
                  >
                    Mid Day Deal / Best Price
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="special"
                    active={offerTypeSelection === "special"}
                  >
                    Special
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="SLP"
                    active={offerTypeSelection === "SLP"}
                  >
                    SLP
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="BulkOffer"
                    active={offerTypeSelection === "BulkOffer"}
                  >
                    Bulk Offer
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="others"
                    active={offerTypeSelection === "others"}
                  >
                    Others
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <>
              <Dropdown onSelect={handleOnActionTypeSelection}>
                <Dropdown.Toggle variant="danger" id="dropdown-basic">
                  {offerActionDropDownText}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="all"
                    active={offerActionSelection === "all"}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="instant"
                    active={offerActionSelection === "instant"}
                  >
                    Instant
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="cashback"
                    active={offerActionSelection === "cashback"}
                  >
                    Cashback
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="freeproduct"
                    active={offerActionSelection === "freeproduct"}
                  >
                    Free product
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="supercoins"
                    active={offerActionSelection === "supercoins"}
                  >
                    Supercoins
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </SimpleGrid>

        <SimpleGrid
          style={{
            width: "80vw",
            marginLeft: "10vw",
            marginRight: "10vw",
            paddingBottom: "40px",
          }}
          cols={2}
        >
          <div>
            <Switch
              size="lg"
              onLabel="ACT"
              offLabel="EXP"
              color="green"
              id="activeOffersSwitch"
              name="activeOffersSwitch"
              checked={showActiveOffersChecked}
              onChange={(event) => {
                setShowActiveOffersChecked(event.target.checked);
              }}
              label={
                <p>
                  Showing{" "}
                  {showActiveOffersChecked ? (
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      active{" "}
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: showActiveOffersChecked ? "normal" : "bold",
                        color: showActiveOffersChecked ? "black" : "red",
                      }}
                    >
                      expired{" "}
                    </span>
                  )}
                  offers
                </p>
              }
            />
          </div>
          <div>
            <Switch
              size="lg"
              color="yellow"
              id="OffersWithNotificationsSwitch"
              name="OffersWithNotificationsSwitch"
              disabled={isNotificationToggleDisabled}
              checked={showOffersWithNotificationsChecked}
              onChange={(event) => {
                setShowOffersWithNotificationsChecked(event.target.checked);
              }}
              label={
                <p>
                  Showing{" "}
                  {showOffersWithNotificationsChecked ? (
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      only offers with{" "}
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: showOffersWithNotificationsChecked
                          ? "normal"
                          : "bold",
                        color: showOffersWithNotificationsChecked
                          ? "black"
                          : "red",
                      }}
                    >
                      all offers with/ without{" "}
                    </span>
                  )}
                  notifications
                </p>
              }
            />
          </div>
        </SimpleGrid>
        <SimpleGrid
          style={{
            width: "80vw",
            marginLeft: "10vw",
            marginRight: "10vw",
            paddingBottom: "40px",
          }}
          cols={1}
        >
          <TextInput
            placeholder=" Search ... offer code / description"
            onChange={(event) => {
              setOfferSearchTerm(event.target.value);
            }}
          />
        </SimpleGrid>

        <InfiniteScroll
          dataLength={paginateItems.length} //This is important field to render the next data
          next={() => {
            if (paginateItems.length >= offerCategoryDataToDisp.length) {
              return setPaginateHasMore(false);
            }
            if (paginateItems.length + 10 > offerCategoryDataToDisp.length) {
              setPaginateItems(offerCategoryDataToDisp);
            } else {
              setPaginateItems(
                offerCategoryDataToDisp.slice(0, paginateItems.length + 10)
              );
            }
          }}
          hasMore={paginateHasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ marginBottom: "50px", textAlign: "center" }}>
              <b>End of Offer List</b>
            </p>
          }
        >
          {paginateItems.map((item, index) => {
            let uniqueKey;
            if (offerCategory === "productOffer") {
              uniqueKey = item.offer_id;
            } else {
              uniqueKey = item.id || item.coupon_code;
            }
            // console.log("check id", item);
            // console.log("check key", uniqueKey);
            // console.log("offer category", offerCategory);

            return (
              <div key={uniqueKey} style={{ marginBottom: "50px" }}>
                <OfferListingCard
                  key={uniqueKey}
                  {...props}
                  offerDetails={item}
                  offerCategory={offerCategory}
                  refetchAllOffersData={refetchAllOffersData}
                  handleDeleteOffer={handleDeleteOffer}
                  handleUploadNewImage={handleUploadNewImage}
                />
              </div>
            );
          })}
        </InfiniteScroll>

        <FooterComponent />
      </div>
    </>
  );
};

export default OffersListingNew;
