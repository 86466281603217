import firebase from 'firebase';
import {apiKey,authDomain,databaseURL,projectId,storageBucket} from "./servicesConfig";

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
    projectId: projectId,
    storageBucket: storageBucket
};

const database = firebase.initializeApp(firebaseConfig);

export default database;
