import React from 'react'
import { Modal, Alert, Dropdown, DropdownButton, Card, Table, Button, Form, ButtonGroup, InputGroup, Accordion } from 'react-bootstrap';
import Switch from "react-switch";


function ViewGRNModal({ data, ...props }) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    View GRN
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Table striped bordered>
                                            <tr>
                        <td>
                            PO Number
                        </td>
                        <td><Form.Control value = {data.po_number} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            PO Date
                        </td>
                        <td><Form.Control value = {data.purchased_date} readOnly/></td>
                    </tr>
                    <tr>
                         
                         
                        <td>Invoice Date</td>
                        <td>
                            <Form.Control value={data.invoice_date} readOnly />
                        </td>
                    </tr>
                     <tr>
                        <td>
                            Source
                        </td>
                        <td>
                            <Form.Control value={data.source_type} readOnly />
                            </td>
                 
                    </tr>
                    <tr>
                        <td>
                            Article Code
                        </td>
                        <td><Form.Control value = {data.article_code} readOnly/></td>
                    </tr>
                     <tr>
                        <td>
                            Item Name
                        </td>
                        <td><Form.Control value = {data.item_name} readOnly/></td>
                    </tr>
                   <tr>
                        <td>
                            Category
                        </td>
                        <td><Form.Control value = {data.category} readOnly/></td>
                    </tr>
                     <tr>
                        <td>HSN</td>
                        <td><Form.Control value = {data.hsn_code} readOnly /></td>
                    </tr>
                     <tr>
                        <td>
                            Outer Type
                        </td>
                        <td>
                            <Form.Control value={data.outer_type} readOnly />
                    </td>
                    </tr>
                    <tr>
                        <td>
                            Outer Size
                        </td>
                        <td><Form.Control value = {`${data.outer_size} ${data?.uom ? data.uom : ""}` } readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Total Weight Ordered
                        </td>
                        <td><Form.Control value = {`${data.total_weight} ${data?.uom ? data.uom : ""}`} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            PO Rate
                        </td>
                        <td><Form.Control value = {data.purchase_price} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Invoice Amount
                        </td>
                        <td><Form.Control value = {data?.invoice_amt ? data.invoice_amt : ""} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Transport
                        </td>
                        <td><Form.Control value={data.transport} readOnly /></td>
                    </tr>
                    {data.transport === 'excluded' ? (<>
                    <tr>
                        <td>
                            Total Transport
                        </td>
                        <td><Form.Control  value ={data.transport_charges*data.total_weight} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Mill Transport (Per KG/Pc)
                        </td>
                        <td><Form.Control  value = {data.transport_charges} readOnly /></td>
                    </tr> </>) : null}
                    
                    <tr>
                        <td>
                            APMC Amount
                        </td>
                        <td><Form.Control value={(Number(data.apmc_charges_per)/100)*Number(data.po_amount)} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            GST Amount
                        </td>
                        <td><Form.Control value={(Number(data.gst_charges_per)/100)*Number(data.po_amount)} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Scheme Amount
                        </td>
                        <td><Form.Control value = {data.scheme_amount}  readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            MRP
                        </td>
                        <td><Form.Control  value = {data.mrp}  readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Final Invoice Amount
                        </td>
                        <td><Form.Control  value = {data?.invoice_amt ? (Number(data.invoice_amt) + ( Number(data.gst_charges_per)/100)* Number(data.invoice_amt) + (Number(data.apmc_charges_per)/100)*Number(data.invoice_amt) + Number(data?.transport_charges ? data.transport_charges : data.transport_charges)*Number(data.total_weight) - Number(data?.scheme_amount ? data?.scheme_amount : 0 )) : ""} readOnly/></td>
                    </tr>
                    <tr>
                        <td>
                            Final Invoice Rate (per KG/Pc)
                        </td>
                        <td><Form.Control value = {data?.invoice_amt ? ((Number(data.invoice_amt) + ( Number(data.gst_charges_per)/100)* Number(data.invoice_amt) + (Number(data.apmc_charges_per)/100)*Number(data.invoice_amt) + Number(data?.transport_charges ? data.transport_charges : data.transport_charges)*Number(data.total_weight) - Number(data?.scheme_amount ? data?.scheme_amount : 0 ))/Number(data.total_weight)).toFixed(2) : ""}readOnly/></td>
                    </tr>

                     <tr></tr>
                        <tr>
                            <td>Material Receiving Date</td>
                            <td><Form.Control readOnly defaultValue={data.grn_date} /></td>
                        </tr>
                        <tr>
                            <td>Bags Received</td>
                            <td><Form.Control readOnly defaultValue={data.bags_received} /></td>
                        </tr>
                        <tr>
                            <td>Total Weight Received</td>
                            <td><Form.Control readOnly defaultValue={data.total_weight_received} /></td>
                        </tr>
                        <tr>
                            <td>Difference in Receiving</td>
                            <td><Form.Control readOnly defaultValue={data.diff_receiving} /></td>
                        </tr>
                        <tr>
                            <td>Base Price</td>
                            <td><Form.Control readOnly  defaultValue={data.base_price}  /></td>
                        </tr>
                        <tr>
                            <td>Replacement Brand</td>
                            <td><Form.Control readOnly defaultValue={data.replacement_brand}  /></td>
                        </tr>
                        <tr>
                            <td>Invoice Number</td>
                            <td><Form.Control readOnly defaultValue={data.invoice_number} /></td>
                        </tr>
                        <tr>
                            <td>Supplier Name</td>
                            <td><Form.Control readOnly defaultValue={data.supplier_name} /></td>
                        </tr>
                        <tr>
                            <td>Remark</td>
                            <td><Form.Control readOnly defaultValue={data.remark} /></td>
                        </tr>
                        <tr>
                            <td>Bill Submitted</td>
                            <td><Switch disabled checked={data.bill_submitted && data.bill_submitted==="true" ? true : false} /></td>
                            <td></td>
                        </tr>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewGRNModal
