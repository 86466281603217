import React from 'react';
import { CheckBoxContainer } from './wrapper';

const CheckBoxComp = (props) => {

    return (
        <CheckBoxContainer>
            <input type="checkbox" id={`toggleCost_${props.idText}`} checked={props.isChecked} onChange={props.toggleIsChecked} disabled = {props.disabled}/>
            <label htmlFor={`toggleCost_${props.idText}`}></label>
        </CheckBoxContainer>
    );
};

export default CheckBoxComp;
