import React, { useEffect, useState } from "react";
import Navbar from "../../Dashboard/Navbar/Dashboard_Navabar";
import database from '../../service/firebaseConfig';
import { Select,Table,createStyles,Modal,Button  } from "@mantine/core";
import  { FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import ModalEditRow from "./editBModal";
import AddRetailerModal from "./addRetailerModal";
import AddProductModal from "./addProductsModal";
import RegisterRetailer from "./retaileRegisterModal";
import { toast } from "react-toastify";
import styled from "styled-components";


const StyledTable = styled(Table)`
  thead tr th {
    text-align: center;
  }
  tbody tr td {
    text-align: center;
  }
`;


const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      zIndex: 1,
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",
  
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },
  
    scrolled: {
      boxShadow: theme.shadows.sm,
    },
}));


const DB_REF_RetProds = database.database().ref(`Retailer_Products`);
const DB_REF_mtProds = database.database().ref(`Product_Master_New/MT`);


const ModernTrade = () => {

    toast.configure({
        autoClose: 10000, 
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
      });

    
    const { classes, cx } = useStyles();    
    const [scrolled, setScrolled] = useState(false);

    const [MT_Retailers, setMT_Retailers] = useState([]);
    const [selectedRetailer, setSelectedRetailer] = useState(""); 
    const [allRetailers,setAllRetailers]  = useState({});
    const [MT_Products,setMtProducts] = useState([]);
  
      // trigger ui refresh
    const [updateRetailerData, setUpdateRetailerData] = useState(true);
    const [updateProductsData, setUpdateProductsData] = useState(true);
    const [updateItem, setUpdateItem] = useState(true);
    const [RetReg,setRetReg] = useState(true);

    // edit modal 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    

    // ret modal

    const[isRetModalOpen,setIsRetModalOpen] = useState(false);
    const[isAddProdOpen,setIsAddProdOpen] = useState(false);
    const [retRegOpen, setIsRetRegOpen] = useState(false);
   
    
    const openRetModal = () => {
        setIsRetModalOpen(true);
    }

    
    const openEditModal = (rowData) => {
       
        setSelectedRow(rowData);
        setIsModalOpen(true);
    };

    const handleSaveModal = (itemId, editedRetailerMargin) => {
       
        setIsModalOpen(false);
    };

    let fetchRetailers =  async () => {
      try{
        
        const snapshot = await DB_REF_RetProds.once("value");
        const retVal = snapshot.val();
        setAllRetailers(retVal)

        let retIds = Object.keys(retVal);
       
           
        let retailerData = [];
        retIds.forEach((retId) => {
            const retailer = {
                label: retId,
                value: retId
            }
            retailerData.push(retailer)
        });

        setMT_Retailers(retailerData)

       } catch (error){
        console.error('Error fetching retailer details:', error);
        setMT_Retailers([]);
    }
  }
      


    const fetchProducts = async () => {
      try{
        
          const snapshot = await DB_REF_mtProds.once('value');
          const prodVal = snapshot.val();

          setMtProducts(prodVal);
          updateDataInRetProds(prodVal);
      } 
      catch (error){
          console.error('Error fetching Product details:', error);
      }
  };

  const updateDataInRetProds = (MTprods) => {

    let retKeys = MT_Retailers.map((item) => item.value);
  
    retKeys.forEach((retKey) => {

      const retailerProductRef = database.database().ref(`Retailer_Products/${retKey}`);

      let subCollections = Object.keys(allRetailers[retKey]);
    

      subCollections.forEach((itemId) => {

        const productData = MTprods[itemId];

        if(productData){

          const updateData = {
            brand: productData.brand,
            brand_gujarati: productData.brand_gujarati,
            brand_hindi: productData.brand_hindi,
            brand_marathi: productData.brand_marathi,
            category: productData.category,
            // goni_image_url: productData.goni_image_url,
            image_url: productData.image_url,
            gujarati_description: productData.gujarati_description,
            hindi_description: productData.hindi_description,
            // hsn_code: productData.hsn_code,
            item_description1: productData.item_description1,
            long_description: productData.long_description,
            // long_description_gujarati: productData.long_description_gujarati,
            // long_description_marathi: productData.long_description_marathi,
            lot_size: productData.lot_size,
            major_category: productData.major_category,
            marathi_description: productData.marathi_description,
            outer_size: productData.outer_size,
            sub_category: productData.sub_category,
            weight: productData.weight,
          };


          const itemRef = database.database().ref(`Retailer_Products/${retKey}/${itemId}`)
          
          itemRef.update(updateData)
          .then(() => {
            // console.log(`Updated data for item ID: ${itemId} in retailer ID: ${retKey}`);
            fetchRetailers();
            return toast(`Successfully synced data  in retailer products for ${retKey}`, { type: toast.TYPE.SUCCESS });
          })
          .catch((error) => {
            // console.error(`Error updating data for item ID: ${itemId} in retailer ID: ${retKey}`, error);
          });
          
        } 
        else {
          // console.log(`No matching data found for item ID: ${itemId} in retailer ID: ${retKey}`);
        }

      });
    });
  }

    

      



    useEffect(() => {
      if (updateRetailerData || updateProductsData || updateItem || RetReg) {
        fetchRetailers();
        setUpdateRetailerData(false);
        setUpdateProductsData(false);
        setUpdateItem(false);
        setRetReg(false);
      }
    }, [updateRetailerData, updateProductsData,updateItem,RetReg]);

    const updateEditItem = () => {
      setUpdateItem(true);
    }

  const updateRetailerWithProducts = () => {
    
    setUpdateRetailerData(true); 
  };

  const updateProducts = () => {
    setUpdateProductsData(true);
  }

  

  const updateRegisteredRetailers = () => {
    setRetReg(true);
  }


    useEffect(() =>{

                let selectedRetProducts = allRetailers[selectedRetailer]
                if(selectedRetProducts !== undefined)
                {

                    renderTable(selectedRetProducts)
                }

    },[selectedRetailer,allRetailers]);


  

    function renderTable (products)  {

        let retailerProducts = Object.values(products);
           
        const ths = (
            <tr>
              <th>SI No</th>
              <th>Item Id</th>
              <th>Item Description</th>
              <th>Category</th>
              <th>Market price</th>
              <th>Retailer Margin %</th>
              <th>GST %</th>
              <th>Dealer Price</th>
              <th>Edit</th>
              <th>Delete</th>

            </tr>
          );


          const rows = retailerProducts.map((item,index) => (
            <tr key={item.item_id}>
              <td>{index + 1}</td>
              <td>{item.item_id}</td>
              <td>{`${item.item_description1}-${item.weight}-${item.brand}`}</td>
              <td>{item.category}</td>
              <td>{item.market_price}</td>
              <td>{(item.retailer_margin !== null && item.retailer_margin !== undefined && item.retailer_margin !== '') ? item.retailer_margin : 0}</td>


              <td>{item.gst_per}</td>
              <td>{item.dealer_price}</td>
              <td>
               
              <Button
                        size="xs"
                        variant="light"
                        onClick={() => openEditModal(item)}
                        leftIcon={<FaPencilAlt />}
                    >
                        Edit
                    </Button>
              
                </td>
              <td>
               
                <FaRegTrashAlt
                 onClick={() => deleteProduct(item.item_id)} 
                style={{ cursor: 'pointer' }}
                
                />
                
                </td>
            </tr>
          ));

          return (
            <StyledTable withBorder withColumnBorders sx={{ minWidth: 700 }}>


              <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>{ths}</thead>
              <tbody>{rows}</tbody>

              </StyledTable>
          );
    }

    const deleteProduct = (productId) => {
      
      const updatedRetailers = { ...allRetailers };
                delete updatedRetailers[selectedRetailer][productId];
                
                setAllRetailers(updatedRetailers);

      DB_REF_RetProds.child(selectedRetailer).child(productId).remove()
          .then(() => {
            return toast("Successfully deleted in server", { type: toast.TYPE.SUCCESS });
            const updatedRetailers = { ...allRetailers };
                delete updatedRetailers[selectedRetailer][productId];
               
          })
          .catch((error) => {
            return toast("Couldn't update ", { type: toast.TYPE.ERROR });
          });
  };

  const updateRetailerProducts = (selectedRetailer, updatedProducts) => {
    
    setAllRetailers((prevRetailers) => ({
      ...prevRetailers,
      [selectedRetailer]: updatedProducts,
    }));
  };

  const updateRetailerItem = (selectedRetailer, item_id, updatedRetailerMargin,updatedDealerPrice) => {
   
    
    const retailerProducts = { ...allRetailers[selectedRetailer] };

  
    if (retailerProducts.hasOwnProperty(item_id)) {
      retailerProducts[item_id] = {
        ...retailerProducts[item_id],
        retailer_margin: updatedRetailerMargin,
        dealer_price: updatedDealerPrice
      };
  
     
      setAllRetailers((prevRetailers) => ({
        ...prevRetailers,
        [selectedRetailer]: retailerProducts,
      }));
    }
  };
  
  
  
    return (
        <>
            <Navbar />

            <div style={{ display: "flex", alignItems: "center" }}>

                         <Select
                        label="Select Retailer"
                        placeholder="Select one"
                        data={MT_Retailers}
                        value={selectedRetailer}
                        onChange={setSelectedRetailer}
                        searchable
                        maxDropdownHeight={400}
                          style={{
                         marginRight: '4%',
                         marginLeft: '10%',
                         marginTop: '6%'
                         
                        }}
                        />

                        <Button style={{
                         marginRight: '10px',
                         marginTop: '8%'
                        
                        }} onClick={() => setIsRetModalOpen(true)}>Add New Retailer</Button>
                        
                        <Button style={{
                         marginRight: '10px',
                         marginTop: '8%',
                         display: selectedRetailer !== "" ? 'block' : 'none'
                         
                        }} onClick={() => setIsAddProdOpen(true)}>Add Products</Button>
                        
                        <Button style={{
                         marginRight: '10px',
                         marginTop: '8%'
                        
                        }} onClick={() => fetchProducts()}>Refresh data</Button>

                      <Button style={{
                         marginRight: '10px',
                         marginTop: '8%'
                        
                        }} onClick={() => {setIsRetRegOpen(true); }}
                        className="btn btn-warning"
                        >Register New Retailer</Button>
            </div>
            <hr></hr>
          

            {selectedRetailer !== "" && allRetailers[selectedRetailer] !== undefined && (
                <div>
                    {renderTable(allRetailers[selectedRetailer])}
                </div>
            )}

        {isModalOpen && (
                <ModalEditRow
                    rowData={selectedRow}
                    show={isModalOpen}
                    onHide = {() => setIsModalOpen(false)}
                    retailer = {selectedRetailer}
                    existingProducts = {allRetailers[selectedRetailer]}
                    updateItem = {updateEditItem}
                    
                />
            )}

            {isRetModalOpen && (
                <AddRetailerModal
                show = {isRetModalOpen}
                onHide = {() => setIsRetModalOpen(false)}
                existingRetailers = {MT_Retailers}
                existingProducts={allRetailers[selectedRetailer]}
                updateRetailerWithProducts={updateRetailerWithProducts}
                updateRetailerDD = {setSelectedRetailer}
                />
            )

            }
            {isAddProdOpen && (
                <AddProductModal
                show = {isAddProdOpen}
                onHide = {() => setIsAddProdOpen(false)}
                retailer = {selectedRetailer}
                existingProducts={allRetailers[selectedRetailer]}
                // updateRetailerProducts={updateRetailerProducts}
                updateRetailerProducts={updateProducts}
               
                />
            )
            }

            {retRegOpen && (
                <RegisterRetailer
                show = {retRegOpen}
                onHide = {() => setIsRetRegOpen(false)}
                updateRegRet = {updateRegisteredRetailers}
                updateRetailerDD = {setSelectedRetailer}
               
                />
               )
            }
        </>
    );
};

export default ModernTrade;
