import React, { useContext } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Center, Flex, Grid, Image, SimpleGrid, Text } from "@mantine/core";
import { IconChevronsRight, IconPlus } from "@tabler/icons-react";
import { ProductMasterContext } from "../../../../context/AppHomeScreen/ProductMasterContext";
import { COMMOM_BACKGROUND_STYLES } from "../../styles/styles";

const CardStyles = styled(Card)`
  position: relative;
  overflow: visible !important;
  background-color: pink;
  border-radius: 0;
  width: 10rem !important;
  #AddIcon {
    z-index: 99;
    background-color: white;
    height: 2rem;
    width: 2rem;
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
    border-radius: 3px;
    box-shadow: 0 0.9px 3px grey;
    top: -7%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export function HorizontalScrollUIForType({
  type,
  data,
  productsListByCategory,
}) {
  const category = data.text ? data.text.toUpperCase() : "";
  const prodsByCategory = productsListByCategory[category] || [];

  if (!type) return <></>;

  switch (type) {
    case "SUB_CATEGORY":
      if (data.data.length > 0)
        return (
          <div style={{ ...COMMOM_BACKGROUND_STYLES(data) }}>
            <Flex
              direction="row"
              justify="space-between"
              align="flex-end"
              style={{
                width: "100%",
                marginBottom: "1rem",
              }}
            >
              <Flex direction="row" align="center" gap=".5rem">
                <div>
                  <Image maw={35} mx="auto" radius="md" src={data.image_url} />
                </div>

                <Text style={{ textAlign: "left" }} fw="600" fz="sm">
                  {data.text}
                </Text>
              </Flex>

              <IconChevronsRight size="1.75rem" stroke={2.3} color="#0047AB" />
            </Flex>
            <Flex gap="sm" direction="row" style={{ overflow: "scroll" }}>
              {data.data.map((item, index) => {
                return (
                  <div>
                    <Card
                      style={{
                        borderRadius: 0,
                        height: "10rem",
                        width: "10rem",
                      }}
                      variant="outlined"
                    >
                      <Center>
                        <CardMedia
                          sx={{ height: 100, width: "80%", marginTop: "1rem" }}
                          image={item.image_url}
                          style={{ backgroundSize: "contain" }}
                        />
                      </Center>

                      <CardContent>
                        <Center>
                          <Text fz="xs">{item.sub_category}</Text>
                        </Center>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </Flex>
          </div>
        );
      else return <></>;

    case "PRODUCTS":
      return (
        <div style={{ ...COMMOM_BACKGROUND_STYLES(data) }}>
          <Flex
            direction="row"
            justify="space-between"
            align="flex-end"
            style={{
              width: "100%",
            }}
          >
            <Flex direction="row" align="center" gap=".5rem">
              <div>
                <Image maw={35} mx="auto" radius="md" src={data.image_url} />
              </div>

              <Text style={{ textAlign: "left" }} fw="600" fz="sm">
                {data.text}
              </Text>
            </Flex>

            <IconChevronsRight size="1.75rem" stroke={2.3} color="#0047AB" />
          </Flex>
          <Flex
            gap="xl"
            direction="row"
            style={{
              overflow: "scroll",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
            }}
          >
            {prodsByCategory.map((item, index) => {
              return (
                <div
                  style={{
                    height: "13rem",
                    overflow: "visible",
                  }}
                >
                  <CardStyles raised={true}>
                    <Center>
                      <CardMedia
                        sx={{ height: 150, width: "80%", marginTop: "1rem" }}
                        image={item.image_url}
                        style={{ backgroundSize: "contain" }}
                      />
                    </Center>

                    {/* <CardContent></CardContent> */}

                    <div id="AddIcon">
                      <IconPlus size="1.2rem" stroke={1.8} color="red" />
                    </div>
                  </CardStyles>
                  <div style={{ marginTop: "5px" }}>
                    {/* <Text> {item.long_description}</Text> */}
                    <Text fz={10} color="black">
                      {" "}
                      {item.brand}
                    </Text>
                    <Text fz={12} color="black">
                      {" "}
                      {item.long_description}
                    </Text>
                  </div>
                </div>
              );
            })}
          </Flex>
        </div>
      );

    default:
      return null;
  }
}

function HorizontalScrollPreviewWidget({ data }) {
  const { state } = useContext(ProductMasterContext);

  return (
    <>
      <HorizontalScrollUIForType
        data={data}
        type={data.type}
        productsListByCategory={state.productsListByCategory}
      />
    </>
  );
}

export default HorizontalScrollPreviewWidget;
