import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  Divider,
  Table,
  ScrollArea,
  Flex,
  Modal,
  Image,
  Text,
  Container,
  Title,
  Col
} from "@mantine/core";

import styled from "styled-components";

const InlineTextStyles = styled.div`
  text-align: center;
  .mantine-Text-root {
    display: inline;
  }
`;
const closeButtonStyles = {
    color: "white",
    background: "red",
    padding: "5px 10px",
    cursor: "pointer",
  };
  

export function ImageOverlay({ selectedImage, onClose }) {
    return (
      <Modal opened={!!selectedImage}  size="xl" padding="none">
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

        
        {/* <Image src={selectedImage} alt="Selected Image" width="90vw" height="90vh" /> */}
        <Image src={selectedImage} alt="Selected Image" width="100%" height="100%" />
        <Button
          style={closeButtonStyles}
          onClick={onClose}
        >
          X
        </Button>
        </div>
      </Modal>
    );
  }

export function ViewItemsModal({opened,setOpened,data,}){
   

    const [selectedImage, setSelectedImage] = useState(null);
    const openImage = (imageUrl) => {
        setSelectedImage(imageUrl);
     };
     const closeImage = () => {
        setSelectedImage(null);
      };

    return(
        <>
         <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Work Order Details"
        padding="xl"
        size={"lg"}
      >

<ScrollArea style={{ height: "100%" }} offsetScrollbars>
          <Stack
            spacing={"1.5rem"}
            align="stretch"
            justify="center"
            pb={"1rem"}
          >
         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
         <Text style={{ color: '#495057', fontSize:'12px', fontWeight:'bold', marginRight: '0.5rem' }}>Supplier Name:</Text>
      
          <Title order={4}>{data.supplier_name}</Title>
        </div>

            <Divider my="sm" variant="dashed" />

          <Table  verticalSpacing="xs" fontSize="xs">
            <thead>
              <tr>
                <th>Description</th>
                <th>Image</th>
                {data.type === "Labour" && (
                  <>
                    <th>Hours</th>
                    <th>Rate (per hour)</th>
                  </>
                )}
                <th>Total Cost</th>
              </tr>
            </thead>
              <tbody>
                {data.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_description}</td>
                    <td>
                      {item.image_url ? (
                       <Image src={item.image_url} alt="Item Image" width={50} height={50}  onClick={() => openImage(item.image_url)}
                        style={{ cursor: "pointer" }} />  ) : ( "No Image")}
                       
                    </td>
                    {data.type === "Labour" ? (
                    <>
                     <td>{item.hours_worked}</td>
                     <td>{item.rate_per_hour}</td>
                     <td>{item.total_cost}</td>
                    </>
                    ): <td>{item.total_cost}</td>}
                 </tr>    
                ))}
           
              </tbody>
          </Table>

            
            <Divider color="black" my="sm" variant="dashed" />
          
            <Flex justify={"flex-end"}>
              <tr>
              <td style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}>Total Amount:</td>
              <td style={{ color: '#2e2d2d',fontSize:'12px' }}>{data.total_amt}</td>
          </tr>
          </Flex>
          {/* <Divider color="black" my="sm" variant="dashed" /> */}

            <Flex justify={"center"}>
              <Button
                style={{ width: "50%" }}
                color="red"
                onClick={(event) => {
                  setOpened(false);
                }}
              >
                Close
              </Button>
            </Flex>
          </Stack>
        </ScrollArea>

      </Modal>
<ImageOverlay selectedImage={selectedImage} onClose={closeImage} />

      
        </>
    )
}