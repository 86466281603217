import {
  Modal,
  Grid,
  Select,
  Button,
  Stack,
  NumberInput,
  Divider,
  Flex,
  Image,
  Tabs,
  Box,
  TextInput,
  MultiSelect,
  Text,
  ColorInput,
  Group,
  Switch,
} from "@mantine/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import {
  typesList,
  widgetsList,
  actionsList,
  storeSeries,
  FORM_DATA_FORMAT,
} from "../utils/types";
import styled from "styled-components";
import { IconCheck, IconPhoto, IconX } from "@tabler/icons-react";
import { widgetCreationSchema } from "../utils/validationSchema";
import { OFFER_BASE_URL } from "../../../service/servicesConfig";
import UploadBannerImage from "../ImageUpload/UploadBannerImage";

import axios from "axios";
import { toast } from "react-toastify";
import DynamicDataForm from "./CreateWidget/DynamicDataForm";
import { getInitialFormData } from "../utils/utils";

const EditWidgetModal = ({
  formatDataBeforeAPICall,
  prepareDataObjFromAPIResponse,
  ...props
}) => {
  const { modalOpened, setModalOpened, widgetData, selectedStoreSeries } =
    props;
  const FORM_INITIAL_VALUES = {
    widget: "CARD",
    type: "BANNER",
    action: "category_list",
    priority: null,
    store_series: "",
    is_active: true,
    data: [
      {
        image_url: "",
        action: "",
        item_id: [],
        text: "",
        view_type: "",
        header_image: "",
        header_title: "",
        header_description: "",
        header_background_color: "",
      },
    ],

    widget_height: null,
    widget_width: null,
    id: "",
  };
  const availableItemsForSelection = props.availableItemsForSelection || [];
  const categoriesUI = props.categoriesUI || [];
  const catWithSubCat = props.catWithSubCat || {};
  const [isFormLoading, setIsFormLoading] = useState(false);

  function populateFormInitialValues(data_from_api) {
    if (!data_from_api) return {};

    const {
      widget,
      type,
      action,
      priority,
      is_active,
      widget_height,
      widget_width,
      text,
      data,
      store_series,
      id,
      item_id,
      text_hi,
      text_mr,
      text_gj,
      image_url,
    } = data_from_api;

    let populated_data = {
      ...FORM_INITIAL_VALUES,
      widget,
      type,
      action,
      is_active: is_active == "Y" ? true : false,
      priority,
      text: text,
      text_hi,
      text_mr,
      text_gj,
      data,
      id,
      item_id,
      image_url,
      store_series: store_series.split(",") || [],
    };

    prepareDataObjFromAPIResponse(populated_data);

    if (widget_height) populated_data.widget_height = Number(widget_height);
    if (widget_width) populated_data.widget_width = Number(widget_width);

    console.log("check populated data", populated_data);
    return populated_data;
  }

  function handleOnFormSubmit(values) {
    const {
      widget,
      type,
      action,
      priority,
      is_active,
      widget_height,
      widget_width,
      widget_text,
      store_series,
      data,
      id,
    } = values;

    const dataToSubmit = {
      widget,
      type,
      action,
      priority,
      widget_height,
      widget_width,
      text: widget_text,
      store_series: store_series.join(","),
      data,
      is_active: is_active ? "Y" : "N",
      id,
    };

    formatDataBeforeAPICall(dataToSubmit);

    console.log("Data to submit", dataToSubmit);
    submitDataServer(dataToSubmit);
  }

  function submitDataServer(data) {
    if (!data) return;
    const url = `${OFFER_BASE_URL}/home-screen-layout?store_series=${selectedStoreSeries}&retailer_id=10010&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`;

    axios
      .put(url, { ...data })
      .then((res) => {
        console.log("Saved data successfully");
        setModalOpened(false);
        return toast(`Widget created successfully`, {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch((err) => {
        return toast(`Error: Could not create widget, ${err}`, {
          type: toast.TYPE.WARNING,
        });
      });
  }

  return (
    <Formik
      initialValues={populateFormInitialValues(widgetData)}
      validationSchema={widgetCreationSchema}
      onSubmit={handleOnFormSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Modal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          title={
            <Group>
              <div>Add new widget</div>
              <Switch
                label="Is Active"
                onLabel="Yes"
                offLabel="No"
                color="teal"
                checked={values.is_active}
                onChange={
                  (event) => {}
                  // setFieldValue("is_active", event.currentTarget.checked)
                }
                // disabled
                size="sm"
                thumbIcon={
                  values.is_active ? (
                    <IconCheck size={12} color={"green"} stroke={3} />
                  ) : (
                    <IconX size={12} color={"red"} stroke={3} />
                  )
                }
              />
            </Group>
          }
          size={"xl"}
        >
          <Form>
            {/* {console.log("check errors", errors)} */}
            <Stack>
              <Field
                as={Select}
                name="widget"
                label="Widget"
                required
                value={values.widget}
                onChange={(value) => {
                  setFieldValue("widget", value);
                  setFieldValue("type", "");
                }}
                data={widgetsList.map((item, index) => {
                  return item;
                })}
              />

              <Field
                as={Select}
                name="type"
                label="Type"
                required
                value={values.type}
                onChange={(value) => {
                  console.log("check", values.widget);
                  setFieldValue("type", value);
                  setFieldValue(
                    "data",
                    getInitialFormData(values.widget, value)
                  );
                }}
                data={typesList[values.widget]}
              />
              <Field
                as={Select}
                name="action"
                label="Action"
                required
                value={values.action}
                onChange={(value) => {
                  setFieldValue("action", value);
                }}
                data={actionsList.map((item, index) => {
                  return item;
                })}
              />

              <Field
                as={NumberInput}
                name="priority"
                value={values.priority}
                placeholder="Enter priority"
                label="Priority"
                onChange={(value) => {
                  setFieldValue("priority", value);
                }}
                required
                error={
                  touched.priority && errors.priority ? errors.priority : false
                }
                hideControls
              />
              <Field
                as={MultiSelect}
                required
                label="Store Series"
                clearable
                name="store_series"
                placeholder="Select a Series"
                onChange={(value) => {
                  setFieldValue("store_series", value);
                }}
                error={errors.store_series ? errors.store_series : false}
                data={storeSeries.map((item, index) => {
                  return item;
                })}
              />

              <Divider label="Widget Data" color="red" labelPosition="center" />

              {values.action && (
                <DynamicDataForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setIsFormLoading={setIsFormLoading}
                  availableItemsForSelection={availableItemsForSelection}
                />
              )}
              <Divider variant="dashed" color="blue" />

              <Divider
                variant="dashed"
                label="Widget Customization"
                labelPosition="center"
              />

              <Flex
                direction={"row"}
                justify={"space-around"}
                align={"center"}
                gap={"lg"}
              >
                <Box style={{ flex: "1" }}>
                  <Field
                    as={NumberInput}
                    placeholder="Enter height"
                    label="Height"
                    hideControls
                    value={values.widget_height}
                    onChange={(val) => setFieldValue("widget_height", val)}
                    error={
                      touched.widget_height && errors.widget_height
                        ? errors.widget_height
                        : false
                    }
                  />
                </Box>

                <Box style={{ flex: "1" }}>
                  <Field
                    as={NumberInput}
                    placeholder="Enter width"
                    label="Width"
                    hideControls
                    value={values.widget_width}
                    onChange={(val) => setFieldValue("widget_width", val)}
                    error={
                      touched.widget_width && errors.widget_width
                        ? errors.widget_width
                        : false
                    }
                  />
                </Box>
              </Flex>

              <br />
              <Stack spacing="xs">{console.log(errors)}</Stack>
              <Button loading={isFormLoading} type="submit">
                Create widget
              </Button>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default EditWidgetModal;
