
import database from '../../service/firebaseConfig';

const DeleteCombo=(itemId,comboitem)=> {


        database.database().ref(`Product_Master_New/S/${itemId}/${comboitem}`).remove().then(()=>{
            
            alert(`${comboitem} deleted successfully`)
            window.location.reload()
        })
  
}

export default DeleteCombo
