import styled from 'styled-components';
import sz_logo from '../../assests/images/sz_logo.png';

export const NavBarContainer = styled.div`
    position: fixed;
    z-index: 5;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.1);
`;

export const LeftContainer = styled.div`
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0rem 1rem;
`;

export const SZLogoContainer = styled.div`
    padding: 2rem 6rem;
    border-radius: 0.5rem; 
    margin: 0rem 2rem;
    background-image: url(${sz_logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
`;

export const MidContainer = styled.div`
    width: 50%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:10px;
`;

export const RightContainer = styled.div`
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0rem 1rem;
`;



export const StockContainer = styled.div`
    border-radius: 0.5rem;
    padding: 5rem;
    margin: 0rem 1rem;
    text-align:center;
    button {
        outline: none;
        padding: 5rem;
        margin-top: 5 rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        background-color: #FFCC00;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);

        &:hover {
            box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.2);
        }
    } 
`;

export const UserStatusContainer = styled.nav`
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0rem 1rem;

    button {
        outline: none;
        padding: 0.5rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        border: 1px solid lightgreen;
        background-color: #ffffff;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);

        &:hover {
            box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.2);
        }
    }    
}
`;