import React, { useState, useEffect,useRef, useContext, Fragment } from 'react';
import { Breadcrumb, Tab,Form, Row, Col, Nav, Button } from 'react-bootstrap';
import Select from 'react-select';
import qualitydb from '../../service/qualityfirebaseconfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import ProductData from './ProductData';
import XLSX from 'xlsx'
import LoaderComp from '../../components/Loader';
import { MainContainer, ShowMoreContainer } from '../ProductsPage/wrapper';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import { validateGRNOnlyUser, validateIsAdminUser, validatePurchaseUser, validatePurchaseExportUser, validatePurchaseExportOnlyUser , validatePurchaseOnlyUser,validatePurchaseReportUser} from '../../service/credentials';


const GrainRecognitionProduct = () => {
  const [productDetails, setProductDetails] = useState([]);
  const [searchProductDetails, setSearchProductDetails] = useState([]);
  const [exportData, setExportData] = useState(false);
  const [exportProdGradingData,setExportProdGradingData] = useState(false);
  const [exportTestProductData,setExportTestProductData] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchProducts();
  }, [])

  useEffect(() => {
      if (searchText){
        filterGrtProductDetails();
      }else{
        setProductDetails(productDetails);
      }
  }, [searchText])

  useEffect(() => {
      if (exportData)
          exportGRTDetails()
  }, [exportData])

  useEffect(() => {
      if (exportProdGradingData)
          exportGRTProdGradingDetails()
  }, [exportProdGradingData])

  useEffect(() => {
      if (exportTestProductData)
          exportGRTTestProductDetails()
  }, [exportTestProductData])

  const fetchProducts = () => {
    qualitydb.database().ref('Product_Master_Grading').on('value', function(snapshot) {
      console.log(snapshot.val());
      setProductDetails(snapshot.val());
      setSearchProductDetails(snapshot.val());
    });
  }

  const filterGrtProductDetails = () => {
    console.log('filterGrtProductDetails');
    let checksearchtext=searchText.trim().toLowerCase();
    console.log(checksearchtext);
    let filteredData=[];

    Object.keys(searchProductDetails).map((prodkey,index) => {
      let pushflag=false;
      // console.log(searchProductDetails[prodkey]);
      let brand=searchProductDetails[prodkey]['brand'];
      let itemid=searchProductDetails[prodkey]['itemnid'];
      let description=searchProductDetails[prodkey]['description'];
      let category=searchProductDetails[prodkey]['category'];

      if(brand){
        brand=brand.trim().toLowerCase();
        if(brand.includes(checksearchtext)){
          pushflag=true;
          filteredData.push(searchProductDetails[prodkey]);
        }
      }

      if(itemid && pushflag==false){
        itemid=itemid.trim().toLowerCase();
        if(itemid.includes(checksearchtext)){
          filteredData.push(searchProductDetails[prodkey]);
          pushflag=true;
        }
      }

      if(description && pushflag==false){
        description=description.trim().toLowerCase();
        if(description.includes(checksearchtext)){
          filteredData.push(searchProductDetails[prodkey]);
          pushflag=true;
        }
      }

      if(category && pushflag==false){
        category=category.trim().toLowerCase();
        if(category.includes(checksearchtext)){
          filteredData.push(searchProductDetails[prodkey]);
          pushflag=true;
        }
      }
    });
    setProductDetails(filteredData);
  }

  const exportGRTDetails = () => {
      console.log('exportGRTDetails');
      const wb = XLSX.utils.book_new()

      let grtProductSummary = [["ItemID", "Product Description", "Brand", "Category", "SubCategory","Grade", "Length", "Width","L/W Ratio","Size","Shape","Broken Grain Perc","Broken Grains","Moisture","Grain Count","Damaged Discolored","Weeviled","Sava","Price Range",
      "Foreign Matter Perc","Perimeter","Area","< 2mm","2 - 4mm", "4 - 8mm", "> 8mm","CreatedAt","CreatedBy","T1 Length","T2 Length","T1 width","T2 width","T1 Broken","T2 Broken","T1 L/W","T2 L/W","T1 Moisture","T2 Moisture","T1 Grain Count","T2 Grain Count","T1 Damaged Discolored","T2 Damaged Discolored","T1 Weeviled","T2 Weeviled","T1 Sava","T2 Sava","W Length","W Width","W L/W","W Broken","W Moisture","W Grain Count","W Damaged Discolored","W Weeviled","W Sava"]]

      Object.keys(productDetails).map((prodkey,index) => {
        let item=productDetails[prodkey];
        let grtProductDetailsPerItem = [];
        grtProductDetailsPerItem.push(item.itemnid)
        grtProductDetailsPerItem.push(item.description)
        grtProductDetailsPerItem.push(item.brand)
        grtProductDetailsPerItem.push(item.category)
        grtProductDetailsPerItem.push(item.sub_category)
        grtProductDetailsPerItem.push(item.grade)
        grtProductDetailsPerItem.push(item.length)
        grtProductDetailsPerItem.push(item.width)
        grtProductDetailsPerItem.push((parseFloat(item.length.replace(/[^\d.-]/g,''))/parseFloat(item.width.replace(/[^\d.-]/g,''))).toFixed(2))
        grtProductDetailsPerItem.push(item.size)
        grtProductDetailsPerItem.push(item.shape)
        grtProductDetailsPerItem.push(item.broken_grain_percentage)
        grtProductDetailsPerItem.push(item.broken_grains)
        grtProductDetailsPerItem.push(item.moisture)
        grtProductDetailsPerItem.push(item.grain_count)
        grtProductDetailsPerItem.push(item.dd_count)
        grtProductDetailsPerItem.push(item.weeviled_grain_per)
        grtProductDetailsPerItem.push(item.sava)        
        grtProductDetailsPerItem.push(item.price_range)
        grtProductDetailsPerItem.push(item.foreign_matter_per)
        grtProductDetailsPerItem.push(item.perimeter)
        grtProductDetailsPerItem.push(item.area)
        grtProductDetailsPerItem.push(item.grain_length1_percentage)
        grtProductDetailsPerItem.push(item.grain_length2_percentage)
        grtProductDetailsPerItem.push(item.grain_length3_percentage)
        grtProductDetailsPerItem.push(item.grain_length4_percentage)
        // grtProductDetailsPerItem.push(item.chalky_grain_per)
        // grtProductDetailsPerItem.push(item.color)
        // grtProductDetailsPerItem.push(item.live_dead_insect)
        // grtProductDetailsPerItem.push(item.moisture)
        // grtProductDetailsPerItem.push(item.paddy_grain_per)
        // grtProductDetailsPerItem.push(item.status)
        // grtProductDetailsPerItem.push(item.total_no_grains)
        // grtProductDetailsPerItem.push(item.type)
        // grtProductDetailsPerItem.push(item.variety)
        // grtProductDetailsPerItem.push(item.weeviled_grain_per)
        grtProductDetailsPerItem.push(item.date)
        grtProductDetailsPerItem.push(item.retailer_name)
        grtProductDetailsPerItem.push(item.length_threshold_abs)
        grtProductDetailsPerItem.push(item.length_threshold_abs2)
        grtProductDetailsPerItem.push(item.width_threshold_abs)
        grtProductDetailsPerItem.push(item.width_threshold_abs2)
        grtProductDetailsPerItem.push(item.broken_threshold_per)
        grtProductDetailsPerItem.push(item.broken_threshold_per2)
        grtProductDetailsPerItem.push(item.length_width_threshold_abs)
        grtProductDetailsPerItem.push(item.length_width_threshold_abs2)
        grtProductDetailsPerItem.push(item.moisture_threshold_abs)
        grtProductDetailsPerItem.push(item.moisture_threshold_abs2)
        grtProductDetailsPerItem.push(item.grain_count_threshold_abs)
        grtProductDetailsPerItem.push(item.grain_count_threshold_abs2)
        grtProductDetailsPerItem.push(item.dd_count_threshold_abs)
        grtProductDetailsPerItem.push(item.dd_count_threshold_abs2)
        grtProductDetailsPerItem.push(item.weeviled_grain_per_threshold_abs)
        grtProductDetailsPerItem.push(item.weeviled_grain_per_threshold_abs2)
        grtProductDetailsPerItem.push(item.sava_threshold_abs)
        grtProductDetailsPerItem.push(item.sava_threshold_abs2)        
        grtProductDetailsPerItem.push(item.length_weightage_per)
        grtProductDetailsPerItem.push(item.width_weightage_per)
        grtProductDetailsPerItem.push(item.length_width_weightage_per)
        grtProductDetailsPerItem.push(item.broken_weightage_per)
        grtProductDetailsPerItem.push(item.moisture_weightage_per)
        grtProductDetailsPerItem.push(item.grain_count_weightage_per)
        grtProductDetailsPerItem.push(item.dd_count_weightage_per)
        grtProductDetailsPerItem.push(item.weeviled_grain_per_weightage_per)
        grtProductDetailsPerItem.push(item.sava_weightage_per)        
        grtProductSummary.push(grtProductDetailsPerItem)
      });

      const grtProdExportDetails = XLSX.utils.aoa_to_sheet(grtProductSummary)
      XLSX.utils.book_append_sheet(wb, grtProdExportDetails, `GRT Product Details`)
      XLSX.writeFile(wb, `GRT_Product_Details.xlsx`)
      setExportData(false)
  }

  const exportGRTProdGradingDetails = () => {
    const wb = XLSX.utils.book_new()

    let grtProdGradingSummary = [["Date", "Phone", "Avg Length", "Avg Area","Avg Perimeter", "Avg Width", "BrokenGrainPerc","Broken Grains","Category","Description","Colour",">2mm","2-4mm","4-8mm",">8mm",
    "L/W Ratio","Major Category","Product ID","Product Name","Sample ID", "Shape", "Size","Sub Category","Supplier Name","Time","Total Grains","Base Price","Length Score","Length Result","Width Score","Width Result","L/W Ratio Score","L/W Ratio Result","Broken % Score","Broken % Result","Overall Index","Overall Result"]];

    let sampleIdSet=new Set();

    Promise.all([
      qualitydb.database().ref(`Product_Grading_Result/`).once('value'),
      qualitydb.database().ref(`Product_Master_Grading/`).once('value')
    ]).then(([snapshot, prodsnapshot]) => {
      let prodgrading=snapshot.val();
      let productmaster=prodsnapshot.val();

      console.log('prodgrading');
      console.log(prodgrading);
      Object.keys(prodgrading).map((prodkey,index) => {
        let date=prodkey;
        let prodgradingdate=prodgrading[prodkey];
        Object.keys(prodgradingdate).map((prkey,prindex) => {
          let phone=prkey;
          let prodgradingphone=prodgradingdate[prkey];
          Object.keys(prodgradingphone).map((phonekey,phoneindex) => {
            Object.keys(productmaster).map((prmkey,prmindex) => {
              if(productmaster[prmkey]['itemnid']==prodgradingphone[phonekey]['product_id'] && !sampleIdSet.has(prodgradingphone[phonekey]['sample_id'])){
                let item=prodgradingphone[phonekey];
                let grtProdgradingDetails = [];
                grtProdgradingDetails.push(date)
                grtProdgradingDetails.push(phone)
                grtProdgradingDetails.push(item.average_width)
                grtProdgradingDetails.push(item.average_rice_area)
                grtProdgradingDetails.push(item.average_rice_perimeter)
                grtProdgradingDetails.push(item.average_length)
                grtProdgradingDetails.push(item.broken_grain_percentage)
                grtProdgradingDetails.push(item.broken_grains)
                grtProdgradingDetails.push(item.category)
                grtProdgradingDetails.push(item.description)
                grtProdgradingDetails.push(item.dominant_color)
                grtProdgradingDetails.push(item.grain_length1_percentage)
                grtProdgradingDetails.push(item.grain_length2_percentage)
                grtProdgradingDetails.push(item.grain_length3_percentage)
                grtProdgradingDetails.push(item.grain_length4_percentage)
                grtProdgradingDetails.push((parseFloat(item.average_width.substring(0, item.average_width.length - 2))/parseFloat(item.average_length.substring(0, item.average_length.length - 2))).toFixed(2))
                grtProdgradingDetails.push(item.major_category)
                grtProdgradingDetails.push(item.product_id)
                grtProdgradingDetails.push(item.product_name)
                grtProdgradingDetails.push(item.sample_id)
                grtProdgradingDetails.push(item.shape)
                grtProdgradingDetails.push(item.size)
                grtProdgradingDetails.push(item.sub_category)
                grtProdgradingDetails.push(item.supplier_name)
                grtProdgradingDetails.push(item.time)
                grtProdgradingDetails.push(item.total_no_grains)
                grtProdgradingDetails.push(item.base_price)

                let reslist=getResultIndexScoreForTestSample(item,productmaster[prmkey]);
                if(reslist){
                  let finalProdList=reslist[2];
                  if(finalProdList.length>0){
                    for(let i=0;i<finalProdList.length;i++){
                      let result=finalProdList[i]['Result'];
                      let score=finalProdList[i]['Score'];
                      grtProdgradingDetails.push(score)
                      grtProdgradingDetails.push(result)
                    }
                  }else{
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                    grtProdgradingDetails.push('')
                  }
                  grtProdgradingDetails.push(reslist[0])
                  grtProdgradingDetails.push(reslist[1])
                }else{
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('')
                  grtProdgradingDetails.push('N/A')
                  grtProdgradingDetails.push('N/A')
                }

                grtProdGradingSummary.push(grtProdgradingDetails)
                sampleIdSet.add(prodgradingphone[phonekey]['sample_id']);
              }
            });
          });
        });
      });

      const grtProdGradingExportDetails = XLSX.utils.aoa_to_sheet(grtProdGradingSummary)
      XLSX.utils.book_append_sheet(wb, grtProdGradingExportDetails, `GRT Product Grading Details`)
      XLSX.writeFile(wb, `GRT_Product_Grading_Details.xlsx`)
      setExportProdGradingData(false)
    });
  }

  const getResult = (criteria,thresh1,thresh2, sample) => {
    let result;
    if(criteria == 'Higher') {
      if(sample>thresh1){
        result="Accept";
      }else{
        if(sample>thresh2){
          result="Warn";
        }else{
          result="Reject";
        }
      }
    } else {
      if(sample<thresh1){
        result="Accept";
      }else{
        if(sample<thresh2){
          result="Warn";
        }else{
          result="Reject";
        }
      }
    }
    return result;
  }

  const getResultIndexScoreForTestSample = (updateDetails,prod_data) => {
    // console.log('getResultIndexScoreForTestSample');
    // console.log(updateDetails);
    // console.log(prod_data);
    let product_id=updateDetails['product_id'];
    // console.log(product_id);
    let finalProdList=[];
    let parameterList=['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %'];
    if(product_id){
        if(prod_data!=undefined){
          let index_score_sum=0;
          for(let i=0;i<4;i++){
            let ele={};
            ele['Parameter']=parameterList[i];
            if(parameterList[i]=='Avg Length (mm)'){
                  let length_threshold_per=(prod_data['length_threshold_per']!=undefined?prod_data['length_threshold_per']:"5%");
                  length_threshold_per = parseInt(length_threshold_per.substring(0, length_threshold_per.length - 1));
                  let standard_length=prod_data['length'];
                  standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                  let thresh1=prod_data['length_threshold_abs']?parseFloat(prod_data['length_threshold_abs']):standard_length*(1+(length_threshold_per/100));
                  thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                  let sample_length=updateDetails['average_width'];
                  sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));
                  let criteria="Higher";
                  let score=sample_length/standard_length;
                  score=Math.round((score + Number.EPSILON) * 100);

                  ele['Sample Value']=updateDetails['average_width'];
                  ele['Standard Value']=prod_data['length'];
                  ele['Criteria']=criteria;


                  ele['Threshold Range 1']=thresh1;

                  let length_threshold_per2=(prod_data['length_threshold_per2']!=undefined?prod_data['length_threshold_per2']:"10%");
                  length_threshold_per2 = parseInt(length_threshold_per2.substring(0, length_threshold_per2.length - 1));
                  let thresh2=prod_data['length_threshold_abs2']?parseFloat(prod_data['length_threshold_abs2']):standard_length*(1+(length_threshold_per2/100));
                  thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                  let result = getResult(criteria,thresh1,thresh2,sample_length)
                  let weightage=(prod_data['length_weightage_per']!=undefined?prod_data['length_weightage_per']:"10%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));
                  index_score_sum=index_score_sum+(score*weightage);

                  ele['Threshold Range 2']=thresh2;
                  ele['Score']=score+'%';
                  ele['Weightage']=weightage;
                  ele['Result']=result;
                  // console.log(product_id+" ::Length:: "+result);
            }else if(parameterList[i]=='Avg Width (mm)'){
                  let width_threshold_per=(prod_data['width_threshold_per']!=undefined?prod_data['width_threshold_per']:"3%");
                  width_threshold_per = parseInt(width_threshold_per.substring(0, width_threshold_per.length - 1));
                  let standard_width=prod_data['width'];
                  standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));
                  let thresh1=prod_data['width_threshold_abs']?parseFloat(prod_data['width_threshold_abs']):standard_width*(1+(width_threshold_per/100));
                  thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                  let sample_width=updateDetails['average_length'];
                  sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));
                  let criteria;
                  let score;
                  if(prod_data['sub_category'].toLowerCase().includes("basmati") || updateDetails.sub_category.toLowerCase().includes("wheat")){
                    criteria="Higher";
                    score=sample_width/standard_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }else{
                    criteria="Lower";
                    score=standard_width/sample_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }

                  ele['Sample Value']=updateDetails['average_length'];
                  ele['Standard Value']=prod_data['width'];
                  ele['Criteria']=criteria;


                  ele['Threshold Range 1']=thresh1;

                  let width_threshold_per2=(prod_data['width_threshold_per2']!=undefined?prod_data['width_threshold_per2']:"5%");
                  width_threshold_per2 = parseInt(width_threshold_per2.substring(0, width_threshold_per2.length - 1));
                  let thresh2=prod_data['width_threshold_abs2']?parseFloat(prod_data['width_threshold_abs2']):standard_width*(1+(width_threshold_per2/100));
                  thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                  let result = getResult(criteria,thresh1,thresh2,sample_width)


                  let weightage=(prod_data['width_weightage_per']!=undefined?prod_data['width_weightage_per']:"35%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));
                  index_score_sum=index_score_sum+(score*weightage);

                  ele['Threshold Range 2']=thresh2;
                  ele['Score']=score+'%';
                  ele['Weightage']=weightage;
                  ele['Result']=result;
                  // console.log(product_id+" ::Width:: "+result);
            }else if(parameterList[i]=='LW Ratio'){
                  let length_width_threshold_per=(prod_data['length_width_threshold_per']!=undefined?prod_data['length_width_threshold_per']:"3%");
                  length_width_threshold_per = parseInt(length_width_threshold_per.substring(0, length_width_threshold_per.length - 1));
                  let standard_length=prod_data['length'];
                  standard_length = parseFloat(standard_length.substring(0, standard_length.length - 2));
                  let standard_width=prod_data['width'];
                  standard_width = parseFloat(standard_width.substring(0, standard_width.length - 2));

                  let standard_length_width=standard_length/standard_width;  // TO BE DONE ---------------------------------------------------------------------------
                  standard_length_width = Math.round((standard_length_width + Number.EPSILON) * 100) / 100;
                  let thresh1=prod_data['length_width_threshold_abs']?parseFloat(prod_data['length_width_threshold_abs']):standard_length_width*(1+(length_width_threshold_per/100));
                  thresh1=Math.round((thresh1 + Number.EPSILON) * 100) / 100;

                  let sample_length=updateDetails['average_width'];
                  sample_length = parseFloat(sample_length.substring(0, sample_length.length - 2));

                  let sample_width=updateDetails['average_length'];
                  sample_width = parseFloat(sample_width.substring(0, sample_width.length - 2));

                  let sample_length_width=sample_length>sample_width?parseFloat(sample_length/sample_width):parseFloat(sample_width/sample_length);
                  let criteria;
                  let score;
                  if(updateDetails.sub_category.toLowerCase().includes("wheat")){
                    criteria="Lower";
                    score=standard_length_width/sample_length_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }else{
                    criteria="Higher";
                    score=sample_length_width/standard_length_width;
                    score=Math.round((score + Number.EPSILON) * 100);
                  }

                  ele['Sample Value']=sample_length_width;
                  ele['Standard Value']=standard_length_width;
                  ele['Criteria']=criteria;
                  ele['Threshold Range 1']=thresh1;

                  let length_width_threshold_per2=(prod_data['length_width_threshold_per2']!=undefined?prod_data['length_width_threshold_per2']:"5%");
                  length_width_threshold_per2 = parseInt(length_width_threshold_per2.substring(0, length_width_threshold_per2.length - 1));
                  let thresh2=prod_data['length_width_threshold_abs2']?parseFloat(prod_data['length_width_threshold_abs2']):standard_length_width*(1+(length_width_threshold_per2/100));
                  thresh2=Math.round((thresh2 + Number.EPSILON) * 100) / 100;

                  let result = getResult(criteria,thresh1,thresh2,sample_length_width)

                  let weightage=(prod_data['length_width_weightage_per']!=undefined?prod_data['length_width_weightage_per']:"40%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));
                  index_score_sum=index_score_sum+(score*weightage);

                  ele['Threshold Range 2']=thresh2;
                  ele['Score']=score+'%';
                  ele['Weightage']=weightage;
                  ele['Result']=result;
                  // console.log(product_id+" ::LW Ratio:: "+result);
            }else if(parameterList[i]=='Broken %'){
                  let broken_threshold_per=(prod_data['broken_threshold_per']!=undefined?prod_data['broken_threshold_per']:"5%");
                  broken_threshold_per = parseFloat(broken_threshold_per.substring(0, broken_threshold_per.length - 1));
                  let standard_broken_perc=prod_data['broken_grain_percentage'];
                  standard_broken_perc = parseFloat(standard_broken_perc.substring(0, standard_broken_perc.length - 1));
                  let thresh1=broken_threshold_per;

                  let sample_broken_perc=updateDetails['broken_grain_percentage'];
                  sample_broken_perc = parseFloat(sample_broken_perc.substring(0, sample_broken_perc.length - 1));
                  let criteria="Lower";;
                  let score=sample_broken_perc >0?standard_broken_perc/sample_broken_perc:1;
                  score=Math.round((score + Number.EPSILON) * 100);

                  ele['Sample Value']=updateDetails['broken_grain_percentage'];
                  ele['Standard Value']=prod_data['broken_grain_percentage'];
                  ele['Criteria']=criteria;
                  ele['Threshold Range 1']=thresh1;

                  let broken_threshold_per2=(prod_data['broken_threshold_per2']!=undefined?prod_data['broken_threshold_per2']:"20%");
                  broken_threshold_per2 = parseFloat(broken_threshold_per2.substring(0, broken_threshold_per2.length - 1));
                  let thresh2=broken_threshold_per2;
                  let result = getResult(criteria,thresh1,thresh2,sample_broken_perc)

                  let weightage=(prod_data['broken_weightage_per']!=undefined?prod_data['broken_weightage_per']:"15%");
                  weightage = parseInt(weightage.substring(0, weightage.length - 1));

                  if(updateDetails.sub_category.toLowerCase().includes("basmati")){
                    ele['Criteria']=criteria;
                    ele['Score']='N/A';
                    ele['Weightage']='N/A';
                    ele['Result']='N/A';
                  }else{
                    ele['Criteria']=criteria;
                    index_score_sum=index_score_sum+(score*weightage);
                    ele['Score']=score+'%';
                    ele['Weightage']=weightage;
                    ele['Result']=result;
                  }
                  // console.log(product_id+" ::Broken %:: "+result);
            }
            finalProdList.push(ele);
          }
          // console.log(finalProdList);
          let overallResult;
          let accept_counter=0;
          let warn_counter=0;
          let reject_counter=0;
          for(let x=0;x<finalProdList.length;x++){
            if(!(finalProdList[x]['Parameter']=="Broken %" && updateDetails.sub_category.toLowerCase().includes("basmati"))){
              let orig_result=finalProdList[x]['Result'];
              if(orig_result=="Accept"){
                accept_counter=accept_counter+1;
              }else if(orig_result=="Warn"){
                warn_counter=warn_counter+1;
              }else if(orig_result=="Reject"){
                reject_counter=reject_counter+1;
              }
            }
          }

          if(updateDetails.sub_category.toLowerCase().includes("basmati")){
            if(reject_counter>0){
              overallResult="Reject";
            }else if(accept_counter>1){
              overallResult="Accept";
            }else{
              overallResult="Warn";
            }
          }else{
            if(reject_counter>0){
              overallResult="Reject";
            }else if(accept_counter>2){
              overallResult="Accept";
            }else{
              overallResult="Warn";
            }
          }


          index_score_sum=index_score_sum/100;
          index_score_sum=Math.round((index_score_sum + Number.EPSILON) * 100) / 100;
          // console.log('index_score_sum'+index_score_sum);

          let updateOrigGrtSampleRowValues=updateDetails;
          updateOrigGrtSampleRowValues['Index Score']=index_score_sum+'%';
          updateOrigGrtSampleRowValues['Result']=overallResult;
          // console.log(finalProdList);
          return [index_score_sum+'%',overallResult,finalProdList];
        }else{
          console.log('invalid product id');
          return ['N/A','N/A',[]];
        }
    }else{
      //product_id is not available
      let defaultProdList=[];
      for(let i=0;i<4;i++){
        let ele={};
        ele['Parameter']=parameterList[i];
        //['Avg Length (mm)','Avg Width (mm)','LW Ratio','Broken %']
        if(parameterList[i]=='Avg Length (mm)'){
          ele['Sample Value']=updateDetails['average_length'];
        }else if(parameterList[i]=='Avg Width (mm)'){
          ele['Sample Value']=updateDetails['average_width'];
        }else if(parameterList[i]=='LW Ratio'){
          ele['Sample Value']=updateDetails['length_width_ratio'];
        }else if(parameterList[i]=='Broken %'){
          ele['Sample Value']=updateDetails['broken_grain_percentage'];
        }

        ele['Standard Value']='N/A';
        ele['Threshold Range 1']='N/A';
        ele['Threshold Range 2']='N/A';
        ele['Score']='N/A';
        ele['Result']='N/A';
        defaultProdList.push(ele);
        return ['N/A','N/A',[]];
      }
    }
  }

  const exportGRTTestProductDetails = () => {
    const wb = XLSX.utils.book_new()

    let grtTestProdSummary = [["Avg Chalkiness", "Avg L/W Ratio", "Avg Width", "Avg Area","Avg Perimeter", "Avg Roundness Ratio", "Avg Length","Basmati Perc","Boiled Perc","Broken grains","BrokenGrainPerc","Category","Date","Colour","FragmentGrainPerc",
    "Total Grains","Kolam Perc","Lokwan Perc","Raw Perc","<2 mm", "2-4mm", "4-8mm",">8mm","Sample ID","Sehori Perc","Shape","Size","SizeShape","Steam Perc","Supplier Name"]]


    qualitydb.database().ref('Testing_Product_Details').once('value', function(snapshot) {
      let testprod=snapshot.val();
      Object.keys(testprod).map((tkey,tindex) => {
        let testprodyear=testprod[tkey];
        Object.keys(testprodyear).map((mkey,mindex) => {
          let testprodmonth=testprodyear[mkey];
          Object.keys(testprodmonth).map((dkey,dindex) => {
            let testproddate=testprodmonth[dkey];
            Object.keys(testproddate).map((skey,sindex) => {
              let item=testproddate[skey];
              let grtTestProdDetails = [];
              grtTestProdDetails.push(item.average_chalkiness)
              grtTestProdDetails.push(item.average_grain_length_width_ratio)
              grtTestProdDetails.push(item.average_height)
              grtTestProdDetails.push(item.average_rice_area)
              grtTestProdDetails.push(item.average_rice_perimeter)
              grtTestProdDetails.push(item.average_rice_roundness_ratio)
              grtTestProdDetails.push(item.average_width)
              grtTestProdDetails.push(item.basmati_percentage)
              grtTestProdDetails.push(item.boiled_percentage)
              grtTestProdDetails.push(item.broken_rice_count)
              grtTestProdDetails.push(item.broken_rice_percentage)
              grtTestProdDetails.push(item.category)
              grtTestProdDetails.push(item.date)
              grtTestProdDetails.push(item.dominant_color)
              grtTestProdDetails.push(item.fragment_rice_percentage)
              grtTestProdDetails.push(item.grain_count)
              grtTestProdDetails.push(item.kolam_perc)
              grtTestProdDetails.push(item.lokwan_perc)
              grtTestProdDetails.push(item.raw_percentage)
              grtTestProdDetails.push(item.rice_width_subgroups.less_than_2)
              grtTestProdDetails.push(item.rice_width_subgroups.between_2_to_4)
              grtTestProdDetails.push(item.rice_width_subgroups.between_4_to_8)
              grtTestProdDetails.push(item.rice_width_subgroups.greater_than_8)
              grtTestProdDetails.push(item.sample_id)
              grtTestProdDetails.push(item.sehori_perc)
              grtTestProdDetails.push(item.shape)
              grtTestProdDetails.push(item.size)
              grtTestProdDetails.push(item.sizeShapeClassifier)
              grtTestProdDetails.push(item.steam_percentage)
              grtTestProdDetails.push(item.supplier_name)
              grtTestProdSummary.push(grtTestProdDetails)
            });
          });
        });
      });
      const grtTestProdExportDetails = XLSX.utils.aoa_to_sheet(grtTestProdSummary)
      XLSX.utils.book_append_sheet(wb, grtTestProdExportDetails, `GRT Test Product Details`)
      XLSX.writeFile(wb, `GRT_Test_Product_Details.xlsx`)
      setExportTestProductData(false)
    });
  }

  const ShowOnInitLoadComp = () => {
    return (
          <Fragment>
              <ProductData productDetails={productDetails} />
          </Fragment>
      );
  };

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

  return (
      <>
      <NavBar />
      {/*(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && */}
      <div className="row" style={{paddingTop:100,paddingLeft:10,marginLeft:15}}>
        <div className="col-md-3">
          <Form.Control type="search" placeholder="Search ItemID/Brand/Description/Category " value={searchText} onChange={(e)=>{ handleKeyPress(e); }}/>
        </div>
        <div className="col-md-2">
          <Button variant={"warning"} onClick={() => setExportData(true)}>Export ProductMaster</Button>
        </div>
        <div className="col-md-2">
          <Button variant={"warning"} onClick={() => setExportProdGradingData(true)}>Export ProductGrading</Button>
        </div>
        <div className="col-md-2">
          <Button variant={"warning"} onClick={() => setExportTestProductData(true)}>Export TestProduct</Button>
        </div>
      </div>

          <MainContainer>
              {(Object.keys(productDetails).length>0) ? <ShowOnInitLoadComp /> : ((searchText)?(<div style={{margin:40}}>No product data match the search</div>) :<LoaderComp />) }
          </MainContainer>
      </>
  );
};

export default GrainRecognitionProduct;
