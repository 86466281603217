import React, { useState } from "react";
import {
  Table,
  Form,
  Alert,
  Dropdown,
  DropdownButton,
  Button,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import firebase from "../../../service/firebaseConfig";
import { support_portal_url } from "../../../service/servicesConfig";

function DeleteUserModalPopup({ deletedUser, ...props }) {
  console.log("AddUserModalPopup");

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const deleteUser = () => {
    console.log("deleteUser");
    console.log(deletedUser);
    if (!deletedUser.id) {
      toast("Invalid user id.", { type: toast.TYPE.ERROR });
    } else {
      fetch(`${support_portal_url}/api/superkreditportaluser`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("apiToken"),
        },
        body: JSON.stringify(deletedUser),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          if (data["success"] == 1) {
            toast("Deleted user.", { type: toast.TYPE.SUCCESS });
            props.onHide();
            props.callback(deletedUser);
          } else {
            let errMessage = data["message"] ? data["message"] : "";
            toast("Error in deleting user : " + errMessage, {
              type: toast.TYPE.ERROR,
            });
            if (data["status"] == 401 || data["status"] == 403) {
              sessionStorage.clear();
              window.location.href = "/";
            }
          }
        })
        .catch((err) => {
          toast("Error in deleting user : " + err, { type: toast.TYPE.ERROR });
        });
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <p>Do you really want to delete the user?</p>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"success"} onClick={deleteUser}>
            Yes
          </Button>
          <Button onClick={props.onHide}>No</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteUserModalPopup;
