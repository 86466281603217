import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import database from '../../service/firebaseConfig';
import { toast } from 'react-toastify';

function UpdatePurchaseOrderModal({updateFullDetails,...props}) {
    const [itemDetailsObject, setItemDetailsObject] = useState({})
    const [maxInventory, setMaxInventory] = useState("");

    useEffect(() => {
      console.log("updateFullDetails");
      console.log(updateFullDetails);
    }, [])

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const GrtFullListTableBody = () => {
        let rows=[];

        if(updateFullDetails) {
            let count =0;
            rows=updateFullDetails.map(items => {
                count=count+1;
                return (
                    <tr key={count}>
                        <td>{items[0]}</td>
                        <td>{items[1]}</td>
                    </tr>
                );
            });
          }else{
            rows=[];
          }

        return (<><tbody>{rows}</tbody></>);
    }

    return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Detailed Report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Table>
                    {(updateFullDetails ?
                        Object.keys(updateFullDetails).map((key, index) => (
                            (key != "scores") ? 
                                (<tr>
                                    <td><b>{key} : </b></td>
                                    <td>{updateFullDetails[key]}</td>
                                </tr>)
                            : (
                                <tr>
                                    <td><b>{key} : </b></td>
                                    <td></td>
                                </tr>                                
                            )
                        )) :(<div>Data is empty</div>))}
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
    )
}

export default UpdatePurchaseOrderModal
