import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  Stack,
  Select,
  TextInput,
  Divider,
  Text,
  Table,
  Box,
  NumberInput,
  Modal,
  Flex,
  Space,
} from "@mantine/core";
import { toast } from "react-toastify";
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";


export function DeleteModal({ id, opened, setOpened, refreshListData  }) {


    const onDeleteConfirm = useCallback(() => {
  
      const body = {
        work_order_id : id
      }
      const url = `${DELIVERY_BASE_URL}/api/superzop/workorder/deleteWorkOrders`;
  
  
    //   console.log('check body ', body)
      fetch(url, {method: 'DELETE', body : JSON.stringify(body),  
      headers: {
        "Content-Type": "application/json",
      }}).then((res) => {
  
        setOpened(false)
        if (res.status != 200) {
         
          return toast("Error : Could not delete record", {
            type: toast.TYPE.ERROR,
          });
        }
        toast('Record deleted successfully', {type: toast.TYPE.SUCCESS})
        refreshListData();
      }).catch(err => {
        setOpened(false)
        toast('Error : Could not delete record', {type: toast.TYPE.ERROR})
      })
  
    }, [])
  
  
    return (
      <Modal opened={opened} onClose={() => setOpened(false)} title="Delete">
        <Stack spacing={"1.5rem"} align="stretch" justify="center">
          
            {`Are you sure you want to delete this ID - ${id}?`}
            
          <Space v={"sm"} />
          <Group grow style={{ width: "100%" }}>
            <Button color="teal" onClick={() => {
              onDeleteConfirm()
            }}>Confirm</Button>
            <Button color="red" onClick={() => setOpened(false)}>Cancel</Button>
          </Group>
        </Stack>
      </Modal>
    );
  }
  