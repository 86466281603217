import React, { useState,useEffect } from 'react'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Tab, Row, Col, Form, Dropdown, ProgressBar, Tabs, Image, InputGroup, Button, DropdownButton, Breadcrumb, Table, ButtonGroup, Container, Accordion, Card, Navbar } from 'react-bootstrap'
import { FaPencilAlt } from 'react-icons/fa';

const GrtAnalysisTableHeader = (props) => {
    const { obsvIndexHeader } = props;
    return (
        <thead className="thead-light">
            <tr>
                <td>SKU Code</td>
                <td>SKU Name</td>
                <td>Category</td>
                <td>Sub-Category</td>
                <td>Brand</td>
                <td>Supplier Name</td>
                {obsvIndexHeader && obsvIndexHeader.map(itemHeader => (
                    <td>{itemHeader}</td>
                ))}
            </tr>
        </thead>
    );
}

const GrtAnalysisTableBody = props => {
  let rows;
    if(props.grtProdData){
      rows=props.grtProdData.map((item, index) => {
            return (
                <tr>
                    {item && item.map((subItems, subIndex) => (
                        subIndex !== item.length - 1 ? <td>{subItems}</td> :
                            subItems.map(obsVal => (
                                <td>{obsVal}</td>
                            ))
                    ))
                    }
                </tr>
            );
        });
    }else{
        rows = [];
    }

    return (<><tbody>{rows}</tbody></>);
}

const GrtAnalysisTable = (props) => {
    const { grtProdData,obsvIndexHeader } = props;
    return (
        <table className="table table-striped" style={{marginTop:10}}>
            <GrtAnalysisTableHeader obsvIndexHeader={obsvIndexHeader} />
            <GrtAnalysisTableBody grtProdData={grtProdData} />
        </table>
    );
}

export default GrtAnalysisTable
