import React, { useState } from "react";
import database from "../../service/firebaseConfig";
import { Button } from "react-bootstrap";
import ImageUploader from "react-images-upload";
// import FileThumbnail from "react-uploaded-video-preview";
import Backdrop from "../../CommonRequirements/backdrop";

const VideoUpload = ({ fileName, children, ...props }) => {

  const { width, height, src } = props;

  const inputFile = React.useRef(null);

  const [source, setSource] = React.useState(src);



  const handleFileChange = (event) => {
    console.log(`event`,event)
    upLoadFile(event.target.files[0]);
    console.log(event);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
  };

  const handleChoose = (event) => {
    inputFile.current.click();
  };
  

  const [backDrop, setBackDrop] = useState(false);

  let modifiedFile = () => {
    if(fileName) {

     return ( fileName.replace(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/ /g, "_"))
    }  

  } 
  let modifiedFileName = modifiedFile();
   
  

  const upLoadFile = (files) => {
  
    setBackDrop(true);
    const storag = database.storage();
    const storeData = storag.ref();
    var uploadTask = storeData
      .child(`superzop_ordering_product_videos/${modifiedFileName}.mp4`)
      .put(files);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        console.log(snapshot.state);
        switch (snapshot.state) {
          case "paused": // or 'paused'
            console.log("Upload is paused");
            break;
          case "running": // or 'running'
            console.log("Upload is running");
            break;
          default:
            console.log("Upload error");
        }
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
          props.newUrl(downloadURL);
          setBackDrop(false);
        });
      }
    );
  };
  return (
    <>
    
 <div className="VideoInput">
 {source ? source && (
  <>
        <video
          className="VideoInput_video"
          width="150px"
          height="190px"
          controls
          src={source}
          />
           <input style={{display:"none"}}
          ref={inputFile}
          className="VideoInput"
          type="file"
          onChange={handleFileChange}
          
          accept=".mov,.mp4, video/*"
          />
          <button onClick={handleChoose} style={{cursor:"pointer", marginTop:"1rem"}} >Change Product Video</button>
          </>
        ) :(
          <>
          <input style={{display:"none"}}
          ref={inputFile}
          className="VideoInput_input"
          type="file"
          onChange={handleFileChange}
          accept=".mov,.mp4, video/*"
          />
          <button onClick={handleChoose} style={{cursor:"pointer"}} >Upload Product Video</button>
          </>
        )  
      }
    </div>

    {/* 
     
       {children ? children : ""} 
       
      <FileThumbnail
    file={modifiedFileName}
    // Optional:
    startTime={3000}
    canvasSize={900}
    startTimeFail={2}
    finalFail={"end"}
    DefaultFailedOrientation={"Horizontal"}
    altText={"Custom Alt Text for screen readers"}
    // CustomFailedFileImageHorizontal={CustomFailedHorizontal}
    // CustomFailedFileImageVertical={CustomFailedVertical}
    onChange={(val) => {
        upLoadFile(val);
        console.log(val);
      }}
    />
   
    
     
      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
      
      <video
        withIcon={false}
        withLabel={false}
        singleImage
        buttonStyles={{ backgroundColor: "white" }}
        buttonText={children}
        onChange={(val) => {
          upLoadFile(val);
          console.log(val);
        }}
        imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
        maxFileSize={5242880}
      ></video>
      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
       */}
    </>
  );
};

export default VideoUpload;


 



/*
const VideoUpload = ({ fileName, children, ...props }) => {
  console.log(props)
  console.log(props.src)
  const [videoSrc , seVideoSrc] = useState(props.src);

  const handleChange = ({file}) => {
    var reader = new FileReader();
    console.log(file)
    var url = URL.createObjectURL(file.originFileObj);
    seVideoSrc(url);
};
    

  return (
    <>
    
    <div className="action">
                <Upload className="mt-3 mb-3"
                    accept=".mp4"
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="video"
                    maxCount={1}
                    onChange={handleChange}>
                    <Button>
                       Upload
                    </Button>
                </Upload>
    </div>
    <Player
          playsInline
          src={videoSrc}
          fluid={false}
          width={100}
          height={100}
      />
    </>
  )
}*/

// export default VideoUpload;
