import React, { useEffect, useState, useRef } from 'react'
import { Breadcrumb, Dropdown, DropdownButton, Alert, Tab, Row, Col, Nav, Table, Button, Form, Modal, Badge, Navbar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import database from '../../service/firebaseConfig';
import {ORDERING_BASE_URL} from '../../service/servicesConfig';
import { FaWindows, FaLessThanEqual } from 'react-icons/fa';
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import UserEmail from '../../CommonRequirements/FetchUserEmailId'
import UpdatedDate from '../../CommonRequirements/UpdatedDate'
import UpdatedTime from '../../CommonRequirements/UpdatedTime'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Switch from "react-switch";


const ProductDumpDetails = database.database().ref(`Product_Dump`)
const Product_Base = database.database().ref(`Product_Base_Data`)
const SuppliersDB = database.database().ref(`Suppliers`)

function ViewProductDump({ data, dataId, ...props }) {


    const [dumpDate, setProductDumpDate] = useState("")
    const [category, setCategory] = useState("")
    const [articleCode, setArticleCode] = useState("")
    const [itemName, setItemName] = useState("")
    const [outerType, setOuterType] = useState("")
    const [qtyDumped, setQtyDumped] = useState("")
    const [articleInfo, setArticleInfo] = useState("")
    const [articleOptions, setArticleOptions] = useState("")
    const [disableList, setDisableList] = useState(false)
    const [dateToModify, setDateToModify] = useState(new Date())
    const [validated, setValidated] = useState(false)
    const [dumpReason, setDumpReason] = useState("")
    const [approvalStatus, setApprovalStatus] = useState(data.approve_status == "Approved" ? true : false)
    const [salvageRate, setSalvageRate] = useState("")
    const [salvageAmt, setSalvageAmt] = useState("")
    const [uomValue, setUomValue] = useState("")

    const PurchaseData = []
    const DateofPurchase = []
    let purchaseItems = {};
    const categoryField = useRef();
    const itemNoField = useRef();
    const itemNameField = useRef();
    const totalWtPurchasedRef = useRef();
    const datepickerRef = useRef();
    const quantityDumpedRef = useRef();
    let btnRef = useRef();
    const salvageRateRef = useRef()

    const ListOfReasons = [{ "value": "Damaged/Spoilt Product", "label": "Damaged/Spoilt Product" },
    { "value": "Expired Product", "label": "Expired Product" },
    { "value": "Wet Product", "label": "Wet Product" },
    { "value": "Infestation", "label": "Infestation" },
    { "value": "Bad Quality", "label": "Bad Quality" }]

    const getSelectedDate = sessionStorage.getItem("datekey") ? new Date(sessionStorage.getItem("datekey")) : "";
    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            return toast("Product Dump Details updated", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't update the Dump Details", { type: toast.TYPE.ERROR });
    };


    const fetchProductItem = () => {
        const productBaseInfo = []
        Product_Base.once("value", snapShot => {
            const productItems = snapShot.val();
            Object.keys(productItems).forEach(productId => {
                if (productItems[productId]["buying_flag"] == 'Y') {
                    productBaseInfo.push({ "articleId": productId, "category": productItems[productId].category, "lot_size": productItems[productId].lot_size,"uom": productItems[productId].uom, "brand": productItems[productId].brand, "variety": productItems[productId].variety, "itemName": productItems[productId].long_description })
                }
            })
        }).then(() => { setArticleInfo(productBaseInfo) })
    }
    useEffect(() => {
        fetchProductItem()
        console.log(datepickerRef.current)
        console.log(data)
    }, [])



    const populateArticle = () => {

        const allOptionsArticle = []
        articleInfo.forEach(item => {
            let itemName = item.itemName
            if (item.brand)
                itemName = item.brand + " - " + item.itemName;

            allOptionsArticle.push({ "value": itemName, "label": itemName })
        })
        setArticleOptions(allOptionsArticle)
    }
    useEffect(() => {
        if (articleInfo)
            populateArticle()
    }, [articleInfo])

    const insertDomData = (itemValue, articleCode) => {
        if (articleCode) {
            setDisableList(false);

            articleInfo.forEach(item => {
                if (articleCode.includes(item.articleId)) {
                    setDisableList(true);
                    categoryField.current.value = item.category
                    let itemValue = item.itemName
                    if (item.brand)
                        itemValue = item.brand + " - " + item.itemName;
                    console.log("Updating based on ArticleCode:: " + articleCode + " Article Name :: " + itemValue + " Category:: " + item.category);
                    setCategory(item.category)
                    setItemName(itemValue)
                    setOuterType(item.variety)
                    setUomValue(item.uom)
                    quantityDumpedRef.current.value = item.lot_size
                    setQtyDumped(Number(item.lot_size))
                    console.log("LotSize" + item.lot_size)
                }
            })
        } else if (itemValue) {
            articleInfo.forEach(item => {
                let itemName = item.itemName
                if (item.brand)
                    itemName = item.brand + " - " + item.itemName;

                if (itemValue == itemName) {
                    console.log("Updating based on ArticleName:: " + itemValue + " Article ID :: " + item.articleId + " Category:: " + item.category);
                    setCategory(item.category)
                    categoryField.current.value = item.category
                    quantityDumpedRef.current.value = item.lot_size
                    setQtyDumped(Number(item.lot_size))
                    setUomValue(item.uom)
                    setArticleCode(item.articleId)
                    itemNoField.current.value = item.articleId
                    setOuterType(item.variety)
                }
            })
        }
    }

    const PushPurchaseDetails = (event) => {


        let todaysDate = ""
        let currentMn = ""
        let currentDt = ""
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let productDumpDate = data.dump_date;
            btnRef.current.setAttribute("disabled", "disabled");

            const updatedData = {"dump_date": productDumpDate, "category": category ? category : data.category, "article_code": articleCode ? articleCode : data.article_code,
            "item_name": itemName ? itemName : data.item_name, "uom": uomValue ? uomValue : data.uom, "qty_dumped": qtyDumped ? qtyDumped : data.qty_dumped, "reason": dumpReason ? dumpReason : data.reason,
            "salvage_rate": salvageRate || data.salvage_rate,
            "salvage_amt": salvageAmt || data.salvage_amt,
            "approve_status": approvalStatus ? "Approved" : "Unapproved", "authorized_by": UserEmail()}

            console.log(updatedData)

            //update in MySql

            fetch(ORDERING_BASE_URL+`/api/superzop/commercial/productdump/updateproductdumprecord?key=${productDumpDate}-${articleCode ? articleCode : data.article_code}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            }).then(response => Promise.resolve(response))
                .then(data => {
                    if(data["status"]=="200"){
                    console.log(data)
                    return toast("Updated Product Dump record in MySQL", { type: toast.TYPE.SUCCESS });
                    }
                })
            // update in firebase

            ProductDumpDetails.child(`${productDumpDate.split("-")[2]}/${productDumpDate.split("-")[1]}/${productDumpDate}`).child(data.uniqKey).update(updatedData).then(() => {
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                notifyUser(true);
            })
        }

        setValidated(true)

    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    const dumpList = () => {
        window.open("#/productDumpList");
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={PushPurchaseDetails}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        View Product Dump
                </Modal.Title>
                </Modal.Header>
                <Modal.Body {...props}>
                        <div>
                            <Table size="sm" >
                                <tr>
                                    <td>Dump Date</td>
                                    <td>
                                         <Form.Control readOnly placeholder={data.dump_date} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Item Name</td>
                                    <td>
                                            <Form.Control ref={itemNameField} value={itemName} readOnly placeholder={data.item_name} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Article Code</td>
                                    <td >
                                        {(itemName == "" || disableList) ?
                                            <Form.Control ref={itemNoField} readOnly onChange={(e) => { setArticleCode(e.target.value); let itemCode = e.target.value; let itemName = null; insertDomData(itemName, itemCode) }} placeholder={data.article_code} /> :
                                            <Form.Control ref={itemNoField} readOnly onChange={(e) => { setArticleCode(e.target.value); let itemCode = e.target.value; let itemName = null; insertDomData(itemName, itemCode) }} readOnly placeholder={data.article_code} />}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>
                                        <Form.Control ref={categoryField} readOnly placeholder={data.category} />
                                    </td></tr>
                                    <tr>
                                <td>UOM</td>
                                    <td><Form.Control value = {uomValue?uomValue:data.uom} readOnly onChange={(e) => {setUomValue(e.target.value)}} />
                                    </td>
                                </tr>

                                {/* <tr>
                                    <td>Outer Type</td>
                                    <td><DropdownButton id="dropdown-basic-button" title={outerType ? outerType : data.outer_type}>
                                        <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"SMALL PACK"} >SMALL PACK</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"GONI"} >GONI</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"BAG OF PACKS"} >BAG OF PACKS</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => setOuterType((e.target.name))} name={"HIGH VALUE OF LOOSE"} >HIGH VALUE OF LOOSE</Dropdown.Item>
                                    </DropdownButton>
                                    </td>
                                </tr> */}
                                <tr>
                                    <td>Qty Dumped(Kg/Pc)</td>
                                    <td>
                                        <Form.Control type="number" ref={quantityDumpedRef} readOnly onChange={(e) => { setQtyDumped(e.target.value); setSalvageAmt(salvageRateRef.current.value ? Number(salvageRateRef.current.value) * Number(e.target.value) : data.salvage_rate ? (Number(data.salvage_rate) * Number(e.target.value)) : "") }} placeholder={data.qty_dumped} />
                                        <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                    </td></tr>
                                <tr>
                                    <td>Salvage Rate</td>
                                    <td>
                                        <Form.Control ref={salvageRateRef} readOnly onChange={(e) => { setSalvageRate(e.target.value); setSalvageAmt(quantityDumpedRef.current.value ? Number(quantityDumpedRef.current.value) * Number(e.target.value) : data.qty_dumped ? (Number(data.qty_dumped) * Number(e.target.value)) : "") }} placeholder={data.salvage_rate} />
                                        <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                    </td></tr>
                                <tr>
                                    <td>Salvage Amount</td>
                                    <td>
                                        <Form.Control readOnly onChange={(e) => { setSalvageAmt(e.target.value) }} value={salvageAmt && salvageAmt} placeholder={data.salvage_amt} />
                                        <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                    </td></tr>
                                <tr>
                                    <td>Reason</td>
                                    <td><Form.Control readOnly disabled={true} placeholder={data.reason} /></td>
                                </tr>
                                <tr>
                                    <td>Approve</td>
                                    <td><Switch onChange={(val) => { setApprovalStatus(val) }} disabled={true} checked={approvalStatus} /></td>
                                </tr>
                            </Table>
                            <br />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="success" type="submit" ref={btnRef}>Update Dump Details  </Button> */}
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}

export default ViewProductDump
