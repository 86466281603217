import React, { useState, useEffect } from "react";
import { Navbar, Form, Badge, Button, Table,Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar';
import xlsx from 'xlsx';
import baseUrl from '../../service/servicesConfig';
import { orderingServer } from "../../service/servicesConfig";
import { toast } from 'react-toastify';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import Backdrop from '../../CommonRequirements/backdrop';
import database from '../../service/firebaseConfig';


function UploadStockTake() {
    const [getDate, setDate] = useState(new Date())
    const [stockTakeFileData, setStockTakeFileData] = useState([]);
    const [getBackDrop, setBackDrop] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [productsData, setProductsData] = useState({});
    const [uploadedItems, setUploadedItems] = useState([]);
    const Product_Base = database.database().ref(`Product_Base_Data`)


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
        if(isEmpty(productsData)) {
            Product_Base.once("value", snapShot => {
                console.log("Set Product Base data")
                console.log(snapShot.val());
                setProductsData(snapShot.val());
            })
        }
    }, []);

    const isEmpty = (obj) =>  {
        return Object.keys(obj).length === 0;
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    const handleStockTakeFile = (val) => {
        setBackDrop(true);
        const reader = new FileReader();
        console.log("validating uploaded values");
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(val)
            fileReader.onload = (e) => {
              const bufferArray = e.target.result;
              const wb = xlsx.read(bufferArray, { type: 'buffer' })
              const wsName = wb.SheetNames[0]
              const ws = wb.Sheets[wsName]
              const data = xlsx.utils.sheet_to_json(ws, { header: 1 })
              resolve(data)
            }
            fileReader.onerror = (error) => {
              reject(error)
            }
          })
      
          promise.then((data) => {
            console.log(JSON.parse(JSON.stringify(data)));
            const validate = validateStockFileDataHeadings(data[0]);
            if (validate) {
                console.log("Setting Uploaded Values");
                setUploadedItems(data)
            } else {
                setBackDrop(false);
                return toast("Please upload Stock Take file with a valid Template", { type: toast.TYPE.ERROR });
            }
          })
    }

    // update product details of item
    useEffect(() => {
        if(uploadedItems && productsData){
            const currYear = getDate.getFullYear();
            const currMonth = getDate.getMonth().toString().length === 1 ? `0${getDate.getMonth() + 1}` : getDate.getMonth() + 1;
            const currDate = getDate.getDate().toString().length === 1 ? `0${getDate.getDate()}` : getDate.getDate();
            const stkTakCompDate = `${currYear}-${currMonth}-${currDate}`;
            const stkTakCompDateRev = `${currDate}-${currMonth}-${currYear}`;
            console.log('updateProductDetails stkTakCompDate: ', stkTakCompDate);
            const created_by = sessionStorage.getItem(LOGGED_USER_EMAIL);
            uploadedItems.splice(0, 1);
            const dataMofiy = uploadedItems.map(item => {
                const unique_key = `${stkTakCompDateRev}-${item[0]}`;
                const obj = {
                    //"stock_date": stkTakCompDate,
                    "stock_date": stkTakCompDateRev,
                    "category": '',
                    "article_code": item[0],
                    "sub_category": '',
                    "UOM": item[3],
                    "item_name": item[1],
                    "outer_size": item[2],
                    "actual_stock_bag_box": item[4],
                    "actual_stock": item[5]?item[5]:item[4],
                    "stock_loss_gain": item[7],
                    "approve_stock_loss_gain": item[7],
                    "system_inventory": item[6],
                    "unique_key": unique_key,
                    "created_by": created_by,
                    "authorized_by":created_by
                }

                if (productsData[item[0]]) {
                    obj.category = productsData[item[0]]["category"];
                    obj.sub_category = productsData[item[0]]["sub_category"];
                    obj.outer_size = productsData[item[0]]["outer_size"];
                    obj.item_name = productsData[item[0]]["long_description"];
                    obj.UOM = productsData[item[0]]["uom"];
                }
                if (obj.UOM != item[3]) {
                    if(obj.UOM == 'PC') {
                        obj.actual_stock = obj.actual_stock_bag_box;
                    } else {
                        console.log("item_id::: "+obj.article_code);
                        console.log("UOM::: "+obj.UOM);
                    }
                }
                return obj;
            })
            setStockTakeFileData(dataMofiy);
            setBackDrop(false);
            setShowUploadButton(true);
        }
    }, [productsData,uploadedItems]);

    const validateStockFileDataHeadings = (headingsArr) => {

        const haystack = ["Item Code", "Item Description", "Outer Size (Kg/Pc)", "UoM", "Physical stock", "Total Weight", "System Inventory",	"Diff."];

        const res = headingsArr.every(val => {
            return haystack.includes(val);
        });

        return res;
    }

    const uploadBulkStocKTake = () => {

        console.log('uploadBulkStocKTake');
        setBackDrop(true);
        let stockTakeFileDataArr = stockTakeFileData;

        const stockTakeFileLen = stockTakeFileDataArr.length;

        if (stockTakeFileLen != 0) {
            let month = getDate.getMonth() + 1;
            month = month.toString().length == 1 ? "0" + month : month;
            const date = getDate.getDate().toString().length == 1 ? "0" + getDate.getDate() : getDate.getDate();
            const stock_date = `${date}-${month}-${getDate.getFullYear()}`;
            console.log('stockTakeFileDataArr: ',stockTakeFileDataArr);

            const uploadItems = [];
            stockTakeFileDataArr.forEach(stockTakeItem => {
                const obj = {
                    ...stockTakeItem,
                    "stock_date": stock_date,
                    "unique_key": `${stock_date}-${stockTakeItem.article_code}`
                }

                uploadItems.push(obj);
            });

            console.log('stockTakeFileDataArr After: ',uploadItems);

            const bulkUplUrl = '/api/superzop/commercial/productstocktake/bulkinsertproductstocktake';

            baseUrl.post(bulkUplUrl, uploadItems).then(() => {
                setTimeout(() => {
                    setBackDrop(false);
                    setStockTakeFileData({});
                }, 2000);
                return toast("Uploaded Successfully", { type: toast.TYPE.SUCCESS });
            }).catch(error => {
                console.error(error);
                setBackDrop(false);
            });

        }
    }

    return (
        <>
            <NavBar />
            <Alert style={{ paddingTop: 100 , display: "flex", textAlign: "justify", alignItems: "center", justifyContent: "center" }} variant={"success"}>
                        Stock Take | Bulk Upload </Alert>
            <Navbar className="justify-content-center" >
                <Form.Group controlId="formFileLg">
                    <input type="file" id="input" accept=".xlsx" onChange={(e) => {handleStockTakeFile(e.target.files[0])}} /> 
                    <Badge variant="dark">Select Stock Take Date </Badge>
                    <DatePicker
                        selected={getDate}
                        onChange={(val) => {setDate(val)}}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        className="red-border"
                        customInput={<CustomDateInput />}
                    />
                </Form.Group>
            </Navbar>
            <br /><br />
            <Table striped bordered style={{ padding: "20em" }}>
                <thead>
                    <tr>
                        <td>
                            <center>Item ID</center>
                        </td>
                        <td>
                            <center>Item Name</center>
                        </td>
                        <td>
                            <center>Category</center>
                        </td>
                        <td>
                            <center>Sub Category</center>
                        </td>
                        <td>
                            <center>Outer Size (Kg/Pc)</center>
                        </td>
                        <td>
                            <center>UOM</center>
                        </td>
                        <td>
                            <center>Actual Stock in Bag / Box</center>
                        </td>
                        <td>
                            <center>Actual Stock (Kg/Pc)</center>
                        </td>
                        <td>
                            <center>Stock Loss / Gain (Kg/Pc) </center>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(stockTakeFileData).map((items, index) => (
                        <>
                            <tr key={index}>
                                <td><center>{stockTakeFileData[items].article_code} </center></td>
                                <td><center>{stockTakeFileData[items].item_name} </center></td>
                                <td><center>{stockTakeFileData[items].category} </center></td>
                                <td><center>{stockTakeFileData[items].sub_category} </center></td>
                                <td><center>{stockTakeFileData[items].outer_size} </center></td>
                                <td><center>{stockTakeFileData[items].UOM} </center></td>
                                <td><center>{stockTakeFileData[items].actual_stock_bag_box} </center></td>
                                <td><center>{stockTakeFileData[items].actual_stock} </center></td>
                                <td><center>{stockTakeFileData[items].stock_loss_gain} </center></td>
                            </tr>
                        </>))}
                </tbody>
            </Table>
            <Navbar className="justify-content-center" >
                {isEmpty(stockTakeFileData) ? null : <Button style={{ margin: 20 , display: "flex", textAlign: "justify", alignItems: "center", justifyContent: "center" }} variant={"success"} variant="warning" onClick={() => { uploadBulkStocKTake() }}>Upload</Button>}
            </Navbar>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}

        </>
    );

}

export default UploadStockTake;