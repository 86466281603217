import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Alert,
  Dropdown,
  DropdownButton,
  Card,
  Table,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Accordion,
} from "react-bootstrap";
import { InputGroupAddon } from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
import Creatable from "react-select/creatable";
import Switch from "react-switch";
import request from "request";
import baseUrl from "../../service/servicesConfig";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { getUserType, validateIsAdminUser } from "../../service/credentials";
import moment from "moment";

const SuppliersDB = database.database().ref(`Suppliers`);
const PurchaseDetailsData = database.database().ref(`Purchase_Details`);
const Product_Base = database.database().ref(`Product_Base_Data`);

const sessionDate = new Date();
let sessionMonth = sessionDate.getMonth() + 1;
if (sessionMonth < 10) sessionMonth = "0" + sessionMonth;
let sessionDt = sessionDate.getDate();
if (sessionDt < 10) sessionDt = "0" + sessionDt;

function EditGRNModal({
  data,
  itemCode,
  selectedDate,
  selectedMonth,
  selectedYear,
  ...props
}) {
  const [suppliersList, setSuppliersList] = useState();

  const [productEditObject, setProductEditObject] = useState({});
  const [materialReceivingDt, setMaterialReceivingDt] = useState(
    data.grn_date && data.grn_date !== ""
      ? new Date(
          data.grn_date.split("-")[0],
          data.grn_date.split("-")[1] - 1,
          data.grn_date.split("-")[2]
        )
      : new Date()
  );
  const [bagRecieved, setBagRecieved] = useState(data.bags_received);
  const [totalWtReceived, setTotalWtReceived] = useState(
    Number(data.total_weight_received)
  );
  const [invoiceNoList, setInvoiceNoList] = useState("");
  const [updateGrn, setUpdateGrn] = useState(false);
  const [billSubmitConfirm, setBillSubmitConfirm] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [replaceBrand, setReplaceBrand] = useState("");
  const [error, setError] = useState('');
  const [invoiceDate, setInvoiceDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [invoiceRatePer, setInvoiceRatePer] = useState();

  const diffWtRef = useRef();
  const totalReceivedWt = useRef();
  const bagReceivedRef = useRef(null);

  const supplierRef = useRef();

  const getGSTCharges = () => {
    let gst_per = data.gst_charges_per
      ? Number(data.gst_charges_per.split("%")[0])
      : 0;
    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.invoice_amt);
    return (gst_per * invoice_amt) / 100;
  };

  const getAPMCCharges = () => {
    let apmc_per = data.apmc_charges_per
      ? Number(data.apmc_charges_per.split("%")[0])
      : 0;
    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.invoice_amt);
    return (apmc_per * invoice_amt) / 100;
  };

  const getTransportCharges = () => {
    let transport_charge = productEditObject?.transport_charges
      ? productEditObject.transport_charges
      : data.transport_charges
      ? data.transport_charges
      : 0;
    return Number(transport_charge) * totalWtReceived;
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const updatePurchaseDetailsNew = async () => {
    setUpdateGrn(false);
    console.log(productEditObject);
    let newSupplier = true;
    if (productEditObject["supplier_name"]) {
      suppliersList.forEach((item) => {
        if (productEditObject["supplier_name"] == item.value) {
          newSupplier = false;
        }
      });
      if (newSupplier)
        SuppliersDB.push({ supplier_name: productEditObject["supplier_name"] });
    }

    const firebaseItemCode = itemCode.substring(11);

    console.log(
      "firebaseItemCode: ",
      firebaseItemCode,
      " itemCode: ",
      itemCode,
      "productEditObject: ",
      productEditObject,
      " materialReceivingDt: ",
      materialReceivingDt,
      " productEditObject: ",
      productEditObject
    );

    const materialReceivingDate = new Date(materialReceivingDt);
    const year = materialReceivingDate.getFullYear();
    const month =
      materialReceivingDate.getMonth().toString().length === 1
        ? `0${materialReceivingDate.getMonth() + 1}`
        : materialReceivingDate.getMonth() + 1;
    const date =
      materialReceivingDate.getDate().toString().length === 1
        ? `0${materialReceivingDate.getDate()}`
        : materialReceivingDate.getDate();

    const grn_date_format = `${year}-${month}-${date}`;

    console.log(
      "grn_date_format: ",
      grn_date_format,
      " purchase_date: ",
      data.purchased_date
    );

    const isAdmin = validateIsAdminUser(
      sessionStorage.getItem(LOGGED_USER_EMAIL)
    );

    console.log("isAdmin: ", isAdmin);

    const grn_date_obj = new Date(grn_date_format);
    const purchased_date_obj = new Date(data.purchased_date);
    const grn_date_millis = grn_date_obj.getTime();
    const purchased_date_millis = purchased_date_obj.getTime();
    let fourDaysOlderDate = new Date();

    const currYear = fourDaysOlderDate.getFullYear();
    const currMonth =
      fourDaysOlderDate.getMonth().toString().length === 1
        ? `0${fourDaysOlderDate.getMonth() + 1}`
        : fourDaysOlderDate.getMonth() + 1;
    const currDate =
      fourDaysOlderDate.getDate().toString().length === 1
        ? `0${fourDaysOlderDate.getDate()}`
        : fourDaysOlderDate.getDate();

    fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
    fourDaysOlderDate = fourDaysOlderDate.setDate(
      fourDaysOlderDate.getDate() - 4
    );

    let last30DaysDate = new Date();
    last30DaysDate = new Date(`${currYear}-${currMonth}-${currDate}`);
    last30DaysDate = last30DaysDate.setDate(last30DaysDate.getDate() - 30);
    if (!isAdmin) {
      if (new Date(grn_date_format) < new Date(last30DaysDate)) {
        return toast("GRN Date can not be more than 30 days old", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    let payment_terms = Number(data.payment_terms);
    let payment_date = moment(new Date(data.grn_date))
      .clone()
      .add(payment_terms, "day");
    const formattedPaymentDate = payment_date.format("YYYY-MM-DD");

    const jsonObj = {
      ...productEditObject,
      bags_received: bagRecieved,
      diff_receiving: diffWtRef.current.value,
      total_weight_received: totalReceivedWt.current.value,
      bill_submitted: billSubmitConfirm !== "" ? "" + billSubmitConfirm : "",
      base_price: basePrice ? basePrice : data.base_price,
      replacement_brand: replaceBrand
        ? replaceBrand
        : data.replacement_brand
        ? data.replacement_brand
        : "",
      updated_by: UserEmail(),
      updated_at: `${UpdatedDate()} ${UpdatedTime()}`,
      isAdmin: isAdmin,
      purchased_date: data.purchased_date,
      purchase_details_key: itemCode,
      payment_date: formattedPaymentDate,
      expiry_date: productEditObject?.expiry_date
        ? productEditObject.expiry_date
        : `${
            new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate()
          }-${
            new Date().getMonth() + 1 < 10
              ? "0" + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1
          }-${new Date().getFullYear()}`,
    };

    const old_grn_date = data.grn_date;
    const old_total_weight_received = data.total_weight_received;

    jsonObj.grn_date = grn_date_format;
    jsonObj.old_grn_date = old_grn_date;
    jsonObj.old_total_weight_received = old_total_weight_received;
    jsonObj.item_id = data.article_code;

    console.log("check obj", jsonObj);
    const url = `/api/superzop/commercial/purchasedetails/addeditgrn?type=edit`;

    baseUrl
      .post(url, jsonObj)
      .then((res) => {
        if (res.status == 200) {
          props.onHide();
          setProductEditObject({});
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          notifyUser(true);
        }
      })
      .catch((error) => {
        toast("Failed to update System Inventory", { type: toast.TYPE.ERROR });
        console.log(error);
      });
  };

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast(`Updated the GRN for ${data.article_code}`, {
        type: toast.TYPE.SUCCESS,
      });
    }
    return toast("Couldn't update the GRN", { type: toast.TYPE.ERROR });
  };
  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({
          name: suppliersList[supplier].supplier_name,
          email: suppliersList[supplier].EmailID,
          key: suppliersList[supplier].supplier_id,
          fssai: suppliersList[supplier].fssai,
          gstin: suppliersList[supplier]["GST_Identification_Number_(GSTIN)"],
          phone: suppliersList[supplier].MobilePhone,
          address: suppliersList[supplier].Billing_Address,
          street: suppliersList[supplier].Billing_Street2,
          city: suppliersList[supplier].Billing_City,
          pin: suppliersList[supplier].Billing_Code,
          state: suppliersList[supplier].Billing_State,
        });
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        suppliers.push({
          value: items.name,
          label: items.name,
          email: items.email,
          key: items.key,
          fssai: items.fssai,
          gstin: items.gstin,
          phone: items.phone,
          address: items.address,
          street: items.street,
          city: items.city,
          pin: items.pin,
          state: items.state,
        });
      });
    }).then(() => {
      setSuppliersList(suppliers);
    });
  };

  const fetchInvoiceNumber = () => {
    const invoiceNumberList = [];
    let allInvoiceNumbers = [];

    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log("fetchInvoiceNumber: ", complete_date);

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    baseUrl
      .get(url)
      .then((res) => {
        console.log("fetchInvoiceNumber: ", res);
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        Object.keys(purchaseList).forEach((items) => {
          if (purchaseList[items].invoice_number)
            allInvoiceNumbers.push(purchaseList[items].invoice_number);
        });
        allInvoiceNumbers = [...new Set(allInvoiceNumbers)];
        allInvoiceNumbers.forEach((invoices) => {
          invoiceNumberList.push({ value: invoices, label: invoices });
        });
      })
      .then(() => {
        console.log(
          "fetchInvoiceNumber invoiceNumberList: ",
          invoiceNumberList
        );
        setInvoiceNoList(invoiceNumberList);
      });
  };

  const createGrnNumber = () => {
    console.log("GRN");
    let countCheck = 0;
    if (
      data.invoice_number &&
      !productEditObject["invoice_number"] &&
      data.supplier_name &&
      !productEditObject["supplier_name"]
    ) {
      setUpdateGrn(true);
      return;
    }

    if (data.supplier_name && !productEditObject["supplier_name"]) {
      setProductEditObject({
        ...productEditObject,
        supplier_name: data.supplier_name,
      });
    }

    if (!productEditObject["expiry_date"]) {
      setProductEditObject({
        ...productEditObject,
        expiry_date: formatDate(new Date()),
      });
    }

    if (!replaceBrand) {
      setError('Replacement Brand is required.');
      return toast("Enter Replacement Brand", { type: toast.TYPE.ERROR });
    } else {
      setError('');
      console.log('Form submitted with:', replaceBrand);
    }

    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log("createGrnNumber: ", complete_date);

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;

    baseUrl
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        let conditionMatchGRN = false;
        Object.keys(purchaseList).forEach((items) => {
          if (
            productEditObject["invoice_number"] ===
            purchaseList[items].invoice_number
          ) {
            conditionMatchGRN = true;
            if (
              productEditObject["supplier_name"] !=
              purchaseList[items].supplier_name
            ) {
              toast("Supplier is not matching with Invoice Number", {
                type: toast.TYPE.ERROR,
              });
            }
            setProductEditObject({
              ...productEditObject,
              grn_no: purchaseList[items].grn_no
                ? purchaseList[items].grn_no
                : "",
            });
            setUpdateGrn(true);
            return;
          }
        });

        if (!conditionMatchGRN) {
          setProductEditObject({
            ...productEditObject,
            grn_no: `${selectedDate.split("-")[0]}${
              selectedDate.split("-")[1]
            }${selectedDate.split("-")[2].substring(0, 2)}${
              Math.floor(Math.random() * 90000) + 10000
            }`,
          });
          setUpdateGrn(true);
        }
      });
  };

  useEffect(() => {
    if (updateGrn) {
      updatePurchaseDetailsNew();
    }
  }, [updateGrn]);

  useEffect(() => {
    fetchSuppliers();
    fetchInvoiceNumber();
  }, []);

  const formatDate = (val) => {
    let date = new Date(val);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit GRN</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <Table>
          <tr>
            <td>PO Number</td>
            <td>
              <Form.Control value={data.po_number} readOnly />
            </td>
          </tr>
          <tr>
            <td>PO Date</td>
            <td>
              <Form.Control value={data.purchased_date} readOnly />
            </td>
          </tr>
          <tr>
            <td>Invoice Date</td>
            <td>
              <DatePicker
                dateFormat={"dd-MM-yyyy"}
                selected={
                  invoiceDate
                    ? invoiceDate
                    : data.invoice_date
                    ? new Date(
                        `${data.invoice_date.split("-")[2]}`,
                        `${data.invoice_date.split("-")[1] - 1}`,
                        `${data.invoice_date.split("-")[0]}`
                      )
                    : ""
                }
                onChange={(val) => {
                  console.log(formatDate(val));
                  setProductEditObject({
                    ...productEditObject,
                    invoice_date: formatDate(val),
                  });
                  setInvoiceDate(val);
                  console.log(val);
                }}
                customInput={<CustomDateInput />}
                aria-describedby="basic-addon1"
                minDate={
                  new Date(
                    `${data.purchased_date.split("-")[0]}`,
                    `${data.purchased_date.split("-")[1] - 1}`,
                    `${data.purchased_date.split("-")[2]}`
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Source</td>
            <td>
              <DropdownButton
                id="dropdown-basic-button"
                required
                title={data?.source_type ? data.source_type.toUpperCase() : ""}
                disabled
              >
                <Dropdown.Item name={"MILL"}>MILL</Dropdown.Item>
                <Dropdown.Item name={"LOCAL PICK"}>LOCAL PICK</Dropdown.Item>
                <Dropdown.Item name={"DC DELIVERED"}>
                  DC DELIVERED
                </Dropdown.Item>
              </DropdownButton>
            </td>
          </tr>
          <tr>
            <td>Article Code</td>
            <td>
              <Form.Control value={data.article_code} readOnly />
            </td>
          </tr>
          <tr>
            <td>Item Name</td>
            <td>
              <Form.Control value={data.item_name} readOnly />
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <Form.Control value={data.category} readOnly />
            </td>
          </tr>
          {data.category && data.category.toUpperCase() == "FRESH" && (
            <tr>
              <td>Batch No</td>
              <td>
                <Form.Control
                  readOnly
                  value={data.batch_no}
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      batch_no: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
          )}
          {data.category && data.category.toUpperCase() == "FRESH" && (
            <tr>
              <td>Expiry Date</td>
              <td>
                {" "}
                <DatePicker
                  selected={
                    expiryDate
                      ? expiryDate
                      : data.expiry_date &&
                        new Date(
                          `${data.expiry_date.split("-")[2]}`,
                          `${data.expiry_date.split("-")[1] - 1}`,
                          `${data.expiry_date.split("-")[0]}`
                        )
                  }
                  onChange={(val) => {
                    setExpiryDate(val);
                    setProductEditObject({
                      ...productEditObject,
                      expiry_date: `${
                        new Date(val).getDate() < 10
                          ? "0" + new Date(val).getDate()
                          : new Date(val).getDate()
                      }-${
                        new Date(val).getMonth() + 1 < 10
                          ? "0" + (new Date(val).getMonth() + 1)
                          : new Date(val).getMonth() + 1
                      }-${new Date(val).getFullYear()}`,
                    });
                  }}
                  minDate={new Date()}
                  // maxDate={new Date()}
                  aria-describedby="basic-addon1"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomDateInput />}
                />
              </td>
            </tr>
          )}
          <tr>
            <td>HSN</td>
            <td>
              <Form.Control value={data.hsn_code} readOnly />
            </td>
          </tr>
          <tr>
            <td>Outer Type</td>
            <td>
              <DropdownButton
                id="dropdown-basic-button"
                title={data?.outer_type ? data.outer_type : "--Select--"}
                disabled
              >
                <Dropdown.Item name={"SMALL PACK"}>SMALL PACK</Dropdown.Item>
                <Dropdown.Item name={"GONI"}>GONI</Dropdown.Item>
                <Dropdown.Item name={"BAG OF PACKS"}>
                  BAG OF PACKS
                </Dropdown.Item>
                <Dropdown.Item name={"HIGH VALUE OF LOOSE"}>
                  HIGH VALUE OF LOOSE
                </Dropdown.Item>
              </DropdownButton>
            </td>
          </tr>
          <tr>
            <td>Outer Size</td>
            <td>
              <Form.Control
                value={`${data.outer_size} ${data?.uom ? data.uom : ""}`}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>Material Receiving Date</td>
            <td>
              <DatePicker
                selected={materialReceivingDt}
                // onChange={(val) => { setMaterialReceivingDt(val); setProductEditObject({ ...productEditObject, "grn_date": (`${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}-${((val.getMonth()) + 1) < 10 ? "0" + (val.getMonth() + 1) : (val.getMonth() + 1)}-${val.getFullYear()}`) }); }}
                // minDate={new Date("2017/01/01")}
                // maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                readOnly={true}
                // customInput={<CustomDateInput />}
              />
            </td>
          </tr>
          <tr>
            <td>PO Rate</td>
            <td>
              <Form.Control value={data.purchase_price} readOnly />
            </td>
          </tr>
          <tr>
            <td>Total Weight Ordered</td>
            <td>
              <Form.Control
                value={`${data.total_weight} ${data?.uom ? data.uom : ""}`}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>Bags Received</td>
            <td>
              <Form.Control
                ref={bagReceivedRef}
                type="number"
                defaultValue={data.bags_received}
                onChange={(e) => {
                  const bagsReceived = parseInt(e.target.value, 10);
                  if (bagsReceived > data.bag_purchased) {
                    bagReceivedRef.current.value = "";
                    toast("Bags Received cannot be more than Bag Purchased", {
                      type: toast.TYPE.ERROR,
                    });
                    return;
                  }

                  setProductEditObject({
                    ...productEditObject,
                    bags_received: e.target.value,
                  });
                  setBagRecieved(e.target.value);
                  setTotalWtReceived(
                    Number(data.outer_size) * Number(e.target.value)
                  );
                  setProductEditObject({
                    ...productEditObject,
                    invoice_amt:
                      Number(data.outer_size) *
                      Number(e.target.value) *
                      invoiceRatePer,
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Total Weight Received</td>
            <td>
              <Form.Control
                ref={totalReceivedWt}
                defaultValue={data.total_weight_received}
                onChange={(e) => {
                  setTotalWtReceived(Number(e.target.value));
                  setProductEditObject({
                    ...productEditObject,
                    invoice_amt: Number(e.target.value) * invoiceRatePer,
                  });
                }}
                value={totalWtReceived}
              />
            </td>
          </tr>
          <tr>
            <td>Difference in Receiving</td>
            <td>
              <Form.Control
                ref={diffWtRef}
                readOnly={true}
                value={
                  bagRecieved ? Number(data.total_weight) - totalWtReceived : ""
                }
              />
            </td>
          </tr>
          <tr>
            <td>Invoice Rate per (KG/Pc)</td>
            <td>
              <Form.Control
                type="number"
                defaultValue={Number(data.invoice_amt) / totalWtReceived}
                onChange={(e) => {
                  setInvoiceRatePer(e.target.value);
                  setProductEditObject({
                    ...productEditObject,
                    invoice_amt:
                      Number(e.target.value) * Number(totalWtReceived),
                  });
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Invoice Amount</td>
            <td>
              <Form.Control
                value={
                  productEditObject?.invoice_amt
                    ? productEditObject.invoice_amt
                    : data.invoice_amt
                }
                readOnly
              />
            </td>
          </tr>
          {data.transport ? (
            <tr>
              <td>Transport</td>
              <td>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    data?.transport
                      ? data.transport.toUpperCase()
                      : "--Select--"
                  }
                  disabled
                >
                  <Dropdown.Item name={"excluded"}>EXCLUDED</Dropdown.Item>
                  <Dropdown.Item name={"included"}>INCLUDED</Dropdown.Item>
                </DropdownButton>
              </td>
            </tr>
          ) : null}
          {data.transport === "excluded" ? (
            <>
              <tr>
                <td>Total Transport</td>
                <td>
                  <Form.Control
                    placeholder={data.transport_charges * data.total_weight}
                    onChange={(e) =>
                      setProductEditObject({
                        ...productEditObject,
                        total_transport: e.target.value,
                        transport_charges:
                          Number(e.target.value) / Number(data.total_weight),
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Mill Transport (Per KG/Pc)</td>
                <td>
                  <Form.Control
                    value={
                      productEditObject?.transport_charges
                        ? productEditObject.transport_charges
                        : data.transport_charges
                    }
                    readOnly
                  />
                </td>
              </tr>{" "}
            </>
          ) : null}

          <tr>
            <td>APMC Amount</td>
            <td>
              <Form.Control value={getAPMCCharges()} readOnly />
            </td>
          </tr>
          <tr>
            <td>GST Amount</td>
            <td>
              <Form.Control value={getGSTCharges()} readOnly />
            </td>
          </tr>
          <tr>
            <td>Scheme Amount</td>
            <td>
              <Form.Control
                onChange={(e) =>
                  setProductEditObject({
                    ...productEditObject,
                    scheme_amount: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>Final Invoice Amount</td>
            <td>
              <Form.Control
                value={
                  Number(
                    productEditObject?.invoice_amt
                      ? productEditObject.invoice_amt
                      : data.invoice_amt
                  ) +
                  getGSTCharges() +
                  getAPMCCharges() +
                  getTransportCharges() -
                  Number(
                    productEditObject?.scheme_amount
                      ? productEditObject?.scheme_amount
                      : 0
                  )
                }
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>Final Invoice Rate (per KG/Pc)</td>
            <td>
              <Form.Control
                value={(
                  (Number(
                    productEditObject?.invoice_amt
                      ? productEditObject.invoice_amt
                      : data.invoice_amt
                  ) +
                    getGSTCharges() +
                    getAPMCCharges() +
                    getTransportCharges() -
                    Number(
                      productEditObject?.scheme_amount
                        ? productEditObject?.scheme_amount
                        : 0
                    )) /
                  totalWtReceived
                ).toFixed(2)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>Replacement Brand</td>
            <td>
              <Form.Control
                onChange={(e) => setReplaceBrand(e.target.value)}
                placeholder={data.replacement_brand}
                isInvalid={!!error}
              />

              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </td>
          </tr>
          <tr>
            <td>Invoice Number</td>
            <td>
              <Creatable
                required
                options={invoiceNoList}
                placeholder={data.invoice_number}
                onChange={(val) =>
                  setProductEditObject({
                    ...productEditObject,
                    invoice_number: val.value,
                  })
                }
                style={{ width: "100px" }}
              />
            </td>
          </tr>
          <tr>
            <td>Supplier Name</td>
            <td>
              <Select
                required
                options={suppliersList}
                ref={supplierRef}
                placeholder={data.supplier_name}
                onChange={(val) =>
                  setProductEditObject({
                    ...productEditObject,
                    supplier_name: val.value,
                    supplier_id: val.key,
                    supplier_email: val.email,
                    bill_address: val.address,
                    bill_city: val.city,
                    bill_state: val.state,
                    bill_street: val.street,
                    bill_pin: val.pin,
                    bill_fssai: val.fssai,
                    bill_gstin: val.gstin,
                    bill_phone: val.phone,
                  })
                }
                style={{ width: "100px" }}
              />
            </td>
          </tr>
          <tr>
            <td>Location</td>
            <td>
              <Form.Control
                defaultValue={data.location}
                onChange={(e) => {
                  setProductEditObject({
                    ...productEditObject,
                    location: e.target.value,
                  });
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Remark</td>
            <td>
              <Form.Control
                defaultValue={data.remark}
                onChange={(e) =>
                  setProductEditObject({
                    ...productEditObject,
                    remark: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>MRP</td>
            <td>
              <Form.Control
                defaultValue={data.mrp}
                onChange={(e) =>
                  setProductEditObject({
                    ...productEditObject,
                    mrp: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          {/* <tr>
                    <td>Bill Submitted</td>
                        <td><Switch  onChange={(val)=>{setBillSubmitConfirm(val)}} checked={billSubmitConfirm?billSubmitConfirm:""}/></td>
                        <td></td>
                    </tr> */}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => createGrnNumber()}>Update</Button>
        <Button onClick={props.onHide} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditGRNModal;
