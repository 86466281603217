import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  Divider,
  Select as MantineSelect,
  MultiSelect,
  NumberInput,
  TextInput,
} from "@mantine/core";

export const RenderFieldsBasedOnSelectedConditions = React.memo(
  (props) => {
    let elementsToReturn = [];
    let greaterThanAmtCondition = props.greaterThanAmtCondition;
    let minOrderValue = props.minOrderValue;
    let setMinOrderValue = props.setMinOrderValue;
    let maxOrderValue = props.maxOrderValue;
    let setMaxOrderValue = props.setMaxOrderValue;
    let minCategoryUnits = props.minCategoryUnits;
    let setMinCategoryUnits = props.setMinCategoryUnits;
    let minCategoryValue = props.minCategoryValue;
    let maxCategoryValue = props.maxCategoryValue;
    let setMinCategoryValue = props.setMinCategoryValue;
    let setMaxCategoryValue = props.setMaxCategoryValue;
    let minCategoryWeight = props.minCategoryWeight;
    let setMinCategoryWeight = props.setMinCategoryWeight;

    let minProductUnits = props.minProductUnits;
    let setMinProductUnits = props.setMinProductUnits;
    let minProductValue = props.minProductValue;
    let maxProductValue = props.maxProductValue;
    let setMinProductValue = props.setMinProductValue;
    let setMaxProductValue = props.setMaxProductValue;
    let minProductWeight = props.minProductWeight;
    let setMinProductWeight = props.setMinProductWeight;

    let minAmtInCategoryCondition = props.minAmtInCategoryCondition;
    let minAmtInProductCondition = props.minAmtInProductCondition;
    let productData = props.productData;

    let handleOnCategorySelect = props.handleOnCategorySelect;
    let handleOnMinProductSelect = props.handleOnMinProductSelect;
    let productType = props.productType;

    let userSelectedMinProduct = props.userSelectedMinProduct;
    let userSelectedMinBrand = props.userSelectedMinBrand;
    let userSelectedMinCategory = props.userSelectedMinCategory;
    let userSelectedMinSubCategory = props.userSelectedMinSubCategory;

    let productCategories = props.productCategories;
    let productBrands = props.productBrands;
    let productSubCategories = props.productSubCategories;
    let categoryQuantityType = props.categoryQuantityType;
    let categoryType = props.categoryType;

    const isMinProductFieldsDisabled =
      Object.keys(props.userSelectedMinProduct).length === 0;
    const isSubCategoryFieldsDisabled =
      Object.keys(props.userSelectedMinCategory).length === 0;
    const isCategoryFieldsDisabled =
      Object.keys(props.userSelectedMinSubCategory).length !== 0;

    if (greaterThanAmtCondition) {
      elementsToReturn.push(
        <Row
          key="greaterThanAmtConditionKey"
          style={{ margin: "auto", paddingBottom: "40px" }}
        >
          <Col>
            <Divider
              my="md"
              label="Order greater that amount condition field"
              styles={{
                label: { color: "orange" },
              }}
            />
            <Form.Group
              controlId="orderValueMinConditionInput"
              style={{
                display: "block",
                width: "500px",
                textAlign: "center",
                margin: "auto",
                paddingBottom: "40px",
              }}
            >
              {/* <Form.Label>Minimum order value</Form.Label> */}
              <NumberInput
                name="orderValueMinConditionInput"
                label="Minimum order value"
                value={minOrderValue}
                onChange={(val) => {
                  setMinOrderValue(val);
                }}
                required
                hideControls
              />
              {/* <Form.Control
              type="number"
              name="orderValueConditionInput"
              required
            /> */}
            </Form.Group>
            <Form.Group
              controlId="orderValueMaxConditionInput"
              style={{
                display: "block",
                width: "500px",
                textAlign: "center",
                margin: "auto",
                paddingBottom: "40px",
              }}
            >
              {/* <Form.Label>Minimum order value</Form.Label> */}
              {/* <NumberInput
                name="orderValueMaxConditionInput"
                label="Maximum order value"
                value={maxOrderValue}
                onChange={(val) => {
                  setMaxOrderValue(val);
                }}
                hideControls
              /> */}
              <TextInput
                value={maxOrderValue}
                onChange={(event) => {
                  const val = event.currentTarget.value;
                  if (/^[0-9]+$/.test(val) || val === "") {
                    setMaxOrderValue(val);
                  }
                }}
                name="orderValueMaxConditionInput"
                label="Maximum order value"
              />
              {/* <Form.Control
              type="number"
              name="orderValueConditionInput"
              required
            /> */}
            </Form.Group>

            <Divider
              my="md"
              label="Condition field"
              labelPosition="right"
              styles={{
                label: { color: "orange" },
              }}
            />
          </Col>
        </Row>
      );
    }

    if (minAmtInCategoryCondition) {
      elementsToReturn.push(
        <React.Fragment key="minAmtInCategoryConditionKey">
          <Row>
            <Col>
              <Divider
                my="md"
                label="Buy atleast minimim amount of products in category / sub-category condition field"
                styles={{
                  label: { color: "teal" },
                }}
              />

              <Form.Group
                controlId="categoryType"
                style={{
                  display: "block",
                  width: "500px",
                  textAlign: "center",
                  margin: "auto",
                  paddingBottom: "40px",
                }}
              >
                <Radio.Group
                  value={categoryType}
                  onChange={props.handleOnCategoryTypeChange}
                  label="Select brand selection or category selection"
                  description=""
                  required
                  styles={{ radioWrapper: { justifyContent: "center" } }}
                >
                  <Radio value="brandSelection" label="Brand Selection" />
                  <Radio
                    value="categorySelection"
                    label="Category or sub category selection"
                  />
                </Radio.Group>
              </Form.Group>
            </Col>
          </Row>
          {categoryType === "brandSelection" ? (
            <Form.Group
              controlId="brandSelect"
              style={{
                display: "block",
                width: "500px",
                textAlign: "center",
                margin: "auto",
                paddingBottom: "40px",
                fontSize: "15px",
              }}
            >
              <Form.Label>Select Brand</Form.Label>

              <MantineSelect
                // isLoading={!productBrands && productBrands.length === 0}
                value={userSelectedMinBrand}
                clearable
                searchable
                onChange={(e) => {
                  handleOnCategorySelect(e, "brand");
                }}
                name="brandSelect"
                data={productBrands}
              />
            </Form.Group>
          ) : null}

          {categoryType === "categorySelection" ? (
            <>
              <fieldset>
                <Form.Group
                  controlId="categorySelect"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                    fontSize: "15px",
                  }}
                >
                  <Form.Label>Select Category</Form.Label>

                  <MantineSelect
                    // isLoading={
                    //   !productCategories && productCategories.length === 0
                    // }
                    clearable
                    searchable
                    value={userSelectedMinCategory}
                    onChange={(e) => {
                      handleOnCategorySelect(e, "category");
                    }}
                    name="categorySelect"
                    data={productCategories}
                  />
                </Form.Group>
              </fieldset>
              <fieldset>
                <Form.Group
                  controlId="subCategorySelect"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                    fontSize: "15px",
                  }}
                >
                  <Form.Label>Select Sub Category (Optional)</Form.Label>
                  {console.log("check prod sub cat", productSubCategories)}
                  <MantineSelect
                    // isLoading={
                    //   !productSubCategories && productSubCategories.length === 0
                    // }
                    clearable
                    value={userSelectedMinSubCategory}
                    searchable
                    onChange={(e) => {
                      handleOnCategorySelect(e, "subCategory");
                    }}
                    name="subCategorySelect"
                    data={productSubCategories}
                  />
                </Form.Group>
              </fieldset>
            </>
          ) : null}

          <Row>
            <Col>
              <Form.Group
                controlId="categoryQuantityType"
                style={{
                  display: "block",
                  width: "500px",
                  textAlign: "center",
                  margin: "auto",
                  paddingBottom: "40px",
                }}
              >
                <Radio.Group
                  value={categoryQuantityType}
                  onChange={props.setCategoryQuantityType}
                  label="Select category units or value"
                  description=""
                  required
                  styles={{ radioWrapper: { justifyContent: "center" } }}
                >
                  <Radio value="categoryUnits" label="Category units" />
                  <Radio value="categoryValue" label="Category value" />
                  <Radio value="categoryWeight" label="Category weight" />
                </Radio.Group>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              {categoryQuantityType === "categoryUnits" ? (
                <Form.Group
                  controlId="categoryUnitsInput"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                  }}
                >
                  {/* <Form.Label>Category Units</Form.Label> */}
                  <NumberInput
                    name="categoryUnitsInput"
                    required
                    label="Category Units"
                    value={minCategoryUnits}
                    onChange={(val) => {
                      setMinCategoryUnits(val);
                    }}
                    hideControls
                  />
                  {/* <Form.Control
                  type="number"
                  name="categoryUnitsInput"
                  required
                /> */}
                </Form.Group>
              ) : categoryQuantityType === "categoryValue" ? (
                <>
                  <Form.Group
                    controlId="categoryValueInput"
                    style={{
                      display: "block",
                      width: "500px",
                      textAlign: "center",
                      margin: "auto",
                      paddingBottom: "40px",
                    }}
                  >
                    {/* <Form.Label>Category Value</Form.Label> */}
                    <NumberInput
                      name="categoryValueInput"
                      label="Category Value"
                      value={minCategoryValue}
                      onChange={(val) => {
                        setMinCategoryValue(val);
                      }}
                      required
                      hideControls
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="categoryMaxValueInput"
                    style={{
                      display: "block",
                      width: "500px",
                      textAlign: "center",
                      margin: "auto",
                      paddingBottom: "40px",
                    }}
                  >
                    {/* <Form.Label>Category Value</Form.Label> */}
                    <NumberInput
                      name="categoryMaxValueInput"
                      label="Max Category Value"
                      value={maxCategoryValue}
                      onChange={(val) => {
                        setMaxCategoryValue(val);
                      }}
                      required
                      hideControls
                    />
                  </Form.Group>
                </>
              ) : (
                <Form.Group
                  controlId="categoryWeightInput"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                  }}
                >
                  <NumberInput
                    name="categoryWeightInput"
                    label="Category Weight Kg(s)"
                    value={minCategoryWeight}
                    onChange={(val) => {
                      setMinCategoryWeight(val);
                    }}
                    required
                    hideControls
                  />
                </Form.Group>
              )}
              <Divider
                my="md"
                label="Condition field"
                labelPosition="right"
                styles={{
                  label: { color: "teal" },
                }}
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    if (minAmtInProductCondition) {
      elementsToReturn.push(
        <React.Fragment key="minAmtInProductConditionKey">
          <Row>
            <Col>
              <Divider
                my="md"
                label="But atleast minimum amount of specific product condition field"
                styles={{
                  label: { color: "rgb(206, 7, 206)" },
                }}
              />
              <Form.Group
                controlId="productSelect"
                style={{
                  display: "block",
                  width: "500px",
                  textAlign: "center",
                  margin: "auto",
                  paddingBottom: "40px",
                  fontSize: "15px",
                }}
              >
                <Form.Label>Select Product</Form.Label>
                <MultiSelect
                  clearable
                  value={userSelectedMinProduct}
                  onChange={handleOnMinProductSelect}
                  searchable
                  name="productSelect"
                  data={productData}
                />
              </Form.Group>
            </Col>
          </Row>

          <fieldset disabled={false}>
            <Row>
              <Col>
                <Form.Group
                  controlId="productType"
                  style={{
                    display: "block",
                    width: "500px",
                    textAlign: "center",
                    margin: "auto",
                    paddingBottom: "40px",
                    fontSize: "15px",
                  }}
                >
                  <Radio.Group
                    value={productType}
                    onChange={props.setProductType}
                    label="Select Category units or value"
                    description=""
                    required
                    styles={{ radioWrapper: { justifyContent: "center" } }}
                  >
                    <Radio value="productUnits" label="Product units" />
                    <Radio value="productValue" label="Product value" />
                    <Radio value="productWeight" label="Product weight" />
                  </Radio.Group>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {productType === "productUnits" ? (
                  <Form.Group
                    controlId="productUnitsInput"
                    style={{
                      display: "block",
                      width: "500px",
                      textAlign: "center",
                      margin: "auto",
                      paddingBottom: "40px",
                    }}
                  >
                    {/* <Form.Label>Product Units</Form.Label> */}
                    <NumberInput
                      name="productUnitsInput"
                      required
                      hideControls
                      label="Product Units"
                      value={minProductUnits}
                      onChange={(val) => {
                        setMinProductUnits(val);
                      }}
                    />
                    {/* <Form.Control
                    type="number"
                    name="productUnitsInput"
                    required
                  /> */}
                  </Form.Group>
                ) : productType === "productWeight" ? (
                  <Form.Group
                    controlId="productWeightInput"
                    style={{
                      display: "block",
                      width: "500px",
                      textAlign: "center",
                      margin: "auto",
                      paddingBottom: "40px",
                    }}
                  >
                    <NumberInput
                      name="productWeightInput"
                      required
                      hideControls
                      label="Product Weight"
                      value={minProductWeight}
                      onChange={(val) => {
                        setMinProductWeight(val);
                      }}
                    />
                    {/* <Form.Control
                    type="number"
                    name="productValueInput"
                    required
                  /> */}
                  </Form.Group>
                ) : (
                  <>
                    <Form.Group
                      controlId="productValueInput"
                      style={{
                        display: "block",
                        width: "500px",
                        textAlign: "center",
                        margin: "auto",
                        paddingBottom: "40px",
                      }}
                    >
                      {/* <Form.Label>Product Value</Form.Label> */}
                      <NumberInput
                        name="productValueInput"
                        required
                        hideControls
                        label="Product Value"
                        value={minProductValue}
                        onChange={(val) => {
                          setMinProductValue(val);
                        }}
                      />
                      {/* <Form.Control
                  type="number"
                  name="productValueInput"
                  required
                /> */}
                    </Form.Group>
                    <Form.Group
                      controlId="productMaxValueInput"
                      style={{
                        display: "block",
                        width: "500px",
                        textAlign: "center",
                        margin: "auto",
                        paddingBottom: "40px",
                      }}
                    >
                      {/* <Form.Label>Product Value</Form.Label> */}
                      <NumberInput
                        name="productMaxValueInput"
                        required
                        hideControls
                        label="Product Max Value"
                        value={maxProductValue}
                        onChange={(val) => {
                          setMaxProductValue(val);
                        }}
                      />
                      {/* <Form.Control
                  type="number"
                  name="productValueInput"
                  required
                /> */}
                    </Form.Group>
                  </>
                )}
                <Divider
                  my="md"
                  label="Condition field"
                  labelPosition="right"
                  styles={{
                    label: { color: "rgb(206, 7, 206)" },
                  }}
                />
              </Col>
            </Row>
          </fieldset>
        </React.Fragment>
      );
    }

    return elementsToReturn.map((item, index) => {
      return item;
    });
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);
