import {
  OFFER_BASE_URL,
  BASE_URL,
  ORDERING_BASE_URL,
} from "../../service/servicesConfig";
const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6Ik";
export const PRODUCT_OFFER_URL = `${OFFER_BASE_URL}/product-offer?token=${TOKEN}`;
export const PRODUCT_OFFER_EDIT_URL = `${OFFER_BASE_URL}/product-offer?token=${TOKEN}&type=update`;
export const PRODUCT_OFFFER_NOTIFICATIONS_URL = `${ORDERING_BASE_URL}/api/superzop/admin/schedulenotificationfortimings`;

export const OFFER_URL = `${OFFER_BASE_URL}/offer?token=${TOKEN}`;
export const OFFER_EDIT_URL = `${OFFER_BASE_URL}/offer?token=${TOKEN}&type=update`;

export const CUSTOM_OFFER_URL = `${BASE_URL}/custom-offer?token=${TOKEN}`;
export const CUSTOM_OFFERS_URL = `${BASE_URL}/custom-offers?token=${TOKEN}`;
export const EXPIRED_CUSTOM_OFFERS_URL = `${BASE_URL}/custom-offers?active=N&token=${TOKEN}`;
export const ACTIVE_CUSTOM_OFFERS_URL = `${BASE_URL}/custom-offers?active=Y&token=${TOKEN}`;
export const CUSTOM_OFFER_EDIT_URL = `${BASE_URL}/custom-offer?token=${TOKEN}&type=update`;

export const ALL_OFFERS_URL = `${BASE_URL}/offers?token=${TOKEN}`;
export const EXPIRED_OFFERS_URL = `${BASE_URL}/offers?token=${TOKEN}&is_active_offer=N`;
export const ACTIVE_OFFERS_URL = `${BASE_URL}/offers?token=${TOKEN}&is_active_offer=Y`;

export const OFFER_BANNER_URL = `${OFFER_BASE_URL}/offer-banner-url?token=${TOKEN}`;

export const RETAILERS_GROUP_URL = `${BASE_URL}/retailers-group?token=${TOKEN}`;
export const RETAILER_GROUP_URL = `${BASE_URL}/retailer-group?token=${TOKEN}`;
export const RETAILERS_GROUP_POST_URL = `${BASE_URL}/group-retailers?token=${TOKEN}`;
export const COUPON_CODES_GET_URL = `${BASE_URL}/custom-offers?token=${TOKEN}`;
