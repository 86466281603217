import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import database from '../../service/firebaseConfig';
import { TRANSPORT_FACTOR } from '../../constants/allConstants';

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('-');
}

function BulkAddProductPendingApprovalModal({tempProductDetails,bulkProductPendingApprovalOpen,regionTransportCharge,region,productDataObj,priceDataObj,...props}) {
  console.log("BulkAddProductPendingApprovalModal");
  console.log(bulkProductPendingApprovalOpen);
  console.log(tempProductDetails);

  let origProductDetails=JSON.parse(JSON.stringify(tempProductDetails));
  const [productDetails,setProductDetails]=useState(origProductDetails);


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
      if(bulkProductPendingApprovalOpen){
        let finalProductsList=[];
        console.log("latest data-------------------");
        console.log(origProductDetails);
        for(let i=0;i<origProductDetails.length;i++){
          let oldBasePrice=origProductDetails[i]['base_price'];
          let newBasePrice=origProductDetails[i]['purchase_price'];
          let actualParentItem=origProductDetails[i]['article_code'];

          let ele=origProductDetails[i];
          ele.type="parent";
          ele.parent_id=actualParentItem;
          finalProductsList.push(ele);

          Object.keys(productDataObj).map((key,value)=>{

            let parent_id=productDataObj[key]['parent_id']?productDataObj[key]['parent_id']:"";
            let parent_id1=productDataObj[key]['parent_id1']?productDataObj[key]['parent_id1']:"";
            let category=productDataObj[key].category;
            let sub_category=productDataObj[key].sub_category;
            let brand=productDataObj[key].brand;

            let childId=key;
            if((parent_id==actualParentItem || parent_id1==actualParentItem) && !(brand.includes("Khetika Naturalé") || brand.includes("Khetika Naturale") || brand.includes("SuperChoice") || brand.includes("Khetika Choice") || brand.includes("KhetikaChoice"))){
              let show_mat = priceDataObj[childId].material_cost > 0 ? true: false;
              let show_was = priceDataObj[childId].wastage > 0 ? true : false;
              let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[childId],productDataObj[childId],show_mat,show_was,newBasePrice);

              let childprodele={};
              childprodele['dealer_price']=updatedProdPriceObj['dealer_price'];
              childprodele['base_price']=newBasePrice;
              childprodele['apmc_charge']=updatedProdPriceObj['apmc_charge'];
              childprodele['gst_charge']=getRoundNoThree(updatedProdPriceObj['gst_charge']);
              childprodele['transport_charge']=parseToNo(productDataObj[childId].transport_charge);
              childprodele['article_code']=childId;
              childprodele['old_base_price']=""+oldBasePrice;
              childprodele['old_dealer_price']=productDataObj[childId].dealer_price;
              childprodele['type']="child";
              childprodele['parent_id']=actualParentItem;

              childprodele['purchase_price']=newBasePrice;
              childprodele['newDealerPrice']=updatedProdPriceObj['dealer_price'];
              childprodele['newgstcharge']=getRoundNoThree(updatedProdPriceObj['gst_charge']);
              childprodele['newapmccharge']=updatedProdPriceObj['apmc_charge'];
              childprodele['newtransportcharge']=parseToNo(productDataObj[childId].transport_charge);
              let randmtwodigitnum=Math.floor(Math.random()*(999-100+1)+100);;
              let childprodkey=childId+"-"+randmtwodigitnum;
              console.log(childprodkey);
              childprodele['rowselectkey']=childprodkey;

              finalProductsList.push(childprodele);
            }
          });
        }
        console.log(finalProductsList);

        setProductDetails(finalProductsList);
      }
    },[bulkProductPendingApprovalOpen])

    //table logic
    const ProductTableHeader = () => {
      const checkProdRef = useRef();

      return (
          <thead className="thead-light">
              <tr>
                  <th>ItemID</th>
                  <th>BasePrice</th>
                  <th>DealerPrice</th>
                  <th>GST</th>
                  <th>APMC Charges</th>
                  <th>Transport</th>
              </tr>
          </thead>
      );
    }

    const updateProductBasedOnBasePrice = (rowNum,itemType,updatedBasePrice) => {
      console.log(updatedBasePrice);
      let itemId=productDetails[rowNum]['article_code'];
      console.log(itemId);

      if(itemType=="parent"){
        let updatedProductDataList=[];
        for(let i=0;i<productDetails.length;i++){
          let childId=productDetails[i]['article_code'];
          let parent_id=productDetails[i]['parent_id']?productDetails[i]['parent_id']:"";
          if(itemId==parent_id){
            let show_mat = priceDataObj[childId].material_cost > 0 ? true: false;
            let show_was = priceDataObj[childId].wastage > 0 ? true : false;
            let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[childId],productDataObj[childId],show_mat,show_was,updatedBasePrice);
            console.log(updatedProdPriceObj);

            //update product state
            let ele=productDetails[i];
            ele['purchase_price'] = updatedBasePrice;
            ele['newDealerPrice'] = updatedProdPriceObj['dealer_price'];
            ele['newgstcharge'] = updatedProdPriceObj['gst_charge'];
            ele['newapmccharge'] = updatedProdPriceObj['apmc_charge'];
            updatedProductDataList.push(ele);
          }else{
            updatedProductDataList.push(productDetails[i]);
          }

        }
        setProductDetails(updatedProductDataList);
        setTimeout(() => {
          let bulkitemqty=document.getElementsByClassName("itembaseprice");
          bulkitemqty[rowNum].focus();
        }, 0)
      }else if(itemType=="child"){
        let show_mat = priceDataObj[itemId].material_cost > 0 ? true: false;
        let show_was = priceDataObj[itemId].wastage > 0 ? true : false;
        let updatedProdPriceObj=dealerPriceCalculations(priceDataObj[itemId],productDataObj[itemId],show_mat,show_was,updatedBasePrice);
        console.log(updatedProdPriceObj);

        //update product state
        let updatedProductDataList = [...productDetails];
        updatedProductDataList[rowNum]['purchase_price'] = updatedBasePrice;
        updatedProductDataList[rowNum]['newDealerPrice'] = updatedProdPriceObj['dealer_price'];
        updatedProductDataList[rowNum]['newgstcharge'] = updatedProdPriceObj['gst_charge'];
        updatedProductDataList[rowNum]['newapmccharge'] = updatedProdPriceObj['apmc_charge'];
        setProductDetails(updatedProductDataList);
        setTimeout(() => {
          let bulkitemqty=document.getElementsByClassName("itembaseprice");
          bulkitemqty[rowNum].focus();
        }, 0)
      }
    }

    const ProductTableBody = () => {
      let rows=[];

      if(productDetails.length>0){
        rows = productDetails.map((prod, index) => {
          return (
              <tr key={index} style={{backgroundColor:((prod.type=="child")?"#808080":"#FFCCCC") }}>
                  <td>{prod['article_code']}</td>
                  <td>
                    <Form.Control className="itembaseprice" type="number" value={prod.purchase_price} onChange={(e) => { updateProductBasedOnBasePrice(index,prod['type'],e.target.value); }} />
                  </td>
                  <td>{prod['newDealerPrice']}</td>
                  <td>{prod['newgstcharge']}</td>
                  <td>{prod['newapmccharge']}</td>
                  <td>{prod['newtransportcharge']}</td>
              </tr>
          );
        });
      }else{
        rows=[];
      }

      return (<><tbody>{rows}</tbody></>);
    }

    const bulkAddProductForApproval = () => {
      console.log(productDetails);

      let d = new Date(),
    // currdformat = [d.getDate().padLeft(),
    //           (d.getMonth()+1).padLeft(),
    //            d.getFullYear()].join('-') +' ' +
    //           [d.getHours().padLeft(),
    //            d.getMinutes().padLeft()].join(':');

      currdformat = [
        d.getDate().padLeft(),
        (d.getMonth() + 1).padLeft(),
        d.getFullYear(),
        ].join("-") + 
        " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");

      let pendingprodele={};
      for(let z=0;z<productDetails.length;z++){
          let prodele={};
          prodele['dealer_price']=productDetails[z].newDealerPrice;
          prodele['base_price']=productDetails[z].purchase_price;
          prodele['apmc_charge']=productDetails[z].newapmccharge;
          prodele['gst_charge']=productDetails[z].newgstcharge;
          prodele['transport_charge']=""+productDetails[z].newtransportcharge;
          prodele['item_id']=productDetails[z].article_code;
          prodele['parent_id']=productDetails[z].parent_id;
          prodele['approved']="N";

          if(productDetails[z].type=="child"){
            prodele['old_base_price']=""+productDetails[z].old_base_price;
            prodele['old_dealer_price']=productDetails[z].old_dealer_price;
          }else{
            prodele['old_base_price']=""+productDetails[z].base_price;
            prodele['old_dealer_price']=productDetails[z].dealer_price;
          }

          prodele['updated_by']=sessionStorage.getItem("Logged User Email Id");
          prodele['updated_at']=currdformat;
          prodele['updated_from']="Price Update";
          prodele['landing_cost'] = productDetails[z].landing_cost;
          prodele['gross_cost'] = productDetails[z].gross_cost;
          prodele['buying_price_ex_gst'] = productDetails[z].buying_price_ex_gst;

          let productid=productDetails[z].article_code;
          let productkey=productDetails[z].rowselectkey;
          pendingprodele[`/${productkey}`]=prodele;

      }

      if(Object.keys(pendingprodele).length>0){
        console.log(pendingprodele);
        let currdate=formatDate(new Date());
        let currentYear=currdate.split("-")[2];
        let currentMonth=currdate.split("-")[1];

        database.database().ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}`).update(pendingprodele)
        .then(() => {
          toast("Successfully sent product price for approval.", { type: toast.TYPE.SUCCESS });
          setTimeout(function(){
             window.location.reload();
          }, 3000);
        }).catch((error) => {
          toast("Error in sending price details for approval : "+error, { type: toast.TYPE.ERROR });
        });
      }else{
        toast("Please select products to send for approval.", { type: toast.TYPE.ERROR });
      }
    }


    const dealerPriceCalculations = (priceFromDB, prodData, showMaterial, showWastage,updatedBasePrice) => {
        const resObj = {};
        resObj.material_cost = priceFromDB.material_cost;

        if (!showMaterial) {
            priceFromDB.material_cost_factor = 0;
        } if (showMaterial) {
            priceFromDB.material_cost_factor = 5;
        } if (!showWastage) {
            priceFromDB.wastage_per = 0;
        } if (showWastage) {
            priceFromDB.wastage_per = 2;
        }

        //material cost
        resObj.material_cost = parseToNo(priceFromDB.material_cost_factor) / parseToNo(priceFromDB.lot_size);
        resObj.material_cost = getRoundNo(resObj.material_cost);

        //wastage cost
        resObj.wastage = getAbsFromPer(priceFromDB.wastage_per) * parseToNo(updatedBasePrice);
        resObj.wastage = getRoundNo(resObj.wastage);

        //szmargin charges
        let sz_margin_per=1;
        resObj.sz_margin_charges = getAbsFromPer(sz_margin_per) * parseToNo(updatedBasePrice);
        resObj.sz_margin_charges = getRoundNo(resObj.sz_margin_charges);

        //conversion charges
        resObj.conversion_charges = getAbsFromPer(priceFromDB.conversion_per) * parseToNo(updatedBasePrice);
        resObj.conversion_charges = getRoundNo(resObj.conversion_charges);

        //extra margin charges
        resObj.extra_margin_charges = getAbsFromPer(priceFromDB.extra_margin_per) * parseToNo(updatedBasePrice);
        resObj.extra_margin_charges = getRoundNo(resObj.extra_margin_charges);

        //total price
        resObj.total_price_wo_rounding = parseToNo(updatedBasePrice) + parseToNo(priceFromDB.apmc_loading) + parseToNo(resObj.wastage) +
            parseToNo((getAbsFromPer(priceFromDB.conversion_per) * parseToNo(updatedBasePrice))) + parseToNo(resObj.material_cost) +
            parseToNo((getAbsFromPer(priceFromDB.extra_margin_per) * parseToNo(updatedBasePrice))) + parseToNo(resObj.sz_margin_charges);
        resObj.total_price = getRoundNo(resObj.total_price_wo_rounding);

        //total bag price
        resObj.total_bag_price = (parseToNo(resObj.total_price_wo_rounding) * parseToNo(priceFromDB.lot_size));
        resObj.total_bag_price = getRoundNo(resObj.total_bag_price);

        //apmc charges
        resObj.apmc_charge = getAbsFromPer(priceFromDB.apmc_per) * parseToNo(updatedBasePrice);
        resObj.apmc_charge = getRoundNoThree(resObj.apmc_charge);

        //gst charges
        let region_name=region!="All"?region:'A';
        resObj.gst_charge = getAbsFromPer(priceFromDB.gst_per) * (parseToNo(resObj.total_price_wo_rounding) + parseToNo(resObj.apmc_charge) + ((parseToNo(priceFromDB.transport_charge) / TRANSPORT_FACTOR['A'])* TRANSPORT_FACTOR[region_name]));
        resObj.gst_charge = getRoundNo(resObj.gst_charge);
        resObj.gst_abs_per = getAbsFromPer(priceFromDB.gst_per);

        //final price
        resObj.final_price = parseToNo(resObj.total_price_wo_rounding) + parseToNo(priceFromDB.transport_charge) + parseToNo(resObj.apmc_charge) + parseToNo(resObj.gst_charge);
        resObj.final_price = getRoundNo(resObj.final_price);


        //final bag price
        resObj.final_bag_price = parseToNo(resObj.final_price) * parseToNo(priceFromDB.lot_size);
        resObj.final_bag_price = getRoundNo(resObj.final_bag_price);


        if (parseToNo(prodData.market_price) > 0 || prodData.long_description.replace(/[^A-Z0-9]/ig, "").includes("Atta5kg")) {
            resObj['dealer_price'] = parseToNo(resObj.total_price) + "";
            resObj['dealer_price_pune'] = parseToNo(resObj.total_price_pune) + "";
        } else if (parseToNo(prodData.market_price) === 0) {
            resObj['dealer_price'] = parseToNo(resObj.total_bag_price) + "";
            resObj['dealer_price_pune'] = parseToNo(resObj.total_bag_price_pune) + "";
        }

        return resObj;
    };

    const isValid = (data) => {
        if (data === undefined || data === null || data === "" || data.length === 0) {
            return 0;
        }
        return data;
    };

    const parseToNo = (string) => {
        return parseFloat(isValid(string));
    };

    const getAbsFromPer = (data) => {
        return parseFloat(parseToNo(data) / 100);
    };

    const getRoundNo = (data) => {
        return parseToNo(data).toFixed(2);
    };

    const getRoundNoThree = (data) => {
        return parseToNo(data).toFixed(3);
    };

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Bulk Update Dealer Price
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
                    <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                      <ProductTableHeader />
                      <ProductTableBody />
                    </table>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={bulkAddProductForApproval}>Bulk Apply for Approval</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BulkAddProductPendingApprovalModal
