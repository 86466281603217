import styled from 'styled-components';

export const MainContainer = styled.div`
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #fff
`;

export const ShowMoreContainer = styled.div`
    width: 100%;
    padding: 0.5rem;
    text-align: center;

    button {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #fff;
        padding: 1rem 1.5rem;
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;

        &:hover {
            font-weight: bolder;
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
`;