import styled from 'styled-components';
import bg_img from '../assests/images/home_background.jpeg';

export const LoginContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${bg_img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
export const LoginHeader = styled.header`
    padding: 3rem;
    margin-top: -12rem;
    margin-bottom: 1rem;
    height: 30vh;
    span {
        padding: 6rem 7rem;
        border-radius: 0.5rem; 
        positon: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
`;
export const UserDetailsContainer = styled.div`
    display: flex;
    height: 42vh;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 2rem 2rem;
    font-size: 0.5rem;
    border-radius: 0.5rem;
    background-color:#fff;
    opacity:0.85;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);

    input {
        width: 100%;
        outline: none;
        margin: 0.1rem auto;
        padding: 1rem 0.5rem;
        font-size: 0.5rem;
        background-color: #fff;
        border-radius: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
        }
    }

    span {
        width: 100%;
        font-size: 0.9rem;
        color: #fff;
        text-align: center;
        background-color: red;
    }

    button {
        position:relative;
        margin: 0.3rem;
        outline: none;
        padding: 0.3rem 0.3rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        // background-color: #96fb96;
        border: 1px solid rgba(0, 0, 0, 0.4); 
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
        
        &:hover {
            color: 	#FFFFFF;
            cursor: pointer;
            box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
        }
    }
`;

