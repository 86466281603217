import React ,{useState,useEffect}from 'react'
import { Form,Modal,Button, Table } from 'react-bootstrap'

function ProductStockModal({itemIdToEdit,updatedStockValue,currentOperation,currentStockValue,initialDomValue,...props}) {
    console.log(props)
    
    const [stockValue, setStockValue] = useState("")
    const [resultantValue, setResultantValue] = useState("")
    const [newDomValue, setNewDomValue] = useState("")
    var resultStock = null;


    const addSubStock = ()=>{

        if(initialDomValue!==""){
            currentStockValue = initialDomValue;
            setNewDomValue(currentStockValue)}

    if(currentOperation==="Add")
    {
    resultStock = parseInt(currentStockValue) + parseInt(stockValue);
    setResultantValue(resultStock);
}
    if(currentOperation==="Subtract"){
    resultStock = parseInt(currentStockValue) - parseInt(stockValue);
    if(resultStock>=0){
    setResultantValue(resultStock)}
    else{
        props.onHide();
        alert(`Resultant stock not valid`)
    }
    }}
    


    useEffect(() => {
        if(stockValue!=="")
        addSubStock()
    }, [stockValue])
    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {currentOperation} products Stock of {itemIdToEdit}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tr>
                        <td>Enter the value to change</td>
                        <td><Form.Control id="changeStockValue" onChange={(e)=>setStockValue(e.target.value)}/></td>
                    </tr>
                    <tr>
                        <td>Result value</td>
                        <td><Form.Control readOnly value={stockValue!==""?resultantValue:initialDomValue?initialDomValue:(currentOperation==="Add"?currentStockValue:currentStockValue)} /></td>
                    </tr>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>{stockValue?updatedStockValue(stockValue):updatedStockValue("1");props.onHide()}}>Update Stock</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default ProductStockModal
