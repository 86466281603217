import styled from 'styled-components';

export const ShowMoreContainer = styled.div`
    width: 100%;
    padding: 0.5rem;
    text-align: center;

    button {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #007bff;
        color:#fff:
        border-color: #007bff;
        padding: 1rem 1.5rem;
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;

        &:hover {
            font-weight: bolder;
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
`;


export const CheckBoxContainer = styled.div`
    margin: 0rem 1rem; 

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;
    }

    label:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #0079bf;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 15px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
    }

    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 14px;
        width: 6px;
        height: 22px;
        border: solid #0079bf;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;