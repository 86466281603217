import React from "react";
const VideoComponent = (props) => {
  const { vidDetails, videoUrl, style, alt } = props;

  return (
    <div>
      {props.newVid === "" ? (
        <figure style={style || {}}>
          <video
            src={videoUrl}
            alt={alt}
            id={vidDetails.id}
            className={vidDetails.class}
            height={vidDetails.height}
            width={vidDetails.width}
            title={vidDetails.titleText}
          />
        </figure>
      ) : (
        <figure style={style || {}}>
          <video
            src={props.newVid}
            alt={alt}
            className={vidDetails.class}
            height={vidDetails.height}
            width={vidDetails.width}
            title="new video"
          />
        </figure>
      )}
    </div>
  );
};

export default VideoComponent;
