import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userType = sessionStorage.getItem("userType") || "";

const BoMHeaders = [
  {
    label: "Creation date",
    value: "created_at_formatted",
  },
  {
    label: "Major Category",
    value: "major_category",
  },
  {
    label: "Product Name",
    value: "bom_item_name",
  },
  {
    label: "Product Code",
    value: "bom_item_id",
  },
  {
    label: "Total Cost",
    value: "total_cost",
  },
  {
    label: "Cost Per Kg",
    value: "cost_per_kg",
  },
  {
    label: "No of Pcs",
    value: "bom_quantity",
  },
  {
    label: "Weight (Kg)",
    value: "total_weight",
  },
  {
    label: "Packaging Material",
    value: "packing_cost",
  },
  {
    label: "Final Cost",
    value: "final_price",
  },
];

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    this.sheet1 = this.props.sheet1;
    this.sheet1Headers = BoMHeaders;
    this.filename = this.props.filename;
    this.sheet1Name = this.props.sheet1Name;

    return (
      <>
        {userType === "Admin" || userType != "Operation" ? (
          <ExcelFile
            element={<Button variant="warning">Export</Button>}
            filename={this.filename}
          >
            <ExcelSheet data={this.sheet1} name={this.sheet1Name}>
              {Object.keys(this.sheet1Headers).map((key, index) => {
                return (
                  <ExcelColumn
                    key={index}
                    label={this.sheet1Headers[key].label}
                    value={this.sheet1Headers[key].value}
                  />
                );
              })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ExportToExcel;
