import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userType = sessionStorage.getItem("userType") || "";

const ExportDataHeaders = [
  {
    label: "Work Order ID",
    value: "work_order_id",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Total Amount",
    value: "total_amt",
  },
  {
    label: "Type",
    value: "type",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Payment Done",
    value: "payment_done",
  },
  {
    label: "Paid Amount",
    value: "paid_amt",
  },
  {
    label: "Payment Details",
    value: "payment_details",
  },
  {
    label: "Created By",
    value: "created_by",
  },
  {
    label: "Created At",
    value: "created_at",
  },
  {
    label: "Approved By",
    value: "approved_by",
  },
];

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
  }

  createExportData(data) {
    const exportData = [];
    data.forEach((workOrder, workOrderIndex) => {
      const exportRow = {};
      ExportDataHeaders.forEach((header) => {
        exportRow[header.label] = workOrder[header.value];
      });

      workOrder.items.forEach((item, itemIndex) => {
        exportRow[`Item ${itemIndex + 1} Description`] = item.item_description;
        exportRow[`Item ${itemIndex + 1} Total Cost`] = item.total_cost;
      });

      exportData.push(exportRow);
    });

    return exportData;
  }

  render() {
    const { sheet1, filename, sheetName } = this.props;

    return (
      <>
        {userType === "Admin" || userType !== "Operation" ? (
          <ExcelFile
            element={<Button variant="warning">Export</Button>}
            filename={filename}
          >
            {sheet1.length > 0 && (
              <ExcelSheet data={this.createExportData(sheet1)} name={sheetName}>
                {ExportDataHeaders.map((header, index) => (
                  <ExcelColumn key={index} label={header.label} value={header.value} />
                ))}
              </ExcelSheet>
            )}
          </ExcelFile>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ExportToExcel;
