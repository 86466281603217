import { object, string, number, array, boolean, bool } from "yup";

export const widgetCreationSchema = object({
  widget: string().required("Please select a widget"),
  type: string().required("Please select widget type"),
  action: string().required("Please select action"),
  priority: number()
    .min(1, "Priority cannot be less than 1")
    .required("Please enter Priority"),
  store_series: array()
    .min(1, "Please select at least one series")
    .of(string())
    .required("Please select at least one series"),
  item_id: array().of(string()),
  is_active: boolean().required(),
  data: array()
    .of(object())
    .when("type", {
      is: "BANNER",
      then: () =>
        array().of(
          object({
            image_url: string(),
            action: string(),
            item_id: array().of(string()),
            text: string(),
            view_type: string(),
            header_image: string(),
            header_title: string(),
            header_description: string(),
            header_background_color: string(),
          })
        ),
    })
    .when("type", {
      is: "SPLIT_BANNER",
      then: () =>
        array().of(
          object({
            image_url: string(),
            action: string(),
            item_id: array().of(string()),
            text: string().required("this is required"),
            view_type: string(),
            header_image: string(),
            header_title: string(),
            header_description: string(),
            header_background_color: string(),
          })
        ),
    })
    .when("type", {
      is: "SHORTCUTS",
      then: () =>
        array().of(
          object({
            icon_url: string(),
            text: string(),
            text_hi: string(),
            text_mr: string(),
            text_gj: string(),
            action: string(),
            hindi_description: string(),
            gujarati_description: string(),
            marathi_description: string(),
            description: string(),
          })
        ),
    })
    .when("type", {
      is: "PROMOTION_VIDEO",
      then: () =>
        array().of(
          object({
            video_url: string(),
            auto_play: boolean(),
            loop: boolean(),
            mute: boolean(),
          })
        ),
    }),

  widget_height: number().nullable(true),
  widget_width: number().nullable(true),
});
