import React, { useState, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import CSVReader from 'react-csv-reader';
import xlsx from 'xlsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@material-ui/core/Checkbox';
import { rootStore } from '../../store';
import database from '../../service/firebaseConfig';
import FooterComponent from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { Button, Tab, Nav, Col, Row, Card } from 'react-bootstrap';
import { MainContainer, BulkUploadContainer, TabContainer } from './wrapper';
import LogoComponent from '../../components/LogoComp';
import ImageUploader from 'react-images-upload'

import { CLOSE_SCREEN, INIT_PAGE_TITLE, SESSION_TEXT, PRICE_TABLE, PROD_MASTER_TABLE, TRANSPORT_FACTOR } from '../../constants/allConstants';



const BulkUploads = () => {

  const AddUserTable_A = database.database().ref(`${PROD_MASTER_TABLE}/A`);
  const AddUserTable_B = database.database().ref(`${PROD_MASTER_TABLE}/B`);
  const AddUserTable_D = database.database().ref(`${PROD_MASTER_TABLE}/D`);
  const AddUserTable_NK = database.database().ref(`${PROD_MASTER_TABLE}/NK`);
  const AddUserTable_DD = database.database().ref(`${PROD_MASTER_TABLE}/DD`);
  const AddUserTable_DG = database.database().ref(`${PROD_MASTER_TABLE}/DG`);
  const AddUserTable_PG = database.database().ref(`${PROD_MASTER_TABLE}/PG`);
  const AddUserTable_H = database.database().ref(`${PROD_MASTER_TABLE}/H`);
  const AddUserTable_F = database.database().ref(`${PROD_MASTER_TABLE}/F`);
  const AddUserTable_S = database.database().ref(`${PROD_MASTER_TABLE}/S`);
  const AddUserTable_N = database.database().ref(`${PROD_MASTER_TABLE}/N`);
  const AddUserTable_C = database.database().ref(`${PROD_MASTER_TABLE}/C`);
  const AddUserTable_CP = database.database().ref(`${PROD_MASTER_TABLE}/CP`);
  const AddUserTable_M = database.database().ref(`${PROD_MASTER_TABLE}/M`);
  const AddUserTable_P = database.database().ref(`${PROD_MASTER_TABLE}/P`);
  const AddUserTable_K = database.database().ref(`${PROD_MASTER_TABLE}/K`);
  const AddUserTable_O = database.database().ref(`${PROD_MASTER_TABLE}/O`);
  const AddUserTable_MT = database.database().ref(`${PROD_MASTER_TABLE}/MT`);

  const PriceDetailsDB = database.database().ref(`${PRICE_TABLE}`);

  const [region_A, onChangeregion_a] = useState(false)
  const [region_B, onChangeregion_b] = useState(false)
  const [region_D, onChangeregion_d] = useState(false)
  const [region_O, onChangeregion_o] = useState(false)
  const [region_NK, onChangeregion_nk] = useState(false)
  const [region_PG, onChangeregion_pg] = useState(false)
  const [region_DD, onChangeregion_dd] = useState(false)
  const [region_DG, onChangeregion_dg] = useState(false)
  const [region_C, onChangeregion_c] = useState(false)
  const [region_P, onChangeregion_p] = useState(false)
  const [region_F, onChangeregion_f] = useState(false)
  const [region_H, onChangeregion_h] = useState(false)
  const [region_S, onChangeregion_s] = useState(false)
  const [region_M, onChangeregion_m] = useState(false)
  const [region_MT, onChangeregion_mt] = useState(false)
  const [region_CP, onChangeregion_cp] = useState(false)
  const [region_N, onChangeregion_n] = useState(false)
  const [region_K, onChangeregion_k] = useState(false)


  toast.configure({
    autoClose: 3000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_RIGHT,
  });

  const globalState = useContext(rootStore);
  const { dispatch } = globalState;
  const history = useHistory();
  const [priceDetailsJson, setPriceDetailsJson] = useState({});
  const [itemsDetailsJson, setItemsDetailsJson] = useState({});
  const [isPriceFileSelected, setPriceFileSelected] = useState(false);
  const [isItemFilesSelected, setItemsFileSelected] = useState(false);
  const [dataObject, setDataObject] = useState("")

  const pricing_list = ["base_price", "lot_size", "apmc_loading", "conversion_per", "material_cost", "wastage", "sz_margin", "extra_margin_per", "apmc_per", "gst_per", "extra_margin_pune_per", "mrp","transport_charge"];

  const notifyUser = (success) => {
    if (success) {
      return toast("Successfully updated the data.", { type: toast.TYPE.SUCCESS });
    }
    return toast("Data could not be saved.", { type: toast.TYPE.ERROR });
  };

  const addItemDetailsToDB = () => {

    console.log("ItemDetails:::: ", itemsDetailsJson)

    if (region_A) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_A.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_D) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_D.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_K) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_K.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_NK) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_NK.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_O) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_O.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_PG) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_PG.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_P) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_P.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_C) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_C.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_B) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        let item_details = itemsDetailsJson[items];
        if (region_A) {
          item_details["transport_charge"] = (Number(item_details["transport_charge"]) * 1.5).toString();
          item_details["gst_charge"] = calculateGST(items, 'B').toString();
        }
        AddUserTable_B.child(items).update(item_details)
        item_details["transport_charge"] = (Number(item_details["transport_charge"]) / 1.5).toString();
      })
    }
    if (region_H) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_H.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_N) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        let item_details = itemsDetailsJson[items];
        if (region_A) {
          item_details["transport_charge"] = (Number(item_details["transport_charge"]) * 0.7).toString();
          item_details["gst_charge"] = calculateGST(items, 'N').toString();
        }
        AddUserTable_N.child(items).update(item_details)
        item_details["transport_charge"] = (Number(item_details["transport_charge"]) / 0.7).toString();
      })
    }
    if (region_S) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_S.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_M) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_M.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_MT) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_MT.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_CP) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_CP.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_F) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        AddUserTable_F.child(items).update(itemsDetailsJson[items])
      })
    }
    if (region_DD) {

      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        let item_details = itemsDetailsJson[items];
        if (region_A) {
          item_details["gst_charge"] = calculateGST(items, 'O').toString();
        }
        item_details["transport_charge"] = '0';
        AddUserTable_DD.child(items).update(item_details)
      })
    }
    if (region_DG) {
      Object.keys(itemsDetailsJson).forEach(items => {
        itemsDetailsJson[items]["transport_charge"] = '0';
        itemsDetailsJson[items]["apmc_charge"] = '0';
        let item_details = itemsDetailsJson[items];
        if (region_A) {
          item_details["gst_charge"] = calculateGST(items, 'O').toString();
        }
        item_details["transport_charge"] = '0';
        AddUserTable_DG.child(items).update(item_details)
      })
    }

    addPriceDetailsToDB("mumbai");

    // notifyUser(true)
    // window.location.reload()
  };

  const addPriceDetailsToDB = (city_name) => {

    console.log("PriceDetails:::: ", priceDetailsJson)

    PriceDetailsDB.child(city_name).child('items').set(priceDetailsJson).then(function () {
      notifyUser(true);
      setPriceFileSelected(false);
    }).catch(function (error) {
      console.log("Error while saving data ->" + error);
      notifyUser(false);
      setPriceFileSelected(false);
    });
  };

  const calculateGST = (item, region) => {
    let dealer_price = parseToNo(itemsDetailsJson[item]["dealer_price"]);
    if (parseToNo(itemsDetailsJson[item]["market_price"]) == 0) {
      dealer_price = parseToNo(itemsDetailsJson[item]["dealer_price"]) / (priceDetailsJson[item]["lot_size"] ? parseToNo(priceDetailsJson[item]["lot_size"]) : 1)
    }
    let gstCharges = (parseToNo(priceDetailsJson[item]["gst_per"]) / 100) * (dealer_price + parseToNo(itemsDetailsJson[item]["apmc_charge"]) +
      (parseToNo(itemsDetailsJson[item]["transport_charge"]) * TRANSPORT_FACTOR[region]));
    console.log("GST Charges::: for region::: " + region + " charges:: " + priceDetailsJson[item]["gst_per"] + itemsDetailsJson[item]["dealer_price"] + itemsDetailsJson[item]["apmc_charge"] + itemsDetailsJson[item]["transport_charge"] + TRANSPORT_FACTOR[region] + gstCharges.toFixed(3));
    return gstCharges.toFixed(3);
  };

  const closeBulkUpload = () => {
    dispatch({ type: CLOSE_SCREEN })
    history.push('/addProd');
  };


  const excelToJSONItemDetails = (data) => {
    console.log(data)

    const item = {};
    let headers = data[0];
    console.log("Headers", headers)
    headers.pop();
    for (let i = 1; i < data.length; i++) {
      const obj = {};
      let currentLine = data[i];

      for (let j = 0; j < headers.length; j++) {
        if (!pricing_list.includes(headers[j]) || headers[j] == "item_id" || headers[j] == "base_price" || headers[j] == "gst_per") {
          console.log(String(currentLine[j]).replace(/\t/g, '').trim())
          if (currentLine[j] === undefined) {
            obj[headers[j]] = ""
          } else {
            obj[headers[j]] = String(currentLine[j]).replace(/\t/g, '').trim();
          }
        }
      }
      if(obj.item_id){
        item[obj.item_id] = obj
      }
    }
    console.log("Item", item)
    setItemsFileSelected(true);
    setItemsDetailsJson(item);
    excelToJSONPriceDetails(data);


  };

  const excelToJSONPriceDetails = (data) => {
    PriceDetailsDB.child('mumbai').child('items').once('value', existingItems => {
      const item = {};
      let headers = data[0];
      existingItems.forEach(child => {
        const itemDetails = child.val();
        const itemNo = child.key;
        item[itemNo] = itemDetails;
      });

      for (let i = 1; i < data.length; i++) {
        const obj = {};
        let currentLine = data[i];

        for (let j = 0; j < headers.length; j++) {
          if (pricing_list.includes(headers[j]) || headers[j] == "item_id") {
            obj[headers[j]] = String(currentLine[j]).replace(/\t/g, '').trim();
          }
        }
        console.log("Added Item for Pricing Details:  ", obj.item_id);
        if(obj.item_id)
          item[obj.item_id] = obj;
      }
      console.log("Price details", item)
      setPriceFileSelected(true);
      setPriceDetailsJson(item);
    });
  };

  const isValid = (data) => {
    if (data === undefined || data === null || data === "" || data.length === 0) {
      return 0;
    }
    return data;
  };

  const parseToNo = (string) => {
    return parseFloat(isValid(string));
  };

  const upLoadFile = (val) => {

    const promise = new Promise((resolve, reject) => {

      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(val)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = xlsx.read(bufferArray, { type: 'buffer' })
        const wsName = wb.SheetNames[0]
        const ws = wb.Sheets[wsName]
        const data = xlsx.utils.sheet_to_json(ws, { header: 1 })
        resolve(data)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((data) => {
      console.log(JSON.parse(JSON.stringify(data)))
      setDataObject(data)
      excelToJSONItemDetails(data)
    })

  }

  const TabComp = () => {
    return (
      <>
        <div>
          {
            console.log(itemsDetailsJson)
          }
          <h5>Upload Items Details </h5>
          {!isPriceFileSelected ? <input type="file" id="input" onChange={(e) => {
            upLoadFile(e.target.files[0])
          }} /> : null}
          {isItemFilesSelected ? <h5>Data Loaded...</h5> : null}
          {isItemFilesSelected &&
            <>
              <Card style={{ margin: '20px' }}>
                <Card.Header>Select regions to upload Items</Card.Header>
                <Card.Body>
                  <Checkbox id="region_A" checked={region_A} onChange={(e) => onChangeregion_a(e.target.checked)} />
                  <label htmlFor="region_A" >Region A </label><br />
                  <Checkbox id="region_B" checked={region_B} onChange={(e) => onChangeregion_b(e.target.checked)} />
                  <label htmlFor="region_B" >Region B </label><br />
                  <Checkbox id="region_C" checked={region_C} onChange={(e) => onChangeregion_c(e.target.checked)} />
                  <label htmlFor="region_C" >Region C </label><br />
                  <Checkbox id="region_NK" checked={region_NK} onChange={(e) => onChangeregion_nk(e.target.checked)} />
                  <label htmlFor="region_NK" >Region NK </label><br />
                  <Checkbox id="region_PG" checked={region_PG} onChange={(e) => onChangeregion_pg(e.target.checked)} />
                  <label htmlFor="region_PG" >Region PG </label><br />
                  <Checkbox id="region_P" checked={region_P} onChange={(e) => onChangeregion_p(e.target.checked)} />
                  <label htmlFor="region_P" >Region P </label><br />
                  <Checkbox id="region_D" checked={region_D} onChange={(e) => onChangeregion_d(e.target.checked)} />
                  <label htmlFor="region_D" >Region D </label><br />
                  <Checkbox id="region_K" checked={region_K} onChange={(e) => onChangeregion_k(e.target.checked)} />
                  <label htmlFor="region_K" >Region K </label><br />
                  <Checkbox id="region_S" checked={region_S} onChange={(e) => onChangeregion_s(e.target.checked)} />
                  <label htmlFor="region_S" >Region S </label><br />
                  <Checkbox id="region_F" checked={region_F} onChange={(e) => onChangeregion_f(e.target.checked)} />
                  <label htmlFor="region_F" >Region F </label><br />
                  <Checkbox id="region_M" checked={region_M} onChange={(e) => onChangeregion_m(e.target.checked)} />
                  <label htmlFor="region_M" >Region M </label><br />
                  <Checkbox id="region_CP" checked={region_CP} onChange={(e) => onChangeregion_cp(e.target.checked)} />
                  <label htmlFor="region_CP" >Region CP </label><br />
                  <Checkbox id="region_N" checked={region_N} onChange={(e) => onChangeregion_n(e.target.checked)} />
                  <label htmlFor="region_N" >Region N </label><br />
                  <Checkbox id="region_O" checked={region_O} onChange={(e) => onChangeregion_o(e.target.checked)} />
                  <label htmlFor="region_O" >Region O </label><br />
                  <Checkbox id="region_MT" checked={region_MT} onChange={(e) => onChangeregion_mt(e.target.checked)} />
                  <label htmlFor="region_MT" >Region MT </label><br />
                </Card.Body>
                <Button variant="primary" onClick={() => addItemDetailsToDB()}> Save </Button>
              </Card>
            </>}
          <br /><br />
        </div>
        <br /><br />
      </>

    );
  };



  return (
    <MainContainer>
      <LogoComponent />
      <BulkUploadContainer>
        <header>
          <h3>Bulk Uploads</h3>
          <span onClick={closeBulkUpload}> x </span>
        </header>
        <hr />
        <TabComp />
      </BulkUploadContainer>
      <FooterComponent />
    </MainContainer>
  );
};

export default BulkUploads;
