import axios from "axios";

const production_url = "http://services2.superzop.com:3000";
const development_url = process.env.REACT_APP_DELIVERY_BACKEND_URL || "http://dev-services2.superzop.com";

const ordering_production_url = "http://services.superzop.com:3000";
const ordering_development_url = process.env.REACT_APP_ORDERING_BACKEND_URL || "http://test-services.superzop.com:3000";
const zoho_sync_production_url = process.env.REACT_APP_ZOHO_SYNC_PRODUCTION_URL || "http://65.2.123.23:8080";

const localhost_url = "http://localhost:3000";

export const apiKey = process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyA_Sfz85Z6StnlSA_65XJ3yJCgSqUZgCos";
export const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "superzop-ordering-development.firebaseapp.com";
export const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL || "https://superzop-ordering-development.firebaseio.com/";
export const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || "superzop-ordering-development";
export const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "superzop-ordering-development.appspot.com";

export default axios.create({
  baseURL: development_url,
});

// delivery commercial server
export const deliveryCommercialServer = axios.create({
  baseURL: development_url,
});

export const DELIVERY_BASE_URL = development_url;


// zoho sync server
export const zohoSyncServer = axios.create({
  baseURL: zoho_sync_production_url,
});

// Superzop ordering server
export const orderingServer = axios.create({
  baseURL: ordering_development_url,
});

export const ORDERING_BASE_URL = ordering_development_url

let prod_support_url = "http://support.superzop.com:3000";
let localhost_support_url = "http://localhost:3001";
let dev_support_url = process.env.REACT_APP_SUPPORT_PORTAL_URL || "http://dev-support.superzop.com";



export const support_portal_url = dev_support_url;

// Offers
let prod_offers_url = "http://api.superzop.com:3000";
let localhost_offers_url = "http://localhost:3000";
let dev_offers_url = process.env.REACT_APP_SUPERZOP_BACKEND_URL || "http://dev-api.superzop.com";
export const BASE_URL = dev_offers_url;
export const OFFER_BASE_URL = dev_offers_url;
