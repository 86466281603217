import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import DeleteWidgetConfirmation from "../Modals/DeleteWidgetConfirmation";

import { FcSupport, FcEmptyTrash } from "react-icons/fc";

const removeItemAnimation = keyframes`
0%{
  transform  :  translate(0);
  background-color: red;

}

100%{
  opacity: 0;
  background-color: red;
  transform: translateX(-100rem);
}
`;

const WidgetItem = styled.div`
  height: 4rem;
  background-color: azure;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem 1.2rem;
  box-shadow: 0.2rem 0.1rem 0.5rem black;
  transition: transform 0.1s ease-out;
  &:hover {
    transform: translateY(-0.4rem);
    box-shadow: 0.2rem 0.1rem 0.3rem black;
  }

  &:is(.delete) {
    animation: ${removeItemAnimation} 1s ease-in;
  }
`;

const ItemHeading = styled.div`
  font-weight: bold;
`;

const ItemRightPane = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 8%;
  font-size: 1.8rem;
  /* border-bottom: 0.1rem solid black; */
  /* background-color: violet; */
`;

const DeleteIcon = styled(FcEmptyTrash)`
  &:hover {
    cursor: pointer;
  }
`;

const EditIcon = styled(FcSupport)`
  font-size: 1.6rem;
  margin-top: 0.15rem;

  &:hover {
    cursor: pointer;
  }
`;

export const WidgetsListItem = ({
  item,
  handleOnEditWidgetModalShow,
  deleteWidgetItem,
  index,
}) => {
  const [classNames, setClassNames] = useState("");
  const [deleteConfirmationModalOpened, setDeleteConfirmationModalOpened] =
    useState(false);

  function handleOnDeleteConfirmed() {
    setClassNames("delete");
    setTimeout(() => deleteWidgetItem(index), 1000);
  }

  return (
    <>
      <WidgetItem className={classNames}>
        <ItemHeading>
          Widget: {item.widget} | Type: {item.type} |{" "}
          {item.action ? `Action: ${item.action}` : ""}
        </ItemHeading>
        <ItemRightPane>
          {/* <DeleteIcon
            onClick={() => {
              setDeleteConfirmationModalOpened(true);
            }}
          /> */}
          <EditIcon onClick={() => handleOnEditWidgetModalShow(true, index)} />
        </ItemRightPane>
      </WidgetItem>
      <DeleteWidgetConfirmation
        modalOpened={deleteConfirmationModalOpened}
        setModalOpened={setDeleteConfirmationModalOpened}
        handleOnDeleteConfirmed={handleOnDeleteConfirmed}
      />
    </>
  );
};
