import styled from 'styled-components';

export const SearchContainer = styled.div`
    width: 100%; 

    input {
        width: 100%;
        padding: 0.6rem;
        outline: none;
        text-align: center;
        border-radius: 0.5rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
`;

export const DisplayItemsContainer = styled.span`
    display: inline-block;
    width: 100%;
    padding: 0.25rem;
    text-align: center;
    color: #a1a1a1;
`;