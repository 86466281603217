import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert, Button,Modal } from 'react-bootstrap'
import database from '../../service/firebaseConfig';
import { toast } from 'react-toastify';

function UpdatePurchaseOrderModal({updateDetails,...props}) {
    const [itemDetailsObject, setItemDetailsObject] = useState({})
    const [maxInventory, setMaxInventory] = useState("");
    const REORDER_POINT = 2;
    const STOCK_TARGET = 2;

    useEffect(() => {
      console.log('useeffect test');
        if(updateDetails.Category=="MASALAS/SPICES" || updateDetails.Category=="DRY FRUITS"){
          setMaxInventory(15);
        }else{
          setMaxInventory(10);
        }
    }, [])

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const updatePurchaseOrderDetails = () => {
        console.log(itemDetailsObject);
        let updatePurchaseOrderDetailsObj={};
        updatePurchaseOrderDetailsObj['reorder_point']=(itemDetailsObject.reorder_point!=undefined?itemDetailsObject.reorder_point:2)
        updatePurchaseOrderDetailsObj['stock_target']=(itemDetailsObject.stock_target!=undefined?itemDetailsObject.stock_target:4)
        updatePurchaseOrderDetailsObj['max_inventory']=(itemDetailsObject.max_inventory!=undefined?itemDetailsObject.max_inventory:maxInventory)

        console.log(updatePurchaseOrderDetailsObj);
        console.log(updateDetails.item_id);

        database.database().ref('Product_Base_Data').child(`${updateDetails.item_id}`).update(updatePurchaseOrderDetailsObj).then(() => {
            props.callback(updatePurchaseOrderDetailsObj);
            props.onHide();
            toast("Updated item successfully", { type: toast.TYPE.SUCCESS });
        }).catch(()=>{
            props.callback(updatePurchaseOrderDetailsObj);
            props.onHide();
            toast("Couldn't update the item", { type: toast.TYPE.ERROR });
        })
    }


    return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Update Purchase Order of {updateDetails.item_disc}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <tbody>
                        <tr>
                            <td>Reorder Point (Days)</td>
                            <td><Form.Control defaultValue={updateDetails.reorder_point?updateDetails.reorder_point:REORDER_POINT} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"reorder_point":(e.target.value)})} /></td>
                        </tr>
                        <tr>
                            <td>Stock Target (Days)</td>
                            <td><Form.Control defaultValue={updateDetails.stock_target?updateDetails.stock_target:STOCK_TARGET} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"stock_target":(e.target.value)})}/></td>
                        </tr>
                        <tr>
                            <td>Max Inventory (Days)</td>
                            <td><Form.Control defaultValue={maxInventory} onChange={(e)=>setItemDetailsObject({...itemDetailsObject,"max_inventory":(e.target.value)})}/></td>
                        </tr>
                      </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updatePurchaseOrderDetails}>Submit</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
    )
}

export default UpdatePurchaseOrderModal
