import React, { useState } from "react";
import { Card } from "@mantine/core";

import OfferListingCardContentScreen from "./OfferListingCardContentScreen";
import OfferListingCardDeleteScreen from "./OfferListingCardDeleteScreen";
import OfferListingCardUploadScreen from "./OfferListingCardUploadScreen";
import { toast } from "react-toastify";

//! Offer ID
//! Image name
//! Start Date
//! End Date
//! OfferType
//! ItemID (probably we can also show item description. If required we can store the item description, when creating the offer).
//! Retailer Group for Special
//! Store Series for other Offers

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

const OfferListingCard = (props) => {
  const handleDeleteOffer = props.handleDeleteOffer;
  const handleUploadNewImage = props.handleUploadNewImage;
  const offerDetails = props.offerDetails;
  const offerCategory = props.offerCategory;

  const [cardContentShown, setCardContentShown] = useState("main");

  const handleDeleteIconClick = (event) => {
    setCardContentShown("delete");
  };

  const handleUploadImageClick = (event) => {
    setCardContentShown("upload");
  };

  const handleCardBannerUpdate = (imageUploadURL, offerCode) => {
    handleUploadNewImage(imageUploadURL, offerCode).then(() => {
      setCardContentShown("main");
    });
  };

  const handleDeleteOfferCancel = (event) => {
    setCardContentShown("main");
  };

  const handleUploadImageCancel = (event) => {
    setCardContentShown("main");
  };

  const renderMainContent = () => {
    if (cardContentShown === "main") {
      return (
        <OfferListingCardContentScreen
          {...props}
          offerDetails={offerDetails}
          offerCategory={offerCategory}
          handleUploadImageClick={handleUploadImageClick}
          handleDeleteIconClick={handleDeleteIconClick}
        />
      );
    } else if (cardContentShown === "delete") {
      return (
        <OfferListingCardDeleteScreen
          offerDetails={offerDetails}
          offerCategory={offerCategory}
          handleDeleteOffer={handleDeleteOffer}
          handleDeleteOfferCancel={handleDeleteOfferCancel}
        />
      );
    } else if (cardContentShown === "upload") {
      return (
        <OfferListingCardUploadScreen
          offerDetails={offerDetails}
          offerCategory={offerCategory}
          handleCardBannerUpdate={handleCardBannerUpdate}
          handleUploadImageCancel={handleUploadImageCancel}
        />
      );
    }
  };

  return (
    <div className="OfferListingCard__Container">
      <Card shadow="sm" style={{ padding: 0 }}>
        {renderMainContent()}
      </Card>
    </div>
  );
};

export default OfferListingCard;
