import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormLabel,
  InputGroup,
  Navbar,
  Table,
} from "react-bootstrap";
import { LOGGED_USER_EMAIL, PRICE_TABLE, PROD_MASTER_TABLE } from "../../constants/allConstants";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import database from "../../service/firebaseConfig";
import PriceCalculations from "../../service/priceCalculations";
import axios from 'axios';
import { ORDERING_BASE_URL} from "../../service/servicesConfig";
import moment from 'moment';
    
const Pending_Price_Updates = () => {
  const priceDetailsDB = database.database().ref(`${PRICE_TABLE}/mumbai/items`);
  const [PendingData, setData] = useState([]);
  const [date, setDate] = useState(new Date());
   const [showMaterial, toggleMaterial] = useState(false);
   const [showWastage, toggleWastage] = useState(false);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }
  function formatDate2(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const addMaterialOrWastageCost = (obj,calcObj,setMaterialCost = true) => {
    const temp = { ...obj };
    if (showMaterial && setMaterialCost) {
      temp.material_cost = calcObj.material_cost;
    } else if (!showMaterial && setMaterialCost) {
      temp.material_cost = "0";
    }

    if (showWastage) {
      temp.wastage = calcObj.wastage;
    } else if (!showWastage) {
      temp.wastage = "0";
    }

    return temp;
  };

const initAddData = (data) => {
  const temp = { ...data };
  temp.sz_margin_per = "1%";
  return temp;
};

const PriceModify = (key,priceFromDB) => {
PendingData.forEach((element) => {
  if (element.key === key) {
    element.approved = "Y";
    const temp = { ...priceFromDB };
    temp.base_price = element.base_price;
    priceFromDB = temp;
  }
});
setData([...PendingData]);
return priceFromDB ; 
}
  const getPriceDetailsFromDB = (item_id,key) => {
    let priceFromDB = {}
    let prodData = {}
    let calcObj = {}
    priceDetailsDB.once("value").then((dataSnapshot) => {
      dataSnapshot.forEach((child) => {
        if (parseInt(child.key) === parseInt(item_id)) {
          const data = child.val();
          priceFromDB = initAddData(data);
          priceFromDB = PriceModify(key, priceFromDB);
          console.log(priceFromDB);
          
        }
      });
    });

    
   database
       .database()
       .ref(`${PROD_MASTER_TABLE}/A/${item_id}`)
       .once("value")
       .then((snapShot) => {
        const fetched = snapShot.val()
        prodData = fetched
        console.log(prodData);
        console.log(priceFromDB);
        calcObj = PriceCalculations(priceFromDB, prodData, showMaterial, showWastage);
        console.log(calcObj);

         setPriceDetailsToDB(item_id,priceFromDB,calcObj);
         setProdData(item_id,prodData,priceFromDB,calcObj);
       })
       .catch((err) => {
         console.log(err);
       });
      
   
  }
const setPriceDetailsToDB = (item_id,priceFromDB,calcObj) => {
  const tempTime = new Date();
  const initObj = {
    base_price: priceFromDB.base_price,
    sz_margin: calcObj.sz_margin_charges,
    total_price: calcObj.total_price,
    total_bag_price: calcObj.total_bag_price,
    updated_by: sessionStorage.getItem(LOGGED_USER_EMAIL),
    last_updated: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') //tempTime,
  }
    const updObj = { ...addMaterialOrWastageCost(initObj,calcObj, false) };
    console.log(updObj);
    database
      .database()
      .ref(`${PRICE_TABLE}/mumbai/items/${item_id}`)
      .update(updObj).then(async () => {
        
        let url = `${ORDERING_BASE_URL}/api/superzop/admin/price_details/insertpricedetails`;
        updObj['item_id'] = item_id;
        let dataToSubmit = updObj;
        let respData = await axios.post(url, dataToSubmit);
        let resCode = respData.data?.success || 0;   
        if(resCode == 1)      {
          console.log('Successfully updated price details');
        }else {
          console.log('Error while updating price details');
        }
        //console.log('Successfully updated price details');
      }).catch((err) => {
        console.log(err);
      })
  
}

const getProdData = (item_id) => {
  database.database().ref(`${PROD_MASTER_TABLE}/A/${item_id}`).once("value").then((snapShot) => {
    // updateProdData(snapShot.val());
    console.log(snapShot.val());
  }).catch((err) => {
    console.log(err);
  })
}
    const getRoundNo = (data) => {
          return parseFloat(data).toFixed(2);
        }
const setProdData = (item_id,prodData,priceFromDB,calcObj) => {
  const tempTime = new Date();
        let total_price = calcObj.total_price_wo_rounding;
        let total_bag_price = calcObj.total_bag_price;
        let dealer_price = calcObj.dealer_price;
        const updObj = {
            base_price: priceFromDB.base_price,
            lot_size: priceFromDB.lot_size,
            gst_charge: calcObj.gst_charge,
            total_price: getRoundNo(total_price),
            total_bag_price: total_bag_price,
            dealer_price: dealer_price,
            hindi_description: prodData.hindi_description,
            item_description1:prodData.item_description1,
            long_description: prodData.long_description,
            long_description_hindi:prodData.long_description_hindi,
            weight:prodData.weight,
            updated_by: sessionStorage.getItem(LOGGED_USER_EMAIL),
            last_updated: tempTime
        };
        console.log(updObj);
database.database().ref(`${PROD_MASTER_TABLE}/A/${item_id}`).update(updObj).then((snapShot) => {
  console.log("Price Got Successfully Updated in Product Master")
}).catch((err) => {
  console.log(err)
})
}
  useEffect(() => {

    let data = [];
    let currdate = formatDate(date);
    let currentYear = currdate.split("-")[2];
    let currentMonth = currdate.split("-")[1];
    console.log(currdate);
    database
      .database()
      .ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}`)
      .once("value")
      .then((snapShot) => {
        console.log("Data Fetched Successfully");
        snapShot.forEach((key) => {
          const obj = {
            key: key.key,
            ...key.val(),
          };
          data.push(obj);
        });
      })
      .then(() => {
        setData([...data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date]);

  const deflt = formatDate2(date);

  const handleApprove = (e) => {
    // approved="Y"to PendingData -Done
let currdate = formatDate(date);
let currentYear = currdate.split("-")[2];
let currentMonth = currdate.split("-")[1];
const obj = {
  approved: "Y",
  approved_at: `${formatDate(
    new Date()
  )} ${new Date().getHours()}:${new Date().getMinutes()}`,
  approved_by: `${sessionStorage.getItem(LOGGED_USER_EMAIL)}`,
};
const update_key = e.target.id;
database
  .database()
  .ref(
    `Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/${e.target.id}`
  )
  .update(obj)
  .then((snapShot) => {
    console.log('Updated in Pending Price Update')
  })
  .catch((err) => {
    console.log(err);
  });

  
PendingData.forEach((element) => {
  if (element.key === update_key) {
    element.approved = "Y";
  }
});
setData([...PendingData]);
getPriceDetailsFromDB(e.target.value,e.target.id);

  };

  
  const handleReject = (e) => {
    // approved = "N"
    let currdate = formatDate(date);
    let currentYear = currdate.split("-")[2];
    let currentMonth = currdate.split("-")[1];
    const obj = {
      approved: "N",
      approved_at: `${formatDate(
        new Date()
      )} ${new Date().getHours()}:${new Date().getMinutes()}`,
      approved_by: `${sessionStorage.getItem(LOGGED_USER_EMAIL)}`,
    };
    const update_key = e.target.id;
    database
      .database()
      .ref(
        `Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/${e.target.id}`
      )
      .update(obj)
      .then((snapShot) => {})
      .catch((err) => {
        console.log(err);
      });
    PendingData.forEach((element) => {
      if (element.key === update_key) {
        element.approved = "N";
      }
    });
    setData([...PendingData]);
    //  console.log(PendingData);

    // base_price = e.target.value to price master

  };
  return (
    <>
      <NavBar />
      <Navbar style={{ paddingTop: 100 }}>
        <FormLabel>Select Date : </FormLabel>
        <Form.Control
          type="date"
          size="sm"
          style={{
            width: "fit-content",
            marginLeft: "15px",
            marginBottom: "10px",
          }}
          defaultValue={deflt}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
      </Navbar>
      <div>
        <Table striped bordered>
          <thead>
            <tr>
              <td>
                <b>
                  <center>SL</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Item ID</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Item Description</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Category</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Old Base Price</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Base Price</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Old Dealer Price</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Dealer Price</center>
                </b>
              </td>
              <td>
                <b>
                  <center>GST Charge</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Transport Charge</center>
                </b>
              </td>
              <td>
                <b>
                  <center>APMC Charge</center>
                </b>
              </td>
              <td>
                <b>
                  <center>Status</center>
                </b>
              </td>
            </tr>
          </thead>
          <tbody>
            {PendingData.map((element, index) => (
              <tr key = {element.key}>
                <td>
                  <center>{index + 1}</center>
                </td>
                <td>
                  <center>{element.item_id}</center>
                </td>
                <td>
                  <center>{element.item_desc}</center>
                </td>
                <td>
                  <center>{element.category}</center>
                </td>
                <td>
                  <center>{element.old_base_price}</center>
                </td>
                <td>
                  <center>{element.base_price}</center>
                </td>
                <td>
                  <center>{element.old_dealer_price}</center>
                </td>
                <td>
                  <center>{element.dealer_price}</center>
                </td>
                <td>
                  <center>{element.gst_charge}</center>
                </td>
                <td>
                  <center>{element.transport_charge}</center>
                </td>
                <td>
                  <center>{element.apmc_charge}</center>
                </td>
                {element.approved === "Y" && (
                  <td>
                    <center>
                      Approved
                    </center>
                  </td>
                )}
                {element.approved === "N" && (
                  <td>
                    <center>Rejected</center>
                  </td>
                )}
                {element.approved === "" && (
                  <td>
                    <Button
                      style={{ marginLeft: "15px" }}
                      onClick={handleApprove}
                      id={element.key}
                      value={element.item_id}
                    >
                      Approve
                    </Button>
                    <Button
                      style={{ marginLeft: "15px" }}
                      onClick={handleReject}
                      id={element.key}
                    >
                      Reject
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Pending_Price_Updates;