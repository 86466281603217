import {
  Box,
  ColorInput,
  Divider,
  Flex,
  Image,
  MultiSelect,
  Select,
  Stack,
  Switch,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import React, { Fragment, useEffect } from "react";
import UploadBannerImage from "../../ImageUpload/UploadBannerImage";
import { ErrorMessage, Field } from "formik";
import { actionsList } from "../../utils/types";
import { formatYoutubeURLForPreview } from "../../utils/utils";

export default function DynamicDataForm(props) {
  const { values } = props;
  // console.log("values in dynamic data form", values);
  if (values.widget === "CARD") {
    return <Card {...props} />;
  } else if (values.widget === "AUTO_SLIDER") {
    return <AutoSlider {...props} />;
  } else if (values.widget === "HORIZONTAL_SCROLL") {
    return <HorizontalScroll {...props} />;
  } else if (values.widget === "VERTICAL_SCROLL") {
    return <VerticalScroll {...props} />;
  } else {
    return <></>;
  }
}

function Card({
  values,
  errors,
  touched,
  setFieldValue,
  setIsFormLoading,
  availableItemsForSelection,
  ...props
}) {
  if (values.type === "BANNER") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Divider
              variant="dashed"
              label="Image upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].image_url && (
                <Image
                  alt="image_url"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].image_url}
                />
              )}

              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].image_url", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Field
              as={Select}
              name="action"
              label="Action"
              required
              value={values.data[0].action}
              onChange={(value) => {
                setFieldValue("data[0].action", value);
              }}
              data={actionsList.map((item, index) => {
                return item;
              })}
            />
            <Field
              as={MultiSelect}
              value={values.data[0].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[0].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                label="Text"
                name="values.data[0].text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
                // error={
                //   Array.isArray(touched.data) &&
                //   touched?.data[0]?.text &&
                //   Array.isArray(errors.data) &&
                //   errors?.data[0]?.text
                //     ? errors?.data[0]?.text
                //     : false
                // }
              />

              <ErrorMessage name="values.data[0].text" />
            </Box>
            <Divider
              variant="dashed"
              label="Header Image upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].header_image && (
                <Image
                  alt="header_image"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].header_image}
                />
              )}
              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].header_image", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter title"
                value={values.data[0].header_title}
                label="Header Title"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].header_title",
                    event.currentTarget.value
                  )
                }
                name={"values.data[0].header_title"}
                // error={
                //   touched?.data[0]?.header_title &&
                //   errors?.data[0]?.header_title
                //     ? errors?.data[0]?.header_title
                //     : false
                // }
              />
              <ErrorMessage name={"values.data[0].header_title"} />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter description"
                value={values.data[0].header_description}
                label="Header description"
                name="values.data[0].header_description"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].header_description",
                    event.currentTarget.value
                  )
                }
                // error={
                //   touched?.data[0]?.header_description &&
                //   errors?.data[0]?.header_description
                //     ? errors?.data[0]?.header_description
                //     : false
                // }
              />
              <ErrorMessage name="values.data[0].header_description" />
            </Box>
            <Divider
              variant="dashed"
              label="Header Background Color"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              <Field
                as={ColorInput}
                format="hex"
                placeholder="Enter color"
                value={values.data[0].header_background_color}
                label="Color"
                onChange={(color) =>
                  setFieldValue("data[0].header_background_color", color)
                }
                // error={
                //   touched?.data[0]?.header_background_color &&
                //   errors?.data[0]?.header_background_color
                //     ? errors?.data[0]?.header_background_color
                //     : false
                // }
              />
              <ErrorMessage name="values.data[0].header_background_color" />
            </Flex>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else if (values.type === "SPLIT_BANNER") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.List>
          <Tabs.Tab style={{ color: "green" }} value="data1">
            Data 1
          </Tabs.Tab>
          <Tabs.Tab style={{ color: "blue" }} value="data2">
            Data 2
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Divider
              variant="dashed"
              label="Image upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].image_url && (
                <Image
                  alt="image_url"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].image_url}
                />
              )}

              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].image_url", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Field
              as={Select}
              name="action"
              label="Action"
              required
              value={values.data[0].action}
              onChange={(value) => {
                setFieldValue("data[0].action", value);
              }}
              data={actionsList.map((item, index) => {
                return item;
              })}
            />
            <Field
              as={MultiSelect}
              value={values.data[0].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[0].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                name={`data[0].text`}
                label="Text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
                // error={
                //   touched?.data[0]?.text && errors?.data[0]?.text
                //     ? errors?.data[0]?.text
                //     : false
                // }
              />
              <ErrorMessage name={`data[0].text`} component={"div"} />
            </Box>
            <Divider
              variant="dashed"
              label="Header Image upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].header_image && (
                <Image
                  alt="header_image"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].header_image}
                />
              )}
              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].header_image", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter title"
                value={values.data[0].header_title}
                label="Header Title"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].header_title",
                    event.currentTarget.value
                  )
                }
                name={"values.data[0].header_title"}
                // error={
                //   touched?.data[0]?.header_title &&
                //   errors?.data[0]?.header_title
                //     ? errors?.data[0]?.header_title
                //     : false
                // }
              />
              <ErrorMessage name={"values.data[0].header_title"} />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter description"
                value={values.data[0].header_description}
                label="Header description"
                name={"values.data[0].header_description"}
                onChange={(event) =>
                  setFieldValue(
                    "data[0].header_description",
                    event.currentTarget.value
                  )
                }
                // error={
                //   touched?.data[0]?.header_description &&
                //   errors?.data[0]?.header_description
                //     ? errors?.data[0]?.header_description
                //     : false
                // }
              />
              <ErrorMessage name={"values.data[0].header_description"} />
            </Box>
            <Divider
              variant="dashed"
              label="Header Background Color"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              <Field
                as={ColorInput}
                format="hex"
                placeholder="Enter color"
                name={`values.data[0].header_background_color`}
                value={values.data[0].header_background_color}
                label="Color"
                onChange={(color) =>
                  setFieldValue("data[0].header_background_color", color)
                }
                // error={
                //   touched?.data[0]?.header_background_color &&
                //   errors?.data[0]?.header_background_color
                //     ? errors?.data[0]?.header_background_color
                //     : false
                // }
              />
              <ErrorMessage name={`values.data[0].header_background_color`} />
            </Flex>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel
          style={{
            backgroundColor: "#bde0ff",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data2"
        >
          {" "}
          <Stack>
            <Divider
              variant="dashed"
              label="Image upload"
              labelPosition="center"
              color="blue"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[1].image_url && (
                <Image
                  alt="image_url"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[1].image_url}
                />
              )}

              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[1].image_url", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Field
              as={Select}
              name="action"
              label="Action"
              required
              value={values.data[1].action}
              onChange={(value) => {
                setFieldValue("data[1].action", value);
              }}
              data={actionsList.map((item, index) => {
                return item;
              })}
            />
            <Field
              as={MultiSelect}
              value={values.data[1].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[1].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[1].text}
                name={"values.data[1].text"}
                label="Text"
                onChange={(event) =>
                  setFieldValue("data[1].text", event.currentTarget.value)
                }
                // error={
                //   touched?.data[1]?.text && errors?.data[1]?.text
                //     ? errors?.data[1]?.text
                //     : false
                // }
              />
              <ErrorMessage name={"values.data[1].text"} />
            </Box>
            <Divider
              variant="dashed"
              label="Header Image upload"
              labelPosition="center"
              color="blue"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[1].header_image && (
                <Image
                  alt="header_image"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[1].header_image}
                />
              )}
              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[1].header_image", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter title"
                value={values.data[1].header_title}
                label="Header Title"
                onChange={(event) =>
                  setFieldValue(
                    "data[1].header_title",
                    event.currentTarget.value
                  )
                }
                name={"values.data[1].header_title"}
                // error={
                //   touched?.data[1]?.header_title &&
                //   errors?.data[1]?.header_title
                //     ? errors?.data[1]?.header_title
                //     : false
                // }
              />
              <ErrorMessage name={"values.data[1].header_title"} />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter description"
                value={values.data[1].header_description}
                label="Header description"
                onChange={(event) =>
                  setFieldValue(
                    "data[1].header_description",
                    event.currentTarget.value
                  )
                }
                name={"values.data[1].header_description"}
                // error={
                //   touched?.data[1]?.header_description &&
                //   errors?.data[1]?.header_description
                //     ? errors?.data[1]?.header_description
                //     : false
                // }
              />
              <ErrorMessage name={"values.data[1].header_description"} />
            </Box>
            <Divider
              variant="dashed"
              label="Header Background Color"
              labelPosition="center"
              color="blue"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              <Field
                as={ColorInput}
                format="hex"
                placeholder="Enter color"
                value={values.data[1].header_background_color}
                label="Color"
                onChange={(color) =>
                  setFieldValue("data[1].header_background_color", color)
                }
              />
              <ErrorMessage name={"values.data[1].header_background_color"} />
            </Flex>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else if (values.type === "SHORTCUTS") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>
        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Divider
              variant="dashed"
              label="Icon upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].icon_url && (
                <Image
                  alt="icon_url"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].icon_url}
                />
              )}

              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].icon_url", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Divider variant="dashed" color="green" />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                label="Text"
                name="data[0].text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text hindi"
                value={values.data[0].text_hi}
                label="Text hindi"
                name="data[0].text_hi"
                onChange={(event) =>
                  setFieldValue("data[0].text_hi", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_hi" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text marathi"
                value={values.data[0].text_mr}
                label="Text marathi"
                name="data[0].text_mr"
                onChange={(event) =>
                  setFieldValue("data[0].text_mr", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_mr" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text gujarati"
                value={values.data[0].text_gj}
                label="Text gujarati"
                name="data[0].text_gj"
                onChange={(event) =>
                  setFieldValue("data[0].text_gj", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_gj" />
            </Box>
            <Field
              as={Select}
              name="action"
              label="Action"
              placeholder="Select"
              required
              value={values.data[0].action}
              onChange={(value) => {
                setFieldValue("data[0].action", value);
              }}
              data={actionsList.map((item, index) => {
                return item;
              })}
            />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter description"
                value={values.data[0].description}
                label="Description"
                name="data[0].description"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].description",
                    event.currentTarget.value
                  )
                }
              />
              <ErrorMessage name="data[0].description" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter hindi description"
                value={values.data[0].hindi_description}
                label="Hindi description"
                name="data[0].hindi_description"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].hindi_description",
                    event.currentTarget.value
                  )
                }
              />
              <ErrorMessage name="data[0].hindi_description" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter gujarati description"
                value={values.data[0].gujarati_description}
                label="Gujarati description"
                name="data[0].gujarati_description"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].gujarati_description",
                    event.currentTarget.value
                  )
                }
              />
              <ErrorMessage name="data[0].gujarati_description" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter marathi description"
                value={values.data[0].marathi_description}
                label="Marathi description"
                name="data[0].marathi_description"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].marathi_description",
                    event.currentTarget.value
                  )
                }
              />
              <ErrorMessage name="data[0].marathi_description" />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else if (values.type === "PROMOTION_VIDEO") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Divider variant="dashed" />

            <Flex
              direction={"row"}
              justify={"space-around"}
              align={"center"}
              gap={"lg"}
            >
              <Box style={{ flex: "2" }}>
                <Field
                  type="text"
                  as={TextInput}
                  name="data[0].video_url"
                  value={values.data[0].video_url}
                  placeholder="Enter url"
                  label="Youtube URL"
                  onChange={(event) =>
                    setFieldValue(
                      "data[0].video_url",
                      event.currentTarget.value
                    )
                  }
                />
                <ErrorMessage name="data[0].video_url" />
              </Box>

              <Divider variant="dashed" orientation="vertical" />

              <Stack style={{ flex: "1" }} justify="space-around">
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  {/^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/.test(
                    values.data[0].video_url
                  ) ? (
                    <iframe
                      width="100%"
                      height="100%"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      src={formatYoutubeURLForPreview(values.data[0].video_url)}
                      allowfullScreen
                    ></iframe>
                  ) : (
                    <Text style={{ textAlign: "center" }} size={"sm"}>
                      Video Preview
                    </Text>
                  )}
                </div>
              </Stack>
            </Flex>
            <Box>
              <Switch
                label="Auto play"
                size="sm"
                color="indigo"
                checked={values.data[0].auto_play}
                onChange={(event) =>
                  setFieldValue(
                    "data[0].auto_play",
                    event.currentTarget.checked
                  )
                }
              />
            </Box>
            <Box>
              <Switch
                label="Loop"
                size="sm"
                color="indigo"
                checked={values.data[0].loop}
                onChange={(event) =>
                  setFieldValue("data[0].loop", event.currentTarget.checked)
                }
              />
            </Box>
            <Box>
              <Switch
                label="Mute"
                size="sm"
                color="indigo"
                checked={values.data[0].mute}
                onChange={(event) =>
                  setFieldValue("data[0].mute", event.currentTarget.checked)
                }
              />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else return <></>;
}

function AutoSlider({
  values,
  errors,
  touched,
  setFieldValue,
  setIsFormLoading,
  availableItemsForSelection,
  ...props
}) {
  if (values.type === "PRODUCTS") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Field
              as={MultiSelect}
              value={values.data[0].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[0].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                label="Text"
                name="values.data[0].text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
              />

              <ErrorMessage name="values.data[0].text" />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else {
    return <></>;
  }
}

function HorizontalScroll({
  values,
  errors,
  touched,
  setFieldValue,
  setIsFormLoading,
  availableItemsForSelection,
  ...props
}) {
  if (values.type === "PRODUCTS") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Field
              as={MultiSelect}
              value={values.data[0].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[0].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                label="Text"
                name="values.data[0].text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
              />

              <ErrorMessage name="values.data[0].text" />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else if (values.type === "SUB_CATEGORY") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Divider
              variant="dashed"
              label="Data Image upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].image_url && (
                <Image
                  alt="image_url"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].image_url}
                />
              )}

              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].image_url", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>
            <Divider
              variant="dashed"
              label="Parent Image upload"
              labelPosition="center"
              color="green"
            />
            <Flex
              justify="center"
              align="center"
              direction={"row"}
              gap={"60px"}
            >
              {values.data[0].parent_image_url && (
                <Image
                  alt="parent_image_url"
                  width={150}
                  height={150}
                  fit="cover"
                  radius={10}
                  src={values.data[0].parent_image_url}
                />
              )}

              <div style={{ marginTop: "15px" }}>
                <UploadBannerImage
                  uniqueKey={`-${Math.random() * 1000}`}
                  imgName={`moment().format("DD-MM-YY")-img1`}
                  setImageUploadURL={(url) => {
                    setFieldValue("data[0].parent_image_url", url);
                  }}
                  setUploadedImageName={(uploadedName) => {}}
                  handleOnResetImageClick={() => {}}
                  setPreventFormSubmit={setIsFormLoading}
                />
              </div>
            </Flex>

            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter Sub Category"
                value={values.data[0].sub_category}
                label="Sub Category"
                name="values.data[0].sub_category"
                onChange={(event) =>
                  setFieldValue(
                    "data[0].sub_category",
                    event.currentTarget.value
                  )
                }
              />

              <ErrorMessage name="values.data[0].sub_category" />
            </Box>

            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                label="Text"
                name="values.data[0].text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
                // error={
                //   Array.isArray(touched.data) &&
                //   touched?.data[0]?.text &&
                //   Array.isArray(errors.data) &&
                //   errors?.data[0]?.text
                //     ? errors?.data[0]?.text
                //     : false
                // }
              />

              <ErrorMessage name="values.data[0].text" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text hindi"
                value={values.data[0].text_hi}
                label="Text hindi"
                name="data[0].text_hi"
                onChange={(event) =>
                  setFieldValue("data[0].text_hi", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_hi" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text marathi"
                value={values.data[0].text_mr}
                label="Text marathi"
                name="data[0].text_mr"
                onChange={(event) =>
                  setFieldValue("data[0].text_mr", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_mr" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text gujarati"
                value={values.data[0].text_gj}
                label="Text gujarati"
                name="data[0].text_gj"
                onChange={(event) =>
                  setFieldValue("data[0].text_gj", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_gj" />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else if (values.type === "COMBINE_PROD") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Field
              as={MultiSelect}
              value={values.data[0].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[0].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else {
    return <></>;
  }
}

function VerticalScroll({
  values,
  errors,
  touched,
  setFieldValue,
  setIsFormLoading,
  availableItemsForSelection,
  ...props
}) {
  if (values.type === "PRODUCTS_LIST" || values.type === "PRODUCTS_GRID") {
    return (
      <Tabs defaultValue="data1">
        <Tabs.Tab style={{ color: "green" }} value="data1">
          Data
        </Tabs.Tab>

        <Tabs.Panel
          style={{
            backgroundColor: "#d6ffd4",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingBottom: "3rem",
          }}
          value="data1"
        >
          <Stack>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text"
                value={values.data[0].text}
                label="Text"
                name="data[0].text"
                onChange={(event) =>
                  setFieldValue("data[0].text", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text hindi"
                value={values.data[0].text_hi}
                label="Text hindi"
                name="data[0].text_hi"
                onChange={(event) =>
                  setFieldValue("data[0].text_hi", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_hi" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text marathi"
                value={values.data[0].text_mr}
                label="Text marathi"
                name="data[0].text_mr"
                onChange={(event) =>
                  setFieldValue("data[0].text_mr", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_mr" />
            </Box>
            <Box style={{ flex: "2" }}>
              <Field
                as={TextInput}
                placeholder="Enter text gujarati"
                value={values.data[0].text_gj}
                label="Text gujarati"
                name="data[0].text_gj"
                onChange={(event) =>
                  setFieldValue("data[0].text_gj", event.currentTarget.value)
                }
              />

              <ErrorMessage name="data[0].text_gj" />
            </Box>
            <Field
              as={MultiSelect}
              value={values.data[0].item_id}
              label="Item Id(s)"
              placeholder="Select"
              searchable
              clearable
              name="ItemId"
              onChange={(value) => setFieldValue("data[0].item_id", value)}
              nothingFound="No options"
              data={availableItemsForSelection}
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>
    );
  } else {
    return <></>;
  }
}
