import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavBarContainer, LeftContainer, SZLogoContainer, MidContainer, RightContainer, BulkUploadContainer, AddProductContainer, UserStatusContainer } from './wrapper';


const LogoComponent = (props) => {
    let history = useHistory();
    const onLogoClick = () => {
        history.push('/home');
    };

    return (
        <SZLogoContainer onClick={onLogoClick} key="0" title="Reload Home Page" />
    );
};

export default LogoComponent;