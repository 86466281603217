import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Backdrop from '../../CommonRequirements/backdrop'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import ConvertedStockEditModal from './ConvertedStockEditModal'
import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import { Badge, Form, Dropdown, Navbar, Jumbotron, InputGroup, Button, Alert, ButtonGroup, Table } from 'react-bootstrap'
import baseUrl from '../../service/servicesConfig';
import { validateIsAdminUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';

function ConvertedStock(props) {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [stockConversionList, setStockConversionList] = useState("")
    const [getBackDrop, setBackDrop] = useState(false)
    const [editStockConversion, setEditStockConversion] = useState(false)
    const [itemsList, setItemsList] = useState("")
    const [exportData, setExportData] = useState(false)
    const [conversionListSearch, setConversionListSearch] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState("")

    let filterResult = ""


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });

    const notify = (unavailability) => {

        if (unavailability)
            return toast("No Stock Conversion details available for selected date range ", { type: toast.TYPE.ERROR })

    }


    useEffect(() => {

        setBackDrop(true)
        startDate.setDate(startDate.getDate() - 1)
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`
        const converted_stock_date_url = `/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${endDateConversion}`;

        baseUrl.get(converted_stock_date_url).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {
            console.log(data);
            setStockConversionList(data);
            setBackDrop(false);
        }).catch(err => console.log(err));

        /*fetch(`https://services2.superzop.com/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setStockConversionList(data); setBackDrop(false) }).catch(err => console.log(err))*/

    }, [])

    const handleDateRangeChange = () => {

        if (startDate > endDate) {

            setBackDrop(false)
            setStockConversionList("")
            return toast("Please enter a valid date range", { type: toast.TYPE.ERROR })

        }
        let conversionStartDtType = `start_date`
        let conversionEndDtType = `end_date`

        setStockConversionList("")
        const startDateConversion = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()}`
        const endDateConversion = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`

        const converted_stock_date_url = `/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${endDateConversion}`;

        baseUrl.get(converted_stock_date_url).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(data => {
            console.log(data);
            setStockConversionList(data);
            setConversionListSearch(false);
            setBackDrop(false);
            if (!data.length > 0) {
                notify(true)
            }
        }).catch(err => console.log(err))

        /*fetch(`https://services2.superzop.com/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${endDateConversion}`).then(res => {
            return res.json()
        }).then(data => { console.log(data); setStockConversionList(data); setConversionListSearch(false); setBackDrop(false); if (!data.length > 0) { notify(true) } }).catch(err => console.log(err))*/
    }
    useEffect(() => {
        if (conversionListSearch) {
            handleDateRangeChange()
        }
    }, [conversionListSearch])

    const exportOrderSummary = () => {

        const wb = XLSX.utils.book_new()

        setBackDrop(true)
        let stockConversionSummary = [["Date Of Packaging", "Major Category", "Parent Code", "Child Item Code", "Child Item Name", "Child Weight", "Child Stock Production", "Total Weight Packed", "Wastage", "Created By", "Updated By"]]
        stockConversionList.map(item => {

            stockConversionSummary.push([item.date_of_packing, item.major_category, item.parent_code, item.child_item_code, item.child_item_name, item.child_weight, item.child_stock_production, item.total_weight_packed, item.wastage, item.created_by, item.updated_by])
        })
        const stockListData = XLSX.utils.aoa_to_sheet(stockConversionSummary)
        XLSX.utils.book_append_sheet(wb, stockListData, `Stock List Report`)
        XLSX.writeFile(wb, `stock_list_report.xlsx`)
        setExportData(false)
        setBackDrop(false)


    }

    useEffect(() => {
        if (exportData)
            exportOrderSummary()
    }, [exportData])

    const searchConversionList = () => {
        const filterData = []
        filterResult = stockConversionList.filter(obj => {

            if (obj.parent_item_code == null || obj.child_item_name == null || obj.major_category == null)
                return
            const itemId = obj.parent_item_code && obj.parent_item_code.trim().toLowerCase();
            const itemDesc = obj.child_item_name && obj.child_item_name.trim().toLowerCase() || "";
            const majorCat = obj.major_category && obj.major_category.trim().toLowerCase() || ""

            setSearchText(searchText.trim().toLowerCase());

            return itemId.match(searchText) || itemDesc.match(searchText) || majorCat.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(items)
        })
        setFilterList(filterData)

    }

    const deleteConvertedStock = (deleteItem) => {

        let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`);

        const answer = window.confirm(`Are you sure you want to delete this item?`);

        const dataToDelete = {
            ...deleteItem,
            "updated_by": loggedEmailId
        }

        console.log('dataToDelete: ', dataToDelete);
        
        if (answer) {

            const url = `/api/superzop/commercial/stockconversion/convertedstocks/editconvertedstockandsi?type=delete_record`;

            baseUrl.post(url, dataToDelete).then(res => {

                console.log('Push Converted Stocks ', res);

                //resolve("Success");

                setTimeout(() => {
                    window.location.reload()
                }, 2000);

                return toast('Deleted and Synced System Inventory', { type: toast.TYPE.SUCCESS });

            }).catch((error) => {

                console.error(error);
                //reject(error);
                return toast('Failed to Edit', { type: toast.TYPE.SUCCESS });
            });
        }

    }

    useEffect(() => {
        if (searchText)
            searchConversionList()
    }, [searchText])
    return (
        <div>
            <NavBar />
            <div style={{ paddingTop: 100 }}>
                <Alert style={{ display: "flex", textAlign: "justify", alignItems: "center", justifyContent: "center" }} variant={"success"}>
                    <Button size="sm" style={{ marginRight: "10px" }} onClick={() => props.history.push('/stockConversionListing')}><i class="fas fa-arrow-circle-left" size="2x"></i></Button>
                    Packaging</Alert>
                <Navbar className="bg-light mr-auto justify-content-between" >
                    <Form inline>
                        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                            <Badge variant="dark"> Start Date </Badge>
                            <DatePicker
                                selected={startDate}
                                onChange={(val) => { setStartDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"

                            />
                        </InputGroup>
                        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
                            <Badge variant="dark">End Date </Badge>
                            <DatePicker
                                selected={endDate}
                                onChange={(val) => { setEndDate(val) }}
                                minDate={new Date("2017/01/01")}
                                maxDate={new Date()}
                                aria-describedby="basic-addon1"
                                dateFormat="dd/MM/yyyy"
                            />
                        </InputGroup>
                        <Button size="sm" style={{ margin: 20 }} onClick={() => { setConversionListSearch(true); setBackDrop(true) }}>Submit</Button>
                        <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Parent Item Code / Child Item Name /Major Category " value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                        <InputGroup size="sm" style={{ marginLeft: 40 }}>
                            <Button size="sm" variant="success" onClick={() => setExportData(true)}>Export</Button>
                        </InputGroup>
                    </Form>
                </Navbar>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <td>Date Of Packaging</td>
                            <td>Major Category</td>
                            <td>Parent Code</td>
                            <td>Child Item Name</td>
                            <td>Child Item Code</td>
                            <td>Child Weight</td>
                            <td>Child Stock Production</td>
                            <td>Total Weight Packed</td>
                            <td>Wastage (in KG) </td>
                            <td>Converted Stock (in KG) </td>
                            <td>Created By</td>
                            <td>Updated By</td>
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>Edit Record</td>}
                            <td>X</td>
                        </tr>
                    </thead>
                    <tbody>
                        {stockConversionList.length > 0 && filterList.length == 0 ? stockConversionList.map(items => (
                            <tr>
                                <td>{items.date_of_packing ? items.date_of_packing : ""}</td>
                                <td>{items.major_category}</td>
                                <td>{items.parent_item_code}</td>
                                <td>{items.child_item_name}</td>
                                <td>{items.child_item_code}</td>
                                <td>{items.child_weight}</td>
                                <td>{items.child_stock_production}</td>
                                <td>{items.total_weight_packed}</td>
                                <td>{items.wastage}</td>
                                <td>{items.converted_stock}</td>
                                <td>{items.created_by && items.created_by.split("@")[0]}</td>
                                <td>{items.updated_by && items.updated_by.split("@")[0]}</td>
                                {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><Button onClick={() => { setEditStockConversion(true); setItemsList(items) }}>Edit</Button></td>}
                                <td><Button onClick={() => { deleteConvertedStock(items) }}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>
                            </tr>
                        )) :
                            filterList.length > 0 && filterList.map(items => (
                                <tr>
                                    <td>{items.date_of_packing ? items.date_of_packing : ""}</td>
                                    <td>{items.major_category}</td>
                                    <td>{items.parent_item_code}</td>
                                    <td>{items.child_item_name}</td>
                                    <td>{items.child_item_code}</td>
                                    <td>{items.child_weight}</td>
                                    <td>{items.child_stock_production}</td>
                                    <td>{items.total_weight_packed}</td>
                                    <td>{items.wastage}</td>
                                    <td>{items.converted_stock}</td>
                                    <td>{items.created_by && items.created_by.split("@")[0]}</td>
                                    <td>{items.updated_by && items.updated_by.split("@")[0]}</td>
                                    {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><Button onClick={() => { setEditStockConversion(true); setItemsList(items) }}>Edit</Button></td>}
                                    <td><Button onClick={() => { deleteConvertedStock(items) }}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>
                                </tr>
                            ))

                        }
                    </tbody>
                </Table>
            </div>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
            {editStockConversion && <ConvertedStockEditModal
                show={editStockConversion}
                onHide={() => setEditStockConversion(false)}
                majorCat={itemsList["major_category"]}
                parentItemCode={itemsList["parent_item_code"]}
                dataItems={itemsList}
            />}
        </div>
    )
}

export default ConvertedStock
