import React, { useState, useEffect } from 'react'
import { Card, Alert, Button, InputGroup, DropdownButton, Dropdown, Table, Breadcrumb, OverlayTrigger,Tooltip, Row, Col } from 'react-bootstrap';
import database from '../../../service/firebaseConfig';
import DeleteNotification from './DeleteNotifications';
import NavBar from '../../../Dashboard/Navbar/Dashboard_Navabar'
import FooterComponent from '../../../components/Footer/index'
import { toast } from 'react-toastify';
import Backdrop from '../../../CommonRequirements/backdrop'
import ImgUpload from './UpdateImgFile'
import ImgFullViewModal from './ViewFullImg'


const notificationsListingDB = database.database().ref(`Notifications`)


function SeriesListing() {


    const [seriesSelected, setSeriesSelected] = useState("")
    const [getListInitialItems, setListInitialItems] = useState("")
    const [getDeleteFunction, setDeleteFunction] = useState("")
    const [imgUrl, setImgUrl] = useState("")
    const [backDrop, setBackDrop] = useState(false)
    const [itemToUpload, setItemToUpload] = useState("")
    const [newImgName, setNewImgName] = useState("")
    const [imgFullView, setImgFullView] = useState(false)
    const [imgViewUrl, setImgViewUrl] = useState("")


    const seriesList = ['A', 'B','AA','BB','NN', 'K', 'N','P','NK','ST','PG']

    const fetchSeriesAFromDb = () => {

        setBackDrop(true)

        let listData = ''

        notificationsListingDB.child(`A`).once("value", snapShot => {

            listData = snapShot.val()

            console.log(listData)
        }).then(() => { setListInitialItems(listData); setBackDrop(false) })

    }

    useEffect(() => {
        fetchSeriesAFromDb()
    }, [])

    useEffect(() => {
        if (seriesSelected) {
            let seriesChosen

            if (seriesSelected === "all")
                seriesChosen = 'A'
            else
                seriesChosen = seriesSelected

            setBackDrop(true)
            let listData = ''

            notificationsListingDB.child(`${seriesChosen}`).once("value", snapShot => {

                listData = snapShot.val()

                console.log(listData)
            }).then(() => { setListInitialItems(listData); setBackDrop(false) })
        }
    }, [seriesSelected])

    useEffect(() => {

        if (imgUrl && itemToUpload) {

            setBackDrop(true)
            let seriesChosen
            let listData = ''
            console.log(imgUrl)
            console.log(itemToUpload)
            if (seriesSelected === "all" || seriesSelected == "")
                seriesChosen = 'A'
            else
                seriesChosen = seriesSelected

            notificationsListingDB.child(`${seriesChosen}`).child(`${itemToUpload}`).update({ image_url: imgUrl,image: newImgName}).then(() => {
                notificationsListingDB.child(`${seriesChosen}`).once("value", snapShot => {

                    listData = snapShot.val()

                }).then(() => { setListInitialItems(listData); setBackDrop(false) })
            })
        }
    }, [imgUrl, itemToUpload])

    useEffect(() => {
        if (getDeleteFunction !== "") {
            let listData = ''
            setBackDrop(true)
            let seriesChosen
            if (seriesSelected === "all" || seriesSelected == "")
                seriesChosen = 'A'
            else
                seriesChosen = seriesSelected
            notificationsListingDB.child(`${seriesChosen}`).once("value", snapShot => {

                listData = snapShot.val()
                console.log(listData)

            }).then(() => { setListInitialItems(listData); setBackDrop(false) })
            return toast(`${getDeleteFunction}`, { type: toast.TYPE.SUCCESS })
        }

    }, [getDeleteFunction])

    const dateConversion = (dateToConvert) => {

        let normalDt = new Date(dateToConvert)
        normalDt = `${normalDt.getDate()}/${normalDt.getMonth() + 1}/${normalDt.getFullYear()}`
        return normalDt

    }

    return (
        <div>
            <NavBar />
            <div style={{ paddingTop: 100, textAlign: "center" }}>
                <Alert variant="success">Series Listing</Alert>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="active-flag">Select Series</InputGroup.Text>
                    </InputGroup.Prepend>
                    <DropdownButton id="dropdown-basic-button" title={seriesSelected ? seriesSelected : "All"}>
                        {seriesList && seriesList.map(items => (
                            <Dropdown.Item value={items} onClick={(e) => { e.preventDefault(); setSeriesSelected(items) }}>{items}</Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item value={"All"} onClick={(e) => { e.preventDefault(); setSeriesSelected("all") }}>All</Dropdown.Item>

                    </DropdownButton>
                </InputGroup>
                <center><Table style={{ width: 1200 }}>
                    {getListInitialItems && Object.keys(getListInitialItems).map((items, index) => (
                        <tr style={{ padding: 50, borderRadius: 10, boxShadow: "0px 4px 8px 5px rgba(0, 0, 0, 0.2)" }}>
                            <td>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="#">{index + 1}</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">
                                        {items}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <Card bg="light">
                                    <Card.Body>
                                        <Table bordered>
                                            <tr>
                                                <td>Calender Date</td>
                                                <td>{dateConversion(getListInitialItems[items].calendar_date)}</td>
                                            </tr>
                                            <tr>
                                                <td>Image Name</td>
                                                <td>{getListInitialItems[items].image}</td>
                                            </tr>
                                            <tr>
                                                <td>Image Folder</td>
                                                <td>{getListInitialItems[items]["image_folder"]}</td>
                                            </tr>
                                            <tr>
                                                <td>Read Status</td>
                                                <td>{getListInitialItems[items]["read_status"]}</td>
                                            </tr>
                                            <tr>
                                                <td>Retailer ID</td>
                                                <td>{getListInitialItems[items]["retailer_id"]}</td>
                                            </tr>
                                            <tr>
                                                <td>Message</td>
                                                <td>{getListInitialItems[items]["message"]}</td>
                                            </tr>
                                            <tr>
                                                <td>Title</td>
                                                <td>{getListInitialItems[items]["title"]}</td>
                                            </tr>
                                            <tr>
                                                <td>Series</td>
                                                <td>{seriesSelected && seriesSelected!=="All"?seriesSelected:"A"}</td>
                                            </tr>
                                        </Table></Card.Body></Card></td>
                            <td>
                                <div style={{ float: "right" }}>
                                    <OverlayTrigger overlay={<Tooltip >Delete {items}!</Tooltip>}>
                                        <Button variant="link" style={{ top: "-20px" }}
                                            onClick={() => { DeleteNotification(setDeleteFunction, items, seriesSelected) }}>
                                            <i style={{ color: "red" }} class="fas fa-trash-alt fa-2x"></i></Button>
                                    </OverlayTrigger></div>
                                <figure><img src={getListInitialItems[items].image_url && getListInitialItems[items].image_url} alt={"image"} height="200" width="200" onClick={()=>{setImgFullView(true);setImgViewUrl(getListInitialItems[items].image_url)}}/></figure>
                                <ImgUpload currentItem={setItemToUpload} imageFileName={setNewImgName} itemIdToUpload={items} downloadedUrl={setImgUrl} />
                            </td>
                        </tr>
                    ))}
                </Table>
                </center>
            </div>
            {imgFullView && <ImgFullViewModal
            show={imgFullView}
            ImgUrl = {imgViewUrl}
            onHide={() => setImgFullView(false)}
            />}
            {backDrop ? (<Backdrop parentLoadStatus={backDrop} />) : (null)}
            <FooterComponent />
        </div>
    )
}

export default SeriesListing
