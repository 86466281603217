import React, { useEffect, useRef, useState } from "react";
import { Center, Grid, Button, Image } from "@mantine/core";
import { Row, Col } from "react-bootstrap";
import deleteLogo from "../../../../assests/images/warning.png";

const OfferListingCardDeleteScreen = (props) => {
  const offerDetails = props.offerDetails;
  const offerCategory = props.offerCategory;

  const [deleteExpirySeconds, setDeleteExpirySeconds] = useState(10);
  const handleDeleteOffer = props.handleDeleteOffer;
  const handleDeleteOfferCancel = props.handleDeleteOfferCancel;

  const deleteExpiryTimer = useRef();

  useEffect(() => {
    deleteExpiryTimer.current = setInterval(() => {
      setDeleteExpirySeconds((prevSeconds) => {
        return prevSeconds - 1;
      });
    }, 1000);
    return function clearTimer() {
      if (deleteExpiryTimer.current) {
        clearInterval(deleteExpiryTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (deleteExpirySeconds <= 0) {
      handleDeleteOfferCancel();
    }
  }, [deleteExpirySeconds]);

  return (
    <>
      <Row className="OfferListingCard__Header">
        <Col
          style={{ backgroundColor: "red" }}
          className="OfferListingCard__Header__Col"
        >
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            Delete offer confirmation
          </div>
        </Col>
      </Row>
      <Row style={{ margin: "0px" }}>
        <Col style={{ paddingTop: "10px" }}>
          <Center>
            <Row>
              <Col>
                <div
                  style={{ width: "50px", margin: "auto", marginRight: "20px" }}
                >
                  <Image
                    radius="md"
                    src={deleteLogo || ""}
                    alt="Warning logo"
                  />
                </div>
              </Col>
            </Row>

            <div
              style={{
                textAlign: "center",
                paddingBottom: "10px",
                paddingTop: "20px",
                fontSize: "18px",
                fontWeight: "bold",
                letterSpacing: "10",
              }}
            >
              Are you sure you want to delete this offer ?
            </div>
          </Center>

          <Center>
            <div style={{ width: "50%", paddingBottom: "10px" }}>
              <Center>
                <Grid
                  justify="space-evenly"
                  style={{ margin: "0", width: "450px" }}
                  gutter="lg"
                >
                  <Grid.Col span={5}>
                    <Button
                      onClick={(event) => {
                        clearInterval(deleteExpiryTimer.current);
                        if (offerCategory === "productOffer") {
                          if (offerDetails.offer_type === "SLP") {
                            handleDeleteOffer(
                              offerDetails.offer_id,
                              offerCategory,
                              offerDetails.offer_type
                            );
                            return;
                          }

                          handleDeleteOffer(
                            offerDetails.offer_code,
                            offerCategory,
                            offerDetails.offer_type
                          );
                        } else if (offerCategory === "customerOffer") {
                          handleDeleteOffer(
                            offerDetails.coupon_code,
                            "customerOffer",
                            offerDetails
                          );
                        } else if (offerCategory === "customOffer") {
                          handleDeleteOffer(
                            offerDetails.id,
                            "customOffer",
                            offerDetails
                          );
                        }
                      }}
                      color="red"
                      style={{ width: "150px", padding: "10px" }}
                    >
                      Delete offer
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={5}>
                    <Button
                      style={{ width: "150px", padding: "10px" }}
                      onClick={handleDeleteOfferCancel}
                      color="teal"
                    >
                      Cancel
                    </Button>
                  </Grid.Col>
                </Grid>
              </Center>
            </div>
          </Center>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            This operation will be auto cancelled in {deleteExpirySeconds} secs
          </p>
        </Col>
      </Row>
    </>
  );
};

export default OfferListingCardDeleteScreen;
