export class ProductMaster {
  constructor(item_id, item_disc, outer_size, major_category) {
    this.item_id = item_id;
    this.item_disc = item_disc;
    this.outer_size = outer_size;
    this.major_category = major_category;
  }
}

export class Ingredient {
  constructor(
    item_id = null,
    weight = null,
    name = null,
    outer_size = null,
    cost = null
  ) {
    console.log("executing", name);
    this.item_id = item_id;
    this.name = name;
    this.cost = cost;
    this.weight = weight;
    this.outer_size = outer_size;
  }
}

export class BOMFormData {
  constructor(
    creation_date = null,

    type = "",
    name = "",
    brand = "",
    item_id = "",
    weight = null,
    total_weight = 0,
    qty = null,
    total_cost = 0,
    cost_per_qty = 0,
    packaging_cost = 6.8,
    final_price = 0,
    major_category = null,
    outer_size = null,
    selectedIngredients = [],
    availableIngredientsForSelection = []
  ) {
    this.creation_date = creation_date;
    this.name = name;
    this.brand = brand;
    this.type = type;
    this.item_id = item_id;
    this.weight = weight;
    this.total_weight = total_weight;
    this.qty = qty;
    this.total_cost = total_cost;
    this.final_price = final_price;
    this.cost_per_qty = cost_per_qty;
    this.packaging_cost = packaging_cost;
    this.major_category = major_category;
    this.outer_size = outer_size;
    this.selectedIngredients = selectedIngredients;
    this.availableIngredientsForSelection = availableIngredientsForSelection;
  }

  clone() {
    return new BOMFormData(
      this.creation_date,
      this.type,
      this.name,
      this.brand,
      this.item_id,
      this.weight,
      this.total_weight,
      this.qty,
      this.total_cost,
      this.cost_per_qty,
      this.packaging_cost,
      this.final_price,
      this.major_category,
      this.outer_size,
      this.selectedIngredients,
      this.availableIngredientsForSelection
    );
  }

  addIngredientToSelection(item_id, weight, name, outer_size, base_price) {
    if (!item_id || !weight) return;

    let ingredient = new Ingredient(
      item_id,
      weight,
      name,
      outer_size,
      Number(weight * Number(base_price)).toFixed(2)
    );
    let filteredIngredients = this.availableIngredientsForSelection.filter(
      (item) => item != item_id
    );
    this.availableIngredientsForSelection = filteredIngredients;
    this.selectedIngredients.push(ingredient);
    this.performTotalCalculations();
  }

  removeIngredientFromSelection(item_id) {
    if (!item_id) return;
    this.availableIngredientsForSelection.push(item_id);
    let filteredIngredients = this.selectedIngredients.filter(
      (item) => item.item_id != item_id
    );
    this.selectedIngredients = filteredIngredients;
    this.performTotalCalculations();
  }

  modifyPackagingCost(packaging_cost) {
    if (!packaging_cost) return;
    this.packaging_cost = packaging_cost;
    this.performTotalCalculations();
  }

  modifyQuantity(qty) {
    if (!qty) return;
    this.qty = qty;
    this.performTotalCalculations();
  }

  performTotalCalculations() {
    let total_weight = 0;
    let total_cost = 0;

    this.selectedIngredients.forEach((item) => {
      total_weight += Number(item.weight);
      total_cost += Number(item.cost);
    });

    this.total_weight = total_weight;
    this.total_cost = total_cost;

    if (this.qty) {
      this.cost_per_qty = Number(total_cost / this.qty).toFixed(2);
    }

    if (this.packaging_cost) {
      this.final_price = Number(
        Number(this.cost_per_qty) + Number(this.packaging_cost)
      ).toFixed(2);
    }
  }
}


export class Prices {
  constructor(
    greenChillyPrice = 160,
    corianderLeavesPrice = 40,
    gingerPrice = 120,
    garlicPrice = 320,
    curryLeavesPrice = 40,
    waterPrice = 0.1,
    packagingPrice = 6.8
  ) {
    this.greenChillyPrice = greenChillyPrice;
    this.corianderLeavesPrice = corianderLeavesPrice;
    this.gingerPrice = gingerPrice;
    this.garlicPrice = garlicPrice;
    this.curryLeavesPrice = curryLeavesPrice;
    this.waterPrice = waterPrice;
    this.packagingPrice = packagingPrice;
  }

  clone() {
    return new Prices(
      this.greenChillyPrice,
      this.corianderLeavesPrice,
      this.gingerPrice,
      this.garlicPrice,
      this.curryLeavesPrice,
      this.waterPrice,
      this.packagingPrice
    );
  }
}


export class PriceMapping {

  static dBObjectsBasePriceMappingKey ={
    "Rice": "Steam IR Kolam Rice",
    "Methidana": "Methi Dana A",
    "Urad": "Urad Gota White",
    "Ragi": "Nachani",
    "Jowar": "Jawari B",
    "Chana Dal": "Chana Dal Kora",
    "Red Poha": "Red Poha",
    "Bajra": "Bajri",
    "Brown rice": "Brown Kolam Rice",
    "Moong": "Moong Green Akkha A",
    "Jeera": "Jeera Platinum",
    "Green Chilly": "Green Chilly",
    "Coriander Leaves": "Coriander Leaves",
    "Ginger": "Ginger",
    "Garlic": "Garlic",
    "Curry Leaves": "Curry Leaves",

    "Almond":'Almond NP - Bulk',
    "Cashew":'Kaaju Akkha W320',
    "Raisin":'Kishmish Raisin Long - Bulk',
    "Cranberry":'Cranberry- Bulk',
    "Pumpkin Seeds":'Pumpkin Seeds- Bulk',
    "Sunflower Seeds":'Sunflower Seeds- Bulk',

    "RO Water": "RO Water",
    "Packaging":"Packaging",
    "PackagingPrice":6.8
  }
  }

  export class hardCodedItemsItemId {
    static dBObjectsItemIdMappingKey ={
      "Green Chilly": "1930110013",
      "Ginger": "1940110013",
      "Curry Leaves": "1950110013",
      "Coriander Leaves": "1950210013",
      "Garlic":"1910110023",
      "RO Water": "2120110013"
    }
  }