import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  Form,
  Card,
  Alert,
  Button,
  Dropdown,
  Col,
  Jumbotron,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  Image,
  Radio,
  RadioGroup,
  Text,
  Center,
  Container,
  Grid,
  Select as MantineSelect,
  SimpleGrid,
  Checkbox,
  MultiSelect,
  LoadingOverlay,
  TextInput,
  Textarea,
  NumberInput,
  Divider,
} from "@mantine/core";

const NotificationFields = (props) => {
  const handleOnNotificationsFieldsEdit = props.handleOnNotificationsFieldsEdit;

  const notificationText = props.notificationText;
  const timingsObj = props.timingsObj;
  // useEffect(() => {
  //   handleOnNotificationsFieldsEdit(dataToSendToParent);
  // }, [dataToSendToParent]);

  // useEffect(() => {
  //   if (notificationText) {
  //     let data = { ...dataToSendToParent };
  //     data["text"] = notificationText;
  //     setDataToSendToParent(data);
  //   }
  // }, [notificationText]);

  // useEffect(() => {
  //   handleOnTimingsCheckboxChanged(timingsObj);
  // }, [timingsObj]);

  return (
    <>
      <SimpleGrid cols={1}>
        <div>
          <TextInput
            name="notificationText"
            value={notificationText}
            onChange={(event) => {
              handleOnNotificationsFieldsEdit(
                event.currentTarget.value,
                "text"
              );
            }}
            placeholder="eg: Check out our new offers"
            label="Notification text"
            description="Text notification that the users will receive"
            required={true}
          />
        </div>
      </SimpleGrid>
      <br />
      <Text className="required" size="sm">
        Notification timings
      </Text>
      <br />

      <SimpleGrid cols={10}>
        {Object.keys(timingsObj)
          .sort()
          .map((key, index) => {
            return (
              <div key={key}>
                <Checkbox
                  label={key}
                  checked={timingsObj[key]}
                  onChange={(event) => {
                    const timings_obj = { ...timingsObj };
                    timings_obj[key] = event.currentTarget.checked;
                    handleOnNotificationsFieldsEdit(timings_obj, "timings");
                  }}
                />
              </div>
            );
          })}

        {/* <div>
          <input
            type="checkbox"
            id="notific__timing_checkbox"
            checked={isUploadPopUpImgEnabled}
            onChange={(event) => {
              setIsUploadPopUpImgEnabled(event.target.checked);
              handleOnResetPopUpImageClick();
            }}
          />
          <label style={{ marginLeft: "10px" }} for="upload_popup_banner">
            {" "}
            Upload popup banner
          </label>
        </div> */}
      </SimpleGrid>
    </>
  );
};

export default NotificationFields;
