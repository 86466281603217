import React,{useEffect,useState} from 'react';
import { Form,Modal,Button,Table } from "react-bootstrap";
import { Select,MultiSelect } from '@mantine/core';
import database from '../../service/firebaseConfig';
import { toast } from "react-toastify";

const DB_REF_MT = database.database().ref(`Product_Master_New/O`);
const DB_REF_ADD_PRODUCTS = database.database().ref(`Retailer_Products`); 


const AddProductModal = ({updateRetailerProducts,...props}) => {

   
   
    const [selectedRetailer, setSelectedRetailer] = useState(props.retailer); 
    
    const [existingProducts,setExistingProducts] = useState(props.existingProducts)
    
    const [keyToAccess,setKeyToAccess] = useState(props.accKey);
    
    
   
   
    // prods
    const [allMTdata, setAllMTdata] = useState({});
    const [mtProducts,setMtProducts] = useState([]);
    const [selectedProducts,setSelectedProducts] = useState([]);

    // save
    const collectionsArray = [];


    useEffect(() => {
      
    
        const fetchProducts = async () => {
            try{

                const snapshot = await DB_REF_MT.once('value');
                const prodVal = snapshot.val();
                setAllMTdata(prodVal)
               
                let Mt_products = Object.values(prodVal);
               
                let productData = [{ label: "", value: ""}];
                Mt_products.forEach((item) => {
                    if (!existingProducts || !existingProducts.hasOwnProperty(item.item_id)) {
                        
                    
                    const product = {
                        label: `${item.item_id}-${item.item_description1}-${item.brand}`,
                        value: item.item_id
                    }
                    productData.push(product);
                }
                })
                setMtProducts(productData);
                

            } catch (error){
                // console.error('Error fetching Product details:', error);
              
            }
        };

        fetchProducts();
       
        
        
      }, []);



    const handleSaveProducts = () => {

      if (!selectedRetailer || selectedProducts.length === 0) {
        return toast("Please select at least one product", { type: toast.TYPE.ERROR });
      }
        if (selectedRetailer && selectedProducts.length > 0) {

                

                const newProducts = {};
                selectedProducts.forEach((item) =>{
                   
                    let itemVal = allMTdata[item]
                    if (itemVal) {
                        const prodsCollection = {
                          [item]: itemVal
                        };
                        newProducts[item] = itemVal;
                        collectionsArray.push(prodsCollection);
                      }
                })
                   

                    const allCollections = collectionsArray.reduce((result, collection) => {
                        return { ...result, ...collection };
                      }, {});

                  
                    const updatedProducts = {
                        ...existingProducts,
                        ...newProducts, 
                      };
                    
                      // updateRetailerProducts(selectedRetailer, updatedProducts);
                      
               
                    database.database().ref(`Retailer_Products/${selectedRetailer}`).update(allCollections)
                      .then(() => {
                        // console.log(`User added successfully`);
                        updateRetailerProducts();
                        props.onHide();
                        return toast("Successfully updated in server", { type: toast.TYPE.SUCCESS });

                      })
                      .catch((error) => {
                        // console.error(`Error adding user with item_id `, error);
                        props.onHide();
                        return toast("Couldn't update ", { type: toast.TYPE.ERROR });
                      });
               
            // Clear the selected products after saving
            setSelectedProducts([]);
        }


    };

    return(
        <>
    <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Add Products for the retailer {selectedRetailer} 
        </Modal.Title>
        </Modal.Header>
        <Modal.Body >
       
                <Table>
                   
                    <tr>
                        <td>Select Products</td>
                        {/* <td> */}
                         <MultiSelect
                         
                         placeholder="All"
                         data={mtProducts}
                         value={selectedProducts}
                         onChange={setSelectedProducts}
                         maxDropdownHeight={400}
                         searchable
                         clearable
                         
                         >
                        </MultiSelect>
                         {/* </td> */}
                    </tr>
                </Table>
               
                                    
        </Modal.Body>
        <Modal.Footer>
        {/* <Button variant="success" onClick={()=>{setDataToDb(true);setDataPushToPriceDetails(true)}} >Submit form</Button> */}
        <Button variant="success" onClick={handleSaveProducts}>Save Products</Button>
        <Button onClick={()=>{props.onHide()}}>Close</Button>
        </Modal.Footer>
    </Modal>
    
        </>
    )
}

export default AddProductModal;