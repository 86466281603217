import React, { useEffect, useState } from 'react';
import database from '../../service/firebaseConfig';
import { ImageOverlay } from './ItemsView';

const UploadImage = ({id, onImageUpload,onUpload }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState('');
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openImageOverlay = (imageURL) => {
    setSelectedImageURL(imageURL);
    setIsOverlayOpen(true);
  };

  const closeImageOverlay = () => {
    setSelectedImageURL('');
    setIsOverlayOpen(false);
  };


  const uploadImageToFirebaseStorage = async (file) => {
    const storageRef = database.storage().ref();
    let storageAddress = `Work_Order_Images/`; 
    const imagesRef = storageRef.child(storageAddress);

    try {
      const fileName = `${id}_${file.name}`; 
      const imageRef = imagesRef.child(fileName);
      // setIsUploaded(true);
      await imageRef.put(file);
      const downloadURL = await imageRef.getDownloadURL();
      
      setIsUploaded(true);
     
      if (onUpload) {
        onUpload(id, downloadURL);
      }
    } catch (error) {
      setIsUploaded(false);
      console.error('Error uploading image:', error);
    }
  };

  const handleFileInputChange = (e) => {
    
    const file = e.target.files[0];
    
    if (file) {
      // Read the file and set the selectedImage state
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        onImageUpload(id, e.target.result);
      };
      reader.readAsDataURL(file);
      uploadImageToFirebaseStorage(file);
    }
  };

  return (
   
    <>
   
      <div style={{marginTop:'10px'}}>
      <div className="upload-container" style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px',cursor: 'pointer' }}>
        <label htmlFor="file-input" className="upload-label" style={{color: isUploaded ? 'red' : 'orange',cursor: 'pointer'}}>
        { isUploaded? 'Image Uploaded successfully                ' : 'Click to Choose a document, maximum file size upto 5mb'}
        </label>
        <input type="file" accept="image/*" onChange={handleFileInputChange} />
        {selectedImage && <img src={selectedImage} alt="Uploaded" style={{ maxWidth: '100px' }} onClick={() => openImageOverlay(selectedImage)} />}
       
      </div>
    </div> 
      <ImageOverlay
      selectedImage={selectedImageURL}
      onClose={closeImageOverlay}
    />
     </>
  );

};

export default UploadImage;
