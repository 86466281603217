import React,{useEffect} from 'react'
import database from '../../../../service/firebaseConfig';

function DeleteApartment(props) {
    console.log(props.apartId)
        
        const Delapartment=()=>{
            if(props.apartId!==''){
                var stats= window.confirm("Are u sure u want to delete");
            if(stats===true){
               
                database.database().ref('Apartments/Mumbai/'+props.apartId).remove().then(()=>{

                    database.database().ref('Blocks/Mumbai/'+props.apartId).remove().then(()=>
                        alert ("removed Block and flat details")
                      )

                    alert("Apartment details removed successfully")
                    
                  })
            }
        }
        }

        useEffect(() => {
            Delapartment()
        }, [props])

 
}

export default React.memo(DeleteApartment)
