import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import database from '../../service/firebaseConfig';
import NavBar from '../../components/NavBar';
import ChangeRegion from '../../components/ChangeRegion';
import ProductData from '../../components/ProductData';
import EditProducts from '../../components/EditProducts';
import FooterComponent from '../../components/Footer';
import LoaderComp from '../../components/Loader';
import EditStock from '../../components/ProductDataCard/EditStock';
import { MainContainer, ShowMoreContainer } from './wrapper';
import ProductContext from '../../context/productList';
import { rootStore } from '../../store';
import { validateIsAdminUser } from '../../service/credentials';
import { getCurrCity, getCurrRegion } from '../../service/getCityRegion';
import {
  INIT_PAGE_TITLE,
  SESSION_TEXT,
  PROD_MASTER_TABLE,
  CITY_ALL,
  CITY_MUMBAI,
  CITY_PUNE,
  REGION_BOM_A,
  REGION_BOM_B,
  REGION_BOM_N,
  REGION_BOM_K,
  REGION_BOM_H,
  REGION_PUNE_P,
  REGION_S,
  REGION_BOM_ALL,
  REGION_ALL,
  REGION_BOM_NK,
  REGION_BOM_PG,
  REGION_BOM_C,
  REGION_BOM_DD,
  REGION_BOM_DE,
  REGION_BOM_DF,
  REGION_BOM_DG,
  
  REGION_YO_GROCERY,
  LOGGED_USER_EMAIL,
} from '../../constants/allConstants';

// Mumbai
const productListDB_A = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_A}`);
const productListDB_B = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_B}`);
const productListDB_N = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_N}`);
const productListDB_H = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_H}`);
const productListDB_K = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_K}`);
const productListDB_NK = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_NK}`);
const productListDB_PG = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_PG}`);
const productListDB_C = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_C}`);
const productListDB_DD = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_DD}`);
const productListDB_DE = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_DE}`);
const productListDB_DF = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_DF}`);
const productListDB_DG = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_BOM_DG}`);

// Pune
const productListDB_P = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_PUNE_P}`);
//YoGrocery
const productListDB_S = database.database().ref(`${PROD_MASTER_TABLE}/${REGION_S}`);

const HomePage = () => {
  const setDB_NameOnCurrRegion_City = () => {
    if (currCity === CITY_ALL) {
      if (currRegion === REGION_BOM_A || currRegion === REGION_ALL) {
        return productListDB_A;
      } else if (currRegion === REGION_BOM_B) {
        return productListDB_B;
      } else if (currRegion === REGION_BOM_N) {
        return productListDB_N;
      } else if (currRegion === REGION_PUNE_P) {
        return productListDB_P;
      } else if (currRegion === REGION_BOM_H) {
        return productListDB_H;
      } else if (currRegion === REGION_BOM_K) {
        return productListDB_K;
      } else if (currRegion === REGION_S) {
        return productListDB_S;
      } else if(currRegion === REGION_BOM_DD){
        return productListDB_DD;
      } else if(currRegion === REGION_BOM_DE){
        return productListDB_DE;
      } else if(currRegion === REGION_BOM_DF){
        return productListDB_DF;
      } else if(currRegion === REGION_BOM_DG){
        return productListDB_DG;
      } else if(currRegion === REGION_BOM_PG){
        return productListDB_PG;
      } else if(currRegion === REGION_BOM_C){
        return productListDB_C;
      } else if(currRegion === REGION_BOM_NK){
        return productListDB_NK;
      }
    }
    if (currCity === CITY_MUMBAI) {
      if (currRegion === REGION_BOM_A || currRegion === REGION_BOM_ALL) {
        return productListDB_A;
      } else if (currRegion === REGION_BOM_B) {
        return productListDB_B;
      } else if (currRegion === REGION_BOM_N) {
        return productListDB_N;
      } else if (currRegion === REGION_BOM_H) {
        return productListDB_H;
      } else if (currRegion === REGION_BOM_K) {
        return productListDB_K;
      }
    } else if (currCity === CITY_PUNE) {
      if (currRegion === REGION_PUNE_P) {
        return productListDB_P;
      }
    } else if (currCity === REGION_YO_GROCERY) {
      return productListDB_S;
    } else if(currCity === REGION_BOM_A){
      return productListDB_A;
    }
  };
  const history = useHistory();
  const global_state = useContext(rootStore);
  const globalState = global_state.state;
  const [stockData, setStockData] = useState(false);
  const [allProductData, setAllProductData] = useState([]);
  const [prodDataToBeSent, setProdDataToBeSent] = useState([]);
  const INIT_PROD_SHOWN = 20;
  const INIT_ON_SEARCH = 10;
  const isUserSearching = globalState.hasOwnProperty('filteredRes') ? true : false;
  const isUserEditingProd = globalState.hasOwnProperty('editData') ? true : false;
  const currCity = getCurrCity(globalState);
  const currRegion = getCurrRegion(globalState);
  const productListFromRegion = setDB_NameOnCurrRegion_City();
  const setInitSearchedItems = () => {
    if (globalState.hasOwnProperty('filteredRes')) {
      const obj = globalState.filteredRes;
      const len = obj.length;
      const resArr = [];
      if (len < INIT_ON_SEARCH) {
        setProdDataToBeSent(obj);
      } else {
        for (let i = 0; i < INIT_ON_SEARCH; i++) {
          resArr.push(obj[i]);
        }
        setProdDataToBeSent(resArr);
      }
    }
  };

  const setInitProdItems = (dataArr) => {
    const initArr = [];
    for (let i = 0; i < INIT_PROD_SHOWN; i++) {
      initArr.push(dataArr[i]);
    }
    setProdDataToBeSent(initArr);
  };

  const getDataFromDB = (data) => {
    const tempArr = [];
    productListFromRegion.once('value').then((dataSnapshot) => {
      let count = 1;
      dataSnapshot.forEach((child) => {
        const item = child.val();
        const keyNo = child.key;
        // if (
        //   item['active_status'] == 'Y' ||
        //   item['stock_ind'] == 'Y' ||
        //   validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))
        // ) {
          item['serialNo'] = count;
          item['keyNo'] = keyNo;
          tempArr.push(item);
          count++;
        // }
      });
      setAllProductData(tempArr);
      setInitProdItems(tempArr);
    });
  };

  const onLoadMoreClick = (event) => {
    event.preventDefault();
    setProdDataToBeSent(allProductData);
  };

  const onSearchShowMoreClick = (event) => {
    event.preventDefault();
    setProdDataToBeSent(globalState.filteredRes);
  };

  const checkValidUrl = () => {
    const userStatus = sessionStorage.getItem(SESSION_TEXT) || false;
    if (userStatus === false) {
      history.push('/login');
    }
  };

  const LoadMoreComp = () => {
    return (
      <ShowMoreContainer>
        <button onClick={onLoadMoreClick}>Load More Products</button>
      </ShowMoreContainer>
    );
  };

  const ShowMoreComp = () => {
    return (
      <ShowMoreContainer>
        <button onClick={onSearchShowMoreClick}>Show Remaining Products</button>
      </ShowMoreContainer>
    );
  };

  const ShowOnInitLoadComp = () => {
    return (
      <Fragment>
        <ProductData region_name={productListFromRegion.key} prodDataToBeShown={prodDataToBeSent} />
        {prodDataToBeSent.length === INIT_PROD_SHOWN ? <LoadMoreComp /> : null}
      </Fragment>
    );
  };

  const ShowOnSearchComp = () => {
    return (
      <Fragment>
        <ProductData region_name={productListFromRegion.key} prodDataToBeShown={prodDataToBeSent} />
        {prodDataToBeSent.length === INIT_ON_SEARCH ? <ShowMoreComp /> : null}
      </Fragment>
    );
  };

  useEffect(() => {
    if (isUserSearching && !isUserEditingProd) {
      setInitSearchedItems();
    } else if (!isUserSearching && prodDataToBeSent.length > 0) {
      setInitProdItems(allProductData);
    }
  }, [isUserSearching, globalState.filteredRes]);

  useEffect(() => {
    checkValidUrl();
  }, []);

  useEffect(() => {
    document.title = INIT_PAGE_TITLE;
    getDataFromDB();
  }, [currRegion, currCity]);

  return (
    <>
      <ProductContext.Provider value={allProductData}>
        <MainContainer>
          <NavBar />
          {!isUserEditingProd ? <ChangeRegion /> : null}

          {!isUserEditingProd && !isUserSearching && prodDataToBeSent.length > 0 ? (
            <ShowOnInitLoadComp />
          ) : !isUserEditingProd && !isUserSearching && prodDataToBeSent.length === 0 ? (
            <LoaderComp />
          ) : null}

          {!isUserEditingProd && isUserSearching && prodDataToBeSent.length > 0 ? (
            <ShowOnSearchComp />
          ) : !isUserEditingProd && isUserSearching && prodDataToBeSent.length === 0 ? (
            <LoaderComp />
          ) : null}

          {isUserEditingProd ? <EditProducts /> : null}
          <FooterComponent />
        </MainContainer>
      </ProductContext.Provider>
    </>
  );
};

export default HomePage;
