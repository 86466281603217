import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import { Badge, Form, Modal, Navbar, Jumbotron, InputGroup, Button, Alert, ButtonGroup, Table } from 'react-bootstrap'
import Select from 'react-select';
import database from '../../service/firebaseConfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import Backdrop from '../../CommonRequirements/backdrop'
import { toast } from 'react-toastify';
import baseUrl from '../../service/servicesConfig';
import { validateIsAdminUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';

const Product_Base = database.database().ref(`Product_Base_Data`)

const ConvertedStockEditModal = ({ majorCat, parentItemCode, dataItems, ...props }) => {

    const [currentDate, setCurrentDate] = useState(new Date(dataItems.date_of_packing))
    const [articleCodesList, setArticleCodesList] = useState("")
    const [childItemCode, setChildItemCode] = useState("")
    const [childItemName, setChildItemName] = useState("")
    const [childWeight, setChildWeight] = useState(dataItems.child_weight)
    const [childUoM, setChildUoM] = useState(dataItems.uom)
    const [actualChildWeight, setActualChildWeight] = useState("")
    const [childStockProduction, setChildStockProduction] = useState(dataItems.child_stock_production)
    const [wastage, setWastage] = useState(dataItems.wastage)
    const [onceDisplay, setOnceDisplay] = useState(true)
    const [backDrop, setBackDrop] = useState(false)
    const { control, handleSubmit, watch } = useForm();

    const [articleCodeSelected, setArticleCodeSelected] = useState("")

    console.log('dataItems: ', dataItems);

    const assignItemvalues = (itemName) => {
        console.log("Selected Item Name:: " + itemName)
        if (itemName) {
            setOnceDisplay(false)
            setBackDrop(true)
            Product_Base.once("value", snapShot => {
                const productDetails = snapShot.val()
                Object.keys(productDetails).forEach(itemCode => {
                    let item_description = productDetails[itemCode].brand ? productDetails[itemCode].brand + " | " + productDetails[itemCode].long_description : productDetails[itemCode].long_description;
                    if (itemName.includes(item_description)) {
                        setChildItemCode(itemCode)
                        setChildWeight(productDetails[itemCode].outer_size && productDetails[itemCode].outer_size)
                        setActualChildWeight(getActualChildWeight(productDetails[itemCode].outer_size, productDetails[itemCode].weight, productDetails[itemCode].uom))
                    }
                })
            }).then(() => setBackDrop(false))
        }
    }
    const getActualChildWeight = (outer_size, weight, uom) => {
        if (uom.toLowerCase() == 'pc' && weight.includes("gm")) {
            outer_size = weight.replace(/[^0-9]/g, '') / 1000;
        } else if (uom.toLowerCase() == 'pc' && weight.toLowerCase().includes("kg")) {
            outer_size = weight.replace(/[^0-9]/g, '');
        }
        return outer_size;
    }


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
        setBackDrop(true)
        Product_Base.once("value", snapShot => {

            let articleCodesArray = []
            const articleCodeList = snapShot.val()
            Object.keys(articleCodeList).forEach(articleCode => {

                if (articleCodeList[articleCode].parent_id && articleCodeList[articleCode].parent_id.includes(parentItemCode)) {
                    articleCodeList[articleCode].long_description = articleCodeList[articleCode].brand ? articleCodeList[articleCode].brand + " | " + articleCodeList[articleCode].long_description : articleCodeList[articleCode].long_description;
                    articleCodesArray.push({ value: articleCodeList[articleCode].long_description, label: articleCodeList[articleCode].long_description })
                }
                if (dataItems.child_item_name && dataItems.child_item_name.includes(articleCodeList[articleCode].long_description)) {
                    setActualChildWeight(getActualChildWeight(articleCodeList[articleCode].outer_size, articleCodeList[articleCode].weight, articleCodeList[articleCode].uom))
                }
            })
            setArticleCodesList(articleCodesArray)
            setBackDrop(false)
        })
    }, [])

    const onSubmitNew = data => {

        let newDate = currentDate ? currentDate : new Date()
        newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
        let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
        data["date_of_packing"] = newDate
        data["updated_by"] = loggedEmailId
        data["parent_item_code"] = parentItemCode ? parentItemCode : dataItems.parent_item_code
        data["major_category"] = majorCat ? majorCat : dataItems.major_category
        data["child_item_name"] = childItemName ? childItemName : dataItems.child_item_name
        data["child_item_code"] = childItemCode ? childItemCode : dataItems.child_item_code
        data["child_weight"] = childWeight ? childWeight : dataItems.child_weight
        data["child_item_code"] = childItemCode ? childItemCode : dataItems.child_item_code
        data["child_stock_production"] = childStockProduction ? childStockProduction : dataItems.child_stock_production
        data["total_weight_packed"] = childWeight && childStockProduction ? childWeight * childStockProduction : dataItems.total_weight_packed
        data["wastage"] = wastage ? wastage : dataItems.wastage
        data["converted_stock"] = actualChildWeight ? wastage ? (actualChildWeight * childStockProduction) + Number(wastage) : (actualChildWeight * childStockProduction) : dataItems.converted_stock
        data["id"] = dataItems.id
        data["old_date_of_packing"] = dataItems.date_of_packing
        data["old_total_weight_packed"] = dataItems.total_weight_packed
        console.log(data);
        console.log("Updation ID:::" + dataItems.id)
        // push to API 

        const item_id = childItemCode ? childItemCode : dataItems.child_item_code;

        const url = `/api/superzop/commercial/stockconversion/convertedstocks/editconvertedstockandsi?type=edit`;

        baseUrl.post(url, data).then(res => {

            console.log('Push Converted Stocks ', res);

            //resolve("Success");

            setTimeout(() => {
                window.location.reload()
            }, 2000);

            return toast('Edited and Synced System Inventory', { type: toast.TYPE.SUCCESS });

        }).catch((error) => {

            console.error(error);
            //reject(error);
            return toast('Failed to Edit', { type: toast.TYPE.SUCCESS });
        });
    }

    const onSubmit = data => {

        let newDate = currentDate ? currentDate : new Date()
        newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
        let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`)
        data["date_of_packing"] = newDate
        data["updated_by"] = loggedEmailId
        data["parent_item_code"] = parentItemCode ? parentItemCode : dataItems.parent_item_code
        data["major_category"] = majorCat ? majorCat : dataItems.major_category
        data["child_item_name"] = childItemName ? childItemName : dataItems.child_item_name
        data["child_item_code"] = childItemCode ? childItemCode : dataItems.child_item_code
        data["child_weight"] = childWeight ? childWeight : dataItems.child_weight
        data["child_item_code"] = childItemCode ? childItemCode : dataItems.child_item_code
        data["child_stock_production"] = childStockProduction ? childStockProduction : dataItems.child_stock_production
        data["total_weight_packed"] = childWeight && childStockProduction ? childWeight * childStockProduction : dataItems.total_weight_packed
        data["wastage"] = wastage ? wastage : dataItems.wastage
        data["converted_stock"] = actualChildWeight ? wastage ? (actualChildWeight * childStockProduction) + Number(wastage) : (actualChildWeight * childStockProduction) : dataItems.converted_stock
        console.log(data);
        console.log("Updation ID:::" + dataItems.id)
        // push to API 

        const item_id = childItemCode ? childItemCode : dataItems.child_item_code;

        const update_converted_stocks_url = `/api/superzop/commercial/stockconversion/convertedstocks/updateconvertedstocksintotable?key=${dataItems.id}`;

        baseUrl.post(update_converted_stocks_url, data).then(resData => {

            console.log(resData)
            props.onHide()
            setTimeout(() => {
                window.location.reload()
            }, 3000);

            /*let date = new Date();
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

            const sync_sys_inv_url = `/api/superzop/systeminventory/calculatesysteminventorywithinventorypoints?start_date=${newDate}&end_date=${date}&item_id=${item_id}`;

            baseUrl.post(sync_sys_inv_url).then(res => {

                console.log('isAdmin updatePurchaseDetails ', res);
                return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
            }).catch((error) => {
                console.log(error);
            });*/

            const url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${newDate}&item_id=${item_id}`;

            const obj = {
                converted_stock: data.converted_stock
            }

            console.log('url: ', url, ' obj: ', obj)

            baseUrl.put(url, obj).then(res => {

                console.log('Synced System Inventory ', res);
                return toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
            }).catch(error => {
                console.log(error);
            })

            return toast("Updated Stock conversion record", { type: toast.TYPE.SUCCESS });
        }).catch((error) => {

            console.log(error)
            props.onHide()
            return toast("Couldn't update the stock conversion record", { type: toast.TYPE.ERROR });
        });

        /*fetch(`https://services2.superzop.com/api/superzop/commercial/stockconversion/convertedstocks/updateconvertedstocksintotable?key=${dataItems.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => Promise.resolve(response))
            .then(data => {

                console.log(data)
                props.onHide()
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
                return toast("Updated Stock conversion record", { type: toast.TYPE.SUCCESS });

            })
            .catch((error) => {

                console.log(error)
                props.onHide()
                return toast("Couldn't update the stock conversion record", { type: toast.TYPE.ERROR });
            });*/
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    )
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Convert Stock
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={/*handleSubmit(onSubmit)*/handleSubmit(onSubmitNew)}>
                    <Table>
                        <tr>
                            <td>Date of packaging</td>
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                <td>
                                    <DatePicker name={"dateField"} selected={currentDate}
                                        onChange={(val) => { setCurrentDate(val) }}
                                        minDate={new Date("2017/01/01")}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<CustomDateInput />} />
                                </td> :
                                <td><Form.Control readOnly value={dataItems.date_of_packing} /></td>}
                        </tr>
                        <tr>
                            <td>Major Category</td>
                            <td><Form.Control name={"major_category"} readOnly value={majorCat && majorCat} /></td>
                        </tr>
                        <tr>
                            <td>Parent Item Code</td>
                            <td><Form.Control name={"parent_item_code"} readOnly value={parentItemCode && parentItemCode} /></td>
                        </tr>
                        <tr>
                            <td>Child Item Name</td>
                            <td>
                                <Select
                                    name="child_item_name"
                                    required
                                    options={articleCodesList}
                                    control={control}
                                    placeholder={dataItems.child_item_name}
                                    onChange={(val) => { setChildItemName(val.value); assignItemvalues(val.value) }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Child Item Code</td>
                            <td><Form.Control readOnly name={"child_item_code"} placeholder={dataItems.child_item_code} value={childItemCode && childItemCode} onChange={(e) => { setArticleCodeSelected(e.target.value); }} /></td>
                        </tr>
                        <tr>
                            <td>Child Weight</td>
                            <td><Form.Control name={"child_weight"} readonly value={childWeight ? childWeight : dataItems.child_weight} /></td>
                        </tr>
                        <tr>
                            <td>Child Stock Production</td>
                            <td><Form.Control name={"child_stock_production"} number value={childStockProduction ? childStockProduction : dataItems.child_stock_production} onChange={(e) => { setChildStockProduction(e.target.value); }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Total Weight Packed</td>
                            <td><Form.Control readOnly name={"total_weight_packed"} readonly number value={childWeight && childStockProduction ? childWeight * childStockProduction : dataItems.total_weight_packed} /></td>
                        </tr>
                        <tr>
                            <td>Wastage in KG</td>
                            <td><Form.Control name={"wastage"} number value={wastage} onChange={(e) => { setWastage(e.target.value); }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Converted Stock in KG</td>
                            <td><Form.Control name={"converted_stock"} value={childWeight ? wastage ? (actualChildWeight * childStockProduction) + Number(wastage) : (actualChildWeight * childStockProduction) : dataItems.converted_stock} /></td>
                        </tr>
                    </Table>
                    <Button onClick={props.onHide} style={{ float: "right" }}>Close</Button>
                    <Button type={"submit"} style={{ float: "right", marginRight: 20 }} variant={"success"}>Submit</Button>
                </form>
                {backDrop ? (<Backdrop parentLoadStatus={backDrop} />) : (null)}
            </Modal.Body>
        </Modal>

    )
}

export default ConvertedStockEditModal
