import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Card, Table, Button, Form, ButtonGroup, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import database from '../../service/firebaseConfig';
import { toast } from 'react-toastify';
import ViewEditModal from './EditListing'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { validatePurchaseExportOnlyUser, validatePurchaseExportUser, validateGRNOnlyUser, validateIsAdminUser, validateGRNOrFinanceUser } from '../../service/credentials';
// import ExportDetails from './ExportPurchaseDetails'
import InfoConfirmPopup from './InfoConfirmPopup'
import Backdrop from '../../CommonRequirements/backdrop'
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import ExportFinancialBill from './ExportFinancialBilling';
import './financelisting.css'
import DoneIcon from '@material-ui/icons/Done';
import Switch from "react-switch";
import PaymentPopup from './PaymentPopup';
import baseUrl from '../../service/servicesConfig';



const PurchaseDetailsData = database.database().ref(`Purchase_Details`)

function Financelisting() {

    const [getDate, setDate] = useState(new Date())
    const [dataRender, setDataRender] = useState(false)
    const [productEditTable, setProductEditTable] = useState([])
    const [modifiedDate, setModifiedDate] = useState("")
    const [modifiedMonth, setModifiedMonth] = useState("")
    const [renderAllDetails, setRenderAllDetails] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState("")
    const [exportDetails, setExportDetails] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [articleToken, setArticleToken] = useState("")
    const [allData, setAllData] = useState("")
    const [uniQRandomKey, setUniQRandomKey] = useState("")
    const [infoPopUp, setInfoPopUp] = useState(false)
    const [infoDetails, setInfoDetails] = useState("")
    const [reasonField, setReasonField] = useState("")
    const [grnToModify, setGrnToModify] = useState("")
    const [billSubmittedConfirm, setBillSubmittedConfirm] = useState("")
    const [billReceivedInfo, setBillReceivedInfo] = useState("")
    const [idToModify, setIdToModify] = useState("")
    const [paymentPopup, setPaymentPopup] = useState(false)
    const [informationItems, setInformationItems] = useState("")
    const [dateRangeType, setDateRangeType] = useState(sessionStorage.getItem("dateRangeType") ? sessionStorage.getItem("dateRangeType") : validateGRNOrFinanceUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? "GRN" : "Purchase");


    let filterResult = ""
    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyDetails = (success) => {
        if (success) {
            let newDtSelected = new Date(sessionStorage.getItem(`datekey`))
            return toast(`No details available for ${newDtSelected.getDate()}-${newDtSelected.getMonth() + 1}-${newDtSelected.getFullYear()}`, { type: toast.TYPE.ERROR });
        }
    };
    const handleChange = (date) => {

        setBackDrop(true)
        if (!date) {
            setDate("")
            return;
        }
        setDate(date)
        setDataRender(true)
        let dateSelected = date.getDate();
        let monthSelected = date.getMonth() + 1;

        if (dateSelected < 10)
            dateSelected = '0' + dateSelected;
        if (monthSelected < 10)
            monthSelected = '0' + monthSelected;
        setModifiedDate(dateSelected);
        setModifiedMonth(monthSelected);
        sessionStorage.setItem("datekey", date)
        window.location.reload()
    }

    useEffect(() => {

        return () => {
            console.log('return () window.onbeforeunload');
            sessionStorage.setItem("dateRangeType", "")
            //window.onbeforeunload = null;
        };
    }, []);

    useEffect(() => {

        console.log('dateRangeType: ', dateRangeType);
        setBackDrop(true)
        const pushTableDetails = []
        let updateItemsKeys = []
        let newItemKeysArray = []
        let grnDetailsCheck = []
        let sessionDate
        let filterDate = ""
        let sessionYr
        let sessionMn
        let sessionDt
        if (sessionStorage.getItem(`datekey`)) {

            sessionDate = sessionStorage.getItem(`datekey`)
            filterDate = new Date(sessionDate)
            setDate(filterDate)
            sessionYr = filterDate.getFullYear();
            sessionMn = filterDate.getMonth() + 1;
            if (sessionMn < 10)
                sessionMn = "0" + sessionMn
            sessionDt = filterDate.getDate();
            if (sessionDt < 10)
                sessionDt = "0" + sessionDt;
        }
        if (filterDate == "") {
            filterDate = getDate
        }

        const year = sessionDate ? sessionYr : getDate.getFullYear();
        const month = sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1);
        const date = sessionDate ? sessionDt : getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate();

        const complete_date = `${year}-${month}-${date}`;

        let getUrl;
        if (dateRangeType === 'Purchase') {

            getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
        } else if (dateRangeType === 'GRN') {

            getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?grn_start_date=${complete_date}&grn_end_date=${complete_date}`;
        } else {

            getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
        }

        console.log('getUrl: ', getUrl);
        //let getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;

        baseUrl.get(getUrl).then(response => {

            //console.log('response: ',response);
            if (response.status === 200) {
                return response.data;
            }
            throw response
        }).then(purchasedDetails => {
            console.log('purchasedDetails ', purchasedDetails);

            if (purchasedDetails.length > 0) {

                let grnNumber = ''
                let inVoiceNo = ''
                //const purchasedDetails = snapShot.val()
                console.log(purchasedDetails)
                setAllData(purchasedDetails)
                let uniqueObjects = []
                // Object.keys(purchasedDetails).forEach(itemListing => {
                //     uniqueObjects.push({ ...purchasedDetails[itemListing], "uniqueId": itemListing })
                // })

                purchasedDetails = purchasedDetails.map(item => {

                    item.uniqueId = item.unique_key;
                    delete item.unique_key;
                    return item;
                })

                uniqueObjects.push(...purchasedDetails);
                uniqueObjects = [...new Map(uniqueObjects.map(item => [item.grn_no, item])).values()]
                console.log('uniqueObjects ', uniqueObjects)
                uniqueObjects.forEach(itemKey => {
                    if (!itemKey.grn_no)
                        return
                    pushTableDetails.push(itemKey);
                })
                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else {

                return toast(`No details available for ${filterDate.getDate()}-${filterDate.getMonth() + 1}-${filterDate.getFullYear()}`, { type: toast.TYPE.ERROR })
            }
        }).then(() => {
            setProductEditTable(pushTableDetails);
            setFilterList(pushTableDetails)
            console.log(pushTableDetails)
            setRenderAllDetails(true)
            setBackDrop(false)
        })

        /*PurchaseDetailsData.child(`${sessionDate ? sessionYr : getDate.getFullYear()}/${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}/${sessionDate ? sessionDt : getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}-${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}-${sessionDate ? sessionYr : getDate.getFullYear()}`).once("value", snapShot => {
            if (snapShot.val()) {

                let grnNumber = ''
                let inVoiceNo = ''
                const purchasedDetails = snapShot.val()
                console.log(purchasedDetails)
                setAllData(purchasedDetails)
                let uniqueObjects = []
                Object.keys(purchasedDetails).forEach(itemListing => {
                    uniqueObjects.push({ ...purchasedDetails[itemListing], "uniqueId": itemListing })
                })
                uniqueObjects = [...new Map(uniqueObjects.map(item => [item.grn_no, item])).values()]
                console.log(uniqueObjects)
                uniqueObjects.forEach(itemKey => {
                    if (!(itemKey.hasOwnProperty('grn_no')))
                        return
                    pushTableDetails.push(
                        itemKey
                    )

                })
                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
            else { return toast(`No details available for ${filterDate.getDate()}-${filterDate.getMonth() + 1}-${filterDate.getFullYear()}`, { type: toast.TYPE.ERROR }) }
        }).then(() => {
            setProductEditTable(pushTableDetails);
            setFilterList(pushTableDetails)
            console.log(pushTableDetails)
            setRenderAllDetails(true)
            setBackDrop(false)
        })*/
    }, [dateRangeType])

    const searchPurchaseList = () => {
        const filterData = []
        filterResult = Object.keys(productEditTable).filter(obj => {

            const itemId = productEditTable[obj].article_code.trim().toLowerCase();
            const itemDesc = productEditTable[obj].item_name && productEditTable[obj].item_name.trim().toLowerCase() || "";
            const invoiceNo = productEditTable[obj].invoice_number && productEditTable[obj].invoice_number.trim().toLowerCase() || "";
            const grnNo = productEditTable[obj].grn_no && productEditTable[obj].grn_no.trim().toLowerCase() || "";
            const supplierName = productEditTable[obj].supplier_name && productEditTable[obj].supplier_name.trim().toLowerCase() || "";

            setSearchText(searchText.trim().toLowerCase());
            return itemId.match(searchText) || itemDesc.match(searchText) || invoiceNo.match(searchText) || grnNo.match(searchText) || supplierName.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(productEditTable[items])
        })
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchPurchaseList()
    }, [searchText])

    const FinancelistingDetails = () => {

        PurchaseDetailsData.child(`${getDate.getFullYear()}/${modifiedMonth}/${modifiedDate}-${modifiedMonth}-${getDate.getFullYear()}`).once("value", snapShot => {
            if (snapShot.val()) {
                const purchasedDetails = snapShot.val()
                setAllData(purchasedDetails)
                let pushTableDetails = []
                let updateItemsKeys = []
                let grnDetailsCheck = []
                let newItemKeysArray = []
                let grnNumber = ''
                let inVoiceNo = ''

                let uniqueObjects = []
                Object.keys(purchasedDetails).forEach(itemListing => {

                    uniqueObjects.push({ ...purchasedDetails[itemListing], "uniqueId": itemListing })
                })
                uniqueObjects = [...new Map(uniqueObjects.map(item => [item.grn_no, item])).values()]
                uniqueObjects.forEach(itemKey => {
                    if (!(itemKey.hasOwnProperty('grn_no')))
                        return
                    pushTableDetails.push(
                        itemKey
                    )

                })
                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                pushTableDetails.forEach((items, index) => {
                    updateItemsKeys.forEach((uniqKey, uniqIndex) => {

                        if (uniqKey.articleCode === items.article_code) {
                            newItemKeysArray.push(uniqKey.uniqueKey)
                            updateItemsKeys.splice(uniqIndex, 1)
                        }
                    })
                })
                setProductEditTable(pushTableDetails)
                setFilterList(pushTableDetails)
                setRenderAllDetails(true)
            }
            else {
                setRenderAllDetails(false)
                notifyDetails(true)
            }
        }).then(() => { setDataRender(false); setBackDrop(false) })
    }

    useEffect(() => {
        if (dataRender)
            FinancelistingDetails()
    }, [dataRender])

    const GetDateInMMM = (date) => {
        if (date) {
            var arr = date.split('-');
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var formatddate = arr[0] + '-' + months[Number(arr[1]) - 1];
            return formatddate;
        } else {
            return '';
        }
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    useEffect(() => {
        if (billSubmittedConfirm !== "") {

            let newDtSelected = new Date(sessionStorage.getItem(`datekey`))
            console.log(newDtSelected)
            Object.keys(filterList).forEach(objectKey => {
                console.log(filterList[objectKey].grn_no)
                console.log(articleToken)
                console.log(filterList[objectKey].uniqueId)
                if (filterList[objectKey].hasOwnProperty('grn_no') && filterList[objectKey].grn_no === articleToken) {
                    console.log(billSubmittedConfirm)
                    filterList[objectKey].bill_submitted = "" + billSubmittedConfirm
                    const unique_key = filterList[objectKey].uniqueId;
                    const firebaseKey = unique_key.substring(11);

                    const jsonObj = {
                        "bill_submitted": billSubmittedConfirm !== "" ? "" + billSubmittedConfirm : ""
                    }

                    const firebaseProm = new Promise((resolve, reject) => {
                        PurchaseDetailsData.child(`${newDtSelected.getFullYear()}/${((newDtSelected.getMonth()) + 1) < 10 ? "0" + (newDtSelected.getMonth() + 1) : (newDtSelected.getMonth() + 1)}/${newDtSelected.getDate() < 10 ? "0" + newDtSelected.getDate() : newDtSelected.getDate()}-${((newDtSelected.getMonth()) + 1) < 10 ? "0" + (newDtSelected.getMonth() + 1) : (newDtSelected.getMonth() + 1)}-${newDtSelected.getFullYear()}`).child(firebaseKey).update(jsonObj).then(() => {
                            //toast(`Bill Submitted for ${filterList[objectKey].uniqueId} successfully`, { type: toast.TYPE.SUCCESS }) ;
                            resolve("Success");
                        }).catch(error => {
                            reject(error);
                        })
                    });

                    const mysqlProm = new Promise((resolve, reject) => {

                        const url = `/api/superzop/commercial/purchasedetails/updatepurchasedetailsrecord?key=${unique_key}`;

                        baseUrl.put(url, jsonObj).then(res => {

                            //console.log('isAdmin updatePurchaseDetails ', res);
                            resolve("Success");
                        }).catch((error) => {

                            //console.log(error);
                            reject(error);
                        });
                    });

                    Promise.all([firebaseProm,mysqlProm]).then(()=>{

                        toast(`Bill Submitted for ${filterList[objectKey].uniqueId} successfully`, { type: toast.TYPE.SUCCESS }) ;
                    }).catch(error=>{
                        console.error(error)
                    })

                }
            })
            setBillSubmittedConfirm("")
            setBackDrop(false)

        }
    }, [billSubmittedConfirm])


    return (
        <div>
            <NavBar />
            <Navbar className="bg-light mr-auto justify-content-between" style={{ paddingTop: 100 }}>
                <Form inline>
                    {/* <Badge variant="dark">Select Purchase Date </Badge> */}
                    <InputGroup size="sm" style={{ marginLeft: "30px", marginRight: "30px" }}>
                        <DropdownButton size="sm" id="dropdown-basic-button" title={dateRangeType === 'Purchase' ? "Select Purchase Date" : "Select GRN Date"}>
                            <Dropdown.Item value="Purchase" onClick={(e) => { e.preventDefault(); setDateRangeType("Purchase"); sessionStorage.setItem("dateRangeType", "Purchase"); }}>Select Purchase Date</Dropdown.Item>
                            <Dropdown.Item value="GRN" onClick={(e) => { e.preventDefault(); setDateRangeType("GRN"); sessionStorage.setItem("dateRangeType", "GRN"); }}>Select GRN Date</Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>
                    <DatePicker
                        selected={getDate}
                        onChange={(handleChange)}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        className="red-border"
                        customInput={<CustomDateInput />}
                    />
                    {productEditTable.length > 0 && <InputGroup style={{ marginLeft: "20px" }}>
                        <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Item Name / Invoice No / GRN / Supplier" value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList("") }} />
                    </InputGroup>}
                    {(productEditTable.length > 0 && validatePurchaseExportUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <Button size="sm" style={{ marginLeft: "30px" }} variant={"warning"} onClick={() => { setExportDetails(true) }}>Export | {`${getDate.getDate()}-${getDate.getMonth() + 1}-${getDate.getFullYear()}`} </Button> : null}
                </Form>

            </Navbar>
            <br /><br />
            {renderAllDetails ?
                <Table className={"main-content"} striped bordered>
                    <thead>
                        <tr>
                            <td>
                                <center>Item Id</center>
                            </td>
                            <td>
                                <center>RGRN No.</center>
                            </td>
                            <td>
                                <center>Invoice No.</center>
                            </td>
                            <td>
                                <center>Supplier Name</center>
                            </td>
                            <td>
                                <center>{(validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? `Bill Submitted` : `Status`}</center>
                            </td>
                            <td>
                                <center>Reason</center>
                            </td>
                            {validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? "" : <td>
                                <center>Bill Submitted</center>
                            </td>}
                            <td>
                                <center>Bill  Received</center>
                            </td>
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center>Payment Status</center>
                            </td>}
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center>Cheque Date</center>
                            </td>}
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center>Cheque Number</center>
                            </td>}
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center>Cheque Amount</center>
                            </td>}
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center>Invoice Amount</center>
                            </td>}
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center>Remarks</center>
                            </td>}
                        </tr>
                    </thead>
                    <tbody>
                        {renderAllDetails ? filterList ?
                            Object.keys(filterList).map((items, index) => (
                                <>
                                    <tr style={{ backgroundColor: filterList[items].info_correct ? filterList[items].info_correct === "No" ? "#FFCCCC" : "#CCFFCC" :"" }}>
                                        <td><center>{index + 1} </center></td>
                                        <td><center><Button onClick={() => { setEditModal(true); setArticleToken(filterList[items].grn_no) }}>{filterList[items].grn_no}</Button> </center></td>
                                        <td><center>{filterList[items].invoice_number} </center></td>
                                        <td><center>{filterList[items].supplier_name} </center></td>
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <td><center><Button variant="link" disabled={validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (!filterList[items].bill_submitted || (filterList[items].info_correct && (filterList[items].info_correct === "Yes")) ? true : false) : false}
                                            onClick={() => { setInfoPopUp(true); setGrnToModify(filterList[items].grn_no); setBillReceivedInfo(filterList[items].bill_received); setUniQRandomKey(filterList[items].uniqueId); setInfoDetails(filterList[items].hasOwnProperty("info_correct") && (filterList[items].info_correct)); setReasonField(filterList[items].hasOwnProperty("reasons") && (filterList[items].reasons)) }}><i class="fas fa-edit"></i></Button></center> </td> :
                                            validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? <td><center><Switch disabled={filterList[items].info_correct && filterList[items].info_correct === "Yes" ? true : false} onChange={(val) => { setBillSubmittedConfirm(val); setBackDrop(true); setIdToModify(filterList[items].uniqueId); setUniQRandomKey(filterList[items].uniqueId); setArticleToken(filterList[items].grn_no) }} checked={filterList[items].bill_submitted === "true" ? true : false} /></center></td> : <td>{filterList[items].info_correct && filterList[items].info_correct === "Yes" ? <DoneIcon /> : ""}</td>}
                                        <td><center>{filterList[items].reasons} </center></td>
                                        {validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? "" : <td><center>{filterList[items].bill_submitted === "true" ? <DoneIcon /> : ""}</center></td>}
                                        <td><center>{filterList[items].info_correct ? (filterList[items].bill_received === "true" ? <DoneIcon /> : "") : ""}</center></td>
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center><Button disabled={validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? ((filterList[items].cheque_date && (filterList[items].info_correct && (filterList[items].info_correct === "Yes"))) ? true : false) : false}
                                            onClick={() => { setPaymentPopup(true); setArticleToken(filterList[items].grn_no); setInformationItems(filterList[items]) }} variant="link"><i class="fas fa-edit"></i></Button></center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center>{filterList[items].cheque_date && filterList[items].cheque_date}</center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center>{filterList[items].cheque_number && filterList[items].cheque_number}</center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center>{filterList[items].cheque_amt && filterList[items].cheque_amt}</center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center>{filterList[items].invoice_amt && filterList[items].invoice_amt}</center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center>{filterList[items].remark && filterList[items].remark}</center></td>}
                                    </tr>
                                </>))
                            :
                            Object.keys(productEditTable).map((items, index) => (
                                <>
                                    <tr style={{ backgroundColor: productEditTable[items].info_correct ? productEditTable[items].info_correct === "No" ? "#FFCCCC" : "#CCFFCC" : "" }}>
                                        <td><center>{index + 1} </center></td>
                                        <td><center><Button onClick={() => { setEditModal(true); setArticleToken(productEditTable[items].grn_no) }}>{productEditTable[items].grn_no}</Button></center></td>
                                        <td><center>{productEditTable[items].invoice_number} </center></td>
                                        <td><center>{productEditTable[items].supplier_name} </center></td>
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ? <td><center><Button variant="link" disabled={validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? ((productEditTable[items].bill_submitted && (!productEditTable[items].bill_submitted) || (productEditTable[items].info_correct && (productEditTable[items].info_correct === "Yes"))) ? true : false) : false}
                                            onClick={() => { setInfoPopUp(true); setGrnToModify(productEditTable[items].grn_no); setBillReceivedInfo(productEditTable[items].bill_received); setUniQRandomKey(productEditTable[items].uniqueId); setInfoDetails(productEditTable[items].hasOwnProperty("info_correct") && (productEditTable[items].info_correct)); setReasonField(productEditTable[items].hasOwnProperty("reasons") && (productEditTable[items].reasons)) }}><i class="fas fa-edit"></i></Button></center> </td> :
                                            validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? <td><center><Switch disabled={productEditTable[items].info_correct && productEditTable[items].info_correct === "Yes" ? true : false} onChange={(val) => { setBillSubmittedConfirm(val); setUniQRandomKey(productEditTable[items].uniqueId); setArticleToken(productEditTable[items].grn_no) }} checked={billSubmittedConfirm !== "" ? billSubmittedConfirm : (productEditTable[items].bill_submitted && productEditTable[items].bill_submitted === "true" ? true : false)} /></center></td> : <td>{filterList[items].info_correct && filterList[items].info_correct === "Yes" ? <DoneIcon /> : ""}</td>}
                                        <td><center>{productEditTable[items].reasons} </center></td>
                                        {validateGRNOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? "" : <td><center>{productEditTable[items].info_correct ? (productEditTable[items].bill_submitted === "true" ? <DoneIcon /> : "") : ""}</center></td>}
                                        <td><center>{productEditTable[items].info_correct ? (productEditTable[items].bill_received === "true" ? <DoneIcon /> : "") : ""}</center></td>
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td><center><Button disabled={validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? ((productEditTable[items].cheque_date && (productEditTable[items].info_correct && (productEditTable[items].info_correct === "Yes"))) ? true : false) : false}
                                            onClick={() => { setPaymentPopup(true); setArticleToken(productEditTable[items].grn_no); setInformationItems(filterList[items]) }} variant="link"><i class="fas fa-edit"></i></Button></center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && filterList[items].bill_submitted === "true") && <td><center>{productEditTable[items].cheque_date} </center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && filterList[items].bill_submitted === "true") && <td><center>{productEditTable[items].cheque_number} </center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && filterList[items].bill_submitted === "true") && <td><center>{productEditTable[items].cheque_amt} </center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && filterList[items].bill_submitted === "true") && <td><center>{productEditTable[items].invoice_amt} </center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validatePurchaseExportOnlyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && filterList[items].bill_submitted === "true") && <td><center>{productEditTable[items].remark} </center></td>}
                                    </tr>
                                </>)) : ""}
                    </tbody>
                </Table> : ""}

            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}

            {exportDetails && <ExportFinancialBill size="sm"
                listedFinanceDetails={productEditTable}
                multipleExport={setExportDetails}
                exportDate={getDate}
            />}

            {infoPopUp && <InfoConfirmPopup
                show={infoPopUp}
                onHide={() => setInfoPopUp(false)}
                selectedDate={sessionStorage.getItem(`datekey`) ? new Date(sessionStorage.getItem(`datekey`)) : getDate}
                uniqKeyToModify={uniQRandomKey}
                informationApproval={infoDetails}
                reason={reasonField}
                dataObjects={allData}
                grnNoToModify={grnToModify}
                billReceivedData={billReceivedInfo}
            />}
            {editModal && <ViewEditModal
                selectedDate={sessionStorage.getItem(`datekey`) ? new Date(sessionStorage.getItem(`datekey`)) : getDate}
                show={editModal}
                onHide={() => setEditModal(false)}
                data={allData}
                token={articleToken}
            />}
            {paymentPopup && <PaymentPopup
                show={paymentPopup}
                onHide={() => setPaymentPopup(false)}
                selectedDate={sessionStorage.getItem(`datekey`) ? new Date(sessionStorage.getItem(`datekey`)) : getDate}
                filterList={filterList}
                articleToken={articleToken}
                infoData={informationItems}
            />}
        </div>
    )
}

export default Financelisting
