import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from "react-hook-form"
import { Badge, Form, Modal, Navbar, Jumbotron, InputGroup, Button, Alert, ButtonGroup, Table } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Backdrop from '../../CommonRequirements/backdrop'
import database from '../../service/firebaseConfig';
import baseUrl from '../../service/servicesConfig';


const PurchaseDetailsData = database.database().ref(`Purchase_Details`)


function PaymentPopup({ selectedDate, filterList, articleToken, infoData, ...props }) {

    const [chequeDate, setChequeDate] = useState(infoData.cheque_date ? new Date(infoData.cheque_date) : new Date())
    const [chequeNumber, setChequeNumber] = useState(infoData.cheque_number ? infoData.cheque_number : "")
    const [chequeAmt, setChequeAmt] = useState(infoData.cheque_amt ? infoData.cheque_amt : "")
    const [invoiceAmt, setInvoiceAmt] = useState(infoData.invoice_amt ? infoData.invoice_amt : "")
    const [remarks, setRemarks] = useState(infoData.remark ? infoData.remark : "")
    const [getBackDrop, setBackDrop] = useState(false)


    const { control, register, errors, getValues, handleSubmit, watch } = useForm();

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });


    const onSubmit = data => {
        if (!chequeNumber || !chequeAmt) {
            return toast(`Cheque details are mandatory`, { type: toast.TYPE.ERROR })
        }

        console.log('data ', data)
        setBackDrop(true)
        Object.keys(filterList).forEach(objectKey => {
            console.log('filterList[objectKey] ', filterList[objectKey])
            //console.log('filterList[objectKey].grn_no ',filterList[objectKey].grn_no)
            console.log('articleToken ', articleToken)
            /*if (filterList[objectKey].hasOwnProperty('grn_no') && filterList[objectKey].grn_no === articleToken) {

                PurchaseDetailsData.child(`${selectedDate.getFullYear()}/${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}/${selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate()}-${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}-${selectedDate.getFullYear()}`).child(filterList[objectKey].uniqueId).update({
                    "cheque_date": chequeDate.toISOString().split('T')[0], "cheque_number": chequeNumber, "cheque_amt": chequeAmt, "invoice_amt": invoiceAmt, "remarks": remarks
                }
                ).then(() => {
                    props.onHide(); setTimeout(() => {
                        window.location.reload()
                    }, 3000); return toast(`Payment Status updated successfully`, { type: toast.TYPE.SUCCESS });
                })
            }*/

            if (filterList[objectKey].grn_no && filterList[objectKey].grn_no === articleToken) {

                const unique_key = filterList[objectKey].uniqueId;
                const firebaseUniqKey = unique_key.substring(11);
                console.log(`uniqueId: ${filterList[objectKey].uniqueId} firebaseUniqKey ${firebaseUniqKey} chequeDate: ${chequeDate.toISOString().split('T')[0]} chequeNumber ${chequeNumber} chequeAmt ${chequeAmt} invoiceAmt ${invoiceAmt} remarks ${remarks}`);

                const url = `/api/superzop/commercial/purchasedetails/updatepurchasedetailsrecord?key=${unique_key}`;

                const cheque_date = chequeDate.toISOString().split('T')[0];

                const jsonObj = {

                    "cheque_date": cheque_date, "cheque_number": chequeNumber, "cheque_amt": chequeAmt, "invoice_amt": invoiceAmt, "remark": remarks
                }

                const firebaseProm = new Promise((resolve, reject) => {

                    PurchaseDetailsData.child(`${selectedDate.getFullYear()}/${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}/${selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate()}-${((selectedDate.getMonth()) + 1) < 10 ? "0" + (selectedDate.getMonth() + 1) : (selectedDate.getMonth() + 1)}-${selectedDate.getFullYear()}`).child(firebaseUniqKey).update(jsonObj).then(() => {
                        resolve("Success");
                    }).catch(error => {
                        reject(error);
                    })
                });

                const mysqlProm = new Promise((resolve, reject) => {

                    baseUrl.put(url, jsonObj).then(res => {

                        resolve("Success");
                    }).catch((error) => {

                        reject(error);
                    });
                });

                Promise.all([firebaseProm, mysqlProm]).then(() => {

                    props.onHide();
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                    return toast(`Payment Status updated successfully`, { type: toast.TYPE.SUCCESS });
                }).catch(error => {
                    console.error('error: ', error);
                })

            }
        })
        setBackDrop(false)
    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Payment Status
                </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Cheque Date</td>
                                    <td>Cheque Number</td>
                                    <td>Cheque Amount</td>
                                    <td>Invoice Amount</td>
                                    <td>Remarks</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <DatePicker name={"dateField"} selected={chequeDate}
                                            onChange={(val) => { setChequeDate(val) }}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<CustomDateInput />} />
                                        {errors.dateField && "date is required"}
                                    </td>
                                    <td>
                                        <Form.Control value={chequeNumber} maxlength="6" type="number" onChange={(e) => setChequeNumber(e.target.value)} />
                                    </td>
                                    <td>
                                        <Form.Control value={chequeAmt} type="number" onChange={(e) => setChequeAmt(e.target.value)} />
                                    </td>
                                    <td>
                                        <Form.Control value={invoiceAmt} type="number" onChange={(e) => setInvoiceAmt(e.target.value)} />
                                    </td>
                                    <td>
                                        <Form.Control value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"}>Update Payment Status</Button>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </div>
    )
}

export default PaymentPopup
