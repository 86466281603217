import React,{useState} from 'react'
import ImageUploader from 'react-images-upload'
import database from '../../../service/firebaseConfig'
import Backdrop from '../../../CommonRequirements/backdrop'


function UpdateImgFile({itemIdToUpload,downloadedUrl,currentItem,imageFileName,...props}) {

    const [backDrop, setBackDrop] = useState(false)
    const [preview, setPreview] = useState(true)

    const upLoadFile = (files,itemIdForImage,imageFileName) => {

        setBackDrop(true)
        const storag= database.storage();
        const storeData=storag.ref()    
        const randomFile = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
        
        // e.target.files contains File object references

        // let uploadedDt = `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`
        let folderName = "superzop_ordering_offers_images"
        // let imgName = `${randomFile}_${uploadedDt}`
        // imageFileName(imgName)

        console.log(files[0]);
        var uploadTask = storeData.child(`${folderName}/${imageFileName}`).put(files[0]);
        uploadTask.on('state_changed', ((snapshot) => {

            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {

                case "paused": 
                    console.log('Upload is paused');
                    break;
                case "running": 
                    console.log('Upload is running');
                    break;
                default:
                    console.log("Upload error")
            }
        }), function (error) {
            // Handle unsuccessful uploads
        }, function () {

            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                downloadedUrl(downloadURL)
                currentItem(itemIdForImage)
                setBackDrop(false)
                setPreview(false)
            });
        });


    }
    return (
        <div>
        <ImageUploader
            withIcon={false}
            withLabel={false}
            withPreview={preview?true:false}
            singleImage
            buttonText='Update Image'
            onChange={(val)=>{upLoadFile(val,itemIdToUpload,imageFileName)}}
            imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
            maxFileSize={5242880}
        />
        {backDrop ? (<Backdrop parentLoadStatus={backDrop} />) : (null)}
        </div>
    )
}

export default UpdateImgFile
