import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import {
Badge,
Form,
Dropdown,
Navbar,
Jumbotron,
InputGroup,
Button,
Alert,
ButtonGroup,
Table,
} from "react-bootstrap";
import Select from "react-select";
import database from "../../service/firebaseConfig";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { toast } from "react-toastify";
import Backdrop from "../../CommonRequirements/backdrop";
import { useHistory } from "react-router-dom";
import baseUrl from "../../service/servicesConfig";
import { validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL, API_TOKEN } from "../../constants/allConstants";

// const Product_Base = database.database().ref(`Product_Base_Data`);
const Product_Base = database.database().ref(`Product_Master_New/A`);

const options = [
// { value: 'This button is Diabled', label: 'This Select is Diabled' },
// { value: 'option2', label: 'Click on proceed first' },
];

const initialValue = [options[0], options[2]];

const AddNewStock = () => {
const [currentDate, setCurrentDate] = useState(new Date());
const [parentNameList, setParentNameList] = useState("");
const [childNameList, setChildNameList] = useState("");
const [childName, setChildName] = useState("");
const [parentName, setParentName] = useState("");
const [itemCode, setItemCode] = useState("");
const [childItemName, setChildItemName] = useState("");
const [childItemCode, setChildItemCode] = useState("");
const [childWeight, setChildWeight] = useState("");
const [actualChildWeight, setActualChildWeight] = useState("");
const [childUoM, setChildUoM] = useState("");
const [childOuterSize, setChildOuterSize] = useState("");
const [outerSize, setOuterSize] = useState("");
const [uomVal, setUomVal] = useState("");
const [category, setCategory] = useState("");
const [variety, setVariety] = useState("");
const [bagWeightLoss, setBagWeightLoss] = useState("");
const [getBackDrop, setBackDrop] = useState(false);
const [stockTransferTotalWt, setStockTransferTotalWt] = useState("");
const [stockTransfer, setStockTransfer] = useState("");
const [systemInventory, setSystemInventory] = useState(0);
const [itemName, setItemName] = useState("");
const [productBaseData, setProductBaseData] = useState({});
const [articleCodesList, setArticleCodesList] = useState([]);
const [previouslySelected, setPreviouslySelected] = useState("");
const [childStock, setChildStock] = useState("");
const [wastage, setWastage] = useState(0);
const [disableList, setDisableList] = useState(false);
const [stkConvEntryClose, setStkConvEntryClose] = useState(false);
const [isSubmitting, setIsSubmitting] = useState(false);
const [BagQrd, setBagQrd] = useState([]);
const [BagQrdValues, setBagQrdValues] = useState([]);
const [StockConversionDate, setStockConversionDate] = useState();
const [QrIdOpt, setQrIdOpt] = useState([]);
const [BagQrd_2, setBagQrd_2] = useState([]);
const [partialBagQrdValues, setpartialBagQrdValues] = useState([]);
const [proceed, setProceedButton] = useState(false)
const [errorMessage, setErrorMessage] = useState(''); 

const [selectedValue, setSelectedValue] = useState(initialValue);
// Simulating Fake Disable select since disabled property is not working with select component
const disabledStyle = {
    control: (provided) => ({
    ...provided,
    backgroundColor: '#e9ecef',
    borderColor: '#ced4da',
    color: '#6c757d',
    cursor: 'not-allowed',
    }),
    singleValue: (provided) => ({
    ...provided,
    color: '#6c757d',
    }),
    option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e9ecef' : provided.backgroundColor,
    color: state.isSelected ? '#6c757d' : provided.color,
    cursor: 'not-allowed',
    }),
    multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#e9ecef',
    }),
    multiValueLabel: (provided) => ({
    ...provided,
    color: '#6c757d',
    }),
    multiValueRemove: (provided) => ({
    ...provided,
    color: '#6c757d',
    cursor: 'not-allowed',
    }),
    options: (provided) => ({
    ...provided,
    backgroundColor: '#e9ecef',
    }),
};

let history = useHistory();

const {
    control,
    register,
    errors,
    getValues,
    handleSubmit,
    watch,
    setValue,
} = useForm();

//   let articleCodeSelected = watch("child_item_name");
let articleCodeSelected = childItemName;

const getActualChildWeight = (outer_size, weight, uom) => {
    if (uom.toLowerCase() == "pc" && weight.includes("gm")) {
    outer_size = weight.replace(/[^0-9]/g, "") / 1000;
    } else if (
    uom.toLowerCase() == "pc" &&
    weight.toLowerCase().includes("kg")
    ) {
    outer_size = weight.replace(/[^0-9]/g, "");
    }
    return outer_size;
};

if (articleCodeSelected && articleCodeSelected != previouslySelected) {
    setPreviouslySelected(articleCodeSelected);
    setBackDrop(true);

    Object.keys(productBaseData).forEach((itemCode) => {
    let item_description = productBaseData[itemCode].brand
        ? productBaseData[itemCode].brand +
        " | " +
        productBaseData[itemCode].long_description
        : productBaseData[itemCode].long_description;
    if (articleCodeSelected.includes(item_description)) {
        setVariety(productBaseData[itemCode].variety);
        setChildWeight(
        productBaseData[itemCode].outer_size &&
            productBaseData[itemCode].outer_size
        );
        setActualChildWeight(
        getActualChildWeight(
            productBaseData[itemCode].outer_size,
            productBaseData[itemCode].weight,
            productBaseData[itemCode].uom
        )
        );
        setChildUoM(productBaseData[itemCode].uom);
        setChildOuterSize(productBaseData[itemCode].outer_size);
        setChildItemName(item_description);
    }
    });
    setBackDrop(false);
}

toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
});

useEffect(() => {
    setBackDrop(true);
    Product_Base.once("value", (snapShot) => {
    let articleCodesArray = [];
    let childNamesArray = [];
    const articleCodeList = snapShot.val();
    const prdBaseData = {};
    const uniqueParentIds = [];

    Object.keys(articleCodeList).forEach((articleCode) => {
        prdBaseData[articleCode] = { ...articleCodeList[articleCode] };
        const parent_id = articleCodeList[articleCode].parent_id;
        if (parent_id && !uniqueParentIds.includes(parent_id)) {
        uniqueParentIds.push(parent_id);
    
        if (parent_id) {
            articleCodeList[articleCode].long_description = articleCodeList[
            articleCode
            ].brand
            ? articleCodeList[articleCode].brand +
                " | " +
                articleCodeList[articleCode].long_description
            : articleCodeList[articleCode].long_description;
            childNamesArray.push({
            articleCode: articleCode,
            parent_id: parent_id,
            value: articleCodeList[articleCode].long_description,
            label: articleCodeList[articleCode].long_description,
            });
        }
        }
    });

    Object.keys(articleCodeList)
        .filter((articleCode) => uniqueParentIds.includes(articleCode))
        .map((articleCode) => {
        if (articleCodeList[articleCode]["buying_flag"] == "Y") {
            articleCodeList[articleCode].long_description = articleCodeList[
            articleCode
            ].brand
            ? articleCodeList[articleCode].brand +
                " | " +
                articleCodeList[articleCode].long_description
            : articleCodeList[articleCode].long_description;
            articleCodesArray.push({
            articleCode: articleCode,
            value: articleCodeList[articleCode].long_description,
            label: articleCodeList[articleCode].long_description,
            });
        }
        });

    setChildNameList(childNamesArray)
    setParentNameList(articleCodesArray);
    setBackDrop(false);

    setProductBaseData(prdBaseData);
    });
}, []);

useEffect(() => {
    if (itemCode) {
    setBackDrop(true);
    let calcDate = currentDate ? currentDate : new Date();
    calcDate = `${calcDate.getFullYear()}-${
        calcDate.getMonth() + 1
    }-${calcDate.getDate()}`;
    baseUrl
        .get(
        `/api/item-inventory-movements?start_date=${calcDate}&end_date=${calcDate}&warehouse=Bhiwandi&item_id=${itemCode}&token=${API_TOKEN}`
        )
        .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
        })
        .then((data) => {
        setBackDrop(false);
        let inventory = data.data[0]["resultant_inventory_weight"]
            ? Number(data.data[0]["resultant_inventory_weight"])
            : 0;
        setSystemInventory(inventory);
        })
        .catch((err) => {
        setBackDrop(false);
        console.log(err);
        setSystemInventory(0);
        });
    }

    stockConversionEntryDateChange();
}, [itemCode, currentDate]);

const stockConversionEntryDateChange = () => {
    let calcDate = currentDate ? currentDate : new Date();

    const complete_date = `${calcDate.getFullYear()}-${
    calcDate.getMonth() + 1 < 10
        ? "0" + (calcDate.getMonth() + 1)
        : calcDate.getMonth() + 1
    }-${
    calcDate.getDate() < 0 ? "0" + calcDate.getDate() : calcDate.getDate()
    }`;

    const stk_conv_entry_close_url = `/api/superzop/commercial/stockconversion/getstockconversioncloserecordbasedonstockconversiondate?stock_conversion_date=${complete_date}`;

    baseUrl
    .get(stk_conv_entry_close_url)
    .then((response) => {
        if (response.status === 200) {
        return response.data;
        }
        throw response;
    })
    .then((data) => {
        //console.log(data);

        if (data.length > 0) {
        const stock_conversion_entry_close =
            data[0].stock_conversion_entry_close;

        if (stock_conversion_entry_close == 1) {
            setStkConvEntryClose(true);
        } else setStkConvEntryClose(false);
        } else {
        setStkConvEntryClose(false);
        }
    })
    .catch((error) => {
        console.log(error);
        // return toast("Couldn't update Purchase Details record in MySQL", { type: toast.TYPE.ERROR });
    });
};


const onSubmitNew = async (data) => {   
    try {
        console.log(
            "StockDetails::: Transfer:: " +
            stockTransferTotalWt +
            " Inventory:: " +
            systemInventory
        );

        // Check if childItemName does not contain "daily good" zepto orders
        if (!childItemName.toLowerCase().includes("daily good") && systemInventory < stockTransferTotalWt) {
            setIsSubmitting(false);
            return toast("System Inventory should be greater than Stock Transfer Total Weight", { type: toast.TYPE.ERROR });
        }
         else if (
            stkConvEntryClose &&
            !validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))
        ) {
            setIsSubmitting(false);
            return toast("Add Stock is closed for this date", {
                type: toast.TYPE.ERROR,
            });
        } else if (bagWeightLoss < 0) {
            setIsSubmitting(false);
            return toast(
                "Stock Transfer Total Weight can't be more than Stock Transfer",
                { type: toast.TYPE.ERROR }
            );
        } else {
            let newDate = currentDate ? currentDate : new Date();

            const currYear = newDate.getFullYear();
            const currMonth =
                newDate.getMonth().toString().length === 1
                    ? `0${newDate.getMonth() + 1}`
                    : newDate.getMonth() + 1;
            const currDate =
                newDate.getDate().toString().length === 1
                    ? `0${newDate.getDate()}`
                    : newDate.getDate();

            newDate = `${currYear}-${currMonth}-${currDate}`;
            let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`);

            data["stock_transfer_date"] = newDate;
            data["created_by"] = loggedEmailId;
            data["parent_name"] = parentName;
            data["parent_code"] = itemCode;
            data["major_category"] = category;
            data["stock_transfer"] = stockTransfer;
            data["stock_transfer_total_weight"] = stockTransferTotalWt;
            data["bag_weight_loss"] = bagWeightLoss ? bagWeightLoss : 0;
            data["outer_size"] = outerSize;
            data["uom"] = uomVal;

            data["date_of_packing"] = newDate;
            data["child_item_name"] = childItemName;
            data["parent_item_code"] = itemCode && itemCode;
            data["major_category"] = category && category;
            data["uom"] = childUoM && childUoM;
            data["child_item_code"] = childItemCode ? childItemCode : "";
            data["child_weight"] = childWeight ? childWeight : "";
            data["child_item_code"] = childItemCode ? childItemCode : "";
            data["child_stock_production"] = childStock ? childStock : "";
            data["total_weight_packed"] = childStock
                ? childWeight * childStock
                : "";
            data["wastage"] = wastage ? wastage : "";
            data["converted_stock"] = actualChildWeight
                ? wastage
                    ? actualChildWeight * childStock + Number(wastage)
                    : actualChildWeight * childStock
                : "";
            data["child_outer_size"] = childOuterSize ? childOuterSize : "";

            const millisecs = new Date().getTime();
            const mapping_id = `${itemCode}${childItemCode ? "-" + childItemCode : ""}-${millisecs}`;
            data["mapping_id"] = mapping_id;

            data["qr_id"] = BagQrd;

            data["qr_ids"] = (() => {
                const itemList = [];
                for (let ids of BagQrd) {
                    itemList.push({
                        qr_id: ids,
                        is_fully_converted: 1
                    });
                }

                // For partially converted
                for (let ids of BagQrd_2) {
                    if (!BagQrd.includes(ids)) {
                        itemList.push({
                            qr_id: ids,
                            is_fully_converted: 0
                        });
                    }
                }

                return itemList;
            })();

            data["delivery_date"] = newDate;
            data["stock_conversion"] = StockConversionDate;
            data["stock_conversion_user"] = loggedEmailId;

            if (BagQrd.length > Math.ceil(data.stock_transfer)) {
                setIsSubmitting(false);
                return toast("More than required QRs are selected", {
                    type: toast.TYPE.ERROR,
                });
            }

            if (BagQrd.length == 0) {
                setIsSubmitting(false);
                return toast("Bag Qr id not selected", { type: toast.TYPE.ERROR });
            }

            try {
                const qur = `/api/superzop/validate-qr/validateQRCodeForUpdate`;
                const result = await baseUrl.post(qur, {
                    qr_id: BagQrd,
                    qr_ids: (() => {
                        const itemList = [];
                        for (let ids of BagQrd) {
                            itemList.push({
                                qr_id: ids,
                                is_fully_converted: 1
                            });
                        }

                        // For partially converted
                        for (let ids of BagQrd_2) {
                            if (!BagQrd.includes(ids)) {
                                itemList.push({
                                    qr_id: ids,
                                    is_fully_converted: 0
                                });
                            }
                        }

                        return itemList;
                    })(),
                    item_id: itemCode,
                });

                let list = result.data.qrObj;

                let list_invalid = [];
                for (let obj in list) {
                    if (list[obj][0].isValid == false) {
                        setIsSubmitting(false);
                        list_invalid.push(obj);
                    }
                }

                if (list_invalid.length > 0) {
                    return toast(`${list_invalid.join(", ")} QR ids are invalid select a different id`, { type: toast.TYPE.ERROR, });
                } else {
                    toast(`Successfully added QR ids`, {
                        type: toast.TYPE.SUCCESS,
                    });
                }
            } catch (error) {
                console.log("Error validating QR codes:", error);
                setIsSubmitting(false);
                return toast("Error validating QR codes", { type: toast.TYPE.ERROR });
            }

            if (data["stock_transfer_total_weight"] < data["converted_stock"]) {
                setIsSubmitting(false);
                return toast(
                    "Stock Transfer Total Weight Can't be less than Converted Stock ",
                    { type: toast.TYPE.ERROR }
                );
            } else {
                const updStkTranSiUrl = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${newDate}&item_id=${itemCode}`;

                const stkTranfObj = {
                    stock_transfer: data.stock_transfer_total_weight,
                };
                setIsSubmitting(true);

                try {
                    const res = await baseUrl.put(updStkTranSiUrl, stkTranfObj);
                    if (childItemCode) {
                        const updconvStkSiUrl = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${newDate}&item_id=${childItemCode}`;

                        const convStkObj = {
                            converted_stock: data.converted_stock,
                        };

                        try {
                            const res = await baseUrl.put(updconvStkSiUrl, convStkObj);
                            const url = "/api/superzop/commercial/stockconversion/savestockconversionandconvertedstocksintotable";

                            try {
                                const res = await baseUrl.post(url, data);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);

                                if (variety == 'GONI') {
                                    const QRGenerationUrl = `/api/bag-qr`;
                                    const qrGenBody = {
                                        grn_number: mapping_id,
                                        item_id: childItemCode,
                                        grn_date: newDate,
                                        bags_received: Number(childStock),
                                    };

                                    try {
                                        const res = await baseUrl.post(QRGenerationUrl, qrGenBody);
                                        if (res.status == 200) {
                                            return toast("Added and Synced System Inventory", {
                                                type: toast.TYPE.SUCCESS,
                                            });
                                        } else {
                                            return toast("Failed to save data", {
                                                type: toast.TYPE.ERROR,
                                            });
                                        }
                                    } catch (error) {
                                        console.log("Error generating QR:", error);
                                        return toast("Failed to save data", {
                                            type: toast.TYPE.ERROR,
                                        });
                                    }
                                } else {
                                    return toast("Added and Synced System Inventory", {
                                        type: toast.TYPE.SUCCESS,
                                    });
                                }
                            } catch (error) {
                                console.error("Error saving stock conversion data:", error);
                                setIsSubmitting(false);
                                return toast("Failed to Add", { type: toast.TYPE.ERROR });
                            }
                        } catch (error) {
                            console.log("Error updating converted stock:", error);
                            setIsSubmitting(false);
                            return toast("Failed to update converted stock", { type: toast.TYPE.ERROR });
                        }
                    } else {
                        const url = "/api/superzop/commercial/stockconversion/savestockconversionandconvertedstocksintotable";

                        try {
                            const res = await baseUrl.post(url, data);
                            console.log("PushPurchaseDetails ", res);

                            if (variety == 'GONI') {
                                const QRGenerationUrl = `/api/bag-qr`;
                                const qrGenBody = {
                                    grn_number: mapping_id,
                                    item_id: itemCode,
                                    grn_date: newDate,
                                    bags_received: Number(childStock),
                                };

                                console.log("Check qr gen body", qrGenBody);

                                try {
                                    const res = await baseUrl.post(QRGenerationUrl, qrGenBody);
                                    if (res.status == 200) {
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 2000);

                                        return toast("Added and Synced System Inventory", {
                                            type: toast.TYPE.SUCCESS,
                                        });
                                    } else {
                                        return toast("Failed to save data", {
                                            type: toast.TYPE.ERROR,
                                        });
                                    }
                                } catch (error) {
                                    console.log("Error generating QR:", error);
                                    return toast("Failed to save data", {
                                        type: toast.TYPE.ERROR,
                                    });
                                }
                            } else {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);

                                return toast("Added and Synced System Inventory", {
                                    type: toast.TYPE.SUCCESS,
                                });
                            }
                        } catch (error) {
                            console.error("Error saving stock conversion data:", error);
                            setIsSubmitting(false);
                            return toast("Failed to Add", { type: toast.TYPE.ERROR });
                        }
                    }
                } catch (error) {
                    console.log("Error updating stock transfer:", error);
                    setIsSubmitting(false);
                }
            }
        }
    } catch (error) {
        console.error("Error saving data:", error);
        setIsSubmitting(false);
        return toast("Error while saving data", { type: toast.TYPE.ERROR });
    }
};

const clearFormFields = () => {
    setCategory('');
    setItemCode('');
    setChildItemCode('');
    setChildItemName('');
    setOuterSize('');
    setUomVal('');
    setChildWeight('');
    setActualChildWeight('');
    setChildUoM('');
    setArticleCodesList([]);
    setParentName('');
    setQrIdOpt([]);
    setBagQrd([]);
    setChildStock('');
    setStockTransfer('');
    setStockTransferTotalWt('');
    setSystemInventory(0)
    setStockConversionDate('')
    setDisableList(true);
};
const showToast = (message, type) => {
    toast(message, { type });
};

const assignItemvalues = (itemName) => {
    // child item code input checks
    if(itemName == ""){
        setErrorMessage('');
        clearFormFields();
        return;
    }

    if (!isNaN(parseFloat(itemName)) && isFinite(itemName)) {
        // incase of enter code, itemname is no. code 
        if (!/^\d{10}$/.test(itemName)) {
            setErrorMessage('Item code should be 10 digits');
            // clearFormFields();
            return;
        }else{
            setErrorMessage('');
            clearFormFields();
            setChildItemCode(itemName)
        }

        if(productBaseData[itemName]){
            itemName = productBaseData[itemName].brand
            ? productBaseData[itemName].brand +
            " | " +
            productBaseData[itemName].long_description
            : productBaseData[itemName].long_description;
        }
        else{
            showToast('Child Item details not found in product database', toast.TYPE.ERROR)
            clearFormFields();
            setChildItemCode(itemName)
            return;
        }
    }
    // child item code checks ends 
    if (itemName) {
    console.log("Selected Child   Name:: " + itemName);
    setDisableList(false);

    Object.keys(productBaseData).forEach((itemCode) => {
    
        let item_description = productBaseData[itemCode].brand
        ? productBaseData[itemCode].brand +
            " | " + 
            productBaseData[itemCode].long_description
        : productBaseData[itemCode].long_description;

        if (item_description && itemName.includes(item_description)) {
        let parentId = productBaseData[itemCode].parent_id;
        let parentObj = {}
        let parent_desc = "";
        if (parentId) {
            parentObj = productBaseData[parentId];
            if (parentObj) {
            parent_desc = parentObj.long_description;
            if (parentObj.brand) {
                parent_desc = parentObj.brand + ' | ' + parentObj.long_description;
            }
            } else {
                clearFormFields();
                setChildItemCode(itemCode)
                setChildItemName(itemName)
                showToast('Parent item details not found in DB', toast.TYPE.ERROR);
            return;
        }
    } else {
        clearFormFields();
        setChildItemCode(itemCode)
        setChildItemName(item_description)
        showToast('Parent item id not found in DB', toast.TYPE.ERROR);
            return;
        }

        setCategory(parentObj.category);
        setItemCode(parentId);
        setChildItemCode(itemCode);
        setChildItemName(item_description)
        setOuterSize(parentObj.outer_size);
        setUomVal(parentObj.uom);
        setChildWeight(
            productBaseData[itemCode].outer_size &&
            productBaseData[itemCode].outer_size
        );
        setActualChildWeight(
            getActualChildWeight(
            productBaseData[itemCode].outer_size,
            productBaseData[itemCode].weight,
            productBaseData[itemCode].uom
            )
        );
        setChildUoM(productBaseData[itemCode].uom);
        setParentName(parent_desc)
        
        let article_Id = parentId;
        const url = `/api/superzop/get-qr-id?item_id=${article_Id}`;
        setQrIdOpt([]);
        setBagQrd([]);
        baseUrl
            .get(url)
            .then((res) => {
            if (res.data.data.qr_id.length > 0) {
                let opt = res.data.data.qr_id.map((item) => {
                return {
                    value: item,
                    label: item,
                };
                });
                setQrIdOpt(opt);
                toast("Qr ids added ", { type: toast.TYPE.SUCCESS });
            } else {
                toast(
                ` Not found any Qr id for this item  ${article_Id} - ${itemName}`,
                {
                    type: toast.TYPE.ERROR,
                }
                );
            }
            })
            .catch((error) => {
            console.log(error);
            toast(
                ` Not found any Qr id for this item ${article_Id} - ${itemName} `,
                {
                type: toast.TYPE.ERROR,
                }
            );
            });
        }
    });
    }else{
    showToast("Child Item details not present in db",toast.TYPE.ERROR)
    }
};

const CustomDateInput = ({ value, onClick }) => (
    <input
    value={value}
    onClick={onClick}
    style={{ border: "solid 1px black" }}
    />
);

return (
    <div>
    <NavBar />
    <div style={{ paddingTop: 100 }}>
        <Alert
        style={{ display: "flex", justifyContent: "center" }}
        variant={"danger"}
        >
        Add New Stock
        </Alert>

        <form onSubmit={/*handleSubmit(onSubmit)*/ handleSubmit(onSubmitNew)}>
        <Table>
            <tr>
            <td>Stock Transfer Date</td>

            <td>
                <DatePicker
                name={"dateField"}
                selected={currentDate}
                onChange={(val) => {
                    setCurrentDate(val);
                }}
                minDate={new Date()}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
                />
                {errors.dateField && "date is required"}
            </td>
            </tr>
            <tr>
            <td>Child Item Name</td>
            <td>
            <Select
                    required
                    options={childNameList}
                    value={{ value: childItemName, label: childItemName }}
                    onChange={(val) => {
                    assignItemvalues(val.value);
                    }}
                    style={{ width: "150px" }}
                />
            </td>
            </tr>
            <tr>
            <td>Child Item Code</td>
            <td>
            <Form.Control
                required
                name="child_item_code"
                 type="number"
                value={childItemCode || ""}
                onChange={(e) => {
                setChildItemCode(e.target.value);
                assignItemvalues(e.target.value)
                }}
            />
            {errorMessage && (
            <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
            {errorMessage}
            </div>
        )}

            </td>
            </tr>
            <tr>
            <td>Parent Name</td>
            {!itemCode || !disableList ? (
                <td>
                <Form.Control
                    readOnly
                    name={"parent_name_included"}
                    value={parentName}
                />
                </td>
            ) : (
                <td>
                <Form.Control
                    readOnly
                    name={"parent_name_included"}
                    value={parentName}
                />
                </td>
            )}
            </tr>
            <tr>
            <td>Parent Code</td>
            <td>
                <Form.Control
                name={"parent_code"}
                value={itemCode}
                readOnly
                onChange={(e) => {
                    setItemCode(e.target.value);
                }}
                defaultValue={itemCode && itemCode}
                />
            </td>
            </tr>

            <tr>
            <td> Bag QR ID - Fully Converted </td>
            <td>
                <Select
                isMulti
                onChange={(selectedOptions) => {
                    setBagQrd(
                    selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : []
                    );
                    setBagQrdValues(selectedOptions ? selectedOptions : []);
                }}
                options={QrIdOpt}
                value={BagQrdValues}
                ></Select>
            </td>
            <td><Button onClick={(e)=>{setProceedButton(true)}}>Proceed</Button></td>
            </tr>

            <tr>
            <td> Bag QR ID - Partially Converted </td>
            <td>
            {proceed ? (
                    <Select
                    isMulti
                    onChange={(e) => {
                        setBagQrd_2(e ? e.map((option) => option.value) : []);
                        setpartialBagQrdValues(e ? e : []);
                    }}
                    options={QrIdOpt.filter((FqrId) => !BagQrdValues.some((QrId) => QrId === FqrId))}
                    value={partialBagQrdValues.filter((FqrId) => !BagQrdValues.some((QrId) => QrId === FqrId))}
                    />
                ) :   <Select
                isMulti
                readOnly
                styles={disabledStyle}
                options={options}
                value={selectedValue}
                />}
            </td>
            </tr>
    

            <tr>
            <td>Major Category</td>
            <td>
                <Form.Control
                name={"major_category"}
                readOnly
                value={category && category}
                />
            </td>
            </tr>
            <tr>
            <td>Outer Size</td>
            <td>
                <Form.Control
                name={"outer_size"}
                readOnly
                value={outerSize && outerSize + " " + uomVal}
                />
            </td>
            </tr>
            <tr>
            <td>System Inventory</td>
            <td>
                <Form.Control
                readOnly
                value={systemInventory ? systemInventory : 0}
                />
            </td>
            </tr>
        
            <tr>
            <td>Child Weight</td>
            <td>
                <Form.Control
                readOnly
                name={"child_weight"}
                value={childWeight && childWeight + " " + childUoM}
                />
            </td>
            </tr>
            <tr>
            <td>Child Stock Production</td>
            <td>
                <Form.Control
                name={"child_stock_production"}
                value={childStock}
                required
                onChange={(e) => {
                    setChildStock(e.target.value);
                    const stockTransferTotalWtCal =
                    e.target.value * actualChildWeight +
                    Number(bagWeightLoss ? bagWeightLoss : 0) +
                    Number(wastage ? wastage : 0);
                    setStockTransferTotalWt(stockTransferTotalWtCal);
                    let stockTransferCal = stockTransferTotalWtCal / outerSize;
                    setStockTransfer(stockTransferCal);
                    setBagWeightLoss(bagWeightLoss ? bagWeightLoss : 0);
                }}
                />
                {errors.child_stock_production && (
                <span style={{ color: "red", float: "left" }}>
                    *Enter Stock Transfer value
                </span>
                )}
            </td>
            </tr>
            <tr>
            <td>Total Weight Packed</td>
            <td>
                <Form.Control
                readOnly
                name={"total_weight_packed"}
                value={childWeight && childStock && childWeight * childStock}
                />
            </td>
            </tr>
            <tr>
            <td>Converted Stock in KG</td>
            <td>
                <Form.Control
                name={"converted_stock"}
                value={
                    actualChildWeight
                    ? wastage
                        ? actualChildWeight * childStock + Number(wastage)
                        : actualChildWeight * childStock
                    : ""
                }
                />
            </td>
            </tr>
            <tr>
            <td>Wastage in KG </td>
            <td>
                <Form.Control
                number
                name={"wastage"}
                value={wastage}
                required
                onChange={(e) => {
                    setWastage(e.target.value);
                    const stockTransferTotalWtCal =
                    childStock * actualChildWeight +
                    Number(bagWeightLoss ? bagWeightLoss : 0) +
                    Number(e.target.value ? e.target.value : 0);
                    setStockTransferTotalWt(stockTransferTotalWtCal);
                    let stockTransferCal = stockTransferTotalWtCal / outerSize;
                    setStockTransfer(stockTransferCal);
                }}
                />
                {errors.wastage && (
                <span style={{ color: "red", float: "left" }}>
                    *Enter wastage value
                </span>
                )}
            </td>
            </tr>
            <tr>
            <td>Bag Weight Loss in KG</td>
            <td>
                <Form.Control
                name={"bag_weight_loss"}
                type="number"
                value={bagWeightLoss}
                onChange={(e) => {
                    setBagWeightLoss(e.target.value);
                    const stockTransferTotalWtCal =
                    childStock * actualChildWeight +
                    Number(e.target.value ? e.target.value : 0) +
                    Number(wastage ? wastage : 0);
                    setStockTransferTotalWt(stockTransferTotalWtCal);
                    let stockTransferCal = stockTransferTotalWtCal / outerSize;
                    setStockTransfer(stockTransferCal);
                }}
                />
            </td>
            </tr>
            <tr>
            <td>Stock Transfer</td>
            <td>
                <Form.Control
                name={"stock_transfer"}
                value={stockTransfer && stockTransfer.toFixed(2)}
                readOnly
                required
                onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/([^0-9.]+)/, "");
                    e.target.value = val;
                    setStockTransfer(val);
                }}
                />
                <Form.Control.Feedback type="invalid">
                *Required
                </Form.Control.Feedback>
            </td>
            </tr>
            <tr>
            <td>Stock Transfer Total Weight</td>
            <td>
                <Form.Control
                name={"stock_transfer_total_weight"}
                readOnly
                value={stockTransferTotalWt && stockTransferTotalWt}
                onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/([^0-9.]+)/, "");
                    e.target.value = val;
                    setStockTransferTotalWt(val);
                }}
                />
            </td>
            </tr>

            <tr>
            <td> Stock Conversion Date </td>
            <td>
                <Form.Control
                type="date"
                name={"StockConversionDate"}
                value={StockConversionDate}
                onChange={(e) => setStockConversionDate(e.target.value)}
                required
                />
            </td>
            </tr>

            <tr>
            <td colSpan="2">
                <Button type="submit" disabled={isSubmitting}>
                Submit
                </Button>
            </td>
            </tr>
        </Table>
        </form>
    </div>
    {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}
    </div>
);
};

export default AddNewStock;
