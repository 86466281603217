import React from 'react';
import { InputTagContainer } from './wrapper';

const InputPriceEdit = (props) => {

    const val = (props.priceVal !== undefined || props.priceVal !== null || props.priceVal.length === 0) ? parseFloat(props.priceVal) : 0;

    return (
        <InputTagContainer>
            <input
                type="number"
                name={props.nameText || ""}
                value={val + ""}
                disabled={props.isDisabled || false}
                onChange={props.modifyVal}
                title ="keep blank for no updation, to update value > 0"
            />
        </InputTagContainer>
    );
};

export default InputPriceEdit;
