import React, { useState } from "react";
import {
  Center,
  Image,
  TextInput,
  Button,
  Group,
  Table,
  Grid,
} from "@mantine/core";
import { Row, Col, Form, Toast } from "react-bootstrap";
import NewImageUpload from "../../NewImageUpload";
import { toast } from "react-toastify";
import Backdrop from "../../../../CommonRequirements/backdrop";

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

const OfferListingCardUploadScreen = (props) => {
  const offerDetails = props.offerDetails;

  const [imageUploadURL, setImageUploadURL] = useState("");
  const [imageName, setImageName] = useState("");
  const [uploadedImageName, setUploadedImageName] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState();
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const handleCardBannerUpdate = props.handleCardBannerUpdate;
  const handleUploadImageCancel = props.handleUploadImageCancel;

  const handleOnResetImageClick = () => {
    setImageUploadURL("");
    setImageName("");
    setUploadedImageName("");
  };

  const handleOnImageNameChanged = (event) => {
    //handler
    setImageName(event.target.value);
  };

  const handleOnConfirmChangesClick = () => {
    if (!imageUploadURL) {
      return toast("Please upload a image", {
        type: toast.TYPE.WARNING,
      });
    }
    //saving your new image
    handleCardBannerUpdate(imageUploadURL, offerDetails.offer_code);
  };

  const handleOnCancelChangesClick = (event) => {
    setImageName("");
    setImageUploadURL("");
    handleUploadImageCancel(event);
  };

  return (
    <>
      {showLoadingOverlay ? (
        <Backdrop parentLoadStatus={showLoadingOverlay} />
      ) : null}
      <Row className="OfferListingCard__Header">
        <Col
          style={{ backgroundColor: "black", color: "white" }}
          className="OfferListingCard__Header__Col"
        >
          <div>Upload new image</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Grid justify="center" gutter="lg">
            <Grid.Col span={5}>
              {/* <Form.Label>Enter Custom Image name</Form.Label> */}
              {uploadedImageName === "" ? (
                <div>
                  <Form.Group
                    controlId="imageName"
                    style={{
                      display: "inline-block",
                      paddingTop: "40px",
                      width: "400px",
                      textAlign: "center",
                    }}
                  >
                    <TextInput
                      name="imageName"
                      placeholder="eg: SUPER100Banner"
                      label="Custom image name"
                      onChange={handleOnImageNameChanged}
                      description="If a image name is given, the uploaded image will have this name"
                      value={imageName}
                    />
                  </Form.Group>
                </div>
              ) : (
                <div
                  style={{
                    display: "inline-block",
                    paddingTop: "40px",
                    width: "400px",
                    textAlign: "center",
                  }}
                >
                  Successfully uploaded : {uploadedImageName}
                </div>
              )}

              {/* <Form.Control
                    type="text"
                    name="imageName"
                    onChange={handleOnImageNameChanged}
                    defaultValue={uploadedImageName}
                    disabled={uploadedImageName !== ""}
                  /> */}
            </Grid.Col>
            <Grid.Col style={{ textAlign: "center" }} span={5}>
              <div
                style={{
                  margin: "auto",
                  width: "100%",
                  padding: "10px",
                  paddingTop: "40px",
                }}
              >
                <Form.Label className={"required"}>
                  Upload new offer image
                </Form.Label>
                <NewImageUpload
                  uniqueKey={"img"}
                  imgName={imageName}
                  setImageUploadURL={setImageUploadURL}
                  setUploadedImageName={setUploadedImageName}
                  handleOnResetImageClick={handleOnResetImageClick}
                  setPreventFormSubmit={setShowLoadingOverlay}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingTop: "20px" }}>
          {" "}
          <Center>
            <div style={{ marginBottom: "20px" }}>
              <Button
                disabled={showLoadingOverlay}
                onClick={handleOnConfirmChangesClick}
                color="dark"
              >
                Confirm Changes
              </Button>
            </div>

            <div style={{ marginLeft: "20px", marginBottom: "20px" }}>
              <Button
                disabled={showLoadingOverlay}
                onClick={handleOnCancelChangesClick}
                color="green"
              >
                Cancel Changes
              </Button>
            </div>
          </Center>
        </Col>
      </Row>
    </>
  );
};

export default OfferListingCardUploadScreen;
