import React from 'react';

const InputProdTag = (props) => {

    let autoF = false;

    if (props.curr === props.nameTag) {
        autoF = true;
    }

    return (
        <input
            autoFocus={autoF}
            value={props.val}
            name={props.nameTag || ""}
            required={props.isRequired}
            disabled={props.isDisabled}
            type={props.inpType || "text"}
            className={props.classForRegion}
            onChange={props.onInputChange}
            placeholder={props.placeText || "Enter the data .."}
        />
    );
};

export default InputProdTag;
