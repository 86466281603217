import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Card, Table, Button, Form, ButtonGroup, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import database from '../../service/firebaseConfig';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditProductDumpModal from './EditProductDump';
import ViewProductDumpModal from './ViewProductDump';
// import ViewPurchaseModal from './ViewPurchaseModal';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { validateIsAdminUser, validatePurchaseUser, validateStockUser, validatePurchaseExportUser, validatePurchaseExportOnlyUser } from '../../service/credentials';
// import ExportDetails from './ExportPurchaseDetails'
import Backdrop from '../../CommonRequirements/backdrop'
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import ExportProductDump from './ExportProductDump'
import baseUrl from '../../service/servicesConfig';


const ProductDumpDetails = database.database().ref(`Product_Dump`)

function ProductDumpListing() {

    const [getDate, setDate] = useState(new Date())
    const [dataRender, setDataRender] = useState(false)
    const [productEditTable, setProductEditTable] = useState([])
    const [modifiedDate, setModifiedDate] = useState("")
    const [modifiedMonth, setModifiedMonth] = useState("")
    const [renderAllDetails, setRenderAllDetails] = useState(false)
    const [editProductDumpModal, setEditProductDump] = useState(false)
    const [viewProductDump, setViewProductDump] = useState(false)
    const [itemToModify, setItemToModify] = useState([])
    const [monthData, setMonthData] = useState("")
    const [selectedDate, setSelectedDate] = useState();
    const [searchText, setSearchText] = useState("")
    const [filterList, setFilterList] = useState([])
    const [exportDetails, setExportDetails] = useState(false)
    const [getBackDrop, setBackDrop] = useState(false)
    const [dumpDetails, setDumpDetails] = useState("")
    const [itemCodeList, setItemCodeList] = useState("")
    const [databaseType, setDatabaseType] = useState("Firebase");

    let filterResult = ""

    const currentDate = sessionStorage.getItem("datekey") ? new Date(sessionStorage.getItem("datekey")) : new Date()

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const selectFirebase = "Firebase - Select Dump Date";
    const selectSql = "Sql - Select Dump Date";

    const notifyDetails = (success) => {
        if (success) {
            return toast(`No details available for ${modifiedDate}-${modifiedMonth}-${getDate.getFullYear()}`, { type: toast.TYPE.ERROR });
        }
    };
    const handleChange = (date) => {

        setBackDrop(true)
        if (!date) {
            setDate("")
            return;
        }
        setDate(date)
        // setDataRender(true)
        let dateSelected = date.getDate();
        let monthSelected = date.getMonth() + 1;

        if (dateSelected < 10)
            dateSelected = '0' + dateSelected;
        if (monthSelected < 10)
            monthSelected = '0' + monthSelected;
        setModifiedDate(dateSelected);
        setModifiedMonth(monthSelected);
        setMonthData(monthSelected)
        sessionStorage.setItem("datekey", date)
        window.location.reload()
    }
    useEffect(() => {

        setBackDrop(true)
        let pushTableDetails = []
        let updateItemsKeys = []
        let newItemKeysArray = []
        let grnDetailsCheck = []
        let sessionDate
        let filterDate
        let sessionYr
        let sessionMn
        let sessionDt
        if (sessionStorage.getItem(`datekey`)) {

            sessionDate = sessionStorage.getItem(`datekey`)
            filterDate = new Date(sessionDate)
            setDate(filterDate)
            sessionYr = filterDate.getFullYear();
            sessionMn = filterDate.getMonth() + 1;
            if (sessionMn < 10)
                sessionMn = "0" + sessionMn
            sessionDt = filterDate.getDate();
            if (sessionDt < 10)
                sessionDt = "0" + sessionDt;
        }

        /*if (databaseType === "Firebase") {
            //setBackDrop(false)
            ProductDumpDetails.child(`${sessionDate ? sessionYr : getDate.getFullYear()}/${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}/${sessionDate ? sessionDt : getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}-${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}-${sessionDate ? sessionYr : getDate.getFullYear()}`).once("value", snapShot => {
                if (snapShot.val()) {

                    const productDumpDetails = snapShot.val()
                    setSelectedDate(snapShot.key);
                    setItemCodeList(snapShot.val())

                    Object.keys(productDumpDetails).forEach(itemKey => {
                        productDumpDetails[itemKey].uniqueKey = itemKey;
                        pushTableDetails.push(
                            { "uniqKey": itemKey, ...productDumpDetails[itemKey] }
                        )

                    })
                    pushTableDetails.sort((a, b) => {
                        if (a.created_at < b.created_at) {
                            return -1;
                        } else if (a.created_at > b.created_at) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                }
                else { return toast(`No details available`, { type: toast.TYPE.ERROR }) }
            }).then(() => {
                setProductEditTable(pushTableDetails); setItemToModify(newItemKeysArray)
                setFilterList(pushTableDetails)
                console.log(pushTableDetails)
                setRenderAllDetails(true)
                setBackDrop(false)
            })
        } else {*/

        const month = `${sessionDate ? sessionMn : ((getDate.getMonth()) + 1) < 10 ? "0" + (getDate.getMonth() + 1) : (getDate.getMonth() + 1)}`;
        const date = `${sessionDate ? sessionDt : getDate.getDate() < 10 ? "0" + getDate.getDate() : getDate.getDate()}`;
        const currentYear = `${sessionDate ? sessionYr : (getDate.getFullYear())}`;
        const complete_date = `${currentYear}-${month}-${date}`
        const url = `/api/superzop/commercial/productdump/getproductdumpbasesondumpdate?start_date=${complete_date}&end_date=${complete_date}`

        console.log(complete_date, url);

        baseUrl.get(url).then(response => {

            if (response.status === 200) {
                return response.data;
            }
            throw response;
        }).then(data => {

            if (data.length === 0) {

                return toast(`No details available`, { type: toast.TYPE.ERROR });
            } else {

                pushTableDetails.push(...data);

                pushTableDetails = pushTableDetails.map((item) => {

                    if (item.unique_key)
                        item.uniqueKey = item.unique_key;
                    else
                        item.uniqueKey = "";
                    return item;
                });

                console.log("pushTableDetails ", pushTableDetails);

                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

            }
        }).then(() => {
            setProductEditTable(pushTableDetails);
            setItemToModify(newItemKeysArray);
            setFilterList(pushTableDetails);
            console.log(pushTableDetails);
            setRenderAllDetails(true);
            setBackDrop(false);
        }).catch((error) => {
            console.log(error);

            if (error === "No Data") {

                return toast(`No details available`, { type: toast.TYPE.ERROR });
            }

        });
        //}

    }, [])

    const searchPurchaseList = () => {
        const filterData = []
        filterResult = Object.keys(productEditTable).filter(obj => {

            const itemId = productEditTable[obj].article_code.trim().toLowerCase();
            const itemDesc = productEditTable[obj].item_name && productEditTable[obj].item_name.trim().toLowerCase() || "";

            setSearchText(searchText.trim().toLowerCase());
            return itemId.match(searchText) || itemDesc.match(searchText)
        })
        filterResult.forEach(items => {
            filterData.push(productEditTable[items])
        })
        setFilterList(filterData)

    }
    useEffect(() => {
        if (searchText)
            searchPurchaseList()
    }, [searchText])

    const listPurchaseDetails = () => {

        /*if (databaseType === "Firebase") {
            ProductDumpDetails.child(`${getDate.getFullYear()}/${modifiedMonth}/${modifiedDate}-${modifiedMonth}-${getDate.getFullYear()}`).once("value", snapShot => {
                if (snapShot.val()) {
                    setSelectedDate(snapShot.key());
                    setItemCodeList(snapShot.val())
                    const productDumpDetails = snapShot.val()
                    const pushTableDetails = []
                    let updateItemsKeys = []
                    let newItemKeysArray = []

                    Object.keys(productDumpDetails).forEach(items => {

                        updateItemsKeys.push({ "uniqueKey": items, "articleCode": productDumpDetails[items].article_code })

                        console.log(items)
                        pushTableDetails.push(
                            productDumpDetails[items]
                        )
                    })
                    pushTableDetails.sort((a, b) => {
                        if (a.created_at < b.created_at) {
                            return -1;
                        } else if (a.created_at > b.created_at) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    pushTableDetails.forEach((items, index) => {
                        updateItemsKeys.forEach((uniqKey, uniqIndex) => {

                            if (uniqKey.articleCode === items.article_code) {
                                newItemKeysArray.push(uniqKey.uniqueKey)
                                updateItemsKeys.splice(uniqIndex, 1)
                            }
                        })
                    })
                    setProductEditTable(pushTableDetails)
                    setFilterList(pushTableDetails)
                    setItemToModify(newItemKeysArray)
                    setRenderAllDetails(true)
                }
                else {
                    setRenderAllDetails(false)
                    notifyDetails(true)
                }
            }).then(() => { setDataRender(false); setBackDrop(false) })
        } else {*/

        const complete_date = `${getDate.getFullYear()}-${modifiedMonth}-${modifiedDate}`
        const complete_date_2 = `${modifiedDate}-${modifiedMonth}-${getDate.getFullYear()}`;
        const url = `/api/superzop/commercial/productdump/getproductdumpbasesondumpdate?start_date=${complete_date}&end_date=${complete_date}`

        console.log(complete_date, url);

        baseUrl.get(url).then(response => {

            if (response.status === 200) {
                return response.data;
            }
            throw response;
        }).then(data => {

            if (data.length > 0) {

                setSelectedDate(complete_date_2);
                setItemCodeList(data);

                const productDumpDetails = data;
                const pushTableDetails = []
                let updateItemsKeys = []
                let newItemKeysArray = []

                pushTableDetails.push(...data);

                productDumpDetails.forEach(item => {

                    const obj = { "uniqueKey": item.unique_key, "articleCode": item.article_code }
                    updateItemsKeys.push(obj);
                });

                pushTableDetails.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                        return -1;
                    } else if (a.created_at > b.created_at) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                pushTableDetails.forEach((items, index) => {
                    updateItemsKeys.forEach((uniqKey, uniqIndex) => {

                        if (uniqKey.articleCode === items.article_code) {
                            newItemKeysArray.push(uniqKey.uniqueKey)
                            updateItemsKeys.splice(uniqIndex, 1)
                        }
                    })
                })

                setProductEditTable(pushTableDetails)
                setFilterList(pushTableDetails)
                setItemToModify(newItemKeysArray)
                setRenderAllDetails(true)
            } else {
                setRenderAllDetails(false)
                notifyDetails(true)
            }
        }).then(() => { setDataRender(false); setBackDrop(false) });

        //}
    }

    useEffect(() => {
        if (dataRender)
            listPurchaseDetails()
    }, [dataRender])

    const GetDateInMMM = (date) => {
        if (date) {
            var arr = date.split('-');
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var formatddate = arr[0] + '-' + months[Number(arr[1]) - 1];
            return formatddate;
        } else {
            return '';
        }
    }

    const deleteDumpItem = (item) => {

        const itemToDelete = item.uniqueKey;

        const answer = window.confirm("Are you sure you want to delete?")
        //console.log("Deleting item:: " + `${getDate.getFullYear()}/${("0" + (getDate.getMonth() + 1)).slice(-2)}/${("0" + getDate.getDate()).slice(-2)}-${("0" + (getDate.getMonth() + 1)).slice(-2)}-${getDate.getFullYear()}/${itemToDelete}`);
        console.log("item: ", item);
        if (answer) {

            const qty_dumped = item.qty_dumped;
            const approve_status = item.approve_status;

            if (approve_status && approve_status === "Approved") {

                const item_id = item.article_code;
                const dump_date = item.dump_date;

                const update_si_url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${dump_date}&item_id=${item_id}`;

                const obj = {
                    qty_dumped: `-${qty_dumped}`
                }

                baseUrl.put(update_si_url, obj).then(response => {

                    const firebaseProm = new Promise((resolve, reject) => {

                        ProductDumpDetails.child(`${getDate.getFullYear()}/${("0" + (getDate.getMonth() + 1)).slice(-2)}/${("0" + getDate.getDate()).slice(-2)}-${("0" + (getDate.getMonth() + 1)).slice(-2)}-${getDate.getFullYear()}/${itemToDelete.substring(11)}`).remove()
                            .then(() => {
                                resolve("Success");
                                //toast(`Deleted ${itemToDelete} successfully`, { type: toast.TYPE.SUCCESS }); window.location.reload()
                            })
                            .catch(error => {
                                reject(error);
                            });
                    });

                    const mySQLProm = new Promise((resolve, reject) => {

                        const url = `/api/superzop/commercial/productdump/deleteproductdump?key=${itemToDelete}`;

                        baseUrl.delete(url).then(res => {

                            console.log('PushPurchaseDetails ', res);
                            resolve("Success");
                        }).catch(error => {

                            reject(error);
                        });
                    });

                    const promisArr = [firebaseProm, mySQLProm];
                    Promise.all(promisArr).then(() => {
                        toast(`Deleted ${itemToDelete} successfully`, { type: toast.TYPE.SUCCESS });
                        window.location.reload();

                    }).catch(error => {
                        toast(`Failed to Delete`, { type: toast.TYPE.ERROR });
                    });

                    toast('Synced System Inventory', { type: toast.TYPE.SUCCESS });
                }).catch(error => {
                    console.log(error);
                })
            } else {

                const firebaseProm = new Promise((resolve, reject) => {

                    ProductDumpDetails.child(`${getDate.getFullYear()}/${("0" + (getDate.getMonth() + 1)).slice(-2)}/${("0" + getDate.getDate()).slice(-2)}-${("0" + (getDate.getMonth() + 1)).slice(-2)}-${getDate.getFullYear()}/${itemToDelete.substring(11)}`).remove()
                        .then(() => {
                            resolve("Success");
                            //toast(`Deleted ${itemToDelete} successfully`, { type: toast.TYPE.SUCCESS }); window.location.reload()
                        })
                        .catch(error => {
                            reject(error);
                        });
                });

                const mySQLProm = new Promise((resolve, reject) => {

                    const url = `/api/superzop/commercial/productdump/deleteproductdump?key=${itemToDelete}`;

                    baseUrl.delete(url).then(res => {

                        console.log('PushPurchaseDetails ', res);
                        resolve("Success");
                    }).catch(error => {

                        reject(error);
                    });
                });

                const promisArr = [firebaseProm, mySQLProm];
                Promise.all(promisArr).then(() => {
                    toast(`Deleted ${itemToDelete} successfully`, { type: toast.TYPE.SUCCESS });
                    window.location.reload();

                }).catch(error => {
                    toast(`Failed to Delete`, { type: toast.TYPE.ERROR });
                });
            }
        }

    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );


    const openAddPurchase = () => {
        window.open("#/addProductDump");
    };

    const getTotalSalvageValue = () => {
      let totalSalvageValue = 0;
      if(filterList) {
        Object.keys(filterList).map((items, index) => {
          let salvage_amt = Number(filterList[items].salvage_rate) || 0;
          let qty = Number(filterList[items].qty_dumped) || 0;
          let salvage_rate = salvage_amt *qty;
          totalSalvageValue += salvage_rate;
        })
      } else {
        Object.keys(productEditTable).map((items, index) => {
          let salvage_amt = Number(productEditTable[items].salvage_rate) || 0;
          let qty = Number(filterList[items].qty_dumped) || 0;
          let salvage_rate = salvage_amt *qty;
          totalSalvageValue += salvage_rate;
        })
      }
      console.log(totalSalvageValue);

      return totalSalvageValue.toFixed(2);
    }

    const getTotalDumpValue = () => {
        let totalDumpValue = 0;
        if(filterList) {
          Object.keys(filterList).map((items, index) => {
            let normal_amt = Number(filterList[items].normal_rate) || 0;
            let qty = Number(filterList[items].qty_dumped) || 0;
            let normal_rate = normal_amt *qty;
            totalDumpValue += normal_rate;
          })
        } else {
          Object.keys(productEditTable).map((items, index) => {
            let normal_amt = Number(filterList[items].normal_rate) || 0;
            let qty = Number(filterList[items].qty_dumped) || 0;
            let normal_rate = normal_amt *qty;
            totalDumpValue += normal_rate;
          })
        }
        console.log(totalDumpValue);
  
        return totalDumpValue.toFixed(2);
      }

    return (
        <>
            <NavBar />
            <Navbar className="bg-light mr-auto justify-content-between" style={{ paddingTop: 100 }}>
                <Form inline>
                    <Badge variant="dark">Select Product Dump Date </Badge>
                    {/* <InputGroup size="sm" style={{ marginLeft: "30px", marginRight: "30px" }}>
                        <DropdownButton size="sm" id="dropdown-basic-button" title={databaseType === 'Firebase' ? selectFirebase : selectSql}>
                            <Dropdown.Item value="Firebase" onClick={(e) => { e.preventDefault(); setDatabaseType("Firebase"); }}>{selectFirebase}</Dropdown.Item>
                            <Dropdown.Item value="SQL" onClick={(e) => { e.preventDefault(); setDatabaseType("SQL"); }}>{selectSql}</Dropdown.Item>
                        </DropdownButton>
                    </InputGroup> */}
                    <DatePicker
                        selected={getDate}
                        onChange={(handleChange)}
                        minDate={new Date("2017/01/01")}
                        maxDate={new Date()}
                        aria-describedby="basic-addon1"
                        dateFormat="dd/MM/yyyy"
                        className="red-border"
                        customInput={<CustomDateInput />}
                    />
                    {productEditTable.length > 0 && <InputGroup style={{ marginLeft: "20px" }}>
                        <Form.Control id="searchProduct" size="sm" style={{ width: "500px" }} className="searchBox" placeholder="Item Id / Item Name " value={searchText} onChange={(e) => { setSearchText(e.target.value); if (e.target.value == "") setFilterList([]) }} />
                    </InputGroup>}
                </Form>
                <Button size="sm" variant="success" onClick={() => { openAddPurchase() }}>Add Product Dump</Button>
                <Link to={"/productDumpReport"} ><Button size={"sm"} variant={"warning"} style={{ marginRight: "10px", marginLeft: "10px" }}>Report</Button></Link>
                {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? <Button size={"sm"} variant="warning" onClick={() => { setExportDetails(true) }}>Export | {`${getDate.getDate()}-${getDate.getMonth() + 1}-${getDate.getFullYear()}`}</Button> : null}

            </Navbar>
            <br /><br />
            <center><strong><div>Total Salvage Value : Rs {getTotalSalvageValue()}</div></strong></center>
            <center><strong><div>Total Dump Value : Rs {getTotalDumpValue()}</div></strong></center>
            <br />
            {renderAllDetails ?
                <Table striped bordered>
                    <thead>
                        <tr>
                            <td>
                                <center>Article Code</center>
                            </td>
                            <td>
                                <center>Item Name</center>
                            </td>
                            <td>
                                <center>Category</center>
                            </td>
                            <td>
                                <center>UoM</center>
                            </td>
                            <td>
                                <center>Qty (Kg/Pc)</center>
                            </td>
                            <td>
                                <center>Normal rate (Rs)</center>
                            </td>
                            <td>
                                <center>Dump Amount (Rs)</center>
                            </td>
                            <td>
                                <center>Salvage rate (Rs)</center>
                            </td>
                            <td>
                                <center>Salvage Amount (Rs)</center>
                            </td>
                            <td>
                                <center>Reason</center>
                            </td>
                            <td>
                                <center> X </center>
                            </td>
                            {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) && <td>
                                <center> X </center>
                            </td>}
                        </tr>
                    </thead>
                    <tbody>
                        {renderAllDetails ? filterList ?
                            Object.keys(filterList).map((items, index) => (
                                <>
                                    <tr>
                                        <td><center>{filterList[items].article_code} </center></td>
                                        <td><center>{filterList[items].item_name} </center></td>
                                        <td><center>{filterList[items].category} </center></td>
                                        <td><center>{filterList[items].uom} </center></td>
                                        <td><center>{filterList[items].qty_dumped} </center></td>
                                        <td><center>{filterList[items].normal_rate} </center></td>
                                        <td><center>{(Number(filterList[items].normal_rate) * Number(filterList[items].qty_dumped) || 0).toFixed(2)}</center></td>
                                        <td><center>{filterList[items].salvage_rate} </center></td>
                                        {/* <td><center>{filterList[items].salvage_amt} </center></td> */}  
                                        {/* previously used above code for salvage amt calc */}
                                        <td><center>{(Number(filterList[items].salvage_rate) * Number(filterList[items].qty_dumped) || 0).toFixed(2)}</center></td>
                                        <td><center>{filterList[items].reason} </center></td>
                                        {(!validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                            (filterList[items].approve_status && filterList[items].approve_status == "Approved") ?
                                                <td><center><Button variant='success' onClick={() => { setViewProductDump(true); setDumpDetails(productEditTable[items]) }}>View</Button></center>
                                                </td> : <td><center><Button variant='secondary' onClick={() => { setEditProductDump(true); setDumpDetails(filterList[items]) }}>Edit</Button></center>
                                                </td> : (filterList[items].approve_status && filterList[items].approve_status == "Approved") ?
                                                <td><center><Button variant='success' onClick={() => { setEditProductDump(true); setDumpDetails(filterList[items]) }}>Edit</Button></center></td>
                                                : <td><center><Button variant='secondary' onClick={() => { setEditProductDump(true); setDumpDetails(filterList[items]) }}>Edit</Button></center></td>}
                                        {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) &&
                                            // <td><Button onClick={() => deleteDumpItem(filterList[items].uniqueKey)}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>
                                            <td><Button onClick={() => deleteDumpItem(filterList[items])}><i style={{ color: "white" }} class="fas fa-trash-alt fa-1x"></i></Button></td>
                                        }
                                    </tr>
                                </>))
                            :
                            Object.keys(productEditTable).map((items, index) => (
                                <>
                                    <tr>
                                        <td><center>{productEditTable[items].article_code} </center></td>
                                        <td><center>{productEditTable[items].item_name} </center></td>
                                        <td><center>{productEditTable[items].category} </center></td>
                                        <td><center>{productEditTable[items].outer_type} </center></td>
                                        <td><center>{productEditTable[items].qty_dumped} </center></td>
                                        <td><center>{productEditTable[items].normal_rate} </center></td>
                                        <td><center>{productEditTable[items].salvage_rate} </center></td>
                                        <td><center>{productEditTable[items].salvage_amt} </center></td>
                                        <td><center>{productEditTable[items].reason} </center></td>
                                        {(!validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) ?
                                            (productEditTable[items].approve_status && productEditTable[items].approve_status == "Approved") ?
                                                <td><center><Button variant='success' onClick={() => { setViewProductDump(true); setDumpDetails(productEditTable[items]) }}>View</Button></center>
                                                </td> : <td><center><Button variant='secondary' onClick={() => { setEditProductDump(true); setDumpDetails(productEditTable[items]) }}>Edit</Button></center>
                                                </td> : (filterList[items].approve_status && filterList[items].approve_status == "Approved") ?
                                                <td><center><Button variant='success' onClick={() => { setEditProductDump(true); setDumpDetails(filterList[items]) }}>Edit</Button></center></td>
                                                : <td><center><Button variant='secondary' onClick={() => { setEditProductDump(true); setDumpDetails(filterList[items]) }}>Edit</Button></center></td>}
                                    </tr>
                                </>)) : ""}
                    </tbody>
                </Table> : ""}

            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}


            {editProductDumpModal && <EditProductDumpModal
                show={editProductDumpModal}
                onHide={() => setEditProductDump(false)}
                data={dumpDetails}
            />}
            {viewProductDump && <ViewProductDumpModal
                show={viewProductDump}
                onHide={() => setViewProductDump(false)}
                data={dumpDetails}
            />}
            {exportDetails && <ExportProductDump size="sm"
                listedPurchaseDump={productEditTable}
                multipleExport={setExportDetails}
                exportDate={getDate}
            />}
        </>
    )
}

export default ProductDumpListing
