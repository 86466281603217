import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Alert,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Button,
  Form,
  Modal,
  Badge,
  Navbar,
  InputGroup
} from "react-bootstrap";
import {
  InputGroupAddon,
  Input,
  FormFeedback,
  InputGroupText,
  CardHeader,
  CardTitle,
  CardText,
  Dropdown as Dropdownreactstrap, DropdownToggle, DropdownMenu, DropdownItem,ButtonDropdown
} from "reactstrap";
import {
  Text,
  Grid,
  Select as MantineSelect,
  SimpleGrid,
  MultiSelect,
  TextInput,
  NumberInput,
  Center,
  Space,
  Table as TableVal,
  Switch,
} from "@mantine/core";

import { validateDCManagerUser, validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { toast } from "react-toastify";
import database from "../../service/firebaseConfig";
import { FaWindows, FaLessThanEqual } from "react-icons/fa";
import Select from "react-select";
import Creatable from "react-select/creatable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import baseURL from "../../service/servicesConfig";
import { deliveryCommercialServer } from "../../service/servicesConfig";
import AddSupplier from "./AddSupplier";

const PurchaseDetailsData = database.database().ref(`Purchase_Details`);
const Product_Base = database.database().ref(`Product_Base_Data`);
const SuppliersDB = database.database().ref(`Suppliers`);
const productsMasterDB = database.database().ref(`Product_Master_New/A`);
const priceDetailsDB = database.database().ref(`Price_Details/mumbai/items`);
function Add_Purchase(props) {
  const [suppliersList, setSuppliersList] = useState();
  const [addNewRecord, setAddNewRecord] = useState(false);
  const [submitDetails, setSubmitDetails] = useState(false);
  const [purchasedDate, setPurchasedDate] = useState("");
  const [category, setCategory] = useState("");
  const [articleCode, setArticleCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemSelection, setItemSelection] = useState("");
  const [outerType, setOuterType] = useState("");
  const [outerSize, setOuterSize] = useState("");
  const [isInvoiceFieldDisabled, setIsInvoiceFieldDisabled] = useState(false);
  const [bagPurchased, setBagPurchased] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [eachPurchaseData, setEachPurchaseData] = useState("");
  const [productBaseData, setProductBaseData] = useState({});
  const [articleInfo, setArticleInfo] = useState("");
  const [articleOptions, setArticleOptions] = useState("");
  const [hsn,setHSN] = useState("");
  const [Source, setSource] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTransportList, setIsOpenTransportList] = useState(Array(articleOptions.length).fill(false));
  const [isOpenSourceList, setIsOpenSourceList] = useState(Array(articleOptions.length).fill(false)); //useState(false);
  const [isOpenItemNameList, setIsOpenItemNameList] =useState(Array(articleOptions.length).fill(false)); //useState(false);
  const [isOpenList, setIsOpenList] = useState(Array(articleOptions.length).fill(false));
  // const [itemSelections, setItemSelections] = useState(Array(articleOptions.length).fill(null));

  const toggleDropdown = (index) => {
    // setIsOpen(!addedItem.isOpen);
    const newIsOpenList = [...isOpenList];
    newIsOpenList[index] = !newIsOpenList[index];
    setIsOpenList(newIsOpenList);
  };

  const toggleDropdownTransport = (index) => {
    let newIsOpenTransportList = [...isOpenTransportList]
    newIsOpenTransportList[index] = !newIsOpenTransportList[index];
    setIsOpenTransportList(newIsOpenTransportList);
  };

  const toggleDropdownSource = (index) => {
    let newIsOpenSourceList = [...isOpenSourceList];
    newIsOpenSourceList[index] = !newIsOpenSourceList[index];
    setIsOpenSourceList(newIsOpenSourceList);
  };

  const toggleDropdownItemName = (index) => {
    let newIsOpenItemNameList = [...isOpenItemNameList];
    newIsOpenItemNameList[index] = !newIsOpenItemNameList[index];
    setIsOpenItemNameList(newIsOpenItemNameList);
  };


  const handleDropdownItemClickItemName = (selectedItem,addedItems,index,addedItem) =>{
    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      itemSelection: selectedItem,
      itemName: selectedItem.value,
      isOpenItemName:false
    };
    setAddedItems(updatedItems);
    // setItemSelection(val);
    // setItemName(val.value);
    let itemName = selectedItem.value;
    let itemCode = null;
    setItemSelection(selectedItem);
    setItemName(selectedItem.value);
    // setIsOpenItemName(false);
    insertDomData(itemName, itemCode, updatedItems, index);
  }

  const handleDropdownItemClickTransport = (selectedTransport,addedItems,index,addedItem) => {
    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      Transport:selectedTransport,
      // isOpenTransport:false
    };
    setAddedItems(updatedItems);
    // setTransport(e.target.name)}
    setTransport(selectedTransport);
    // setIsOpenTransport(false);
  };

  const handleDropdownItemClickSource = (selectedSource,addedItems,index, addedItem) => {
    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      Source:selectedSource,
      // isOpenSource:false
    };
    setAddedItems(updatedItems);
    setSource(selectedSource);
    // setIsOpenSource(false);
  };

  const handleDropdownItemClick = (selectedType,addedItems,index, addedItem) => {
    // const newItemSelections = [...itemSelections];
    // newItemSelections[index] = selectedType;
    // setItemSelections(newItemSelections);

    // const newItemNames = [...itemNames];
    // newItemNames[index] = selectedItem.value;
    // setItemNames(newItemNames);

    toggleDropdown(index);

    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      outerType:selectedType,
      // isOpen:false
    };
    setAddedItems(updatedItems);
    setOuterType(selectedType);
    // setIsOpen(false);
  }

  const [addedItems, setAddedItems] = useState([{
    articleCode: '',
    disableList: false,
    itemSelection: null,
    itemName: '',
    category:'',
    hsn:'',
    outerType:'',
    uomValue:'',
    basePrice:'',
    purchasePrice:'',
    outerSize:'',
    bagPurchased:'',
    totalWeight:'',
    CashDisc:'',
    TransCharges:'',
    Transport:'',
    Source:'',
    replacementBrand:'',
    isOpen:false
  }]);
  const [itemDetails, setItemDetails] = useState({
    articleCode: '',
    disableList: false,
    itemSelection: null,
    itemName: '',
    category:'',
    hsn:'',
    outerType:'',
    uomValue:'',
    basePrice:'',
    purchasePrice:'',
    outerSize:'',
    bagPurchased:'',
    totalWeight:'',
    CashDisc:'',
    TransCharges:'',
    Transport:'',
    Source:'',
    replacementBrand:'',
    isOpen:false
  });

  const handleAddItem = () => {
    setAddedItems([...addedItems, { ...itemDetails }]);
    setItemDetails({
      articleCode: '',
      disableList: false,
      itemSelection: null,
      itemName: '',
      category:'',
      hsn:'',
      outerType:'',
      uomValue:'',
      basePrice:'',
      purchasePrice:'',
      outerSize:'',
      bagPurchased:'',
      totalWeight:'',
      CashDisc:'',
      TransCharges:'',
      Transport:'',
      Source:'',
      replacementBrand:''
    });
  };
 
  const [dateChange,setdateChange] = useState(new Date());
   const [invoiceDate,setinvoiceDate] = useState(`${
                          dateChange.getDate() < 10
                            ? "0" + dateChange.getDate()
                            : dateChange.getDate()
                        }-${
                          dateChange.getMonth() + 1 < 10
                            ? "0" + (dateChange.getMonth() + 1)
                            : dateChange.getMonth() + 1
                        }-${dateChange.getFullYear()}`);
  const [disableList, setDisableList] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [supplierSelection, setSupplierSelection] = useState("");
  const [isSupplierSelectionDisabled, setIsSupplierSelectionDisabled] =
    useState(false);
  const [correspondingItems, setCorrespondingItems] = useState("");
  const [dateToModify, setDateToModify] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [basePrice, setBasePrice] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [SupplierID, setSupplierID] = useState("");
  const [SupplierEmail, setSupplierEmail] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [replacementBrand, setReplacementBrand] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [showExistingPoChecked, setShowExistingPoChecked] = useState(false);
  const [existingPoNumbersToDisp, setExistingPoNumbersToDisp] = useState([]);
  const [existingPoSelection, setExistingPoSelection] = useState({});
  const [Payment_Terms, setPayment_Terms] = useState("")
  const [Delivery, setDelivery] = useState("")
  const [exisitingPoNumbersAndSuppliers, setExistingPoNumbersAndSuppliers] =
    useState({});
      const [PoAmt, setPoAmt] = useState(Number(purchasePrice)* Number(totalWeight));
const [GstCharges, setGstCharges] = useState("");
const [Fssai, setFssai] = useState("");
const [Gstin, setGstin] = useState("");
const [phone, setPhone] = useState("");
const [Address, setAddress] = useState("");
const [Street, setStreet] = useState("");
const [City, setCity] = useState("");
const [Pin, setPin] = useState("");
const [State, setState] = useState("");
const [Apmc, setApmc] = useState("");

const [Mrp,setMrp] = useState("")




  const PurchaseData = [];
  const DateofPurchase = [];
  let purchaseItems = {};
  const categoryField = useRef();
  const itemNoField = useRef();
  const itemNameField = useRef();
  const totalWtPurchasedRef = useRef();
  const supplierRef = useRef();
  const datepickerRef = useRef();
  let btnRef = useRef();
const [Transport, setTransport] = useState("");
const [CashDisc, setCashDisc] = useState("");
const [TransCharges, setTransCharges] = useState("");
  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast("Purchase Details added", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't add the Purchase Details", {
      type: toast.TYPE.ERROR,
    });
  };

  const fetchProductItem = () => {
    const productBaseInfo = [];
    productsMasterDB
      .once("value", (snapShot) => {
        const productItems = snapShot.val();

        Object.keys(productItems).forEach((productId) => {
          if (productItems[productId]["buying_flag"] == "Y") {
            productBaseInfo.push({
              articleId: productId,
              category: productItems[productId].category,
              lot_size: productItems[productId].lot_size,
              brand: productItems[productId].brand,
              variety: productItems[productId].variety,
              itemName: productItems[productId].long_description,
              base_price:
                productItems[productId].base_price &&
                productItems[productId].base_price,
              hsn_code : productItems[productId].hsn_code,
})

          }
        });
      })
      .then(() => {
        setArticleInfo(productBaseInfo);
      });
  };

  const fetchPoList = (selectedDate) => {
    console.log("selectedDate", moment(selectedDate).format("YYYY-MM-DD"));
    if (!selectedDate) {
      return console.log("ERROR date arg not received");
    }

    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

    const getUrl = `/api/superzop/commercial/purchasedetails/getpolist?create_date=${formattedDate}`;
    console.log("fetch po list URL ", getUrl);
    deliveryCommercialServer
      .get(getUrl)
      .then((response) => {
        if (response.status === 200) {
          const po_list = [];
          const po_list_with_suppliers = {};
          const data = response.data;

          data.forEach((item, index) => {
            po_list.push({ value: item.po_number, label: item.po_number });
            po_list_with_suppliers[item.po_number] = {
              supplier_name: item.supplier_name,
              invoice_number: item.invoice_number || "",
            };
          });
          console.log("set this po list", data);
          setExistingPoNumbersToDisp(po_list);
          setExistingPoNumbersAndSuppliers(po_list_with_suppliers);
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.log("error occured while fetching po list");
        console.log(err);
        setExistingPoNumbersToDisp([]);
      });
  };

  useEffect(() => {
    fetchProductItem();
    fetchPoList(dateToModify);
    console.log(datepickerRef.current);
  }, []);

  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({name : suppliersList[supplier].supplier_name,email :suppliersList[supplier].EmailID,key : suppliersList[supplier].supplier_id,fssai : suppliersList[supplier].fssai,gstin:suppliersList[supplier]['GST_Identification_Number_(GSTIN)'],phone: suppliersList[supplier].MobilePhone,address:suppliersList[supplier].Billing_Address,street:suppliersList[supplier].Billing_Street2,city:suppliersList[supplier].Billing_City,pin:suppliersList[supplier].Billing_Code,state:suppliersList[supplier].Billing_State, supplier_id:suppliersList[supplier].supplier_id})
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        if(!(items.supplier_id && items.supplier_id.includes('VA'))) {
          suppliers.push({ value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state});
        } else if(items.supplier_id && items.supplier_id.includes('VA') && (validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)))) {
          suppliers.push({ value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state});
        }
      });
    }).then(() => {
      setSuppliersList(suppliers)
    });
  };

  useEffect(() => {
    fetchProductItem();
    fetchSuppliers();
    console.log(datepickerRef.current);
  }, []);

  const populateArticle = () => {
    const allOptionsArticle = [];
    articleInfo.forEach((item) => {
      let itemName = item.itemName;
      if (item.brand) itemName = item.brand + " - " + item.itemName;

      allOptionsArticle.push({ value: itemName, label: itemName });
    });
    setArticleOptions(allOptionsArticle);
  };

  useEffect(() => {
    if (articleInfo) populateArticle();
  }, [articleInfo]);

  const insertDomData = (itemValue, articleCode, addedItems,index) => {
    if (articleCode) {
      setDisableList(false);

      articleInfo.forEach((item) => {
        if (articleCode.includes(item.articleId)) {
          setDisableList(true);
          console.log(item);
          categoryField.current.value = item.category;
          let itemValue = item.itemName;
          if (item.brand) itemValue = item.brand + " - " + item.itemName;
          console.log(
            "Updating based on ArticleCode:: " +
              articleCode +
              " Article Name :: " +
              itemValue +
              " Category:: " +
              item.category
          )
          let updatedItems = addedItems;
          // updatedItems[index]['articleCode'] = item.articleId;
          updatedItems[index]['disableList'] = true;
          updatedItems[index]['category'] = item.category;
          updatedItems[index]['hsn'] = item.hsn_code ? item.hsn_code :'';  
          updatedItems[index]['outerType'] = item.variety;
          updatedItems[index]['itemName'] = itemValue;
          // updatedItems[index]['uomValue'] = item.uom;
          updatedItems[index]['basePrice'] = item.base_price ? item.base_price : '';
          updatedItems[index]['purchasePrice'] = item.base_price ? item.base_price : '';
          setAddedItems(updatedItems);

          item.hsn_code ? setHSN(item.hsn_code) : setHSN("");
          setCategory(item.category);
          setItemName(itemValue);
          setOuterType(item.variety);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${articleCode}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
            updatedItems[index]['uomValue'] = itemsDetails?.uom?.toLowerCase() == "kg" ? "KG" : "PC";
            setUomValue(itemsDetails?.uom?.toLowerCase() == "kg" ? "KG" : "PC");
            updatedItems[index]['outerSize'] = itemsDetails?.outer_size && Number(itemsDetails.outer_size) ? Number(itemsDetails.outer_size) : 1;
            setOuterSize(itemsDetails?.outer_size && Number(itemsDetails.outer_size) ? Number(itemsDetails.outer_size) : 1);
          });
          priceDetailsDB.child(`${articleCode}`).once('value').then((snapShot) => {
            console.log(snapShot.val());
            const prices = snapShot.val();
            updatedItems[index]['gstCharges'] = prices?.gst_per  ? prices.gst_per : '0';
            updatedItems[index]['apmc'] = prices?.apmc_per ? prices.apmc_per : '0';
            setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
            setApmc(prices?.apmc_per ? prices.apmc_per : "0")
            if(prices?.source_type === 'local_pick')
            {
              updatedItems[index]['Source'] = 'LOCAL PICK';
              setSource("LOCAL PICK")
            }
            else if(prices?.source_type === 'dc_delivered')
            {
              updatedItems[index]['Source'] = 'DC DELIVERED';
              setSource("DC DELIVERED")
            }
            else
            {
             updatedItems[index]['Source'] = 'MILL';
             updatedItems[index]['Transport'] = prices?.mill_transport ? prices.mill_transport : "";
             updatedItems[index]['TransCharges'] = prices?.mill_transport_value ? prices.mill_transport_value  : "";
             updatedItems[index]['CashDisc'] = prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "";
             setSource("MILL")
             setTransport(prices?.mill_transport ? prices.mill_transport : "");
             setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
             setCashDisc(prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
            }
          })

        }
      });
    } else if (itemValue) {
      articleInfo.forEach((item) => {
        let itemName = item.itemName;
        if (item.brand) itemName = item.brand + " - " + item.itemName;

        if (itemValue == itemName) {
          console.log(
            "Updating based on ArticleName:: " +
              itemValue +
              " Article ID :: " +
              item.articleId +
              " Category:: " +
              item.category
          );

          let updatedItems = addedItems;
          // const newItemSelections = [...itemSelections];
          // itemSelections[index]['outerType'] = item.variety;
          // setItemSelections(newItemSelections);
          updatedItems[index]['articleCode'] = item.articleId;
          updatedItems[index]['category'] = item.category;
          updatedItems[index]['hsn'] = item.hsn_code ? item.hsn_code :'';  
          updatedItems[index]['outerType'] = item.variety;
          updatedItems[index]['uomValue'] = item.uom;
          updatedItems[index]['basePrice'] = item.base_price ? item.base_price : '';
          updatedItems[index]['purchasePrice'] = item.base_price ? item.base_price : '';
          setAddedItems(updatedItems);
          
          setCategory(item.category);
          item.hsn_code ? setHSN(item.hsn_code) : setHSN("");
          categoryField.current.value = item.category;
          setArticleCode(item.articleId);
          itemNoField.current.value = item.articleId;
          setOuterType(item.variety);
          setUomValue(item.uom);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${item.articleId}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
            console.log(itemsDetails);
            if (itemsDetails) {
              updatedItems[index]['uomValue'] = itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC";
              setUomValue(itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC");
              updatedItems[index]['outerSize'] = Number(itemsDetails.outer_size) ? Number(itemsDetails.outer_size) : 1;
              setOuterSize(
                Number(itemsDetails.outer_size)
                  ? Number(itemsDetails.outer_size)
                  : 1
              );
            }
          });
          priceDetailsDB.child(`${item.articleId}`).once('value').then((snapShot) => {
              console.log(snapShot.val());
              const prices = snapShot.val();
              updatedItems[index]['gstCharges'] = prices?.gst_per  ? prices.gst_per : '0';
              updatedItems[index]['apmc'] = prices?.apmc_per ? prices.apmc_per : '0';
              setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
              setApmc(prices?.apmc_per ? prices.apmc_per : "0")
              if(prices?.source_type === 'local_pick')
              {
                updatedItems[index]['Source'] = 'LOCAL PICK';
                setSource("LOCAL PICK")
              }
              else if(prices?.source_type === 'dc_delivered')
              {
                updatedItems[index]['Source'] = 'DC DELIVERED';
                setSource("DC DELIVERED")
              }
              else
              {
               updatedItems[index]['Source'] = 'MILL';
               updatedItems[index]['Transport'] = prices?.mill_transport ? prices.mill_transport : "";
               updatedItems[index]['TransCharges'] = prices?.mill_transport_value ? prices.mill_transport_value  : "";
               updatedItems[index]['CashDisc'] = prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "";
               setSource("MILL")
               setTransport(prices?.mill_transport ? prices.mill_transport : "");
               setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
               setCashDisc(prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
              }
            })
        }
      });
    }
  };

  const resetValues = () => {
    setArticleCode("");
    setCategory("");
    setItemName("");
    setInvoiceNumber("");
    setExistingPoSelection(null);
    setShowExistingPoChecked(false);
    setItemSelection("");
    setSupplierSelection(null);
    setIsSupplierSelectionDisabled(false);
    setSupplier("");
    setOuterType("");
    setCategory("");
    setOuterSize("");
    setInvoiceNumber("");
    setUomValue("");
    setBasePrice("");
    setPurchasePrice("");
    setTransport("")
    setCashDisc("")
    setReplacementBrand("");
    setBagPurchased("");
    setButtonStatus(false);
    setTotalWeight("");
    setHSN("");
    setPayment_Terms("");
    setDelivery("");
    setSupplierID("");
    setSupplierEmail("");
    setMrp("");
    setAddedItems([{
      articleCode: '',
      disableList: false,
      itemSelection: null,
      itemName: '',
      category:'',
      hsn:'',
      outerType:'',
      uomValue:'',
      basePrice:'',
      purchasePrice:'',
      outerSize:'',
      bagPurchased:'',
      totalWeight:'',
      CashDisc:'',
      TransCharges:'',
      Transport:'',
      Source:''
    }])
  };

  const generateNewPoNumber = (selectedDate) => {
    let yearFromDate = selectedDate.split("-")[2];
    let newYearStr = yearFromDate.substr(yearFromDate.length - 2);
    let randomFiveDigitNum = new Date().getTime() % 100000;
    let po_number = `${newYearStr}${
      selectedDate.split("-")[1]
    }${selectedDate.split("-")[0]}${randomFiveDigitNum}`;
    return po_number;
  };

  const PushPurchaseDetails = (event) => {
    let currentMn = "";
    let currentDt = "";
    const form = event.currentTarget;
    console.log("check existingPoSel", existingPoSelection);
    if (showExistingPoChecked && Object.keys(existingPoSelection).length < 2) {
      return toast("Please select an purchase order number", {
        type: toast.TYPE.ERROR,
      });
    }
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let purchaseDateValue = purchasedDate;
      setButtonStatus(true);
      console.log("source"+Source);

      let finalObj = [];

      if (purchasedDate == "") {
        if (dateToModify.getDate() < 10) {
          currentDt = "0" + dateToModify.getDate();
        } else {
          currentDt = dateToModify.getDate();
        }
        if (dateToModify.getMonth() + 1 < 10)
          currentMn = "0" + (dateToModify.getMonth() + 1);
        else {
          currentMn = dateToModify.getMonth() + 1;
        }
        purchaseDateValue = `${currentDt}-${currentMn}-${dateToModify.getFullYear()}`;
      }
      var newSupplier = true;
      suppliersList.forEach((item) => {
        if (supplier == item.value) {
          newSupplier = false;
        }
      });
      if (newSupplier) {
        SuppliersDB.push({ supplier_name: supplier });
      }
      let po_number = "";
      if (showExistingPoChecked) {
        po_number = existingPoSelection.value;
      } else {
        po_number = generateNewPoNumber(purchaseDateValue);
      }
      console.log("PurchaseDate" + purchaseDateValue);
      console.log("saved supplier name", supplier);
      let jsonObj = {
        purchased_date: purchaseDateValue,
        bill_address:Address?Address:"",
        bill_city:City?City:"",
        bill_street:Street?Street:"",
        bill_state:State?State:"",
        bill_pin:Pin?Pin:"",
        bill_gstin:Gstin?Gstin:"",
        bill_fssai:Fssai?Fssai:"",
        bill_phone:phone?phone:"",
        // gst_charges_per : itemValue.GstCharges?itemValue.GstCharges:"",
        // apmc_charges_per:itemValue.Apmc?itemValue.Apmc:"",
        // category: itemValue.category?itemValue.category:"",
        // hsn_code:itemValue.hsn?itemValue.hsn:"",
        // article_code: itemValue.articleCode,
        // base_price: itemValue.basePrice,
        invoice_number: invoiceNumber,
        // purchase_price: itemValue.purchasePrice ? itemValue.purchasePrice : itemValue.basePrice,
        // uom: itemValue.uomValue ? itemValue.uomValue :"",
        // item_name: itemValue.itemName,
        invoice_number:invoiceNumber,
        invoice_date:invoiceDate,
        po_number: po_number,
        // outer_type: itemValue.outerType,
        // outer_size: itemValue.outerSize,
        // replacement_brand: itemValue.replacementBrand ? itemValue.replacementBrand : "",
        // bag_purchased: itemValue.bagPurchased,
        // total_weight: itemValue.totalWeight ? itemValue.totalWeight : itemValue.outerSize * itemValue.bagPurchased,
        // source_type : itemValue.Source,
        // transport_charges: itemValue.TransCharges,
        // cash_discount : itemValue.CashDisc,
        // transport : itemValue.Transport,
        supplier_name: supplier,
        supplier_email:SupplierEmail?SupplierEmail:"",
        supplier_id:SupplierID,
        delivery_in_days:Delivery?Delivery:"",
        payment_terms : Payment_Terms?Payment_Terms:"",
        // po_amount : PoAmt ? PoAmt : itemValue.purchasePrice * (itemValue.totalWeight ? itemValue.totalWeight : itemValue.outerSize * itemValue.bagPurchased),
        mrp: Mrp,
        created_by: UserEmail(),
        created_at: `${UpdatedDate()} ${UpdatedTime()}`,
      };


      addedItems.map((itemValue)=>{
        if (
          itemValue.articleCode !== "" ||
          itemValue.itemName !== "" ||
          (supplier != "" && itemValue.uomValue && itemValue.outerSize && itemValue.outerType && itemValue.Source !="undefined")
        ) {
          if (itemValue.outerType !== "") {
            const randomKey = itemValue.articleCode + "-" + Math.floor(Math.random() * (999 - 100 + 1) + 100); //itemKey
            let items= {
                gst_charges_per : itemValue.GstCharges?itemValue.GstCharges:"",
                apmc_charges_per:itemValue.Apmc?itemValue.Apmc:"",
                category: itemValue.category?itemValue.category:"",
                hsn_code:itemValue.hsn?itemValue.hsn:"",
                article_code: itemValue.articleCode,
                base_price: itemValue.basePrice,
                purchase_price: itemValue.purchasePrice ? itemValue.purchasePrice : itemValue.basePrice,
                uom: itemValue.uomValue ? itemValue.uomValue :"",
                item_name: itemValue.itemName,
                outer_type: itemValue.outerType,
                outer_size: itemValue.outerSize,
                replacement_brand: itemValue.replacementBrand ? itemValue.replacementBrand : "",
                bag_purchased: itemValue.bagPurchased,
                total_weight: itemValue.totalWeight ? itemValue.totalWeight : itemValue.outerSize * itemValue.bagPurchased,
                source_type : itemValue.Source,
                transport_charges: itemValue.TransCharges,
                cash_discount : itemValue.CashDisc,
                transport : itemValue.Transport,
                po_amount : PoAmt ? PoAmt : itemValue.purchasePrice * (itemValue.totalWeight ? itemValue.totalWeight : itemValue.outerSize * itemValue.bagPurchased),
                mrp: Mrp,
                key:randomKey
            }
            
            console.log("data to save", jsonObj);
            
            const updatedJsonObj = {...jsonObj,...items};
            PurchaseDetailsData.child(
              `${purchaseDateValue.split("-")[2]}/${
                purchaseDateValue.split("-")[1]
              }/${purchaseDateValue}`
            )
              .child(randomKey)
              .update(updatedJsonObj);

            // jsonObj.key = randomKey;
            jsonObj.updated_at = `${UpdatedDate()} ${UpdatedTime()}`;
            finalObj.push(items);
  
            // const url =
            //   "/api/superzop/commercial/purchasedetails/createpurchasedetailsrecord";
  
            // baseURL
            //   .post(url, jsonObj)
            //   .then((res) => {
            //     console.log("updatePurchaseDetails ", res);
            //     notifyUser(true);
            //     setButtonStatus(false);
            //     resetValues();
            //   })
            //   .catch((error) => {
            //     console.error("An Error has occurred ", error);
            //     notifyUser(false);
            //     setButtonStatus(false);
            //   });
  
          } else {
            return toast("Select Outer Type", { type: toast.TYPE.ERROR });
          }
        } else {
          return toast("Article Code / Outer Size / UOM / Supplier can't be empty", {
            type: toast.TYPE.ERROR,
          });
        }
      });
      jsonObj['items'] = finalObj;
      
      const articleCodeSet = new Set();

      for (const item of finalObj) {
        const articleCode = item.article_code;

        if (articleCodeSet.has(articleCode)) {
          setButtonStatus(false);
          return toast(`Duplicate Item Id found: ${articleCode}`, { type: toast.TYPE.ERROR });
        }

        articleCodeSet.add(articleCode);
      }
      
      const url = "/api/superzop/commercial/purchasedetails/createPurchaseDetailsRecordMySqlForItems";
  
            baseURL
              .post(url, jsonObj)
              .then((res) => {
                console.log("updatePurchaseDetails ", res);
                notifyUser(true);
                setButtonStatus(false);
                resetValues();
                setValidated(false);
              })
              .catch((error) => {
                console.error("An Error has occurred ", error);
                notifyUser(false);
                setButtonStatus(false);
              });
    }

    setValidated(true);
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const openListPurchase = () => {
    window.open("#/listpurchase");
  };

  return (
    <>
      <NavBar />
      
      <Form noValidate validated={validated} onSubmit={PushPurchaseDetails}>
        <Navbar
          className="bg-light mr-auto justify-content-between"
          style={{ paddingTop: 100 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Badge variant="dark">Create Purchase Details </Badge>
          </div>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              openListPurchase();
            }}
          >
            List Purchase Details
          </Button>
        </Navbar>
        {articleInfo == "" ? (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ display: "flex", justifyContent: "center" }}
            timeout={10000} //20 secs
          />
        ) : (
          <>

{/* <Table size="sm" responsive>
            <tbody>
              <tr>
                <td>Purchase Date</td>
                <td> */}
                <SimpleGrid cols={1} spacing={10}>
                <Col span={12} style={{zIndex: 12}}> 
                <Form.Label>Purchase Date *</Form.Label>
                <DatePicker
                    ref={datepickerRef}
                    required
                    selected={dateToModify}
                    onChange={(val) => {
                      setDateToModify(val);
                      setPurchasedDate(
                        `${
                          val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );
                      // console.log("DEBUG check this", val);
                      fetchPoList(val);
                    }}
                    minDate={new Date("2017/01/01")}
                    maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </Col>
                </SimpleGrid>
                <br />
                {/* <SimpleGrid cols={1} spacing={10} >
                  <Col span={12} style={{zIndex: 12}}>
                    <Form.Group>
                    <table style={{ width: '100%' }}>
                      <tbody>
                      <tr>
                        <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                        <input
                          style={{ marginLeft: "10px", display:"flex" }}
                          type="checkbox"
                          id="existing_po_checkbox"
                          name="existing_po"
                          checked={showExistingPoChecked}
                          onChange={(event) => {
                            setShowExistingPoChecked(event.target.checked);
                            setExistingPoSelection(null);
                            setIsSupplierSelectionDisabled(false);
                            setSupplierSelection(null);
                            setSupplier("");
                          }}
                        />
                          <label style={{ marginLeft: "10px", display:"flex", marginBottom: 0 }}> {" "}Select an Existing PO Number </label>
                          </div>
                          {showExistingPoChecked ? (
                            <Select
                              style={{ width: "100%"}}
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={existingPoNumbersToDisp.length === 0}
                              isClearable={true}
                              isSearchable={true}
                              name="existing_po_select"
                              options={existingPoNumbersToDisp}
                              value={existingPoSelection}
                              onChange={(val) => {
                                if (val) {
                                  setExistingPoSelection(val);
                                  if (
                                    exisitingPoNumbersAndSuppliers[val.value]
                                      .supplier_name &&
                                    exisitingPoNumbersAndSuppliers[val.value]
                                      .supplier_name !== ""
                                  ) {
                                    const supplier_name =
                                      exisitingPoNumbersAndSuppliers[val.value]
                                        .supplier_name;
                                    setSupplierSelection({
                                      value: supplier_name,
                                      label: supplier_name,
                                    });
                                    setSupplier(supplier_name);
                                    setIsSupplierSelectionDisabled(true);

                                    if (
                                      exisitingPoNumbersAndSuppliers[val.value]
                                        .invoice_number
                                    ) {
                                      setInvoiceNumber(
                                        exisitingPoNumbersAndSuppliers[val.value]
                                          .invoice_number
                                      );
                                      setIsInvoiceFieldDisabled(true);
                                    } else {
                                      setInvoiceNumber("");
                                      setIsInvoiceFieldDisabled(false);
                                    }
                                  } else {
                                    setSupplierSelection(null);
                                    setSupplier("");
                                    setInvoiceNumber("");
                                    setIsSupplierSelectionDisabled(false);
                                    setIsInvoiceFieldDisabled(false);
                                  }
                                } else {
                                  setSupplierSelection(null);
                                  setSupplier("");
                                  setInvoiceNumber("");
                                  setExistingPoSelection({});
                                  setIsSupplierSelectionDisabled(false);
                                  setIsInvoiceFieldDisabled(false);
                                }
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        </tr>
                      </tbody>
                    </table>  
                    </Form.Group>
                </Col>
                </SimpleGrid> */}
                
                {/* </td>
              </tr>
              </tbody>
              </Table> */}
          {addedItems.map((addedItem, index) => (
                  <div key={index}>

                    <SimpleGrid cols={1} spacing={10} >
                      <Col span={12} style={{zIndex: 10}}>
                        <Form.Group>
                          {/* <InputGroup> */}
                            {/* <InputGroupAddon addonType="append"> */}
                            <table style={{ width: '100%' }}>
                            <tbody>
                              <tr><td><label style={{display:"flex"}}>Item Name * </label>
                              
                            {/* </InputGroupAddon> */}
                            {addedItem.articleCode == '' || addedItem.disableList == false ? (
                              // <Select
                              //   required
                              //   options={articleOptions}
                              //   value={addedItem.itemSelection}
                              //   onChange={(val) => {
                              //     const updatedItems = [...addedItems];
                              //     updatedItems[index] = {
                              //       ...addedItem,
                              //       itemSelection: val,
                              //       itemName: val.value,
                              //     };
                              //     setAddedItems(updatedItems);
                              //     setItemSelection(val);
                              //     setItemName(val.value);
                              //     let itemName = val.value;
                              //     let itemCode = null;
                              //     insertDomData(itemName, itemCode, updatedItems, index);
                              //   }}
                              //   style={{ width: "10px" }}
                              // />
                              <>
                              {/* <Input
                                value={addedItems[index].itemSelection ? addedItems[index].itemSelection.label : ''}
                                readOnly
                                required
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                              /> */}
                              {/* <InputGroupAddon addonType="append"> */}
                              {/* <ButtonDropdown isOpen={isOpenItemNameList[index]} toggle={()=>toggleDropdownItemName(index)} direction="left"> */}
                                {/* <DropdownToggle caret >
                                  Select
                                </DropdownToggle>
                                <DropdownMenu>
                                  {articleOptions && articleOptions.map((option) => (
                                    <DropdownItem key={option.value} onClick={() => handleDropdownItemClickItemName(option,addedItems,index,addedItem)}>
                                      {option.label}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu> */}
                                
                                {/* <td> */}
                                <Select
                                required
                                options={articleOptions}
                                value={addedItem.itemSelection}
                                // onChange={(val) => {
                                //   const updatedItems = [...addedItems];
                                //   updatedItems[index] = {
                                //     ...addedItem,
                                //     itemSelection: val,
                                //     itemName: val.value,
                                //   };
                                //   setAddedItems(updatedItems);
                                //   setItemSelection(val);
                                //   setItemName(val.value);
                                //   let itemName = val.value;
                                //   let itemCode = null;
                                //   insertDomData(itemName, itemCode, updatedItems, index);
                                // }}
                                onChange={(val) => handleDropdownItemClickItemName(val,addedItems,index,addedItem)}
                                style={{ width: "100%"}}
                              />
                              
                              
                              {/* </ButtonDropdown> */}
                            {/* </InputGroupAddon> */}
                            </>
                            ) : (
                              <Input value={addedItem.itemName} readOnly />
                              // <Form.Control value={addedItem.itemName} readOnly />
                            )}
                            {/* </InputGroupAddon> */}
                              </td> 
                              </tr>
                            </tbody>
                            </table>
                          {/* </InputGroup> */}
                        </Form.Group>
                      </Col>
                    </SimpleGrid>
                    
                    <SimpleGrid cols={3} spacing={5}>
                      <Col span={5}>
                          <Form.Group> 
                            <InputGroup>
                            <InputGroupAddon addonType="prepend">Source *</InputGroupAddon>
                                    <Input
                                      value={addedItems[index].Source?.toUpperCase() ? addedItems[index].Source.toUpperCase() : ""}
                                      readOnly
                                    />
                                    <InputGroupAddon addonType="append">
                                      <ButtonDropdown isOpen={isOpenSourceList[index]} toggle={()=>toggleDropdownSource(index)} style={{ zIndex: 1 }}>
                                        <DropdownToggle caret>
                                          Select
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem onClick={() => handleDropdownItemClickSource('MILL',addedItems,index,addedItem)}>MILL</DropdownItem>
                                          <DropdownItem onClick={() => handleDropdownItemClickSource('LOCAL PICK',addedItems,index,addedItem)}>LOCAL PICK</DropdownItem>
                                          <DropdownItem onClick={() => handleDropdownItemClickSource('DC DELIVERED',addedItems,index,addedItem)}>DC DELIVERED</DropdownItem>
                                        </DropdownMenu>
                                      </ButtonDropdown>
                                    </InputGroupAddon>
                            </InputGroup>
                            <FormFeedback>*Required</FormFeedback>
                          </Form.Group>
                      </Col>
                      <Col span ={5}>
                      {addedItem.Source === 'MILL' ? (
                            <>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend">Transport *</InputGroupAddon>
                          <Input value={addedItems[index].Transport?.toUpperCase()  ? addedItems[index].Transport.toUpperCase() : ""} readOnly />
                          <InputGroupAddon addonType="append">
                            <ButtonDropdown isOpen={isOpenTransportList[index]} toggle={()=>toggleDropdownTransport(index)} style={{ zIndex: 1 }}>
                              <DropdownToggle caret>  
                                Select
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => handleDropdownItemClickTransport('INCLUDED',addedItems,index,addedItem)}>INCLUDED</DropdownItem>
                                <DropdownItem onClick={() => handleDropdownItemClickTransport('EXCLUDED',addedItems,index,addedItem)}>EXCLUDED</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </InputGroupAddon>
                        </InputGroup>
                        </> ) : null}

                      </Col>
                      <Col span = {5}>
                        {addedItem.Source === 'MILL' && addedItem.Transport.toLowerCase() === 'excluded' ? (
                          // <tr><td>Transport Charges</td>
                          // <td><Form.Control value={addedItem.TransCharges} onChange={(e) => {
                          //                     const updatedItems = [...addedItems];
                          //                     updatedItems[index] = {
                          //                       ...addedItem,
                          //                       TransCharges:e.target.value
                          //                     };
                          //                     setAddedItems(updatedItems);
                          //                     setTransCharges(e.target.value)}
                          //                   }/></td></tr>

                          <Form.Group> 
                          <InputGroup>
                              <InputGroupAddon addonType="prepend"> Transport Charges </InputGroupAddon>
                              <Input
                                value={addedItem.TransCharges} onChange={(e) => {
                                      const updatedItems = [...addedItems];
                                      updatedItems[index] = {
                                        ...addedItem,
                                        TransCharges:e.target.value
                                      };
                                      setAddedItems(updatedItems);
                                      setTransCharges(e.target.value)}
                                    }
                              />
                          </InputGroup>     
                          </Form.Group>) : null}
                        </Col>
                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                        
                        <Col span = {5}>
                        {addedItem.Source === 'MILL' ? (
                          // <tr><td>Cash Discount</td>
                          // <td><Form.Control value={addedItem.CashDisc} onChange={(e) => {
                          //                                 const updatedItems = [...addedItems];
                          //                                 updatedItems[index] = {
                          //                                   ...addedItem,
                          //                                   CashDisc:e.target.value
                          //                                 };
                          //                                 setAddedItems(updatedItems);
                          //                                 setCashDisc(e.target.value)}
                          //                                 }/></td></tr>

                          <Form.Group> 
                          <InputGroup>
                              <InputGroupAddon addonType="prepend"> Cash Discount </InputGroupAddon>
                              <Input
                                value={addedItem.CashDisc} onChange={(e) => {
                                      const updatedItems = [...addedItems];
                                      updatedItems[index] = {
                                        ...addedItem,
                                        CashDisc:e.target.value
                                      };
                                      setAddedItems(updatedItems);
                                      setCashDisc(e.target.value)}
                                      }
                              />
                          </InputGroup>     
                          </Form.Group>):null}
                        </Col>
                        <Col span = {5}>
                        <Form.Group> 
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                            Category
                            </InputGroupAddon>
                            <Input
                              value={ addedItem.category}
                              ref={categoryField} 
                              readOnly
                            />
                        </InputGroup>     
                        </Form.Group> 
                          {/* <Form.Label>Category</Form.Label>
                          <Form.Control value={addedItem.category} ref={categoryField} readOnly /> */}
                        </Col>
                        <Col span= {5}>
                        <Form.Group> 
                        <InputGroup>
                        <InputGroupAddon addonType="prepend"> Article Code </InputGroupAddon>
                        {addedItem.itemName == "" || addedItem.disableList ? (
                                <Input
                                  ref={itemNoField}
                                  value={addedItem.articleCode}
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      articleCode: e.target.value,
                                      // itemName: val.value,
                                    };
                                    setAddedItems(updatedItems);
                                    setArticleCode(e.target.value);
                                    let itemCode = e.target.value;
                                    let itemName = null;
                                    insertDomData(itemName, itemCode,updatedItems, index);
                                  }}
                                />
                              ) : (
                                <Input
                                  ref={itemNoField}
                                  value={addedItem.articleCode}
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      articleCode: e.target.value,
                                      // itemName: val.value,
                                    };
                                    setAddedItems(updatedItems);
                                    setArticleCode(e.target.value);
                                    let itemCode = e.target.value;
                                    let itemName = null;
                                    insertDomData(itemName, itemCode,updatedItems,index);
                                  }}
                                  readOnly
                                />
                              )}
                        </InputGroup> 
                        </Form.Group> 
                        </Col>
                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                          <Col >
                            {/* <Form.Label>HSN Code</Form.Label>
                              <Form.Control  value = {addedItem.hsn} readOnly /> */}
                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                HSN Code
                                </InputGroupAddon>
                                <Input
                                  value={ addedItem.hsn}
                                  readOnly
                                />
                            </InputGroup>     
                            </Form.Group>   
                          </Col>
                          <Col >
                          <Form.Group>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend"> Outer Type</InputGroupAddon>
                                  <Input
                                    value={addedItems[index].outerType ? addedItems[index].outerType :''}
                                    readOnly
                                  />
                                  {/* <InputGroupAddon addonType="append">
                                    <ButtonDropdown isOpen={isOpenList[index]} toggle={() => toggleDropdown(index)} style={{ zIndex: 1 }}>
                                      <DropdownToggle caret>
                                        {/* You can customize the button appearance here */}
                                        {/* Select */}
                                      {/* </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => handleDropdownItemClick('SMALL PACK',addedItems,index,addedItem)}>SMALL PACK</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('GONI',addedItems,index,addedItem)}>GONI</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('BAG OF PACKS',addedItems,index,addedItem)}>BAG OF PACKS</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('HIGH VALUE OF LOOSE',addedItems,index,addedItem)}>HIGH VALUE OF LOOSE</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('FMCG',addedItems,index,addedItem)}>FMCG</DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </InputGroupAddon>  */}
                          </InputGroup>
                          </Form.Group>
                          </Col>
                          <Col >
                          {/* <Form.Label>Outer Size(Kg/Pc)</Form.Label>
                              <Form.Control
                                readOnly
                                value={addedItem.outerSize ? addedItem.outerSize + " " + addedItem.uomValue : ""}
                              />
                              <Form.Control.Feedback type="invalid">
                                *Required
                              </Form.Control.Feedback> */}

                              <Form.Group> 
                              <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                  Outer Size(Kg/Pc)
                                  </InputGroupAddon>
                                  <Input
                                    value={addedItem.outerSize ? addedItem.outerSize + " " + addedItem.uomValue : ""}
                                    readOnly
                                  />
                                  <FormFeedback> *Required </FormFeedback>
                              </InputGroup>     
                              </Form.Group>
                          </Col>

                    </SimpleGrid>

                    <SimpleGrid cols = {3} spacing={5}>
                    <Col>
                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Base Price
                                </InputGroupAddon>
                                <Input type = "number"
                                  value={addedItem.basePrice ? addedItem.basePrice : ""}
                                  readOnly
                                />
                            </InputGroup>     
                            </Form.Group>
                      </Col>
                      <Col>
                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Purchase Price
                                </InputGroupAddon>
                                <Input type = "number"
                                  required
                                  step={"any"}
                                  value={addedItem.purchasePrice}
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      purchasePrice: e.target.value,
                                      PoAmt:''
                                    };
                                    setAddedItems(updatedItems);
                                    setPurchasePrice(e.target.value)
                                    setPoAmt("")}}
                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>     
                            </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Replacement Brand
                                </InputGroupAddon>
                                <Input value={addedItem.replacementBrand ? addedItem.replacementBrand : ""}
                                  required
                                  onChange={(e) => {
                                  const updatedItems = [...addedItems];
                                  updatedItems[index] = {
                                    ...addedItem,
                                    replacementBrand: e.target.value
                                  };
                                  setAddedItems(updatedItems);
                                  setReplacementBrand(e.target.value);
                                  }}
                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>     
                              </Form.Group>
                        </Col>
                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                      <Col span={5}>
                        {/* <Form.Label>No Of Bags Purchased *</Form.Label>
                        <Form.Control
                              type="number"
                              value={addedItem.bagPurchased}
                              required
                              onChange={(e) => {
                                const updatedItems = [...addedItems];
                                updatedItems[index] = {
                                  ...addedItem,
                                  bagPurchased:e.target.value
                                };
                                setAddedItems(updatedItems);
                                setBagPurchased(e.target.value);
                                setTotalWeight("");

                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              *Required
                            </Form.Control.Feedback> */}

                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                No Of Bags Purchased *
                                </InputGroupAddon>
                                <Input type="number"
                                  value={addedItem.bagPurchased}
                                  required
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      bagPurchased:e.target.value,
                                      totalWeight:addedItem.outerSize * e.target.value
                                    };
                                    setAddedItems(updatedItems);
                                    setBagPurchased(e.target.value);
                                    setTotalWeight(addedItem.outerSize * e.target.value);
                                  }}
                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>     
                            </Form.Group>
                      </Col>

                      <Col span={5}>
                      {/* <Form.Label>Total Weight Purchased *</Form.Label>
                        <Form.Control
                              ref={totalWtPurchasedRef}
                              value={
                                addedItem.totalWeight
                                  ? addedItem.totalWeight
                                  : addedItem.bagPurchased && addedItem.outerSize
                                  ? addedItem.outerSize * addedItem.bagPurchased
                                  : ""
                              }
                              onChange={(e) => {
                                const updatedItems = [...addedItems];
                                updatedItems[index] = {
                                  ...addedItem,
                                  totalWeight:e.target.value
                                };
                                setAddedItems(updatedItems);
                                setTotalWeight(e.target.value);
                                setPoAmt(Number(e.target.value) *  Number(purchasePrice))
                              }}
                        /> */}
                        <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Total Weight Purchased *
                                </InputGroupAddon>
                                <Input
                                  value={
                                    addedItem.totalWeight
                                      ? addedItem.totalWeight
                                      : addedItem.bagPurchased && addedItem.outerSize
                                      ? addedItem.outerSize * addedItem.bagPurchased
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      totalWeight:e.target.value
                                    };
                                    setAddedItems(updatedItems);
                                    setTotalWeight(e.target.value);
                                    setPoAmt(Number(e.target.value) *  Number(purchasePrice))
                                  }}
                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>
                            </Form.Group>
                      </Col>

                    </SimpleGrid>
                      <br />
                      <br />
                  </div>
          ))}
          
          <Button variant="primary" onClick={handleAddItem}>
            Add Item
          </Button>
          <br />
          <br />
          
          <Table size="sm" responsive>
          <tbody>                    
          <tr>
                <td>Invoice Date</td>
                <td>
                  <DatePicker
                    required
                    selected={dateChange}
                    minDate={dateToModify}
                    // selected should be greater than or equal to po date
                    onChange={(val) => {
                      setdateChange(val);
                      setinvoiceDate(
                        `${
                            val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );                    }}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </td>
              </tr>
              <tr>
                <td>Invoice Number</td>
                <td>
                  <Form.Control
                    step={"any"}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    value={invoiceNumber}
                  />
                </td>
              </tr>
              <tr>
                <td>Payment Terms (in Days)</td>
                <td>
                  <Form.Control required type="number"  value={Payment_Terms} onChange={(e) => {
                    setPayment_Terms(e.target.value)
                  }}/>
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Delivery Period (in Days)</td>
                <td>
                  <Form.Control type="number" required value={Delivery} onChange = {(e) =>{setDelivery(e.target.value)} } />
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Supplier Name </td>
                <td>
                  {/* <Creatable required options={suppliersList} ref={supplierRef} value={supplierSelection} onChange={(val) => { setSupplierSelection(val); setSupplier(val.value) }} style={{ width: "100px" }} /> */}
                  <Select
                    required
                    options={suppliersList}
                    ref={supplierRef}
                    value={supplierSelection}
                    onChange={(val) => {
                      setSupplierSelection(val);
                      setSupplier(val.value);
                      setSupplierID(val.key);
                      setSupplierEmail(val.email);
                      setAddress(val.address);
                      setStreet(val.street)
                      setCity(val.city)
                      setPin(val.pin)
                      setState(val.state)
                      setPhone(val.phone);
                      setGstin(val.gstin);
                      
                      console.log(val);
                    }}
                    style={{ width: "100px" }}
                    isDisabled={isSupplierSelectionDisabled}
                  />
                </td>
                <td>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                      window.open("#/addsupplier");
                    }}
                  >
                    Add Supplier
                  </Button>
                </td>
              </tr>
              {/* Supplier ID from Supplier DB */}
              <tr>
                <td>Supplier ID </td>
                <td>
                  <Form.Control value = {SupplierID} readOnly />
                </td>
              </tr>
              {/* Supplier Email from Supplier DB */}
              <tr>
                <td>Supplier Email</td>
                <td>
                  <Form.Control value = {SupplierEmail}   onChange = {(e) => setSupplierEmail(e.target.value) }/>
                </td>
              </tr>
              <tr>
                <td>PO Amount </td>
                <td>
                  <Form.Control value ={PoAmt ? PoAmt.toFixed(2) : Number(purchasePrice * (totalWeight ? totalWeight : outerSize * bagPurchased)).toFixed(2)} readOnly />
                </td>
              </tr>
              </tbody>
          </Table>


          <center>
              <Button variant="success" type="submit" disabled={buttonStatus}>
                Add Purchase Details{" "}
              </Button>
            </center>
          </>
        )}
      </Form>
    </>
  );
}

export default Add_Purchase;
