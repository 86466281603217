import { Grid, NumberInput, Radio, RadioGroup, TextInput } from "@mantine/core";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const OfferRange = React.memo(
  ({
    index,
    length,
    offerDiscountType,
    productFinalPriceRef,
    productFinalPrice,
    userSelectedProduct,
    offerPercentage,
    offerPrice,
    maxPurQty,
    minPurQty,
    isMinPurQtyDisabled,
    isMaxPurQtyDisabled,
    handleOfferRangeStackValueModified,
    handleAddNewOfferRange,
    handleRemoveOfferRange,
    offerTypeSelection,
  }) => {
    return (
      <div>
        <Grid
          justify="space-between"
          style={{
            marginLeft: "40px",
            marginTop: "10px",
            marginRight: "40px",
          }}
        >
          <Grid.Col span={2}>
            <Form.Group
              controlId="minimumPurchaseQuantity"
              style={{
                display: "block",
                width: "250px",
                textAlign: "center",
                margin: "auto",
                paddingBottom: "40px",
              }}
            >
              <NumberInput
                disabled={isMinPurQtyDisabled}
                hideControls
                description="Minimum quantity of products that must be purchased for this offer to apply"
                name="minimumPurchaseQuantity"
                label="Minimum purchase quantity"
                value={minPurQty}
                onChange={(val) => {
                  handleOfferRangeStackValueModified(index, "minPurQty", val);
                  // setMinPurQty(val);
                }}
                required
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Form.Group
              controlId="maximumPurchaseQuantity"
              style={{
                display: "block",
                width: "250px",
                textAlign: "center",
                margin: "auto",
                paddingBottom: "40px",
              }}
            >
              <TextInput
                disabled={isMaxPurQtyDisabled}
                hideControls
                required
                value={maxPurQty}
                onChange={(event) => {
                  const val = event.currentTarget.value;
                  if (/^[0-9]+$/.test(val) || val === "") {
                    handleOfferRangeStackValueModified(index, "maxPurQty", val);
                  }
                }}
                description="Maximum quantity of products that must be purchased for this offer to apply"
                name="maximumPurchaseQuantity"
                label="Maximum purchase quantity"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Row>
              <Col>
                <Row
                  style={{
                    margin: "auto",
                    paddingBottom: "40px",
                  }}
                >
                  <Col>
                    <Radio.Group
                      value={offerDiscountType}
                      styles={{
                        radioWrapper: { alignSelf: "center" },
                      }}
                      onChange={(value) => {
                        handleOfferRangeStackValueModified(
                          index,
                          "offerDiscountType",
                          value
                        );

                        handleOfferRangeStackValueModified(
                          index,
                          "offerPrice",
                          0
                        );
                        handleOfferRangeStackValueModified(
                          index,
                          "offerPercentage",
                          0
                        );
                        // setOfferDiscountType(value);
                        // setOfferPrice(0);
                        // setOfferPercentage(0);
                      }}
                      label="Select offer % or price"
                      description=""
                      required
                    >
                      <Radio value="offerPrice" label="Offer Price" />
                      <Radio value="offerPercentage" label="Percentage off" />
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid.Col>
          <Grid.Col span={2}>
            {offerDiscountType === "offerPrice" ? (
              <Form.Group
                controlId="offerDiscountPrice"
                style={{
                  display: "block",
                  width: "250px",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                <NumberInput
                  value={offerPrice}
                  onChange={(val) => {
                    handleOfferRangeStackValueModified(
                      index,
                      "offerPrice",
                      val
                    );
                    // setOfferPrice(val);
                    //
                  }}
                  hideControls
                  precision={2}
                  name="offerDiscountPrice"
                  required
                  disabled={!userSelectedProduct.dealer_price}
                  label="Offer price"
                  description="New price of product after applying offer"
                />
              </Form.Group>
            ) : (
              <Form.Group
                controlId="offerDiscountPercentage"
                style={{
                  display: "block",
                  width: "250px",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                <NumberInput
                  name="offerDiscountPercentage"
                  value={offerPercentage}
                  onChange={(val) => {
                    handleOfferRangeStackValueModified(
                      index,
                      "offerPercentage",
                      val
                    );
                    // setOfferPercentage(val);
                    // setProductFinalPrice(
                    //   Math.round(
                    //     Number(userSelectedProduct.dealer_price) -
                    //       Number(userSelectedProduct.dealer_price) * (val / 100)
                    //   )
                    // );
                  }}
                  required
                  label="Offer Percentage"
                  disabled={!userSelectedProduct.dealer_price}
                  description="Offer percentage to apply on the product"
                  hideControls
                  precision={2}
                  max={99}
                  min={0.1}
                />
              </Form.Group>
            )}
          </Grid.Col>
          {offerDiscountType === "offerPercentage" && (
            <Grid.Col span={2}>
              <Form.Group
                controlId="productFinalPrice"
                style={{
                  display: "block",
                  width: "250px",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                <NumberInput
                  ref={productFinalPriceRef}
                  value={productFinalPrice}
                  disabled
                  precision={2}
                  hideControls
                  description="Final price of product after applying offer"
                  name="minimumPurchaseQuantity"
                  label="Final price of product"
                />
              </Form.Group>
            </Grid.Col>
          )}
        </Grid>
        <div
          style={{
            textAlign: "right",
            marginRight: "30px",
            marginTop: "-20px",
            fontSize: "13px",
          }}
        >
          {offerTypeSelection === "SLP" && (
            <>
              {index === length - 1 && length < 5 ? (
                <a
                  onClick={(event) => {
                    handleAddNewOfferRange(event);
                  }}
                  href="#"
                >
                  + Add another range
                </a>
              ) : (
                ""
              )}
              {index !== 0 && index === length - 1 ? (
                <a
                  style={{ marginLeft: "20px" }}
                  onClick={(event) => {
                    handleRemoveOfferRange(event);
                  }}
                  href="#"
                >
                  Remove this range
                </a>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    const pProps = { ...prevProps };
    const nProps = { ...nextProps };
    pProps.productFinalPriceRef = null;
    nProps.productFinalPriceRef = null;
    return JSON.stringify(pProps) === JSON.stringify(nProps);
  }
);

export { OfferRange };
