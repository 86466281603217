import React from 'react';
import { ProductContainer, NoProdContainer } from './wrapper';
import ProductDataCard from './ProductDataCard';


const ProductData = ({productDetails}) => {
    const NoProdFoundComp = () => {
        return (
            <NoProdContainer>
                <span>No Product to show.</span>
            </NoProdContainer>
        );
    };

    const ShowComp = () => {
      let cards=[];
      if(Object.keys(productDetails).length==0){
        cards=[];
      }else{
        cards=Object.keys(productDetails).map((productkey,index) => {
          return (<ProductDataCard key={index} productkey={productkey} productCardDetails={productDetails[productkey]} />);
        });
      }

      return (<><div>{cards}</div></>)
    };

    return (
      <ProductContainer>
          {productDetails ? <ShowComp /> : <NoProdFoundComp />}
      </ProductContainer>
    );
};

export default ProductData;
