import React, { useEffect, useState } from "react";
import Navbar from "../../Dashboard/Navbar/Dashboard_Navabar";
import database from '../../service/firebaseConfig';
import firebase from "../../service/firebaseConfig";
import {
    Box,
    Button,
    Container,
    Flex,
    Image,
    Stack,
    Table,
    Title,
    MultiSelect
  } from "@mantine/core";
  import { FaPencilAlt,FaCheck,FaRegTrashAlt,FaInfoCircle} from 'react-icons/fa';
  import { DatePicker } from "@mantine/dates";
  import moment from "moment";
  import axios from "axios";
  import { DELIVERY_BASE_URL } from "../../service/servicesConfig";
  import { CreateWorkOrderDrawer } from "./WorkOrderDrawer";
  import { ViewItemsModal } from "./ItemsView";
  import { DeleteModal } from "./DeleteItem";
  import { StatusModal } from "./editStatus";
  import { toast } from "react-toastify";
import { PaymentModal } from "./editPayment";
import ExportToExcel from "./export/ExportToExcel";
import {validateOPSUser, validatePurchaseExportUser,getUserType } from "../../service/credentials";
import { LOGGED_USER_TYPE,LOGGED_USER_EMAIL } from "../../constants/allConstants";


const WorkOrder = () =>{

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [IsWorkOrderDrawerOpened,setIsWorkOrderDrawerOpened] = useState(false);
  const [workOrderData,setWorkOrderData] = useState([]);
  const [expandedOrderIndex, setExpandedOrderIndex] = useState(null);
  const [isAdminUser,setIsAdminUser] = useState(false);
 
  const [isFinanceUser,setIsFinanceUser] = useState(false);
  const [userType,setUserType] = useState(sessionStorage.getItem(LOGGED_USER_TYPE));
  const [userEmail,setUserEmail] = useState(sessionStorage.getItem(LOGGED_USER_EMAIL));
  // view modal
  const [IsViewModalOpen,setIsViewModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [IdForDeletion, setIdForDeletion] = useState(false);
  

  // edit modals
  const [isStatusModalOpened,setIsStatusModalOpened] = useState(false);
  const [editStatusID,setEditStatusID] = useState("");
  const [isPaymentModalOpened,setIsPaymentModalOpened] = useState(false);
  const [editPaymentID,setEditPaymentID] = useState("");

  // status 
  const statusOptions = [
                        { value: "Approved", label: "Approved" },
                        { value: "open", label: "Submitted" },
                        { value: "Processed", label: "Paid" },
                        { value: "Rejected", label: "Rejected" }]
  
  const [selectedStatus,setSelectedStatus] = useState(["open","Approved"]);

  const approverData = [
    { value: 'amresh@superzop.com', label: 'Amresh' },
    { value: 'darshan@superzop.com', label: 'Darshan' },
    { value: 'prithwi@superzop.com', label: 'Prithwi' },
    { value: 'raghu@superzop.com', label: 'Raghu' }
  ];

  const [selectedApprovers, setSelectedApprovers] = useState([]);


  toast.configure({
    autoClose: 4000, 
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

 

  useEffect(() => {
    if (!startDate || !endDate) return;
    fetchWorkOrderListingData();
    let AdminUser = validateOPSUser(sessionStorage.getItem(LOGGED_USER_EMAIL));
    setIsAdminUser(AdminUser);
    let financeUser = validatePurchaseExportUser(sessionStorage.getItem(LOGGED_USER_EMAIL));
    setIsFinanceUser(financeUser);

    
  }, [startDate,endDate]); 

function fetchWorkOrderListingData(){
  let start_date = moment(startDate).format("YYYY-MM-DD");
  let end_date = moment(endDate).format("YYYY-MM-DD");

  axios.get(`${DELIVERY_BASE_URL}/api/superzop/workorder/getWorkOrders?start_date=${start_date}&end_date=${end_date}`)
      .then((response) => {
       
        setWorkOrderData(response.data.workOrderDetails);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
}


// row color indicators
const getRowColor = (status) => {
  if (status === 'Approved') {
    return 'rgba(255, 165, 0, 0.4)'; 
  } else if (status === 'Processed') {
    return 'rgba(0, 128, 0, 0.4)'; 
  } else if (status === 'Rejected') {
    return 'rgba(255, 0, 0, 0.4)'; 
  }
  return 'rgba(0, 0, 0, 0)'; 
};


const handleApproverChange = (value) => {
  setSelectedApprovers(value);
};


const calculateDaysOverdue = (dueDate) => {
  
  const dueDateParts = dueDate.split('-').map(Number);

  const due = new Date(dueDateParts[0], dueDateParts[1] - 1, dueDateParts[2]);

  const today = new Date();

  const timeDifference = due - today;
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference == 0) {
    return "Today";
  } else if (daysDifference < 0) {
    return Math.abs(daysDifference).toString();
  } else {
    return ""; 
  }
};


function renderPaymentDetails(paymentDetails, paymentMode, paymentDate) {
  let details = paymentDetails || '-';

  if (paymentMode) {
    details += `, ${paymentMode}`;
    
  }
  
  if (paymentDate) {
    details += `, ${paymentDate}`;
    
  }
  

  return details;
}


    return (
        <>
         <Navbar />
         <Box style={{ margin: 0 }}>
        <Stack align="stretch" justify="center">
          <Box style={{ paddingTop: "6rem", paddingBottom: "3rem" }}>
            <Flex
              style={{ marginLeft: "1rem" }}
              gap="md"
              justify="flex-start"
              align="end"
              direction="row"
              wrap="nowrap"
            >
              <Title mt={30} order={1}>
                Work Order
              </Title>
              <Box style={{ display: "inline-block" }}>
                <DatePicker
                  placeholder="Pick date"
                  label="Start date"
                  value={startDate}
                  maxDate={new Date()}
                  onChange={setStartDate}
                />
              </Box>
              <DatePicker
                placeholder="Pick date"
                label="End date"
                value={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={setEndDate}
              />
              <Flex direction="row" align={"end"}>
                <Button
                  variant="gradient"
                  gradient={{ from: "teal", to: "lime", deg: 105 }}
                  style={{ alignSelf: "flex-end", marginRight: "1rem", display: isAdminUser ? 'block' : 'none'  }}

                  onClick={() => setIsWorkOrderDrawerOpened(true)}
                >
                  Create Work Order
                </Button>
                <MultiSelect
                 
                  label="Status Options"
                  placeholder="Select"
                  data={statusOptions}
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  searchable
                  maxDropdownHeight={400}
                  clearable
                />

            <MultiSelect
              label="Approver Options"
              placeholder="Select"
              data={approverData}
              value={selectedApprovers}
              onChange={handleApproverChange}
              searchable
              maxDropdownHeight={400}
            clearable
            />
                 {/* <ExportToExcel
                  sheet1={workOrderData}
                  filename={"work_order"}
                  sheet1Name={"Sheet 1"}
                /> */}
          
              </Flex>
            </Flex>
          </Box>
          <Box>
            {" "}
            <Table striped fontSize={"xs"}>
  <thead>
    <tr>
      <th style={{ textAlign: "center" }}>Work order ID</th>
      <th style={{ textAlign: "center" }}>Creation Date</th>
      <th style={{ textAlign: "center" }}>Type</th>
      <th style={{ textAlign: "center" }}>Sub Type</th>
      <th style={{ textAlign: "center" }}>Description</th>
      <th style={{ textAlign: "center" }}>Total Amount</th>
      <th style={{ textAlign: "center" }}>Status</th>
      <th style={{ textAlign: "center" }}>Created By</th>
      <th style={{ textAlign: "center" }}>Approver</th>
      <th style={{ textAlign: "center" }}>Amount Paid</th>
      <th style={{ textAlign: "center" }}>Payment Details</th>
      <th style={{ textAlign: "center" }}>Due Days</th>
      <th style={{ textAlign: "center" }}>Due Date</th>
      <th style={{ textAlign: "center" }}>View</th>
      <th style={{ textAlign: "center",display: userType == "admin" ? 'block' : 'none'  }}>Delete</th>
    </tr>
  </thead>
  <tbody>
    {workOrderData.map((order, index) => {
       if (
        (selectedStatus.length === 0 ||  
        selectedStatus.includes(order.status) ) &&
        (selectedApprovers.length === 0 || selectedApprovers.includes(order.approved_by))
      ) {
        const rowColor = getRowColor(order.status);
        const rowStyle = {
          backgroundColor: rowColor,
        };
        const daysOverdue = calculateDaysOverdue(order.due_date);
        const paymentDetails = renderPaymentDetails(order.payment_details, order.payment_mode, order.payment_date);
      
        return (
      <React.Fragment key={index}>
        <tr style={rowStyle}>
          <td>{order.work_order_id || '-'}</td>
          <td>{order.created_at || '-'}</td>
          <td>{order.type || '-'}</td>
          <td>{order.sub_type || '-'}</td>
          <td>{order.description || '-'}</td>
          <td>{order.total_amt || '-'}</td>
          <td>
             {order.status === "Processed" ? (
                "Paid"
                ) : order.status === "Rejected" ? (
               
                  "Rejected"
                ) : (
               
                <>
                  {order.status.toLowerCase() === "open" ? "Submitted" : (order.status || '-')} 
                  {(userEmail === order.approved_by) && ( //approver, usertype check
        <FaPencilAlt
          style={{ marginLeft:"2rem", cursor: "pointer", display: isFinanceUser ? 'block' : 'none' }}
          onClick={() => {
            setIsStatusModalOpened(true);
            setEditStatusID(order.work_order_id);
            
          }}
        />
      )}
               </>
              )}
        </td>

          {/* <td>{order.created_by || '-'}</td> */}
          <td>{order.created_by && order.created_by.includes('@') ? order.created_by.split('@')[0].charAt(0).toUpperCase()+ order.created_by.split('@')[0].slice(1) : order.created_by || '-'}</td>
          {/* <td>{order.approved_by || '-'}</td> */}
          <td>{order.approved_by && order.approved_by.includes('@') ? order.approved_by.split('@')[0].charAt(0).toUpperCase()+ order.approved_by.split('@')[0].slice(1) : order.approved_by || '-'}</td>
          <td>{order.paid_amt || '-'}</td>
          <td>
          {(order.status === "Approved") ? (
            <div>
              {(userType.toLowerCase() === "admin" || userType.toLowerCase() === "finance" || userType.toLowerCase() === "financehead") && ( // usertyep check
        <FaPencilAlt
          style={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={() => { 
            setIsPaymentModalOpened(true); 
            setEditPaymentID(order.work_order_id); 
            
          }}
        />
      )}
          </div>
          ) : (
            // renderPaymentDetails(order.payment_details, order.payment_mode, order.payment_date)
            paymentDetails

            )}
        </td>

        <td>{daysOverdue}</td>
        <td>{order.due_date || "-"}</td>


          <td > <Button color="orange" variant="light"
          onClick={() =>{
            setIsViewModalOpen(true);
            setSelectedOrder(order);
          }}
          >
                    <FaInfoCircle></FaInfoCircle>
                </Button>
                </td>
                <td style={{ display: userType === "admin" ? 'block' : 'none' }}>
  {order.status.toLowerCase() == 'open' && (
    <Button
      color="red"
      variant="light"
      onClick={() => {
        setIdForDeletion(order.work_order_id);
        setIsDeleteModalOpened(true);
      }}
    >
      <FaRegTrashAlt></FaRegTrashAlt>
    </Button>
  )}
</td>

        </tr>
       
      </React.Fragment>);
              }
              return null;
            })}
  </tbody>
            </Table>

          </Box>
        </Stack>
      </Box>
      {IsWorkOrderDrawerOpened && (
        <CreateWorkOrderDrawer
          opened={IsWorkOrderDrawerOpened}
          setOpened={setIsWorkOrderDrawerOpened}
          refreshListData = {fetchWorkOrderListingData}
          userName = {userEmail}
        />
      )}

{IsViewModalOpen && (
        <ViewItemsModal
          opened={IsViewModalOpen}
          setOpened={setIsViewModalOpen}
          data={selectedOrder}
          
        />
      )}

{isDeleteModalOpened && (
        <DeleteModal
          opened={isDeleteModalOpened}
          refreshListData ={fetchWorkOrderListingData}
          setOpened={setIsDeleteModalOpened}
          id={IdForDeletion}
        />
      )}

  {isStatusModalOpened && (
        <StatusModal
          opened={isStatusModalOpened}
          refreshListData ={fetchWorkOrderListingData}
          setOpened={setIsStatusModalOpened}
          id={editStatusID}
          user = {userEmail}
         
        />
      )}

{isPaymentModalOpened && (
        <PaymentModal
          opened={isPaymentModalOpened}
           refreshListData ={fetchWorkOrderListingData}
          setOpened={setIsPaymentModalOpened}
          id={editPaymentID}
          user = {userEmail}
          usertype = {userType}
         
        />
      )}

        </>
    )
}

export default WorkOrder;