import React, { useContext, useState, useEffect, useRef } from 'react';
import { Breadcrumb, Tab, Row, Col, Nav, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import TestSampleData from './TestSampleData';
import { FaTrashAlt,FaPencilAlt,FaNewspaper,FaVideo,FaCogs,FaChalkboard } from 'react-icons/fa';
import qualitydb from '../../service/qualityfirebaseconfig';
import { ProductDataCardContainer, TabFirstContainer, TabSecondContainer } from './productwrapper';
import ToggleBarComp from '../../components/ToggleBar';


const ProductDataCard = ({productkey,productCardDetails}) => {
    const [testSampleDetails, setTestSampleDetails] = useState([]);
    const [productActive,setProductActive] = useState(false);

    const showTestSamplesRef= useRef();
    const hideTestSamplesRef= useRef();

    const fetchTestSamples = (itemId,prodKey) => {

      Promise.all([
        qualitydb.database().ref(`Product_Grading_Result/`).once('value'),
        qualitydb.database().ref(`Testing_Product_Details/`).once('value')
      ]).then(([snapshot, snap]) => {
        if(snapshot.hasChildren()){
          let finalTestSamplesData=[];
          let testSamplesData=snapshot.val();

          Object.keys(testSamplesData).map((dkey,dindex)=>{
            let testSamplesByDateData=testSamplesData[dkey];
            let proddatekey=dkey.split('-');

            let year=proddatekey[2];
            let month=proddatekey[1];
            if(month<10){
              month='0'+month;
            }
            let date=proddatekey[0];
            if(date<10){
              date='0'+date;
            }
            let prodDate=year+'-'+month+'-'+date;

            Object.keys(testSamplesByDateData).map((phkey,phindex)=>{
              let testSamplesByDatePhoneData=testSamplesByDateData[phkey];
              Object.keys(testSamplesByDatePhoneData).map((tskey,tsindex)=>{
                let actualTestSampleData=testSamplesByDatePhoneData[tskey];
                if(actualTestSampleData['product_id']==itemId){
                  console.log('actualTestSampleData');
                  console.log(actualTestSampleData);
                  let ele={};
                  ele['testkey']=prodKey;
                  ele['product_id']=actualTestSampleData['product_id'];
                  ele['date']=prodDate;
                  ele['grain_length1']=actualTestSampleData['grain_length1'];
                  ele['grain_length1_percentage']=actualTestSampleData['grain_length1_percentage'];
                  ele['grain_length2']=actualTestSampleData['grain_length2'];
                  ele['grain_length2_percentage']=actualTestSampleData['grain_length2_percentage'];
                  ele['grain_length3']=actualTestSampleData['grain_length3'];
                  ele['grain_length3_percentage']=actualTestSampleData['grain_length3_percentage'];
                  ele['grain_length4']=actualTestSampleData['grain_length4'];
                  ele['grain_length4_percentage']=actualTestSampleData['grain_length4_percentage'];
                  ele['broken_grain_percentage']=actualTestSampleData['broken_grain_percentage'];
                  ele['length']=actualTestSampleData['average_width'];
                  ele['width']=actualTestSampleData['average_length'];
                  ele['lwratio']=actualTestSampleData['length_width_ratio'];
                  ele['test_type']='Compare';
                  finalTestSamplesData.push(ele);
                }
              });
            });
          });

          if(snap.hasChildren()){
            let test_prod_dets=snap.val();
            console.log(test_prod_dets);

            Object.keys(test_prod_dets).map((productkey,indeex) => {
              let test_prod_year=test_prod_dets[productkey];
              Object.keys(test_prod_year).map((mkey,mindex) => {
                let test_prod_month=test_prod_year[mkey];
                Object.keys(test_prod_month).map((dkey,dindex) => {
                  let test_prod_date=test_prod_month[dkey];
                  Object.keys(test_prod_date).map((skey,sindex) => {
                    let test_prod_sample=test_prod_date[skey];
                    console.log(dkey);
                    if(test_prod_sample['product_id']==itemId){
                      let ele={};
                      ele['testkey']=prodKey;
                      ele['product_id']=test_prod_sample['product_id'];
                      ele['date']=dkey;
                      ele['grain_length1']='';
                      ele['grain_length1_percentage']=test_prod_sample['rice_width_subgroups']['less_than_2'];
                      ele['grain_length2']='';
                      ele['grain_length2_percentage']=test_prod_sample['rice_width_subgroups']['between_2_to_4'];
                      ele['grain_length3']='';
                      ele['grain_length3_percentage']=test_prod_sample['rice_width_subgroups']['between_4_to_8'];
                      ele['grain_length4']='';
                      ele['grain_length4_percentage']=test_prod_sample['rice_width_subgroups']['greater_than_8'];
                      ele['broken_grain_percentage']=test_prod_sample['broken_rice_percentage'];
                      ele['length']=test_prod_sample['average_height'];
                      ele['width']=test_prod_sample['average_width'];
                      ele['lwratio']='';
                      ele['test_type']='Analyse';
                      finalTestSamplesData.push(ele);
                    }
                  });
                });
              });
            });
          }

          finalTestSamplesData.sort(function(a, b) {
            var keyA = a.date,
              keyB = b.date;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          setTestSampleDetails(finalTestSamplesData);
          setProductActive(true);
        }else{
          setTestSampleDetails([]);
          setProductActive(true);
        }
      });
    }

    return (
        <div>
            <ProductDataCardContainer key={productkey}>
                <Breadcrumb className="topDetails">
                    <Breadcrumb.Item active variant="primary"> {productCardDetails.itemnid} / {productCardDetails.description} </Breadcrumb.Item>
                </Breadcrumb>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                    <Row>
                        <Col sm={3} >
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">ProductInfo</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <TabFirstContainer>
                                        <div className="leftMain">
                                            <h6><span>Brand</span>: {productCardDetails.brand} </h6>
                                            <h6><span>Major Category</span>: {productCardDetails.major_category} </h6>
                                            <h6><span>Category</span>: {productCardDetails.category} </h6>
                                            <h6><span>Sub Category</span>: {productCardDetails.sub_category} </h6>
                                            <h6><span>Price Range</span>: {productCardDetails.price_range} </h6>
                                            <div>
                                                <center><h5>Standard Values</h5></center>
                                                <table className="table table-striped">
                                                  <thead className="thead-light">
                                                    <tr>
                                                      <th>Length</th>
                                                      <th>{productCardDetails.grain_length1}</th>
                                                      <th>{productCardDetails.grain_length2}m</th>
                                                      <th>{productCardDetails.grain_length3}</th>
                                                      <th>{productCardDetails.grain_length4}</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>Percentage</td>
                                                      <td>{productCardDetails.grain_length1_percentage}</td>
                                                      <td>{productCardDetails.grain_length2_percentage}</td>
                                                      <td>{productCardDetails.grain_length3_percentage}</td>
                                                      <td>{productCardDetails.grain_length4_percentage}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                            </div>
                                            <h6><span>Broken Grain Percentage</span>: {productCardDetails.broken_grain_percentage} </h6>
                                            <h6><span>Shape</span>: {productCardDetails.shape} </h6>
                                            <h6><span>Length</span>: {productCardDetails.length} </h6>
                                            <h6><span>Width</span>: {productCardDetails.width} </h6>

                                            <Button ref={showTestSamplesRef} variant="outline-danger" onClick={()=>{ fetchTestSamples(productCardDetails.itemnid,productkey) }}>Show Test Samples</Button>
                                            <Button ref={hideTestSamplesRef} variant="outline-danger" onClick={()=>{ setTestSampleDetails([]);setProductActive(false); }}>Hide Test Samples</Button>
                                            {(testSampleDetails.length>0)?<TestSampleData productkey={productkey} testSampleDetails={testSampleDetails} />:((productActive==true)?( <center><div>No test samples available</div></center>):null)}
                                        </div>
                                        <div className="rightMain" style={{ height: "400px", position: "relative" }}>
                                            <div >
                                                <a href={productCardDetails.image_url} target="_blank"><img src={productCardDetails.image_url} className="img-thumbnail" height="300" width="300" /></a>
                                            </div>
                                        </div>
                                    </TabFirstContainer>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </ProductDataCardContainer>
        </div>
    );
};

export default ProductDataCard;
