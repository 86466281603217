import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Card,
  Navbar,
  NavDropdown,
  Nav,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoginContainer, LoginHeader, UserDetailsContainer } from "./wrapper";
import NavBar from "./Navbar/Dashboard_Navabar";
import FooterComponent from "../components/Footer/index";
import dashboardLogo from "../assests/images/Dashboard_logo.png";
import { LOGGED_USER_EMAIL } from "../constants/allConstants";
import firebase from "../service/firebaseConfig";
import {
  validateIsAdminUser,
  validatePurchaseUser,
  validatePurchaseExportUser,
  validateIsInternalUser,
  validatePackageOnlyUser,
  validatePurchaseExportOnlyUser,
  validateBuyingHead,
  validateProductUser,
  validateProductOnlyUser,
  validateGRNUser,
  validateProductHeadOnlyUser,
  validatePurchaseReportUser,
  validateOPSUser,
  validateOPSOnlyUser,
  validateDispatchOnlyUser,
  validateStockUser,
} from "../service/credentials";
import Backdrop from "../CommonRequirements/backdrop";

let allowedRoutesSet = new Set();

export default function Home(props) {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let userType = sessionStorage.getItem("Logged User Type");

    if (userType && userLoggedIn == false) {
      // alert("fb fetch")
      firebase
        .database()
        .ref(`User_Role_Access/AdminPortal/${userType}/allowed_routes`)
        .once("value")
        .then((snapshot) => {
          let userRoleDetails = snapshot.val();
          console.log(userRoleDetails);

          if (userRoleDetails) {
            allowedRoutesSet = new Set();
            let userRoutes = Object.values(userRoleDetails);
            for (let i = 0; i < userRoutes.length; i++) {
              let route = `/${userRoutes[i]}`;
              if (!allowedRoutesSet.has(route)) {
                allowedRoutesSet.add(route);
              }
            }
            console.log(allowedRoutesSet);
          }
          setUserLoggedIn(true);
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <NavBar />
      {userLoggedIn == true ||
      validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ? (
        <LoginContainer>
          <LoginHeader>
            <img
              style={{ height: "300px" }}
              src={dashboardLogo}
              alt="Dashboard logo"
            />
          </LoginHeader>

          {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ||
            validateIsInternalUser(
              sessionStorage.getItem(LOGGED_USER_EMAIL)
            )) && (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Navbar.Brand href="#home">Portals</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="http://disha.superzop.com/" target="_blank">
                    Delivery
                  </Nav.Link>
                  <Nav.Link href="http://support.superzop.com/" target="_blank">
                    Support
                  </Nav.Link>
                  <Nav.Link
                    href="http://bidsdashboard.superzop.com/"
                    target="_blank"
                  >
                    SuperBids
                  </Nav.Link>
                  <Nav.Link
                    href="http://superkredit.superzop.com/"
                    target="_blank"
                  >
                    SuperKredit
                  </Nav.Link>
                  <NavDropdown
                    title="RoutePlanning"
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item href="http://priority.superzop.com/">
                      Priority
                    </NavDropdown.Item>
                    <NavDropdown.Item href="http://routing.superzop.com/delivery">
                      Routing
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="http://maps.superzop.com/"
                      target="_blank"
                    >
                      Routing Map
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="PhpMyAdmin" id="collasible-nav-dropdown">
                    <NavDropdown.Item
                      href="http://services.superzop.com:8080/phpmyadmin"
                      target="_blank"
                    >
                      Orders
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="http://services2.superzop.com/phpmyadmin"
                      target="_blank"
                    >
                      Delivery
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="SAP" id="collasible-nav-dropdown">
                    <NavDropdown.Item
                      href="http://sap.superzop.com/"
                      target="_blank"
                    >
                      SAP Dashboard
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="http://34.93.11.107/excel_files/"
                      target="_blank"
                    >
                      SAP Master Files
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form inline>
                  <Nav.Link
                    onClick={() => {
                      props.history.push("/custom_portal_index_update");
                    }}
                  >
                    CustomChanges
                  </Nav.Link>
                </Form>
              </Navbar.Collapse>
            </Navbar>
          )}
          <Form style={{ margin: "20px" }}>
            <Form.Row>
              <Form.Group as={Col}>
                {/* MDM */}
                <ListGroup style={{ width: "220px", fontSize: "15px" }}>
                  <ListGroup.Item variant="primary">MDM</ListGroup.Item>
                  {allowedRoutesSet.has("/home") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/home">
                      <i class="fa fa-bars" aria-hidden="true"></i> Pricing
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/priceupdates") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/priceupdates">
                      <i class="fa fa-book fa-fw" aria-hidden="true"></i> Price
                      Updates
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/priceList") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/priceList">
                      <i class="fa fa-table" aria-hidden="true"></i> Price List
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/mtPrice") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/mtPrice">
                     <i class="fa fa-table" aria-hidden="true"></i>  Modern Trade
                  </ListGroup.Item>
                ) : null}
                  {allowedRoutesSet.has("/manageUsers") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/manageUsers">
                      <i class="fa fa-users" aria-hidden="true"></i> Admin
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                {/* Commercial */}
                <ListGroup style={{ width: "220px", fontSize: "15px" }}>
                  <ListGroup.Item variant="danger">Commercial</ListGroup.Item>

                  {allowedRoutesSet.has("/stockEdit") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/stockEdit">
                      <i class="fa fa-tasks" aria-hidden="true"></i>{" "}
                      Availability
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/purchaseorder") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/purchaseorder">
                      <i class="fa fa-server" aria-hidden="true"></i> Purchase
                      Order
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/listpurchase") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/listpurchase">
                      <i class="fa fa-industry" aria-hidden="true"></i> Purchase
                      / GRN
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/financialBillListing") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/financialBillListing">
                      <i class="fa fa-check-square" aria-hidden="true"></i>{" "}
                      Invoice Approval
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Form.Group>

              {/* Operations */}
              <Form.Group as={Col}>
                <ListGroup.Item variant="success">Operation</ListGroup.Item>
                <ListGroup style={{ width: "220px", fontSize: "15px" }}>
                  {allowedRoutesSet.has("/productDumpList") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/productDumpList">
                      <i class="fa fa-minus-square" aria-hidden="true"></i>{" "}
                      Product Dump
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/stockConversionListing") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/stockConversionListing">
                      <i class="fa fa-plus-square" aria-hidden="true"></i> Stock
                      Conversion
                    </ListGroup.Item>
                  ) : null}


                  {allowedRoutesSet.has("/billOfMaterials") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/billOfMaterials">
                      <i class="fa fa-plus-square" aria-hidden="true"></i> Bill
                      Of Materials
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/SampleEntry") ||
                  validateIsAdminUser(
                    sessionStorage.getItem(LOGGED_USER_EMAIL)
                  ) ? (
                    <ListGroup.Item action href="#/SampleEntry">
                      <i class="fa fa-plus-square" aria-hidden="true"></i> Sample Entry
                    </ListGroup.Item>
                  ) : null} 

              {allowedRoutesSet.has("/grt") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/grt" >
                    <i class="fa fa-window-restore" aria-hidden="true"></i>  GRT Analysis
                  </ListGroup.Item>
                ) : null}
                
              {allowedRoutesSet.has("/workOrder") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/workOrder">
                     <i class="fa fa-table" aria-hidden="true"></i>  Work Order
                  </ListGroup.Item>
                ) : null}

              </ListGroup>
              </Form.Group>
              {/* Marketing */}
              <ListGroup style={{ width: "220px", fontSize: "15px" }}>
                <ListGroup.Item variant="dark">Marketing</ListGroup.Item>
                {allowedRoutesSet.has("/offersMaster") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/offersMaster">
                    <i class="fa fa-compass" aria-hidden="true"></i> Offers
                  </ListGroup.Item>
                ) : null}

                {allowedRoutesSet.has("/create_notifications") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/create_notifications">
                    <i class="fa fa-bullhorn" aria-hidden="true"></i>{" "}
                    Notifications
                  </ListGroup.Item>
                ) : null}

                {allowedRoutesSet.has("/apphomescreenconfig") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/apphomescreenconfig">
                    <i class="fa fa-desktop" aria-hidden="true"></i> App
                    Homescreen
                  </ListGroup.Item>
                ) : null}

                {allowedRoutesSet.has("/popular") ||
                validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) ? (
                  <ListGroup.Item action href="#/popular">
                    <i class="fa fa-book fa-fw" aria-hidden="true"></i> Super
                    Basket / New Products
                  </ListGroup.Item>
                ) : null}
              </ListGroup>
            </Form.Row>
          </Form>
        </LoginContainer>
      ) : null}

      {loading ? <Backdrop parentLoadStatus={loading} /> : null}
      <FooterComponent />
    </>
  );
}
