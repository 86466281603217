import React,{ useState,useEffect }from "react"; 
import Navbar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { PRICE_SERIES } from "../../constants/allConstants";

import { DatePicker } from "@mantine/dates";
import { Flex, Header, MultiSelect,Select,createStyles } from "@mantine/core";
import { Form,Button,Table, InputGroup } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import XLSX from 'xlsx';
import { toast } from "react-toastify";

import styled from "styled-components";
import { OFFER_BASE_URL, deliveryCommercialServer, ORDERING_BASE_URL } from "../../service/servicesConfig";
import { ShowMoreContainer } from "./wrapper";
import { CheckBoxContainer } from './wrapper';


const StyledTable = styled(Table)`
  thead tr th {
    text-align: center;
  }
  tbody tr td {
    text-align: center;
  }
`;


const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      zIndex: 1,
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",
  
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },
  
    scrolled: {
      boxShadow: theme.shadows.sm,
    },
  }));

 
 

const PriceLists = () => {
    const { classes, cx } = useStyles();
    const [scrolled, setScrolled] = useState(false);
    const [dateValue,setDateValue] = useState(new Date())
        let [categoryVarieties,setCategoryVarieties] = useState(["All"]);
        let [categoriesResponse,setCategoriesResponse] = useState([]);
        let [categoryInfo,setCategoryInfo] = useState("All");
        let [subCategoryVarieties,setSubCategoryVarieties] = useState(["All"]);
        let [subCategoryInfo,setSubCategoryInfo] = useState("All");
        const [initCheckbox,setInitCheckbox] = useState(false);
        let catWithSubCat = [];

            // table rows limit state
        const INIT_PROD_SHOWN = 20;
        const[next,setNext] = useState(INIT_PROD_SHOWN);
      
        const [searchText, setSearchText] = useState("");
        let[prodList,setProdList] = useState([])

        const [selectedSeries,setSelectedSeries] = useState(['A'])
       

        toast.configure({
            autoClose: 4000, 
            draggable: true,
            pauseOnHover: true,
            position: toast.POSITION.TOP_LEFT,
          });

         // export products

    const [exportData, setExportData] = useState(false);

    const LoadMoreComp = () => {
      return (
       <ShowMoreContainer>
       <button onClick={onLoadMoreClick} style={{color:"#fff"}}>Load More Products</button>
      </ShowMoreContainer>
      );
  };

  const onLoadMoreClick = (event) => {
      event.preventDefault();
     
      let count = prodList.length
      setNext(count)
      // setCountStock(count)

    
    };

        const seriesHeader = selectedSeries.map((seriesName) =>{
            return(
                <th  colSpan={3}>
                {seriesName}
            </th>
            )
           
        })

        const seriesColumns = selectedSeries.map((seriesName) => {
            return(
                <>
              
                <th style={{backgroundColor:"#e9e979"}}>
                    Total Price(Per Kg/Pc/Ex-GST)
                </th>
                 <th style={{backgroundColor:"#e9e979"}}>
                   Final Price(Per Kg/Pc/Incl-GST)
                </th>
                <th style={{backgroundColor:"#e9e979"}}>
                  Offer Price(Per Kg/Pc/Ex-GST)
               </th>
             </>
            )
        })

      

const seriesColumnVals = (priceList,offerPrice,storeSeries) => selectedSeries.map((seriesName) => {
  
      let filtered = priceList.filter((item)=> seriesName.includes(item.product_series))

      return(
          <>
        
          <td>
            
             {filtered[0] && filtered[0].total_price ?filtered[0].total_price:""}
          </td>
           <td>
           {filtered[0] && filtered[0].final_price?filtered[0].final_price:""}
          </td>
          <td>
          {offerPrice?offerPrice:""}
         </td>
       </>
      )
  })

       

  
        function getCategories(){

          axios.get(`${ORDERING_BASE_URL}/api/superzop/admin/pricelist/getdetails`).then(function (response){
                    
                  //  console.log("response")
                   setCategoriesResponse(response.data.data)
                  //  console.log(response.data.data)
                    let categoriesData = Object.keys(response.data.data)
                    catWithSubCat.push(Object.entries(response.data.data))
                
                    let categories = [{label:"All",value:"All"}]
                    categoriesData.forEach((cat) => {
                        if(cat !==""){
                            const category = {
                                label: `${cat} `,
                                value: cat,
                                
                              };
                              categories.push(category);
                        }
                     
                      });
                    
                      setCategoryVarieties(categories)
                      return categories;
                    
                }).catch(function(error){
                    console.log(error)
                })
        }


    
        function fetchAllData(){
           
            let prodsUrl = `${ORDERING_BASE_URL}/api/superzop/admin/pricelist/getpricelistdetails`
            let date = moment(dateValue).format("YYYY-MM-DD")
            let obj = {"date":date,"store_series":selectedSeries,"category":categoryInfo,"sub_category":subCategoryInfo}
            let newDate = moment(new Date()).format("YYYY-MM-DD")
            let token="eyJhbGciOiJIUzI1NiIsInR5cCI6Ik"
            if(date !== newDate){
              Promise.all([axios.post(prodsUrl,obj).then((response) => {
                return response.data.data;
              }),axios.get(`${OFFER_BASE_URL}/historic-product-offers?token=${token}`).then((response) => {
                return response.data.data
              })]).then(
               ([prodsList,offersList]) =>{
                
                
                // console.log('prodsList')
                // console.log(prodsList)
            
                let productIds = Object.keys(prodsList)
                let productValues  = Object.values(prodsList)
                // console.log(productValues)
                // console.log(Object.entries(prodsList))
                if(productValues.length <=0){
                    toast("No products available for selected date", {
                        type: toast.TYPE.ERROR,
                      });
                }
                
                // console.log("offers")
                // console.log(offersList)
         
                

                let offerItems = []
                
                offersList.forEach((item) =>{
                    
                   
                    if((item.offer_type =="BPP" || item.offer_type =="MDD") &&(item.offer_date == date) )
                    {
                       
                        offerItems.push(item)
                    }
                })
                // console.log(offerItems)
                let newprodList = []
               
                if(offerItems.length !==0){
                  productValues.forEach((prodItem) =>{
                
                    offerItems.map((item)=>{
                        
                        if(prodItem[0].item_id == item.item_id)
                        {
                            prodItem[0].offer_price = Number(item.offer_price/prodItem[0].outer_size).toFixed(2);
                            prodItem[0].store_series = item.store_series
                            newprodList.push(prodItem[0])
                        }else{
                            
                            newprodList.push(prodItem[0])
                        }
                    })
                })

                
                // console.log("newprodList")
                newprodList = [...new Set([...newprodList])]
                // console.log(newprodList)
                if(initCheckbox) {
                  newprodList = newprodList.filter(val => {
                    return val.offer_price != '' && val.offer_price != undefined
                  })
                }
                setProdList(newprodList)
                }
                else{
                  let newprodList = []
                  productValues.forEach((item)=>{
                      newprodList.push(item[0])
                  })
                  if(initCheckbox) {
                    newprodList = newprodList.filter(val => {
                      return val.offer_price != '' && val.offer_price != undefined
                    })
                  }
                  setProdList(newprodList)
                }
              
               }
            )
            // categories get
            getCategories()
            
            }else{  

            Promise.all([axios.post(prodsUrl,obj).then((response) => {
              return response.data.data;
            }),
            axios.get(`${OFFER_BASE_URL}/offers?token=${token}`).then((response) => {
              return response.data.product_offers
            })]).
            then(([prodsList,offersList]) =>{

                // console.log('prodsList')
                // console.log(prodsList)
            
                let productIds = Object.keys(prodsList)
                let productValues  = Object.values(prodsList)
                
                
                if(productValues.length <=0){
                    toast("No products available for selected date", {
                        type: toast.TYPE.ERROR,
                      });
                }
              
               
                // console.log("offers")
                // console.log(offersList)
                    let filteredVals = []
              

                let offerItems = []
                
                offersList.forEach((item) =>{
                    
                   
                    if((item.offer_type =="BPP" || item.offer_type =="MDD"))
                    {
                       
                        offerItems.push(item)
                    }
                })
                // console.log(offerItems)
                let newprodList = []
                if(offerItems.length !==0){
                  productValues.forEach((prodItem) =>{
                  
                    offerItems.map((item)=>{
                        
                        if(prodItem[0].item_id == item.item_id)
                        {
                            prodItem[0].offer_price = Number(item.offer_price/prodItem[0].outer_size).toFixed(2)
                            prodItem[0].store_series = item.store_series
                            newprodList.push(prodItem[0])
                        }else{
                            
                            newprodList.push(prodItem[0])
                        }
                    })
                })

                

                // console.log("newprodList")
                newprodList = [...new Set([...newprodList])]
                // console.log(newprodList);
                if(initCheckbox) {
                  newprodList = newprodList.filter(val => {
                    return val.offer_price != '' && val.offer_price != undefined
                  })
                }
                setProdList(newprodList)
                }
              

                else{
                  let newprodList = []
                  productValues.forEach((item)=>{
                      newprodList.push(item[0])
                  })
                  if(initCheckbox) {
                    newprodList = newprodList.filter(val => {
                      return val.offer_price != '' && val.offer_price != undefined
                    })
                  }
                  setProdList(newprodList)
                }
               }
            )
            // categories function
            getCategories()
            }
        }
            
        useEffect(() =>{
          //  console.log("use effect - categoryInfo")
          //  console.log(categoryInfo)
            fetchAllData()
            setNext(INIT_PROD_SHOWN)
          
        },[dateValue,selectedSeries,categoryInfo,initCheckbox,subCategoryInfo])

        const renderList = () => {
           let filterResult = [];
       
            filterResult = prodList.filter((obj) => {
                // console.log(obj)
                const productName = obj.item_description1? obj.item_description1.trim().toLowerCase():"";
                
              
              const brandName = obj.brand?obj.brand.trim().toLowerCase():"";
              
              const itemIdSearch = obj.item_id?obj.item_id.trim().toLowerCase():"";
              
           
              return productName.match(searchText.trim().toLowerCase())  || brandName.match(searchText.trim().toLowerCase()) || itemIdSearch.match(searchText.trim().toLowerCase());
            });

            setProdList(filterResult);
                        
          };
        
          useEffect(() => {
            if (searchText){
              renderList();
            }else if(searchText==""){
              fetchAllData()
              setNext(INIT_PROD_SHOWN)
            }    
          }, [searchText]);
              
                
                
          const handleCheckboxChange = (event) => {
            setInitCheckbox(event.target.checked);
            // console.log(event.target.checked);
          };
        
          const exportStockDetails = () => {
            let prodsUrl = `${ORDERING_BASE_URL}/api/superzop/admin/pricelist/getpricelistdetailsforexport`
            let date = moment(dateValue).format("YYYY-MM-DD")
            let obj = {"date":date,"store_series":'All',"category":categoryInfo}
            let newDate = moment(new Date()).format("YYYY-MM-DD")
            let token="eyJhbGciOiJIUzI1NiIsInR5cCI6Ik"
            //if(date !== newDate){
              Promise.all([axios.post(prodsUrl,obj).then((response) => {
                return response.data.data;
              })]).then(
               ([prodsList]) =>{ 
                // console.log(prodsList);
                const wb = XLSX.utils.book_new();
                const itemsLabelList = [
                  ["ITEM ID", "ITEM DESCRIPTION", "CATEGORY", "SUB-CATEGORY", "LOT SIZE" ,"WEIGHT","UOM","HSN","STOCK OUT", 
                   "BASE PRICE", "SOURCE TYPE", "TRANSPORT" , "MILL TRANSPORT VALUE" , "APMC%",
                   "APMC(Rs)", "FINAL BUYING PRICE", "BUYING PRICE EX GST", "LANDING COST",
                   "EXTRA MARGIN PER_A","EXTRA_MARGIN","EXTRA MARGIN PER_DD","EXTRA_MARGIN_DD",
                   "EXTRA MARGIN PER_B","EXTRA_MARGIN_B","EXTRA MARGIN PER_k","EXTRA_MARGIN_K",
                   "EXTRA MARGIN PER_N","EXTRA_MARGIN_N", "EXTRA MARGIN PER_NK","EXTRA_MARGIN_NK",
                   "EXTRA MARGIN PER_DE","EXTRA_MARGIN_DE","EXTRA MARGIN PER_DF","EXTRA_MARGIN_DF",
                   "EXTRA MARGIN PER_O","EXTRA_MARGIN_O", 
                   "OUTBOUND_TRAN_CHARGES_A","OUTBOUND_TRAN_CHARGES_B","OUTBOUND_TRAN_CHARGES_K",
                   "OUTBOUND_TRAN_CHARGES_N","OUTBOUND_TRAN_CHARGES_NK",
                   "OUTBOUND_TRAN_CHARGES_DD","OUTBOUND_TRAN_CHARGES_DF","OUTBOUND_TRAN_CHARGES_DE",
                   "TOTAL_PRICE_A","TOTAL_PRICE_B","TOTAL_PRICE_K","TOTAL_PRICE_N","TOTAL_PRICE_NK",
                   "TOTAL_PRICE_DD","TOTAL_PRICE_DE","TOTAL_PRICE_DF","TOTAL_PRICE_O",
                   "FINAL_PRICE_A","FINAL_PRICE_B","FINAL_PRICE_K","FINAL_PRICE_N","FINAL_PRICE_NK",
                   "FINAL_PRICE_DD","FINAL_PRICE_DE","FINAL_PRICE_DF","FINAL_PRICE_O",
                   "CD%", "CD AMOUNT", "GST %", "GST","PRIMARY PM COST", "SECONDARY PM COST", "LONG DESCRIPTION","WASTAGE%","WASTAGE"]
                ];
               
               
                prodsList.forEach((item) => {
                      itemsLabelList.push([
                        item.item_id,
                        item.item_description1,
                        item.category, 
                        item.sub_category,
                        item.lot_size,
                        item.weight,
                        item.uom,
                        item.hsn_code,
                        item.stock_ind,
                        item.base_price,
                        item.source_type,
                        item.mill_transport,
                        item.mill_transport_value,
                        item.apmc_per,
                        item.apmc_amt,
                        item.final_buying_price,
                        item.buying_price_ex_gst,
                        item.landing_cost,
                        item.extra_margin_per_A,
                        item.extra_margin,
                        item.extra_margin_per_DD,
                        item.extra_margin_DD,
                        item.extra_margin_per_DD,
                        item.extra_margin_DD,
                        item.extra_margin_per_B,
                        item.extra_margin_B,
                        item.extra_margin_per_K,
                        item.extra_margin_K,
                        item.extra_margin_per_N,
                        item.extra_margin_N,
                        item.extra_margin_per_NK,
                        item.extra_margin_NK,
                        item.extra_margin_per_DE,
                        item.extra_margin_DE,
                        item.extra_margin_per_DF,
                        item.extra_margin_DF,
                        item.extra_margin_per_O,
                        item.extra_margin_O,
                        item.transport_charge,
                        "1.5","1","1","1","1.5","2.4","3","0",
                        item.total_price_A,
                        item.total_price_B,
                        item.total_price_K,
                        item.total_price_N,
                        item.total_price_NK,
                        item.total_price_DD,
                        item.total_price_DF,
                        item.total_price_DE,
                        item.total_price_O,
                        item.final_price_A,
                        item.final_price_B,
                        item.final_price_K,
                        item.final_price_N,
                        item.final_price_NK,
                        item.final_price_DD,
                        item.final_price_DF,
                        item.final_price_DE,
                        item.final_price_O,
                        item.cd_per,
                        item.CD_amt,
                        item.gst_per,
                        item.gst,
                        item.primary_pm_cost,
                        item.secondary_pm_cost,
                        item.long_description,
                        item.wastage_per,
                        item.wastage                        
                      ]);
                });
                const itemsExported = XLSX.utils.aoa_to_sheet(itemsLabelList);
                XLSX.utils.book_append_sheet(wb, itemsExported, `Product List`);
                XLSX.writeFile(wb, `Product_List.xlsx`);
                setExportData(false);
               })
            //}
          };
        
          useEffect(() => {
            if (exportData) exportStockDetails();
          }, [exportData]);
          
          const handleCategoryChange = selectedCategory => {
            // console.log(selectedCategory)
            setCategoryInfo(selectedCategory);
            setSubCategoryInfo("All");
          // console.log(categoriesResponse)
            // Get the sub-categories for the selected category
            const subCategories = categoriesResponse[selectedCategory] || [];
          
            // Create the sub-category options
            const subCategoryOptions =  [{ label: "All", value: "All" },...subCategories.map(subCategory => ({
              label: subCategory,
              value: subCategory
            }))];
          
            
            console.log(subCategoryOptions)
            setSubCategoryVarieties(subCategoryOptions);
          };
          
 return(
    <>
        <Navbar/>

        <div style={{paddingTop:150,marginLeft:50,marginBottom:20}}>
                <Flex direction="column">

            <Header fluid>

            <Flex direction="row" gap={"lg"} align={"flex-end"}>
                    <div>
                    <DatePicker
                    label="Select Date"
                    value={dateValue}
                    onChange={setDateValue}
                    />
                    </div>

                    <div>
                        <Select
                         label = "Select Category"
                         placeholder="All"
                         data={categoryVarieties}
                         value={categoryInfo}
                         onChange={handleCategoryChange}
                         maxDropdownHeight={400}
                         clearable
                         >
                        </Select> 
                    </div>

                     {categoryInfo !== "All" && (
                          <Select
                          label="Select Sub-Category"
                          placeholder="All"
                          data={subCategoryVarieties}
                          value={subCategoryInfo}
                          onChange={setSubCategoryInfo}
                          maxDropdownHeight={400}
                          clearable
                        />
                      )}


                    <div>
                        <MultiSelect
                         label = "Select Series"
                         placeholder="All"
                         data={PRICE_SERIES}
                         value={selectedSeries}
                         onChange={setSelectedSeries}
                         maxDropdownHeight={400}
                         clearable
                         
                         >
                        </MultiSelect> 
                    </div>

                    <div>
                         <Form.Control
                            type="search"
                            style={{ marginLeft: 10,width:"fit-content" }}
                            placeholder="ID / BRAND/ DESCRIPTION"
                           value={searchText}
                           onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>

                    <div>
                          <Button
                            variant="warning"
                            style={{ marginLeft: 30 }}
                            onClick={() => {
                                setExportData(true)
                                }}
                          >
                             Export
                         </Button>
                      </div>
                      <div>
                      <InputGroup>
                      <InputGroup.Prepend>
                            <InputGroup.Text >Product With Offers</InputGroup.Text>
                            
                            </InputGroup.Prepend>
                            <CheckBoxContainer>

                            <input type="checkbox" id={`productwithoffer`} checked={initCheckbox} onChange={handleCheckboxChange} />
                              <label htmlFor={`productwithoffer`}></label>
                            </CheckBoxContainer>

                      </InputGroup>
                      </div>
                </Flex>

            </Header>

                </Flex>
         </div>

         {/* table lists */}

       
         <Table striped bordered>
            <thead >
                <tr style={{backgroundColor:"aliceblue"}}>
                    <th colSpan={9}>Pricing sheet</th>
                    {seriesHeader}
                </tr>
                <tr  >
                
                <th style={{backgroundColor:"antiquewhite"}}>ITEM ID</th>
                
                <th style={{backgroundColor:"antiquewhite"}}>ITEM DESCRIPTION</th>
                <th style={{backgroundColor:"antiquewhite"}}>BRAND</th>
                <th style={{backgroundColor:"antiquewhite"}}> CATEGORY</th>
                <th style={{backgroundColor:"antiquewhite"}}>SUB CATEGORY</th>
                <th style={{backgroundColor:"antiquewhite"}}>WEIGHT</th>

                <th style={{backgroundColor:"antiquewhite"}}>MOQ</th>
                <th style={{backgroundColor:"antiquewhite"}}>MARKET PRICE</th>
                <th style={{backgroundColor:"antiquewhite"}}>GST %</th>
                {seriesColumns}     
              </tr>
            </thead>
            <tbody>
                {prodList.length>0 &&
                prodList.slice(0,next).map((product) => {
                    return(
                      <tr>
                      <td>{product.item_id}</td>
                      <td>{product.item_description1}</td>
                      <td>{product.brand}</td>
                      <td>{product.category}</td>
                      <td>{product.sub_category}</td>
                      <td>{product.weight}</td>
                      <td>{product.moq}</td>
                      <td>{product.market_price?product.market_price:"0"}</td>
                      <td>{product.gst_per}</td>
                      {seriesColumnVals(product.itemPriceList,product.offer_price,product.store_series)}
                      </tr>
                       )                   
                })
                }
            </tbody>
            </Table> 
            {next<prodList.length?<LoadMoreComp/>:""}
    </>
               )   
              }
export default PriceLists;
        
       

    
   
  
