import React from 'react';
import { ProductContainer, NoProdContainer } from './wrapper';
import ProductDataCard from '../ProductDataCard';


const ProductDataComp = ({ prodDataToBeShown, region_name }) => {

    const NoProdFoundComp = () => {
        return (
            <NoProdContainer>
                <span>No Product to show.</span>
            </NoProdContainer>
        );
    };

    const ShowComp = () => {
    const stockDetailsArray = []
        return (prodDataToBeShown.map((obj) => {
            if(obj===undefined)
            return;
            console.log(obj)
            if (obj && obj.id === 0 && obj.productNotFound === true) {
                return <NoProdFoundComp key={obj.id} />
            }
            return <ProductDataCard {...obj} key={obj.keyNo} regionName={region_name} StockDetailsArray={stockDetailsArray}/>;
        }));
    };

    return (
        <ProductContainer>
            {prodDataToBeShown.length > 0 ? <ShowComp /> : <NoProdFoundComp />}
        </ProductContainer>
    );
};

export default ProductDataComp;