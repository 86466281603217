import React, { useState, useContext, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton, ButtonToolbar, NavItem } from 'react-bootstrap';
import { ChangeRegionContainer } from './wrapper';
import { rootStore } from '../../store';
import { getCurrCity, getCurrRegion } from '../../service/getCityRegion';
import { REGION_CHANGE_BOM, REGION_CHANGE_PUNE,REGION_CHANGE_S,REGION_CHANGE_DF,  REGION_YO_GROCERY, REGION_CHANGE_ALL, CITY_CHANGE, CITY_ALL, CITY_MUMBAI, CITY_PUNE,
REGION_BOM_A,REGION_BOM_B,REGION_BOM_DD,REGION_BOM_DE,REGION_BOM_DF,REGION_BOM_DG,REGION_BOM_K,REGION_BOM_NK,REGION_BOM_PG,REGION_BOM_O, REGION_BOM_N, ALL_REGION_DD } from '../../constants/allConstants';

const ChangeRegion = () => {

    const globalState = useContext(rootStore);
    const { dispatch } = globalState;
    const [currCity, setCity] = useState(getCurrCity(globalState.state, "CHANGE_REGION"));
    const [currRegionBom, setRegionBom] = useState(getCurrRegion(globalState.state, 'CHANGE_REGION'));
    const [currRegionPune, setRegionPune] = useState(getCurrRegion(globalState.state, 'CHANGE_REGION'));
    const [currRegion_S, setRegion_S] = useState(getCurrRegion(globalState.state, 'CHANGE_REGION'));
    const [currRegionAll, setRegionAll] = useState(getCurrRegion(globalState.state, 'CHANGE_REGION'));
    const [currRegion_DF, setRegion_DF] = useState(getCurrRegion(globalState.state, 'CHANGE_REGION'));
    const onBomRegionChange = (val) => {
        setRegionBom(val);
        dispatch({ type: REGION_CHANGE_BOM, data: val });
    };

    const onPuneRegionChange = (val) => {
        setRegionPune(val);
        dispatch({ type: REGION_CHANGE_PUNE, data: val });
    };

    const onAllRegionChange = (val) => {
        setRegionAll(val);
        dispatch({ type: REGION_CHANGE_ALL, data: val });
    };

    const onYoGroceryChange = (val) => {
        

        setRegion_S(val);
        dispatch({ type: REGION_CHANGE_S, data: val });
    }

    const onCityChange = (event) => {
        setCity(event.target.value);
    };

    /*
    const onDFseriesChange = (val) => {
        setRegion_DF(val);
        dispatch({ type: REGION_CHANGE_DF, data: val });
    }*/

   

    useEffect(() => {
        dispatch({ type: CITY_CHANGE, data: currCity });
    }, [currCity])

    const AllComp = () => {
        return (
            <ButtonToolbar>
                <ToggleButtonGroup type="radio" value={currRegionAll} name="all_region" onChange={onAllRegionChange}>
                    <ToggleButton value='ALL' variant="light"> All </ToggleButton>
                   { ALL_REGION_DD.map((item) =>{
                        return(
                            <ToggleButton value={item.value} variant="light">{item.label} <span> {item.title} </span> </ToggleButton>
                        )
                    })
                    }
                   
                </ToggleButtonGroup>
            </ButtonToolbar>
        );
    };

    const MumbaiComp = () => {
        return (
            <ButtonToolbar>
                <ToggleButtonGroup type="radio" value={currRegionBom} name="region_bom" onChange={onBomRegionChange}>
                    <ToggleButton value='ALL_B' variant="light">Region All <span> (Mumbai) </span>  </ToggleButton>
                    <ToggleButton value='A' variant="light">Region A <span> (Core Mumbai) </span> </ToggleButton>
                    <ToggleButton value='B' variant="light">Region B <span> (Virar | Karjat) </span> </ToggleButton>
                    <ToggleButton value='H' variant="light">Region N <span> (Navi Mumbai) </span> </ToggleButton>
                    <ToggleButton value='N' variant="light">Region H <span> (HORECA) </span> </ToggleButton>
                    <ToggleButton value='K' variant="light">Region K </ToggleButton>

                </ToggleButtonGroup>
            </ButtonToolbar>
        );
    };

    const PuneComp = () => {
        return (
            <ButtonToolbar>
                <ToggleButtonGroup type="radio" value={currRegionPune} name="region_pune" onChange={onPuneRegionChange}>
                    <ToggleButton value='P' variant="light">Region P <span> (Pune) </span> </ToggleButton>
                </ToggleButtonGroup>
            </ButtonToolbar>
        );
    };

    const YoGroceryComp = () => {
        return (
            <ButtonToolbar>
                <ToggleButtonGroup type="radio" value={currRegion_S} name="region_yogrocery" onChange={onYoGroceryChange}>
                    <ToggleButton value='S' variant="light">Region S <span> (YoGrocery) </span> </ToggleButton>
                </ToggleButtonGroup>
            </ButtonToolbar>
        );
    };

    /*
    const RegionDFComp = () => {
        return (
            <ButtonToolbar>
                <ToggleButtonGroup type="radio" value={currRegion_DF} name="region_yogrocery" onChange={onDFseriesChange}>
                    <ToggleButton value='DF' variant="light">Region DF <span> (SERIES DF) </span> </ToggleButton>
                </ToggleButtonGroup>
            </ButtonToolbar>
        );
    };*/


    return (
        <ChangeRegionContainer>
            <select name="reg_name" id="area_name" onChange={onCityChange} defaultValue={currCity}>
                <option value={CITY_ALL}>All</option>
                <option value={CITY_MUMBAI}>Mumbai</option>
                <option value={CITY_PUNE}>Pune</option>
                <option value={REGION_YO_GROCERY}>YoGrocery</option>
              
            </select>
            {currCity === CITY_ALL ? <AllComp /> : null}
            {currCity === CITY_MUMBAI ? <MumbaiComp /> : null}
            {currCity === CITY_PUNE ? <PuneComp /> : null}
            {currCity === REGION_YO_GROCERY ?  <YoGroceryComp /> : null}
          
        
        </ChangeRegionContainer>
    );
};

export default ChangeRegion; 
