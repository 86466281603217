import React,{useState,useEffect} from 'react';
import {Container, Toast, Table, Button, Modal, Form, Row, Col} from 'react-bootstrap';
import { toast } from 'react-toastify';
import firebase from '../../service/firebaseConfig';
import AddUserModalPopup from  './SupportPortalUsers/adduser';
import EditUserModalPopup from './SupportPortalUsers/edituser';
import DeleteUserModalPopup from './SupportPortalUsers/deleteuser';
import {support_portal_url} from '../../service/servicesConfig';

const SupportPortal = () => {
  const [usersList,setUsersList] = useState([]);
  const [distinctUsertype,setDistinctUsertype] = useState([]);
  const [selectedUser,setSelectedUser] = useState({});
  const [rowIndex,setRowIndex] = useState();
  const [deletedUser,setDeletedUser] = useState({});
  const [addUserModalShow,setAddUserModalShow] = useState(false);
  const [editUserModalShow,setEditUserModalShow] = useState(false);
  const [deleteUserModalShow,setDeleteUserModalShow] = useState(false);

  toast.configure({
      autoClose: 4000,
      draggable: true,
      pauseOnHover: true,
      position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    let apiurlstr=`${support_portal_url}/api/users?token=${sessionStorage.getItem("apiToken")}`
    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result['success']==1) {
        let userData=result['data'];
        let updatedUsersList=[];

        for(let i=0;i<userData.length;i++){
          let ele=userData[i];
          ele.otp_bypass=ele.otp_bypass=="1"?true:false;
          if(ele.status==='Active'){
            updatedUsersList.push(ele);
          }
        }

        setUsersList(updatedUsersList);
      } else {
        setUsersList([]);
        let errMessage=result['message']?result['message']:"";
        alert(errMessage);
        if(result['status']==401 || result['status']==403){
          sessionStorage.clear();
          window.location.href="/"
        }
      }
    })
    .catch((error) => {
      setUsersList([]);
    });
  }, [])

  useEffect(() => {
    if(usersList.length>0){
      let usertypeSet = new Set();
      for(let i=0;i<usersList.length;i++){
        if(!usertypeSet.has(usersList[i].usertype)){
          usertypeSet.add(usersList[i].usertype);
        }
      }
      setDistinctUsertype([...usertypeSet]);
    }
  },[usersList]);

  const UsersTableHeader = () => {
      return (
          <thead className="thead-light">
              <tr>
                  <td>Status</td>
                  <td>Username</td>
                  <td>Password</td>
                  <td>Usertype</td>
                  <td>Mobile</td>
                  <td>OTP Bypass</td>
                  <td>Last Login</td>
                  <td>Edit</td>
                  <td>Delete</td>
              </tr>
          </thead>
      );
  }

  const UsersTableBody = () => {
    let rows = usersList.map((user, index) => {
          return (
              <tr key={index}>
                  <td>{user.status === "Active" ? " ✅ " : " ❌ "}</td>
                  <td>{user.username}</td>
                  <td>*****</td>
                  <td>{user.usertype}</td>
                  <td>{user.mobile}</td>
                  <td>{user.otp_bypass?"Y":user.mobile?"N":""}</td>
                  <td>{user.last_login}</td>
                  <td><Button variant="primary" onClick={()=>{setEditUserModalShow(true);setSelectedUser(user);setRowIndex(index); }}>Edit</Button></td>
                  <td><Button variant="danger" onClick={()=>{setDeleteUserModalShow(true);setDeletedUser(user);}}>Delete</Button></td>
              </tr>
          );
      });

      return (<><tbody>{rows}</tbody></>);
  }

  const UsersTable = () => {
    return (
        <table className="table" style={{marginTop:10}}>
            <UsersTableHeader />
            <UsersTableBody />
        </table>
    );
  }

  const addUser = (newUserData) => {
    console.log(newUserData);
    if(newUserData){
      setUsersList(usersList.concat(newUserData))
    }
  }

  const editUser = (updatedData) => {
    console.log('editUser');
    console.log(updatedData);
    let updatedUsersList = [...usersList];
    updatedUsersList[rowIndex]['username'] = updatedData['username'];
    updatedUsersList[rowIndex]['password'] = updatedData['password'];
    updatedUsersList[rowIndex]['usertype'] = updatedData['usertype'];
    updatedUsersList[rowIndex]['mobile'] = updatedData['mobile'];
    updatedUsersList[rowIndex]['otp_bypass'] = updatedData['otp_bypass'];

    setUsersList(updatedUsersList);
  };

  const deleteUser = (user) => {
    setUsersList(usersList.filter(item => item.ID !== user.ID));
  }

  return (
    <>
    <center><h5 style={{paddingTop:30}}>Manage Support Users</h5></center>
    <Button variant="success" style={{float:'right'}} onClick={()=>{setAddUserModalShow(true); }}>Add User</Button>

    {(usersList.length>0) ? <UsersTable /> : (<div style={{margin:40}}>No users present</div>) }

    {addUserModalShow && <AddUserModalPopup
        show={addUserModalShow}
        onHide={() => setAddUserModalShow(false)}
        distinctUsertype = {distinctUsertype}
        callback={addUser}
    />}

    {editUserModalShow && <EditUserModalPopup
        show={editUserModalShow}
        onHide={() => setEditUserModalShow(false)}
        selectedUser = {selectedUser}
        distinctUsertype = {distinctUsertype}
        callback={editUser}
    />}

    {deleteUserModalShow && <DeleteUserModalPopup
        show={deleteUserModalShow}
        onHide={() => setDeleteUserModalShow(false)}
        deletedUser = {deletedUser}
        callback={deleteUser}
    />}
    </>
  );
};

export default SupportPortal;