import React,{useEffect,useState} from 'react';
import { Form,Modal,Button,Table } from "react-bootstrap";
import { Select,MultiSelect } from '@mantine/core';
import database from '../../service/firebaseConfig';
import { toast } from "react-toastify";
import { ORDERING_BASE_URL } from '../../service/servicesConfig';
import axios from 'axios';

const DB_REF_RET = database.database().ref(`Retailer_Master_New`);
const DB_REF_MT = database.database().ref(`Product_Master_New/O`);
const DB_REF_ADD_PRODUCTS = database.database().ref(`Retailer_Products`); 



const AddRetailerModal = ({updateRetailerWithProducts,updateRetailerDD,...props}) => {

    // const [allRetailers, setAllRetailers] = useState([]);
    const [selectedRetailer, setSelectedRetailer] = useState(""); 
    const [existingRetailers,setExistingRetailers] = useState(props.existingRetailers);
    const [existingProducts,setExistingProducts] = useState(props.existingProducts)
    
    const [MT_Retailers, setMT_Retailers] = useState([]);
    let [allRetData,setRetData] = useState([]);
   

    // for counter effect
    const [isLoading, setIsLoading] = useState(true); 
    const [countdown, setCountdown] = useState(35); 
    // ret data taking time coz of some 50k entries.
    // prods
    const [allMTdata, setAllMTdata] = useState({});
    const [mtProducts,setMtProducts] = useState([]);
    const [selectedProducts,setSelectedProducts] = useState([]);

    // save
    const collectionsArray = [];


    useEffect(() => {
       
        let fetchRetailers = () => {
           
            let API_URL = `${ORDERING_BASE_URL}/api/superzop/admin/retailers/getretailersbasedonstoreseries?store_series=O`
              axios.get(API_URL)
                  .then((response) => {
                      let retailers = response.data; 
                    
                 
                    //  let existingRetailerIds = existingRetailers.map((retailer) => retailer.value);
                    let existingRetailerIds = existingRetailers.map((retailer) => {
                        const parts = retailer.value.split('-');
                        return parts[0];
                      });
                      
                     setRetData(retailers)

                     let filteredRetailers = retailers.filter((retailer) =>
                        !existingRetailerIds.includes(retailer.retailer_id.toString())
                        );
                        
                       
                    filteredRetailers.sort((a, b) => a.retailer_id - b.retailer_id);
                    
                      let retailerData = filteredRetailers.map((retailer) => ({
                          label: `${retailer.retailer_id}-${retailer.shop_name}`,
                          value: retailer.retailer_id
                      }));
                      

                      setMT_Retailers(retailerData);
                    
                      setIsLoading(false);
                  })
                  .catch((err) => {
                      setMT_Retailers([]);
                      setIsLoading(false);
                      
                  });
          };



    
        const fetchProducts = async () => {
            try{

                const snapshot = await DB_REF_MT.once('value');
                const prodVal = snapshot.val();
                setAllMTdata(prodVal)
               
                let Mt_products = Object.values(prodVal);
               
                let productData = [{ label: "", value: ""}];
                Mt_products.forEach((item) => {
                    if (!existingProducts || !existingProducts.hasOwnProperty(item.item_id)){

                    const product = {
                        label: `${item.item_id}-${item.item_description1}-${item.brand}`,
                        value: item.item_id
                    }
                    productData.push(product);
                }
                })
                setMtProducts(productData);

            } catch (error){
                console.error('Error fetching Product details:', error);
            }
        };

        fetchProducts();
        fetchRetailers();
      }, []);


      // Countdown timer effect
    useEffect(() => {
        if (isLoading) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);

            return () => clearInterval(timer); // Clean up the timer on component unmount
        }
    }, [isLoading]);

    const handleSaveProducts = () => {
       
       
        
        if (!selectedRetailer || selectedProducts.length === 0) {
            return toast("Please select a retailer and at least one product", { type: toast.TYPE.ERROR });
          }

        if (selectedRetailer && selectedProducts.length > 0) {

            const foundRetailer = allRetData.find((retailer) => retailer.retailer_id == selectedRetailer);
             let ret_phone = '';
             let fbKey = '';
             if(foundRetailer){
                ret_phone = foundRetailer.phone;
        
             const shopName = foundRetailer.shop_name;
             const hyphenatedShopName = shopName.replace(/[^a-zA-Z0-9]/g, '-');
              fbKey = `${selectedRetailer}-${hyphenatedShopName}`;
             }
              
                
                selectedProducts.forEach((item) =>{
                   
                    let itemVal = allMTdata[item]
                    if (itemVal) {
                        const prodsCollection = {
                          [item]: itemVal
                        };
                        collectionsArray.push(prodsCollection);
                      }
                })
                    
                    const allCollections = collectionsArray.reduce((result, collection) => {
                        return { ...result, ...collection };
                      }, {});
                      

                      const retailerProductsRef =  database.database().ref(`Retailer_Products/${fbKey}`)
                      const retailerMasterRef = database.database().ref(`Retailer_Master_New/${ret_phone}`);


               
                   retailerProductsRef.set(allCollections)
                      .then(() => {
                        
                        retailerMasterRef.update({ retailer_products: "Y" }).then(() => {

                        updateRetailerWithProducts();
                        updateRetailerDD(String(fbKey));
                        props.onHide();
                        return toast(`Updated ${fbKey} retailer in Retailer Master and Retailer Products`, { type: toast.TYPE.SUCCESS });

                      })
                      .catch((error) => {

                        console.error(`Error adding user with item_id `, error);
                        props.onHide();
                        return toast("Couldn't update in Retailer master  ", { type: toast.TYPE.ERROR });

                      });
                    })
                    .catch((error) => {

                        console.error(`Error adding user with item_id `, error);
                        props.onHide();
                        return toast("Couldn't update in Retailer Products", { type: toast.TYPE.ERROR });

                      });
                
               
            // Clear the selected products after saving
            setSelectedProducts([]);
        }


    };

    return(
        <>
    <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Add Retailer Products
        </Modal.Title>
        </Modal.Header>
        <Modal.Body >
       
                <Table>
                    <tr>
                        <td>Select Retailer Id</td>
                        <td>  
                             <Select
                           
                        placeholder="Select one"
                        data={MT_Retailers}
                        value={selectedRetailer}
                        onChange={setSelectedRetailer}
                        searchable
                        maxDropdownHeight={400}
                          style={{
                         marginRight: '4%',
                         marginLeft: '10%',
                         marginTop: '6%'
                         // Add space between Select and Button
                        }}
                        />
                        </td>
                    </tr>
                    <tr>
                        <td>Select Products</td>
                        <td>
                         <MultiSelect
                        
                         placeholder="All"
                         data={mtProducts}
                         value={selectedProducts}
                         onChange={setSelectedProducts}
                         searchable
                         maxDropdownHeight={400}
                         clearable
                         
                         >
                        </MultiSelect> 
                        </td>
                    </tr>
                </Table>
             
                   
                   
                    
                   

                 
        </Modal.Body>
        <Modal.Footer>
       
        <Button variant="success" onClick={handleSaveProducts}>Save Products</Button>
        <Button onClick={()=>{props.onHide()}}>Close</Button>
        </Modal.Footer>
    </Modal>
    
        </>
    )
}

export default AddRetailerModal;