import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  Stack,
  Select,
  TextInput,
  Divider,
  Text,
  Table,
  Box,
  NumberInput,
  Textarea,
  Modal,
  Flex,
  Space,
} from "@mantine/core";
import { toast } from "react-toastify";
import axios from "axios"; 
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";



export function StatusModal({id, opened, setOpened, refreshListData,user  }) {

const [statusData, setStatusData] = useState([ { value: "Approved", label: "Approved" },{ value: "Rejected", label: "Rejected" }]);
const [selectedStatus,setSelectedStatus] = useState("");
const [remarks,setRemarks] = useState("");


function updateStatusInDb(objData){
    const url = `${DELIVERY_BASE_URL}/api/superzop/workorder/updateWorkOrders`
    axios.put(url, objData)
      .then(function (response) {
        refreshListData();
        toast(`Changed Status to ${objData.status}`, { type: toast.TYPE.SUCCESS });
        setOpened(false);
      })
      .catch(function (error) {
        console.log(error);
        toast(`Couldn't change the status to ${objData.status}`, { type: toast.TYPE.ERROR });
      });
}
function handleConfirmClick() {
  if (selectedStatus === "" || remarks === "") {
    if (selectedStatus === "") {
      toast("Please select a status option.", { type: toast.TYPE.ERROR });
    }
    if (remarks === "") {
      toast("Please provide remarks.", { type: toast.TYPE.ERROR });
    }
  } else {
    let updateObj = {
      work_order_id: id,
      status: selectedStatus,
      remarks: remarks,
      approved_by: user
    }
    updateStatusInDb(updateObj);
  }
}

    return (
      <Modal opened={opened} onClose={() => setOpened(false)} title="Change status">
        <Stack spacing={"1.5rem"} align="stretch" justify="center">
          
            {`Change status for this work ID - ${id}?`}
            
          <Space v={"sm"} />
          <Group grow style={{ width: "100%" }}>
             <Select
                required
                label="Status Options"
                placeholder="Select"
                data={statusData}
             value={selectedStatus}
             onChange={setSelectedStatus}
            searchable
            maxDropdownHeight={400}
            clearable
              />
           
          </Group>
          <Textarea
          label="Remarks"
          placeholder="Enter remarks..."
          value={remarks}
          onChange={(event) => setRemarks(event.target.value)}
        />
          <Space v={"sm"} />
          <Button color="teal" onClick={handleConfirmClick}>Confirm</Button>
            <Button color="red" onClick={() => setOpened(false)}>Cancel</Button>
        </Stack>
      </Modal>
    );
  }
  