import XLSX from 'xlsx'

function ExportProductDump({listedPurchaseDump,multipleExport,exportDate,...props}) {

    const exportPurchaseDump = [["Item Id","Item Name","Category","UOM","Quantity Dumped","Dump Date","Reason","Approve Status","Salvage Rate","Salvage Amount","Dump Amount"]]

    Object.keys(listedPurchaseDump).forEach(item=>{

        exportPurchaseDump.push([listedPurchaseDump[item].article_code,listedPurchaseDump[item].item_name,listedPurchaseDump[item].category,listedPurchaseDump[item].uom,
            listedPurchaseDump[item].qty_dumped,listedPurchaseDump[item].dump_date,listedPurchaseDump[item].reason,listedPurchaseDump[item].approve_status,
            listedPurchaseDump[item].salvage_rate,listedPurchaseDump[item].salvage_amt,(listedPurchaseDump[item].normal_rate*listedPurchaseDump[item].qty_dumped).toFixed(2)])
    })

    const wb = XLSX.utils.book_new()

    const purchaseDumpList = XLSX.utils.aoa_to_sheet(exportPurchaseDump)
    XLSX.utils.book_append_sheet(wb, purchaseDumpList, `Product Dump Details`)
    XLSX.writeFile(wb, `Purchase_Dump_Details_${exportDate}.xlsx`);
    multipleExport(false)

    return null
}

export default ExportProductDump
