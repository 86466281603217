import React, { useEffect, useState, useRef } from 'react'
import { Breadcrumb, Dropdown, DropdownButton, Alert, Tab, Row, Col, Nav, Table, Button, Form, Modal, Badge, Navbar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import database from '../../service/firebaseConfig';
import { FaWindows, FaLessThanEqual } from 'react-icons/fa';
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import UserEmail from '../../CommonRequirements/FetchUserEmailId'
import UpdatedDate from '../../CommonRequirements/UpdatedDate'
import UpdatedTime from '../../CommonRequirements/UpdatedTime'
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import baseUrl from '../../service/servicesConfig';
import { validateIsAdminUser } from '../../service/credentials';


const StockTakeDatabase = database.database().ref(`Product_Stock_Take`)
const Product_Base = database.database().ref(`Product_Base_Data`)

function AddStockTake(props) {

    const [stockDate, setStockDate] = useState("")
    const [category, setCategory] = useState("")
    const [articleCode, setArticleCode] = useState("")
    const [itemName, setItemName] = useState("")
    const [articleInfo, setArticleInfo] = useState("")
    const [articleOptions, setArticleOptions] = useState("")
    const [disableList, setDisableList] = useState(false)
    const [dateToModify, setDateToModify] = useState(new Date())
    const [validated, setValidated] = useState(false)
    const [outerSize, setOuterSize] = useState("")
    const [lotSize, setLotSize] = useState("")
    const [systemInventory, setSystemInventory] = useState("")
    const [inventoryOuters, setInventoryOuters] = useState("")
    const [bagOrBoxStock, setBagOrBoxStock] = useState("")
    const [looseStock, setLooseStock] = useState(0)
    const [actualStock, setActualStock] = useState("")
    const [stockLossGain, setStockLossGain] = useState("")
    const [approveLossGain, setApproveLossGain] = useState("")
    const [stockDetails, setStockDetails] = useState("")
    const [subCategory, setSubCategory] = useState("")
    const [uomValue, setUomValue] = useState("")
    const [pushNewRecord, setPushNewRecord] = useState(false)

    const categoryField = useRef();
    const itemNoField = useRef();
    const itemNameField = useRef();
    const datepickerRef = useRef();

    let btnRef = useRef();
    let outersizeRef = useRef();
    let systemInventoryRef = useRef()
    let outerInventoryRef = useRef()
    let actualStockRef = useRef()
    let stockLossGainRef = useRef()

    console.log("stock take")

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            return toast("Stock Take Details added", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't add the Stock Take Details", { type: toast.TYPE.ERROR });
    };


    const fetchProductItem = () => {


        const productBaseInfo = []
        Product_Base.once("value", snapShot => {
            const productItems = snapShot.val();
            Object.keys(productItems).forEach(productId => {
                productBaseInfo.push({
                    "articleId": productId, "category": productItems[productId].category, "sub_category": productItems[productId].sub_category, "lot_size": productItems[productId].lot_size, "brand": productItems[productId].brand, "variety": productItems[productId].variety, "itemName": productItems[productId].long_description,
                    "product_stock": productItems[productId].product_stock, "case_size": productItems[productId].case_size, "weight": productItems[productId].weight, "uom": productItems[productId].uom, "outer_size": productItems[productId].outer_size
                })
            })
        }).then(() => { setArticleInfo(productBaseInfo) })
    }
    useEffect(() => {
        fetchProductItem()
        console.log(datepickerRef.current)
    }, [])

    useEffect(() => {
        insertDomData(articleCode,itemName);
        //console.log(datepickerRef.current)
    }, [dateToModify,stockDate])


    const populateArticleInfo = () => {

        const allOptionsArticle = []
        articleInfo.forEach(item => {
            let itemName = item.itemName
            if (item.brand)
                itemName = item.brand + " - " + item.itemName;

            allOptionsArticle.push({ "value": itemName, "label": itemName })
        })
        setArticleOptions(allOptionsArticle)
    }


    useEffect(() => {
        if (articleInfo)
            populateArticleInfo()
    }, [articleInfo])


    const insertDomData = (itemId, itemValue) => {
        let stockTakeDate = getStockDate();
        console.log("Date:: " + stockTakeDate)
        if (itemId) {

            articleInfo.forEach(item => {
                if (itemId.includes(item.articleId)) {
                    setDisableList(true);
                    categoryField.current.value = item.category
                    let itemValue = item.itemName
                    if (item.brand)
                        itemValue = item.brand + " - " + item.itemName;
                    setItemName(itemValue);
                    console.log("Updating based on ArticleCode:: " + itemId + " Article Name :: " + itemValue + " Category:: " + item.category);
                    console.log(dateToModify)
                    const getUrl = `/api/superzop/systeminventory/getsysteminventory?date=${getStockDateForAPI()}&item_id=${item.articleId}`;
                    baseUrl.get(getUrl).then(response => {
                        console.log(response);
                        if (response.status === 200) {
                            return response.data;
                        }
                        throw response;
                    }).then(data => {
                        console.log(data[0]);
                        setStockDetails(data[0]);

                        setCategory(item.category != undefined ? item.category : data[0].category)
                        setSubCategory(item.sub_category != undefined ? item.sub_category : data[0].sub_category)
                        setUomValue(item.uom ? item.uom : data[0].uom)
                        setLotSize(item.outer_size ? item.outer_size : data[0].outer_size)

                        outersizeRef.current.value = item.outer_size ? item.outer_size : data[0].outer_size
                        console.log(data[0]?.stock_data)
                        setSystemInventory(data[0]?Number(data[0].system_inventory):"")

                    }).catch(err => console.log(err));
                    StockTakeDatabase.child(`${stockTakeDate.split("-")[2]}/${stockTakeDate.split("-")[1]}/${stockTakeDate}/${item.articleId}`).once("value", snapShot => {
                        if (snapShot.hasChildren()) {
                            const stockTableData = snapShot.val()
                            setLooseStock(Number(stockTableData.loose_stock))
                            setBagOrBoxStock(Number(stockTableData.actual_stock_bag_box))
                            setStockLossGain(Number(stockTableData.stock_loss_gain))
                        }
                    })
                }
            })
        } else if (itemValue) {
            articleInfo.forEach(item => {
                let itemName = item.itemName
                if (item.brand)
                    itemName = item.brand + " - " + item.itemName;

                if (itemValue == itemName) {
                    console.log("Updating based on ArticleName:: " + itemValue + " Article ID :: " + item.articleId + " Category:: " + item.category);

                    setArticleCode(item.articleId)
                    itemNoField.current.value = item.articleId


                    const getUrl = `/api/superzop/systeminventory/getsysteminventory?date=${getStockDateForAPI()}&item_id=${item.articleId}`;
                    baseUrl.get(getUrl).then(response => {
                        console.log(response);
                        if (response.status === 200) {
                            return response.data;
                        }
                        throw response;
                    }).then(data => {
                        console.log(data[0]);
                        setStockDetails(data[0]);

                        setCategory(item.category != undefined ? item.category : data[0].category)
                        setSubCategory(item.sub_category != undefined ? item.sub_category : data[0].sub_category)
                        setUomValue(item.uom)
                        setLotSize(item.outer_size)

                        outersizeRef.current.value = item.outer_size ? item.outer_size : data[0].outer_size
                        console.log(data[0].system_inventory)
                        setSystemInventory(data[0]?Number(data[0].system_inventory):"")
                    }).catch(err => console.log(err));

                    StockTakeDatabase.child(`${stockTakeDate.split("-")[2]}/${stockTakeDate.split("-")[1]}/${stockTakeDate}/${item.articleId}`).once("value", snapShot => {
                        if (snapShot.hasChildren()) {
                            console.log(snapShot.val())
                            const stockTableData = snapShot.val()
                            setActualStock(Number(stockTableData.actual_stock))
                            setLooseStock(Number(stockTableData.loose_stock))
                            setBagOrBoxStock(Number(stockTableData.actual_stock_bag_box))
                            setStockLossGain(Number(stockTableData.stock_loss_gain))
                        }
                    })

                }
            })
        }

    }

    const getStockDate = () => {
        let currentMn = ""
        let currentDt = ""

        if (stockDate)
            return stockDate;
        if ((dateToModify.getDate()) < 10) {
            currentDt = "0" + (dateToModify.getDate())
        }
        else { currentDt = dateToModify.getDate() }
        if ((dateToModify.getMonth() + 1) < 10)
            currentMn = "0" + (dateToModify.getMonth() + 1);
        else { currentMn = dateToModify.getMonth() + 1 }
        return `${currentDt}-${currentMn}-${dateToModify.getFullYear()}`
    }

    const getStockDateForAPI = () => {
        let currentMn = ""
        let currentDt = ""

        if (stockDate) {
            let stockDateSplit = stockDate.split('-')
            return `${stockDateSplit[2]}-${stockDateSplit[1]}-${stockDateSplit[0]}`
        }
        if ((dateToModify.getDate()) < 10) {
            currentDt = "0" + (dateToModify.getDate())
        }
        else { currentDt = dateToModify.getDate() }
        if ((dateToModify.getMonth() + 1) < 10)
            currentMn = "0" + (dateToModify.getMonth() + 1);
        else { currentMn = dateToModify.getMonth() + 1 }
        return `${dateToModify.getFullYear()}-${currentMn}-${currentDt}`
    }

    const PushStockTakeDetails = (event) => {
        event.preventDefault();
        let todaysDate = ""
        let currentMn = ""
        let currentDt = ""
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            let stockTakeDate = stockDate;
            if (articleCode !== "" || itemName !== "" || systemInventory  !== "") {
                if (bagOrBoxStock !== "") {
                    btnRef.current.setAttribute("disabled", "disabled");
                    const randomKey = articleCode + "-" + Math.floor((Math.random() * 100) + 1); //itemKey
                    stockTakeDate = getStockDate();
                    console.log('stockTakeDate: ', stockTakeDate);

                    const isAdmin = validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL));

                    const stockTakeDt = new Date(stockTakeDate.split("-")[2], stockTakeDate.split("-")[1], stockTakeDate.split("-")[0]);
                    const year = stockTakeDt.getFullYear();
                    const month = stockTakeDt.getMonth().toString().length === 1 ? `0${stockTakeDt.getMonth()}` : stockTakeDt.getMonth();
                    const date = stockTakeDt.getDate().toString().length === 1 ? `0${stockTakeDt.getDate()}` : stockTakeDt.getDate();

                    const stock_take_date_format = `${year}-${month}-${date}`;

                    const currentDateObj = new Date();

                    const currYear = currentDateObj.getFullYear();
                    const currMonth = currentDateObj.getMonth().toString().length === 1 ? `0${currentDateObj.getMonth() + 1}` : currentDateObj.getMonth() + 1;
                    const currDate = currentDateObj.getDate().toString().length === 1 ? `0${currentDateObj.getDate()}` : currentDateObj.getDate();

                    let fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
                    fourDaysOlderDate = fourDaysOlderDate.setDate(fourDaysOlderDate.getDate() - 4);

                    console.log('stock_take_date_format: ', stock_take_date_format, ' fourDaysOlderDate: ', fourDaysOlderDate, ' isAdmin: ', isAdmin);

                    if (!isAdmin) {
                        if (new Date(stock_take_date_format) < new Date(fourDaysOlderDate)) {

                            console.log("if (new Date(stock_take_date_format) > new Date(fourDaysGreaterDate)) {");

                            return toast('Stock Take date can not be older than 4 days from current date', { type: toast.TYPE.ERROR });
                        }
                    }

                    const obj = {
                        "stock_date": stockTakeDate,
                        "category": category,
                        "article_code": articleCode,
                        "sub_category": subCategory,
                        "UOM": uomValue,
                        "item_name": itemName,
                        "outer_size": outerSize || outersizeRef.current.value,
                        "system_inventory": systemInventory ? systemInventory : '',
                        "inventory_outers": inventoryOuters ? inventoryOuters : '',
                        "actual_stock_bag_box": bagOrBoxStock,
                        "loose_stock": looseStock,
                        "actual_stock": actualStock,
                        "stock_loss_gain": stockLossGain,
                        "created_by": UserEmail(), "created_at": `${UpdatedDate()} ${UpdatedTime()}`
                    }

                    const fireBasProm = new Promise((resolve, reject) => {

                        StockTakeDatabase.child(`${stockTakeDate.split("-")[2]}/${stockTakeDate.split("-")[1]}/${stockTakeDate}`).child(articleCode).update(obj).then(() => {
                            resolve("Success");
                        }).catch(error => {
                            reject(error);
                        });
                    });

                    const mysqlProm = new Promise((resolve, reject) => {

                        //const stock_take_sql = `${stockTakeDate.split("-")[2]}-${stockTakeDate.split("-")[1]}-${stockTakeDate.split("-")[0]}`;

                        //obj.stock_date = stock_take_sql;
                        obj.unique_key = `${stockTakeDate}-${obj.article_code}`
                        const postUrl = `/api/superzop/commercial/productstocktake/createproductstocktakerecord`;

                        baseUrl.post(postUrl, obj).then(() => {
                            resolve("Success");
                        }).catch(error => {
                            reject(error);
                        });
                    });

                    Promise.all([fireBasProm, mysqlProm]).then(() => {
                        setTimeout(() => {
                            window.location.reload();
                            setPushNewRecord(false);
                            // props.history.push('/listStockTake');
                        }, 3000);
                        notifyUser(true);

                    }).catch(error => {
                        notifyUser(false);
                    })
                } else { return toast("Enter Actual Bag/Box stock field ", { type: toast.TYPE.ERROR }) }
            }
            else {
                return toast("Article Code / Outer type / System Inventory can't be empty", { type: toast.TYPE.ERROR })
            }
        }

        setValidated(true)

    }

    const CustomDateInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            style={{ border: "solid 1px black" }}
        />
    );

    const StockTakeList = () => {
        window.open("#/listStockTake");
    };


    return (
        <>
            <NavBar />
            <Form noValidate validated={validated} onSubmit={PushStockTakeDetails}>
                <Navbar className="bg-light mr-auto justify-content-between" style={{ paddingTop: 100 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Badge variant="dark">Create Stock Take Details </Badge>
                    </div>
                    <Button size="sm" variant="success" onClick={() => { StockTakeList() }}>List Stock Take</Button>
                </Navbar>
                {(articleInfo == "") ?
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ display: 'flex', justifyContent: 'center' }}
                        timeout={10000} //20 secs
                    /> :
                    <div>
                        <Table size="sm" >
                            <tr>
                                <td>Stock Take Date</td>
                                <td>
                                    <DatePicker
                                        ref={datepickerRef}
                                        required
                                        selected={dateToModify}
                                        onChange={(val) => { setDateToModify(val);itemName && articleCode && insertDomData(articleCode, itemName); setStockDate((`${val.getDate() < 10 ? "0" + val.getDate() : val.getDate()}-${((val.getMonth()) + 1) < 10 ? "0" + (val.getMonth() + 1) : (val.getMonth() + 1)}-${val.getFullYear()}`)); console.log("val: ",val, " dateToModify: ",dateToModify) }}
                                        minDate={new Date("2017/01/01")}
                                        maxDate={new Date()}
                                        aria-describedby="basic-addon1"
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<CustomDateInput />}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Item Name</td>
                                <td>
                                    {(articleCode == "" || disableList == false) ? <Select required options={articleOptions} ref={itemNameField} onChange={(val) => { setItemName(val.value); let itemName = val.value; let itemCode = null; insertDomData(itemCode, itemName) }} style={{ width: "150px" }} /> :
                                        <Form.Control ref={itemNameField} value={itemName} readOnly />}
                                </td>
                            </tr>
                            <tr>
                                <td>Article Code</td>
                                <td >
                                    {(itemName == "" || disableList) ?
                                        <Form.Control ref={itemNoField} onChange={(e) => { setArticleCode(e.target.value); let itemCode = e.target.value; let itemName = null; insertDomData(itemCode, itemName) }} /> :
                                        <Form.Control ref={itemNoField} onChange={(e) => { setArticleCode(e.target.value); let itemCode = e.target.value; let itemName = null; insertDomData(itemCode, itemName) }} readOnly />}
                                </td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>
                                    <Form.Control ref={categoryField} value={category} readOnly />
                                </td></tr>
                            <tr>
                                <td>Sub-Category</td>
                                <td>
                                    <Form.Control value={subCategory} readOnly />
                                </td>
                            </tr>
                            <tr>
                                <td>UOM</td>
                                <td>
                                    <Form.Control value={uomValue} readOnly />
                                </td>
                            </tr>
                            <tr>
                                <td>Outer Size(Kg/Pc)</td>
                                <td>
                                    <Form.Control ref={outersizeRef} required readOnly onChange={(e) => { setOuterSize(e.target.value); setLotSize(Number(e.target.value)) }} />
                                    <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                </td></tr>
                            <tr>
                                <td>System Inventory(Kg/Pc)</td>
                                <td>
                                    <Form.Control type="number" ref={systemInventoryRef} readOnly value={systemInventory} onChange={(e) => { setSystemInventory(e.target.value); }} />
                                    <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                </td></tr>
                            {/* <tr>
                                <td>Inventory In Outers</td>
                                <td>
                                    <Form.Control ref={outerInventoryRef} readOnly value={inventoryOuters} onChange={(e) => { setInventoryOuters(e.target.value) }} />
                                </td></tr> */}
                            <tr>
                                <td>Actual Stock in Bag /Box</td>
                                <td>
                                    <Form.Control type="number" required value={bagOrBoxStock} onChange={(e) => { setBagOrBoxStock(e.target.value); setActualStock((e.target.value !== "") ? Number((e.target.value) * lotSize) + Number(looseStock) : 0); setStockLossGain(systemInventoryRef.current.value ? Number((e.target.value) * lotSize) + Number(looseStock) - Number(systemInventoryRef.current.value) : "") }} />
                                    <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                </td></tr>
                            <tr>
                                <td>Actual Loose Stock Kg/Pc</td>
                                <td>
                                    <Form.Control type="number" defaultValue={looseStock} onChange={(e) => { setLooseStock(e.target.value); setActualStock(Number(bagOrBoxStock * lotSize) + Number(e.target.value) ); setStockLossGain(bagOrBoxStock && systemInventoryRef.current.value ? Number(bagOrBoxStock * lotSize) + Number(e.target.value) - Number(systemInventoryRef.current.value) : "") }} />
                                    {/* <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback> */}
                                </td></tr>
                            <tr>
                                <td>Actual Stock (Kg/Pc)</td>
                                <td>
                                    <Form.Control ref={actualStockRef} readonly required value={actualStock ? actualStock : 0} />
                                    <Form.Control.Feedback type="invalid">*Required</Form.Control.Feedback>
                                </td></tr>
                            <tr>
                                <td>Stock Loss/Gain (Kg/Pc)	</td>
                                <td>
                                    <Form.Control ref={stockLossGainRef} onChange={(e) => { setStockLossGain(e.target.value) }} value={stockLossGain} />
                                </td></tr>
                        </Table>
                        <center><Button variant="success" type="submit" ref={btnRef}>Add Stock Take Details  </Button></center>
                        <br />
                    </div>}

            </Form>

        </>
    )

}

export default AddStockTake
