import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import {
  Badge,
  Form,
  Dropdown,
  Navbar,
  Jumbotron,
  InputGroup,
  Button,
  Alert,
  ButtonGroup,
  Table,
  Modal,
  Row,
  Col,
  Container
} from "react-bootstrap";
import { InputGroupAddon } from "reactstrap";
import { toast } from "react-toastify";
import database from "../../service/firebaseConfig";
import "react-toastify/dist/ReactToastify.css";
import XLSX from "xlsx";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import Backdrop from "../../CommonRequirements/backdrop";
import StockEditModal from "./StockConversionEditModal";
import baseUrl ,{ DELIVERY_BASE_URL } from "../../service/servicesConfig";
import {
  validateIsAdminUser,
  validateDCManagerUser,
} from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import ListConversionOpenModal from "./ListConversionOpenModal";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import firebase from "../../service/firebaseConfig";
import axios from "axios";

const Product_Base = database.database().ref(`Product_Base_Data`);

function StockConversionMain(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [stockConversionList, setStockConversionList] = useState("");
  const [convertedStockList, setConvertedStockList] = useState("");
  const [exportData, setExportData] = useState(false);
  const [getBackDrop, setBackDrop] = useState(false);
  const [conversionListSearch, setConversionListSearch] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [editConversionModal, setEditConversionModal] = useState(false);
  const [infoItem, setInfoItem] = useState("");
  const [childInfoItem, setchildInfoItem] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [stockConvAndConvStkList, setStockConvAndConvStkList] = useState("");
  const [stkConvEntryClose, setStkConvEntryClose] = useState(false);
  const [listConversionOpen, setListConversionOpen] = useState(false);
  const [BarCodeList, setBarCodeList] = useState([]);
  const [BarCodeSplitList, setBarCodeSplitList] = useState([]);
  const [Product, setProduct] = useState({});
  const [ProductItem, setProductItem] = useState({});
  const [varietyChildItem,setVarietyChildItem] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  async function fetchFromFirebase(id) {
    let ref = firebase.database().ref(`Product_Master_New/A/${id}`);
    let snapshot = await ref.once("value");
    let val = snapshot.val();
    console.log(val);
    setProduct(val);
  }

  const handleShow = async (item) => {
    fetchFromFirebase(item.child_item_code);

    // if (!item.grn_no)
    //   return toast("Error : Unable to fetch qr codes, GRN No not found.", {
    //     type: toast.TYPE.ERROR,
    //   });
    const url = `${DELIVERY_BASE_URL}/api/superzop/get-qr-id-grn?item_id=${item.child_item_code}&grn_date=${item.stock_transfer_date}`;

    let toast_id = toast("Fetching data, Please wait", {
      type: toast.TYPE.SUCCESS,
      autoClose: false,
    });

    let res = await axios
      .get(url)
      .catch((err) => {
        console.log("Error occured while fetching qr ids", err.message);
        return toast(`Error occured while fetching qr ids, ${err.message}`, {
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        toast.dismiss(toast_id);
      });

    const { data } = res.data;
    let qr_list = [];
    let qr_list_split = [];

    if(data.length == 0) {
      toast(`No qr ids fetched for: ${item.child_item_code}`, {
        type: toast.TYPE.SUCCESS,
      })
    } else {
      data.forEach((item, index) => {
        const qrcode = item.qr_id;
        qr_list_split.push(qrcode);
        qr_list.push(qrcode);
      });
      let sp = item?.child_item_name.split("-").join(',').split('|');
      let new_item_name = sp[0];
      let obj = item;
      obj["new_item_name"] = new_item_name;
      setProductItem(obj);
      setBarCodeList(qr_list);
      setBarCodeSplitList(qr_list_split);
      setShow(true);
    }
  };

  let filterResult = "";

  let componentRef = useRef();

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_CENTER,
  });

  const notify = (unavailability) => {
    if (unavailability)
      return toast("No Stock conversions available for selected date ", {
        type: toast.TYPE.ERROR,
      });
  };

  const monthsList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    setBackDrop(true);

    Product_Base.once("value", (snapShot) => {
      let varietyMap = {};
      const articleCodeList = snapShot.val();
    
      Object.keys(articleCodeList).forEach((articleCode) => {
          if(!varietyMap[articleCode])
          varietyMap[articleCode] = [];

          varietyMap[articleCode].push(articleCodeList[articleCode]["variety"])
       });
      setBackDrop(false);    
      setVarietyChildItem(varietyMap);
    });
    //startDate.setDate(startDate.getDate() - 1)
    const startDateConversion = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()
    }`;

    const endDateConversion = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1 < 10
        ? "0" + (endDate.getMonth() + 1)
        : endDate.getMonth() + 1
    }-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`;

    stockConversionEntryDateChange();

    baseUrl
      .get(
        `/api/superzop/commercial/stockconversion/getstockconversiondata?start_date=${startDateConversion}&end_date=${endDateConversion}`
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .then((data) => {
        baseUrl
          .get(
            `/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${endDateConversion}`
          )
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .then((convStkData) => {
            setConvertedStockList(convStkData);
            setStockConversionList(data);
            setBackDrop(false);
            if (!data.length > 0) {
              notify(true);
            }
            const parentChildList = data.map((item) => {
              const childCodeList = convStkData.filter((childItem) => {
                const child_mapping_id = childItem.mapping_id;

                const parent_mapping_id = item.mapping_id;

                if (parent_mapping_id)
                  return child_mapping_id === parent_mapping_id;
              });

              if (childCodeList.length > 0) {
                const obj = { ...childCodeList[0], ...item };
                obj.child_id = childCodeList[0].id;
                obj.child_uom = childCodeList[0].uom;
                return obj;
              } else return item;
            });

            setStockConvAndConvStkList(parentChildList);

            console.log("stockConvAndConvStkList: ", stockConvAndConvStkList);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const stockConversionEntryDateChange = () => {
    const complete_date = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()
    }`;

    const stk_conv_entry_close_url = `/api/superzop/commercial/stockconversion/getstockconversioncloserecordbasedonstockconversiondate?stock_conversion_date=${complete_date}`;

    baseUrl
      .get(stk_conv_entry_close_url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
      .then((data) => {
        //console.log(data);

        if (data.length > 0) {
          const stock_conversion_entry_close =
            data[0].stock_conversion_entry_close;

          if (stock_conversion_entry_close == 1) {
            setStkConvEntryClose(true);
          } else setStkConvEntryClose(false);
        } else {
          setStkConvEntryClose(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // return toast("Couldn't update Purchase Details record in MySQL", { type: toast.TYPE.ERROR });
      });
  };

  const handleDateRangeChange = () => {
    let conversionStartDtType = `start_date`;
    let conversionEndDtType = `end_date`;

    setStockConversionList("");
    const startDateConversion = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 0 ? "0" + startDate.getDate() : startDate.getDate()
    }`;

    const endDateConversion = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1 < 10
        ? "0" + (endDate.getMonth() + 1)
        : endDate.getMonth() + 1
    }-${endDate.getDate() < 0 ? "0" + endDate.getDate() : endDate.getDate()}`;

    baseUrl
      .get(
        `/api/superzop/commercial/stockconversion/getstockconversiondata?${conversionStartDtType}=${startDateConversion}&${conversionEndDtType}=${endDateConversion}`
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .then((data) => {
        baseUrl
          .get(
            `/api/superzop/commercial/stockconversion/convertedstocks/getconvertedstocksdata?start_date=${startDateConversion}&end_date=${endDateConversion}`
          )
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .then((convStkData) => {
            setConvertedStockList(convStkData);
            setStockConversionList(data);
            setConversionListSearch(false);
            setBackDrop(false);
            if (!data.length > 0) {
              notify(true);
            }
            const parentChildList = data.map((item) => {
              const childCodeList = convStkData.filter((childItem) => {
                const mapping_id_item = childItem.mapping_id;

                return mapping_id_item === item.mapping_id;
              });

              if (childCodeList.length > 0) {
                const obj = { ...childCodeList[0], ...item };
                obj.child_id = childCodeList[0].id;
                obj.child_uom = childCodeList[0].uom;
                return obj;
              } else return item;
            });

            setStockConvAndConvStkList(parentChildList);

            console.log("stockConvAndConvStkList: ", stockConvAndConvStkList);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (conversionListSearch || startDate) {
      handleDateRangeChange();
      stockConversionEntryDateChange();
    }
  }, [conversionListSearch, startDate]);

  const exportOrderSummary = () => {
    const wb = XLSX.utils.book_new();

    setBackDrop(true);
    let stockConversionSummary = [
      [
        "Stock Transfer Date",
        "Major Category",
        "Parent Name",
        "Parent Code",
        "Outer Size",
        "Stock Transfer (Bags)",
        "Stock Transfer Total Weight (Kgs)",
        "Bag Weight Loss",
        "Child Code",
        "Child Name",
        "Child Outer Size",
        "Converted Pcs",
        "Converted Stock in Kg",
        "Wastage in Kg",
        "Created By",
        "Updated By",
      ],
    ];
    stockConvAndConvStkList.map((item) => {
      stockConversionSummary.push([
        item.stock_transfer_date,
        item.major_category,
        item.parent_name,
        item.parent_code,
        item.outer_size + " " + item.uom,
        item.stock_transfer,
        item.stock_transfer_total_weight,
        item.bag_weight_loss,
        item.child_item_code,
        item.child_item_name,
        item.child_outer_size + " " + item.child_uom,
        item.child_stock_production,
        item.converted_stock,
        item.wastage,
        item.created_by,
        item.updated_by,
      ]);
    });
    const stockListData = XLSX.utils.aoa_to_sheet(stockConversionSummary);
    XLSX.utils.book_append_sheet(wb, stockListData, `Stock Conversion Report`);
    XLSX.writeFile(wb, `stock_conversion_report.xlsx`);
    setExportData(false);
    setBackDrop(false);
  };

  const deleteStockConvertion = (deleteItem) => {
    let loggedEmailId = sessionStorage.getItem(`Logged User Email Id`);

    const dataObj = {
      ...deleteItem,
      updated_by: loggedEmailId,
    };

    //dataObj.updated_by = loggedEmailId;

    console.log("deleteItem: ", dataObj);

    const answer = window.confirm(`Are you sure you want to delete this item?`);

    if (answer) {
      const url = `/api/superzop/commercial/stockconversion/editstockconversionandsi?type=delete_record`;

      baseUrl
        .post(url, dataObj)
        .then((res) => {
          console.log("PushPurchaseDetails ", res);

          //resolve("Success");

          deleteConvertedStock(deleteItem);
          //toast('Deleted Stock Conversion Item and Synced System Inventory', { type: toast.TYPE.SUCCESS });
        })
        .catch((error) => {
          console.error(error);
          //reject(error);
          return toast("Failed to Delete", { type: toast.TYPE.ERROR });
        });
    }
  };

  const deleteConvertedStock = (deleteItem) => {
    console.log("deleteConvertedStock deleteItem: ", deleteItem);

    const loggedEmailId = sessionStorage.getItem(`Logged User Email Id`);

    const childItems = convertedStockList.filter((item) => {
      const mapping_id_item = item.mapping_id;

      return mapping_id_item === deleteItem.mapping_id;
    });

    if (childItems.length > 0) {
      const childItem = childItems[0];

      const dataToDelete = {
        ...childItem,
        updated_by: loggedEmailId,
      };

      console.log("deleteConvertedStock childItem: ", childItem);

      const url = `/api/superzop/commercial/stockconversion/convertedstocks/editconvertedstockandsi?type=delete_record`;

      baseUrl
        .post(url, dataToDelete)
        .then((res) => {
          console.log("Push Converted Stocks ", res);

          //resolve("Success");

          setTimeout(() => {
            window.location.reload();
          }, 2000);

          return toast(
            "Deleted Converted Stock Item and Synced System Inventory",
            { type: toast.TYPE.SUCCESS }
          );
        })
        .catch((error) => {
          console.error(error);
          //reject(error);
          return toast("Failed to Delete", { type: toast.TYPE.ERROR });
        });
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const getChildCode = (mapping_id) => {
    const childCodeList = convertedStockList.filter((item) => {
      const mapping_id_item = item.mapping_id;

      return mapping_id_item === mapping_id;
    });

    if (childCodeList.length > 0) {
      return childCodeList[0].child_item_code;
    } else return "";
  };

  const getChildName = (mapping_id) => {
    const childNameList = convertedStockList.filter((item) => {
      const mapping_id_item = item.mapping_id;

      return mapping_id_item === mapping_id;
    });

    if (childNameList.length > 0) {
      return childNameList[0].child_item_name;
    } else return "";
  };

  const getConvertedStock = (mapping_id) => {
    const convStkList = convertedStockList.filter((item) => {
      const mapping_id_item = item.mapping_id;

      return mapping_id_item === mapping_id;
    });

    if (convStkList.length > 0) {
      return convStkList[0].converted_stock;
    } else return "";
  };

  const getWastage = (mapping_id) => {
    const wastageList = convertedStockList.filter((item) => {
      const mapping_id_item = item.mapping_id;

      return mapping_id_item === mapping_id;
    });

    if (wastageList.length > 0) {
      return wastageList[0].wastage;
    } else return "";
  };

  const setChildItem = (parentItem) => {
    const childItem = convertedStockList.filter((item) => {
      const mapping_id_item = item.mapping_id;

      return mapping_id_item === parentItem.mapping_id;
    });

    if (childItem.length > 0) setchildInfoItem(childItem[0]);
  };

  const searchPurchaseList = () => {
    const filterData = [];
    console.log(stockConversionList);
    filterResult = Object.keys(stockConvAndConvStkList).filter((obj) => {
      const itemId = stockConversionList[obj].parent_code.trim().toLowerCase();
      const itemDesc =
        (stockConversionList[obj].parent_name &&
          stockConversionList[obj].parent_name.trim().toLowerCase()) ||
        "";
      const majorCat =
        (stockConversionList[obj].major_category &&
          stockConversionList[obj].major_category.trim().toLowerCase()) ||
        "";

      setSearchText(searchText.trim().toLowerCase());

      return (
        itemId.match(searchText) ||
        itemDesc.match(searchText) ||
        majorCat.match(searchText)
      );
    });
    filterResult.forEach((items) => {
      console.log(stockConversionList[items]);
      filterData.push(stockConvAndConvStkList[items]);
    });
    setFilterList(filterData);
  };

  useEffect(() => {
    if (searchText) searchPurchaseList();
  }, [searchText]);

  useEffect(() => {
    if (exportData) exportOrderSummary();
  }, [exportData]);

  const parentCodeUrl = (parent_code) => {
    const url = "/stockconversiondetails/" + parent_code;
    return url;
  };

  const closeStkConvEntry = () => {
    const stk_conv_date = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }`;
    const answer = window.confirm(
      `Are you sure you want to close GRN for ${stk_conv_date} date?`
    );

    //console.log("answer: ", answer);

    const today = new Date();
    const date = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
    const time = `${
      today.getHours() < 10 ? "0" + today.getHours() : today.getHours()
    }:${
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()
    }:${
      today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds()
    }`;

    const dateTime = date + " " + time;
    //console.log(dateTime);

    const jsonObj = {
      stock_conversion_date: stk_conv_date,
      stock_conversion_entry_close: true,
      updated_by: "Admin",
      updated_at: dateTime,
    };

    console.log("closeStkConvEntry jsonObj: ", jsonObj);

    if (answer) {
      const url =
        "/api/superzop/commercial/stockconversion/createstockconversionentrycloserecord";

      //baseUrl.post(url, jsonObj).then(res => {
      baseUrl
        .post(url, jsonObj)
        .then((res) => {
          //console.log('closeGRNEntry ', res);
          if (res.status === 200) {
            toast(
              `Closed Stock Conversion for ${stk_conv_date} date successfully`,
              { type: toast.TYPE.SUCCESS }
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function printString(columnNumber) {
    let columnName = [];

    while (columnNumber > 0) {
      let rem = columnNumber % 26;

      if (rem == 0) {
        columnName.push("Z");
        columnNumber = Math.floor(columnNumber / 26) - 1;
      } else {
        columnName.push(String.fromCharCode(rem - 1 + "A".charCodeAt(0)));
        columnNumber = Math.floor(columnNumber / 26);
      }
    }
    return columnName.reverse().join("");
  }

  return (
    <>
      <NavBar />

      <div style={{ paddingTop: 100 }}>
        <Alert
          style={{
            display: "flex",
            textAlign: "justify",
            alignItems: "center",
            justifyContent: "center",
          }}
          variant={"success"}
        >
          Stock Transfer
          <Button
            size="sm"
            style={{ marginLeft: "15px" }}
            onClick={() => props.history.push("/stockConversionListing")}
          >
            <i class="fas fa-list" size="2x"></i>
          </Button>
        </Alert>
        <Navbar className="bg-light mr-auto justify-content-between">
          <Form inline>
            <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
              <Badge variant="dark"> Select Start Date </Badge>
              <DatePicker
                selected={startDate}
                onChange={(val) => {
                  setStartDate(val);
                  setBackDrop(true);
                }}
                minDate={new Date("2017/01/01")}
                maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>

            <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 20 }}>
              <Badge variant="dark"> Select End Date </Badge>
              <DatePicker
                selected={endDate}
                onChange={(val) => {
                  setEndDate(val);
                  setBackDrop(true);
                }}
                minDate={new Date("2017/01/01")}
                maxDate={new Date()}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>

            <Form.Control
              id="searchProduct"
              size="sm"
              style={{ width: "400px" }}
              className="searchBox"
              placeholder="Parent Id / Parent Name /Major Category "
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value == "") setFilterList("");
              }}
            />
            <Button
              style={{ marginLeft: 60 }}
              size="sm"
              onClick={() => window.open("#/addStockConversion")}
            >
              Add Stock
            </Button>
            <InputGroup size="sm" style={{ margin: 10 }}>
              <Button
                size="sm"
                variant="success"
                onClick={() => setExportData(true)}
              >
                Export
              </Button>
            </InputGroup>
            {!stkConvEntryClose ? (
              <Button
                size="sm"
                variant="success"
                style={{ margin: 10 }}
                onClick={() => {
                  closeStkConvEntry();
                }}
              >
                Conversion Open
              </Button>
            ) : (
              <Button
                size="sm"
                variant="danger"
                disabled={true}
                style={{ margin: 10 }}
              >
                Conversion Closed
              </Button>
            )}
            {validateDCManagerUser(
              sessionStorage.getItem(LOGGED_USER_EMAIL)
            ) ? (
              <Button
                size="sm"
                variant={"warning"}
                style={{ margin: 10 }}
                onClick={() => {
                  setListConversionOpen(true);
                }}
              >
                List Conversion Open
              </Button>
            ) : null}
          </Form>
        </Navbar>
        {stockConversionList.length > 0 && (
          <Table striped bordered>
            <thead>
              <tr>
                <td>Stock Transfer Date</td>
                <td>Major Category</td>
                <td>Parent Code</td>
                <td>Parent Name</td>
                <td>Outer Size</td>
                <td>Stock Transfer (Bags)</td>
                <td>Stock Transfer Total Weight (Kgs)</td>
                <td>Bag Weight Loss</td>
                <td>Child Code</td>
                <td>Child Name</td>
                <td>Child Outer Size</td>
                <td>Converted Stock in Kg</td>
                <td>Wastage in Kg</td>
                <td>Consumed qr id</td>
                <td>Created By</td>
                <td>Updated By</td>
                {validateIsAdminUser(
                  sessionStorage.getItem(LOGGED_USER_EMAIL)
                ) && <td>Edit </td>}
                <td>X </td>
                <td>Print QR</td>
              </tr>
            </thead>
            <tbody>
              {stockConvAndConvStkList.length > 0 && filterList.length == 0
                ? stockConvAndConvStkList.map((items) => (
                    <tr>
                      <td>{items.stock_transfer_date}</td>
                      <td>{items.major_category}</td>
                      <td>
                        <Link to={parentCodeUrl(items.parent_code)}>
                          {items.parent_code}
                        </Link>
                      </td>
                      <td>{items.parent_name}</td>
                      <td>
                        {items.outer_size} {items.uom}
                      </td>
                      <td>{items.stock_transfer}</td>
                      <td>{items.stock_transfer_total_weight}</td>
                      <td>{items.bag_weight_loss}</td>
                      <td>{items.child_item_code}</td>
                      <td>{items.child_item_name}</td>
                      <td>
                        {items.child_outer_size} {items.child_uom}
                      </td>
                      <td>{items.converted_stock}</td>
                      <td>{items.wastage}</td>
                      <td>
                        <ul style={{ listStyleType: "none" }}>
                          {items.consumed_qr_id.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </td>

                      <td>
                        {items.created_by && items.created_by.split("@")[0]}
                      </td>
                      <td>
                        {items.updated_by && items.updated_by.split("@")[0]}
                      </td>
                      {validateIsAdminUser(
                        sessionStorage.getItem(LOGGED_USER_EMAIL)
                      ) && (
                        <td>
                          <Button
                            disabled={stkConvEntryClose}
                            onClick={() => {
                              setEditConversionModal(true);
                              setInfoItem(items);
                              setChildItem(items);
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                      )}
                      <td>
                        <Button
                          disabled={stkConvEntryClose}
                          onClick={() => {
                            deleteStockConvertion(
                              items
                            ); /*deleteConvertedStock(items)*/
                          }}
                        >
                          <i
                            style={{ color: "white" }}
                            class="fas fa-trash-alt fa-1x"
                          ></i>
                        </Button>
                      </td>
                      <td>
                        {varietyChildItem[items.child_item_code] == 'GONI' &&
                        <Button
                          variant="primary"
                          onClick={() => handleShow(items)}
                        >
                          {" "}
                          Qr{" "}
                        </Button>}
                      </td>
                    </tr>
                  ))
                : filterList.map((items) => (
                    <tr>
                      <td>{items.stock_transfer_date}</td>
                      <td>{items.major_category}</td>
                      <td>{items.parent_code}</td>
                      <td>{items.parent_name}</td>
                      <td>
                        {items.outer_size} {items.uom}
                      </td>
                      <td>{items.stock_transfer}</td>
                      <td>{items.stock_transfer_total_weight}</td>
                      <td>{items.bag_weight_loss}</td>
                      <td>{items.child_item_code}</td>
                      <td>{items.child_item_name}</td>
                      <td>
                        {items.child_outer_size} {items.child_uom}
                      </td>
                      <td>{items.converted_stock}</td>
                      <td>{items.wastage}</td>
                      <td>
                        {items.created_by && items.created_by.split("@")[0]}
                      </td>
                      <td>
                        {items.updated_by && items.updated_by.split("@")[0]}
                      </td>
                      {validateIsAdminUser(
                        sessionStorage.getItem(LOGGED_USER_EMAIL)
                      ) && (
                        <td>
                          <Button
                            disabled={stkConvEntryClose}
                            onClick={() => {
                              setEditConversionModal(true);
                              setInfoItem(items);
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                      )}
                      <td>
                        <Button
                          disabled={stkConvEntryClose}
                          onClick={() => {
                            deleteStockConvertion(items);
                          }}
                        >
                          <i
                            style={{ color: "white" }}
                            class="fas fa-trash-alt fa-1x"
                          ></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        )}
      </div>

      {editConversionModal && (
        <StockEditModal
          show={editConversionModal}
          dataItems={infoItem}
          childDataItems={childInfoItem}
          onHide={() => setEditConversionModal(false)}
        />
      )}
      {listConversionOpen && (
        <ListConversionOpenModal
          show={listConversionOpen}
          onHide={() => setListConversionOpen(false)}
        />
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Print Qr Code </Modal.Title>
          <style type="text/css" media="print">
            {
              "\
              @page { size: 50mm 25mm, orientation:portrait  }\
            "
            }
          </style>
          <ReactToPrint
            trigger={() => <Button> Print </Button>}
            content={() => componentRef}
          />
        </Modal.Header>

        <Modal.Body ref={(el) => (componentRef = el)}>
          <Container>
            {BarCodeList &&
              BarCodeList.map((item, i) => {
                return (
                  <div
                    style={{
                      pageBreakAfter: "always",
                      marginTop: "300px",
                    }}
                  >
                    <Row className="">
                      <Col>
                        <QRCode
                          title="stockConversion"
                          value={item}
                          size={500}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "auto",
                          }}
                          viewBox={`0 0 200 200`}
                        />
                        <h3 style={{ "font-size": "3.0em" }}>
                          {BarCodeSplitList[i]}{" "}
                        </h3>
                      </Col>
                      <Col>
                        <h2 style={{ "font-size": "5.5em" }}>
                          <b>
                            <u> {ProductItem?.new_item_name?.toUpperCase()} </u>
                          </b>
                        </h2>

                        {/* <p> Bar code : {item} </p> */}
                        {/* <p>BATCH NO : </p> */}

                        {/* <b>NET QUANTITY: </b> {ProductItem?.total_weight} {' '}  {ProductItem?.uom} <br/> */}
                        {/* <b>MRP:</b>  {Product?.market_price} {' '}  <br/> */}
                        {/* <b>DATE OF PACKAGING:</b>  {ProductItem?.date_of_packing} {' '} <br/> */}
                        {/* <b>BEST BEFORE :</b>  {'90 Days'} {' '} <br/>
                            <b>POCESSED & PACKAGED BY : </b>  Retranz Infolabs Private Limited. D1, Indian corporation compound Diva Anjur Village, bhiwandi Thane - 421302 (Maharastia) */}
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <style type="text/css" media="print">
            {
              "\
              @page { size: 50mm 25mm  }\
            "
            }
          </style>
          <ReactToPrint
            trigger={() => <Button> Print </Button>}
            content={() => componentRef}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StockConversionMain;
