import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Center, Flex, Grid, Image, SimpleGrid, Text } from "@mantine/core";

export function GridUIForType({ type, data }) {
  if (!type) return <></>;

  switch (type) {
    case "CATEGORY":
      return (
        <React.Fragment>
          <Center>
            <Text style={{ marginBottom: "1rem" }} fw="600" fz="lg">
              CATEGORY
            </Text>
          </Center>
          <SimpleGrid cols={3} spacing={0}>
            {data.map((item, index) => {
              return (
                <div>
                  <Card
                    style={{ borderRadius: 0, height: "10rem" }}
                    variant="outlined"
                  >
                    <CardContent>
                      <Image
                        maw={90}
                        mx="auto"
                        radius="md"
                        src={item.image_url}
                      />
                      <Center>
                        <Text fz="xs" style={{ marginTop: "1rem" }}>
                          {item.category}
                        </Text>
                      </Center>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </SimpleGrid>
        </React.Fragment>
      );
  }
}

export function GridPreviewWidget({ data }) {
  return (
    <>
      <GridUIForType type={data.type} data={data.data} />
    </>
  );
}

export default Grid;
