import React, { useState,useEffect } from 'react'
import { Modal, Alert, Dropdown, DropdownButton, Card, Table, Button, Form, ButtonGroup, InputGroup, Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import database from '../../service/firebaseConfig';

const PurchaseDetailsData = database.database().ref(`Purchase_Details`)

function EditListing({ data, token,selectedDate, ...props }) {



    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            return toast("Edit Details updated", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't Update the Edit Details", { type: toast.TYPE.ERROR });
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form style={{ display: "flex", flexDirection: "column" }} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Listing
        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {Object.keys(data).map((itemsList, index) => (
                            data[itemsList].grn_no === token ? <>
                                <Accordion defaultActiveKey={index}>
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                                {/* {itemsList} */}
                                                {data[itemsList].uniqueId.substring(11)}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body>
                                                <Table striped bordered>
                                                    <tr>
                                                        <td>Article code</td>
                                                        <td><Form.Control readOnly defaultValue={data[itemsList].article_code} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Category</td>
                                                        <td><Form.Control readOnly defaultValue={data[itemsList].category} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Item Name</td>
                                                        <td><Form.Control readOnly defaultValue={data[itemsList].item_name} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Outer Type</td>
                                                        <td><Form.Control readOnly defaultValue={data[itemsList].outer_type} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Outer Size (Kg/Pc)</td>
                                                        <td><Form.Control readOnly defaultValue={data[itemsList].outer_size} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bags Received</td>
                                                        <td><Form.Control readOnly defaultValue={data[itemsList].bags_received} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Weight Received(Kg/Pc)</td>
                                                        <td><Form.Control readOnly readOnly defaultValue={data[itemsList].total_weight_received} /></td>
                                                    </tr>
                                                </Table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion></> : ""
                        ))}
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default EditListing
