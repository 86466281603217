import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import { Breadcrumb, Tab, Form, Row, Col, Nav, Button, InputGroup, Badge } from 'react-bootstrap';
import Select from 'react-select';
import qualitydb from '../../service/qualityfirebaseconfig';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import XLSX from 'xlsx'
import database from '../../service/firebaseConfig';
import LoaderComp from '../../components/Loader';
import { validateGRNOnlyUser, validateIsAdminUser, validatePurchaseUser, validatePurchaseExportUser, validatePurchaseExportOnlyUser, validatePurchaseOnlyUser, validatePurchaseReportUser } from '../../service/credentials';
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import PriceAnalysisTable from './priceanalysistable';
import baseUrl from '../../service/servicesConfig';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const PriceAnalysis = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [priceAnalysisList, setPriceAnalysisList] = useState([]);
  const [searchPriceAnalysisList, setSearchPriceAnalysisList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [priceChangeText, setPriceChangeText] = useState("");
  const [finalPriceChartData, setFinalPriceChartData] = useState([]);

  useEffect(() => {
    if (priceAnalysisList.length > 0) {
      generateChartData();
    } else {
      setFinalPriceChartData([]);
    }

  }, [priceAnalysisList])

  // useEffect(() => {
  //   if (searchText) {
  //     filterProductPriceDetails();
  //   } else {
  //     setPriceAnalysisList(searchPriceAnalysisList);
  //   }
  // }, [searchText])

  useEffect(() => {
    if (priceChangeText) {
      filterProductsByPriceChange();
    } else {
      setPriceAnalysisList(searchPriceAnalysisList);
    }
  }, [priceChangeText])

  const filterProductPriceDetails = () => {
    console.log(searchText);

    let checksearchtext = searchText.trim().toLowerCase();
    let filteredData = [];
    for (let i = 0; i < searchPriceAnalysisList.length; i++) {
      let pushflag = false;
      let itemname = searchPriceAnalysisList[i]['item_name'];
      let itemid = searchPriceAnalysisList[i]['article_code'];
      let category = searchPriceAnalysisList[i]['category'];

      if (itemname) {
        itemname = itemname.trim().toLowerCase();
        if (itemname.includes(checksearchtext)) {
          pushflag = true;
          filteredData.push(searchPriceAnalysisList[i]);
        }
      }

      if (itemid && pushflag == false) {
        itemid = itemid.trim().toLowerCase();
        if (itemid.includes(checksearchtext)) {
          filteredData.push(searchPriceAnalysisList[i]);
          pushflag = true;
        }
      }

      if (category && pushflag == false) {
        category = category.trim().toLowerCase();
        if (category.includes(checksearchtext)) {
          filteredData.push(searchPriceAnalysisList[i]);
          pushflag = true;
        }
      }
    }
    setPriceAnalysisList(filteredData);
  }

  const filterProductsByPriceChange = () => {
    console.log(priceChangeText);
    let filteredData = [];
    if (priceChangeText == "All") {
      setPriceAnalysisList(searchPriceAnalysisList);
    } else {
      for (let i = 0; i < searchPriceAnalysisList.length; i++) {
        let purchasePrice = Number(searchPriceAnalysisList[i]['purchase_price']);
        let basePrice = Number(searchPriceAnalysisList[i]['base_price']);

        if (priceChangeText == "Increased") {
          if (purchasePrice > basePrice) {
            filteredData.push(searchPriceAnalysisList[i]);
          }
        } else if (priceChangeText == "Decreased") {
          if (purchasePrice < basePrice) {
            filteredData.push(searchPriceAnalysisList[i]);
          }
        } else if (priceChangeText == "No Change") {
          if (purchasePrice == basePrice) {
            filteredData.push(searchPriceAnalysisList[i]);
          }
        }
      }
      setPriceAnalysisList(filteredData);
    }
  }

  const fetchProducts = () => {
    let finalStartDate = formatDate(new Date(startDate));
    let finalEndDate = formatDate(new Date(endDate));
    let checksearchtext = searchText?searchText.trim().toLowerCase():"";
    console.log(finalStartDate);
    console.log(finalEndDate);
    console.log(checksearchtext);

    setLoading(true);

    //let apiurlstr=`https://services2.superzop.com/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${finalStartDate}&purchase_end_date=${finalEndDate}`;

    const apiurlstr = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${finalStartDate}&purchase_end_date=${finalEndDate}`;

    Promise.all([
      baseUrl.get(apiurlstr).then(response => { return response.data }),
      database.database().ref(`Product_Master_New/A/`).once('value')
    ]).then(([result, productsnap]) => {
      let productData = productsnap.val() || {};
      console.log(productData);
      console.log("result");
      console.log(result);

      let filteredData = [];
      for (let i = 0; i < result.length; i++) {
        let pushflag = false;
        let itemname = result[i]['item_name'];
        let itemid = result[i]['article_code'];
        let category = result[i]['category'];
        let brand = productData[itemid]?productData[itemid].brand:"";

        if (itemname) {
          itemname = itemname.trim().toLowerCase();
          if (itemname.includes(checksearchtext)) {
            pushflag = true;
            filteredData.push(result[i]);
          }
        }

        if (itemid && pushflag == false) {
          itemid = itemid.trim().toLowerCase();
          if (itemid.includes(checksearchtext)) {
            filteredData.push(result[i]);
            pushflag = true;
          }
        }

        if (category && pushflag == false) {
          category = category.trim().toLowerCase();
          if (category.includes(checksearchtext)) {
            filteredData.push(result[i]);
            pushflag = true;
          }
        }

        if (brand && pushflag == false) {
          brand = brand.trim().toLowerCase();
          if (brand.includes(checksearchtext)) {
            filteredData.push(result[i]);
            pushflag = true;
          }
        }
      }

      setLoading(false);
      setPriceAnalysisList(filteredData);
      setSearchPriceAnalysisList(filteredData);
    }).catch((error) => {
      setLoading(false);
      setPriceAnalysisList([]);
      setSearchPriceAnalysisList([]);
    });

  }

  const onFormSubmit = () => {
    setLoading(true);
    setPriceAnalysisList([]);
    setSearchPriceAnalysisList([]);
    setFinalPriceChartData([]);
    setPriceChangeText(null);

    fetchProducts();
  }

  const handleStartDateChange = (selecteddate) => {
    setStartDate(new Date(selecteddate));
  }

  const handleEndDateChange = (selecteddate) => {
    setEndDate(new Date(selecteddate));
  }

  const generateChartData = () => {
    console.log('generateChartData');

    let distinctItemSet = new Set();
    for (let i = 0; i < priceAnalysisList.length; i++) {
      let itemid = priceAnalysisList[i]['article_code'];
      if (!distinctItemSet.has(itemid)) {
        distinctItemSet.add(itemid);
      }
    }
    // console.log(distinctItemSet);
    let chartHeaderDetails = [];
    chartHeaderDetails.push("X");
    distinctItemSet.forEach(item => chartHeaderDetails.push(item));
    // console.log(chartHeaderDetails);

    let dateRangeList = [];
    for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
      dateRangeList.push(formatDate(new Date(d)));
    }
    console.log(dateRangeList);

    //create actual chart data
    let chartItemPurchaseDataObj = {};
    let finalChartHeaderDetails = [];
    finalChartHeaderDetails.push("X");
    distinctItemSet.forEach((item) => {
      let chartItemDataObj = {};
      for (let i = 0; i < priceAnalysisList.length; i++) {
        let itemid = priceAnalysisList[i]['article_code'];
        let purchasedate = priceAnalysisList[i]['purchased_date'];
        let purchaseprice = priceAnalysisList[i]['purchase_price'];
        if (itemid == item) {
          if (purchaseprice) {
            chartItemDataObj[purchasedate] = purchaseprice;
          }
        }
      }
      if (Object.keys(chartItemDataObj).length > 0) {
        chartItemPurchaseDataObj[item] = chartItemDataObj;
        finalChartHeaderDetails.push(item);
      }
    });
    console.log(chartItemPurchaseDataObj);

    //build basic skeleton of data
    let chartData = [];
    chartData.push(finalChartHeaderDetails);
    for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
      let currdate = formatDate(new Date(d));
      let itemDataList = [];
      itemDataList.push(currdate);

      for (let z = 1; z < finalChartHeaderDetails.length; z++) {
        let itemid = finalChartHeaderDetails[z];
        if (chartItemPurchaseDataObj[itemid]) {
          let currItemPurchasePrice = chartItemPurchaseDataObj[itemid][currdate] ? Number(chartItemPurchaseDataObj[itemid][currdate]) : null;
          itemDataList.push(currItemPurchasePrice);
        } else {
          itemDataList.push(null);
        }
      }
      chartData.push(itemDataList);
    }
    console.log('final chart data');
    console.log(chartData);

    let numrows = chartData.length;
    let numcols = chartData[0].length;

    for (let i = 1; i < numcols; i++) {
      let currItemPrice = 0;
      for (let j = 1; j < chartData.length; j++) {
        if (chartData[j][i]) {
          currItemPrice = chartData[j][i];
        } else {
          if (currItemPrice) {
            chartData[j][i] = currItemPrice;
          }
        }
      }
    }
    console.log(distinctItemSet);

    if (distinctItemSet.size > 0) {
      setFinalPriceChartData(chartData);
    } else {
      setFinalPriceChartData([]);
    }


  }

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };


  return (
    <>
      <NavBar />
      <Form inline style={{ margin: 30, width: '90%', marginLeft: "auto", marginRight: "auto", paddingTop: 100 }}>
        <InputGroup size="sm" style={{ margin: 5 }}>
          <InputGroupAddon addonType="prepend">Start Date</InputGroupAddon>
          <DatePicker
            selected={startDate}
            onChange={(handleStartDateChange)}
            minDate={new Date("2017/01/01")}
            maxDate={new Date()}
            aria-describedby="basic-addon1"
            dateFormat="dd/MM/yyyy"
            customInput={<CustomDateInput />}
          />
        </InputGroup>
        <InputGroup size="sm" style={{ margin: 5 }}>
          <InputGroupAddon addonType="prepend">End Date</InputGroupAddon>
          <DatePicker
            selected={endDate}
            onChange={(handleEndDateChange)}
            minDate={new Date("2017/01/01")}
            maxDate={new Date()}
            aria-describedby="basic-addon1"
            dateFormat="dd/MM/yyyy"
            customInput={<CustomDateInput />}
          />
        </InputGroup>
      </Form>
      <div className="row" style={{ margin: 5 }}>
        <div className="col-md-3">
          <Form.Control type="search" style={{ marginLeft: 10 }} placeholder="ItemID / ItemName / Brand / Category" value={searchText} onChange={(e) => { handleKeyPress(e); }} />
        </div>
        <div className="col-md-3">
          <Button style={{ margin: 10 }} variant="success" onClick={() => { onFormSubmit(); }}>Submit</Button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <Select options={[{ "value": "All", "label": "All" }, { "value": "No Change", "label": "No Change" }, { "value": "Increased", "label": "Increased" }, { "value": "Decreased", "label": "Decreased" }]} placeholder={priceChangeText ? priceChangeText : 'Price ➼ All'} onChange={(val) => { setPriceChangeText(val.value); }} />
        </div>
        <div className="col-md-3">
          <Button style={{ margin: 10 }} variant="success" onClick={() => { window.open("#/priceupdates"); }}>Update Price</Button>
        </div>
      </div>

      <div>
        {finalPriceChartData && <Chart
          width={1000}
          height={500}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={finalPriceChartData && finalPriceChartData}
          options={{
            title: 'Price Analysis',
            chartArea: { width: '50%' },
            hAxis: {
              title: 'Pruchase Date',
              minValue: startDate,
            },
            vAxis: {
              title: 'Purchase Price',
            },
            legend: 'none'
          }}
        />}
      </div>

      {(priceAnalysisList.length > 0) ? <PriceAnalysisTable priceAnalysisListData={priceAnalysisList} /> : ((loading) ? (<LoaderComp />) : (<div style={{ margin: 40 }}>No test data present</div>))}

    </>
  );
};

export default PriceAnalysis;
