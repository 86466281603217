import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Navbar, Dropdown, DropdownButton, Alert, Table, Button, Form, Jumbotron, InputGroup, Accordion, Toast, Badge } from 'react-bootstrap';
import { InputGroupAddon, Input } from 'reactstrap';
import NavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import './PurchaseOrder.css'
import database from '../../service/firebaseConfig';
import Backdrop from '../../CommonRequirements/backdrop'
import { toast } from 'react-toastify';
import ReactSelect from 'react-select'
import Select from 'react-select';
import XLSX from 'xlsx'
import { LOGGED_USER_EMAIL } from '../../constants/allConstants';
import { validateIsAdminUser,validateProductUser, validatePurchaseUser, validatePurchaseExportUser, validatePurchaseExportOnlyUser } from '../../service/credentials';
import { deliveryCommercialServer, orderingServer } from '../../service/servicesConfig';
import PurchaseOrderListTable from './purchaseorderstable';
import './arrows.css';


function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const PurchaseOrder = () => {
  const [loading, setLoading] = useState(true);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
  const [searchPurchaseOrderDetails, setSearchPurchaseOrderDetails] = useState([]);
  const [exportData, setExportData] = useState(false);
  const [searchText, setSearchText] = useState("")
  const [statusText, setStatusText] = useState("All")
  const [category, setCategory] = useState("")
  const [subCategory, setSubCategory] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("")
  const [subCategoryFilter, setSubCategoryFilter] = useState("")
  const [showSubCategory, setShowSubCategory] = useState(false)
  const [buyingFlagFilter,setBuyingFlagFilter] = useState("N")
  const [activeFlag, setActiveFlag] = useState("")
  const [filterList, setFilterList] = useState("")
  const [activeStatusLines, setActiveStatusLines] = useState("")
  const [orderFilterObject, setOrderFilterObject] = useState({});
  const STOCK_TARGET = 2;

  useEffect(() => {
    fetchPurchaseOrderDetails(new Date());
  }, [])

  useEffect(() => {
    if (exportData)
      exportPurchaseOrderDetails()
  }, [exportData])

  useEffect(() => {
   
    if (searchText && statusText ) {
     
      filterPurchaseOrder()
    } else if (searchText == "" && statusText) {
      
      filterByStatus();
    } else {
      
      setPurchaseOrderDetails(searchPurchaseOrderDetails);
    }
  }, [searchText,statusText])

  const fetchPurchaseOrderDetails = (orderDt) => {
    
    let curdate = formatDate(orderDt);
   
    let dt = "2022-06-12"

    
    const getsysteminventory_url = `/api/inventory-movements?start_date=${curdate}&end_date=${curdate}&warehouse=Bhiwandi&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut`;

    deliveryCommercialServer.get(getsysteminventory_url).then(response => {

     
      if (response.status === 200) {
        return response.data.data;
      }
    }).then((result) => {
      
      let finalResultList = [];
      let itemIdSet = new Set()
      let categoryFilterArray = [];

      let firbaseProductObj = {};
   
      Promise.all([
        database.database().ref(`Product_Base_Data`).once("value"),
        database.database().ref(`Price_Details/mumbai/items/`).once("value")
      ]).then(([snapShot, snapVal]) => {
        if (snapShot.hasChildren()) {
          let productdata = snapShot.val();
          let itempricedata = snapVal.val();
         
          Object.keys(productdata).map((key, index) => {
            // if (productdata[key]['buying_flag'] == 'N') 
            {
              
              // let item_id = productdata[key]['item_id'];   --- changed coz some of items have missing item_id as key
             
              let item_id = productdata[key]['item_id'] || key;
                 itemIdSet.add(item_id);

              let el = {};
              el['reorder_point'] = productdata[key]['reorder_point'];
              el['stock_target'] = productdata[key]['stock_target'];
              el['max_inventory'] = productdata[key]['max_inventory'];
              el['Category'] = productdata[key]['category']?productdata[key]['category']:"";
              el['sub_category'] = productdata[key]['sub_category']?productdata[key]['sub_category']:"";
              el['active_status'] = productdata[key]['active_status']?productdata[key]['active_status']:"N"
              el['buying_flag'] = productdata[key]['buying_flag']?productdata[key]['buying_flag']:"N"
              el['brand'] = productdata[key]['brand']?productdata[key]['brand']:"";
              el['outer_size'] = productdata[key]['outer_size']?productdata[key]['outer_size']:"1"
              el['UOM'] = productdata[key]['uom']?productdata[key]['uom']:"PC"
              if (itempricedata[item_id]) {
                el['base_price'] = itempricedata[item_id]['landing_cost'] ? itempricedata[item_id]['landing_cost'] : "";
              } else {
                el['base_price'] = "";
              }

              firbaseProductObj[item_id] = el;
             
              // categoryFilterArray.push(el['Category']);
              // commented here so that catergory dd contains only categories which are listed in ui not all categories.
            }

          });

         // Make a request to the other API here
         
const getAvgOrder_url = `api/superzop/admin/orders/getaverage14daysorder`
      orderingServer.get(getAvgOrder_url)
      .then(response => {
        if (response.status === 200) {
          
          const avgOrderData = response.data;
         
          for (let i = 0; i < result.length; i++) {
            if (itemIdSet.has(result[i]['item_id'])) {
              let item_id = result[i]['item_id'];
              let ele = result[i];
              ele['reorder_point'] = firbaseProductObj[item_id]['reorder_point'];
              ele['stock_target'] = firbaseProductObj[item_id]['stock_target'];
              ele['max_inventory'] = firbaseProductObj[item_id]['max_inventory'];
              ele['Category'] = firbaseProductObj[item_id]['Category'];
              ele['active_status'] = firbaseProductObj[item_id]['active_status'];
              ele['buying_flag'] = firbaseProductObj[item_id]['buying_flag'];
              ele['brand'] = firbaseProductObj[item_id]['brand'];
              ele['base_price'] = firbaseProductObj[item_id]['base_price'];
              ele['outer_size'] = firbaseProductObj[item_id]['outer_size'];
              ele['UOM'] = firbaseProductObj[item_id]['UOM'];

             
          let matchingObject = avgOrderData.find(obj => obj.item_id === item_id);
         
         
          if (matchingObject) {
            ele['order_qty_avg'] = matchingObject['item_delivered_qty_avg'];
            
          } else {
            ele['order_qty_avg'] = ""; // Default value if matching object not found
            
          }
          if (ele['Category'] !== 'SUPER COINS') {
            finalResultList.push(ele);
            categoryFilterArray.push(ele['Category']);
          }
          // sort category array
          categoryFilterArray.sort((a, b) => {
            const firstWordA = a.split(' ')[0].toLowerCase();
            const firstWordB = b.split(' ')[0].toLowerCase();
            return firstWordA.localeCompare(firstWordB);
          });
        }
      }
      
         
          setLoading(false);
          // let filteredData = finalResultList.filter(item => item.buying_flag === buyingFlag)
          // console.log(filteredData)
          setPurchaseOrderDetails(finalResultList);
          setSearchPurchaseOrderDetails(finalResultList);
          let newCategoryList = [...new Set(categoryFilterArray)];
          let allCategory = "All";
          setCategory([allCategory].concat(newCategoryList));
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setPurchaseOrderDetails([]);
        setSearchPurchaseOrderDetails([]);
      });
  }
         else {
          setLoading(false);
          setPurchaseOrderDetails([]);
          setSearchPurchaseOrderDetails([]);
        }
      });
    }).catch((error) => {
      setLoading(false);
      setPurchaseOrderDetails([]);
      setSearchPurchaseOrderDetails([]);
    });


  }

  //category filter selection
  useEffect(() => {
    if (categoryFilter) {
      setSearchText("");
      filterCategory();
    }
  }, [categoryFilter])

  //sub category filter selection
  useEffect(() => {
    if (subCategoryFilter) {
      filterSubCategory()
    }
  }, [subCategoryFilter])

  //active status filter selection

  useEffect(() => {
    if (activeFlag) {
      filterActiveFlag();
    }
  }, [activeFlag])

  useEffect(() =>{
    if(buyingFlagFilter){
      
      filterBuyingFlag();
    }
  },[buyingFlagFilter])

  const filterBuyingFlag = () => {
   
    let filteredData = []
    resetFilter();
    if(buyingFlagFilter == "Y"){
      filteredData = searchPurchaseOrderDetails.filter(item => item.buying_flag == buyingFlagFilter)
     
      setPurchaseOrderDetails(filteredData)
      
    }else if (buyingFlagFilter == "N") {
          filteredData = searchPurchaseOrderDetails.filter(item => item.buying_flag == buyingFlagFilter)
         
          setPurchaseOrderDetails(filteredData)
          
        }else{
         
          setPurchaseOrderDetails(searchPurchaseOrderDetails);
          
        }
      
    
    }

    const resetFilter = () => {
      setActiveFlag("Y");
      setCategoryFilter("All");
      setStatusText("All")
    }
  

  const filterPurchaseOrder = () => {
    
    if (statusText == "All") {
      filterItems();
    } else {
      let checksearchtext = searchText.trim().toLowerCase();
      let filteredData = [];
      for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
        let pushflag = false;
        let itemid = searchPurchaseOrderDetails[i]['item_id'];
        let itemdesc = searchPurchaseOrderDetails[i]['item_disc'];
        let Category = searchPurchaseOrderDetails[i]['Category'];
        let Brand = searchPurchaseOrderDetails[i]['brand']
        let row = searchPurchaseOrderDetails[i];
        let status = findStatus(row);
      
        if (Category) {
          Category = Category.trim().toLowerCase();
          if (Category.includes(checksearchtext) && statusText == status) {
            pushflag = true;
            filteredData.push(searchPurchaseOrderDetails[i]);
          }
        }
        if (Brand) {
          Brand = Brand.trim().toLowerCase();
          if (Brand.includes(checksearchtext) && statusText == status) {
            pushflag = true;
            filteredData.push(searchPurchaseOrderDetails[i]);
          }
        }
        if (itemid && pushflag == false) {
          itemid = itemid.trim().toLowerCase();
          if (itemid.includes(checksearchtext) && statusText == status) {
            filteredData.push(searchPurchaseOrderDetails[i]);
            pushflag = true;
          }
        }
        if (itemdesc && pushflag == false) {
          itemdesc = itemdesc.trim().toLowerCase();
          if (itemdesc.includes(checksearchtext) && statusText == status) {
            filteredData.push(searchPurchaseOrderDetails[i]);
            pushflag = true;
          }
        }
      }
      setPurchaseOrderDetails(filteredData);
    }
  }

   const filterCategory = () => {
    let checksearchtext = categoryFilter.trim().toLowerCase();
   
    let filteredData = [];
    let subcategoryset = new Set();

    if (categoryFilter == "All") {
      setShowSubCategory(false);
      setSubCategory("");
      
      setPurchaseOrderDetails(searchPurchaseOrderDetails);
    } else {
      for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
        let Category = searchPurchaseOrderDetails[i]['Category'];
        let sub_category = searchPurchaseOrderDetails[i]['sub_category'];

        if (Category) {
          Category = Category.trim().toLowerCase();
          if (Category.includes(checksearchtext)) {
            if (!subcategoryset.has(sub_category)) {
              subcategoryset.add(sub_category);
            }
            filteredData.push(searchPurchaseOrderDetails[i]);
          }
        }
      }
      let subcategorylist = [...subcategoryset];
      subcategorylist.sort((a, b) => {
        const firstLetterA = a.split(' ')[0][0].toLowerCase();
        const firstLetterB = b.split(' ')[0][0].toLowerCase();
        return firstLetterA.localeCompare(firstLetterB);
      });
      let allSubCategory = "All";

      setShowSubCategory(true);
      setSubCategory([allSubCategory].concat(subcategorylist));
      setSubCategoryFilter(""); 
      // setStatusText("All")
      setPurchaseOrderDetails(filteredData);
    }
  }

  const filterSubCategory = () => {
    let checksearchtext = subCategoryFilter.trim().toLowerCase();
    let filteredData = [];

    if (subCategoryFilter == "All") {
      let chksearchtext = categoryFilter.trim().toLowerCase();
      for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
        let Category = searchPurchaseOrderDetails[i]['Category'];
        if (Category) {
          Category = Category.trim().toLowerCase();
          if (Category.includes(chksearchtext)) {
            filteredData.push(searchPurchaseOrderDetails[i]);

          }
        }
      }
      setPurchaseOrderDetails(filteredData);
    } else {
      for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
        let sub_category = searchPurchaseOrderDetails[i]['sub_category'];

        if (sub_category) {
          sub_category = sub_category.trim().toLowerCase();
          if (sub_category.includes(checksearchtext)) {
            filteredData.push(searchPurchaseOrderDetails[i]);
            
          }
        }
      }
      setPurchaseOrderDetails(filteredData);
    }
  }

  const filterActiveFlag = () => {
   
    let checksearchtext = activeFlag.trim().toLowerCase();
    let filteredData = [];
    if (activeFlag == "All") {
      setPurchaseOrderDetails(searchPurchaseOrderDetails);
    } else {
      for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
        let active_status = searchPurchaseOrderDetails[i]['active_status'];

        if (active_status) {
          active_status = active_status.trim().toLowerCase();
          if (active_status.includes(checksearchtext)) {
            filteredData.push(searchPurchaseOrderDetails[i]);
          }
        }
      }
      setPurchaseOrderDetails(filteredData);
    }
  }

  const filterItems = () => {
    // debugger;
    let checksearchtext = searchText.trim().toLowerCase();
    let filteredData = [];
    for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
      let pushflag = false;
      let itemid = searchPurchaseOrderDetails[i]['item_id'];
      let itemdesc = searchPurchaseOrderDetails[i]['item_disc'];
      let Category = searchPurchaseOrderDetails[i]['Category'];
      let Brand = searchPurchaseOrderDetails[i]['brand'];
      if (Category) {
        Category = Category.trim().toLowerCase();
        if (Category.includes(checksearchtext)) {
          pushflag = true;
          filteredData.push(searchPurchaseOrderDetails[i]);
        }
      }
      if (Brand) {
        Brand = Brand.trim().toLowerCase();
        if (Brand.includes(checksearchtext)) {
          pushflag = true;
          filteredData.push(searchPurchaseOrderDetails[i]);
        }
      }
      if (itemid && pushflag == false) {
        itemid = itemid.trim().toLowerCase();
        if (itemid.includes(checksearchtext)) {
          filteredData.push(searchPurchaseOrderDetails[i]);
          pushflag = true;
        }
      }
      if (itemdesc && pushflag == false) {
        itemdesc = itemdesc.trim().toLowerCase();
        if (itemdesc.includes(checksearchtext)) {
          filteredData.push(searchPurchaseOrderDetails[i]);
        }
      }
    }
    setCategoryFilter("All")
    setPurchaseOrderDetails(filteredData);

  }

  
  const filterByStatus = (subcategoryFilter) => {

    let filterItemsByStatus = [];
    if (statusText === "All" && categoryFilter === "All" && subcategoryFilter === "All") {
      setPurchaseOrderDetails(searchPurchaseOrderDetails);
    } else {
      for (let i = 0; i < searchPurchaseOrderDetails.length; i++) {
        let row = searchPurchaseOrderDetails[i];
        if (categoryFilter !== "All") {
          // Category filter is selected
          let category = row['Category'];
          if (category === categoryFilter) {
            if (subcategoryFilter !== "All") {
              // Subcategory filter is selected
              let subcategory = row['Subcategory'];
              if (subcategory === subcategoryFilter) {
                let status = findStatus(row);
                if (statusText === "OnPar") {
                  if (status !== "Buy" && status !== "Excess") {
                    filterItemsByStatus.push(row);
                  }
                } else {
                  if (statusText === status) {
                    filterItemsByStatus.push(row);
                  }
                }
              }
            } else {
              let status = findStatus(row);
              if (statusText === "OnPar") {
                if (status !== "Buy" && status !== "Excess") {
                  filterItemsByStatus.push(row);
                }
              } else {
                if (statusText === status) {
                  filterItemsByStatus.push(row);
                }
              }
            }
          }
        } else {
          // Category filter not selected
          let status = findStatus(row);
          if (statusText === "OnPar") {
            if (status !== "Buy" && status !== "Excess") {
              filterItemsByStatus.push(row);
            }
          } else {
            if (statusText === status) {
              filterItemsByStatus.push(row);
            }
          }
        }
      }
      setPurchaseOrderDetails(filterItemsByStatus);
    }
  }
  

  const findStatus = (row) => {
    
    let effectiveInventory;
    let inventory_after_shipped = ((row.inventory_after_shipped)*(row.outer_size)).toFixed(2)
   
     

      if (row.inventory_after_shipped !== undefined && row.order_qty_avg !== undefined) {
       
        if (row.order_qty_avg === null || row.order_qty_avg === 0) {
          effectiveInventory = 0;
        } else {
          effectiveInventory = row.inventory_after_shipped / row.order_qty_avg;
          effectiveInventory = Math.round((effectiveInventory + Number.EPSILON) * 100) / 100;
        }
      } else {
        effectiveInventory = 0;
      }
      
    let status = "OnPar";
    let reorder_qty;
    if (row.stock_target != undefined) {
      if (effectiveInventory < row.stock_target) {
        status = "Buy";
      }
    } else {
      if (effectiveInventory < STOCK_TARGET) {
        status = "Buy";
      }
    }

    if (row.max_inventory != undefined) {
      if (effectiveInventory > row.max_inventory) {
        status = "Excess";
      }
    } else {
      if (row.Category == "MASALAS/SPICES" || row.Category == "DRY FRUITS") {
        if (effectiveInventory > 15) {
          status = "Excess";
        }
      } else {
        if (effectiveInventory > 10) {
          status = "Excess";
        }
      }
    }
    return status;
  }

  const exportPurchaseOrderDetails = () => {
    

    const wb = XLSX.utils.book_new()

    setLoading(true)
    let purchaseOrderSummary = [["Item Code", "Item Name", "Category", "UOM", "Outer Size (Kg/Pc)", "Landing Cost", "Inventory (Kg/Pc)","Inventory Value", "Inventory in Outers (Bag/Pc)", "Avg Daily Sale Rate (Kg/Pc)", "Inventory (Days)", "Reorder Point (Days)", "Stock Target (Days)", "Max Inventory (Days)", "Reorder Qty (In Outers)", "Status"]]

    purchaseOrderDetails.map(item => {
     
      let effectiveInventory;
      let inventory_after_shipped = ((item.inventory_after_shipped)*(item.outer_size)).toFixed(2)
      

        if (item.inventory_after_shipped !== undefined && item.order_qty_avg !== undefined) {
        
          if (item.order_qty_avg === null || item.order_qty_avg === 0) {
            effectiveInventory = 0;
          } else {
            effectiveInventory = item.inventory_after_shipped / item.order_qty_avg;
            effectiveInventory = Math.round((effectiveInventory + Number.EPSILON) * 100) / 100;
          }
        } else {
          effectiveInventory = 0;
        }
        
      let effectiveInventoryOuters;
      
      if (item.inventory_after_shipped != undefined && item.outer_size != undefined) {
        
        effectiveInventoryOuters = inventory_after_shipped / item.outer_size;
        effectiveInventoryOuters = Math.round((effectiveInventoryOuters + Number.EPSILON) * 100) / 100
      }
      let status = "OnPar";
      let reorder_qty;
      if (item.stock_target != undefined) {
        if (effectiveInventory < item.stock_target) {
          status = "Buy";
        }
       
        reorder_qty = ((item.stock_target * item.order_qty_avg * item.outer_size) - item.inventory_after_shipped) / item.outer_size;
        reorder_qty = Math.round((reorder_qty + Number.EPSILON) * 100) / 100
      } else {
        if (effectiveInventory < STOCK_TARGET) {
          status = "Buy";
        }
        
        reorder_qty = ((4 * item.order_qty_avg) - inventory_after_shipped) / item.outer_size;
        reorder_qty = Math.round((reorder_qty + Number.EPSILON) * 100) / 100
      }
      if (item.max_inventory != undefined) {
        if (effectiveInventory > item.max_inventory) {
          status = "Excess";
        }
      } else {
        if (item.category == "MASALAS/SPICES" || item.category == "DRY FRUITS") {
          if (effectiveInventory > 15) {
            status = "Excess";
          }
        } else {
          if (effectiveInventory > 10) {
            status = "Excess";
          }
        }
      }

      let avgDailySales;
      if (item.order_qty_avg != undefined && item.outer_size != undefined) {
        avgDailySales = item.order_qty_avg * item.outer_size;
        avgDailySales = Math.round((avgDailySales + Number.EPSILON) * 100) / 100
      }

      let inventoryValueCol =  (inventory_after_shipped * (item.base_price)).toFixed(2);

      let purchaseOrderDetailsPerItem = [];
      purchaseOrderDetailsPerItem.push(item.item_id)
      purchaseOrderDetailsPerItem.push(item.brand?item.brand +' | '+item.item_disc : item.item_disc)
      purchaseOrderDetailsPerItem.push(item.category + " | " + item.sub_category)
      purchaseOrderDetailsPerItem.push(item.UOM)
      purchaseOrderDetailsPerItem.push(item.outer_size?Number(item.outer_size):'')
      purchaseOrderDetailsPerItem.push(Number(item.base_price))
     
      purchaseOrderDetailsPerItem.push(inventory_after_shipped?Number(inventory_after_shipped):'')
      purchaseOrderDetailsPerItem.push(inventoryValueCol?Number(inventoryValueCol):'')
      purchaseOrderDetailsPerItem.push(effectiveInventoryOuters?Number(effectiveInventoryOuters):'')
      purchaseOrderDetailsPerItem.push(avgDailySales?Number(avgDailySales):'')
      purchaseOrderDetailsPerItem.push(effectiveInventory?Number(effectiveInventory):'')
      purchaseOrderDetailsPerItem.push(Number(item.reorder_point ? item.reorder_point : 2))
      purchaseOrderDetailsPerItem.push(Number(item.stock_target ? item.stock_target : STOCK_TARGET))
      purchaseOrderDetailsPerItem.push(Number(item.max_inventory ? item.max_inventory : 10))
      purchaseOrderDetailsPerItem.push(reorder_qty?Number(reorder_qty):"")
      purchaseOrderDetailsPerItem.push(status)
      purchaseOrderSummary.push(purchaseOrderDetailsPerItem)

    })
    const purchaseOrderExportDetails = XLSX.utils.aoa_to_sheet(purchaseOrderSummary)

    let wscols = [
      { wch: 15 },
      { wch: 40 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 }
    ];

    purchaseOrderExportDetails['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, purchaseOrderExportDetails, `Purchase Order List`)
    XLSX.writeFile(wb, `Purchase_Order_List.xlsx`)
    setExportData(false)
    setLoading(false)
  }

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

 
  

  return (
    <>
      <NavBar />
      <div className="container col-sm-12" style={{ paddingTop: 100 }}>
        <center><h3>Purchase Order</h3></center>
        <div className="row">
          <div className="col-md-2">
            <Form.Control type="search" placeholder="Search by item name / item code /Brand " value={searchText} onChange={(e) => { handleKeyPress(e); }} />
          </div>
          <div className="col-md-2">
            <Select options={[{ "value": "All", "label": "All" }, { "value": "Excess", "label": "Excess" }, { "value": "Buy", "label": "Buy" }, { "value": "OnPar", "label": "OnPar" }]} placeholder='Status ➼ All' onChange={(val) => { setStatusText(val.value); }} />
          </div>
          <div>
            <InputGroup >
              <InputGroup.Prepend>
                <InputGroup.Text id="active-flag">Category</InputGroup.Text>
              </InputGroup.Prepend>
              <DropdownButton id="dropdown-basic-button" title={categoryFilter && categoryFilter !== "All" ? categoryFilter : "All"}>
                {category && category.map(items => (
                  <Dropdown.Item value={items} onClick={(e) => { e.preventDefault(); setCategoryFilter(items) }}>{items}</Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          </div>
          {showSubCategory == true ? (<div style={{ paddingLeft: 30 }}>
            <InputGroup >
              <InputGroup.Prepend>
                <InputGroup.Text id="active-flag">Sub-Cat</InputGroup.Text>
              </InputGroup.Prepend>
              <DropdownButton id="dropdown-basic-button" title={subCategoryFilter && subCategoryFilter !== "all" ? subCategoryFilter : "All"}>
                {subCategory && subCategory.map(items => (
                  <Dropdown.Item value={items} onClick={(e) => { e.preventDefault(); setSubCategoryFilter(items) }}>{items}</Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          </div>) : null}
          <div>
            <InputGroup style={{ paddingLeft: 30 }}>
              <InputGroup.Prepend>
                <InputGroup.Text id="active-flag">Buying Flag</InputGroup.Text>
              </InputGroup.Prepend>
              <DropdownButton id="dropdown-basic-button" title={buyingFlagFilter ? buyingFlagFilter : "N"}>
                <Dropdown.Item value={"Y"} onClick={(e) => { e.preventDefault(); setBuyingFlagFilter("Y") }}>Y</Dropdown.Item>
                <Dropdown.Item value={"N"} onClick={(e) => { e.preventDefault(); setBuyingFlagFilter("N") }}>N</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item value={"All"} onClick={(e) => { e.preventDefault(); setBuyingFlagFilter("All") }}>All</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </div>
          <div>
            <InputGroup style={{ paddingLeft: 30 }}>
              <InputGroup.Prepend>
                <InputGroup.Text id="active-flag">Active</InputGroup.Text>
              </InputGroup.Prepend>
              <DropdownButton id="dropdown-basic-button" title={activeFlag ? activeFlag : "Y"}>
                <Dropdown.Item value={"Y"} onClick={(e) => { e.preventDefault(); setActiveFlag("Y") }}>Y</Dropdown.Item>
                <Dropdown.Item value={"N"} onClick={(e) => { e.preventDefault(); setActiveFlag("N") }}>N</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item value={"All"} onClick={(e) => { e.preventDefault(); setActiveFlag("All") }}>All</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </div>
          {validateProductUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button style={{ marginLeft: "auto", marginRight: 30 }} variant="warning" onClick={() => setExportData(true)}>Export</Button>}
        </div>
       
        
        {(purchaseOrderDetails.length > 0) ? (<PurchaseOrderListTable purchaseOrderListData={purchaseOrderDetails}  />) : ((loading) ? ("") : (<div>No purchase order</div>))}
      </div>
      {loading ? (<Backdrop parentLoadStatus={loading} />) : (null)}
    </>
  )
}

export default PurchaseOrder
