import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { rootStore } from '../../store';
import database from '../../service/firebaseConfig';
import { CLOSE_SCREEN, INIT_PAGE_TITLE, PRICE_TABLE, PROD_MASTER_TABLE,BULK_UPLOADS } from '../../constants/allConstants';
import InputTag from '../../components/InputProdTag';
import LogoComponent from '../../components/LogoComp';
import FooterComponent from '../../components/Footer';
import PriceCalculations from '../../service/priceCalculations';
import { SESSION_TEXT } from '../../constants/allConstants';
import CSVReader from 'react-csv-reader';
import { MainContainer, AddProductContainer, TopContainer, MidSplit, LeftContainer, MidContainer, RightContainer } from './wrapper';

const AddProducts = () => {

    const tempRegionObj = {
        region_a: true,
        region_b: true,
        region_k: true,
        region_n: true,
        region_p: true,
        region_h: true,
        region_t: true
    };

    const regions = {
        "region_a": "A",
        "region_b": "B",
        "region_n": "N",
        "region_k": "K",
        "region_p": "P",
        "region_h": "H",
        "region_t": "T"
    };

    const dbStatusObj = {
        "region_a": null,
        "region_b": null,
        "region_n": null,
        "region_k": null,
        "region_p": null,
        "region_h": null,
        "region_t": null,
        "priceDetails": null
    };

    const transportCharges = {
        "region_a": "1.00",
        "region_b": "1.50",
        "region_n": "0.70",
        "region_p": "0.70",
        "region_h": "1.00",
        "region_k": "1.00",
        "region_t": "1.00"
    };

    toast.configure({
        autoClose: 3000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_RIGHT,
    });

    const globalState = useContext(rootStore);
    const { dispatch } = globalState;
    const history = useHistory();
    const [priceDetailsObj, setPriceDetailsObj] = useState({});
    const [productDataObj, setProductDataObj] = useState({});
    const [transPriceObj, setTransPriceObj] = useState(transportCharges);
    const [calcObj, setCalcObj] = useState({});
    const [regionObj, setRegionObj] = useState(tempRegionObj);
    const [showAddPrd, setShowAddPrd] = useState(false);
    const [currInputName, setNewInputName] = useState("");
    const [dbStatus, setDBStatus] = useState(dbStatusObj);
    const noData = (<span style={{ color: "#aaa" }}>No Data</span>);
    const reqSymbol = (<span className="req_symb">*</span>);
    const transText = "Enter Transport price";
    const [bulkProducts, setBulkProducts] = useState({});
    const [bulkPrice, setBulkPrice] = useState({});
    let Each_Region_Db_Obj = {}, Price_Details_Db_Obj = {};

    const closeAddProd = () => {
        dispatch({ type: CLOSE_SCREEN })
        history.push('/home');
    };



    const notifyUser = (success, region = null) => {
        let outputString = "Successfully added in server";
        outputString += region === "all" ? ` for region ${region}` : null;

        if (success) {
            return toast(outputString, { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't add the product", { type: toast.TYPE.ERROR });
    };

    const mandatoryFieldError = () => {
        return toast("Fill the mandatory fields", { type: toast.TYPE.ERROR });
    };

    const reloadHPAfterDBSet = () => {
        document.title = `${INIT_PAGE_TITLE}`;
        history.push('/home');

        const init = (region = null) => {
            notifyUser(true, region);
        };

        if (dbStatus.region_a && !regionObj.region_b && !regionObj.region_t && dbStatus.priceDetails) {   // iff region A
            init("A");
        } else if (dbStatus.region_b && !regionObj.region_a && !regionObj.region_t && dbStatus.priceDetails) {    // iff region B
            init("B");
        } else if (dbStatus.region_t && !regionObj.region_a && !regionObj.region_b && dbStatus.priceDetails) {    // iff region T
            init("T");
        } else if (dbStatus.region_t && !regionObj.region_a && !regionObj.region_b && dbStatus.priceDetails) {    // iff region T
            init("T");
        } else if (dbStatus.region_t && !regionObj.region_a && !regionObj.region_b && dbStatus.priceDetails) {    // iff region T
            init("T");
        } else if (dbStatus.region_a && dbStatus.region_b && dbStatus.region_t && dbStatus.priceDetails) {
            init("all");
        } else if (!dbStatus.region_a && !dbStatus.region_b && !dbStatus.region_t && !dbStatus.priceDetails) {
            notifyUser(false);
        }
    };

    const writeDataToDB = (region_name) => {

        const init = (db_name) => {
            console.log("updating to DB::",db_name);
            database.database().ref(`${db_name}` + productDataObj.item_id).set({
                ...Each_Region_Db_Obj
            }, function (error) {
                if (error) {
                    setDBStatus(dbStatus[`${region_name}`] = false);
                } else {
                    setDBStatus(dbStatus[`${region_name}`] = true);
                }
                reloadHPAfterDBSet();
            });
        };

        Each_Region_Db_Obj = {
            ...productDataObj,
            transport: transPriceObj[`${region_name}`]
        };
        init(`${PROD_MASTER_TABLE}/${regions[region_name]}/`);
    };

    const writeDataToPriceDB = () => {

        Price_Details_Db_Obj = {
            ...priceDetailsObj
        };

        console.log("Price Details Table: ", Price_Details_Db_Obj)

        database.database().ref(`${PRICE_TABLE}/mumbai/items/${productDataObj.item_id}`).set({
            ...Price_Details_Db_Obj
        }, function (error) {
            if (error) {
                setDBStatus(dbStatus['priceDetails'] = false);
            } else {
                setDBStatus(dbStatus['priceDetails'] = true);
            }
            reloadHPAfterDBSet();
        });
    };

    const bulkUpdate = () => {
        document.title = `${INIT_PAGE_TITLE} | Bulk Upload`;
        dispatch({ type: BULK_UPLOADS });
        history.push('/bulk');
    };

    const addDataInDB = () => {
        if(!productDataObj.item_id && !productDataObj.brand && !productDataObj.item_id && !priceDetailsObj.base_price) {
            mandatoryFieldError();
            return;
        }
        console.log("Writing to db");
        if (regionObj.region_a) {
            writeDataToDB("region_a");
        } if (regionObj.region_b) {
            writeDataToDB("region_b");
        } if (regionObj.region_n) {
            writeDataToDB("region_n");
        } if (regionObj.region_h) {
            writeDataToDB("region_h");
        } if (regionObj.region_p) {
            writeDataToDB("region_p");
        } if (regionObj.region_t) {
            writeDataToDB("region_t");
        } if (regionObj.region_k) {
            writeDataToDB("region_k");
        }
        writeDataToPriceDB();
    };

    const regionChanged = (event) => {
        const region_name = event.target.id;
        const tempObj = { ...regionObj };
        switch(region_name) {
        case"region_A": 
            tempObj.region_a = !tempObj.region_a;
            break;
        case"region_B": 
            tempObj.region_b = !tempObj.region_b;
            break;
        case"region_N": 
            tempObj.region_n = !tempObj.region_n;
            break;
        case"region_H": 
            tempObj.region_h = !tempObj.region_h;
            break;
        case"region_P": 
            tempObj.region_p = !tempObj.region_p;
            break;
        case"region_K": 
            tempObj.region_k = !tempObj.region_k;
            break;
        case"region_T": 
            tempObj.region_t = !tempObj.region_t;
            break;
        }
        setRegionObj(tempObj);
    };

    const onProductChange = (event) => {
        const target = event.target;
        setProductDataObj({
            ...productDataObj,
            [target.name]: target.value
        });
        setNewInputName(target.name);
    };

    const onPriceChange = (event) => {
        const target = event.target;
        setPriceDetailsObj({
            ...priceDetailsObj,
            [target.name]: target.value
        });
        setNewInputName(target.name);
    };

    const onTransChange = (event) => {
        const target = event.target;
        setTransPriceObj({
            ...transPriceObj,
            [target.name]: target.value
        });
        setNewInputName(target.name);
    };

    const onClickCalculate = () => {
        setCalcObj(PriceCalculations(priceDetailsObj));
    };

    const toggleShowAddProduct = () => {
        if (regionObj.region_a === false && regionObj.region_b === false && regionObj.region_c === false && regionObj.region_h === false && regionObj.region_k === false) {
            setShowAddPrd(false);
        } else {
            setShowAddPrd(true);
        }
    };

    const addBulkProducts = (csv) => {
        dispatch({ type: CLOSE_SCREEN })
        history.push('/');
    };

    const addBasePrice = (csv) => {
        dispatch({ type: CLOSE_SCREEN })
        history.push('/');
    };

    useEffect(() => {
        toggleShowAddProduct();
    }, [regionObj]);

    useEffect(() => {
        setNewInputName(currInputName);
    }, [currInputName]);

    useEffect(() => {
        setProductDataObj(productDataObj);
    }, [productDataObj]);

    useEffect(() => {
        setPriceDetailsObj(priceDetailsObj);
    }, [priceDetailsObj]);

    useEffect(() => {
        setTransPriceObj(transPriceObj);
    }, [transPriceObj]);


    const TopPartComp = () => {
        return (
            <TopContainer>
                <h6><span>Item Id {reqSymbol} </span>: <InputTag curr={currInputName} inpType="number" nameTag="item_id" onInputChange={onProductChange} val={productDataObj.item_id} /> </h6>
                <h6><span>Agent Id  </span>: <InputTag curr={currInputName} inpType="number" nameTag="agent" onInputChange={onProductChange} val={productDataObj.agent} /> </h6>
                <h6><span>Distributor Id  </span>: <InputTag curr={currInputName} inpType="number" nameTag="distributor_id" onInputChange={onProductChange} val={productDataObj.distributor_id} /> </h6>
                <h6><span>Stock Indicator</span>: <InputTag curr={currInputName} nameTag="stock_ind" onInputChange={onProductChange} val={productDataObj.stock_ind} /> </h6>
            </TopContainer>
        );
    };

    const LeftSideComp = () => {
        return (
            <LeftContainer>
                <section>
                    <h6><span>EAN</span>: <InputTag curr={currInputName} inpType="number" nameTag="ean" onInputChange={onProductChange} val={productDataObj.ean} /> </h6>
                    <h6><span>Brand {reqSymbol} </span>: <InputTag isRequired={true} curr={currInputName} nameTag="brand" onInputChange={onProductChange} val={productDataObj.brand} /> </h6>
                    <h6><span>Brand in Hindi</span>: <InputTag curr={currInputName} nameTag="brand_hindi" onInputChange={onProductChange} val={productDataObj.brand_hindi} /> </h6>
                    <h6><span>Category {reqSymbol} </span>: <InputTag isRequired={true} curr={currInputName} nameTag="category" onInputChange={onProductChange} val={productDataObj.category} /> </h6>
                    <h6><span>Sub Category</span>: <InputTag curr={currInputName} nameTag="sub_category" onInputChange={onProductChange} val={productDataObj.sub_category} /> </h6>
                    <h6><span>Major Category {reqSymbol} </span>: <InputTag isRequired={true} curr={currInputName} nameTag="major_category" onInputChange={onProductChange} val={productDataObj.major_category} /> </h6>
                    <h6><span>Image Name</span>: <InputTag curr={currInputName} nameTag="image" onInputChange={onProductChange} val={productDataObj.image} /> </h6>
                    <h6><span>Image Folder</span>: <InputTag curr={currInputName} nameTag="image_folder" onInputChange={onProductChange} val={productDataObj.image_folder} /> </h6>
                    <h6><span>Image URL</span>: <InputTag curr={currInputName} nameTag="image_url" onInputChange={onProductChange} val={productDataObj.image_url} /> </h6>
                    <h6><span>Variety</span>: <InputTag curr={currInputName} nameTag="variety" onInputChange={onProductChange} val={productDataObj.variety} /> </h6>
                    <h6><span>Item Description</span>: <InputTag curr={currInputName} nameTag="item_description1" onInputChange={onProductChange} val={productDataObj.item_description1} /> </h6>
                    <h6><span>Description in Hindi</span>: <InputTag curr={currInputName} nameTag="hindi_description" onInputChange={onProductChange} val={productDataObj.hindi_description} /> </h6>
                    <h6><span>Long Description</span>: <InputTag curr={currInputName} nameTag="long_description" onInputChange={onProductChange} val={productDataObj.long_description} /> </h6>
                    <h6><span>Long Description in Hindi</span>: <InputTag curr={currInputName} showAdd={true} nameTag="long_description_hindi" onInputChange={onProductChange} val={productDataObj.long_description_hindi} /> </h6>
                    <h6><span>Weight</span>: <InputTag curr={currInputName} nameTag="weight" onInputChange={onProductChange} val={productDataObj.weight} /> </h6>
                </section>
            </LeftContainer>
        );
    };

    const MidComp = () => {
        return (
            <MidContainer>
                <section>
                    <h6><span>Base Price{reqSymbol} </span>: <InputTag isRequired={true} curr={currInputName} inpType="number" nameTag="base_price" onInputChange={onPriceChange} val={priceDetailsObj.base_price} /> </h6>
                    <h6><span>APMC Loading</span>: <InputTag curr={currInputName} inpType="number" nameTag="apmc_loading" onInputChange={onPriceChange} val={priceDetailsObj.apmc_loading} /> </h6>
                    <h6><span>Conversion (%)</span>: <InputTag curr={currInputName} nameTag="conversion_per" onInputChange={onPriceChange} val={priceDetailsObj.conversion_per} /> % </h6>
                    <h6><span>SZ Margin</span>: <InputTag curr={currInputName} inpType="number" nameTag="sz_margin" onInputChange={onPriceChange} val={priceDetailsObj.sz_margin} /> </h6>
                    <h6><span>Lot Size</span>: <InputTag curr={currInputName} inpType="number" nameTag="lot_size" onInputChange={onPriceChange} val={priceDetailsObj.lot_size} /> </h6>
                    <h6><span>MRP Price</span>: <InputTag curr={currInputName} inpType="number" nameTag="mrp" onInputChange={onPriceChange} val={priceDetailsObj.mrp} /> </h6>
                    <h6><span>Extra Margin (%)</span>: <InputTag curr={currInputName} nameTag="extra_margin_per" onInputChange={onPriceChange} val={priceDetailsObj.extra_margin_per} /> % </h6>
                    <h6><span>Pune Extra Margin (%)</span>: <InputTag curr={currInputName} nameTag="extra_margin_pune_per" onInputChange={onPriceChange} val={priceDetailsObj.extra_margin_pune_per} /> % </h6>
                    <h6><span>Material Cost</span>: <InputTag curr={currInputName} inpType="number" nameTag="material_cost" onInputChange={onPriceChange} val={priceDetailsObj.material_cost} /> </h6>
                    <h6><span>Wastage Cost</span>: <InputTag curr={currInputName} inpType="number" nameTag="wastage" onInputChange={onPriceChange} val={priceDetailsObj.wastage} /> </h6>
                    <h6><span>Market Price</span>: <InputTag curr={currInputName} inpType="number" nameTag="market_price" onInputChange={onPriceChange} val={productDataObj.market_price} /> </h6>
                    <h6><span>Minimum Order Quantity</span>: <InputTag curr={currInputName} inpType="number" nameTag="moq" onInputChange={onPriceChange} val={productDataObj.moq} /> </h6>
                    <h6><span>Maximum Order Quantity</span>: <InputTag curr={currInputName} inpType="number" nameTag="max_order_quantity" onInputChange={onPriceChange} val={productDataObj.max_order_quantity} /> </h6>
                    <h6><span>GST (%)</span>: <InputTag curr={currInputName} nameTag="gst_per" onInputChange={onPriceChange} val={priceDetailsObj.gst_per} /> % </h6>
                    <h6><span>APMC (%)</span>: <InputTag curr={currInputName} nameTag="apmc_per" onInputChange={onPriceChange} val={priceDetailsObj.apmc_per} /> % </h6>
                </section>
            </MidContainer>
        );
    };

    const RightSideComp = () => {
        return (
            <RightContainer>
                <section className="topPart">
                    <h6> <input id="region_A" type="checkbox" checked={regionObj.region_a} onChange={regionChanged} />
                        <label htmlFor="region_A" style={regionObj.region_a ? null : { textDecorationLine: "line-through" }} >Region A </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_a} placeText={transText} onInputChange={onTransChange} nameTag="region_a" val={transPriceObj.region_a} />
                    </h6>
                    <h6> <input id="region_B" type="checkbox" checked={regionObj.region_b} onChange={regionChanged} />
                        <label htmlFor="region_B" style={regionObj.region_b ? null : { textDecorationLine: "line-through" }} >Region B </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_b} placeText={transText} onInputChange={onTransChange} nameTag="region_b" val={transPriceObj.region_b} />
                    </h6>
                    <h6> <input id="region_K" type="checkbox" checked={regionObj.region_k} onChange={regionChanged} />
                        <label htmlFor="region_K" style={regionObj.region_t ? null : { textDecorationLine: "line-through" }} >Region K </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_k} placeText={transText} onInputChange={onTransChange} nameTag="region_k" val={transPriceObj.region_k} />
                    </h6>
                    <h6> <input id="region_N" type="checkbox" checked={regionObj.region_n} onChange={regionChanged} />
                        <label htmlFor="region_N" style={regionObj.region_n ? null : { textDecorationLine: "line-through" }} >Region N </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_n} placeText={transText} onInputChange={onTransChange} nameTag="region_n" val={transPriceObj.region_n} />
                    </h6>
                    <h6> <input id="region_H" type="checkbox" checked={regionObj.region_h} onChange={regionChanged} />
                        <label htmlFor="region_H" style={regionObj.region_h ? null : { textDecorationLine: "line-through" }} >Region H </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_h} placeText={transText} onInputChange={onTransChange} nameTag="region_h" val={transPriceObj.region_h} />
                    </h6>
                    <h6> <input id="region_P" type="checkbox" checked={regionObj.region_p} onChange={regionChanged} />
                        <label htmlFor="region_P" style={regionObj.region_p ? null : { textDecorationLine: "line-through" }} >Region P </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_p} placeText={transText} onInputChange={onTransChange} nameTag="region_p" val={transPriceObj.region_p} />
                    </h6>
                    <h6> <input id="region_T" type="checkbox" checked={regionObj.region_t} onChange={regionChanged} />
                        <label htmlFor="region_T" style={regionObj.region_t ? null : { textDecorationLine: "line-through" }} >Region T </label>
                        <InputTag curr={currInputName} classForRegion="classForRegion" inpType="number" isDisabled={!regionObj.region_t} placeText={transText} onInputChange={onTransChange} nameTag="region_t" val={transPriceObj.region_t} />
                    </h6>
                </section>
                {/* <section className="calcPart">
                    <div className="calc_btn" onClick={onClickCalculate}>Calculate</div>
                </section>
                <section className="midPart">
                    <h6> <span>APMC Charges</span> : <span className="data_text">{calcObj.apmc_charges || noData}</span> </h6>
                    <h6> <span>GST Charges</span> : <span className="data_text">{calcObj.gst_charges || noData}</span> </h6>
                    <h6> <span>Total Price</span> : <span className="data_text">{calcObj.total_price || noData}</span> </h6>
                    <h6> <span>Total Bag Price</span> : <span className="data_text">{calcObj.total_bag_price || noData}</span> </h6>
                    <h6> <span>Dealer Price</span> : <span className="data_text">{calcObj.dealer_price || noData}</span> </h6>
                </section> */}

                {/* <section className="botPart">
                    <h6> <span>Upload Base Price Details</span>  </h6>
                    <CSVReader onFileLoaded={data => addBulkProducts(data)} />
                    <CSVReader onFileLoaded={data => addBasePrice(data)} />
                    {showAddPrd ? <div className="addBulk" onClick={addDataInDB}> Add Products </div> : null}

                </section> */}
                <section className="botPart">
                    {showAddPrd ? <div className="add" onClick={addDataInDB}> Add Product </div> : null}
                    <div className="cancel" onClick={closeAddProd}> Cancel</div>
                </section>
            </RightContainer>
        );
    };

    return (
        <MainContainer>
            <LogoComponent />
            <AddProductContainer>
                <header>
                    <h3>Add Product</h3>
                    <button onClick={bulkUpdate}> Bulk Upload </button> 
                    <span onClick={closeAddProd}> x </span>
                </header>
                <hr />
                <form>
                    <TopPartComp />
                    <MidSplit>
                        <LeftSideComp />
                        <MidComp />
                        <RightSideComp />
                    </MidSplit>
                </form>
            </AddProductContainer>
            <FooterComponent />
        </MainContainer>
    );
};

export default AddProducts;