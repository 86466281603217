import React, { useState } from "react";
import database from "../../service/firebaseConfig";
import { Button } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import Backdrop from "../../CommonRequirements/backdrop";

const ImageUpload = ({ fileName, children, ...props }) => {
  
  const [backDrop, setBackDrop] = useState(false);

  let modifiedFileName = fileName.replace(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/ /g, "_") + Math.floor(Math.random() * 90 + 10);

  const upLoadFile = (files) => {
    
    setBackDrop(true);
    const storag = database.storage();
    const storeData = storag.ref();
    var uploadTask = storeData
      .child(`superzop_ordering_product_images/${modifiedFileName}.jpg`)
      .put(files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        console.log(snapshot.state);
        switch (snapshot.state) {
          case "paused": // or 'paused'
            console.log("Upload is paused");
            break;
          case "running": // or 'running'
            console.log("Upload is running");
            break;
          default:
            console.log("Upload error");
        }
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
          props.newUrl(downloadURL);
          setBackDrop(false);
        });
      }
    );
  };
  return (
    <>
      {/* {children ? children : ""} */}
      <ImageUploader
        withIcon={false}
        withLabel={false}
        singleImage
        buttonStyles={{ backgroundColor: "white" }}
        buttonText={children}
        onChange={(val) => {
          console.log(val)
          upLoadFile(val);
          console.log(val);
        }}
        imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
        maxFileSize={5242880}
      ></ImageUploader>
      {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}
    </>
  );
};

export default ImageUpload;
