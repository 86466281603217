import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Form,
  Card,
  Alert,
  Button,
  Dropdown,
  Col,
  Jumbotron,
  InputGroup,
  Row,
  Container,
} from "react-bootstrap";
import database from "../../service/firebaseConfig";
import { Link } from "react-router-dom";
import NewimageUpload from "./NewImageUpload";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import FooterComponent from "../../components/Footer/index";
import { toast } from "react-toastify";
import { BASE_IMAGE_URL, IMAGE_TOKEN } from "../../constants/allConstants";
import { LeftContainer } from "../../Dashboard/Navbar/wrapper";
import ProductOffer from "./OfferCategory/ProductOffer/ProductOffer";
import CustomerOffer from "./OfferCategory/CustomerOffer";
import { LoadingOverlay } from "@mantine/core";
import Backdrop from "../../CommonRequirements/backdrop";
import {
  PRODUCT_OFFER_URL,
  PRODUCT_OFFER_EDIT_URL,
  OFFER_URL,
  OFFER_EDIT_URL,
  CUSTOM_OFFER_EDIT_URL,
  CUSTOM_OFFER_URL,
  PRODUCT_OFFFER_NOTIFICATIONS_URL,
} from "./OfferMasterURLs";
import CustomOffer from "./OfferCategory/CustomOffer";

const userModifying = sessionStorage.getItem(`Logged User Email Id`);

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

const OfferMasterMainNew = (props) => {
  const [offerCategory, setOfferCategory] = useState(
    sessionStorage.getItem("offerCategory") || "productOffer"
  );
  const [isOfferCategoryDisabled, setIsOfferCategoryDisabled] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const offerCategoryDropdownText = `${offerCategory}`;
  const uploadingToastId = React.useRef(null);

  const submitDataToApi = (dataToSubmit, offerType, isEditable) => {
    uploadingToastId.current = toast("Creating offer, please wait....", {
      type: toast.TYPE.INFO,
      autoClose: false,
      hideProgressBar: true,
      closeButton: false,
    });

    setShowLoadingOverlay(true);
    return new Promise((resolve, reject) => {
      if (offerType === "product") {
        const prod_url = isEditable
          ? PRODUCT_OFFER_EDIT_URL
          : PRODUCT_OFFER_URL;
        axios
          .post(prod_url, dataToSubmit)
          .then(async (response) => {
            if (!isEditable) {
              if (dataToSubmit.notification_timings) {
                const notification_data = {
                  notification_title: dataToSubmit.offer_description,
                  notification_description: dataToSubmit.notification_text,
                  notification_image: dataToSubmit.image_url,
                  scheNotTimObj: {
                    notificationTimings: dataToSubmit.notification_timings.map(
                      (item) => {
                        let time = item.split(":");
                        let hour = time[0];
                        let minute = time[1];
                        if (Number(hour) > 12) {
                          hour = hour - 12;

                          if (minute === "00") {
                            return `${hour} PM`;
                          }
                          return `${hour}:${minute} PM`;
                        } else {
                          if (minute === "00") {
                            return `${hour} AM`;
                          }
                          return `${hour}:${minute} AM`;
                        }
                      }
                    ),
                  },
                };
                dataToSubmit.store_series.split(",").map((item) => {
                  notification_data[`${item.toLowerCase()}_series`] = item;
                });

                let notification_res = await axios.post(
                  PRODUCT_OFFFER_NOTIFICATIONS_URL,
                  notification_data
                );
              }
            }
            let resCode = response.data?.success || 0;
            setShowLoadingOverlay(false);
            toast.dismiss(uploadingToastId.current);
            if (resCode === 1) {
              resolve();
              return toast(`Offer created Successfully`, {
                type: toast.TYPE.SUCCESS,
              });
            } else {
              reject();
              return toast(`Offer creation Failed`, {
                type: toast.TYPE.ERROR,
              });
            }
            // console.log("Successfully sent data to API", response.data);
          })
          .catch((error) => {
            reject();
            setShowLoadingOverlay(false);
            toast.dismiss(uploadingToastId.current);
            let errorMessage =
              error.response?.data?.message ||
              "An error occured while uploading data";
            console.log("An error occured while sending data", error);
            return toast(JSON.stringify(errorMessage), {
              type: toast.TYPE.ERROR,
            });
          });
      }
      if (offerType === "customer") {
        const cust_url = isEditable ? OFFER_EDIT_URL : OFFER_URL;
        axios
          .post(cust_url, dataToSubmit)
          .then((response) => {
            let resCode = response.data?.success || 0;
            setShowLoadingOverlay(false);
            toast.dismiss(uploadingToastId.current);

            if (resCode === 1) {
              resolve();
              return toast(`Offer created Successfully`, {
                type: toast.TYPE.SUCCESS,
              });
            } else {
              reject("Offer creation Failed");
              return toast(`Offer creation Failed`, {
                type: toast.TYPE.ERROR,
              });
            }
            // console.log("Successfully send data to API", response.data);
          })
          .catch((error) => {
            reject();
            setShowLoadingOverlay(false);
            toast.dismiss(uploadingToastId.current);
            let errorMessage =
              error.response?.data?.message ||
              "An Error occured while uploading data";
            console.log("An error occured while sending data", errorMessage);
            return toast(errorMessage, {
              type: toast.TYPE.ERROR,
            });
          });
      }
      if (offerType === "custom") {
        const cust_url = isEditable ? CUSTOM_OFFER_EDIT_URL : CUSTOM_OFFER_URL;
        axios
          .post(cust_url, dataToSubmit)
          .then((response) => {
            let resCode = response.data?.success || 0;
            setShowLoadingOverlay(false);
            toast.dismiss(uploadingToastId.current);

            if (resCode === 1) {
              resolve();
              return toast(`Offer created Successfully`, {
                type: toast.TYPE.SUCCESS,
              });
            } else {
              reject("Offer creation Failed");
              return toast(`Offer creation Failed`, {
                type: toast.TYPE.ERROR,
              });
            }
            // console.log("Successfully send data to API", response.data);
          })
          .catch((error) => {
            reject();
            setShowLoadingOverlay(false);
            toast.dismiss(uploadingToastId.current);
            let errorMessage =
              error.response?.data?.message ||
              "An Error occured while uploading data";
            console.log("An error occured while sending data", errorMessage);
            return toast(errorMessage, {
              type: toast.TYPE.ERROR,
            });
          });
      }
    });
  };

  const handleOnOfferCategorySelection = (eventKey, obj) => {
    sessionStorage.setItem("offerCategory", eventKey);
    setOfferCategory(eventKey);
  };

  const renderOfferForm = () => {
    if (offerCategory === "productOffer") {
      return (
        <ProductOffer
          {...props}
          submitDataToApi={submitDataToApi}
          SetOfferCategoryChangeDisabled={setIsOfferCategoryDisabled}
        />
      );
    } else if (offerCategory === "customerOffer") {
      return (
        <CustomerOffer
          {...props}
          submitDataToApi={submitDataToApi}
          SetOfferCategoryChangeDisabled={setIsOfferCategoryDisabled}
        />
      );
    } else if (offerCategory === "customOffer") {
      return (
        <CustomOffer
          {...props}
          submitDataToApi={submitDataToApi}
          SetOfferCategoryChangeDisabled={setIsOfferCategoryDisabled}
        />
      );
    }
  };
  return (
    <div className="offerMasterMainContainer">
      {showLoadingOverlay ? (
        <Backdrop parentLoadStatus={showLoadingOverlay} />
      ) : null}
      <div>
        <NavBar />
      </div>
      <br />
      <br />
      <br />
      <br />
      <Alert variant="primary" style={{ height: "80px" }}>
        Create New Offers
        <span>
          <Button variant="info" style={{ float: "right" }}>
            <Link style={{ color: "white" }} to="/offersListing">
              List Series
            </Link>
          </Button>
        </span>
      </Alert>
      <Row>
        <Col>
          <fieldset disabled={isOfferCategoryDisabled}>
            <Dropdown
              style={{ textAlign: "center" }}
              onSelect={handleOnOfferCategorySelection}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {offerCategoryDropdownText}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="productOffer"
                  active={offerCategory === "productOffer"}
                >
                  Product Offer
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="customerOffer"
                  active={offerCategory === "customerOffer"}
                >
                  Customer Offer
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="customOffer"
                  active={offerCategory === "customOffer"}
                >
                  Custom Offer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </fieldset>
        </Col>
      </Row>

      <div className="offerMasterFormContainer">{renderOfferForm()}</div>
      {/* renderProductOffer or CustomerOffer here */}
      <br />
      <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
        <FooterComponent />
      </div>
    </div>
  );
};

export default OfferMasterMainNew;
